import {
  type PersistenceUpdateCompanyOnboardingAnswersBodyType,
  type UpdateCompanyOnboardingAnswersType
} from 'data/modules/cards/companyOnboarding';

import { CustomDate, CustomObject } from 'shared/utils/custom';
import { Mask } from 'shared/utils/format';

class UpdateCompanyOnboardingAnswersMapper {
  toPersistence(
    payload: UpdateCompanyOnboardingAnswersType
  ): PersistenceUpdateCompanyOnboardingAnswersBodyType {
    return {
      step: payload.step,
      ...(payload.context === 'usage_estimation'
        ? {
            context: payload.context,
            answers: CustomObject.removeUndefinedKeys({
              maximum_transaction_value_estimate:
                payload.answers.maximumTransactionValueEstimate,
              most_transaction_types: payload.answers.mostTransactionTypes,
              average_montly_spend: payload.answers.averageMontlySpend,
              dont_know_answer_average_spend:
                payload.answers.dontKnowAnswerAverageSpend,
              transactions_over_fifteen_thousand:
                payload.answers.transactionsOverFifteenThousand,
              cards_amount: payload.answers.cardsAmount
            })
          }
        : payload.context === 'company_account'
          ? {
              context: payload.context,
              answers: CustomObject.removeUndefinedKeys({
                ...CustomObject.pick(payload.answers, [
                  'city',
                  'complement',
                  'contract',
                  'country',
                  'email',
                  'neighborhood',
                  'number',
                  'state',
                  'street'
                ]),
                trading_name: payload.answers.tradingName,
                ...(payload.answers.foundingDate && {
                  founding_date: CustomDate.formatToIso(
                    payload.answers.foundingDate
                  )
                }),
                legal_name: payload.answers.legalName,
                ...(payload.answers.mainActivity && {
                  main_activity: Mask.remove(payload.answers.mainActivity)
                }),
                ...(payload.answers.postalCode && {
                  postal_code: Mask.remove(payload.answers.postalCode)
                }),
                ...(payload.answers.businessType && {
                  business_type: Mask.remove(payload.answers.businessType)
                }),
                document_number:
                  payload.answers.documentNumber &&
                  Mask.remove(payload.answers.documentNumber),
                ...(payload.answers.phoneNumber && {
                  phone_number: `55${Mask.remove(payload.answers.phoneNumber)}`
                }),
                shareholders: payload.answers.shareholders?.map(shareholder =>
                  CustomObject.removeUndefinedKeys({
                    ...CustomObject.pick(shareholder, [
                      'type',
                      'name',
                      'document',
                      'email',
                      'contract'
                    ]),
                    mother_name: shareholder.motherName,
                    ...(shareholder.birthDate && {
                      birth_date: CustomDate.formatToIso(shareholder.birthDate)
                    }),
                    ...(shareholder.phoneNumber && {
                      phone_number: `55${Mask.remove(shareholder.phoneNumber)}`
                    }),
                    legal_name: shareholder.legalName,
                    trading_name: shareholder.tradingName,
                    business_type: shareholder.businessType,
                    main_activity: shareholder.mainActivity,
                    shareholder_type:
                      shareholder.shareholderType === 'legalRepresentative'
                        ? 'legal_representative'
                        : shareholder.shareholderType,
                    ...(shareholder.foundingDate && {
                      founding_date: CustomDate.formatToIso(
                        shareholder.foundingDate
                      )
                    }),
                    addresses: shareholder.addresses?.map(address =>
                      CustomObject.removeUndefinedKeys({
                        ...CustomObject.omit(address, ['postalCode']),
                        ...(address.postalCode && {
                          postal_code: Mask.remove(address.postalCode)
                        })
                      })
                    )
                  })
                )
              })
            }
          : payload.context === 'cards_order'
            ? {
                context: payload.context,
                answers: {
                  cards_order:
                    payload.answers.cardsOrder === null
                      ? null
                      : {
                          city: payload.answers.cardsOrder?.city,
                          complement: payload.answers.cardsOrder?.complement,
                          country: payload.answers.cardsOrder?.country,
                          neighborhood:
                            payload.answers.cardsOrder?.neighborhood,
                          number: payload.answers.cardsOrder?.number,
                          phone_number: payload.answers.cardsOrder?.phoneNumber,
                          postal_code: payload.answers.cardsOrder?.postalCode,
                          quantity: payload.answers.cardsOrder?.quantity,
                          receiver: payload.answers.cardsOrder?.receiver,
                          state: payload.answers.cardsOrder?.state,
                          street: payload.answers.cardsOrder?.street
                        }
                }
              }
            : payload.context === 'filler'
              ? {
                  context: payload.context,
                  answers: {
                    ...CustomObject.omit(payload.answers, ['phoneNumber']),
                    ...(payload.answers.phoneNumber && {
                      phone_number: `55${Mask.remove(payload.answers.phoneNumber)}`
                    })
                  }
                }
              : payload.context === 'configuration'
                ? {
                    context: payload.context,
                    answers: {
                      use_international_transaction:
                        payload.answers.useInternationalTransaction,
                      use_pix: payload.answers.usePix,
                      use_withdraw: payload.answers.useWithdraw,
                      users_to_use_cards: payload.answers.usersToUseCards?.map(
                        user => ({
                          ...CustomObject.pick(user, ['name', 'type']),
                          user_id: user.uuid,
                          company_id: user.companyUuid,
                          must_have_account: user.mustHaveAccount
                        })
                      )
                    }
                  }
                : payload.context === 'company_terms_of_use'
                  ? {
                      context: payload.context,
                      answers: {
                        accept_privacy_policy:
                          payload.answers.acceptPrivacyPolicy,
                        accept_terms_of_use: payload.answers.acceptTermsOfUse
                      }
                    }
                  : payload.context === 'feedback'
                    ? {
                        context: payload.context,
                        answers: {
                          feedback_note: payload.answers.feedbackNote,
                          feedback_comment: payload.answers.feedbackComment
                        }
                      }
                    : {
                        context: payload.context,
                        answers: {
                          legal_procuration: payload.answers.legalProcuration,
                          member_legal_representative:
                            payload.answers.memberLegalRepresentative
                        }
                      })
    };
  }
}

export default new UpdateCompanyOnboardingAnswersMapper();
