import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useAuthContext, useModalContext } from 'data/contexts';
import { AccountQueryKeys } from 'data/modules/cards/account';
import {
  BusinessCardsMutationKeys,
  BusinessCardsService,
  type IAttachUserPayload,
  type ICreateBusinessCardsFieldsForm,
  type ICreateSharedAccount,
  type ICreateSharedAccountPayload,
  type ICreateTeamMembersVirtual,
  type ICreateTeamMembersVirtualPayload,
  type IResponseAttachUser,
  type IUseCreateBusinessCards,
  type IUseCreateBusinessCardsParams
} from 'data/modules/cards/businessCard';

import { useFormWithSchema } from 'shared/hooks/forms';

import { useCreateBusinessCardSchema } from './schemas';

export function useCreateBusinessCard({
  onSetBusinessCardProps,
  onChangeAccountId
}: IUseCreateBusinessCardsParams): IUseCreateBusinessCards {
  const { handleOpenModal } = useModalContext();

  const company = useAuthContext(state => state.company);

  const [uuidSharedAccounts, setUuidSharedAccounts] = useState<string>('');

  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    reset: formReset,
    clearErrors,
    formState: { errors }
  } = useFormWithSchema(useCreateBusinessCardSchema(), {
    defaultValues: {
      accountHolderId: '',
      email: '',
      name: ''
    }
  });

  const {
    mutate: userAttachingMutation,
    isLoading: loadingUserAttachingMutation
  } = useMutationCache({
    mutationKey: [BusinessCardsMutationKeys.ATTACH_USER],
    mutationFn: async (payload: IAttachUserPayload) =>
      await BusinessCardsService.attachUser(payload),
    onSuccess: ({ nameCard, uuid }: IResponseAttachUser) => {
      onSetBusinessCardProps({
        uuid,
        nameCard
      });
      queryClient.invalidateQueries({
        queryKey: [AccountQueryKeys.GET_COMPANY_ACCOUNTS]
      });
      handleOpenModal('setUpAccountability');
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  const { mutate: createVirtualUser, isLoading: loadingCreateVirtualUser } =
    useMutationCache({
      mutationKey: [BusinessCardsMutationKeys.CREATE_VIRTUAL_USER],
      mutationFn: async (payload: ICreateTeamMembersVirtualPayload) =>
        await BusinessCardsService.createTeamMembersVirtual(payload),
      onSuccess: (
        { id }: ICreateTeamMembersVirtual,
        payload: ICreateSharedAccountPayload
      ) => {
        userAttachingMutation({
          userAttach: [
            {
              id,
              name: payload.name,
              type: 'VIRTUAL'
            }
          ],
          uuidSharedAccounts,
          nameCard: payload.name,
          uuidBusinessCard: id
        });
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  const {
    mutate: createBusinessCardMutation,
    isLoading: loadingCreateBusinessCardMutation
  } = useMutationCache({
    mutationKey: [BusinessCardsMutationKeys.CREATE_SHARED_ACCOUNT],
    mutationFn: async (payload: ICreateSharedAccountPayload) =>
      await BusinessCardsService.createSharedAccount(payload),
    onSuccess: (
      { id }: ICreateSharedAccount,
      payload: ICreateSharedAccountPayload
    ) => {
      setUuidSharedAccounts(id);
      onChangeAccountId({ id, backOption: true });
      createVirtualUser({
        ...payload,
        companyId: company?.uuid as string,
        name: `Cartão ${payload.name}`
      });
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function onSubmit(data: ICreateBusinessCardsFieldsForm): void {
    createBusinessCardMutation(data as ICreateSharedAccountPayload);
    formReset();
  }

  return {
    control,
    errors,
    isLoading:
      loadingCreateBusinessCardMutation ||
      loadingCreateVirtualUser ||
      loadingUserAttachingMutation,
    handleSubmit: handleSubmit(onSubmit),
    clearErrors,
    reset: formReset
  };
}
