import { type ChangeEventHandler } from 'react';

import { Controller, type FieldValues, type Path } from 'react-hook-form';

import { type IDsFileInputProps } from './DsFileInput.types';

import {
  Container,
  CustomFileInput,
  IconFileInput,
  Message
} from './DsFileInput.styles';

export function DsFileInput<T extends FieldValues>({
  label,
  name,
  accept,
  disabled = false,
  error,
  hint,
  inputClass = '',
  underlined,
  value,
  control,
  ...rest
}: IDsFileInputProps<T>): JSX.Element {
  const inputToRender = (
    value?: FileList | string,
    onChange?: ChangeEventHandler<HTMLInputElement>
  ): JSX.Element => {
    return (
      <div>
        <Container
          className={inputClass}
          $disabled={disabled}
          $error={error}
          $underlined={underlined}
        >
          <CustomFileInput
            placeholder={label}
            type='file'
            name={name}
            onChange={onChange}
            accept={accept}
            {...rest}
          />
          <span>
            {typeof value === 'string' ? value : value?.[0]?.name ?? label}
          </span>

          <IconFileInput
            name='attachment'
            type='line'
            size={2}
            $attached={!!value}
          />

          {hint && <Message $hint={hint}>{hint}</Message>}
        </Container>

        {error && <Message $error={error}>{error}</Message>}
      </div>
    );
  };

  if (!control) {
    return inputToRender(value);
  }

  return (
    <Controller
      name={name as Path<T>}
      control={control}
      render={({ field }) => {
        return inputToRender(
          field.value && field.value[0] instanceof File
            ? field.value[0].name
            : field.value,
          e => {
            field.onChange(e.target.files);
          }
        );
      }}
    />
  );
}
