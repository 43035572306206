import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useShallow } from 'zustand/react/shallow';

import {
  useCardDetailsContext,
  useLangContext,
  useModalContext
} from 'data/contexts';
import {
  AccountQueryKeys,
  type ICardData,
  useBlockCard,
  useUnblockCard
} from 'data/modules/cards/account';
import { CardGroupsQueryKeys } from 'data/modules/cards/cardGroups';

import { type IUseChangeCardStatusModal } from './ChangeCardStatusModal.types';

export function useChangeCardStatusModal(): IUseChangeCardStatusModal {
  const queryClient = useQueryClient();

  const { lang, currentLangKey } = useLangContext();

  const { handleOpenModal } = useModalContext();

  const [selectedAccount] = useCardDetailsContext(
    useShallow(state => [state.selectedAccount])
  );

  const cachedCardData = queryClient.getQueryData<ICardData>([
    AccountQueryKeys.GET_CARD_DATA_BY_ACCOUNT_ID,
    {
      accountId: selectedAccount?.accountId || ''
    }
  ]);

  function updateCardDataInCache(
    action: 'BLOCK' | 'UNBLOCK',
    cardData: ICardData,
    accountId: string
  ): void {
    queryClient.setQueryData(
      [
        AccountQueryKeys.GET_CARD_DATA_BY_ACCOUNT_ID,
        {
          accountId
        }
      ],
      cardData
    );

    queryClient.resetQueries({
      queryKey: [CardGroupsQueryKeys.GET_GROUP_USERS]
    });

    toast.success(
      action === 'BLOCK'
        ? lang.cards.global.card_block_success[currentLangKey]
        : lang.cards.global.card_unblock_success[currentLangKey]
    );

    handleOpenModal('cardDetails');
  }

  const { blockCard, isBlockingCard } = useBlockCard({
    onAfterSuccess: (cardData, payload) => {
      updateCardDataInCache('BLOCK', cardData, payload.accountId);
    }
  });

  const { unblockCard, isUnblockingCard } = useUnblockCard({
    onAfterSuccess: (cardData, payload) => {
      updateCardDataInCache('UNBLOCK', cardData, payload.accountId);
    }
  });

  function handleChangeCardStatus(): void {
    const accountId = selectedAccount?.accountId || '';
    const isActive = cachedCardData?.status === 'ACTIVE';

    isActive
      ? blockCard({
          accountId,
          blockLevel: 'MANAGER'
        })
      : unblockCard({ accountId });
  }

  return {
    handleChangeCardStatus,
    isLoading: isBlockingCard || isUnblockingCard,
    cardData: cachedCardData
  };
}
