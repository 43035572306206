import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import {
  type ICreateTeamMemberFieldsForm,
  type IUseCreateTeamMemberSchemaParams,
  TeamMemberType
} from 'data/modules/teamMembers';

import { Mask } from 'shared/utils/format';
import { Validate } from 'shared/utils/validation';

export function useCreateTeamMemberSchema(
  params?: IUseCreateTeamMemberSchemaParams
): ObjectSchema<ICreateTeamMemberFieldsForm> {
  const { t } = useTranslation('schemas');

  const createTeamMemberSchema: ObjectSchema<ICreateTeamMemberFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = t('isRequired');

      return yup.object({
        type: yup
          .string()
          .oneOf(Object.values(TeamMemberType))
          .required(fieldRequiredMessage),
        name: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'name',
            message: t('writeTwoName'),
            test: Validate.fullName
          }),
        email: yup
          .string()
          .email(t('invalidEmail'))
          .required(fieldRequiredMessage),
        document: yup
          .string()
          .defined()
          .when('type', {
            is: TeamMemberType.ADMINISTRADOR,
            then: schema => schema.required(fieldRequiredMessage)
          })
          .test('document', (value, { createError }) => {
            if (!value?.length) return true;

            const unmaskedValue = Mask.remove(value ?? '');

            if (unmaskedValue.length <= 11) {
              return Validate.cpf(unmaskedValue)
                ? true
                : createError({
                    message: t('invalidCpf'),
                    path: 'document'
                  });
            }

            if (unmaskedValue.length < 14) {
              return createError({
                message: t('invalidValue'),
                path: 'document'
              });
            }

            if (!Validate.cnpj(unmaskedValue)) {
              return createError({
                message: t('invalidCnpj'),
                path: 'document'
              });
            }

            return true;
          }),
        bank: yup.string(),
        agency: yup.string(),
        pixKey: yup.string(),
        account: yup.string(),
        position: yup.string(),
        phone: yup
          .string()
          .defined()
          .when('type', {
            is: TeamMemberType.ADMINISTRADOR,
            then: schema =>
              schema.required(fieldRequiredMessage).test({
                name: 'phoneNumber',
                message: t('phoneNumberMustHave8And9Digits'),
                test: value => {
                  const unmaskedValue = Mask.remove(value ?? '');
                  return (
                    unmaskedValue.length === 10 || unmaskedValue.length === 11
                  );
                }
              })
          }),

        altPhone: yup
          .string()
          .optional()
          .test({
            name: 'altPhone',
            message: t('phoneNumberMustHave8And9Digits'),
            test: value => {
              const unmaskedValue = Mask.remove(value ?? '');
              return (
                !value ||
                unmaskedValue.length === 10 ||
                unmaskedValue.length === 11
              );
            }
          }),
        birthDate: yup
          .string()
          .test({
            name: 'birthDate',
            message: t('dateMustNotBeFuture'),
            test: value => !Validate.isFutureDate(value ?? '', 'yyyy-MM-dd')
          })
          .transform(value => {
            return value === null ? undefined : value;
          }),
        password: yup
          .string()
          .required(fieldRequiredMessage)
          .min(
            params?.passwordLength ?? 7,
            `${t('min')} ${params?.passwordLength ?? 7}`
          )
          .test('password', (value, { createError }) => {
            const hasUpperCase = /[A-Z]/.test(value);
            const hasLowerCase = /[a-z]/.test(value);
            const hasNumber = /[0-9]/.test(value);
            const hasSpecialCharacter = /[,.:;?~!@#$%^&*()]/.test(value);

            if (params?.uppercase && !hasUpperCase) {
              return createError({
                message: t('passwordUppercaseRequirement'),
                path: 'password'
              });
            }

            if (params?.lowercase && !hasLowerCase) {
              return createError({
                message: t('passwordLowercaseRequirement'),
                path: 'password'
              });
            }

            if (params?.number && !hasNumber) {
              return createError({
                message: t('passwordNumberRequirement'),
                path: 'password'
              });
            }

            if (params?.specialCharacter && !hasSpecialCharacter) {
              return createError({
                message: t('passwordSymbolRequirement'),
                path: 'password'
              });
            }

            return true;
          }),
        passwordConfirm: yup
          .string()
          .required(fieldRequiredMessage)
          .oneOf([yup.ref('password'), ''], t('passwordDoNotMatch')),
        projects: yup
          .array()
          .of(yup.string().required())
          .transform(value => {
            return value === null ? undefined : value;
          }),
        costCenters: yup
          .array()
          .of(yup.string().required())
          .transform(value => {
            return value === null ? undefined : value;
          }),
        approvalFlows: yup.array().of(yup.string().required()),
        expensePolicy: yup.string()
      });
    }, [
      params?.lowercase,
      params?.number,
      params?.passwordLength,
      params?.specialCharacter,
      params?.uppercase,
      t
    ]);

  return createTeamMemberSchema;
}
