import {
  type ICardsExpense,
  type IPersistenceExpense
} from 'data/modules/expenses';

import { CustomObject } from 'shared/utils/custom';

class GetExpensesListByTransactionsIdsMapper {
  toDomain(persistenceExpense: IPersistenceExpense): ICardsExpense {
    return {
      expenseType: persistenceExpense.expense_type,
      transactionId: persistenceExpense.transaction_id,
      costsCenter: persistenceExpense.costs_center,
      currencyIso: persistenceExpense.currency_iso,
      receiptType: persistenceExpense.receipt_type,
      receiptUrl: persistenceExpense.receipt_url,
      userName: persistenceExpense.user_name,
      apportionments:
        persistenceExpense.apportionments?.map(item => ({
          id: item.id,
          description: item.description
        })) ?? null,
      ...CustomObject.pick(persistenceExpense, ['report', 'id', 'url', 'value'])
    };
  }
}

export default new GetExpensesListByTransactionsIdsMapper();
