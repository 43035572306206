import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

import { useAuthContext } from 'data/contexts';
import {
  AuthQueryKeys,
  type IAuthUser,
  type IUseLoadInitialData,
  type IUser,
  useGetOutsourcedUsersAndPermissions
} from 'data/modules/auth';

import { useGa4 } from 'shared/hooks/global';

export function useLoadInitialData(): IUseLoadInitialData {
  const [user, company, outsourcedUser, isAuthenticating] = useAuthContext(
    useShallow(state => [
      state.user,
      state.company,
      state.outsourcedUser,
      state.isAuthenticating
    ])
  );

  const { isFetchingOutsourcedUsersAndPermissions } =
    useGetOutsourcedUsersAndPermissions({
      enabled:
        (!isAuthenticating && user?.parameters.hasOutsourcingAccess) ?? false
    });

  const queryClient = useQueryClient();

  const { sendUser } = useGa4();

  /* Engajamento do usuario set datalayer */
  if (user && company) {
    sendUser({
      userId: user.id,
      companyId: company.id,
      companyUuId: company.uuid
    });
  }

  useEffect(() => {
    if (!isAuthenticating && outsourcedUser) {
      const newOutsourcingParameters: Partial<IUser['parameters']> = {
        usesCards:
          outsourcedUser.user.parameters.usesCards ??
          outsourcedUser.user.parameters.sharedAccountsCards ??
          false
      };

      queryClient.setQueryData<IAuthUser>(
        [AuthQueryKeys.AUTH_USER],
        oldData => {
          if (!oldData) return;

          return {
            ...oldData,
            user: {
              ...oldData.user,
              parameters: {
                ...oldData.user.parameters,
                ...newOutsourcingParameters
              }
            }
          };
        }
      );
    }
  }, [outsourcedUser, queryClient, isAuthenticating]);

  return {
    isLoading: isAuthenticating || isFetchingOutsourcedUsersAndPermissions,
    hasError: false
  };
}
