import { api, apiWeb } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import { type IDefaultResponse } from 'data/modules/global';
import {
  type IGetRelatedTeamMembersPayload,
  type IGetTeamMembersItemsPayload,
  type IGetTeamMembersPayload,
  type IPersistenceTeamMember,
  type IPersistenceTeamMemberApprovalFlowOption,
  type IPersistenceTeamMemberCostCenterOption,
  type IPersistenceTeamMemberLimitPolicyOption,
  type IPersistenceTeamMemberPositionOption,
  type IPersistenceTeamMemberProjectOption,
  type IPersistenceTeamMembersResume,
  type IRelatedTeamMember,
  type ITeamMemberApprovalFlowOption,
  type ITeamMemberCostCenterOption,
  type ITeamMemberItem,
  type ITeamMemberLimitPolicyOption,
  type ITeamMemberPositionOption,
  type ITeamMemberProjectOption,
  type ITeamMembersResume,
  type IUseGetTeamMembersResponse
} from 'data/modules/teamMembers';
import {
  GetTeamMembersMapper,
  GetTeamMembersPositionOptionsMapper,
  GetTeamMembersResumeMapper
} from 'data/modules/teamMembers/services/mappers';

import { PaginationMapper } from 'shared/mappers';

class TeamMembersService {
  async getTeamMembersItems(
    payload: IGetTeamMembersItemsPayload
  ): Promise<ITeamMemberItem[]> {
    const response = await api.get<IDefaultResponse<ITeamMemberItem[]>>(
      '/team-members',
      {
        params: PaginationMapper.toPersistence(payload)
      }
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getRelatedTeamMembers(
    payload: IGetRelatedTeamMembersPayload
  ): Promise<IRelatedTeamMember[]> {
    const response = await api.get<IDefaultResponse<IRelatedTeamMember[]>>(
      `/team-members/${payload.uuid}/outsourcers`
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getTeamMembers(
    payload: IGetTeamMembersPayload
  ): Promise<IUseGetTeamMembersResponse> {
    const response = await apiWeb.get<
      IDefaultResponse<IPersistenceTeamMember[]>
    >('/team-members', {
      params: GetTeamMembersMapper.toPersistence(payload)
    });

    const { data, currentPage, lastPage } =
      ResponseAdapter.formatRegularResponse(response.data);

    return {
      teamMembers: data.map(GetTeamMembersMapper.toDomain),
      pagination: {
        page: currentPage,
        totalPage: lastPage
      }
    };
  }

  async getTeamMembersResume(): Promise<ITeamMembersResume> {
    const response = await apiWeb.get<
      IDefaultResponse<IPersistenceTeamMembersResume>
    >('/team-members/resume');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetTeamMembersResumeMapper.toDomain(data);
  }

  async getTeamMembersCostCenterOptions(): Promise<
    ITeamMemberCostCenterOption[]
  > {
    const response =
      await apiWeb.get<
        IDefaultResponse<IPersistenceTeamMemberCostCenterOption[]>
      >('/costs-centers');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getTeamMembersProjectOptions(): Promise<ITeamMemberProjectOption[]> {
    const response =
      await apiWeb.get<IDefaultResponse<IPersistenceTeamMemberProjectOption[]>>(
        '/projects'
      );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getTeamMembersLimitPolicyOptions(): Promise<
    ITeamMemberLimitPolicyOption[]
  > {
    const response =
      await apiWeb.get<
        IDefaultResponse<IPersistenceTeamMemberLimitPolicyOption[]>
      >('/limit-policies');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getTeamMembersApprovalFlowOptions(): Promise<
    ITeamMemberApprovalFlowOption[]
  > {
    const response =
      await apiWeb.get<
        IDefaultResponse<IPersistenceTeamMemberApprovalFlowOption[]>
      >('/approval-flow');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getTeamMembersPositionOptions(): Promise<ITeamMemberPositionOption[]> {
    const response =
      await apiWeb.get<
        IDefaultResponse<IPersistenceTeamMemberPositionOption[]>
      >('/positions');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data.map(GetTeamMembersPositionOptionsMapper.toDomain);
  }
}

export default new TeamMembersService();
