import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type IContainerStyles } from './Onboarding.types';

export const Container = styled.div<AddPrefixToType<IContainerStyles>>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighDark};
  min-height: ${({ $isLoggedIn }) =>
    $isLoggedIn ? 'calc(100vh - 5.6rem)' : '100vh'};
  overflow-y: hidden;

  img.logo {
    width: 30rem;
    height: 15rem;
    z-index: 2;
  }

  .circle {
    position: absolute;
    height: 250rem;
    width: 100%;
    top: -50rem;
    right: 0;
    background: linear-gradient(
      70deg,
      ${({ theme }) => theme.tokens.brandColorPrimaryPure},
      ${({ theme }) => theme.tokens.brandColorSecondaryPure}
    );
    clip-path: circle(50% at top);
    z-index: 1;
  }

  .back-btn {
    margin-bottom: 4rem;
  }

  @media screen and (min-width: 1860px) {
    .circle {
      top: -50rem;
      height: 260rem;
      clip-path: circle(60% at 50% -390px);
    }
  }

  @media screen and (min-width: 2260px) {
    .circle {
      top: -50rem;
      height: 260rem;
      clip-path: circle(70% at 50% -700px);
    }
  }

  @media screen and (max-width: 800px) {
    .circle {
      top: -50rem;
      height: 300rem;
      clip-path: circle(50% at 50% -100px);
    }
  }

  @media screen and (max-width: 800px) {
    gap: 4rem;

    img.logo {
      margin-top: 1.6rem;
      width: 20rem;
      height: 5rem;
    }
  }
`;

export const StepIndicator = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1.6rem;
  left: 1.6rem;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighDark};
  color: ${({ theme }) => theme.tokens.brandColorSecondaryPure};
  font-size: 1.6rem;
  font-weight: 500;
  z-index: 2;
`;

export const CircularDetail = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const RetangularDetail = styled.img`
  position: absolute;
  bottom: 10rem;
  left: 1.6rem;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;
