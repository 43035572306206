import { apiWeb } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import {
  type IAdvancePayment,
  type IPersistenceAdvancePayment
} from 'data/modules/advancePayments';
import { type IDefaultResponse } from 'data/modules/global';

import { GetAdvancePaymentsMapper } from './mappers';

class AdvancePaymentsService {
  async getAdvancePayments(): Promise<IAdvancePayment[]> {
    const response = await apiWeb.get<
      IDefaultResponse<IPersistenceAdvancePayment[]>
    >(`/advances/list-by-user`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data.map(item => GetAdvancePaymentsMapper.toDomain(item));
  }
}

export default new AdvancePaymentsService();
