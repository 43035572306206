import { useMutationCache } from 'data/cache';
import {
  CardGroupsMutationKeys,
  CardGroupsService,
  type IRemoveCardFromGroupPayload,
  type IUseRemoveCardFromGroup
} from 'data/modules/cards/cardGroups';

export function useRemoveCardFromGroup(): IUseRemoveCardFromGroup {
  const { mutateAsync, isPending } = useMutationCache({
    mutationKey: [CardGroupsMutationKeys.REMOVE_CARD_FROM_GROUP],
    mutationFn: async (payload: IRemoveCardFromGroupPayload) => {
      await CardGroupsService.removeCardFromGroup(payload);
    }
  });

  return {
    removeCardFromGroup: mutateAsync,
    isRemovingCardFromGroup: isPending
  };
}
