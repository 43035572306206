import {
  type ICompanyOnboardingConfigurationUsersCache,
  type IPersistenceCompanyOnboardingConfigurationUsersCache
} from 'data/modules/cards/companyOnboarding';

import { CustomObject } from 'shared/utils/custom';

class GetCompanyOnboardingConfigurationUsersCacheMapper {
  toDomain(
    persistenceData: IPersistenceCompanyOnboardingConfigurationUsersCache
  ): ICompanyOnboardingConfigurationUsersCache {
    return {
      companyUuid: persistenceData.company_uuid,
      mustHaveAccount: persistenceData.must_have_account,
      ...CustomObject.omit(persistenceData, [
        'company_uuid',
        'must_have_account'
      ])
    };
  }
}

export default new GetCompanyOnboardingConfigurationUsersCacheMapper();
