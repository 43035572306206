import { useQueryCache } from 'data/cache';
import {
  FinancialService,
  type IUseGetChargedUsers
} from 'data/modules/financial';
import { FinancialQueryKeys } from 'data/modules/financial/keys/financial.keys';

export function useGetChargedUsers(): IUseGetChargedUsers {
  const { data, isLoading } = useQueryCache({
    queryKey: [FinancialQueryKeys.GET_CHARGED_USERS],
    queryFn: async () => await FinancialService.getChargedUsers()
  });

  return {
    chargedUsers: data ?? [],
    isLoadingChargedUsers: isLoading
  };
}
