import { DSIcons } from 'ds/icons';

import { type IStepperItemProps } from './StepperItem.types';

import {
  ClickArea,
  Container,
  NumberContainer,
  TextContainer
} from './StepperItem.styles';

export function StepperItem({
  active,
  allowClick,
  label,
  number,
  onClick,
  checked = false,
  alignText = 'center'
}: IStepperItemProps): JSX.Element {
  if (active && checked) {
    throw new Error(
      'StepperItem cannot be active and checked at the same time'
    );
  }

  return (
    <Container
      $alignText={alignText}
      $active={active}
      $checked={checked}
      className={active ? 'active' : ''}
      data-testid='stepper-item-container'
    >
      {allowClick && onClick && (
        <ClickArea
          data-testid='stepper-item-clickarea'
          onClick={onClick}
        />
      )}
      <NumberContainer>
        <span>{checked ? <DSIcons.CheckIcon /> : number}</span>
      </NumberContainer>

      <TextContainer>
        <span>{label}</span>
      </TextContainer>
    </Container>
  );
}
