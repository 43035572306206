import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import {
  type ContainerStylesType,
  type TooltipContainerStylesType
} from './InternationalFinalValue.types';

export const Container = styled.div<AddPrefixToType<ContainerStylesType>>`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.emperor};
  line-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    font-size: 1.6rem;
    font-weight: 700;
    margin-left: 1rem;

    ${({ $isTransactionReversed }) =>
      $isTransactionReversed &&
      css`
        text-decoration: line-through;
      `}
  }
`;

export const TooltipContainer = styled.div<
  AddPrefixToType<TooltipContainerStylesType>
>`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.emperor};
  line-height: 3rem;
  display: flex;
  align-items: center;

  > div {
    margin-left: 0.5rem;
  }

  ${({ $isTransactionReversed }) =>
    $isTransactionReversed &&
    css`
      text-decoration: line-through;
    `}
`;

export const PendingSpread = styled.p`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    margin-right: 0.8rem;
  }
`;
