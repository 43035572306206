import { apiWeb } from 'data/config';
import {
  useGetAuthUser,
  useGetOutsourcedUser,
  useGetOutsourcedUsersAndPermissions
} from 'data/modules/auth';

import { CustomCookies } from 'shared/utils/global';

import { type IUseLoadCollaboratorInitialData } from './useLoadCollaboratorInitialData.types';

export function useLoadCollaboratorInitialData(): IUseLoadCollaboratorInitialData {
  const outsourcingUserUuid = CustomCookies.get('outsourcing') ?? '';

  const { user, company, isFetchingAuthUser } = useGetAuthUser({
    enabled: true
  });

  const isGetOutsourcedUserRequestEnabled =
    !!outsourcingUserUuid && !!user?.parameters.hasOutsourcingAccess;

  const { outsourcedUser, isFetchingOutsourcedUser } = useGetOutsourcedUser({
    userId: outsourcingUserUuid,
    enabled: isGetOutsourcedUserRequestEnabled
  });

  const {
    isFetchingOutsourcedUsersAndPermissions,
    outsourcedUsersAndPermissions
  } = useGetOutsourcedUsersAndPermissions({
    enabled:
      (!isFetchingAuthUser && user?.parameters.hasOutsourcingAccess) ?? false
  });

  if (outsourcingUserUuid !== '') {
    apiWeb.defaults.headers.common['X-Header-Outsourcing'] =
      outsourcingUserUuid;
  }

  return {
    isLoading:
      isFetchingAuthUser ||
      isFetchingOutsourcedUser ||
      isFetchingOutsourcedUsersAndPermissions,
    company,
    user,
    outsourcedUsersAndPermissions,
    outsourcedUser
  };
}
