import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type InputContainerStylesType } from './InputContainer.types';

export const Container = styled.div`
  width: 100%;

  p {
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.colors.danger};
    font-size: 1.2rem;
  }
`;

export const LabelContainer = styled.div<
  AddPrefixToType<InputContainerStylesType>
>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-height: 2rem;

  > label {
    display: block;
    color: ${({ theme, $error }) =>
      $error ? theme.colors.danger : theme.colors.mineShaft};
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
`;
