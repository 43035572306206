import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { vexDomain } from 'data/config';
import { useAuthContext } from 'data/contexts';
import {
  useGetOutsourcedUser,
  useGetOutsourcedUsersAndPermissions
} from 'data/modules/auth';

import { cookiesKeys } from 'shared/constants/global';
import { CustomCookies } from 'shared/utils/global';

import {
  type IUseOutsourced,
  type OutsourcingOptionType
} from './Outsourced.types';

export function useOutsourced(): IUseOutsourced {
  const [user, outsourcedUser, company] = useAuthContext(
    useShallow(state => [state.user, state.outsourcedUser, state.company])
  );
  const location = useLocation();

  const { t } = useTranslation('global');

  const { isFetchingOutsourcedUser } = useGetOutsourcedUser({
    userId: CustomCookies.get('outsourcing') ?? '',
    enabled:
      !!CustomCookies.get('outsourcing') &&
      !!user?.parameters.hasOutsourcingAccess
  });

  const {
    isFetchingOutsourcedUsersAndPermissions,
    outsourcedUsersAndPermissions
  } = useGetOutsourcedUsersAndPermissions({
    enabled: user?.parameters.hasOutsourcingAccess ?? false
  });

  let outsourcingOptions: OutsourcingOptionType[] =
    outsourcedUsersAndPermissions?.map(({ directorUuid, directorName }) => ({
      name: directorName,
      uuid: directorUuid
    })) ?? [];

  // Caso o usuário logado esteja em terceirização, ele deve aparecer como primeira opção da lista
  // e o usuário sendo terceirizado deve ser removido das opções
  outsourcingOptions =
    outsourcedUser !== undefined
      ? [
          {
            name: `${user?.name} (${t('you')})`,
            uuid: user?.uuid as string
          },
          ...outsourcingOptions.filter(
            ({ uuid }) => uuid !== outsourcedUser?.user.uuid
          )
        ]
      : outsourcingOptions;

  function handleChangeOutsourcingOption(userUuid: string): void {
    if (userUuid === user?.uuid) {
      CustomCookies.remove('outsourcing');
    } else {
      CustomCookies.set('outsourcing', userUuid, {
        daysToExpire: 1
      });
    }

    if (
      outsourcedUser?.user.parameters.usesCards &&
      window.location.pathname.includes('cartoes')
    ) {
      window.location.href = `${vexDomain}/cartoes/inicio`;
      return;
    }

    const showOldExpensesPage = CustomCookies.get(
      cookiesKeys.redesignExpensesOldVersion
    );

    const usesRedesignExpenses = company?.parameters.usesRedesignExpenses;

    const isExpensesOldVersionAllowed =
      company?.parameters.isExpensesOldVersionAllowed;

    // se não tiver o parâmetro de redesign
    // ou tiver o parâmetro, for menor que 30 dias e a página antiga estiver habilitada
    // redireciona para a página antiga
    if (
      usesRedesignExpenses === null ||
      (isExpensesOldVersionAllowed && showOldExpensesPage === 'true')
    ) {
      if (location.pathname.includes('/cartoes')) {
        window.location.href = `${vexDomain}/cartoes/inicio`;
        return;
      }

      window.location.href = `${vexDomain}/despesas`;
      return;
    }

    location.pathname.includes('/cartoes')
      ? (window.location.href = `${vexDomain}/cartoes/inicio`)
      : (window.location.href = `${vexDomain}/despesas`);
  }

  const nameOfTheUserWhoIsPerformingActions = (
    outsourcedUser ? outsourcedUser?.user.name : user?.name
  ) as string;

  return {
    outsourcingOptions,
    isFetchingOutsourcingOptions: isFetchingOutsourcedUsersAndPermissions,
    outsourcedUser: outsourcedUser!,
    isFetchingOutsourcedUser,
    handleChangeOutsourcingOption,
    nameOfTheUserWhoIsPerformingActions
  };
}
