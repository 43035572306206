import { useMemo } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { useAuthContext, useLangContext } from 'data/contexts';

import { CustomDate } from 'shared/utils/custom';

import {
  type IUseInconsistentKyc,
  type IUseInconsistentKycProps
} from './InconsistentKyc.types';

export function useInconsistentKyc({
  isCompany
}: IUseInconsistentKycProps): IUseInconsistentKyc {
  const [company, user] = useAuthContext(
    useShallow(state => [state.company, state.user])
  );
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  /* Limpeza de historico rota de navegação */
  window.history.replaceState({}, document.title, window.location.pathname);
  window.history.replaceState(null, '', 'kyc');
  window.history.pushState(null, '', 'kyc');

  const dateDifference = useMemo(() => {
    if (isCompany && company?.cards?.accountHolder?.kycUpdatedAt) {
      return CustomDate.differenceBetweenDates(
        new Date(),
        new Date(company?.cards.accountHolder?.kycUpdatedAt)
      );
    }

    if (!isCompany && user?.cards?.accountHolder?.kycUpdatedAt) {
      return CustomDate.differenceBetweenDates(
        new Date(),
        new Date(user?.cards.accountHolder?.kycUpdatedAt)
      );
    }

    return 0;
  }, [company, isCompany, user]);

  const title = useMemo(() => {
    if (!isCompany && user?.cards?.accountHolder?.kycStatus === 'REPROVED') {
      return lang.cards.kycStatus.your_account_has_been_disapproved[
        currentLangKey
      ];
    }

    if (!isCompany && user?.cards?.accountHolder?.kycStatus === 'IN_ANALYSIS') {
      return lang.cards.kycStatus.your_account_is_under_review[currentLangKey];
    }

    if (isCompany && company?.cards?.accountHolder?.kycStatus === 'REPROVED') {
      return lang.cards.kycStatus.your_business_account_has_been_disapproved[
        currentLangKey
      ];
    }

    return lang.cards.kycStatus.your_company_account_is_under_review[
      currentLangKey
    ];
  }, [
    isCompany,
    user?.cards?.accountHolder?.kycStatus,
    company?.cards?.accountHolder?.kycStatus,
    lang.cards.kycStatus.your_company_account_is_under_review,
    lang.cards.kycStatus.your_account_has_been_disapproved,
    lang.cards.kycStatus.your_account_is_under_review,
    lang.cards.kycStatus.your_business_account_has_been_disapproved,
    currentLangKey
  ]);

  const status = useMemo(() => {
    if (isCompany) {
      return company?.cards?.accountHolder?.kycStatus;
    }

    return user?.cards?.accountHolder?.kycStatus;
  }, [
    isCompany,
    user?.cards?.accountHolder?.kycStatus,
    company?.cards?.accountHolder?.kycStatus
  ]);

  return {
    dateDifference,
    kycStatus: status ?? 'IN_ANALYSIS',
    titleKyc: title
  };
}
