import { faker } from '@faker-js/faker';

import {
  type ICompanyStatement,
  type IsReversalType
} from 'data/modules/cards/statement';
import { type CreditDebitType } from 'data/modules/global';

import { MockApi } from 'shared/utils/global';

export async function getCompanyStatementMock(): Promise<ICompanyStatement[]> {
  const data = [
    {
      date: '2024-05-21',
      balance: 37270.4,
      items: [
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'TRANSFER_DEBIT',
          paymentDescription:
            'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #9DA512BC',
          datetime: '2024-05-21T20:41:49.389000Z',
          transactionId: '7F23A06FFEAE89E1E59130C20CE1D542',
          finalBillingAmount: -302.79,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -302.79,
          amount: -302.79,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'GOODS_PURCHASE',
          paymentDescription: '',
          datetime: '2024-05-21T20:41:34.221000Z',
          transactionId: '9DA512BC0C427D203E73F64A716951E6',
          finalBillingAmount: -7189.43,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -6912.91,
          amount: -1351.55,
          currency: '840',
          currencyCode: 'USD',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'ATLASSIAN',
          merchantCity: '+14157011110',
          mcc: 5734,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: true,
          relateds: [
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #9DA512BC',
              datetime: '2024-05-21T20:41:49.389000Z',
              transactionId: '7F23A06FFEAE89E1E59130C20CE1D542',
              finalBillingAmount: -302.79,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -302.79,
              amount: -302.79,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: true,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            },
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'TX INT - VEXPENSES S.A. - VEXPENSES S.A. - #9DA512BC',
              datetime: '2024-05-21T20:41:49.187000Z',
              transactionId: '6394DFFAB1AA86C8A793E141A0F0F704',
              finalBillingAmount: 0,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -276.52,
              amount: -276.52,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: false,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            }
          ],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão SCIALPHA 02AL',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'TRANSFER_DEBIT',
          paymentDescription:
            'TAXA SAQUE - CONDOMINIO SHOPPING CENTER IGUATEMI ALPHAVILLE - CONDOMINIO S. C. I. ALPHAVILLE - #7D7390B7',
          datetime: '2024-01-30T10:28:27.235000Z',
          transactionId: 'A97116E279B5935E758D393EC0E6657A',
          finalBillingAmount: -7,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -7,
          amount: -7,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão SCIALPHA 02AL',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'CASH_WITHDRAWAL',
          paymentDescription: '',
          datetime: '2024-01-30T10:28:11.028000Z',
          transactionId: '7D7390B74BA97F4387395E80351D39CB',
          finalBillingAmount: -1000,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -1000,
          amount: -1000,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: '4471',
          merchantName: 'SAQUE - 2019203',
          merchantCity: 'BARUERI',
          mcc: 6011,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'TAXA SAQUE - CONDOMINIO SHOPPING CENTER IGUATEMI ALPHAVILLE - CONDOMINIO S. C. I. ALPHAVILLE - #7D7390B7',
              datetime: '2024-01-30T10:28:27.235000Z',
              transactionId: 'A97116E279B5935E758D393EC0E6657A',
              finalBillingAmount: -7,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -7,
              amount: -7,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: true,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            }
          ],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'CREDIT' as CreditDebitType,
          type: 'TRANSFER_CREDIT',
          paymentDescription:
            'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #A63E8C05',
          datetime: '2024-05-21T09:40:49.312000Z',
          transactionId: '282F6A9624DFA645AEEF15C9FB37C630',
          finalBillingAmount: 0.22,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: 0.22,
          amount: 0.22,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'FULLY' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'CREDIT' as CreditDebitType,
          type: 'GOODS_PURCHASE',
          paymentDescription: '',
          datetime: '2024-05-21T09:40:26.547000Z',
          transactionId: '7FE7AB573931F13EBF0D479812CB970B',
          finalBillingAmount: 5.34,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: 5.13,
          amount: 1,
          currency: '840',
          currencyCode: 'USD',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'PRITUNL.COM',
          merchantCity: '+12062022276',
          mcc: 7372,
          isReversal: 'FULLY' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: true,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'TRANSFER_DEBIT',
          paymentDescription:
            'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #B9D99B3D',
          datetime: '2024-05-21T09:39:38.280000Z',
          transactionId: 'A63E8C0589B765F63B0285DEDC6B39CE',
          finalBillingAmount: -0.22,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -0.22,
          amount: -0.22,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0.22,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: true,
          visible: true,
          isInternational: false,
          relateds: [
            {
              debitCredit: 'CREDIT' as CreditDebitType,
              type: 'TRANSFER_CREDIT',
              paymentDescription:
                'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #A63E8C05',
              datetime: '2024-05-21T09:40:49.312000Z',
              transactionId: '282F6A9624DFA645AEEF15C9FB37C630',
              finalBillingAmount: 0.22,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: 0.22,
              amount: 0.22,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'FULLY' as IsReversalType,
              reversed: false,
              visible: true,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            }
          ],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'E_COMMERCE',
          paymentDescription: '',
          datetime: '2024-05-21T09:39:15.017000Z',
          transactionId: 'B9D99B3D8D80E7C0C045F752A052BA6E',
          finalBillingAmount: -5.34,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -5.13,
          amount: -1,
          currency: '840',
          currencyCode: 'USD',
          reversalAmount: 5.34,
          cardNumber: '7154',
          merchantName: 'PRITUNL.COM',
          merchantCity: '+12062022276',
          mcc: 7372,
          isReversal: 'NOT' as IsReversalType,
          reversed: true,
          visible: true,
          isInternational: true,
          relateds: [
            {
              debitCredit: 'CREDIT' as CreditDebitType,
              type: 'TRANSFER_CREDIT',
              paymentDescription:
                'TX INT - VEXPENSES S.A. - VEXPENSES S.A. - #B9D99B3D',
              datetime: '2024-05-21T09:40:49.580000Z',
              transactionId: '13C58BDDD04E8961236485CC00B56FAB',
              finalBillingAmount: 0,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: 0.21,
              amount: 0.21,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'FULLY' as IsReversalType,
              reversed: false,
              visible: false,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            },
            {
              debitCredit: 'CREDIT' as CreditDebitType,
              type: 'GOODS_PURCHASE',
              paymentDescription: '',
              datetime: '2024-05-21T09:40:26.547000Z',
              transactionId: '7FE7AB573931F13EBF0D479812CB970B',
              finalBillingAmount: 5.34,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: 5.13,
              amount: 1,
              currency: '840',
              currencyCode: 'USD',
              cardNumber: '7154',
              merchantName: 'PRITUNL.COM',
              merchantCity: '+12062022276',
              mcc: 7372,
              isReversal: 'FULLY' as IsReversalType,
              reversed: false,
              visible: true,
              isInternational: true,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            },
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #B9D99B3D',
              datetime: '2024-05-21T09:39:38.280000Z',
              transactionId: 'A63E8C0589B765F63B0285DEDC6B39CE',
              finalBillingAmount: -0.22,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -0.22,
              amount: -0.22,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: true,
              visible: true,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            },
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'TX INT - VEXPENSES S.A. - VEXPENSES S.A. - #B9D99B3D',
              datetime: '2024-05-21T09:39:38.068000Z',
              transactionId: 'DFE0183E1FAFE2431D6256D4E678804E',
              finalBillingAmount: 0,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -0.21,
              amount: -0.21,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: false,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            }
          ],
          transferorName: null,
          companyId: faker.string.uuid()
        }
      ]
    },
    {
      date: '2024-05-18',
      balance: 44762.62,
      items: [
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'TRANSFER_DEBIT',
          paymentDescription:
            'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #71522C6A',
          datetime: '2024-05-18T14:47:01.793000Z',
          transactionId: '312576FBC58482795C1A27715647E298',
          finalBillingAmount: -2.03,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -2.03,
          amount: -2.03,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'GOODS_PURCHASE',
          paymentDescription: '',
          datetime: '2024-05-18T14:46:43.484000Z',
          transactionId: '71522C6AC3DF4D43F697309F76CCB3FC',
          finalBillingAmount: -48.1,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -46.25,
          amount: -9,
          currency: '840',
          currencyCode: 'USD',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'WWW.MAKE.COM',
          merchantCity: '+12128470692',
          mcc: 5734,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: true,
          relateds: [
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'TX INT - VEXPENSES S.A. - VEXPENSES S.A. - #71522C6A',
              datetime: '2024-05-18T14:47:01.882000Z',
              transactionId: '5FCCD6A94DF10F0985CFC84D31E62F88',
              finalBillingAmount: 0,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -1.85,
              amount: -1.85,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: false,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            },
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #71522C6A',
              datetime: '2024-05-18T14:47:01.793000Z',
              transactionId: '312576FBC58482795C1A27715647E298',
              finalBillingAmount: -2.03,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -2.03,
              amount: -2.03,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: true,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            }
          ],
          transferorName: null,
          companyId: faker.string.uuid()
        }
      ]
    },
    {
      date: '2024-05-17',
      balance: 44812.75,
      items: [
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'TRANSFER_DEBIT',
          paymentDescription:
            'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #95008BDA',
          datetime: '2024-05-17T11:46:03.937000Z',
          transactionId: '391BEA5085BBF6EF7573745F7B592E8C',
          finalBillingAmount: -0.2,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -0.2,
          amount: -0.2,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'GOODS_PURCHASE',
          paymentDescription: '',
          datetime: '2024-05-17T11:45:42.505000Z',
          transactionId: '95008BDA3433E551B03D20E55F5B9035',
          finalBillingAmount: -4.82,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -4.63,
          amount: -0.9,
          currency: '840',
          currencyCode: 'USD',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'WWW.MAKE.COM',
          merchantCity: '+12128470692',
          mcc: 5734,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: true,
          relateds: [
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'TX INT - VEXPENSES S.A. - VEXPENSES S.A. - #95008BDA',
              datetime: '2024-05-17T11:46:04.077000Z',
              transactionId: 'EDE7CD4363EC6E4D08496A18E13D7BAA',
              finalBillingAmount: 0,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -0.19,
              amount: -0.19,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: false,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            },
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #95008BDA',
              datetime: '2024-05-17T11:46:03.937000Z',
              transactionId: '391BEA5085BBF6EF7573745F7B592E8C',
              finalBillingAmount: -0.2,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -0.2,
              amount: -0.2,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: true,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            }
          ],
          transferorName: null,
          companyId: faker.string.uuid()
        }
      ]
    },
    {
      date: '2024-05-15',
      balance: 44817.77,
      items: [
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'TRANSFER_DEBIT',
          paymentDescription:
            'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #E727BEB3',
          datetime: '2024-05-15T10:24:51.446000Z',
          transactionId: '93C107A5F751DE1A319524B4C49035C9',
          finalBillingAmount: -0.2,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -0.2,
          amount: -0.2,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'GOODS_PURCHASE',
          paymentDescription: '',
          datetime: '2024-05-15T10:24:37.650000Z',
          transactionId: 'E727BEB324926C12564D091901DA4907',
          finalBillingAmount: -4.84,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -4.65,
          amount: -0.9,
          currency: '840',
          currencyCode: 'USD',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'WWW.MAKE.COM',
          merchantCity: '+12128470692',
          mcc: 5734,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: true,
          relateds: [
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #E727BEB3',
              datetime: '2024-05-15T10:24:51.446000Z',
              transactionId: '93C107A5F751DE1A319524B4C49035C9',
              finalBillingAmount: -0.2,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -0.2,
              amount: -0.2,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: true,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            },
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'TX INT - VEXPENSES S.A. - VEXPENSES S.A. - #E727BEB3',
              datetime: '2024-05-15T10:24:51.431000Z',
              transactionId: '77C1E0861252948C8F4041505D535AA8',
              finalBillingAmount: 0,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -0.19,
              amount: -0.19,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: false,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            }
          ],
          transferorName: null,
          companyId: faker.string.uuid()
        }
      ]
    },
    {
      date: '2024-05-14',
      balance: 44822.81,
      items: [
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'GOODS_PURCHASE',
          paymentDescription: '',
          datetime: '2024-05-14T10:13:09.086000Z',
          transactionId: 'C57025995ABCA5143BB1F0829A6EB8EE',
          finalBillingAmount: -349,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -349,
          amount: -349,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'LANGOWSKI INTERNET LTD',
          merchantCity: 'CURITIBA',
          mcc: 4816,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        }
      ]
    },
    {
      date: '2024-05-10',
      balance: 45171.81,
      items: [
        {
          name: 'Cartão DEV',
          debitCredit: 'CREDIT' as CreditDebitType,
          type: 'TRANSFER_CREDIT',
          paymentDescription:
            'Transf. VEXPENSES > Cartao DEV: Transferencia de saldo em conta',
          datetime: '2024-05-10T17:31:12.550000Z',
          transactionId: 'F5884A7DEA2BCD8A70D36C857DAD0E92',
          finalBillingAmount: 45000,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: 45000,
          amount: 45000,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: 'Afonso Henriq,ue Marques Hernandes',
          companyId: faker.string.uuid()
        }
      ]
    },
    {
      date: '2024-05-06',
      balance: 171.81,
      items: [
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'TRANSFER_DEBIT',
          paymentDescription:
            'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #4AABC774',
          datetime: '2024-05-06T15:02:02.606000Z',
          transactionId: '81EFC7854FEB39EFB1A7197FD1223C88',
          finalBillingAmount: -22.42,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -22.42,
          amount: -22.42,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'E_COMMERCE',
          paymentDescription: '',
          datetime: '2024-05-06T15:01:39.875000Z',
          transactionId: '4AABC7742B8F1677F3E0EE15F31ADF7E',
          finalBillingAmount: -532.26,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -511.79,
          amount: -100,
          currency: '840',
          currencyCode: 'USD',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'OPENAI',
          merchantCity: '+14158799686',
          mcc: 5734,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: true,
          relateds: [
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #4AABC774',
              datetime: '2024-05-06T15:02:02.606000Z',
              transactionId: '81EFC7854FEB39EFB1A7197FD1223C88',
              finalBillingAmount: -22.42,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -22.42,
              amount: -22.42,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: true,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            },
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'TX INT - VEXPENSES S.A. - VEXPENSES S.A. - #4AABC774',
              datetime: '2024-05-06T15:02:02.250000Z',
              transactionId: '087798E8E3AAF718A64F566491D957D6',
              finalBillingAmount: 0,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -20.47,
              amount: -20.47,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: false,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            }
          ],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'CREDIT' as CreditDebitType,
          type: 'TRANSFER_CREDIT',
          paymentDescription: 'Transf. VEXPENSES > Cartao DEV',
          datetime: '2024-05-06T15:01:22.180000Z',
          transactionId: '1DF4DFA0CA20438AD0FA247ABD682C35',
          finalBillingAmount: 600,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: 600,
          amount: 600,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: 'Thiago Abboud, Campaz',
          companyId: faker.string.uuid()
        }
      ]
    },
    {
      date: '2024-05-05',
      balance: 126.49,
      items: [
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'TRANSFER_DEBIT',
          paymentDescription:
            'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #ACE7B757',
          datetime: '2024-05-05T16:47:47.746000Z',
          transactionId: '820EE83C9860798DB79750C88641F245',
          finalBillingAmount: -4.48,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -4.48,
          amount: -4.48,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'GOODS_PURCHASE',
          paymentDescription: '',
          datetime: '2024-05-05T16:47:18.015000Z',
          transactionId: 'ACE7B7573EFEA8D46347C3848D1BDC73',
          finalBillingAmount: -106.45,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -102.36,
          amount: -20,
          currency: '840',
          currencyCode: 'USD',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'CHATGPT SUBSCRIPTION',
          merchantCity: '+14158799686',
          mcc: 5734,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: true,
          relateds: [
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #ACE7B757',
              datetime: '2024-05-05T16:47:47.746000Z',
              transactionId: '820EE83C9860798DB79750C88641F245',
              finalBillingAmount: -4.48,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -4.48,
              amount: -4.48,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: true,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            },
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'TX INT - VEXPENSES S.A. - VEXPENSES S.A. - #ACE7B757',
              datetime: '2024-05-05T16:47:47.460000Z',
              transactionId: 'CD8591F816E5250D8F7A87F5678E34D2',
              finalBillingAmount: 0,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -4.09,
              amount: -4.09,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: false,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            }
          ],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'TRANSFER_DEBIT',
          paymentDescription:
            'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #AC95928D',
          datetime: '2024-05-05T16:28:58.938000Z',
          transactionId: '260B4A467C61366DE1F23A9497BA9E7A',
          finalBillingAmount: -91.87,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -91.87,
          amount: -91.87,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'GOODS_PURCHASE',
          paymentDescription: '',
          datetime: '2024-05-05T16:27:58.273000Z',
          transactionId: 'AC95928DEA9CE71E21AD27F64A4641E7',
          finalBillingAmount: -2181.47,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -2097.57,
          amount: -379,
          currency: '978',
          currencyCode: 'EUR',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'HXCS-MDGK GRAPHHOPPER',
          merchantCity: '4989250077190',
          mcc: 5734,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: true,
          relateds: [
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #AC95928D',
              datetime: '2024-05-05T16:28:58.938000Z',
              transactionId: '260B4A467C61366DE1F23A9497BA9E7A',
              finalBillingAmount: -91.87,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -91.87,
              amount: -91.87,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: true,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            },
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'TX INT - VEXPENSES S.A. - VEXPENSES S.A. - #AC95928D',
              datetime: '2024-05-05T16:28:58.860000Z',
              transactionId: '06F09082288B333BF2B8360B59A61F0A',
              finalBillingAmount: 0,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -83.9,
              amount: -83.9,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: false,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            }
          ],
          transferorName: null,
          companyId: faker.string.uuid()
        }
      ]
    },
    {
      date: '2024-05-02',
      balance: 2510.76,
      items: [
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'TRANSFER_DEBIT',
          paymentDescription:
            'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #8796DE95',
          datetime: '2024-05-02T11:59:50.093000Z',
          transactionId: '013A58C8AE0F6B67737E747B603CC989',
          finalBillingAmount: -2.05,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -2.05,
          amount: -2.05,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'GOODS_PURCHASE',
          paymentDescription: '',
          datetime: '2024-05-02T11:59:26.454000Z',
          transactionId: '8796DE95BAA50C431F9EFA804FD2B660',
          finalBillingAmount: -48.67,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -46.8,
          amount: -9,
          currency: '840',
          currencyCode: 'USD',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'WWW.MAKE.COM',
          merchantCity: '+12128470692',
          mcc: 5734,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: true,
          relateds: [
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #8796DE95',
              datetime: '2024-05-02T11:59:50.093000Z',
              transactionId: '013A58C8AE0F6B67737E747B603CC989',
              finalBillingAmount: -2.05,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -2.05,
              amount: -2.05,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: true,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            },
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'TX INT - VEXPENSES S.A. - VEXPENSES S.A. - #8796DE95',
              datetime: '2024-05-02T11:59:50.072000Z',
              transactionId: 'C552AA1DF7E3976836A3BB51A3526211',
              finalBillingAmount: 0,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -1.87,
              amount: -1.87,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: false,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            }
          ],
          transferorName: null,
          companyId: faker.string.uuid()
        }
      ]
    },
    {
      date: '2024-05-01',
      balance: 2561.48,
      items: [
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'GOODS_PURCHASE',
          paymentDescription: '',
          datetime: '2024-05-01T12:44:20.474000Z',
          transactionId: '736DE23399761BF5F59D880BA1DF32E2',
          finalBillingAmount: -7195.37,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -7195.37,
          amount: -7195.37,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'Google CLOUD DGGVFJ',
          merchantCity: 'SAO PAULO',
          mcc: 4816,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'GOODS_PURCHASE',
          paymentDescription: '',
          datetime: '2024-05-01T07:24:45.148000Z',
          transactionId: '5FC5478FE23405295F160A930F552C76',
          finalBillingAmount: -7605,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -7605,
          amount: -7605,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'Google GSUITE_vexpense',
          merchantCity: 'SAO PAULO',
          mcc: 4816,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        }
      ]
    },
    {
      date: '2024-04-27',
      balance: 17361.85,
      items: [
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'TRANSFER_DEBIT',
          paymentDescription:
            'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #0DE2654B',
          datetime: '2024-04-27T23:17:47.399000Z',
          transactionId: '8BAF92BA96B6F2C9755FB24CE48DAC99',
          finalBillingAmount: -4.53,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -4.53,
          amount: -4.53,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'GOODS_PURCHASE',
          paymentDescription: '',
          datetime: '2024-04-27T23:17:25.648000Z',
          transactionId: '0DE2654BCA3F7D3462C635B5968A9F1F',
          finalBillingAmount: -107.64,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -103.5,
          amount: -20,
          currency: '840',
          currencyCode: 'USD',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'PADDLE.NET*FREDDY F.',
          merchantCity: 'London',
          mcc: 5817,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: true,
          relateds: [
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #0DE2654B',
              datetime: '2024-04-27T23:17:47.399000Z',
              transactionId: '8BAF92BA96B6F2C9755FB24CE48DAC99',
              finalBillingAmount: -4.53,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -4.53,
              amount: -4.53,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: true,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            },
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'TX INT - VEXPENSES S.A. - VEXPENSES S.A. - #0DE2654B',
              datetime: '2024-04-27T23:17:47.300000Z',
              transactionId: '0040982A004E60B1C09709F959C88433',
              finalBillingAmount: 0,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -4.14,
              amount: -4.14,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: false,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            }
          ],
          transferorName: null,
          companyId: faker.string.uuid()
        }
      ]
    },
    {
      date: '2024-04-26',
      balance: 17474.02,
      items: [
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'TRANSFER_DEBIT',
          paymentDescription:
            'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #70D1AEF6',
          datetime: '2024-04-26T03:26:40.520000Z',
          transactionId: '0A3058A92E1E9909C1FF520A95F91F11',
          finalBillingAmount: -39.36,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -39.36,
          amount: -39.36,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'GOODS_PURCHASE',
          paymentDescription: '',
          datetime: '2024-04-26T03:26:15.642000Z',
          transactionId: '70D1AEF69323A9E6C6B1CF069C4C910F',
          finalBillingAmount: -934.55,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -898.61,
          amount: -173,
          currency: '840',
          currencyCode: 'USD',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'ATLASSIAN',
          merchantCity: '+14157011110',
          mcc: 5734,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: true,
          relateds: [
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #70D1AEF6',
              datetime: '2024-04-26T03:26:40.520000Z',
              transactionId: '0A3058A92E1E9909C1FF520A95F91F11',
              finalBillingAmount: -39.36,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -39.36,
              amount: -39.36,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: true,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            },
            {
              debitCredit: 'DEBIT' as CreditDebitType,
              type: 'TRANSFER_DEBIT',
              paymentDescription:
                'TX INT - VEXPENSES S.A. - VEXPENSES S.A. - #70D1AEF6',
              datetime: '2024-04-26T03:26:40.321000Z',
              transactionId: '63BA3AECAE76EC793690C49B0BD33A85',
              finalBillingAmount: 0,
              finalBillingCurrency: '986',
              finalBillingCurrencyCode: 'BRL',
              billingAmountAuthorized: -35.94,
              amount: -35.94,
              currency: '986',
              currencyCode: 'BRL',
              cardNumber: null,
              merchantName: null,
              merchantCity: null,
              mcc: null,
              isReversal: 'NOT' as IsReversalType,
              reversed: false,
              visible: false,
              isInternational: false,
              companyId: faker.string.uuid(),
              relateds: [],
              reversalAmount: 0,
              transferorName: '',
              name: ''
            }
          ],
          transferorName: null,
          companyId: faker.string.uuid()
        }
      ]
    },
    {
      date: '2024-04-23',
      balance: 18447.93,
      items: [
        {
          name: 'Cartão DEV',
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'GOODS_PURCHASE',
          paymentDescription: '',
          datetime: '2024-04-23T13:05:32.557000Z',
          transactionId: '7E2988FFC6652CBAC08E5CB9088DD07B',
          finalBillingAmount: -20000,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -20000,
          amount: -20000,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: '7154',
          merchantName: 'Google CLOUD JVJ8Q4',
          merchantCity: 'SAO PAULO',
          mcc: 4816,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: null,
          companyId: faker.string.uuid()
        },
        {
          name: 'Cartão DEV',
          debitCredit: 'CREDIT' as CreditDebitType,
          type: 'TRANSFER_CREDIT',
          paymentDescription:
            'Transf. VEXPENSES > Cartao DEV: Transferencia de saldo em conta',
          datetime: '2024-04-23T06:26:30.927000Z',
          transactionId: '06B7A9274791E66F49B7498BBF6146A7',
          finalBillingAmount: 38000,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: 38000,
          amount: 38000,
          currency: '986',
          currencyCode: 'BRL',
          reversalAmount: 0,
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          relateds: [],
          transferorName: 'Afonso Henriq,ue Marques Hernandes',
          companyId: faker.string.uuid()
        }
      ]
    }
  ];

  await MockApi.sleep(2000);

  return data;
}
