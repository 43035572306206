import { useEffect, useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useModalContext } from 'data/contexts';
import {
  CardGroupsQueryKeys,
  useCheckIfCardCanBeRemoved,
  useInfiniteCardGroupManagers,
  useInfiniteCardGroupUsers
} from 'data/modules/cards/cardGroups';

import { useDebounce, useInfiniteScroll } from 'shared/hooks/global';

import { type IUseUsersAndManagersList } from './UsersAndManagersList.types';

export function useUsersAndManagersList(): IUseUsersAndManagersList {
  const checkIfCardCanBeRemoved = useRef(false);

  const infiniteScrollUsersRef = useRef<null | HTMLDivElement>(null);

  const infiniteScrollManagersRef = useRef<null | HTMLDivElement>(null);

  const rootUsersRef = useRef<null | HTMLDivElement>(null);

  const rootManagersRef = useRef<null | HTMLDivElement>(null);

  const { t } = useTranslation();
  const { handleOpenModal } = useModalContext();
  const queryClient = useQueryClient();

  const [
    selectedCardGroup,
    groupManagerSearchTerm,
    groupCardsSearchTerm,
    cardBeingExcluded,
    handleSetCardBeingExcluded,
    { managersPagination, usersPagination }
  ] = useCardGroupsContext(
    useShallow(state => [
      state.selectedCardGroup,
      state.groupManagerSearchTerm,
      state.groupCardsSearchTerm,
      state.cardBeingExcluded,
      state.handleSetCardBeingExcluded,
      state.paginationModalDetails
    ])
  );

  const {
    cardGroupManagersPagination,
    isLoadingInfiniteCardGroupManagers,
    infiniteCardGroupManagers
  } = useInfiniteCardGroupManagers({
    id: selectedCardGroup?.id as string,
    limit: managersPagination.limit,
    name: useDebounce(groupManagerSearchTerm.toLowerCase())
  });

  const {
    cardGroupUsersPagination,
    isLoadingInfiniteCardGroupUsers,
    infiniteCardGroupUsers
  } = useInfiniteCardGroupUsers({
    id: selectedCardGroup?.id as string,
    limit: usersPagination.limit,
    page: usersPagination.page,
    name: useDebounce(groupCardsSearchTerm.toLowerCase())
  });

  const {
    isCheckIfCardCanBeRemovedError,
    isCheckIfCardCanBeRemovedSuccess,
    isCheckingIfCardCanBeRemoved
  } = useCheckIfCardCanBeRemoved({
    userId: cardBeingExcluded?.userId as string,
    enabled: checkIfCardCanBeRemoved.current
  });

  useInfiniteScroll({
    hasNextPage: cardGroupManagersPagination.hasNextPage,
    isFetchingNextPage: cardGroupManagersPagination.isFetchingNextPage,
    actionFn: cardGroupManagersPagination.fetchNextPage,
    infiniteScrollRef: infiniteScrollManagersRef.current,
    rootRef: rootManagersRef.current,
    rootMargin: '20%'
  });

  useInfiniteScroll({
    hasNextPage: cardGroupUsersPagination.hasNextPage,
    isFetchingNextPage: cardGroupUsersPagination.isFetchingNextPage,
    actionFn: cardGroupUsersPagination.fetchNextPage,
    infiniteScrollRef: infiniteScrollUsersRef.current,
    rootRef: rootUsersRef.current,
    rootMargin: '20%'
  });

  const hasNoManagers =
    !infiniteCardGroupManagers || infiniteCardGroupManagers.length === 0;

  const hasNoUsers =
    !infiniteCardGroupUsers || infiniteCardGroupUsers.length === 0;

  useEffect(() => {
    checkIfCardCanBeRemoved.current = false;

    if (!isCheckingIfCardCanBeRemoved && isCheckIfCardCanBeRemovedError) {
      toast.error(t('unavailableToBeRemoved'));

      queryClient.resetQueries({
        queryKey: [CardGroupsQueryKeys.CHECK_IF_CARD_CAN_BE_REMOVED]
      });

      handleSetCardBeingExcluded(null);
    }

    if (!isCheckingIfCardCanBeRemoved && isCheckIfCardCanBeRemovedSuccess) {
      handleOpenModal('removeCardFromGroup');

      queryClient.resetQueries({
        queryKey: [CardGroupsQueryKeys.CHECK_IF_CARD_CAN_BE_REMOVED]
      });
    }
  }, [
    cardBeingExcluded?.userId,
    handleOpenModal,
    isCheckIfCardCanBeRemovedError,
    isCheckIfCardCanBeRemovedSuccess,
    queryClient,
    t,
    handleSetCardBeingExcluded,
    isCheckingIfCardCanBeRemoved
  ]);

  function handleCheckIfCardCanBeRemoved(): void {
    checkIfCardCanBeRemoved.current = true;
  }

  return {
    cardGroupManagers: infiniteCardGroupManagers,
    cardGroupUsers: infiniteCardGroupUsers,
    hasNoManagers,
    hasNoUsers,
    isFetchingCardGroupUsers: isLoadingInfiniteCardGroupUsers,
    isFetchingCardGroupManagers: isLoadingInfiniteCardGroupManagers,
    isCheckingIfCardCanBeRemoved,
    infiniteScrollManagersRef,
    rootManagersRef,
    infiniteScrollUsersRef,
    rootUsersRef,
    isFetchingManagersNextPage: cardGroupManagersPagination.isFetchingNextPage,
    isFetchingUsersNextPage: cardGroupUsersPagination.isFetchingNextPage,
    handleCheckIfCardCanBeRemoved
  };
}
