import { type IModalTitleProps } from './ModalTitle.types';

import { Container } from './ModalTitle.styles';

export function ModalTitle({
  children,
  ...rest
}: IModalTitleProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
