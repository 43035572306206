import styled from 'styled-components';

export const Container = styled.div`
  min-width: 27rem;
  width: 100%;
  padding: 1.5rem 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    min-width: 70rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5rem;
  margin-bottom: 1rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FormInputs = styled.div`
  margin-top: -7rem;
  width: 100%;
  margin-right: 2rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    position: relative;
    margin-top: 0;
    margin-right: 0;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const ScanButton = styled.button`
  position: relative;
  left: 23rem;
  border: 0;
  background: none;
  font-size: 1.3rem;
  text-decoration: underline;
  white-space: nowrap;
  margin-bottom: -10rem;
  color: ${({ theme }) => theme.colors.royalBlue};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    right: 6rem;
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 2rem;
    margin-left: 0;
  }
`;

export const Step = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 0.2fr 1fr;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StepNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  font-size: 1.8rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};

  && {
    width: 3.5rem;
    height: 3.5rem;
  }
`;

export const StepText = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.emperor};
`;

export const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;

  section {
    width: 75%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 2rem;
  }
`;

export const AlertContainer = styled.div`
  margin-top: 3rem;

  ul {
    margin-left: 2rem;

    li {
      list-style-type: disc;
      font-size: 1.2rem;
      margin: 0.3rem 0;
    }
  }
`;
