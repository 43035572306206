import { Modal, tokens } from 'ds';
import styled from 'styled-components';

export const StyledModalContent = styled(Modal.Content)`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowDark};
  font-weight: ${tokens.fontWeightRegular};
  width: 43rem;
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  height: fit-content;
  min-height: fit-content;
`;

export const StyledModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
  gap: ${tokens.spacingXs};
`;
