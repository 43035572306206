import {
  type IPersistenceValidatedPinCard,
  type IValidatedPinCard
} from 'data/modules/cards/businessCard';

class ActivateCardMapper {
  toPersistence(persistence: IPersistenceValidatedPinCard): IValidatedPinCard {
    return {
      isValid: persistence.is_valid
    };
  }
}

export default new ActivateCardMapper();
