import { useEffect } from 'react';

import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useLangContext, useModalContext } from 'data/contexts';
import {
  BalanceMutationKeys,
  BalanceService,
  type IRequestWithdrawalFieldsForm,
  type IRequestWithdrawalPayload,
  type IUseRequestWithdrawal,
  useGetUnallocatedBalance
} from 'data/modules/cards/balance';

import { useFormWithSchema } from 'shared/hooks/forms';
import { Currency } from 'shared/utils/format';

import { useRequestWithdrawalSchema } from './schemas';

export function useRequestWithdrawal(): IUseRequestWithdrawal {
  const { unallocatedBalance } = useGetUnallocatedBalance({});
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);
  const { handleCloseModal } = useModalContext();

  const methods = useFormWithSchema(useRequestWithdrawalSchema(), {
    defaultValues: {
      account: '',
      agency: '',
      amount: '',
      bank: '',
      documentNumber: '',
      pixKey: '',
      reason: ''
    }
  });

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      methods.reset();
    }
  }, [methods]);

  const requestWithdrawal = useMutationCache({
    mutationKey: [BalanceMutationKeys.REQUEST_WITHDRAWAL],
    mutationFn: async (payload: IRequestWithdrawalPayload) =>
      await BalanceService.requestWithdrawal(payload),
    onSuccess: () => {
      toast.success(
        lang.cards.withdrawal.request_made_with_success[currentLangKey]
      );

      handleCloseModal();
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function onSubmit(data: IRequestWithdrawalFieldsForm): void {
    if (!unallocatedBalance) {
      return;
    }

    if (
      Currency.parseToFloat(data.amount) >
      Currency.parseToFloat(unallocatedBalance?.amount.toFixed(2))
    ) {
      toast.error(lang.cards.withdrawal.invalid_amount[currentLangKey]);
      return;
    }

    requestWithdrawal.mutate(data);
  }

  return {
    isLoading: requestWithdrawal.isLoading,
    handleSubmit: methods.handleSubmit(onSubmit),
    methods
  };
}
