import { DatePicker } from 'ds/components/Input/DatePicker';
import { useTranslation } from 'react-i18next';

import { useLangContext } from 'data/contexts';

import { useFastDates } from './useFastDates';

import { type IFastDatesProps } from './FastDates.types';

import { ButtonsRow, DatePickerRow, SelectionButton } from './FastDates.styles';

export function FastDates(props: IFastDatesProps): JSX.Element {
  const { t } = useTranslation('analytics');
  const { currentLangKey } = useLangContext();

  const { fastDateActiveOption, handleSetFastDates } = useFastDates(props);

  return (
    <>
      <ButtonsRow>
        <SelectionButton
          type='button'
          onClick={handleSetFastDates('TODAY')}
          $isActive={fastDateActiveOption === 'TODAY'}
        >
          {t('filters.today')}
        </SelectionButton>

        <SelectionButton
          type='button'
          onClick={handleSetFastDates('LAST_WEEK')}
          $isActive={fastDateActiveOption === 'LAST_WEEK'}
        >
          {t('filters.lastDays', { days: '7' })}
        </SelectionButton>

        <SelectionButton
          type='button'
          onClick={handleSetFastDates('LAST_MONTH')}
          $isActive={fastDateActiveOption === 'LAST_MONTH'}
        >
          {t('filters.lastDays', { days: '30' })}
        </SelectionButton>

        <SelectionButton
          type='button'
          onClick={handleSetFastDates('THIS_MONTH')}
          $isActive={fastDateActiveOption === 'THIS_MONTH'}
        >
          {t('filters.thisMonth')}
        </SelectionButton>
      </ButtonsRow>

      <DatePickerRow>
        <DatePicker.Controlled
          variant='small'
          name='initialDate'
          iconRight='ArrowDownIcon'
          currentLangKey={currentLangKey}
          label={t('filters.initialDate')}
        />

        <DatePicker.Controlled
          name='endDate'
          variant='small'
          iconRight='ArrowDownIcon'
          label={t('filters.finalDate')}
          currentLangKey={currentLangKey}
        />
      </DatePickerRow>
    </>
  );
}
