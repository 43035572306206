import { tokens } from 'ds';
import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
`;

export const ReportInformationList = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 4rem;
`;

export const SearchSelectReportContainer = styled.div`
  background: ${tokens.neutralColorHighLight};
  border-radius: ${tokens.borderRadiusMd};
  padding: ${tokens.spacingXs};
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
`;

export const SelectReportContainer = styled.div`
  padding: ${tokens.spacingXs};
  background-color: ${tokens.deepColorWhite};
  border-radius: ${tokens.borderRadiusMd};
  border: 1px solid ${tokens.neutralColorLowLight};

  > div {
    overflow: auto;
    height: 32rem;

    @media screen and (max-height: 760px) {
      height: 17.6rem;
    }

    padding-right: ${tokens.spacingXs};
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacingXs};

    .report-radio-item {
      max-width: unset;
    }

    .multiple-loaders-container {
      display: flex;
      flex-direction: column;
      gap: ${tokens.spacingXs};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    > div .report-radio-item {
      height: unset;
    }
  }
`;

export const ReportNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingLg};
  align-items: center;
  justify-content: center;
  height: 100%;

  > p {
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxs};
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
    letter-spacing: 0.75px;
    color: ${tokens.neutralColorLowMedium};
    max-width: 63rem;
    text-align: center;
  }
`;

export const ReportItemSubtitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingXs};

  > div {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    flex-basis: 33.33%;
    flex-grow: 1;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    align-items: start;
  }
`;
