import { useTranslation } from 'react-i18next';

import { useCardGroupsContext } from 'data/contexts';

import { type IDescriptionModalProps } from './DescriptionModal.types';

import { Container } from './DescriptionModal.styles';

export function DescriptionModal({
  ...rest
}: IDescriptionModalProps): JSX.Element {
  const { t } = useTranslation('cards');

  const currentStepOfGroupCreation = useCardGroupsContext(
    state => state.currentStepOfGroupCreation
  );

  return (
    <Container {...rest}>
      {currentStepOfGroupCreation === 4 &&
        t('startChooseANameForTheNewCardGroup')}

      {currentStepOfGroupCreation === 5 &&
        t('selectTheUsersYouWantToIncludeInThisGroup')}

      {currentStepOfGroupCreation === 6 &&
        t('selectTheUsersWhoWillBeTheManagersOfTheGroup')}
    </Container>
  );
}
