import { faker } from '@faker-js/faker';

import { type IAccountBalanceAmount } from 'data/modules/cards/account';

import { MockApi } from 'shared/utils/global';

export async function getAccountBalanceAmountMock(): Promise<IAccountBalanceAmount> {
  await MockApi.sleep(2000);

  return {
    amount: faker.number.float({ fractionDigits: 1, max: 500 })
  };
}
