import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import { Spinner } from 'presentation/components/base/Spinner';
import { DsFileInput } from 'presentation/ds/DsFileInput';
import { DsSelect } from 'presentation/ds/DsSelect';
import { DsTextInput } from 'presentation/ds/DsTextInput';
import {
  OnboardingFooter,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { Onboarding } from 'shared/utils/cards';

import { useCompanyData } from './useCompanyData';

import {
  Container,
  CustomLoadingOverlay,
  FormContainer
} from './CompanyData.styles';

export function CompanyData(): JSX.Element {
  const navigate = useNavigate();

  const [forms, onboarding, currentLangKey] = useLangContext(state => [
    state.lang.forms,
    state.lang.cards.onboarding,
    state.currentLangKey
  ]);

  const {
    control,
    errors,
    handleUpdateCompanyData,
    setValue,
    cnaeOptions,
    legalNatureOptions,
    isPullingSerproInformations
  } = useCompanyData();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  return (
    <Container>
      <OnboardingTitle>
        {onboarding.company.company_data.title[currentLangKey]}
      </OnboardingTitle>

      {isPullingSerproInformations && (
        <CustomLoadingOverlay>
          <Spinner
            color='royalBlue'
            size={4}
          />
        </CustomLoadingOverlay>
      )}

      <FormContainer onSubmit={handleUpdateCompanyData}>
        <DsTextInput
          name='cnpj'
          label={forms.cnpj[currentLangKey]}
          control={control}
          mask='cnpj'
          onClear={() => {
            setValue('cnpj', '');
          }}
          error={errors.cnpj?.message}
        />
        <DsTextInput
          onClear={() => {
            setValue('legalName', '');
          }}
          name='legalName'
          label={forms.corporate_name[currentLangKey]}
          control={control}
          error={errors.legalName?.message}
          maxLength={60}
        />

        <DsSelect
          name='mainActivity'
          control={control}
          label={forms.main_activity_cnae[currentLangKey]}
          options={cnaeOptions}
          error={errors.mainActivity?.message}
        />

        <DsSelect
          name='legalNature'
          control={control}
          label={forms.legal_nature[currentLangKey]}
          options={legalNatureOptions}
          error={errors.legalNature?.message}
        />

        <DsTextInput
          onClear={() => {
            setValue('foundingDate', '');
          }}
          mask='date'
          name='foundingDate'
          label={`${forms.select.founding_date[currentLangKey]} (dd/mm/aaaa)`}
          control={control}
          error={errors.foundingDate?.message}
        />

        <DsTextInput
          onClear={() => {
            setValue('email', '');
          }}
          name='email'
          label={forms.company_email[currentLangKey]}
          control={control}
          error={errors.email?.message}
        />

        <DsTextInput
          onClear={() => {
            setValue('phoneNumber', '');
          }}
          mask='phone'
          name='phoneNumber'
          label={forms.phone[currentLangKey]}
          control={control}
          error={errors.phoneNumber?.message}
        />

        <DsFileInput
          label={forms.attach_contract[currentLangKey]}
          name='contract'
          control={control}
          accept='application/pdf'
          error={errors.contract?.message}
        />

        <OnboardingFooter
          backButtonProps={{
            onClick: () => {
              navigate(`/cartoes/onboarding/empresa/${currentStep - 1}`);
            }
          }}
          continueButtonProps={{
            onClick: () => {}
          }}
        />
      </FormContainer>
    </Container>
  );
}
