import { useQueryCache } from 'data/cache';
import {
  CardGroupsQueryKeys,
  CardGroupsService,
  type IGetCardGroupUsersParams,
  type IUseGetCardGroupUsers
} from 'data/modules/cards/cardGroups';

export function useGetCardGroupUsers(
  params: IGetCardGroupUsersParams,
  enabled?: boolean
): IUseGetCardGroupUsers {
  const { data, isFetching } = useQueryCache({
    enabled,
    queryKey: [CardGroupsQueryKeys.GET_GROUP_USERS, params],
    queryFn: async () => await CardGroupsService.getGroupUsers(params)
  });

  return {
    cardGroupUsers: data,
    isFetchingCardGroupUsers: isFetching
  };
}
