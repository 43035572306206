import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import {
  type ICreateRouteByMapDefaultFieldsForm,
  type IUseDefaultFieldsSchemaParams
} from 'data/modules/expenses/types/expenses.types';
import { useGetExpenseParameters } from 'data/modules/expenses/useCases/get-expense-parameters/useGetExpenseParameters';

import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';
import { Validate } from 'shared/utils/validation';

export function useDefaultFieldsSchema({
  userAction
}: IUseDefaultFieldsSchemaParams): ObjectSchema<ICreateRouteByMapDefaultFieldsForm> {
  const { lang, currentLangKey } = useLangContext();

  const { t } = useTranslation('expenses');

  const { expenseParameters } = useGetExpenseParameters();

  const defaultFieldsSchema: ObjectSchema<ICreateRouteByMapDefaultFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];
      const fieldInvalidMessage = lang.schemas.invalid_value[currentLangKey];

      return yup.object({
        // se o parâmetro preferencias_descricaoDespesaObrigatoria for true, então o campo description é obrigatório
        description: expenseParameters?.isExpenseDescriptionMandatory
          ? yup.string().required(fieldRequiredMessage)
          : yup.string(),
        date: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'date',
            message: lang.schemas.invalid_date[currentLangKey],
            test: (value: string) => Validate.isDate(value, 'yyyy-MM-dd')
          })
          .test(
            'validate-future-days',
            (value: string, { createError, path }) => {
              const expensesPolicyType = expenseParameters?.policyType;

              if (expensesPolicyType === 'USUARIO' && userAction === 'create') {
                const amountOfFutureDaysThatIsAllowToCreateExpense =
                  expenseParameters?.daysPolicy.futureDays;

                if (!amountOfFutureDaysThatIsAllowToCreateExpense) {
                  return true;
                }

                const todayIsoString = CustomDate.formatDateToIso(new Date());
                const selectedDate = CustomDate.parseIsoToDate(value);

                const differenceBetweenDates =
                  CustomDate.differenceBetweenDates(
                    CustomDate.parseIsoToDate(todayIsoString),
                    selectedDate
                  );

                if (
                  differenceBetweenDates <
                  -amountOfFutureDaysThatIsAllowToCreateExpense
                ) {
                  const exceedInDays =
                    Math.abs(differenceBetweenDates) -
                    amountOfFutureDaysThatIsAllowToCreateExpense;

                  return createError({
                    path,
                    message: t(
                      'errors.expenseExceedsTheMaximumAmountOfFutureDays',
                      {
                        days: exceedInDays
                      }
                    )
                  });
                }
              }

              return true;
            }
          )
          .test(
            'validate-past-days',
            (value: string, { createError, path }) => {
              const expensesPolicyType = expenseParameters?.policyType;

              if (expensesPolicyType === 'USUARIO' && userAction === 'create') {
                const amountOfPastDaysThatIsAllowToCreateExpense =
                  expenseParameters?.daysPolicy.pastDays;

                if (!amountOfPastDaysThatIsAllowToCreateExpense) {
                  return true;
                }

                const todayIsoString = CustomDate.formatDateToIso(new Date());
                const selectedDate = CustomDate.parseIsoToDate(value);

                const differenceBetweenDates =
                  CustomDate.differenceBetweenDates(
                    CustomDate.parseIsoToDate(todayIsoString),
                    selectedDate
                  );

                if (
                  differenceBetweenDates >
                  amountOfPastDaysThatIsAllowToCreateExpense
                ) {
                  const exceedInDays =
                    Math.abs(differenceBetweenDates) -
                    amountOfPastDaysThatIsAllowToCreateExpense;

                  return createError({
                    path,
                    message: t(
                      'errors.expenseExceedsTheMaximumAmountOfDaysPassed',
                      {
                        days: exceedInDays
                      }
                    )
                  });
                }
              }

              return true;
            }
          ),
        mileage: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'notANumber',
            message: fieldInvalidMessage,
            test: value => {
              if (value) {
                const numberValue = Currency.parseToFloat(value);

                return !isNaN(numberValue);
              }

              return true;
            }
          }),
        mileagePaidValue: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'notANumber',
            message: fieldInvalidMessage,
            test: value => {
              if (value) {
                const numberValue = Currency.parseToFloat(value);

                return !isNaN(numberValue);
              }

              return true;
            }
          }),
        currency: yup.string().required(fieldRequiredMessage),
        value: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'notANumber',
            message: fieldInvalidMessage,
            test: value => {
              if (value) {
                const numberValue = Currency.parseToFloat(value);

                return !isNaN(numberValue);
              }

              return true;
            }
          }),
        costsCenter: yup
          .object({
            value: yup.string(),
            label: yup.string()
          })
          .test({
            name: 'costsCenterRequired',
            message: fieldRequiredMessage,
            test: Validate.isOptionSelected
          }),
        // se o parâmetro preferencias_formaPagamentoObrigatoria for true, então o campo paymentMethod é obrigatório
        paymentMethod: expenseParameters?.isPaymentMethodMandatory
          ? yup.string().required(fieldRequiredMessage)
          : yup.string().defined(),
        refundable: yup.boolean().required(fieldRequiredMessage),
        observation: expenseParameters?.isExpensesObsMandatory
          ? yup.string().required(fieldRequiredMessage)
          : yup.string().defined(),
        apportionment: yup.array().of(
          yup.object({
            project: yup
              .object({
                value: yup.string(),
                label: yup.string()
              })
              .test({
                name: 'projectRequired',
                message: fieldRequiredMessage,
                test: Validate.isOptionSelected
              }),
            percentage: yup.number().required(fieldRequiredMessage),
            value: yup.number().required(fieldRequiredMessage)
          })
        )
      });
    }, [lang, currentLangKey, expenseParameters, t, userAction]);

  return defaultFieldsSchema;
}
