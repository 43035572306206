import {
  type IPersistenceQuantityOfBalanceSolicitationByStatus,
  type IQuantityOfBalanceSolicitationByStatus
} from 'data/modules/cards/balance/types/balance.types';

class GetQuantityOfBalanceSolicitationByStatusMapper {
  toDomain(
    data: IPersistenceQuantityOfBalanceSolicitationByStatus
  ): IQuantityOfBalanceSolicitationByStatus {
    return {
      sent: data.SENT,
      all: data.ALL,
      approved: data.APPROVED,
      rejected: data.REJECTED
    };
  }
}
export default new GetQuantityOfBalanceSolicitationByStatusMapper();
