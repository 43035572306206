import { format } from 'date-fns';
import { setUserId, setUserProperties } from 'firebase/analytics';
import ga from 'react-ga4';
import { useShallow } from 'zustand/react/shallow';

import { analytics } from 'data/config/firebase.config';
import { useAuthContext } from 'data/contexts';

import { ga4 } from 'shared/eventsGa4';

import {
  type GAEventsType,
  type GAParamsType,
  type GAScopeType,
  type ISendUser,
  type IUseGa4
} from './useGa4.types';

export function useGa4(): IUseGa4 {
  const [user, company] = useAuthContext(
    useShallow(state => [state.user, state.company])
  );

  function sendUser({ userId, companyId, companyUuId }: ISendUser): void {
    setUserId(analytics, `${userId}`);

    setUserProperties(analytics, { sessionUserId: userId.toString() });

    setUserProperties(analytics, { companyId: companyId.toString() });

    setUserProperties(analytics, { companyUuId });
  }

  function sendGaEvent<
    ScopeType extends GAScopeType,
    EventType extends GAEventsType<ScopeType>
  >(
    scope: ScopeType,
    event: EventType,
    params?: GAParamsType<ScopeType, EventType>,
    replace?: boolean
  ): void {
    if (user && company) {
      ga.set({
        sessionUserId: user.id,
        companyId: company.id,
        usesCard: user.parameters.usesCards,
        cardsManagerType:
          user?.cards?.type === 'ACCOUNT_MANAGER'
            ? 'accountManager'
            : user?.cards?.type === 'FINANCIAL_MANAGER' && 'financialManager'
      });
    }

    const eventJson: GAParamsType<ScopeType, EventType> =
      ga4[scope]?.[event] ?? {};

    params = params
      ? replace
        ? params
        : {
            ...eventJson,
            ...params
          }
      : eventJson;

    if (params) {
      ga.event(event as string, {
        ...params,
        timeStamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
      });
    }
  }

  return {
    sendGaEvent,
    sendUser
  };
}
