import styled, { css } from 'styled-components';

import { type DsDefaultButtonStylesType } from './DsDefaultButton.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.button<
  AddPrefixToType<DsDefaultButtonStylesType>
>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-width: ${({ theme }) => theme.tokens.borderWidthThin};
  border-color: transparent;
  background-color: ${({ $secondary, theme }) =>
    !$secondary
      ? `${theme.tokens.brandColorPrimaryPure}`
      : `${theme.tokens.highlightColorPure}`};
  font-weight: ${({ theme }) => theme.tokens.fontWeightSemibold};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins}, sans-serif;
  color: ${({ theme }) => theme.tokens.neutralColorHighPure};
  transition: all 0.3s ease-in;
  cursor: pointer;

  &:hover {
    background-color: ${({ $secondary, theme }) =>
      !$secondary
        ? `${theme.tokens.brandColorPrimaryDark}`
        : `${theme.tokens.highlightColorDark}`};
  }

  &:active {
    outline: none;
    background-color: ${({ $secondary, theme }) =>
      !$secondary
        ? `${theme.tokens.brandColorSecondaryDark}`
        : `${theme.tokens.highlightColorDarkest}`};
  }

  ${({ $size }) =>
    $size === 'small' &&
    css`
      padding: ${({ theme }) => theme.tokens.spacingInsetXxxs}
        ${({ theme }) => theme.tokens.spacingInsetMd};
      line-height: 1.4rem;
      font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
      border-radius: ${({ theme }) => theme.tokens.borderRadiusXs};
      gap: ${({ theme }) => theme.tokens.spacingInsetXxs};
    `};

  ${({ $size }) =>
    $size === 'medium' &&
    css`
      padding: ${({ theme }) => theme.tokens.spacingInsetXs}
        ${({ theme }) => theme.tokens.spacingInsetMdd};
      line-height: 1.5rem;
      font-size: ${({ theme }) => theme.tokens.fontSizeXxs};
      border-radius: ${({ theme }) => theme.tokens.borderRadiusSm};
      gap: ${({ theme }) => theme.tokens.spacingInsetXxs};
    `};

  ${({ $size }) =>
    $size === 'large' &&
    css`
      padding: ${({ theme }) => theme.tokens.spacingInsetSm}
        ${({ theme }) => theme.tokens.spacingInsetLg};
      line-height: 1.5rem;
      font-size: ${({ theme }) => theme.tokens.fontSizeXxs};
      border-radius: ${({ theme }) => theme.tokens.borderRadiusSm};
      gap: ${({ theme }) => theme.tokens.spacingInsetXs};
    `};

  ${({ $size }) =>
    $size === 'huge' &&
    css`
      padding: ${({ theme }) => theme.tokens.spacingInsetSm}
        ${({ theme }) => theme.tokens.spacingInsetLg};
      line-height: 2rem;
      font-size: ${({ theme }) => theme.tokens.fontSizeXs};
      border-radius: ${({ theme }) => theme.tokens.borderRadiusMd};
      gap: ${({ theme }) => theme.tokens.spacingInsetXs};
    `};

  ${({ $variant }) =>
    $variant === 'pill' &&
    css`
      border-radius: ${({ theme }) => theme.tokens.borderRadiusCircular};
    `};

  &:disabled {
    background-color: ${({ $secondary, theme }) =>
      !$secondary
        ? `${theme.tokens.brandColorPrimaryDarkest}`
        : `${theme.tokens.highlightColorMedium}`};

    cursor: default;
  }
`;
