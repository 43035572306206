import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-size: 1.2rem;

  > input {
    cursor: pointer;
  }

  > label {
    cursor: pointer;
  }
`;
