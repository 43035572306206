import styled from 'styled-components';

export const Container = styled.h1`
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.veniceBlue};
  font-size: ${({ theme }) => theme.tokens.fontSizeMd};
  font-weight: ${({ theme }) => theme.tokens.fontWeightBold};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};

  margin-bottom: ${({ theme }) => theme.tokens.spacingMd};
`;
