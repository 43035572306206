import { CardInfo } from 'ds';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetUnallocatedBalance } from 'data/modules/cards/balance';

import { Currency } from 'shared/utils/format';

import { type IAvailableValueCardInfoProps } from './AvailableValueCardInfo.types';

import { CardInfoContainer } from './AvailableValueCardInfo.styles';

export function AvailableValueCardInfo({
  notHasIcon,
  value,
  ...rest
}: IAvailableValueCardInfoProps): JSX.Element {
  const { t } = useTranslation('cards');
  const { groupId } = useParams();

  const { unallocatedBalance, isFetchingUnallocatedBalance } =
    useGetUnallocatedBalance({ ...(groupId && { cardGroupId: groupId }) });

  return (
    <CardInfoContainer {...rest}>
      <CardInfo.Title
        variant='small'
        {...(notHasIcon ? {} : { icon: 'MoneyWalletAdd' })}
      >
        {t('amountAvailableToAllocate')}
      </CardInfo.Title>

      <CardInfo.ValueContainer>
        <CardInfo.Value
          isLoading={isFetchingUnallocatedBalance || !unallocatedBalance}
          variant='small'
        >
          {value || value === 0
            ? Currency.format('BRL', value, true)
            : unallocatedBalance &&
              Currency.format('BRL', unallocatedBalance?.amount, true)}
        </CardInfo.Value>
      </CardInfo.ValueContainer>

      <CardInfo.Caption variant='small'>
        {t('amountNotAllocatedToCards')}
      </CardInfo.Caption>
    </CardInfoContainer>
  );
}
