import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';
import { useNavigate } from 'react-router-dom';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import { type ILegalNatureOption } from 'data/modules/auth';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUploadCompanyDocumentPayload,
  type IUseAddLegalEntityDocument,
  type LegalEntityPartnerDataSchemaFieldsType,
  type LegalPersonShareholderType,
  type OnboardingShareholderType,
  useAddLegalEntityPartner
} from 'data/modules/cards/companyOnboarding';
import { type ICnae } from 'data/modules/global';

import { cnae, legalNature } from 'shared/constants/global';
import { useFormWithSchema } from 'shared/hooks/forms';
import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';
import { CustomObject } from 'shared/utils/custom';

import { useHandleLegalEntityPartnerDataSchema } from './schemas';

export function useHandleLegalEntityPartnerData(): IUseAddLegalEntityDocument {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const legalEntityData = queryClient.getQueryData<LegalPersonShareholderType>([
    'legalEntityData'
  ]);

  const selectedShareholder =
    queryClient.getQueryData<LegalPersonShareholderType>([
      'selectedShareholder'
    ]);

  function getDefaultLegalNature(): ILegalNatureOption | undefined {
    return legalNature.find(item => {
      if (legalEntityData?.businessType) {
        return item.value === legalEntityData.businessType;
      }

      return item.value === selectedShareholder?.businessType;
    });
  }

  function getDefaultMainActivity(): ICnae | undefined {
    return cnae.find(item => {
      if (legalEntityData?.businessType) {
        return item.value === legalEntityData.mainActivity;
      }

      return item.value === selectedShareholder?.mainActivity;
    });
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useFormWithSchema<LegalEntityPartnerDataSchemaFieldsType>(
    useHandleLegalEntityPartnerDataSchema(),
    {
      defaultValues: {
        document: legalEntityData?.document ?? selectedShareholder?.document,
        legalName: legalEntityData?.legalName ?? selectedShareholder?.legalName,
        businessType: getDefaultLegalNature(),
        mainActivity: getDefaultMainActivity(),
        email: legalEntityData?.email ?? selectedShareholder?.email,
        foundingDate:
          legalEntityData?.foundingDate ?? selectedShareholder?.foundingDate,
        phoneNumber:
          legalEntityData?.phoneNumber ?? selectedShareholder?.phoneNumber,
        contract: legalEntityData?.contract ?? selectedShareholder?.contract
      }
    }
  );

  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const {
    data: cachedCompanyOnboardingShareholders,
    updateCache: updateShareholdersCache
  } = useHandleQueryCache({
    query: {
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingShareholders(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_SHAREHOLDERS]
    }
  });

  const { mutate: uploadCompanyDocument } = useMutationCache({
    mutationKey: [CompanyOnboardingMutationKeys.UPLOAD_COMPANY_DOCUMENT],
    mutationFn: async (payload: IUploadCompanyDocumentPayload) =>
      await CompanyOnboardingService.uploadCompanyDocument(payload),
    onSuccess: data => {
      setValue('contract', data.contract);

      handleSubmit(onSubmit)();
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  const { handleAddLegalEntityPartner } = useAddLegalEntityPartner({
    onAfterAddingLegalEntityPartner: handleUpdateCacheAndNavigatePartnersList
  });

  function handleUpdateCacheAndNavigatePartnersList(
    newPartnersList: Required<OnboardingShareholderType>[]
  ): void {
    navigate(`/cartoes/onboarding/empresa/${currentStep}`, {
      replace: true
    });

    updateShareholdersCache({
      newData: newPartnersList
    });
  }

  function onSubmit(
    userEnteredValue: LegalEntityPartnerDataSchemaFieldsType
  ): void {
    const hasAlreadyAddedShareholderDocument =
      cachedCompanyOnboardingShareholders?.find(
        shareholder =>
          shareholder.document === userEnteredValue.document &&
          shareholder.document !== selectedShareholder?.document
      );

    if (hasAlreadyAddedShareholderDocument) {
      toast.error(
        lang.cards.onboarding.company.add_partner.natural_person_or_legal_entity
          .errors.already_added_document[currentLangKey]
      );

      return;
    }

    if (userEnteredValue.contract instanceof FileList) {
      uploadCompanyDocument({
        document: userEnteredValue.contract[0],
        uuidCompanyOnboarding:
          cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      });

      return;
    }

    handleAddLegalEntityPartner({
      ...CustomObject.omit(userEnteredValue, [
        'businessType',
        'mainActivity',
        'contract'
      ]),
      businessType: userEnteredValue.businessType as string,
      mainActivity: userEnteredValue.mainActivity as string,
      contract: userEnteredValue.contract as string
    });
  }

  return {
    control,
    errors,
    setValue,
    handleForwardDataToNextStep: handleSubmit(onSubmit)
  };
}
