import { useEffect, useRef, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useModalContext } from 'data/contexts';
import {
  FuelQueryKeys,
  type IVehicle,
  useInfinitePaginatedMembers,
  useLinkOrUnlinkMembersFromVehicle
} from 'data/modules/fuel';
import { useGetVehicle } from 'data/modules/fuel/useCases/get-vehicle/useGetVehicle';

import { routesPathPrefix } from 'shared/constants/global';
import { useInfiniteScroll } from 'shared/hooks/global';

import {
  type IUseViewVehicleModal,
  type IUseViewVehicleModalParams
} from './ViewVehicleModal.types';

export function useViewVehicleModal({
  vehicleId,
  isVehicleOnList
}: IUseViewVehicleModalParams): IUseViewVehicleModal {
  const { vehicle, isLoadingVehicle } = useGetVehicle({
    vehicleId,
    enabled: true
  });
  const showMoreDivRef = useRef<HTMLDivElement>(null);

  const [showMoreMembers, setShowMoreMembers] = useState(false);

  const [memberToUnlink, setMemberToUnlink] = useState<number | undefined>();

  const [membersUnlinked, setMembersUnlinked] = useState<number[]>([]);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { handleCloseModal } = useModalContext();

  const { t } = useTranslation('fuel');

  useEffect(() => {
    if (!isLoadingVehicle && vehicle) {
      if (!isVehicleOnList) {
        queryClient.setQueryData<IVehicle>(
          [FuelQueryKeys.GET_VEHICLE, vehicle.id],
          vehicle
        );
      }

      navigate(
        `${routesPathPrefix.settings}/combustivel/veiculos/${vehicle.id}`
      );
    }

    if (!isLoadingVehicle && !vehicle) {
      toast.error(t('messages.vehicleNotFound'));
      handleCloseModal();
      navigate(`${routesPathPrefix.settings}/combustivel/veiculos`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingVehicle, vehicle]);

  function handleDispatchRequestOnScroll(): void {
    if (!linkedMembersPagination.hasNextPage) {
      return;
    }

    linkedMembersPagination.fetchNextPage();
  }

  const infiniteScrollRef = useRef<null | HTMLDivElement>(null);

  const {
    isLoadingInfiniteMembers: isLoadingLinkedInfiniteMembers,
    membersPagination: linkedMembersPagination,
    infiniteMembers: linkedInfiniteMembers
  } = useInfinitePaginatedMembers({
    perPage: 30,
    search: '',
    vehicleId,
    linked: true
  });

  const hasNextPageToFetch =
    !isLoadingLinkedInfiniteMembers || linkedMembersPagination.hasNextPage;

  const isFetchingNextPage = linkedMembersPagination.isFetchingNextPage;

  useInfiniteScroll({
    hasNextPage: hasNextPageToFetch,
    isFetchingNextPage,
    actionFn: handleDispatchRequestOnScroll,
    infiniteScrollRef: infiniteScrollRef.current,
    rootRef: showMoreDivRef.current,
    rootMargin: '30%'
  });

  const {
    linkOrUnlinkMembersFromVehicle,
    isLinkingOrUnlinkingMembersFromVehicle
  } = useLinkOrUnlinkMembersFromVehicle({
    onErrorLinkOrUnlinkMembersFromVehicle: () => {
      toast.error(t('modal.errorUnlinkingMember'));
    },
    onSuccessLinkOrUnlinkMembersFromVehicle: () => {
      const member = memberToUnlink;

      setMembersUnlinked(oldData => [...oldData, member as number]);

      setMemberToUnlink(undefined);

      queryClient.invalidateQueries({
        queryKey: [FuelQueryKeys.GET_VEHICLE, vehicleId]
      });

      queryClient.invalidateQueries({
        queryKey: [
          FuelQueryKeys.GET_MEMBERS,
          {
            perPage: 30,
            search: '',
            vehicleId,
            linked: true
          }
        ]
      });

      toast.success(t('modal.successUnlinkingMember'));
    }
  });

  async function handleUnlinkMember(): Promise<void> {
    if (memberToUnlink) {
      await linkOrUnlinkMembersFromVehicle({
        vehicleId,
        membersIdsToDetach: [memberToUnlink]
      });
    }
  }

  return {
    isShowingModalLoading: isLoadingVehicle || isLoadingLinkedInfiniteMembers,
    vehicleToShow: vehicle,
    showDeleteModal,
    setShowDeleteModal,
    memberToUnlink,
    setMemberToUnlink,
    showMoreDivRef,
    showMoreMembers,
    setShowMoreMembers,
    linkedInfiniteMembers,
    infiniteScrollRef,
    isFetchingInfiniteMembersNextPage:
      linkedMembersPagination.isFetchingNextPage,
    isLoadingLinkedInfiniteMembers,
    handleUnlinkMember,
    isLinkingOrUnlinkingMembersFromVehicle,
    membersUnlinked
  };
}
