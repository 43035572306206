import { useCallback, useState } from 'react';

import {
  type CopiedValueType,
  type CopyFnType
} from './useCopyToClipboard.types';

export function useCopyToClipboard(): [CopiedValueType, CopyFnType] {
  const [copiedText, setCopiedText] = useState<CopiedValueType>(null);

  const handleCopyToClipboard: CopyFnType = useCallback(async text => {
    if (!navigator?.clipboard) {
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);

      setCopiedText(text);

      return true;
    } catch (error) {
      setCopiedText(null);

      return false;
    }
  }, []);

  return [copiedText, handleCopyToClipboard];
}
