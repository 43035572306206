import { useQueryCache } from 'data/cache';
import {
  CardGroupsQueryKeys,
  CardGroupsService,
  type IUseCheckIfCardCanBeRemoved,
  type IUseCheckIfCardCanBeRemovedParams
} from 'data/modules/cards/cardGroups';

export function useCheckIfCardCanBeRemoved({
  userId,
  enabled
}: IUseCheckIfCardCanBeRemovedParams): IUseCheckIfCardCanBeRemoved {
  const { isFetching, isSuccess, isError } = useQueryCache({
    queryKey: [CardGroupsQueryKeys.CHECK_IF_CARD_CAN_BE_REMOVED],
    queryFn: async () => {
      return await CardGroupsService.checkIfCardCanBeRemoved({ userId });
    },
    enabled
  });

  return {
    isCheckingIfCardCanBeRemoved: isFetching,
    isCheckIfCardCanBeRemovedSuccess: isSuccess,
    isCheckIfCardCanBeRemovedError: isError
  };
}
