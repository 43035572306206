import { Pagination } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useLangContext } from 'data/contexts';

import { type ITablePaginationProps } from './TablePagination.types';

import {
  Container,
  StyledControl,
  StyledPaginationContainer
} from './TablePagination.styles';

export function TablePagination({
  disabled,
  ...rest
}: ITablePaginationProps): JSX.Element {
  const currentLangKey = useLangContext(state => state.currentLangKey);

  const [pagination, handleChangePaginationGroupManagement] =
    useCardGroupsContext(
      useShallow(state => [
        state.paginationGroupManagement,
        state.handleChangePaginationGroupManagement
      ])
    );

  return (
    <Container {...rest}>
      {!disabled && (
        <StyledPaginationContainer
          currentPage={pagination.page}
          perPage={pagination.limit}
          totalPage={
            pagination.total
              ? Math.ceil(pagination.total / pagination.limit)
              : 1
          }
        >
          <Pagination.Label langKey={currentLangKey} />

          <StyledControl
            onChangePage={page => {
              handleChangePaginationGroupManagement({ page });
            }}
          />

          <Pagination.Pages
            langKey={currentLangKey}
            onChangePerPage={perPage => {
              handleChangePaginationGroupManagement({
                limit: perPage
              });
            }}
            options={[10, 20, 50]}
          />
        </StyledPaginationContainer>
      )}
    </Container>
  );
}
