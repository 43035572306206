import { tokens } from 'ds';
import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type ContainerStylesType } from './TransactionDetailsSection.types';

export const Container = styled.div<AddPrefixToType<ContainerStylesType>>`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  padding: ${tokens.spacingXs} 0;
  border-top: ${({ $hasDivider }) =>
    $hasDivider ? `1px solid ${tokens.neutralColorHighDark}` : 'none'};

  strong {
    font-size: ${tokens.fontSizeXxs};
    font-weight: ${tokens.fontWeightSemibold};
    color: ${tokens.neutralColorLowDark};
  }
`;
