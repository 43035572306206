import { ImageFullScreen } from 'ds/components/ImageFullscreen/ImageFullScreen.component';
import { Portal } from 'ds/components/Portal';
import { zIndex } from 'ds/constants';
import { DSIcons } from 'ds/icons';

import { useViewImagePdf } from './useViewImagePdf';

import { type IViewImagePdfProps } from './ViewImagePdf.types';
import { type LanguageType } from 'ds/types';

import { ImageContainer, NotImageFileContainer } from './ViewImagePdf.styles';

export function ViewImagePdf({
  url,
  fileLabel,
  langKey = 'pt',
  viewImageProps,
  portalId,
  hasZoomControl,
  containerHasBorder,
  showZoomTour,
  ...rest
}: IViewImagePdfProps): JSX.Element {
  const { isOpenedImage, setIsOpenedImage, isFileImage } = useViewImagePdf({
    url
  });

  if (url !== null && !isFileImage && !fileLabel) {
    throw new Error(
      'ViewImagePdf: If you are not using an image, you must pass a fileLabel'
    );
  }

  const noFileMessage: Record<LanguageType, string> = {
    en: 'No receipt attached to expense',
    pt: 'Sem comprovante anexado à despesa',
    es: 'Sin recibo adjunto a la despesa'
  };

  const withFilePdfMessage: Record<LanguageType, string> = {
    en: 'View PDF',
    pt: 'Visualizar PDF',
    es: 'Ver PDF'
  };

  const withFileMessage: Record<LanguageType, string> = {
    en: 'Click to enlarge',
    pt: 'Clique para ampliar',
    es: 'Haga clic para ampliar'
  };

  const IconToShow =
    url === null ? DSIcons.ImagePictureIcon : DSIcons.SearchZoomPlusIcon;

  return (
    <>
      {
        // Se não tem arquivo ou se tem e é imagem, renderiza o container
      }
      {(url === null || (url !== null && isFileImage)) && (
        <ImageContainer
          {...rest}
          $containerHasBorder={containerHasBorder}
          data-testid='view-image-container'
          onClick={() => {
            url !== null && isFileImage && setIsOpenedImage(true);
          }}
          $url={isFileImage ? url : null}
          $isOpenedImage={isOpenedImage}
        >
          <div>
            <IconToShow />
            <span>
              {url === null ? noFileMessage[langKey] : withFileMessage[langKey]}
            </span>
          </div>
        </ImageContainer>
      )}

      {
        // Se tem arquivo e não é imagem, renderiza o container
      }
      {url !== null && !isFileImage && (
        <NotImageFileContainer
          {...rest}
          data-testid='view-image-container'
          onClick={() => {
            window.open(url, '_blank');
          }}
          $containerHasBorder={containerHasBorder}
        >
          <DSIcons.FileIcon />
          <span>{fileLabel}</span>
          <div>
            <DSIcons.PdfLinkIcon />
            <span>{withFilePdfMessage[langKey]}</span>
          </div>
        </NotImageFileContainer>
      )}

      {
        // Se tem imagem, renderiza o componente de visualização
      }
      {url !== null && isFileImage && (
        <>
          {!portalId ? (
            <>
              {isOpenedImage && (
                <ImageFullScreen
                  hasZoomControl={hasZoomControl}
                  style={{
                    zIndex: zIndex.imageViewer
                  }}
                  overlayStyle={{
                    backgroundColor: 'rgba(0,0,0,0.8)'
                  }}
                  onClose={() => {
                    setIsOpenedImage(false);
                  }}
                  closeOnClickOutside
                  url={url}
                />
              )}
            </>
          ) : (
            <Portal containerId={portalId}>
              {isOpenedImage && (
                <ImageFullScreen
                  showZoomTour={showZoomTour}
                  hasZoomControl={hasZoomControl}
                  style={{
                    zIndex: zIndex.imageViewerPortal
                  }}
                  overlayStyle={{
                    backgroundColor: 'rgba(0,0,0,0.8)'
                  }}
                  onClose={() => {
                    setIsOpenedImage(false);
                  }}
                  closeOnClickOutside
                  url={url}
                />
              )}
            </Portal>
          )}
        </>
      )}
    </>
  );
}
