import { type UIEvent, useEffect, useState } from 'react';

import {
  type IUseScrollBottomReach,
  type IUseScrollBottomReachParams
} from './useScrollBottomReach.types';

export function useScrollBottomReach({
  threshold = 250,
  onReachBottom
}: IUseScrollBottomReachParams): IUseScrollBottomReach {
  const [isBottom, setIsBottom] = useState(false);

  function handleScroll(e: UIEvent<HTMLDivElement>): void {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const bottom = scrollTop + clientHeight >= scrollHeight - threshold;

    setIsBottom(bottom);
  }

  useEffect(() => {
    if (isBottom) {
      onReachBottom();
    }
  }, [isBottom, onReachBottom]);

  return { isBottom, handleScroll };
}
