import { tokens } from 'ds';
import styled from 'styled-components';

import { Container as AnalysisHeaderContainer } from './components/AnalysisHeader/AnalysisHeader.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingLg};
  padding: ${tokens.spacingXl} ${tokens.spacingLg};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: ${tokens.spacingLg} ${tokens.spacingXs};
  }
`;

export const Content = styled.div`
  display: grid;
  gap: ${tokens.spacingXs} ${tokens.spacingMd};
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media screen and (min-width: 834px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  ${AnalysisHeaderContainer} {
    &:not(:first-of-type) {
      padding-top: ${tokens.spacingXs};
    }
  }
`;
