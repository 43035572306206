enum StatementMutationKeys {
  'GENERATE_STATEMENT_PDF' = 'GENERATE_STATEMENT_PDF',
  'GENERATE_STATEMENT_EXCEL' = 'GENERATE_STATEMENT_EXCEL'
}

enum StatementQueryKeys {
  'GET_COMPANY_STATEMENT' = 'GET_COMPANY_STATEMENT',
  'GET_ACCOUNT_STATEMENT' = 'GET_ACCOUNT_STATEMENT',
  'GET_TRANSACTIONS_LIST' = 'GET_TRANSACTIONS_LIST'
}

export { StatementMutationKeys, StatementQueryKeys };
