import { Route } from 'react-router-dom';

import { ReportsList } from 'presentation/pages/reports';

import { ReportsRoutes } from 'shared/constants/global';
import { AuthenticationMiddleware } from 'shared/middlewares';
import { ReportsAuthMiddleware } from 'shared/middlewares/ReportsAuth/ReportsAuth.middleware';

export const reportsRoutes = [
  <Route
    key='reports'
    element={<AuthenticationMiddleware />}
  >
    <Route element={<ReportsAuthMiddleware />}>
      <Route
        path={ReportsRoutes.REPORTS_REPORT}
        // TODO : Mudar para a página de show de show
        element={<ReportsList />}
      />
    </Route>
    ,
    <Route element={<ReportsAuthMiddleware />}>
      <Route
        path={ReportsRoutes.REPORTS}
        element={<ReportsList />}
      />
    </Route>
  </Route>
];
