import * as Checkbox from '@radix-ui/react-checkbox';
import styled, { css } from 'styled-components';

import { type CustomLabelPropsType } from './DsCheckbox.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

export const Label = styled.label<AddPrefixToType<CustomLabelPropsType>>`
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-size: ${({ theme }) => theme.tokens.fontSizeSm};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  color: ${({ color }) => color};

  &:hover {
    cursor: pointer;

    ${({ $disabled }) =>
      $disabled &&
      css`
        cursor: default;
      `};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `};
`;

export const StyledCheckbox = styled(Checkbox.Root)`
  all: unset;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: ${({ theme }) => theme.tokens.borderRadiusNano};
  border: solid 1.8px ${({ theme }) => theme.colors.primary};

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:not(:disabled):hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.tokens.neutralColorLowLight};
    transition: all 0.3s;
  }

  &[data-state='checked'] {
    background-color: ${({ theme }) => theme.tokens.brandColorPrimaryPure};
    border-color: transparent;

    &:disabled {
      opacity: 0.5;
    }

    &:not(:disabled):hover {
      background-color: ${({ theme }) => theme.tokens.brandColorPrimaryDark};
    }
  }
`;

export const StyledIndicator = styled(Checkbox.Indicator)`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.tokens.neutralColorHighPure};
    width: 2rem;
    height: 2rem;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  word-break: break-all;
  margin-top: 0.5rem;
  margin-left: 0.3rem;
`;
