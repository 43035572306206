import { useQueryCache } from 'data/cache';
import { AccountQueryKeys } from 'data/modules/cards/account/keys/account.keys';
import AccountService from 'data/modules/cards/account/services/Account.service';
import {
  type IUseGetAccounts,
  type IUseGetAccountsParams
} from 'data/modules/cards/account/types/accounts.types';

export function useGetAccounts({
  enabled = true,
  ...params
}: IUseGetAccountsParams): IUseGetAccounts {
  const { data, isFetching } = useQueryCache({
    queryKey: [AccountQueryKeys.GET_ACCOUNTS, params],
    queryFn: async () => await AccountService.getAccounts(params),
    enabled
  });

  return {
    paginatedAccounts: data,
    isFetchingAccounts: isFetching
  };
}
