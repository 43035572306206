import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useLangContext } from 'data/contexts';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  useApproveLegalRepresentative,
  useGetDataToRepresentativeApproval
} from 'data/modules/cards/companyOnboarding';
import { useGetLegalRepresentativeToApproval } from 'data/modules/cards/companyOnboarding/useCases/get-legal-representative-to-approval/useGetLegalRepresentativeToApproval';

import { useHandleQueryCache } from 'shared/hooks/global';
import { JsxHelper, Lang } from 'shared/utils/global';

import { type IUseRepresentativePublicApproval } from './RepresentativePublicApproval.types';

export function useRepresentativePublicApproval(): IUseRepresentativePublicApproval {
  const [currentLangKey, representativePublicApproval] = useLangContext(
    state => [
      state.currentLangKey,
      state.lang.cards.onboarding.company.representative_public_approval
    ]
  );

  const [urlParams] = useSearchParams();
  const hashCode = urlParams.get('hash_code') ?? '';
  const fallbackName = 'Sem nome';

  const {
    dataToRepresentativeApproval,
    isLoadingDataToRepresentativeApproval,
    isErrorRepresentativeApproval,
    representativeApprovalError
  } = useGetDataToRepresentativeApproval({
    hashCode
  });

  useEffect(() => {
    if (isErrorRepresentativeApproval) {
      toast.error(representativeApprovalError?.message);
    }
  }, [representativeApprovalError, isErrorRepresentativeApproval]);

  const {
    representativeToApproval,
    isLoadingRepresentativeToApproval,
    isErrorRepresentativeToApproval,
    errorRepresentativeToApproval
  } = useGetLegalRepresentativeToApproval({
    hashCode
  });

  useEffect(() => {
    if (isErrorRepresentativeToApproval) {
      toast.error(errorRepresentativeToApproval?.message);
    }
  }, [errorRepresentativeToApproval, isErrorRepresentativeToApproval]);

  const { isApprovingLegalRepresentative, approveLegalRepresentative } =
    useApproveLegalRepresentative({
      hashCode,
      onApproveLegalRepresentativeMutationSuccess:
        updateApproveLegalRepresentativeCache
    });

  const { updateCache } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_LEGAL_REPRESENTATIVE_TO_APPROVAL,
        hashCode
      ],
      query: async () =>
        await CompanyOnboardingService.getLegalRepresentativeToApproval({
          hashCode
        })
    },
    params: { hashCode }
  });

  const isAuthorized = representativeToApproval?.isAuthorized ?? null;

  function handleApprove(): void {
    if (isApprovingLegalRepresentative) return;

    approveLegalRepresentative(true);
  }

  function handleDisapprove(): void {
    if (isApprovingLegalRepresentative) return;

    approveLegalRepresentative(false);
  }

  function updateApproveLegalRepresentativeCache(approve: boolean): void {
    updateCache({
      key: 'isAuthorized',
      newData: approve
    });
  }

  const currentContent =
    representativePublicApproval[
      isAuthorized
        ? 'approved'
        : isAuthorized === false
          ? 'disapproved'
          : 'pending'
    ];

  const content = {
    title: currentContent.title[currentLangKey],
    first: JsxHelper.putHtmlTag(
      Lang.putValuesInString(currentContent.first[currentLangKey], {
        representative:
          dataToRepresentativeApproval?.answerableName ?? fallbackName,
        approver:
          dataToRepresentativeApproval?.legalRepresentativeName ?? fallbackName
      })
    ),
    second: JsxHelper.putHtmlTag(
      Lang.putValuesInString(currentContent.second[currentLangKey], {
        representative:
          dataToRepresentativeApproval?.answerableName ?? fallbackName
      })
    )
  };

  return {
    content,
    isAuthorized,
    handleApprove,
    handleDisapprove,
    isLoadingRepresentativeApproval:
      isLoadingDataToRepresentativeApproval || isLoadingRepresentativeToApproval
  };
}
