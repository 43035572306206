import styled from 'styled-components';

import { IconButton } from 'presentation/components/base/Button';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.emperor};
  font-size: 1.8rem;
  font-weight: 300;
`;

export const CloseButton = styled(IconButton)`
  align-self: self-start;
  color: ${({ theme }) => theme.colors.alto};

  &:hover {
    color: ${({ theme }) => theme.colors.neutral};
  }
`;

export const Description = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.emperor};
  margin-bottom: 1.5rem;
  padding: 0 1.6rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Footer = styled.div`
  padding: 1rem 1.5rem;
  width: 100%;
  min-height: 5.5rem;
  background: ${({ theme }) => theme.colors.alabaster};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  > span {
    font-weight: 300;
    color: ${({ theme }) => theme.colors.emperor};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;

  > button,
  > a {
    width: auto;
  }
`;
