import { useIsFetching, useQueryClient } from '@tanstack/react-query';

import { useQueryCache } from 'data/cache';
import {
  FuelQueryKeys,
  FuelService,
  type IGetVehiclePayload,
  type IUseGetVehicle,
  type IUseGetVehicleParams,
  type IVehicle
} from 'data/modules/fuel';

export function useGetVehicle(params?: IUseGetVehicleParams): IUseGetVehicle {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQueryCache({
    queryKey: [FuelQueryKeys.GET_VEHICLE, params?.vehicleId as number],
    queryFn: async () =>
      await FuelService.getVehicle({ vehicleId: params?.vehicleId as number }),
    enabled:
      (params?.enabled === undefined || params.enabled) &&
      params?.vehicleId !== undefined
  });

  async function getVehicle({
    vehicleId
  }: IGetVehiclePayload): Promise<IVehicle> {
    const data = await queryClient.ensureQueryData({
      queryKey: [FuelQueryKeys.GET_VEHICLE, vehicleId],
      queryFn: async () => await FuelService.getVehicle({ vehicleId })
    });

    return data;
  }

  const isFetchingAndPendingVehicle =
    useIsFetching({
      predicate: query => {
        return (
          query.queryKey[0] === FuelQueryKeys.GET_VEHICLE &&
          query.state.status === 'pending'
        );
      }
    }) > 0;

  return {
    vehicle: data,
    isLoadingVehicle: isLoading,
    getVehicle,
    isFetchingAndPendingVehicle
  };
}
