import { Fragment } from 'react';

import { DSIcons, LabelUnityValue, TextButton } from 'ds';
import { RouteInput } from 'ds/components/Input/RouteInput';

import { useLangContext } from 'data/contexts';

import { MockApi } from 'shared/utils/global';

import { type IRecordRouteStepProps } from './RecordRouteStep.types';

import {
  AddPlaceButtonContainer,
  RouteContainer
} from './RecordRouteStep.styles';

export function RecordRouteStep({
  isMapLoaded,
  distance,
  items,
  setItems
}: IRecordRouteStepProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  return (
    <>
      <LabelUnityValue
        label={lang.global.distance[currentLangKey]}
        unity='KM'
        value={distance}
      />

      <RouteContainer>
        <RouteInput.Container
          items={items}
          setItems={setItems}
        >
          <div className='route-input-items-container'>
            {isMapLoaded &&
              items.map((item, index) => {
                return (
                  <Fragment key={item.id}>
                    <RouteInput.Input
                      small
                      label={item.label}
                      id={item.id}
                      onChangeValue={value => {
                        setItems(items =>
                          items.map(itemMap => {
                            if (itemMap.id === item.id) {
                              return {
                                ...itemMap,
                                place: value
                              };
                            }

                            return itemMap;
                          })
                        );
                      }}
                      itemFormattedAddress={item.place?.formatted_address}
                      placeholder={item.label}
                      isFinalPoint={items.length - 1 === index}
                      showRemoveIcon={items.length > 2}
                      onRemoveItem={() => {
                        setItems(oldItems =>
                          oldItems.filter(
                            filterItem => filterItem.id !== item.id
                          )
                        );
                      }}
                    />

                    <br />
                    {index + 1 !== items.length && <DSIcons.DotsVerticalIcon />}
                  </Fragment>
                );
              })}
          </div>

          <AddPlaceButtonContainer
            className='add-place-button-container'
            onClick={() => {
              setItems(oldItems => [
                ...oldItems,
                {
                  id: MockApi.fakeUuid(),
                  label:
                    lang.expenses.modal.create_route_by_map.add_new_place[
                      currentLangKey
                    ],
                  place: null
                }
              ]);
            }}
          >
            <DSIcons.AddCircleIcon />
            <TextButton
              size='small'
              type='button'
            >
              {
                lang.expenses.modal.create_route_by_map.add_new_place[
                  currentLangKey
                ]
              }
            </TextButton>
          </AddPlaceButtonContainer>
        </RouteInput.Container>
      </RouteContainer>
    </>
  );
}
