import { useDsGa4 } from 'ds/hooks/globals';
import { DSIcons } from 'ds/icons';

import { usePaginationController } from './usePaginationController';

import { type IPaginationControllerProps } from './PaginationController.types';

import {
  Container,
  CurrentPage,
  NumberPage
} from './PaginationController.styles';

export function PaginationController({
  onChangePage,
  ...rest
}: IPaginationControllerProps): JSX.Element {
  const { currentPage, paginationItems, totalPage } = usePaginationController();

  const { sendDsGaEvent } = useDsGa4();

  return (
    <Container {...rest}>
      {currentPage > 1 && (
        <DSIcons.ArrowLeftIcon
          onClick={() => {
            onChangePage(currentPage - 1);

            sendDsGaEvent('components', 'pagination', {
              eventLabel: 'previous_pagination',
              description: 'click no anterior'
            });
          }}
        />
      )}

      {paginationItems.map(page => {
        return currentPage === page ? (
          <CurrentPage key={`numberPageItem-${page}`}>{page}</CurrentPage>
        ) : (
          <NumberPage
            key={`numberPageItem-${page}`}
            onClick={() => {
              if (page > 0) onChangePage(page);
            }}
          >
            {page < 0 ? '...' : page}
          </NumberPage>
        );
      })}

      {currentPage !== totalPage && (
        <DSIcons.ArrowRightIcon
          onClick={() => {
            onChangePage(currentPage + 1);

            sendDsGaEvent('components', 'pagination', {
              eventLabel: 'next_pagination',
              description: 'click no proximo'
            });
          }}
        />
      )}
    </Container>
  );
}
