import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  flex-grow: 1;
  align-self: flex-start;
  margin-bottom: 5rem;

  &::before {
    content: '';
    position: absolute;
    left: -1rem;
    top: 1.6rem;
    z-index: 2;
    width: 2.2rem;
    height: 1.733rem;
    background-color: ${tokens.brandColorPrimaryLightest};
    clip-path: polygon(70% 2%, 2% 70%, 100% 100%);
    border-radius: 0 0 8px;
    transform: rotate(135deg);
  }

  > div {
    padding: ${tokens.spacingNano} ${tokens.spacingXs};
    background: ${tokens.brandColorPrimaryLightest};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
`;
