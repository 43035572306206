import { type FC, type ReactNode } from 'react';

import { Types } from 'ds';
import { SkeletonLoader } from 'ds/components/SkeletonLoader/SkeletonLoader.component';
import { useDsGa4 } from 'ds/hooks/globals';
import { DSIcons } from 'ds/icons';
import {
  type ActionMeta,
  components,
  type DropdownIndicatorProps,
  type GroupBase,
  type GroupProps,
  type InputActionMeta,
  type MenuProps
} from 'react-select';
import { type MenuPortalProps } from 'react-select/dist/declarations/src/components/Menu';
import { type FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { useSelectDefault } from './useSelectDefault';

import {
  type ISelectDefaultGroupedOption,
  type ISelectDefaultOption,
  type ISelectDefaultProps,
  type OnChangeSelectDefaultType
} from './SelectDefault.types';

import {
  Caption,
  Container,
  Fieldset,
  GroupContainer,
  Label,
  Legend,
  MenuInformation,
  MenuPortalContainer,
  StyledSelect,
  Wrapper
} from './SelectDefault.styles';

const Menu: FC<MenuProps & { information?: string }> = ({
  children,
  information,
  ...props
}): ReactNode => {
  return (
    <components.Menu {...props}>
      {Boolean(information) && <MenuInformation>{information}</MenuInformation>}
      {children}
    </components.Menu>
  );
};

export function SelectDefault({
  name,
  options,
  value,
  error,
  disabled,
  className,
  placeholder,
  information,
  noOptionsMessage,
  onChange,
  onInputChange,
  hasArrowDownIndicator = false,
  isLoading = false,
  label,
  large = false,
  small = false,
  menuPlacement = 'bottom',
  usePortal,
  portalZIndex = 9999,
  closeMenuOnSelect = true,
  portalContainerClassName
}: ISelectDefaultProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  const { searchValue, setSearchValue } = useSelectDefault();

  const DropdownIndicator: FC<DropdownIndicatorProps> = (props): ReactNode => {
    return (
      <components.DropdownIndicator {...props}>
        <DSIcons.ChevronIcon />
      </components.DropdownIndicator>
    );
  };

  const Group: FC<GroupProps> = (props): ReactNode => {
    const isCustomDisabled = (props.data as ISelectDefaultGroupedOption)
      .disabled;

    return (
      <GroupContainer $disabled={isCustomDisabled}>
        <components.Group {...props} />
      </GroupContainer>
    );
  };

  const MenuPortal: FC<
    MenuPortalProps<unknown, boolean, GroupBase<unknown>>
  > = (props): ReactNode => {
    return (
      <MenuPortalContainer
        $disabled={disabled}
        $large={large}
        $small={small}
        className={portalContainerClassName}
        $hasInformation={Boolean(information)}
        {...props}
      >
        {props.children}
      </MenuPortalContainer>
    );
  };

  const componentToRender = (
    value: string,
    onChange: OnChangeSelectDefaultType
  ): JSX.Element => (
    <div>
      <Wrapper
        $large={large}
        $small={small}
      >
        <StyledSelect
          $large={large}
          isClearable
          $error={error}
          styles={{ menuPortal: base => ({ ...base, zIndex: portalZIndex }) }}
          $small={small}
          $isTouched={!!value && !disabled}
          $disabled={disabled}
          $hasInformation={Boolean(information)}
          components={{
            DropdownIndicator,
            Group,
            MenuPortal,
            Menu: props => (
              <Menu
                {...props}
                information={information}
              />
            )
          }}
          name={name}
          classNamePrefix={'react-select-custom'}
          isSearchable
          placeholder={!disabled ? placeholder ?? 'Selecione...' : placeholder}
          menuPlacement={menuPlacement}
          isDisabled={disabled}
          options={options}
          className={className}
          isOptionSelected={(option: unknown) => {
            const typedOption = option as ISelectDefaultOption;
            const typedValue = value;
            return typedValue === typedOption.value;
          }}
          inputValue={searchValue}
          onInputChange={(value: string, action: InputActionMeta) => {
            if (action?.action !== 'menu-close') {
              const inputValue = onInputChange?.(value) ?? value;

              setSearchValue(inputValue);

              sendDsGaEvent('components', 'dropdown', {
                description: 'Digitar texto',
                eventAction: 'text_dropdown',
                eventLabel: label ?? 'text_dropdown',
                eventValue: value
              });
            }
          }}
          filterOption={(option: FilterOptionOption<unknown>, input: string) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          value={
            options
              .map(option =>
                Types.isType<ISelectDefaultGroupedOption, typeof option>(
                  option,
                  'options'
                )
                  ? option.options
                  : [option]
              )
              .flat()
              .find(option => option.value === value) ?? null
          }
          $hasArrowDownIndicator={hasArrowDownIndicator}
          onChange={(newValue: unknown, action: ActionMeta<unknown>) => {
            if (action.action === 'clear') {
              onChange('');
              return;
            }

            const typedNewValue = newValue as ISelectDefaultOption;
            onChange(typedNewValue?.value ? typedNewValue.value : null);
            typedNewValue?.value &&
              sendDsGaEvent('components', 'dropdown', {
                description: 'Click nas opções',
                eventAction: 'select_dropdown',
                eventLabel: label ?? 'dropdown_without_label',
                eventValue: typedNewValue.value
              });
          }}
          closeMenuOnSelect={closeMenuOnSelect}
          noOptionsMessage={() => noOptionsMessage ?? 'Sem mais opções'}
          onMenuOpen={() => {
            sendDsGaEvent('components', 'dropdown', {
              description: 'Abrir a lista de opções',
              eventAction: 'list_dropdown',
              eventLabel: 'open_list_dropdown'
            });
          }}
          onMenuClose={() => {
            sendDsGaEvent('components', 'dropdown', {
              description: 'Fechar lista de opções',
              eventAction: 'list_dropdown',
              eventLabel: 'close_list_dropdown'
            });
          }}
          menuPortalTarget={usePortal ? document.body : null}
        />

        <Fieldset>
          <Legend>
            <span>{label}</span>
          </Legend>
        </Fieldset>

        {label && <Label>{label}</Label>}
      </Wrapper>

      {error && <Caption $error={error}>{error}</Caption>}
    </div>
  );

  return (
    <Container>
      {isLoading ? (
        <SkeletonLoader height='3rem' />
      ) : (
        componentToRender(value as string, onChange)
      )}
    </Container>
  );
}
