import { useEffect, useState } from 'react';

import { toast } from 'ds/utils';
import { useNavigate } from 'react-router-dom';

import { useLangContext, useModalContext } from 'data/contexts';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  useDeleteLegalRepresentative,
  useGetLegalRepresentatives,
  useIncrementCompanyStep,
  useResendLegalRepresentativesApprovalSolicitation
} from 'data/modules/cards/companyOnboarding';

import { Badge } from 'presentation/components/base/Badge';
import { BaseIcon } from 'presentation/components/base/Icon';
import { DsGhostButton } from 'presentation/ds/DsButton';

import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import { type IUseApprovalRepresentatives } from './ApprovalRepresentatives.types';

export function useApprovalRepresentatives(): IUseApprovalRepresentatives {
  const navigate = useNavigate();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const { handleOpenModal, handleCloseModal } = useModalContext();
  const [selectedApprovalRepresentative, setSelectedApprovalRepresentative] =
    useState<string>();

  const {
    representatives,
    isLoadingRepresentatives,
    isErrorLegalRepresentatives,
    errorLegalRepresentatives
  } = useGetLegalRepresentatives();

  useEffect(() => {
    if (isErrorLegalRepresentatives) {
      toast.error(errorLegalRepresentatives?.message as string);
    }
  }, [errorLegalRepresentatives?.message, isErrorLegalRepresentatives]);

  const { handleDeleteLegalRepresentative } = useDeleteLegalRepresentative({
    onDeleteLegalRepresentativeMutationSuccess: resetStateAndCloseModal
  });

  const { handleResendLegalRepresentativesApprovalSolicitation } =
    useResendLegalRepresentativesApprovalSolicitation();

  const [currentLangKey, approvalRepresentatives] = useLangContext(state => [
    state.currentLangKey,
    state.lang.cards.onboarding.company.approval_representatives
      .approval_representatives
  ]);

  function handleAddRepresentative(): void {
    navigate(`/cartoes/onboarding/empresa/${currentStep}/representantes`);
  }

  function handleClickDeleteLegalRepresentative(
    representativeId: string
  ): void {
    setSelectedApprovalRepresentative(representativeId);
    handleOpenModal('confirmation');
  }

  function resetStateAndCloseModal(): void {
    setSelectedApprovalRepresentative(undefined);
    handleCloseModal();
  }

  function handleDeleteSelectedLegalRepresentative(): void {
    handleDeleteLegalRepresentative(selectedApprovalRepresentative ?? '');
  }

  function badgeToRender(isAuthorized: boolean | null): JSX.Element {
    return (
      <Badge
        size='sm'
        color={
          isAuthorized === true
            ? 'success'
            : isAuthorized === false
              ? 'danger'
              : 'warning'
        }
        label={
          isAuthorized === true
            ? 'Autorizado'
            : isAuthorized === false
              ? 'Reprovado'
              : 'Pendente'
        }
      />
    );
  }

  const approvalRepresentativeTableHeader = [
    {
      key: 'responsible',
      label: approvalRepresentatives.responsible[currentLangKey]
    },
    {
      key: 'email',
      label: 'Email'
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'buttons',
      label: ''
    }
  ];

  const approvalRepresentativeTableData =
    representatives?.map(representative => ({
      id: representative?.id ?? '',
      responsible: (
        <>
          <BaseIcon
            size={2.4}
            type='line'
            name='user-3'
            color='secondary'
          />
          <strong>{representative.name}</strong>
        </>
      ),
      email: <p>{representative.email}</p>,
      status: badgeToRender(representative?.isAuthorized ?? null),
      buttons: (
        <>
          <DsGhostButton
            onClick={() => {
              representative?.id &&
                handleClickDeleteLegalRepresentative(representative?.id);
            }}
            iconProps={{
              size: 2.4,
              name: 'delete-bin',
              type: 'line',
              color: 'secondary'
            }}
          />

          <DsGhostButton
            onClick={() => {
              representative?.id &&
                handleResendLegalRepresentativesApprovalSolicitation(
                  representative?.id
                );
            }}
            iconProps={{
              size: 2.4,
              name: 'mail-send',
              type: 'line',
              color: 'secondary'
            }}
          />
        </>
      )
    })) ?? [];

  const isAuthorized = Boolean(
    representatives?.every(
      representative => representative.isAuthorized === true
    )
  );

  const { incrementStep } = useIncrementCompanyStep();

  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  function handleContinue(): void {
    incrementStep({
      step: currentStep,
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    });
  }

  return {
    handleAddRepresentative,
    isLoadingRepresentatives,
    approvalRepresentativeTableData,
    approvalRepresentativeTableHeader,
    handleDeleteSelectedLegalRepresentative,
    isAuthorized,
    handleContinue
  };
}
