import { useLangContext } from 'data/contexts';

import { ReversalIcon } from 'presentation/components/base/Icon';
import { Tooltip } from 'presentation/components/base/Tooltip';

import { type IExpenseStatusAlertProps } from './ExpenseStatusAlert.types';

import { Container } from './ExpenseStatusAlert.styles';

export function ExpenseStatusAlert({
  status
}: IExpenseStatusAlertProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);
  const tooltipContent = {
    SENT: lang.cards.statement.transaction_details_modal.expense_sent_tooltip[
      currentLangKey
    ],
    UNREPORTED:
      lang.cards.statement.transaction_details_modal.expense_open_tooltip[
        currentLangKey
      ],
    OPEN: lang.cards.statement.transaction_details_modal.expense_open_tooltip[
      currentLangKey
    ],
    REOPENED:
      lang.cards.statement.transaction_details_modal.expense_open_tooltip[
        currentLangKey
      ]
  };

  return (
    <Container>
      <Tooltip
        id='expenseStatusAlert'
        maxWidth={20}
        content={tooltipContent[status]}
      >
        <ReversalIcon
          backgroundColor='tulipTree'
          iconProps={{
            name: 'alert',
            color: 'white',
            size: 1
          }}
        />
      </Tooltip>
    </Container>
  );
}
