import { useQueryCache } from 'data/cache';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUseGetLegalRepresentatives
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';

export function useGetLegalRepresentatives(): IUseGetLegalRepresentatives {
  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const {
    data: representatives,
    isInitialLoading: isLoadingRepresentatives,
    isError: isErrorLegalRepresentatives,
    error: errorLegalRepresentatives
  } = useQueryCache({
    queryKey: [
      CompanyOnboardingQueryKeys.GET_LEGAL_REPRESENTATIVES,
      cachedCompanyOnboardingProgress?.onboardingCompanyId
    ],
    queryFn: async () =>
      await CompanyOnboardingService.getLegalRepresentatives({
        uuidCompanyOnboarding:
          cachedCompanyOnboardingProgress?.onboardingCompanyId as string
      }),
    enabled: !!cachedCompanyOnboardingProgress
  });

  return {
    representatives,
    isLoadingRepresentatives,
    isErrorLegalRepresentatives,
    errorLegalRepresentatives
  };
}
