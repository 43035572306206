import { useQueryCache } from 'data/cache';
import {
  FuelQueryKeys,
  FuelService,
  type IUseGetFuelPolicies
} from 'data/modules/fuel';

export function useGetFuelPolicies(): IUseGetFuelPolicies {
  const { data, isLoading } = useQueryCache({
    queryKey: [FuelQueryKeys.GET_FUEL_POLICIES],
    queryFn: async () => await FuelService.getFuelPolicies()
  });

  return {
    fuelPolicies: data,
    isLoadingFuelPolicies: isLoading
  };
}
