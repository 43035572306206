import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import {
  OnboardingFooter,
  OnboardingText,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { Onboarding } from 'shared/utils/cards';

import { Container, Content, Warning } from './RepresentativePermission.styles';

export function RepresentativePermission(): JSX.Element {
  const [currentLangKey, onboarding] = useLangContext(state => [
    state.currentLangKey,
    state.lang.cards.onboarding
  ]);
  const navigate = useNavigate();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  return (
    <Container>
      <Content>
        <OnboardingTitle>
          {onboarding.company.representative_permission.title[currentLangKey]}
        </OnboardingTitle>
        <OnboardingText>
          {
            onboarding.company.representative_permission.description[
              currentLangKey
            ]
          }
        </OnboardingText>
        <Warning>
          <BaseIcon
            size={4}
            type='line'
            name='alert'
            color='tango'
          />
          <p>
            <strong>
              {
                onboarding.company.representative_permission.warning[
                  currentLangKey
                ]
              }
            </strong>
          </p>
        </Warning>
      </Content>
      <OnboardingFooter
        continueButtonProps={{
          onClick: () => {
            navigate(
              `/cartoes/onboarding/empresa/${currentStep}/representantes`
            );
          }
        }}
        backButtonProps={{
          onClick: () => {
            navigate(`/cartoes/onboarding/empresa/${currentStep}`);
          }
        }}
      />
    </Container>
  );
}
