import { useModalContext } from 'data/contexts';

import {
  MovementsTableBody,
  MovementsTableFilters,
  TransactionDetailsModal
} from '../';

import { Container } from './MovementsTable.styles';

export function MovementsTable(): JSX.Element {
  const { visibleModal } = useModalContext();

  return (
    <Container>
      <MovementsTableFilters />

      <MovementsTableBody />

      {visibleModal === 'transactionDetails' && <TransactionDetailsModal />}
    </Container>
  );
}
