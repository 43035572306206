import { useAuthContext, useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { Dropdown } from 'presentation/components/base/OldDropdown';

import { useNotifications } from './useNotifications';

import {
  DropdownContainer,
  List,
  Notification,
  NotificationContainer,
  NotificationCounter
} from './Notifications.styles';

export function Notifications(): JSX.Element {
  const user = useAuthContext(state => state.user);
  const { notifications } = useNotifications();
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  /**
   * O componente não é renderizado enquanto não tiver os dados do user
   */
  if (!user) return <></>;

  /**
   * Monta conteúdo que será exibido no dropdown
   */
  const notificationsList = (
    <List>
      {/* Sem notificações */}
      {notifications.length === 0 ? (
        <li>
          <button type='button'>
            {lang.navbar.components.no_notifications_found[currentLangKey]}
          </button>
        </li>
      ) : (
        <>
          {/* Lista de notificações */}
          {notifications.map(notification => (
            <li key={notification.message}>
              <button type='button'>
                <Notification $svgColor={notification.color}>
                  <BaseIcon
                    name={notification.icon}
                    size={4}
                  />

                  {notification.message}
                </Notification>
              </button>
            </li>
          ))}
        </>
      )}
    </List>
  );

  return (
    <Dropdown
      direction='left'
      content={notificationsList}
      padding={0.7}
    >
      {/* Componente que abre/fecha o dropdown (Trigger) */}
      <DropdownContainer className='old-notifications-dropdown'>
        <NotificationContainer data-testid='notifications'>
          <BaseIcon
            name='bell'
            color='primary'
          />

          {notifications.length > 0 && (
            <NotificationCounter data-testid='notification-counter'>
              {notifications.length}
            </NotificationCounter>
          )}
        </NotificationContainer>
      </DropdownContainer>
    </Dropdown>
  );
}
