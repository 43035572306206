import { useAuthContext, useLangContext, useModalContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { Spinner } from 'presentation/components/base/Spinner';
import { GenericTable } from 'presentation/components/global/Table';
import {
  OnboardingFooter,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { DeletePartnerModal } from '..';

import { usePartnersList } from './usePartnersList';

import {
  Container,
  CustomLoadingOverlay,
  EmptyPartnersTable,
  PartnersTableContainer,
  TitleContainer
} from './PartnersList.styles';

export function PartnersList(): JSX.Element {
  const {
    handleAddPartner,
    partnersTableData,
    partnersTableHeader,
    handleCloseDeleteModal,
    isPartnersTableDataLoading,
    handleSaveShareholdersClick
  } = usePartnersList();

  const { visibleModal } = useModalContext();

  const company = useAuthContext(state => state.company);

  const [currentLangKey, global, addPartner] = useLangContext(state => [
    state.currentLangKey,
    state.lang.global,
    state.lang.cards.onboarding.company.add_partner
  ]);

  return (
    <Container>
      <TitleContainer>
        <BaseIcon
          name='building-business-office'
          color='primary'
          size={2.4}
        />
        <OnboardingTitle>{company?.name as string}</OnboardingTitle>
      </TitleContainer>

      {isPartnersTableDataLoading && (
        <CustomLoadingOverlay>
          <Spinner
            color='royalBlue'
            size={4}
          />
        </CustomLoadingOverlay>
      )}

      <PartnersTableContainer
        $showHeaderOnMobile={!!partnersTableHeader.length}
      >
        <GenericTable
          thead={
            <tr>
              {partnersTableHeader.map(item => (
                <th key={item.key}>{item.label}</th>
              ))}
            </tr>
          }
          tbody={
            partnersTableData.length === 0 ? (
              <EmptyPartnersTable>
                <td colSpan={partnersTableHeader.length}>
                  {addPartner.partners_list.table.empty[currentLangKey]}
                </td>
              </EmptyPartnersTable>
            ) : (
              partnersTableData.map(item => (
                <tr key={String(item.id)}>
                  {partnersTableHeader.map(headerItem => (
                    <td key={String(item.id) + headerItem.key}>
                      <span>{headerItem.label}</span>
                      <span>{item[headerItem.key] as React.ReactNode}</span>
                    </td>
                  ))}
                </tr>
              ))
            )
          }
        />
      </PartnersTableContainer>

      <OnboardingFooter
        continueButtonProps={
          partnersTableData.length
            ? {
                text: global.continue[currentLangKey],
                onClick: handleSaveShareholdersClick
              }
            : undefined
        }
        addButtonProps={{
          onClick: handleAddPartner
        }}
      />

      <DeletePartnerModal
        isOpen={visibleModal === 'confirmation'}
        onClose={handleCloseDeleteModal}
      />
    </Container>
  );
}
