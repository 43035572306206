import type {
  IChangeExpenseTypePayload,
  IChangeExpenseTypePayloadPersistence
} from 'data/modules/fuel/types/fuel.types';

class ChangeExpenseTypeMapper {
  toPersistence(
    payload: IChangeExpenseTypePayload
  ): IChangeExpenseTypePayloadPersistence {
    return {
      new_type: payload.newType
    };
  }
}

export default new ChangeExpenseTypeMapper();
