import { useTranslation } from 'react-i18next';

import { vexDomain } from 'data/config';

import { AnalysisItem } from 'presentation/pages/analytics/Analysis/components/AnalysisItem';
import { ConversionNotice } from 'presentation/pages/analytics/Analysis/components/ConversionNotice';
import { UserAnalysisExpensesTable } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisExpensesTable';
import { UserAnalysisReportHeader } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisReportHeader';

import { type IUserAnalysisItemProps } from './UserAnalysisItem.types';

import { ItemSection, TableHeader } from './UserAnalysisItem.styles';

export function UserAnalysisItem({
  id,
  name,
  cards,
  reports,
  positionDepartment,
  hasPendingConversion
}: IUserAnalysisItemProps): JSX.Element {
  const { t } = useTranslation(['global', 'analytics']);

  const standaloneExpenses = reports?.['']?.expenses;

  return (
    <AnalysisItem.Container key={id}>
      <AnalysisItem.SectionName
        name={name}
        positionDepartment={positionDepartment}
      />
      <AnalysisItem.Cards cards={cards} />

      {hasPendingConversion && (
        <ConversionNotice
          actionText={t('analytics:userSummaryCard.convertCurrencies')}
          onActionClick={() => {
            window.location.href = `${vexDomain}/admin/moeda-estrangeira`;
          }}
        >
          {t('analytics:userSummaryCard.conversionNotice')}
        </ConversionNotice>
      )}

      {standaloneExpenses && (
        <ItemSection>
          <TableHeader>
            {t('analytics:userResume.standaloneExpenses')}
          </TableHeader>

          {<UserAnalysisExpensesTable expenses={standaloneExpenses} />}
        </ItemSection>
      )}

      {reports &&
        Object.entries(reports)
          .filter(([reportId]) => reportId !== '')
          .map(([reportId, { expenses, ...reportResume }]) => (
            <ItemSection key={reportId}>
              {reportResume && <UserAnalysisReportHeader {...reportResume} />}

              {expenses && <UserAnalysisExpensesTable expenses={expenses} />}
            </ItemSection>
          ))}
    </AnalysisItem.Container>
  );
}
