import { useQueryCache } from 'data/cache';
import {
  type IUseGetPreRegisteredSections,
  PreRegisteredSectionsQueryKeys,
  PreRegisteredSectionsService
} from 'data/modules/preRegisteredSections';

export function useGetPreRegisteredSections(): IUseGetPreRegisteredSections {
  const { data, isLoading } = useQueryCache({
    queryKey: [PreRegisteredSectionsQueryKeys.GET_PRE_REGISTERED_SECTIONS],
    queryFn: async () =>
      await PreRegisteredSectionsService.getPreRegisteredSections()
  });

  return {
    preRegisteredSections: data ?? [],
    isLoadingPreRegisteredSections: isLoading
  };
}
