import { createContext, type ReactElement, useContext } from 'react';

import { closestCenter, DndContext, type DragEndEvent } from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';

import {
  type IRouteInputContext,
  type IRouteInputProviderProps
} from './RouteInput.types';

const RouteInputContext = createContext<IRouteInputContext | null>(null);

export function RouteInputProvider({
  items,
  setItems,
  children
}: IRouteInputProviderProps): ReactElement {
  function onDragEnd(event: DragEndEvent): void {
    const { active, over } = event;

    if (active.id === over?.id) {
      return;
    }

    setItems(oldItems => {
      const oldIndex = oldItems.findIndex(user => user.id === active.id);
      const newIndex = oldItems.findIndex(user => user.id === over?.id);
      return arrayMove(oldItems, oldIndex, newIndex);
    });
  }

  return (
    <RouteInputContext.Provider
      value={{
        onDragEnd
      }}
    >
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext
          items={items}
          strategy={verticalListSortingStrategy}
        >
          {children}
        </SortableContext>
      </DndContext>
    </RouteInputContext.Provider>
  );
}

export function useRouteInputContext(): IRouteInputContext {
  const RouteInput = useContext(RouteInputContext);

  return RouteInput as IRouteInputContext;
}
