import { type IStatusTextProps } from './StatusText.types';

import { Container } from './StatusText.styles';

export function StatusText({
  status,
  children,
  ...props
}: IStatusTextProps): JSX.Element {
  return (
    <Container
      {...props}
      $status={status}
    >
      {children}
    </Container>
  );
}
