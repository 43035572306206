import { useState } from 'react';

type ToggleFunctionType = () => void;
type SetTrueFunctionType = () => void;
type SetFalseFunctionType = () => void;

/**
 * Hook personalizado para gerenciar um valor booleano com funcionalidades adicionais.
 *
 * @param initialValue - O valor inicial do booleano.
 *
 * @returns Uma tupla contendo:
 * - `value` (boolean): O valor atual do booleano.
 * - `toggle` (ToggleFunctionType): Função para alternar o valor booleano (true para false e vice-versa).
 * - `setTrue` (SetTrueFunctionType): Função para definir o valor como true.
 * - `setFalse` (SetFalseFunctionType): Função para definir o valor como false.
 *
 * @example
 * const [isVisible, toggleVisibility, show, hide] = useBoolean(false);
 *
 * // Para alternar o valor entre true e false:
 * toggleVisibility();
 *
 * // Para definir o valor como true:
 * show();
 *
 * // Para definir o valor como false:
 * hide();
 */
export function useBoolean(
  initialValue: boolean
): [boolean, ToggleFunctionType, SetTrueFunctionType, SetFalseFunctionType] {
  const [value, setValue] = useState(initialValue);

  function toggle(): void {
    setValue(oldValue => !oldValue);
  }

  function setTrue(): void {
    setValue(true);
  }

  function setFalse(): void {
    setValue(false);
  }

  return [value, toggle, setTrue, setFalse];
}
