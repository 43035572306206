import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 2.5rem;
  padding: 0 1.6rem;

  > p {
    color: ${({ theme }) => theme.colors.emperor};
    margin: 1rem 0;
    font-size: 1.2rem;
  }
`;

export const DatePrevisionInputWrapper = styled.div`
  display: flex;

  label {
    font-size: 1.2rem;
    font-weight: 400;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 2rem;

  > div {
    &:first-child {
      width: 12rem;
      flex-grow: 1;
    }

    &:nth-child(2) {
      flex: 1 0 16rem;
    }
  }
`;

export const InformationWrapper = styled.div`
  margin-top: 1rem;
  word-break: break-all;

  span {
    color: ${({ theme }) => theme.colors.mineShaft};
    display: block;
    width: fit-content;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  p {
    width: 100%;
    color: ${({ theme }) => theme.colors.mineShaft};
    font-size: 1.2rem;
    max-width: 40rem;
  }
`;

export const DatePrevisionLabelWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
`;

export const JustifyInputWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
`;

export const IncrementButtons = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0.5rem;

  button {
    height: 3.7rem;
    padding: 0.3rem 0.5rem;
    background: ${({ theme }) => theme.colors.gallery};
    border: 0;
    color: ${({ theme }) => theme.colors.doveGray};
    font-weight: 700;
    white-space: nowrap;

    &:hover {
      background: ${({ theme }) => darken(0.03, theme.colors.gallery)};
    }
  }
`;

export const ModalHeaderOnFormSuccess = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  padding-right: 2rem;

  > h2 {
    color: ${({ theme }) => theme.colors.emperor};
    font-size: 1.8rem;
    font-weight: 300;
    margin-right: 0.5rem;
  }
`;
