import { CardInfo } from 'ds';
import styled from 'styled-components';

export const CardInfoContainer = styled(CardInfo.Container)`
  min-width: 37.2rem;
  min-height: 15.2rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 42rem;
  }
`;
