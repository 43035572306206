import { useDsGa4 } from 'ds/hooks/globals';

import { type ICustomRadioItemDefaultContainer } from './CustomRadioItemDefaultContainer.types';

import {
  CheckIndicator,
  Container,
  InputComponent,
  Label,
  LabelContainer,
  RadioComponent
} from './CustomRadioItemDefaultContainer.styles';

export function CustomRadioItemDefaultContainer({
  children,
  onClick,
  checked,
  inputId,
  name,
  ...rest
}: ICustomRadioItemDefaultContainer): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  return (
    <Container {...rest}>
      <InputComponent
        type='radio'
        name={name}
        onClick={() => {
          onClick();

          sendDsGaEvent('components', 'list_relatorio', {
            eventValue: `${checked}`
          });
        }}
        readOnly
        checked={checked}
        id={inputId}
      />

      <LabelContainer>
        <RadioComponent>
          <CheckIndicator />
        </RadioComponent>

        <Label>{children}</Label>
      </LabelContainer>
    </Container>
  );
}
