import { useQueryCache } from 'data/cache';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUseGetDataToRepresentativeApproval,
  type IUseGetDataToRepresentativeApprovalParams
} from 'data/modules/cards/companyOnboarding';

export function useGetDataToRepresentativeApproval({
  hashCode,
  enabled = true
}: IUseGetDataToRepresentativeApprovalParams): IUseGetDataToRepresentativeApproval {
  const {
    data: dataToRepresentativeApproval,
    isInitialLoading: isLoadingDataToRepresentativeApproval,
    isError: isErrorRepresentativeApproval,
    error: representativeApprovalError
  } = useQueryCache({
    queryKey: [
      CompanyOnboardingQueryKeys.GET_DATA_TO_REPRESENTATIVE_APPROVAL,
      hashCode
    ],
    queryFn: async () =>
      await CompanyOnboardingService.getDataToRepresentativeApproval({
        hashCode
      }),
    enabled
  });
  return {
    dataToRepresentativeApproval,
    isLoadingDataToRepresentativeApproval,
    isErrorRepresentativeApproval,
    representativeApprovalError
  };
}
