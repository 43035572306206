import { type IProductFaqSectionProps } from './ProductFaqSection.types';

import { Container } from './ProductFaqSection.styles';

export function ProductFaqSection({
  children,
  ...props
}: IProductFaqSectionProps): JSX.Element {
  return <Container {...props}>{children}</Container>;
}
