import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.tokens.neutralColorLowDark};
  font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto};
  display: flex;
  flex-direction: column;
  gap: 2rem;

  tr {
    background-color: ${({ theme }) => theme.tokens.neutralColorHighDark};
  }

  thead > tr {
    background-color: ${({ theme }) => theme.tokens.brandColorPrimaryLight};
  }

  .tableRow {
    display: table-row;
  }
`;

export const CustomTr = styled.tr`
  height: 4.8rem;

  &:nth-of-type(2n) {
    background-color: ${({ theme }) => theme.tokens.brandColorPrimaryLight};
  }
`;

export const CustomTh = styled.th`
  font-weight: 500;
  font-size: 1.6rem;
  padding: 1rem 2rem;

  &:first-child {
    text-align: left;
    padding-left: 5rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 1.5rem 3rem;
  }
`;

export const CustomTd = styled.td`
  font-weight: 500;
  font-size: 1.4rem;
  padding: 1.5rem 2rem;

  > div {
    justify-content: center;
  }

  &:first-child {
    padding-left: 2rem;
  }

  &:not(:first-child) {
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    &:first-child {
      padding-left: 5rem;
    }
  }
`;

export const CustomRadioInput = styled.input`
  appearance: none;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighPurest};
  font: inherit;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  place-content: center;
  margin: 0 auto;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.tokens.neutralColorLowLight};
  }

  &::before {
    content: '';
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    transform: scale(0);
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:checked {
    background-color: ${({ theme }) => theme.tokens.brandColorSecondaryPure};
  }

  &:checked::before {
    transform: scale(1);
  }
`;

export const TooltipLabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  svg {
    flex-shrink: 0;
  }
`;
