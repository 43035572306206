import { useDropdown } from './useDropdown';

import { type IDropdownProps } from './Dropdown.types';

import { Button, Container, Content } from './Dropdown.styles';

export function Dropdown({
  children,
  content,
  direction = 'center',
  padding = 0
}: IDropdownProps): JSX.Element {
  const { ref, isOpen, toggleIsOpen } = useDropdown();

  return (
    <Container ref={ref}>
      <Button onClick={toggleIsOpen}>{children}</Button>
      <Content
        $padding={padding}
        $direction={direction}
        $opened={isOpen}
      >
        {content}
      </Content>
    </Container>
  );
}
