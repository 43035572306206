import { AnalysisDefaultFilter } from 'data/modules/analytics';

class AnalysisFilter {
  /**
   * Remove opções padrão do payload.
   * @param option Opções a serem filtradas.
   * @param optionsToRemove Outras opções a serem removidas.
   * @returns Opções filtradas.
   */
  removePayloadDefaultOptions(
    option: string[] | undefined,
    optionsToRemove: string[] = []
  ): string[] | undefined {
    return option?.filter(
      option =>
        ![AnalysisDefaultFilter.ALL, ...optionsToRemove].includes(option) &&
        !option.includes(AnalysisDefaultFilter.ALL_WITH)
    );
  }

  /**
   * Obtém todas as opções, incluindo as com um termo específico.
   * @param selectedOptions Opções selecionadas.
   * @param allOptions Todas as opções disponíveis.
   * @param key Chave para buscar no objeto de opções.
   * @returns Opções selecionadas, incluindo as com o termo específico.
   */
  getAllWithTermOptions(
    selectedOptions?: string[],
    allOptions?: { id: number | string; name?: string }[],
    key: 'id' | 'name' = 'name'
  ): string[] | undefined {
    const options = selectedOptions ?? [];

    const allWithIndex = options.findIndex(option =>
      option.includes(AnalysisDefaultFilter.ALL_WITH)
    );

    if (allWithIndex >= 0) {
      const term = options[allWithIndex]?.replace(
        AnalysisDefaultFilter.ALL_WITH,
        ''
      );

      const allWithSelected =
        allOptions
          ?.filter(option =>
            String(option[key])
              ?.toLocaleLowerCase()
              .includes(term.trim().toLocaleLowerCase())
          )
          .map(option => String(option.id)) ?? [];

      return Array.from(new Set([...options, ...allWithSelected]));
    }

    return selectedOptions;
  }
}

export default new AnalysisFilter();
