import { useLangContext } from 'data/contexts';

import activeDirectoryBanner from 'shared/assets/images/store/activeDirectory/activeDirectory-header-banner.png';
import cardsBanner from 'shared/assets/images/store/cards/cards-header-banner.png';
import cardsPjBanner from 'shared/assets/images/store/cardsPj/cardsPj-header-banner.png';
import conciliationBanner from 'shared/assets/images/store/conciliation/conciliation-header-banner.png';
import defaultBanner from 'shared/assets/images/store/default-product-banner.png';
import horusBanner from 'shared/assets/images/store/horus/horus-header-banner.png';
import outsourcingBanner from 'shared/assets/images/store/outsourcing/outsourcing-header-banner.png';

import {
  type IUseProductHeaderBanner,
  type IUseProductHeaderBannerParams
} from './ProductHeaderBanner.types';

export function useProductHeaderBanner({
  identifier
}: IUseProductHeaderBannerParams): IUseProductHeaderBanner {
  const {
    currentLangKey,
    lang: {
      store: {
        global,
        product: {
          product_header_banner: { descriptions }
        }
      }
    }
  } = useLangContext();

  function getName(): string {
    try {
      return global.product_name[identifier][currentLangKey];
    } catch {
      return '';
    }
  }

  function getDescription(): string {
    try {
      return descriptions[identifier][currentLangKey];
    } catch {
      return '';
    }
  }

  function getBanner(): string {
    switch (identifier) {
      case 'cards':
        return cardsBanner;
      case 'cards-pj':
        return cardsPjBanner;
      case 'horus':
        return horusBanner;
      case 'conciliation':
        return conciliationBanner;
      case 'outsourcing':
        return outsourcingBanner;
      case 'active-directory':
        return activeDirectoryBanner;
      default:
        return defaultBanner;
    }
  }

  return {
    banner: getBanner(),
    title: getName(),
    description: getDescription()
  };
}
