import { BadgeStatus, DefaultTable, DSIcons } from 'ds';

import {
  useCardDetailsContext,
  useLangContext,
  useModalContext
} from 'data/contexts';

import { CardNumber, Currency } from 'shared/utils/format';

import { type ICardsTableRowProps } from './CardsTableRow.types';

import { StyledRow } from './CardsTableRow.styles';

export function CardsTableRow({
  card,
  ...rest
}: ICardsTableRowProps): JSX.Element {
  const {
    currentLangKey,
    lang: {
      cards: { global }
    }
  } = useLangContext();

  const handleOpenModal = useModalContext(state => state.handleOpenModal);

  const handleSetSelectedAccount = useCardDetailsContext(
    state => state.handleSetSelectedAccount
  );

  return (
    <StyledRow
      {...rest}
      onClick={() => {
        handleOpenModal('cardDetails');

        handleSetSelectedAccount({
          accountId: card.accountId,
          balanceId: card.balanceId,
          name: card.name,
          availableBalance: Currency.format(
            'BRL',
            card.currentValue as number,
            true
          ),
          number: card.number as string,
          status: card.status,
          type: card.type,
          userId: card.userId
        });
      }}
    >
      <DefaultTable.Td title={card.name}>{card.name}</DefaultTable.Td>

      <DefaultTable.Td>
        {card.type === 'BUSINESS'
          ? global.account_type.business[currentLangKey]
          : global.account_type.individual[currentLangKey]}
      </DefaultTable.Td>

      <DefaultTable.Td>
        {card.number
          ? CardNumber.format(card.number)
          : global.not_bound[currentLangKey]}
      </DefaultTable.Td>

      <DefaultTable.Td
        isLoading={!card.currentValue && card.currentValue !== 0}
      >
        {Currency.format('BRL', card.currentValue ?? 0, true)}
      </DefaultTable.Td>

      <DefaultTable.Td
        isLoading={!card.currentValue && card.currentValue !== 0}
      >
        <BadgeStatus
          type={
            card.status === 'ACTIVE'
              ? 'success'
              : card.status === 'BLOCKED'
                ? 'error'
                : 'general'
          }
        >
          {card.status === 'ACTIVE'
            ? global.card_active[currentLangKey]
            : card.status === 'BLOCKED'
              ? global.card_blocked[currentLangKey]
              : global.not_bound[currentLangKey]}
        </BadgeStatus>
      </DefaultTable.Td>

      <DefaultTable.Td
        isLoading={!card.currentValue && card.currentValue !== 0}
      >
        <DSIcons.CreditCardInfoIcon />
      </DefaultTable.Td>
    </StyledRow>
  );
}
