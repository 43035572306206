import { CardInfoCaption } from './CardInfoCaption/CardInfoCaption.container';
import { CardInfoContainer } from './CardInfoContainer/CardInfoContainer.container';
import { CardInfoRefreshButton } from './CardInfoRefreshButton/CardInfoRefreshButton.component';
import { CardInfoTitle } from './CardInfoTitle/CardInfoTitle.container';
import { CardInfoValue } from './CardInfoValue/CardInfoValue.container';
import { CardInfoValueContainer } from './CardInfoValueContainer/CardInfoValueContainer.container';

export const CardInfo = {
  Container: CardInfoContainer,
  Title: CardInfoTitle,
  ValueContainer: CardInfoValueContainer,
  Value: CardInfoValue,
  RefreshButton: CardInfoRefreshButton,
  Caption: CardInfoCaption
};
