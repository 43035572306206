import { useCallback, useEffect, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useLangContext, useModalContext } from 'data/contexts';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type CompanyOnboardingShareholderType,
  type ICompanyAccount,
  useCompleteAccountSteps,
  useGetCompanyOnboardingShareholders,
  useUpdateShareholders
} from 'data/modules/cards/companyOnboarding';

import { Badge } from 'presentation/components/base/Badge';
import { BaseIcon } from 'presentation/components/base/Icon';

import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import {
  type IPartnerHeaderItem,
  type IUsePartnersList
} from './PartnersList.types';

import {
  ControllersContainer,
  CustomDeleteButton,
  CustomEditButton
} from './PartnersList.styles';

export function usePartnersList(): IUsePartnersList {
  const navigate = useNavigate();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const [currentLangKey, addPartner, concluded] = useLangContext(state => [
    state.currentLangKey,
    state.lang.cards.onboarding.company.add_partner,
    state.lang.global.concluded
  ]);

  const partnersTableHeader: IPartnerHeaderItem[] = [
    {
      key: 'icon',
      label: ''
    },
    {
      key: 'responsible',
      label: addPartner.partners_list.table.headers.responsible[currentLangKey]
    },
    {
      key: 'document',
      label: addPartner.partners_list.table.headers.document[currentLangKey]
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'buttons',
      label: ''
    }
  ];

  const { handleUpdateShareholders } = useUpdateShareholders({
    onUpdateCompanyOnboardingAnswersMutationSuccess: updateCacheAndCompleteSteps
  });

  const {
    data: cachedCompanyOnboardingProgress,
    updateCache: updateCurrentStepCache
  } = useHandleQueryCache({
    query: {
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS],
      query: async () => await CompanyOnboardingService.getProgress()
    }
  });

  const { updateCache } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      ],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingAnswers({
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    }
  });

  const {
    companyOnboardingShareholders,
    isCompanyOnboardingShareholdersLoading
  } = useGetCompanyOnboardingShareholders();

  const { handleCompleteAccountSteps } = useCompleteAccountSteps({
    onCompleteAccountStepsMutationSuccess: navigateNextStep
  });

  const queryClient = useQueryClient();
  const { handleOpenModal, handleCloseModal } = useModalContext();

  useEffect(() => {
    queryClient.setQueryData(['selectedShareholder'], null);
    queryClient.setQueryData(['legalEntityData'], null);
  }, [queryClient]);

  const handleEditShareholderClick = useCallback(
    (selectedShareholder: CompanyOnboardingShareholderType) => {
      queryClient.setQueryData(['selectedShareholder'], selectedShareholder);

      if (selectedShareholder.type === 'INDIVIDUAL') {
        navigate(
          `/cartoes/onboarding/empresa/${currentStep}/classificacao-pessoa-fisica`
        );

        return;
      }

      navigate(
        `/cartoes/onboarding/empresa/${currentStep}/dados-pessoa-juridica`
      );
    },
    [currentStep, navigate, queryClient]
  );

  const handleDeleteShareholderClick = useCallback(
    (selectedShareholder: CompanyOnboardingShareholderType) => {
      queryClient.setQueryData(['selectedShareholder'], selectedShareholder);

      handleOpenModal('confirmation');
    },
    [queryClient, handleOpenModal]
  );

  function handleCloseDeleteModal(): void {
    queryClient.setQueryData(['selectedShareholder'], null);
    handleCloseModal();
  }

  function handleSaveShareholdersClick(): void {
    if (
      !cachedCompanyOnboardingProgress?.onboardingCompanyId ||
      isCompanyOnboardingShareholdersLoading
    )
      return;

    handleUpdateShareholders(companyOnboardingShareholders);
  }

  function updateCacheAndCompleteSteps({
    shareholders
  }: ICompanyAccount): void {
    if (!cachedCompanyOnboardingProgress?.onboardingCompanyId) return;

    updateCache({
      key: 'companyAccount',
      newData: {
        shareholders
      }
    });

    handleCompleteAccountSteps();
  }

  function navigateNextStep(): void {
    if (!cachedCompanyOnboardingProgress?.onboardingCompanyId) return;

    navigate(`/cartoes/onboarding/empresa/${currentStep + 1}`);

    if (currentStep === Number(cachedCompanyOnboardingProgress?.currentStep)) {
      updateCurrentStepCache({
        key: 'currentStep',
        newData: currentStep + 1
      });
    }
  }

  const partnersTableData = useMemo(() => {
    return companyOnboardingShareholders.map((shareholder, index) => ({
      id: `${shareholder.document}-${index}`,
      icon: (
        <BaseIcon
          size={2.4}
          type='line'
          name={
            shareholder.type === 'BUSINESS'
              ? 'building-business-office'
              : shareholder.shareholderType === 'partner'
                ? 'user-star'
                : shareholder.shareholderType === 'legalRepresentative'
                  ? 'user-settings'
                  : shareholder.shareholderType === 'proxyholder'
                    ? 'user-search'
                    : 'user-shared'
          }
          color='secondary'
        />
      ),
      responsible: (
        <>
          <strong>
            {shareholder.type === 'BUSINESS'
              ? shareholder.legalName
              : shareholder.name}
          </strong>
          <p>
            {shareholder.type === 'BUSINESS'
              ? addPartner.natural_person_or_legal_entity.options.legal_entity[
                  currentLangKey
                ]
              : shareholder.shareholderType === 'partner'
                ? addPartner.natural_person_type.partner[currentLangKey]
                : shareholder.shareholderType === 'proxyholder'
                  ? addPartner.natural_person_type.proxyholder[currentLangKey]
                  : shareholder.shareholderType === 'legalRepresentative'
                    ? addPartner.natural_person_type.legal_representative[
                        currentLangKey
                      ]
                    : addPartner.natural_person_type.others[currentLangKey]}
          </p>
        </>
      ),
      document: <p>{shareholder.document}</p>,
      status: (
        <Badge
          color='feedbackColorSuccessPure'
          label={concluded[currentLangKey]}
        />
      ),
      buttons: (
        <ControllersContainer>
          <CustomEditButton
            onClick={() => {
              handleEditShareholderClick(shareholder);
            }}
          >
            <BaseIcon
              size={2.4}
              type='line'
              name='edit'
            />
          </CustomEditButton>
          <CustomDeleteButton
            onClick={() => {
              handleDeleteShareholderClick(shareholder);
            }}
          >
            <BaseIcon
              size={2.4}
              type='line'
              name='delete-bin'
            />
          </CustomDeleteButton>
        </ControllersContainer>
      )
    }));
  }, [
    companyOnboardingShareholders,
    addPartner,
    concluded,
    currentLangKey,
    handleEditShareholderClick,
    handleDeleteShareholderClick
  ]);

  function handleAddPartner(): void {
    navigate(`/cartoes/onboarding/empresa/${currentStep}/tipo-representante`);
  }

  return {
    handleAddPartner,
    partnersTableData,
    partnersTableHeader,
    handleCloseDeleteModal,
    handleSaveShareholdersClick,
    isPartnersTableDataLoading: isCompanyOnboardingShareholdersLoading
  };
}
