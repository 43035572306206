import { BadgeStatus } from 'ds';

import { useLangContext } from 'data/contexts';

import { type ISolicitationBadgeStatusProps } from './SolicitationBadgeStatus.types';

export function SolicitationBadgeStatus({
  status,
  ...rest
}: ISolicitationBadgeStatusProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  return (
    <>
      {status === 'APPROVED' && (
        <BadgeStatus
          type='success'
          {...rest}
        >
          {lang.cards.valueSolicitation.status.approved[currentLangKey]}
        </BadgeStatus>
      )}

      {status === 'REJECTED' && (
        <BadgeStatus
          type='error'
          {...rest}
        >
          {lang.cards.valueSolicitation.status.disapprove[currentLangKey]}
        </BadgeStatus>
      )}

      {status === 'SENT' && (
        <BadgeStatus
          type='warning'
          {...rest}
        >
          {lang.cards.valueSolicitation.status.pending[currentLangKey]}
        </BadgeStatus>
      )}
    </>
  );
}
