import { api, env } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import {
  getAccountBalanceAmountMock,
  getAccountsMock,
  getCardDataByAccountIdMock,
  getCompanyAccountsMock,
  getOutsourcedUserAccountMock,
  getUserAccountsByUserUuidMock,
  getUserAccountsMock
} from 'data/mocks/cards/account';
import {
  type IAccountBalanceAmount,
  type IBlockCardPayload,
  type ICardData,
  type IDepositAccountBalanceAmountPayload,
  type IGetAccountBalanceAmountPayload,
  type IGetAccountsPayload,
  type IGetCardDataByAccountIdPayload,
  type IGetCompanyAccounts,
  type IGetCompanyAccountsPayload,
  type IGetOutsourcedUserAccountsPayload,
  type IGetUserAccounts,
  type IGetUserAccountsByUserUuidPayload,
  type IGetUserAccountsPayload,
  type IPaginatedAccounts,
  type IPersistenceCardData,
  type IPersistenceCompanyAccount,
  type IPersistenceDepositAccountBalanceAmount,
  type IPersistenceUserAccount,
  type IPersistenceUserAccountByUserUuid,
  type IPersistenceWithdrawAccountBalanceAmount,
  type IResetCardPinPayload,
  type IUnblockCardPayload,
  type IUserAccountByUserUuid,
  type IWithdrawAccountBalanceAmountPayload,
  type PersistenceAccountsType
} from 'data/modules/cards/account';
import {
  type IDefaultResponse,
  type IDomainResponse,
  type IObjectsArray
} from 'data/modules/global';

import {
  AccountsMapper,
  CardDataMapper,
  CompanyAccountMapper,
  UserAccountMapper,
  UserAccountsByUserUuidMapper
} from './mappers';

class AccountService {
  async getUserAccounts(
    payload: IGetUserAccountsPayload
  ): Promise<IGetUserAccounts> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getUserAccountsMock();
    }

    const response = await api.get<
      IDefaultResponse<IObjectsArray<IPersistenceUserAccount> | null>
    >('/pay/accounts/balances', {
      params: payload
    });

    const { data, total } = ResponseAdapter.formatObjectsArrayResponse(
      response.data
    );

    return {
      data: data?.map(UserAccountMapper.toDomain) ?? [],
      total: total ?? 0
    };
  }

  async getCompanyAccounts(
    payload: IGetCompanyAccountsPayload
  ): Promise<IGetCompanyAccounts> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getCompanyAccountsMock();
    }

    const response = await api.get<
      IDefaultResponse<IObjectsArray<IPersistenceCompanyAccount> | null>
    >('/pay/shared-accounts/balances', {
      params: payload
    });

    const { data, total } = ResponseAdapter.formatObjectsArrayResponse(
      response.data
    );

    return {
      data: data?.map(CompanyAccountMapper.toDomain) ?? [],
      total: total ?? 0
    };
  }

  async getUserAccountsByUserUuid({
    userUuid
  }: IGetUserAccountsByUserUuidPayload): Promise<IUserAccountByUserUuid[]> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getUserAccountsByUserUuidMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceUserAccountByUserUuid[]>
    >(`/pay/users/${userUuid}/accounts`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data.map(UserAccountsByUserUuidMapper.toDomain);
  }

  async getOutsourcedUserAccounts({
    outsourcedUserUuid
  }: IGetOutsourcedUserAccountsPayload): Promise<IUserAccountByUserUuid[]> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getOutsourcedUserAccountMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceUserAccountByUserUuid[]>
    >(`/pay/users/${outsourcedUserUuid}/shared-accounts`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data.map(UserAccountsByUserUuidMapper.toDomain);
  }

  async blockCard({
    accountId,
    blockLevel
  }: IBlockCardPayload): Promise<ICardData> {
    const response = await api.patch<IDefaultResponse<IPersistenceCardData>>(
      `/pay/accounts/${accountId}/card/block`,
      {
        level_block: blockLevel
      }
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return CardDataMapper.toDomain(data);
  }

  async unblockCard({ accountId }: IUnblockCardPayload): Promise<ICardData> {
    const response = await api.patch<IDefaultResponse<IPersistenceCardData>>(
      `/pay/accounts/${accountId}/card/activate`
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return CardDataMapper.toDomain(data);
  }

  async resetCardPin({
    accountId,
    pin
  }: IResetCardPinPayload): Promise<IDomainResponse<null>> {
    const response = await api.patch<IDefaultResponse<null>>(
      `/pay/accounts/${accountId}/card/pin`,
      {
        digits: pin
      }
    );

    return ResponseAdapter.formatRegularResponse(response.data);
  }

  async getCardDataByAccountId({
    accountId
  }: IGetCardDataByAccountIdPayload): Promise<ICardData | null> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getCardDataByAccountIdMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceCardData | null>
    >(`/pay/accounts/${accountId}/card`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data !== null ? CardDataMapper.toDomain(data) : null;
  }

  async getAccountBalanceAmount({
    accountBalanceId
  }: IGetAccountBalanceAmountPayload): Promise<IAccountBalanceAmount> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getAccountBalanceAmountMock();
    }

    const response = await api.get<IDefaultResponse<IAccountBalanceAmount>>(
      `/pay/accounts/balances/${accountBalanceId}/amount`
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return {
      amount: data.amount
    };
  }

  async depositAccountBalanceAmount({
    accountBalanceId,
    amount,
    description
  }: IDepositAccountBalanceAmountPayload): Promise<
    IDomainResponse<IPersistenceDepositAccountBalanceAmount>
  > {
    const response = await api.post<
      IDefaultResponse<IPersistenceDepositAccountBalanceAmount>
    >('/pay/accounts/balances/deposit', {
      balance_account_id: accountBalanceId,
      amount,
      description
    });

    return ResponseAdapter.formatRegularResponse(response.data);
  }

  async withdrawAccountBalanceAmount({
    accountBalanceId,
    amount,
    description
  }: IWithdrawAccountBalanceAmountPayload): Promise<
    IDomainResponse<IPersistenceWithdrawAccountBalanceAmount>
  > {
    const response = await api.post<
      IDefaultResponse<IPersistenceWithdrawAccountBalanceAmount>
    >('/pay/accounts/balances/withdrawal', {
      balance_account_id: accountBalanceId,
      amount,
      description
    });

    return ResponseAdapter.formatRegularResponse(response.data);
  }

  async getAccounts(payload: IGetAccountsPayload): Promise<IPaginatedAccounts> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getAccountsMock();
    }

    const response = await api.get<
      IDefaultResponse<IObjectsArray<PersistenceAccountsType>>
    >('/pay/v2/app/accounts', {
      params: AccountsMapper.toPersistence(payload)
    });

    const { data, total } = ResponseAdapter.formatObjectsArrayResponse(
      response.data
    );

    return {
      data: data?.map(item => AccountsMapper.toDomain(item)) ?? [],
      total: total ?? 0
    };
  }
}

export default new AccountService();
