import ReactModal from 'react-modal';

import { RectButton } from 'presentation/components/base/Button';
import { Loader } from 'presentation/components/global/Loader';

import { type IModalProps } from './Modal.types';

import {
  ButtonsContainer,
  CloseButton,
  Content,
  Description,
  Footer,
  Header,
  Title
} from './Modal.styles';

ReactModal.setAppElement('#root');

export function Modal({
  isOpen,
  children,
  title,
  primaryButton,
  secondaryButton,
  extraButtons,
  footerMessage,
  description,
  onClose,
  onSubmit,
  isLoading = false
}: IModalProps): JSX.Element {
  const footerContainer = (
    <Footer>
      <span>{footerMessage}</span>

      <ButtonsContainer>
        <>
          {secondaryButton && (
            <RectButton
              type='button'
              color='carnation'
              {...secondaryButton}
            >
              {secondaryButton.children}
            </RectButton>
          )}

          {extraButtons?.map((value, index) => (
            <RectButton
              key={index}
              {...value}
            >
              {value.children}
            </RectButton>
          ))}

          {primaryButton && (
            <RectButton
              type={onSubmit ? 'submit' : 'button'}
              {...primaryButton}
            >
              {primaryButton.children}
            </RectButton>
          )}
        </>
      </ButtonsContainer>
    </Footer>
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName='default-modal-overlay'
      className='default-modal-content'
    >
      <Header>
        {typeof title === 'string' ? <Title>{title}</Title> : title}

        <CloseButton
          iconProps={{
            name: 'close',
            color: 'inherit',
            size: 1.4
          }}
          onClick={onClose}
        />
      </Header>

      {description && <Description>{description}</Description>}

      {isLoading && <Loader isLoading />}

      <Content>
        {onSubmit ? (
          <form onSubmit={onSubmit}>
            {children}

            {(primaryButton ?? secondaryButton) && footerContainer}
          </form>
        ) : (
          <>
            {children}
            {footerContainer}
          </>
        )}
      </Content>
    </ReactModal>
  );
}
