import analyticsJSON from './analytics.lang.json';
import cardsJSON from './cards.lang.json';
import errorsJSON from './errors.lang.json';
import expensesJSON from './expenses.lang.json';
import fuelJSON from './fuel.lang.json';
import globalJSON from './global.lang.json';
import notificationsJSON from './notifications.lang.json';
import reportsJSON from './reports.lang.json';
import schemasJSON from './schemas.lang.json';
import settingsJSON from './settings.lang.json';

export const es = {
  analytics: analyticsJSON,
  expenses: expensesJSON,
  cards: cardsJSON,
  global: globalJSON,
  schemas: schemasJSON,
  notifications: notificationsJSON,
  reports: reportsJSON,
  fuel: fuelJSON,
  errors: errorsJSON,
  settings: settingsJSON
};
