import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import { DsFileInput } from 'presentation/ds/DsFileInput';
import { DsRadioButton, DsRadioGroup } from 'presentation/ds/DsRadio';
import {
  OnboardingFooter,
  OnboardingLoader,
  OnboardingText,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { Onboarding } from 'shared/utils/cards';

import { usePartnerOrRepresentative } from './usePartnerOrRepresentative';

import {
  Container,
  Content,
  ErrorMessage,
  OptionsContainer
} from './PartnerOrRepresentative.styles';

export function PartnerOrRepresentative(): JSX.Element {
  const navigate = useNavigate();
  const [onboarding, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding,
    state.currentLangKey
  ]);

  const {
    handleUpdatePartnerOrRepresentative,
    control,
    errors,
    isLoadingRepresentatives
  } = usePartnerOrRepresentative();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  if (isLoadingRepresentatives) {
    return <OnboardingLoader />;
  }

  return (
    <Container onSubmit={handleUpdatePartnerOrRepresentative}>
      <OnboardingTitle>
        {onboarding.company.partner_or_representative.title[currentLangKey]}
      </OnboardingTitle>

      <Content>
        <OptionsContainer>
          <DsRadioGroup
            name='option'
            justify='center'
            control={control}
            direction='column'
          >
            <DsRadioButton
              value={'yes'}
              label={
                onboarding.company.partner_or_representative.options.yes[
                  currentLangKey
                ]
              }
            />

            <DsRadioButton
              value={'no'}
              label={
                onboarding.company.partner_or_representative.options.no[
                  currentLangKey
                ]
              }
            />
          </DsRadioGroup>

          {errors.option && (
            <ErrorMessage>{errors.option.message}</ErrorMessage>
          )}
        </OptionsContainer>

        <OnboardingText>
          {
            onboarding.company.partner_or_representative.description_b[
              currentLangKey
            ]
          }
        </OnboardingText>

        <DsFileInput
          label={
            onboarding.company.partner_or_representative.document[
              currentLangKey
            ]
          }
          name='document'
          control={control}
          accept='application/pdf'
        />
      </Content>

      <OnboardingFooter
        backButtonProps={{
          onClick: () => {
            navigate(`/cartoes/onboarding/empresa/${currentStep - 1}`);
          }
        }}
        continueButtonProps={{
          onClick: () => {}
        }}
      />
    </Container>
  );
}
