import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IUpdatePoliciesAndTermsFieldsForm } from 'data/modules/cards/userOnboarding';

export function useUpdatePoliciesAndTermsSchema(): ObjectSchema<IUpdatePoliciesAndTermsFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const updatePoliciesAndTermsSchema: ObjectSchema<IUpdatePoliciesAndTermsFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object().shape({
        privacyPolicy: yup
          .boolean()
          .required(fieldRequiredMessage)
          .test({
            name: 'privacyPolicy',
            message: lang.schemas.invalid_privacy_policy[currentLangKey],
            test: value => value
          }),
        termsOfUse: yup
          .boolean()
          .required(fieldRequiredMessage)
          .test({
            name: 'termsOfUse',
            message: lang.schemas.invalid_terms_of_use[currentLangKey],
            test: value => value
          })
      });
    }, [lang, currentLangKey]);

  return updatePoliciesAndTermsSchema;
}
