import { useGetValueSolicitationsQuantityByBalanceId } from 'data/modules/cards/balance';

import { useGetActiveBalanceIds } from 'shared/hooks/cards';

import { type IUseMyCardTabs } from './MyCardTabs.types';

export function useMyCardTabs(): IUseMyCardTabs {
  const { activeAccountBalancesIds, isFetchingActiveBalanceIds } =
    useGetActiveBalanceIds();

  const {
    quantitySolicitationByStatus,
    isFetchingQuantitySolicitationByStatus
  } = useGetValueSolicitationsQuantityByBalanceId({
    balanceId: activeAccountBalancesIds[0],
    enabled: !isFetchingActiveBalanceIds
  });

  return {
    quantitySolicitationByStatus,
    isLoadingQuantitySolicitationByStatus:
      isFetchingActiveBalanceIds || isFetchingQuantitySolicitationByStatus
  };
}
