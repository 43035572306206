import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0.8rem;
  }

  > h1 {
    text-align: left;
    margin-top: 2.4rem;
  }
`;

export const InfoList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 3.2rem;
  margin-bottom: 2rem;
  gap: 3.2rem;

  li {
    font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto}, sans-serif;
    font-size: ${({ theme }) => theme.tokens.fontSizeXs};
    color: #4f4f4f;
    list-style: disc;
    margin-left: 1.5rem;
  }
`;
