import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import {
  type IUserOnboardingAnswers,
  UserOnboardingQueryKeys,
  UserOnboardingService,
  useUpdatePoliciesAndTerms
} from 'data/modules/cards/userOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';

import {
  type IUpdatePoliciesAndTermsCachePayload,
  type IUsePoliciesAndTerms
} from './PoliciesAndTerms.types';

export function usePoliciesAndTerms(): IUsePoliciesAndTerms {
  const {
    isUpdatingPoliciesAndTerms,
    updatePoliciesAndTerms,
    control,
    errors
  } = useUpdatePoliciesAndTerms({
    onUpdatePoliciesAndTermsCache: updatePoliciesAndTermsCache
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: cachedUserOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () =>
        await UserOnboardingService.getUserOnboardingProgress(),
      key: [UserOnboardingQueryKeys.GET_USER_ONBOARDING_PROGRESS]
    }
  });

  function updatePoliciesAndTermsCache(
    payload: IUpdatePoliciesAndTermsCachePayload
  ): void {
    queryClient.setQueryData<IUserOnboardingAnswers>(
      [
        UserOnboardingQueryKeys.GET_USER_ONBOARDING_ANSWERS,
        cachedUserOnboardingProgress?.onboardingUserId ?? ''
      ],
      currentAnswers => {
        return {
          ...currentAnswers,
          userTermsOfUse: {
            acceptPrivacyPolicy: payload.privacyPolicy,
            acceptTermsOfUse: payload.termsOfUse
          }
        };
      }
    );

    navigate('/cartoes/onboarding/usuario/2');
  }

  return {
    control,
    isUpdatingPoliciesAndTerms,
    updatePoliciesAndTerms,
    errors
  };
}
