import { useQueryCache } from 'data/cache';
import {
  type IUseGetStoreProducts,
  StoreQueryKeys,
  StoreService
} from 'data/modules/store';

export function useGetStoreProducts(): IUseGetStoreProducts {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [StoreQueryKeys.GET_STORE_PRODUCTS],
    queryFn: async () => await StoreService.getStoreProducts()
  });

  return {
    products: data ?? [],
    isLoadingStoreProducts: isLoading,
    isFetchingStoreProducts: isFetching
  };
}
