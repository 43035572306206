import { Modal, tokens } from 'ds';
import styled from 'styled-components';

export const RowInputs = styled.div`
  display: flex;
  gap: ${tokens.spacingXs};
  width: 100%;

  > div {
    width: calc((100% - ${tokens.spacingXs}) / 2);
  }

  .modal-textinput {
    min-width: unset !important;
  }
`;

export const ModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  margin-bottom: ${tokens.spacingXs};
  padding-top: 1rem;
  padding-bottom: 1rem;

  .react-select-custom__control,
  .react-select-custom__menu,
  .react-multiSelect-custom__control {
    min-width: unset !important;
    max-width: unset !important;
  }

  .react-select-custom__menu {
    z-index: 3;
    max-height: 23rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
    width: 100%;

    ${RowInputs} {
      flex-direction: column;

      > div {
        width: 100%;
      }
    }
  }
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledModalContainer = styled(Modal.Container)`
  @media screen and (max-width: 1300px) {
    max-width: 50rem;
    width: 50rem;
  }

  @media screen and (max-width: 500px) {
    width: 40rem;
    max-width: 40rem;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  > form {
    overflow: auto;
  }
`;
