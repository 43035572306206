import { BadgeStatus, DefaultTable, DSIcons } from 'ds';
import { useTranslation } from 'react-i18next';

import { CardNumber, Currency } from 'shared/utils/format';

import { useGroupManagementTableRow } from './useGroupManagementTableRow';

import { type IGroupManagementTableRowProps } from './GroupManagementTableRow.types';

import { StyledRow } from './GroupManagementTableRow.styles';

export function GroupManagementTableRow({
  user,
  ...rest
}: IGroupManagementTableRowProps): JSX.Element {
  const { t } = useTranslation('global');

  const { handleOpenDetails } = useGroupManagementTableRow({
    user
  });

  return (
    <>
      <StyledRow
        {...rest}
        onClick={() => {
          handleOpenDetails();
        }}
      >
        <DefaultTable.Td title={user.name}>{user.name}</DefaultTable.Td>

        <DefaultTable.Td>
          {user.type === 'CORP_PERSONAL' ? t('individual') : t('business')}
        </DefaultTable.Td>

        <DefaultTable.Td>
          {user.cardNumber ? CardNumber.format(user.cardNumber) : t('notBound')}
        </DefaultTable.Td>

        <DefaultTable.Td>
          {typeof user.amount === 'number'
            ? Currency.format('BRL', user.amount, true)
            : '-'}
        </DefaultTable.Td>

        <DefaultTable.Td>
          <BadgeStatus
            type={
              user.cardStatus === 'ACTIVE'
                ? 'success'
                : user.cardStatus === 'BLOCKED'
                  ? 'error'
                  : 'general'
            }
          >
            {user.cardStatus === 'ACTIVE'
              ? t('active', { context: 'male_one' })
              : user.cardStatus === 'BLOCKED'
                ? t('blocked')
                : t('notBound')}
          </BadgeStatus>
        </DefaultTable.Td>

        <DefaultTable.Td>
          <DSIcons.CreditCardInfoIcon />
        </DefaultTable.Td>
      </StyledRow>
    </>
  );
}
