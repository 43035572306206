import { useQueryCache } from 'data/cache';
import {
  BalanceQueryKeys,
  BalanceService,
  type IBalanceSolicitation,
  type IUseGetBalanceSolicitationsStaged
} from 'data/modules/cards/balance';

export function useGetBalanceSolicitationsStaged(): IUseGetBalanceSolicitationsStaged {
  const {
    data: balanceSolicitationStaged,
    isFetching: isFetchingBalanceSolicitationsStaged
  } = useQueryCache({
    queryKey: [BalanceQueryKeys.GET_CACHED_BALANCE_SOLICITATIONS],
    queryFn: async () => await BalanceService.getBalanceSolicitationsStaged()
  });

  return {
    balanceSolicitationStaged: balanceSolicitationStaged as
      | IBalanceSolicitation[]
      | null,
    isFetchingBalanceSolicitationsStaged
  };
}
