import vexpensesLogo from 'shared/assets/images/support/help_banner_logo.png';

import { type IBannerProps } from './Banner.types';

import { BannerLogo, BannerText, Container } from './Banner.styles';

export function Banner({
  children,
  background,
  ...props
}: IBannerProps): JSX.Element {
  return (
    <Container
      {...props}
      $background={background}
    >
      <BannerLogo
        src={vexpensesLogo}
        alt='VExpenses logo'
      />
      <BannerText>{children}</BannerText>
    </Container>
  );
}
