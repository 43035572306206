import { useController } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type ISendOnboardingFeedbackSchemaFields,
  useCompleteCompanyOnboarding,
  useSendOnboardingFeedback
} from 'data/modules/cards/companyOnboarding';

import { type IBaseIconProps } from 'presentation/components/base/Icon/BaseIcon/BaseIcon.types';
import { DsGhostButton } from 'presentation/ds/DsButton';

import { useHandleQueryCache } from 'shared/hooks/global';

import { type IUseFeedback } from './Feedback.types';

export function useFeedback(): IUseFeedback {
  const navigate = useNavigate();

  const { control, errors, handleSendOnboardingFeedback, isDirty, setValue } =
    useSendOnboardingFeedback({
      onUpdateCompanyOnboardingAnswersMutationSuccess:
        updateCacheAndCompleteCompanyOnboarding
    });

  const { completeCompanyOnboarding } = useCompleteCompanyOnboarding({
    onCompleteCompanyOnboardingMutationSuccess: navigateNextStep
  });

  const {
    field: { value: currentFeedbackComment }
  } = useController({
    control,
    name: 'feedbackComment'
  });

  const {
    field: { value: currentFeedbackNote }
  } = useController({
    control,
    name: 'feedbackNote'
  });

  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const { updateCache } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      ],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingAnswers({
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    }
  });

  function updateCacheAndCompleteCompanyOnboarding(
    data: ISendOnboardingFeedbackSchemaFields
  ): void {
    if (!cachedCompanyOnboardingProgress?.onboardingCompanyId) return;

    if (isDirty) {
      updateCache({
        newData: data,
        key: 'feedback'
      });
    }

    completeCompanyOnboarding();
  }

  function navigateNextStep(): void {
    navigate('/cartoes/onboarding/empresa/final');
  }

  const emotionOptionsIcons: Record<number, IBaseIconProps['name']> = {
    1: 'emotion-sad',
    2: 'emotion-normal',
    3: 'emotion-1',
    4: 'emotion-2',
    5: 'emotion-happy'
  };

  function emotionOptionsToRender(): JSX.Element[] {
    return Object.entries(emotionOptionsIcons).map(
      ([onboardingNote, iconName]) => (
        <DsGhostButton
          type='button'
          key={onboardingNote}
          onClick={() => {
            setValue('feedbackNote', Number(onboardingNote));
          }}
          iconProps={{
            size: 4,
            name: iconName,
            type:
              Number(onboardingNote) === currentFeedbackNote ? 'line' : 'fill'
          }}
        />
      )
    );
  }

  return {
    errors,
    control,
    setValue,
    handleSendOnboardingFeedback,
    emotionOptionsToRender,
    skippingFeedback: !currentFeedbackComment && !currentFeedbackNote
  };
}
