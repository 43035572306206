import styled from 'styled-components';

export const Container = styled.div`
  min-width: 25rem;
  max-width: 90%;
  width: 100%;
  padding: 1.6rem;

  > div {
    margin-bottom: 1rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    min-width: 70rem;
  }
`;

export const InputsContainer = styled.div`
  margin-bottom: 1rem;

  > div {
    margin-bottom: 1rem;
  }

  > div:nth-child(2) {
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
`;
