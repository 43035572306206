import { tokens } from 'ds';
import { styled } from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${tokens.spacingXs};
  width: 100%;
  padding-bottom: 1rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
    grid-template-columns: 1fr;
  }
`;
