import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useLangContext, useModalContext } from 'data/contexts';
import {
  BusinessCardsMutationKeys,
  BusinessCardsService,
  type IChangeBusinessCardProjectsFieldsForm,
  type IRemoveProjectProps,
  type IRemoveRelatedProjectPayload,
  type IUseChangeBusinessCardProjects,
  type IUseChangeBusinessCardProjectsParams
} from 'data/modules/cards/businessCard';
import {
  type IAttachProjectsPayload,
  type IProjects,
  ProjectsMutationKeys,
  ProjectsQueryKeys,
  ProjectsService
} from 'data/modules/projects';

import { useFormWithSchema } from 'shared/hooks/forms';

import { useChangeBusinessCardProjectsSchema } from './schemas';

export function useChangeBusinessCardProjects({
  uuid
}: IUseChangeBusinessCardProjectsParams): IUseChangeBusinessCardProjects {
  const { currentLangKey, lang } = useLangContext();

  const { handleCloseModal } = useModalContext();

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset
  } = useFormWithSchema(useChangeBusinessCardProjectsSchema(), {
    defaultValues: {
      projects: []
    }
  });

  const queryClient = useQueryClient();

  const { mutate: removeRelatedProject, isLoading: isRemovingRelatedProject } =
    useMutationCache({
      mutationKey: [BusinessCardsMutationKeys.REMOVE_RELATED_PROJECT],
      mutationFn: async (payload: IRemoveRelatedProjectPayload) =>
        await BusinessCardsService.removeRelatedProject(payload),
      onSuccess: (data, payload) => {
        if (data) {
          toast.success(
            lang.settings.manageBusinessCard.project_unlinked_successfully[
              currentLangKey
            ]
          );

          queryClient.setQueryData<IProjects[]>(
            [
              ProjectsQueryKeys.GET_RELATED_PROJECTS,
              {
                uuid,
                page: 1,
                perPage: 500
              }
            ],
            oldData => {
              return oldData?.filter(({ id }) => id !== payload.projects[0]);
            }
          );
          handleCloseModal();
        }
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  const { mutate: attachProjects, isLoading: isAttachingProjects } =
    useMutationCache({
      mutationKey: [ProjectsMutationKeys.CREATE_PROJECTS],
      mutationFn: async (payload: IAttachProjectsPayload) =>
        await ProjectsService.attachProjects(payload),
      onSuccess: (data, payload) => {
        toast.success(
          lang.settings.manageBusinessCard.projects_linked_successfully[
            currentLangKey
          ]
        );
        const projects = queryClient.getQueryData([
          ProjectsQueryKeys.GET_PROJECTS,
          {
            page: 1,
            perPage: 500
          }
        ]) as IProjects[];

        queryClient.setQueryData<IProjects[]>(
          [
            ProjectsQueryKeys.GET_RELATED_PROJECTS,
            {
              uuid,
              page: 1,
              perPage: 500
            }
          ],
          oldData => {
            const newProjects = payload.projects.map(item => {
              return projects.find(({ id }) => id === item);
            }) as IProjects[];

            if (oldData && oldData.length > 0) {
              return [...oldData, ...newProjects];
            }

            return newProjects;
          }
        );
        handleCloseModal();
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  function handleRemoveProject({ id }: IRemoveProjectProps): void {
    if (id) {
      removeRelatedProject({
        projects: [id],
        uuid
      });
    }
  }

  function onSubmit(data: IChangeBusinessCardProjectsFieldsForm): void {
    if (data.projects.length > 0) {
      attachProjects({
        uuid,
        projects: data.projects
      });
      reset();
    }
  }

  return {
    control,
    errors,
    isAttachingProjects,
    isRemovingRelatedProject,
    handleSubmit: handleSubmit(onSubmit),
    clearErrors,
    handleRemoveProject
  };
}
