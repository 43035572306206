import { api, apiWeb } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import {
  type IAttachCostCentersPayload,
  type ICostsCenters,
  type IGetCostCentersPayload,
  type IGetPaginatedCostsCentersPayload,
  type IGetRelatedCostCentersPayload,
  type IPaginatedCostsCenters,
  type IPersistenceWebCostsCenter
} from 'data/modules/costsCenters';
import { type IDefaultResponse } from 'data/modules/global';

import PaginationMapper from 'shared/mappers/global/Pagination.mapper';

import {
  AttachCostCentersMapper,
  GetPaginatedCostsCentersMapper
} from './mappers';

class CostCentersService {
  async getCostCenters(
    payload: IGetCostCentersPayload
  ): Promise<ICostsCenters[]> {
    const response = await api.get<IDefaultResponse<ICostsCenters[]>>(
      '/costs-centers',
      {
        params: PaginationMapper.toPersistence(payload)
      }
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async attachCostCenters(payload: IAttachCostCentersPayload): Promise<null> {
    const response = await api.post<IDefaultResponse<null>>(
      `/team-members/${payload.uuid}/costs-centers`,
      AttachCostCentersMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getRelatedCostCenters(
    payload: IGetRelatedCostCentersPayload
  ): Promise<ICostsCenters[]> {
    const response = await api.get<IDefaultResponse<ICostsCenters[]>>(
      `/team-members/${payload.uuid}/costs-centers`,
      {
        params: PaginationMapper.toPersistence(payload)
      }
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getPaginatedCostsCenters(
    payload: IGetPaginatedCostsCentersPayload
  ): Promise<IPaginatedCostsCenters> {
    const response = await apiWeb.get<
      IDefaultResponse<IPersistenceWebCostsCenter[] | null>
    >(`/cost-center/list-by-user`, {
      params: {
        description: payload.description,
        per_page: payload.perPage,
        page: payload.page
      }
    });

    const data = ResponseAdapter.formatRegularResponse(response.data);

    return {
      data: data.data?.map(GetPaginatedCostsCentersMapper.toDomain) || [],
      total: data.total || 0
    };
  }
}

export default new CostCentersService();
