import { useLangContext, useModalContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { ActionModal } from 'presentation/components/global/ActionModal';

import { Currency } from 'shared/utils/format';

import { type IValueTransferFeedbackModalProps } from './ValueTransferFeedbackModal.types';

import {
  CustomErrorIcon,
  CustomSuccessIcon,
  FailedTransferListContainer,
  ListItemContainer,
  MessageContainer,
  ModalMessage,
  Value
} from './ValueTransferFeedbackModal.styles';

export function ValueTransferFeedbackModal({
  transferredData,
  isOpen
}: IValueTransferFeedbackModalProps): JSX.Element {
  const {
    currentLangKey,
    lang: {
      cards: { valueAllocation }
    }
  } = useLangContext();

  const { handleCloseModal } = useModalContext();

  const hasFailedTransferredData =
    transferredData && transferredData.failure.length > 0;

  return (
    <>
      {isOpen && (
        <ActionModal
          title=''
          onClose={handleCloseModal}
          onConfirm={() => {
            handleCloseModal();
          }}
        >
          <MessageContainer>
            {hasFailedTransferredData ? (
              <>
                <CustomErrorIcon>
                  <BaseIcon
                    size={6}
                    name='close-circle'
                    color='feedbackColorErrorLight'
                  />
                </CustomErrorIcon>

                <ModalMessage>
                  {
                    valueAllocation.failed_transfer_message_part_a[
                      currentLangKey
                    ]
                  }
                  <br />
                  {
                    valueAllocation.failed_transfer_message_part_b[
                      currentLangKey
                    ]
                  }
                </ModalMessage>
              </>
            ) : (
              <>
                <CustomSuccessIcon>
                  <BaseIcon
                    size={6}
                    name='checkbox-circle'
                    color='feedbackColorSuccessPure'
                  />
                </CustomSuccessIcon>

                <ModalMessage>
                  {valueAllocation.success_transfer_message[currentLangKey]}
                </ModalMessage>
              </>
            )}
          </MessageContainer>

          <FailedTransferListContainer>
            {transferredData?.failure.map(card => {
              const value =
                card.type === 'CREDIT' ? card.amount : card.amount * -1;

              return (
                <ListItemContainer key={card.balanceAccountId}>
                  <ModalMessage>{card.userName}</ModalMessage>

                  <Value>{Currency.format('BRL', value, true)}</Value>
                </ListItemContainer>
              );
            })}
          </FailedTransferListContainer>
        </ActionModal>
      )}
    </>
  );
}
