import { faker } from '@faker-js/faker';

import { type IUnallocatedBalance } from 'data/modules/cards/balance';

import { MockApi } from 'shared/utils/global';

export async function getUnallocatedBalanceMock(): Promise<IUnallocatedBalance> {
  const data = {
    amount: parseFloat(faker.finance.amount())
  };

  await MockApi.sleep(2000);

  return data;
}
