import { useMemo } from 'react';

import { useGetExpensePolicies } from 'data/modules/expensePolicies';
import { useGetRoutePolicies } from 'data/modules/routePolicies';

import { type ISelectOption } from 'presentation/components/base/Select/Select.types';

import { TransformArray } from 'shared/utils/global';

import { type IUseExpensePoliciesManageBusinessCard } from './useExpensePoliciesManageBusinessCard.types';

export function useExpensePoliciesManageBusinessCard(): IUseExpensePoliciesManageBusinessCard {
  const payload = {
    page: 1,
    perPage: 500
  };

  const { expensePolicies, isLoadingExpensePolicies } =
    useGetExpensePolicies(payload);

  const { routePolicies, isLoadingRoutePolicies } =
    useGetRoutePolicies(payload);

  const expensePoliciesOptions: ISelectOption[] = useMemo(() => {
    return TransformArray.objectsToSelectOptions(
      expensePolicies,
      'id',
      'description'
    );
  }, [expensePolicies]);

  const routePoliciesOptions: ISelectOption[] = useMemo(() => {
    return TransformArray.objectsToSelectOptions(
      routePolicies,
      'id',
      'description'
    );
  }, [routePolicies]);

  return {
    expensePoliciesOptions,
    routePoliciesOptions,
    isLoadingExpensePolicies,
    isLoadingRoutePolicies
  };
}
