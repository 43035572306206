import { useMutationCache } from 'data/cache';
import {
  type ICreateReportFieldsForm,
  type ICreateReportPayload,
  type IUseCreateReport,
  type IUseCreateReportParams,
  ReportsMutationKeys,
  ReportsService
} from 'data/modules/reports';

import { useFormWithSchema } from 'shared/hooks/forms';
import { CustomObject } from 'shared/utils/custom';

import { useCreateReportSchema } from './schemas';

export function useCreateReport(
  params?: IUseCreateReportParams
): IUseCreateReport {
  const formMethods = useFormWithSchema(useCreateReportSchema(), {
    defaultValues: {
      advancePayment: '',
      paymentMethod: '',
      observation: ''
    }
  });

  const { mutate: createReport, isLoading: isCreatingReport } =
    useMutationCache({
      mutationKey: [ReportsMutationKeys.CREATE_REPORT],
      mutationFn: async (payload: ICreateReportPayload) =>
        await ReportsService.createReport(payload),
      onSuccess: data => {
        params?.onAfterCreateReportSuccess?.(data);
      }
    });

  function onSubmitCreateReport(data: ICreateReportFieldsForm): void {
    createReport(
      CustomObject.exchangeNullValues({
        ...data,
        costsCenter: data.costsCenter?.value as string
      })
    );
  }

  return {
    form: {
      methods: formMethods,
      handleSubmit: formMethods.handleSubmit(onSubmitCreateReport)
    },
    isCreatingReport
  };
}
