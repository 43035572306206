import { tokens, transitions } from 'ds';
import Select, { components } from 'react-select';
import styled, { css } from 'styled-components';

import {
  type SelectDefaultGroupStylesType,
  type SelectDefaultStylesType
} from './SelectDefault.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
`;

export const GroupContainer = styled.div<
  AddPrefixToType<SelectDefaultGroupStylesType>
>`
  &:not(:last-of-type) {
    border-bottom: 0.5px solid ${tokens.neutralColorHighDark};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      .react-select-custom__option {
        color: ${tokens.neutralColorLowLight} !important;
        font-style: italic;
      }
    `}
`;

export const Wrapper = styled.div<AddPrefixToType<SelectDefaultStylesType>>`
  width: 100%;
  position: relative;
  min-height: 5.2rem;
  max-height: 5.2rem;

  ${({ $large }) =>
    $large &&
    `
    min-height: 6.4rem;
    max-height: 6.4rem;
  `}

  ${({ $small }) =>
    $small &&
    `
    min-height: 4.1rem;
    max-height: 4.1rem;
  `}
`;

export const Legend = styled.legend`
  opacity: 0;
  visibility: hidden;
  padding: 0;
  overflow: hidden;
  max-width: 0.001rem;
  white-space: nowrap;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  letter-spacing: 0.075rem;

  span {
    opacity: 0;
    visibility: visible;
  }
`;

export const Fieldset = styled.fieldset`
  text-align: left;
  position: absolute;
  inset: -5px 0 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 1;
  min-width: 0%;
  padding: 0 ${tokens.spacingXs};
  border-radius: ${tokens.borderRadiusXs};
  border-color: ${tokens.neutralColorLowLight};
  border-width: ${tokens.borderWidthHairline};
  border-style: solid;
  top: -1rem;
  transition: border 100ms ease-in-out;
`;

export const MenuInformation = styled.p`
  text-align: left;
  line-height: 1.8rem;
  letter-spacing: 0.075rem;
  padding: ${tokens.spacingNano};
  font-size: ${tokens.fontSizeXxss};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
  color: ${tokens.neutralColorLowMediumLight};
`;

export const StyledSelect = styled(Select)<
  AddPrefixToType<SelectDefaultStylesType>
>`
  ~ span {
    color: ${tokens.neutralColorLowLight};

    ${({ $error }) =>
      $error &&
      css`
        color: ${tokens.feedbackColorErrorPure} !important;
        opacity: 1;
      `}

    ${({ $large }) =>
      $large &&
      css`
        font-size: ${tokens.fontSizeXxs};
      `}
  }

  ${({ $error }) =>
    !$error &&
    css`
      &:focus-within {
        ~ span {
          color: ${tokens.brandColorSecondaryPure};
          opacity: 1;
        }

        input,
        .react-select-custom__single-value {
          color: ${tokens.neutralColorLowDarkest} !important;
        }

        svg {
          opacity: 1;
          color: ${tokens.brandColorSecondaryPure};
        }

        ~ ${Fieldset} {
          border-color: ${tokens.brandColorSecondaryPure};
          border-width: ${tokens.borderWidthThin};

          ${Legend} {
            opacity: 1;
            visibility: visible;
            max-width: unset;
            overflow: visible;
            padding: 0 ${tokens.spacingQuarck};
          }
        }
      }
    `}

  * {
    line-height: ${tokens.spacingInsetMd};
    font-size: ${tokens.fontSizeXxs};
  }

  ${({ $isTouched }) =>
    $isTouched &&
    css`
      ~ ${Fieldset} {
        border-color: ${tokens.neutralColorLowMedium};
        border-width: ${tokens.borderWidthHairline};

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      &:not(:focus-within) {
        ~ ${Fieldset} {
          border-color: ${tokens.neutralColorLowDarkest};
          border-width: ${tokens.borderWidthHairline};
        }

        * {
          color: ${tokens.neutralColorLowDarkest} !important;
        }

        ~ span {
          opacity: 1;
          color: ${tokens.neutralColorLowDarkest};
        }
      }
    `}

  ${({ $large }) =>
    $large &&
    css`
      * {
        line-height: unset;
        font-size: ${tokens.fontSizeXs};
      }
    `}

  ${({ $small }) =>
    $small &&
    css`
      * {
        font-size: ${tokens.fontSizeXxss};
        line-height: 1.7rem;
        letter-spacing: 0.075rem;
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      ~ ${Fieldset} {
        border-color: ${tokens.neutralColorLowLightest} !important;
        border-width: ${tokens.borderWidthHairline} !important;

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      input,
      .react-select-custom__single-value,
      .react-select-custom__placeholder {
        color: ${tokens.neutralColorLowMediumLight} !important;
      }

      ~ span {
        opacity: 1;
        color: ${tokens.neutralColorLowMediumLight} !important;
      }
    `}


    ${({ $error }) =>
    !!$error &&
    css`
      input,
      .react-select-custom__single-value {
        color: ${tokens.feedbackColorErrorPure} !important;
      }

      ~ ${Fieldset} {
        border-color: ${tokens.feedbackColorErrorPure} !important;
        border-width: ${tokens.borderWidthThin} !important;

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      svg * {
        color: ${tokens.feedbackColorErrorPure} !important;
      }
    `}

  .react-select-custom__placeholder {
    font-family: ${tokens.fontFamilyPoppins};
    font-weight: ${tokens.fontWeightRegular};
    letter-spacing: 0.075rem;
  }

  .react-select-custom__control {
    background-color: ${tokens.neutralColorHighPure};
    position: relative;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: border 100ms ease-in-out;
    min-width: 32.2rem;
    gap: ${tokens.spacingInsetNano};
    padding: ${tokens.spacingNano} ${tokens.spacingXs};
    border-radius: ${tokens.borderRadiusXs};
    border: none;

    svg {
      width: 2.2rem;
      height: 2.2rem;
      opacity: 0.5;
      transition: ${transitions.allEaseInOut};
    }

    .react-select-custom__single-value {
      background-color: transparent;
      color: ${tokens.neutralColorLowLight};
      font-family: ${tokens.fontFamilyPoppins};
      font-weight: ${tokens.fontWeightRegular};
      letter-spacing: 0.075rem;

      ${({ $error }) =>
        $error &&
        css`
          color: ${tokens.feedbackColorErrorPure};
        `}
    }

    .react-select-custom__input-container {
      background-color: transparent;
      font-family: ${tokens.fontFamilyPoppins} !important;
      font-weight: ${tokens.fontWeightRegular} !important;
      letter-spacing: 0.075rem !important;

      ${({ $error }) =>
        $error &&
        css`
          color: ${tokens.feedbackColorErrorPure} !important;
        `}
    }

    .react-select-custom__clear-indicator {
      display: none;
    }

    ${({ $large }) =>
      $large &&
      css`
        padding-top: ${tokens.spacingXxs} !important;
        padding-bottom: ${tokens.spacingXxs} !important;
        min-width: 32.2rem;
      `}

    ${({ $small }) =>
      $small &&
      css`
        padding-top: ${tokens.spacingNano} !important;
        padding-bottom: ${tokens.spacingNano} !important;
        min-width: 32.2rem;
      `}
  }

  .react-select-custom__value-container {
    padding: 0;
  }

  .react-select__value-container > div {
    width: auto !important;
  }

  .react-select-custom__control--is-disabled {
    pointer-events: auto;
    cursor: not-allowed;
    background-color: ${tokens.neutralColorHighLight} !important;
    color: ${tokens.neutralColorLowMediumLight} !important;
  }

  .react-select-custom__control--menu-is-open {
    input,
    .react-select-custom__single-value {
      color: ${tokens.neutralColorLowDarkest} !important;
    }

    svg {
      opacity: 1;

      color: ${tokens.brandColorSecondaryPure};
      transform: rotateZ(3.14rad);
    }
  }

  .react-select-custom__dropdown-indicator {
    display: ${({ $hasArrowDownIndicator }) =>
      $hasArrowDownIndicator ? `` : 'none'};
    padding: 0;
  }

  .react-select-custom__indicator-separator {
    display: none;
  }

  .react-select-custom__menu {
    margin-top: 0.3rem;
    z-index: 5;
    border-radius: ${tokens.borderRadiusXs};
    box-shadow: ${tokens.shadowLeve4.x} ${tokens.shadowLeve4.y}
      ${tokens.shadowLeve4.blur} ${tokens.shadowLeve4.spread}
      ${tokens.shadowLeve4.color};
    padding: ${tokens.spacingXxs} ${tokens.spacingNano};
    border: 2px solid ${tokens.neutralColorHighMedium};

    &:has(.react-select-custom__menu-notice--no-options) > ${MenuInformation} {
      display: none;
    }
  }

  .react-select-custom__menu-list {
    padding: 0;
    scroll-behavior: unset;
  }

  .react-select-custom__group-heading {
    background-color: transparent;
    padding: ${tokens.spacingNano};
    padding-left: ${tokens.spacingXxs};
    gap: ${tokens.spacingInsetNano};
    font-size: ${tokens.fontSizeXxs};
    color: ${tokens.neutralColorLowMedium};
    font-weight: ${tokens.fontWeightRegular};
    text-align: left;
    font-family: ${tokens.fontFamilyPoppins};
    border-radius: ${tokens.borderRadiusNano};
    line-height: 1.7rem;
    text-transform: none;
    letter-spacing: 0.025rem;

    ${({ $small }) =>
      $small &&
      css`
        font-size: ${tokens.fontSizeXxss} !important;
      `}

    ${({ $large }) =>
      $large &&
      css`
        font-size: ${tokens.fontSizeXs} !important;
      `}
  }

  .react-select-custom__option {
    cursor: pointer;
    background-color: transparent;
    padding: ${tokens.spacingXxs} ${tokens.spacingXs};
    gap: ${tokens.spacingInsetNano};
    font-size: ${tokens.fontSizeXxs};
    color: ${tokens.neutralColorLowDarkest};
    font-weight: ${tokens.fontWeightRegular};
    font-family: ${tokens.fontFamilyPoppins};
    border-radius: ${tokens.borderRadiusNano};
    transition: background 100ms ease-in-out;

    &:hover {
      background-color: ${tokens.neutralColorHighDark} !important;
    }

    ${({ $small }) =>
      $small &&
      css`
        font-size: ${tokens.fontSizeXxss} !important;
      `}

    ${({ $large }) =>
      $large &&
      css`
        font-size: ${tokens.fontSizeXs} !important;
      `}
  }
`;

export const Label = styled.span`
  position: absolute;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  top: -1.5rem;
  left: 1.6rem;
  z-index: 2;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  letter-spacing: 0.075rem;
  padding: ${tokens.spacingQuarck};
`;

export const Caption = styled.span<AddPrefixToType<SelectDefaultStylesType>>`
  color: ${tokens.neutralColorLowLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  margin-top: ${tokens.spacingInsetNano};

  ${({ $error }) =>
    $error &&
    css`
      color: ${tokens.feedbackColorErrorPure} !important;
    `}
`;

export const MenuPortalContainer = styled(components.MenuPortal)<
  AddPrefixToType<SelectDefaultStylesType>
>`
  ${({ $large }) =>
    $large &&
    css`
      * {
        line-height: unset;
        font-size: ${tokens.fontSizeXs};
      }
    `}
  ${({ $small }) =>
    $small &&
    css`
      * {
        font-size: ${tokens.fontSizeXxss};
        line-height: 1.7rem;
        letter-spacing: 0.075rem;
      }
    `}

  .react-select-custom__menu {
    margin-top: 0.3rem;
    z-index: 5;
    border-radius: ${tokens.borderRadiusXs};
    box-shadow: ${tokens.shadowLeve4.x} ${tokens.shadowLeve4.y}
      ${tokens.shadowLeve4.blur} ${tokens.shadowLeve4.spread}
      ${tokens.shadowLeve4.color};
    padding: ${tokens.spacingXxs} ${tokens.spacingNano};
    border: 2px solid ${tokens.neutralColorHighMedium};

    &:has(.react-select-custom__menu-notice--no-options) > ${MenuInformation} {
      display: none;
    }
  }

  .react-select-custom__menu-list {
    padding: 0;
    scroll-behavior: unset;
  }

  .react-select-custom__group-heading {
    background-color: transparent;
    padding: ${tokens.spacingNano};
    padding-left: ${tokens.spacingXxs};
    gap: ${tokens.spacingInsetNano};
    font-size: ${tokens.fontSizeXxs};
    color: ${tokens.neutralColorLowMedium};
    font-weight: ${tokens.fontWeightRegular};
    text-align: left;
    font-family: ${tokens.fontFamilyPoppins};
    border-radius: ${tokens.borderRadiusNano};
    line-height: 1.7rem;
    text-transform: none;
    letter-spacing: 0.025rem;

    ${({ $small }) =>
      $small &&
      css`
        font-size: ${tokens.fontSizeXxss} !important;
      `}

    ${({ $large }) =>
      $large &&
      css`
        font-size: ${tokens.fontSizeXs} !important;
      `}
  }

  .react-select-custom__option {
    cursor: pointer;
    background-color: transparent;
    padding: ${tokens.spacingXs} ${tokens.spacingXxs};
    gap: ${tokens.spacingInsetNano};
    font-size: ${tokens.fontSizeXxs};
    color: ${tokens.neutralColorLowDarkest};
    font-weight: ${tokens.fontWeightRegular};
    font-family: ${tokens.fontFamilyPoppins};
    border-radius: ${tokens.borderRadiusNano};
    transition: background 100ms ease-in-out;

    &:hover {
      background-color: ${tokens.neutralColorHighDark} !important;
    }

    ${({ $small }) =>
      $small &&
      css`
        font-size: ${tokens.fontSizeXxss} !important;
      `}

    ${({ $large }) =>
      $large &&
      css`
        font-size: ${tokens.fontSizeXs} !important;
      `}
  }
`;
