import { type IProjectWithPolicy } from 'data/modules/projects';

export const mockExpenseProjects: IProjectWithPolicy[] = [
  {
    id: 1,
    name: 'projeto 1',
    uuid: 'projeto 1',
    kilometerPolicy: {
      allowChangeKmValueInput: false,
      value: 200,
      valueKm: 1
    }
  },
  {
    id: 2,
    name: 'projeto 2',
    uuid: 'projeto 2',
    kilometerPolicy: {
      allowChangeKmValueInput: true,
      value: 200,
      valueKm: 1
    }
  },
  {
    id: 3,
    name: 'projeto 3',
    uuid: 'projeto 3'
  }
];
