import { useAuthContext, useModalContext } from 'data/contexts';
import { useGetAccountBalancesAmounts } from 'data/modules/cards/account';

import { useGetUserLoggedAndCardData } from 'shared/hooks/cards';

import { type IUseMyCardPageHeader } from './MyCardPageHeader.types';

export function useMyCardPageHeader(): IUseMyCardPageHeader {
  const user = useAuthContext(state => state.user);
  const { handleOpenModal } = useModalContext();
  const {
    userAccounts,
    outsourcedUserAccounts,
    cardData,
    isLoadingUserAccount,
    userHasCard
  } = useGetUserLoggedAndCardData({});

  const isSharedAccount = !!user?.cards?.sharedAccounts;

  const {
    accountBalancesAmounts,
    isFetchingAccountBalancesAmounts: isFetchingAccountBalanceAmount
  } = useGetAccountBalancesAmounts({
    accountBalancesIds: [
      isSharedAccount
        ? (outsourcedUserAccounts?.[0].balances.find(balance => balance.inUse)
            ?.id as string)
        : (userAccounts?.[0].balances.find(balance => balance.inUse)
            ?.id as string)
    ] as string[],
    enabled: userHasCard
  });

  const accountBalanceAmount = accountBalancesAmounts?.[0];

  function handleBlockUnblockCard(action: 'block' | 'unblock'): void {
    if (
      action === 'unblock' &&
      (cardData?.lockLevel === 'SYSTEM' ||
        (cardData?.lockLevel === 'MANAGER' &&
          user?.cards?.type !== 'ACCOUNT_MANAGER'))
    ) {
      handleOpenModal('unblockCardPermissionDenied');
      return;
    }

    handleOpenModal('blockCard');
  }

  return {
    availableValue: accountBalanceAmount,
    cardData: cardData || null,
    userHasCard,
    isLoading: isFetchingAccountBalanceAmount || isLoadingUserAccount,
    handleBlockUnblockCard,
    outsourcedUserAccounts,
    userAccounts
  };
}
