import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { type ICreateManualExpenseSecondStepFieldsForm } from 'data/modules/expenses/types/expenses.types';

export function useSecondStepFormSchema(): ObjectSchema<ICreateManualExpenseSecondStepFieldsForm> {
  const createManualExpenseSecondStepSchema: ObjectSchema<ICreateManualExpenseSecondStepFieldsForm> =
    useMemo(() => {
      return yup.object({
        report: yup.string()
      });
    }, []);

  return createManualExpenseSecondStepSchema;
}
