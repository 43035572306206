import * as Switch from '@radix-ui/react-switch';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto}, sans-serif;
  font-size: ${({ theme }) => theme.tokens.fontSizeSm};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  color: ${({ theme }) => theme.tokens.brandColorSecondaryPure};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledSwitch = styled(Switch.Root)`
  all: unset;
  width: 4.4rem;
  height: 2.4rem;
  background-color: ${({ theme }) => theme.tokens.neutralColorLowLight};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusXxxl};
  position: relative;
  transition: background-color 0.3s ease-in-out;

  &[data-state='checked'] {
    background-color: ${({ theme }) => theme.tokens.brandColorPrimaryPure};
  }

  &:hover {
    background-color: ${({ theme }) => theme.tokens.neutralColorLowMedium};
    cursor: pointer;

    &[data-state='checked'] {
      background-color: ${({ theme }) => theme.tokens.brandColorPrimaryDark};
    }
  }

  &:focus {
    outline: ${({ theme }) => theme.tokens.brandColorPrimaryLight} solid
      ${({ theme }) => theme.tokens.borderWidthThick};

    &[data-state='unchecked'] {
      outline-color: ${({ theme }) => theme.tokens.neutralColorHighMediumest};
    }
  }

  &[data-disabled] {
    background-color: ${({ theme }) => theme.tokens.brandColorPrimaryDarkest};
    cursor: default;

    &:hover {
      background-color: ${({ theme }) => theme.tokens.brandColorPrimaryDarkest};
    }

    &[data-state='unchecked'] {
      opacity: 0.5;
      background-color: ${({ theme }) => theme.tokens.neutralColorLowLight};
    }
  }
`;

export const StyledThumb = styled(Switch.Thumb)`
  display: block;
  width: 2rem;
  height: 2rem;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighPure};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusCircular};
  transition: transform 100ms;
  transform: translateX(0.2rem);
  will-change: transform;

  &[data-state='checked'] {
    transform: translateX(2.2rem);
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  word-break: break-all;
  margin-top: 0.5rem;
  margin-left: 0.3rem;
`;
