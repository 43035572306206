import { useQueryClient } from '@tanstack/react-query';

import { useModalContext } from 'data/contexts';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type CompanyOnboardingShareholderType,
  useDeletePartner
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';

import { type IUseDeletePartnerModal } from './DeletePartnerModal.types';

export function useDeletePartnerModal(): IUseDeletePartnerModal {
  const queryClient = useQueryClient();

  const selectedShareholder =
    queryClient.getQueryData<CompanyOnboardingShareholderType>([
      'selectedShareholder'
    ]);

  const { updateCache } = useHandleQueryCache({
    query: {
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_SHAREHOLDERS],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingShareholders()
    }
  });

  const { handleCloseModal } = useModalContext();

  const { handleDeletePartner, isDeletingPartner } = useDeletePartner({
    selectedPartnerDocument: selectedShareholder?.document,
    onAfterDeletingPartner: updateCacheAndCloseModal
  });

  function updateCacheAndCloseModal(
    newPartnersList: CompanyOnboardingShareholderType[]
  ): void {
    updateCache({
      newData: newPartnersList
    });

    handleCloseModal();
  }

  const selectedShareholderName =
    selectedShareholder?.type === 'BUSINESS'
      ? selectedShareholder.legalName
      : selectedShareholder?.name;

  return {
    isDeletingPartner,
    handleDeletePartner,
    selectedShareholderName
  };
}
