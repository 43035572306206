import {
  type IBalanceSolicitation,
  type IPersistenceBalanceSolicitation
} from 'data/modules/cards/balance';

class BalanceSolicitationMapper {
  toDomain(data: IPersistenceBalanceSolicitation): IBalanceSolicitation {
    return {
      userId: data.user_id,
      balanceId: data.balance_id,
      destinationAccount: data.destination_account,
      id: data.id,
      dateApproved: data.date_approved,
      justify: data.justify,
      requestedAmount: data.requested_amount,
      userName: data.user_name,
      status: data.status,
      transferAmount: data.transfer_amount as number,
      transferredAmount: data.transferred_amount,
      dateSent: data.date_sent,
      managerJustify: data.manager_justify ?? null,
      datePrevision: data.date_prevision
    };
  }
}

export default new BalanceSolicitationMapper();
