import {
  type IModifiedBalanceAllocationItem,
  type IPersistenceSendModifiedBalanceAllocationItemsToCache
} from 'data/modules/cards/balance';

import { ModifiedBalanceAllocationItemMapper } from './';

class SendModifiedBalanceAllocationItemsToCacheMapper {
  toPersistence(
    balanceAllocationItems: IModifiedBalanceAllocationItem[]
  ): IPersistenceSendModifiedBalanceAllocationItemsToCache {
    return {
      domain: 'balance_allocation',
      life_cycle: 48,
      value: balanceAllocationItems.map(
        ModifiedBalanceAllocationItemMapper.toPersistence
      )
    };
  }
}

export default new SendModifiedBalanceAllocationItemsToCacheMapper();
