import { useLocation } from 'react-router-dom';

import { useLangContext } from 'data/contexts';
import {
  type IStoreProduct,
  StoreQueryKeys,
  StoreService
} from 'data/modules/store';

import { useHandleQueryCache } from 'shared/hooks/global';

import { type IUseProduct, type IUseProductState } from './Product.types';

export function useProduct(): IUseProduct {
  const { pathname, state } = useLocation();
  const { showToast } = (state ?? {}) as IUseProductState;

  const identifier =
    (pathname.split('/').pop() as IStoreProduct['identifier']) ?? 'cards';

  const { data: products } = useHandleQueryCache({
    query: {
      key: [StoreQueryKeys.GET_STORE_PRODUCTS],
      query: async () => await StoreService.getStoreProducts()
    }
  });

  const status =
    products?.find(product => product.identifier === identifier)?.status ??
    'not_owned';

  const {
    currentLangKey,
    lang: { store }
  } = useLangContext();

  function getTitle(): string {
    try {
      return store.global.product_name[identifier][currentLangKey];
    } catch {
      return '';
    }
  }

  function getStatusName(): string {
    try {
      return store.productCards.status[
        status as keyof typeof store.productCards.status
      ][currentLangKey];
    } catch {
      return '';
    }
  }

  const toastMessage = showToast
    ? store.global.toastMessage[currentLangKey]
    : undefined;

  return {
    status,
    identifier,
    toastMessage,
    title: getTitle(),
    statusName: getStatusName()
  };
}
