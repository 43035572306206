import { tokens } from 'ds';
import styled from 'styled-components';

import { RectButton } from 'presentation/components/base/Button';

export const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.alabaster};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Content = styled.div`
  flex: 1;
  padding: 0 1.2rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 37rem;
  padding: 0;
  margin: 2rem auto;

  > button,
  a > button {
    margin-bottom: 1.2rem;
  }
`;

export const Title = styled.div`
  padding: 0.8rem 0;
  font-size: 1.5rem;
`;

export const NavigationContainer = styled.div`
  display: flex;
  padding: 2rem;

  a {
    width: 100%;
  }
`;

export const BalanceCardGroupContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: column;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0;
  border: 0.9px solid ${({ theme }) => theme.colors.neutralColorHighMedium};
  margin: 1rem 0 3.5rem;
`;

export const NewBadge = styled.div`
  display: inline-flex;
  padding: 0.3rem 0.7rem;
  border-radius: 8px;
  background-color: ${tokens.brandColorPrimaryLightest};
  color: ${tokens.brandColorPrimaryPure};
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: 1.2rem;
  font-weight: ${tokens.fontWeightMedium};
  letter-spacing: 0.25px;
  text-align: center;
  margin-left: 1.6rem;
`;

export const FakeDoorButton = styled(RectButton)`
  margin-bottom: 0 !important;
`;
