import { useMemo, useState } from 'react';

import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import { useLangContext } from 'data/contexts';
import {
  type ICompanyAccount,
  useGetCompanyAccounts,
  useGetUserAccounts
} from 'data/modules/cards/account';
import { type ICardGroupUser } from 'data/modules/cards/cardGroups';
import { useGetCardGroupUsers } from 'data/modules/cards/cardGroups/useCases/get-card-group-users/useGetCardGroupUsers';
import { useGetPendencies } from 'data/modules/cards/pendencies';
import {
  type IAccountStatement,
  type IAccountTransactionWithAdditionalInfo,
  type IAdminStatementFilters,
  useGenerateStatementInFile,
  useGetAccountStatement,
  useGetCompanyStatement
} from 'data/modules/cards/statement';

import { type ISelectOption } from 'presentation/components/base/Select/Select.types';
import { CentralMessage } from 'presentation/pages/cards/Statement/components/CentralMessage';
import { StatementTable } from 'presentation/pages/cards/Statement/components/Table';

import { Statement } from 'shared/utils/cards';
import { CustomDate } from 'shared/utils/custom';
import { CardNumber } from 'shared/utils/format';

import { type IUseAdminStatement } from './AdminStatement.types';

export function useAdminStatement(): IUseAdminStatement {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);
  const today = new Date();
  const { userId, groupId } = useParams();

  const [checkedAllUsers, setCheckedAllUsers] = useState(false);
  const [statementFilters, setStatementFilters] =
    useState<IAdminStatementFilters>({
      dateFilter: {
        from: today,
        to: today
      },
      searchInput: '',
      selectedCards: [],
      selectedMovementType: 'all',
      selectedUsers: userId ? [userId] : []
    });

  const companyStatementQueryEnabled =
    statementFilters.selectedUsers.length === 0;

  const statementPayload = {
    startDate: format(
      statementFilters.dateFilter.from as Date,
      'yyyy-MM-dd'
    ).concat('T00:00:00.000Z'),
    endDate: format(
      statementFilters.dateFilter.to as Date,
      'yyyy-MM-dd'
    ).concat('T00:00:00.000Z'),
    timezone: CustomDate.getTimezone(),
    ...(groupId && { cardGroupId: groupId })
  };

  const { generateStatementInFile, isGeneratingStatementInFile } =
    useGenerateStatementInFile();

  const { companyStatement, isLoadingCompanyStatement } =
    useGetCompanyStatement({
      enabled: companyStatementQueryEnabled,
      ...statementPayload
    });

  const filteredCompanyStatement = Statement.filterStatementTransactions(
    companyStatement,
    statementFilters.selectedCards,
    statementFilters.selectedMovementType,
    statementFilters.searchInput
  );

  const { paginatedUserAccounts } = useGetUserAccounts({
    limit: 1000,
    page: 1,
    status: ['ACTIVE'],
    enabled: !groupId
  });

  const { paginatedCompanyAccounts } = useGetCompanyAccounts({
    page: 1,
    limit: 1000,
    enabled: !groupId
  });

  const { cardGroupUsers } = useGetCardGroupUsers(
    {
      id: groupId as string,
      limit: 1000,
      page: 1
    },
    !!groupId
  );

  function transformICardGroupUserToICompanyAccount(
    cardGroupUser: ICardGroupUser
  ): ICompanyAccount {
    return {
      id: cardGroupUser.id,
      name: cardGroupUser.name,
      status: cardGroupUser.status === 'ACTIVE' ? 'ACTIVE' : 'BLOCKED',
      type: 'CORP_BUSINESS',
      description: 'Descrição da empresa',
      cardNumber: cardGroupUser.cardNumber || null,
      cardStatus: cardGroupUser.status === 'ACTIVE' ? 'ACTIVE' : 'BLOCKED',
      cardLockLevel: null,
      accessAccounts: [
        {
          id: cardGroupUser.userId,
          type: 'USER'
        }
      ],
      balances: [
        {
          id: cardGroupUser.balanceId,
          description: ''
        }
      ]
    };
  }

  function transformArrayICardGroupUserToICompanyAccount(
    cardGroupUsers: ICardGroupUser[]
  ): ICompanyAccount[] {
    return cardGroupUsers.map(transformICardGroupUserToICompanyAccount);
  }

  const userAccounts = paginatedUserAccounts?.data;

  const companyAccounts = !groupId
    ? paginatedCompanyAccounts?.data
    : cardGroupUsers?.data
      ? transformArrayICardGroupUserToICompanyAccount(cardGroupUsers?.data)
      : [];

  const userAccountBalancesIdsBySelectedUsers =
    userAccounts
      ?.filter(userAccount =>
        statementFilters.selectedUsers.includes(userAccount.userId)
      )
      .reduce<
        string[]
      >((acc, userAccount) => acc.concat(userAccount.account.balances.map(balance => balance.id)), []) ??
    [];

  const companyAccountBalancesIdsBySelectedUsers =
    companyAccounts
      ?.filter(companyAccount =>
        statementFilters.selectedUsers.includes(companyAccount.id)
      )
      .reduce<
        string[]
      >((acc, companyAccount) => acc.concat(companyAccount.balances.map(balance => balance.id)), []) ??
    [];

  const accountBalancesIdsToFetch =
    userAccountBalancesIdsBySelectedUsers.concat(
      companyAccountBalancesIdsBySelectedUsers
    );

  const { accountsStatements, isLoadingAccountsStatements } =
    useGetAccountStatement({
      accountBalancesIds: accountBalancesIdsToFetch,
      ...statementPayload
    });

  const cardNumbersSet = new Set<string>();

  accountsStatements.forEach(statement => {
    statement.items.forEach(transaction => {
      if (transaction.cardNumber) {
        cardNumbersSet.add(transaction.cardNumber);
      }
    });
  });

  const cardsToBeFiltered = Array.from(cardNumbersSet).map<ISelectOption>(
    cardNumber => ({
      label: CardNumber.format(cardNumber),
      value: cardNumber
    })
  );

  const { pendencies, isLoadingPendencies } = useGetPendencies({
    accountBalancesIds: accountBalancesIdsToFetch
  });

  const filteredAccountStatement: IAccountStatement<IAccountTransactionWithAdditionalInfo>[] =
    accountsStatements
      ?.map(accountStatementRow => {
        const transactionsWithUserName = accountStatementRow.items.map(
          transaction => {
            const userAccountTransactionUserName = userAccounts?.find(
              userAccount => userAccount.userId === transaction.userId
            )?.userName;

            const companyAccountTransactionUserName = companyAccounts?.find(
              companyAccount => companyAccount.id === transaction.userId
            )?.name;

            const transactionPendency = pendencies?.find(
              pendency => pendency.transactionId === transaction.transactionId
            );

            return {
              ...transaction,
              userName:
                userAccountTransactionUserName ??
                companyAccountTransactionUserName ??
                '',
              pendency: transactionPendency
            };
          }
        );

        const filteredTransactions =
          Statement.filterAccountTransactionsWithUserName(
            transactionsWithUserName,
            statementFilters.selectedCards,
            statementFilters.selectedMovementType,
            statementFilters.searchInput
          );

        const adjustedAccountStatementRow = {
          ...accountStatementRow,
          items: filteredTransactions
        };

        return adjustedAccountStatementRow;
      })
      .filter(accountStatementRow => accountStatementRow.items.length > 0);

  const noTransactionsMessage = useMemo(() => {
    return (
      <CentralMessage
        iconName='arrow-left-right'
        title={lang.cards.statement.no_transactions_found[currentLangKey]}
        description={
          lang.cards.statement.transactions_will_be_listed_here[currentLangKey]
        }
      />
    );
  }, [lang.cards.statement, currentLangKey]);

  function centerElementToRender(): JSX.Element {
    if (checkedAllUsers) {
      return (
        <CentralMessage
          iconName='file-3'
          title={lang.cards.statement.all_filter_selected[currentLangKey]}
          description={lang.cards.statement.all_filter_explain[currentLangKey]}
        />
      );
    }

    if (companyStatementQueryEnabled && !isLoadingCompanyStatement) {
      if (filteredCompanyStatement.length === 0) {
        return noTransactionsMessage;
      }

      return (
        <StatementTable
          showDayBalance={true}
          data={filteredCompanyStatement}
          type='company'
        />
      );
    }

    if (
      !companyStatementQueryEnabled &&
      !isLoadingAccountsStatements &&
      !isLoadingPendencies
    ) {
      if (filteredAccountStatement.length === 0) {
        return noTransactionsMessage;
      }

      return (
        <StatementTable
          showDayBalance={statementFilters.selectedUsers.length === 1}
          data={filteredAccountStatement}
          type='account'
        />
      );
    }

    return <></>;
  }

  function generateDownloadLink(url: string, openInNewTab: boolean): void {
    const link = document.createElement('a');
    link.download = 'true';
    link.href = url;
    if (openInNewTab) link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function handleGenerateStatementInFile(
    type: 'pdf' | 'excel',
    filters: IAdminStatementFilters
  ): void {
    const userAccountBalancesIdsBySelectedUsers =
      userAccounts
        ?.filter(userAccount =>
          filters.selectedUsers.includes(userAccount.userId)
        )
        .reduce<
          string[]
        >((acc, userAccount) => acc.concat(userAccount.account.balances.map(balance => balance.id)), []) ??
      [];

    const companyAccountBalancesIdsBySelectedUsers =
      companyAccounts
        ?.filter(companyAccount =>
          filters.selectedUsers.includes(companyAccount.id)
        )
        .reduce<
          string[]
        >((acc, companyAccount) => acc.concat(companyAccount.balances.map(balance => balance.id)), []) ??
      [];

    const accountBalancesIdsToFetch =
      userAccountBalancesIdsBySelectedUsers.concat(
        companyAccountBalancesIdsBySelectedUsers
      );

    const statementPayload = {
      startDate: format(filters.dateFilter.from as Date, 'yyyy-MM-dd').concat(
        'T00:00:00.000Z'
      ),
      endDate: format(filters.dateFilter.to as Date, 'yyyy-MM-dd').concat(
        'T00:00:00.000Z'
      ),
      timezone: CustomDate.getTimezone()
    };

    const generateFilePayload = {
      ...statementPayload,
      accountBalancesIds: accountBalancesIdsToFetch,
      checkedAllUsers,
      companyAccounts: companyAccounts ?? [],
      userAccounts: userAccounts ?? [],
      searchInput: filters.searchInput,
      selectedMovementType: filters.selectedMovementType,
      selectedCards: filters.selectedCards
    };

    generateStatementInFile(
      {
        ...generateFilePayload,
        fileType: type
      },
      {
        onSuccess({ url }) {
          generateDownloadLink(url, true);
        }
      }
    );
  }

  return {
    statementFilters,
    setStatementFilters,
    isLoadingStatement:
      (companyStatementQueryEnabled && isLoadingCompanyStatement) ||
      (!companyStatementQueryEnabled &&
        (isLoadingAccountsStatements || isLoadingPendencies)),
    checkedAllUsers,
    setCheckedAllUsers,
    centerElementToRender: centerElementToRender(),
    handleGenerateStatementInFile,
    isGeneratingFile: isGeneratingStatementInFile,
    cardsToBeFiltered
  };
}
