import { useLangContext } from 'data/contexts';
import { useChangeBusinessCardApprovalFlow } from 'data/modules/cards/businessCard';

import { Select } from 'presentation/components/base/Select';
import { Loader } from 'presentation/components/global/Loader';

import { useApprovalFlowManageBusinessCard } from './useApprovalFlowManageBusinessCard';

import { type IApprovalFlowManageBusinessCardProps } from './useApprovalFlowManageBusinessCard.types';

import {
  Container,
  CustomButton,
  FormContainer
} from './ApprovalFlowManageBusinessCard.styles';

export function ApprovalFlowManageBusinessCard({
  card
}: IApprovalFlowManageBusinessCardProps): JSX.Element {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const { approvalFlowsOptions, isLoadingApprovalFlows } =
    useApprovalFlowManageBusinessCard();

  const { control, errors, handleSubmit, isLoadingApprovalFlow } =
    useChangeBusinessCardApprovalFlow({ card });

  return (
    <Container>
      {isLoadingApprovalFlow && <Loader isLoading />}
      <FormContainer>
        <Select
          name='approvalFlow'
          options={approvalFlowsOptions}
          label={lang.settings.manageBusinessCard.approval_flow[currentLangKey]}
          placeholder={
            lang.settings.manageBusinessCard.general_flow[currentLangKey]
          }
          control={control}
          error={errors.approvalFlow?.message}
          isLoading={isLoadingApprovalFlows}
        />

        <CustomButton
          color='success'
          onClick={handleSubmit}
        >
          {lang.global.save[currentLangKey]}
        </CustomButton>
      </FormContainer>
    </Container>
  );
}
