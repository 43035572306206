import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type AppliedFiltersContainerStyleType } from './AppliedFiltersContainer.types';

export const Container = styled.div<
  AddPrefixToType<AppliedFiltersContainerStyleType>
>`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.spacingMd};
  padding: ${tokens.spacingMd};
  border-radius: ${tokens.borderRadiusMd};
  background: ${tokens.neutralColorHighPure};
  border: 1px solid ${tokens.neutralColorHighDark};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    cursor: pointer;
  }

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          ${Label} > svg {
            transform: rotate(-180deg);
          }

          ${InfoText} {
            overflow: hidden;
            max-height: 0;
            opacity: 0;
          }
        `
      : css`
          ${Content} {
            @media screen and (max-width: ${({ theme }) =>
                theme.breakpoints.mobile}px) {
              overflow: hidden;
              max-height: 0;
              opacity: 0;
            }
          }
        `}
`;

export const Label = styled.strong`
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  justify-content: space-between;
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightSemibold};

  > svg {
    transition: all 0.25s ease-in-out;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    > svg {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: ${tokens.spacingXs};
  align-items: flex-end;
  justify-content: space-between;

  > button {
    white-space: nowrap;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    align-items: flex-start;
    gap: 0;

    > button {
      margin-top: ${tokens.spacingXs};
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 100vh;
  align-items: flex-start;
  align-content: flex-start;
  gap: ${tokens.spacingXxs};

  opacity: 1;
  transition: all 0.25s ease-in-out;
`;

export const InfoText = styled.p`
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  justify-content: space-between;
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowDark};
  font-weight: ${tokens.fontWeightMedium};
  font-family: ${tokens.fontFamilyPoppins};

  opacity: 1;
  max-height: 4rem;
  transition: all 0.25s ease-in-out;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    display: none;
  }
`;
