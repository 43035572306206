import {
  DefaultButton,
  DSIcons,
  MultiSelect,
  SecondaryButton,
  TextInput
} from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import {
  useAuthContext,
  useCardsContext,
  useFlagsContext,
  useModalContext
} from 'data/contexts';
import { type CardType } from 'data/contexts/cards/cards/useCardsContext.types';

import { BaseIcon } from 'presentation/components/base/Icon';

import { type ISearchAndFiltersProps } from './SearchAndFilters.types';

import {
  CardsModeSwitch,
  Container,
  ControllersContainer,
  DisplayModeLabel,
  DisplayModeLabelTourReference,
  DisplayModeSwitchSelectorContainer,
  ListModeSwitch,
  SearchAndFiltersContainer,
  TableDisplayModeSelectorContainer
} from './SearchAndFilters.styles';

export function SearchAndFilters({
  ...rest
}: ISearchAndFiltersProps): JSX.Element {
  const { t } = useTranslation('cards');

  const user = useAuthContext(state => state.user);

  const [listLayout, filters, handleChangeListLayout, handleChangeFilters] =
    useCardsContext(
      useShallow(state => [
        state.listLayout,
        state.filters,
        state.handleChangeListLayout,
        state.handleChangeFilters
      ])
    );

  const { handleOpenModal } = useModalContext();

  const cardGroupsFlag = useFlagsContext(state => state.cardGroupsFlag);

  return (
    <Container {...rest}>
      <SearchAndFiltersContainer>
        <TextInput.Default
          label={t('searchCard')}
          placeholder={t('searchCard')}
          value={filters.searchTerm ?? ''}
          onChangeValue={value => {
            handleChangeFilters({
              searchTerm: value.trim() === '' ? null : value
            });
          }}
          onClearValue={() => {
            handleChangeFilters({ searchTerm: null });
          }}
          iconLeft='SearchIcon'
          small
        />

        <MultiSelect.Default
          hasArrowDownIndicator
          name='card-type'
          value={filters.cardType ?? []}
          label={t('cardType')}
          placeholder={t('cardType')}
          onChange={value => {
            handleChangeFilters({
              cardType: value as CardType[]
            });
          }}
          options={[
            {
              value: 'INDIVIDUAL',
              label: t('individual')
            },
            {
              value: 'BUSINESS',
              label: t('business')
            }
          ]}
          small
        />
      </SearchAndFiltersContainer>

      <ControllersContainer>
        <TableDisplayModeSelectorContainer>
          <DisplayModeLabel>
            <DisplayModeLabelTourReference className='cards-tour-preview-button'>
              {t('viewMode')}
            </DisplayModeLabelTourReference>
            {t('viewMode')}
          </DisplayModeLabel>

          <DisplayModeSwitchSelectorContainer>
            <ListModeSwitch
              onClick={() => {
                handleChangeListLayout('LIST');
              }}
              $tableDisplayMode={listLayout}
            >
              <DSIcons.ListLayoutIcon />
            </ListModeSwitch>

            <CardsModeSwitch
              onClick={() => {
                handleChangeListLayout('CARDS');
              }}
              $tableDisplayMode={listLayout}
            >
              <DSIcons.GridLayoutIcon />
            </CardsModeSwitch>
          </DisplayModeSwitchSelectorContainer>
        </TableDisplayModeSelectorContainer>

        {user?.cards?.type === 'ACCOUNT_MANAGER' && (
          <SecondaryButton
            onClick={() => {
              handleOpenModal('requestCards');
            }}
            size='small'
          >
            {t('requestCards')}
          </SecondaryButton>
        )}

        {user?.cards?.type === 'ACCOUNT_MANAGER' && cardGroupsFlag && (
          <DefaultButton
            size='small'
            onClick={() => {
              handleOpenModal('cardsGroupCreation');
            }}
          >
            <BaseIcon name='add' />
            {t('createNewGroup')}
          </DefaultButton>
        )}
      </ControllersContainer>
    </Container>
  );
}
