import {
  DangerButton,
  DefaultButton,
  DSIcons,
  Modal,
  SecondaryButton,
  Stepper,
  TextButton
} from 'ds';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { useLangContext, useModalContext } from 'data/contexts';

import { AlertModal } from 'presentation/components/global/AlertModal';
import { Loader } from 'presentation/components/global/Loader';
import { CreateReportForm } from 'presentation/pages/expenses/ExpensesList/components/CreateReportForm';
import {
  FirstStepForm,
  SecondStepForm
} from 'presentation/pages/expenses/ExpensesList/components/Modal/CreateManualRouteExpenseModal';

import { animations } from 'shared/constants/animations';

import { useCreateManualRouteExpenseModal } from './useCreateManualRouteExpenseModal';

import { type ICreateManualRouteExpenseModalProps } from './CreateManualRouteExpenseModal.types';

import {
  ContainerWithMotion,
  CreateReportButtonContainer,
  ModalContent,
  ModalFooter
} from './CreateManualRouteExpenseModal.styles';

export function CreateManualRouteExpenseModal({
  expenseUuidToUpdate
}: ICreateManualRouteExpenseModalProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  const { handleCloseModal } = useModalContext();

  const { t } = useTranslation('expenses');

  const {
    step,
    firstStepForm,
    submitButtonDisabled,
    handleGoBackButton,
    handleChangeFormExpenseCurrency,
    isExpenseCurrencyDifferentFromReportCurrency,
    setStep,
    secondStepForm,
    handleClearSelection,
    selectedReport,
    confirmedReportInformationBySelectedReport,
    handleUpdateExpenseDataBySelectedReport,
    readOnlyFieldsWhenUserIsUpdatingRoute,
    isShowingModalLoading,
    showGoBackModal,
    setShowGoBackModal,
    handleSubmitReportForm,
    setShowSeparateReportWarningModal,
    showSeparateReportWarningModal,
    setShowCreateReportForm,
    showPreRegisteredSectionsField,
    userAction,
    showCreateReportForm,
    allowUserChangeExpenseProjectByReportProject,
    firstStepSelectedCostsCenterId,
    firstStepSelectedPaymentMethodId,
    firstStepSelectedProjectId,
    updateFormAfterReportCreated,
    handleClickOverlay,
    handleConfirmGoBackModal
  } = useCreateManualRouteExpenseModal({
    expenseUuidToUpdate
  });

  const stepperComponentToShowOnCreateReportForm = (
    <Stepper.Container>
      <Stepper.Item
        active={false}
        checked={true}
        allowClick={true}
        onClick={() => {
          setShowCreateReportForm(false);
          setStep('defaultFields');
        }}
        label={
          lang.expenses.modal.create_manual_expense.create_expense[
            currentLangKey
          ]
        }
        number={1}
      />
      <Stepper.Item
        active={true}
        allowClick={false}
        label={lang.reports.modal.create_report.create_report[currentLangKey]}
        number={2}
      />
    </Stepper.Container>
  );

  return (
    <>
      <Loader isLoading={isShowingModalLoading} />
      <Modal.Container
        variant='large'
        onClose={handleClickOverlay}
        containerArrowsStyle={{
          maxWidth: '100%'
        }}
        style={{
          maxWidth: '100%'
        }}
      >
        {showCreateReportForm && (
          <AnimatePresence>
            <ContainerWithMotion {...animations.fadeInOut}>
              <CreateReportForm
                stepperComponent={stepperComponentToShowOnCreateReportForm}
                onClickGoBackButton={() => {
                  setShowCreateReportForm(false);
                  setStep('selectReport');
                }}
                onReportCreated={updateFormAfterReportCreated}
              />
            </ContainerWithMotion>
          </AnimatePresence>
        )}

        {!showCreateReportForm && (
          <AnimatePresence>
            <ContainerWithMotion {...animations.fadeInOut}>
              <Modal.Header>
                <Modal.Title>
                  {step === 'defaultFields'
                    ? userAction === 'create'
                      ? lang.expenses.modal.create_manual_route_expense
                          .new_expense[currentLangKey]
                      : t('modal.editRoute')
                    : lang.expenses.modal.select_report[currentLangKey]}
                </Modal.Title>
                <Modal.IconClose onClick={handleCloseModal} />
              </Modal.Header>
              <Modal.Form
                methods={
                  step === 'defaultFields'
                    ? firstStepForm.methods
                    : secondStepForm.methods
                }
                handleSubmit={
                  step === 'defaultFields'
                    ? firstStepForm.handleSubmit
                    : handleSubmitReportForm
                }
              >
                <Modal.Content>
                  <ModalContent>
                    <Stepper.Container>
                      <Stepper.Item
                        active={step === 'defaultFields'}
                        checked={step !== 'defaultFields'}
                        allowClick={step !== 'defaultFields'}
                        onClick={() => {
                          handleGoBackButton();
                        }}
                        label={
                          userAction === 'create'
                            ? lang.expenses.modal.create_manual_expense
                                .create_expense[currentLangKey]
                            : t('modal.editRoute')
                        }
                        number={1}
                      />
                      <Stepper.Item
                        active={step === 'selectReport'}
                        allowClick={false}
                        label={
                          lang.reports.global.select_report[currentLangKey]
                        }
                        number={2}
                      />
                    </Stepper.Container>

                    {step === 'defaultFields' && (
                      <FirstStepForm
                        showPreRegisteredSectionsField={
                          showPreRegisteredSectionsField
                        }
                        readOnlyFieldsWhenUserIsUpdatingRoute={
                          readOnlyFieldsWhenUserIsUpdatingRoute
                        }
                        onChangeFormExpenseCurrency={
                          handleChangeFormExpenseCurrency
                        }
                        expenseUuidToUpdate={expenseUuidToUpdate}
                      />
                    )}
                    {step === 'selectReport' && (
                      <SecondStepForm
                        onUpdateExpenseDataBySelectedReport={
                          handleUpdateExpenseDataBySelectedReport
                        }
                        allowUserChangeExpenseProjectByReportProject={
                          allowUserChangeExpenseProjectByReportProject
                        }
                        isExpenseCurrencyDifferentFromReportCurrency={
                          isExpenseCurrencyDifferentFromReportCurrency
                        }
                        confirmedReportInformationBySelectedReport={
                          confirmedReportInformationBySelectedReport
                        }
                        expenseUuidToUpdate={expenseUuidToUpdate}
                        onClickToEditExpenseCurrency={handleGoBackButton}
                        firstStepSelectedCostsCenterId={
                          firstStepSelectedCostsCenterId
                        }
                        firstStepSelectedPaymentMethodId={
                          firstStepSelectedPaymentMethodId
                        }
                        firstStepSelectedProjectId={firstStepSelectedProjectId}
                      />
                    )}
                  </ModalContent>
                </Modal.Content>
                <Modal.Footer className='modal-footer'>
                  {step === 'selectReport' && (
                    <CreateReportButtonContainer>
                      <TextButton
                        size='small'
                        type='button'
                        onClick={() => {
                          setShowCreateReportForm(true);
                        }}
                        className='create-report-button'
                      >
                        <DSIcons.DocumentFileAddPlusCenter />
                        {lang.expenses.modal.create_report[currentLangKey]}
                      </TextButton>
                    </CreateReportButtonContainer>
                  )}

                  <ModalFooter>
                    <SecondaryButton
                      size='small'
                      onClick={handleGoBackButton}
                      type='button'
                    >
                      {lang.global.back[currentLangKey]}
                    </SecondaryButton>
                    <div>
                      {step === 'selectReport' && !!selectedReport && (
                        <DangerButton
                          size='small'
                          type='button'
                          onClick={handleClearSelection}
                        >
                          {lang.global.clear_selection[currentLangKey]}
                        </DangerButton>
                      )}
                      <DefaultButton
                        size='small'
                        type='submit'
                        disabled={submitButtonDisabled}
                        className='create-expense-button-submit'
                      >
                        {step === 'defaultFields' &&
                          lang.global.advance[currentLangKey]}

                        {step === 'selectReport' &&
                          userAction === 'create' &&
                          !!selectedReport &&
                          lang.expenses.modal.create_manual_expense
                            .create_expense[currentLangKey]}

                        {step === 'selectReport' &&
                          userAction === 'create' &&
                          !selectedReport &&
                          lang.expenses.modal.create_manual_expense
                            .create_separate_expense[currentLangKey]}

                        {step === 'selectReport' &&
                          userAction === 'update' &&
                          t('modal.save')}
                      </DefaultButton>
                    </div>
                  </ModalFooter>
                </Modal.Footer>
              </Modal.Form>
            </ContainerWithMotion>
          </AnimatePresence>
        )}
      </Modal.Container>

      {showGoBackModal && (
        <AlertModal
          cancelButtonProps={{
            onCancel: () => {
              setShowGoBackModal(false);
            }
          }}
          confirmButtonProps={{
            onConfirm: handleConfirmGoBackModal
          }}
          icon={<DSIcons.WarningConfirmationIcon />}
        >
          {expenseUuidToUpdate
            ? t('modal.ifYouGoBackNowUpdate')
            : lang.expenses.modal.create_manual_expense.if_you_go_back_now[
                currentLangKey
              ]}
        </AlertModal>
      )}

      {showSeparateReportWarningModal && (
        <>
          <Loader isLoading={isShowingModalLoading} />

          <AlertModal
            cancelButtonProps={{
              onCancel: () => {
                setShowSeparateReportWarningModal(false);
              }
            }}
            confirmButtonProps={{
              onConfirm: () => {
                secondStepForm.handleSubmit();
              }
            }}
            icon={<DSIcons.WarningConfirmationIcon />}
          >
            {
              lang.expenses.modal.create_manual_expense
                .are_you_sure_to_create_separate_expense[currentLangKey]
            }
          </AlertModal>
        </>
      )}
    </>
  );
}
