import {
  type IAnalysisUserExpense,
  type IAnalysisUserList,
  type IAnalysisUserListPayload,
  type IPersistenceAnalysisUserList,
  type IPersistenceAnalysisUserListPayload
} from 'data/modules/analytics';

import { Currency } from 'shared/utils/format';

class GetAnalysisUserListMapper {
  toPersistence(
    domain: IAnalysisUserListPayload
  ): IPersistenceAnalysisUserListPayload {
    return {
      page: domain.page,
      users: domain.users,
      status: domain.status,
      per_page: domain.perPage,
      projects: domain.projects,
      final_date: domain.finalDate,
      cost_centers: domain.costCenters,
      initial_date: domain.initialDate,
      payment_form: domain.paymentForm,
      payment_type: domain.paymentType,
      report_codes: domain.reportCodes,
      expense_types: domain.expenseTypes,
      date_to_consider: domain.dateToConsider
    };
  }

  toDomain(persistence: IPersistenceAnalysisUserList): IAnalysisUserList {
    return {
      name: persistence.name,
      userId: persistence.user_id,
      totalExpenses: persistence.total_expenses,
      departmentPosition: persistence.department_position ?? '',
      total: Currency.parseToFloat(persistence?.total ?? '0'),
      hasPendingConversion: persistence.has_pending_conversion,
      sumRefundable: Currency.parseToFloat(persistence.sum_refundable ?? '0'),
      sumNoRefundable: Currency.parseToFloat(
        persistence.sum_no_refundable ?? '0'
      ),
      averagePerExpense: Currency.parseToFloat(
        persistence.average_per_expense ?? '0'
      ),
      reports: persistence?.reports
        ? Object.entries(persistence.reports).reduce(
            (reports, [reportId, persistenceReport]) => ({
              ...reports,
              [reportId]: {
                reportId: persistenceReport.report_id,
                reportUuid: persistenceReport.report_uuid,
                allExpensesReturned: persistenceReport.all_expenses_returned,
                approvalFlowName: persistenceReport.approval_flow_name,
                reportName: persistenceReport.report_name,
                reportObservation: persistenceReport.report_observation,
                reportStatus: persistenceReport.report_status,
                reportSumNoRefundable: Currency.parseToFloat(
                  persistenceReport.report_sum_no_refundable ?? '0'
                ),
                reportSumNoRefundableConverted:
                  persistenceReport?.report_sum_non_refundable_converted
                    ? Currency.parseToFloat(
                        persistenceReport.report_sum_non_refundable_converted ??
                          '0'
                      )
                    : null,
                reportSumRefundable: Currency.parseToFloat(
                  persistenceReport.report_sum_refundable ?? '0'
                ),
                reportSumRefundableConverted:
                  persistenceReport?.report_sum_refundable_converted
                    ? Currency.parseToFloat(
                        persistenceReport.report_sum_refundable_converted ?? '0'
                      )
                    : null,
                reportTotal: Currency.parseToFloat(
                  persistenceReport.report_total ?? '0'
                ),
                reportTotalConverted: persistenceReport.report_total_converted
                  ? Currency.parseToFloat(
                      persistenceReport.report_total_converted ?? '0'
                    )
                  : null,
                originalCurrency: persistenceReport.original_currency,
                convertedCurrency: persistenceReport.converted_currency,
                expenses: persistenceReport.expenses?.map<IAnalysisUserExpense>(
                  persistenceExpense => ({
                    expenseId: persistenceExpense.expense_id,
                    expenseDate: persistenceExpense.expense_date,
                    creatorName: persistenceExpense.creator_name,
                    expenseTitle: persistenceExpense.expense_title,
                    expenseType: persistenceExpense.expense_type,
                    expenseCostsCenter: persistenceExpense.expense_costs_center,
                    expenseProject: persistenceExpense.expense_project,
                    expensePaymentMethod:
                      persistenceExpense.expense_payment_method,
                    refundablePaymentMethod: Boolean(
                      persistenceExpense.refundable_payment_method
                    ),
                    originalCurrency: persistenceExpense.original_currency,
                    expenseValue: Currency.parseToFloat(
                      persistenceExpense.expense_value ?? '0'
                    ),
                    convertedCurrency: persistenceExpense.converted_currency,
                    convertedValue: persistenceExpense?.converted_value
                      ? Currency.parseToFloat(
                          persistenceExpense.converted_value
                        )
                      : null,
                    observationsCount: persistenceExpense.observations_count,
                    expenseImage: persistenceExpense.expense_image,
                    expenseImageType: persistenceExpense.expense_image_type,
                    hasPendingConversion:
                      persistenceExpense.has_pending_conversion
                  })
                )
              }
            }),
            {}
          )
        : undefined
    };
  }
}

export default new GetAnalysisUserListMapper();
