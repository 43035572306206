import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IRequestCardsFieldsForm } from 'data/modules/cards/balance';

export function useRequestCardsSchema(): ObjectSchema<IRequestCardsFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const requestCardsSchema: ObjectSchema<IRequestCardsFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];
      const fieldMatchesMessage = lang.schemas.invalid_value[currentLangKey];

      return yup.object({
        receiver: yup.string().required(fieldRequiredMessage),
        postalCode: yup
          .string()
          .required(fieldRequiredMessage)
          .matches(/^[0-9]{5}-[0-9]{3}$/, fieldMatchesMessage),
        street: yup.string().required(fieldRequiredMessage),
        number: yup.string().required(fieldRequiredMessage),
        complement: yup.string(),
        neighborhood: yup.string().required(fieldRequiredMessage),
        city: yup.string().required(fieldRequiredMessage),
        state: yup.string().required(fieldRequiredMessage),
        phoneNumber: yup
          .string()
          .required(fieldRequiredMessage)
          .matches(
            /^\(((\d{2}))\)\s((\d{1})?)\s?(\d{4})-\d{4}$/g,
            fieldMatchesMessage
          ),
        quantity: yup
          .number()
          .typeError(fieldRequiredMessage)
          .required(fieldRequiredMessage)
      });
    }, [lang, currentLangKey]);

  return requestCardsSchema;
}
