import {
  type IAttachTheCard,
  type IPersistenceAttachTheCard
} from 'data/modules/cards/businessCard';

class AttachTheCardMapper {
  toPersistence(domain: IAttachTheCard): IPersistenceAttachTheCard {
    return {
      card_id: domain.cardId.toUpperCase()
    };
  }
}

export default new AttachTheCardMapper();
