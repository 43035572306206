import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IConfiguration,
  type IUseUpdateCompanyOnboardingMembersConfiguration,
  type IUseUpdateCompanyOnboardingMembersConfigurationParams,
  type UpdateCompanyOnboardingAnswersPayloadType
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

export function useUpdateCompanyOnboardingMembersConfiguration({
  onAfterUpdatingMembersConfigurationSuccessfully
}: IUseUpdateCompanyOnboardingMembersConfigurationParams): IUseUpdateCompanyOnboardingMembersConfiguration {
  const [currentLangKey, finishModal] = useLangContext(state => [
    state.currentLangKey,
    state.lang.cards.onboarding.company.managers_and_cardholders
      .managers_and_cardholders_list.finish_modal
  ]);

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const { mutate, isLoading } = useMutationCache({
    mutationKey: [
      CompanyOnboardingMutationKeys.UPDATE_COMPANY_ONBOARDING_ANSWERS
    ],
    mutationFn: async (payload: UpdateCompanyOnboardingAnswersPayloadType) => {
      await CompanyOnboardingService.updateCompanyOnboardingAnswers(payload);
    },
    onSuccess: (data, variables) => {
      onAfterUpdatingMembersConfigurationSuccessfully(
        variables.answers as IConfiguration['usersToUseCards']
      );
    },
    onError: () => {
      toast.error(finishModal.no_configuration_found[currentLangKey]);
    }
  });

  async function handleUpdateCompanyOnboardingMembersConfiguration(
    configuratedMembersData: IConfiguration['usersToUseCards']
  ): Promise<void> {
    mutate({
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '',
      context: 'configuration',
      step: currentStep,
      answers: {
        usersToUseCards: configuratedMembersData
      }
    });
  }

  return {
    handleUpdateCompanyOnboardingMembersConfiguration,
    isUpdatingCompanyOnboardingMembersConfiguration: isLoading
  };
}
