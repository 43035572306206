import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${tokens.spacingLg};

  svg {
    width: 37rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    svg {
      width: 80%;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXxs};

  p {
    text-align: center;
    font-style: normal;
    line-height: 2.4rem;
    letter-spacing: 0.075rem;
    font-size: ${tokens.fontSizeXxs};
    font-weight: ${tokens.fontWeightMedium};
    color: ${tokens.neutralColorLowDarkest};
    font-family: ${tokens.fontFamilyPoppins};
  }
`;
