import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type ContainerStylesType } from './ContentPlaceholder.types';

export const Container = styled.div<AddPrefixToType<ContainerStylesType>>`
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  padding: 0 2rem;
  color: ${({ theme }) => theme.colors.neutral};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: ${({ $justifyContent }) => $justifyContent};
  align-items: center;

  > strong {
    font-size: 2rem;
    line-height: 3rem;
    margin: 3rem 0 2rem;
  }
`;
