import { Pagination, SkeletonLoader } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useCardsContext, useLangContext } from 'data/contexts';

import { type ITablePaginationProps } from './TablePagination.types';

import {
  Container,
  StyledControl,
  StyledPaginationContainer
} from './TablePagination.styles';

export function TablePagination({
  ...rest
}: ITablePaginationProps): JSX.Element {
  const [{ page, limit, total }, listLayout, handleChangePagination] =
    useCardsContext(
      useShallow(state => [
        state.pagination,
        state.listLayout,
        state.handleChangePagination
      ])
    );

  const currentLangKey = useLangContext(state => state.currentLangKey);

  const totalPages = total && Math.ceil(total / limit);

  return (
    <Container {...rest}>
      <StyledPaginationContainer
        $isCardsMode={listLayout === 'CARDS'}
        currentPage={page}
        perPage={limit}
        totalPage={totalPages ?? 1}
      >
        <Pagination.Label langKey={currentLangKey} />

        {!total ? (
          <SkeletonLoader
            margin='1rem 0'
            height='3rem'
            width='100px'
          />
        ) : (
          <StyledControl
            onChangePage={page => {
              handleChangePagination({ page });
            }}
          />
        )}

        <Pagination.Pages
          langKey={currentLangKey}
          onChangePerPage={limit => {
            handleChangePagination({ limit });
          }}
          options={[10, 20, 50]}
        />
      </StyledPaginationContainer>
    </Container>
  );
}
