import { useMemo } from 'react';

import { localStorageKeys } from 'shared/constants/global';

import {
  type IUsePagination,
  type UsePaginationParamsType
} from './Pagination.types';

export function usePagination({
  total,
  pageSize,
  onChangePageSize
}: UsePaginationParamsType): IUsePagination {
  const pageSizeStorage = localStorage.getItem(localStorageKeys.pageSize);

  if (!pageSizeStorage) {
    localStorage.setItem(localStorageKeys.pageSize, '10');
  }

  const lastPage = Math.ceil(total / pageSize);

  function handleChangePageSize(value: string): void {
    onChangePageSize(parseInt(value) as 10 | 50);
    localStorage.setItem(localStorageKeys.pageSize, value);
  }

  const pageSizeOptions = useMemo(() => {
    return [
      { value: '10', label: '10' },
      {
        value: '50',
        label: '50'
      }
    ];
  }, []);

  return {
    lastPage,
    handleChangePageSize,
    pageSizeOptions
  };
}
