import { useLangContext, useModalContext } from 'data/contexts';
import { useAttachTeamMembersToBusinessCard } from 'data/modules/cards/businessCard';

import { Modal } from 'presentation/components/base/Modal';
import { Select } from 'presentation/components/base/Select';

import { useAttachTeamMembersToBusinessCardModal } from './useAttachTeamMembersToBusinessCardModal';

import { type IAttachTeamMembersToBusinessCardModalProps } from './AttachTeamMembersToBusinessCardModal.types';

import {
  ActionsList,
  Container,
  Wrapper
} from './AttachTeamMembersToBusinessCardModal.styles';

export function AttachTeamMembersToBusinessCardModal({
  uuid
}: IAttachTeamMembersToBusinessCardModalProps): JSX.Element {
  const { visibleModal, handleCloseModal, handleOpenModal } = useModalContext();

  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { clearErrors, control, errors, isLoading, handleSubmit, reset } =
    useAttachTeamMembersToBusinessCard({ uuid });

  const { teamMembersOptions, isLoadingTeamMembers } =
    useAttachTeamMembersToBusinessCardModal();

  return (
    <Modal
      isOpen={visibleModal === 'teamMember'}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      onClose={() => {
        handleCloseModal();
        clearErrors();
        reset();
      }}
      title={
        lang.settings.businessCards.successful_team_members[currentLangKey]
      }
      description={
        lang.settings.businessCards
          .which_team_members_will_be_able_to_manage_expenses_for_this_card[
          currentLangKey
        ]
      }
      primaryButton={{
        children: lang.global.confirm[currentLangKey],
        color: 'success'
      }}
      secondaryButton={{
        children: lang.forms.previous[currentLangKey],
        color: 'danger',
        onClick: () => {
          handleOpenModal('setUpAccountability');
        }
      }}
    >
      <Container>
        <Wrapper>
          <Select
            isMulti
            name='teamMembers'
            options={teamMembersOptions}
            label={
              lang.settings.businessCards.select_team_members[currentLangKey]
            }
            placeholder={
              lang.settings.businessCards.search_members[currentLangKey]
            }
            isLoading={isLoadingTeamMembers}
            control={control}
            error={errors.teamMembers?.message}
          />

          <ActionsList>
            <li>
              {
                lang.settings.businessCards.actions_allowed_for_execution[
                  currentLangKey
                ]
              }
            </li>
            <li>
              {lang.settings.businessCards.actions_expenses[currentLangKey]}
            </li>
            <li>
              {lang.settings.businessCards.actions_reports[currentLangKey]}
            </li>
            <li>
              {lang.settings.businessCards.actions_approvals[currentLangKey]}
            </li>
            <li>
              {lang.settings.businessCards.actions_advances[currentLangKey]}
            </li>
            <li>{lang.settings.businessCards.actions_cards[currentLangKey]}</li>
          </ActionsList>
        </Wrapper>
      </Container>
    </Modal>
  );
}
