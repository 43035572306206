import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useAnalysisUserFiltersContext } from 'data/contexts';

import { AnalysisSummaryCard } from 'presentation/pages/analytics/Analysis/components/AnalysisSummaryCard';
import { ConversionNotice } from 'presentation/pages/analytics/Analysis/components/ConversionNotice';
import { EmptyAnalysisResults } from 'presentation/pages/analytics/Analysis/components/EmptyAnalysisResults';
import { UserAnalysisSummaryTable } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisSummaryTable';

import { useUserAnalysisResume } from './useUserAnalysisResume';

import { type IUserAnalysisResumeProps } from './UserAnalysisResume.types';

export function UserAnalysisResume({
  isFullResume
}: IUserAnalysisResumeProps): JSX.Element {
  const { t } = useTranslation(['analytics', 'global']);

  const [isEmptyResults] = useAnalysisUserFiltersContext(
    useShallow(state => [state.isEmptyResults])
  );

  const {
    resumeCards,
    handleActionClick,
    showInformationRow,
    userAnalysisSummary,
    hasPendingConversion,
    isLoadingAnalysisResume,
    isLoadingAnalysisUserHeader
  } = useUserAnalysisResume({ isFullResume });

  return (
    <>
      {isEmptyResults ? (
        <EmptyAnalysisResults />
      ) : (
        <AnalysisSummaryCard
          cards={resumeCards}
          title={t('userSummaryCard.title')}
          isLoadingCards={isLoadingAnalysisResume}
        >
          {hasPendingConversion && (
            <ConversionNotice
              actionText={t('userSummaryCard.convertCurrencies')}
              onActionClick={handleActionClick}
            >
              {t('userSummaryCard.conversionNotice')}
            </ConversionNotice>
          )}

          <UserAnalysisSummaryTable
            isWithPagination={!!isFullResume}
            showInformationRow={showInformationRow}
            userAnalysisSummary={userAnalysisSummary}
            isLoadingAnalysisUserSummary={isLoadingAnalysisUserHeader}
          />
        </AnalysisSummaryCard>
      )}
    </>
  );
}
