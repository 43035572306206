import { useQueryCache } from 'data/cache';
import {
  AccountQueryKeys,
  AccountService,
  type IUseGetCompanyAccounts,
  type IUseGetCompanyAccountsParams
} from 'data/modules/cards/account';

export function useGetCompanyAccounts({
  enabled,
  ...payload
}: IUseGetCompanyAccountsParams): IUseGetCompanyAccounts {
  const {
    data: paginatedCompanyAccounts,
    isLoading: isLoadingCompanyAccounts,
    isFetching: isFetchingCompanyAccounts
  } = useQueryCache({
    queryKey: [AccountQueryKeys.GET_COMPANY_ACCOUNTS, payload],
    queryFn: async () => await AccountService.getCompanyAccounts(payload),
    enabled: enabled ?? true
  });

  return {
    paginatedCompanyAccounts,
    isLoadingCompanyAccounts,
    isFetchingCompanyAccounts
  };
}
