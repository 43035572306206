import { useEffect } from 'react';

import { toast } from 'ds/utils';
import { useNavigate } from 'react-router-dom';

import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUpdateCompanyOnboardingAnswersMutationSuccessParams,
  useGetLegalRepresentatives,
  useUpdatePartnerOrRepresentative
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';
import OnboardingUtil from 'shared/utils/cards/Onboarding.util';

import { type IUsePartnerOrRepresentative } from './PartnerOrRepresentative.types';

export function usePartnerOrRepresentative(): IUsePartnerOrRepresentative {
  const {
    data: cachedCompanyOnboardingProgress,
    updateCache: updateCurrentStepCache
  } = useHandleQueryCache({
    query: {
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS],
      query: async () => await CompanyOnboardingService.getProgress()
    }
  });

  const navigate = useNavigate();
  const {
    isLoadingRepresentatives,
    representatives,
    isErrorLegalRepresentatives,
    errorLegalRepresentatives
  } = useGetLegalRepresentatives();

  useEffect(() => {
    if (isErrorLegalRepresentatives) {
      toast.error(errorLegalRepresentatives?.message as string);
    }
  }, [errorLegalRepresentatives?.message, isErrorLegalRepresentatives]);

  useEffect(() => {
    if (!isLoadingRepresentatives && representatives?.length) {
      navigate('/cartoes/onboarding/empresa/3/aprovacao/representantes');
    }
  }, [isLoadingRepresentatives, navigate, representatives?.length]);

  const { control, errors, handleUpdatePartnerOrRepresentative } =
    useUpdatePartnerOrRepresentative({
      onUpdateCompanyOnboardingAnswersMutationSuccess:
        updateCacheAndNavigateNextStep
    });

  const currentStep = Number(OnboardingUtil.getCurrentStep(location.pathname));

  function updateCacheAndNavigateNextStep(
    data: IUpdateCompanyOnboardingAnswersMutationSuccessParams
  ): void {
    navigate(`/cartoes/onboarding/empresa/${currentStep + 1}`);

    if (currentStep === Number(cachedCompanyOnboardingProgress?.currentStep)) {
      updateCurrentStepCache({
        key: 'currentStep',
        newData: currentStep + 1
      });
    }
  }

  return {
    errors,
    control,
    handleUpdatePartnerOrRepresentative,
    isLoadingRepresentatives
  };
}
