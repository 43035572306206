import { BaseIcon } from 'presentation/components/base/Icon';

import { type IAlertProps } from './Alert.types';

import { Container } from './Alert.styles';

export function Alert({
  fontColor = 'white',
  backgroundColor = 'secondary',
  fontSize = 1.2,
  iconProps,
  children,
  fontWeight = 400
}: IAlertProps): JSX.Element {
  return (
    <Container
      $backgroundColor={backgroundColor}
      $fontColor={fontColor}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
    >
      {iconProps && (
        <BaseIcon name={iconProps ? iconProps.name : 'information'} />
      )}

      <span>{children}</span>
    </Container>
  );
}
