import { vexDomain } from 'data/config';

import { langJSON } from 'shared/lang';

import { Icons } from './icons.constant';

/**
 * Lista de rotas disponíveis para serem exibidos na Sidebar
 */
export const sidebarRoutes = {
  home: {
    name: langJSON.sidebar.routes.home,
    route: `${vexDomain}/inicio`,
    icon: Icons.HomeIcon,
    isReactRoute: false
  },
  reports: {
    name: langJSON.sidebar.routes.reports,
    route: `/relatorios`,
    icon: Icons.ReportsIcon,
    isReactRoute: false
  },
  approvals: {
    name: langJSON.sidebar.routes.approvals,
    route: `${vexDomain}/aprovacoes`,
    icon: Icons.ApprovalsIcon,
    isReactRoute: false
  },
  approvalsRequest: {
    name: langJSON.sidebar.routes.approvalsRequest,
    route: '/cartoes/solicitacoes-de-valores',
    icon: Icons.ApprovalsIcon,
    isReactRoute: true
  },
  cards: {
    name: langJSON.sidebar.routes.cards,
    route: '/cartoes/inicio',
    icon: Icons.CardsIcon,
    isReactRoute: true
  },
  conciliations: {
    name: langJSON.sidebar.routes.conciliations,
    route: `${vexDomain}/conciliacoes`,
    icon: Icons.ConciliationIcon,
    isReactRoute: false
  },
  withdraw: {
    name: langJSON.sidebar.routes.withdrawals_advance,
    route: `${vexDomain}/saques`,
    icon: Icons.WithdrawIcon,
    isReactRoute: false
  },
  management: {
    name: langJSON.sidebar.routes.management,
    route: `${vexDomain}/admin/dashboard-graficos`,
    icon: Icons.ManagementIcon,
    isReactRoute: false
  },
  settingsAdmin: {
    name: langJSON.sidebar.routes.settings,
    route: `${vexDomain}/settings`,
    icon: Icons.SettingsIcon,
    isReactRoute: false
  },
  settingsUser: {
    name: langJSON.sidebar.routes.settings,
    route: `${vexDomain}/settings/meus-dados`,
    icon: Icons.SettingsIcon,
    isReactRoute: false
  },
  store: {
    name: langJSON.sidebar.routes.store,
    icon: Icons.StoreIcon,
    isReactRoute: true,
    route: '/store'
  },
  support: {
    name: langJSON.sidebar.routes.support,
    icon: Icons.HelpCenterIcon,
    isReactRoute: true,
    route: '/suporte'
  },
  analytics: {
    name: langJSON.sidebar.routes.analytics,
    icon: Icons.AnalyticsIcon,
    isReactRoute: true,
    route: '/analises'
  }
};
