import { useQueryCache } from 'data/cache';
import {
  ExpensesQueryKeys,
  ExpensesService,
  type IUseGetExpenseParameters
} from 'data/modules/expenses';

export function useGetExpenseParameters(): IUseGetExpenseParameters {
  const { data: expenseParameters, isLoading: isLoadingExpenseParameters } =
    useQueryCache({
      queryKey: [ExpensesQueryKeys.GET_EXPENSE_PARAMETERS],
      queryFn: async () => await ExpensesService.getExpenseParameters()
    });

  return {
    expenseParameters,
    isLoadingExpenseParameters
  };
}
