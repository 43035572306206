import { useEffect, useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useModalContext } from 'data/contexts';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IConfiguration,
  useGetCompanyOnboardingConfigurationUsersCache,
  useGetCompanyOnboardingTeamMembers,
  useUpdateCompanyOnboardingConfigurationUsersCache,
  useUpdateCompanyOnboardingMembersConfiguration
} from 'data/modules/cards/companyOnboarding';
import { type PaginationType } from 'data/modules/global';

import { localStorageKeys } from 'shared/constants/global';
import { useDebounce, useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import {
  type IUseManagersAndCardholdersList,
  type IUserConfigurations,
  type PaginationDataType
} from './ManagersAndCardholdersList.types';

export function useManagersAndCardholdersList(): IUseManagersAndCardholdersList {
  const pageSizeStorage = localStorage.getItem(localStorageKeys.pageSize);

  const [isViewSelectedMembersEnabled, setIsViewSelectedMembersEnabled] =
    useState(false);

  const [searchTerm, setSearchTerm] = useState<string>();

  const [paginationData, setPaginationData] = useState<PaginationDataType>({
    currentPage: 1,
    pageSize:
      pageSizeStorage === '10' || pageSizeStorage === '50'
        ? (Number(pageSizeStorage) as PaginationType)
        : 10,
    total: 0
  });

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const { handleCloseModal } = useModalContext();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const debouncedSearchTerm = useDebounce(searchTerm);

  const { updateCache } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_CONFIGURATIONS_USERS_CACHE
      ],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingConfigurationUsersCache()
    }
  });

  function handleChangeSearchTerm(searchTerm: string | undefined): void {
    setSearchTerm(searchTerm);
  }

  function handleToggleViewSelectedMembers(): void {
    setIsViewSelectedMembersEnabled(prevState => !prevState);
  }

  function handleChangePage(page: number): void {
    setPaginationData(currPaginationData => ({
      ...currPaginationData,
      currentPage: page
    }));
  }

  function handleChangePageSize(pageSize: PaginationType): void {
    setPaginationData(currPaginationData => ({
      ...currPaginationData,
      pageSize
    }));
  }

  const {
    companyOnboardingConfigurationUsersCache,
    isCompanyOnboardingConfigurationUsersCacheLoading
  } = useGetCompanyOnboardingConfigurationUsersCache();

  function handleGetConfiguratedUsersUuids(): string[] {
    return companyOnboardingConfigurationUsersCache.map(
      configuratedUser => configuratedUser.uuid
    );
  }

  const {
    paginatedCompanyOnboardingTeamMembers,
    isPaginatedCompanyOnboardingTeamMembersLoading
  } = useGetCompanyOnboardingTeamMembers({
    queryPayload: {
      page: paginationData.currentPage,
      perPage: paginationData.pageSize,
      search: debouncedSearchTerm?.toLowerCase().trim(),
      ...(isViewSelectedMembersEnabled && {
        users: handleGetConfiguratedUsersUuids()
      })
    }
  });

  const parsedManagersAndCardholders = useMemo(() => {
    if (
      companyOnboardingConfigurationUsersCache.length === 0 &&
      isViewSelectedMembersEnabled
    ) {
      return [];
    }

    if (paginatedCompanyOnboardingTeamMembers?.data) {
      return paginatedCompanyOnboardingTeamMembers.data.map(teamMember => {
        const foundUserConfigurationCache =
          companyOnboardingConfigurationUsersCache.find(
            user => user.uuid === teamMember.uuid
          );

        return {
          uuid: teamMember.uuid,
          name: teamMember.name,
          email: teamMember.email,
          type: foundUserConfigurationCache?.type ?? 'USER',
          companyUuid: teamMember.companyUuid,
          mustHaveAccount: foundUserConfigurationCache?.mustHaveAccount ?? false
        };
      });
    }

    return [];
  }, [
    isViewSelectedMembersEnabled,
    paginatedCompanyOnboardingTeamMembers,
    companyOnboardingConfigurationUsersCache
  ]);

  useEffect(() => {
    if (paginatedCompanyOnboardingTeamMembers?.total) {
      setPaginationData(currPaginationData => ({
        ...currPaginationData,
        total: paginatedCompanyOnboardingTeamMembers.total
      }));
    }
  }, [paginatedCompanyOnboardingTeamMembers?.total, setPaginationData]);

  function handleAddMemberRedirection(): void {
    window.open('/settings/membros-de-equipe', '_blank');

    handleCloseModal();
  }

  function handleUpdateMembersList(): void {
    setSearchTerm(undefined);

    setPaginationData(currPaginationData => ({
      ...currPaginationData,
      currentPage: 1,
      pageSize: 10
    }));

    queryClient.invalidateQueries({
      queryKey: [
        CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_TEAM_MEMBERS,
        {
          page: 1,
          perPage: 10,
          search: undefined
        }
      ]
    });

    handleCloseModal();
  }

  const { updateCompanyOnboardingConfigurationUsersCache } =
    useUpdateCompanyOnboardingConfigurationUsersCache({
      onAfterUpdatingCacheSuccessfully: handleUpdateDomainCache
    });

  function handleUpdateDomainCache(
    newUsersConfiguration: IUserConfigurations[]
  ): void {
    updateCache({ newData: newUsersConfiguration });
  }

  async function handleChangeMemberSettings(
    newMemberSettings: IUserConfigurations
  ): Promise<void> {
    const filteredMembersSettings =
      companyOnboardingConfigurationUsersCache.filter(
        member => member.uuid !== newMemberSettings.uuid
      );

    const newMembersSettingsList = [
      ...filteredMembersSettings,
      newMemberSettings
    ];

    updateCompanyOnboardingConfigurationUsersCache(newMembersSettingsList);
  }

  const { handleUpdateCompanyOnboardingMembersConfiguration } =
    useUpdateCompanyOnboardingMembersConfiguration({
      onAfterUpdatingMembersConfigurationSuccessfully:
        updateCacheAndNavigateNextStep
    });

  const {
    data: cachedCompanyOnboardingProgress,
    updateCache: updateCurrentStepCache
  } = useHandleQueryCache({
    query: {
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS],
      query: async () => await CompanyOnboardingService.getProgress()
    }
  });

  const { updateCache: updateOnboardingAnswersCache } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      ],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingAnswers({
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    }
  });

  function updateCacheAndNavigateNextStep(
    newConfiguratedMembersData: IConfiguration['usersToUseCards']
  ): void {
    navigate(`/cartoes/onboarding/empresa/${currentStep + 1}`);

    updateOnboardingAnswersCache({
      key: 'configuration',
      newData: {
        usersToUseCards: newConfiguratedMembersData
      }
    });

    if (currentStep === Number(cachedCompanyOnboardingProgress?.currentStep)) {
      updateCurrentStepCache({
        key: 'currentStep',
        newData: currentStep + 1
      });
    }
  }

  function handleConfirmMembersConfiguration(): void {
    handleUpdateCompanyOnboardingMembersConfiguration(
      companyOnboardingConfigurationUsersCache
    );

    handleCloseModal();
  }

  return {
    managersAndCardholders: parsedManagersAndCardholders,
    isManagersAndCardholdersLoading:
      isPaginatedCompanyOnboardingTeamMembersLoading ||
      isCompanyOnboardingConfigurationUsersCacheLoading,
    paginationData,
    handleChangePage,
    handleChangePageSize,
    handleChangeSearchTerm,
    searchTerm,
    handleAddMemberRedirection,
    handleUpdateMembersList,
    handleChangeMemberSettings,
    isViewSelectedMembersEnabled,
    handleToggleViewSelectedMembers,
    handleConfirmMembersConfiguration
  };
}
