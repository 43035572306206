import { Modal, tokens } from 'ds';
import styled from 'styled-components';

export const ModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  margin-top: ${tokens.spacingXs};
  padding-right: 0;

  > div {
    justify-content: start;
  }
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${tokens.spacingInsetXxl};
`;

export const MemberListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  max-height: 35rem;
  overflow-x: hidden;
  overflow-y: auto;

  > div {
    width: 50%;
    justify-content: start;
    height: 4.1rem;

    span {
      overflow: hidden;
      max-width: 95%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const StyledModalContainer = styled(Modal.Container)`
  @media screen and (max-width: 1300px) {
    max-width: 50rem;
    width: 50rem;
  }

  @media screen and (max-width: 500px) {
    width: 40rem;
    max-width: 40rem;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
