import { tokens } from 'ds';
import styled from 'styled-components';

import { CustomNavigationWrapper } from 'presentation/pages/store/components/Carrousel/CarrouselContainer/CarrouselContainer.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.tokens.spacingLg};
  padding: ${({ theme }) => theme.tokens.spacingLg} 0;
  margin: 0 ${({ theme }) => theme.tokens.spacingXl};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${CustomNavigationWrapper} {
      display: none;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.tokens.spacingXl}
    ${({ theme }) => theme.tokens.spacingLg};
  grid-template-columns: repeat(1, minmax(0, 1fr));

  h3 {
    font-size: ${tokens.fontSizeMd};
    font-weight: ${tokens.fontWeightBold};
    font-family: ${tokens.fontFamilyPoppins};
    color: ${tokens.brandColorSecondaryMedium};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    h3 {
      grid-column: span 2;
    }
  }
`;

export const LoaderContainer = styled(Grid)`
  width: auto;

  > span {
    border-radius: ${({ theme }) => theme.tokens.borderRadiusXxl};
  }
`;

export const SlideContent = styled.div`
  color: white;
  display: flex;
  align-items: center;
`;

export const SlideImage = styled.img`
  height: 100%;
  max-width: 40%;
  object-fit: cover;
  padding-left: ${({ theme }) => theme.tokens.spacingInsetDisplay};

  @media screen and (max-width: 1250px) {
    display: none;
    visibility: hidden;
  }
`;

export const SlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.tokens.spacingNano};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};

  padding: ${({ theme }) =>
    `${theme.tokens.spacingInsetLg}
     ${theme.tokens.spacingInsetHuge}
     ${theme.tokens.spacingInsetGiant}
     ${theme.tokens.spacingInsetDisplay}`};

  & > h2 {
    font-size: ${({ theme }) => theme.tokens.fontSizeMd};
    font-weight: ${({ theme }) => theme.tokens.fontWeightSemibold};
  }

  & > p {
    font-size: ${({ theme }) => theme.tokens.fontSizeSm};
    width: 85%;
  }

  .slide-button {
    width: fit-content;
    background-color: ${({ theme }) =>
      theme.tokens.neutralColorHighLight} !important;
    margin-top: ${({ theme }) => theme.tokens.spacingSm};
  }

  @media screen and (max-width: 1650px) {
    padding-left: ${({ theme }) => theme.tokens.spacingInsetGiant};
    padding-right: ${({ theme }) => theme.tokens.spacingInsetGiant};
  }

  @media screen and (max-width: 1250px) {
    padding-left: ${({ theme }) => theme.tokens.spacingInsetDisplay};
    padding-right: ${({ theme }) => theme.tokens.spacingInsetDisplay};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-left: ${({ theme }) => theme.tokens.spacingInsetXl};
    padding-right: ${({ theme }) => theme.tokens.spacingInsetXl};

    & > h2 {
      font-size: ${({ theme }) => theme.tokens.fontSizeMd};
    }

    & > p {
      font-size: ${({ theme }) => theme.tokens.fontSizeXs};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    & > h2 {
      font-size: ${({ theme }) => theme.tokens.fontSizeSm};
    }

    & > p {
      font-size: ${({ theme }) => theme.tokens.fontSizeXxs};
    }
  }
`;
