import {
  type ICompanyOnboardingAnswers,
  type PersistenceCompanyOnboardingAnswersType
} from 'data/modules/cards/companyOnboarding';

import { CustomDate, CustomObject } from 'shared/utils/custom';
import { Mask } from 'shared/utils/format';

class GetCompanyOnboardingAnswersMapper {
  toDomain(
    persistenceCompanyOnboardingAnswers: PersistenceCompanyOnboardingAnswersType
  ): ICompanyOnboardingAnswers {
    const companyOnboardingAnswers =
      persistenceCompanyOnboardingAnswers.reduce<ICompanyOnboardingAnswers>(
        (acc, curr) => {
          switch (curr.context) {
            case 'usage_estimation':
              return {
                ...acc,
                usageEstimation: {
                  maximumTransactionValueEstimate:
                    curr.answers.maximum_transaction_value_estimate,
                  mostTransactionTypes: curr.answers.most_transaction_types,
                  averageMontlySpend: {
                    min: curr.answers.average_montly_spend?.min,
                    max: curr.answers.average_montly_spend?.max
                  },
                  dontKnowAnswerAverageSpend:
                    curr.answers.dont_know_answer_average_spend,
                  transactionsOverFifteenThousand:
                    curr.answers.transactions_over_fifteen_thousand,
                  cardsAmount: curr.answers.cards_amount
                }
              };

            case 'company_account':
              return {
                ...acc,
                companyAccount: {
                  ...CustomObject.pick(curr.answers, [
                    'city',
                    'complement',
                    'contract',
                    'country',
                    'email',
                    'neighborhood',
                    'number',
                    'state',
                    'street'
                  ]),
                  tradingName: curr.answers.trading_name,
                  foundingDate: CustomDate.formatFromISO(
                    curr.answers.founding_date
                  ),
                  legalName: curr.answers.legal_name,
                  mainActivity: Mask.apply('cnae', curr.answers.main_activity),
                  postalCode: Mask.apply('cep', curr.answers.postal_code),
                  businessType: Mask.apply(
                    'legalNature',
                    curr.answers.business_type
                  ),
                  documentNumber: Mask.apply(
                    'cnpj',
                    curr.answers.document_number
                  ),
                  phoneNumber:
                    curr.answers.phone_number &&
                    Mask.apply('phone', curr.answers.phone_number.slice(2)),
                  shareholders: curr.answers.shareholders?.map(shareholder => ({
                    ...CustomObject.pick(shareholder, [
                      'type',
                      'name',
                      'document',
                      'email',
                      'contract'
                    ]),
                    motherName: shareholder.mother_name,
                    birthDate: CustomDate.formatFromISO(shareholder.birth_date),
                    phoneNumber:
                      shareholder.phone_number &&
                      Mask.apply('phone', shareholder.phone_number.slice(2)),
                    legalName: shareholder.legal_name,
                    tradingName: shareholder.trading_name,
                    businessType: shareholder.business_type,
                    shareholderType:
                      shareholder.shareholder_type === 'legal_representative'
                        ? 'legalRepresentative'
                        : shareholder.shareholder_type,
                    mainActivity: shareholder.main_activity,
                    foundingDate: CustomDate.formatFromISO(
                      shareholder.founding_date
                    ),
                    addresses: shareholder.addresses?.map(address => ({
                      ...CustomObject.omit(address, ['postal_code']),
                      postalCode: Mask.apply('cep', address.postal_code)
                    }))
                  }))
                }
              };

            case 'cards_order':
              return {
                ...acc,
                cardsOrder: {
                  cardsOrder: {
                    city: curr.answers.cards_order?.city,
                    complement: curr.answers.cards_order?.complement,
                    country: curr.answers.cards_order?.country,
                    neighborhood: curr.answers.cards_order?.neighborhood,
                    number: curr.answers.cards_order?.number,
                    phoneNumber: curr.answers.cards_order?.phone_number,
                    postalCode: curr.answers.cards_order?.postal_code,
                    quantity: curr.answers.cards_order?.quantity,
                    receiver: curr.answers.cards_order?.receiver,
                    state: curr.answers.cards_order?.state,
                    street: curr.answers.cards_order?.street,
                    cardsOrder: curr.answers.cards_order
                  }
                }
              };

            case 'filler':
              return {
                ...acc,
                filler: {
                  ...CustomObject.omit(curr.answers, ['phone_number']),
                  phoneNumber:
                    curr.answers.phone_number &&
                    Mask.apply('phone', curr.answers.phone_number.slice(2))
                }
              };

            case 'configuration':
              return {
                ...acc,
                configuration: {
                  useInternationalTransaction:
                    curr.answers.use_international_transaction,
                  usePix: curr.answers.use_pix,
                  useWithdraw: curr.answers.use_withdraw,
                  usersToUseCards: curr.answers.users_to_use_cards?.map(
                    user => ({
                      ...CustomObject.pick(user, ['name', 'type']),
                      userId: user.user_id,
                      companyId: user.company_id,
                      mustHaveAccount: user.must_have_account
                    })
                  )
                }
              };

            case 'company_terms_of_use':
              return {
                ...acc,
                companyTermsOfUse: {
                  acceptPrivacyPolicy: curr.answers.accept_privacy_policy,
                  acceptTermsOfUse: curr.answers.accept_terms_of_use
                }
              };

            case 'feedback':
              return {
                ...acc,
                feedback: {
                  feedbackNote: curr.answers.feedback_note,
                  feedbackComment: curr.answers.feedback_comment
                }
              };

            case 'legal_representative':
              return {
                ...acc,
                legalRepresentative: {
                  legalProcuration: curr.answers.legal_procuration,
                  memberLegalRepresentative:
                    curr.answers.member_legal_representative
                }
              };

            default:
              return {};
          }
        },
        {}
      );

    return companyOnboardingAnswers;
  }
}

export default new GetCompanyOnboardingAnswersMapper();
