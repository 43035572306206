import { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useLangContext, useModalContext } from 'data/contexts';
import {
  AccountHoldersQueryKeys,
  AccountHoldersService,
  type IGetAccountHoldersPayload,
  useStoreUpdateAccountHolder
} from 'data/modules/cards/accountHolders';
import { shareholdersFormInitialValue } from 'data/modules/cards/accountHolders/useCases/store-update-account-holder/constants';

import { CustomDate, CustomObject } from 'shared/utils/custom';
import { Mask } from 'shared/utils/format';

import {
  type IUseManageBusinessAccountHolderModal,
  type IUseManageBusinessAccountHolderModalParams
} from './ManageBusinessAccountHolderModal.types';

export function useManageBusinessAccountHolderModal({
  accountHolderToBeUpdated,
  onAfterUpdateAccountHolder
}: IUseManageBusinessAccountHolderModalParams): IUseManageBusinessAccountHolderModal {
  const [manageModalFormStep, setManageModalFormStep] = useState(1);
  const [
    isLoadingMainCompanyShareholders,
    setIsLoadingMainCompanyShareholders
  ] = useState(false);

  const { handleCloseModal, visibleModal } = useModalContext();

  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const queryClient = useQueryClient();

  function handleCloseModalOnClick(): void {
    shareholderFormFieldsAndMethods.reset(shareholdersFormInitialValue);
    handleCloseModal();
  }

  const {
    accountHolderFormMethods,
    shareholderFormFieldsAndMethods,
    isStoringAccountHolder,
    isUpdatingAccountHolder
  } = useStoreUpdateAccountHolder({
    onAfterAccountHolderFormSubmit: () => {
      setManageModalFormStep(2);
    },
    onAfterSuccessStoreAccountHolderMutation: () => {
      toast.success(
        lang.settings.accountHolders.account_holder_created[currentLangKey]
      );
      handleCloseModalOnClick();
    },
    accountHolderIdToBeUpdated: accountHolderToBeUpdated?.id ?? undefined,
    onAfterSuccessUpdateAccountHolderMutation: data => {
      toast.success(
        lang.settings.accountHolders.account_holder_updated[currentLangKey]
      );
      handleCloseModalOnClick();
      onAfterUpdateAccountHolder(data);
    }
  });

  useEffect(() => {
    if (visibleModal === 'manageBusinessAccountHolder') {
      setManageModalFormStep(1);

      if (accountHolderToBeUpdated !== null) {
        const adjustedPhoneNumber =
          (accountHolderToBeUpdated.phoneNumber.length === 12 ||
            accountHolderToBeUpdated.phoneNumber.length === 13) &&
          accountHolderToBeUpdated.phoneNumber.startsWith('55')
            ? accountHolderToBeUpdated.phoneNumber.slice(2)
            : accountHolderToBeUpdated.phoneNumber;

        accountHolderFormMethods.reset({
          ...CustomObject.omit(accountHolderToBeUpdated, [
            'shareholders',
            'id',
            'kycStatus'
          ]),
          complement: accountHolderToBeUpdated.complement ?? '',
          website: accountHolderToBeUpdated.website ?? '',
          name: accountHolderToBeUpdated.name ?? '',
          foundingDate: CustomDate.formatFromISO(
            accountHolderToBeUpdated.foundingDate
          ),
          documentNumber: Mask.apply(
            'cnpj',
            accountHolderToBeUpdated.documentNumber
          ),
          postalCode: Mask.apply('cep', accountHolderToBeUpdated.postalCode),
          number: Mask.apply('integer', accountHolderToBeUpdated.number),
          phoneNumber: Mask.apply('phone', adjustedPhoneNumber)
        });

        const shareholdersToBeUpdated =
          accountHolderToBeUpdated.shareholders.map(shareholder => {
            const adjustedPhoneNumber =
              (shareholder.phoneNumber.length === 12 ||
                shareholder.phoneNumber.length === 13) &&
              shareholder.phoneNumber.startsWith('55')
                ? shareholder.phoneNumber.slice(2)
                : shareholder.phoneNumber;

            return {
              ...CustomObject.exchangeNullValues(
                CustomObject.omit(shareholder, ['id'])
              ),
              complement: shareholder.complement ?? '',
              birthDate: CustomDate.formatFromISO(shareholder.birthDate),
              document: Mask.apply('cpf', shareholder.document),
              postalCode: Mask.apply('cep', shareholder.postalCode),
              number: Mask.apply('integer', shareholder.number),
              phoneNumber: Mask.apply('phone', adjustedPhoneNumber)
            };
          });

        shareholderFormFieldsAndMethods.reset({
          shareholders: shareholdersToBeUpdated
        });

        return;
      }

      accountHolderFormMethods.reset({
        legalName: ''
      });
      shareholderFormFieldsAndMethods.reset(shareholdersFormInitialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleModal, accountHolderToBeUpdated]);

  const handleSubmitByStep =
    manageModalFormStep === 1
      ? accountHolderFormMethods.handleSubmit
      : shareholderFormFieldsAndMethods.handleSubmit;

  async function handleFillWithMainCompanyShareholders(): Promise<void> {
    setIsLoadingMainCompanyShareholders(true);

    const accountHoldersPayload = {
      limit: 1000,
      page: 1,
      type: 'BUSINESS'
    } as IGetAccountHoldersPayload;

    try {
      const { accountHoldersPaginated } = await queryClient.ensureQueryData({
        queryKey: [
          AccountHoldersQueryKeys.GET_ACCOUNT_HOLDERS,
          accountHoldersPayload
        ],
        queryFn: async () =>
          await AccountHoldersService.getAccountHolders(accountHoldersPayload)
      });

      const mainCompany = accountHoldersPaginated.find(
        item => item.subtype === 'NONE'
      );

      if (!mainCompany) {
        throw new Error();
      }

      const mainCompanyShareholders = mainCompany.shareholders;

      if (mainCompanyShareholders.length === 0) {
        throw new Error();
      }

      shareholderFormFieldsAndMethods.setValue(
        'shareholders',
        mainCompanyShareholders.map(shareholder => {
          const adjustedPhoneNumber =
            (shareholder.phoneNumber.length === 12 ||
              shareholder.phoneNumber.length === 13) &&
            shareholder.phoneNumber.startsWith('55')
              ? shareholder.phoneNumber.slice(2)
              : shareholder.phoneNumber;

          return {
            ...CustomObject.exchangeNullValues(
              CustomObject.omit(shareholder, ['id'])
            ),
            complement: shareholder.complement ?? '',
            birthDate: CustomDate.formatFromISO(shareholder.birthDate),
            document: Mask.apply('cpf', shareholder.document),
            postalCode: Mask.apply('cep', shareholder.postalCode),
            number: Mask.apply('integer', shareholder.number),
            phoneNumber: Mask.apply('phone', adjustedPhoneNumber)
          };
        })
      );
      shareholderFormFieldsAndMethods.clearErrors('shareholders');
    } catch {
      toast.error(
        lang.settings.accountHolders.it_was_not_possible_to_find_the_partners[
          currentLangKey
        ]
      );
    } finally {
      setIsLoadingMainCompanyShareholders(false);
    }
  }

  return {
    manageModalFormStep,
    setManageModalFormStep,
    handleSubmitByStep,
    accountHolderFormMethods,
    shareholderFormFieldsAndMethods,
    handleFillWithMainCompanyShareholders,
    isLoadingMainCompanyShareholders,
    isStoringAccountHolder,
    isUpdatingAccountHolder,
    handleCloseModalOnClick
  };
}
