import styled from 'styled-components';

export const Container = styled.tr`
  font-size: 1.3rem;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.alto};

  td,
  small,
  strong {
    color: ${({ theme }) => theme.colors.mineShaft};
    font-weight: 300;
  }
`;

export const PendencyDescription = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.doveGray};
  font-weight: 300;
  font-size: 1.3rem;

  svg {
    margin-left: 0.5rem;
  }
`;

export const DetailsButton = styled.button`
  border: 0;
  background: none;
  color: ${({ theme }) => theme.colors.royalBlue};
  font-size: 1.1rem;
  text-decoration: underline;
  white-space: nowrap;
`;
