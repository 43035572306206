import { Select, TextInput } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useMyValueSolicitationContext } from 'data/contexts';
import { type BalanceSolicitationStatusType } from 'data/modules/cards/balance';

import { useMyValueSolicitationFilters } from './useMyValueSolicitationsFilters';

import { type IMyValueSolicitationsFiltersProps } from './MyValueSolicitationsFilters.types';

import { Container } from './MyValueSolicitationsFilters.styles';

export function MyValueSolicitationsFilters({
  ...rest
}: IMyValueSolicitationsFiltersProps): JSX.Element {
  const { t } = useTranslation('cards');

  const [
    myValueSolicitationSearchInput,
    handleChangeMyValueSolicitationsSearchInput,
    myValueSolicitationStatus,
    handleChangeMyValueSolicitationStatus,
    handleResetMyValueSolicitationPagination
  ] = useMyValueSolicitationContext(
    useShallow(state => [
      state.myValueSolicitationsSearchInput,
      state.handleChangeMyValueSolicitationsSearchInput,
      state.myValueSolicitationsStatus,
      state.handleChangeMyValueSolicitationsStatus,
      state.handleResetMyValueSolicitationPagination
    ])
  );

  const { valueSolicitationsStatusOptions } = useMyValueSolicitationFilters();

  return (
    <Container {...rest}>
      <TextInput.Default
        label={t('searchValueSolicitation')}
        placeholder={t('searchValueSolicitation')}
        value={myValueSolicitationSearchInput}
        onChangeValue={handleChangeMyValueSolicitationsSearchInput}
        onClearValue={() => {
          handleChangeMyValueSolicitationsSearchInput('');
          handleResetMyValueSolicitationPagination();
        }}
        type='text'
        iconLeft='SearchIcon'
        small
      />
      <Select.Default
        small
        name='valueSolicitationStatus'
        onChange={value => {
          handleChangeMyValueSolicitationStatus(
            value as BalanceSolicitationStatusType
          );
          handleResetMyValueSolicitationPagination();
        }}
        options={valueSolicitationsStatusOptions}
        value={myValueSolicitationStatus}
        hasArrowDownIndicator
        label={t('status')}
        placeholder={t('status')}
      />
    </Container>
  );
}
