import { apiWeb } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import { mockTypesOfOwners } from 'data/mocks';
import type {
  IChangeExpenseTypePayload,
  ICreateVehiclePayload,
  IDeleteVehiclePayload,
  IFuelPolicies,
  IFuelPoliciesPersistence,
  IGetPaginatedCompanyVehicles,
  IGetPaginatedCompanyVehiclesPayload,
  IGetPaginatedMembersPayload,
  IGetTypesOfFuelsPayload,
  IGetVehiclePayload,
  ILinkOrUnlinkMembersFromVehiclePayload,
  IMember,
  IPaginatedMembers,
  ITypeOfFuel,
  ITypeOfFuelPersistence,
  ITypesOfOwners,
  ITypesOfVehicle,
  IUpdateFuelPoliciesPayload,
  IUpdateVehiclePayload,
  IVehicle,
  IVehiclePersistence
} from 'data/modules/fuel';
import { type IDefaultResponse } from 'data/modules/global';

import { MockApi } from 'shared/utils/global';

import {
  ChangeExpenseTypeMapper,
  CreateVehicleMapper,
  GetFuelPoliciesMapper,
  GetPaginatedMembersMapper,
  GetPaginatedVehiclesMapper,
  GetTypesOfFuelsMapper,
  GetVehicleMapper,
  LinkOrUnlinkMembersFromVehicleMapper,
  UpdateFuelPoliciesMapper,
  UpdateVehicleMapper
} from './mappers';

class FuelService {
  async getTypesOfVehicles(): Promise<ITypesOfVehicle[]> {
    const response =
      await apiWeb.get<IDefaultResponse<ITypesOfVehicle[]>>(`/vehicle-types`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getTypesOfFuels(
    payload: IGetTypesOfFuelsPayload
  ): Promise<ITypeOfFuel[]> {
    const response = await apiWeb.get<
      IDefaultResponse<ITypeOfFuelPersistence[]>
    >(`/fuels`, {
      params: GetTypesOfFuelsMapper.toPersistence(payload)
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data.map(GetTypesOfFuelsMapper.toDomain);
  }

  async getTypesOfOwners(): Promise<ITypesOfOwners[]> {
    return await MockApi.getData(mockTypesOfOwners);

    // const {
    //   data: { data }
    // } =
    //   await apiWeb.get<IDefaultResponse<ITypesOfOwners[]>>(`/types-of-owners`);

    // return data;
  }

  async getPaginatedMembers(
    payload: IGetPaginatedMembersPayload
  ): Promise<IPaginatedMembers> {
    const response = await apiWeb.get<IDefaultResponse<IMember[] | null>>(
      `/users/list-by-vehicle`,
      {
        params: GetPaginatedMembersMapper.toPersistence(payload)
      }
    );

    const { data, total } = ResponseAdapter.formatRegularResponse(
      response.data
    );

    return {
      data: data ?? [],
      total: total ?? 0
    };
  }

  async getVehicle({ vehicleId }: IGetVehiclePayload): Promise<IVehicle> {
    const response = await apiWeb.get<IDefaultResponse<IVehiclePersistence>>(
      `/vehicles/${vehicleId}`
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetVehicleMapper.toDomain(data);
  }

  async createVehicle(payload: ICreateVehiclePayload): Promise<IVehicle> {
    const response = await apiWeb.post<IDefaultResponse<IVehiclePersistence>>(
      `/vehicles`,
      CreateVehicleMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetVehicleMapper.toDomain(data);
  }

  async updateVehicle(payload: IUpdateVehiclePayload): Promise<IVehicle> {
    const response = await apiWeb.post<IDefaultResponse<IVehiclePersistence>>(
      `/vehicles/${payload.vehicleId}`,
      UpdateVehicleMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetVehicleMapper.toDomain(data);
  }

  async getPaginatedCompanyVehicles(
    payload: IGetPaginatedCompanyVehiclesPayload
  ): Promise<IGetPaginatedCompanyVehicles> {
    const response = await apiWeb.get<
      IDefaultResponse<IVehiclePersistence[] | null>
    >('/vehicles/list-by-company', {
      params: GetPaginatedVehiclesMapper.toPersistence(payload)
    });

    const data = ResponseAdapter.formatRegularResponse(response.data);

    return {
      data: data.data
        ? data.data.map(item => GetVehicleMapper.toDomain(item))
        : [],
      total: data?.total ?? 0,
      lastPage: data.lastPage
    };
  }

  async getVehiclesByCurrentUser(): Promise<IVehicle[]> {
    const response = await apiWeb.get<IDefaultResponse<IVehiclePersistence[]>>(
      `/vehicles/list-by-user`
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data.map(GetVehicleMapper.toDomain);
  }

  async linkOrUnlinkMembersFromVehicle({
    vehicleId,
    membersIdsToAttach,
    membersIdsToDetach
  }: ILinkOrUnlinkMembersFromVehiclePayload): Promise<void> {
    await apiWeb.post<IDefaultResponse<null>>(
      `/vehicles/${vehicleId}/update-users`,
      LinkOrUnlinkMembersFromVehicleMapper.toPersistence({
        membersIdsToAttach,
        membersIdsToDetach
      })
    );
  }

  async deleteVehicle({ vehicleId }: IDeleteVehiclePayload): Promise<void> {
    await apiWeb.delete<IDefaultResponse<null>>(`/vehicles/${vehicleId}`);
  }

  async getFuelPolicies(): Promise<IFuelPolicies> {
    const response = await apiWeb.get<
      IDefaultResponse<IFuelPoliciesPersistence>
    >('/settings/fuel-policies');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetFuelPoliciesMapper.toDomain(data);
  }

  async updateFuelPolicies(payload: IUpdateFuelPoliciesPayload): Promise<void> {
    await apiWeb.post<IDefaultResponse<void>>(
      '/settings/fuel-policies',
      UpdateFuelPoliciesMapper.toPersistence(payload)
    );
  }

  async changeExpenseType(payload: IChangeExpenseTypePayload): Promise<void> {
    await apiWeb.post<IDefaultResponse<void>>(
      `/expenses/change-type/${payload.uuid}`,
      ChangeExpenseTypeMapper.toPersistence(payload)
    );
  }
}

export default new FuelService();
