import { forwardRef } from 'react';

import { useLangContext } from 'data/contexts';

import { InputContainer, TextInput } from 'presentation/components/base/Input';

import { type IInputSearchFilterProps } from './InputSearchFilter.types';

export const InputSearchFilter = forwardRef<
  HTMLInputElement,
  IInputSearchFilterProps
>(function InputSearchFilter({ onChange, disabled = false }, ref): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  return (
    <InputContainer label={lang.cards.statement.sidebar.search[currentLangKey]}>
      <TextInput
        name='search'
        onChange={event => {
          onChange?.(event.target.value);
        }}
        disabled={disabled}
        ref={ref}
      />
    </InputContainer>
  );
});
