import { useQueryCache } from 'data/cache';
import {
  CardGroupsQueryKeys,
  CardGroupsService,
  type IUseGetCardGroupData,
  type IUseGetCardGroupDataParams
} from 'data/modules/cards/cardGroups';

export function useGetCardGroup({
  cardGroupId,
  enabled = true
}: IUseGetCardGroupDataParams): IUseGetCardGroupData {
  const { data, isFetching } = useQueryCache({
    queryKey: [CardGroupsQueryKeys.GET_CARD_GROUP, { cardGroupId }],
    queryFn: async () => await CardGroupsService.getCardGroup({ cardGroupId }),
    enabled
  });

  return {
    cardGroupData: data,
    isFetchingCardGroupData: isFetching
  };
}
