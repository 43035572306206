import { useMutationCache } from 'data/cache';
import {
  BalanceMutationKeys,
  BalanceService,
  type ISendModifiedBalanceAllocationItemsToCachePayload,
  type IUseSendModifiedBalanceAllocationItemsToCache,
  type IUseSendModifiedBalanceAllocationItemsToCacheParams
} from 'data/modules/cards/balance';

export function useSendModifiedBalanceAllocationItemsToCache(
  params?: IUseSendModifiedBalanceAllocationItemsToCacheParams
): IUseSendModifiedBalanceAllocationItemsToCache {
  const {
    mutate: sendModifiedBalanceAllocationItemsToCache,
    isLoading: isSendingModifiedBalanceAllocationItemsToCache
  } = useMutationCache({
    mutationKey: [
      BalanceMutationKeys.SEND_MODIFIED_BALANCE_ALLOCATION_ITEMS_TO_CACHE
    ],
    mutationFn: async (
      payload: ISendModifiedBalanceAllocationItemsToCachePayload
    ) =>
      await BalanceService.sendModifiedBalanceAllocationItemsToCache(payload),
    onSuccess: () => {
      params?.onAfterSuccess?.();
    }
  });

  return {
    sendModifiedBalanceAllocationItemsToCache,
    isSendingModifiedBalanceAllocationItemsToCache
  };
}
