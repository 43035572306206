import { tokens } from 'ds';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  margin: 0 auto;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2.4rem;
`;

export const WarningIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${tokens.feedbackColorWarningLightest};
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
`;

export const WarningIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${tokens.feedbackColorWarningPure};
  border-radius: 50%;
  width: 4.8rem;
  height: 4.8rem;
`;

export const WarningDescription = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightRegular};
  color: ${tokens.neutralColorLowDark};
  line-height: 3.2rem;
  letter-spacing: 0.75px;
  text-align: center;
`;
