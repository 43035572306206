import { DSIcons, SkeletonLoader } from 'ds';
import { useDsGa4 } from 'ds/hooks/globals';

import { type IDefaultTableHeadCellProps } from './DefaultTableHeadCell.types';

import { Container, ItemContainer } from './DefaultTableHeadCell.styles';

export function DefaultTableHeadCell({
  order,
  isActive,
  children,
  width = 'auto',
  isLoading = false,
  colSpan,
  onClick,
  ...rest
}: IDefaultTableHeadCellProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  let Icon = DSIcons.SortableIcon;

  if (isActive) {
    Icon = order === 'asc' ? DSIcons.SortAscIcon : DSIcons.SortDescIcon;
  }

  return (
    <Container
      $width={width}
      $order={order}
      onClick={event => {
        if (onClick) {
          order &&
            sendDsGaEvent('components', 'table', {
              objectName: 'tables',
              description: 'colunas',
              eventAction:
                order === 'default' || order === 'desc'
                  ? 'ascending_columns_tables'
                  : 'descending_columns_tables',
              eventLabel: children?.toString()
            });

          onClick(event);
        }
      }}
      colSpan={colSpan}
      {...rest}
    >
      {isLoading ? (
        <SkeletonLoader color='neutralColorHighDark' />
      ) : (
        <ItemContainer>
          {children}
          {order && <Icon />}
        </ItemContainer>
      )}
    </Container>
  );
}
