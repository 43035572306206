import { type ITransaction } from 'data/modules/cards/statement';
import { useGetExpense } from 'data/modules/expenses';

import { type IUseTransactionExpenseStatus } from './TransactionExpenseStatus.types';

export function useTransactionExpenseStatus(
  transaction: ITransaction
): IUseTransactionExpenseStatus {
  const expenseQueryEnabled =
    transaction !== null &&
    transaction.debitCredit === 'DEBIT' &&
    transaction.type !== 'TRANSFER_DEBIT';

  const { expense, isFetchingExpense, isErrorExpense, refetchExpense } =
    useGetExpense({
      enabled: expenseQueryEnabled,
      transactionId:
        expenseQueryEnabled && transaction !== null
          ? transaction.transactionId
          : 'disabled'
    });

  return {
    status: expense?.report === null ? 'UNREPORTED' : expense?.report.status,
    isLoadingExpense: isFetchingExpense,
    isErrorExpense,
    refetchExpense
  };
}
