import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  useAuthContext,
  useFlagsContext,
  useValueSolicitationContext
} from 'data/contexts';

import {
  AvailableValueCardInfo,
  CardsContainerPageHeader,
  CardsPageHeader,
  ChargeCardsCardInfo,
  DistributedValueCardInfo
} from 'presentation/pages/cards/components';

export function ValueSolicitationPageHeader(): JSX.Element {
  const [adminMovementsFlag] = useFlagsContext(
    useShallow(state => [state.adminMovementsFlag])
  );
  const user = useAuthContext(state => state.user);

  const [step, handleClearAllSelectSolicitation, handleChangeStep] =
    useValueSolicitationContext(
      useShallow(state => [
        state.step,
        state.handleClearAllSelectSolicitation,
        state.handleChangeStep
      ])
    );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId } = useParams();

  return (
    <CardsPageHeader
      tabs={[
        {
          active: false,
          label: t('cardsManagement'),
          hidden: !groupId && user?.cards?.type === 'USER',
          onClick: () => {
            groupId
              ? navigate(`/cartoes/gestao-de-grupos/${groupId}`)
              : navigate('/cartoes/gestao');
          }
        },
        {
          active: false,
          label: t('movements'),
          onClick: () => {
            adminMovementsFlag
              ? groupId
                ? navigate(`/cartoes/gestao-de-grupos/${groupId}/movimentacoes`)
                : navigate('/cartoes/movimentacoes')
              : groupId
                ? navigate(`/cartoes/gestao-de-grupos/${groupId}/movimentacoes`)
                : navigate('/cartoes/extrato');
          }
        },
        {
          active: true,
          label: t('valuesSolicitations'),
          onClick: () => {
            handleClearAllSelectSolicitation();
            handleChangeStep('solicitation');
          }
        },
        {
          active: false,
          label: t('myCard'),
          hidden: !user?.cards?.hasAccount || !!groupId,
          onClick: () => {
            navigate('/cartoes/meu-cartao');
          }
        }
      ]}
    >
      {step === 'solicitation' && (
        <>
          <CardsContainerPageHeader>
            <AvailableValueCardInfo />
          </CardsContainerPageHeader>

          <CardsContainerPageHeader>
            <DistributedValueCardInfo hasCardGroup />

            <ChargeCardsCardInfo />
          </CardsContainerPageHeader>
        </>
      )}
    </CardsPageHeader>
  );
}
