import {
  type IPersistenceUserAccount,
  type IUserAccount
} from 'data/modules/cards/account';

class UserAccountMapper {
  toDomain(data: IPersistenceUserAccount): IUserAccount {
    return {
      userId: data.user_id,
      userName: data.user_name,
      account: {
        id: data.account.id,
        status: data.account.status,
        cardNumber: data.account.card_number,
        cardStatus: data.account.card_status,
        cardLockLevel: data.account.card_lock_level,
        balances: data.account.balances
      }
    };
  }
}

export default new UserAccountMapper();
