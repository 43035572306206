import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IActivateCardsFieldsForm } from 'data/modules/cards/businessCard';

import { Validate } from 'shared/utils/validation';

export function useActivateCardSchema(): ObjectSchema<IActivateCardsFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const activateCardSchema: ObjectSchema<IActivateCardsFieldsForm> =
    useMemo(() => {
      return yup.object().shape({
        digits: yup
          .string()
          .required(lang.schemas.is_required[currentLangKey])
          .min(4, `${lang.schemas.min[currentLangKey]} 4`)
          .max(4, `${lang.schemas.max[currentLangKey]} 4`)
          .test({
            name: 'digits',
            message:
              lang.schemas.do_not_use_sequential_numbers_or_repetitions[
                currentLangKey
              ],
            test: Validate.isPinStrong
          }),
        cardId: yup
          .string()
          .required(lang.schemas.is_required[currentLangKey])
          .min(32, `${lang.schemas.min[currentLangKey]} 32`)
          .max(32, `${lang.schemas.max[currentLangKey]} 32`)
      });
    }, [lang, currentLangKey]);

  return activateCardSchema;
}
