import { Outlet, Route } from 'react-router-dom';

import {
  Analytics,
  Suggestion,
  UserExpenses,
  UserExpensesResume
} from 'presentation/pages/analytics';

import { AnalysisRoutes } from 'shared/constants/global';
import {
  AuthenticationMiddleware,
  FlagMiddleware,
  ManagerMiddleware
} from 'shared/middlewares';
import { AnalyticsMiddleware } from 'shared/middlewares/AnalyticsMiddleware/Analytics.middleware';

export const analyticsRoutes = [
  <Route
    key='analytics'
    element={<AuthenticationMiddleware />}
  >
    <Route element={<ManagerMiddleware />}>
      <Route element={<FlagMiddleware />}>
        <Route element={<AnalyticsMiddleware />}>
          <Route
            element={<Analytics />}
            path={AnalysisRoutes.ANALYSIS}
          />

          <Route
            element={<Suggestion />}
            path={AnalysisRoutes.ANALYSIS_SUGGESTION}
          />

          <Route
            element={<Outlet />}
            path={AnalysisRoutes.ANALYSIS_EXPENSES_PER_USER}
          >
            <Route
              element={<UserExpenses />}
              index
            />
            <Route
              element={<UserExpensesResume />}
              path={AnalysisRoutes.ANALYSIS_RESUME}
            />
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
];
