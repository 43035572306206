import { Alert } from 'ds';
import styled from 'styled-components';

export const Container = styled(Alert)`
  flex-direction: column;
  align-items: flex-end;

  button {
    white-space: nowrap;
    align-self: flex-end;
    margin-right: 1rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: row;
    align-items: center;

    button {
      align-self: center;
    }
  }
`;

export const MessageContainer = styled.div`
  margin-left: 3.65rem;
`;
