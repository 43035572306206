import { buttons } from './buttons';
import { dsButtons } from './ds';

export const defaultTheme = {
  colors: {
    black: '#000000',
    codGray: '#111111',
    mineShaft: '#333333',
    mako: '#3b4146',
    emperor: '#515151',
    doveGray: '#626262',
    neutral: '#7d7d7d',
    lynch: '#707c97',
    dustyGray: '#999999',
    alto: '#cccccc',
    mercury: '#e9e9e9',
    gallery: '#eeeeee',
    alabaster: '#f8f8f8',
    white: '#ffffff',
    danger: '#f83b3a',
    carnation: '#f76c51',
    tango: '#f7841e',
    clementine: '#EA6E00',
    warning: '#edb25b',
    greenHaze: '#00944f',
    green: '#00c305',
    success: '#97c664',
    puertoRico: '#48c5a3',
    tacha: '#D5D76E',
    firefly: '#0d1c2e',
    veniceBlue: '#064881',
    royalBlue: '#3366dd',
    adamantineBlue: '#0871cc',
    primary: '#0a7ee3',
    secondary: '#3fa1ff',
    havelockBlue: '#5FA2DD',
    selago: '#e7f2fC',
    cranberry: '#d84b92',
    oldRose: '#C68282',
    tulipTree: '#ebaa4b',
    gray70: '#b3b3b3',
    blueJeans: '#66afe9',
    americanSilver: '#d0d0d0',
    neutralColorLowDarkest: '#6e7191',
    alloyOrange: '#C46818',
    princetonOrangeOpacity: '#f7841e14',
    ghostWhite: '#F7F7FC',
    antiFlashWhite: '#eff0f6',
    independence: '#4e4b66',
    outerSpace: '#444444',
    outSourced: '#222',
    lightSilver: '#D9D9D9',
    neutralColorLowMedium: '#666666',
    neutralColorLowDark: '#222222',
    neutralColorHighMedium: '#dddddd',
    feedbackColorErrorLight: '#FA5D5D',
    feedbackColorSuccessPure: '#008C1F',
    neutralColorDark: '#212b36',
    sooty: '#141414',
    gray85: '#d9d9d9',
    error: '#ff4842'
  },
  buttons,
  zIndex: {
    topbar: 10,
    sidebar: 19,
    sidebarMobile: 40,
    dropdown: 10,
    toast: 100,
    modal: 50,
    loader: 55
  },
  ds: {
    dsButtons
  },
  breakpoints: {
    mobile: 480,
    tablet: 768,
    mid: 1000,
    desktop: 1335
  },
  tokens: {
    borderRadiusNone: 0,
    borderRadiusNano: '0.4rem',
    borderRadiusXs: '0.8rem',
    borderRadiusSm: '1.2rem',
    borderRadiusMd: '1.6rem',
    borderRadiusLg: '2rem',
    borderRadiusXl: '2.4rem',
    borderRadiusXxl: '3.2rem',
    borderRadiusPill: '4.8rem',
    borderRadiusCircular: '20rem',
    borderWidthNone: 0,
    borderWidthHairline: '0.1rem',
    borderWidthThin: '0.2rem',
    borderWidthThick: '0.8rem',
    lineHeightDefault: '10rem',
    lineHeightXxss: '11.6rem',
    lineHeightXxs: '12.8rem',
    lineHeightXs: '13.2rem',
    lineHeightSm: '13.5rem',
    lineHeightMd: '13.8rem',
    lineHeightLg: '14rem',
    lineHeightXl: '15rem',
    lineHeightXxl: '16rem',
    lineHeightXxxl: '16.5rem',
    lineHeightDisplay: '17rem',
    fontWeightBold: 700,
    fontWeightSemibold: 600,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontSizeXxss: '1.3rem',
    fontSizeXxs: '1.5rem',
    fontSizeXs: '1.8rem',
    fontSizeSm: '2rem',
    fontSizeMd: '2.4rem',
    fontSizeLg: '3.2rem',
    fontSizeXl: '3.6rem',
    fontSizeXxl: '4.8rem',
    fontSizeXxxl: '5.6rem',
    fontSizeDisplay: '6.4rem',
    spacingMini: '0.2rem',
    spacingQuarck: '0.4rem',
    spacingNano: '0.8rem',
    spacingXxs: '1.2rem',
    spacingXs: '1.6rem',
    spacingSm: '2rem',
    spacingMd: '2.4rem',
    spacingLg: '3.2rem',
    spacingXl: '4rem',
    spacingXxl: '4.8rem',
    spacingXxxl: '5.6rem',
    spacingHuge: '9.6rem',
    spacingGiant: '12.8rem',
    shadowLevel1: {
      color: '#000000',
      type: 'dropShadow',
      x: '0',
      y: '0',
      blur: '16',
      spread: '0'
    },
    shadowLevel2: {
      color: '#0000000a',
      type: 'dropShadow',
      x: '0',
      y: '0',
      blur: '32',
      spread: '0'
    },
    shadowLevel3: {
      color: '#000000',
      type: 'dropShadow',
      x: '0',
      y: '0',
      blur: '72',
      spread: '0'
    },
    fontFamilyPoppins: 'Poppins',
    fontFamilyRoboto: 'Roboto',
    neutralColorLowPure: '#262338',
    neutralColorLowDark: '#4e4b66',
    neutralColorLowDarkTwo: '#4e4b6625',
    neutralColorLowDarkest: '#6e7191',
    neutralColorLowMedium: '#a0a3bd',
    neutralColorLowLight: '#d9dbe9',
    neutralColorHighPure: '#fcfcfc',
    neutralColorHighDark: '#f7f7fc',
    neutralColorHighMedium: '#eff0f6',
    neutralColorHighLight: '#f7f7f7',
    neutralColorHighPurest: 'rgba(20,20,43,0.1)',
    neutralColorHigh: 'rgba(247,247,247,0.1)',
    neutralColorLowPurest: '#14142B',
    neutralColorHighMediumest: 'rgba(239,240,246,0.5)',
    neutralColorLowLightest: 'rgba(110,113,145,0.5)',
    brandColorPrimaryPure: '#3fa1ff',
    brandColorPrimaryDark: '#2e5de8',
    brandColorPrimaryDarkest: '#3fa1ffb3',
    brandColorPrimaryMedium: '#3fa1ff4d',
    brandColorPrimaryLight: '#3fa1ff29',
    brandColorPrimaryLightest: '#3fa1ff14',
    brandColorSecondaryPure: '#0a7ee3',
    brandColorSecondaryDark: '#0245fa',
    brandColorSecondaryDarkest: '#0a7ee3b3',
    brandColorSecondaryMedium: '#0a7ee34d',
    brandColorSecondaryLight: '#0a7ee329',
    brandColorSecondaryLightest: '#0a7ee314',
    highlightColorPure: '#f7841e',
    highlightColorDark: '#c46818',
    highlightColorDarkest: '#f7841eb3',
    highlightColorMedium: '#f7841e4d',
    highlightColorLight: '#f7841e29',
    highlightColorLightest: '#f7841e14',
    feedbackColorSuccessPure: '#0bcf0b',
    feedbackColorSuccessPurest: '#09AC09',
    feedbackColorSuccessDark: '#067306',
    feedbackColorSuccessDarkest: '#034503',
    feedbackColorSuccessMedium: '#cbffae',
    feedbackColorSuccessLight: '#eaf9de',
    feedbackColorSuccessLightest: '#daffe2',
    feedbackColorWarningPure: '#946300',
    feedbackColorWarningDarkest: '#614100',
    feedbackColorWarningPurest: '#E09600',
    feedbackColorWarningDark: '#ffdf9a',
    feedbackColorWarningMedium: '#ffe6b0',
    feedbackColorWarningLight: '#fff8e9',
    feedbackColorWarningLightest: '#FFDF9A',
    feedbackColorErrorPure: '#9E0038',
    feedbackColorErrorPurest: '#ca024f',
    feedbackColorErrorDark: '#FF75CB',
    feedbackColorErrorMedium: '#FFABE8',
    feedbackColorErrorMediumest: '#EB0052',
    feedbackColorErrorLight: '#FFECFC',
    feedbackColorErrorLightest: '#ffdde9',
    brandColorTertiaryPure: '#F7841E',
    brandColorTertiaryLightest: '#FEF3E9',
    brandColorTertiaryMedium: '#bb5c07',
    fontFamiliesPoppins: 'Poppins',
    lineHeights0: 'AUTO',
    fontWeightsPoppins0: 600,
    letterSpacing0: 0,
    paragraphSpacing0: 0,
    textCaseNone: 'none',
    textDecorationNone: 'none',
    colorHighMediumest: '#eff0f680',
    spacingInsetMini: '0.2rem',
    spacingInsetQuarck: '0.4rem',
    spacingInsetQuarckk: '0.5rem',
    spacingInsetNano: '0.8rem',
    spacingInsetXxxs: '0.9rem',
    spacingInsetXxs: '1.2rem',
    spacingInsetXs: '1.6rem',
    spacingInsetX: '1.8rem',
    spacingInsetSm: '2rem',
    spacingInsetMd: '2.2rem',
    spacingInsetMdd: '2.4rem',
    spacingInsetLg: '3.2rem',
    spacingInsetXl: '4rem',
    spacingInsetXxl: '4.8rem',
    spacingInsetHuge: '5.6rem',
    spacingInsetGiant: '7.2rem',
    spacingInsetDisplay: '12.8rem',
    borderRadiusXxxl: '4rem'
  }
};
