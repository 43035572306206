import {
  type IExpenseParameters,
  type IPersistenceWebExpenseParameters
} from 'data/modules/expenses/types/expenses.types';

class GetExpenseParametersMapper {
  toDomain(persistence: IPersistenceWebExpenseParameters): IExpenseParameters {
    return {
      allowAddRouteWithMap: persistence.allow_add_route_with_map,
      allowAddManualRoute: persistence.allow_add_manual_route,
      defaultCurrency: {
        company: persistence.default_currency.company,
        user: persistence.default_currency.user
      },
      hasObservationAttachment: persistence.uses_observation_attachment === 1,
      allowChangeAmountPerKm: persistence.allow_change_amount_per_km === 1,
      amountPerKm:
        typeof persistence.amount_per_km === 'string'
          ? parseFloat(persistence.amount_per_km)
          : persistence.amount_per_km,
      usesAdvance: persistence.uses_advance === 1,
      isProjectFieldMandatory: persistence.is_project_mandatory,
      usesInvoiceReconciliation: persistence.uses_invoice_reconciliation,
      isPaymentMethodMandatory: persistence.is_payment_method_mandatory === 1,
      allowExpenseWithNegativeValue:
        persistence.allow_expense_with_negative_value,
      isExpenseDescriptionMandatory:
        persistence.is_expense_description_mandatory,
      usesExchange: persistence.uses_exchange,
      allowPaymentMethodChangeForImportedExpenses:
        persistence.allow_payment_method_change_for_imported_expenses,
      isExpensesObsMandatory: persistence.is_obs_mandatory,
      usesCheckingAccount: persistence.uses_checking_account === 1,
      checkingAccountMulticurrency: persistence.checking_account_multicurrency,
      companyFields: {
        companyUsesFields: persistence.company_fields.company_uses_fields,
        expenseCostCenter:
          persistence.company_fields.company_fields.expense_cost_center,
        expenseProject:
          persistence.company_fields.company_fields.expense_project,
        expensePaymentMethod:
          persistence.company_fields.company_fields.expense_payment_method,
        expenseNote: persistence.company_fields.company_fields.expense_note,
        reportCostCenter:
          persistence.company_fields.company_fields.report_cost_center,
        reportPaymentMethod:
          persistence.company_fields.company_fields.report_payment_method,
        reportProject: persistence.company_fields.company_fields.report_project
      },
      daysPolicy: {
        futureDays: persistence.days_policy.future_days,
        pastDays: persistence.days_policy.past_days
      },
      policyType: persistence.policy_type,
      companyApprovalType: persistence.company_approval_type,
      mileagePolicyType: persistence.route_policy_type,
      requiredReceiptInManualRoute:
        persistence.is_manual_route_receipt_mandatory,
      fuelsPolicies: Array.isArray(persistence.fuels_policies)
        ? null
        : {
            isKilometrageMandatory:
              persistence.fuels_policies.is_kilometrage_mandatory,
            isOdometerMandatory:
              persistence.fuels_policies.is_odometer_mandatory,
            isPlateMandatory: persistence.fuels_policies.is_plate_mandatory,
            isTypeMandatory: persistence.fuels_policies.is_type_mandatory
          }
    };
  }
}

export default new GetExpenseParametersMapper();
