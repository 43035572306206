import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';

import { ActionModal } from 'presentation/components/global/ActionModal';
import { Loader } from 'presentation/components/global/Loader';

import { useBlockAndUnblockCardModal } from './useBlockAndUnblockCardModal';

import { Content } from './BlockAndUnblockCardModal.styles';

export function BlockAndUnblockCardModal(): JSX.Element {
  const { t } = useTranslation(['global', 'cards']);
  const { handleCloseModal } = useModalContext();

  const { cardData, handleBlockUnblockCard, isBlockingOrUnblockingCard } =
    useBlockAndUnblockCardModal();

  return (
    <ActionModal
      title={
        cardData?.status === 'BLOCKED'
          ? t('cards:unblockCard')
          : t('cards:blockCard')
      }
      onClose={() => {
        handleCloseModal();
      }}
      onCancel={() => {
        handleCloseModal();
      }}
      onConfirm={() => {
        cardData?.status === 'BLOCKED'
          ? handleBlockUnblockCard('unblock')
          : handleBlockUnblockCard('block');
      }}
      disabledFixedHeight
    >
      <Loader isLoading={isBlockingOrUnblockingCard} />

      <Content>
        {cardData?.status === 'BLOCKED' ? (
          <>
            <p>{t('cards:aboutUnblockCard')}</p>
            <p>{t('cards:confirmUnblock')}</p>
          </>
        ) : (
          <>
            <p>{t('cards:aboutBlockCard')}</p>
            <p>{t('cards:confirmBlock')}</p>
          </>
        )}
      </Content>
    </ActionModal>
  );
}
