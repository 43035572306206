import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useCardGroupsContext, useModalContext } from 'data/contexts';
import { useGetCardGroup } from 'data/modules/cards/account/useCases/get-card-group/useGetCardGroup';

import { SideCardDetailsModal } from 'presentation/pages/cards/Management/components/SideCardDetailsModal';
import { DefaultLayout } from 'presentation/templates';

import { GroupManagementPageHeader, GroupManagementTable } from './components';

import { PageContainer } from './GroupManagement.styles';

export function GroupManagement(): JSX.Element {
  const { t } = useTranslation('cards');
  const { groupId } = useParams();

  const visibleModal = useModalContext(state => state.visibleModal);

  const contextCardGroup = useCardGroupsContext(
    state => state.selectedCardGroup
  );

  const { cardGroupData } = useGetCardGroup({
    cardGroupId: groupId || '',
    enabled: !contextCardGroup
  });

  const selectedCardGroup = contextCardGroup || cardGroupData;

  return (
    <DefaultLayout
      pageNameAndBreadcrumbs={{
        pageName: selectedCardGroup?.name ?? t('groupName'),
        isBackButtonVisible: true,
        isBreadcrumbsVisible: true,
        isClassicMode: false,
        breadcrumbs: [
          {
            name: t('cardsManagement'),
            pageLink: '/cartoes/grupos-de-cartoes'
          },
          {
            name: selectedCardGroup?.name ?? ''
          }
        ]
      }}
    >
      <PageContainer>
        <GroupManagementPageHeader />

        <GroupManagementTable />
      </PageContainer>

      <SideCardDetailsModal
        isOpen={visibleModal === 'cardDetails'}
        title={t('card')}
        side='right'
      />
    </DefaultLayout>
  );
}
