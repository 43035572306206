import { useEffect } from 'react';

import { toast } from 'ds/utils';
import { useNavigate } from 'react-router-dom';

import { useLangContext, useModalContext } from 'data/contexts';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  useUpdateRequestCards
} from 'data/modules/cards/companyOnboarding';
import { useGetViaCepAddressByPostalCode } from 'data/modules/global';

import { countries, states } from 'shared/constants/global';
import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import {
  type IUpdateRequestCardsAndNavigateNextStepParams,
  type IUseRequestCardsForm
} from './RequestCardsForm.types';

export function useRequestCardsForm(): IUseRequestCardsForm {
  const navigate = useNavigate();

  const { handleOpenModal } = useModalContext();

  const [postalCodeNotFound, currentLangKey] = useLangContext(state => [
    state.lang.global.postal_code_not_found,
    state.currentLangKey
  ]);

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const {
    data: cachedCompanyOnboardingProgress,
    updateCache: updateCurrentStepCache
  } = useHandleQueryCache({
    query: {
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS],
      query: async () => await CompanyOnboardingService.getProgress()
    }
  });

  const { updateCache } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      ],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingAnswers({
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    }
  });

  const {
    control,
    errors,
    handleUpdateRequestCards,
    setValue,
    watch,
    handleCancelRequestCards,
    trigger
  } = useUpdateRequestCards({
    onUpdateCompanyOnboardingAnswersMutationSuccess:
      updateCacheAndNavigateNextStep
  });

  const postalCode = watch('postalCode');

  const { isFetchingAddress, address } = useGetViaCepAddressByPostalCode({
    postalCode: postalCode ?? '',
    enabled: postalCode?.length === 9 || false
  });

  useEffect(() => {
    const country = watch('country');
    const foundState = states.find(state => state.value === address?.state);
    const foundCountry = countries.find(state => state.value === country);

    if (address === null) {
      toast.error(postalCodeNotFound[currentLangKey]);
      return;
    }

    if (address) {
      setValue('street', address.street, {
        shouldValidate: true
      });
      setValue('complement', address.complement, {
        shouldValidate: true
      });
      setValue('neighborhood', address.neighborhood, {
        shouldValidate: true
      });
      setValue('city', address.city, {
        shouldValidate: true
      });
    }

    if (foundState) {
      setValue('state', foundState as unknown as string, {
        shouldValidate: true
      });
    }

    if (foundCountry) {
      setValue('country', foundCountry as unknown as string, {
        shouldValidate: true
      });
    }
  }, [address, setValue, currentLangKey, postalCodeNotFound, watch]);

  function updateCacheAndNavigateNextStep(
    newCardsOrder: IUpdateRequestCardsAndNavigateNextStepParams
  ): void {
    navigate(`/cartoes/onboarding/empresa/${currentStep + 1}`);

    updateCache({
      key: 'cardsOrder',
      newData: { cardsOrder: newCardsOrder }
    });

    if (currentStep === Number(cachedCompanyOnboardingProgress?.currentStep)) {
      updateCurrentStepCache({
        key: 'currentStep',
        newData: currentStep + 1
      });
    }
  }

  async function handleClickContinueButton(): Promise<void> {
    const isFormValid = await trigger(undefined, {
      shouldFocus: true
    });

    if (isFormValid) {
      handleOpenModal('confirmation');
    }
  }

  return {
    control,
    errors,
    handleUpdateRequestCards,
    handleCancelRequestCards,
    setValue,
    isFetchingAddress,
    watch,
    handleClickContinueButton
  };
}
