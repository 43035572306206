import { useQueryClient } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

import { useChargeCardsContext } from 'data/contexts';
import {
  BalanceQueryKeys,
  type IModifiedBalanceAllocationItem,
  useDeleteModifiedBalanceAllocationItemsFromCache,
  useSendModifiedBalanceAllocationItemsToCache
} from 'data/modules/cards/balance';

import {
  ChargeCardsSteps,
  type IValueAllocationCardItem
} from 'presentation/pages/cards/Management/ChargeCards/ChargeCards.types';

import { type IUseChargeCardsTableRow } from './ChargeCardsTableRow.types';

export function useChargeCardsTableRow(
  card: IValueAllocationCardItem
): IUseChargeCardsTableRow {
  const queryClient = useQueryClient();

  const [
    handleRemoveOperation,
    handleGetOperationsList,
    handleChangeCurrentStep
  ] = useChargeCardsContext(
    useShallow(state => [
      state.handleRemoveOperation,
      state.handleGetOperationsList,
      state.handleChangeCurrentStep
    ])
  );

  const {
    deleteModifiedBalanceAllocationItemsFromCache,
    isDeletingModifiedBalanceAllocationItemsFromCache
  } = useDeleteModifiedBalanceAllocationItemsFromCache({
    onAfterSuccess: () => {
      handleRemoveOperation({
        balanceId: card.balanceId,
        cardNumber: card.number
      });

      handleChangeCurrentStep(ChargeCardsSteps.CHARGE_CARDS);
    }
  });

  const {
    sendModifiedBalanceAllocationItemsToCache,
    isSendingModifiedBalanceAllocationItemsToCache
  } = useSendModifiedBalanceAllocationItemsToCache({
    onAfterSuccess: () => {
      handleRemoveOperation({
        balanceId: card.balanceId,
        cardNumber: card.number
      });

      queryClient.setQueryData<IModifiedBalanceAllocationItem[]>(
        [BalanceQueryKeys.GET_CACHED_MODIFIED_BALANCE_ALLOCATION_ITEMS],
        handleGetOperationsList()
      );

      const filteredOperationsList = handleGetOperationsList().filter(
        operation =>
          `${operation.balanceAccountId}-${operation.cardNumber}` !==
          `${card.balanceId}-${card.number}`
      );

      if (filteredOperationsList.length === 0) {
        deleteModifiedBalanceAllocationItemsFromCache(null);
      }
    }
  });

  function handleDeleteCachedOperation(): void {
    if (
      isDeletingModifiedBalanceAllocationItemsFromCache ||
      isSendingModifiedBalanceAllocationItemsToCache
    )
      return;

    const filteredOperationsList = handleGetOperationsList().filter(
      operation =>
        `${operation.balanceAccountId}-${operation.cardNumber}` !==
        `${card.balanceId}-${card.number}`
    );

    if (filteredOperationsList.length === 0) {
      deleteModifiedBalanceAllocationItemsFromCache(null);
      return;
    }

    sendModifiedBalanceAllocationItemsToCache({
      balanceAllocationItems: filteredOperationsList
    });
  }

  return {
    isDeletingModifiedBalanceAllocationItemsFromCache,
    isSendingModifiedBalanceAllocationItemsToCache,
    handleDeleteCachedOperation
  };
}
