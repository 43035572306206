import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { type ICreateVehicleForm } from 'data/modules/fuel';

export function useCreateVehicleSchema(): ObjectSchema<ICreateVehicleForm> {
  const { t } = useTranslation('schemas');

  const createVehicleSchema: ObjectSchema<ICreateVehicleForm> = useMemo(() => {
    return yup.object({
      model: yup.string().required(t('isRequired')),
      plate: yup
        .string()
        .required(t('isRequired'))
        .max(8, `${t('max')} 8`),
      type: yup.number().typeError(t('isRequired')).required(t('isRequired')),
      fuel: yup
        .array()
        .of(yup.number().required())
        .required(t('isRequired'))
        .min(1, t('isRequired')),
      owner: yup
        .string()
        .oneOf(['Empresa', 'Colaborador'])
        .required(t('isRequired'))
    });
  }, [t]);

  return createVehicleSchema;
}
