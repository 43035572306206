import globalJSON from './global.lang.json';
import productJSON from './product.lang.json';
import productCardsJSON from './productCards.lang.json';
import requestModalJSON from './requestModal.lang.json';
import slidesJSON from './slides.lang.json';

export const store = {
  global: globalJSON,
  slides: slidesJSON,
  product: productJSON,
  productCards: productCardsJSON,
  requestModal: requestModalJSON
};
