import {
  type ICreateTeamMembersVirtualPayload,
  type IPersistenceTeamMembersVirtual
} from 'data/modules/cards/businessCard';

class TeamMembersVirtualMapper {
  toPersistence(
    domainTeamMembersVirtual: ICreateTeamMembersVirtualPayload
  ): IPersistenceTeamMembersVirtual {
    return {
      company_uuid: domainTeamMembersVirtual.companyId,
      name: domainTeamMembersVirtual.name,
      email: domainTeamMembersVirtual.email
    };
  }
}

export default new TeamMembersVirtualMapper();
