import { DSIcons, TextInput } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext } from 'data/contexts';

import { type ISearchAndFiltersProps } from './SearchAndFilters.types';

import {
  CardsModeSwitch,
  Container,
  ControllersContainer,
  DisplayModeLabel,
  DisplayModeSwitchSelectorContainer,
  ListModeSwitch,
  SearchAndFiltersContainer,
  TableDisplayModeSelectorContainer
} from './SearchAndFilters.styles';

export function SearchAndFilters({
  ...rest
}: ISearchAndFiltersProps): JSX.Element {
  const { t } = useTranslation('cards');

  const [filtersGroupManagement, handleChangeFiltersGroupManagement] =
    useCardGroupsContext(
      useShallow(state => [
        state.filtersGroupManagement,
        state.handleChangeFiltersGroupManagement
      ])
    );

  return (
    <Container {...rest}>
      <SearchAndFiltersContainer>
        <TextInput.Default
          label={t('searchCard')}
          placeholder={t('searchCard')}
          value={filtersGroupManagement.name ?? ''}
          onChangeValue={value => {
            handleChangeFiltersGroupManagement({
              name: value.trim() === '' ? null : value
            });
          }}
          onClearValue={() => {
            handleChangeFiltersGroupManagement({
              name: null
            });
          }}
          iconLeft='SearchIcon'
          small
        />

        {/* será adicionado após alteracao de endpoint */}
        {/* <MultiSelect.Default
          hasArrowDownIndicator
          name='card-type'
          value={['']}
          label={t('cardType')}
          placeholder={t('cardType')}
          onChange={() => {}}
          options={[]}
          small
        /> */}
      </SearchAndFiltersContainer>

      <ControllersContainer>
        <TableDisplayModeSelectorContainer className='cards-tour-preview-button'>
          <DisplayModeLabel>{t('viewMode')}</DisplayModeLabel>

          <DisplayModeSwitchSelectorContainer>
            <ListModeSwitch
              onClick={() => {
                handleChangeFiltersGroupManagement({ tableViewMode: 'LIST' });
              }}
              $tableDisplayMode={filtersGroupManagement.tableViewMode}
            >
              <DSIcons.ListLayoutIcon />
            </ListModeSwitch>

            <CardsModeSwitch
              onClick={() => {
                handleChangeFiltersGroupManagement({ tableViewMode: 'CARDS' });
              }}
              $tableDisplayMode={filtersGroupManagement.tableViewMode}
            >
              <DSIcons.GridLayoutIcon />
            </CardsModeSwitch>
          </DisplayModeSwitchSelectorContainer>
        </TableDisplayModeSelectorContainer>
      </ControllersContainer>
    </Container>
  );
}
