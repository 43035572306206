import { Modal, tokens } from 'ds';
import styled from 'styled-components';

export const ModalForm = styled(Modal.Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${tokens.spacingMd};

  > div {
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
  }
`;

export const Receiver = styled.p`
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowDark};
  margin-top: ${tokens.spacingInsetXs};
  line-height: 3.2rem;
  letter-spacing: 0.75px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetXs};
  margin-top: ${tokens.spacingInsetXs};
`;

export const CurrentValue = styled.div`
  width: fit-content;
  background-color: ${tokens.brandColorSecondaryLightest};
  border-radius: ${tokens.borderRadiusXs};
  padding: ${tokens.spacingXxs};
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.brandColorSecondaryPure};
  line-height: 3.2rem;
  letter-spacing: 0.75px;
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${tokens.spacingInsetXs};

  > button {
    width: 100%;
    max-width: 16rem;
    max-height: ${tokens.fontSizeXxl};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;

    > button {
      max-width: 100%;
      height: fit-content;
    }
  }
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
  gap: ${tokens.spacingXs};

  button {
    width: 100%;
    white-space: nowrap;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    button {
      width: fit-content;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${tokens.spacingInsetXs};
  margin-top: ${tokens.spacingInsetLg};
`;

export const FinalValue = styled.p`
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowDark};
  line-height: 3.2rem;
  letter-spacing: 0.75px;
`;

export const CurrencyInputContainer = styled.div`
  min-width: 20rem;

  > div {
    max-width: 100% !important;
    position: relative;

    > p {
      position: absolute;
      margin-top: 4.5rem;
    }
  }
`;
