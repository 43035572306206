import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'data/contexts';

import { Notification } from 'presentation/components/base/Notification';

import { useNotifications } from './useNotifications';

export function Notifications(): JSX.Element {
  const { t } = useTranslation('notifications');

  const user = useAuthContext(state => state.user);

  const {
    countUnread,
    notifications,
    getMenuIsLoading,
    loadingMoreStatus,
    newNotificationArrived,
    handleReadNotification,
    handleNotificationClick,
    handleUnreadNotification,
    isReadingAllNotifications,
    handleReadAllNotificationsClick,
    handleGetNextNotificationsPageClick
  } = useNotifications();

  /**
   * O componente não é renderizado enquanto não tiver os dados do user
   */
  if (!user) return <></>;

  return (
    <Notification.Dropdown>
      {/* Componente que abre/fecha o dropdown (Trigger) */}
      <Notification.Trigger>
        <Notification.Counter
          counter={countUnread}
          totalCount={notifications.length}
          newNotificationArrived={newNotificationArrived}
        />
      </Notification.Trigger>

      {/* Componente que mostra as notificações (Central) */}
      <Notification.Container>
        <Notification.Header
          unreadCounter={countUnread}
          isReadingAllNotifications={isReadingAllNotifications}
          onReadAllNotificationsClick={handleReadAllNotificationsClick}
        />
        <Notification.List
          loadingStatus={loadingMoreStatus}
          onLoadMoreClick={handleGetNextNotificationsPageClick}
        >
          {notifications.map(notification => (
            <Notification.Card
              key={notification.id}
              url={notification.url}
              read={notification.isRead}
              onClick={handleNotificationClick(notification.id)}
            >
              <Notification.Icon icon={notification.icon} />
              <Notification.Title>{notification.title}</Notification.Title>
              <Notification.Body>{notification.message}</Notification.Body>

              <Notification.Body>{notification.subMessage}</Notification.Body>
              {notification.createdAt && (
                <Notification.Date>{notification.createdAt}</Notification.Date>
              )}

              <Notification.Menu isLoading={getMenuIsLoading(notification.id)}>
                {notification.isRead ? (
                  <Notification.Action
                    onClick={() => {
                      handleUnreadNotification(notification.id);
                    }}
                  >
                    {t('notificationUnreadAction')}
                  </Notification.Action>
                ) : (
                  <Notification.Action
                    onClick={() => {
                      handleReadNotification(notification.id);
                    }}
                  >
                    {t('notificationReadAction')}
                  </Notification.Action>
                )}
              </Notification.Menu>
            </Notification.Card>
          ))}
        </Notification.List>
      </Notification.Container>
    </Notification.Dropdown>
  );
}
