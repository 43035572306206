import * as RadioGroup from '@radix-ui/react-radio-group';
import styled from 'styled-components';

import { type IDsStyleProps } from './DsRadioGroup.types';

export const Container = styled(RadioGroup.Root)<IDsStyleProps>`
  gap: 1.6rem;
  display: flex;
  justify-content: ${({ $justify }) => $justify};
  flex-direction: ${({ $direction }) => $direction};
`;
