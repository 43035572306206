import { BadgeStatus } from 'ds';

import { useLangContext } from 'data/contexts';

import { Spinner } from 'presentation/components/base/Spinner';

import { CardNumber } from 'shared/utils/format';

import { type ICreditCardProps } from './CreditCard.types';

import {
  BalanceContainer,
  Container,
  Label,
  LoaderContainer,
  Name,
  NameNumberContainer,
  NameNumberStatusContainer,
  Number,
  Value,
  ValueLabelContainer,
  VerticalDivider
} from './CreditCard.styles';

export function CreditCard({
  cardInfo,
  isLoading = false,
  ...rest
}: ICreditCardProps): JSX.Element {
  const {
    currentLangKey,
    lang: {
      cards: { global }
    }
  } = useLangContext();

  if (isLoading) {
    return (
      <LoaderContainer {...rest}>
        <Spinner
          color='white'
          size={4}
        />
      </LoaderContainer>
    );
  }

  return (
    <Container {...rest}>
      <NameNumberStatusContainer>
        <NameNumberContainer>
          <Name
            title={cardInfo.name}
            className='credit-card-name'
          >
            {cardInfo.name}
          </Name>

          <Number className='credit-card-number'>
            {cardInfo.number
              ? CardNumber.format(cardInfo.number)
              : global.not_bound[currentLangKey]}
          </Number>
        </NameNumberContainer>

        <BadgeStatus
          type={
            cardInfo.status === 'ACTIVE'
              ? 'success'
              : cardInfo.status === 'BLOCKED'
                ? 'error'
                : 'general'
          }
          className='credit-card-status'
        >
          {cardInfo.status === 'ACTIVE'
            ? global.card_active[currentLangKey]
            : cardInfo.status === 'BLOCKED'
              ? global.card_blocked[currentLangKey]
              : global.not_bound[currentLangKey]}
        </BadgeStatus>
      </NameNumberStatusContainer>

      <ValueLabelContainer>
        {cardInfo.usedBalance && (
          <BalanceContainer>
            <Value>{cardInfo.usedBalance}</Value>
            <Label>{global.used_balance[currentLangKey]}</Label>
          </BalanceContainer>
        )}

        {cardInfo.usedBalance && <VerticalDivider />}

        <BalanceContainer>
          <Value className='credit-card-value'>
            {cardInfo.availableBalance === ''
              ? 'R$ 0,00'
              : cardInfo.availableBalance}
          </Value>
          <Label className='credit-card-label'>
            {global.available_balance[currentLangKey]}
          </Label>
        </BalanceContainer>
      </ValueLabelContainer>
    </Container>
  );
}
