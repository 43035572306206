import { DSIcons } from 'ds/icons';
import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import {
  badgeStatusOutlinedProps,
  badgeStatusProps
} from './constants/badgeStatusProps';

import { type BadgeStatusStyleType } from './BadgeStatus.types';
import { type AddPrefixToType } from 'ds/types';

export const CloseIcon = styled(DSIcons.CloseIcon)`
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
`;

export const Container = styled.span<AddPrefixToType<BadgeStatusStyleType>>`
  gap: ${tokens.spacingInsetQuarck};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  line-height: 2.2rem;
  border-radius: ${tokens.borderRadiusXs};
  padding: calc(${tokens.spacingMini} + 0.1rem) ${tokens.spacingNano};
  font-family: ${tokens.fontFamilyPoppins}, sans-serif;
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  background-color: ${tokens.brandColorPrimaryLightest};
  color: ${tokens.brandColorPrimaryPure};
  letter-spacing: 0.25px;

  svg:not(${CloseIcon}) {
    flex-shrink: 0;
    cursor: pointer;
    width: ${tokens.fontSizeXxss};
    height: ${tokens.fontSizeXxss};
  }

  ${({ $outlined, $type }) =>
    $outlined &&
    !$type &&
    css`
      background-color: ${tokens.neutralColorHighPure};
      border-style: solid;
      border-width: ${tokens.borderWidthThin};
      border-color: ${tokens.brandColorPrimaryPure};
    `}

  ${({ $type, $outlined }) => {
    const colors = $outlined ? badgeStatusOutlinedProps : badgeStatusProps;
    return (
      $type &&
      css`
        border: ${$outlined
          ? `solid ${tokens.borderWidthThin} ${colors[$type].border as string}`
          : 'none'};
        background-color: ${colors[$type].background};
        color: ${colors[$type].font};

        svg * {
          color: ${colors[$type].font};
        }
      `
    );
  }}
`;
