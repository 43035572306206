import { DefaultButton } from 'ds';
import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';

import {
  Container,
  Content,
  EmptyText,
  TipText
} from './TeamMembersEmptyResults.styles';

export function TeamMembersEmptyResults(): JSX.Element {
  const { handleOpenModal } = useModalContext();
  const { t } = useTranslation('settings');

  return (
    <Container>
      <Content>
        <EmptyText>{t('emptyList.noUsersFound')}</EmptyText>
        <TipText>{t('emptyList.tryAnotherTermOrRegisterNewMember')}</TipText>
      </Content>

      <DefaultButton
        size='small'
        onClick={() => {
          handleOpenModal('newTeamMember');
        }}
      >
        {t('teamMembers.newTeamMember')}
      </DefaultButton>
    </Container>
  );
}
