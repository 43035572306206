import { DefaultTable, tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type IUserAnalysisExpensesTableRowContainerStyleType } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisExpensesTableRow/UserAnalysisExpensesTableRow.types';

export const TableRowContainer = styled(DefaultTable.Tr)<
  AddPrefixToType<IUserAnalysisExpensesTableRowContainerStyleType>
>`
  ${({ $hasPendingConversion }) =>
    $hasPendingConversion &&
    css`
      td {
        background-color: ${tokens.highlightColorLightest};
      }
    `};
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img,
  svg,
  div {
    flex-shrink: 0;
  }
`;

export const TableCellWithOverflow = styled.span`
  display: flex;
  flex-grow: 1;

  .tooltip-container span {
    white-space: pre-wrap;
  }
`;

export const TextWithOverflow = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ExpenseValueWrapper = styled.div`
  display: grid;

  span {
    font-weight: ${tokens.fontWeightSemibold};
  }
`;
