import {
  type IBalanceSolicitation,
  type IBalanceSolicitationCachePayload,
  type IPersistenceBalanceSolicitationApprovedSummary,
  type IPersistenceUpdateAnswerableUserPayload
} from 'data/modules/cards/balance';

class BalanceSolicitationApprovedStagedMapper {
  toDomain(
    data: IPersistenceBalanceSolicitationApprovedSummary
  ): IBalanceSolicitation {
    return {
      id: data.id,
      userId: data.user_id,
      dateSent: data.date_send,
      dateApproved: data.date_approved,
      userName: data.user_name,
      approve: data.approve,
      justify: data.justify,
      requestedAmount: data.requested_amount.rawValue,
      transferAmount: data.transfer_amount.rawValue,
      balanceId: data.balance_id,
      status: data.status,
      destinationAccount: data.destination_account,
      managerJustify: data.manager_justify ?? null
    };
  }

  toPersistence(
    payload: IBalanceSolicitationCachePayload
  ): IPersistenceUpdateAnswerableUserPayload {
    return {
      domain: 'balance_solicitations',
      life_cycle: 48,
      value: payload.value.map(data => ({
        id: data.id,
        user_id: data.userId,
        user_name: data.userName,
        date_send: data.dateSent,
        date_approved: data.dateApproved,
        approve: data.approve as boolean,
        justify: data.justify,
        requested_amount: {
          formatted: data.requestedAmount.toString(),
          rawValue: data.requestedAmount
        },
        transfer_amount: {
          formatted: data.transferAmount?.toString() ?? '',
          rawValue: data.transferAmount
        },
        balance_id: data.balanceId,
        status: data.status,
        destination_account: data.destinationAccount,
        transferred_amount: data.transferredAmount as number | null
      }))
    };
  }
}

export default new BalanceSolicitationApprovedStagedMapper();
