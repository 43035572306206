import { useMemo, useRef, useState } from 'react';

import { BaseIcon } from 'presentation/components/base/Icon';
import { Title } from 'presentation/components/global/Title';

import { useOnClickOutside } from 'shared/hooks/global';

import { type ITripleColumnProps } from './TripleColumn.types';

import {
  CenterContent,
  Container,
  Content,
  ContentTitle,
  ExpandButton,
  LeftContent,
  RightContent
} from './TripleColumn.styles';

export function TripleColumn({
  centerElement,
  leftElement,
  rightElement,
  lgScreenGridSize,
  pageTitle,
  mobileLeftButtonIconName = 'equalizer',
  mobileRightButtonIconName = 'equalizer',
  pageTitleButtonProps
}: ITripleColumnProps): JSX.Element {
  const leftContentRef = useRef<HTMLDivElement>(null);
  const rightContentRef = useRef<HTMLDivElement>(null);
  const [isExpandedLeftContentOnMobile, setIsExpandedLeftContentOnMobile] =
    useState(false);
  const [isExpandedRightContentOnMobile, setIsExpandedRightContentOnMobile] =
    useState(false);

  function handleExpandOrCollapseContent(side: 'left' | 'right'): void {
    if (side === 'left') {
      setIsExpandedLeftContentOnMobile(prevState => !prevState);
    } else if (side === 'right') {
      setIsExpandedRightContentOnMobile(prevState => !prevState);
    }
  }

  function handleClickOutside(side: 'left' | 'right'): void {
    if (side === 'left' && isExpandedLeftContentOnMobile) {
      setIsExpandedLeftContentOnMobile(false);
    } else if (side === 'right' && isExpandedRightContentOnMobile) {
      setIsExpandedRightContentOnMobile(false);
    }
  }

  useOnClickOutside(leftContentRef, () => {
    handleClickOutside('left');
  });

  useOnClickOutside(rightContentRef, () => {
    handleClickOutside('right');
  });

  const contentLgGridTemplateColumns = useMemo(() => {
    const gridTemplateColumns = [];

    if (leftElement) {
      gridTemplateColumns.push(
        lgScreenGridSize?.left ? lgScreenGridSize.left : '1fr'
      );
    }

    gridTemplateColumns.push(
      lgScreenGridSize?.center ? lgScreenGridSize.center : '2fr'
    );

    if (rightElement) {
      gridTemplateColumns.push(
        lgScreenGridSize?.right ? lgScreenGridSize.right : '1fr'
      );
    }

    return gridTemplateColumns;
  }, [
    lgScreenGridSize?.left,
    lgScreenGridSize?.center,
    lgScreenGridSize?.right,
    leftElement,
    rightElement
  ]);

  return (
    <Container>
      <Content
        $hasLeftElement={!!leftElement}
        $hasRightElement={!!rightElement}
        $hasTitle={!!pageTitle}
        $lgGridTemplateColumns={contentLgGridTemplateColumns.join(' ')}
      >
        {pageTitle && (
          <ContentTitle>
            <Title
              text={pageTitle}
              buttonProps={pageTitleButtonProps}
              backgroundColor='alabaster'
            />
          </ContentTitle>
        )}
        {leftElement && (
          <LeftContent
            $isExpandedOnMobile={isExpandedLeftContentOnMobile}
            ref={leftContentRef}
          >
            <ExpandButton
              aria-expanded={isExpandedLeftContentOnMobile}
              onClick={() => {
                handleExpandOrCollapseContent('left');
              }}
              $side='left'
            >
              <BaseIcon
                name={mobileLeftButtonIconName}
                color='white'
                size={1.8}
                type='fill'
              />
            </ExpandButton>
            <div>{leftElement}</div>
          </LeftContent>
        )}
        <CenterContent>{centerElement}</CenterContent>
        {rightElement && (
          <RightContent
            $isExpandedOnMobile={isExpandedRightContentOnMobile}
            ref={rightContentRef}
          >
            <ExpandButton
              aria-expanded={isExpandedRightContentOnMobile}
              onClick={() => {
                handleExpandOrCollapseContent('right');
              }}
              $side='right'
            >
              <BaseIcon
                name={mobileRightButtonIconName}
                color='white'
                size={1.8}
                type='fill'
              />
            </ExpandButton>
            <div>{rightElement}</div>
          </RightContent>
        )}
      </Content>
    </Container>
  );
}
