import { CardInfo, tokens, Tooltip } from 'ds';

import { Icons } from 'shared/constants/global';

import { type IAnalysisItemCardsProps } from './AnalysisItemCards.types';

import { Container, IconWrapper } from './AnalysisItemCards.styles';

export function AnalysisItemCards({
  cards,
  children,
  ...props
}: IAnalysisItemCardsProps): JSX.Element {
  return (
    <Container {...props}>
      {cards.map(card => (
        <CardInfo.Container key={card.title}>
          <CardInfo.Title variant='small'>{card.title}</CardInfo.Title>

          <CardInfo.ValueContainer>
            {card.showLeftIcon && (
              <IconWrapper>
                <Icons.CashBanknoteWarningIcon />
              </IconWrapper>
            )}
            <CardInfo.Value>{card.value}</CardInfo.Value>
            {card.tooltip && (
              <Tooltip
                message={card.tooltip}
                placement='top'
                containerClassName='analysis-summary-card-tooltip'
              >
                <div>
                  <Icons.InfoIcon color={tokens.neutralColorLowLight} />
                </div>
              </Tooltip>
            )}
          </CardInfo.ValueContainer>
        </CardInfo.Container>
      ))}
    </Container>
  );
}
