import { useQueryClient } from '@tanstack/react-query';

import { useQueryCache } from 'data/cache';
import {
  FuelQueryKeys,
  FuelService,
  type IUseGetPaginatedMembers,
  type IUseGetPaginatedMembersParams
} from 'data/modules/fuel';

import { CustomObject } from 'shared/utils/custom';

export function useGetPaginatedMembers(
  params: IUseGetPaginatedMembersParams
): IUseGetPaginatedMembers {
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching, isInitialLoading } = useQueryCache({
    queryKey: [
      FuelQueryKeys.GET_MEMBERS,
      CustomObject.omit(params, ['enabled'])
    ],
    queryFn: async () =>
      await FuelService.getPaginatedMembers(
        CustomObject.omit(params, ['enabled'])
      ),
    enabled: params?.enabled
  });

  function invalidatePaginatedMembersQueries(): void {
    queryClient.invalidateQueries({
      queryKey: [FuelQueryKeys.GET_MEMBERS]
    });
  }

  function removeQueriesByVehicleId(vehicleId: number): void {
    queryClient.removeQueries({
      predicate: query => {
        return (
          query.queryKey[0] === FuelQueryKeys.GET_MEMBERS &&
          (query.queryKey[1] as IUseGetPaginatedMembersParams)?.vehicleId ===
            vehicleId
        );
      }
    });
  }

  return {
    paginatedMembers: data,
    isLoadingPaginatedMembers: isLoading,
    isFetchingPaginatedMembers: isFetching,
    isInitialLoadingPaginatedMembers: isInitialLoading,
    invalidatePaginatedMembersQueries,
    removeQueriesByVehicleId
  };
}
