import { type ChangeEvent, useEffect, useRef, useState } from 'react';

import { useController, useFormContext } from 'react-hook-form';

import { type ICreateFuelExpenseFirstStepFieldsForm } from 'data/modules/expenses';

import { type IUseSelectOdometerImage } from './SelectOdometerImage.types';

export function useSelectOdotometerImage(): IUseSelectOdometerImage {
  const inputRef = useRef<HTMLInputElement>(null);

  const { control } = useFormContext<ICreateFuelExpenseFirstStepFieldsForm>();

  const {
    field: { value: selectedOdometerImage, onChange: onChangeOdometerImage },
    fieldState: { error }
  } = useController({
    name: 'odometerImage',
    control
  });

  const [blobImageFile, setBlobImageFile] = useState<
    string | ArrayBuffer | null | undefined
  >(null);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);

  useEffect(() => {
    const currentFile: File | string | null = selectedOdometerImage;

    if (currentFile !== null && currentFile instanceof File) {
      const fileReader = new FileReader();
      const isCancel = false;

      fileReader.onload = e => {
        const result: string | ArrayBuffer | null | undefined =
          e.target?.result;

        if (result && !isCancel) {
          setBlobImageFile(result);
        }
      };

      fileReader.readAsDataURL(currentFile);
      return;
    }

    if (
      currentFile !== null &&
      typeof currentFile === 'string' &&
      currentFile !== ''
    ) {
      setBlobImageFile(currentFile);
      return;
    }

    setBlobImageFile(null);
  }, [selectedOdometerImage]);

  const acceptedFileTypes = ['jpeg', 'png', 'jpg'];

  function handleChangeFile(e: ChangeEvent<HTMLInputElement>): void {
    if (!e.target.files) return;

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const extension = file.name.split('.').pop();
      const size = Math.round(+file.size / 1024) / 1000;

      if (size > 16) {
        setSizeError(true);
        onChangeOdometerImage(null);
        return;
      }

      if (extension === undefined || !acceptedFileTypes.includes(extension)) {
        setFileTypeError(true);
        onChangeOdometerImage(null);
        return;
      }

      onChangeOdometerImage(file);
      setFileTypeError(false);
      setSizeError(false);
    }
  }

  const hookFormError = error?.message ?? '';
  const hasError = fileTypeError || sizeError || hookFormError !== '';

  return {
    inputRef,
    handleChangeFile,
    blobImageFile,
    fileTypeError,
    hasError,
    sizeError,
    hookFormError,
    acceptedFileTypes
  };
}
