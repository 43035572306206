import { useMemo } from 'react';

import { format, subMonths } from 'date-fns';

import { useAuthContext, useLangContext } from 'data/contexts';
import {
  useGetAccountBalancesAmounts,
  useGetCardDataByAccountId,
  useGetOutsourcedUserAccounts,
  useGetUserAccountsByUserUuid
} from 'data/modules/cards/account';
import {
  type IAccountStatement,
  useGetAccountStatement
} from 'data/modules/cards/statement';

import { SkeletonLoader } from 'presentation/components/global/Loader';

import { Statement } from 'shared/utils/cards';
import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';
import { Lang } from 'shared/utils/global';

import { type IUseCardAndValues } from './CardAndValues.types';

import { Value } from './CardAndValues.styles';

export function useCardAndValues(): IUseCardAndValues {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const user = useAuthContext(state => state.user);

  const today = new Date();

  const isSharedAccount = !!user?.cards?.sharedAccounts;

  const { userAccounts, isFetchingUserAccounts } = useGetUserAccountsByUserUuid(
    {
      userUuid: user?.cards?.id as string,
      enabled: !isSharedAccount
    }
  );

  const { outsourcedUserAccounts, isFetchingOutsourcedUserAccounts } =
    useGetOutsourcedUserAccounts({
      outsourcedUserUuid: user?.cards?.id as string,
      enabled: isSharedAccount
    });

  const { cardData, isFetchingCardData } = useGetCardDataByAccountId({
    accountId: isSharedAccount
      ? (outsourcedUserAccounts?.[0].account.id as string)
      : (userAccounts?.[0].account.id as string),
    enabled: isSharedAccount
      ? !!outsourcedUserAccounts?.[0].account.id
      : !!userAccounts?.[0].account.id
  });

  const userHasCard = !!cardData;

  const {
    accountBalancesAmounts,
    isFetchingAccountBalancesAmounts: isFetchingAccountBalanceAmount
  } = useGetAccountBalancesAmounts({
    accountBalancesIds: [
      isSharedAccount
        ? (outsourcedUserAccounts?.[0].balances.find(balance => balance.inUse)
            ?.id as string)
        : (userAccounts?.[0].balances.find(balance => balance.inUse)
            ?.id as string)
    ] as string[],
    enabled: userHasCard
  });

  const accountBalanceAmount = accountBalancesAmounts?.[0];

  const isFetchingUserAccountsOrFetchingCardData =
    isFetchingUserAccounts ||
    isFetchingOutsourcedUserAccounts ||
    isFetchingCardData;

  const isFetchingCardDataAndAccountBalanceAmount =
    isFetchingUserAccountsOrFetchingCardData || isFetchingAccountBalanceAmount;

  const activeAccountBalancesIds = isSharedAccount
    ? outsourcedUserAccounts?.reduce<string[]>((acc, userAccount) => {
        const activeAccountBalanceId = userAccount.balances.find(
          balance => balance.inUse
        )?.id;

        if (activeAccountBalanceId) {
          return [...acc, activeAccountBalanceId];
        }

        return acc;
      }, []) ?? []
    : userAccounts?.reduce<string[]>((acc, userAccount) => {
        const activeAccountBalanceId = userAccount.balances.find(
          balance => balance.inUse
        )?.id;

        if (activeAccountBalanceId) {
          return [...acc, activeAccountBalanceId];
        }

        return acc;
      }, []) ?? [];

  const { accountsStatements, isLoadingAccountsStatements } =
    useGetAccountStatement({
      accountBalancesIds: userHasCard ? activeAccountBalancesIds : [],
      startDate: format(subMonths(today, 1), 'yyyy-MM-dd').concat(
        'T00:00:00.000Z'
      ),
      endDate: format(today, 'yyyy-MM-dd').concat('T00:00:00.000Z'),
      timezone: CustomDate.getTimezone()
    });

  const accountStatementWithOnlyDebits: IAccountStatement[] = accountsStatements
    ?.map(accountStatementRow => {
      const filteredApprovedTransactions = Statement.filterApprovedTransactions(
        accountStatementRow.items
      );

      const transactionsWithDebitType =
        Statement.filterTransactionsByMovementType(
          filteredApprovedTransactions,
          'exits'
        );

      const adjustedAccountStatementRow = {
        ...accountStatementRow,
        items: transactionsWithDebitType
      };

      return adjustedAccountStatementRow;
    })
    .filter(accountStatementRow => accountStatementRow.items.length > 0);

  const totalExpensesForTheLastThirtyDays =
    accountStatementWithOnlyDebits.reduce((acc, accountStatementRow) => {
      const notReversedTransactions = accountStatementRow.items.filter(
        item =>
          !item.reversed &&
          item.isReversal === 'NOT' &&
          !(
            (item.type === 'TRANSFER_DEBIT' ||
              item.type === 'TRANSFER_CREDIT') &&
            item.originalTransactionId === null
          )
      );

      const transactionsAmount = notReversedTransactions.reduce(
        (acc, transaction) => {
          const transactionAmount = transaction.finalBillingAmount;

          return acc + transactionAmount;
        },
        0
      );

      return acc + transactionsAmount;
    }, 0);

  const orderedAccountStatementByDateDesc = [...accountStatementWithOnlyDebits]
    .sort(Statement.sortStatementByDate)
    .reverse();

  const lastExpenseAmount =
    orderedAccountStatementByDateDesc[0]?.items[0].billingAmountAuthorized ?? 0;

  const formattedLastThirtyDays = useMemo(() => {
    return (
      <span>
        {Lang.putValuesInString(
          lang.cards.statement.sidebar.card_and_values.last_days[
            currentLangKey
          ],
          { days: '30' }
        )}
      </span>
    );
  }, [lang.cards.statement.sidebar.card_and_values.last_days, currentLangKey]);

  const formattedTotalExpensesForTheLastThirtyDays = useMemo(() => {
    if (
      isFetchingUserAccountsOrFetchingCardData ||
      isLoadingAccountsStatements
    ) {
      return (
        <>
          <SkeletonLoader
            maxWidth='6.2rem'
            height='2.1rem'
            margin='0.3rem auto'
          />
          {formattedLastThirtyDays}
        </>
      );
    }

    if (userHasCard) {
      return (
        <>
          <Value>
            <label>BRL</label>
            {Currency.format(
              'BRL',
              Math.abs(totalExpensesForTheLastThirtyDays)
            )}
          </Value>
          {formattedLastThirtyDays}
        </>
      );
    }

    return (
      <span>
        {
          lang.cards.statement.sidebar.card_and_values.no_linked_card[
            currentLangKey
          ]
        }
      </span>
    );
  }, [
    userHasCard,
    totalExpensesForTheLastThirtyDays,
    isFetchingUserAccountsOrFetchingCardData,
    lang,
    currentLangKey,
    isLoadingAccountsStatements,
    formattedLastThirtyDays
  ]);

  const formattedLastExpenseAmount = useMemo(() => {
    if (isLoadingAccountsStatements) {
      return (
        <SkeletonLoader
          maxWidth='9.5rem'
          height='1.6rem'
          margin='0.2rem auto'
        />
      );
    }

    if (lastExpenseAmount !== 0) {
      return (
        <span>
          {Lang.putValuesInString(
            lang.cards.statement.sidebar.card_and_values.last_expense_of[
              currentLangKey
            ],
            {
              value: Currency.format('BRL', Math.abs(lastExpenseAmount), true)
            }
          )}
        </span>
      );
    }

    return <></>;
  }, [
    currentLangKey,
    lang.cards.statement.sidebar.card_and_values.last_expense_of,
    lastExpenseAmount,
    isLoadingAccountsStatements
  ]);

  const currentBalanceBlock = useMemo(() => {
    if (isFetchingCardDataAndAccountBalanceAmount) {
      return (
        <>
          <SkeletonLoader
            maxWidth='6.2rem'
            height='2.1rem'
            margin='0.3rem auto'
          />
          {formattedLastExpenseAmount}
        </>
      );
    }

    if (userHasCard) {
      return (
        <>
          <Value>
            <label>BRL</label>
            {Currency.format('BRL', accountBalanceAmount as number)}
          </Value>
          {formattedLastExpenseAmount}
        </>
      );
    }

    return (
      <span>
        {
          lang.cards.statement.sidebar.card_and_values.no_linked_card[
            currentLangKey
          ]
        }
      </span>
    );
  }, [
    isFetchingCardDataAndAccountBalanceAmount,
    accountBalanceAmount,
    userHasCard,
    formattedLastExpenseAmount,
    lang,
    currentLangKey
  ]);

  return {
    formattedTotalExpensesForTheLastThirtyDays,
    currentBalanceBlock,
    cardData,
    isFetchingUserAccountsOrFetchingCardData
  };
}
