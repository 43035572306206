import { useLangContext } from 'data/contexts';

import { Container } from './TariffsTableHeader.styles';

export function TariffsTableHeader(): JSX.Element {
  const [settings, currentLangKey] = useLangContext(state => [
    state.lang.settings,
    state.currentLangKey
  ]);

  return (
    <Container>
      <th>{settings.tariffs.tariff[currentLangKey]}</th>

      <th>{settings.tariffs.value[currentLangKey]}</th>

      <th>{settings.tariffs.event[currentLangKey]}</th>

      <th>{settings.tariffs.charge[currentLangKey]}</th>
    </Container>
  );
}
