export const localStorageKeys = {
  pageSize: '@vex/pageSize',
  feedbackChargeCards: '@vex/feedbackChargeCards',
  feedbackValueSolicitation: '@vex/feedbackValueSolicitation',
  feedbackCards: '@vex/feedbackCards',
  valueAllocationTutorial: '@vex/alreadyShowedValueAllocationTutorial',
  cardsTour: '@vex/alreadyShowedCardsTour',
  chargeCardsTour: '@vex/alreadyShowedChargeCardsTour',
  valueSolicitationTour: '@vex/alreadyShowedValueSolicitationTour',
  expensesPageTour: '@vex/alreadyShowedExpensePageTour',
  reportsPageTour: '@vex/alreadyShowedReportsPageTour',
  zoomTour: '@vex/alreadyShowedZoomTour',
  manualExpenseModalTour: '@vex/alreadyShowedManualExpenseModalTour',
  routeByMapModalTour: '@vex/alreadyShowedRouteByMapModalTour',
  reportSelectionTour: '@vex/alreadyShowedReportSelectionTour',
  viewExpensesModalTour: '@vex/alreadyShowedViewExpensesModalTour'
};
