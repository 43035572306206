import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 45rem;
  gap: ${tokens.spacingXs};
  padding: ${tokens.spacingXxs};
  border-radius: ${tokens.spacingXs};
  background-color: ${tokens.neutralColorHighLight};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 80rem;
    flex-direction: row;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: fit-content;
  }
`;
