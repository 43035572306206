import { CardInfo } from 'ds';
import { useParams } from 'react-router-dom';

import { useLangContext, useValueSolicitationContext } from 'data/contexts';
import {
  useGetAllocatedBalance,
  useGetUnallocatedBalance
} from 'data/modules/cards/balance';

import { animations } from 'shared/constants/animations';
import { Currency } from 'shared/utils/format';
import { Lang } from 'shared/utils/global';

import { useBalanceCardSection } from './useBalanceCardSection';

import { type IBalanceCardSectionProps } from './BalanceCardSection.types';

import {
  CardInfoContainer,
  CardsContent,
  Container
} from './BalanceCardSection.styles';

export function BalanceCardSection({
  ...rest
}: IBalanceCardSectionProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  const { groupId } = useParams();

  const requestedAmount = useValueSolicitationContext(
    state => state.requestedAmount
  );

  const {
    isLoadingAllocatedBalance,
    allocatedBalance,
    refreshAllocatedBalance
  } = useGetAllocatedBalance({ ...(groupId && { cardGroupId: groupId }) });

  const { isFetchingUnallocatedBalance, unallocatedBalance } =
    useGetUnallocatedBalance({ ...(groupId && { cardGroupId: groupId }) });

  useBalanceCardSection();

  return (
    <Container {...rest}>
      <CardsContent {...animations.popFadeInOut}>
        <CardInfoContainer>
          <CardInfo.Title variant='small'>
            {
              lang.cards.valueSolicitation.cards_info
                .amount_available_to_allocate[currentLangKey]
            }
          </CardInfo.Title>

          <CardInfo.ValueContainer>
            <CardInfo.Value
              isLoading={isFetchingUnallocatedBalance}
              variant='small'
            >
              {unallocatedBalance &&
                Currency.format('BRL', unallocatedBalance?.amount, true)}
            </CardInfo.Value>
          </CardInfo.ValueContainer>

          <CardInfo.Caption variant='small'>
            {
              lang.cards.valueSolicitation.cards_info
                .amount_not_allocated_to_cards[currentLangKey]
            }
          </CardInfo.Caption>
        </CardInfoContainer>

        <CardInfoContainer>
          <CardInfo.Title variant='small'>
            {
              lang.cards.valueSolicitation.cards_info
                .amount_distributed_on_cards[currentLangKey]
            }
          </CardInfo.Title>

          <CardInfo.ValueContainer>
            <CardInfo.Value
              isLoading={isLoadingAllocatedBalance}
              variant='small'
            >
              {allocatedBalance &&
                Currency.format('BRL', allocatedBalance?.amount, true)}
            </CardInfo.Value>

            <CardInfo.RefreshButton
              onClick={() => {
                !isLoadingAllocatedBalance && refreshAllocatedBalance.mutate(1);
              }}
            />
          </CardInfo.ValueContainer>

          <CardInfo.Caption
            isLoading={isLoadingAllocatedBalance}
            variant='small'
          >
            {allocatedBalance &&
              Lang.putValuesInString(
                lang.cards.valueSolicitation.cards_info.updated_on[
                  currentLangKey
                ],
                {
                  date: allocatedBalance.lastUpdateDate,
                  hours: allocatedBalance.lastUpdateTime
                }
              )}
          </CardInfo.Caption>
        </CardInfoContainer>

        <CardInfoContainer>
          <CardInfo.Title variant='small'>
            {
              lang.cards.valueSolicitation.cards_info
                .amount_available_after_allocating[currentLangKey]
            }
          </CardInfo.Title>

          <CardInfo.ValueContainer>
            <CardInfo.Value
              isLoading={isFetchingUnallocatedBalance}
              variant='small'
            >
              {unallocatedBalance &&
                Currency.format(
                  'BRL',
                  unallocatedBalance?.amount - requestedAmount,
                  true
                )}
            </CardInfo.Value>
          </CardInfo.ValueContainer>

          <CardInfo.Caption variant='small'>
            {
              lang.cards.valueSolicitation.cards_info
                .amount_not_allocated_to_cards[currentLangKey]
            }
          </CardInfo.Caption>
        </CardInfoContainer>

        <CardInfoContainer>
          <CardInfo.Title variant='small'>
            {
              lang.cards.valueSolicitation.cards_info
                .amount_distributed_after_allocating[currentLangKey]
            }
          </CardInfo.Title>

          <CardInfo.Value
            variant='small'
            isLoading={isLoadingAllocatedBalance}
            className={
              allocatedBalance &&
              allocatedBalance?.amount + requestedAmount !==
                allocatedBalance?.amount
                ? 'balance-card-feedback'
                : ''
            }
          >
            {allocatedBalance &&
              Currency.format(
                'BRL',
                allocatedBalance?.amount + requestedAmount,
                true
              )}
          </CardInfo.Value>

          <CardInfo.Caption variant='small'>
            {
              lang.cards.valueSolicitation.cards_info.total_value_of_transfers[
                currentLangKey
              ]
            }
          </CardInfo.Caption>
        </CardInfoContainer>
      </CardsContent>
    </Container>
  );
}
