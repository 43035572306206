import { useTranslation } from 'react-i18next';

import { type IUseMyValueSolicitationsFilters } from './MyValueSolicitationsFilters.types';

export function useMyValueSolicitationFilters(): IUseMyValueSolicitationsFilters {
  const { t } = useTranslation('cards');

  const valueSolicitationsStatusOptions = [
    {
      value: 'ALL',
      label: t('all')
    },
    {
      value: 'SENT',
      label: t('pending')
    },
    {
      value: 'APPROVED',
      label: t('approved')
    },
    {
      value: 'REJECTED',
      label: t('rejected')
    }
  ];

  return {
    valueSolicitationsStatusOptions
  };
}
