import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightRegular};
  line-height: ${tokens.spacingMd};
  letter-spacing: 0.75px;
  color: ${tokens.neutralColorLowDark};
`;
