import { Pagination } from 'ds/components/Table/Pagination';
import { useShallow } from 'zustand/react/shallow';

import { useMyValueSolicitationContext } from 'data/contexts';
import { type PaginationType } from 'data/modules/global';

import { type IMyValueSolicitationsTablePaginationProps } from './MyValueSolicitationsTablePagination.types';

import {
  Container,
  StyledControl,
  StyledPagination
} from './MyValueSolicitationsTablePagination.styles';

export function MyValueSolicitationsTablePagination({
  disable,
  ...rest
}: IMyValueSolicitationsTablePaginationProps): JSX.Element {
  const [
    currentPage,
    pageSize,
    totalPages,
    handleChangeMyValueSolicitationPagination
  ] = useMyValueSolicitationContext(
    useShallow(state => [
      state.paginationMyValueSolicitations.currentPage,
      state.paginationMyValueSolicitations.pageSize,
      state.paginationMyValueSolicitations.totalPages,
      state.handleChangeMyValueSolicitationPagination
    ])
  );

  return (
    <Container {...rest}>
      {!disable && (
        <StyledPagination
          currentPage={currentPage}
          perPage={pageSize}
          totalPage={totalPages ?? 0}
        >
          <Pagination.Label langKey='pt' />

          <StyledControl
            onChangePage={page => {
              handleChangeMyValueSolicitationPagination({ currentPage: page });
            }}
          />

          <Pagination.Pages
            langKey='pt'
            onChangePerPage={pageSize => {
              handleChangeMyValueSolicitationPagination({
                pageSize: pageSize as PaginationType
              });
            }}
            options={[10, 20, 50]}
          />
        </StyledPagination>
      )}
    </Container>
  );
}
