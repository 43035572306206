import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';

import { CardNumber } from 'shared/utils/format';

import { type ICreditCardProps } from './CreditCard.types';

import {
  BlockedOverlay,
  Container,
  StyledCardNumber
} from './CreditCard.styles';

export function CreditCard({
  number,
  isBlocked = false,
  isDisabled = false,
  isLoading = false
}: ICreditCardProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  return (
    <Container $isDisabled={isDisabled || number === null}>
      <StyledCardNumber>
        {isLoading && '****'}
        {!isLoading &&
          (number
            ? CardNumber.format(number)
            : lang.cards.global.not_bound[currentLangKey])}
      </StyledCardNumber>

      {isBlocked && (
        <BlockedOverlay>
          <BaseIcon
            name='lock'
            size={4}
            color='gallery'
          />
        </BlockedOverlay>
      )}
    </Container>
  );
}
