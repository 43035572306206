import { api, env } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import { persistenceMockCardGroupsDistributedValue } from 'data/mocks';
import {
  checkIfCardCanBeRemovedMock,
  checkIfCardsCanBeAddedMock,
  getAllocatedBalanceCardGroupMock,
  getAvailableToBeManagerMock,
  getCardGroupMock,
  getCardGroupsMock,
  getGroupManagersMock,
  getGroupUsersMock,
  getManagersGroupsMock,
  getPartialManagersMock,
  getUsersWithoutCardGroupMock
} from 'data/mocks/cards/cardGroups';
import {
  type IAddCardsToGroupPayload,
  type IAddManagersToGroupPayload,
  type IAllocatedBalanceCardGroup,
  type ICardGroup,
  type ICardGroupsDistributedValue,
  type ICardsAddPermissions,
  type ICheckIfCardCanBeRemovedPayload,
  type ICheckIfCardsCanBeAddedPayload,
  type ICreateGroupCardPayload,
  type IEditCardGroupNamePayload,
  type IGetAvailableToBeManager,
  type IGetAvailableToBeManagerPayload,
  type IGetCardGroupDataPayload,
  type IGetCardGroupsDistributedValuePayload,
  type IGetCardGroupsPayload,
  type IGetCardGroupUsersParams,
  type IGetManagersGroupsPayload,
  type IGetUsersWithoutCardGroup,
  type IGetUsersWithoutCardGroupPayload,
  type IPaginatedCardGroup,
  type IPaginatedCardGroupManagers,
  type IPaginatedCardGroupUsers,
  type IPaginatedManagersGroups,
  type IPersistenceAvailableToBeManager,
  type IPersistenceCardGroup,
  type IPersistenceCardGroupManager,
  type IPersistenceCardGroupUser,
  type IPersistenceGetCardGroupManagersParams,
  type IPersistenceManagerGroup,
  type IPersistenceUserCardGroup,
  type IRemoveCardFromGroupPayload,
  type IRemoveManagerFromGroupPayload
} from 'data/modules/cards/cardGroups';
import {
  type IDefaultResponse,
  type IDomainResponse,
  type IObjectsArray
} from 'data/modules/global';

import { MockApi } from 'shared/utils/global';

import GetManagersCardGroupsMapper from './mappers/GetManagersCardGroups.mapper';
import {
  CheckIfCardsCanBeAddedMapper,
  GetAllocatedBalanceCardGroupMapper,
  GetAvailableToBeManagerMapper,
  GetCardGroupsDistributedValueMapper,
  GetCardGroupsMapper,
  GetCardGroupUsersMapper,
  GetGroupManagersMapper,
  GetUsersWithoutCardGroupMapper
} from './mappers';

class CardGroupsService {
  /** Retorna todos os grupos de cartões */
  async getCardGroups(
    payload: IGetCardGroupsPayload
  ): Promise<IPaginatedCardGroup> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getCardGroupsMock();
    }

    const response = await api.get<
      IDefaultResponse<IObjectsArray<IPersistenceCardGroup>>
    >('pay/v2/app/card-groups', {
      params: GetCardGroupsMapper.toPersistence(payload)
    });

    const { data, total } = ResponseAdapter.formatObjectsArrayResponse(
      response.data
    );

    return {
      data: data?.map(GetCardGroupsMapper.toDomain) ?? [],
      total: total ?? 0
    };
  }

  // Retorna os dados de um grupo de cartões
  async getCardGroup({
    cardGroupId
  }: IGetCardGroupDataPayload): Promise<ICardGroup | null> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getCardGroupMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceCardGroup | null>
    >(`/pay/v2/app/card-groups/${cardGroupId}`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data !== null ? GetCardGroupsMapper.toDomain(data) : null;
  }

  /** Retorna apenas os gestores de grupo que não são gestores de conta */
  async getPartialManagers(): Promise<IPaginatedCardGroupManagers> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getPartialManagersMock();
    }

    const response = await api.get<
      IDefaultResponse<IObjectsArray<IPersistenceCardGroupManager>>
    >('/pay/v2/app/card-group-admins', { params: { limit: 100 } });

    const { data, total } = ResponseAdapter.formatObjectsArrayResponse(
      response.data
    );

    return {
      data: data?.map(object => object) ?? [],
      total: total ?? 0
    };
  }

  /** Retorna listagem de contas de um gestor de grupo **/
  async getManagersGroups(
    payload: IGetManagersGroupsPayload
  ): Promise<IPaginatedManagersGroups> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getManagersGroupsMock();
    }

    const response = await api.get<
      IDefaultResponse<IObjectsArray<IPersistenceManagerGroup>>
    >('pay/v2/app/card-groups/manager-groups', {
      params: GetManagersCardGroupsMapper.toPersistence(payload)
    });

    const { data, total } = ResponseAdapter.formatObjectsArrayResponse(
      response.data
    );

    return {
      data: data?.map(GetManagersCardGroupsMapper.toDomain) ?? [],
      total: total ?? 0
    };
  }

  /** Retorna os gestores do grupo */
  async getGroupManagers({
    id,
    ...rest
  }: IPersistenceGetCardGroupManagersParams): Promise<IPaginatedCardGroupManagers> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getGroupManagersMock();
    }

    const response = await api.get<
      IDefaultResponse<IObjectsArray<IPersistenceCardGroupManager>>
    >(`/pay/v2/app/card-groups/${id}/managers`, {
      params: GetGroupManagersMapper.toPersistence({ ...rest })
    });

    const { data, total } = ResponseAdapter.formatObjectsArrayResponse(
      response.data
    );

    return {
      data: data?.map(object => object) ?? [],
      total: total ?? 0
    };
  }

  /** Retorna os portadores do grupo */
  async getGroupUsers({
    id,
    ...rest
  }: IGetCardGroupUsersParams): Promise<IPaginatedCardGroupUsers> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getGroupUsersMock();
    }

    const response = await api.get<
      IDefaultResponse<IObjectsArray<IPersistenceCardGroupUser>>
    >(`/pay/v2/app/card-groups/${id}/users`, {
      params: GetCardGroupUsersMapper.toPersistence({ ...rest })
    });

    const { data, total } = ResponseAdapter.formatObjectsArrayResponse(
      response.data
    );

    return {
      data: data?.map(GetCardGroupUsersMapper.toDomain) ?? [],
      total: total ?? 0
    };
  }

  async getUsersWithoutCardGroup(
    payload: IGetUsersWithoutCardGroupPayload
  ): Promise<IGetUsersWithoutCardGroup> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getUsersWithoutCardGroupMock();
    }

    const response = await api.get<
      IDefaultResponse<IObjectsArray<IPersistenceUserCardGroup>>
    >('/pay/v2/app/users/without-card-group', {
      params: GetUsersWithoutCardGroupMapper.toPersistence(payload)
    });

    const { data, total } = ResponseAdapter.formatObjectsArrayResponse(
      response.data
    );

    return {
      data: data?.map(GetUsersWithoutCardGroupMapper.toDomain) ?? [],
      total: total ?? 0
    };
  }

  async getAvailableToBeManager(
    payload: IGetAvailableToBeManagerPayload
  ): Promise<IGetAvailableToBeManager> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getAvailableToBeManagerMock();
    }

    const response = await api.get<
      IDefaultResponse<IObjectsArray<IPersistenceAvailableToBeManager>>
    >('/pay/v2/app/users/card-groups/available-to-be-manager', {
      params: GetAvailableToBeManagerMapper.toPersistence(payload)
    });

    const { data, total } = ResponseAdapter.formatObjectsArrayResponse(
      response.data
    );

    return {
      data: data?.map(GetAvailableToBeManagerMapper.toDomain) ?? [],
      total: total ?? 0
    };
  }

  async createCardGroup(
    payload: ICreateGroupCardPayload
  ): Promise<IDomainResponse<null>> {
    const response = await api.post<IDefaultResponse<null>>(
      '/pay/v2/app/card-groups/create',
      payload
    );

    return ResponseAdapter.formatRegularResponse(response.data);
  }

  async removeCardFromGroup({
    id,
    users
  }: IRemoveCardFromGroupPayload): Promise<void> {
    await api.put(`/pay/v2/app/card-groups/${id}/detach-users`, { users });
  }

  async editGroupName({ id, name }: IEditCardGroupNamePayload): Promise<void> {
    await api.put(`/pay/v2/app/card-groups/${id}/update-name`, { name });
  }

  async getCardGroupsDistributedValue(
    payload: IGetCardGroupsDistributedValuePayload
  ): Promise<ICardGroupsDistributedValue> {
    const persistenceCardGroupsDistributedValue = await MockApi.getData(
      persistenceMockCardGroupsDistributedValue,
      2500
    );

    return GetCardGroupsDistributedValueMapper.toDomain(
      persistenceCardGroupsDistributedValue
    );
  }

  async removeManagerFromGroup({
    id,
    managers
  }: IRemoveManagerFromGroupPayload): Promise<void> {
    await api.put(`/pay/v2/app/card-groups/${id}/detach-managers`, {
      managers
    });
  }

  async addManagersToGroup({
    id,
    managers
  }: IAddManagersToGroupPayload): Promise<void> {
    await api.put(`/pay/v2/app/card-groups/${id}/attach-managers`, {
      managers
    });
  }

  async checkIfCardCanBeRemoved({
    userId
  }: ICheckIfCardCanBeRemovedPayload): Promise<boolean> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await checkIfCardCanBeRemovedMock();
    }

    const response = await api.get<IDefaultResponse<null>>(
      `/pay/v2/app/card-group-users/${userId}/can-be-detach`
    );

    const data = ResponseAdapter.formatRegularResponse(response.data);

    return data.success;
  }

  /* Verifica se os cartões podem ser adicionados ao grupo */
  async checkIfCardsCanBeAdded({
    usersIds
  }: ICheckIfCardsCanBeAddedPayload): Promise<ICardsAddPermissions> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await checkIfCardsCanBeAddedMock();
    }

    const response = await api.get<IDefaultResponse<ICardsAddPermissions>>(
      `/pay/v2/app/card-group-users/can-be-attach`,
      { params: CheckIfCardsCanBeAddedMapper.toPersistence({ usersIds }) }
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async addCardsToGroup({ id, users }: IAddCardsToGroupPayload): Promise<void> {
    await api.put(`/pay/v2/app/card-groups/${id}/attach-users`, {
      users
    });
  }

  /* Soma do saldo das bags das subcontas  */
  async getAllocatedBalanceCardGroup(): Promise<IAllocatedBalanceCardGroup> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getAllocatedBalanceCardGroupMock();
    }

    const response = await api.get<
      IDefaultResponse<IAllocatedBalanceCardGroup>
    >('pay/v2/app/card-groups/allocated-balance');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetAllocatedBalanceCardGroupMapper.toDomain(data);
  }
}

export default new CardGroupsService();
