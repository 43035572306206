import { faker } from '@faker-js/faker';

import { type IAllocatedBalanceCardGroup } from 'data/modules/cards/cardGroups';

import { MockApi } from 'shared/utils/global';

export async function getAllocatedBalanceCardGroupMock(): Promise<IAllocatedBalanceCardGroup> {
  const data = {
    amount: faker.number.float({ fractionDigits: 1, max: 5000 })
  };

  await MockApi.sleep(2000);

  return data;
}
