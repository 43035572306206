import { faker } from '@faker-js/faker';

import {
  type CardGroupStatusType,
  type CardStatusType,
  type IPaginatedCardGroupUsers
} from 'data/modules/cards/cardGroups';

import { MockApi } from 'shared/utils/global';

import { cardGroupStatusOptions, cardStatusOptions } from '..';

export async function getGroupUsersMock(): Promise<IPaginatedCardGroupUsers> {
  const data = [
    {
      id: faker.string.uuid(),
      name: faker.person.fullName(),
      cardNumber: faker.string.numeric(16),
      cardStatus: faker.helpers.arrayElement(
        cardStatusOptions
      ) as CardStatusType,
      type: faker.string.numeric(4),
      balanceId: faker.string.uuid(),
      status: faker.helpers.arrayElement(
        cardGroupStatusOptions
      ) as CardGroupStatusType,
      userId: faker.string.uuid(),
      amount: faker.number.float({ fractionDigits: 1, max: 500 })
    },
    {
      id: faker.string.uuid(),
      name: faker.person.fullName(),
      cardNumber: faker.string.numeric(16),
      cardStatus: faker.helpers.arrayElement(
        cardStatusOptions
      ) as CardStatusType,
      type: faker.string.numeric(4),
      balanceId: faker.string.uuid(),
      status: faker.helpers.arrayElement(
        cardGroupStatusOptions
      ) as CardGroupStatusType,
      userId: faker.string.uuid(),
      amount: faker.number.float({ fractionDigits: 1, max: 500 })
    },
    {
      id: faker.string.uuid(),
      name: faker.person.fullName(),
      cardNumber: faker.string.numeric(16),
      cardStatus: faker.helpers.arrayElement(
        cardStatusOptions
      ) as CardStatusType,
      type: faker.string.numeric(4),
      balanceId: faker.string.uuid(),
      status: faker.helpers.arrayElement(
        cardGroupStatusOptions
      ) as CardGroupStatusType,
      userId: faker.string.uuid(),
      amount: faker.number.float({ fractionDigits: 1, max: 500 })
    },
    {
      id: faker.string.uuid(),
      name: faker.person.fullName(),
      cardNumber: faker.string.numeric(16),
      cardStatus: faker.helpers.arrayElement(
        cardStatusOptions
      ) as CardStatusType,
      type: faker.string.numeric(4),
      balanceId: faker.string.uuid(),
      status: faker.helpers.arrayElement(
        cardGroupStatusOptions
      ) as CardGroupStatusType,
      userId: faker.string.uuid(),
      amount: faker.number.float({ fractionDigits: 1, max: 500 })
    },
    {
      id: faker.string.uuid(),
      name: faker.person.fullName(),
      cardNumber: faker.string.numeric(16),
      cardStatus: faker.helpers.arrayElement(
        cardStatusOptions
      ) as CardStatusType,
      type: faker.string.numeric(4),
      balanceId: faker.string.uuid(),
      status: faker.helpers.arrayElement(
        cardGroupStatusOptions
      ) as CardGroupStatusType,
      userId: faker.string.uuid(),
      amount: faker.number.float({ fractionDigits: 1, max: 500 })
    }
  ];

  await MockApi.sleep(2000);

  return {
    data,
    total: data.length
  };
}
