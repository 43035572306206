import styled from 'styled-components';

import { Container as OnboardingStepContainer } from 'presentation/pages/cards/Onboarding/components/General/OnboardingStep/OnboardingStep.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  padding: 2.4rem;

  > h1 {
    text-align: left;
    margin-bottom: 1.6rem;
  }

  ${OnboardingStepContainer}:has(> &) {
    min-height: 30vh;
    margin-top: 8rem;

    @media screen and (max-width: 800px) {
      margin-top: 10rem;
    }
  }

  @media screen and (max-width: 800px) {
    padding: 1.6rem;
  }
`;

export const CustomLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighMediumest};
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
