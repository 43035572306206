import {
  type IPersistenceRequestCards,
  type IRequestCardsPayload
} from 'data/modules/cards/balance';

import { Mask } from 'shared/utils/format';

class RequestCardsMapper {
  toPersistence(
    domainRequestCards: IRequestCardsPayload
  ): IPersistenceRequestCards {
    return {
      receiver: domainRequestCards.receiver,
      postal_code: Mask.remove(domainRequestCards.postalCode),
      street: domainRequestCards.street,
      number: domainRequestCards.number,
      complement: domainRequestCards.complement,
      neighborhood: domainRequestCards.neighborhood,
      city: domainRequestCards.city,
      state: domainRequestCards.state,
      phone_number: Mask.remove(domainRequestCards.phoneNumber),
      quantity: domainRequestCards.quantity
    };
  }
}

export default new RequestCardsMapper();
