import { CardInfo } from 'ds';
import styled from 'styled-components';

export const Container = styled(CardInfo.Container)`
  min-width: 37.2rem;
  min-height: 15.2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    & > div {
      flex-flow: row nowrap;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 1.6rem;
    }
  }
`;

export const ContentContainer = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 31rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    width: 21rem;
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;

  button {
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
      height: 4rem;
    }
  }
`;
