import { type ICarrouselSlideProps } from './CarrouselSlide.types';

import { SlideContent } from './CarrouselSlide.styles';

export function CarrouselSlide({
  cover,
  content
}: ICarrouselSlideProps): JSX.Element {
  return <SlideContent $cover={cover}>{content}</SlideContent>;
}
