import { MultiSelect } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useChargeCardsContext, useLangContext } from 'data/contexts';
import { type CardType } from 'data/contexts/cards/chargeCards/useChargeCardsContext.types';

import { type ISearchAndFiltersProps } from './SearchAndFilters.types';

import {
  Container,
  MultiSelectContainer,
  SearchContainer,
  StyledSearch
} from './SearchAndFilters.styles';

export function SearchAndFilters({
  ...rest
}: ISearchAndFiltersProps): JSX.Element {
  const {
    lang: {
      cards: { global: cardsGlobal, valueAllocation },
      forms: { select }
    },
    currentLangKey
  } = useLangContext();

  const [{ cardType, isCardTypeVisible, search }, handleChangeFilters] =
    useChargeCardsContext(
      useShallow(state => [state.filters, state.handleChangeFilters])
    );

  return (
    <Container {...rest}>
      <SearchContainer>
        <StyledSearch
          label={valueAllocation.search_card[currentLangKey]}
          placeholder={valueAllocation.search_card[currentLangKey]}
          value={search ?? ''}
          onChangeValue={search => {
            handleChangeFilters({ search: search === '' ? null : search });
          }}
          onClearValue={() => {
            handleChangeFilters({ search: null });
          }}
          iconLeft='SearchIcon'
          small
        />
      </SearchContainer>

      {isCardTypeVisible && (
        <MultiSelectContainer>
          <MultiSelect.Default
            name='card-type'
            small
            onChange={value => {
              handleChangeFilters({ cardType: value as CardType[] });
            }}
            options={[
              {
                value: 'INDIVIDUAL',
                label: cardsGlobal.account_type.individual[currentLangKey]
              },
              {
                value: 'BUSINESS',
                label: cardsGlobal.account_type.business[currentLangKey]
              }
            ]}
            value={cardType ?? []}
            label={valueAllocation.card_type[currentLangKey]}
            placeholder={valueAllocation.card_type[currentLangKey]}
            noOptionsMessage={select.no_more_options[currentLangKey]}
            hasArrowDownIndicator
          />
        </MultiSelectContainer>
      )}
    </Container>
  );
}
