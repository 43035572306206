import { useMemo } from 'react';

import { Currency } from 'shared/utils/format';

import {
  type IUseTransactionValue,
  type UseTransactionValueParamsType
} from './TransactionValue.types';

export function useTransactionValue({
  transaction
}: UseTransactionValueParamsType): IUseTransactionValue {
  const transactionValue = useMemo(() => {
    if (transaction.isInternational && transaction.currencyCode === 'BRL') {
      return '-';
    }

    const formattedValue = Currency.format(
      transaction.currencyCode,
      transaction.isInternational
        ? transaction.amount
        : transaction.finalBillingAmount
    );

    return `${transaction.currencyCode} ${formattedValue}`;
  }, [transaction]);

  const isTransactionPartiallyReversed =
    !transaction.reversed && !!transaction.reversalAmount;

  return {
    transactionValue,
    isTransactionPartiallyReversed
  };
}
