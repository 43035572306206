import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  padding: 0 2.5rem 2.5rem;
  max-width: 46.3rem;
  text-align: center;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.tokens.highlightColorMedium};

  > svg {
    fill: ${({ theme }) => theme.colors.tango};
  }
`;

export const Divider = styled.div`
  border-bottom: ${({ theme }) => theme.tokens.neutralColorHighPurest} solid 1px;
  width: 100%;
  margin: 1.5rem 0;
`;
