import { type ITestimonialSlideProps } from './TestimonialSlide.types';

import {
  Container,
  Content,
  Deponent,
  ThumbNail,
  Wrapper
} from './TestimonialSlide.styles';

export function TestimonialSlide({
  title,
  thumbNail,
  description,
  companyName,
  nameAndPosition
}: ITestimonialSlideProps): JSX.Element {
  return (
    <Container>
      <Wrapper>
        <ThumbNail
          src={thumbNail}
          alt='testimonial-thumb'
        />

        <Content>
          <strong>{title}</strong>
          <p>{description}</p>

          <Deponent>
            <strong>{companyName}</strong>
            <span>{nameAndPosition}</span>
          </Deponent>
        </Content>
      </Wrapper>
    </Container>
  );
}
