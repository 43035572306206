import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { vexDomain } from 'data/config';
import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import {
  DropdownLabel,
  DropdownRoot as Container,
  DropdownTrigger
} from 'presentation/ds/DsDropdown';

import { routesPathPrefix } from 'shared/constants/global';

import { useProfile } from './useProfile';

import { type IProfileProps } from './Profile.types';

import {
  DropdownContentWrapper,
  DropdownProfileGroup,
  DropdownProfileItem,
  ProfileWrapper,
  Wrapper
} from './Profile.styles';

export function Profile({ user }: IProfileProps): JSX.Element {
  const { t } = useTranslation('settings');

  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { dropdownOpen, setDropdownOpen } = useProfile();

  return (
    <Container
      onOpenChange={() => {
        setDropdownOpen(old => !old);
      }}
      open={dropdownOpen}
    >
      <Wrapper>
        <DropdownTrigger>
          <ProfileWrapper $dropdownOpen={dropdownOpen}>
            <p>{user?.name}</p>

            <BaseIcon
              name='arrow-down-s'
              size={1.8}
              type='line'
            />
          </ProfileWrapper>
        </DropdownTrigger>

        <DropdownContentWrapper withArrow>
          <DropdownProfileGroup>
            <DropdownLabel subLabel={user?.email ?? ''}>
              {user?.name}
            </DropdownLabel>
          </DropdownProfileGroup>

          <DropdownProfileItem>
            <a href={`${vexDomain}/settings/meus-dados`}>
              {t('sidebar.myData')}
            </a>
          </DropdownProfileItem>

          <DropdownProfileItem>
            <Link to={routesPathPrefix.support}>
              {lang.sidebar.routes.support[currentLangKey]}
            </Link>
          </DropdownProfileItem>

          <DropdownProfileItem>
            <a href={`${vexDomain}/logout`}>
              {lang.navbar.components.logout[currentLangKey]}
            </a>
          </DropdownProfileItem>
        </DropdownContentWrapper>
      </Wrapper>
    </Container>
  );
}
