import { tokens } from 'ds';
import { styled } from 'styled-components';

export const RightContent = styled.div`
  padding-bottom: 0.3rem;
  flex-shrink: 0;

  > div {
    height: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
    > div {
      height: 45.2rem;
    }
  }
`;

export const RowInputs = styled.div`
  display: flex;
  gap: ${tokens.spacingXs};
  width: 100%;

  .react-select-custom__control,
  .react-select-custom__menu {
    min-width: unset !important;
  }

  .modal-textinput {
    min-width: unset !important;
  }

  .react-select-custom__menu {
    z-index: 3;
  }

  > div {
    width: calc((100% - ${tokens.spacingXs}) / 2);
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingMd};
  align-items: start;

  /* o tamanho do container é 100% da Modal, menos 40rem do componente de recibo e menos o gap */
  width: calc(100% - 40rem - ${tokens.spacingXs});
  flex-grow: 1;
  justify-content: space-between;

  .textarea-notes > textarea {
    min-height: 6.6rem;
  }

  .select-project-button {
    width: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
    width: 100%;

    ${RowInputs} {
      flex-direction: column;
      gap: ${tokens.spacingMd};

      > div {
        width: 100%;
      }
    }
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: ${tokens.spacingXs};
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
    flex-direction: column-reverse;

    & > div {
      width: auto;
    }

    ${RightContent} > div {
      margin: 0 auto;
    }
  }
`;
