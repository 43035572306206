import { useRef } from 'react';

import { Tooltip } from 'ds/components/Tooltip/Tooltip.component';

import { type ICardInfoContainerProps } from './CardInfoContainer.types';

import {
  ClickableIconIndicator,
  Container,
  ContentWrapper
} from './CardInfoContainer.styles';

export function CardInfoContainer({
  clickable = false,
  children,
  tooltipMessage,
  ...rest
}: ICardInfoContainerProps): JSX.Element {
  const cardInfoRef = useRef<HTMLDivElement>(null);

  if (tooltipMessage) {
    return (
      <Tooltip
        message={tooltipMessage}
        trigger='hover'
        placement='top'
      >
        <Container
          $clickable={clickable}
          ref={cardInfoRef}
          {...(clickable && { role: 'button', tabIndex: 0 })}
          {...rest}
        >
          <ContentWrapper>{children}</ContentWrapper>
          {clickable && <ClickableIconIndicator data-testid='clickable' />}
        </Container>
      </Tooltip>
    );
  }

  return (
    <Container
      $clickable={clickable}
      ref={cardInfoRef}
      {...(clickable && { role: 'button', tabIndex: 0 })}
      {...rest}
    >
      <ContentWrapper>{children}</ContentWrapper>
      {clickable && <ClickableIconIndicator data-testid='clickable' />}
    </Container>
  );
}
