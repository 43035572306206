import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type ContainerStylesType } from './GenericTable.types';

export const Container = styled.div<AddPrefixToType<ContainerStylesType>>`
  width: 100%;
  overflow-x: auto;

  > table {
    width: 100%;
    background-color: ${({ $tableBackgroundColor, theme }) =>
      theme.colors[$tableBackgroundColor]};
    border-spacing: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    > table > thead > tr {
      display: flex;
      flex-wrap: wrap;
    }

    > table > tbody > tr {
      display: block;
    }

    ${({ $showHeaderOnMobile }) =>
      !$showHeaderOnMobile &&
      css`
        table > thead {
          display: none;
        }
      `}
  }
`;
