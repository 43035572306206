import { CardInfo } from 'ds/components/CardInfo';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    gap: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
`;

export const CardInfoContainer = styled(CardInfo.Container)`
  width: 100%;
`;
