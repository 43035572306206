import { useLangContext } from 'data/contexts';

import { ReversalIcon } from 'presentation/components/base/Icon';
import { Tooltip } from 'presentation/components/base/Tooltip';
import { ResponsiveCell } from 'presentation/components/global/Table';
import {
  ExpenseStatus,
  TransactionValue
} from 'presentation/pages/cards/Statement/components/Table';

import { Statement } from 'shared/utils/cards';
import { CustomDate } from 'shared/utils/custom';
import { CardNumber } from 'shared/utils/format';

import { useRowTransaction } from './useRowTransaction';

import { type IRowTransactionProps } from './RowTransaction.types';

import { Container, DetailsButton } from './RowTransaction.styles';

export function RowTransaction({
  transaction,
  onShowTransactionDetails,
  onShowPendencyDetails,
  pendency,
  userName,
  originalTransactionProps
}: IRowTransactionProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const {
    formattedTransactionPendencyDescription,
    formattedTransactionValue,
    transactionTooltipContent,
    expense,
    isLoadingExpense
  } = useRowTransaction({
    transaction,
    originalTransactionProps,
    pendency
  });

  return (
    <>
      {pendency && (
        <Container>
          <ResponsiveCell label={lang.global.date[currentLangKey]}>
            {CustomDate.formatFromISO(pendency.createdAt)}
          </ResponsiveCell>

          <ResponsiveCell label={lang.global.card[currentLangKey]}>
            {transaction.cardNumber
              ? CardNumber.format(transaction.cardNumber)
              : '-'}
          </ResponsiveCell>

          <ResponsiveCell label={lang.global.user[currentLangKey]}>
            {userName ?? '-'}
          </ResponsiveCell>

          <ResponsiveCell label={lang.global.description[currentLangKey]}>
            {formattedTransactionPendencyDescription}
          </ResponsiveCell>

          <ResponsiveCell label={lang.global.value[currentLangKey]}>
            <TransactionValue
              type='brazilian'
              value={pendency.amount}
            />
          </ResponsiveCell>

          <ResponsiveCell label={lang.global.status[currentLangKey]}>
            -
          </ResponsiveCell>

          <ResponsiveCell>
            <DetailsButton
              type='button'
              onClick={() => {
                onShowPendencyDetails?.();
              }}
            >
              {lang.global.more_details[currentLangKey]}
            </DetailsButton>
          </ResponsiveCell>
        </Container>
      )}

      <Container>
        <ResponsiveCell label={lang.global.date[currentLangKey]}>
          {CustomDate.formatFromISO(transaction.datetime)}
        </ResponsiveCell>

        <ResponsiveCell label={lang.global.card[currentLangKey]}>
          {transaction.cardNumber
            ? CardNumber.format(transaction.cardNumber)
            : '-'}
        </ResponsiveCell>

        <ResponsiveCell label={lang.global.user[currentLangKey]}>
          {userName ?? '-'}
        </ResponsiveCell>

        <ResponsiveCell label={lang.global.description[currentLangKey]}>
          {Statement.formatTransactionDescription(
            transaction,
            lang,
            currentLangKey
          )}

          {(!!transaction.reversalAmount ||
            transaction.isReversal !== 'NOT') && (
            <Tooltip
              id={`tableDataReversalIcon${transaction.transactionId}`}
              content={transactionTooltipContent}
              display='inline-block'
              marginLeft={0.5}
            >
              <ReversalIcon
                backgroundColor='tulipTree'
                iconProps={{
                  name: 'alert',
                  color: 'white',
                  size: 1
                }}
              />
            </Tooltip>
          )}
        </ResponsiveCell>

        <ResponsiveCell label={lang.global.value[currentLangKey]}>
          {formattedTransactionValue}
        </ResponsiveCell>

        <ResponsiveCell label={lang.global.status[currentLangKey]}>
          <ExpenseStatus
            status={
              expense?.report === null ? 'UNREPORTED' : expense?.report?.status
            }
            isLoading={isLoadingExpense}
          />
        </ResponsiveCell>

        <ResponsiveCell>
          <DetailsButton
            type='button'
            onClick={onShowTransactionDetails}
          >
            {lang.global.more_details[currentLangKey]}
          </DetailsButton>
        </ResponsiveCell>
      </Container>
    </>
  );
}
