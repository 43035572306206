import { Accordion, tokens } from 'ds';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
  `;

export const IconTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
`;

export const QRCodeContainer = styled.div`
  height: auto;
  margin: 0 auto;
  max-width: 14rem;
  border: 0;

  > svg {
    height: auto;
    max-width: 100%;
    width: 100%;
  }
`;

export const AccordionItemContainer = styled(Accordion.Item)`
  background: ${tokens.neutralColorHighPure} !important;
  border: 1px solid red;
`;

export const AccordionContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  width: 100%;
  padding: 2rem 3rem 3rem;

  p {
    font-size: ${tokens.fontSizeXxs};
    color: ${tokens.neutralColorLowDark};
  }
`;

export const LabelAboveButton = styled.div`
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
  font-size: ${tokens.fontSizeXs};
  line-height: ${tokens.spacingInsetLg};
  color: ${tokens.neutralColorLowDark};
  letter-spacing: 0.075rem;
`;

export const LoadingSpin = styled.div`
  display: flex;
  align-self: center;
  border: 3px solid ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  border-top: 3px solid transparent;
  width: 3rem;
  height: 3rem;
  animation: ${rotate} 1s linear infinite;
`;

export const ContainerLoader = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14rem;
  height: 14rem;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.9;
`;
