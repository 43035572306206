import { useLangContext, useModalContext } from 'data/contexts';

import { Modal } from 'presentation/components/base/Modal';

import { type IUnblockCardPermissionDeniedProps } from './UnblockCardPermissionDeniedModal.types';

import { Container } from './UnblockCardPermissionDeniedModal.styles';

export function UnblockCardPermissionDeniedModal({
  lockLevel
}: IUnblockCardPermissionDeniedProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);
  const { visibleModal, handleCloseModal } = useModalContext();

  return (
    <Modal
      isOpen={visibleModal === 'unblockCardPermissionDenied'}
      onClose={handleCloseModal}
      title={
        lang.cards.global.unblock_card_permission_denied_modal
          .permission_denied[currentLangKey]
      }
      secondaryButton={{
        children: lang.global.close[currentLangKey],
        color: 'carnation',
        size: 'xl',
        onClick: handleCloseModal
      }}
    >
      <Container>
        <p>
          {`
            ${
              lockLevel === 'SYSTEM'
                ? lang.cards.global.unblock_card_permission_denied_modal
                    .card_blocked_by_system[currentLangKey]
                : lang.cards.global.unblock_card_permission_denied_modal
                    .card_blocked_by_admin[currentLangKey]
            } ${
              lang.cards.global.unblock_card_permission_denied_modal.more_info[
                currentLangKey
              ]
            }
          `}
        </p>
      </Container>
    </Modal>
  );
}
