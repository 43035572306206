import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import {
  OnboardingText,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { useInconsistentKyc } from './useInconsistentKyc';

import { type IInconsistentKycProps } from './InconsistentKyc.types';

import {
  Container,
  CustomLink,
  IconContainer,
  ListContainer,
  SupportContainer
} from './InconsistentKyc.styles';

export function InconsistentKyc({
  isCompany
}: IInconsistentKycProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { dateDifference, kycStatus, titleKyc } = useInconsistentKyc({
    isCompany
  });

  return (
    <Container>
      <IconContainer>
        <BaseIcon
          name={kycStatus === 'REPROVED' ? 'close' : 'error-warning'}
          color={kycStatus === 'REPROVED' ? 'danger' : 'tango'}
          size={3}
          type='fill'
        />
      </IconContainer>

      <OnboardingTitle>{titleKyc}</OnboardingTitle>

      <OnboardingText>
        {dateDifference >= 3
          ? lang.cards.kycStatus.calm_lets_help_you_solve_it[currentLangKey]
          : lang.cards.kycStatus.calm_this_analysis_can_take_up_to_72_hours[
              currentLangKey
            ]}
      </OnboardingText>

      <ListContainer>
        <OnboardingText>
          <b>
            {
              lang.cards.kycStatus
                .here_are_some_reasons_that_could_be_causing_this[
                currentLangKey
              ]
            }
          </b>
        </OnboardingText>

        <ul>
          <li>
            <OnboardingText>
              {lang.cards.kycStatus.data_entered_incorrectly[currentLangKey]}
            </OnboardingText>
          </li>

          <li>
            <OnboardingText>
              {isCompany
                ? lang.cards.kycStatus.restrictions_on_the_companys_cnpj[
                    currentLangKey
                  ]
                : lang.cards.kycStatus.restrictions_on_your_cpf[currentLangKey]}
            </OnboardingText>
          </li>
        </ul>

        {dateDifference >= 3 && (
          <SupportContainer>
            <OnboardingText>
              <b>
                {
                  lang.cards.kycStatus
                    .please_contact_support_for_more_information[currentLangKey]
                }
              </b>
            </OnboardingText>
            <CustomLink
              to='https://suporte.vexpenses.com.br/hc/pt-br'
              target='__blank'
            >
              {lang.cards.kycStatus.talk_to_support[currentLangKey]}
            </CustomLink>
          </SupportContainer>
        )}
      </ListContainer>
    </Container>
  );
}
