import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import { DsFileInput } from 'presentation/ds/DsFileInput';
import { DsSelect } from 'presentation/ds/DsSelect';
import { DsTextInput } from 'presentation/ds/DsTextInput';
import {
  OnboardingFooter,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { cnae, legalNature } from 'shared/constants/global';
import { Onboarding } from 'shared/utils/cards';

import { useLegalEntityData } from './useLegalEntityData';

import { Container, FormContainer } from './LegalEntityData.styles';

export function LegalEntityData(): JSX.Element {
  const navigate = useNavigate();

  const [forms, legalEntityData, currentLangKey] = useLangContext(state => [
    state.lang.forms,
    state.lang.cards.onboarding.company.add_partner.legal_entity_data,
    state.currentLangKey
  ]);

  const { control, errors, handleForwardDataToNextStep, setValue, isEditMode } =
    useLegalEntityData();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  return (
    <Container>
      <OnboardingTitle>{legalEntityData.title[currentLangKey]}</OnboardingTitle>

      <FormContainer onSubmit={handleForwardDataToNextStep}>
        <DsTextInput
          name='document'
          label={forms.cnpj[currentLangKey]}
          control={control}
          mask='cnpj'
          onClear={() => {
            setValue('document', '');
          }}
          error={errors.document?.message}
        />

        <DsTextInput
          name='legalName'
          label={forms.corporate_name[currentLangKey]}
          onChange={() => {}}
          error={errors.legalName?.message}
          onClear={() => {
            setValue('legalName', '');
          }}
          control={control}
          maxLength={60}
        />

        <DsSelect
          name='mainActivity'
          control={control}
          label={forms.main_activity_cnae[currentLangKey]}
          options={cnae}
          error={errors.mainActivity?.message}
        />

        <DsSelect
          name='businessType'
          control={control}
          label={forms.legal_nature[currentLangKey]}
          options={legalNature}
          error={errors.businessType?.message}
        />

        <DsTextInput
          type='date'
          mask='date'
          name='foundingDate'
          onChange={() => {}}
          label={forms.select.founding_date[currentLangKey]}
          error={errors.foundingDate?.message}
          onClear={() => {
            setValue('foundingDate', '');
          }}
          control={control}
        />

        <DsTextInput
          name='email'
          type='email'
          onChange={() => {}}
          label={forms.company_email[currentLangKey]}
          error={errors.email?.message}
          onClear={() => {
            setValue('email', '');
          }}
          control={control}
        />

        <DsTextInput
          onClear={() => {
            setValue('phoneNumber', '');
          }}
          mask='phone'
          name='phoneNumber'
          label={forms.phone[currentLangKey]}
          control={control}
          error={errors.phoneNumber?.message}
        />

        <DsFileInput
          label={forms.attach_contract_optional[currentLangKey]}
          name='contract'
          control={control}
          accept='application/pdf'
          error={errors.contract?.message}
        />

        <OnboardingFooter
          backButtonProps={{
            onClick: () => {
              navigate(
                isEditMode
                  ? `/cartoes/onboarding/empresa/${currentStep}`
                  : `/cartoes/onboarding/empresa/${currentStep}/tipo-representante`
              );
            }
          }}
          continueButtonProps={{
            onClick: () => {}
          }}
        />
      </FormContainer>
    </Container>
  );
}
