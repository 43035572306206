import { faker } from '@faker-js/faker';

import {
  type CardStatusType,
  type CardUseType,
  type IModifiedBalanceAllocationItem
} from 'data/modules/cards/balance';
import { type CreditDebitType } from 'data/modules/global';

import { MockApi } from 'shared/utils/global';

import {
  cardStatusOptions,
  cardTypeOptions,
  cardUseOptions
} from '../balanceOptions.mock';

export async function getModifiedBalanceAllocationItemsFromCacheMock(): Promise<
  IModifiedBalanceAllocationItem[] | null
> {
  const data = [
    {
      balanceAccountId: faker.string.uuid(),
      amount: parseFloat(faker.finance.amount()),
      type: faker.helpers.arrayElement(cardTypeOptions) as CreditDebitType,
      userName: faker.person.fullName(),
      cardNumber: faker.helpers.arrayElement([
        faker.finance.creditCardNumber(),
        null
      ]),
      cardType: faker.helpers.arrayElement(cardUseOptions) as CardUseType,
      currentValue: parseFloat(faker.finance.amount()),
      status: faker.helpers.arrayElement(cardStatusOptions) as CardStatusType,
      accountId: faker.string.uuid(),
      userId: faker.string.uuid()
    }
  ];

  await MockApi.sleep(2000);

  return data;
}
