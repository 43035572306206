import { useInfiniteQueryCache } from 'data/cache';

import { CardGroupsQueryKeys } from '../../keys/cardGroups.keys';
import CardGroupsService from '../../services/CardGroups.service';

import {
  type IUseInfiniteAvailableToBeManager,
  type IUseInfiniteAvailableToBeManagerParams
} from '../../types/cardGroups.types';

export function useInfiniteAvailableToBeManager({
  params,
  enabled
}: IUseInfiniteAvailableToBeManagerParams): IUseInfiniteAvailableToBeManager {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQueryCache({
      queryKey: [CardGroupsQueryKeys.GET_AVAILABLE_TO_BE_MANAGER, params],
      initialPageParam: 1,
      queryFn: async ({ pageParam }) =>
        await CardGroupsService.getAvailableToBeManager({
          ...params,
          page: pageParam
        }),
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        const totalPages = Math.ceil(lastPage.total) / params.limit;
        const isLastPage = allPages.length >= totalPages;

        if (isLastPage) {
          return null;
        }

        return lastPageParam + 1;
      },
      enabled: enabled ?? true
    });

  return {
    infiniteAvailableToBeManager: data?.pages.flatMap(page => page.data),
    isLoadingInfiniteAvailableToBeManager: isLoading,
    availableToBeManagerPagination: {
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage
    }
  };
}
