import { useTranslation } from 'react-i18next';

import { type ITransactionDetailsRelatedsContainerProps } from './TransactionDetailsRelatedsContainer.types';

import { Container } from './TransactionDetailsRelatedsContainer.styles';

export function TransactionDetailsRelatedsContainer({
  children
}: ITransactionDetailsRelatedsContainerProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Container>
      <h3>{t('relatedTransactions')}</h3>

      {children}
    </Container>
  );
}
