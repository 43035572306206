import { create } from 'zustand';

import { type IUseAuthContext } from './useAuthContext.types';

export const useAuthContext = create<IUseAuthContext>()(set => ({
  user: null,
  company: null,
  outsourcedUserWithoutCard: false,
  outsourcedUser: null,
  isAuthenticating: true,
  isErrorOnAuthenticate: false,

  setAuthenticatedCompany: company => {
    set({ company });
  },

  setAuthenticatedUser: user => {
    set({ user });
  },

  setOutsourcedUserWithoutCard: value => {
    set({ outsourcedUserWithoutCard: value });
  },

  setOutsourcedUser: user => {
    set({ outsourcedUser: user });
  },

  setIsAuthenticating: value => {
    set({ isAuthenticating: value });
  },

  setIsErrorOnAuthenticate: value => {
    set({ isErrorOnAuthenticate: value });
  }
}));
