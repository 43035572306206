import { useDsGa4 } from 'ds/hooks/globals';

import { type IBadgeStatusProps } from './BadgeStatus.types';

import { CloseIcon, Container } from './BadgeStatus.styles';

export function BadgeStatus({
  onClose,
  outlined,
  type,
  children,
  ...rest
}: IBadgeStatusProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  return (
    <Container
      $outlined={outlined}
      $type={type}
      {...rest}
    >
      {children}

      {onClose && (
        <CloseIcon
          onClick={() => {
            sendDsGaEvent('components', 'badge');

            onClose();
          }}
        />
      )}
    </Container>
  );
}
