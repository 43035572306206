import { type IDefaultTableBodyProps } from './DefaultTableBody.types';

import { Container } from './DefaultTableBody.styles';

export function DefaultTableBody({
  children,
  ...rest
}: IDefaultTableBodyProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
