import { useMemo } from 'react';

import { useLangContext } from 'data/contexts';

import { CustomDate } from 'shared/utils/custom';
import { PendencyType } from 'shared/utils/format';

import {
  type IUsePendencyDetailsModal,
  type UsePendencyDetailsModalParamsType
} from './PendencyDetailsModal.types';

import { Header } from './PendencyDetailsModal.styles';

export function usePendencyDetailsModal({
  pendency
}: UsePendencyDetailsModalParamsType): IUsePendencyDetailsModal {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const formattedPendencyDescription = useMemo(() => {
    if (!pendency) return '';

    return PendencyType.format(pendency.type, lang, currentLangKey);
  }, [currentLangKey, lang, pendency]);

  const modalHeader = (
    <Header>
      <h1>{lang.global.details[currentLangKey]}</h1>

      <span>{CustomDate.formatFromISO(pendency?.createdAt)}</span>
    </Header>
  );

  return {
    formattedPendencyDescription,
    modalHeader
  };
}
