import {
  type ICompanyAccount,
  type IPersistenceCompanyAccount
} from 'data/modules/cards/account';

class CompanyAccountMapper {
  toDomain(data: IPersistenceCompanyAccount): ICompanyAccount {
    return {
      id: data.id,
      name: data.name,
      status: data.status,
      cardNumber: data.card_number,
      cardStatus: data.card_status,
      cardLockLevel: data.card_lock_level,
      type: data.type,
      description: data.description,
      accessAccounts: data.access_accounts,
      balances: data.balances
    };
  }
}

export default new CompanyAccountMapper();
