import { DSIcons, tokens } from 'ds';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 3rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 4.8rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 70rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding-right: ${tokens.spacingXs};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding-right: 3.4rem;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${tokens.spacingXs} 1rem;
  gap: ${tokens.spacingXs};
  height: 100%;
  overflow: auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding-right: 2.4rem;

    &::-webkit-scrollbar-track {
      margin-top: -3.8rem;
    }
  }
`;

export const ModalTitle = styled.h1`
  font-family: ${({ theme }) => theme.tokens.fontFamiliesPoppins};
  font-size: ${({ theme }) => theme.tokens.spacingSm};
  font-weight: ${({ theme }) => theme.tokens.fontWeightBold};
  color: ${({ theme }) => theme.colors.neutralColorLowDark};
  letter-spacing: 1px;
`;

export const CloseButton = styled(DSIcons.CloseLargerIcon)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ theme }) => theme.colors.neutralColorLowMedium};
  cursor: pointer;
`;
