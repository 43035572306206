import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  display: flex;

  > h1 {
    text-align: left;
    padding: 2.4rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 0 2.4rem 15rem;

  > div {
    flex-direction: column;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  word-break: break-all;
  margin-top: 2rem;
  margin-left: 0.5rem;
`;
