import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type MyCardStylesType } from './MyCardTabs.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<AddPrefixToType<MyCardStylesType>>`
  overflow: auto hidden;
  padding-bottom: ${tokens.spacingInsetNano};
  width: min-content;

  ${({ $movements }) =>
    !$movements &&
    css`
      padding: ${tokens.spacingXs};
    `}

  > nav > ul {
    padding: 0;
    gap: ${tokens.spacingInsetXs};
  }

  /* Width */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const TabItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingNano};
  font-size: ${tokens.fontSizeXxss};

  > span {
    font-size: ${tokens.fontSizeXxss};
  }
`;
