import { DefaultTable, SkeletonLoader } from 'ds';

import { type IChargeCardsTableLoaderProps } from './ChargeCardsTableLoader.types';

export function ChargeCardsTableLoader({
  rows,
  columns
}: IChargeCardsTableLoaderProps): JSX.Element {
  const rowsArray = Array.from(Array(rows).keys());
  const columnsArray = Array.from(Array(columns).keys());

  return (
    <DefaultTable.Container>
      <DefaultTable.Thead>
        <DefaultTable.Tr>
          {columnsArray.map(column => (
            <DefaultTable.Th key={column}>
              <SkeletonLoader />
            </DefaultTable.Th>
          ))}
        </DefaultTable.Tr>
      </DefaultTable.Thead>

      <DefaultTable.Tbody>
        {rowsArray.map(row => (
          <DefaultTable.Tr key={row}>
            {columnsArray.map(column => (
              <DefaultTable.Td key={column}>
                <SkeletonLoader />
              </DefaultTable.Td>
            ))}
          </DefaultTable.Tr>
        ))}
      </DefaultTable.Tbody>
    </DefaultTable.Container>
  );
}
