import { useQueryCache } from 'data/cache';
import {
  AccountQueryKeys,
  AccountService,
  type IUseGetUserAccounts,
  type IUseGetUserAccountsParams
} from 'data/modules/cards/account';

export function useGetUserAccounts({
  enabled,
  ...payload
}: IUseGetUserAccountsParams): IUseGetUserAccounts {
  const {
    data: paginatedUserAccounts,
    isLoading: isLoadingUserAccounts,
    isFetching: isFetchingUserAccounts
  } = useQueryCache({
    enabled,
    queryKey: [AccountQueryKeys.GET_USER_ACCOUNTS, payload],
    queryFn: async () => await AccountService.getUserAccounts(payload)
  });

  return {
    paginatedUserAccounts,
    isLoadingUserAccounts,
    isFetchingUserAccounts
  };
}
