import React from 'react';

import { DefaultTable, DSIcons } from 'ds';
import empty from 'ds/images/illustration_booking.png';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useModalContext, useMovementsContext } from 'data/contexts';
import { mockCompanyStatement } from 'data/mocks';

import { CustomInfoMessage } from 'presentation/components/global/CustomInfoMessage';

import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';
import CardNumberUtil from 'shared/utils/format/CardNumber.util';

import { Movements } from '../../utils';
import { TransactionExpenseStatus, TransactionPendency } from '../';

import { useMovementsTableBody } from './useMovementsTableBody';

import {
  Container,
  MessageContainer,
  StyledLimitedWidthTd,
  StyledTableContainer,
  StyledTd,
  StyledTh,
  TableContainer
} from './MovementsTableBody.styles';

export function MovementsTableBody(): JSX.Element {
  const {
    movements,
    isListEmpty,
    isError,
    isLoading,
    pendencies,
    isLoadingPendencies,
    accounts,
    isEmptySearch,
    movementType,
    getEntriesTotalValue,
    getExitsTotalValue,
    isEmpty,
    shouldShowContent,
    isUnlinkedCard
  } = useMovementsTableBody();

  const { userId } = useParams();

  const { t } = useTranslation(['cards', 'global']);
  const { handleOpenModal } = useModalContext();
  const handleSetTransactionDetails = useMovementsContext(
    state => state.handleSetTransactionDetails
  );

  return (
    <Container>
      <TableContainer>
        {isUnlinkedCard && !isLoading && (
          <MessageContainer>
            <CustomInfoMessage
              icon={<DSIcons.EmptyFolderIcon />}
              title={t('cards:cardMessageNotLinked')}
              message={t('cards:activateUnlinkedCardMessage')}
            />
          </MessageContainer>
        )}

        {isError && !isUnlinkedCard && (
          <MessageContainer>
            <img
              src={empty}
              alt={t('global:opsError')}
            />
            <p>{t('global:opsError')}</p>

            <span>{t('cantLoadTransactions')}</span>
          </MessageContainer>
        )}

        {isEmpty && (
          <MessageContainer>
            <DSIcons.EmptyFolderIcon />
            <p>{t('emptyMovementsList')}</p>
            <span>{t('global:tryResetFilters')}</span>
          </MessageContainer>
        )}

        {isEmptySearch && !isError && !isLoading && (
          <MessageContainer>
            <img
              src={empty}
              alt={t('transactionNotFound')}
            />

            <p>{t('transactionNotFound')}</p>

            <span>{t('global:searchNotFound')}</span>
          </MessageContainer>
        )}

        {isLoading && !isError && !isEmptySearch && !isListEmpty && (
          <DefaultTable.Container>
            <DefaultTable.Thead>
              <DefaultTable.Tr>
                <DefaultTable.Th
                  isLoading
                  colSpan={3}
                />

                <DefaultTable.Th isLoading />

                <DefaultTable.Th isLoading />

                <DefaultTable.Th
                  isLoading
                  colSpan={3}
                />
              </DefaultTable.Tr>
            </DefaultTable.Thead>

            <DefaultTable.Tbody>
              {Array.from({ length: 8 }, (value, index) => (
                <DefaultTable.Tr key={index}>
                  <DefaultTable.Td
                    truncate
                    isLoading={isLoading}
                  />
                  <DefaultTable.Td
                    truncate
                    isLoading={isLoading}
                  />
                  <DefaultTable.Td
                    truncate
                    isLoading={isLoading}
                  />
                  <DefaultTable.Td
                    truncate
                    isLoading={isLoading}
                  />
                  <DefaultTable.Td
                    truncate
                    isLoading={isLoading}
                  />
                  <DefaultTable.Td
                    truncate
                    isLoading={isLoading}
                  />
                  <DefaultTable.Td
                    truncate
                    isLoading={isLoading}
                  />
                  <DefaultTable.Td
                    truncate
                    isLoading={isLoading}
                  />
                </DefaultTable.Tr>
              ))}
            </DefaultTable.Tbody>
          </DefaultTable.Container>
        )}

        {shouldShowContent && (
          <StyledTableContainer>
            {movements.map(
              (item, itemIndex) =>
                item.items.length > 0 && (
                  <React.Fragment key={item.date + item.balance}>
                    <DefaultTable.Thead
                      className={itemIndex !== 0 ? 'no-rounded-thead' : ''}
                    >
                      <DefaultTable.Tr>
                        <DefaultTable.Th
                          colSpan={
                            accounts && accounts.length <= 1
                              ? userId
                                ? 1
                                : 2
                              : 7
                          }
                        >
                          {Movements.getDateLabel(item.date)}{' '}
                          {CustomDate.formatFromISO(item.date)}
                        </DefaultTable.Th>

                        {accounts && accounts.length <= 1 && (
                          <>
                            <DefaultTable.Th>
                              <StyledTh
                                className={
                                  movementType !== 'DEBIT' ? '' : 'hidden-th'
                                }
                              >
                                <DSIcons.ArrowDownloadIcon className='header-icon' />

                                <div>
                                  <span>{t('global:entries')}</span>
                                  <strong>
                                    {Currency.format(
                                      'BRL',
                                      getEntriesTotalValue(item.items),
                                      true
                                    )}
                                  </strong>
                                </div>
                              </StyledTh>
                            </DefaultTable.Th>

                            <DefaultTable.Th>
                              <StyledTh
                                className={
                                  movementType !== 'CREDIT' ? '' : 'hidden-th'
                                }
                              >
                                <DSIcons.ArrowUpIcon className='header-icon' />

                                <div>
                                  <span>{t('global:exits')}</span>
                                  <strong>
                                    {Currency.format(
                                      'BRL',
                                      getExitsTotalValue(item.items),
                                      true
                                    )}
                                  </strong>
                                </div>
                              </StyledTh>
                            </DefaultTable.Th>

                            <DefaultTable.Th colSpan={3}>
                              <StyledTh
                                className={
                                  movementType === 'ALL' ? '' : 'hidden-th'
                                }
                              >
                                <DSIcons.CalendarScheduleIcon className='header-icon' />

                                <div>
                                  <span>{t('dayValue')}</span>
                                  <strong>
                                    {Currency.format('BRL', item.balance, true)}
                                  </strong>
                                </div>
                              </StyledTh>
                            </DefaultTable.Th>
                          </>
                        )}
                      </DefaultTable.Tr>
                    </DefaultTable.Thead>

                    <DefaultTable.Tbody>
                      {item.items.map((transaction, transactionIndex) => (
                        <React.Fragment key={transaction.transactionId}>
                          {transactionIndex === 0 && (
                            <DefaultTable.Tr>
                              <StyledTd truncate>
                                {t('global:description')}
                              </StyledTd>

                              {!userId && (
                                <StyledTd truncate>
                                  {t('global:cardName')}
                                </StyledTd>
                              )}

                              <StyledTd truncate>{t('global:card')}</StyledTd>
                              <StyledTd truncate>{t('global:date')}</StyledTd>
                              <StyledTd truncate>{t('global:amount')}</StyledTd>
                              <StyledTd
                                truncate
                                className='center'
                              >
                                {t('global:expenses_one')}
                              </StyledTd>
                              <StyledTd
                                truncate
                                className='center'
                              >
                                {t('global:details')}
                              </StyledTd>
                            </DefaultTable.Tr>
                          )}

                          <DefaultTable.Tr
                            className={
                              mockCompanyStatement.length - 1 === itemIndex &&
                              item.items.length - 1 === transactionIndex
                                ? 'rounded-tr'
                                : ''
                            }
                          >
                            <StyledLimitedWidthTd
                              truncate
                              isLoading={isLoading}
                              className='centralized-td'
                            >
                              <div>
                                <span>
                                  {Movements.getTransactionIcon(transaction)}
                                </span>

                                <span
                                  title={
                                    transaction.paymentDescription ||
                                    transaction.merchantName!
                                  }
                                  className={
                                    transaction.type === 'CASH_WITHDRAWAL'
                                      ? 'underlined'
                                      : ''
                                  }
                                >
                                  {Movements.getTransactionDescription(
                                    transaction
                                  )}
                                </span>
                              </div>
                            </StyledLimitedWidthTd>

                            {!userId && (
                              <DefaultTable.Td
                                truncate
                                isLoading={isLoading}
                                title={transaction.name!}
                                className={
                                  transaction.type === 'CASH_WITHDRAWAL'
                                    ? 'underlined'
                                    : ''
                                }
                              >
                                {transaction.name || '-'}
                              </DefaultTable.Td>
                            )}

                            <DefaultTable.Td
                              truncate
                              isLoading={isLoading}
                              className={
                                transaction.type === 'CASH_WITHDRAWAL'
                                  ? 'underlined'
                                  : ''
                              }
                            >
                              {transaction.cardNumber
                                ? CardNumberUtil.format(transaction.cardNumber)
                                : '-'}
                            </DefaultTable.Td>

                            <DefaultTable.Td
                              truncate
                              isLoading={isLoading}
                              className={
                                transaction.type === 'CASH_WITHDRAWAL'
                                  ? 'underlined'
                                  : ''
                              }
                            >
                              {CustomDate.formatFromISO(transaction.datetime)}
                            </DefaultTable.Td>

                            <DefaultTable.Td
                              truncate
                              isLoading={isLoading || isLoadingPendencies}
                              className={
                                transaction.type === 'CASH_WITHDRAWAL'
                                  ? 'underlined'
                                  : 'pendency-td'
                              }
                            >
                              <div>
                                <span>
                                  {Currency.format(
                                    'BRL',
                                    transaction.finalBillingAmount,
                                    true
                                  )}
                                </span>

                                {pendencies.some(
                                  pendency =>
                                    pendency.transactionId ===
                                    transaction.transactionId
                                ) && <TransactionPendency />}
                              </div>
                            </DefaultTable.Td>

                            <DefaultTable.Td
                              truncate
                              className='center'
                              isLoading={isLoading}
                            >
                              <TransactionExpenseStatus
                                transaction={transaction}
                              />
                            </DefaultTable.Td>
                            <DefaultTable.Td
                              truncate
                              className='center'
                              isLoading={isLoading}
                              onClick={() => {
                                handleSetTransactionDetails(transaction);
                                handleOpenModal('transactionDetails');
                              }}
                            >
                              <DSIcons.ArrowRightIcon className='table-icon' />
                            </DefaultTable.Td>
                          </DefaultTable.Tr>
                        </React.Fragment>
                      ))}
                    </DefaultTable.Tbody>
                  </React.Fragment>
                )
            )}
          </StyledTableContainer>
        )}
      </TableContainer>
    </Container>
  );
}
