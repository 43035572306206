import { useLangContext, useModalContext } from 'data/contexts';
import { useCreateBusinessCard } from 'data/modules/cards/businessCard';

import { InputContainer, TextInput } from 'presentation/components/base/Input';
import { Modal } from 'presentation/components/base/Modal';
import { Select } from 'presentation/components/base/Select';

import { useCreateBusinessCardModal } from './useCreateBusinessCardModal';

import { type ICreateBusinessCardModalProps } from './CreateBusinessCardModal.types';

import { Container } from './CreateBusinessCardModal.styles';

export function CreateBusinessCardModal({
  onSetBusinessCardProps,
  onChangeAccountId
}: ICreateBusinessCardModalProps): JSX.Element {
  const { visibleModal, handleCloseModal } = useModalContext();

  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { accountHoldersOptions, isLoadingAccountHolders } =
    useCreateBusinessCardModal();

  const { control, errors, reset, handleSubmit, clearErrors, isLoading } =
    useCreateBusinessCard({ onSetBusinessCardProps, onChangeAccountId });

  return (
    <Modal
      isOpen={visibleModal === 'createBusinessCard'}
      isLoading={isLoading}
      onClose={() => {
        handleCloseModal();
        clearErrors();
        reset();
      }}
      title={
        lang.settings.businessCards.create_card_holder_entity[currentLangKey]
      }
      description={
        lang.settings.businessCards.nickname_for_this_card[currentLangKey]
      }
      primaryButton={{
        children: lang.global.confirm[currentLangKey],
        color: 'success',
        onClick: () => {
          clearErrors();
        }
      }}
      onSubmit={handleSubmit}
    >
      <Container>
        <Select
          name='accountHolderId'
          options={accountHoldersOptions}
          label={
            lang.settings.businessCards.business_account_holder[currentLangKey]
          }
          isLoading={isLoadingAccountHolders}
          control={control}
          error={errors.accountHolderId?.message}
        />

        <InputContainer
          label={lang.global.surname[currentLangKey]}
          error={errors.name?.message}
        >
          <TextInput
            name='name'
            control={control}
            error={!!errors.name?.message}
          />
        </InputContainer>

        <InputContainer
          label={lang.forms.email[currentLangKey]}
          error={errors.email?.message}
        >
          <TextInput
            name='email'
            type='email'
            control={control}
            error={!!errors.email?.message}
          />
        </InputContainer>
      </Container>
    </Modal>
  );
}
