import { tokens } from 'ds';
import styled from 'styled-components';

export const RemoveButton = styled.div`
  display: flex;
  align-items: center;

  background: none;
  border: none;

  > svg {
    cursor: pointer;
    width: ${tokens.fontSizeMd};
    height: ${tokens.fontSizeMd};
    color: ${tokens.feedbackColorErrorPure};
  }
`;

export const UserNameRowContent = styled.div`
  max-width: 38rem;
  text-align: left;
  font-size: ${tokens.fontSizeXxss};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const JustifyRowContent = styled.div`
  max-width: 38rem;
  text-align: left;
  font-size: ${tokens.fontSizeXxss};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const JustifyDissaproveSolicitationContent = styled.tr`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120rem;
  margin: ${tokens.spacingNano};

  > td {
    width: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-width: 100%;
  }
`;
