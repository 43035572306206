import { useQueries } from '@tanstack/react-query';

import {
  type IAccountStatement,
  type IUseGetAccountStatement,
  type IUseGetAccountStatementParams,
  StatementQueryKeys,
  StatementService
} from 'data/modules/cards/statement';

export function useGetAccountStatement({
  accountBalancesIds,
  enabled = true,
  ...payload
}: IUseGetAccountStatementParams): IUseGetAccountStatement {
  const queriesParams = accountBalancesIds.map(accountBalanceId => ({
    queryKey: [
      StatementQueryKeys.GET_ACCOUNT_STATEMENT,
      { accountBalanceId, ...payload }
    ],
    queryFn: async () =>
      await StatementService.getAccountStatement({
        accountBalanceId,
        ...payload
      }),
    enabled
  }));

  const results = useQueries({
    queries: [...queriesParams]
  });

  return {
    accountsStatements: results.reduce<IAccountStatement[]>((acc, result) => {
      if (result.data) {
        return [...acc, ...result.data];
      }

      return acc;
    }, []),
    isLoadingAccountsStatements: results.some(result => result.isLoading),
    isFetchingAccountsStatements: results.some(result => result.isFetching),
    isErrorAccountsStatements: results.some(result => result.isError)
  };
}
