import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 0 0;
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  gap: ${tokens.spacingXxl};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  gap: ${tokens.spacingXs};
`;

export const EmptyText = styled.p`
  text-align: center;
  font-style: normal;
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightSemibold};
`;

export const TipText = styled.span`
  text-align: center;
  font-style: normal;
  line-height: 2.2rem;
  letter-spacing: 0.025rem;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowMedium};
  font-weight: ${tokens.fontWeightMedium};
  font-family: ${tokens.fontFamilyPoppins};
`;
