import { type FC } from 'react';

import { useModalContext } from 'data/contexts';
import { type AvailableModalsType } from 'data/contexts/modal/useModalContext.types';

/**
 * Higher-Order Component (HOC) que controla a exibição de um modal.
 *
 * @template T - Tipo das propriedades do modal.
 * @param {AvailableModalsType} key - Chave que controla a exibição do modal.
 * @param {FC<T>} Component - O componente modal que será exibido ou ocultado.
 * @returns {(props: T) => JSX.Element | null} - Uma função que renderiza o componente passado como argumento se o modal correspondente estiver visível, caso contrário, retorna null.
 *
 * @example
 * // Exemplo de uso do HOC withModal
 * import React from 'react';
 * import { withModal } from 'shared/hocs';
 *
 * interface IMyModalProps = {
 *   title: string;
 * };
 *
 * function MyModalComponent({ title }: IMyModalProps): JSX.Element {
 *   return <div>{title}</div>;
 * }
 *
 * // Envolvendo o MyModalComponent com o HOC withModal
 * const MyModal = withModal('exampleModalKey', MyModalComponent);
 *
 * // No render:
 * <MyModal title={'Example title'} />
 */
export function withModal<T extends object>(
  key: AvailableModalsType,
  Component: FC<T>
): (props: T) => JSX.Element | null {
  const handler = (props: T): JSX.Element | null => {
    const { visibleModal } = useModalContext.getState();

    return visibleModal === key ? <Component {...props} /> : null;
  };

  return handler;
}

/**
 * Higher-Order Component (HOC) que controla condicionalmente a exibição de um modal.
 *
 * @template T - Tipo das propriedades do modal.
 * @param {FC<T>} Component - O componente modal que será exibido ou ocultado.
 * @param {AvailableModalsType | undefined} key - (Opcional) Chave que controla a exibição do modal.
 * @returns {(props: T) => JSX.Element | null} - Uma função que renderiza o componente passado como argumento se o modal correspondente estiver visível (se `key` for fornecido) e a condição `isOpen` for verdadeira, caso contrário, retorna null.
 */
export function withModalCondition<T extends object>(
  Component: FC<T>,
  key: AvailableModalsType | undefined = undefined
): (props: T & { isOpen: boolean }) => JSX.Element | null {
  const handler = ({
    isOpen,
    ...props
  }: T & { isOpen: boolean }): JSX.Element | null => {
    const { visibleModal } = useModalContext.getState();

    return (!key || key === visibleModal) && isOpen ? (
      <Component {...(props as T)} />
    ) : null;
  };

  return handler;
}
