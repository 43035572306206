import { useQueryClient } from '@tanstack/react-query';

import { useMutationCache } from 'data/cache';
import {
  CompanyFormCacheDomains,
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUseAddLegalEntityPartner,
  type IUseAddLegalEntityPartnerParams,
  type LegalPersonShareholderType,
  type OnboardingShareholderType,
  type UpdateCompanyFormCachePayloadType
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';

export function useAddLegalEntityPartner({
  onAfterAddingLegalEntityPartner
}: IUseAddLegalEntityPartnerParams): IUseAddLegalEntityPartner {
  const { data: cachedCompanyOnboardingShareholders } = useHandleQueryCache({
    query: {
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingShareholders(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_SHAREHOLDERS]
    }
  });

  const { mutate } = useMutationCache({
    mutationKey: [CompanyOnboardingMutationKeys.UPDATE_COMPANY_FORM_CACHE],
    mutationFn: async (payload: UpdateCompanyFormCachePayloadType) =>
      await CompanyOnboardingService.updateCompanyFormCache(payload),
    onSuccess: (data, variables) => {
      onAfterAddingLegalEntityPartner(
        variables.value as Required<OnboardingShareholderType>[]
      );
    }
  });

  const queryClient = useQueryClient();

  const cachedSelectedShareholder =
    queryClient.getQueryData<LegalPersonShareholderType>([
      'selectedShareholder'
    ]);

  function handleAddLegalEntityPartner(
    newLegalEntityPartnerData: OnboardingShareholderType
  ): void {
    const isEditMode =
      cachedSelectedShareholder && cachedCompanyOnboardingShareholders;

    if (isEditMode) {
      const filteredShareholders = cachedCompanyOnboardingShareholders.filter(
        shareholder =>
          shareholder.document !== cachedSelectedShareholder.document
      );

      mutate({
        domain: CompanyFormCacheDomains.onboardingShareholders,
        lifeCycle: 360,
        value: [
          ...(filteredShareholders ?? []),
          {
            ...newLegalEntityPartnerData,
            type: 'BUSINESS'
          }
        ]
      });

      return;
    }

    mutate({
      domain: CompanyFormCacheDomains.onboardingShareholders,
      lifeCycle: 360,
      value: [
        ...(cachedCompanyOnboardingShareholders ?? []),
        {
          ...newLegalEntityPartnerData,
          type: 'BUSINESS'
        }
      ]
    });
  }

  return {
    handleAddLegalEntityPartner
  };
}
