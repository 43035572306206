import { useRef } from 'react';

import { DSIcons, Elements } from 'ds';
import { useDsGa4 } from 'ds/hooks/globals';

import { type ICheckboxDefaultProps } from './CheckboxDefault.types';

import {
  CheckboxComponent,
  Container,
  InputComponent,
  Label
} from './CheckboxDefault.styles';

export function CheckboxDefault({
  label,
  name,
  disabled,
  small,
  value,
  defaultChecked,
  onChangeValue,
  ...rest
}: ICheckboxDefaultProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();
  const checkboxRef = useRef(null);

  return (
    <Container
      data-testid='container'
      $disabled={disabled}
      $small={small}
      {...rest}
    >
      <InputComponent
        ref={checkboxRef}
        type='checkbox'
        checked={value}
        disabled={disabled}
        defaultChecked={defaultChecked}
        onChange={event => {
          if (onChangeValue) {
            const isCheckboxInTable = Elements.isInTable(checkboxRef);

            if (isCheckboxInTable) {
              sendDsGaEvent('components', 'checkbox', {
                objectName: 'tables',
                description: 'checkbox',
                eventName: 'tables',
                eventAction: event.target.checked
                  ? 'enable_checkbox_tables'
                  : 'disable_checkbox_tables',
                eventLabel: label ?? name ?? 'checkbox_without_label'
              });
            } else {
              sendDsGaEvent('components', 'checkbox', {
                description: event.target.checked
                  ? 'Habilitar checkbox'
                  : 'Desabilitar checkbox',
                eventAction: event.target.checked
                  ? 'enable_checkbox'
                  : 'disable_checkbox',
                eventLabel: label ?? name ?? 'checkbox_without_label'
              });
            }

            onChangeValue(event.target);
          }
        }}
      />
      <CheckboxComponent $small={small}>
        <DSIcons.CheckIcon />
        <DSIcons.IndeterminatedIcon />
      </CheckboxComponent>
      {label && <Label>{label}</Label>}
    </Container>
  );
}
