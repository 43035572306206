import { useEffect, useRef, useState } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetCurrencies } from 'data/modules/currencies';
import {
  type ICreateManualExpenseFirstStepFieldsForm,
  type IValidateDateLimitsByProjectPayload,
  useGetExpenseParameters,
  useGetTypesOfExpenses,
  useValidateDateLimitsByProject
} from 'data/modules/expenses';
import { useGetExpenseDetails } from 'data/modules/expenses/useCases/get-expense-details/useGetExpenseDetails';
import { useGetPaymentMethods } from 'data/modules/paymentMethods';

import { CustomDate } from 'shared/utils/custom';
import { Exchange } from 'shared/utils/expenses';
import { Currency } from 'shared/utils/format';
import { TransformArray } from 'shared/utils/global';
import { Validate } from 'shared/utils/validation';

import {
  type IUseFirstStepForm,
  type IUseFirstStepFormParams
} from './FirstStepForm.types';

export function useFirstStepForm({
  onToggleShowHideExchangeFields,
  toggleShowHideExchangeFields,
  readOnlyFieldsWhenUserIsUpdatingExpense,
  expenseUuidToUpdate
}: IUseFirstStepFormParams): IUseFirstStepForm {
  const { expenseParameters } = useGetExpenseParameters();

  const inputRef = useRef<HTMLInputElement>(null);

  const { control, getValues, setValue, clearErrors, setError } =
    useFormContext<ICreateManualExpenseFirstStepFieldsForm>();

  const { getEnsuredValidateDateLimitsByProject } =
    useValidateDateLimitsByProject();

  const { t } = useTranslation('expenses');

  const {
    append: apportionmentAppend,
    fields: apportionmentFields,
    replace: apportionmentReplace
  } = useFieldArray({
    control,
    name: 'apportionment'
  });

  const { typesOfExpenses, isLoadingTypesOfExpenses } = useGetTypesOfExpenses();

  const typesOfExpensesOptions = TransformArray.objectsToSelectOptions(
    typesOfExpenses,
    'id',
    'description',
    true
  );

  const { paymentMethods, isLoadingPaymentMethods } = useGetPaymentMethods();

  const paymentMethodsOptions = TransformArray.objectsToSelectOptions(
    paymentMethods,
    'id',
    'description',
    true
  );

  const { getEnsuredExpenseDetails } = useGetExpenseDetails({
    expenseUuid: expenseUuidToUpdate ?? '',
    enabled: false
  });

  const paymentMethodIsSelected = !!getValues('paymentMethod');

  const [fieldRefundableDisabled, setFieldRefundableDisabled] = useState(false);

  useEffect(() => {
    setFieldRefundableDisabled(paymentMethodIsSelected);
  }, [paymentMethodIsSelected]);

  const { currencies, isLoadingCurrencies } = useGetCurrencies();

  const currenciesOptions = TransformArray.objectsToSelectOptions(
    currencies,
    'id',
    'isoCode',
    true
  );

  function handleClickSelectProjectButton(): void {
    const expenseValue = getValues('value');
    const expenseValueAsNumber = Currency.parseToFloat(expenseValue);

    apportionmentAppend({
      project: undefined,
      percentage: 100,
      value: expenseValueAsNumber
    });
  }

  function calculateApportionmentValues(
    expenseValueAsPositiveNumber: number
  ): void {
    const apportionmentValue = getValues('apportionment');
    const countExistingProjects = apportionmentValue?.length || 0;

    if (countExistingProjects === 0) {
      return;
    }

    if (countExistingProjects === 1) {
      setValue(
        'apportionment',
        apportionmentValue?.map(item => {
          return {
            ...item,
            percentage: 100,
            value: expenseValueAsPositiveNumber
          };
        })
      );
      return;
    }

    // se existir mais de um projeto, a porcentagem é 100 dividido pela quantidade de projetos
    const calculatedPercentage = 100 / countExistingProjects;
    const percentageForExistingProjects =
      Currency.roundToTwoDecimals(calculatedPercentage);

    // se existir mais de um projeto, o valor é o valor da despesa dividido pela quantidade de projetos
    const calculatedValue =
      expenseValueAsPositiveNumber / countExistingProjects;
    const valueForExistingProjects =
      Currency.roundToTwoDecimals(calculatedValue);

    // para cada projeto, o valor e a porcentagem são os resultados do cálculo acima
    const newFields = apportionmentValue?.map(item => {
      return {
        ...item,
        percentage: percentageForExistingProjects,
        value: valueForExistingProjects
      };
    });

    // para o último projeto, a porcentagem é o valor da despesa menos a soma das porcentagens dos outros projetos
    // isso é necessário para que a soma das porcentagens seja 100% e não tenha diferença de arredondamento
    const percentageDiff =
      percentageForExistingProjects * (countExistingProjects - 1);

    const percentageForLastProject = Currency.subtractTwoDecimals(
      100,
      percentageDiff
    );

    // para o último projeto, o valor é o valor da despesa menos a soma dos valores dos outros projetos
    // isso é necessário para que a soma dos valores seja o valor da despesa e não tenha diferença de arredondamento
    const valueDiff = valueForExistingProjects * (countExistingProjects - 1);

    const valueForLastProject = Currency.subtractTwoDecimals(
      expenseValueAsPositiveNumber,
      valueDiff
    );

    // aqui nunca vai ser undefined, mas o typescript não sabe disso
    if (newFields) {
      // coloca os valores calculados para o último projeto
      newFields[countExistingProjects - 1].percentage =
        percentageForLastProject;
      newFields[countExistingProjects - 1].value = valueForLastProject;
    }

    setValue('apportionment', newFields);
  }

  function updateExchangeFields(expenseValueAsPositiveNumber: number): void {
    const exchangeRate = getValues('exchangeRate');
    const hasExchangeRate = !!exchangeRate;

    // se tem taxa de câmbio preenchida
    if (hasExchangeRate) {
      const exchangeRateAsNumber = Currency.parseToFloat(exchangeRate, 5);

      const convertedValue = Exchange.calculate(
        exchangeRateAsNumber,
        expenseValueAsPositiveNumber,
        '*',
        2
      );

      setValue('convertedValue', convertedValue);
      clearErrors('convertedValue');
      return;
    }

    const convertedValue = getValues('convertedValue');
    const hasConvertedValue = !!convertedValue;

    // se tem valor convertido preenchido e for permitido alterar a taxa de câmbio
    if (
      hasConvertedValue &&
      !readOnlyFieldsWhenUserIsUpdatingExpense.has('exchangeRate')
    ) {
      const convertedValueAsNumber = Currency.parseToFloat(convertedValue, 2);

      const exchangeRate = Exchange.calculate(
        convertedValueAsNumber,
        expenseValueAsPositiveNumber,
        '/',
        5
      );

      setValue('exchangeRate', exchangeRate);
      clearErrors('exchangeRate');
    }
  }

  async function handleChangeExpenseValue(value: string): Promise<void> {
    const expenseValueAsNumber = Currency.parseToFloat(value);
    const expenseValueAsPositiveNumber = Math.abs(expenseValueAsNumber);

    // usuário está editando uma despesa
    if (expenseUuidToUpdate) {
      const expenseToUpdate = await getEnsuredExpenseDetails({
        expenseUuid: expenseUuidToUpdate
      });

      const expenseTransactionValue: number =
        expenseToUpdate.transaction &&
        expenseToUpdate.transaction.isInternational
          ? expenseToUpdate.transaction.finalInternationalAmount ?? 0
          : expenseToUpdate.transaction?.finalBillingAmount ?? 0;

      if (
        expenseToUpdate.transaction &&
        expenseValueAsPositiveNumber > expenseTransactionValue
      ) {
        setError('value', {
          type: 'max-value',
          message: t('errors.expenseAmountCannotExceedTransactionValue', {
            currency: expenseToUpdate.currency.isoCode,
            value: Currency.format('BRL', expenseTransactionValue)
          })
        });
      }

      if (
        expenseToUpdate.transaction &&
        expenseTransactionValue &&
        expenseValueAsPositiveNumber <= expenseTransactionValue
      ) {
        clearErrors('value');
      }
    }

    // função handlerCalculateExchageValuesFromExpense no arquivo ConversaoCambio.js no repositório vexpenses-web
    toggleShowHideExchangeFields &&
      updateExchangeFields(expenseValueAsPositiveNumber);

    calculateApportionmentValues(expenseValueAsNumber);
  }

  // função handlerCalculateExchangeValueFromRate no arquivo ConversaoCambio.js no repositório vexpenses-web
  function handleChangeExchangeRate(value: string): void {
    const expenseValue = getValues('value');
    if (!value || !expenseValue) return;

    const expenseValueAsNumber = Currency.parseToFloat(expenseValue);
    const exchangeRate = Currency.parseToFloat(value, 5);

    const expenseValueAsPositiveNumber = Math.abs(expenseValueAsNumber);

    const convertedValue = Exchange.calculate(
      exchangeRate,
      expenseValueAsPositiveNumber,
      '*',
      2
    );

    setValue('convertedValue', convertedValue);
    clearErrors('convertedValue');
  }

  // função handlerCalculateExchangeValueFromConverted no arquivo ConversaoCambio.js no repositório vexpenses-web
  function handleChangeConvertedValue(value: string): void {
    if (!value) return;

    // se estiver em edição de despesa e o campo de taxa de câmbio estiver desabilitado, faz o cálculo do valor da despesa
    if (readOnlyFieldsWhenUserIsUpdatingExpense.has('exchangeRate')) {
      const exchangeRate = getValues('exchangeRate');

      if (!exchangeRate) {
        return;
      }

      const exchangeRateAsNumber = Currency.parseToFloat(exchangeRate, 5);

      const convertedValue = Currency.parseToFloat(value, 2);

      const expenseValue = Exchange.calculate(
        convertedValue,
        exchangeRateAsNumber,
        '/',
        2
      );

      setValue('value', expenseValue);

      const expenseValueAsNumber = Currency.parseToFloat(expenseValue);

      toggleShowHideExchangeFields &&
        updateExchangeFields(expenseValueAsNumber);

      calculateApportionmentValues(expenseValueAsNumber);
      return;
    }

    const expenseValue = getValues('value');

    if (expenseValue !== '') {
      const convertedValue = Currency.parseToFloat(value, 2);
      const expenseValueAsNumber = Currency.parseToFloat(expenseValue, 2);

      const exchangeRate = Exchange.calculate(
        convertedValue,
        expenseValueAsNumber,
        '/',
        5
      );

      setValue('exchangeRate', exchangeRate);
      clearErrors('exchangeRate');
    }
  }

  async function validateDateLimitsPolicyByUser(
    selectedDate: string,
    futureDaysLimit: number | null,
    pastDaysLimit: number | null
  ): Promise<void> {
    if (expenseUuidToUpdate === undefined) {
      return;
    }

    const expenseToUpdate = await getEnsuredExpenseDetails({
      expenseUuid: expenseUuidToUpdate
    });

    // transforma a data de criação da despesa em ISO (yyyy-MM-dd)
    const createdAtDateInIsoFormat = CustomDate.formatFromISO(
      expenseToUpdate.createdAt,
      'yyyy-MM-dd'
    );

    // transforma yyyy-MM-dd em Date
    const expenseCreatedAtDate = CustomDate.parseIsoToDate(
      createdAtDateInIsoFormat
    );

    // pega a diferença de dias entre a data de criação da despesa e a data selecionada
    const differenceBetweenDates = CustomDate.differenceBetweenDates(
      expenseCreatedAtDate,
      CustomDate.parseIsoToDate(selectedDate)
    );

    // pega a diferença absoluta entre a data de criação da despesa e a data selecionada
    const absoluteDifferenceBetweenDates = Math.abs(differenceBetweenDates);

    // se a diferença de datas for um número negativo e a diferença absoluta for maior que o limite de dias futuros
    // então a despesa excede o limite de dias futuros
    if (
      futureDaysLimit !== null &&
      differenceBetweenDates < 0 &&
      absoluteDifferenceBetweenDates > futureDaysLimit
    ) {
      const exceedInDays = absoluteDifferenceBetweenDates - futureDaysLimit;

      setError('date', {
        type: 'validated-by-user-policy',
        message: t('errors.expenseExceedsTheMaximumAmountOfFutureDays', {
          days: exceedInDays
        })
      });
      return;
    }

    // se a diferença de datas for um número positivo e a diferença absoluta for maior que o limite de dias passados
    // então a despesa excede o limite de dias passados
    if (
      pastDaysLimit !== null &&
      absoluteDifferenceBetweenDates > pastDaysLimit
    ) {
      const exceedInDays = absoluteDifferenceBetweenDates - pastDaysLimit;

      setError('date', {
        type: 'validated-by-user-policy',
        message: t('errors.expenseExceedsTheMaximumAmountOfDaysPassed', {
          days: exceedInDays
        })
      });
      return;
    }

    clearErrors('date');
  }

  async function handleChangeDate(value: string | undefined): Promise<void> {
    if (value === undefined) {
      return;
    }

    // se o parâmetro de política de despesa for USUARIO e estiver editando uma despesa e algum dos parâmetros de dias futuros ou passados for diferente de null
    if (
      expenseParameters?.policyType === 'USUARIO' &&
      expenseUuidToUpdate &&
      (expenseParameters.daysPolicy.futureDays !== null ||
        expenseParameters.daysPolicy.pastDays !== null)
    ) {
      await validateDateLimitsPolicyByUser(
        value,
        expenseParameters.daysPolicy.futureDays,
        expenseParameters.daysPolicy.pastDays
      );
    }

    if (expenseParameters?.policyType !== 'PROJETO') {
      return;
    }

    if (Validate.isDate(value, 'yyyy-mm-dd')) {
      const apportionment = getValues('apportionment');

      const filteredApportionment = apportionment
        ? apportionment.filter(item => item.project !== undefined)
        : [];

      if (filteredApportionment.length === 0) {
        clearErrors('date');
        return;
      }

      const policyByProjectQueryPayload: IValidateDateLimitsByProjectPayload = {
        date: value,
        projects: filteredApportionment.map(item => {
          return {
            id: item.project?.value as string,
            percentage: item.percentage
          };
        }),
        expenseUuid: expenseUuidToUpdate
      };

      const checkPolicyByProject = await getEnsuredValidateDateLimitsByProject(
        policyByProjectQueryPayload
      );

      if (checkPolicyByProject !== null && !checkPolicyByProject.success) {
        if (checkPolicyByProject.daysLimitAgo !== null) {
          setError('date', {
            type: 'validated-by-project',
            message: t('errors.expenseExceedsTheMaximumAmountOfDaysPassed', {
              days: checkPolicyByProject.daysLimitAgo
            })
          });
        }

        if (checkPolicyByProject.daysLimitAhead !== null) {
          setError('date', {
            type: 'validated-by-project',
            message: t('errors.expenseExceedsTheMaximumAmountOfFutureDays', {
              days: checkPolicyByProject.daysLimitAhead
            })
          });
        }

        return;
      }

      clearErrors('date');
    }
  }

  function handleChangePaymentMethod(paymentMethodId: string): void {
    if (!paymentMethodId) {
      setFieldRefundableDisabled(false);
      return;
    }

    const searchedPaymentMethod = paymentMethods?.find(
      paymentMethod => paymentMethod.id.toString() === paymentMethodId
    );

    if (searchedPaymentMethod === undefined) {
      setFieldRefundableDisabled(false);
      return;
    }

    const isPaymentMethodRefundable = searchedPaymentMethod?.refundable;

    setValue('refundable', isPaymentMethodRefundable ?? false);
    setFieldRefundableDisabled(true);

    if (expenseParameters?.usesExchange === 'FORMA_DE_PAGAMENTO') {
      const selectedCurrencyId = getValues('currency');
      const companyDefaultCurrencyId = String(
        expenseParameters?.defaultCurrency.company ?? ''
      );

      onToggleShowHideExchangeFields(
        searchedPaymentMethod.usesExchange &&
          selectedCurrencyId !== companyDefaultCurrencyId
      );
    }
  }

  const updatedPaymentMethodsOptions =
    // se o campo de formas de pagamento estiver desabilitado, não remove a opção de Cartão VExpenses
    readOnlyFieldsWhenUserIsUpdatingExpense.has('paymentMethod')
      ? paymentMethodsOptions
      : paymentMethodsOptions.filter(
          paymentMethod => paymentMethod.label !== 'Cartão VExpenses'
        );

  const observationAttachments = getValues('observationAttachments');

  const showObservationsAttachmentsIcon =
    expenseParameters?.hasObservationAttachment ||
    (observationAttachments !== undefined && observationAttachments.length > 0);

  return {
    paymentMethodsOptions: updatedPaymentMethodsOptions,
    typesOfExpensesOptions,
    isLoadingExpensePaymentMethods: isLoadingPaymentMethods,
    isLoadingTypesOfExpenses,
    currenciesOptions,
    // é necessário ter o isLoadingPaymentMethods por conta das regras de negócio da forma de pagamento com câmbio
    isLoadingCurrencies: isLoadingCurrencies || isLoadingPaymentMethods,
    expenseParameters,
    handleClickSelectProjectButton,
    showObservationsAttachmentsIcon,
    apportionmentFields,
    apportionmentReplace,
    handleChangeExpenseValue,
    handleChangeExchangeRate,
    handleChangeConvertedValue,
    handleChangePaymentMethod,
    fieldRefundableDisabled,
    handleChangeDate,
    inputRef
  };
}
