import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useLangContext, useModalContext } from 'data/contexts';
import {
  BusinessCardsMutationKeys,
  BusinessCardsService,
  type IAttachTeamMembersToBusinessCardPayload,
  type IChangeBusinessCardTeamMembersFieldsForm,
  type IRemoveRelatedTeamMembersPayload,
  type IRemoveTeamMembersParams,
  type IUseChangeBusinessCardTeamMembers,
  type IUseChangeBusinessCardTeamMembersParams
} from 'data/modules/cards/businessCard';
import {
  type ITeamMemberItem,
  TeamMembersQueryKeys
} from 'data/modules/teamMembers';

import { useFormWithSchema } from 'shared/hooks/forms';

import { useChangeBusinessCardTeamMembersSchema } from './schemas';

export function useChangeBusinessCardTeamMembers({
  uuid
}: IUseChangeBusinessCardTeamMembersParams): IUseChangeBusinessCardTeamMembers {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const { handleCloseModal } = useModalContext();

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset
  } = useFormWithSchema(useChangeBusinessCardTeamMembersSchema(), {
    defaultValues: {
      teamMembers: []
    }
  });

  const queryClient = useQueryClient();

  const {
    mutate: removeRelatedTeamMembers,
    isLoading: isRemovingRelatedTeamMembers
  } = useMutationCache({
    mutationKey: [BusinessCardsMutationKeys.REMOVE_RELATED_TEAM_MEMBERS],
    mutationFn: async (payload: IRemoveRelatedTeamMembersPayload) =>
      await BusinessCardsService.removeRelatedTeamMembers(payload),
    onSuccess: (data, payload) => {
      if (data) {
        toast.success(
          lang.settings.manageBusinessCard.member_unlinked_successfully[
            currentLangKey
          ]
        );

        queryClient.setQueryData<ITeamMemberItem[]>(
          [TeamMembersQueryKeys.GET_RELATED_TEAM_MEMBERS, uuid],
          oldData => {
            return oldData?.filter(({ uuid }) => uuid !== payload.uuidMember);
          }
        );
        handleCloseModal();
      }
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  const {
    mutate: attachTeamMembersToBusinessCard,
    isLoading: loadingAttachTeamMembersToBusinessCard
  } = useMutationCache({
    mutationKey: [BusinessCardsMutationKeys.ATTACH_TEAM_MEMBERS],
    mutationFn: async (payload: IAttachTeamMembersToBusinessCardPayload) =>
      await BusinessCardsService.attachTeamMembersToBusinessCard(payload),
    onSuccess: (data, payload) => {
      toast.success(
        lang.settings.manageBusinessCard.members_linked_successfully[
          currentLangKey
        ]
      );
      const teamMembers = queryClient.getQueryData([
        TeamMembersQueryKeys.GET_TEAM_MEMBERS,
        {
          page: 1,
          perPage: 500
        }
      ]) as ITeamMemberItem[];

      queryClient.setQueryData<ITeamMemberItem[]>(
        [TeamMembersQueryKeys.GET_RELATED_TEAM_MEMBERS, uuid],
        oldData => {
          const newTeamMembers = payload.outsourcers.map(item => {
            return teamMembers.find(({ uuid }) => uuid === item);
          }) as ITeamMemberItem[];

          if (oldData && oldData.length > 0) {
            return [...oldData, ...newTeamMembers];
          }

          return newTeamMembers;
        }
      );
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function handleRemoveTeamMembers({ id }: IRemoveTeamMembersParams): void {
    if (id) {
      removeRelatedTeamMembers({
        uuidMember: id,
        uuid
      });
    }
  }

  function onSubmit(data: IChangeBusinessCardTeamMembersFieldsForm): void {
    if (data.teamMembers && data.teamMembers.length > 0) {
      attachTeamMembersToBusinessCard({
        outsourcers: data.teamMembers,
        outsourced: [uuid],
        permissions: [
          'EXPENSES',
          'REPORTS',
          'CONCILIATION',
          'SEND_REPORT_FOR_APPROVAL',
          'WITHDRAWALS_ADVANCE',
          'CARDS'
        ]
      });

      reset();
    }
  }

  return {
    control,
    errors,
    loadingAttachTeamMembersToBusinessCard,
    isRemovingRelatedTeamMembers,
    handleSubmit: handleSubmit(onSubmit),
    clearErrors,
    handleRemoveTeamMembers
  };
}
