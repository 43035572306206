import { useEffect, useMemo, useState } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { useModalContext, useTeamMembersContext } from 'data/contexts';
import { useGetChargedUsers } from 'data/modules/financial';
import {
  useGetTeamMembers,
  useGetTeamMembersResume
} from 'data/modules/teamMembers';

import { useBoolean, useDebounce } from 'shared/hooks/global';
import { useGetSearchParams } from 'shared/hooks/global/useGetSearchParams/useGetSearchParams';

import {
  type ITeamMemberFilters,
  type ITeamMemberWithChargedUser,
  type IUseTeamMembers,
  type TeamMemberOrderByType
} from './TeamMembers.types';

export function useTeamMembers(): IUseTeamMembers {
  const { handleOpenModal } = useModalContext();
  const [isSearchChanged, , setIsSearchChangedTrue, setIsSearchChangedFalse] =
    useBoolean(true);

  const {
    setSearchParams,
    removeSearchParam,
    searchParams: { orderBy, order, search, page }
  } = useGetSearchParams<ITeamMemberFilters & { page: number }>({
    values: ['orderBy', 'order', 'search', 'page']
  });

  const [perPage, totalPage, handleChangePagination] = useTeamMembersContext(
    useShallow(state => [
      state.perPage,
      state.totalPage,
      state.handleChangePagination
    ])
  );

  const [filters, setFilters] = useState<ITeamMemberFilters>({
    orderBy: orderBy ?? 'name',
    order: order ?? 'asc',
    search: search ?? ''
  } as ITeamMemberFilters);

  const debouncedOrder = useDebounce(filters.order);
  const debouncedOrderBy = useDebounce(filters.orderBy);
  const debouncedSearch = useDebounce(filters.search, 1000);

  const payload = {
    perPage,
    page: page ?? 1,
    order: debouncedOrder,
    search: debouncedSearch,
    orderBy: debouncedOrderBy
  };

  const { teamMembers, isLoadingTeamMembers, pagination } =
    useGetTeamMembers(payload);

  const { chargedUsers, isLoadingChargedUsers } = useGetChargedUsers();

  const { teamMembersResume, isLoadingTeamMembersResume } =
    useGetTeamMembersResume();

  const teamMembersWithChargedData = useMemo(
    () =>
      teamMembers.map(teamMember => {
        const chargedUser = chargedUsers.find(
          chargedUser => chargedUser.id === teamMember.id
        );

        return {
          ...teamMember,
          charged: chargedUser
            ? {
                startDate: chargedUser.startDate,
                endDate: chargedUser.endDate
              }
            : undefined
        } as ITeamMemberWithChargedUser;
      }),
    [chargedUsers, teamMembers]
  );

  function handleExcelExport(): void {
    alert('Export Excel');
  }

  function handleAddNewTeamMember(): void {
    handleOpenModal('newTeamMember');
  }

  function handleChangeSearch(search: string): void {
    setIsSearchChangedTrue();
    setFilters(filter => ({ ...filter, search }));
    setSearchParams({
      page: 1
    });
  }

  function handleSortTeamMembers(value: TeamMemberOrderByType): void {
    setFilters(oldState => {
      const order =
        value !== oldState.orderBy
          ? 'asc'
          : oldState.order === 'asc'
            ? 'desc'
            : 'asc';

      setSearchParams({
        order,
        orderBy: value
      });

      return {
        ...oldState,
        orderBy: value,
        order
      };
    });
  }

  /**
   * Atualiza total de páginas
   * */
  useEffect(() => {
    handleChangePagination({ totalPage: pagination?.totalPage ?? 0 });

    setIsSearchChangedFalse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination?.totalPage]);

  /**
   * Seta 'false' a flag de change do termo de pesquisa
   * */
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSearchChangedFalse();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [filters.search, setIsSearchChangedFalse]);

  /**
   * Atualiza termo da pesquisa na URL,
   * ou remove se estiver vazio
   * */
  useEffect(() => {
    if (debouncedSearch !== '') {
      setSearchParams({
        search: debouncedSearch
      });

      return;
    }

    removeSearchParam('search');
  }, [setSearchParams, debouncedSearch, removeSearchParam]);

  /**
   * Valida se 'page' é numérico, caso contrário seta como 1
   * Se 'page' maior que total de páginas, também seta como 1
   */
  useEffect(() => {
    if (isNaN(Number(page)) || (totalPage && page > totalPage)) {
      setSearchParams({
        page: 1
      });
    }
  }, [setSearchParams, page, totalPage]);

  return {
    filters,
    teamMembersResume,
    handleExcelExport,
    handleChangeSearch,
    handleSortTeamMembers,
    isLoadingChargedUsers,
    handleAddNewTeamMember,
    isLoadingTeamMembersResume,
    teamMembers: teamMembersWithChargedData,
    isLoadingTeamMembers: isLoadingTeamMembers || isSearchChanged
  };
}
