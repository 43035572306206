import { useLangContext } from 'data/contexts';

import { DsTextInput } from 'presentation/ds/DsTextInput';
import {
  OnboardingFooter,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { useNaturalPersonData } from './useNaturalPersonData';

import { Container, FormContainer } from './NaturalPersonData.styles';

export function NaturalPersonData(): JSX.Element {
  const [forms, naturalPersonData, currentLangKey] = useLangContext(state => [
    state.lang.forms,
    state.lang.cards.onboarding.company.add_partner.natural_person_data,
    state.currentLangKey
  ]);

  const {
    control,
    errors,
    handleAddMembersOrLegalRepresentatives,
    setValue,
    handleBackButtonClick
  } = useNaturalPersonData();

  return (
    <Container>
      <OnboardingTitle>
        {naturalPersonData.title[currentLangKey]}
      </OnboardingTitle>

      <FormContainer onSubmit={handleAddMembersOrLegalRepresentatives}>
        <DsTextInput
          name='name'
          mask='letters'
          label={forms.full_name[currentLangKey]}
          onChange={() => {}}
          control={control}
          error={errors.name?.message}
          onClear={() => {
            setValue('name', '');
          }}
        />

        <DsTextInput
          name='document'
          label={forms.cpf[currentLangKey]}
          mask='cpf'
          onChange={() => {}}
          control={control}
          error={errors.document?.message}
          onClear={() => {
            setValue('document', '');
          }}
        />

        <DsTextInput
          name='birthDate'
          label={forms.birth_date[currentLangKey]}
          mask='date'
          onChange={() => {}}
          control={control}
          error={errors.birthDate?.message}
          onClear={() => {
            setValue('birthDate', '');
          }}
        />

        <DsTextInput
          name='phoneNumber'
          label={forms.phone[currentLangKey]}
          mask='phone'
          onChange={() => {}}
          control={control}
          error={errors.phoneNumber?.message}
          onClear={() => {
            setValue('phoneNumber', '');
          }}
        />

        <DsTextInput
          name='email'
          label={forms.email[currentLangKey]}
          onChange={() => {}}
          control={control}
          error={errors.email?.message}
          onClear={() => {
            setValue('email', '');
          }}
        />

        <OnboardingFooter
          backButtonProps={{
            onClick: () => {
              handleBackButtonClick();
            }
          }}
          continueButtonProps={{
            onClick: () => {}
          }}
        />
      </FormContainer>
    </Container>
  );
}
