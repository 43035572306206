import { useQueryCache } from 'data/cache';
import {
  FuelQueryKeys,
  FuelService,
  type IUseGetVehiclesByCurrentUser,
  type IUseGetVehiclesByCurrentUserParams
} from 'data/modules/fuel';

export function useGetVehiclesByCurrentUser(
  params?: IUseGetVehiclesByCurrentUserParams
): IUseGetVehiclesByCurrentUser {
  const {
    data: vehiclesByCurrentUser,
    isLoading: isLoadingVehiclesByCurrentUser
  } = useQueryCache({
    queryKey: [FuelQueryKeys.GET_VEHICLES_BY_CURRENT_USER],
    queryFn: FuelService.getVehiclesByCurrentUser,
    enabled: params?.enabled
  });

  return {
    vehiclesByCurrentUser,
    isLoadingVehiclesByCurrentUser
  };
}
