import * as RadioGroup from '@radix-ui/react-radio-group';
import styled, { css } from 'styled-components';

import { type IDsStyleProps } from './DsRadioButton.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<AddPrefixToType<IDsStyleProps>>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
  height: min(5.2rem, 100%);
  padding: ${({ theme }) =>
    `${theme.tokens.spacingInsetXs} ${theme.tokens.spacingInsetSm}`};
  border: ${({ theme }) => `${theme.tokens.borderWidthThin} solid transparent`};
  background-color: ${({ theme }) => `${theme.tokens.neutralColorHighMedium}`};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusSm};
  opacity: ${({ $disabled }) => $disabled && '50%'};
  cursor: pointer;

  > i {
    color: ${({ theme }) => theme.tokens.brandColorSecondaryPure};
    opacity: ${({ $disabled }) => $disabled && '50%'};
  }

  &:disabled {
    cursor: default;
  }

  &:has(button[data-state='checked']) {
    border-color: ${({ theme }) => `${theme.tokens.brandColorPrimaryPure}`};
    background-color: transparent;
    transition: all 0.3s;
  }

  &:hover:has(button[data-state='unchecked']) > button[data-state='unchecked'] {
    background-color: ${({ theme }) => theme.tokens.neutralColorLowMedium};
    transition: all 0.3s;
  }
`;

export const StyledRadioItem = styled(RadioGroup.Item)`
  display: flex;
  all: unset;
  width: 2rem;
  height: 2rem;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusCircular};

  &:hover {
    background-color: ${({ theme }) => theme.tokens.brandColorPrimaryDark};
    transition: all 0.3s;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.tokens.brandColorPrimaryMedium};
    cursor: default;
  }

  &[data-state='unchecked'] {
    background-color: ${({ theme }) => theme.tokens.neutralColorLowLight};

    &:hover {
      background-color: ${({ theme }) => theme.tokens.neutralColorLowMedium};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.tokens.neutralColorHighPurest};
      cursor: default;
    }
  }
`;

export const StyledIndicator = styled(RadioGroup.Indicator)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &[data-state='checked'] {
    &::after {
      content: '';
      display: block;
      width: 0.9rem;
      height: 0.9rem;
      border-radius: ${({ theme }) => theme.tokens.borderRadiusCircular};
      background-color: ${({ theme }) => theme.tokens.neutralColorHighPure};
    }
  }
`;
export const Label = styled.label<AddPrefixToType<IDsStyleProps>>`
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxs};
  font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
  color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
  letter-spacing: 0.5px;
  flex: 1;

  &:hover {
    cursor: pointer;

    ${({ $disabled }) =>
      $disabled &&
      css`
        cursor: default;
      `};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `};
`;
