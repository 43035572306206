import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type ContainerImageStyleType } from './SelectOdometerImage.types';
import { type AddPrefixToType } from 'ds/types';

export const AvatarContent = styled.div`
  display: flex;
  gap: ${tokens.spacingNano};
  color: ${tokens.neutralColorLowLight};
  align-items: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.075rem;

  .avatar-odometer-image {
    cursor: pointer;
  }

  .avatar-odometer-with-icon {
    padding: 0.4rem;
    color: ${tokens.neutralColorLowMedium};
  }
`;

export const ContainerImage = styled.div<
  AddPrefixToType<ContainerImageStyleType>
>`
  display: flex;
  gap: ${tokens.spacingNano};
  justify-content: space-between;
  align-items: center;
  padding: ${tokens.spacingQuarck} ${tokens.spacingNano};
  height: 4.1rem;
  border-radius: ${tokens.borderRadiusXs};
  border: 1px solid ${tokens.neutralColorLowLight};

  .select-odometer-image-button {
    padding: 0.8rem 2rem;
    height: 3.2rem;
  }

  ${({ $hasImage }) =>
    $hasImage &&
    css`
      border: 1px solid ${tokens.neutralColorLowMedium};

      ${AvatarContent} {
        color: ${tokens.neutralColorLowMedium};
      }
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Caption = styled.span`
  display: inline-block;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  color: ${tokens.feedbackColorErrorPure};
`;

export const InputFile = styled.input`
  display: none;
`;
