import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { Tooltip } from 'presentation/components/base/Tooltip';

import { Currency } from 'shared/utils/format';

import { useInternationalFinalValue } from './useInternationalFinalValue';

import { type IInternationalFinalValueProps } from './InternationalFinalValue.types';

import { Container, TooltipContainer } from './InternationalFinalValue.styles';

export function InternationalFinalValue({
  transaction
}: IInternationalFinalValueProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { tooltipContent } = useInternationalFinalValue({
    transaction
  });

  return (
    <Container $isTransactionReversed={transaction.reversed}>
      <TooltipContainer $isTransactionReversed={transaction.reversed}>
        {/* VALOR FINAL */}
        {
          lang.cards.statement.transaction_details_modal.final_value[
            currentLangKey
          ]
        }

        {/* TOOLTIP */}
        <Tooltip
          id='finalValueTooltip'
          content={tooltipContent}
        >
          <BaseIcon
            name='question'
            color='secondary'
            size={1.8}
          />
        </Tooltip>
      </TooltipContainer>

      {/* VALOR */}
      <span>
        {`${transaction.finalBillingCurrencyCode} ${Currency.format(
          transaction.finalBillingCurrencyCode,
          transaction.finalBillingAmount
        )}`}
      </span>
    </Container>
  );
}
