import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;

  > h1 {
    text-align: left;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 4.8rem 1rem 0;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const RowDisposedFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  > div {
    flex-grow: 1;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
    gap: 3.2rem;
  }
`;

export const CustomLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighMediumest};
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
