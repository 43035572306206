import Select, { type StylesConfig } from 'react-select';
import styled, { css } from 'styled-components';

import { defaultTheme } from 'shared/styles/themes/default.theme';

import {
  type DsSelectStylesType,
  type LabelStylesType
} from './DsSelect.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  min-width: 30rem;

  span {
    display: none;
  }
`;

export const StyledSelect = styled(Select)<AddPrefixToType<DsSelectStylesType>>`
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxs};
  color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
  width: 100%;

  &:focus-within {
    z-index: 2;
  }

  div {
    border-radius: ${({ theme }) => theme.tokens.borderRadiusMd};
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  .react-select__control {
    background-color: ${({ theme }) => theme.tokens.neutralColorHighMedium};
    box-shadow: none;
    border: ${({ theme }) => theme.tokens.borderWidthThin} solid transparent;
    ${({ $label }) =>
      $label
        ? css`
            padding-top: 2.2rem;
          `
        : css`
            height: 6.4rem;
          `}
    width: 100%;

    cursor: pointer;

    &:hover {
      border-color: ${({ theme }) => theme.tokens.brandColorSecondaryPure};
    }

    svg {
      width: 2.8rem;
      cursor: pointer;
    }

    opacity: ${({ isDisabled }) => isDisabled && `0.5`};

    ${({ $error }) =>
      $error &&
      css`
        background-color: ${({ theme }) =>
          theme.tokens.feedbackColorErrorLight};
        border-color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};

        &:hover {
          border-color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
        }
      `}
  }

  .react-select__indicator {
    color: ${({ $error }) =>
      $error
        ? `${defaultTheme.tokens.feedbackColorErrorPure}`
        : `${defaultTheme.tokens.neutralColorLowMedium}`};

    margin-top: ${({ value }) => (value ? '-2.2rem' : 'unset')};
    margin-right: 1.2rem;
  }

  .react-select__placeholder {
    color: ${({ $error }) =>
      $error
        ? `${defaultTheme.tokens.feedbackColorErrorPure}`
        : `${defaultTheme.tokens.neutralColorLowDarkest}`};
  }

  .react-select__single-value {
    color: ${({ $error }) =>
      $error
        ? `${defaultTheme.tokens.feedbackColorErrorPure}`
        : `${defaultTheme.tokens.neutralColorLowPure}`};
  }

  .react-select__control--menu-is-open {
    border-color: ${({ theme }) => theme.tokens.brandColorSecondaryPure};
    background-color: transparent;
  }

  .react-select__multi-value {
    cursor: default;
    background-color: ${({ theme }) => theme.tokens.brandColorPrimaryLight};

    div {
      font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
      font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
      color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
    }

    svg {
      width: 2rem;
      cursor: pointer;
    }
  }

  .react-select__multi-value__remove {
    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
    }
  }

  .react-select__clear-indicator {
    color: ${({ theme }) => theme.tokens.neutralColorLowMedium};
  }

  .react-select__menu-list {
    overflow-x: hidden;
    max-height: 25rem;

    ::-webkit-scrollbar {
      width: 1.6rem;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 16px;
      background-color: ${({ theme }) => theme.tokens.neutralColorLowLight};
      background-clip: padding-box;
      border: 4px solid rgb(0 0 0 / 0%);
    }
  }
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighDark};
  padding: ${({ theme }) =>
    `${theme.tokens.spacingInsetSm} ${theme.tokens.spacingInsetMdd}`};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusMd};
  color: ${({ theme }) => theme.tokens.neutralColorLowDark};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-size: ${({ theme }) => theme.tokens.fontSizeXs};
  font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
  min-width: 16rem;
  box-shadow: 0 0 32px rgb(0 0 0 / 4%);
  z-index: 9999;
`;

export const colourStyles: StylesConfig = {
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? `${defaultTheme.tokens.neutralColorHighMediumest}`
        : isSelected
          ? `${defaultTheme.tokens.brandColorSecondaryLightest}`
          : isFocused
            ? `${defaultTheme.tokens.colorHighMediumest}`
            : undefined,
      color: isSelected
        ? `${defaultTheme.tokens.brandColorSecondaryPure}`
        : `${defaultTheme.tokens.neutralColorLowDarkest}`,
      width: '95%',
      borderRadius: `${defaultTheme.tokens.borderRadiusSm}`,
      margin: `${defaultTheme.tokens.spacingNano}`,
      cursor: isFocused ? 'pointer' : 'default',
      overflowX: 'hidden'
    };
  },
  dropdownIndicator: (base, state) => ({
    ...base,
    transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
    transition: '250ms',
    color: `${defaultTheme.tokens.neutralColorLowMedium}`
  })
};

export const Label = styled.label<AddPrefixToType<LabelStylesType>>`
  pointer-events: none;
  z-index: 1;
  position: absolute;
  top: 1rem;
  left: 1.2rem;
  color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  ${({ $error }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.tokens.neutralColorLowLightest};
    `}
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  word-break: break-all;
  margin-top: 0.5rem;
  margin-left: 0.3rem;
`;
