import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useCardGroupsContext, useLangContext } from 'data/contexts';
import { useGetCardGroup } from 'data/modules/cards/account/useCases/get-card-group/useGetCardGroup';

import { Loader } from 'presentation/components/global/Loader';
import { DefaultLayout, TripleColumn } from 'presentation/templates';

import { AdminStatementPageHeader } from './components/AdminMovementsPageHeader/AdminStatementPageHeader.component';
import { AdminStatementSidebar } from './components/AdminStatementSidebar';
import { useAdminStatement } from './useAdminStatement';

export function AdminStatement(): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { t } = useTranslation('cards');

  const {
    setStatementFilters,
    statementFilters,
    isLoadingStatement,
    centerElementToRender,
    checkedAllUsers,
    setCheckedAllUsers,
    isGeneratingFile,
    handleGenerateStatementInFile,
    cardsToBeFiltered
  } = useAdminStatement();

  document.title = `vexpenses | ${lang.cards.statement.page_title[currentLangKey]}`;

  const { groupId } = useParams();

  const contextCardGroup = useCardGroupsContext(
    state => state.selectedCardGroup
  );

  const { cardGroupData } = useGetCardGroup({
    cardGroupId: groupId || '',
    enabled: !contextCardGroup
  });

  const selectedCardGroup = contextCardGroup || cardGroupData;

  return (
    <DefaultLayout
      {...(groupId && selectedCardGroup
        ? {
            pageNameAndBreadcrumbs: {
              pageName: t('movements'),
              isBackButtonVisible: true,
              isBreadcrumbsVisible: true,
              isClassicMode: false,
              breadcrumbs: [
                {
                  name: t('cardsManagement'),
                  pageLink: `/cartoes/gestao-de-grupos/${selectedCardGroup?.id}`
                },
                {
                  name: selectedCardGroup.name,
                  pageLink: `/cartoes/gestao-de-grupos/${selectedCardGroup?.id}`
                },
                {
                  name: t('movements')
                }
              ]
            }
          }
        : {})}
    >
      {groupId && <AdminStatementPageHeader />}

      <TripleColumn
        leftElement={
          <AdminStatementSidebar
            cardsToBeFiltered={cardsToBeFiltered}
            isGeneratingFile={isGeneratingFile}
            onGenerateExcel={filters => {
              handleGenerateStatementInFile('excel', filters);
            }}
            onGeneratePdf={filters => {
              handleGenerateStatementInFile('pdf', filters);
            }}
            initialDateFilter={statementFilters.dateFilter}
            initialSelectedUsers={statementFilters.selectedUsers}
            onFilter={filters => {
              setStatementFilters(filters);
            }}
            checkedAllUsers={checkedAllUsers}
            onToggleCheckedAllUsers={() => {
              setCheckedAllUsers(!checkedAllUsers);
            }}
          />
        }
        centerElement={centerElementToRender}
        lgScreenGridSize={{
          left: '1fr',
          center: '3fr'
        }}
      />
      <Loader isLoading={isLoadingStatement} />
    </DefaultLayout>
  );
}
