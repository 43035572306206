import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import {
  type OnboardingContainerStylesType,
  type OnboardingStepStylesType
} from './OnboardingStep.types';

export const Container = styled.div<
  AddPrefixToType<OnboardingContainerStylesType>
>`
  background-color: ${({ theme }) => theme.tokens.neutralColorHighPure};
  position: relative;
  z-index: 2;
  max-width: ${({ $currentStep }) =>
    $currentStep === '9' ? '130rem' : '84rem'};
  padding: 2.4rem;
  width: 95%;
  min-height: 40rem;
  height: fit-content;
  box-shadow: 0 4px 132px rgb(12 26 115 / 42%);
  border-radius: 10px;
  margin: 2rem;
  margin-top: 0;

  @media screen and (max-width: 800px) {
    width: 90%;
    min-width: unset;
  }
`;

export const ProgressBar = styled.div<
  AddPrefixToType<OnboardingStepStylesType>
>`
  width: ${({ $percentage }) => $percentage}%;
  height: 1rem;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.tokens.brandColorSecondaryPure} 0%,
    ${({ theme }) => theme.tokens.brandColorPrimaryPure} 93.75%
  );
  border-radius: 5px;
  margin-bottom: 1.6rem;
`;
