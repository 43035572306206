import { useLangContext } from 'data/contexts';

import {
  InputContainer,
  MultipleInputs,
  TextInput
} from 'presentation/components/base/Input';
import { Select } from 'presentation/components/base/Select';

import { businessTypes } from 'shared/constants/company';
import { countries, states } from 'shared/constants/global';
import { TransformArray } from 'shared/utils/global';

import { type IAccountHolderFormProps } from './AccountHolderForm.types';

import {
  FirstLineMultipleInputs,
  ManageBusinessAccountHolderFormTitle
} from './AccountHolderForm.styles';

export function AccountHolderForm({
  control,
  errors,
  isFetchingAddress,
  isUpdating
}: IAccountHolderFormProps): JSX.Element {
  const [forms, global, settings, currentLangKey] = useLangContext(state => [
    state.lang.forms,
    state.lang.global,
    state.lang.settings,
    state.currentLangKey
  ]);

  return (
    <>
      <ManageBusinessAccountHolderFormTitle>
        {
          settings.accountHolders.write_below_business_account_holder_data[
            currentLangKey
          ]
        }
      </ManageBusinessAccountHolderFormTitle>

      <FirstLineMultipleInputs>
        <InputContainer
          label={`${global.corporate_name[currentLangKey]} *`}
          error={errors.legalName?.message}
        >
          <TextInput
            name='legalName'
            control={control}
            error={!!errors.legalName?.message}
          />
        </InputContainer>

        <InputContainer
          label={global.surname[currentLangKey]}
          error={errors.name?.message}
        >
          <TextInput
            name='name'
            control={control}
            error={!!errors.name?.message}
          />
        </InputContainer>

        <InputContainer label={`${forms.company_type[currentLangKey]} *`}>
          <Select
            hasArrowDownIndicator
            options={TransformArray.stringsToSelectOptions(businessTypes)}
            control={control}
            name='businessType'
            error={errors.businessType?.message}
          />
        </InputContainer>
      </FirstLineMultipleInputs>

      <MultipleInputs>
        <InputContainer
          label={`${forms.cnpj[currentLangKey]} *`}
          error={errors.documentNumber?.message}
        >
          <TextInput
            name='documentNumber'
            control={control}
            mask='cnpj'
            error={!!errors.documentNumber?.message}
            disabled={isUpdating}
          />
        </InputContainer>

        <InputContainer
          label={`${forms.foundation_date[currentLangKey]} *`}
          error={errors.foundingDate?.message}
        >
          <TextInput
            name='foundingDate'
            control={control}
            mask='date'
            error={!!errors.foundingDate?.message}
          />
        </InputContainer>

        <InputContainer
          label={`${forms.main_activity[currentLangKey]} *`}
          error={errors.mainActivity?.message}
        >
          <TextInput
            name='mainActivity'
            control={control}
            error={!!errors.mainActivity?.message}
          />
        </InputContainer>
      </MultipleInputs>

      <MultipleInputs>
        <InputContainer
          label={`${forms.email[currentLangKey]} *`}
          error={errors.email?.message}
        >
          <TextInput
            name='email'
            control={control}
            type='email'
            error={!!errors.email?.message}
          />
        </InputContainer>

        <InputContainer
          label={`${forms.phone[currentLangKey]} *`}
          error={errors.phoneNumber?.message}
        >
          <TextInput
            name='phoneNumber'
            control={control}
            mask='phone'
            error={!!errors.phoneNumber?.message}
          />
        </InputContainer>

        <InputContainer
          label={forms.website[currentLangKey]}
          error={errors.website?.message}
        >
          <TextInput
            name='website'
            control={control}
            error={!!errors.website?.message}
          />
        </InputContainer>
      </MultipleInputs>

      <MultipleInputs>
        <InputContainer
          label={`${forms.address.zip_code[currentLangKey]} *`}
          error={errors.postalCode?.message}
        >
          <TextInput
            name='postalCode'
            control={control}
            mask='cep'
            error={!!errors.postalCode?.message}
          />
        </InputContainer>

        <InputContainer
          label={`${forms.address.neighborhood[currentLangKey]} *`}
          error={errors.neighborhood?.message}
        >
          <TextInput
            name='neighborhood'
            maxLength={30}
            mask='letters'
            control={control}
            error={!!errors.neighborhood?.message}
            disabled={isFetchingAddress}
          />
        </InputContainer>

        <InputContainer
          label={`${forms.address.street[currentLangKey]} *`}
          error={errors.street?.message}
        >
          <TextInput
            name='street'
            mask='letters'
            control={control}
            error={!!errors.street?.message}
            disabled={isFetchingAddress}
          />
        </InputContainer>
      </MultipleInputs>

      <MultipleInputs>
        <InputContainer label={`${forms.address.country[currentLangKey]} *`}>
          <Select
            hasArrowDownIndicator
            options={countries}
            control={control}
            name='country'
            error={errors.country?.message}
            disabled={isFetchingAddress}
          />
        </InputContainer>

        <InputContainer label={`${forms.address.state[currentLangKey]} *`}>
          <Select
            hasArrowDownIndicator
            options={states}
            control={control}
            name='state'
            error={errors.state?.message}
            disabled={isFetchingAddress}
          />
        </InputContainer>

        <InputContainer
          label={`${forms.address.city[currentLangKey]} *`}
          error={errors.city?.message}
        >
          <TextInput
            name='city'
            mask='letters'
            control={control}
            error={!!errors.city?.message}
            disabled={isFetchingAddress}
          />
        </InputContainer>
      </MultipleInputs>

      <MultipleInputs>
        <InputContainer
          label={`${forms.address.number[currentLangKey]}*`}
          error={errors.number?.message}
        >
          <TextInput
            name='number'
            control={control}
            error={!!errors.number?.message}
          />
        </InputContainer>

        <InputContainer
          label={forms.address.complement[currentLangKey]}
          error={errors.complement?.message}
        >
          <TextInput
            name='complement'
            mask='alphanumeric'
            control={control}
            error={!!errors.complement?.message}
            disabled={isFetchingAddress}
          />
        </InputContainer>
      </MultipleInputs>
    </>
  );
}
