import {
  type IPersistenceRequestBalance,
  type IRequestBalance
} from 'data/modules/cards/balance';

class RequestBalanceMapper {
  toDomain(data: IPersistenceRequestBalance): IRequestBalance {
    return {
      id: data.id,
      balanceId: data.balance_id,
      requestedAmount: data.requested_amount,
      status: data.status,
      userId: data.user_id,
      userName: data.user_name
    };
  }
}

export default new RequestBalanceMapper();
