import { Select } from 'ds';

import { useLangContext } from 'data/contexts';

import { useSelectDynamicCostsCenters } from './useSelectDynamicCostsCenters';

import { type ISelectDynamicCostsCentersProps } from './SelectDynamicCostsCenters.types';

export function SelectDynamicCostsCenters({
  customLabel,
  disabled
}: ISelectDynamicCostsCentersProps): JSX.Element {
  const { currentLangKey, lang } = useLangContext();

  const {
    handleChangeSearchValue,
    handleMenuScrollToBottom,
    isLoadingMoreOptions,
    options,
    search
  } = useSelectDynamicCostsCenters();

  return (
    <Select.DynamicControlled
      small
      name='costsCenter'
      menuPlacement='auto'
      hasArrowDownIndicator
      disabled={disabled}
      isLoading={false}
      options={options}
      placeholder={customLabel ?? lang.global.costs_center[currentLangKey]}
      label={customLabel ?? lang.global.costs_center[currentLangKey]}
      isLoadingMoreOptions={isLoadingMoreOptions}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      searchValue={search}
      onChangeSearchValue={handleChangeSearchValue}
    />
  );
}
