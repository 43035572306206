import { SecondaryButton } from 'ds/components/Buttons';
import { useDsGa4 } from 'ds/hooks/globals';
import { DSIcons } from 'ds/icons';

import { alertProps } from './constants/alertProps.constant';
import { useAlert } from './useAlert';

import { type IAlertProps } from './Alert.types';

import { Container, Text, Title } from './Alert.styles';

export function Alert({
  title,
  type,
  children,
  outlined,
  close,
  onClickAction,
  actionText,
  ...rest
}: IAlertProps): JSX.Element | null {
  const { sendDsGaEvent } = useDsGa4();

  const Icon = alertProps[type].icon;
  const { closeAlert, isVisible } = useAlert();

  if (!isVisible) {
    return null;
  }

  return (
    <Container
      $title={title}
      $type={type}
      $outlined={outlined}
      {...rest}
    >
      <div>
        {title && (
          <Title>
            <Icon />
            {title}
          </Title>
        )}

        {children && (
          <Text>
            {!title && <Icon />}

            {children}
          </Text>
        )}
      </div>

      {close && (
        <DSIcons.CloseIcon
          onClick={() => {
            sendDsGaEvent('components', 'alert', {
              description: 'Alertas com click no X',
              eventAction: 'close_alert',
              eventLabel: typeof children === 'string' ? children : 'alert'
            });

            closeAlert();
          }}
        />
      )}

      {onClickAction && actionText && (
        <SecondaryButton
          size='small'
          onClick={() => {
            sendDsGaEvent('components', 'alert', {
              description: 'Alertas com ação',
              eventAction: 'action_alert',
              eventLabel: actionText
            });

            onClickAction();
          }}
        >
          {actionText}
        </SecondaryButton>
      )}
    </Container>
  );
}
