import { format } from 'date-fns';

import {
  type ICardGroupsDistributedValue,
  type IPersistenceCardGroupsDistributedValue
} from 'data/modules/cards/cardGroups';

class GetCardGroupsDistributedValueMapper {
  toDomain(
    persistenceData: IPersistenceCardGroupsDistributedValue
  ): ICardGroupsDistributedValue {
    return {
      amount: persistenceData.amount,
      lastUpdateDate: format(new Date(persistenceData.date), 'dd/MM/yyyy'),
      lastUpdateTime: format(new Date(persistenceData.date), "HH'h'mm'")
    };
  }
}

export default new GetCardGroupsDistributedValueMapper();
