import { tokens } from 'ds';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 45rem;
  gap: 1.4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: row wrap;
    max-width: 100%;
  }
`;

export const CardsContent = styled.div`
  display: flex;
  justify-content: center;
  height: 18rem;

  padding: ${tokens.spacingXxs};
  gap: ${tokens.spacingXs};
  background: ${tokens.neutralColorHighLight};
  border-radius: ${tokens.borderRadiusMd};

  & > div:first-child {
    width: 32.5rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    height: auto;

    & > div:first-child {
      width: 100%;
    }
  }
`;
