import { useState } from 'react';

import { type FieldValues, type Path, useController } from 'react-hook-form';

import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { DsSwitch } from 'presentation/ds/DsSwitch';
import {
  OnboardingText,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { type ICardsFunctionOptionProps } from './CardsFunctionOption.types';

import {
  Container,
  Content,
  ContentWrapper,
  DescriptionContainer,
  DetailsContainer,
  IconContainer,
  InputContainer,
  MoreDetailsButton
} from './CardsFunctionOption.styles';

export function CardsFunctionOption<T extends FieldValues>({
  icon,
  title,
  description,
  details,
  name,
  control,
  error
}: ICardsFunctionOptionProps<T>): JSX.Element {
  const [isMoreDetailsOpened, setIsMoreDetailsOpened] = useState(false);

  const [global, currentLangKey] = useLangContext(state => [
    state.lang.global,
    state.currentLangKey
  ]);

  const { field } = useController({
    control,
    name: name as Path<T>
  });

  return (
    <Container>
      <IconContainer>
        <BaseIcon
          name={icon}
          size={4}
          color='white'
        />
      </IconContainer>

      <ContentWrapper>
        <Content>
          <DescriptionContainer>
            <OnboardingTitle>{title}</OnboardingTitle>

            <OnboardingText>{description}</OnboardingText>
          </DescriptionContainer>

          <InputContainer>
            <DsSwitch
              name={name}
              error={error}
              control={control}
              checked={field.value}
            />
          </InputContainer>
        </Content>

        {!isMoreDetailsOpened && (
          <MoreDetailsButton
            onClick={() => {
              setIsMoreDetailsOpened(true);
            }}
          >
            {global.more_details_no_icon[currentLangKey]}
          </MoreDetailsButton>
        )}

        {isMoreDetailsOpened && (
          <DetailsContainer>
            {details.map(detail => (
              <OnboardingText key={detail}>{detail}</OnboardingText>
            ))}
          </DetailsContainer>
        )}
      </ContentWrapper>
    </Container>
  );
}
