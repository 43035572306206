import { format } from 'date-fns';

import { useLangContext, useModalContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import {
  DatePicker,
  InputContainer,
  TextArea,
  TextInput
} from 'presentation/components/base/Input';
import { Modal } from 'presentation/components/base/Modal';
import { Select } from 'presentation/components/base/Select';

import { useRequestBalanceModal } from './useRequestBalanceModal';

import {
  Container,
  DatePrevisionInputWrapper,
  DatePrevisionLabelWrapper,
  IncrementButtons,
  InformationWrapper,
  InputsWrapper,
  JustifyInputWrapper,
  ModalHeaderOnFormSuccess
} from './RequestBalanceModal.styles';

export function RequestBalanceModal(): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);
  const { visibleModal } = useModalContext();

  const {
    handleSubmit,
    control,
    errors,
    getValues,
    isLoading,
    isSuccess,
    handleCloseModalOnClick,
    handleAddAmount
  } = useRequestBalanceModal();

  const modalHeaderOnFormSuccess = (
    <ModalHeaderOnFormSuccess>
      <h2>
        {
          lang.cards.statement.request_balance_modal.balance_request_successful[
            currentLangKey
          ]
        }
      </h2>

      <BaseIcon
        name='check'
        color='success'
        size={2}
      />
    </ModalHeaderOnFormSuccess>
  );

  const modalForm = (
    <>
      <p>
        {
          lang.cards.statement.request_balance_modal.enter_wanted_amount[
            currentLangKey
          ]
        }
      </p>
      <DatePrevisionInputWrapper>
        <DatePicker
          id='datePrevision'
          label={
            lang.cards.statement.request_balance_modal.usage_prediction[
              currentLangKey
            ]
          }
          name='datePrevision'
          control={control}
          dateFormat='dd/MM/yyyy'
          error={errors.datePrevision?.message as string}
        />
      </DatePrevisionInputWrapper>
      <InputsWrapper>
        {/* CAMPO MOEDA */}
        <Select
          name='currency'
          control={control}
          label={`${lang.global.currency[currentLangKey]}*`}
          options={[{ value: 'BRL', label: 'BRL' }]}
          error={errors.currency?.message as string}
          hasArrowDownIndicator
        />

        {/* CAMPO VALOR */}
        <InputContainer
          label={`${lang.forms.amount[currentLangKey]}*`}
          error={errors.amount?.message as string}
        >
          <TextInput
            name='amount'
            control={control}
            mask='currency'
            error={!!errors.amount?.message}
          />
        </InputContainer>

        {/* BOTÕES DE INCREMENTO */}
        <IncrementButtons>
          <button
            type='button'
            onClick={() => {
              handleAddAmount(100);
            }}
          >
            + R$100
          </button>
          <button
            type='button'
            onClick={() => {
              handleAddAmount(1000);
            }}
          >
            + R$1.000
          </button>
          <button
            type='button'
            onClick={() => {
              handleAddAmount(10000);
            }}
          >
            + R$10.000
          </button>
        </IncrementButtons>
      </InputsWrapper>

      {/* CAMPO JUSTIFICATIVA */}
      <JustifyInputWrapper>
        <InputContainer
          label={`${lang.cards.statement.request_balance_modal.justify[currentLangKey]}*`}
          error={errors.justify?.message as string}
        >
          <TextArea
            name='justify'
            control={control}
            error={!!errors.justify?.message}
            maxLength={255}
          />
        </InputContainer>
      </JustifyInputWrapper>
    </>
  );

  const modalContentOnFormSuccess = (
    <>
      <p>
        {
          lang.cards.statement.request_balance_modal.admin_notified[
            currentLangKey
          ]
        }
      </p>
      <InputsWrapper>
        {/* CAMPO MOEDA */}
        <InformationWrapper>
          <span>{lang.global.currency[currentLangKey]}</span>
          <p>{getValues('currency')}</p>
        </InformationWrapper>

        {/* CAMPO VALOR */}
        <InformationWrapper>
          <span>{lang.forms.amount[currentLangKey]}</span>
          <p>{getValues('amount')}</p>
        </InformationWrapper>
      </InputsWrapper>

      {/* CAMPO JUSTIFICATIVA */}
      <JustifyInputWrapper>
        <InformationWrapper>
          <span>
            {lang.cards.statement.request_balance_modal.justify[currentLangKey]}
          </span>
          <p>{getValues('justify')}</p>
        </InformationWrapper>
      </JustifyInputWrapper>

      {/* CAMPO PREVISÃO DE USO */}
      {getValues('datePrevision') && (
        <DatePrevisionLabelWrapper>
          <InformationWrapper>
            <span>
              {
                lang.cards.statement.request_balance_modal.usage_prediction[
                  currentLangKey
                ]
              }
            </span>
            <p>{format(getValues('datePrevision') || '', 'dd/MM/yyyy')}</p>
          </InformationWrapper>
        </DatePrevisionLabelWrapper>
      )}
    </>
  );

  const modalContent = isSuccess ? modalContentOnFormSuccess : modalForm;

  return (
    <Modal
      isOpen={visibleModal === 'requestBalance'}
      onClose={handleCloseModalOnClick}
      isLoading={isLoading}
      footerMessage={
        isSuccess
          ? undefined
          : lang.cards.statement.request_balance_modal.admin_will_be_notified[
              currentLangKey
            ]
      }
      title={
        isSuccess
          ? modalHeaderOnFormSuccess
          : lang.cards.statement.request_balance_modal.request[currentLangKey]
      }
      onSubmit={handleSubmit}
      primaryButton={
        isSuccess
          ? undefined
          : {
              children:
                lang.cards.statement.request_balance_modal.request_balance[
                  currentLangKey
                ],
              color: 'success',
              size: 'xl'
            }
      }
      secondaryButton={{
        children: isSuccess
          ? lang.global.close[currentLangKey]
          : lang.global.cancel[currentLangKey],
        color: 'carnation',
        size: 'xl',
        onClick: handleCloseModalOnClick
      }}
    >
      <Container>{modalContent}</Container>
    </Modal>
  );
}
