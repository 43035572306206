import { Autoplay, Keyboard, Mousewheel, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { DsGhostButton } from 'presentation/ds/DsButton';

import 'swiper/css';
import 'swiper/css/pagination';

import { CarrouselSlide } from '..';

import { useCarrouselContainer } from './useCarrouselContainer';

import { type ICarrouselContainerProps } from './CarrouselContainer.types';

import {
  Container,
  CustomNavigationWrapper
} from './CarrouselContainer.styles';

export function CarrouselContainer({
  slides,
  mousewheel = false,
  navigation = false,
  variant = 'default',
  ...props
}: ICarrouselContainerProps): JSX.Element {
  const { handleSlideToNext, handleSlideToPrev, sliderRef } =
    useCarrouselContainer();

  return (
    <Container $variant={variant}>
      <Swiper
        loop
        keyboard
        ref={sliderRef}
        mousewheel={mousewheel}
        className='custom-swiper'
        modules={[Autoplay, Keyboard, Mousewheel, Pagination]}
        pagination={{
          clickable: true
        }}
        autoplay={{
          delay: 10000
        }}
        {...props}
      >
        {slides.map(({ key, ...slide }) => (
          <SwiperSlide key={key}>
            <CarrouselSlide {...slide} />
          </SwiperSlide>
        ))}
      </Swiper>

      {navigation && (
        <CustomNavigationWrapper $variant={variant}>
          <DsGhostButton
            className='prev-arrow'
            onClick={handleSlideToPrev}
            iconProps={{
              name: 'arrow-left-s',
              type: 'line',
              size: 6.4
            }}
          />

          <DsGhostButton
            className='next-arrow'
            onClick={handleSlideToNext}
            iconProps={{
              name: 'arrow-right-s',
              type: 'line',
              size: 6.4
            }}
          />
        </CustomNavigationWrapper>
      )}
    </Container>
  );
}
