import { MultiSelect, Select, TextInput } from 'ds';
import { DatePicker } from 'ds/components/Input/DatePicker';
import { useTranslation } from 'react-i18next';

import { useAuthContext, useLangContext } from 'data/contexts';
import { TeamMemberType } from 'data/modules/teamMembers';

import { PasswordRequirements } from 'presentation/components/global/PasswordRequirements';

import { useTeamMemberForm } from './useTeamMemberForm';

import {
  Container,
  FieldNotice,
  FieldWrapper,
  FormSection,
  FormSectionTitle,
  TwoColumnFormSection,
  TwoFieldRow
} from './TeamMemberForm.styles';

export function TeamMemberForm(): JSX.Element {
  const { t } = useTranslation(['settings', 'global']);
  const currentLangKey = useLangContext(state => state.currentLangKey);

  const company = useAuthContext(state => state.company);

  const {
    type,
    projectOptions,
    optionsToRender,
    positionOptions,
    costCenterOptions,
    limitPolicyOptions,
    approvalFlowOptions,
    handleDeselectOption,
    handleSelectInputChange,
    isLoadingProjectOptions,
    isLoadingPositionOptions,
    isLoadingCostCenterOptions,
    isLoadingLimitPolicyOptions,
    isLoadingApprovalFlowOptions
  } = useTeamMemberForm();

  return (
    <Container>
      <FormSection>
        <FormSectionTitle>
          {t('teamMemberForm.sectionTitles.accessLevel')}
        </FormSectionTitle>

        <FieldWrapper>
          <Select.Controlled
            small
            name='type'
            hasArrowDownIndicator
            label={t('teamMemberForm.userType')}
            placeholder={t('teamMemberForm.userType')}
            noOptionsMessage={t('global:noMoreOptions')}
            options={[
              {
                label: t('userTypes.administrator'),
                value: TeamMemberType.ADMINISTRADOR
              },
              {
                label: t('userTypes.intermediate'),
                value: TeamMemberType.INTERMEDIARIO
              },
              {
                label: t('userTypes.normal'),
                value: TeamMemberType.USUARIO
              }
            ]}
          />

          {type === TeamMemberType.ADMINISTRADOR && (
            <FieldNotice>{t('teamMemberForm.adminUserAccess')}</FieldNotice>
          )}
        </FieldWrapper>
      </FormSection>

      <TwoColumnFormSection>
        <FormSection>
          <FormSectionTitle>
            {t('teamMemberForm.sectionTitles.personalData')}
          </FormSectionTitle>

          <TextInput.Controlled
            small
            name='name'
            label={t('global:name')}
            placeholder={t('global:name')}
          />

          <TextInput.Controlled
            small
            mask='cpf-cnpj'
            name='document'
            label='CPF/CNPJ'
            autoComplete='off'
            placeholder='CPF/CNPJ'
          />

          <TwoFieldRow>
            <TextInput.Controlled
              small
              mask='phone'
              name='phone'
              label={t('teamMemberForm.mainPhone')}
              placeholder={t('teamMemberForm.mainPhone')}
            />

            <TextInput.Controlled
              small
              mask='phone'
              name='altPhone'
              label={t('teamMemberForm.additionalPhone')}
              placeholder={t('teamMemberForm.additionalPhone')}
            />
          </TwoFieldRow>

          <DatePicker.Controlled
            variant='small'
            name='birthDate'
            currentLangKey={currentLangKey}
            label={t('teamMemberForm.dateOfBirth')}
            placeholder={t('teamMemberForm.dateOfBirth')}
          />
        </FormSection>

        <FormSection>
          <FormSectionTitle>
            {t('teamMemberForm.sectionTitles.bankDetails')}
          </FormSectionTitle>

          <TextInput.Controlled
            small
            name='bank'
            label={t('teamMemberForm.bank')}
            placeholder={t('teamMemberForm.bank')}
          />

          <TwoFieldRow>
            <TextInput.Controlled
              small
              name='agency'
              autoComplete='off'
              label={t('teamMemberForm.agency')}
              placeholder={t('teamMemberForm.agency')}
            />

            <TextInput.Controlled
              small
              name='account'
              autoComplete='off'
              label={t('teamMemberForm.account')}
              placeholder={t('teamMemberForm.account')}
            />
          </TwoFieldRow>

          <TextInput.Controlled
            small
            name='pixKey'
            autoComplete='off'
            label={t('teamMemberForm.pixKey')}
            placeholder={t('teamMemberForm.pixKey')}
          />
        </FormSection>
      </TwoColumnFormSection>

      <FormSection>
        <FormSectionTitle>
          {t('teamMemberForm.sectionTitles.accessData')}
        </FormSectionTitle>

        <FieldWrapper>
          <TextInput.Controlled
            small
            name='email'
            label={t('global:email')}
            placeholder={t('global:email')}
          />

          <FieldNotice>
            {t('teamMemberForm.passwordResetRequestSent')}
          </FieldNotice>
        </FieldWrapper>

        {!company?.parameters.blockChangePasswordTeamMember && (
          <>
            <TwoFieldRow>
              <TextInput.Controlled
                small
                name='password'
                type='password'
                label={t('global:password')}
                placeholder={t('global:password')}
              />

              <TextInput.Controlled
                small
                type='password'
                name='passwordConfirm'
                label={t('global:confirmPassword')}
                placeholder={t('global:confirmPassword')}
              />
            </TwoFieldRow>

            <PasswordRequirements />
          </>
        )}
      </FormSection>

      <FormSection>
        <FormSectionTitle>
          {t('teamMemberForm.sectionTitles.companyLinks')}
        </FormSectionTitle>
        <TwoColumnFormSection>
          <FormSection>
            <Select.Controlled
              small
              name='position'
              hasArrowDownIndicator
              options={positionOptions}
              label={t('global:position')}
              placeholder={t('global:position')}
              isLoading={isLoadingPositionOptions}
              noOptionsMessage={t('global:noMoreOptions')}
            />

            <Select.Controlled
              small
              name='expensePolicy'
              hasArrowDownIndicator
              options={limitPolicyOptions}
              isLoading={isLoadingLimitPolicyOptions}
              noOptionsMessage={t('global:noMoreOptions')}
              label={t('teamMemberForm.expenseLimitPolicy')}
              placeholder={t('teamMemberForm.expenseLimitPolicy')}
            />

            <MultiSelect.Controlled
              small
              name='costCenter'
              hasArrowDownIndicator
              selectionMode='checkbox'
              options={costCenterOptions}
              label={t('global:costCenter')}
              placeholder={t('global:costCenter')}
              isLoading={isLoadingCostCenterOptions}
              onDeselectOption={handleDeselectOption}
              onInputChange={handleSelectInputChange(60)}
              noOptionsMessage={t('global:noMoreOptions')}
              optionsToRender={optionsToRender?.costCenter}
            />
          </FormSection>

          <FormSection>
            <MultiSelect.Controlled
              small
              hasHighlight
              name='projects'
              options={projectOptions}
              selectionMode='checkbox'
              label={t('global:projects')}
              placeholder={t('global:projects')}
              isLoading={isLoadingProjectOptions}
              onDeselectOption={handleDeselectOption}
              optionsToRender={optionsToRender?.projects}
              onInputChange={handleSelectInputChange(60)}
              noOptionsMessage={t('global:noMoreOptions')}
            />

            <Select.Controlled
              small
              name='approvalFlow'
              hasArrowDownIndicator
              options={approvalFlowOptions}
              label={t('global:approvalFlow')}
              placeholder={t('global:approvalFlow')}
              isLoading={isLoadingApprovalFlowOptions}
              noOptionsMessage={t('global:noMoreOptions')}
            />
          </FormSection>
        </TwoColumnFormSection>
      </FormSection>
    </Container>
  );
}
