import { type BaseSyntheticEvent, useEffect, useState } from 'react';

import { useIsFetching } from '@tanstack/react-query';
import { toast } from 'ds/utils';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useLangContext, useModalContext } from 'data/contexts';
import { CostCentersQueryKeys } from 'data/modules/costsCenters';
import { CurrenciesQueryKeys } from 'data/modules/currencies';
import {
  ExpensesQueryKeys,
  type ICreateManualExpenseFirstStepFieldsForm,
  type IDetailedExpense,
  useCreateManualExpense
} from 'data/modules/expenses';
import { useGetExpenseDetails } from 'data/modules/expenses/useCases/get-expense-details/useGetExpenseDetails';
import { useChangeExpenseType } from 'data/modules/fuel';
import {
  PaymentMethodsQueryKeys,
  useGetPaymentMethods
} from 'data/modules/paymentMethods';
import {
  type ReportInformationType,
  useGetOpenReports
} from 'data/modules/reports';

import { useManualExpenseModalTour } from 'presentation/pages/expenses/ExpensesList/components/Modal/CreateManualExpenseModal/tours';

import { routesPathPrefix } from 'shared/constants/global';
import { Currency } from 'shared/utils/format';
import { FreddySurvey } from 'shared/utils/global';

import {
  type IShowExpenseOutOfPolicyParamsModalState,
  type IUseCreateManualExpenseModal,
  type IUseCreateManualExpenseModalParams,
  type ReadOnlyFieldsWhenUserIsUpdatingExpenseStateType,
  type StepType
} from './CreateManualExpenseModal.types';

export function useCreateManualExpenseModal({
  expenseUuidToUpdate
}: IUseCreateManualExpenseModalParams): IUseCreateManualExpenseModal {
  const [step, setStep] = useState<StepType>('defaultFields');

  const { handleCloseModal } = useModalContext();

  const [showGoBackModal, setShowGoBackModal] = useState(false);

  const [showSeparateReportWarningModal, setShowSeparateReportWarningModal] =
    useState(false);

  const [showChangeExpenseTypeAlert, setShowChangeExpenseTypeAlert] =
    useState(false);

  const [showCreateReportForm, setShowCreateReportForm] = useState(false);

  const { handleOpenModal, handleChangeErrorMessage } = useModalContext();

  const { lang, currentLangKey } = useLangContext();

  const { t } = useTranslation('expenses');

  const { t: tFuel } = useTranslation('fuel');

  const [
    showExpenseOutOfPolicyParamsModal,
    setShowExpenseOutOfPolicyParamsModal
  ] = useState<IShowExpenseOutOfPolicyParamsModalState | undefined>(undefined);

  const { paymentMethods } = useGetPaymentMethods();

  const [
    confirmedReportInformationBySelectedReport,
    setConfirmedReportInformationBySelectedReport
  ] = useState<ReportInformationType | null>(null);

  // o estado controla se os campos de câmbio serão exibidos ou não
  const [toggleShowHideExchangeFields, setToggleShowHideExchangeFields] =
    useState(false);

  function handleUpdateExpenseDataBySelectedReport(
    data: ReportInformationType | null
  ): void {
    setConfirmedReportInformationBySelectedReport(data);
  }

  const userAction = expenseUuidToUpdate ? 'update' : 'create';

  const [
    readOnlyFieldsWhenUserIsUpdatingExpense,
    setReadOnlyFieldsWhenUserIsUpdatingExpense
  ] = useState<ReadOnlyFieldsWhenUserIsUpdatingExpenseStateType>(new Set());

  const {
    firstStepForm,
    isLoadingExpenseParameters,
    secondStepForm,
    isCreatingManualExpense,
    isUpdatingManualExpense,
    allowUserChangeExpenseProjectByReportProject,
    expenseParameters,
    handleCreateManualExpenseAfterConfirmationOutOfPolicy,
    handleUpdateManualExpenseAfterConfirmationOutOfPolicy
  } = useCreateManualExpense({
    onAfterSubmitFirstStep: () => {
      setStep('selectReport');
    },
    onSuccessCreateManualExpense: () => {
      handleCloseModal();
      toast.success(
        lang.expenses.modal.create_manual_expense
          .manual_expense_added_successfully[currentLangKey]
      );

      FreddySurvey.show();
    },
    onSuccessUpdateManualExpense: () => {
      handleCloseModal();
      toast.success(t('messages.expenseUpdatedSuccessfully'));
    },
    onPolicyErrorCreateManualExpense: (
      responseData,
      expenseTypeId,
      hasUserFilledInTheObservationField
    ) => {
      showSeparateReportWarningModal &&
        setShowSeparateReportWarningModal(false);

      setShowExpenseOutOfPolicyParamsModal({
        show: true,
        expenseTypeId,
        errorsFromApi: responseData,
        hasUserFilledInTheObservationField
      });
    },
    onErrorCreateOrUpdateManualExpense: (message: string) => {
      handleChangeErrorMessage({
        title: expenseUuidToUpdate
          ? t('modal.errorWhenUpdatingExpense')
          : t('modal.errorWhenCreatingExpense'),
        message
      });
    },
    onErrorUpdateManualExpense: (
      responseData,
      expenseTypeId,
      hasUserFilledInTheObservationField
    ) => {
      showSeparateReportWarningModal &&
        setShowSeparateReportWarningModal(false);

      setShowExpenseOutOfPolicyParamsModal({
        show: true,
        expenseTypeId,
        errorsFromApi: responseData,
        hasUserFilledInTheObservationField
      });
    },
    confirmedReportInformationBySelectedReport,
    expenseUuidToUpdate
  });

  const { getEnsuredExpenseDetails, isFetchingAndPendingExpenseDetails } =
    useGetExpenseDetails({
      expenseUuid: expenseUuidToUpdate ?? '',
      enabled: false
    });

  function applyReadOnlyFieldsWhenUserIsUpdatingExpense(
    expenseToUpdate: IDetailedExpense
  ): void {
    if (userAction === 'create') {
      return;
    }

    const readOnlyFields: ReadOnlyFieldsWhenUserIsUpdatingExpenseStateType =
      new Set();

    if (expenseToUpdate.isFromCards) {
      readOnlyFields.add('description');
      readOnlyFields.add('exchangeRate');
      readOnlyFields.add('paymentMethod');
      readOnlyFields.add('date');
      readOnlyFields.add('currency');
    }

    if (expenseToUpdate.conciliationBalance !== null) {
      readOnlyFields.add('value');
      readOnlyFields.add('currency');
      readOnlyFields.add('paymentMethod');
    }

    if (
      expenseToUpdate.isImported &&
      expenseParameters?.allowPaymentMethodChangeForImportedExpenses === false
    ) {
      readOnlyFields.add('paymentMethod');
    }

    if (
      expenseParameters?.companyApprovalType === 'CC' &&
      expenseToUpdate.report !== null
    ) {
      readOnlyFields.add('costsCenter');
    }

    setReadOnlyFieldsWhenUserIsUpdatingExpense(readOnlyFields);
  }

  async function loadExpenseAndPrePopulateFieldsFromUpdate(): Promise<void> {
    if (expenseUuidToUpdate === undefined) {
      return;
    }

    const expenseToUpdate = await getEnsuredExpenseDetails({
      expenseUuid: expenseUuidToUpdate
    });

    const isExchangeRateVisible =
      (!!expenseToUpdate?.exchangeRate && !expenseToUpdate.isFromCards) ||
      (!!expenseToUpdate?.exchangeRate &&
        expenseToUpdate?.isFromCards &&
        expenseToUpdate?.transaction?.isInternational);

    const valuesFromExpenseToUpdate:
      | ICreateManualExpenseFirstStepFieldsForm
      | undefined =
      expenseToUpdate !== undefined
        ? {
            description: expenseToUpdate.title,
            currency: expenseToUpdate.currency.id,
            value: Currency.format('BRL', expenseToUpdate.value),
            exchangeRate:
              isExchangeRateVisible && expenseToUpdate.exchangeRate !== null
                ? Currency.format('BRL', expenseToUpdate.exchangeRate, false, 5)
                : undefined,
            convertedValue:
              isExchangeRateVisible && expenseToUpdate.convertedValue !== null
                ? Currency.format('BRL', expenseToUpdate.convertedValue)
                : undefined,
            date: expenseToUpdate.date,
            type: expenseToUpdate.type ? expenseToUpdate.type.id : '',
            costsCenter:
              expenseToUpdate.costsCenter !== null
                ? {
                    label: expenseToUpdate.costsCenter?.name,
                    value: expenseToUpdate.costsCenter?.id
                  }
                : undefined,
            paymentMethod: expenseToUpdate.paymentMethod?.id ?? '',
            refundable: expenseToUpdate.isReimbursable,
            observation: expenseToUpdate.observation ?? '',
            observationAttachments:
              expenseToUpdate.observationAttachments !== null
                ? expenseToUpdate.observationAttachments
                : undefined,
            receipt: expenseToUpdate?.receipt?.url ?? null,
            apportionment:
              expenseToUpdate.apportionments?.map(item => ({
                percentage: item.percentage,
                project: {
                  label: item.project.name,
                  value: item.project.id
                },
                value: item.value
              })) ?? []
          }
        : undefined;

    if (isExchangeRateVisible) {
      setToggleShowHideExchangeFields(true);
    }

    firstStepForm.methods.reset(valuesFromExpenseToUpdate);
    secondStepForm.methods.reset({
      report: expenseToUpdate.report?.uuid ?? ''
    });

    applyReadOnlyFieldsWhenUserIsUpdatingExpense(expenseToUpdate);
  }

  useEffect(() => {
    if (expenseUuidToUpdate !== undefined && !isLoadingExpenseParameters) {
      loadExpenseAndPrePopulateFieldsFromUpdate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseUuidToUpdate, isLoadingExpenseParameters]);

  const isValidatingDateLimitsByProject =
    useIsFetching({
      predicate: query => {
        return (
          query.queryKey[0] ===
            ExpensesQueryKeys.VALIDATE_DATE_LIMITS_BY_PROJECT &&
          query.state.status === 'pending'
        );
      }
    }) > 0;

  const isLoadingSomeData =
    useIsFetching({
      predicate: query => {
        return (
          (query.queryKey[0] === ExpensesQueryKeys.GET_TYPES_OF_EXPENSES ||
            query.queryKey[0] === PaymentMethodsQueryKeys.GET_PAYMENT_METHODS ||
            query.queryKey[0] === CostCentersQueryKeys.GET_COST_CENTERS ||
            query.queryKey[0] === CurrenciesQueryKeys.GET_CURRENCIES) &&
          query.state.status === 'pending'
        );
      }
    }) > 0 ||
    isLoadingExpenseParameters ||
    isValidatingDateLimitsByProject ||
    isFetchingAndPendingExpenseDetails;

  const { openReports } = useGetOpenReports();

  const selectedReport = useWatch({
    control: secondStepForm.methods.control,
    name: 'report'
  });

  const selectedReportCurrency = openReports?.find(
    ({ uuid }) => uuid === selectedReport
  )?.currency;

  const {
    methods: {
      formState: { isDirty, errors }
    }
  } = firstStepForm;

  const checkIfDateFieldHasError =
    errors.date !== undefined &&
    (errors.date.type === 'validated-by-project' ||
      errors.date.type === 'validated-by-user-policy');

  const checkIfValueFieldHasError =
    errors.value !== undefined && errors.value.type === 'max-value';

  const selectedExpenseCurrencyId = firstStepForm.selectedCurrencyId;

  const isExpenseCurrencyDifferentFromReportCurrency =
    selectedExpenseCurrencyId !== undefined &&
    selectedReportCurrency !== undefined &&
    selectedReportCurrency.id !== null &&
    String(selectedReportCurrency.id) !== selectedExpenseCurrencyId;

  const navigate = useNavigate();

  function handleClearSelection(): void {
    secondStepForm.methods.setValue('report', '');

    setConfirmedReportInformationBySelectedReport(null);

    // quando limpar seleção de relatório na edição de despesa, habilita novamente o campo de centro de custos
    userAction === 'update' &&
      setReadOnlyFieldsWhenUserIsUpdatingExpense(oldState => {
        const newState = new Set(oldState);

        newState.delete('costsCenter');

        return newState;
      });
  }

  async function handleFirstStepGoBackButton(): Promise<void> {
    if (isDirty) {
      setShowGoBackModal(true);
      return;
    }

    if (userAction === 'update') {
      const expenseToUpdate = await getEnsuredExpenseDetails({
        expenseUuid: expenseUuidToUpdate as string
      });

      navigate(`${routesPathPrefix.expenses}/${expenseToUpdate.id}`);

      handleOpenModal('viewExpensesModal');
      return;
    }

    handleOpenModal('selectExpenseToAdd');
  }

  async function handleConfirmGoBackModal(): Promise<void> {
    if (userAction === 'update') {
      const expenseToUpdate = await getEnsuredExpenseDetails({
        expenseUuid: expenseUuidToUpdate as string
      });

      navigate(`${routesPathPrefix.expenses}/${expenseToUpdate.id}`);

      handleOpenModal('viewExpensesModal');
      return;
    }

    handleOpenModal('selectExpenseToAdd');
  }

  function handleSubmitReportForm(e: BaseSyntheticEvent): void {
    e.preventDefault();

    if (!selectedReport && userAction === 'create') {
      setShowSeparateReportWarningModal(true);
      return;
    }

    secondStepForm.handleSubmit();
  }

  function updateFormAfterReportCreated(reportUuid: string): void {
    setShowCreateReportForm(false);
    setStep('selectReport');
    secondStepForm.methods.setValue('report', reportUuid);
  }

  function handleGoBackButton(): void {
    step === 'defaultFields' && handleFirstStepGoBackButton();

    if (step === 'selectReport') {
      setStep('defaultFields');

      if (isExpenseCurrencyDifferentFromReportCurrency) {
        firstStepForm.methods.setError('currency', {
          type: 'custom',
          message: lang.expenses.modal.different_currency_error[currentLangKey]
        });
      }
    }
  }

  function handleClickOverlay(): void {
    if (isDirty) {
      setShowGoBackModal(true);
      return;
    }

    handleCloseModal();
  }

  function validateExpenseCurrencyAndReportCurrency(
    expenseCurrencyId: string,
    reportCurrencyId: string
  ): void {
    if (expenseCurrencyId === reportCurrencyId) {
      firstStepForm.methods.clearErrors('currency');
      return;
    }

    firstStepForm.methods.setError('currency', {
      type: 'custom',
      message: lang.expenses.modal.different_currency_error[currentLangKey]
    });
  }

  function handleChangeFormExpenseCurrency(currencyId: string): void {
    firstStepForm.methods.setValue('currency', currencyId);

    // trata a validação de moeda diferente da moeda do relatório
    if (
      selectedReportCurrency !== undefined &&
      selectedReportCurrency.id !== null
    ) {
      const reportCurrencyId = String(selectedReportCurrency.id);

      validateExpenseCurrencyAndReportCurrency(currencyId, reportCurrencyId);
    }

    // o trecho de código abaixo trata a parte do câmbio
    if (expenseParameters?.usesExchange === 'NAO_UTILIZA') {
      return;
    }

    const defaultCurrencyFromCompany =
      expenseParameters?.defaultCurrency.company;

    if (
      defaultCurrencyFromCompany &&
      String(defaultCurrencyFromCompany) === currencyId
    ) {
      setToggleShowHideExchangeFields(false);
      firstStepForm.methods.setValue('exchangeRate', undefined);
      firstStepForm.methods.setValue('convertedValue', undefined);
      return;
    }

    if (expenseParameters?.usesExchange === 'SEMPRE_EXIGE') {
      setToggleShowHideExchangeFields(true);
      return;
    }

    if (expenseParameters?.usesExchange === 'FORMA_DE_PAGAMENTO') {
      const selectedPaymentMethodId =
        firstStepForm.methods.getValues('paymentMethod');

      const searchedPaymentMethod = paymentMethods?.find(
        paymentMethod => paymentMethod.id.toString() === selectedPaymentMethodId
      );

      const showHideExchangeFields =
        searchedPaymentMethod?.usesExchange ?? false;
      setToggleShowHideExchangeFields(showHideExchangeFields);

      if (!showHideExchangeFields) {
        firstStepForm.methods.setValue('exchangeRate', undefined);
        firstStepForm.methods.setValue('convertedValue', undefined);
      }
    }
  }

  useManualExpenseModalTour({
    step
  });

  function handleToggleShowHideExchangeFields(show: boolean): void {
    setToggleShowHideExchangeFields(show);

    if (!show) {
      firstStepForm.methods.setValue('exchangeRate', undefined);
      firstStepForm.methods.setValue('convertedValue', undefined);
    }
  }

  function handleCloseExpenseOutOfPolicyModal(): void {
    setShowExpenseOutOfPolicyParamsModal(oldState => {
      return oldState ? { ...oldState, show: false } : undefined;
    });
  }

  function handleSubmitExpenseOutOfPolicy(): void {
    if (userAction === 'create') {
      handleCreateManualExpenseAfterConfirmationOutOfPolicy();
      return;
    }

    handleUpdateManualExpenseAfterConfirmationOutOfPolicy();
  }

  const { changeExpenseType, isChangingExpenseType } = useChangeExpenseType({
    onErrorChangeExpenseType: () => {
      toast.error(tFuel('modal.errorExpenseTypeChanged'));
    },
    onSuccessChangeExpenseType: () => {
      toast.success(tFuel('modal.expenseTypeChanged'));
      handleOpenModal('createFuelExpense');
    }
  });

  function handleChangeExpenseType(): void {
    changeExpenseType({
      newType: 'fueling',
      uuid: expenseUuidToUpdate as string
    });
  }

  const firstStepSelectedCostsCenterId =
    firstStepForm.methods.getValues('costsCenter')?.value ?? '';

  const firstStepSelectedPaymentMethodId =
    firstStepForm.methods.getValues('paymentMethod');

  const apportionmentValue = firstStepForm.methods.getValues('apportionment');

  const firstStepSelectedProjectId =
    apportionmentValue !== undefined && apportionmentValue.length === 1
      ? apportionmentValue[0]?.project?.value ?? ''
      : '';

  const submitButtonDisabled =
    isLoadingSomeData ||
    (step === 'selectReport' && isExpenseCurrencyDifferentFromReportCurrency) ||
    checkIfDateFieldHasError ||
    checkIfValueFieldHasError;

  const isShowingModalLoading =
    isCreatingManualExpense || isUpdatingManualExpense || isChangingExpenseType;

  return {
    step,
    setStep,
    submitButtonDisabled,
    firstStepForm,
    secondStepForm,
    selectedReport,
    handleClearSelection,
    handleUpdateExpenseDataBySelectedReport,
    isShowingModalLoading,
    showGoBackModal,
    setShowGoBackModal,
    handleSubmitReportForm,
    showSeparateReportWarningModal,
    setShowSeparateReportWarningModal,
    setShowCreateReportForm,
    showCreateReportForm,
    updateFormAfterReportCreated,
    allowUserChangeExpenseProjectByReportProject,
    isExpenseCurrencyDifferentFromReportCurrency,
    handleGoBackButton,
    handleChangeFormExpenseCurrency,
    toggleShowHideExchangeFields,
    handleToggleShowHideExchangeFields,
    showExpenseOutOfPolicyParamsModal,
    handleCloseExpenseOutOfPolicyModal,
    confirmedReportInformationBySelectedReport,
    handleSubmitExpenseOutOfPolicy,
    userAction,
    readOnlyFieldsWhenUserIsUpdatingExpense,
    handleClickOverlay,
    firstStepSelectedCostsCenterId,
    firstStepSelectedPaymentMethodId,
    firstStepSelectedProjectId,
    handleConfirmGoBackModal,
    handleChangeExpenseType,
    showChangeExpenseTypeAlert,
    setShowChangeExpenseTypeAlert
  };
}
