import { api, env } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import { getPendenciesListByAccountBalanceIdMock } from 'data/mocks/cards/pendencies';
import {
  type IGetPendenciesListByAccountBalanceIdPayload,
  type IPendency,
  type IPersistencePendency
} from 'data/modules/cards/pendencies';
import { type IDefaultResponse } from 'data/modules/global';

import { GetPendenciesListByAccountBalanceIdMapper } from './mappers';

class PendenciesService {
  async getPendenciesListByAccountBalanceId({
    accountBalanceId
  }: IGetPendenciesListByAccountBalanceIdPayload): Promise<IPendency[]> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getPendenciesListByAccountBalanceIdMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistencePendency[] | null>
    >(`/pay/accounts/balances/${accountBalanceId}/pendencies`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data?.map(GetPendenciesListByAccountBalanceIdMapper.toDomain) ?? [];
  }
}

export default new PendenciesService();
