import { useCallback, useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useMovementsContext } from 'data/contexts';
import { useGetPendencies } from 'data/modules/cards/pendencies';
import {
  type IAccountStatement,
  type IAccountTransaction,
  type ICompanyStatement,
  type ITransaction,
  useGetAccountStatement,
  useGetCompanyStatement
} from 'data/modules/cards/statement';

import {
  useGetActiveBalanceIds,
  useGetUserLoggedAndCardData
} from 'shared/hooks/cards';
import { useDebounce } from 'shared/hooks/global';

import { type IUseMovementsTableBody } from './MovementsTableBody.types';

export function useMovementsTableBody(): IUseMovementsTableBody {
  const { userId, groupId } = useParams();
  const {
    activeAccountBalancesIds: activeAccountBalancesId,
    isFetchingActiveBalanceIds: isFetchingActiveBalanceId
  } = useGetActiveBalanceIds();

  const { userHasCard, isLoadingUserAccount } = useGetUserLoggedAndCardData({
    enabled: !!userId
  });

  const [
    movementsFilters,
    movementsSearch,
    dateFilterValidatingStatus,
    handleSetTransactionsToExport
  ] = useMovementsContext(
    useShallow(state => [
      state.movementsFilters,
      state.movementsSearch,
      state.dateFilterValidatingStatus,
      state.handleSetTransactionsToExport
    ])
  );

  const debouncedSearch = useDebounce(movementsSearch);
  const filters = movementsFilters;

  const isCompanyStatement =
    movementsFilters.accounts && !userId
      ? movementsFilters.accounts.includes('COMPANY')
      : false;

  const {
    companyStatement,
    isFetchingCompanyStatement,
    isErrorCompanyStatement
  } = useGetCompanyStatement({
    startDate: `${filters.startDate}`,
    endDate: `${filters.endDate}`,
    timezone: filters.timezone,
    enabled: isCompanyStatement && dateFilterValidatingStatus === 'OK',
    cardGroupId: groupId
  });

  const {
    accountsStatements,
    isFetchingAccountsStatements,
    isErrorAccountsStatements
  } = useGetAccountStatement({
    accountBalancesIds: userId
      ? activeAccountBalancesId
      : movementsFilters.accounts!,
    startDate: `${filters.startDate}`,
    endDate: `${filters.endDate}`,
    timezone: filters.timezone,
    enabled: !isCompanyStatement && dateFilterValidatingStatus === 'OK'
  });

  const { pendencies, isLoadingPendencies } = useGetPendencies({
    accountBalancesIds:
      movementsFilters.accounts?.filter(account => account !== 'COMPANY') ?? []
  });

  const isLoading =
    isFetchingCompanyStatement ||
    isFetchingAccountsStatements ||
    isFetchingActiveBalanceId ||
    isLoadingUserAccount;

  const isError = isErrorCompanyStatement || isErrorAccountsStatements;

  function getEntriesTotalValue(transactions: ITransaction[]): number {
    const entries = transactions.filter(
      transaction => transaction.debitCredit === 'CREDIT'
    );

    return entries.reduce((acc, transaction) => {
      return acc + transaction.amount;
    }, 0);
  }

  function getExitsTotalValue(transactions: ITransaction[]): number {
    const entries = transactions.filter(
      transaction => transaction.debitCredit === 'DEBIT'
    );

    const negativeValue = entries.reduce((acc, transaction) => {
      return acc + transaction.amount;
    }, 0);

    return Math.abs(negativeValue);
  }

  const filteredCompanyStatement = useMemo(() => {
    const filteredArray: ICompanyStatement[] = [];
    const finalArray: ICompanyStatement[] = [];

    companyStatement?.forEach(object =>
      filteredArray.push({
        ...object,
        items:
          filters.movementType === 'ALL'
            ? object.items
            : object.items.filter(
                item => item.debitCredit === filters.movementType
              )
      })
    );

    filteredArray?.forEach(object =>
      finalArray.push({
        ...object,
        items: object.items.filter(item =>
          item.paymentDescription
            ?.toLowerCase()
            .includes(debouncedSearch?.toLowerCase())
        )
      })
    );

    return finalArray;
  }, [companyStatement, debouncedSearch, filters.movementType]);

  const filteredAccountStatement = useMemo(() => {
    const filteredArray: IAccountStatement<IAccountTransaction>[] = [];
    const finalArray: IAccountStatement<IAccountTransaction>[] = [];

    accountsStatements?.forEach(object =>
      filteredArray.push({
        ...object,
        items:
          filters.movementType === 'ALL'
            ? object.items
            : object.items.filter(
                item => item.debitCredit === filters.movementType
              )
      })
    );

    filteredArray?.forEach(object =>
      finalArray.push({
        ...object,
        items: object.items.filter(item =>
          item.paymentDescription
            ?.toLowerCase()
            .includes(debouncedSearch?.toLowerCase())
        )
      })
    );

    return finalArray;
  }, [accountsStatements, debouncedSearch, filters.movementType]);

  const movements = isCompanyStatement
    ? filteredCompanyStatement
    : filteredAccountStatement;

  const setTransactionsToExport = useCallback(() => {
    const transactionsIds: string[] = [];

    movements.forEach(movement => {
      movement.items.forEach(item => transactionsIds.push(item.transactionId));
    });

    handleSetTransactionsToExport(transactionsIds);
  }, [handleSetTransactionsToExport, movements]);

  useEffect(() => {
    setTransactionsToExport();
  }, [setTransactionsToExport]);

  const isListEmpty =
    (movements.length === 0 ||
      movements.every(movement => movement.items.length === 0)) &&
    !isLoading &&
    !isError;

  const isEmptySearch =
    debouncedSearch.length > 0 &&
    movements.every(movement => movement.items.length === 0);

  const isUnlinkedCard = !!(userId && !userHasCard);

  const isEmpty =
    isListEmpty && !isError && !isEmptySearch && !isLoading && !isUnlinkedCard;

  const shouldShowContent =
    !isError && !isListEmpty && !isEmptySearch && !isLoading && !isUnlinkedCard;

  return {
    movements: movements || [],
    isLoading,
    isListEmpty,
    isError,
    pendencies,
    isLoadingPendencies,
    isEmpty,
    shouldShowContent,
    isUnlinkedCard,
    isEmptySearch:
      debouncedSearch.length > 0 &&
      movements.every(movement => movement.items.length === 0),
    accounts: movementsFilters.accounts,
    movementType: filters.movementType,
    getEntriesTotalValue,
    getExitsTotalValue
  };
}
