import { TextButton, tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid ${tokens.neutralColorHighDark};
`;

export const CardAndActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 31.1rem;
  max-height: 32rem;
  column-gap: 1.2rem;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-top: 1.6rem;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CustomTextButton = styled(TextButton)`
  > span {
    max-width: 25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
