import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type QuantityInputStylesType } from './QuantityInput.types';

export const Container = styled.div<AddPrefixToType<QuantityInputStylesType>>`
  width: 100%;
  height: 3.7rem;
  padding: 0 1.2rem;
  background: ${({ theme }) => theme.colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ $error, theme }) =>
    $error ? theme.colors.danger : theme.colors.gallery};
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;

    &:hover {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }

  input {
    width: 100%;
    margin: 0 0.5rem;
    color: ${({ theme }) => theme.colors.mineShaft};
    font-size: 1.3rem;
    text-align: center;
    border: 0;
  }

  ${({ $error }) =>
    !$error &&
    css`
      &:focus-within {
        box-shadow: 0 0 2px 1px ${({ theme }) => theme.colors.primary};
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background: ${({ theme }) => theme.colors.alabaster};
      cursor: not-allowed;

      > input {
        background: ${({ theme }) => theme.colors.alabaster};
        cursor: not-allowed;
      }
    `}
`;

export const IconButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
`;
