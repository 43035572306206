import { useQueryCache } from 'data/cache';
import {
  ExpensesQueryKeys,
  ExpensesService,
  type IUseGetExpensesParams,
  type IUseGetTypesOfExpenses
} from 'data/modules/expenses';

export function useGetTypesOfExpenses(
  params?: IUseGetExpensesParams
): IUseGetTypesOfExpenses {
  const { data: typesOfExpenses, isLoading: isLoadingTypesOfExpenses } =
    useQueryCache({
      queryKey: [ExpensesQueryKeys.GET_TYPES_OF_EXPENSES],
      queryFn: async () => await ExpensesService.getTypesOfExpenses()
    });

  return {
    typesOfExpenses: params?.withInactives
      ? typesOfExpenses
      : typesOfExpenses?.filter(item => item.active),
    isLoadingTypesOfExpenses
  };
}
