import { useQueries } from '@tanstack/react-query';

import {
  AccountQueryKeys,
  AccountService,
  type IUseGetAccountBalancesAmounts,
  type IUseGetAccountBalancesAmountsParams
} from 'data/modules/cards/account';

export function useGetAccountBalancesAmounts({
  accountBalancesIds,
  enabled = true
}: IUseGetAccountBalancesAmountsParams): IUseGetAccountBalancesAmounts {
  const queriesParams = accountBalancesIds.map(accountBalanceId => ({
    queryKey: [AccountQueryKeys.GET_ACCOUNT_BALANCE_AMOUNT, accountBalanceId],
    queryFn: async () =>
      await AccountService.getAccountBalanceAmount({ accountBalanceId }),
    enabled
  }));

  const results = useQueries({
    queries: [...queriesParams]
  });

  return {
    accountBalancesAmounts: results.map(result => result.data?.amount),
    isFetchingAccountBalancesAmounts: results.some(result => result.isFetching)
  };
}
