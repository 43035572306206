import { Link, useLocation } from 'react-router-dom';

import { vexDomain } from 'data/config';

import { BaseIcon } from 'presentation/components/base/Icon';
import { Tooltip } from 'presentation/components/base/Tooltip';

import { type ISettingsSidebarItemProps } from './SettingsSidebarItem.types';

import { Container } from './SettingsSidebarItem.styles';

export function SettingsSidebarItem({
  url,
  title,
  active,
  tooltip,
  warning,
  isReactRoute
}: ISettingsSidebarItemProps): JSX.Element {
  const { pathname } = useLocation();

  const CustomLink = isReactRoute ? Link : 'a';

  return (
    <Container $active={active ?? url === pathname}>
      <CustomLink
        to={url ?? ''}
        href={isReactRoute ? undefined : `${vexDomain}${url}`}
      >
        {title}

        {warning && (
          <BaseIcon
            size={1.8}
            type='fill'
            color='danger'
            name='error-warning'
          />
        )}

        {tooltip && (
          <Tooltip
            id={title}
            maxWidth={25}
            content={tooltip}
          >
            <BaseIcon
              size={1.8}
              type='fill'
              name='question'
              color='secondary'
            />
          </Tooltip>
        )}
      </CustomLink>
    </Container>
  );
}
