import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IRequestWithdrawalFieldsForm } from 'data/modules/cards/balance';

import { Currency } from 'shared/utils/format';

export function useRequestWithdrawalSchema(): ObjectSchema<IRequestWithdrawalFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const requestWithdrawalSchema: ObjectSchema<IRequestWithdrawalFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];
      const fieldMatchesMessage = lang.schemas.invalid_value[currentLangKey];

      return yup.object({
        amount: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'amountEqualsToZero',
            message: lang.cards.withdrawal.amount_equals_zero[currentLangKey],
            test: amount => Currency.parseToFloat(amount) > 0
          }),
        account: yup.string().required(fieldRequiredMessage),
        bank: yup.string().required(fieldRequiredMessage),
        agency: yup
          .string()
          .required(fieldRequiredMessage)
          .min(4, `${lang.schemas.min[currentLangKey]} 4`),
        documentNumber: yup
          .string()
          .required(fieldRequiredMessage)
          .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, fieldMatchesMessage),
        pixKey: yup.string(),
        reason: yup.string().max(255, `${lang.schemas.max[currentLangKey]} 255`)
      });
    }, [lang, currentLangKey]);

  return requestWithdrawalSchema;
}
