import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { type IChangeBusinessCardExpensePoliciesFieldsForm } from 'data/modules/cards/businessCard';

export function useChangeBusinessCardExpensePoliciesSchema(): ObjectSchema<IChangeBusinessCardExpensePoliciesFieldsForm> {
  const changeBusinessCardExpensePoliciesSchema: ObjectSchema<IChangeBusinessCardExpensePoliciesFieldsForm> =
    useMemo(() => {
      return yup.object({
        expensePolicyId: yup.number(),
        routePolicyId: yup.string()
      });
    }, []);

  return changeBusinessCardExpensePoliciesSchema;
}
