import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { type IAttachTeamMembersToBusinessCardFieldsForm } from 'data/modules/cards/businessCard';

export function useAttachTeamMembersToBusinessCardSchema(): ObjectSchema<IAttachTeamMembersToBusinessCardFieldsForm> {
  const attachTeamMembersToBusinessCardSchema: ObjectSchema<IAttachTeamMembersToBusinessCardFieldsForm> =
    useMemo(() => {
      return yup.object({
        teamMembers: yup.array().of(yup.string())
      });
    }, []);

  return attachTeamMembersToBusinessCardSchema;
}
