import styled from 'styled-components';

import { TableContainer } from 'presentation/components/global/Table/SimpleTable/SimpleTable.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex-direction: column;
  padding: 2.4rem 6.4rem;
  display: flex;

  > h1 {
    text-align: left;
    margin-bottom: 1rem;
  }

  > p {
    margin-bottom: 2.4rem;
  }

  ${TableContainer} {
    overflow: hidden;
    border-radius: 12px;
    border-collapse: separate;
    border: 1px solid ${({ theme }) => theme.tokens.neutralColorLowLight};

    thead tr {
      background-color: ${({ theme }) => theme.tokens.neutralColorHighDark};

      th {
        padding: 0.8rem;
        text-align: left;
        font-size: 1.4rem;
        text-transform: none;
        color: ${({ theme }) => theme.tokens.neutralColorLowDark};
        font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto};
        font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
      }
    }

    tbody td {
      padding: 0 0.8rem;
      border-top: 1px solid ${({ theme }) => theme.tokens.neutralColorLowLight};
      height: 7rem;
      font-size: 1.4rem;
      font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto};

      span:not(:first-child) {
        display: flex;
        align-items: center;
      }

      button {
        border: none !important;
        padding: 0;
      }

      p,
      strong {
        overflow: hidden;
        max-width: 18rem;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      strong {
        padding-left: 0.8rem;
        color: ${({ theme }) => theme.tokens.neutralColorLowDark};
        font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
      }

      svg {
        min-width: 2.4rem;

        &:first-child {
          margin-right: 0.8rem;
        }
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 2.4rem;

    ${TableContainer} {
      tbody tr {
        border: none;
        border-bottom: 1px solid
          ${({ theme }) => theme.tokens.neutralColorLowLight};

        td {
          padding: 0;
          height: 4rem;
          border: none;
          display: flex;

          > span {
            width: 50%;
            padding: 0 0.8rem;
          }

          p,
          strong {
            max-width: 14rem;
          }

          > span:first-child {
            display: flex;
            align-items: center;
            font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
            background-color: ${({ theme }) =>
              theme.tokens.neutralColorHighDark};
            border-right: 1px solid
              ${({ theme }) => theme.tokens.neutralColorLowLight};
          }
        }
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 2.4rem 0;

    ${TableContainer} {
      tbody tr td {
        p,
        strong {
          max-width: 9rem;
        }
      }
    }
  }
`;

export const EmptyPartnersTable = styled.tr`
  td {
    font-size: 1.8rem;
    font-style: italic;
    text-align: center;
    padding: 12rem 0 !important;
    color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
    font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto};
    font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
  }
`;

export const CustomLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighMediumest};
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
