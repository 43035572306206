import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useLangContext, useModalContext } from 'data/contexts';
import { AccountQueryKeys } from 'data/modules/cards/account';
import {
  BusinessCardsMutationKeys,
  BusinessCardsService,
  type IActivateCardsFieldsForm,
  type IAttachPasswordOnCardPayload,
  type IAttachTheCardPayload,
  type IUseActivateCard,
  type IUseActivateCardProps,
  type IValidatedPinCardPayload
} from 'data/modules/cards/businessCard';

import { useFormWithSchema } from 'shared/hooks/forms';

import { useActivateCardSchema } from './schemas';

export function useActivateCard({
  accountId
}: IUseActivateCardProps): IUseActivateCard {
  const { handleCloseModal } = useModalContext();
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const {
    control,
    setValue,
    handleSubmit,
    reset: formReset,
    clearErrors,
    formState: { errors },
    setError
  } = useFormWithSchema(useActivateCardSchema());

  const [openScan, setOpenScan] = useState(false);

  const queryClient = useQueryClient();

  const {
    mutate: attachPasswordOnCard,
    isLoading: loadingAttachPasswordOnCard
  } = useMutationCache({
    mutationKey: [BusinessCardsMutationKeys.LINK_PASSWORD_TO_CARD],
    mutationFn: async (payload: IAttachPasswordOnCardPayload) =>
      await BusinessCardsService.attachPasswordOnCard(payload),
    onSuccess: () => {
      toast.success(
        lang.settings.businessCards.the_card_has_been_successfully_activated[
          currentLangKey
        ]
      );
      queryClient.invalidateQueries({
        queryKey: [AccountQueryKeys.GET_COMPANY_ACCOUNTS]
      });
      formReset({
        digits: ''
      });
      handleCloseModal();
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  const { mutate: attachTheCard, isLoading: loadingAttachTheCard } =
    useMutationCache({
      mutationKey: [BusinessCardsMutationKeys.LINK_CARD],
      mutationFn: async (payload: IAttachTheCardPayload) =>
        await BusinessCardsService.attachTheCard(payload),
      onSuccess: (data, payload: IAttachTheCardPayload) => {
        attachPasswordOnCard(payload);
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  const { mutate: validatedPinCard, isLoading: loadingValidatedPinCard } =
    useMutationCache({
      mutationKey: [BusinessCardsMutationKeys.VALIDATE_PIN_CARD],
      mutationFn: async (payload: IValidatedPinCardPayload) =>
        await BusinessCardsService.validatedPinCard(payload),
      onSuccess: (data, payload: IValidatedPinCardPayload) => {
        if (data.isValid) {
          attachTheCard(payload);
          return;
        }

        toast.error(
          lang.settings.businessCards.pin_not_strong_enough[currentLangKey]
        );
        setError(
          'digits',
          {
            type: 'custom',
            message: lang.schemas.is_required[currentLangKey]
          },
          { shouldFocus: true }
        );
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  function getIdCardQrCode(id: string): void {
    setValue('cardId', id);

    clearErrors('cardId');

    setOpenScan(false);

    toast.success(
      lang.settings.businessCards.the_qr_code_has_been_read_successfully[
        currentLangKey
      ]
    );
  }

  function onSubmit(data: IActivateCardsFieldsForm): void {
    validatedPinCard({
      ...data,
      uuid: accountId
    });
  }

  return {
    control,
    errors,
    isLoading:
      loadingValidatedPinCard ||
      loadingAttachTheCard ||
      loadingAttachPasswordOnCard,
    handleSubmit: handleSubmit(onSubmit),
    clearErrors,
    reset: formReset,
    openScan,
    setOpenScan,
    getIdCardQrCode
  };
}
