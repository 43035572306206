import { type ISectionContainerProps } from './SectionContainer.types';

import { Container } from './SectionContainer.styles';

export function SectionContainer({
  children,
  ...rest
}: ISectionContainerProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
