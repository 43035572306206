import { Pagination, tokens } from 'ds';
import styled from 'styled-components';

import { type IPaginationContainerStyles } from './TablePagination.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  margin-top: -1.6rem;
`;

export const StyledPaginationContainer = styled(Pagination.Container)<
  AddPrefixToType<IPaginationContainerStyles>
>`
  flex-wrap: wrap;
  row-gap: 2rem;
  border-radius: ${({ $isCardsMode }) => $isCardsMode && tokens.borderRadiusMd};
  margin-top: ${({ $isCardsMode }) => $isCardsMode && '0.5rem'};

  svg {
    min-width: fit-content;
  }
`;

export const StyledControl = styled(Pagination.Control)`
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: fit-content;
    justify-content: center;
  }
`;
