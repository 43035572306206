import { ContentPlaceholder } from 'presentation/components/global/ContentPlaceholder';

import { type ICentralMessageProps } from './CentralMessage.types';

import { Container, Paragraph } from './CentralMessage.styles';

export function CentralMessage({
  description,
  iconName,
  title
}: ICentralMessageProps): JSX.Element {
  return (
    <Container>
      <ContentPlaceholder
        iconProps={{
          name: iconName,
          color: 'dustyGray',
          size: 5
        }}
        title={title}
      >
        <Paragraph>{description}</Paragraph>
      </ContentPlaceholder>
    </Container>
  );
}
