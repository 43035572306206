import { tokens } from 'ds';
import { styled } from 'styled-components';

import { Container as CardsContainer } from 'presentation/pages/analytics/Analysis/components/AnalysisItem/AnalysisItemCards/AnalysisItemCards.styles';
import { Container as SectionNameContainer } from 'presentation/pages/analytics/Analysis/components/AnalysisItem/AnalysisItemSectionName/AnalysisItemSectionName.styles';
import { ItemSection as ItemSectionContainer } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisItem/UserAnalysisItem.styles';

export const Container = styled.div`
  table {
    pointer-events: none;
  }
`;

export const CardsSkeleton = styled(CardsContainer)`
  span {
    flex: 1 0 20rem;
    height: 11.6rem;
    border-radius: ${tokens.borderRadiusMd};
  }
`;

export const SectionNameSkeleton = styled(SectionNameContainer)`
  span {
    background-color: rgb(255 255 255 / 50%);
    opacity: 0.5;
  }
`;

export const TableContainerSkeleton = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const ItemSectionSkeleton = styled(ItemSectionContainer)``;

export const ReportHeaderSkeleton = styled.div`
  display: flex;
  width: 100%;
  gap: ${tokens.spacingXs};
  justify-content: space-between;
  flex-direction: column;
`;

export const ReportTitleSkeleton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ReportContainerSkeleton = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: ${tokens.spacingXs};
`;

export const ReportContentSkeleton = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};

  span {
    width: 60%;

    &:last-of-type {
      width: 100%;
    }
  }
`;

export const ReportTotalSkeleton = styled.div`
  width: 20%;
  display: flex;
  height: min-content;
  align-items: center;
  gap: ${tokens.spacingNano};
  border-radius: ${tokens.borderRadiusMd};
  padding: ${tokens.spacingXs} ${tokens.spacingMd};
  border: 1px solid ${tokens.neutralColorHighDarkest};

  span {
    width: 60%;
  }
`;
