import { faker } from '@faker-js/faker';

import { type ICardGroup } from 'data/modules/cards/cardGroups';

import { MockApi } from 'shared/utils/global';

export async function getCardGroupMock(): Promise<ICardGroup> {
  await MockApi.sleep(2000);

  return {
    id: faker.string.uuid(),
    name: 'Comercial',
    balanceId: faker.string.uuid(),
    availableValue: undefined,
    isManager: false
  };
}
