import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type TextAreaDefaultStylesType } from './TextAreaDefault.types';
import { type AddPrefixToType } from 'ds/types';

export const Legend = styled.legend<AddPrefixToType<TextAreaDefaultStylesType>>`
  opacity: 0;
  visibility: hidden;
  padding: 0;
  overflow: hidden;
  max-width: 0.001rem;
  white-space: nowrap;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};

  span {
    opacity: 0;
    visibility: visible;
  }

  ${({ $hasLabel }) =>
    !$hasLabel &&
    css`
      padding: 0 !important;
    `}
`;

export const Fieldset = styled.fieldset`
  text-align: left;
  position: absolute;
  inset: -5px 0 0;
  pointer-events: none;
  overflow: hidden;
  min-width: 0%;
  padding: 0 ${tokens.spacingXs};
  border-radius: ${tokens.borderRadiusXs};
  border-color: ${tokens.neutralColorLowLight};
  border-width: ${tokens.borderWidthHairline};
  border-style: solid;
  top: -1rem;
  transition: border 100ms ease-in-out;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetNano};
  width: 100%;
  position: relative;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const TextareaContainer = styled.div<
  AddPrefixToType<TextAreaDefaultStylesType>
>`
  position: relative;
  transition: border 100ms ease-in-out;
  background-color: ${tokens.neutralColorHighPure};
  padding: ${tokens.spacingXxs} ${tokens.spacingXs};
  min-width: 25.2rem;
  width: 100%;

  ${({ $small }) =>
    $small &&
    css`
      textarea {
        font-size: ${tokens.fontSizeXxss};
      }
    `};

  ${({ $large }) =>
    $large &&
    css`
      textarea {
        font-size: ${tokens.fontSizeXs};
      }
    `};

  &:focus-within {
    span {
      color: ${tokens.brandColorSecondaryPure};
      opacity: 1;
    }

    textarea {
      color: ${tokens.neutralColorLowDarkest};
    }

    ~ ${Fieldset} {
      border-color: ${tokens.brandColorSecondaryPure};
      border-width: ${tokens.borderWidthThin};

      ${Legend} {
        opacity: 1;
        visibility: visible;
        max-width: unset;
        overflow: visible;
        padding: 0 ${tokens.spacingQuarck};
      }
    }
  }

  ${({ $isTouched }) =>
    $isTouched &&
    css`
      ~ ${Fieldset} {
        border-color: ${tokens.neutralColorLowMedium};
        border-width: ${tokens.borderWidthHairline};

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      textarea {
        color: ${tokens.neutralColorLowDarkest};
      }

      span {
        opacity: 1;
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${tokens.neutralColorHighLight} !important;

      ~ ${Fieldset} {
        border-color: ${tokens.neutralColorLowLightest} !important;
        border-width: ${tokens.borderWidthHairline} !important;

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      * {
        color: ${tokens.neutralColorLowMediumLight} !important;
      }

      span {
        opacity: 1;
      }
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      ~ ${Fieldset} {
        border-color: ${tokens.feedbackColorErrorPure} !important;
        border-width: ${tokens.borderWidthThin} !important;

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      span {
        color: ${tokens.feedbackColorErrorPure} !important;
        opacity: 1;
      }
    `}
`;

export const TextAreaComponent = styled.textarea`
  width: 100%;
  min-height: 10rem;
  min-width: 100%;
  border: none !important;
  cursor: auto;
  outline: none !important;
  background-color: transparent !important;
  resize: none;
  box-sizing: border-box;
  color: ${tokens.neutralColorLowLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
  padding: ${tokens.spacingNano} 0;
  line-height: ${tokens.spacingInsetMd};
  font-size: ${tokens.fontSizeXxs};

  &::-webkit-scrollbar {
    width: 1.2rem !important;
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-track {
    border: 2px solid transparent !important;
    border-radius: ${tokens.borderRadiusXs};
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${tokens.borderRadiusXs};
    background-color: ${tokens.neutralColorLowLightest};
  }
`;

export const Label = styled.span`
  position: absolute;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  top: -1.5rem;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  padding: ${tokens.spacingQuarck};
`;

export const Caption = styled.span<AddPrefixToType<TextAreaDefaultStylesType>>`
  display: inline-block;
  color: ${tokens.neutralColorLowLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};

  ${({ $hasError }) =>
    $hasError &&
    css`
      color: ${tokens.feedbackColorErrorPure} !important;
    `}
`;
