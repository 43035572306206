import { type IDefaultTableHeadProps } from './DefaultTableHead.types';

import { Container } from './DefaultTableHead.styles';

export function DefaultTableHead({
  children,
  ...rest
}: IDefaultTableHeadProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
