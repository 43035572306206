import { Alert, MenuTabs } from 'ds';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuthContext } from 'data/contexts';

import { animations } from 'shared/constants/animations';

import { type ICardsPageHeaderProps } from './CardsPageHeader.types';

import {
  CardsInfoWrapper,
  Container,
  MenuTabItemCustom,
  TabsContainer
} from './CardsPageHeader.styles';

export function CardsPageHeader({
  tabs,
  children
}: ICardsPageHeaderProps): JSX.Element {
  const user = useAuthContext(state => state.user);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container>
      <TabsContainer>
        <MenuTabs.Container
          spaced
          hasTrack
        >
          {tabs.map(tab => {
            if (tab.hidden) {
              return null;
            }

            return (
              <MenuTabItemCustom
                key={tab.label}
                active={tab.active}
                onClick={tab.onClick}
                className={tab.className}
                tabPosition={tab.tabPosition}
              >
                {tab.label}
              </MenuTabItemCustom>
            );
          })}
        </MenuTabs.Container>
      </TabsContainer>

      {location.pathname !== '/cartoes/solicitacoes-de-valores' &&
        user?.cards?.mustHaveAccount &&
        user?.cards?.type === 'ACCOUNT_MANAGER' &&
        !user?.cards?.isOnboardingFinished &&
        user?.cards?.accountHolder?.kycStatus !== 'IN_ANALYSIS' && (
          <Alert
            type='warning'
            onClickAction={(): void => {
              navigate('/cartoes/onboarding/usuario');
            }}
            actionText={t('finishRegistration')}
          >
            {t('stillNeedToFinishRegistration')}
          </Alert>
        )}

      {children && (
        <CardsInfoWrapper {...animations.popFadeInOut}>
          {children}
        </CardsInfoWrapper>
      )}
    </Container>
  );
}
