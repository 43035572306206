import { Outlet } from 'react-router-dom';

import { useModalContext } from 'data/contexts';

import { RequestModal } from 'presentation/pages/store/components/RequestModal';
import { StatusPill } from 'presentation/pages/store/components/StatusPill';
import { DefaultLayout } from 'presentation/templates';

import { ProductHeaderBanner } from './components/General';
import { useProduct } from './useProduct';

import { Container, Toast } from './Product.styles';

export function Product(): JSX.Element {
  const { identifier, title, status, statusName, toastMessage } = useProduct();
  const { visibleModal, handleCloseModal } = useModalContext();

  return (
    <DefaultLayout
      pageNameAndBreadcrumbs={{
        pageName: title,
        breadcrumbs: [
          {
            name: 'VExpenses Store',
            pageLink: '/store'
          },
          {
            name: title
          }
        ],
        isClassicMode: false,
        isBackButtonVisible: true,
        isBreadcrumbsVisible: true,
        rightElement: <StatusPill status={status}>{statusName}</StatusPill>
      }}
    >
      <Container>
        {toastMessage && <Toast>{toastMessage}</Toast>}

        <ProductHeaderBanner
          identifier={identifier}
          status={status}
        />
        <Outlet />
      </Container>
      {visibleModal === 'storeRequestModal' && (
        <RequestModal
          identifier={identifier}
          onClose={handleCloseModal}
        />
      )}
    </DefaultLayout>
  );
}
