import { useMemo } from 'react';

import {
  useGetCostsCenters,
  useGetRelatedCostsCenters
} from 'data/modules/costsCenters';

import { type ISelectOption } from 'presentation/components/base/Select/Select.types';

import { TransformArray } from 'shared/utils/global';

import {
  type IRequestForSelectionFields,
  type IUseCostsCentersManageBusinessCard,
  type IUseCostsCentersManageBusinessCardParams
} from './useCostsCentersManageBusinessCard.types';

export function useCostsCentersManageBusinessCard({
  uuid
}: IUseCostsCentersManageBusinessCardParams): IUseCostsCentersManageBusinessCard {
  const payload = {
    page: 1,
    perPage: 500
  } as IRequestForSelectionFields;

  const { costsCenters, isLoadingCostsCenters } = useGetCostsCenters(payload);

  const { relatedCostsCenters, isLoadingRelatedCostsCenters } =
    useGetRelatedCostsCenters({
      uuid,
      ...payload
    });

  const relatedCostsCentersOptions: ISelectOption[] = useMemo(() => {
    return TransformArray.objectsToSelectOptions(
      relatedCostsCenters,
      'id',
      'name'
    );
  }, [relatedCostsCenters]);

  const costsCentersOptions: ISelectOption[] = useMemo(() => {
    const filterCostsCenters = costsCenters.filter(
      option =>
        !relatedCostsCenters.find(costsCenters => costsCenters.id === option.id)
    );

    return TransformArray.objectsToSelectOptions(
      filterCostsCenters,
      'id',
      'name'
    );
  }, [costsCenters, relatedCostsCenters]);

  return {
    costsCentersOptions,
    relatedCostsCenters: relatedCostsCentersOptions,
    isLoadingCostsCenters: isLoadingRelatedCostsCenters || isLoadingCostsCenters
  };
}
