import styled from 'styled-components';

import { Container as OnboardingStepContainer } from 'presentation/pages/cards/Onboarding/components/General/OnboardingStep/OnboardingStep.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;

  ${OnboardingStepContainer}:has(> &) {
    max-width: 90%;
  }

  > h1 {
    padding-top: 2.4rem;
    text-align: left;
  }

  & > :last-child {
    margin-top: 9rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    & > :last-child {
      gap: 1.8rem;
      flex-direction: column;
    }
  }
`;
