import { useEffect, useRef, useState } from 'react';

import { useModalContext } from 'data/contexts';

import {
  type IUseSideModal,
  type IUseSideModalParams,
  type ModalAnimationType
} from './SideModal.types';

export function useSideModal({
  onAfterClose
}: IUseSideModalParams): IUseSideModal {
  const { handleCloseModal } = useModalContext();

  const [modalAnimationState, setModalAnimationState] =
    useState<ModalAnimationType>('opening');

  const closingAnimationTimerRef = useRef<NodeJS.Timeout>();

  function handleCloseModalWithAnimation(): void {
    setModalAnimationState('closing');

    closingAnimationTimerRef.current = setTimeout(() => {
      handleCloseModal();
      onAfterClose?.();
    }, 250);
  }

  useEffect(
    () => () => {
      clearTimeout(closingAnimationTimerRef.current);
    },
    []
  );

  return {
    modalAnimationState,
    handleCloseModalWithAnimation
  };
}
