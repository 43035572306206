import {
  type ICardData,
  type IPersistenceCardData
} from 'data/modules/cards/account';

class CardDataMapper {
  toDomain(persistence: IPersistenceCardData): ICardData {
    return {
      id: persistence.id,
      status: persistence.status === 'active' ? 'ACTIVE' : 'BLOCKED',
      lockLevel: persistence.lock_level,
      panMasked: persistence.pan_masked
    };
  }
}

export default new CardDataMapper();
