import styled from 'styled-components';

export const Container = styled.tr`
  border: 1px solid ${({ theme }) => theme.colors.alto};
  background: ${({ theme }) => theme.colors.gallery};

  td {
    font-size: 1.3rem;
  }
`;

export const DateContent = styled.p`
  color: ${({ theme }) => theme.colors.doveGray};
  font-size: 1.3rem;
  font-weight: 700;
`;

export const BalanceOfDayContent = styled.p`
  font-weight: 700;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
`;
