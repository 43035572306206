import { useShallow } from 'zustand/react/shallow';

import { useValueSolicitationContext } from 'data/contexts';
import { type ValueSolicitationStepType } from 'data/contexts/cards/valueSolicitation/useValueSolicitationContext.types';
import { useChangeBalanceSolicitationCache } from 'data/modules/cards/balance';

import { type IUseValueSolicitationFilters } from './ValueSolicitationFilters.types';

export function useValueSolicitationFilters(): IUseValueSolicitationFilters {
  const [handleChangeStep, selectedSolicitationIdsList] =
    useValueSolicitationContext(
      useShallow(state => [
        state.handleChangeStep,
        state.selectedSolicitationIdsList
      ])
    );

  const { sendBalanceSolicitationToCache } = useChangeBalanceSolicitationCache({
    onAfterSuccess: () => {}
  });

  function handleConfirmValueSolicitation(
    type: ValueSolicitationStepType
  ): void {
    handleChangeStep(type);

    sendBalanceSolicitationToCache({ value: selectedSolicitationIdsList });
  }

  return {
    handleConfirmValueSolicitation
  };
}
