import { useMemo } from 'react';

import { BaseIcon } from 'presentation/components/base/Icon';
import { type icons } from 'presentation/components/base/Icon/BaseIcon/constants/icons.constant';

import { type IOrderHeadCellProps } from './OrderHeadCell.types';

import { FilterButton } from './OrderHeadCell.styles';

export function OrderHeadCell({
  children,
  isActive,
  onClick,
  order
}: IOrderHeadCellProps): JSX.Element {
  const iconName: keyof typeof icons = useMemo(() => {
    if (!isActive) {
      return 'expand-up-down';
    }

    if (order === 'asc') {
      return 'arrow-up-s';
    }

    return 'arrow-down-s';
  }, [isActive, order]);

  return (
    <th>
      <FilterButton
        type='button'
        onClick={onClick}
      >
        <BaseIcon
          name={iconName}
          color='puertoRico'
          size={1.8}
        />

        {children}
      </FilterButton>
    </th>
  );
}
