import { Modal, tokens } from 'ds';
import styled from 'styled-components';

export const ModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  p {
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxs};
    font-weight: ${tokens.fontWeightRegular};
    line-height: 2.4rem;
    letter-spacing: 0.075rem;
    color: ${tokens.neutralColorLowDark};
  }

  strong {
    font-weight: ${tokens.fontWeightSemibold};
  }
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
  margin-top: ${tokens.spacingInsetXs};
`;
