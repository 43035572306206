import { type DefaultTheme } from 'styled-components';

import { type ReportStatusType } from 'data/modules/reports';

export const reportStatusColors: Record<
  ReportStatusType,
  keyof DefaultTheme['colors']
> = {
  APPROVED: 'puertoRico',
  UNREPORTED: 'dustyGray',
  REJECTED: 'danger',
  SENT: 'primary',
  PAID: 'emperor',
  OPEN: 'mineShaft',
  REOPENED: 'tulipTree'
};
