import { tokens } from 'ds';
import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type ListMessagesStylesType } from './ExpenseOutOfPolicyModal.types';

export const ListMessages = styled.ul<AddPrefixToType<ListMessagesStylesType>>`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${({ $fontSmall }) =>
    $fontSmall ? tokens.fontSizeXxss : tokens.fontSizeXxs};
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: 0.75px;
  list-style: disc inside;
  color: ${tokens.neutralColorLowDark};

  > li {
    margin-left: 0.5rem;

    span {
      position: relative;
      left: -0.5rem;
    }
  }
`;

export const StyledSeparator = styled.hr`
  border: 0.5px solid rgb(145 158 171 / 25%);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
`;

export const Subtitle = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  color: ${tokens.neutralColorLowDark};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: 600;
  line-height: 3.2rem;
  letter-spacing: 0.75px;
`;

export const SubtitleWithMargin = styled(Subtitle)`
  margin-top: ${tokens.spacingXs};
`;

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingNano};
`;
