import { useMemo } from 'react';

import { Marker } from '@react-google-maps/api';
import mapFinal from 'ds/icons/svg/map_final.svg';
import mapInitial from 'ds/icons/svg/map_initial.svg';
import mapWaypoint from 'ds/icons/svg/map_waypoint.svg';

import {
  type IUseMapsDefault,
  type IUseMapsDefaultProps
} from './MapsDefault.types';

export function useMapsDefault({
  positions
}: IUseMapsDefaultProps): IUseMapsDefault {
  const markers = useMemo(() => {
    if (positions === undefined || positions.routes.length === 0) {
      return <></>;
    }

    const icons = {
      initial: mapInitial,
      final: mapFinal,
      waypoint: mapWaypoint
    };

    const locations = positions?.routes[0];
    const legs = locations?.legs || [];

    const markers = new Map<
      string,
      {
        latLng: google.maps.LatLng;
        icon: keyof typeof icons;
      }
    >();

    legs.forEach((leg, index) => {
      markers.set(leg.start_address, {
        latLng: leg.start_location,
        icon: index === 0 ? 'initial' : 'waypoint'
      });

      markers.set(leg.end_address, {
        latLng: leg.end_location,
        icon: index === legs.length - 1 ? 'final' : 'waypoint'
      });
    });

    const markersArray = Array.from(markers.values());

    if (markersArray.length === 0) return <></>;

    const markersArrayFirstItem = markersArray.shift() as {
      latLng: google.maps.LatLng;
      icon: keyof typeof icons;
    };

    const markersArrayLastItem = markersArray.pop() as {
      latLng: google.maps.LatLng;
      icon: keyof typeof icons;
    };

    return (
      <>
        <Marker
          position={markersArrayFirstItem.latLng}
          icon={icons[markersArrayFirstItem.icon]}
        />

        {markersArray.map((item, index) => {
          return (
            <Marker
              key={`google-maps-marker-${index}`}
              position={item.latLng}
              icon={icons.waypoint}
            />
          );
        })}

        <Marker
          position={markersArrayLastItem.latLng}
          icon={icons[markersArrayLastItem.icon]}
        />
      </>
    );
  }, [positions]);

  return {
    markers
  };
}
