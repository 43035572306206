import { tokens } from 'ds';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  padding: 2.5rem 1.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: ${tokens.spacingInsetLg};
  }
`;
