import { BaseIcon } from 'presentation/components/base/Icon';

import { type IInfoMessageProps } from './InfoMessage.types';

import { Container, IconContainer } from './InfoMessage.styles';

export function InfoMessage({
  type,
  children,
  ...rest
}: IInfoMessageProps): JSX.Element {
  return (
    <Container {...rest}>
      <IconContainer>
        <BaseIcon
          name={type === 'warning' ? 'error-warning' : 'information'}
          size={type === 'warning' ? 1.8 : 1.6}
          type={type === 'warning' ? 'line' : 'fill'}
        />
      </IconContainer>

      {children}
    </Container>
  );
}
