import { type IPaginatePayload } from 'data/modules/global';
import { type IPasswordPolicy } from 'data/modules/settings/passwordPolicy';

export interface IGetTeamMembersItemsPayload extends IPaginatePayload {}

export interface ITeamMemberItem {
  id: number;
  name: string;
  uuid: string;
}

export interface IRelatedTeamMember extends ITeamMemberItem {}

export interface IGetRelatedTeamMembersPayload {
  uuid: string;
}

export interface IUseGetRelatedTeamMembersParams {
  enabled?: boolean;
  uuid: string;
}

export interface IUseGetRelatedTeamMembers {
  relatedTeamMembers: ITeamMemberItem[];
  isLoadingRelatedTeamMembers: boolean;
}

export interface IUseGetTeamMembersItemsParams extends IPaginatePayload {
  enabled?: boolean;
}

export interface IUseGetTeamMembersItems {
  teamMembers: ITeamMemberItem[];
  isLoadingTeamMembers: boolean;
}

export enum TeamMemberType {
  USUARIO = 'USUARIO',
  ADMINISTRADOR = 'ADMINISTRADOR',
  INTERMEDIARIO = 'INTERMEDIARIO'
}

export enum TeamMemberLinkDefaultOptions {
  ALL = 'ALL'
}

export interface IPersistenceTeamMember {
  id: number;
  cpf: string;
  uuid: string;
  name: string;
  active: boolean;
  position: string;
  safe_email: string;
  is_approver: boolean;
  type_user: TeamMemberType;
}

export interface ITeamMember {
  id: number;
  cpf: string;
  uuid: string;
  name: string;
  active: boolean;
  position: string;
  safeEmail: string;
  isApprover: boolean;
  typeUser: TeamMemberType;
}

export interface IPersistenceGetTeamMembersPayload {
  page: number;
  order?: string;
  search?: string;
  per_page: number;
  order_by?: string;
}

export interface IGetTeamMembersPayload {
  page: number;
  order?: string;
  search?: string;
  perPage: number;
  orderBy?: string;
}

export interface IUseGetTeamMembersParams extends IGetTeamMembersPayload {}

export interface IUseGetTeamMembersResponse {
  teamMembers: ITeamMember[];
  pagination?: ITeamMembersPagination;
}

export interface ITeamMembersPagination {
  page?: number;
  totalPage?: number;
}

export interface IUseGetTeamMembers extends IUseGetTeamMembersResponse {
  isLoadingTeamMembers: boolean;
}

export interface IPersistenceTeamMembersResume {
  total_registered_users: number;
  total_active_users: number;
  total_admin_users: number;
}

export interface ITeamMembersResume {
  totalRegisteredUsers: number;
  totalActiveUsers: number;
  totalAdminUsers: number;
}

export interface IUseGetTeamMembersResume {
  teamMembersResume?: ITeamMembersResume;
  isLoadingTeamMembersResume: boolean;
}

export interface ICreateTeamMemberFieldsForm {
  name: string;
  email: string;
  bank?: string;
  phone?: string;
  agency?: string;
  pixKey?: string;
  document: string;
  account?: string;
  password: string;
  position?: string;
  altPhone?: string;
  birthDate?: string;
  projects?: string[];
  type: TeamMemberType;
  expensePolicy?: string;
  costCenters?: string[];
  passwordConfirm: string;
  approvalFlows?: string[];
}

export interface IUseCreateTeamMemberSchemaParams extends IPasswordPolicy {}

export interface IPersistenceTeamMemberPositionOption {
  position_id: number;
  position_name: string;
  department_id: number;
  department_name: string;
}

export interface ITeamMemberPositionOption {
  positionId: number;
  positionName: string;
  departmentId: number;
  departmentName: string;
}

export interface IPersistenceBaseTeamMemberOption {
  id: number;
  name: string;
}

export interface IBaseTeamMemberOption
  extends IPersistenceBaseTeamMemberOption {}

export interface IPersistenceTeamMemberCostCenterOption
  extends IPersistenceBaseTeamMemberOption {}

export interface ITeamMemberCostCenterOption extends IBaseTeamMemberOption {}

export interface IPersistenceTeamMemberProjectOption
  extends IPersistenceBaseTeamMemberOption {}

export interface ITeamMemberProjectOption extends IBaseTeamMemberOption {}

export interface IPersistenceTeamMemberLimitPolicyOption
  extends IPersistenceBaseTeamMemberOption {}

export interface ITeamMemberLimitPolicyOption extends IBaseTeamMemberOption {}

export interface IPersistenceTeamMemberApprovalFlowOption
  extends IPersistenceBaseTeamMemberOption {}

export interface ITeamMemberApprovalFlowOption extends IBaseTeamMemberOption {}

export interface IUseGetTeamMembersCostCenterOptions {
  isLoadingCostCenterOptions: boolean;
  costCenterOptions: ITeamMemberCostCenterOption[];
}

export interface IUseGetTeamMembersProjectOptions {
  isLoadingProjectOptions: boolean;
  projectOptions: ITeamMemberProjectOption[];
}

export interface IUseGetTeamMembersLimitPolicyOptions {
  isLoadingLimitPolicyOptions: boolean;
  limitPolicyOptions: ITeamMemberLimitPolicyOption[];
}

export interface IUseGetTeamMembersApprovalFlowOptions {
  isLoadingApprovalFlowOptions: boolean;
  approvalFlowOptions: ITeamMemberApprovalFlowOption[];
}

export interface IUseGetTeamMembersPositionOptions {
  isLoadingPositionOptions: boolean;
  positionOptions: ITeamMemberPositionOption[];
}
