import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import {
  type IRequestProductFieldsForm,
  type IStoreProduct
} from 'data/modules/store';

export function useRequestProductSchema({
  identifier
}: {
  identifier: IStoreProduct['identifier'];
}): ObjectSchema<IRequestProductFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const requestProductSchema: ObjectSchema<IRequestProductFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];
      const withCardsFields = ['cards', 'cards-pj'].includes(identifier);

      return yup.object().shape({
        identifier: yup
          .mixed<IStoreProduct['identifier']>()
          .required(fieldRequiredMessage),
        companyId: yup.string().required(fieldRequiredMessage),
        userId: yup.string().required(fieldRequiredMessage),
        cardsUseType: withCardsFields
          ? yup.string().required(fieldRequiredMessage)
          : yup.string(),
        cardsQtt: withCardsFields
          ? yup.string().required(fieldRequiredMessage)
          : yup.string(),
        email: yup
          .string()
          .required(fieldRequiredMessage)
          .email(lang.schemas.invalid_email[currentLangKey]),
        phone: yup
          .string()
          .required(fieldRequiredMessage)
          .min(15, `${lang.schemas.min[currentLangKey]} 15`)
      });
    }, [
      identifier,
      currentLangKey,
      lang.schemas.min,
      lang.schemas.is_required,
      lang.schemas.invalid_email
    ]);

  return requestProductSchema;
}
