import { type BaseSyntheticEvent } from 'react';

import {
  type QueryObserverResult,
  type RefetchOptions
} from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { type UseFormReturn } from 'react-hook-form';

import {
  type CurrencyType,
  type IErrorResponse,
  type IPaginatedResponse
} from 'data/modules/global';
import { type IProjectKilometerPolicy } from 'data/modules/projects';
import {
  type IReport,
  type ReportInformationType,
  type ReportStatusType
} from 'data/modules/reports';

export type ExpenseOrderByType =
  | 'date'
  | 'title'
  | 'type'
  | 'report'
  | 'amount'
  | 'status';

export interface IUseGetUserExpensesInformationParams
  extends IGetUserExpensesPendingAccountabilityPayload {}

export interface IUseGetUserExpensesInformation {
  isLoadingUserExpensesInformation: boolean;
  userExpensesDetachedList: ExpensesCardsType[];
  userExpensesDetached: Record<string, ExpensesCardsType>;
}

export type ExpensesCardsType = {
  status: IExpensesPendingAccountability['status'];
  value: number;
  total: number;
};

export interface ICardsExpense {
  expenseType: string;
  report: IReport | null;
  transactionId: string;
  value: number;
  url: string;
  currencyIso: string;
  receiptUrl: string;
  receiptType: string;
  userName: string;
  costsCenter: string;
  apportionments:
    | {
        id: string;
        description: string;
      }[]
    | null;
}

export interface IUseGetExpenseParams
  extends IGetExpenseByTransactionIdPayload {
  enabled: boolean;
}

export interface IUseGetExpenseDetailsParams {
  expenseUuid: string;
  enabled?: boolean;
}

export interface IUseGetExpense {
  expense?: ICardsExpense | null;
  isLoadingExpense: boolean;
  isErrorExpense: boolean;
  isFetchingExpense: boolean;
  refetchExpense: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<ICardsExpense | null, AxiosError>>;
}

export interface IUseGetExpenseDetails {
  expense: IDetailedExpense | undefined;
  isLoadingExpense: boolean;
  getEnsuredExpenseDetails: (
    payload: IGetExpensePayload
  ) => Promise<IDetailedExpense>;
  isFetchingAndPendingExpenseDetails: boolean;
}

export interface IGetExpenseByTransactionIdPayload {
  transactionId: string;
}

export interface IGetUserExpensesPendingAccountability
  extends IExpensesPendingAccountability {}

export interface IExpensesPendingAccountability {
  value: number;
  convertedValue: number;
  status: 'AVULSA' | 'ABERTO' | 'ENVIADO' | 'REPROVADO' | '';
}

export interface IUseGetUserExpensesPendingAccountability {
  userExpensesPendingAccountability:
    | IExpensesPendingAccountability[]
    | undefined;
  isFetchingUserExpensesPendingAccountability: boolean;
}

export interface IGetUserExpensesPendingAccountabilityPayload {
  userUuid: string;
  status?: IPersistenceUserExpensesPendingAccountability['status'][];
}

export interface IPersistenceUserExpensesPendingAccountability {
  value: number;
  converted_value: number;
  status: 'AVULSA' | 'ABERTO' | 'ENVIADO' | 'REPROVADO';
}

export interface IGetExpensesListByTransactionsPayload {
  transactionsIds: string[] | readonly string[];
}

export interface IPersistenceReport {
  status: ReportStatusType;
  title: string;
  url: string;
}

export interface IPersistenceExpense {
  id: string;
  user_name: string;
  transaction_id: string;
  value: number;
  currency_iso: string;
  expense_type: string;
  costs_center: string;
  receipt_type: string;
  receipt_url: string;
  url: string;
  report: IPersistenceReport | null;
  apportionments:
    | {
        id: string;
        description: string;
      }[]
    | null;
}

export interface IGetPaginatedExpensesPayload {
  paymentMethod: string;
  endDate: string | undefined;
  startDate: string | undefined;
  refundable: string[];
  page: number;
  perPage: number;
  search: string;
  typeOfExpense: string;
  statusTab: StatusTabTypePersistenceType | undefined;
  orderBy: ExpenseOrderByType;
  order: 'asc' | 'desc';
}

export interface IApportionmentPayload {
  project: string;
  percentage: number;
  value: number;
}

export interface ICreateRouteExpenseFirstStepFieldsForm {
  preRegisteredSection: string;
  description: string;
  currency: string;
  value: string;
  date: string;
  costsCenter: SelectValueType;
  paymentMethod: string;
  mileage: string;
  mileagePaidValue: string;
  refundable: boolean;
  observation: string;
  receipt: File | string | null;
  apportionment:
    | {
        project: SelectValueType;
        percentage: number;
        value: number;
      }[]
    | undefined;
}

export interface ICreateRouteExpensePayload
  extends Omit<
      ICreateRouteExpenseFirstStepFieldsForm,
      'costsCenter' | 'apportionment'
    >,
    ICreateRouteExpenseSecondStepFieldsForm {
  costsCenter: string;
  // campos abaixo são opcionais para envio no payload
  apportionment?: IApportionmentPayload[];
}

export interface IUpdateRouteExpensePayload extends ICreateRouteExpensePayload {
  expenseUuid: string;
}

export interface IPersistenceCreateRouteExpensePayload {
  receipt?: File | string;
  amount: number;
  amount_per_kilometer: number;
  kilometrage: number;
  date: string;
  title?: string;
  is_reimbursable: string;
  observations?: string;
  route_id?: number;
  currency_id: number;
  cost_center_id: number;
  payment_method_id?: number;
  report_uuid?: string;
  apportionments: {
    project_id: number;
    percentage: number;
    amount: number;
  }[];
}

export interface IPersistenceUpdateRouteExpensePayload {
  receipt?: File | string;
  amount: number;
  amount_per_kilometer: number;
  kilometrage: number;
  date: string;
  title?: string;
  is_reimbursable: string;
  observations?: string;
  route_id?: number;
  currency_id: number;
  cost_center_id: number;
  payment_method_id?: number;
  report_uuid?: string;
  apportionments: {
    project_id: number;
    percentage: number;
    amount: number;
  }[];
}

export interface IPersistenceCreateRouteByMapPayload {
  amount: number;
  date: string | undefined;
  title?: string;
  is_reimbursable: string;
  receipt: File;
  observations?: string;
  currency_id: number;
  kilometrage: number;
  cost_center_id: number;
  payment_method_id?: number;
  amount_per_kilometer: number;
  report_uuid?: string;
  apportionments: {
    project_id: number;
    percentage: number;
    amount: number;
  }[];
  route: string | undefined;
  places: string;
}

export interface IPersistenceUpdateRouteByMapPayload {
  amount: number;
  date: string | undefined;
  title?: string;
  is_reimbursable: string;
  receipt: File;
  observations?: string;
  currency_id: number;
  kilometrage: number;
  cost_center_id: number;
  payment_method_id?: number;
  amount_per_kilometer: number;
  report_uuid?: string;
  apportionments: {
    project_id: number;
    percentage: number;
    amount: number;
  }[];
  route: string | undefined;
  places: string;
}

export interface ICreateRouteByMapPayload
  extends Omit<
      ICreateRouteByMapDefaultFieldsForm,
      'costsCenter' | 'apportionment'
    >,
    ICreateRouteByMapReportSelectionFieldsForm {
  routeImage: File;
  costsCenter: string;
  apportionment?: IApportionmentPayload[];
  places: IGooglePlaces[];
  mapDirectionsResults: google.maps.DirectionsResult | undefined;
}

export interface IUpdateRouteByMapPayload
  extends Omit<
      ICreateRouteByMapDefaultFieldsForm,
      'costsCenter' | 'apportionment'
    >,
    ICreateRouteByMapReportSelectionFieldsForm {
  routeImage: File;
  costsCenter: string;
  apportionment?: IApportionmentPayload[];
  places: IGooglePlaces[];
  mapDirectionsResults: google.maps.DirectionsResult | undefined;
  expenseUuid: string;
}

export interface ICreateManualExpensePayload
  extends Omit<
      ICreateManualExpenseFirstStepFieldsForm,
      'costsCenter' | 'refundable' | 'apportionment'
    >,
    ICreateManualExpenseSecondStepFieldsForm {
  costsCenter: string;
  // campos abaixo são opcionais para envio no payload
  apportionment?: IApportionmentPayload[];
  refundable: boolean | undefined;
  // campo abaixo é para enviar despesa mesmo ultrapassando o limite da política
  validated?: 'N';
}

export interface ICreateFuelExpensePayload
  extends Omit<
      ICreateFuelExpenseFirstStepFieldsForm,
      'costsCenter' | 'apportionment' | 'fuelType'
    >,
    ICreateFuelExpenseSecondStepFieldsForm {
  costsCenter: string;
  fuelType: string;
  // campos abaixo são opcionais para envio no payload
  apportionment?: IApportionmentPayload[];
}

export interface IPersistenceCreateFuelExpensePayload {
  fuel_id: string;
  vehicle_id?: string;
  vehicle_type_id?: string;
  amount_per_litre: number;
  volume_in_litres: number;
  odometer_image: File | string | null;
  odometer_kilometrage?: number;
  receipt: File | string | null;
  amount: number;
  date?: string;
  title?: string;
  currency_id: number;
  cost_center_id: string;
  payment_method_id: string;
  is_reimbursable: boolean | string;
  observations: string;
  report_uuid: string;
  attachments?: (File | string)[];
  apportionments: {
    project_id: number;
    percentage: number;
    amount: number;
  }[];
}

export interface IUpdateFuelExpensePayload extends ICreateFuelExpensePayload {
  expenseUuid: string;
}

export interface IPersistenceUpdateFuelExpensePayload
  extends IPersistenceCreateFuelExpensePayload {}

export interface IUpdateManualExpensePayload
  extends ICreateManualExpensePayload {
  expenseUuid: string;
}

export interface IPersistenceCreateManualExpensePayload {
  description?: string;
  currency_id: number;
  amount: number;
  exchange_rate?: number;
  converted_amount?: number;
  date: string | undefined;
  expense_type_id: number;
  cost_center_id: string;
  payment_method_id?: string;
  is_reimbursable: boolean | undefined | string;
  observations: string | undefined;
  report_uuid: string | undefined;
  attachments?: (File | string)[];
  receipt: File | string | null;
  apportionments: {
    project_id: number;
    percentage: number;
    amount: number;
  }[];
  title: string | undefined;
  // serve para indicar que a despesa está fora da política
  validated?: 'N';
}

export interface IPersistenceUpdateManualExpensePayload {
  description?: string;
  currency_id: number;
  amount: number;
  exchange_rate?: number;
  converted_amount?: number;
  date: string | undefined;
  expense_type_id: number;
  cost_center_id: string;
  payment_method_id?: string;
  is_reimbursable: boolean | undefined | string;
  observations: string | undefined;
  report_uuid: string | undefined;
  receipt: File | string | null;
  attachments?: Record<number, File | string>;
  apportionments: {
    project_id: number;
    percentage: number;
    amount: number;
  }[];
  title: string | undefined;
  // serve para indicar que a despesa está fora da política
  validated?: 'N';
}

export interface IGetExpensesKmPolicyPayload {
  valueKm: number;
  expenseUuid?: string;
  date: string;
}

export interface IGetExpensePayload {
  expenseUuid: string;
}

export interface IPersistenceExpenseList {
  uuid: string;
  id: number;
  title: string;
  amount: number;
  date: string;
  cost_center: {
    id: number;
    uuid: string;
    nome: string;
  } | null;
  is_deletable: boolean;
  is_reimbursable: boolean;
  is_from_cards: boolean;
  receipt: {
    uuid: string;
    type: 'IMG' | 'PDF';
    name: string;
    original_url: string;
    thumbnail_url: string;
  } | null;
  report: {
    uuid: string;
    description: string;
    status: ExpenseStatusType;
  };
  conciliation: {
    is_enabled: boolean;
    was_imported: boolean;
    status: 'not_reconciled' | 'partially_reconciled' | 'reconciled';
  };
  currency: {
    id: number;
    symbol: string;
    iso_code: string;
  };
  type: {
    id: number;
    description: string;
    route_type: 'TRACKING' | 'MAPA' | null;
  };
  payment_method: {
    id: number;
    description: string;
  } | null;
  reversal_status: string | null;
}

export type StatusTabApiType =
  | 'ENVIADO'
  | 'REJEITADO'
  | 'APROVADO'
  | 'ABERTO'
  | 'PAGO'
  | 'REABERTO'
  | 'AVULSO';

export interface IStatusTabObjectPersistence {
  count: number;
  status: StatusTabApiType;
}

export interface IGetNumberOfExpensesByStatusPayload
  extends Partial<IGetPaginatedExpensesPayload> {}

export interface IDeleteExpensesPayload {
  expenses: string[];
}

export interface IExpenseReport {
  uuid: string;
  label: string;
  status: ExpenseStatusType;
}

export interface IExpenseAttachment {
  url: string;
  label?: string;
  uuid?: string;
  type: 'IMG' | 'PDF';
}

export interface IExpenseExchange {
  rate: string;
  value: string;
}

export interface IExpenseApportioment {
  percentage: number;
  title: string;
  value: string;
  observation: string;
}

export interface ITypesOfExpense {
  id: number;
  description: string;
  active: boolean;
}

export interface IGetTypesOfExpensesParams {
  withInactives?: boolean;
}

export interface IExpenseCostCenter {
  id: number;
  uuid: string;
  label: string;
}

export interface IExpensePaymentMethods {
  uuid: string;
  label: string;
}
export enum ExpenseStatus {
  PAID = 'PAGO',
  OPEN = 'ABERTO',
  SENT = 'ENVIADO',
  SINGLE = 'AVULSA',
  REOPENED = 'REABERTO',
  APPROVED = 'APROVADO',
  REPROVED = 'REPROVADO'
}

export type ExpenseStatusType = `${ExpenseStatus}`;

export type StatusTabType =
  | 'all'
  | 'single'
  | 'approved'
  | 'sent'
  | 'open'
  | 'reopened'
  | 'reproved'
  | 'paid';

export type StatusTabTypePersistenceType =
  | 'todos'
  | 'avulso'
  | 'aprovado'
  | 'enviado'
  | 'reaberto'
  | 'aberto'
  | 'reprovado'
  | 'pago';

export type StatusTabObjectType = Record<StatusTabType, number>;

export interface IExpense {
  uuid: string;
  attachment: IExpenseAttachment | null;
  date: string;
  apportioment: IExpenseApportioment[] | null;
  title: string | null;
  cardNumber: string | null;
  type: string;
  value: number;
  currency: string;
  report: IExpenseReport | null;
  formOfPayment: string | null;
  canDelete: boolean;
  isReconciled: boolean | null;
  isImported: boolean;
  refundable: boolean;
  isFromCards: boolean;
  observation: string | null;
  observationAttachments: IExpenseAttachment[] | null;
  costCenter: IExpenseCostCenter | null;
  exchange: IExpenseExchange | null;
  isReversal: string | null;
}
export interface IExpenseTableRow
  extends Omit<
    IExpense,
    | 'apportioment'
    | 'cardNumber'
    | 'observation'
    | 'observationAttachments'
    | 'apportioment'
    | 'exchange'
  > {
  routeType: 'TRACKING' | 'MAPA' | null;
  typeId: number | null;
  id: number;
}

type UsesExchangeType = 'NAO_UTILIZA' | 'SEMPRE_EXIGE' | 'FORMA_DE_PAGAMENTO';

export interface IExpensePaginatedResponse
  extends IPaginatedResponse<IExpenseTableRow> {
  lastPage: number;
}

export type SelectValueType =
  | {
      value?: string;
      label?: string;
    }
  | undefined;

export interface ICreateManualExpenseFirstStepFieldsForm {
  description?: string;
  currency: string;
  value: string;
  exchangeRate?: string;
  convertedValue?: string;
  date: string | undefined;
  type: string;
  costsCenter: SelectValueType;
  paymentMethod: string;
  refundable: boolean;
  observation: string;
  observationAttachments?: (File | IObservationAttachment)[];
  receipt: File | string | null;
  apportionment: IExpenseApportionment[] | undefined;
}

export interface IExpenseApportionment {
  project: SelectValueType;
  percentage: number;
  value: number;
}

export interface IPersistenceExpenseApportionment {
  project_id: number;
  percentage: number;
  amount: number;
}

export interface ICreateManualExpenseSecondStepFieldsForm {
  report?: string;
}

export interface IUseCreateManualExpenseParams {
  onAfterSubmitFirstStep?: () => void;
  onSuccessCreateManualExpense?: () => void;
  onSuccessUpdateManualExpense?: () => void;
  onErrorCreateOrUpdateManualExpense?: (message: string) => void;
  onPolicyErrorCreateManualExpense?: (
    error: ICreateOrUpdateManualExpenseResponseError,
    expenseTypeId: string,
    hasUserFilledInTheObservationField: boolean
  ) => void;
  onErrorUpdateManualExpense?: (
    error: ICreateOrUpdateManualExpenseResponseError,
    expenseTypeId: string,
    hasUserFilledInTheObservationField: boolean
  ) => void;
  confirmedReportInformationBySelectedReport: ReportInformationType | null;
  expenseUuidToUpdate?: string;
}

export interface ICreateFuelExpenseFirstStepFieldsForm {
  description: string;
  currency: string;
  value: string;
  licensePlate: string;
  vehicleType: string;
  quantityOfLiters: string;
  pricePerLiter: string;
  fuelType: SelectValueType | null;
  date: string | undefined;
  paymentMethod: string;
  costsCenter: SelectValueType;
  odometerImage: File | string | null;
  mileage: string;
  refundable: boolean;
  observation: string;
  observationAttachments?: (File | IObservationAttachment)[];
  receipt: File | string | null;
  apportionment: IExpenseApportionment[] | undefined;
}

export interface ICreateFuelExpenseSecondStepFieldsForm {
  report: string;
}

export interface IUseCreateFuelExpenseParams {
  onAfterSubmitFirstStep?: () => void;
  onSuccessCreateFuelExpense?: () => void;
  onSuccessUpdateFuelExpense?: () => void;
  onErrorCreateOrUpdateFuelExpense: (message: string) => void;
  onPolicyErrorCreateFuelExpense?: () => void;
  onErrorUpdateFuelExpense?: () => void;
  confirmedReportInformationBySelectedReport: ReportInformationType | null;
  expenseUuidToUpdate?: string;
}

export interface IUseFirstStepFormSchemaParams {
  userAction: 'create' | 'update';
}

export interface IUseDefaultFieldsSchemaParams {
  userAction: 'create' | 'update';
}

export type ItemsFlagsValueKeyType =
  | 'this_expense_exceeded_the_limit_amount_by_expense'
  | 'this_expense_exceeded_the_limit_amount_by_day'
  | 'this_expense_exceeded_the_limit_quantity_by_day'
  | 'this_expense_exceeded_the_limit_quantity_by_month';

export type ItemsFlagsErrorsType =
  | 'its_necessary_add_a_receipt_for_this_expense'
  | 'this_expense_cant_to_exceed_limits_of_policy'
  | 'this_expense_needs_a_note_to_exceed_expense_limit'
  | 'the_expense_limit_cant_to_be_exceeded'
  | ItemsFlagsValueKeyType;

export interface ICreateOrUpdateManualExpenseResponseError {
  allowExceedLimit: boolean;
  requireNotes: boolean;
  failures: {
    project: string | null;
    itemsFlags: ItemsFlagsErrorsType[];
    itemsValues: Partial<Record<ItemsFlagsValueKeyType, string>>;
  }[];
  expenseTypeId: string;
}

export interface IUseCreateManualExpense {
  isLoadingExpenseParameters: boolean;
  isCreatingManualExpense: boolean;
  isUpdatingManualExpense: boolean;
  expenseParameters: IExpenseParameters | undefined;
  firstStepForm: {
    methods: UseFormReturn<ICreateManualExpenseFirstStepFieldsForm>;
    handleSubmit: (e: BaseSyntheticEvent) => void;
    selectedCurrencyId: string | undefined;
  };
  secondStepForm: {
    methods: UseFormReturn<ICreateManualExpenseSecondStepFieldsForm>;
    handleSubmit: (e?: BaseSyntheticEvent) => void;
  };
  allowUserChangeExpenseProjectByReportProject: boolean;
  handleCreateManualExpenseAfterConfirmationOutOfPolicy: () => Promise<void>;
  handleUpdateManualExpenseAfterConfirmationOutOfPolicy: () => Promise<void>;
}

export interface IUseCreateFuelExpense {
  isLoadingExpenseParameters: boolean;
  isCreatingFuelExpense: boolean;
  isUpdatingFuelExpense: boolean;
  expenseParameters: IExpenseParameters | undefined;
  firstStepForm: {
    methods: UseFormReturn<ICreateFuelExpenseFirstStepFieldsForm>;
    handleSubmit: (e: BaseSyntheticEvent) => void;
    selectedCurrencyId: string | undefined;
  };
  secondStepForm: {
    methods: UseFormReturn<ICreateFuelExpenseSecondStepFieldsForm>;
    handleSubmit: (e?: BaseSyntheticEvent) => void;
  };
  allowUserChangeExpenseProjectByReportProject: boolean;
}

export interface ICreateRouteByMapDefaultFieldsForm {
  description?: string;
  currency: string;
  value: string;
  date: string | undefined;
  costsCenter: SelectValueType;
  paymentMethod: string;
  mileage: string;
  mileagePaidValue: string;
  refundable: boolean;
  observation: string;
  apportionment:
    | {
        project: SelectValueType;
        percentage: number;
        value: number;
      }[]
    | undefined;
}

export interface ICreateRouteByMapReportSelectionFieldsForm {
  report?: string;
}

export interface IGooglePlaces {
  placeId: string | undefined;
  search: string | undefined;
}
export interface IUseCreateRouteByMapParams {
  onAfterSubmitDefaultFieldsForm?: () => void;
  onSuccessCreateRouteByMap?: () => void;
  onSuccessUpdateRouteByMap?: () => void;
  generateRouteImage: () => Promise<File | null>;
  onErrorCreateOrUpdateRouteByMapExpense?: (message: string) => void;
  places: IGooglePlaces[];
  mapDirectionsResults: google.maps.DirectionsResult | undefined;
  confirmedReportInformationBySelectedReport: ReportInformationType | null;
  expenseUuidToUpdate?: string;
}

export interface IUseCreateRouteByMap {
  isLoadingExpenseParameters: boolean;
  isCreatingRouteByMap: boolean;
  defaultFieldsForm: {
    methods: UseFormReturn<ICreateRouteByMapDefaultFieldsForm>;
    handleSubmit: (e?: BaseSyntheticEvent) => void;
    selectedCurrencyId: string | undefined;
  };
  reportSelectionForm: {
    methods: UseFormReturn<ICreateRouteByMapReportSelectionFieldsForm>;
    handleSubmit: (e?: BaseSyntheticEvent) => void;
  };
  allowUserChangeExpenseProjectByReportProject: boolean;
  isUpdatingRouteByMap: boolean;
}

export interface ICreateRouteExpenseSecondStepFieldsForm {
  report?: string;
}

export interface IUseCreateRouteExpenseParams {
  onAfterSubmitFirstStep?: () => void;
  onSuccessCreateRouteExpense?: () => void;
  onSuccessUpdateRouteExpense?: () => void;
  confirmedReportInformationBySelectedReport: ReportInformationType | null;
  expenseUuidToUpdate?: string;
  requiredReceiptInManualRoute: boolean;
  onErrorCreateOrUpdateManualRouteExpense?: (message: string) => void;
}

export interface IUseCreateRouteExpense {
  isLoadingExpenseParameters: boolean;
  isCreatingRouteExpense: boolean;
  isUpdatingRouteExpense: boolean;
  firstStepForm: {
    methods: UseFormReturn<ICreateRouteExpenseFirstStepFieldsForm>;
    handleSubmit: (e: BaseSyntheticEvent) => void;
    selectedCurrencyId: string | undefined;
  };
  secondStepForm: {
    methods: UseFormReturn<ICreateRouteExpenseSecondStepFieldsForm>;
    handleSubmit: (e?: BaseSyntheticEvent) => void;
  };
  allowUserChangeExpenseProjectByReportProject: boolean;
}

export interface IUseDeleteExpensesParams {
  onSuccess?: (numberOfExpensesDeleted: number) => void;
  onError?: (error: IErrorResponse[] | undefined) => void;
}

export interface IUseDeleteExpenses {
  isDeletingExpenses: boolean;
  deleteExpenses: (payload: IDeleteExpensesPayload) => void;
}

export interface IUseGetExpenseKmPolicyParams
  extends IGetExpensesKmPolicyPayload {}

export interface IUseGetExpenseKmPolicy {
  expenseKmPolicyEnsureQueryData: (
    params: IUseGetExpenseKmPolicyParams
  ) => Promise<IProjectKilometerPolicy | null>;
}

export interface IUseGetExpenseParameters {
  expenseParameters: IExpenseParameters | undefined;
  isLoadingExpenseParameters: boolean;
}

export interface IUseGetNumberOfExpensesByStatus {
  numberOfExpensesByStatus: StatusTabObjectType | undefined | null;
  isLoadingNumberOfExpensesByStatus: boolean;
}

export interface IUseGetNumberOfExpensesByStatusParams
  extends IGetPaginatedExpensesPayload {}

export interface IUseGetPaginatedExpenses {
  paginatedExpenses: IExpensePaginatedResponse | null | undefined;
  isLoadingPaginatedExpenses: boolean;
  isFetchingPaginatedExpenses: boolean;
}

export interface IUseGetPaginatedExpensesParams
  extends IGetPaginatedExpensesPayload {}
export interface IUseGetTypesOfExpenses {
  typesOfExpenses: ITypesOfExpense[] | undefined;
  isLoadingTypesOfExpenses: boolean;
}

export interface IUseGetExpensesParams extends IGetTypesOfExpensesParams {}

export interface IPersistenceWebExpenseParameters {
  allow_add_route_with_map: boolean;
  allow_add_manual_route: boolean;
  default_currency: {
    company: number | null;
    user: number | null;
  };
  days_policy: {
    past_days: number | null;
    future_days: number | null;
  };
  policy_type: 'PROJETO' | 'USUARIO';
  route_policy_type: 'PROJETO' | 'USUARIO';
  company_fields: {
    company_fields: {
      expense_cost_center: string | null;
      expense_payment_method: string | null;
      expense_note: string | null;
      expense_project: string | null;
      report_cost_center: string | null;
      report_payment_method: string | null;
      report_project: string | null;
    };
    company_uses_fields: boolean;
  };
  company_approval_type: 'CC' | 'USUARIO';
  uses_observation_attachment: number;
  allow_change_amount_per_km: number;
  amount_per_km: string | number;
  uses_advance: number;
  uses_invoice_reconciliation: boolean;
  allow_expense_with_negative_value: boolean;
  uses_exchange: UsesExchangeType;
  uses_checking_account: number;
  allow_payment_method_change_for_imported_expenses: boolean;
  checking_account_multicurrency: boolean;
  is_project_mandatory: boolean;
  is_payment_method_mandatory: number;
  is_expense_description_mandatory: boolean;
  is_obs_mandatory: boolean;
  is_manual_route_receipt_mandatory: boolean;
  fuels_policies:
    | {
        is_type_mandatory: boolean;
        is_plate_mandatory: boolean;
        is_odometer_mandatory: boolean;
        is_kilometrage_mandatory: boolean;
      }
    | [];
}

export interface IExpenseParameters {
  allowAddRouteWithMap: boolean;
  allowAddManualRoute: boolean;
  /** parâmetro empresa_idMoedaPadrao */
  defaultCurrency: {
    company: number | null;
    user: number | null;
  };
  /**  parâmetro funcionalidade_utilizaAnexoObservacoes */
  hasObservationAttachment: boolean;
  /** variável $permiteAlterarValorKm no arquivo fields-percurso.blade.php */
  allowChangeAmountPerKm: boolean;
  /** variável $valorKm no arquivo fields-percurso.blade.php */
  amountPerKm: number;
  /** parâmetro de empresa utilizaAdiantamento */
  usesAdvance: boolean;
  /** parâmetro preferencias_projetoItemObrigatorio */
  isProjectFieldMandatory: boolean;
  /** parâmetro funcionalidade_utilizaConciliacaoFatura */
  usesInvoiceReconciliation: boolean;
  /** parâmetro preferencias_formaPagamentoObrigatoria */
  isPaymentMethodMandatory: boolean;
  /** parâmetro preferencias_permiteValorNegativo */
  allowExpenseWithNegativeValue: boolean;
  /** parâmetro preferencias_descricaoDespesaObrigatoria */
  isExpenseDescriptionMandatory: boolean;
  /** parâmetro utilizaCambio */
  usesExchange: UsesExchangeType;
  /** parâmetro utilizaContaCorrente */
  usesCheckingAccount: boolean;
  /** parâmetro permiteAlterarFormaPagamentoDespesaImportada */
  allowPaymentMethodChangeForImportedExpenses: boolean;
  /** se a observação de despesa é obrigatória ou não */
  isExpensesObsMandatory: boolean;
  // parâmetro lancamentoMultimoedaContaCorrente
  checkingAccountMulticurrency: boolean;
  companyFields: {
    companyUsesFields: boolean;
    expenseCostCenter: string | null;
    expensePaymentMethod: string | null;
    expenseNote: string | null;
    expenseProject: string | null;
    reportCostCenter: string | null;
    reportPaymentMethod: string | null;
    reportProject: string | null;
  };
  policyType: 'PROJETO' | 'USUARIO';
  mileagePolicyType: 'PROJETO' | 'USUARIO';
  daysPolicy: {
    pastDays: number | null;
    futureDays: number | null;
  };
  companyApprovalType: 'CC' | 'USUARIO';
  requiredReceiptInManualRoute: boolean;
  // Políticas de combustível
  fuelsPolicies: {
    isTypeMandatory: boolean;
    isPlateMandatory: boolean;
    isOdometerMandatory: boolean;
    isKilometrageMandatory: boolean;
  } | null;
}

interface IVehicle {
  id: number;
  plate: string;
  model: string;
  owner: string;
}

interface IPersistenceFuelImage {
  id: number;
  uuid: string;
  type: string;
  name: string;
  original_url: string;
  thumbnail_url: string;
}

interface IFuel {
  id: number;
  description: string;
}

interface IVehicleType {
  id: number;
  description: string;
}

interface IPersistenceFueling {
  odometer_kilometrage: number | null;
  volume: number | null;
  amount_per_litre: number | null;
  vehicle: IVehicle | null;
  odometer_image: IPersistenceFuelImage | null;
  fuel: IFuel | null;
  vehicle_type: IVehicleType | null;
}

export interface IPersistenceShowExpense {
  id: number;
  uuid: string;
  user_id: number;
  user_creator_id: number;
  user_creator_name: string;
  route_id: number | null;
  title: string;
  amount: number;
  date: string;
  is_deletable: boolean;
  is_editable: boolean;
  is_from_cards: boolean;
  is_reimbursable: boolean;
  was_validated: string;
  observations: string | null;
  exchange_rate: null | number;
  converted_value: null | number;
  converted_currency_id: null | string;
  converted_currency_iso: null | string;
  conciliation_balance: null | number;
  read_ocr: null;
  modified_intelliscan_field: null | boolean;
  card_number: string;
  type: {
    id: number;
    description: string;
    kilometrage: string;
    amount_per_kilometer: string;
    route_type: 'TRACKING' | 'MAPA' | null;
    route_points:
      | null
      | [
          {
            location: string;
            placeId: string;
            search: string;
          }
        ];
    route_polyline: google.maps.DirectionsResult | null;
    gps_route_start_timezone: string | null;
    gps_route_final_timezone: string | null;
    gps_route_start_date_naive: string | null;
    gps_route_final_date_naive: string | null;
  } | null;
  currency: {
    id: number;
    symbol: string;
    iso_code: CurrencyType;
  };
  conciliation: {
    is_enabled: boolean;
    was_imported: boolean;
    status: string;
  };
  payment_method: {
    id: number;
    description: string;
  } | null;
  receipt: {
    id: number;
    uuid: string;
    type: 'PDF' | 'IMAGE';
    name: string;
    original_url: string;
    thumbnail_url: string;
  } | null;
  report: {
    id: number;
    uuid: string;
    description: string;
    status: ExpenseStatusType;
  } | null;
  cost_center: {
    id: number;
    uuid: string;
    nome: string;
  } | null;
  transaction: {
    id: number;
    amount: number | null;
    is_international: boolean;
    final_international_amount: number | null;
    final_billing_amount: number | null;
    card_number: string | null;
  } | null;
  fueling: IPersistenceFueling | null;
  apportionments: IPersistenceApportionment[] | null;
  observations_attachments: IPersistenceAttachment[] | null;
  created_at: string;
  updated_at: string;
  reversal_status: string | null;
}

export interface IPersistenceApportionment {
  id: number;
  uuid: string;
  percentual: string;
  project: {
    id: number;
    uuid: string;
    nome: string;
  };
  comments: null | string;
  created_at: string;
  updated_at: string;
}

export interface IPersistenceAttachment {
  name: string;
  url: string;
}

export interface IObservationAttachment {
  title: string;
  url: string;
}

interface IFuelImage {
  id: number;
  uuid: string;
  type: string;
  name: string;
  originalUrl: string;
  thumbnailUrl: string;
}

interface IFueling {
  odometerKilometrage: number | null;
  volume: number | null;
  amountPerLitre: number | null;
  vehicle: IVehicle | null;
  odometerImage: IFuelImage | null;
  fuel: IFuel | null;
  vehicleType: IVehicleType | null;
}

export interface IDetailedExpense {
  id: number;
  uuid: string;
  value: number;
  userId: number;
  userCreatorId: number;
  userCreatorName: string;
  routeId: string | null;
  cardNumber: string | null;
  title: string;
  date: string;
  canDelete: boolean;
  canEdit: boolean;
  isFromCards: boolean;
  isReimbursable: boolean;
  modifiedIntelliscanField: null | boolean;
  wasValidated: string;
  observation: string | null;
  exchangeRate: null | number;
  convertedValue: null | number;
  convertedCurrencyId: null | string;
  convertedCurrencyIso: null | string;
  conciliationBalance: null | number;
  readOcr: null;
  isReversal: string | null;
  isImported: boolean;
  gpsRouteStartTimezone?: string | null;
  gpsRouteFinalTimezone?: string | null;
  gpsRouteStartDateNaive?: string | null;
  gpsRouteFinalDateNaive?: string | null;
  type: {
    id: string;
    description: string;
  } | null;
  currency: {
    id: string;
    isoCode: CurrencyType;
  };
  kilometrage: string | null;
  amountPerKilometer: string | null;
  routeType: 'TRACKING' | 'MAPA' | null;
  routePoints:
    | null
    | [
        {
          location: string;
          placeId: string;
          search: string;
        }
      ];
  routePolyline: google.maps.DirectionsResult | null;
  isReconciled: boolean | null;
  paymentMethod: {
    id: string;
    description: string;
  } | null;
  fueling: IFueling | null;
  receipt: {
    type: 'PDF' | 'IMAGE';
    name: string | null;
    url: string;
  } | null;
  report: {
    id: number;
    uuid: string;
    description: string;
    status: ExpenseStatusType;
  } | null;
  costsCenter: {
    id: string;
    name: string;
  } | null;
  transaction: {
    id: number;
    amount: number | null;
    isInternational: boolean;
    finalInternationalAmount: number | null;
    finalBillingAmount: number | null;
  } | null;
  apportionments:
    | {
        project: {
          id: string;
          name: string;
        };
        value: number;
        percentage: number;
      }[]
    | undefined;
  observationAttachments: IObservationAttachment[] | null;
  createdAt: string; // data no formato 2024-02-22T00:00:00.000000Z
}

export type ApportionmentErrorType =
  | 'percentage-is-not-100'
  | 'sum-of-values'
  | 'duplicate-projects';

export interface IValidateDateLimitsByProjectPayload {
  date: string;
  projects: {
    id: string;
    percentage: number;
  }[];
  expenseUuid?: string;
}

export interface IValidateMileagePolicyByUserPayload {
  date: string;
  mileage: number;
  expenseUuid?: string;
}

export interface IPersistenceValidateMileagePolicyByUserPayload {
  route_uuid?: string;
  kilometrage: number;
  date: string;
}

export interface IValidateMileagePolicyByUserResponse {
  value: number;
  mileagePaidValue: number;
  observations: string;
}

export interface IPersistenceValidateMileagePolicyByUserResponse {
  amount: number;
  amount_per_kilometer: number;
  observations: string;
}

export interface IValidateMileagePolicyByProjectPayload {
  mileage: number;
  projectId: number | string;
}

export interface IPersistenceValidateMileagePolicyByProjectPayload {
  kilometrage: number;
  project_id: number;
}

export interface IValidateMileagePolicyByProjectResponse {
  value: number;
  mileagePaidValue: number;
  allowChangeAmountPerKm: boolean;
  requiredReceiptInManualRoute: boolean;
}

export interface IPersistenceValidateMileagePolicyByProjectResponse {
  amount: number;
  amount_per_kilometer: string;
  is_amount_per_kilometer_change_allowed: boolean;
  is_manual_route_receipt_mandatory: boolean;
}

export interface IPersistenceValidateDateLimitsByProjectPayload {
  date: string;
  projects: Record<string, number>;
  expenseUuid?: string;
}

export interface IValidateDateLimitsByProjectResponse {
  success: boolean;
  daysLimitAgo: number | null;
  daysLimitAhead: number | null;
}

export interface IPersistenceValidateDateLimitsByProjectResponse {
  success: boolean;
  days_limit_ago: number | null;
  days_limit_ahead: number | null;
}

export interface IUseValidateDateLimitsByProject {
  getEnsuredValidateDateLimitsByProject: (
    payload: IValidateDateLimitsByProjectPayload
  ) => Promise<IValidateDateLimitsByProjectResponse | null>;
}

export interface IUseValidateMileagePolicyByUser {
  getEnsuredValidateMileagePolicyByUser: (
    payload: IValidateMileagePolicyByUserPayload
  ) => Promise<IValidateMileagePolicyByUserResponse | null>;
}

export interface IUseValidateMileagePolicyByProject {
  getEnsuredValidateMileagePolicyByProject: (
    payload: IValidateMileagePolicyByProjectPayload
  ) => Promise<IValidateMileagePolicyByProjectResponse | null>;
}
