import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { IconButton } from 'presentation/components/base/Button';

import { type PaginationStylesType } from './Pagination.types';

export const Container = styled.div<AddPrefixToType<PaginationStylesType>>`
  align-items: center;
  color: ${({ theme }) => theme.colors.dustyGray};
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
  width: 100%;

  ${({ $breakpointToBreakInColumns, theme }) =>
    $breakpointToBreakInColumns &&
    css`
      @media screen and (max-width: ${theme.breakpoints[
          $breakpointToBreakInColumns
        ]}px) {
        flex-direction: column;
      }
    `};
`;

export const SwitchPageButton = styled(IconButton)`
  height: 4rem;
  width: 4rem;
`;

export const PagesContainer = styled.span`
  align-items: center;
  display: flex;
  margin-left: 1.5rem;
`;

export const CurrentPage = styled.p`
  color: ${({ theme }) => theme.colors.mineShaft};
  font-size: 1.8rem;
  padding: 0.4rem;
`;

export const PageDetails = styled.p`
  color: ${({ theme }) => theme.colors.doveGray};
  font-size: 1.4rem;
`;

export const PageSizeContainer = styled.span`
  color: ${({ theme }) => theme.colors.doveGray};
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 50rem;
  gap: 1rem;

  p {
    width: 20rem;
    word-break: break-all;
  }
`;
