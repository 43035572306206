import { DefaultTable } from 'ds';

import { useValueSolicitationContext } from 'data/contexts';

import {
  SolicitationActionsEmptyList,
  SolicitationActionsFilter,
  SolicitationActionsPagination,
  SolicitationActionsRow,
  SolicitationActionsTableHead
} from 'presentation/pages/cards/Management/ValueSolicitation/components/SolicitationActions/';

import { useSolicitationActionsTable } from './useSolicitationActionsTable';

import { type ISolicitationActionsTableProps } from './SolicitationActionsTable.types';

import { Container, TableContainer } from './SolicitationActionsTable.styles';

export function SolicitationActionsTable({
  handleSubmitSolicitations
}: ISolicitationActionsTableProps): JSX.Element {
  const {
    solicitation,
    balanceSolicitationConfirmParams,
    totalOfSolicitationsStaged,
    handleChangeSearchParam,
    sortArrayByDate
  } = useSolicitationActionsTable();

  const sortByDate = useValueSolicitationContext(state => state.sortByDate);

  return (
    <Container>
      <SolicitationActionsFilter
        handleSubmitSolicitations={handleSubmitSolicitations}
        balanceSolicitationConfirmParams={balanceSolicitationConfirmParams}
        handleChangeSearchParam={handleChangeSearchParam}
      />

      <TableContainer>
        <DefaultTable.Container>
          {!!solicitation.length && <SolicitationActionsTableHead />}

          <DefaultTable.Tbody>
            {sortArrayByDate(
              solicitation[balanceSolicitationConfirmParams.currentPage - 1],
              sortByDate
            )?.map(solicitation => (
              <SolicitationActionsRow
                solicitation={solicitation}
                key={solicitation.id}
              />
            ))}
          </DefaultTable.Tbody>
        </DefaultTable.Container>
      </TableContainer>

      {!solicitation.length && (
        <TableContainer>
          <SolicitationActionsEmptyList />
        </TableContainer>
      )}

      {!!solicitation.length && (
        <SolicitationActionsPagination
          balanceSolicitationConfirmParams={balanceSolicitationConfirmParams}
          totalOfSolicitationsStaged={totalOfSolicitationsStaged}
          handleChangeSearchParam={handleChangeSearchParam}
        />
      )}
    </Container>
  );
}
