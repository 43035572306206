import { useQueries } from '@tanstack/react-query';

import {
  type IPendency,
  type IUseGetPendencies,
  type IUseGetPendenciesParams,
  PendenciesQueryKeys,
  PendenciesService
} from 'data/modules/cards/pendencies';

export function useGetPendencies({
  accountBalancesIds
}: IUseGetPendenciesParams): IUseGetPendencies {
  const queriesParams = accountBalancesIds.map(accountBalanceId => ({
    queryKey: [PendenciesQueryKeys.GET_PENDENCIES_LIST, accountBalanceId],
    queryFn: async () =>
      await PendenciesService.getPendenciesListByAccountBalanceId({
        accountBalanceId
      })
  }));

  const results = useQueries({
    queries: [...queriesParams]
  });

  return {
    pendencies: results.reduce<IPendency[]>((acc, result) => {
      if (result.data) {
        return [...acc, ...result.data];
      }

      return acc;
    }, []),
    isLoadingPendencies: results.some(result => result.isFetching)
  };
}
