import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 5rem);
  padding: 2rem 3rem;
  overflow: auto;
  background: ${({ theme }) => theme.colors.alabaster};
  border: 1px solid ${({ theme }) => theme.colors.mercury};

  th,
  td {
    border: 1px solid ${({ theme }) => theme.colors.mineShaft};
    text-align: center;
    font-size: 1.3rem;
    padding: 1.2rem;
  }

  th {
    background: ${({ theme }) => theme.colors.gallery};
  }

  td {
    background: ${({ theme }) => theme.colors.alabaster};
  }
`;
