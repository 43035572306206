import {
  type IGetPaginatedCompanyVehiclesPayload,
  type IGetPaginatedCompanyVehiclesPersistencePayload
} from 'data/modules/fuel';

class GetPaginatedVehicles {
  toPersistence(
    payload: IGetPaginatedCompanyVehiclesPayload
  ): IGetPaginatedCompanyVehiclesPersistencePayload {
    return {
      text_search: payload.search,
      vehicle_type_id: payload.vehicleType,
      owner: payload.owner,
      fuel_id: payload.fuelType,
      order_by: payload.orderBy,
      order_direction: payload.order,
      per_page: payload.perPage,
      page: payload.page,
      plate: payload.plate
    };
  }
}

export default new GetPaginatedVehicles();
