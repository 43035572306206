import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuthContext, useFlagsContext } from 'data/contexts';

import {
  AvailableValueCardInfo,
  CardsContainerPageHeader,
  CardsPageHeader,
  ChargeCardsCardInfo,
  DepositAndWithdrawCardInfo,
  DistributedValueCardInfo
} from 'presentation/pages/cards/components';

export function CardListPageHeader(): JSX.Element {
  const adminMovementsFlag = useFlagsContext(state => state.adminMovementsFlag);
  const user = useAuthContext(state => state.user);

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CardsPageHeader
      tabs={[
        {
          active: true,
          label: t('cardsManagement'),
          className: 'cards-tour-navigate'
        },
        {
          active: false,
          label: t('movements'),
          className: 'cards-tour-movement',
          hidden: user?.cards?.type === 'USER',
          onClick: () => {
            adminMovementsFlag
              ? navigate('/cartoes/movimentacoes')
              : navigate('/cartoes/extrato');
          }
        },
        {
          active: false,
          label: t('valuesSolicitations'),
          className: 'cards-tour-value-solicitation',
          hidden: user?.cards?.type !== 'ACCOUNT_MANAGER',
          onClick: () => {
            navigate('/cartoes/solicitacoes-de-valores');
          }
        },
        {
          active: false,
          label: t('myCard'),
          className: 'cards-tour-my-card',
          hidden: !user?.cards?.hasAccount,
          onClick: () => {
            navigate('/cartoes/meu-cartao');
          }
        }
      ]}
    >
      {user?.cards?.type !== 'USER' && (
        <CardsContainerPageHeader>
          <AvailableValueCardInfo />

          <DistributedValueCardInfo />
        </CardsContainerPageHeader>
      )}

      {user?.cards?.type === 'ACCOUNT_MANAGER' && (
        <CardsContainerPageHeader>
          <DepositAndWithdrawCardInfo />

          <ChargeCardsCardInfo />
        </CardsContainerPageHeader>
      )}
    </CardsPageHeader>
  );
}
