import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  type IUpdatePoliciesAndTermsFieldsForm,
  type IUpdateUserOnboardingAnswersPayload,
  type IUseUpdatePoliciesAndTerms,
  type IUseUpdatePoliciesAndTermsParams,
  UserOnboardingMutationKeys,
  UserOnboardingQueryKeys,
  UserOnboardingService
} from 'data/modules/cards/userOnboarding';

import { useFormWithSchema } from 'shared/hooks/forms';
import { useHandleQueryCache } from 'shared/hooks/global';

import { useUpdatePoliciesAndTermsSchema } from './schemas';

export function useUpdatePoliciesAndTerms({
  onUpdatePoliciesAndTermsCache
}: IUseUpdatePoliciesAndTermsParams): IUseUpdatePoliciesAndTerms {
  const { data: cachedUserOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () =>
        await UserOnboardingService.getUserOnboardingProgress(),
      key: [UserOnboardingQueryKeys.GET_USER_ONBOARDING_PROGRESS]
    }
  });

  const { data: cachedUserOnboardingAnswers } = useHandleQueryCache({
    query: {
      key: [
        UserOnboardingQueryKeys.GET_USER_ONBOARDING_ANSWERS,
        cachedUserOnboardingProgress?.onboardingUserId ?? ''
      ],
      query: async () =>
        await UserOnboardingService.getUserOnboardingAnswers({
          onboardingUserId: cachedUserOnboardingProgress?.onboardingUserId ?? ''
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedUserOnboardingProgress?.onboardingUserId ?? ''
    }
  });

  const {
    control,
    reset,
    formState: { errors, isDirty },
    getValues,
    handleSubmit
  } = useFormWithSchema(useUpdatePoliciesAndTermsSchema(), {
    defaultValues: {
      privacyPolicy:
        cachedUserOnboardingAnswers?.userTermsOfUse?.acceptPrivacyPolicy ??
        false,
      termsOfUse:
        cachedUserOnboardingAnswers?.userTermsOfUse?.acceptTermsOfUse ?? false
    }
  });

  const { mutate, isLoading, isError } = useMutationCache({
    mutationKey: [UserOnboardingMutationKeys.UPDATE_USER_ONBOARDING],
    mutationFn: async (payload: IUpdateUserOnboardingAnswersPayload) =>
      await UserOnboardingService.updateUserOnboardingAnswers(payload),
    onSuccess: () => {
      onUpdatePoliciesAndTermsCache({
        privacyPolicy: getValues('privacyPolicy'),
        termsOfUse: getValues('termsOfUse')
      });
    },

    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function onSubmit(payload: IUpdatePoliciesAndTermsFieldsForm): void {
    if (!cachedUserOnboardingProgress || isLoading) {
      return;
    }

    if (!isDirty) {
      onUpdatePoliciesAndTermsCache(getValues());
      return;
    }

    mutate({
      onboardingUserId: cachedUserOnboardingProgress.onboardingUserId,
      step: 1,
      context: 'user_terms_of_use',
      answers: {
        acceptPrivacyPolicy: payload.privacyPolicy,
        acceptTermsOfUse: payload.termsOfUse
      }
    });
  }

  return {
    isUpdatingPoliciesAndTerms: isLoading,
    updatePoliciesAndTerms: handleSubmit(onSubmit),
    reset,
    control,
    errors,
    isError
  };
}
