import { tokens } from 'ds';
import styled from 'styled-components';

import { Container as SuggestionFormLabel } from './components/SuggestionFormLabel/SuggestionFormLabel.styles';

export const Container = styled.div`
  display: grid;
  gap: ${tokens.spacingXxl};
  padding: ${tokens.spacingLg};
  padding-bottom: ${tokens.spacingGiant};
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media screen and (min-width: 834px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const DisclaimerContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: ${tokens.spacingXxl};

  > svg {
    padding: 0 ${tokens.spacingXxl};
  }

  @media screen and (max-width: 834px) {
    > svg {
      display: none;
    }
  }
`;

export const DisclaimerWrapper = styled.div`
  display: grid;
  width: 100%;
  row-gap: ${tokens.spacingXs};
`;

export const DisclaimerText = styled.p`
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.neutralColorLowDark};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamilyPoppins};
  white-space: pre-line;
`;

export const DisclaimerTip = styled.span`
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.highlightColorMedium};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamilyPoppins};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};

  ${SuggestionFormLabel} {
    padding-bottom: ${tokens.spacingNano};
  }

  .two-rows {
    height: 8rem;

    textarea {
      min-height: unset;
    }
  }
`;

export const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${tokens.spacingXs};

  > p {
    line-height: 2.4rem;
    letter-spacing: 0.075rem;
    font-size: ${tokens.fontSizeXxs};
    color: ${tokens.neutralColorLowLight};
    font-weight: ${tokens.fontWeightMedium};
    font-family: ${tokens.fontFamilyPoppins};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${tokens.spacingXs};

    > button {
      width: 100%;
    }
  }
`;
