import styled from 'styled-components';

import CardBackground from 'shared/assets/images/cards/new-card-background.svg';

export const Container = styled.div`
  background-image: url(${CardBackground});
  background-size: cover;
  width: 31rem;
  height: 19.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const LoaderContainer = styled.div`
  background-image: url(${CardBackground});
  background-size: cover;
  width: 31rem;
  height: 19.5rem;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
`;

export const NameNumberStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const NameNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.span`
  color: white;
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxs};
  font-weight: ${({ theme }) => theme.tokens.fontWeightSemibold};
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Number = styled.p`
  color: white;
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxs};
  font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
`;

export const ValueLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VerticalDivider = styled.div`
  border: 0.9px solid ${({ theme }) => theme.colors.neutralColorHighMedium};
`;

export const Value = styled.p`
  color: white;
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-size: ${({ theme }) => theme.tokens.fontSizeXs};
  font-weight: ${({ theme }) => theme.tokens.fontWeightSemibold};
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
`;

export const Label = styled.p`
  color: white;
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  white-space: nowrap;
`;
