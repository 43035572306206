import { apiWeb } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import {
  type IAnalysis,
  type IAnalysisResume,
  type IAnalysisResumePayload,
  type IAnalysisUserFilters,
  type IAnalysisUserHeader,
  type IAnalysisUserHeaderPagination,
  type IAnalysisUserHeaderPayload,
  type IAnalysisUserList,
  type IAnalysisUserListPagination,
  type IAnalysisUserListPayload,
  type IAnalysisUserReportId,
  type IPersistenceAnalysis,
  type IPersistenceAnalysisResume,
  type IPersistenceAnalysisUserFilters,
  type IPersistenceAnalysisUserHeader,
  type IPersistenceAnalysisUserList,
  type ISendAnalysisSuggestionPayload
} from 'data/modules/analytics/types/analytics.types';
import { type IDefaultResponse } from 'data/modules/global';

import {
  GetAnalysisMapper,
  GetAnalysisResumeMapper,
  GetAnalysisUserFiltersMapper,
  GetAnalysisUserHeaderMapper,
  GetAnalysisUserListMapper,
  SendAnalysisSuggestionMapper
} from './mappers';

class AnalyticsService {
  async getAnalysis(): Promise<IAnalysis> {
    const response =
      await apiWeb.get<IDefaultResponse<IPersistenceAnalysis>>('/analysis');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetAnalysisMapper.toDomain(data);
  }

  async sendAnalysisSuggestion(
    payload: ISendAnalysisSuggestionPayload
  ): Promise<null> {
    const response = await apiWeb.post<IDefaultResponse<null>>(
      '/analysis/feedback',
      SendAnalysisSuggestionMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getAnalysisUserFilters(): Promise<IAnalysisUserFilters> {
    const response = await apiWeb.get<
      IDefaultResponse<IPersistenceAnalysisUserFilters>
    >('/analysis/users/filters');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetAnalysisUserFiltersMapper.toDomain(data);
  }

  async getAnalysisUserReportsId(): Promise<IAnalysisUserReportId[]> {
    const response = await apiWeb.get<
      IDefaultResponse<IAnalysisUserReportId[]>
    >('/analysis/users/reports');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getAnalysisResume(
    payload: IAnalysisResumePayload
  ): Promise<IAnalysisResume> {
    const response = await apiWeb.get<
      IDefaultResponse<IPersistenceAnalysisResume>
    >('/analysis/users/resume', {
      params: GetAnalysisResumeMapper.toPersistence(payload)
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetAnalysisResumeMapper.toDomain(data);
  }

  async getAnalysisUserHeader(
    payload: IAnalysisUserHeaderPayload
  ): Promise<[IAnalysisUserHeader[], IAnalysisUserHeaderPagination]> {
    const response = await apiWeb.get<
      IDefaultResponse<IPersistenceAnalysisUserHeader[]>
    >('/analysis/users/header', {
      params: GetAnalysisUserHeaderMapper.toPersistence(payload)
    });

    const { data, to, from, total, lastPage, currentPage } =
      ResponseAdapter.formatRegularResponse(response.data);

    return [
      data?.map(GetAnalysisUserHeaderMapper.toDomain) ?? [],
      {
        to,
        from,
        total,
        lastPage,
        currentPage
      }
    ];
  }

  async getAnalysisUserList(
    payload: IAnalysisUserListPayload
  ): Promise<[IAnalysisUserList[], IAnalysisUserListPagination]> {
    const response = await apiWeb.get<
      IDefaultResponse<IPersistenceAnalysisUserList[]>
    >('/analysis/users/list', {
      params: GetAnalysisUserListMapper.toPersistence(payload)
    });

    const { data, to, from, total, lastPage, currentPage } =
      ResponseAdapter.formatRegularResponse(response.data);

    return [
      data?.map(GetAnalysisUserListMapper.toDomain) ?? [],
      {
        to,
        from,
        total,
        lastPage,
        currentPage
      }
    ];
  }
}

export default new AnalyticsService();
