import { Fragment } from 'react';

import { SkeletonLoader } from 'ds';

import { useLangContext } from 'data/contexts';

import { CustomDate } from 'shared/utils/custom';
import { CardNumber, Currency } from 'shared/utils/format';

import { useLatestTransactionsList } from './useLatestTransactionsList';

import {
  CardTransactionItem,
  Container,
  Date,
  Divider,
  NoTransactionsMessage,
  StyledCardNumber,
  TitleAndCardNumberContainer,
  TransactionTitle,
  Value,
  ValueAndDateContainer
} from './LatestTransactionsList.styles';

export function LatestTransactionsList(): JSX.Element {
  const { latestTransactions, isLoading } = useLatestTransactionsList();
  const {
    lang: { cards },
    currentLangKey
  } = useLangContext();

  if (isLoading) {
    return (
      <Container>
        <CardTransactionItem>
          <TitleAndCardNumberContainer>
            <TransactionTitle>
              <SkeletonLoader width='40rem' />
            </TransactionTitle>

            <StyledCardNumber>
              <SkeletonLoader width='10rem' />
            </StyledCardNumber>
          </TitleAndCardNumberContainer>

          <ValueAndDateContainer>
            <Value>
              <SkeletonLoader width='10rem' />
            </Value>

            <Date>
              <SkeletonLoader width='5rem' />
            </Date>
          </ValueAndDateContainer>
        </CardTransactionItem>
      </Container>
    );
  }

  return (
    <Container>
      {latestTransactions.length <= 0 && (
        <NoTransactionsMessage>
          {cards.management.card_details.no_latest_transactions[currentLangKey]}
        </NoTransactionsMessage>
      )}

      {latestTransactions.length > 0 &&
        latestTransactions.map((transaction, index) => (
          <Fragment key={`${transaction.cardNumber}-${index}`}>
            <CardTransactionItem>
              <TitleAndCardNumberContainer>
                <TransactionTitle title={transaction.paymentDescription}>
                  {transaction.paymentDescription}
                </TransactionTitle>

                <StyledCardNumber>
                  {CardNumber.format(transaction.cardNumber as string)}
                </StyledCardNumber>
              </TitleAndCardNumberContainer>

              <ValueAndDateContainer>
                <Value>{`R$ ${Currency.format(
                  'BRL',
                  transaction.finalBillingAmount
                )}`}</Value>

                <Date>{CustomDate.formatFromISO(transaction.datetime)}</Date>
              </ValueAndDateContainer>
            </CardTransactionItem>

            <Divider />
          </Fragment>
        ))}
    </Container>
  );
}
