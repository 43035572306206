import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import {
  type TabButtonStylesType,
  type TabContainerStylesType
} from './NavigationTabsTab.types';

export const Container = styled.li<AddPrefixToType<TabContainerStylesType>>`
  ${({ $containerWidth }) =>
    $containerWidth &&
    css`
      width: ${$containerWidth};
    `}
`;

export const TabButton = styled.button<AddPrefixToType<TabButtonStylesType>>`
  width: 100%;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.gallery};
  border: 1px solid ${({ theme }) => theme.colors.gallery};
  border-top: 1px solid ${({ theme }) => theme.colors.gallery};
  border-left: 1px solid ${({ theme }) => theme.colors.gallery};
  border-right: 1px solid ${({ theme }) => theme.colors.gallery};
  border-bottom: none;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.emperor};
  transition: background 0.15s;

  &:hover {
    background: ${({ theme }) => darken(0.05, theme.colors.gallery)};
  }

  ${({ $active }) =>
    $active &&
    css`
      background: ${({ theme }) => theme.colors.white};
      font-weight: 700;

      &:hover {
        background: ${({ theme }) => theme.colors.white};
      }
    `}
`;
