import { isValid, parse } from 'date-fns';

class Validate {
  isPinStrong(value: string): boolean {
    const digits = value.split('').map(Number);
    const isAscending =
      digits.join('') === [...digits].sort((a, b) => a - b).join('') &&
      digits.every(
        (digit, index) => index === 0 || digit === digits[index - 1] + 1
      );
    const isDescending =
      digits.join('') === [...digits].sort((a, b) => b - a).join('') &&
      digits.every(
        (digit, index) => index === 0 || digit === digits[index - 1] - 1
      );
    const isRepetead = digits.every(digit => digit === digits[0]);

    return !isAscending && !isDescending && !isRepetead;
  }

  cpf(cpf: string): boolean {
    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf.length !== 11) {
      return false;
    }

    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }

    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }

    let remainder = sum % 11;
    const digit1 = remainder < 2 ? 0 : 11 - remainder;

    if (parseInt(cpf.charAt(9)) !== digit1) {
      return false;
    }

    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }

    remainder = sum % 11;
    const digit2 = remainder < 2 ? 0 : 11 - remainder;

    if (parseInt(cpf.charAt(10)) !== digit2) {
      return false;
    }

    return true;
  }

  fullName(name: string): boolean {
    const words = name.trim().split(' ');

    return words.length > 1;
  }

  isDate(date: string, formatString = 'dd/MM/yyyy'): boolean {
    if (formatString === 'dd/MM/yyyy') {
      const receivedYear = date.split('/').pop();

      if (receivedYear && receivedYear.length < 4) {
        return false;
      }
    }

    const parsed = parse(date, formatString, new Date());
    return isValid(parsed);
  }

  cnpj(cnpj: string): boolean {
    cnpj = cnpj?.replace(/[^\d]+/g, '');

    if (!cnpj || cnpj === '') return false;

    if (cnpj.length !== 14) return false;

    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    )
      return false;

    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    const digits = cnpj.substring(size);
    let sum = 0;
    let position = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += parseInt(numbers.charAt(size - i)) * position--;
      if (position < 2) position = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) return false;

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    position = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += parseInt(numbers.charAt(size - i)) * position--;
      if (position < 2) position = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(1))) return false;

    return true;
  }

  isFutureDate(date: string, formatString = 'dd/MM/yyyy'): boolean {
    const parsed = parse(date, formatString, new Date());
    return isValid(parsed) && parsed > new Date();
  }

  isInteger(value: string): boolean {
    const formattedValue = value.replaceAll('.', '');

    return Number.isInteger(Number(formattedValue));
  }

  isBiggerThanZero(value: string): boolean {
    const formattedValue = value.replaceAll('.', '');

    return Number(formattedValue) > 0;
  }

  isOptionSelected(
    value:
      | undefined
      | null
      | {
          value?: string;
          label?: string;
        }
  ): boolean {
    return !!value && !!value.value && !!value.label;
  }
}

export default new Validate();
