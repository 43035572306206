import { useQueryCache } from 'data/cache';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUseGetLegalRepresentativeToApproval,
  type IUseGetLegalRepresentativeToApprovalParams
} from 'data/modules/cards/companyOnboarding';

export function useGetLegalRepresentativeToApproval({
  hashCode,
  enabled = true
}: IUseGetLegalRepresentativeToApprovalParams): IUseGetLegalRepresentativeToApproval {
  const {
    data: representativeToApproval,
    isInitialLoading: isLoadingRepresentativeToApproval,
    isError: isErrorRepresentativeToApproval,
    error: errorRepresentativeToApproval
  } = useQueryCache({
    queryKey: [
      CompanyOnboardingQueryKeys.GET_LEGAL_REPRESENTATIVE_TO_APPROVAL,
      hashCode
    ],
    queryFn: async () =>
      await CompanyOnboardingService.getLegalRepresentativeToApproval({
        hashCode
      }),
    enabled
  });
  return {
    representativeToApproval,
    isLoadingRepresentativeToApproval,
    isErrorRepresentativeToApproval,
    errorRepresentativeToApproval
  };
}
