import { format, sub } from 'date-fns';
import { useShallow } from 'zustand/react/shallow';

import { useCardDetailsContext } from 'data/contexts';
import { useGetAccountStatement } from 'data/modules/cards/statement';

import { CustomDate } from 'shared/utils/custom';

import { type IUseLatestTransactionsList } from './LatestTransactionsList.types';

export function useLatestTransactionsList(): IUseLatestTransactionsList {
  const [selectedAccount] = useCardDetailsContext(
    useShallow(state => [state.selectedAccount])
  );

  const params = {
    startDate: format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd').concat(
      'T00:00:00.000Z'
    ),
    endDate: format(new Date(), 'yyyy-MM-dd').concat('T00:00:00.000Z'),
    timezone: CustomDate.getTimezone()
  };

  const {
    accountsStatements,
    isLoadingAccountsStatements,
    isFetchingAccountsStatements
  } = useGetAccountStatement({
    accountBalancesIds: [selectedAccount?.balanceId || ''],
    ...params
  });

  const latestTransactions =
    accountsStatements.length > 0
      ? accountsStatements[0].items.slice(0, 4)
      : [];

  return {
    latestTransactions,
    isLoading: isLoadingAccountsStatements || isFetchingAccountsStatements
  };
}
