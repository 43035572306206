import {
  type IPersistenceUserOnboardingProgress,
  type IUserOnboardingProgress
} from 'data/modules/cards/userOnboarding';

class GetUserOnboardingProgressMapper {
  toDomain(
    payload: IPersistenceUserOnboardingProgress
  ): IUserOnboardingProgress {
    return {
      userId: payload.user_id,
      onboardingUserId: payload.onboarding_user_id,
      currentStep: payload.current_step,
      createdAt: payload.created_at,
      updatedAt: payload.updated_at
    };
  }
}

export default new GetUserOnboardingProgressMapper();
