import axios from 'axios';

import { ErrorHandle } from 'shared/utils/errors';

import { env } from './env.config';

// Access Token gerado pelo set-access-token.cjs
const accessToken = env.VITE_ACCESS_TOKEN;

// Pega domínio e seta baseURL de acordo com ele (staging/prod/dev)
const domain = window.location.hostname
  .replace('amp.', 'app.')
  .replace('app.', 'api.');
const prodBaseURL = `https://${domain}/v3`;

/** Domínio do VExpenses que não está no amplify */
// se estiver em desenvolvimento, pega o domínio local
export const vexDomain =
  process.env.NODE_ENV === 'development'
    ? `http://${window.location.hostname}:${window.location.port}`
    : `https://${window.location.hostname.replace('amp', 'app')}`;

// Instância do Axios para desenvolvimento local
const apiLocal = axios.create({
  baseURL: env.VITE_API_LOCALHOST,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'X-Header-FrontLocalOrigin': `http://${window.location.host}`
  }
});

// Instância do Axios para desenvolvimento em prod, staging e dev
const apiProd = axios.create({
  baseURL: prodBaseURL,
  withCredentials: true,
  headers: {
    Accept: 'application/json'
  }
});

// Verifica qual o ambiente e exporta as instâncias baseado nisso
const api = process.env.NODE_ENV === 'development' ? apiLocal : apiProd;

api.interceptors.response.use(ErrorHandle.apiError, ErrorHandle.defaultError);

export default api;
