import styled from 'styled-components';

import { RectButton } from 'presentation/components/base/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.white};

  > div:first-child {
    margin-right: 2rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;

    > div:first-child {
      margin-right: 0;
    }
  }
`;

export const SelectProjectContainer = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gallery};

  > button {
    margin-top: 2rem;
  }
`;

export const FormInputs = styled.div`
  min-width: 35rem;
  padding: 1.5rem;

  > div {
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    max-width: 37rem;
  }
`;

export const CustomButton = styled(RectButton)`
  width: fit-content;
`;

export const ActionsList = styled.ul`
  min-width: 25rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 1.5rem;
  margin: 1rem 0;

  li {
    list-style-type: disc;
    font-size: 1.2rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.emperor};
    margin: 0.4rem 0;

    &:first-child {
      list-style-type: none;
      margin-bottom: 1rem;
      margin-left: -1rem;
    }
  }
`;
