import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue
} from 'react-hook-form';

import { type ISwitchProps } from './Switch.types';

import {
  Container,
  Label,
  SwitchBase,
  SwitchContainer,
  SwitchPointer
} from './Switch.styles';

export function Switch<T extends FieldValues>({
  initialValue,
  label,
  labelPosition,
  name,
  value = initialValue,
  onChangeValue,
  control
}: ISwitchProps<T>): JSX.Element {
  if (control) {
    return (
      <Controller
        name={name as Path<T>}
        control={control}
        defaultValue={initialValue as PathValue<T, Path<T>>}
        render={({ field: { value, onChange } }) => (
          <Container>
            <input
              type='checkbox'
              name={name}
              id={name}
              checked={value}
              onChange={onChange}
            />

            <Label
              htmlFor={name}
              $position={labelPosition}
            >
              <SwitchContainer $isDisabled={!value}>
                <SwitchBase $isActive={value} />
                <SwitchPointer $isActive={value} />
              </SwitchContainer>
              {label}
            </Label>
          </Container>
        )}
      />
    );
  }

  return (
    <Container>
      <input
        type='checkbox'
        name={name}
        id={name}
        checked={value}
        onChange={onChangeValue}
      />

      <Label
        htmlFor={name}
        $position={labelPosition}
      >
        <SwitchContainer $isDisabled={!value}>
          <SwitchBase $isActive={value} />
          <SwitchPointer $isActive={value} />
        </SwitchContainer>
        {label}
      </Label>
    </Container>
  );
}
