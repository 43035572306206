import { useDsGa4 } from 'ds/hooks/globals';
import { useController, useFormContext } from 'react-hook-form';

import { type ICustomRadioItemControlledContainer } from './CustomRadioItemControlledContainer.types';

import {
  CheckIndicator,
  Container,
  InputComponent,
  Label,
  LabelContainer,
  RadioComponent
} from './CustomRadioItemControlledContainer.styles';

export function CustomRadioItemControlledContainer({
  children,
  name,
  inputId,
  onCustomClick,
  inputValue,
  ...rest
}: ICustomRadioItemControlledContainer): JSX.Element {
  const { control } = useFormContext();

  const {
    field: { value, onChange }
  } = useController({
    control,
    name
  });

  const { sendDsGaEvent } = useDsGa4();

  return (
    <Container {...rest}>
      <InputComponent
        type='radio'
        name={name}
        id={inputId}
        onClick={() => {
          onChange(inputValue);
          sendDsGaEvent('components', 'list_relatorio', {
            eventValue: inputValue
          });
          onCustomClick?.();
        }}
        checked={value === inputValue}
        readOnly
      />

      <LabelContainer>
        <RadioComponent>
          <CheckIndicator />
        </RadioComponent>

        <Label>{children}</Label>
      </LabelContainer>
    </Container>
  );
}
