import { CardInfo } from 'ds';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetAllocatedBalance } from 'data/modules/cards/balance';

import { Currency } from 'shared/utils/format';

import { type IDistributedValueCardInfoProps } from './DistributedValueCardInfo.types';

import { CardInfoContainer } from './DistributedValueCardInfo.styles';

export function DistributedValueCardInfo({
  notHasIcon,
  value,
  isLoading,
  hasCardGroup,
  ...rest
}: IDistributedValueCardInfoProps): JSX.Element {
  const { t } = useTranslation();
  const { groupId } = useParams();

  const {
    allocatedBalance,
    isLoadingAllocatedBalance,
    refreshAllocatedBalance
  } = useGetAllocatedBalance({
    ...(hasCardGroup && groupId && { cardGroupId: groupId })
  });

  return (
    <CardInfoContainer {...rest}>
      <CardInfo.Title
        {...(notHasIcon ? {} : { icon: 'MoneyWalletCheckMark' })}
        variant='small'
      >
        {t('amountDistributedOnCards')}
      </CardInfo.Title>

      <CardInfo.ValueContainer>
        <CardInfo.Value
          isLoading={
            isLoadingAllocatedBalance || !allocatedBalance || isLoading
          }
          variant='small'
        >
          {value || value === 0
            ? Currency.format('BRL', value ?? 0, true)
            : allocatedBalance &&
              Currency.format('BRL', allocatedBalance?.amount, true)}
        </CardInfo.Value>

        {!value && !isLoading && (
          <CardInfo.RefreshButton
            onClick={() => {
              !isLoadingAllocatedBalance && refreshAllocatedBalance.mutate(1);
            }}
          />
        )}
      </CardInfo.ValueContainer>

      {value ? (
        <CardInfo.Caption variant='small'>&nbsp;</CardInfo.Caption>
      ) : (
        <CardInfo.Caption
          isLoading={
            value ? isLoadingAllocatedBalance || !allocatedBalance : isLoading
          }
          variant='small'
        >
          {allocatedBalance &&
            t('updatedOnDateAtHours', {
              date: allocatedBalance.lastUpdateDate,
              hours: allocatedBalance.lastUpdateTime
            })}
        </CardInfo.Caption>
      )}
    </CardInfoContainer>
  );
}
