import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue
} from 'react-hook-form';
import {
  components,
  type ControlProps,
  createFilter,
  type CSSObjectWithLabel
} from 'react-select';

import { type IDsSelectProps } from './DsSelect.types';

import {
  colourStyles,
  Container,
  ErrorMessage,
  Label,
  StyledSelect
} from './DsSelect.styles';

export function DsSelect<T extends FieldValues>({
  options,
  label = 'Selecione uma opção',
  disabled = false,
  multiple = false,
  customStyle,
  ref,
  value,
  defaultValue,
  error = '',
  control,
  name,
  ...rest
}: IDsSelectProps<T>): JSX.Element {
  const inputToRender = (
    value?: string,
    onChange?: (value: unknown) => void,
    name?: string
  ): JSX.Element => (
    <Container>
      <StyledSelect
        filterOption={createFilter({ ignoreAccents: false })}
        options={options}
        name={name}
        $error={error}
        ref={ref}
        $label={value ?? defaultValue}
        value={value}
        defaultValue={defaultValue}
        data-testid='select-input'
        isDisabled={disabled}
        classNamePrefix={'react-select'}
        placeholder={label}
        components={{
          Control: (props: ControlProps): JSX.Element => (
            <>
              {(value ?? defaultValue) && (
                <Label
                  $disabled={disabled}
                  $error={error}
                >
                  {label}
                </Label>
              )}
              <components.Control {...props} />
            </>
          )
        }}
        styles={{
          ...colourStyles,
          ...customStyle,
          menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 99999 })
        }}
        isMulti={multiple}
        onChange={onChange}
        {...rest}
      />
    </Container>
  );

  if (!control) {
    return inputToRender(value);
  }

  return (
    <div>
      <Controller
        name={name as Path<T>}
        control={control}
        defaultValue={defaultValue as PathValue<T, Path<T>>}
        render={({ field: { name, onChange, value } }) =>
          inputToRender(value, onChange as (val: unknown) => void, name)
        }
      />

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
}
