import { useQueryCache } from 'data/cache';
import {
  AdvancePaymentsQueryKeys,
  AdvancePaymentsService,
  type IUseGetAdvancePayments
} from 'data/modules/advancePayments';

export function useGetAdvancePayments(): IUseGetAdvancePayments {
  const { data: advancePayments, isLoading: isLoadingAdvancePayments } =
    useQueryCache({
      queryKey: [AdvancePaymentsQueryKeys.GET_ADVANCE_PAYMENTS],
      queryFn: async () => await AdvancePaymentsService.getAdvancePayments()
    });

  return {
    advancePayments,
    isLoadingAdvancePayments
  };
}
