import { useRef, useState } from 'react';

import {
  useGetPaginatedMembers,
  useInfinitePaginatedMembers
} from 'data/modules/fuel';

import { useDebounce, useInfiniteScroll } from 'shared/hooks/global';

import { type IUseInfiniteScrollSelectMembers } from './InfiniteScrollSelectMembers.types';

export function useInfiniteScrollSelectMembers(): IUseInfiniteScrollSelectMembers {
  const infiniteScrollRef = useRef<null | HTMLDivElement>(null);
  const rootRef = useRef<null | HTMLDivElement>(null);

  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search);

  const { paginatedMembers } = useGetPaginatedMembers({
    page: 1
  });

  const [selectedMembers, setSelectedMembers] = useState(0);

  const { isLoadingInfiniteMembers, membersPagination, infiniteMembers } =
    useInfinitePaginatedMembers({
      perPage: 30,
      search: debouncedSearch
    });

  useInfiniteScroll({
    hasNextPage: membersPagination.hasNextPage,
    isFetchingNextPage: membersPagination.isFetchingNextPage,
    actionFn: membersPagination.fetchNextPage,
    infiniteScrollRef: infiniteScrollRef.current,
    rootRef: rootRef.current,
    rootMargin: '30%'
  });

  function handleChangeSearch(value: string): void {
    setSearch(value);
  }

  const showSelectAll = debouncedSearch === '';

  return {
    infiniteScrollRef,
    rootRef,
    infiniteMembers,
    isLoadingInfiniteMembers,
    isFetchingInfiniteMembersNextPage: membersPagination.isFetchingNextPage,
    handleChangeSearch,
    search,
    showSelectAll,
    totalMembers: paginatedMembers?.total ?? 0,
    selectedMembers,
    setSelectedMembers
  };
}
