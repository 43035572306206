import { SkeletonLoader } from 'ds';

import { type ICardInfoValueProps } from './CardInfoValue.types';

import { Container } from './CardInfoValue.styles';

export function CardInfoValue({
  variant = 'medium',
  children,
  isLoading = false,
  ...rest
}: ICardInfoValueProps): JSX.Element {
  const SkeletonSizes = {
    small: '18px',
    medium: '36px',
    large: '47.5px'
  };

  return (
    <Container
      $variant={variant}
      {...rest}
    >
      {!isLoading && children}

      {isLoading && (
        <SkeletonLoader
          height={SkeletonSizes[variant]}
          width='20rem'
          max-width='80%'
        />
      )}
    </Container>
  );
}
