import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import {
  OnboardingFooter,
  OnboardingSubtitle,
  OnboardingText,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import {
  Container,
  Divider,
  IconTextContainer,
  InfoContainer,
  InfoSectionTitle,
  TitleTextContainer
} from './FirstScreen.styles';

export function FirstScreen(): JSX.Element {
  const navigate = useNavigate();

  const [firstScreen, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.user.first_screen,
    state.currentLangKey
  ]);

  return (
    <Container>
      <TitleTextContainer>
        <OnboardingTitle>{firstScreen.title[currentLangKey]}</OnboardingTitle>

        <OnboardingText>{firstScreen.message[currentLangKey]}</OnboardingText>
      </TitleTextContainer>

      <Divider />

      <InfoContainer>
        <InfoSectionTitle>
          {firstScreen.important_info.title[currentLangKey]}
        </InfoSectionTitle>

        <IconTextContainer>
          <BaseIcon
            name='profile'
            color='primary'
            type='line'
            size={2.4}
          />
          <OnboardingSubtitle>
            {firstScreen.important_info.required_data[currentLangKey]}
          </OnboardingSubtitle>
        </IconTextContainer>

        <IconTextContainer>
          <BaseIcon
            name='clock'
            color='primary'
            type='line'
            size={2.4}
          />
          <OnboardingSubtitle>
            {firstScreen.important_info.estimated_time[currentLangKey]}
          </OnboardingSubtitle>
        </IconTextContainer>
      </InfoContainer>

      <OnboardingFooter
        continueButtonProps={{
          onClick: () => {
            navigate('/cartoes/onboarding/usuario/1');
          }
        }}
      />
    </Container>
  );
}
