import { useQueryCache } from 'data/cache';
import {
  AnalyticsQueryKeys,
  AnalyticsService,
  type IUseGetAnalysisResume,
  type IUseGetAnalyticsResumeParams
} from 'data/modules/analytics';

export function useGetAnalysisResume(
  params: IUseGetAnalyticsResumeParams
): IUseGetAnalysisResume {
  const { data, isLoading } = useQueryCache({
    queryKey: [AnalyticsQueryKeys.GET_ANALYSIS_RESUME, params],
    queryFn: async () => await AnalyticsService.getAnalysisResume(params)
  });

  return {
    resume: data,
    isLoadingAnalysisResume: isLoading
  };
}
