import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  useUpdateCompanyData
} from 'data/modules/cards/companyOnboarding';

import { cnae, legalNature } from 'shared/constants/global';
import { useHandleQueryCache } from 'shared/hooks/global';
import OnboardingUtil from 'shared/utils/cards/Onboarding.util';

import {
  type IUpdateCacheAndNavigateNextStepParams,
  type IUseCompanyData
} from './CompanyData.types';

export function useCompanyData(): IUseCompanyData {
  const navigate = useNavigate();
  const currentStep = Number(OnboardingUtil.getCurrentStep(location.pathname));

  const {
    data: cachedCompanyOnboardingProgress,
    updateCache: updateCurrentStepCache
  } = useHandleQueryCache({
    query: {
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS],
      query: async () => await CompanyOnboardingService.getProgress()
    }
  });

  const { updateCache, data: cachedCompanyOnboardingAnswers } =
    useHandleQueryCache({
      query: {
        key: [
          CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
          cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
        ],
        query: async () =>
          await CompanyOnboardingService.getCompanyOnboardingAnswers({
            uuidCompanyOnboarding:
              cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
          })
      },
      params: {
        uuidCompanyOnboarding:
          cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      }
    });

  const {
    control,
    errors,
    handleUpdateCompanyData,
    setValue,
    watch,
    isPullingSerproInformations
  } = useUpdateCompanyData({
    onUpdateCompanyOnboardingAnswersMutationSuccess:
      updateCacheAndNavigateNextStep
  });

  useEffect(() => {
    const foundMainActivity = cnae.find(
      state =>
        state.value ===
        cachedCompanyOnboardingAnswers?.companyAccount?.mainActivity
    );

    if (foundMainActivity) {
      setValue('mainActivity', foundMainActivity, {
        shouldValidate: true
      });
    }
  }, [
    watch,
    setValue,
    cachedCompanyOnboardingAnswers?.companyAccount?.mainActivity
  ]);

  function updateCacheAndNavigateNextStep(
    newCompanyData: IUpdateCacheAndNavigateNextStepParams
  ): void {
    navigate(`/cartoes/onboarding/empresa/${currentStep + 1}`);

    updateCache({
      key: 'companyAccount',
      newData: {
        ...newCompanyData,
        contract: newCompanyData.contract as string
      }
    });

    if (currentStep === Number(cachedCompanyOnboardingProgress?.currentStep)) {
      updateCurrentStepCache({
        key: 'currentStep',
        newData: currentStep + 1
      });
    }
  }
  return {
    control,
    errors,
    handleUpdateCompanyData,
    setValue,
    cnaeOptions: cnae,
    legalNatureOptions: legalNature,
    isPullingSerproInformations
  };
}
