import { useShallow } from 'zustand/react/shallow';

import { useValueSolicitationContext } from 'data/contexts';
import { useRemoveAllCacheBalanceSolicitations } from 'data/modules/cards/balance/useCases/remove-all-cache-balance-solicitations/useRemoveAllCacheBalanceSolicitations';

import { type IUseSolicitationActionsFilter } from './SolicitationActionsFilter.types';

export function useSolicitationActionsFilter(): IUseSolicitationActionsFilter {
  const [handleClearAllSelectSolicitation, handleChangeStep] =
    useValueSolicitationContext(
      useShallow(state => [
        state.handleClearAllSelectSolicitation,
        state.handleChangeStep
      ])
    );

  const { removeAllBalanceSolicitationsCache, isSuccess } =
    useRemoveAllCacheBalanceSolicitations();

  function removeAllBalanceSolicitationDisapproved(): void {
    handleClearAllSelectSolicitation();

    handleChangeStep('solicitation');
  }

  function removeAllBalanceSolicitationApproved(): void {
    removeAllBalanceSolicitationsCache(null);
  }

  if (isSuccess) {
    removeAllBalanceSolicitationDisapproved();
  }

  return {
    removeAllBalanceSolicitationApproved
  };
}
