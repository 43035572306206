import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${tokens.spacingInsetXxs};
  gap: ${tokens.spacingInsetXs};
  background-color: ${({ theme }) => theme.tokens.neutralColorHighLight};
  border-radius: ${tokens.borderRadiusMd};
  overflow: auto;

  table {
    min-width: 126rem;
  }

  margin-bottom: ${tokens.spacingInsetGiant};

  .justify-tooltip {
    > span {
      white-space: pre-line;
      overflow: hidden;
      max-width: 100%;
    }
  }
`;

export const TableContainer = styled.div`
  max-width: 100%;
  overflow: auto;
`;

export const PaginationContent = styled.div`
  margin-top: -2.4rem;
`;
