import { SettingsSidebar } from 'presentation/components/global/SettingsSidebar';
import { DefaultLayout, TripleColumn } from 'presentation/templates';

import { type ISettingsLayout } from './SettingsLayout.types';

export function SettingsLayout({
  currentSettingsPage,
  pageNameAndBreadcrumbs
}: ISettingsLayout): JSX.Element {
  return (
    <DefaultLayout pageNameAndBreadcrumbs={pageNameAndBreadcrumbs}>
      <TripleColumn
        leftElement={<SettingsSidebar />}
        centerElement={currentSettingsPage}
        lgScreenGridSize={{ center: '3fr' }}
      />
    </DefaultLayout>
  );
}
