import {
  DSIcons,
  FloatingMenu,
  MenuTabs,
  MultiSelect,
  SecondaryButton,
  Select,
  TextInput
} from 'ds';
import { DatePicker } from 'ds/components/Input/DatePicker';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  useLangContext,
  useModalContext,
  useMovementsContext
} from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';
import { MyCardTabs } from 'presentation/pages/cards/components';

import { ExportMovementsModal } from '../ExportMovementsModal/ExportMovementsModal.component';

import { useMovementsTableFilters } from './useMovementsTableFilters';

import {
  Container,
  InputsContainer,
  InputWrapper,
  StyledFileExcelIcon,
  StyledFilePdfIcon,
  StyledMenuTabsContainer,
  TabsAndExport
} from './MovementsTableFilters.styles';

export function MovementsTableFilters(): JSX.Element {
  const { t } = useTranslation(['global', 'cards']);
  const { userId, groupId } = useParams();

  const { currentLangKey } = useLangContext();
  const { visibleModal } = useModalContext();
  const [
    movementsFilters,
    movementsSearch,
    transactionsToExport,
    handleChangeMovementsFilters,
    handleChangeMovementsSearch,
    handleChangeDateFilterValidatingStatus
  ] = useMovementsContext(
    useShallow(state => [
      state.movementsFilters,
      state.movementsSearch,
      state.transactionsToExport,
      state.handleChangeMovementsFilters,
      state.handleChangeMovementsSearch,
      state.handleChangeDateFilterValidatingStatus
    ])
  );

  const {
    accounts,
    isLoading,
    startDate,
    endDate,
    startDateError,
    endDateError,
    isExportingMovementsInPdf,
    isExportingMovementsInExcel,
    cardGroupData,
    isCompanySelected,
    shouldBlurMultiSelect,
    handleBlurMultiselect,
    handleChangeUsersFilter,
    handleExportMovements,
    movementsTypeOptions
  } = useMovementsTableFilters();

  return (
    <Container>
      {visibleModal === 'exportMovementsPdf' && (
        <ExportMovementsModal handleBlurMultiselect={handleBlurMultiselect} />
      )}

      <Loader
        isLoading={isExportingMovementsInPdf || isExportingMovementsInExcel}
      />

      <TabsAndExport>
        {!userId && (
          <StyledMenuTabsContainer hasTrack>
            <MenuTabs.Item
              active={movementsFilters.movementType === 'ALL'}
              size='small'
              onClick={() => {
                handleChangeMovementsFilters({
                  ...movementsFilters,
                  movementType: 'ALL'
                });
              }}
            >
              {t('all_female_other')}
            </MenuTabs.Item>

            <MenuTabs.Item
              size='small'
              active={movementsFilters.movementType === 'CREDIT'}
              onClick={() => {
                handleChangeMovementsFilters({
                  ...movementsFilters,
                  movementType: 'CREDIT'
                });
              }}
            >
              {t('entries')}
            </MenuTabs.Item>

            <MenuTabs.Item
              active={movementsFilters.movementType === 'DEBIT'}
              size='small'
              onClick={() => {
                handleChangeMovementsFilters({
                  ...movementsFilters,
                  movementType: 'DEBIT'
                });
              }}
            >
              {t('exits')}
            </MenuTabs.Item>
          </StyledMenuTabsContainer>
        )}

        {userId && <MyCardTabs selectedTab='MY_MOVEMENTS' />}

        <FloatingMenu
          trigger={
            <SecondaryButton
              disabled={transactionsToExport.length === 0}
              size='small'
            >
              <DSIcons.DownloadArrowIcon />
              {t('export')}
            </SecondaryButton>
          }
          options={[
            {
              label: t('downloadInPdf'),
              icon: <StyledFilePdfIcon />,
              onClick: () => {
                handleExportMovements('PDF');
              }
            },
            {
              label: t('downloadInExcel'),
              icon: <StyledFileExcelIcon />,
              onClick: () => {
                handleExportMovements('EXCEL');
              }
            }
          ]}
        />
      </TabsAndExport>

      <InputsContainer>
        <InputWrapper>
          <TextInput.Default
            label={t('search')}
            placeholder={t('search')}
            value={movementsSearch}
            onChangeValue={value => {
              handleChangeMovementsSearch(value);
            }}
            onClearValue={() => {
              handleChangeMovementsSearch('');
            }}
            iconLeft='SearchIcon'
            small
          />
        </InputWrapper>

        {!userId && (
          <InputWrapper>
            <MultiSelect.Default
              name='users'
              isLoading={isLoading}
              allowClearValues={!isCompanySelected}
              shouldBlur={shouldBlurMultiSelect}
              options={
                groupId
                  ? [
                      {
                        label: cardGroupData?.name || t('company'),
                        value: 'COMPANY'
                      },
                      ...accounts
                    ]
                  : [
                      { label: t('allUsers'), value: 'ALL' },
                      {
                        label: t('company'),
                        value: 'COMPANY'
                      },
                      ...accounts
                    ]
              }
              value={movementsFilters.accounts}
              onChange={value => {
                handleChangeUsersFilter(value);
              }}
              small
              label={t('user_other')}
            />
          </InputWrapper>
        )}

        <InputWrapper className='date-picker'>
          <DatePicker.Default
            variant='small'
            containerClassName='datepicker-container'
            label={t('from')}
            currentLangKey={currentLangKey}
            onChangeValue={value => {
              handleChangeDateFilterValidatingStatus('VALIDATING');

              handleChangeMovementsFilters({
                ...movementsFilters,
                startDate: value || movementsFilters.startDate
              });
            }}
            value={startDate}
            iconRight='CalendarScheduleIcon'
            placeholder={t('from')}
            errorMessage={startDateError}
          />
        </InputWrapper>

        <InputWrapper className='date-picker'>
          <DatePicker.Default
            variant='small'
            containerClassName='datepicker-container'
            label={t('to')}
            currentLangKey={currentLangKey}
            onChangeValue={value => {
              handleChangeDateFilterValidatingStatus('VALIDATING');

              handleChangeMovementsFilters({
                ...movementsFilters,
                endDate: value || movementsFilters.endDate
              });
            }}
            value={endDate}
            iconRight='CalendarScheduleIcon'
            placeholder={t('from')}
            errorMessage={endDateError}
          />
        </InputWrapper>

        {userId && (
          <InputWrapper>
            <Select.Default
              small
              name='movementsFilter'
              onChange={value => {
                handleChangeMovementsFilters({
                  ...movementsFilters,
                  movementType: value as 'ALL' | 'CREDIT' | 'DEBIT'
                });
              }}
              options={movementsTypeOptions}
              value={movementsFilters.movementType}
              hasArrowDownIndicator
              label={t('cards:movements')}
              placeholder={t('cards:movements')}
            />
          </InputWrapper>
        )}
      </InputsContainer>
    </Container>
  );
}
