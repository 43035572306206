import { useQueryCache } from 'data/cache';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUseGetCompanyOnboardingTeamMembers,
  type IUseGetCompanyOnboardingTeamMembersParams
} from 'data/modules/cards/companyOnboarding';

export function useGetCompanyOnboardingTeamMembers({
  queryPayload
}: IUseGetCompanyOnboardingTeamMembersParams): IUseGetCompanyOnboardingTeamMembers {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [
      CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_TEAM_MEMBERS,
      queryPayload
    ],
    queryFn: async () =>
      await CompanyOnboardingService.getCompanyOnboardingTeamMembers(
        queryPayload
      )
  });

  return {
    paginatedCompanyOnboardingTeamMembers: data,
    isPaginatedCompanyOnboardingTeamMembersLoading: isLoading || isFetching
  };
}
