import DataLoader from 'dataloader';

import { useQueryCache } from 'data/cache';
import {
  type ITransaction,
  type IUseGetTransactionDetails,
  type IUseGetTransactionDetailsParams,
  StatementQueryKeys,
  StatementService
} from 'data/modules/cards/statement';

export function useGetTransactionDetails({
  transactionId,
  enabled
}: IUseGetTransactionDetailsParams): IUseGetTransactionDetails {
  const transactionsListByIdsLoader = new DataLoader(
    async (
      transactionsIds: readonly string[]
    ): Promise<(ITransaction | null)[]> => {
      const transactions =
        await StatementService.getTransactionsDetailsListByIds({
          transactionsIds
        });

      return transactionsIds.map(
        transactionId =>
          transactions.find(
            transaction => transaction.transactionId === transactionId
          ) ?? null
      );
    },
    {
      cache: false
    }
  );

  const { data, isLoading } = useQueryCache({
    queryKey: [StatementQueryKeys.GET_TRANSACTIONS_LIST, transactionId],
    queryFn: async () => await transactionsListByIdsLoader.load(transactionId),
    enabled
  });

  return {
    transaction: data,
    isLoadingTransaction: isLoading
  };
}
