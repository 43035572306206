import { faker } from '@faker-js/faker';

import { type IResponseTeamMembersVirtual } from 'data/modules/cards/businessCard';

import { MockApi } from 'shared/utils/global';

export async function attachApprovalFlowsAndExpensePoliciesMock(): Promise<IResponseTeamMembersVirtual> {
  await MockApi.sleep(2000);

  return {
    approval_flow_id: faker.number.int(100),
    created_at: faker.date.past().toISOString(),
    email: faker.internet.email(),
    expense_policy_id: faker.number.int(100),
    name: faker.person.fullName(),
    route_policy_id: faker.number.int(100),
    updated_at: faker.date.past().toISOString(),
    uuid: faker.string.uuid()
  };
}
