import { useTranslation } from 'react-i18next';

import { useAuthContext, useCardGroupsContext } from 'data/contexts';

import { SideModal } from 'presentation/components/global/Modal';

import { CardGroupCardAndActions, UsersAndManagersList } from './components';

export function CardGroupDetailsModal(): JSX.Element {
  const { t } = useTranslation('cards');
  const user = useAuthContext(state => state.user);

  const handleResetCardGroupDetailsModal = useCardGroupsContext(
    state => state.handleResetCardGroupDetailsModal
  );

  return (
    <SideModal
      isOpen
      title={t('groupDetails')}
      onAfterClose={handleResetCardGroupDetailsModal}
    >
      <CardGroupCardAndActions />

      <UsersAndManagersList listContent='USERS' />

      {user?.cards?.type !== 'USER' && (
        <UsersAndManagersList listContent='MANAGERS' />
      )}
    </SideModal>
  );
}
