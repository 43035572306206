class Types {
  isType<U extends T, T extends Record<keyof T, unknown>>(
    param: T,
    key: keyof U
  ): param is U {
    return key in param;
  }
}

export default new Types();
