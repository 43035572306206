import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type StatusTextStylesType } from './StatusText.types';

export const Container = styled.strong<AddPrefixToType<StatusTextStylesType>>`
  font-size: ${({ theme }) => theme.tokens.fontSizeSm};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-weight: ${({ theme }) => theme.tokens.fontWeightSemibold};

  ${({ $status }) =>
    $status === 'acquired'
      ? css`
          color: ${tokens.feedbackColorSuccessMedium};
        `
      : css`
          color: ${tokens.highlightColorMedium};
        `}
`;
