import { useEffect, useState } from 'react';

import { useGetPaginatedCostsCenters } from 'data/modules/costsCenters';

import { useDebounce } from 'shared/hooks/global';
import { TransformArray } from 'shared/utils/global';

import {
  type ISelectDynamicCostsCentersState,
  type IUseSelectDynamicCostsCenters
} from './SelectDynamicCostsCenters.types';
import { type ISelectDynamicControlledOption } from 'ds/components/Select/Dynamic/Controlled/SelectDynamicControlled.types';

export function useSelectDynamicCostsCenters(): IUseSelectDynamicCostsCenters {
  const [payloadParams, setPayloadParams] =
    useState<ISelectDynamicCostsCentersState>({
      page: 1,
      perPage: 10,
      description: ''
    });

  const [options, setOptions] = useState<ISelectDynamicControlledOption[]>([]);

  const debouncedSearch = useDebounce(payloadParams.description);

  const { paginatedCostsCenters, isFetchingPaginatedCostsCenters } =
    useGetPaginatedCostsCenters({
      ...payloadParams,
      description: debouncedSearch !== '' ? debouncedSearch : undefined
    });

  useEffect(() => {
    if (paginatedCostsCenters && !isFetchingPaginatedCostsCenters) {
      const newOptions = TransformArray.objectsToSelectOptions(
        paginatedCostsCenters && paginatedCostsCenters.data !== null
          ? paginatedCostsCenters.data
          : [],
        'id',
        'description',
        true
      );

      if (payloadParams.page === 1) {
        setOptions(newOptions);
      }

      if (payloadParams.page > 1) {
        setOptions(prevState => [...prevState, ...newOptions]);
      }
    }
  }, [
    paginatedCostsCenters,
    isFetchingPaginatedCostsCenters,
    payloadParams.perPage,
    payloadParams.page
  ]);

  function handleChangeSearchValue(value: string): void {
    setPayloadParams(prevState => ({
      ...prevState,
      page: 1,
      description: value
    }));
  }

  function handleMenuScrollToBottom(): void {
    // Só vai carregar mais se a quantidade de itens retornados for igual ao perPage
    if (paginatedCostsCenters?.data.length === payloadParams.perPage) {
      setPayloadParams(prevState => ({
        ...prevState,
        page: prevState.page + 1
      }));
    }
  }

  return {
    handleChangeSearchValue,
    handleMenuScrollToBottom,
    isLoadingMoreOptions: isFetchingPaginatedCostsCenters,
    options,
    search: payloadParams.description
  };
}
