import { CardInfo } from 'ds';
import styled from 'styled-components';

export const Container = styled.div``;

export const CardInfoContainer = styled(CardInfo.Container)`
  min-width: 37.2rem;
  min-height: 15.2rem;

  & > div {
    width: fit-content;
    flex-direction: row;
    align-items: center;
    gap: 1.6rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  max-width: 25rem;

  button {
    width: fit-content;
    margin-top: 0.6rem;
  }
`;

export const CardInfoCaption = styled(CardInfo.Caption)`
  margin-top: 0;
`;

export const CustomImage = styled.img`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    display: block;
  }
`;
