import {
  type IApproveLegalRepresentativePayload,
  type IPersistenceApproveLegalRepresentativePayload
} from 'data/modules/cards/companyOnboarding';

class ApproveLegalRepresentativePayloadMapper {
  toPersistence(
    payload: Omit<IApproveLegalRepresentativePayload, 'uuidCompanyOnboarding'>
  ): IPersistenceApproveLegalRepresentativePayload {
    return {
      hash_code: payload.hashCode,
      approve: payload.approve
    };
  }
}

export default new ApproveLegalRepresentativePayloadMapper();
