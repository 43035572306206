import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { Container } from 'presentation/pages/store/components/Carrousel/CarrouselContainer/CarrouselContainer.styles';

import { type CarrouselSlideStylesType } from './CarrouselSlide.types';

export const SlideContent = styled.div<
  AddPrefixToType<CarrouselSlideStylesType>
>`
  height: 100%;

  ${({ $cover }) =>
    $cover &&
    css`
      ${Container}:has(&) .custom-swiper {
        background: url(${$cover}) no-repeat center right;
        background-size: cover;
      }
    `}
`;
