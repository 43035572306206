import { useMemo } from 'react';

import { Charts } from 'ds';
import {
  axiosLabelsStyle,
  axiosTitleStyle,
  captionStyle,
  dataLabelsStyle,
  legendStyle,
  subtitleStyle,
  titleStyle,
  tooltipStyle
} from 'ds/components/Chart/constants/chartStyles.constant';
import { tokens } from 'ds/tokens';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { type IBasicBarChartProps } from './BasicBarChart.types';

export function BasicBarChart({
  title,
  label,
  series,
  colors,
  xAxios,
  yAxios,
  height,
  caption,
  subtitle,
  pointWidth,
  tooltipFormatter,
  responsiveOptions,
  enableLegend = true,
  enableTooltip = true
}: IBasicBarChartProps): JSX.Element {
  Charts.getCustomContrast(
    Highcharts,
    tokens.neutralColorLowMedium,
    tokens.neutralColorHighMedium
  );

  const options = useMemo(() => {
    return {
      chart: {
        type: 'bar',
        height
      },
      accessibility: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      colors,
      title: {
        text: title,
        style: titleStyle
      },
      subtitle: {
        text: subtitle,
        style: subtitleStyle
      },
      caption: {
        text: caption,
        style: captionStyle
      },
      legend: {
        enabled: enableLegend,
        itemStyle: legendStyle
      },
      responsive: {
        rules: responsiveOptions
      },
      tooltip: {
        style: tooltipStyle,
        enabled: enableTooltip,
        backgroundColor: tooltipStyle.backgroundColor,
        formatter: Charts.getTooltipFormatter(tooltipFormatter)
      },
      yAxis: {
        visible: yAxios?.enabled,
        tickAmount: yAxios?.tickAmount,
        lineWidth: yAxios?.showLine ? 1 : 0,
        gridLineWidth: yAxios?.showGridLine ? 1 : 0,
        labels: {
          overflow: 'justify',
          format: yAxios?.format,
          style: axiosLabelsStyle,
          formatter: Charts.getCustomLabelFormatter(yAxios?.formatter)
        },
        title: {
          text: yAxios?.title,
          style: axiosTitleStyle
        }
      },
      xAxis: {
        visible: xAxios?.enabled,
        categories: xAxios?.categories,
        lineWidth: xAxios?.showLine ? 1 : 0,
        gridLineWidth: xAxios?.showGridLine ? 1 : 0,
        title: {
          text: xAxios?.title,
          style: axiosTitleStyle
        },
        labels: {
          format: xAxios?.format,
          style: axiosLabelsStyle,
          formatter: Charts.getCustomLabelFormatter(xAxios?.formatter)
        }
      },
      plotOptions: {
        bar: {
          borderRadius: '20%',
          dataLabels: {
            groupPadding: 0,
            pointPadding: 0.21,
            format: label?.format,
            style: dataLabelsStyle,
            enabled: label?.enabled,
            formatter: Charts.getCustomLabelFormatter(label?.formatter)
          }
        }
      },
      series: series.map(({ name, data }) => ({
        type: 'bar',
        pointWidth,
        name,
        data
      }))
    } as Highcharts.Options;
  }, [
    title,
    height,
    colors,
    series,
    caption,
    subtitle,
    pointWidth,
    enableLegend,
    enableTooltip,
    label?.format,
    xAxios?.title,
    yAxios?.title,
    label?.enabled,
    xAxios?.format,
    yAxios?.format,
    xAxios?.enabled,
    yAxios?.enabled,
    xAxios?.showLine,
    yAxios?.showLine,
    tooltipFormatter,
    label?.formatter,
    xAxios?.formatter,
    yAxios?.formatter,
    responsiveOptions,
    xAxios?.categories,
    yAxios?.tickAmount,
    xAxios?.showGridLine,
    yAxios?.showGridLine
  ]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}
