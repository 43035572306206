import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingXl};
  justify-content: space-between;
  border-radius: ${tokens.borderRadiusMd};
  padding: ${tokens.spacingMd} ${tokens.spacingLg};
  background-color: ${tokens.neutralColorHighLight};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;
  }
`;

export const IconWrapper = styled.div`
  svg {
    height: 20rem;
    max-width: auto;
    max-width: min-content;
    background-size: contain;

    @media screen and (max-width: 560px) {
      max-height: 14rem;
    }
  }
`;

export const Content = styled.div`
  width: 100%;

  > p {
    margin-top: ${tokens.spacingXs};
  }

  > button {
    margin-top: ${tokens.spacingMd};
  }
`;

export const CardTitle = styled.strong`
  line-height: 150%;
  color: ${tokens.baseColorBlack};
  font-size: ${tokens.fontSizeSm};
  font-weight: ${tokens.fontWeightMedium};
  font-family: ${tokens.fontFamilyPoppins};
`;

export const CardDescription = styled.p`
  line-height: 150%;
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.neutralColorLowDark};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamilyPoppins};
`;
