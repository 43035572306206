import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type ContainerStylesType } from './TransactionValue.types';

export const Container = styled.p<AddPrefixToType<ContainerStylesType>>`
  ${({ $isTransactionReversed }) =>
    $isTransactionReversed &&
    css`
      text-decoration: line-through;

      > span {
        text-decoration: line-through;
      }
    `}

  > span {
    margin-left: 1rem;

    ${({ $isTransactionPartiallyReversed }) =>
      $isTransactionPartiallyReversed &&
      css`
        &:first-child {
          text-decoration: line-through;
        }
      `}
  }
`;

export const TooltipContainer = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
