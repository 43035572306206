import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IChangeBusinessCardProjectsFieldsForm } from 'data/modules/cards/businessCard';

export function useChangeBusinessCardProjectsSchema(): ObjectSchema<IChangeBusinessCardProjectsFieldsForm> {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const changeBusinessCardProjectsSchema: ObjectSchema<IChangeBusinessCardProjectsFieldsForm> =
    useMemo(() => {
      return yup.object({
        projects: yup
          .array(yup.number())
          .required(lang.schemas.is_required[currentLangKey])
      });
    }, [lang, currentLangKey]);

  return changeBusinessCardProjectsSchema;
}
