import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
  `;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  gap: 1rem;
`;

export const QRCodeContainer = styled.div`
  height: auto;
  margin: 0 auto;
  max-width: 14rem;
  border: 0;

  > svg {
    height: auto;
    max-width: 100%;
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  max-width: fit-content;
`;

export const AccordionContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  width: 100%;
  padding: 2rem 3rem 3rem;
`;

export const LabelAboveButton = styled.div`
  font-size: 1.5rem;
  display: flex;
  color: ${({ theme }) => theme.colors.independence};
  text-align: center;

  > b {
    margin: 0 0.4rem;
  }
`;

export const LoadingSpin = styled.div`
  display: flex;
  align-self: center;
  border: 3px solid ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  border-top: 3px solid transparent;
  width: 3rem;
  height: 3rem;
  animation: ${rotate} 1s linear infinite;
`;

export const ContainerLoader = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14rem;
  height: 14rem;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.9;
`;

export const ContainerContentTed = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1rem;

  div {
    width: fit-content;
  }

  p {
    font-size: 1.6rem;
  }
`;
