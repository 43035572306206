import { DefaultButton, Modal, SecondaryButton, Select, TextInput } from 'ds';

import { useLangContext, useModalContext } from 'data/contexts';
import { useRequestCards } from 'data/modules/cards/balance';

import { MultipleInputs } from 'presentation/components/base/Input';
import { Loader } from 'presentation/components/global/Loader';

import { states } from 'shared/constants/global';

import {
  ModalContent,
  ModalFooter,
  ModalForm
} from './RequestCardsModal.styles';

export function RequestCardsModal(): JSX.Element {
  const { handleCloseModal } = useModalContext();
  const {
    currentLangKey,
    lang: { global, cards, forms }
  } = useLangContext();

  const { methods, isLoadingAddress, isSubmiting, handleSubmit } =
    useRequestCards();

  if (isSubmiting) {
    return <Loader isLoading />;
  }

  return (
    <Modal.Container onClose={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>
          {cards.requestCards.request_cards_modal_title[currentLangKey]}
        </Modal.Title>

        <Modal.IconClose onClick={handleCloseModal} />
      </Modal.Header>

      <ModalForm
        methods={methods}
        handleSubmit={handleSubmit}
      >
        <ModalContent>
          <TextInput.Controlled
            autoComplete='off'
            name='quantity'
            label={forms.quantity_of_cards[currentLangKey]}
            placeholder={forms.quantity_of_cards[currentLangKey]}
            mask='numeric'
            small
          />

          <TextInput.Controlled
            autoComplete='off'
            name='receiver'
            label={forms.receiver[currentLangKey]}
            placeholder={forms.receiver[currentLangKey]}
            small
          />

          <MultipleInputs>
            <TextInput.Controlled
              autoComplete='off'
              name='postalCode'
              label={forms.cep[currentLangKey]}
              placeholder={forms.cep[currentLangKey]}
              disabled={isLoadingAddress}
              mask='cep'
              small
            />

            <TextInput.Controlled
              autoComplete='off'
              name='street'
              label={forms.street[currentLangKey]}
              placeholder={forms.street[currentLangKey]}
              disabled={isLoadingAddress}
              small
            />
          </MultipleInputs>

          <MultipleInputs>
            <TextInput.Controlled
              autoComplete='off'
              name='number'
              label={forms.number[currentLangKey]}
              placeholder={forms.number[currentLangKey]}
              small
            />

            <TextInput.Controlled
              autoComplete='off'
              name='complement'
              label={forms.complement[currentLangKey]}
              placeholder={forms.complement[currentLangKey]}
              disabled={isLoadingAddress}
              small
            />
          </MultipleInputs>

          <MultipleInputs>
            <TextInput.Controlled
              autoComplete='off'
              name='neighborhood'
              label={forms.neighborhood[currentLangKey]}
              placeholder={forms.neighborhood[currentLangKey]}
              disabled={isLoadingAddress}
              small
            />

            <TextInput.Controlled
              autoComplete='off'
              name='city'
              label={forms.city[currentLangKey]}
              placeholder={forms.city[currentLangKey]}
              disabled={isLoadingAddress}
              small
            />
          </MultipleInputs>

          <Select.Controlled
            name='state'
            label={forms.state[currentLangKey]}
            placeholder={forms.state[currentLangKey]}
            disabled={isLoadingAddress}
            options={states}
            small
          />

          <TextInput.Controlled
            autoComplete='off'
            name='phoneNumber'
            label={forms.phone[currentLangKey]}
            placeholder={forms.phone[currentLangKey]}
            mask='phone'
            small
          />
        </ModalContent>

        <ModalFooter>
          <SecondaryButton
            size='small'
            onClick={handleCloseModal}
          >
            {global.cancel[currentLangKey]}
          </SecondaryButton>
          <DefaultButton size='small'>
            {global.request[currentLangKey]}
          </DefaultButton>
        </ModalFooter>
      </ModalForm>
    </Modal.Container>
  );
}
