import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IShareholderFieldsForm } from 'data/modules/cards/accountHolders';

import { Mask } from 'shared/utils/format';
import { Validate } from 'shared/utils/validation';

export function useShareholderSchema(): ObjectSchema<IShareholderFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const shareholderSchema: ObjectSchema<IShareholderFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object({
        shareholders: yup
          .array()
          .of(
            yup.object({
              name: yup.string().required(fieldRequiredMessage).test({
                name: 'name',
                message: lang.schemas.write_two_name[currentLangKey],
                test: Validate.fullName
              }),
              motherName: yup.string().required(fieldRequiredMessage).test({
                name: 'motherName',
                message: lang.schemas.write_two_name[currentLangKey],
                test: Validate.fullName
              }),
              email: yup
                .string()
                .email(lang.schemas.invalid_email[currentLangKey])
                .required(fieldRequiredMessage),
              document: yup.string().required(fieldRequiredMessage).test({
                name: 'document',
                message: lang.schemas.invalid_cpf[currentLangKey],
                test: Validate.cpf
              }),
              birthDate: yup
                .string()
                .required(fieldRequiredMessage)
                .test({
                  name: 'birthDate',
                  message: lang.schemas.invalid_date[currentLangKey],
                  test: (value: string) => Validate.isDate(value)
                })
                .test({
                  name: 'birthDate',
                  message: lang.schemas.date_must_not_be_future[currentLangKey],
                  test: (value: string) => !Validate.isFutureDate(value)
                })
                .matches(
                  /(\d{2})[-./](\d{2})[-./](\d{4})/,
                  lang.schemas.invalid_date[currentLangKey]
                ),
              phoneNumber: yup
                .string()
                .required(fieldRequiredMessage)
                .test({
                  name: 'phoneNumber',
                  message:
                    lang.schemas.phone_number_must_have_8_and_9_digits[
                      currentLangKey
                    ],
                  test: value => {
                    const unmaskedValue = Mask.remove(value);
                    return (
                      unmaskedValue.length === 10 || unmaskedValue.length === 11
                    );
                  }
                }),
              postalCode: yup
                .string()
                .required(fieldRequiredMessage)
                .matches(
                  /(\d{5})[-./](\d{3})/,
                  lang.schemas.invalid_postal_code[currentLangKey]
                ),
              neighborhood: yup
                .string()
                .max(30, `${lang.schemas.max[currentLangKey]} 30`)
                .required(fieldRequiredMessage),
              country: yup.string().required(fieldRequiredMessage),
              state: yup.string().required(fieldRequiredMessage),
              street: yup.string().required(fieldRequiredMessage),
              city: yup.string().required(fieldRequiredMessage),
              number: yup
                .string()
                .required(fieldRequiredMessage)
                .test({
                  name: 'number',
                  message: lang.schemas.positive_number[currentLangKey],
                  test: value => {
                    return (
                      Validate.isInteger(value) &&
                      Validate.isBiggerThanZero(value)
                    );
                  }
                }),
              swapType: yup.string().required(fieldRequiredMessage),
              complement: yup
                .string()
                .nullable()
                .defined()
                .transform(value => {
                  return value === '' ? null : value;
                })
            })
          )
          .required(fieldRequiredMessage)
      });
    }, [lang, currentLangKey]);

  return shareholderSchema;
}
