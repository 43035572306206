import {
  type IPersistenceWebCostsCenter,
  type IWebCostsCenter
} from 'data/modules/costsCenters';

class GetPaginatedCostsCentersMapper {
  toDomain(
    persistenceCostsCenter: IPersistenceWebCostsCenter
  ): IWebCostsCenter {
    return {
      id: persistenceCostsCenter.id,
      description: persistenceCostsCenter.description,
      uuid: persistenceCostsCenter.uuid
    };
  }
}

export default new GetPaginatedCostsCentersMapper();
