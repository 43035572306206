import { useLangContext, useModalContext } from 'data/contexts';

import { CreditCard } from 'presentation/components/base/CreditCard';
import { InputContainer, TextInput } from 'presentation/components/base/Input';
import { Modal } from 'presentation/components/base/Modal';

import { useResetPinModal } from './useResetPinModal';

import {
  Container,
  Content,
  CreditCardContainer
} from './ResetPinModal.styles';

export function ResetPinModal(): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);
  const { visibleModal } = useModalContext();

  const {
    cardNumber,
    handleResetCardPin,
    control,
    errors,
    isResetingCardPin,
    handleCloseModalOnClick
  } = useResetPinModal();

  return (
    <Modal
      isOpen={visibleModal === 'resetCardPin'}
      onClose={handleCloseModalOnClick}
      isLoading={isResetingCardPin}
      title={
        lang.cards.statement.reset_pin_modal.reset_card_pin[currentLangKey]
      }
      onSubmit={handleResetCardPin}
      primaryButton={{
        children:
          lang.cards.statement.reset_pin_modal.pin_reset[currentLangKey],
        color: 'success',
        size: 'xl'
      }}
      secondaryButton={{
        children: lang.global.close[currentLangKey],
        color: 'carnation',
        size: 'xl',
        onClick: handleCloseModalOnClick
      }}
    >
      <Container>
        <Content>
          <p>
            {lang.cards.statement.reset_pin_modal.set_password[currentLangKey]}
          </p>

          <InputContainer
            label={`
              ${lang.cards.statement.reset_pin_modal.card_password[currentLangKey]}*`}
            error={errors.password?.message as string}
          >
            <TextInput
              name='password'
              type='password'
              mask='numeric'
              maxLength={4}
              control={control}
              error={!!errors.password?.message}
            />
          </InputContainer>

          <InputContainer
            label={`${lang.cards.statement.reset_pin_modal.card_confirm_password[currentLangKey]}*`}
            error={errors.confirmPassword?.message as string}
          >
            <TextInput
              name='confirmPassword'
              type='password'
              mask='numeric'
              maxLength={4}
              control={control}
              error={!!errors.confirmPassword?.message}
            />
          </InputContainer>
        </Content>

        <CreditCardContainer>
          <CreditCard number={cardNumber} />
        </CreditCardContainer>
      </Container>
    </Modal>
  );
}
