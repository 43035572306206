import { type IBalanceSolicitation } from 'data/modules/cards/balance';

export class Balance {
  sortBalanceSolicitationsArrayByDate(
    array: IBalanceSolicitation[],
    order: 'asc' | 'desc' | 'default'
  ): IBalanceSolicitation[] {
    if (order === 'default') {
      return array;
    }

    const sortedArray = [...array];

    sortedArray.sort(function (a, b) {
      const dateA = new Date(a.dateSent);
      const dateB = new Date(b.dateSent);

      return order === 'asc'
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    });

    return sortedArray;
  }
}

export default new Balance();
