import { useNavigate } from 'react-router-dom';

import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUpdateCardsFunctionsSchemaFields,
  useUpdateCardsFunctions
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import { type IUseCardsFunctionsActivation } from './CardsFunctionsActivation.types';

export function useCardsFunctionsActivation(): IUseCardsFunctionsActivation {
  const navigate = useNavigate();

  const {
    data: cachedCompanyOnboardingProgress,
    updateCache: updateCurrentStepCache
  } = useHandleQueryCache({
    query: {
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS],
      query: async () => await CompanyOnboardingService.getProgress()
    }
  });

  const { updateCache } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      ],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingAnswers({
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    }
  });

  const { handleUpdateCardsFunctions, control, errors, isDirty } =
    useUpdateCardsFunctions({
      onUpdateCompanyOnboardingAnswersMutationSuccess:
        updateCacheAndNavigateNextStep
    });

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));
  function updateCacheAndNavigateNextStep(
    data: IUpdateCardsFunctionsSchemaFields
  ): void {
    navigate(`/cartoes/onboarding/empresa/${currentStep + 1}`);

    if (currentStep === Number(cachedCompanyOnboardingProgress?.currentStep)) {
      updateCurrentStepCache({
        key: 'currentStep',
        newData: currentStep + 1
      });
    }

    if (!cachedCompanyOnboardingProgress?.onboardingCompanyId) return;

    if (isDirty) {
      updateCache({
        newData: data,
        key: 'configuration'
      });
    }
  }

  return {
    errors,
    control,
    handleUpdateCardsFunctions
  };
}
