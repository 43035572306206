import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import {
  type ItemsFlagsValueKeyType,
  useGetExpenseParameters,
  useGetTypesOfExpenses
} from 'data/modules/expenses';

import {
  type IUseExpenseOutOfPolicyModal,
  type IUseExpenseOutOfPolicyModalParams
} from './ExpenseOutOfPolicyModal.types';

export function useExpenseOutOfPolicyModal({
  errorsFromApi,
  expenseTypeId,
  hasUserFilledInTheObservationField
}: IUseExpenseOutOfPolicyModalParams): IUseExpenseOutOfPolicyModal {
  const { t } = useTranslation(['expenses', 'global']);

  const { typesOfExpenses, isLoadingTypesOfExpenses } = useGetTypesOfExpenses();

  const { expenseParameters, isLoadingExpenseParameters } =
    useGetExpenseParameters();

  const individualMessages = useMemo(() => {
    const getIndividualMessagesFromApi = errorsFromApi?.failures.find(
      failure => failure.project === null
    );

    if (getIndividualMessagesFromApi !== undefined) {
      const messages = getIndividualMessagesFromApi.itemsFlags.map(message => {
        if (message in getIndividualMessagesFromApi.itemsValues) {
          return t(`expenses:errors.fromApi.${message}`, {
            value: getIndividualMessagesFromApi.itemsValues[
              message as ItemsFlagsValueKeyType
            ] as string
          });
        }

        return t(`expenses:errors.fromApi.${message}`);
      });

      return messages;
    }

    return [];
  }, [errorsFromApi, t]);

  const observationFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.expenseNote !== null
      ? expenseParameters.companyFields.expenseNote
      : t('global:observations');

  const observationMessage = useMemo(() => {
    if (
      errorsFromApi?.allowExceedLimit &&
      errorsFromApi?.requireNotes &&
      !hasUserFilledInTheObservationField
    ) {
      const expenseTypeLabel = typesOfExpenses?.find(
        type => String(type.id) === expenseTypeId
      )?.description;

      if (expenseTypeLabel) {
        return t('expenses:errors.observationError', {
          type: expenseTypeLabel,
          observationsLabel: observationFormLabel
        });
      }

      return undefined;
    }

    return undefined;
  }, [
    errorsFromApi,
    t,
    expenseTypeId,
    typesOfExpenses,
    observationFormLabel,
    hasUserFilledInTheObservationField
  ]);

  const projectsMessages = useMemo(() => {
    const projectsMessages: {
      projectName: string;
      messages: string[];
    }[] = [];

    const getProjectsMessagesFromApi = errorsFromApi?.failures.filter(
      item => item.project !== null
    );

    if (
      getProjectsMessagesFromApi !== undefined &&
      getProjectsMessagesFromApi.length > 0
    ) {
      getProjectsMessagesFromApi.forEach(item => {
        const messages = item.itemsFlags.map(message => {
          if (message in item.itemsValues) {
            return t(`expenses:errors.fromApi.${message}`, {
              value: item.itemsValues[
                message as ItemsFlagsValueKeyType
              ] as string
            });
          }

          return t(`expenses:errors.fromApi.${message}`);
        });

        projectsMessages.push({
          projectName: item.project as string,
          messages
        });
      });
    }

    return projectsMessages;
  }, [errorsFromApi, t]);

  const showConfirmButton = useMemo(() => {
    if (!errorsFromApi?.allowExceedLimit) {
      return false;
    }

    if (
      errorsFromApi?.allowExceedLimit &&
      errorsFromApi?.requireNotes &&
      !hasUserFilledInTheObservationField
    ) {
      return false;
    }

    return true;
  }, [
    errorsFromApi?.allowExceedLimit,
    errorsFromApi?.requireNotes,
    hasUserFilledInTheObservationField
  ]);

  return {
    individualMessages,
    observationMessage,
    projectsMessages,
    observationFormLabel,
    isLoadingData: isLoadingExpenseParameters || isLoadingTypesOfExpenses,
    showConfirmButton
  };
}
