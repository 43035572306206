import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IChangeBusinessCardApprovalFlowFieldsForm } from 'data/modules/cards/businessCard';

export function useChangeBusinessCardApprovalFlowSchema(): ObjectSchema<IChangeBusinessCardApprovalFlowFieldsForm> {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const changeBusinessCardApprovalFlowSchema: ObjectSchema<IChangeBusinessCardApprovalFlowFieldsForm> =
    useMemo(() => {
      return yup.object({
        approvalFlow: yup
          .number()
          .required(lang.schemas.is_required[currentLangKey])
      });
    }, [lang, currentLangKey]);

  return changeBusinessCardApprovalFlowSchema;
}
