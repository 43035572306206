import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type ContainerStylesType } from './RecordRouteDefaultFieldsSteps.types';

export const LeftContentWithRouteItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingMd};
  align-items: start;
  flex-basis: 45%;
  flex-grow: 1;
  max-width: 45rem;
`;

export const MapContainerRightContent = styled.div`
  flex: 1 0 45%;

  .map-container {
    height: 42.2rem;
    width: unset;
  }
`;

export const Container = styled.div<AddPrefixToType<ContainerStylesType>>`
  ${({ $step }) =>
    $step === 'selectReport' &&
    css`
      display: none;
    `}

  ${({ $step }) =>
    $step === 'defaultFields' &&
    css`
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      gap: ${tokens.spacingXs};
      width: 100%;
      justify-content: space-between;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
        flex-direction: column-reverse;

        & > div {
          width: auto;
        }

        ${MapContainerRightContent} > div {
          margin: 0 auto;
        }
      }

      ${MapContainerRightContent} {
        flex: 1 0 20%;

        .map-container {
          height: 100%;
          width: 40rem;

          @media screen and (max-width: ${({ theme }) =>
              theme.breakpoints.mid}px) {
            height: 45.2rem;
          }
        }
      }
    `}

  ${({ $step }) =>
    $step === 'recordRoute' &&
    css`
      display: flex;
      gap: ${tokens.spacingMd};

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
        flex-direction: column-reverse;

        ${LeftContentWithRouteItems} {
          flex-basis: unset;
          max-width: unset;
        }
      }
    `}
`;
