import {
  type ILegalRepresentatives,
  type IPersistenceLegalRepresentatives
} from 'data/modules/cards/companyOnboarding';

class GetLegalRepresentativesMapper {
  toDomain(
    persistenceLegalRepresentatives: IPersistenceLegalRepresentatives[]
  ): ILegalRepresentatives[] {
    return persistenceLegalRepresentatives.map(
      persistenceLegalRepresentative => ({
        id: persistenceLegalRepresentative.id,
        name: persistenceLegalRepresentative.name,
        email: persistenceLegalRepresentative.email,
        emailSentAt: persistenceLegalRepresentative.email_sent_at,
        isAuthorized: persistenceLegalRepresentative.is_authorized,
        onboardingCompanyId:
          persistenceLegalRepresentative.onboarding_company_id
      })
    );
  }
}

export default new GetLegalRepresentativesMapper();
