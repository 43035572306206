import { type FC, type ReactNode, useCallback, useMemo } from 'react';

import { DSIcons } from 'ds';
import { useController, useFormContext } from 'react-hook-form';
import {
  components,
  type DropdownIndicatorProps,
  type LoadingIndicatorProps
} from 'react-select';

import {
  type IUseSelectDynamicControlled,
  type IUseSelectDynamicControlledParams
} from './SelectDynamicControlled.types';
import { type LanguageType } from 'ds/types';

import { StyledSpinner } from './SelectDynamicControlled.styles';

export function useSelectDynamicControlled({
  langKey,
  placeholder,
  noOptionsMessage,
  name,
  options,
  searchValue
}: IUseSelectDynamicControlledParams): IUseSelectDynamicControlled {
  const { control } = useFormContext();

  const {
    fieldState: { error },
    field: { onChange, value, ref }
  } = useController({
    control,
    name
  });

  const selectedValue = value;

  const placeholderMessage = useMemo(() => {
    if (placeholder) {
      return placeholder;
    }

    const placeholderByLang: Record<LanguageType, string> = {
      en: 'Select...',
      pt: 'Selecione...',
      es: 'Seleccione...'
    };

    return placeholderByLang[langKey];
  }, [langKey, placeholder]);

  const noMoreOptionsMessage = useMemo(() => {
    if (noOptionsMessage) {
      return noOptionsMessage;
    }

    const noMoreOptionsByLang: Record<LanguageType, string> = {
      en: 'No more options',
      pt: 'Sem mais opções',
      es: 'Sin más opciones'
    };

    return noMoreOptionsByLang[langKey];
  }, [langKey, noOptionsMessage]);

  const loadingMessage = useMemo(() => {
    const loadingMessageByLang: Record<LanguageType, string> = {
      en: 'Loading...',
      pt: 'Carregando...',
      es: 'Cargando...'
    };

    return loadingMessageByLang[langKey];
  }, [langKey]);

  const DropdownIndicator: FC<DropdownIndicatorProps> = useCallback(
    (props): ReactNode => {
      return (
        <components.DropdownIndicator {...props}>
          <DSIcons.ChevronIcon />
        </components.DropdownIndicator>
      );
    },
    []
  );

  const LoadingIndicator: FC<LoadingIndicatorProps> = useCallback(
    (props): ReactNode => {
      return (
        <StyledSpinner
          $size={1.6}
          $color='primary'
        />
      );
    },
    []
  );

  const optionsToRender = useMemo(() => {
    if (selectedValue && searchValue === '') {
      const optionsHasSelectedValue = options.find(
        option => option.value === selectedValue.value
      );

      if (!optionsHasSelectedValue) {
        return [selectedValue, ...options];
      }
    }

    return options;
  }, [options, selectedValue, searchValue]);

  return {
    DropdownIndicator,
    LoadingIndicator,
    placeholderMessage,
    noMoreOptionsMessage,
    loadingMessage,
    error,
    onChange,
    optionsToRender,
    ref,
    value
  };
}
