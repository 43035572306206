import { useEffect, useState } from 'react';

import { useDsGa4 } from 'ds/hooks/globals';

import {
  type IUseRouteInput,
  type IUseRouteInputParams
} from './RouteInput.types';

export function useRouteInput({
  itemFormattedAddress,
  isDragging
}: IUseRouteInputParams): IUseRouteInput {
  const [searchValue, setSearchValue] = useState(itemFormattedAddress ?? '');
  const [hasDragged, setHasDragged] = useState(false);

  const { sendDsGaEvent } = useDsGa4();

  useEffect(() => {
    if (!isDragging && hasDragged) {
      sendDsGaEvent('components', 'rotas', {
        description: 'click para arrastar',
        eventLabel: 'move_rotas'
      });

      setHasDragged(false);
    }

    if (isDragging) {
      setHasDragged(isDragging);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging, hasDragged]);

  return {
    searchValue,
    setSearchValue
  };
}
