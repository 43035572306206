import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useAnalysisUserFiltersContext } from 'data/contexts';
import {
  AnalysisUserFilter,
  type IAnalysisUserPayload,
  useGetAnalysisUserFilters,
  useGetAnalysisUserReportsId
} from 'data/modules/analytics';
import { AnalysisFilter } from 'data/modules/analytics/utils';
import { ExpenseStatus } from 'data/modules/expenses';

import { type IUseUserExpenses } from 'presentation/pages/analytics/Analysis/UserExpenses/UserExpenses.types';

import { useScrollBottomReach } from 'shared/hooks/global';

export function useUserExpenses(): IUseUserExpenses {
  const { t } = useTranslation(['analytics', 'global']);

  const [analysisFilters, handleChangeIsListBottomReached] =
    useAnalysisUserFiltersContext(
      useShallow(state => [
        state.filters,
        state.handleChangeIsListBottomReached
      ])
    );

  const handleOnReachBottom = useCallback(() => {
    handleChangeIsListBottomReached(true);
  }, [handleChangeIsListBottomReached]);

  const { handleScroll } = useScrollBottomReach({
    threshold: 500,
    onReachBottom: handleOnReachBottom
  });

  const { analysisUserFilters } = useGetAnalysisUserFilters();
  const { analysisUserReports } = useGetAnalysisUserReportsId();

  const expensesStatusFallbackOptions: Record<string, string> = useMemo(
    () => ({
      [ExpenseStatus.SINGLE]: t('global:standalone', {
        context: 'female',
        count: 2
      }),
      [ExpenseStatus.OPEN]: t('global:open', { context: 'female', count: 2 }),
      [ExpenseStatus.SENT]: t('global:sent', { context: 'female', count: 2 }),
      [ExpenseStatus.APPROVED]: t('global:approved', {
        context: 'female',
        count: 2
      }),
      [ExpenseStatus.REPROVED]: t('global:rejected', {
        context: 'female',
        count: 2
      }),
      [ExpenseStatus.PAID]: t('global:paid', { context: 'female', count: 2 }),
      [ExpenseStatus.REOPENED]: t('global:reopened', {
        context: 'female',
        count: 2
      })
    }),
    [t]
  );

  const payload: IAnalysisUserPayload = {
    dateToConsider: analysisFilters.dateToConsider,
    initialDate: analysisFilters.initialDate,
    finalDate: analysisFilters.endDate,
    paymentType: analysisFilters.reimbursableNonReimbursable,
    users: AnalysisFilter.removePayloadDefaultOptions(
      AnalysisFilter.getAllWithTermOptions(
        analysisFilters.users,
        analysisUserFilters?.users
      ),
      [AnalysisUserFilter.ACTIVE, AnalysisUserFilter.INACTIVE] as string[]
    ),
    status: AnalysisFilter.removePayloadDefaultOptions(
      AnalysisFilter.getAllWithTermOptions(
        analysisFilters.expensesStatus,
        Object.entries(expensesStatusFallbackOptions).map(([key, name]) => ({
          id: key,
          name
        }))
      )
    ),
    expenseTypes: AnalysisFilter.removePayloadDefaultOptions(
      AnalysisFilter.getAllWithTermOptions(
        analysisFilters.expensesTypes,
        analysisUserFilters?.expenseTypes
      )
    ),
    paymentForm: AnalysisFilter.removePayloadDefaultOptions(
      AnalysisFilter.getAllWithTermOptions(
        analysisFilters.paymentForms,
        analysisUserFilters?.paymentForms
      )
    ),
    projects: AnalysisFilter.removePayloadDefaultOptions(
      AnalysisFilter.getAllWithTermOptions(
        analysisFilters.projects,
        analysisUserFilters?.projects
      )
    ),
    costCenters: AnalysisFilter.removePayloadDefaultOptions(
      AnalysisFilter.getAllWithTermOptions(
        analysisFilters.costCenters,
        analysisUserFilters?.costCenters
      )
    ),
    reportCodes: AnalysisFilter.removePayloadDefaultOptions(
      AnalysisFilter.getAllWithTermOptions(
        analysisFilters.reportCodes,
        analysisUserReports,
        'id'
      )
    )
  };

  return { payload, handleInfinityScroll: handleScroll };
}
