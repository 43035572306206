import { format } from 'date-fns';
import { DefaultTable, DSIcons, Tooltip } from 'ds';

import { useModalContext, useMyValueSolicitationContext } from 'data/contexts';

import { SolicitationBadgeStatus } from 'presentation/pages/cards/Management/ValueSolicitation/components/SolicitationBadgeStatus/SolicitationBadgeStatus.component';

import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

import { type IMyValueSolicitationsTableRowProps } from './MyValueSolicitationsTableRow.types';

import {
  DetailsButton,
  JustifyRowContent
} from './MyValueSolicitationsTableRow.styles';

export function MyValueSolicitationsTableRow({
  solicitation,
  ...rest
}: IMyValueSolicitationsTableRowProps): JSX.Element {
  const { handleOpenModal } = useModalContext();

  const { handleSetSelectedSolicitation } = useMyValueSolicitationContext();

  return (
    <>
      <DefaultTable.Tr {...rest}>
        <DefaultTable.Td>
          {CustomDate.formatFromISO(solicitation.dateSent)}
        </DefaultTable.Td>
        <DefaultTable.Td>
          {solicitation.datePrevision
            ? format(
                new Date(solicitation.datePrevision + 'T00:00:00'),
                'dd/MM/yyyy'
              )
            : '-'}
        </DefaultTable.Td>
        <DefaultTable.Td>
          {Currency.format('BRL', solicitation.requestedAmount, true)}
        </DefaultTable.Td>

        <DefaultTable.Td>
          {solicitation.status === 'REJECTED' ? (
            solicitation.managerJustify ? (
              <Tooltip
                containerClassName='justify-tooltip'
                message={solicitation.managerJustify}
              >
                <JustifyRowContent>
                  {solicitation.managerJustify}
                </JustifyRowContent>
              </Tooltip>
            ) : (
              <JustifyRowContent>
                {solicitation.managerJustify ?? '-'}
              </JustifyRowContent>
            )
          ) : (
            <Tooltip
              message={solicitation.justify}
              containerClassName='justify-tooltip'
            >
              <JustifyRowContent>{solicitation.justify}</JustifyRowContent>
            </Tooltip>
          )}
        </DefaultTable.Td>

        <DefaultTable.Td>
          <SolicitationBadgeStatus status={solicitation.status} />
        </DefaultTable.Td>

        <DefaultTable.Td>
          {solicitation.status === 'APPROVED'
            ? Currency.format(
                'BRL',
                Number(solicitation.transferredAmount),
                true
              )
            : '-'}
        </DefaultTable.Td>

        <DefaultTable.Td
          onClick={() => {
            handleSetSelectedSolicitation(solicitation);
            handleOpenModal('myValueSolicitationsDetails');
          }}
        >
          <DetailsButton>
            <DSIcons.ArrowRightIcon color='neutralColorLowDark' />
          </DetailsButton>
        </DefaultTable.Td>
      </DefaultTable.Tr>
    </>
  );
}
