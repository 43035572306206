import { Navigate, Route } from 'react-router-dom';

import { Product, Store } from 'presentation/pages/store';
import {
  ActiveDirectory,
  Cards,
  CardsPj,
  Conciliation,
  Horus,
  Outsourcing
} from 'presentation/pages/store/Product/components/Products';

import { routesPathPrefix, StoreRoutes } from 'shared/constants/global';
import {
  AuthenticationMiddleware,
  FlagMiddleware,
  ManagerMiddleware,
  StoreMiddleware
} from 'shared/middlewares';

export const storeRoutes = [
  <Route
    key='store'
    element={<AuthenticationMiddleware />}
    path={StoreRoutes.STORE}
  >
    <Route element={<ManagerMiddleware />}>
      <Route element={<FlagMiddleware />}>
        <Route element={<StoreMiddleware />}>
          <Route
            index
            element={<Store />}
          />
          <Route
            path='*'
            element={<Product />}
          >
            <Route
              path='*'
              element={<Navigate to={routesPathPrefix.store} />}
            />

            <Route
              path={StoreRoutes.STORE_CARDS}
              element={<Cards />}
            />
            <Route
              path={StoreRoutes.STORE_CARDS_PJ}
              element={<CardsPj />}
            />
            <Route
              path={StoreRoutes.STORE_HORUS}
              element={<Horus />}
            />
            <Route
              path={StoreRoutes.STORE_CONCILIATION}
              element={<Conciliation />}
            />
            <Route
              path={StoreRoutes.STORE_OUTSOURCING}
              element={<Outsourcing />}
            />
            <Route
              path={StoreRoutes.STORE_ACTIVE_DIRECTORY}
              element={<ActiveDirectory />}
            />
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
];
