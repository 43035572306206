import { useMutationCache } from 'data/cache';
import {
  type IExportMovementsInPdfPayload,
  type IUseExportMovementsInPdf,
  MovementsMutationKeys,
  MovementsService
} from 'data/modules/cards/movements';

export function useExportMovementsInPdf(): IUseExportMovementsInPdf {
  const {
    data: movementsInPdfData,
    isPending,
    mutateAsync
  } = useMutationCache({
    mutationKey: [MovementsMutationKeys.EXPORT_MOVEMENTS_IN_PDF],
    mutationFn: async (payload: IExportMovementsInPdfPayload) =>
      await MovementsService.exportMovementsInPdf(payload)
  });

  return {
    movementsInPdfData,
    isExportingMovementsInPdf: isPending,
    exportMovementsInPdf: mutateAsync
  };
}
