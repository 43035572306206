import { type ChangeEvent } from 'react';

import { DSIcons } from 'ds';
import { useDsGa4 } from 'ds/hooks/globals';
import { Mask } from 'ds/utils';
import { useFormContext } from 'react-hook-form';

import { type ITextInputControlledProps } from './TextInputControlled.types';

import {
  Caption,
  Container,
  Fieldset,
  InputComponent,
  InputContainer,
  Label,
  Legend,
  Wrapper
} from './TextInputControlled.styles';

export function TextInputControlled({
  name,
  iconLeft,
  iconRight,
  defaultValue,
  label,
  disabled,
  mask,
  large,
  small,
  value,
  registerOptions,
  readOnly,
  onChangeValue,
  shouldPreventPaste,
  shouldPreventAutoComplete,
  inputContainerAttributes,
  ...rest
}: ITextInputControlledProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  const IconLeft = iconLeft ? DSIcons[iconLeft] : null;
  const IconRight = iconRight ? DSIcons[iconRight] : null;
  const {
    register,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();

  return (
    <Wrapper>
      <Container
        $large={large}
        $small={small}
        $disabled={disabled || readOnly}
      >
        <InputContainer
          $disabled={disabled || readOnly}
          data-testid='container'
          $hasError={!!errors[name]?.message}
          $large={large}
          $small={small}
          $isTouched={!!watch(name)}
          {...inputContainerAttributes}
        >
          <Label>{label}</Label>
          <div>
            {IconLeft && <IconLeft />}

            <InputComponent
              readOnly={readOnly}
              {...rest}
              {...register(name, {
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                  const value = mask
                    ? Mask.apply(mask, event.target.value)
                    : event.target.value;
                  mask && setValue(name, value);
                  onChangeValue && onChangeValue(value);
                },
                onBlur: event => {
                  event.target.value.trim() &&
                    sendDsGaEvent('components', 'input', {
                      eventLabel: label,
                      eventValue: event.target.value
                    });

                  registerOptions?.onBlur?.(event);
                },
                ...registerOptions
              })}
              onPaste={e => {
                shouldPreventPaste && e.preventDefault();
              }}
              autoComplete={shouldPreventAutoComplete ? 'false' : undefined}
              defaultValue={defaultValue}
              disabled={disabled}
            />
          </div>
          {IconRight && <IconRight />}
        </InputContainer>

        <Fieldset>
          <Legend>
            <span>{label}</span>
          </Legend>
        </Fieldset>
      </Container>

      {!!errors[name]?.message && (
        <Caption $hasError>{errors[name]?.message as string}</Caption>
      )}
    </Wrapper>
  );
}
