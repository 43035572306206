import { apiWeb } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import { type IDefaultResponse } from 'data/modules/global';
import {
  type ICreateReportPayload,
  type ILinkExpensesToReportPayload,
  type IOpenReport,
  type IPersistenceOpenReport
} from 'data/modules/reports';

import { GetOpenReportsMapper } from './mappers';

class ReportsService {
  async getOpenReports(): Promise<IOpenReport[] | null> {
    const response =
      await apiWeb.get<IDefaultResponse<IPersistenceOpenReport[] | null>>(
        '/reports/open'
      );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data ? data.map(item => GetOpenReportsMapper.toDomain(item)) : null;
  }

  async createReport(payload: ICreateReportPayload): Promise<IOpenReport> {
    const response = await apiWeb.post<
      IDefaultResponse<IPersistenceOpenReport>
    >('/reports', {
      description: payload.title,
      observation: payload.observation,
      cost_center_id: payload.costsCenter,
      payment_method_id: payload.paymentMethod,
      advances: [payload.advancePayment],
      projects: [payload.project?.value]
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetOpenReportsMapper.toDomain(data);
  }

  async linkExpensesToReport(
    payload: ILinkExpensesToReportPayload
  ): Promise<void> {
    const { uuid, expenses } = payload;

    await apiWeb.patch<IDefaultResponse<null>>(
      `/reports/${uuid}/attach-expenses`,
      {
        expenses
      }
    );
  }
}

export default new ReportsService();
