import { useTranslation } from 'react-i18next';
import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { type IAnalysisUserFilterFieldsForm } from 'data/modules/analytics';

import { Validate } from 'shared/utils/validation';

export function useAnalysisUserFiltersSchema(): ObjectSchema<IAnalysisUserFilterFieldsForm> {
  const { t } = useTranslation('schemas');

  const analysisUserFiltersSchema: ObjectSchema<IAnalysisUserFilterFieldsForm> =
    yup.object({
      orderBy: yup.string(),
      dateToConsider: yup.string(),
      saveFilterPreferences: yup.boolean(),
      reimbursableNonReimbursable: yup.string(),
      users: yup
        .array()
        .of(yup.string().required())
        .transform(value => {
          return value === null ? undefined : value;
        }),
      projects: yup
        .array()
        .of(yup.string().required())
        .transform(value => {
          return value === null ? undefined : value;
        }),
      costCenters: yup
        .array()
        .of(yup.string().required())
        .transform(value => {
          return value === null ? undefined : value;
        }),
      paymentForms: yup
        .array()
        .of(yup.string().required())
        .transform(value => {
          return value === null ? undefined : value;
        }),
      expensesStatus: yup
        .array()
        .of(yup.string().required())
        .transform(value => {
          return value === null ? undefined : value;
        }),
      expensesTypes: yup
        .array()
        .of(yup.string().required())
        .transform(value => {
          return value === null ? undefined : value;
        }),
      reportCodes: yup
        .array()
        .of(yup.string().required())
        .transform(value => {
          return value === null ? undefined : value;
        }),
      endDate: yup
        .string()
        .test({
          name: 'endDate',
          message: t('invalidDate'),
          test: value => Validate.isDate(value ?? '', 'yyyy-MM-dd')
        })
        .test({
          name: 'endDate',
          message: t('invalidDate'),
          test: (value, context) =>
            (value ?? '') >= ((context.parent.initialDate ?? '') as string)
        }),
      initialDate: yup
        .string()
        .test({
          name: 'initialDate',
          message: t('invalidDate'),
          test: value => Validate.isDate(value ?? '', 'yyyy-MM-dd')
        })
        .test({
          name: 'initialDate',
          message: t('invalidDate'),
          test: (value, context) =>
            (value ?? '') <= ((context.parent.endDate ?? '') as string)
        })
    });

  return analysisUserFiltersSchema;
}
