import { Navigate, Route } from 'react-router-dom';

import { SetInitialRoute } from 'presentation/components/global/SetInitialRoute';

import { CardsRoutes } from 'shared/constants/global';
import { AuthenticationMiddleware } from 'shared/middlewares';

export const globalRoutes = [
  <Route
    key='global'
    element={<AuthenticationMiddleware />}
  >
    <Route
      path={CardsRoutes.CARDS_BEGIN}
      element={<SetInitialRoute />}
    />
    ,
    <Route
      key='redirect-cartoes'
      path={CardsRoutes.CARDS_REDIRECT}
      element={<Navigate to='/cartoes/gestao' />}
    />
  </Route>
];
