import {
  type IPaginatePayload,
  type IPersistencePaginatePayload
} from 'data/modules/global';

class PaginationMapper {
  toDomain(data: IPersistencePaginatePayload): IPaginatePayload {
    return {
      page: data.page,
      perPage: data.per_page,
      ...(data.search && { search: data.search })
    };
  }

  toPersistence(data: IPaginatePayload): IPersistencePaginatePayload {
    return {
      page: data.page,
      per_page: data.perPage,
      ...(data.search && { search: data.search })
    };
  }
}

export default new PaginationMapper();
