import { useMemo } from 'react';

import { useLangContext } from 'data/contexts';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  useIncrementCompanyStep
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import { type UseCompanyOwnersType } from './CompanyOwners.types';

export function useCompanyOwners(): UseCompanyOwnersType {
  const {
    data: cachedCompanyOnboardingProgress,
    updateCache: updateCurrentStepCache
  } = useHandleQueryCache({
    query: {
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS],
      query: async () => await CompanyOnboardingService.getProgress()
    }
  });

  const { data: cachedCompanyOnboardingAnswers } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      ],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingAnswers({
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId as string
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    }
  });
  const [instructions, company, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.company.company_owners.instructions,
    state.lang.global.company,
    state.currentLangKey
  ]);

  const businessType =
    cachedCompanyOnboardingAnswers?.companyAccount?.businessType ?? '';

  const parsedInstructions = useMemo(() => {
    switch (businessType) {
      case 'LTDA':
        return instructions.ltda_and_others.map(
          ltdaInstruction => ltdaInstruction[currentLangKey]
        );

      case '205-4':
        return instructions.sa_closed.map(
          saClosedInstruction => saClosedInstruction[currentLangKey]
        );

      case 'S.A. Aberta':
        return instructions.sa_opened.map(
          saOpenedInstruction => saOpenedInstruction[currentLangKey]
        );

      case 'Individuais':
        return instructions.individual.map(
          individualInstruction => individualInstruction[currentLangKey]
        );

      default:
        return instructions.ltda_and_others.map(
          otherTypesInstruction => otherTypesInstruction[currentLangKey]
        );
    }
  }, [instructions, currentLangKey, businessType]);

  const { incrementStep } = useIncrementCompanyStep();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  function handleNavigateNextStep(): void {
    incrementStep({
      step: currentStep,
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId as string
    });

    if (currentStep === Number(cachedCompanyOnboardingProgress?.currentStep)) {
      updateCurrentStepCache({
        key: 'currentStep',
        newData: currentStep + 1
      });
    }
  }

  return {
    legalName:
      cachedCompanyOnboardingAnswers?.companyAccount?.legalName ??
      company[currentLangKey],
    businessType,
    instructions: parsedInstructions,
    handleNavigateNextStep
  };
}
