import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-top: ${({ theme }) => theme.tokens.spacingInsetMdd};
  }

  .react-select-custom__control {
    max-width: unset;
  }

  .react-select-custom__menu {
    z-index: 2;
  }
`;

export const Subtitle = styled.p`
  font-weight: 400;
  line-height: 3.2rem;
  letter-spacing: 0.75px;
  font-size: ${({ theme }) => theme.tokens.fontSizeXs};
  color: ${({ theme }) => theme.tokens.neutralColorLowDark};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.tokens.spacingInsetMdd};
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  gap: 2rem;

  p {
    font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
    color: ${({ theme }) => theme.tokens.neutralColorLowDark};
    font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  }
`;
