import { faker } from '@faker-js/faker';

import {
  type IGetUserAccounts,
  type StatusType
} from 'data/modules/cards/account';

import { MockApi } from 'shared/utils/global';

import { statusWithNullOptions } from '..';

export async function getUserAccountsMock(): Promise<IGetUserAccounts> {
  const data = [
    {
      userId: faker.string.uuid(),
      userName: faker.person.fullName(),
      account: {
        id: faker.string.uuid(),
        status: faker.helpers.arrayElement(statusWithNullOptions) as StatusType,
        cardNumber: null,
        cardStatus: null,
        cardLockLevel: null,
        balances: [
          {
            id: faker.string.uuid(),
            description: faker.lorem.sentence(2)
          }
        ]
      }
    },
    {
      userId: faker.string.uuid(),
      userName: faker.person.fullName(),
      account: {
        id: faker.string.uuid(),
        status: faker.helpers.arrayElement(statusWithNullOptions) as StatusType,
        cardNumber: faker.string.numeric(16),
        cardStatus: faker.helpers.arrayElement(
          statusWithNullOptions
        ) as StatusType,
        cardLockLevel: null,
        balances: [
          {
            id: faker.string.uuid(),
            description: faker.lorem.sentence(2)
          }
        ]
      }
    },
    {
      userId: faker.string.uuid(),
      userName: faker.person.fullName(),
      account: {
        id: faker.string.uuid(),
        status: faker.helpers.arrayElement(statusWithNullOptions) as StatusType,
        cardNumber: null,
        cardStatus: faker.helpers.arrayElement(
          statusWithNullOptions
        ) as StatusType,
        cardLockLevel: null,
        balances: [
          {
            id: faker.string.uuid(),
            description: faker.lorem.sentence(2)
          }
        ]
      }
    }
  ];

  await MockApi.sleep(2000);

  return {
    data,
    total: data.length
  };
}
