import {
  type IExpenseTableRow,
  type IPersistenceExpenseList
} from 'data/modules/expenses';

class GetPaginatedExpensesMapper {
  toDomain(persistenceExpense: IPersistenceExpenseList): IExpenseTableRow {
    return {
      id: persistenceExpense.id,
      costCenter: persistenceExpense.cost_center
        ? {
            id: persistenceExpense.cost_center.id,
            uuid: persistenceExpense.cost_center.uuid,
            label: persistenceExpense.cost_center.nome
          }
        : null,
      uuid: persistenceExpense.uuid,
      date: persistenceExpense.date,
      title: persistenceExpense.title,
      isFromCards: persistenceExpense.is_from_cards,
      attachment: persistenceExpense.receipt
        ? {
            url: persistenceExpense.receipt.original_url ?? '',
            label: persistenceExpense.receipt.name,
            type: persistenceExpense.receipt.type ?? 'IMG',
            uuid: persistenceExpense.receipt.uuid
          }
        : null,
      type: persistenceExpense.type ? persistenceExpense.type.description : '',
      typeId: persistenceExpense.type ? persistenceExpense.type.id : null,
      value: persistenceExpense.amount,
      currency: persistenceExpense.currency.iso_code,
      report: persistenceExpense.report
        ? {
            label: persistenceExpense.report.description,
            status: persistenceExpense.report.status,
            uuid: persistenceExpense.report.uuid
          }
        : null,
      formOfPayment: persistenceExpense.payment_method?.description ?? '',
      canDelete: persistenceExpense.is_deletable,
      isReconciled: !persistenceExpense.conciliation.is_enabled
        ? null
        : persistenceExpense.conciliation.status === 'reconciled',
      isImported: persistenceExpense.conciliation.was_imported,
      refundable: persistenceExpense.is_reimbursable,
      routeType: persistenceExpense.type
        ? persistenceExpense.type.route_type
        : null,
      isReversal: persistenceExpense.reversal_status
    };
  }
}

export default new GetPaginatedExpensesMapper();
