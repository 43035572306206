import { DefaultButton, Modal, SecondaryButton, TextInput } from 'ds';
import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';

import { useEditCardGroupNameModal } from './useEditCardGroupNameModal';

import { ModalFooter, ModalForm } from './EditCardGroupNameModal.styles';

export function EditCardGroupNameModal(): JSX.Element {
  const { handleCloseModal, handleOpenModal } = useModalContext();
  const { t } = useTranslation(['cards', 'global']);
  const { methods, handleSubmit, isEditingCardGroupName } =
    useEditCardGroupNameModal();

  if (isEditingCardGroupName) {
    return <Loader isLoading />;
  }

  return (
    <Modal.Container
      onClose={() => {
        handleCloseModal();
        handleOpenModal('groupCardDetails');
      }}
      variant='small'
    >
      <Modal.Header>
        <Modal.Title>{t('editGroupName')}</Modal.Title>

        <Modal.IconClose
          onClick={() => {
            handleCloseModal();
            handleOpenModal('groupCardDetails');
          }}
        />
      </Modal.Header>

      <ModalForm
        methods={methods}
        handleSubmit={handleSubmit}
      >
        <p>{t('chosseNewGroupName')}</p>

        <TextInput.Controlled
          name='name'
          label={t('accountNickname')}
          placeholder={t('accountNickname')}
          small
        />

        <ModalFooter>
          <SecondaryButton
            size='small'
            onClick={() => {
              handleCloseModal();
              handleOpenModal('groupCardDetails');
            }}
            type='button'
          >
            {t('global:cancel')}
          </SecondaryButton>

          <DefaultButton size='small'>{t('global:confirm')}</DefaultButton>
        </ModalFooter>
      </ModalForm>
    </Modal.Container>
  );
}
