import { Fragment } from 'react';

import { useLangContext } from 'data/contexts';

import { IconButton } from 'presentation/components/base/Button';
import { BaseIcon } from 'presentation/components/base/Icon';
import {
  InputContainer,
  MultipleInputs,
  TextInput
} from 'presentation/components/base/Input';
import { Select } from 'presentation/components/base/Select';
import { Tooltip } from 'presentation/components/base/Tooltip';

import { useShareholdersForm } from './useShareholdersForm';

import { type IShareholdersFormProps } from './ShareholdersForm.types';

import {
  ManageShareHolderFormTitle,
  PartnerWrapper,
  ShareholderFormsContainer,
  StyledRectButton
} from './ShareholdersForm.styles';

export function ShareholdersForm({
  control,
  errors,
  shareholdersFields,
  shareholdersRemove,
  onFillWithMainCompanyShareholders,
  setValue,
  clearErrors
}: IShareholdersFormProps): JSX.Element {
  const [settings, forms, currentLangKey] = useLangContext(state => [
    state.lang.settings,
    state.lang.forms,
    state.currentLangKey
  ]);

  const {
    handleBlurPostalCode,
    isLoadingShareholderAddressByPostalCode,
    selectOptionsShareHolderType
  } = useShareholdersForm({
    setValue,
    clearErrors
  });

  return (
    <>
      <ManageShareHolderFormTitle>
        {settings.accountHolders.write_below_shareholders_data[currentLangKey]}

        <Tooltip
          direction='right'
          id='getDataBelow'
          maxWidth={35}
          content={
            settings.accountHolders.tooltip_shareholder_form[currentLangKey]
          }
        >
          <BaseIcon
            name='question'
            type='fill'
            size={1.8}
            color='primary'
          />
        </Tooltip>
      </ManageShareHolderFormTitle>

      <StyledRectButton
        iconProps={{ name: 'add', color: 'white' }}
        type='button'
        onClick={onFillWithMainCompanyShareholders}
      >
        {settings.accountHolders.fill_up_with_shareholders[currentLangKey]}
      </StyledRectButton>

      <ShareholderFormsContainer>
        {shareholdersFields.map((shareholder, index) => (
          <Fragment key={shareholder.id}>
            <PartnerWrapper>
              <h2>
                {settings.accountHolders.shareholder[currentLangKey]}{' '}
                {index + 1}
              </h2>

              {shareholdersFields.length > 1 && (
                <Tooltip
                  direction='bottom'
                  id={'removeShareholder' + index}
                  content={
                    settings.accountHolders.tooltip_remove_shareholder[
                      currentLangKey
                    ]
                  }
                >
                  <IconButton
                    iconProps={{
                      name: 'close-circle',
                      type: 'fill',
                      size: 2.3,
                      color: 'danger'
                    }}
                    onClick={() => {
                      shareholdersRemove(index);
                    }}
                  />
                </Tooltip>
              )}
            </PartnerWrapper>

            <MultipleInputs>
              <InputContainer
                label={`${forms.name[currentLangKey]} *`}
                error={errors?.shareholders?.[index]?.name?.message}
              >
                <TextInput
                  name={`shareholders.${index}.name`}
                  control={control}
                  error={!!errors?.shareholders?.[index]?.name?.message}
                  mask='letters'
                />
              </InputContainer>

              <InputContainer
                label={`${forms.mother_name[currentLangKey]} *`}
                error={errors?.shareholders?.[index]?.motherName?.message}
              >
                <TextInput
                  name={`shareholders.${index}.motherName`}
                  control={control}
                  error={!!errors?.shareholders?.[index]?.motherName?.message}
                  mask='letters'
                />
              </InputContainer>

              <InputContainer
                error={errors?.shareholders?.[index]?.email?.message}
                label={`${forms.email[currentLangKey]} *`}
              >
                <TextInput
                  name={`shareholders.${index}.email`}
                  control={control}
                  error={!!errors?.shareholders?.[index]?.email?.message}
                />
              </InputContainer>
            </MultipleInputs>

            <MultipleInputs>
              <InputContainer
                error={errors?.shareholders?.[index]?.document?.message}
                label={`${forms.cpf[currentLangKey]} *`}
              >
                <TextInput
                  name={`shareholders.${index}.document`}
                  mask='cpf'
                  control={control}
                  error={!!errors?.shareholders?.[index]?.document?.message}
                />
              </InputContainer>

              <InputContainer
                label={`${forms.birth_date[currentLangKey]} *`}
                error={errors?.shareholders?.[index]?.birthDate?.message}
              >
                <TextInput
                  name={`shareholders.${index}.birthDate`}
                  mask='date'
                  control={control}
                  error={!!errors?.shareholders?.[index]?.birthDate?.message}
                />
              </InputContainer>

              <InputContainer
                error={errors?.shareholders?.[index]?.swapType?.message}
                label={`${settings.accountHolders.natural_person_type.title[currentLangKey]} *`}
              >
                <Select
                  options={selectOptionsShareHolderType}
                  control={control}
                  name={`shareholders.${index}.swapType`}
                />
              </InputContainer>
            </MultipleInputs>

            <MultipleInputs>
              <InputContainer
                label={`${forms.address.zip_code[currentLangKey]} *`}
                error={errors?.shareholders?.[index]?.postalCode?.message}
              >
                <TextInput
                  name={`shareholders.${index}.postalCode`}
                  mask='cep'
                  control={control}
                  error={!!errors?.shareholders?.[index]?.postalCode?.message}
                  onBlur={e => {
                    handleBlurPostalCode(index, e.target.value);
                  }}
                />
              </InputContainer>

              <InputContainer
                error={errors?.shareholders?.[index]?.state?.message}
                label={`${forms.address.state[currentLangKey]} *`}
              >
                <TextInput
                  name={`shareholders.${index}.state`}
                  error={!!errors?.shareholders?.[index]?.state?.message}
                  control={control}
                  disabled={
                    isLoadingShareholderAddressByPostalCode.shareholderIndex ===
                      index && isLoadingShareholderAddressByPostalCode.isLoading
                  }
                />
              </InputContainer>

              <InputContainer
                error={errors?.shareholders?.[index]?.city?.message}
                label={`${forms.address.city[currentLangKey]} *`}
              >
                <TextInput
                  name={`shareholders.${index}.city`}
                  error={!!errors?.shareholders?.[index]?.city?.message}
                  control={control}
                  disabled={
                    isLoadingShareholderAddressByPostalCode.shareholderIndex ===
                      index && isLoadingShareholderAddressByPostalCode.isLoading
                  }
                />
              </InputContainer>
            </MultipleInputs>

            <MultipleInputs>
              <InputContainer
                label={`${forms.address.country[currentLangKey]} *`}
                error={errors?.shareholders?.[index]?.country?.message}
              >
                <TextInput
                  name={`shareholders.${index}.country`}
                  control={control}
                  error={!!errors?.shareholders?.[index]?.country?.message}
                  disabled={
                    isLoadingShareholderAddressByPostalCode.shareholderIndex ===
                      index && isLoadingShareholderAddressByPostalCode.isLoading
                  }
                />
              </InputContainer>

              <InputContainer
                error={errors?.shareholders?.[index]?.neighborhood?.message}
                label={`${forms.address.neighborhood[currentLangKey]} *`}
              >
                <TextInput
                  name={`shareholders.${index}.neighborhood`}
                  error={!!errors?.shareholders?.[index]?.neighborhood?.message}
                  control={control}
                  disabled={
                    isLoadingShareholderAddressByPostalCode.shareholderIndex ===
                      index && isLoadingShareholderAddressByPostalCode.isLoading
                  }
                />
              </InputContainer>

              <InputContainer
                error={errors?.shareholders?.[index]?.street?.message}
                label={`${forms.address.street[currentLangKey]} *`}
              >
                <TextInput
                  name={`shareholders.${index}.street`}
                  error={!!errors?.shareholders?.[index]?.street?.message}
                  control={control}
                  disabled={
                    isLoadingShareholderAddressByPostalCode.shareholderIndex ===
                      index && isLoadingShareholderAddressByPostalCode.isLoading
                  }
                />
              </InputContainer>
            </MultipleInputs>

            <MultipleInputs>
              <InputContainer
                label={`${forms.phone[currentLangKey]} *`}
                error={errors?.shareholders?.[index]?.phoneNumber?.message}
              >
                <TextInput
                  name={`shareholders.${index}.phoneNumber`}
                  mask='phone'
                  control={control}
                  error={!!errors?.shareholders?.[index]?.phoneNumber?.message}
                />
              </InputContainer>

              <InputContainer
                error={errors?.shareholders?.[index]?.number?.message}
                label={`${forms.address.number[currentLangKey]} *`}
              >
                <TextInput
                  name={`shareholders.${index}.number`}
                  error={!!errors?.shareholders?.[index]?.number?.message}
                  control={control}
                />
              </InputContainer>

              <InputContainer
                error={errors?.shareholders?.[index]?.complement?.message}
                label={forms.address.complement[currentLangKey]}
              >
                <TextInput
                  name={`shareholders.${index}.complement`}
                  error={!!errors?.shareholders?.[index]?.complement?.message}
                  control={control}
                  disabled={
                    isLoadingShareholderAddressByPostalCode.shareholderIndex ===
                      index && isLoadingShareholderAddressByPostalCode.isLoading
                  }
                />
              </InputContainer>
            </MultipleInputs>
          </Fragment>
        ))}
      </ShareholderFormsContainer>
    </>
  );
}
