import {
  type IStatusTabObjectPersistence,
  type StatusTabObjectType
} from 'data/modules/expenses';

class GetNumberOfExpensesByStatus {
  toDomain(
    statusTab: IStatusTabObjectPersistence[] | null
  ): StatusTabObjectType | null {
    if (!statusTab) {
      return null;
    }

    return {
      all: statusTab.reduce((accumulator, { count }) => accumulator + count, 0),
      approved: statusTab.find(item => item.status === 'APROVADO')?.count ?? 0,
      open:
        (statusTab.find(item => item.status === 'ABERTO')?.count ?? 0) +
        (statusTab.find(item => item.status === 'REABERTO')?.count ?? 0),
      paid: statusTab.find(item => item.status === 'PAGO')?.count ?? 0,
      reproved: statusTab.find(item => item.status === 'REJEITADO')?.count ?? 0,
      sent: statusTab.find(item => item.status === 'ENVIADO')?.count ?? 0,
      single: statusTab.find(item => item.status === 'AVULSO')?.count ?? 0,
      reopened: statusTab.find(item => item.status === 'REABERTO')?.count ?? 0
    };
  }
}

export default new GetNumberOfExpensesByStatus();
