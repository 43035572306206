import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type ServiceChannelStyleType } from './ServiceChannel.types';

export const Container = styled.div<AddPrefixToType<ServiceChannelStyleType>>`
  display: grid;
  justify-items: center;
  gap: ${tokens.spacingMd};
  padding: ${tokens.spacingXxl};

  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
    `};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: ${tokens.spacingXs};
  }
`;

export const Content = styled.div`
  display: grid;
  justify-items: center;
  gap: ${tokens.spacingNano};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  gap: ${tokens.spacingNano};

  > button {
    border: none;
    outline: none;
    background: none;

    > svg {
      transition: color 0.25s linear;

      color: ${tokens.neutralColorLowMediumLight};

      &:hover {
        color: ${tokens.brandColorSecondaryPure};
      }

      &:active {
        color: ${tokens.brandColorSecondaryMedium};
      }
    }
  }
`;

export const ChannelName = styled.strong`
  text-align: center;
  line-height: 3.2rem;
  letter-spacing: 0.75px;
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightSemibold};

  text-shadow: ${tokens.shadowLevel2.x} ${tokens.shadowLevel2.y}
    ${tokens.shadowLevel2.blur} ${tokens.shadowLevel2.color};
`;

export const ChannelDescription = styled.p`
  text-align: center;
  line-height: 2.4rem;
  letter-spacing: 0.75px;
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
`;

export const ChannelInformation = styled(ChannelDescription)`
  color: ${tokens.neutralColorLowMediumLight};
`;
