import { useLangContext, useModalContext } from 'data/contexts';

import { RectButton } from 'presentation/components/base/Button';
import { ConfirmationModal } from 'presentation/components/global/ConfirmationModal';
import { SkeletonLoader } from 'presentation/components/global/Loader';
import { UnblockCardPermissionDeniedModal } from 'presentation/components/global/Modal';
import { ActivateCardModal } from 'presentation/pages/cards/components';
import {
  RequestBalanceModal,
  ResetPinModal
} from 'presentation/pages/cards/Statement/UserStatement/components/Sidebar/Modal';

import { useCardActions } from './useCardActions';

import { Container } from './CardActions.styles';

export function CardActions(): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { handleOpenModal, visibleModal } = useModalContext();

  const {
    handleBlockUnblockCard,
    actionInProgress,
    isBlockingOrUnblockingCard,
    handleConfirmBlockOrUnblockAction,
    isFetchingUserAccountsOrFetchingCardData,
    cardData
  } = useCardActions();

  const confirmationModalTitle =
    actionInProgress === 'block'
      ? lang.cards.global.block_card[currentLangKey]
      : lang.cards.global.unblock_card[currentLangKey];

  const userHasCard = !!cardData;
  const userHasNoCard = cardData === null;

  return (
    <>
      <Container>
        {isFetchingUserAccountsOrFetchingCardData && (
          <>
            <SkeletonLoader
              height='3.1rem'
              margin='1.3rem 0'
            />
            <SkeletonLoader
              height='3.1rem'
              margin='0 0 1.3rem'
            />
            <SkeletonLoader
              height='3.1rem'
              margin='0 0 1.3rem'
            />
          </>
        )}

        {/* ATIVAR CARTÃO */}
        {userHasNoCard && (
          <RectButton
            type='button'
            size='xl'
            color='puertoRico'
            iconProps={{
              name: 'lock',
              color: 'white',
              size: 1.6
            }}
            onClick={() => {
              handleOpenModal('activateCard');
            }}
          >
            {lang.cards.statement.sidebar.options.activate_card[currentLangKey]}
          </RectButton>
        )}

        {userHasCard && (
          <>
            {/* SOLICITAR SALDO */}
            <RectButton
              type='button'
              size='xl'
              color='puertoRico'
              iconProps={{
                name: 'add',
                color: 'white',
                size: 1.6
              }}
              onClick={() => {
                handleOpenModal('requestBalance');
              }}
            >
              {
                lang.cards.statement.sidebar.options.request_balance[
                  currentLangKey
                ]
              }
            </RectButton>

            {/* BLOQUEAR CARTÃO */}
            {cardData.status === 'ACTIVE' && (
              <RectButton
                type='button'
                size='xl'
                color='danger'
                iconProps={{
                  name: 'lock',
                  color: 'white',
                  size: 1.6
                }}
                onClick={() => {
                  handleBlockUnblockCard('block');
                }}
              >
                {lang.cards.global.block_card[currentLangKey]}
              </RectButton>
            )}

            {/* DESBLOQUEAR CARTÃO */}
            {cardData.status === 'BLOCKED' && (
              <RectButton
                type='button'
                size='xl'
                color='success'
                iconProps={{
                  name: 'unlock',
                  color: 'white',
                  size: 1.6
                }}
                onClick={() => {
                  handleBlockUnblockCard('unblock');
                }}
              >
                {lang.cards.global.unblock_card[currentLangKey]}
              </RectButton>
            )}

            {/* RESETAR PIN */}
            {cardData.status === 'ACTIVE' && (
              <RectButton
                type='button'
                size='xl'
                color='primary'
                iconProps={{
                  name: 'pencil',
                  color: 'white',
                  size: 1.6
                }}
                onClick={() => {
                  handleOpenModal('resetCardPin');
                }}
              >
                {lang.cards.statement.sidebar.options.pin_reset[currentLangKey]}
              </RectButton>
            )}
          </>
        )}
      </Container>

      {visibleModal === 'requestBalance' && <RequestBalanceModal />}

      {visibleModal === 'resetCardPin' && <ResetPinModal />}

      {visibleModal === 'activateCard' && <ActivateCardModal />}

      {visibleModal === 'confirmation' && (
        <ConfirmationModal
          onConfirm={handleConfirmBlockOrUnblockAction}
          title={confirmationModalTitle}
          isLoading={isBlockingOrUnblockingCard}
        />
      )}

      {visibleModal === 'unblockCardPermissionDenied' && (
        <UnblockCardPermissionDeniedModal
          lockLevel={cardData?.lockLevel as 'MANAGER' | 'SYSTEM'}
        />
      )}
    </>
  );
}
