import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;

  th {
    white-space: nowrap;
  }

  tbody tr {
    cursor: pointer;

    &td + td:nth-last-child(2) {
      font-weight: bold;
    }
  }
`;
