import { useTranslation } from 'react-i18next';

import { AnalysisDefaultContainer } from 'presentation/pages/analytics/Analysis/components/AnalysisDefaultContainer';
import { AnalysisHeader } from 'presentation/pages/analytics/Analysis/components/AnalysisHeader';
import { UserAnalysisAppliedFilters } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisAppliedFilters';
import { UserAnalysisResume } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisResume';
import { DefaultLayout } from 'presentation/templates';

import { analysisPagesPaths } from 'shared/constants/analytics';
import { routesPathPrefix } from 'shared/constants/global';

export function UserExpensesResume(): JSX.Element {
  const { t } = useTranslation(['analytics', 'global']);

  return (
    <DefaultLayout
      pageNameAndBreadcrumbs={{
        pageName: t('pageName.userExpensesResume'),
        isBreadcrumbsVisible: true,
        isBackButtonVisible: true,
        isClassicMode: false,
        breadcrumbs: [
          {
            name: t('pageName.analytics'),
            pageLink: routesPathPrefix.analytics
          },
          {
            name: t('pageName.userExpenses'),
            pageLink: `${routesPathPrefix.analytics}/${analysisPagesPaths.userExpenses}`
          },
          { name: t('pageName.userExpensesResume') }
        ]
      }}
    >
      <AnalysisDefaultContainer>
        <AnalysisHeader />

        <UserAnalysisAppliedFilters />

        <UserAnalysisResume isFullResume />
      </AnalysisDefaultContainer>
    </DefaultLayout>
  );
}
