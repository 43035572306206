import { BaseIcon } from 'presentation/components/base/Icon';

import { type IReversalIconProps } from './ReversalIcon.types';

import { Container } from './ReversalIcon.styles';

export function ReversalIcon({
  backgroundColor = 'tulipTree',
  size = 1.6,
  iconProps
}: IReversalIconProps): JSX.Element {
  return (
    <Container
      $size={size}
      $backgroundColor={backgroundColor}
    >
      <BaseIcon {...iconProps} />
    </Container>
  );
}
