import { useQueryCache } from 'data/cache';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IGetCompanyOnboardingShareholders
} from 'data/modules/cards/companyOnboarding';

export function useGetCompanyOnboardingShareholders(): IGetCompanyOnboardingShareholders {
  const { data, isLoading } = useQueryCache({
    queryKey: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_SHAREHOLDERS],
    queryFn: async () =>
      await CompanyOnboardingService.getCompanyOnboardingShareholders()
  });

  return {
    companyOnboardingShareholders: data ?? [],
    isCompanyOnboardingShareholdersLoading: isLoading
  };
}
