import { useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds';
import { useTranslation } from 'react-i18next';

import { useMutationCache } from 'data/cache';
import { type IErrorDefault } from 'data/contexts/modal/useModalContext.types';
import {
  FuelMutationKeys,
  FuelQueryKeys,
  FuelService,
  type ICreateVehicleForm,
  type IUseCreateVehicle,
  type IUseCreateVehicleParams,
  useGetPaginatedCompanyVehicles,
  useGetPaginatedMembers,
  useGetVehicle
} from 'data/modules/fuel';

import { useFormWithSchema } from 'shared/hooks/forms';
import { ErrorHandle } from 'shared/utils/errors';

import { useLinkOrUnlinkMembersFromVehicle } from '../link-or-unlink-members-from-vehicle/useLinkOrUnlinkMembersFromVehicle';

import { useCreateVehicleSchema } from './schemas';

export function useCreateVehicle({
  onSuccessCreateVehicle,
  onSuccessUpdateVehicle,
  onErrorCreateOrUpdateVehicle,
  vehicleIdToUpdate,
  linkMembersOnCreate,
  linkMembersOnUpdate,
  onAfterSubmitDefaultFields
}: IUseCreateVehicleParams): IUseCreateVehicle {
  const queryClient = useQueryClient();

  const { t } = useTranslation('fuel');

  const { t: tGlobal } = useTranslation('global');

  const createVehiceFormMethods = useFormWithSchema(useCreateVehicleSchema());

  const createVehicleFormDefaultFieldsData = useRef<ICreateVehicleForm | null>(
    null
  );

  const {
    getVehicle,
    isFetchingAndPendingVehicle: isFetchingAndPendingVehicleOnUpdate
  } = useGetVehicle({
    vehicleId: 0,
    enabled: false
  });

  const {
    getEnsuredPaginatedCompanyVehicles,
    isFetchingAndPending: isFetchingAndPendingVehiclePlate,
    removeQueriesByPlate
  } = useGetPaginatedCompanyVehicles({
    enabled: false
  });

  const { isLoading: isCreatingVehicle, mutateAsync: createVehicleAsync } =
    useMutationCache({
      mutationKey: [FuelMutationKeys.CREATE_VEHICLE],
      mutationFn: FuelService.createVehicle
    });

  const {
    isLinkingOrUnlinkingMembersFromVehicle,
    linkOrUnlinkMembersFromVehicleAsync
  } = useLinkOrUnlinkMembersFromVehicle();

  const { removeQueriesByVehicleId } = useGetPaginatedMembers({
    enabled: false
  });

  const { isLoading: isUpdatingVehicle, mutateAsync: updateVehicleAsync } =
    useMutationCache({
      mutationKey: [FuelMutationKeys.UPDATE_VEHICLE, vehicleIdToUpdate],
      mutationFn: FuelService.updateVehicle
    });

  async function onSubmitCreateVehicleForm(
    data: ICreateVehicleForm
  ): Promise<void> {
    createVehicleFormDefaultFieldsData.current = data;

    const userAction = vehicleIdToUpdate ? 'update' : 'create';

    // se for uma atualização, verifica se a placa foi alterada
    // caso não tenha sido, não faz a request de verificação de placa
    if (userAction === 'update') {
      const vehicleToUpdate = await getVehicle({
        vehicleId: vehicleIdToUpdate as number
      });

      if (vehicleToUpdate.plate === data.plate) {
        onAfterSubmitDefaultFields?.();
        return;
      }
    }

    try {
      const vehicles = await getEnsuredPaginatedCompanyVehicles({
        plate: data.plate
      });

      if (vehicles?.total === 0) {
        onAfterSubmitDefaultFields?.();
        return;
      }

      toast.errorWithoutToastId(t('modal.plateAlreadyExists'));
    } catch (e) {
      toast.error(tGlobal('opsError'));
    }
  }

  async function createVehicle(): Promise<void> {
    try {
      const createdVehicle = await createVehicleAsync(
        createVehicleFormDefaultFieldsData.current as ICreateVehicleForm
      );

      // caso linkAllMembers seja true ou o array de detach tenha algum elemento,
      // chama a request de vincular todos e depois a request de desvincular o array
      // a lógica foi feita dessa forma por conta da paginação da lista de membros no segundo step
      if (
        linkMembersOnCreate.linkAllMembers ||
        linkMembersOnCreate.detachMembersIds.length > 0
      ) {
        await linkOrUnlinkMembersFromVehicleAsync({
          vehicleId: createdVehicle.id,
          membersIdsToAttach: [0]
        });

        if (linkMembersOnCreate.detachMembersIds.length > 0) {
          await linkOrUnlinkMembersFromVehicleAsync({
            vehicleId: createdVehicle.id,
            membersIdsToDetach: linkMembersOnCreate.detachMembersIds
          });
        }
      } else if (linkMembersOnCreate.attachMembersIds.length > 0) {
        linkOrUnlinkMembersFromVehicleAsync({
          vehicleId: createdVehicle.id,
          membersIdsToAttach: linkMembersOnCreate.attachMembersIds
        });
      }

      queryClient.invalidateQueries({
        queryKey: [FuelQueryKeys.GET_COMPANY_VEHICLES]
      });

      // para forçar a verificação de placa novamente
      removeQueriesByPlate(createdVehicle.plate);

      onSuccessCreateVehicle?.();
    } catch (e) {
      const errorMessage =
        ErrorHandle.getErrorMessage(e as IErrorDefault) ??
        t('modal.errorCreatingVehicle');

      onErrorCreateOrUpdateVehicle?.(errorMessage);
    }
  }

  async function makeRequestsToLinkOrUnlinkMembersWhenUpdate(): Promise<void> {
    // se o usuário manipulou o campo de "Selecionar todos"
    if (linkMembersOnUpdate.clickedOnSelectAllMembers) {
      // caso o "Selecionar todos esteja marcado" ou tenha algo no Set de detach
      if (
        linkMembersOnUpdate.all ||
        linkMembersOnUpdate.detachMembersIds.size > 0
      ) {
        // primeiro, vai vincular todos e depois desvincular os selecionados
        await linkOrUnlinkMembersFromVehicleAsync({
          vehicleId: vehicleIdToUpdate as number,
          membersIdsToAttach: [0]
        });

        if (linkMembersOnUpdate.detachMembersIds.size > 0) {
          await linkOrUnlinkMembersFromVehicleAsync({
            vehicleId: vehicleIdToUpdate as number,
            membersIdsToDetach: Array.from(linkMembersOnUpdate.detachMembersIds)
          });
        }
      } else {
        // se não, vai desvincular todos e depois vincular os selecionados
        await linkOrUnlinkMembersFromVehicleAsync({
          vehicleId: vehicleIdToUpdate as number,
          membersIdsToDetach: [0]
        });

        if (linkMembersOnUpdate.attachMembersIds.size > 0) {
          await linkOrUnlinkMembersFromVehicleAsync({
            vehicleId: vehicleIdToUpdate as number,
            membersIdsToAttach: Array.from(linkMembersOnUpdate.attachMembersIds)
          });
        }
      }

      return;
    }

    // caso não tenha manipulado o campo de "Selecionar todos"
    // e existir alguma coisa no Set de attach ou detach de membros alterados
    if (
      linkMembersOnUpdate.changedMembers.attach.size > 0 ||
      linkMembersOnUpdate.changedMembers.detach.size > 0
    ) {
      await linkOrUnlinkMembersFromVehicleAsync({
        vehicleId: vehicleIdToUpdate as number,
        membersIdsToAttach: Array.from(
          linkMembersOnUpdate.changedMembers.attach
        ),
        membersIdsToDetach: Array.from(
          linkMembersOnUpdate.changedMembers.detach
        )
      });
    }
  }

  async function updateVehicle(): Promise<void> {
    try {
      const updateVehicle = await updateVehicleAsync({
        ...(createVehicleFormDefaultFieldsData.current as ICreateVehicleForm),
        vehicleId: vehicleIdToUpdate as number
      });

      await makeRequestsToLinkOrUnlinkMembersWhenUpdate();

      queryClient.setQueryData(
        [FuelQueryKeys.GET_VEHICLE, vehicleIdToUpdate],
        updateVehicle
      );

      queryClient.invalidateQueries({
        queryKey: [FuelQueryKeys.GET_COMPANY_VEHICLES]
      });

      // nesse caso, vai remover do cache os dados armazenados referentes as requests dos membros do veículo
      // para que, ao acessar o step de membros ou o modal de vincular/desvincular membros, os dados sejam atualizados
      removeQueriesByVehicleId(vehicleIdToUpdate as number);

      // para forçar a verificação de placa novamente
      removeQueriesByPlate(updateVehicle.plate);

      onSuccessUpdateVehicle?.();
    } catch (e) {
      const errorMessage =
        ErrorHandle.getErrorMessage(e as IErrorDefault) ??
        t('modal.errorUpdatingVehicle');

      onErrorCreateOrUpdateVehicle?.(errorMessage);
    }
  }

  function handleSubmitCreateOrUpdateVehicle(): void {
    if (vehicleIdToUpdate) {
      updateVehicle();
      return;
    }

    createVehicle();
  }

  return {
    defaultFields: {
      formMethods: createVehiceFormMethods,
      handleSubmit: createVehiceFormMethods.handleSubmit(
        onSubmitCreateVehicleForm
      )
    },
    isCreatingVehicle:
      isCreatingVehicle || isLinkingOrUnlinkingMembersFromVehicle,
    isUpdatingVehicle,
    handleSubmitCreateOrUpdateVehicle,
    isSearchingPlate:
      isFetchingAndPendingVehiclePlate || isFetchingAndPendingVehicleOnUpdate
  };
}
