import styled from 'styled-components';

export const Container = styled.nav`
  width: 100%;
`;

export const TabsList = styled.ul`
  width: 100%;
  display: flex;
`;
