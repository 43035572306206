import type Highcharts from 'highcharts';
import {
  type Tooltip,
  type TooltipFormatterCallbackFunction,
  type TooltipFormatterContextObject
} from 'highcharts';
import { renderToString } from 'react-dom/server';

import { type TooltipFormatterType } from 'ds/types/charts/charts.types';

class Charts {
  /**
   * Estende o protótipo SVGRenderer do Highcharts e customiza o método que calcula
   * a cor de contraste, para retornar um token de cor do DS.
   */
  getCustomContrast(
    H: typeof Highcharts,
    lowColor: string,
    highColor: string
  ): void {
    H.SVGRenderer.prototype.getContrast = function (color) {
      const rgbaStr = new H.Color(color).get('rgba') as string;

      const rgba = rgbaStr
        .replace(/[^\d,]+/g, '')
        .split(',')
        .map(part => Number(part));

      return rgba[0] + rgba[1] + rgba[2] > 500 ? lowColor : highColor;
    };
  }

  getCustomLabelFormatter<ThisType, OptionsType, ReturnType>(
    formatter?: (ctx: ThisType, opt: OptionsType) => ReturnType
  ): ((this: ThisType, opt: OptionsType) => ReturnType) | undefined {
    if (formatter) {
      return function (this: ThisType, opt: OptionsType): ReturnType {
        return formatter(this, opt);
      };
    }
  }

  getTooltipFormatter(
    formatter?: TooltipFormatterType
  ): TooltipFormatterCallbackFunction {
    return function (
      this: TooltipFormatterContextObject,
      tooltip: Tooltip
    ): ReturnType<TooltipFormatterCallbackFunction> {
      if (formatter) {
        return formatter(this, tooltip);
      }

      return renderToString(
        <>
          <tspan style={{ color: this.point.color?.toString() }}>●</tspan>
          {` ${this.point.category ?? this.point.name}: `}
          <tspan style={{ fontWeight: 700 }}>{this.point.y}</tspan>
        </>
      );
    };
  }
}

export default new Charts();
