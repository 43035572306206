import { useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useAuthContext, useCardGroupsContext } from 'data/contexts';
import { useGetUnallocatedBalances } from 'data/modules/cards/balance';
import {
  useGetCardGroups,
  useGetGroupManagers
} from 'data/modules/cards/cardGroups';

import { useDebounce } from 'shared/hooks/global';

import { type IUseCardGroupsTable } from './CardGroupsTable.types';

export function useCardGroupsTable(): IUseCardGroupsTable {
  const [
    { managers, name },
    page,
    limit,
    ordenationType,
    handleChangePagination,
    handleSetHasCardGroups
  ] = useCardGroupsContext(
    useShallow(state => [
      state.filters,
      state.pagination.page,
      state.pagination.limit,
      state.ordenationType,
      state.handleChangePagination,
      state.handleSetHasCardGroups
    ])
  );

  const user = useAuthContext(state => state.user);

  const navigate = useNavigate();

  const debouncedName = useDebounce(name);

  const { paginatedCardGroups, isFetchingCardGroups, isSuccessGetCardGroups } =
    useGetCardGroups({
      page,
      limit,
      ...(debouncedName && { name: debouncedName.trim().toLowerCase() }),
      ...(managers && { managers }),
      ...(ordenationType && { ordenationType }),
      enabled:
        user?.cards?.type === 'ACCOUNT_MANAGER' ||
        user?.cards?.type === 'FINANCIAL_MANAGER'
    });

  const { paginatedManagersGroups, isFetchingManagersGroups } =
    useGetGroupManagers({
      page,
      limit,
      ...(debouncedName && { name: debouncedName.trim().toLowerCase() }),
      ...(ordenationType && { ordenationType }),
      enabled: user?.cards?.type === 'USER'
    });

  useEffect(() => {
    if (
      isSuccessGetCardGroups &&
      paginatedCardGroups?.data.length &&
      paginatedCardGroups.data.length > 0
    ) {
      handleSetHasCardGroups(true);

      navigate('/cartoes/grupos-de-cartoes');
    }
  }, [
    handleSetHasCardGroups,
    isSuccessGetCardGroups,
    navigate,
    paginatedCardGroups?.data.length
  ]);

  function getCardGroupsIds(): string[] {
    const data =
      user?.cards?.type === 'USER'
        ? paginatedManagersGroups?.data
        : paginatedCardGroups?.data;

    if (!data) {
      return [];
    }

    return data.map(item => item.id);
  }

  const { unallocatedBalances, isFetchingUnallocatedBalances } =
    useGetUnallocatedBalances({
      cardGroupIds: getCardGroupsIds(),
      enabled: paginatedCardGroups && !isFetchingCardGroups
    });

  const cardGroups = useMemo(() => {
    const data =
      user?.cards?.type === 'USER'
        ? paginatedManagersGroups?.data
        : paginatedCardGroups?.data;

    return data?.map((cardGroup, index) => ({
      ...cardGroup,
      availableValue: unallocatedBalances[index]
    }));
  }, [
    unallocatedBalances,
    user?.cards?.type,
    paginatedCardGroups?.data,
    paginatedManagersGroups?.data
  ]);

  useEffect(() => {
    if (paginatedCardGroups && !isFetchingCardGroups) {
      handleChangePagination({
        total: paginatedCardGroups.total
      });
    }
  }, [paginatedCardGroups, isFetchingCardGroups, handleChangePagination]);

  return {
    cardGroups,
    isFetchingCardGroups: isFetchingCardGroups || isFetchingManagersGroups,
    isFetchingAmounts: isFetchingUnallocatedBalances
  };
}
