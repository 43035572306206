import { toast } from 'ds/utils';

import { useAuthContext, useLangContext, useModalContext } from 'data/contexts';
import {
  useGetCardDataByAccountId,
  useGetOutsourcedUserAccounts,
  useGetUserAccountsByUserUuid,
  useResetCardPin
} from 'data/modules/cards/account';

import { type IUseResetPinModal } from './ResetPinModal.types';

export function useResetPinModal(): IUseResetPinModal {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);
  const user = useAuthContext(state => state.user);
  const { handleCloseModal } = useModalContext();

  const isSharedAccount = !!user?.cards?.sharedAccounts;

  const { userAccounts } = useGetUserAccountsByUserUuid({
    userUuid: user?.cards?.id as string,
    enabled: !isSharedAccount
  });

  const { outsourcedUserAccounts } = useGetOutsourcedUserAccounts({
    outsourcedUserUuid: user?.cards?.id as string,
    enabled: isSharedAccount
  });

  const { cardData } = useGetCardDataByAccountId({
    accountId: isSharedAccount
      ? (outsourcedUserAccounts?.[0].account.id as string)
      : (userAccounts?.[0].account.id as string),
    enabled: isSharedAccount
      ? !!outsourcedUserAccounts?.[0].account.id
      : !!userAccounts?.[0].account.id
  });

  const { handleResetCardPin, isResetingCardPin, control, errors, reset } =
    useResetCardPin({
      accountId: isSharedAccount
        ? (outsourcedUserAccounts?.[0].account.id as string)
        : (userAccounts?.[0].account.id as string),
      onAfterSuccess: () => {
        toast.success(
          lang.cards.statement.reset_pin_modal.pin_set_successful[
            currentLangKey
          ]
        );
        handleCloseModal();
      },
      resetFormOnSuccess: true
    });

  function handleCloseModalOnClick(): void {
    reset();
    handleCloseModal();
  }

  return {
    cardNumber: cardData?.panMasked ?? null,
    control,
    errors,
    handleResetCardPin,
    isResetingCardPin,
    handleCloseModalOnClick
  };
}
