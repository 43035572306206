import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  border-radius: 10px;
  overflow: hidden;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.2rem;
  background-color: ${({ theme }) => theme.tokens.brandColorSecondaryPure};
  flex-shrink: 0;
`;

export const Content = styled.div`
  display: flex;
  gap: 2rem;
  flex-grow: 1;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 16rem;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  > h1 {
    font-size: 2.1rem;
    text-align: left;
  }

  > p {
    font-size: 1.5rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MoreDetailsButton = styled.button`
  width: fit-content;
  background-color: transparent;
  border: none;
  font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto};
  font-weight: 500;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.tokens.brandColorSecondaryPure};
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > p {
    font-weight: 400;
    font-size: 1.4rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  gap: 2rem;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighMedium};
`;
