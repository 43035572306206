import { useMutationCache } from 'data/cache';
import {
  type IExportMovementsInExcelPayload,
  type IUseExportMovementsInExcel,
  MovementsMutationKeys,
  MovementsService
} from 'data/modules/cards/movements';

export function useExportMovementsInExcel(): IUseExportMovementsInExcel {
  const {
    data: movementsInExcelData,
    isPending,
    mutateAsync
  } = useMutationCache({
    mutationKey: [MovementsMutationKeys.EXPORT_MOVEMENTS_IN_EXCEL],
    mutationFn: async (payload: IExportMovementsInExcelPayload) =>
      await MovementsService.exportMovementsInExcel(payload)
  });

  return {
    movementsInExcelData,
    isExportingMovementsInExcel: isPending,
    exportMovementsInExcel: mutateAsync
  };
}
