import { SkeletonLoader } from 'ds';

import { useSelectDynamicDefault } from './useSelectDynamicDefault';

import {
  type ISelectDynamicDefaultOption,
  type ISelectDynamicDefaultProps
} from './SelectDynamicDefault.types';

import {
  Caption,
  Container,
  Fieldset,
  Label,
  Legend,
  StyledSelect,
  Wrapper
} from './SelectDynamicDefault.styles';

export function SelectDynamicDefault({
  options,
  disabled,
  className,
  placeholder,
  noOptionsMessage,
  hasArrowDownIndicator = false,
  isLoading = false,
  label,
  large = false,
  small = false,
  menuPlacement = 'bottom',
  isLoadingMoreOptions,
  onChangeSearchValue,
  onMenuScrollToBottom,
  searchValue,
  langKey = 'pt',
  onChange,
  value,
  error
}: ISelectDynamicDefaultProps): JSX.Element {
  const {
    DropdownIndicator,
    LoadingIndicator,
    placeholderMessage,
    loadingMessage,
    noMoreOptionsMessage,
    optionsToRender
  } = useSelectDynamicDefault({
    langKey,
    placeholder,
    noOptionsMessage,
    options,
    searchValue,
    value
  });

  return (
    <Container>
      {isLoading ? (
        <SkeletonLoader height='3rem' />
      ) : (
        <div>
          <Wrapper
            $small={small}
            $large={large}
          >
            <StyledSelect
              $large={large}
              $error={!!error}
              $small={small}
              $hasArrowDownIndicator={hasArrowDownIndicator}
              $isTouched={!!value}
              $disabled={disabled}
              components={{ DropdownIndicator, LoadingIndicator }}
              isLoading={isLoadingMoreOptions}
              classNamePrefix='react-select-custom'
              placeholder={placeholderMessage}
              captureMenuScroll
              menuPlacement={menuPlacement}
              isDisabled={disabled}
              options={optionsToRender}
              className={className}
              isOptionSelected={(option: unknown) => {
                const typedOption = option as ISelectDynamicDefaultOption;
                return !!value && value.value === typedOption.value;
              }}
              // como o filtro é feito no backend, não é necessário filtrar aqui
              filterOption={() => true}
              value={value}
              onChange={(newValue: unknown) => {
                const typedNewValue = newValue as ISelectDynamicDefaultOption;
                onChange(typedNewValue);
              }}
              noOptionsMessage={() => noMoreOptionsMessage}
              loadingMessage={() => loadingMessage}
              onMenuScrollToBottom={onMenuScrollToBottom}
              inputValue={searchValue}
              onInputChange={(value: string) => {
                onChangeSearchValue(value);
              }}
            />

            <Fieldset>
              <Legend>
                <span>{label}</span>
              </Legend>
            </Fieldset>

            {label && <Label>{label}</Label>}
          </Wrapper>

          {error && <Caption $error={true}>{error}</Caption>}
        </div>
      )}
    </Container>
  );
}
