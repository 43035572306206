import { DSIcons } from 'ds';

import { RouteInput as Input } from './RouteInput.component';
import { RouteInputProvider } from './RouteInput.context';

export const RouteInput = {
  Input,
  Container: RouteInputProvider,
  Separator: DSIcons.DotsVerticalIcon
};
