import { faker } from '@faker-js/faker';

import {
  type AccessAccountsType,
  type CardLockLevelType,
  type IPaginatedAccounts,
  type StatusType
} from 'data/modules/cards/account';

import { MockApi } from 'shared/utils/global';

import {
  accessAccountsTypeOptions,
  cardLockLevelOptions,
  statusWithNullOptions
} from '..';

export async function getAccountsMock(): Promise<IPaginatedAccounts> {
  const data = [
    {
      individual: {
        userId: faker.string.uuid(),
        userName: faker.person.fullName(),
        account: {
          id: faker.string.uuid(),
          status: faker.helpers.arrayElement(
            statusWithNullOptions
          ) as StatusType,
          cardNumber: faker.string.numeric(16),
          cardStatus: faker.helpers.arrayElement(
            statusWithNullOptions
          ) as StatusType,
          cardLockLevel: faker.helpers.arrayElement(
            cardLockLevelOptions
          ) as CardLockLevelType,
          balances: [
            {
              id: faker.string.uuid(),
              description: faker.lorem.sentence(2)
            }
          ]
        }
      },
      business: null
    },
    {
      individual: null,
      business: {
        id: faker.string.uuid(),
        name: faker.company.name(),
        status: faker.helpers.arrayElement(statusWithNullOptions) as StatusType,
        type: 'CORP_BUSINESS' as 'CORP_BUSINESS',
        description: faker.lorem.sentence(2),
        cardNumber: faker.string.numeric(16),
        cardStatus: faker.helpers.arrayElement(
          statusWithNullOptions
        ) as StatusType,
        cardLockLevel: faker.helpers.arrayElement(
          cardLockLevelOptions
        ) as CardLockLevelType,
        accessAccounts: [
          {
            id: faker.string.uuid(),
            type: faker.helpers.arrayElement(
              accessAccountsTypeOptions
            ) as AccessAccountsType
          }
        ],
        balances: [
          {
            id: faker.string.uuid(),
            description: faker.lorem.sentence(2)
          }
        ]
      }
    }
  ];

  await MockApi.sleep(2000);

  return {
    data,
    total: data.length
  };
}
