import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type NpsModalItemStylesType } from './NpsModal.types';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  align-items: end;
  justify-content: center;
  gap: ${tokens.spacingXs};

  h3 {
    color: ${tokens.neutralColorLowDark};
    text-align: justify;
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxs};
    font-weight: 400;
    line-height: 3.2rem;
    letter-spacing: 0.075rem;
  }
`;

export const Content = styled.div`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXs};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
  text-align: center;
`;

export const SubTitle = styled.p`
  color: ${tokens.neutralColorLowLightest};
  text-align: center;
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: 500;
  line-height: 2.2rem;
  letter-spacing: 0.025rem;
  margin: ${tokens.spacingMd} 0;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  gap: ${tokens.spacingMd};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: end;
  gap: ${tokens.spacingNano};

  button {
    width: 100%;
  }

  button:last-of-type {
    color: ${tokens.neutralColorLowMediumLight};
  }
`;

export const NpsItemVote = styled.div<AddPrefixToType<NpsModalItemStylesType>>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.6rem;
  width: 3.6rem;
  border-radius: ${tokens.borderRadiusNano};
  color: ${tokens.neutralColorHighPure};
  text-align: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 2.2rem;
  cursor: pointer;
  transition: all 100ms ease-in-out;

  ${({ $active }) =>
    !$active &&
    css`
      opacity: 0.25;
    `}

  &:nth-of-type(1) {
    background-color: #b72224;
  }

  &:nth-of-type(2) {
    background-color: #d52029;
  }

  &:nth-of-type(3) {
    background-color: #e95223;
  }

  &:nth-of-type(4) {
    background-color: #ea6f22;
  }

  &:nth-of-type(5) {
    background-color: #f6a726;
  }

  &:nth-of-type(6) {
    background-color: #fdc729;
  }

  &:nth-of-type(7) {
    background-color: #ebdb0a;
  }

  &:nth-of-type(8) {
    background-color: #e5e044;
  }

  &:nth-of-type(9) {
    background-color: #c2d234;
  }

  &:nth-of-type(10) {
    background-color: #aec93c;
  }

  &:nth-of-type(11) {
    background-color: #66b44e;
  }
`;

export const NpsItemVoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${tokens.spacingQuarck};
`;
