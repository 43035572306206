import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';

import { DefaultLayout } from 'presentation/templates';

import { MyCardPageHeader, MyCardTabs } from '../components';

import {
  MyValueSolicitationsDetailsSideModal,
  MyValueSolicitationsTable
} from './components';

import { PageContainer, PageContent } from './MyValueSolicitations.styles';

export function MyValueSolicitations(): JSX.Element {
  const { t } = useTranslation('cards');

  const { visibleModal } = useModalContext();

  document.title = `vexpenses | ${t('myCard')}`;

  return (
    <DefaultLayout
      pageNameAndBreadcrumbs={{
        pageName: t('myCard'),
        isBackButtonVisible: false,
        isBreadcrumbsVisible: false,
        isClassicMode: false
      }}
    >
      <PageContainer>
        <MyCardPageHeader />

        <PageContent>
          <MyCardTabs selectedTab='MY_SOLICITATIONS' />

          <MyValueSolicitationsTable />
        </PageContent>
      </PageContainer>

      <MyValueSolicitationsDetailsSideModal
        isOpen={visibleModal === 'myValueSolicitationsDetails'}
        title={t('details')}
        side='right'
      />
    </DefaultLayout>
  );
}
