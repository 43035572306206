import { useCallback, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useLangContext, useModalContext } from 'data/contexts';
import {
  BalanceMutationKeys,
  BalanceService,
  type IRequestCardsFieldsForm,
  type IUseRequestCards
} from 'data/modules/cards/balance';
import { GlobalQueryKeys, GlobalService } from 'data/modules/global';

import { useFormWithSchema } from 'shared/hooks/forms';

import { useRequestCardsSchema } from './schemas';

export function useRequestCards(): IUseRequestCards {
  const [modalStep, setModalStep] = useState(1);
  const [summaryData, setSummaryData] =
    useState<IRequestCardsFieldsForm | null>(null);

  const [isLoadingByPostalCode, setIsLoadingByPostalCode] = useState(false);

  const { handleCloseModal } = useModalContext();
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const methods = useFormWithSchema(useRequestCardsSchema());

  const postalCode = methods.watch('postalCode', '');

  const queryClient = useQueryClient();

  const handleFillFieldsByPostalCode = useCallback(async () => {
    if (postalCode.length < 9) {
      return;
    }

    setIsLoadingByPostalCode(true);

    try {
      const address = await queryClient.ensureQueryData({
        queryKey: [
          GlobalQueryKeys.GET_VIA_CEP_ADDRESS_BY_POSTAL_CODE,
          postalCode
        ],
        queryFn: async () =>
          await GlobalService.getViaCepAddressByPostalCode({ postalCode })
      });

      if (!address) {
        throw new Error();
      }

      methods.clearErrors();

      methods.setValue('postalCode', address.postalCode);
      methods.setValue('street', address.street);
      methods.setValue('neighborhood', address.neighborhood);
      methods.setValue('complement', address.complement);
      methods.setValue('city', address.city);
      methods.setValue('state', address.state);
    } catch {
      toast.error(lang.global.postal_code_not_found[currentLangKey]);
    } finally {
      setIsLoadingByPostalCode(false);
    }
  }, [currentLangKey, lang, methods, postalCode, queryClient]);

  useEffect(() => {
    handleFillFieldsByPostalCode();
  }, [handleFillFieldsByPostalCode]);

  function onSubmit(data: IRequestCardsFieldsForm): void {
    mutation.mutate(data);
  }

  const mutation = useMutationCache({
    mutationKey: [BalanceMutationKeys.REQUEST_CARDS],
    mutationFn: async (payload: IRequestCardsFieldsForm) => {
      await BalanceService.requestCards(payload);
    },
    onSuccess: () => {
      toast.success(
        lang.cards.requestCards.request_sent_successfully[currentLangKey]
      );

      setSummaryData(null);
      handleCloseModal();
      methods.reset({
        city: '',
        complement: '',
        neighborhood: '',
        number: '',
        phoneNumber: '',
        postalCode: '',
        quantity: 1,
        receiver: '',
        state: '',
        street: ''
      });
      setModalStep(1);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  return {
    methods,
    isSubmiting: mutation.isLoading,
    isLoadingAddress: isLoadingByPostalCode,
    summaryData,
    modalStep,
    setModalStep,
    handleSubmit: methods.handleSubmit(onSubmit)
  };
}
