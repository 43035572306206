import { type IAccordionCaptionProps } from './AccordionCaption.types';

import { Container } from './AccordionCaption.styles';

export function AccordionCaption({
  children,
  ...props
}: IAccordionCaptionProps): JSX.Element {
  return <Container {...props}>{children}</Container>;
}
