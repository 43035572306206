import { Modal, tokens } from 'ds';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ModalContainer = styled(Modal.Container)`
  max-width: 50rem;
  width: 50rem;

  @media screen and (max-width: 500px) {
    max-width: unset;
    width: auto;
  }
`;

export const ModalForm = styled(Modal.Form)`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};

  p {
    font-weight: ${tokens.fontWeightRegular};
    font-size: ${tokens.fontSizeXxs};
    color: ${tokens.neutralColorLowDark};
    letter-spacing: 0.075rem;
    line-height: ${tokens.spacingMd};
  }
`;

export const CardContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 30rem;
    height: 18.9rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${tokens.spacingXs};
  max-width: unset;
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
  margin-top: ${tokens.spacingInsetXs};
`;
