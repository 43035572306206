import { type ICardsTableEmptyProps } from './CardsTableEmpty.types';

import { Container } from './CardsTableEmpty.styles';

export function CardsTableEmpty({
  children,
  ...rest
}: ICardsTableEmptyProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
