import {
  type IUserOnboardingAnswers,
  type PersistenceUserOnboardingAnswersType
} from 'data/modules/cards/userOnboarding';

import { CustomDate } from 'shared/utils/custom';
import { Mask } from 'shared/utils/format';

class GetUserOnboardingAnswersMapper {
  toDomain(
    payload: PersistenceUserOnboardingAnswersType
  ): IUserOnboardingAnswers {
    const userOnboardingAnswers = payload.reduce<IUserOnboardingAnswers>(
      (acc, curr) => {
        if (curr.context === 'user_account') {
          return {
            ...acc,
            userAccount: {
              birthDate: CustomDate.formatFromISO(curr.answers.birth_date),
              documentNumber: Mask.apply('cpf', curr.answers.document_number),
              motherName: curr.answers.mother_name,
              email: curr.answers.email,
              fullName: curr.answers.full_name,
              phoneNumber:
                curr.answers.phone_number &&
                Mask.apply('phone', curr.answers.phone_number.slice(2)),
              street: curr.answers.street,
              number: curr.answers.number,
              complement: curr.answers.complement,
              neighborhood: curr.answers.neighborhood,
              postalCode: Mask.apply('cep', curr.answers.postal_code),
              city: curr.answers.city,
              state: curr.answers.state,
              country: curr.answers.country
            }
          };
        }
        return {
          ...acc,
          userTermsOfUse: {
            acceptTermsOfUse: curr.answers.accept_terms_of_use,
            acceptPrivacyPolicy: curr.answers.accept_privacy_policy
          }
        };
      },
      {}
    );

    return userOnboardingAnswers ?? {};
  }
}

export default new GetUserOnboardingAnswersMapper();
