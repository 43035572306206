import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { type ICreateRouteExpenseSecondStepFieldsForm } from 'data/modules/expenses/types/expenses.types';

export function useSecondStepFormSchema(): ObjectSchema<ICreateRouteExpenseSecondStepFieldsForm> {
  const createManualRouteExpenseSecondStepSchema: ObjectSchema<ICreateRouteExpenseSecondStepFieldsForm> =
    useMemo(() => {
      return yup.object({
        report: yup.string()
      });
    }, []);

  return createManualRouteExpenseSecondStepSchema;
}
