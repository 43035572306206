import { toast } from 'ds/utils';
import { useNavigate } from 'react-router-dom';

import { useMutationCache } from 'data/cache';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type ILegalRepresentative,
  type IUpdatePartnerOrRepresentativeFieldsForm,
  type IUploadAuxiliaryDocumentPayload,
  type IUseUpdatePartnerOrRepresentative,
  type IUseUpdatePartnerOrRepresentativeParams,
  type UpdateCompanyOnboardingAnswersPayloadType
} from 'data/modules/cards/companyOnboarding';

import { useFormWithSchema } from 'shared/hooks/forms';
import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import { useUpdatePartnerOrRepresentativeSchema } from './schemas';

export function useUpdatePartnerOrRepresentative({
  onUpdateCompanyOnboardingAnswersMutationSuccess
}: IUseUpdatePartnerOrRepresentativeParams): IUseUpdatePartnerOrRepresentative {
  const navigate = useNavigate();
  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty }
  } = useFormWithSchema(useUpdatePartnerOrRepresentativeSchema());

  const { isLoading, mutate } = useMutationCache({
    mutationKey: [
      CompanyOnboardingMutationKeys.UPDATE_COMPANY_ONBOARDING_ANSWERS
    ],
    mutationFn: async (payload: UpdateCompanyOnboardingAnswersPayloadType) => {
      await CompanyOnboardingService.updateCompanyOnboardingAnswers(payload);
    },
    onSuccess: (data, payload) => {
      onUpdateCompanyOnboardingAnswersMutationSuccess(
        payload.answers as ILegalRepresentative
      );
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  const { mutate: uploadDocument, isLoading: isUploadingDocument } =
    useMutationCache({
      mutationKey: [CompanyOnboardingMutationKeys.UPLOAD_AUXILIARY_DOCUMENT],
      mutationFn: async (payload: IUploadAuxiliaryDocumentPayload) =>
        await CompanyOnboardingService.uploadAuxiliaryDocument(payload),
      onSuccess: data => {
        setValue('document', data.contract);

        handleSubmit(onSubmit)();
      }
    });

  async function onSubmit(
    userEnteredData: IUpdatePartnerOrRepresentativeFieldsForm
  ): Promise<void> {
    if (isLoading) {
      return;
    }

    if (userEnteredData.option === 'yes') {
      if (userEnteredData.document instanceof FileList) {
        uploadDocument({
          document: userEnteredData.document[0],
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
        });

        return;
      }

      mutate({
        step: currentStep,
        context: 'legal_representative',
        answers: {
          memberLegalRepresentative: true,
          ...(userEnteredData.document && {
            legalProcuration: userEnteredData.document
          })
        },
        uuidCompanyOnboarding:
          cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      });

      return;
    }

    navigate(`/cartoes/onboarding/empresa/${currentStep}/autorizacao`);
  }

  return {
    errors,
    control,
    isDirty,
    isUpdatingPartnerOrRepresentative: isLoading || isUploadingDocument,
    handleUpdatePartnerOrRepresentative: handleSubmit(onSubmit)
  };
}
