import { BaseIcon } from 'presentation/components/base/Icon';
import { ErrorMessage } from 'presentation/components/base/Input';
import { Tooltip } from 'presentation/components/base/Tooltip';

import { type IInputContainerProps } from './InputContainer.types';

import { Container, LabelContainer } from './InputContainer.styles';

export function InputContainer({
  children,
  label,
  tooltip,
  error
}: IInputContainerProps): JSX.Element {
  return (
    <Container>
      <LabelContainer $error={error}>
        <label>{label}</label>

        {tooltip && (
          <Tooltip
            id={tooltip.id}
            content={tooltip.content}
            maxWidth={30}
          >
            <BaseIcon
              name='question'
              size={1.6}
              color='secondary'
            />
          </Tooltip>
        )}
      </LabelContainer>

      {children}

      {error && <ErrorMessage message={error} />}
    </Container>
  );
}
