import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.tokens.spacingLg};

  padding: ${({ theme }) =>
    `${theme.tokens.spacingLg} ${theme.tokens.spacingXl}`};
`;

export const Toast = styled.span`
  padding: ${({ theme }) => theme.tokens.spacingXs};
  font-size: ${({ theme }) => theme.tokens.fontSizeSm};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusXs};
  color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  background: ${({ theme }) => theme.tokens.feedbackColorWarningLight};
  border: 1px solid ${({ theme }) => theme.tokens.feedbackColorWarningPurest};
`;
