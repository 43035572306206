import {
  type IGetTeamMembersPayload,
  type IPersistenceGetTeamMembersPayload,
  type IPersistenceTeamMember,
  type ITeamMember
} from 'data/modules/teamMembers';

class GetTeamMembersMapper {
  toPersistence(
    domain: IGetTeamMembersPayload
  ): IPersistenceGetTeamMembersPayload {
    return {
      page: domain.page,
      order: domain.order,
      search: domain.search,
      per_page: domain.perPage,
      order_by: domain.orderBy
    };
  }

  toDomain(persistence: IPersistenceTeamMember): ITeamMember {
    return {
      id: persistence.id,
      cpf: persistence.cpf,
      name: persistence.name,
      uuid: persistence.uuid,
      active: persistence.active,
      position: persistence.position,
      typeUser: persistence.type_user,
      safeEmail: persistence.safe_email,
      isApprover: persistence.is_approver
    };
  }
}

export default new GetTeamMembersMapper();
