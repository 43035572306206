import { useMemo } from 'react';

import { Maps } from 'ds';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { type ICreateRouteByMapDefaultFieldsForm } from 'data/modules/expenses';

import { RecordRouteStep } from 'presentation/pages/expenses/ExpensesList/components/Modal/CreateRouteByMapModal';

import { Currency } from 'shared/utils/format';

import {
  type IUseRecordRouteDefaultFieldsSteps,
  type IUseRecordRouteDefaultFieldsStepsParams
} from './RecordRouteDefaultFieldsSteps.types';

export function useRecordRouteDefaultFieldsSteps({
  isMapLoaded,
  mapDirectionsResults,
  distance,
  mapRouteItems,
  setMapRouteItems
}: IUseRecordRouteDefaultFieldsStepsParams): IUseRecordRouteDefaultFieldsSteps {
  const memoizedMap = useMemo(() => {
    return (
      <Maps.Default
        className='map-container'
        isLoaded={isMapLoaded}
        positions={mapDirectionsResults}
        defaultCenter={{
          lat: -23.533773,
          lng: -46.62529
        }}
      />
    );
  }, [isMapLoaded, mapDirectionsResults]);

  const memoizedRecordRouteStep = useMemo(() => {
    return (
      <RecordRouteStep
        isMapLoaded={isMapLoaded}
        distance={distance}
        items={mapRouteItems}
        setItems={setMapRouteItems}
      />
    );
  }, [distance, isMapLoaded, mapRouteItems, setMapRouteItems]);

  const { control, getValues } =
    useFormContext<ICreateRouteByMapDefaultFieldsForm>();

  const {
    fields: apportionmentFields,
    replace: apportionmentReplace,
    append: apportionmentAppend
  } = useFieldArray({
    control,
    name: 'apportionment'
  });

  function handleClickSelectProjectButton(): void {
    const expenseValueAsNumber = Currency.parseToFloat(
      getValues('value') ?? ''
    );

    apportionmentAppend({
      project: undefined,
      percentage: 100,
      value: expenseValueAsNumber
    });
  }

  return {
    memoizedMap,
    memoizedRecordRouteStep,
    apportionmentFields,
    apportionmentReplace,
    handleClickSelectProjectButton
  };
}
