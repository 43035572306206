import { useLangContext } from 'data/contexts';

import { SkeletonLoader } from 'presentation/components/global/Loader';

import { type IExpenseStatusProps } from './ExpenseStatus.types';

import { Container } from './ExpenseStatus.styles';

export function ExpenseStatus({
  isLoading = false,
  status
}: IExpenseStatusProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  if (isLoading) {
    return (
      <SkeletonLoader
        height='1.2rem'
        maxWidth='9.5rem'
      />
    );
  }

  if (!status) return <>-</>;

  const translatedStatus =
    lang.expenses.status[
      status.toLowerCase() as keyof typeof lang.expenses.status
    ][currentLangKey];

  return (
    <Container $status={status}>
      <span />
      {translatedStatus}
    </Container>
  );
}
