import { useLangContext, useModalContext } from 'data/contexts';
import { useSetUpAccountability } from 'data/modules/cards/businessCard';

import { Checkbox } from 'presentation/components/base/Input';
import { Modal } from 'presentation/components/base/Modal';
import { Select } from 'presentation/components/base/Select';

import { useSetUpAccountabilityModal } from './useSetUpAccountabilityModal';

import { type IUseSetUpAccountabilityModalProps } from './SetUpAccountabilityModal.types';

import { Container, InputsContainer } from './SetUpAccountabilityModal.styles';

export function SetUpAccountabilityModal({
  nameCard,
  uuid
}: IUseSetUpAccountabilityModalProps): JSX.Element {
  const { visibleModal, handleCloseModal } = useModalContext();

  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const {
    control,
    errors,
    reset,
    handleSubmit,
    clearErrors,
    isLoading,
    disabledSelectCostsCenters,
    disabledSelectProjects,
    onChangeCheckbox
  } = useSetUpAccountability({ uuid });

  const {
    approvalFlowsOptions,
    costsCentersOptions,
    expensePoliciesOptions,
    projectsOptions,
    isLoadingApprovalFlows,
    isLoadingCostsCenters,
    isLoadingExpensePolicies,
    isLoadingProjects,
    costsCentersIds,
    projectsIds
  } = useSetUpAccountabilityModal();

  return (
    <Modal
      isOpen={visibleModal === 'setUpAccountability'}
      isLoading={isLoading}
      onClose={() => {
        handleCloseModal();
        clearErrors();
        reset();
      }}
      title={lang.settings.businessCards.set_up_accountability[currentLangKey]}
      description={`${lang.global.surname[currentLangKey]} : ${nameCard}`}
      primaryButton={{
        children: lang.global.confirm[currentLangKey],
        color: 'success',
        onClick: () => {
          clearErrors();
        }
      }}
      onSubmit={handleSubmit}
    >
      <Container>
        <InputsContainer>
          <Select
            isMulti
            name='costsCenters'
            options={costsCentersOptions}
            label={
              lang.settings.businessCards.select_cost_centers_for_this_card[
                currentLangKey
              ]
            }
            placeholder={
              lang.settings.businessCards.search_cost_center[currentLangKey]
            }
            isLoading={isLoadingCostsCenters}
            control={control}
            error={errors.costsCenters?.message}
            disabled={disabledSelectCostsCenters}
          />

          <Checkbox
            id='allCostsCenters'
            label={
              lang.settings.businessCards.add_all_cost_centers[currentLangKey]
            }
            onToggle={() => {
              onChangeCheckbox(costsCentersIds, 'costsCenters');
            }}
          />
        </InputsContainer>

        <InputsContainer>
          <Select
            isMulti
            name='projects'
            options={projectsOptions}
            label={
              lang.settings.businessCards.select_projects_for_this_card[
                currentLangKey
              ]
            }
            placeholder={
              lang.settings.businessCards.search_projects[currentLangKey]
            }
            isLoading={isLoadingProjects}
            control={control}
            error={errors.projects?.message}
            disabled={disabledSelectProjects}
          />

          <Checkbox
            id='all_projects'
            label={lang.settings.businessCards.add_all_projects[currentLangKey]}
            onToggle={() => {
              onChangeCheckbox(projectsIds, 'projects');
            }}
          />
        </InputsContainer>

        <Select
          name='approvalFlow'
          options={approvalFlowsOptions}
          label={
            lang.settings.businessCards
              .expenses_should_follow_which_approval_flow[currentLangKey]
          }
          placeholder={
            lang.settings.businessCards.select_approval_flow[currentLangKey]
          }
          isLoading={isLoadingApprovalFlows}
          control={control}
          error={errors.approvalFlow?.message}
        />

        <Select
          name='expensePolicies'
          options={expensePoliciesOptions}
          label={
            lang.settings.businessCards.expenses_should_follow_which_policy[
              currentLangKey
            ]
          }
          placeholder={
            lang.settings.businessCards.select_expense_policy[currentLangKey]
          }
          isLoading={isLoadingExpensePolicies}
          control={control}
          error={errors.expensePolicies?.message}
        />
      </Container>
    </Modal>
  );
}
