enum BalanceMutationKeys {
  'SEND_BALANCE_SOLICITATION_CACHE' = 'SEND_BALANCE_SOLICITATION_CACHE',
  'REMOVE_ALL_BALANCE_SOLICITATION_CACHE' = 'REMOVE_ALL_BALANCE_SOLICITATION_CACHE',
  'SEND_BALANCE_SOLICITATION_APPROVED' = 'SEND_BALANCE_SOLICITATION_APPROVED',
  'SEND_MODIFIED_BALANCE_ALLOCATION_ITEMS_TO_CACHE' = 'SEND_MODIFIED_BALANCE_ALLOCATION_ITEMS_TO_CACHE',
  'DELETE_MODIFIED_BALANCE_ALLOCATION_ITEMS_FROM_CACHE' = 'DELETE_MODIFIED_BALANCE_ALLOCATION_ITEMS_FROM_CACHE',
  'TRANSFER_BALANCE_ALLOCATION_ITEMS' = 'TRANSFER_BALANCE_ALLOCATION_ITEMS',
  'REQUEST_BALANCE' = 'REQUEST_BALANCE',
  'REQUEST_CARDS' = 'REQUEST_CARDS',
  'REQUEST_PIX_QR_CODE' = 'REQUEST_PIX_QR_CODE',
  'REQUEST_WITHDRAWAL' = 'REQUEST_WITHDRAWAL'
}

enum BalanceQueryKeys {
  'GET_CACHED_BALANCE_SOLICITATIONS' = 'GET_CACHED_BALANCE_SOLICITATIONS',
  'GET_BALANCE_SOLICITATIONS' = 'GET_BALANCE_SOLICITATIONS',
  'GET_ALLOCATED_BALANCE' = 'GET_ALLOCATED_BALANCE',
  'GET_UNALLOCATED_BALANCE' = 'GET_UNALLOCATED_BALANCE',
  'GET_CACHED_MODIFIED_BALANCE_ALLOCATION_ITEMS' = 'GET_CACHED_MODIFIED_BALANCE_ALLOCATION_ITEMS',
  'GET_PIX_QR_CODE_STRING' = 'GET_PIX_QR_CODE_STRING',
  'GET_QUANTITY_OF_BALANCE_SOLICITATION_BY_STATUS' = 'GET_QUANTITY_OF_BALANCE_SOLICITATION_BY_STATUS',
  'GET_BALANCE_SHOW' = 'GET_BALANCE_SHOW',
  'GET_VALUE_SOLICITATIONS_QUANTITY' = 'GET_VALUE_SOLICITATIONS_QUANTITY',
  'GET_MY_VALUE_SOLICITATIONS' = 'GET_MY_VALUE_SOLICITATIONS'
}

export { BalanceMutationKeys, BalanceQueryKeys };
