import { CardInfo, DefaultButton, SecondaryButton } from 'ds';
import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';

import { type IDepositAndWithdrawCardInfoProps } from './DepositAndWithdrawCardInfo.types';

import {
  ButtonsContainer,
  Container,
  ContentContainer
} from './DepositAndWithdrawCardInfo.styles';

export function DepositAndWithdrawCardInfo({
  ...rest
}: IDepositAndWithdrawCardInfoProps): JSX.Element {
  const { t } = useTranslation('cards');

  const { handleOpenModal } = useModalContext();

  return (
    <Container {...rest}>
      <ContentContainer>
        <CardInfo.Title variant='small'>
          {t('depositAndWithdraw')}
        </CardInfo.Title>

        <CardInfo.Caption variant='small'>
          {t('depositAndWithdrawText')}
        </CardInfo.Caption>
      </ContentContainer>

      <ButtonsContainer>
        <DefaultButton
          size='small'
          onClick={() => {
            handleOpenModal('deposit');
          }}
        >
          {t('deposit')}
        </DefaultButton>

        <SecondaryButton
          size='small'
          onClick={() => {
            handleOpenModal('withdrawal');
          }}
        >
          {t('withdraw')}
        </SecondaryButton>
      </ButtonsContainer>
    </Container>
  );
}
