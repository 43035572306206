import { DefaultTable, SkeletonLoader } from 'ds';

import { type ITableLoaderProps } from './TableLoader.types';

import {
  CardsLayoutLoaderContainer,
  CreditCardSkeleton,
  GroupCardSkeleton
} from './TableLoader.styles';

export function TableLoader({
  rows,
  columns,
  layout = 'LIST'
}: ITableLoaderProps): JSX.Element {
  const rowsArray = Array.from(Array(rows).keys());
  const columnsArray = Array.from(Array(columns).keys());
  const cardsArray = Array.from(Array(10).keys());

  return (
    <>
      {layout === 'LIST' && (
        <DefaultTable.Container>
          <DefaultTable.Thead>
            <DefaultTable.Tr>
              {columnsArray.map(column => (
                <DefaultTable.Th key={column}>
                  <SkeletonLoader />
                </DefaultTable.Th>
              ))}
            </DefaultTable.Tr>
          </DefaultTable.Thead>

          <DefaultTable.Tbody>
            {rowsArray.map(row => (
              <DefaultTable.Tr key={row}>
                {columnsArray.map(column => (
                  <DefaultTable.Td key={column}>
                    <SkeletonLoader />
                  </DefaultTable.Td>
                ))}
              </DefaultTable.Tr>
            ))}
          </DefaultTable.Tbody>
        </DefaultTable.Container>
      )}

      {layout === 'CARDS' && (
        <CardsLayoutLoaderContainer>
          {cardsArray.map(item => (
            <CreditCardSkeleton
              key={item}
              isLoading
              cardInfo={{
                name: '',
                number: '',
                availableBalance: '',
                status: 'ACTIVE'
              }}
            />
          ))}
        </CardsLayoutLoaderContainer>
      )}

      {layout === 'GROUP_CARDS' && (
        <CardsLayoutLoaderContainer>
          {cardsArray.map(item => (
            <GroupCardSkeleton
              key={item}
              isLoading
              groupInfo={{
                name: '',
                availableValue: 0
              }}
            />
          ))}
        </CardsLayoutLoaderContainer>
      )}
    </>
  );
}
