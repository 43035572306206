import { useEffect } from 'react';

import { DSIcons } from 'ds';
import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';

import { AlertModal } from 'presentation/components/global/AlertModal';
import { Loader } from 'presentation/components/global/Loader';

import { useExportMovementsModal } from './useExportMovementsModal';

import { type IExportMovementsModalProps } from './ExportMovementsModal.types';

import {
  ActionContainer,
  ButtonsContainer,
  ExportButton,
  ModalContent
} from './ExportMovementsModal.styles';

export function ExportMovementsModal({
  handleBlurMultiselect
}: IExportMovementsModalProps): JSX.Element {
  const {
    isExportingMovementsInExcel,
    isExportingMovementsInPdf,
    exportationFileType,
    handleChangeExportationFileType,
    handleExportMovements
  } = useExportMovementsModal();

  const { handleCloseModal } = useModalContext();

  const { t } = useTranslation(['global', 'cards']);

  useEffect(() => {
    handleBlurMultiselect(false);

    return () => {
      handleBlurMultiselect(true);
    };
  }, [handleBlurMultiselect]);

  return (
    <AlertModal
      icon={<DSIcons.WarningConfirmationIcon />}
      cancelButtonProps={{
        label: t('global:cancel'),
        onCancel: () => {
          handleChangeExportationFileType(null);
          handleCloseModal();
        }
      }}
      confirmButtonProps={{
        label: t('global:export'),
        onConfirm: () => {
          handleExportMovements(exportationFileType!);
        },
        disabled: exportationFileType === null
      }}
      onClose={() => {
        handleChangeExportationFileType(null);
        handleCloseModal();
      }}
    >
      <Loader
        isLoading={isExportingMovementsInExcel || isExportingMovementsInPdf}
      />

      <ModalContent>
        <p>{t('cards:exportAllUsersFilter')}</p>

        <ActionContainer>
          <span>{t('cards:exportInFormat')}</span>

          <ButtonsContainer>
            <ExportButton
              $isActive={exportationFileType === 'PDF'}
              onClick={() => {
                handleChangeExportationFileType('PDF');
              }}
            >
              <DSIcons.FilePdfIcon className='icon' />

              <span>PDF</span>
            </ExportButton>

            <ExportButton
              $isActive={exportationFileType === 'EXCEL'}
              onClick={() => {
                handleChangeExportationFileType('EXCEL');
              }}
            >
              <DSIcons.FileExcelIcon className='icon' />

              <span>Excel</span>
            </ExportButton>
          </ButtonsContainer>
        </ActionContainer>
      </ModalContent>
    </AlertModal>
  );
}
