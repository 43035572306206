import { useQueryCache } from 'data/cache';
import { BalanceQueryKeys } from 'data/modules/cards/balance/keys/balance.keys';
import BalanceService from 'data/modules/cards/balance/services/Balance.service';

import {
  type IUseGetBalancesAndAccount,
  type IUseGetBalancesAndAccountPayload
} from './useGetBalancesAndAccount.types';

export function useGetBalancesAndAccount({
  ...payload
}: IUseGetBalancesAndAccountPayload): IUseGetBalancesAndAccount {
  const { data: balancesAndAccount, isFetching: isFetchingBalancesAndAccount } =
    useQueryCache({
      queryKey: [BalanceQueryKeys.GET_BALANCE_SHOW, payload],
      queryFn: async () => await BalanceService.getBalancesAndAccount(payload),
      enabled: payload.enabled
    });

  return { balancesAndAccount, isFetchingBalancesAndAccount };
}
