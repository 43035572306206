import { api, apiWeb } from 'data/config';
import {
  type IAuthUser,
  type ICardsCompany,
  type ICardsUser,
  type IGetOutsourcedUserPayload,
  type IOutsourcedUserPermission,
  type IPersistenceAuthUser,
  type IPersistenceCardsCompany,
  type IPersistenceCardsUser,
  type IPersistenceOutsourcedUserPermission
} from 'data/modules/auth';
import { type IDefaultResponse } from 'data/modules/global';

import { AuthUserMapper } from 'shared/mappers';
import { CustomCookies } from 'shared/utils/global';

import {
  GetAuthCardsCompanyMapper,
  GetAuthCardsUserMapper,
  GetOutsourcedUsersAndPermissionsMapper
} from './mappers';

class AuthService {
  async getAuthUser(): Promise<IAuthUser> {
    const {
      status,
      data: { data }
    } = await api.get<IDefaultResponse<IPersistenceAuthUser>>('/oauth/user');

    if (status !== 200) {
      CustomCookies.remove('outsourcing');
    }

    if (data.user.id && data.user.company_id) {
      CustomCookies.set('GACID', `GACID${data.user.company_id}`, {
        sharedDomain: false
      });
      CustomCookies.set('GAUID', `GAUID${data.user.id}`, {
        sharedDomain: false
      });
    }

    return AuthUserMapper.toDomain(data);
  }

  async getAuthCardsCompany(): Promise<ICardsCompany> {
    const {
      data: { data }
    } = await api.get<IDefaultResponse<IPersistenceCardsCompany>>(
      '/pay/company/authenticated'
    );

    return GetAuthCardsCompanyMapper.toDomain(data);
  }

  async getAuthCardsUser(): Promise<ICardsUser> {
    const {
      data: { data }
    } = await api.get<IDefaultResponse<IPersistenceCardsUser>>(
      '/pay/users/authenticated'
    );

    return GetAuthCardsUserMapper.toDomain(data);
  }

  async getOutsourcedUser({
    userId
  }: IGetOutsourcedUserPayload): Promise<IAuthUser> {
    const {
      data: { data }
    } = await api.get<IDefaultResponse<IPersistenceAuthUser>>(
      '/outsourcer-user/' + userId
    );

    return AuthUserMapper.toDomain(data);
  }

  async getOutsourcedUsersAndPermissions(): Promise<
    IOutsourcedUserPermission[]
  > {
    const {
      data: { data }
    } = await apiWeb.get<
      IDefaultResponse<IPersistenceOutsourcedUserPermission[] | null>
    >('/outsourcing-permissions/list-by-user');

    return data?.map(GetOutsourcedUsersAndPermissionsMapper.toDomain) ?? [];
  }
}

export default new AuthService();
