import { useRef, useState } from 'react';

import { type IUsePercentageList } from './PercentageList.types';

export function usePercentageList(): IUsePercentageList {
  const [show, setShow] = useState<boolean>(false);

  const scrollRef = useRef<HTMLDivElement>(null);

  return {
    isVisible: show,
    scrollRef,
    toggleShow: () => {
      scrollRef.current?.scrollTo({ top: 0 });
      setShow(!show);
    }
  };
}
