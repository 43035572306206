import { type IDsProgressSteps } from './DsProgressSteps.types';

import { Container, StepBall } from './DsProgressSteps.styles';

export function DsProgressSteps({
  currentStep,
  totalSteps
}: IDsProgressSteps): JSX.Element {
  return (
    <Container>
      {Array.from({ length: totalSteps }, (v, index) =>
        index === currentStep - 1 ? (
          <StepBall
            key={index}
            $isActive
          />
        ) : (
          <StepBall key={index} />
        )
      )}
    </Container>
  );
}
