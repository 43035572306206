import {
  type IOpenReport,
  type IPersistenceOpenReport
} from 'data/modules/reports';

class GetOpenReportsMapper {
  toDomain(report: IPersistenceOpenReport): IOpenReport {
    return {
      amountOfExpenses: report.number_of_expenses,
      totalAmountOfExpenses: report.total_amount_of_expenses,
      costsCenter: report.cost_center
        ? {
            name: report.cost_center.nome,
            id: report.cost_center.id,
            uuid: report.cost_center.uuid
          }
        : null,
      currency: {
        id: report.currency.id,
        isoCode: report.currency.iso_code
      },
      createdAt: report.created_at,
      paymentMethod: report.payment_method
        ? {
            description: report.payment_method.description,
            id: report.payment_method.id
          }
        : null,
      project: report.project
        ? {
            name: report.project.nome,
            id: report.project.id,
            uuid: report.project.uuid
          }
        : null,
      title: report.title,
      uuid: report.uuid
    };
  }
}

export default new GetOpenReportsMapper();
