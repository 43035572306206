import { useLangContext } from 'data/contexts';

import { Lang } from 'shared/utils/global';

import { type IClassicModeAlertProps } from './ClassicModeAlert.types';

import { Container, MessageContainer } from './ClassicModeAlert.styles';

export function ClassicModeAlert({
  pageName,
  trialEndDate,
  isClassicMode,
  onActionClick
}: IClassicModeAlertProps): JSX.Element {
  const {
    currentLangKey,
    lang: {
      cards: { global }
    }
  } = useLangContext();

  return (
    <Container
      type='info'
      title={
        global.classic_mode_alert[
          isClassicMode ? 'classic_mode' : 'redesign_mode'
        ].title[currentLangKey]
      }
      actionText={
        global.classic_mode_alert[
          isClassicMode ? 'classic_mode' : 'redesign_mode'
        ].button[currentLangKey]
      }
      onClickAction={onActionClick}
    >
      <MessageContainer>
        {Lang.putValuesInString(
          global.classic_mode_alert[
            isClassicMode ? 'classic_mode' : 'redesign_mode'
          ].message[currentLangKey],
          {
            trialEndDate: trialEndDate ?? '',
            pageName
          }
        )}
      </MessageContainer>
    </Container>
  );
}
