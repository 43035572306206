import { useModalContext } from 'data/contexts';
import { useGetExpenseParameters } from 'data/modules/expenses';

import { ApportionmentBlockItem } from 'presentation/pages/expenses/ExpensesList/components/ApportionmentBlock';

import { useApportionmentBlockForm } from './useApportionmentBlockForm';

import { type IApportionmentBlockFormProps } from './ApportionmentBlockForm.types';

import { Container } from './ApportionmentBlockForm.styles';

export function ApportionmentBlockForm({
  onHideApportionmentBlock,
  expenseUuidToUpdate
}: IApportionmentBlockFormProps): JSX.Element {
  const { visibleModal } = useModalContext();

  const { expenseParameters } = useGetExpenseParameters();

  const { fields, handleAddNewProject, handleRemoveProject } =
    useApportionmentBlockForm({
      onHideApportionmentBlock,
      expenseUuidToUpdate
    });

  return (
    <Container>
      {fields.map((item, index) => (
        <ApportionmentBlockItem
          key={item.id}
          fieldArrayIndex={index}
          onClickActionMinusButton={() => {
            handleRemoveProject(index);
          }}
          actionPlusButtonDisabled={
            // se estiver aberta a modal de criar despesa do tipo rota manual ou rota por mapa
            // e se a politica de KM for por projeto
            (visibleModal === 'createManualRouteExpense' ||
              visibleModal === 'createRouteByMap') &&
            expenseParameters?.mileagePolicyType === 'PROJETO'
              ? fields.length === 1
              : fields.length === 50
          }
          onClickActionPlusButton={handleAddNewProject}
          expenseUuidToUpdate={expenseUuidToUpdate}
        />
      ))}
    </Container>
  );
}
