import { useLangContext } from 'data/contexts';

import { DefaultLayout } from 'presentation/templates';

import bannerBg from 'shared/assets/images/support/default-banner.png';
import illustrationEmail from 'shared/assets/images/support/illustration_email.png';
import { Icons } from 'shared/constants/global';

import { Banner } from './components/Banner';
import { ButtonCard } from './components/ButtonCard';
import { ImageCard } from './components/ImageCard';
import { ServiceChannel } from './components/ServiceChannel';
import { useSupport } from './useSupport';

import {
  ButtonCardsContent,
  ButtonCardWrapper,
  ChannelIllustration,
  ChannelsWrapper,
  Container,
  Content,
  ImageCardWrapper,
  SectionTitle
} from './Support.styles';

export function Support(): JSX.Element {
  const {
    currentLangKey,
    lang: {
      support,
      sidebar: { routes }
    }
  } = useLangContext();

  const {
    handleCopyServiceChannelToClipboard,
    handleOnHelpCenterButtonCardClick,
    handleOnUserGuidesButtonCardClick,
    handleOnChatServiceChannelClick,
    handleOnImageCardClick
  } = useSupport();

  return (
    <DefaultLayout
      pageNameAndBreadcrumbs={{
        pageName: routes.support[currentLangKey],
        isBackButtonVisible: false,
        isBreadcrumbsVisible: false,
        isClassicMode: false
      }}
    >
      <Container>
        <Banner background={bannerBg}>
          {support.global.banner_text[currentLangKey]}
        </Banner>

        <ButtonCardsContent>
          <ButtonCardWrapper>
            <SectionTitle>
              {support.global.help_center[currentLangKey]}
            </SectionTitle>
            <ButtonCard
              title={support.global.button_cards.first.title[currentLangKey]}
              description={
                support.global.button_cards.first.description[currentLangKey]
              }
              buttonLabel={
                support.global.button_cards.first.buttonLabel[currentLangKey]
              }
              icon={<Icons.IllustrationHelpCenterIcon />}
              onButtonClick={handleOnHelpCenterButtonCardClick}
            />
          </ButtonCardWrapper>

          <ButtonCardWrapper>
            <SectionTitle>
              {support.global.user_guides[currentLangKey]}
            </SectionTitle>
            <ButtonCard
              title={support.global.button_cards.second.title[currentLangKey]}
              description={
                support.global.button_cards.second.description[currentLangKey]
              }
              buttonLabel={
                support.global.button_cards.second.buttonLabel[currentLangKey]
              }
              icon={<Icons.IllustrationUserGuidesIcon />}
              onButtonClick={handleOnUserGuidesButtonCardClick}
            />
          </ButtonCardWrapper>
        </ButtonCardsContent>

        <Content>
          <SectionTitle>
            {support.global.vexpenses_university[currentLangKey]}
          </SectionTitle>
          <ImageCardWrapper>
            <ImageCard
              onClick={handleOnImageCardClick}
              icon={<Icons.VexpensesUniversityIcon />}
              title={support.global.image_cards.first.title[currentLangKey]}
              items={support.global.image_cards.first.items.map(
                item => item[currentLangKey]
              )}
            />
            <ImageCard
              onClick={handleOnImageCardClick}
              icon={<Icons.IllustrationCardsIcon />}
              title={support.global.image_cards.second.title[currentLangKey]}
              items={support.global.image_cards.second.items.map(
                item => item[currentLangKey]
              )}
            />
          </ImageCardWrapper>
        </Content>

        <ChannelsWrapper>
          <SectionTitle>
            {support.global.service_channels[currentLangKey]}
          </SectionTitle>
          <ServiceChannel
            icon={<Icons.IllustrationChatIcon />}
            onClick={handleOnChatServiceChannelClick}
            name={support.global.channels.chat.name[currentLangKey]}
            channel={support.global.channels.chat.channel[currentLangKey]}
            information={support.global.channels.information[currentLangKey]}
          />
          <ServiceChannel
            withCopyButton
            channel='suporte@vexpenses.com'
            contentToCopy='suporte@vexpenses.com'
            icon={
              <ChannelIllustration
                src={illustrationEmail}
                alt='illustration-email'
              />
            }
            onCopyButtonClick={handleCopyServiceChannelToClipboard}
            name={support.global.channels.email.name[currentLangKey]}
            information={support.global.channels.information[currentLangKey]}
          />
          <ServiceChannel
            withCopyButton
            contentToCopy='1130037254'
            channel='(11) 3003-7254 (ramal 2)'
            icon={<Icons.IllustrationPhoneIcon />}
            onCopyButtonClick={handleCopyServiceChannelToClipboard}
            name={support.global.channels.phone.name[currentLangKey]}
            information={support.global.channels.information[currentLangKey]}
          />
        </ChannelsWrapper>
      </Container>
    </DefaultLayout>
  );
}
