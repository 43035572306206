import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import {
  useAuthContext,
  useCardsContext,
  useModalContext
} from 'data/contexts';
import {
  AccountQueryKeys,
  type ICardData,
  useBlockCard,
  useUnblockCard
} from 'data/modules/cards/account';
import { CardGroupsQueryKeys } from 'data/modules/cards/cardGroups';

import { useGetUserLoggedAndCardData } from 'shared/hooks/cards';

import { type IUseBlockAndUnblockCardModal } from './BlockAndUnblockCardModal.types';

export function useBlockAndUnblockCardModal(): IUseBlockAndUnblockCardModal {
  const { handleCloseModal } = useModalContext();
  const user = useAuthContext(state => state.user);
  const queryClient = useQueryClient();
  const { t } = useTranslation('global');
  const { userAccounts, outsourcedUserAccounts, cardData } =
    useGetUserLoggedAndCardData({});

  const isSharedAccount = !!user?.cards?.sharedAccounts;

  const accountId = isSharedAccount
    ? outsourcedUserAccounts?.[0]?.account?.id
    : userAccounts?.[0]?.account?.id ?? '';

  const [{ searchTerm, cardType, order }, page, limit] = useCardsContext(
    useShallow(state => [
      state.filters,
      state.pagination.page,
      state.pagination.limit
    ])
  );

  function successOnBlockOrUnblockCard(
    action: 'block' | 'unblock',
    cardData: ICardData,
    accountId: string
  ): void {
    queryClient.setQueryData(
      [AccountQueryKeys.GET_CARD_DATA_BY_ACCOUNT_ID, { accountId }],
      cardData
    );

    if (user?.cards?.type === 'ACCOUNT_MANAGER') {
      queryClient.invalidateQueries({
        queryKey: [AccountQueryKeys.GET_USER_ACCOUNTS, null]
      });
    }

    queryClient.invalidateQueries({
      queryKey: [
        AccountQueryKeys.GET_ACCOUNTS,
        {
          limit,
          page,
          ordenationType: order.cardName,
          ...(cardType?.length === 1 && { accountType: cardType[0] }),
          ...(searchTerm && { name: searchTerm })
        }
      ]
    });

    queryClient.resetQueries({
      queryKey: [CardGroupsQueryKeys.GET_GROUP_USERS]
    });

    toast.success(
      action === 'block' ? t('cardBlockSuccess') : t('cardUnblockSuccess')
    );

    handleCloseModal();
  }

  const { blockCard, isBlockingCard } = useBlockCard();

  const { unblockCard, isUnblockingCard } = useUnblockCard();

  async function handleBlockUnblockCard(
    action: 'block' | 'unblock'
  ): Promise<void> {
    try {
      if (cardData) {
        if (action === 'block') {
          await blockCard({
            accountId: accountId || '',
            blockLevel: 'USER'
          });

          successOnBlockOrUnblockCard(
            'block',
            {
              id: cardData?.id,
              panMasked: cardData?.panMasked,
              lockLevel: cardData?.lockLevel,
              status: 'BLOCKED'
            },
            accountId as string
          );

          return;
        }

        await unblockCard({
          accountId: accountId || ''
        });

        successOnBlockOrUnblockCard(
          'unblock',
          {
            id: cardData?.id,
            panMasked: cardData?.panMasked,
            lockLevel: cardData?.lockLevel,
            status: 'ACTIVE'
          },
          accountId as string
        );
      }
    } catch (error) {
      toast.error((error as Error)?.message);

      handleCloseModal();
    }
  }

  return {
    handleBlockUnblockCard,
    isBlockingOrUnblockingCard: isBlockingCard || isUnblockingCard,
    cardData
  };
}
