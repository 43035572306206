import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';
import { type IStoreProduct, useGetStoreProducts } from 'data/modules/store';

import activeDirectorySlide from 'shared/assets/images/store/activeDirectory/activeDirectory-slide.png';
import cardsSlide from 'shared/assets/images/store/cards/cards-slide.png';
import cardsPjSlide from 'shared/assets/images/store/cardsPj/cardsPj-slide.png';
import conciliationSlide from 'shared/assets/images/store/conciliation/conciliation-slide.png';
import horusSlide from 'shared/assets/images/store/horus/horus-slide.png';
import outsourcingSlide from 'shared/assets/images/store/outsourcing/outsourcing-slide.png';
import { useGa4 } from 'shared/hooks/global';

import {
  type IStoreCarrouselSlideContent,
  type IUseStore
} from './Store.types';

export function useStore(): IUseStore {
  const navigate = useNavigate();
  const { sendGaEvent } = useGa4();

  const {
    currentLangKey,
    lang: {
      store: { slides }
    }
  } = useLangContext();

  const [identifier, setIdentifier] = useState<IStoreProduct['identifier']>();

  const { products, isLoadingStoreProducts } = useGetStoreProducts();

  function handleRequestProductClick(
    identifier: IStoreProduct['identifier']
  ): void {
    setIdentifier(identifier);
  }

  function handleCloseRequestModal(): void {
    setIdentifier(undefined);
  }

  function handleSlideActionClick(
    identifier: IStoreProduct['identifier']
  ): void {
    sendGaEvent('store', 'store_clickBanner');
    navigate(`/store/${identifier}`);
  }

  function getSlideImage(identifier: IStoreProduct['identifier']): string {
    switch (identifier) {
      case 'active-directory':
        return activeDirectorySlide;
      case 'cards':
        return cardsSlide;
      case 'cards-pj':
        return cardsPjSlide;
      case 'conciliation':
        return conciliationSlide;
      case 'horus':
        return horusSlide;
      case 'outsourcing':
        return outsourcingSlide;
      default:
        return '';
    }
  }

  function getCarrouselSlides(): IStoreCarrouselSlideContent[] {
    return Object.entries(slides).map(
      ([key, slide]) =>
        ({
          key,
          title: slide.title[currentLangKey],
          description: slide.description[currentLangKey],
          image: getSlideImage(key as IStoreProduct['identifier'])
        }) as IStoreCarrouselSlideContent
    );
  }

  /** A função agrupa produtos por categoria com base em seus identificadores.
    @returns um objeto que agrupa produtos por categoria. As chaves do objeto
    representam as categorias ('first', 'second', 'third') e os valores são
    arrays de produtos que pertencem a cada categoria.
    */
  function groupProductsByCategory(): IUseStore['groupedProducts'] {
    const groups: Record<string, IStoreProduct['identifier'][]> = {
      first: ['horus', 'outsourcing'],
      second: ['cards', 'cards-pj'],
      third: ['conciliation', 'active-directory']
    };

    return Object.entries(groups).reduce(
      (grouped, [key, identifiers]) => ({
        ...grouped,
        [key]: identifiers.map(id =>
          products.find(product => product.identifier === id)
        )
      }),
      {}
    );
  }

  return {
    identifier,
    isLoadingStoreProducts,
    handleCloseRequestModal,
    handleSlideActionClick,
    handleRequestProductClick,
    slides: getCarrouselSlides(),
    groupedProducts: groupProductsByCategory()
  };
}
