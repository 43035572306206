import styled from 'styled-components';

export const Container = styled.tr`
  padding-left: 3.6rem;
  background-color: ${({ theme }) => theme.colors.alabaster};
  border: 1px solid ${({ theme }) => theme.colors.alto};

  th {
    align-items: start;
    text-align: start;
    font-size: 1.5rem;
    text-transform: uppercase;
    width: fit-content;
    color: ${({ theme }) => theme.colors.dustyGray};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    border: none;

    th {
      display: none;
    }
  }
`;
