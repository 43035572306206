import {
  type ChangeEvent,
  forwardRef,
  type ReactElement,
  type Ref
} from 'react';

import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue
} from 'react-hook-form';

import { BaseIcon } from 'presentation/components/base/Icon';

import { Mask } from 'shared/utils/format';

import { type ITextAreaProps } from './TextArea.types';

import { Container, StyledTextArea } from './TextArea.styles';

const TextAreaComponent = <T extends FieldValues>(
  {
    control,
    error,
    name,
    mask,
    defaultValue = '',
    iconProps,
    valuePrefix,
    ...rest
  }: ITextAreaProps<T>,
  ref: Ref<HTMLTextAreaElement>
): JSX.Element => {
  if (!control) {
    return (
      <Container
        $disabled={rest.disabled}
        $error={error}
      >
        {iconProps && (
          <BaseIcon
            color='dustyGray'
            size={1.6}
            {...iconProps}
          />
        )}

        <StyledTextArea
          name={name}
          ref={ref}
          {...rest}
        />
      </Container>
    );
  }

  return (
    <Container
      $disabled={rest.disabled}
      $error={error}
    >
      {iconProps && (
        <BaseIcon
          color='dustyGray'
          size={1.6}
          {...iconProps}
        />
      )}

      <Controller
        name={name as Path<T>}
        control={control}
        defaultValue={defaultValue as PathValue<T, Path<T>>}
        render={({ field: { onChange, value, ref } }) => (
          <StyledTextArea
            name={name}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              onChange(
                Mask.apply(mask, e.target.value) as PathValue<T, Path<T>>
              );
            }}
            ref={ref}
            value={(valuePrefix ?? '') + value}
            {...rest}
          />
        )}
      />
    </Container>
  );
};

export const TextArea = forwardRef(TextAreaComponent) as <
  T extends FieldValues
>(
  p: ITextAreaProps<T> & { ref?: Ref<HTMLInputElement> }
) => ReactElement;
