export class Onboarding {
  getCurrentStep(pathname: string): string {
    const splittedPathname = pathname.split('/');

    const foundContextIndex = splittedPathname.findIndex(
      item => item === 'empresa' || item === 'usuario'
    );

    const onboardingStepPathname = splittedPathname.slice(
      foundContextIndex + 1
    );

    return onboardingStepPathname[0];
  }
}

export default new Onboarding();
