import { useMemo } from 'react';

import { type ISelectOption } from 'presentation/components/base/Select/Select.types';

import { banks } from 'shared/constants/global';

import { type IUseNewWithdrawalModal } from './NewWithdrawalModal.types';

export function useNewWithdrawalModal(): IUseNewWithdrawalModal {
  /**
   * Ordena a lista de bancos disponíveis
   */
  const bankOptions: ISelectOption[] = useMemo(() => {
    return banks.map(el => ({
      value: `${el.number} - ${el.name}`,
      label: `${el.number} - ${el.name}`
    }));
  }, []);

  return { bankOptions };
}
