import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useLangContext, useModalContext } from 'data/contexts';
import {
  BusinessCardsMutationKeys,
  BusinessCardsService,
  type IChangeBusinessCardCostsCentersFieldsForm,
  type IRemoveCostsCentersProps,
  type IRemoveRelatedCostsCentersPayload,
  type IUseChangeBusinessCardCostsCenters,
  type IUseChangeBusinessCardCostsCentersParams
} from 'data/modules/cards/businessCard';
import {
  CostCentersMutationKeys,
  CostCentersQueryKeys,
  CostCentersService,
  type IAttachCostCentersPayload,
  type ICostsCenters
} from 'data/modules/costsCenters';

import { useFormWithSchema } from 'shared/hooks/forms';

import { useChangeBusinessCardCostsCentersSchema } from './schemas';

export function useChangeBusinessCardCostsCenters({
  uuid
}: IUseChangeBusinessCardCostsCentersParams): IUseChangeBusinessCardCostsCenters {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const { handleCloseModal } = useModalContext();

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset
  } = useFormWithSchema(useChangeBusinessCardCostsCentersSchema(), {
    defaultValues: {
      costsCenters: []
    }
  });

  const queryClient = useQueryClient();

  const {
    mutate: removeRelatedCostsCenters,
    isLoading: isRemovingRelatedCostsCenters
  } = useMutationCache({
    mutationKey: [BusinessCardsMutationKeys.REMOVE_RELATED_COST_CENTER],
    mutationFn: async (payload: IRemoveRelatedCostsCentersPayload) =>
      await BusinessCardsService.removeRelatedCostsCenters(payload),
    onSuccess: (data, payload) => {
      if (data) {
        toast.success(
          lang.settings.manageBusinessCard.costs_centers_unlinked_successfully[
            currentLangKey
          ]
        );

        queryClient.setQueryData<ICostsCenters[]>(
          [
            CostCentersQueryKeys.GET_RELATED_COST_CENTERS,
            {
              uuid,
              page: 1,
              perPage: 500
            }
          ],
          oldData => {
            return oldData?.filter(({ id }) => id !== payload.costsCenters[0]);
          }
        );
        handleCloseModal();
      }
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  const { mutate: attachCostsCenters, isLoading: isAttachingCostsCenters } =
    useMutationCache({
      mutationKey: [
        CostCentersMutationKeys.ATTACH_COST_CENTERS_TO_BUSINESS_CARD
      ],
      mutationFn: async (payload: IAttachCostCentersPayload) =>
        await CostCentersService.attachCostCenters(payload),
      onSuccess: (data, payload) => {
        toast.success(
          lang.settings.manageBusinessCard.costsCenters_linked_successfully[
            currentLangKey
          ]
        );
        const costsCenters = queryClient.getQueryData([
          CostCentersQueryKeys.GET_COST_CENTERS,
          {
            page: 1,
            perPage: 500
          }
        ]) as ICostsCenters[];

        queryClient.setQueryData<ICostsCenters[]>(
          [
            CostCentersQueryKeys.GET_RELATED_COST_CENTERS,
            {
              uuid,
              page: 1,
              perPage: 500
            }
          ],
          oldData => {
            const newCostsCenters = payload.costsCenters.map(item => {
              return costsCenters.find(({ id }) => id === item);
            }) as ICostsCenters[];

            if (oldData && oldData.length > 0) {
              return [...oldData, ...newCostsCenters];
            }

            return newCostsCenters;
          }
        );
        handleCloseModal();
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  function handleRemoveCostsCenters({ id }: IRemoveCostsCentersProps): void {
    if (id) {
      removeRelatedCostsCenters({
        costsCenters: [id],
        uuid
      });
    }
  }

  function onSubmit(data: IChangeBusinessCardCostsCentersFieldsForm): void {
    clearErrors();
    if (data.costsCenters.length > 0) {
      attachCostsCenters({
        uuid,
        costsCenters: data.costsCenters
      });
      reset();
    }
  }

  return {
    control,
    errors,
    isAttachingCostsCenters,
    isRemovingRelatedCostsCenters,
    handleSubmit: handleSubmit(onSubmit),
    clearErrors,
    handleRemoveCostsCenters
  };
}
