import { BaseIcon } from 'presentation/components/base/Icon';

import { type IIconButtonProps } from './IconButton.types';

import { Container } from './IconButton.styles';

export function IconButton({
  iconProps,
  ...rest
}: IIconButtonProps): JSX.Element {
  return (
    <Container
      aria-disabled={rest.disabled}
      {...rest}
    >
      <BaseIcon {...iconProps} />
    </Container>
  );
}
