import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useCardGroupsContext } from 'data/contexts';
import { useGetCardGroup } from 'data/modules/cards/account/useCases/get-card-group/useGetCardGroup';

import { DefaultLayout } from 'presentation/templates';

import { MovementsPageHeader, MovementsTable } from '../components';

import { Container } from './AdminMovements.styles';

export function AdminMovements(): JSX.Element {
  const { t } = useTranslation('cards');
  const { groupId } = useParams();

  const contextCardGroup = useCardGroupsContext(
    state => state.selectedCardGroup
  );

  const { cardGroupData } = useGetCardGroup({
    cardGroupId: groupId || '',
    enabled: !contextCardGroup
  });

  const selectedCardGroup = contextCardGroup || cardGroupData;

  document.title = `vexpenses | ${t('movements')}`;

  return (
    <DefaultLayout
      pageNameAndBreadcrumbs={
        groupId
          ? {
              pageName: t('movements'),
              isBackButtonVisible: true,
              isBreadcrumbsVisible: true,
              isClassicMode: false,
              breadcrumbs: [
                {
                  name: t('cardsManagement'),
                  pageLink: `/cartoes/gestao-de-grupos/${selectedCardGroup?.id}`
                },
                {
                  name: selectedCardGroup?.name || '',
                  pageLink: `/cartoes/gestao-de-grupos/${selectedCardGroup?.id}`
                },
                {
                  name: t('movements')
                }
              ]
            }
          : {
              pageName: t('movements'),
              isBreadcrumbsVisible: false,
              isBackButtonVisible: false,
              isClassicMode: false
            }
      }
    >
      <Container>
        <MovementsPageHeader />

        <MovementsTable />
      </Container>
    </DefaultLayout>
  );
}
