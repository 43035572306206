import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IAddMembersOrLegalRepresentativesFieldsForm } from 'data/modules/cards/companyOnboarding';

import { Validate } from 'shared/utils/validation';

export function useAddMembersOrLegalRepresentativesSchema(): ObjectSchema<IAddMembersOrLegalRepresentativesFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const addMembersOrLegalRepresentativesSchema: ObjectSchema<IAddMembersOrLegalRepresentativesFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object({
        birthDate: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'birthDate',
            message: lang.schemas.invalid_date[currentLangKey],
            test: (value: string) => Validate.isDate(value)
          })
          .test({
            name: 'birthDate',
            message: lang.schemas.date_must_not_be_future[currentLangKey],
            test: (value: string) => !Validate.isFutureDate(value)
          }),
        document: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'documentNumber',
            message: lang.schemas.invalid_cpf[currentLangKey],
            test: (value: string) => Validate.cpf(value)
          }),
        email: yup
          .string()
          .required(fieldRequiredMessage)
          .email(lang.schemas.invalid_email[currentLangKey]),
        name: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'fullName',
            message: lang.schemas.required_full_name[currentLangKey],
            test: (value: string) => Validate.fullName(value)
          }),
        phoneNumber: yup
          .string()
          .required(fieldRequiredMessage)
          .min(15, `${lang.schemas.min[currentLangKey]} 15`)
      });
    }, [lang, currentLangKey]);

  return addMembersOrLegalRepresentativesSchema;
}
