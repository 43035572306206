import { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import { ActionModal } from 'presentation/components/global/ActionModal';

import { useExpenseOutOfPolicyModal } from './useExpenseOutOfPolicyModal';

import { type IExpenseOutOfPolicyModalProps } from './ExpenseOutOfPolicyModal.types';

import {
  Container,
  ListMessages,
  StyledSeparator,
  Subtitle,
  SubtitleContainer,
  SubtitleWithMargin
} from './ExpenseOutOfPolicyModal.styles';

export function ExpenseOutOfPolicyModal({
  onClose,
  errorsFromApi,
  expenseTypeId,
  hasUserFilledInTheObservationField,
  handleSubmitExpenseOutOfPolicy
}: IExpenseOutOfPolicyModalProps): JSX.Element {
  const { t } = useTranslation(['expenses', 'global']);

  const {
    individualMessages,
    observationMessage,
    projectsMessages,
    observationFormLabel,
    isLoadingData,
    showConfirmButton
  } = useExpenseOutOfPolicyModal({
    errorsFromApi,
    expenseTypeId,
    hasUserFilledInTheObservationField
  });

  return (
    <ActionModal
      title={t('expenses:modal.expenseOutsideTheEstablishedPolicy')}
      onClose={onClose}
      isLoading={isLoadingData}
      headerGapDisabled
      onCancel={!showConfirmButton ? undefined : onClose}
      onConfirm={!showConfirmButton ? onClose : handleSubmitExpenseOutOfPolicy}
      confirmLabel={!showConfirmButton ? t('global:cancel') : undefined}
    >
      <Container>
        {individualMessages.length > 0 && (
          <ListMessages>
            {individualMessages.map((message, index) => (
              <li key={index}>
                <span>{message}</span>
              </li>
            ))}
          </ListMessages>
        )}

        {individualMessages.length > 0 && observationMessage !== undefined && (
          <StyledSeparator />
        )}

        {observationMessage !== undefined && (
          <SubtitleContainer>
            <Subtitle>{observationFormLabel}</Subtitle>
            <ListMessages $fontSmall>
              <li>
                <span>{observationMessage}</span>
              </li>
            </ListMessages>
          </SubtitleContainer>
        )}

        {observationMessage !== undefined && projectsMessages.length > 0 && (
          <StyledSeparator />
        )}

        {projectsMessages.length > 0 &&
          projectsMessages.map((project, index) => (
            <Fragment key={project.projectName + index}>
              <SubtitleContainer>
                <Subtitle>{project.projectName}</Subtitle>
                <ListMessages>
                  {project.messages.map((message, messageIndex) => (
                    <li
                      key={`${project.projectName} + ${index} + ${messageIndex}`}
                    >
                      <span>{message}</span>
                    </li>
                  ))}
                </ListMessages>
              </SubtitleContainer>

              {index !== projectsMessages.length - 1 && <StyledSeparator />}
            </Fragment>
          ))}

        {showConfirmButton && (
          <SubtitleWithMargin>
            {t('expenses:modal.doYouWantToContinueAnyway')}
          </SubtitleWithMargin>
        )}
      </Container>
    </ActionModal>
  );
}
