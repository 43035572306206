import { apiWeb } from 'data/config';
import { type IDefaultResponse } from 'data/modules/global';

import { type INpsSurveyResponsePayload } from '..';

class SurveyService {
  async createNpsSurveyResponse(
    payload: INpsSurveyResponsePayload
  ): Promise<void> {
    await apiWeb.post<IDefaultResponse<void>>('/nps', {
      grade: payload.grade,
      postpone: payload.postpone,
      comments: payload.comments,
      nps_survey_identifier: payload.npsSurveyId
    });
  }
}

export default new SurveyService();
