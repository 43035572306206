import { api } from 'data/config';
import { type IDefaultResponse } from 'data/modules/global';
import {
  type IGetRoutePoliciesPayload,
  type IRoutePolicies
} from 'data/modules/routePolicies';

import { PaginationMapper } from 'shared/mappers';

class RoutePoliciesService {
  async getRoutePolicies(
    payload: IGetRoutePoliciesPayload
  ): Promise<IRoutePolicies[]> {
    const {
      data: { data }
    } = await api.get<IDefaultResponse<IRoutePolicies[]>>('/route-policies', {
      params: PaginationMapper.toPersistence(payload)
    });

    return data;
  }
}

export default new RoutePoliciesService();
