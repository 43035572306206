import { useQueryCache } from 'data/cache';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUseGetCompanyOnboardingProgress,
  type IUseGetCompanyOnboardingProgressParams
} from 'data/modules/cards/companyOnboarding';

export function useGetCompanyOnboardingProgress({
  enabled
}: IUseGetCompanyOnboardingProgressParams): IUseGetCompanyOnboardingProgress {
  const {
    data: companyProgress,
    isInitialLoading: isLoadingCompanyProgress,
    isSuccess: isSuccessCompanyOnboardingProgress,
    isError: isErrorCompanyOnboardingProgress
  } = useQueryCache({
    queryKey: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS],
    queryFn: async () => await CompanyOnboardingService.getProgress(),
    enabled
  });

  return {
    companyProgress,
    isLoadingCompanyProgress,
    isSuccessCompanyOnboardingProgress,
    isErrorCompanyOnboardingProgress
  };
}
