import { useMutationCache } from 'data/cache';
import {
  FuelMutationKeys,
  FuelService,
  type ILinkOrUnlinkMembersFromVehiclePayload,
  type IUseLinkOrUnlinkMembersFromVehicle,
  type IUseLinkOrUnlinkMembersFromVehicleParams
} from 'data/modules/fuel';

export function useLinkOrUnlinkMembersFromVehicle(
  params?: IUseLinkOrUnlinkMembersFromVehicleParams
): IUseLinkOrUnlinkMembersFromVehicle {
  const {
    isLoading: isLinkingOrUnlinkingMembersFromVehicle,
    mutateAsync: linkOrUnlinkMembersFromVehicleAsync
  } = useMutationCache({
    mutationKey: [FuelMutationKeys.LINK_MEMBERS_TO_VEHICLE],
    mutationFn: FuelService.linkOrUnlinkMembersFromVehicle
  });

  async function linkOrUnlinkMembersFromVehicle(
    payload: ILinkOrUnlinkMembersFromVehiclePayload
  ): Promise<void> {
    try {
      await linkOrUnlinkMembersFromVehicleAsync(payload);

      params?.onSuccessLinkOrUnlinkMembersFromVehicle?.();
    } catch (e) {
      params?.onErrorLinkOrUnlinkMembersFromVehicle?.();
    }
  }

  return {
    linkOrUnlinkMembersFromVehicleAsync,
    linkOrUnlinkMembersFromVehicle,
    isLinkingOrUnlinkingMembersFromVehicle
  };
}
