import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.tokens.brandColorPrimaryLightest};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;
  max-width: 55rem;
  text-align: center;

  h1 {
    font-size: 2.4rem;
    color: ${({ theme }) => theme.tokens.brandColorSecondaryPure};
    font-weight: 600;
  }

  p {
    color: ${({ theme }) => theme.tokens.neutralColorLowDark};
    font-size: 1.8rem;
  }
`;

export const AnswerableUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-top: 6.4rem;
  border: 1px solid ${({ theme }) => theme.tokens.brandColorSecondaryPure};
  padding: 1.6rem;
  border-radius: 4px;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.tokens.neutralColorLowDark};

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 25rem;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: 600;
  }

  span.legal-representative {
    margin-top: 0.8rem;
    color: ${({ theme }) => theme.tokens.neutralColorLowMedium};
  }

  span.name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 20rem;
  }
`;
