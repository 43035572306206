import { tokens } from 'ds';
import styled from 'styled-components';

export const ItemSection = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.spacingMd};
  padding: ${tokens.spacingMd};
  border-radius: ${tokens.borderRadiusMd};
  background: ${tokens.neutralColorHighPure};
  border: 1px solid ${tokens.neutralColorHighMedium};
`;

export const TableHeader = styled.h3`
  font-style: normal;
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightSemibold};
`;
