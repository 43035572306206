import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type SectionTitleType } from './TermsOfUse.types';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 5rem);
  padding: 2rem 3rem;
  overflow: auto;
  background: ${({ theme }) => theme.colors.alabaster};
  border: 1px solid ${({ theme }) => theme.colors.mercury};
  color: ${({ theme }) => theme.colors.mineShaft};

  > h1 {
    margin-top: 0;
  }

  div,
  ol {
    li::marker {
      font-size: 1.6rem;
    }

    p {
      margin: 0.5rem 0;
    }
    margin-left: 6rem;
  }
`;

export const Paragraph = styled.p`
  font-size: 1.6rem;
  margin: 1.5rem 0;
  text-align: justify;

  strong,
  a,
  em,
  u {
    font-size: 1.6rem;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const SectionTitle = styled.h1<AddPrefixToType<SectionTitleType>>`
  font-size: 1.8rem;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  text-align: ${({ $align }) => $align ?? 'start'};
`;

export const Footer = styled.p`
  font-size: 1.6rem;
  margin: 1.5rem 0;
  text-align: center;
`;
