import { useQueryClient } from '@tanstack/react-query';

import { useMutationCache } from 'data/cache';
import {
  FuelMutationKeys,
  FuelQueryKeys,
  FuelService,
  type IFuelPolicies,
  type IUpdateFuelPoliciesPayload,
  type IUseUpdateFuelPolicies,
  type IUseUpdateFuelPoliciesParams
} from 'data/modules/fuel';

export function useUpdateFuelPolicies({
  onErrorUpdateFuelPolicies,
  onSuccessUpdateFuelPolicies
}: IUseUpdateFuelPoliciesParams): IUseUpdateFuelPolicies {
  const {
    isLoading: isUpdatingFuelPolicies,
    mutateAsync: updateFuelPoliciesAsync
  } = useMutationCache({
    mutationKey: [FuelMutationKeys.UPDATE_FUEL_POLICIES],
    mutationFn: FuelService.updateFuelPolicies
  });

  const queryClient = useQueryClient();

  async function updateFuelPolicies(
    payload: IUpdateFuelPoliciesPayload
  ): Promise<void> {
    try {
      await updateFuelPoliciesAsync(payload);

      queryClient.setQueryData<IFuelPolicies>(
        [FuelQueryKeys.GET_FUEL_POLICIES],
        payload
      );

      onSuccessUpdateFuelPolicies?.();
    } catch (e) {
      onErrorUpdateFuelPolicies?.();
    }
  }

  return {
    isUpdatingFuelPolicies,
    updateFuelPolicies
  };
}
