import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 2.4rem 0;

  > h1 {
    text-align: left;
    padding: 0 2.4rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.4rem;
  gap: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    > div {
      gap: 8rem;
      flex-direction: row;
    }
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  word-break: break-all;
  text-align: center;
  margin-top: 1rem;
`;

export const OptionsContainer = styled.div`
  padding: 1rem 0;
  width: fit-content;
`;
