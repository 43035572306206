import {
  BadgeStatus,
  DefaultTable,
  DSIcons,
  FloatingMenu,
  SkeletonLoader,
  tokens,
  Tooltip
} from 'ds';
import { useTranslation } from 'react-i18next';

import { vexDomain } from 'data/config';
import { TeamMemberType } from 'data/modules/teamMembers';

import { Mask } from 'shared/utils/format';

import { type ITeamMembersTableRowProps } from './TeamMembersTableRow.types';

export function TeamMembersTableRow({
  isLoadingChargedUsers,
  ...member
}: ITeamMembersTableRowProps): JSX.Element {
  const { t } = useTranslation(['settings', 'global']);

  const memberTypeLabel = {
    [TeamMemberType.USUARIO]: t('userTypes.normal'),
    [TeamMemberType.ADMINISTRADOR]: t('userTypes.administrator'),
    [TeamMemberType.INTERMEDIARIO]: t('userTypes.intermediate')
  };

  return (
    <DefaultTable.Tr>
      <DefaultTable.Td align='center'>
        {isLoadingChargedUsers ? (
          <SkeletonLoader />
        ) : (
          member.charged && (
            <Tooltip
              placement='top'
              containerClassName='team-members-tooltip'
              message={t('teamMembers.userMadeExpenses', {
                initialDate: member.charged.startDate ?? '--/--/----',
                endDate: member.charged.endDate ?? '--/--/----'
              })}
            >
              <div
                onClick={() => {
                  window.location.href = `${vexDomain}/settings/financeiro`;
                }}
              >
                <DSIcons.ReceiptCurrencyDollarIcon />
              </div>
            </Tooltip>
          )
        )}
      </DefaultTable.Td>

      <DefaultTable.Td truncate>{member.name}</DefaultTable.Td>

      <DefaultTable.Td truncate>{member.safeEmail}</DefaultTable.Td>

      <DefaultTable.Td>{memberTypeLabel[member.typeUser]}</DefaultTable.Td>

      <DefaultTable.Td>{Mask.apply('cpf', member.cpf)}</DefaultTable.Td>

      <DefaultTable.Td>
        <Tooltip
          placement='top'
          containerClassName='team-members-tooltip'
          message={t(
            member.active
              ? 'teamMembers.userEnabledWithAccess'
              : 'teamMembers.userDisabledNoExpenses'
          )}
        >
          <div>
            <BadgeStatus type={member.active ? 'success' : 'error'}>
              {member.active ? (
                <DSIcons.SingleUserCheckmarkIcon />
              ) : (
                <DSIcons.UserDeleteCrossIcon />
              )}
              {t(member.active ? 'global:enabled_one' : 'global:disabled_one')}
            </BadgeStatus>
          </div>
        </Tooltip>
      </DefaultTable.Td>

      <DefaultTable.Td
        align='center'
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <FloatingMenu
          options={[
            {
              icon: (
                <DSIcons.EditPenIcon
                  width={24}
                  color={tokens.brandColorPrimaryMedium}
                />
              ),
              label: t('teamMembers.editUser'),
              onClick: () => {}
            },
            {
              icon: member.active ? (
                <DSIcons.UserDeleteCrossIcon
                  width={24}
                  color={tokens.feedbackColorErrorPure}
                />
              ) : (
                <DSIcons.SingleUserCheckmarkIcon
                  width={24}
                  color={tokens.feedbackColorSuccessPure}
                />
              ),
              label: t(
                member.active
                  ? 'teamMembers.disableUser'
                  : 'teamMembers.activateUser'
              ),
              onClick: () => {}
            } /* Será implementado futuramente
            ,
            {
              icon: member.useCards ? (
                <DSIcons.BlockIcon
                  width={24}
                  color={tokens.feedbackColorErrorPure}
                />
              ) : (
                <DSIcons.CreditCardIcon
                  width={24}
                  color={tokens.feedbackColorSuccessPure}
                />
              ),
              label: t(
                member.isApprover
                  ? 'teamMembers.blockCard'
                  : 'teamMembers.enableCard'
              ),

              onClick: () => {}
            } */
          ]}
          trigger={<DSIcons.DotsActionIcon />}
        />
      </DefaultTable.Td>
    </DefaultTable.Tr>
  );
}
