import { DefaultButton, Modal, SecondaryButton } from 'ds';
import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';

import { TeamMemberForm } from 'presentation/pages/settings/companyData/TeamMembers/components/TeamMemberForm';

import { withModal } from 'shared/hocs';

import { useNewTeamMemberModal } from './useNewTeamMemberModal';

import {
  ModalContent,
  ModalFooter,
  ModalTitle
} from './NewTeamMemberModal.styles';

function NewTeamMemberModalComponent(): JSX.Element {
  const { t } = useTranslation(['global', 'settings']);
  const { handleCloseModal } = useModalContext();

  const { methods, handleCreateTeamMember } = useNewTeamMemberModal();

  return (
    <Modal.Container
      onClose={handleCloseModal}
      variant='large'
    >
      <Modal.Header>
        <ModalTitle>{t('settings:teamMembers.newTeamMember')}</ModalTitle>

        <Modal.IconClose onClick={handleCloseModal} />
      </Modal.Header>

      <Modal.Form
        methods={methods}
        handleSubmit={handleCreateTeamMember}
      >
        <ModalContent>
          <TeamMemberForm />
        </ModalContent>

        <ModalFooter>
          <SecondaryButton
            size='small'
            type='button'
            onClick={handleCloseModal}
          >
            {t('cancel')}
          </SecondaryButton>
          <DefaultButton size='small'>{t('save')}</DefaultButton>
        </ModalFooter>
      </Modal.Form>
    </Modal.Container>
  );
}

export const NewTeamMemberModal = withModal(
  'newTeamMember',
  NewTeamMemberModalComponent
);
