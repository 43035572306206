import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type TransactionIdContainerStylesType } from './Header.types';

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  padding-right: 0.5rem;

  > p {
    display: flex;
    flex-direction: column;
    align-items: center;

    > span:first-child {
      font-size: 1.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.emperor};
    }

    > span:last-child {
      font-size: 1.6rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  > h1 {
    font-size: 1.8rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.emperor};
    display: flex;
    align-items: baseline;

    > div {
      margin-left: 0.5rem;
    }
  }
`;

export const TransactionIdContainer = styled.span<
  AddPrefixToType<TransactionIdContainerStylesType>
>`
  display: block;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.dustyGray};

  ${({ $isReversed }) =>
    $isReversed &&
    css`
      text-decoration: line-through;
    `}
`;
