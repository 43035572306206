import { tokens } from 'ds';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  .no-padding-right {
    padding-right: 0;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4.8rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    gap: 2.4rem;

    > img {
      align-self: center;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    overflow-x: hidden;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingNano};
  width: 100%;

  > p {
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxss};
    color: ${tokens.neutralColorLowDark};
    font-style: normal;
    font-weight: ${tokens.fontWeightMedium};
    line-height: 2.5rem;
    letter-spacing: 0.25px;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
