import styled from 'styled-components';

export const HeadRow = styled.tr`
  padding-left: 3.6rem;
  background: ${({ theme }) => theme.colors.alabaster};

  th {
    padding: 1rem 1.2rem;
  }
`;

export const HeadCell = styled.th`
  background: none;
  border: 0;
  color: ${({ theme }) => theme.colors.dustyGray};
  padding: 0.2rem 0.3rem;
  font-size: 1.6rem;
  text-transform: uppercase;
  text-align: left;
  font-weight: 400;
`;
