import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { reportStatusColors } from 'shared/constants/reports';

import { type ContainerStylesType } from './ExpenseStatus.types';

export const Container = styled.p<AddPrefixToType<ContainerStylesType>>`
  display: flex;
  align-items: center;

  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 400;
  color: ${({ $status, theme }) =>
    theme.colors[reportStatusColors[$status] || 'emperor']};

  > span {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1rem;
    border-radius: 50%;

    background: ${({ $status, theme }) =>
      theme.colors[reportStatusColors[$status] || 'emperor']};
  }
`;
