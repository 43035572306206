import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  gap: ${tokens.spacingMd};

  > div {
    flex: 1 0 20rem;

    h1 {
      white-space: nowrap;
    }

    :not(.analysis-summary-card-tooltip) > span {
      font-size: 1.8rem;
    }
  }
`;

export const IconWrapper = styled.div`
  svg {
    width: 2.4rem;
    height: 2.4rem;
    color: ${tokens.highlightColorPure};
  }
`;
