import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IUpdateCompanyPoliciesAndTermsFieldsForm } from 'data/modules/cards/companyOnboarding';

export function useUpdateCompanyPoliciesAndTermsSchema(): ObjectSchema<IUpdateCompanyPoliciesAndTermsFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);
  const updateCompanyPoliciesAndTermsSchema: ObjectSchema<IUpdateCompanyPoliciesAndTermsFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object({
        acceptPrivacyPolicy: yup
          .boolean()
          .required(fieldRequiredMessage)
          .test({
            name: 'privacyPolicy',
            message: lang.schemas.invalid_privacy_policy[currentLangKey],
            test: value => value
          }),
        acceptTermsOfUse: yup
          .boolean()
          .required(fieldRequiredMessage)
          .test({
            name: 'termsOfUse',
            message: lang.schemas.invalid_terms_of_use[currentLangKey],
            test: value => value
          })
      });
    }, [lang, currentLangKey]);

  return updateCompanyPoliciesAndTermsSchema;
}
