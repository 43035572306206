import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleTextContainer = styled.div`
  padding-left: 3.7rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  > h1 {
    text-align: left;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-left: 0;
  }
`;

export const Divider = styled.div`
  border-bottom: ${({ theme }) => theme.colors.alto} solid 1px;
  width: 100%;
  margin: 4.5rem 0;
`;

export const InfoContainer = styled.div`
  padding-left: 3.7rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-left: 0;
  }
`;

export const InfoSectionTitle = styled.p`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.tokens.neutralColorLowDark};
  font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto};
`;

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: 100%;

  svg {
    flex-shrink: 0;
  }

  > h1 {
    text-align: left;
  }
`;
