import styled, { css } from 'styled-components';

import { type IStepBallStyles } from './DsProgressSteps.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
`;

export const StepBall = styled.span<AddPrefixToType<IStepBallStyles>>`
  background-color: ${({ theme }) => theme.colors.lightSilver};
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      background-color: ${theme.colors.secondary};
      width: 2rem;
      border-radius: 16px;
    `}
`;
