import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0.8rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.8rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 1rem;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 1rem;

  > h1 {
    text-align: left;
  }

  > p {
    font-size: 1.8rem;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: fit-content;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  word-break: break-all;
  margin-top: 2rem;
  width: 100%;
  text-align: center;
  position: absolute;
`;

export const OptionsWrapper = styled.div`
  position: relative;
`;
