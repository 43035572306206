import { useLangContext } from 'data/contexts';

import { CardsFunctionOption } from 'presentation/pages/cards/Onboarding/components/Company/CardsFunctionsActivation/';
import {
  OnboardingFooter,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { useCardsFunctionsActivation } from './useCardsFunctionsActivation';

import {
  Container,
  Content,
  OptionsContainer
} from './CardsFunctionsActivation.styles';

export function CardsFunctionsActivation(): JSX.Element {
  const { handleUpdateCardsFunctions, control, errors } =
    useCardsFunctionsActivation();
  const [title, withdrawn, internationalTransactions, currentLangKey] =
    useLangContext(state => [
      state.lang.cards.onboarding.company.cards_functions_activation.title,
      state.lang.cards.onboarding.company.cards_functions_activation.options
        .withdrawn,
      state.lang.cards.onboarding.company.cards_functions_activation.options
        .international_transactions,
      state.currentLangKey
    ]);

  return (
    <Container>
      <Content onSubmit={handleUpdateCardsFunctions}>
        <OnboardingTitle>{title[currentLangKey]}</OnboardingTitle>

        <OptionsContainer>
          <CardsFunctionOption
            icon='swap'
            control={control}
            name='useWithdraw'
            error={errors.useWithdraw?.message}
            title={withdrawn.title[currentLangKey]}
            description={withdrawn.description[currentLangKey]}
            details={withdrawn.details.map(detail => detail[currentLangKey])}
          />

          <CardsFunctionOption
            icon='earth'
            control={control}
            name='useInternationalTransaction'
            error={errors.useInternationalTransaction?.message}
            title={internationalTransactions.title[currentLangKey]}
            description={internationalTransactions.description[currentLangKey]}
            details={internationalTransactions.details.map(
              detail => detail[currentLangKey]
            )}
          />
        </OptionsContainer>

        <OnboardingFooter
          continueButtonProps={{
            onClick: () => {}
          }}
        />
      </Content>
    </Container>
  );
}
