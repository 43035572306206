import { DSIcons, tokens } from 'ds';
import styled from 'styled-components';

export const InputFile = styled.input`
  display: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetNano};
  width: 100%;
`;

export const Caption = styled.span`
  display: inline-block;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  color: ${tokens.feedbackColorErrorPure};
`;

export const AttachmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
`;

export const AttachmentItem = styled.div`
  display: flex;
  align-self: normal;
  gap: ${tokens.spacingNano};
`;

export const Filename = styled.span`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: 0.75px;
  flex-grow: 1;
  word-break: break-all;
`;

export const StyledClipIcon = styled(DSIcons.ClipIcon)`
  color: ${tokens.brandColorSecondaryPure};
  flex-shrink: 0;
`;

export const StyledCloseIcon = styled(DSIcons.CloseIcon)`
  color: ${tokens.neutralColorLowDarkest};
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  flex-shrink: 0;
`;
