import { useEffect, useState } from 'react';

import { toast } from 'ds';
import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';
import {
  useGetPaginatedMembers,
  useGetVehicle,
  useLinkOrUnlinkMembersFromVehicle
} from 'data/modules/fuel';

import {
  type ILinkUnlinkMembersStepState,
  type IUseLinkOrUnlinkMembersModal,
  type IUseLinkOrUnlinkMembersModalParams
} from './LinkOrUnlinkMembersModal.types';

export function useLinkOrUnlinkMembersModal({
  vehicleId
}: IUseLinkOrUnlinkMembersModalParams): IUseLinkOrUnlinkMembersModal {
  const { t } = useTranslation(['fuel', 'global']);

  const { handleCloseModal } = useModalContext();

  const [linkUnlinkMembersStepState, setLinkUnlinkMembersStepState] =
    useState<ILinkUnlinkMembersStepState>({
      clickedOnSelectAllMembers: false,
      all: false,
      attachMembersIds: new Set(),
      detachMembersIds: new Set(),
      changedMembers: {
        attach: new Set(),
        detach: new Set()
      }
    });

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [showConfirmEmptyModal, setShowConfirmEmptyModal] = useState(false);

  const { vehicle, isLoadingVehicle } = useGetVehicle({
    vehicleId
  });

  const {
    paginatedMembers,
    isLoadingPaginatedMembers,
    removeQueriesByVehicleId
  } = useGetPaginatedMembers({
    page: 1
  });

  const totalMembers = paginatedMembers?.total;

  const {
    paginatedMembers: paginatedLinkedMembers,
    isLoadingPaginatedMembers: isLoadingPaginatedLinkedMembers
  } = useGetPaginatedMembers({
    linked: true,
    vehicleId
  });

  const totalLinkedMembers = paginatedLinkedMembers?.total;

  const {
    isLinkingOrUnlinkingMembersFromVehicle,
    linkOrUnlinkMembersFromVehicleAsync
  } = useLinkOrUnlinkMembersFromVehicle();

  function handleConfirmLinkMember(): void {
    if (
      // se o tamanho do detach for igual ao número de membros previamente vinculados
      // e o tamanho do attach for igual a 0, então não será vinculado membros ao veículo
      (!linkUnlinkMembersStepState.clickedOnSelectAllMembers &&
        linkUnlinkMembersStepState.changedMembers.detach.size ===
          totalLinkedMembers &&
        linkUnlinkMembersStepState.changedMembers.attach.size === 0) ||
      // ou então se o usuário manipulou o botão de "Selecionar todos"
      // e o all não está marcado
      // e (número de membros desvinculados é igual ao total de membros ou número de membros vinculados é igual a 0)
      // então, não será vinculado membros ao veículo
      (linkUnlinkMembersStepState.clickedOnSelectAllMembers &&
        !linkUnlinkMembersStepState.all &&
        (linkUnlinkMembersStepState.detachMembersIds.size === totalMembers ||
          linkUnlinkMembersStepState.attachMembersIds.size === 0))
    ) {
      setShowConfirmEmptyModal(true);
      return;
    }

    setShowConfirmModal(true);
  }

  async function makeRequestsToLinkOrUnlinkMembers(): Promise<void> {
    // se o usuário manipulou o campo de "Selecionar todos"
    if (linkUnlinkMembersStepState.clickedOnSelectAllMembers) {
      // caso o "Selecionar todos esteja marcado" ou tenha algo no Set de detach
      if (
        linkUnlinkMembersStepState.all ||
        linkUnlinkMembersStepState.detachMembersIds.size > 0
      ) {
        // primeiro, vai vincular todos e depois desvincular os selecionados
        await linkOrUnlinkMembersFromVehicleAsync({
          vehicleId,
          membersIdsToAttach: [0]
        });

        if (linkUnlinkMembersStepState.detachMembersIds.size > 0) {
          await linkOrUnlinkMembersFromVehicleAsync({
            vehicleId,
            membersIdsToDetach: Array.from(
              linkUnlinkMembersStepState.detachMembersIds
            )
          });
        }
      } else {
        // se não, vai desvincular todos e depois vincular os selecionados
        await linkOrUnlinkMembersFromVehicleAsync({
          vehicleId,
          membersIdsToDetach: [0]
        });

        if (linkUnlinkMembersStepState.attachMembersIds.size > 0) {
          await linkOrUnlinkMembersFromVehicleAsync({
            vehicleId,
            membersIdsToAttach: Array.from(
              linkUnlinkMembersStepState.attachMembersIds
            )
          });
        }
      }

      return;
    }

    // caso não tenha manipulado o campo de "Selecionar todos"
    // e existir alguma coisa no Set de attach ou detach de membros alterados
    if (
      linkUnlinkMembersStepState.changedMembers.attach.size > 0 ||
      linkUnlinkMembersStepState.changedMembers.detach.size > 0
    ) {
      await linkOrUnlinkMembersFromVehicleAsync({
        vehicleId,
        membersIdsToAttach: Array.from(
          linkUnlinkMembersStepState.changedMembers.attach
        ),
        membersIdsToDetach: Array.from(
          linkUnlinkMembersStepState.changedMembers.detach
        )
      });
    }
  }

  async function handleConfirmSaveLinkMember(): Promise<void> {
    try {
      // faz as requisições para vincular e desvincular membros
      await makeRequestsToLinkOrUnlinkMembers();

      handleCloseModal();

      toast.success(t('modal.successOnLinkMembers'));

      setTimeout(() => {
        // espera pelo menos 500 milissegundos para limpar o cache das requests de membros relacionados ao veículo
        // isso é necessário porque o Modal ainda pode estar aberto e, portanto, as queries vão estar ativas
        // caso ela estejam ativas, serão feitas requisições desnecessárias
        removeQueriesByVehicleId(vehicleId);
      }, 500);
    } catch (e) {
      // se houver erro, exibe mensagem de erro
      toast.error(t('modal.errorOnLinkMembers'));
    }
  }

  useEffect(() => {
    // se não estiver carregando membros paginados vinculados e desvinculados
    // e o total de membros e membros vinculados for diferente de undefined
    // então, verifica se todos os membros estão vinculados
    if (!isLoadingPaginatedLinkedMembers && !isLoadingPaginatedMembers) {
      setLinkUnlinkMembersStepState(oldState => ({
        ...oldState,
        all: totalLinkedMembers === totalMembers
      }));
    }
  }, [
    totalMembers,
    totalLinkedMembers,
    isLoadingPaginatedLinkedMembers,
    isLoadingPaginatedMembers
  ]);

  function handleClickSelectAllMembers(): void {
    setLinkUnlinkMembersStepState(prevState => {
      return {
        changedMembers: {
          attach: new Set(),
          detach: new Set()
        },
        // registra que a opção "Selecionar Todos os Membros" foi clicada
        clickedOnSelectAllMembers: true,
        // inverte o estado de "Selecionar Todos os Membros"
        all: !prevState.all,
        attachMembersIds: new Set(),
        detachMembersIds: new Set()
      };
    });
  }

  function handleClickOnMember(
    isLinkedOrUnlinked: 'linked' | 'unlinked',
    id: number
  ): void {
    setLinkUnlinkMembersStepState(oldState => {
      // Verifica se a opção "Selecionar Todos os Membros" foi clicada
      const clickedOnSelectAllMembers = oldState.clickedOnSelectAllMembers;

      // Se, em algum momento, o "Selecionar todos" foi clicado, só entra nesse IF
      // isso acontece porque o "Selecionar todos" é uma ação que sobrescreve as outras
      if (clickedOnSelectAllMembers) {
        // Obtém o estado anterior da opção "Selecionar Todos" e os conjuntos de membros anexados e desanexados
        const oldSelectAll = oldState.all;
        const newAttachMembersIds = new Set([...oldState.attachMembersIds]);
        const newDetachMembersIds = new Set([...oldState.detachMembersIds]);

        // Se a opção "Selecionar Todos" estava previamente marcada
        if (oldSelectAll) {
          // Retorna um novo estado onde o membro clicado é adicionado ao conjunto de desanexação
          return {
            ...oldState,
            changedMembers: {
              attach: new Set(),
              detach: new Set()
            },
            all: false,
            attachMembersIds: new Set(),
            detachMembersIds: new Set([id])
          };
        }

        // Se houver algum membro no conjunto de desanexação
        if (newDetachMembersIds.size > 0) {
          // Se o membro está no conjunto de desanexação, remova-o
          if (newDetachMembersIds.has(id)) {
            newDetachMembersIds.delete(id);

            return {
              ...oldState,
              changedMembers: {
                attach: new Set(),
                detach: new Set()
              },
              all: newDetachMembersIds.size === 0,
              attachMembersIds: newAttachMembersIds,
              detachMembersIds: newDetachMembersIds
            };
          }

          // Se o membro não estava no conjunto de desanexação, adiciona-o
          newDetachMembersIds.add(id);
          return {
            ...oldState,
            changedMembers: {
              attach: new Set(),
              detach: new Set()
            },
            all: false,
            attachMembersIds: newAttachMembersIds,
            detachMembersIds: newDetachMembersIds
          };
        }

        // Se o ID estava em attachMembersIds, remove-o
        if (newAttachMembersIds.has(id)) {
          newAttachMembersIds.delete(id);

          return {
            ...oldState,
            changedMembers: {
              attach: new Set(),
              detach: new Set()
            },
            all: false,
            attachMembersIds: newAttachMembersIds,
            detachMembersIds: newDetachMembersIds
          };
        }

        // Se o ID não estava em nenhum dos conjuntos, adiciona-o em attachMembersIds
        newAttachMembersIds.add(id);
        return {
          ...oldState,
          changedMembers: {
            attach: new Set(),
            detach: new Set()
          },
          all: newAttachMembersIds.size === totalMembers,
          attachMembersIds: newAttachMembersIds,
          detachMembersIds: newDetachMembersIds
        };
      }

      // entra nos códigos abaixo se a opção "Selecionar Todos os Membros" não foi clicada
      // nesse caso, o usuário está clicando em membros individualmente
      // Se o membro veio da API como vinculado
      if (isLinkedOrUnlinked === 'linked') {
        const newChangedMembersDetach = new Set(oldState.changedMembers.detach);

        // Se o membro já está no conjunto de desanexação, remove-o, caso contrário, adiciona-o
        if (newChangedMembersDetach.has(id)) {
          newChangedMembersDetach.delete(id);
        } else {
          newChangedMembersDetach.add(id);
        }

        return {
          ...oldState,
          changedMembers: {
            attach: oldState.changedMembers.attach,
            detach: newChangedMembersDetach
          },
          all:
            oldState.changedMembers.attach.size +
              (totalLinkedMembers as number) -
              newChangedMembersDetach.size ===
            totalMembers,
          attachMembersIds: new Set(),
          detachMembersIds: new Set()
        };
      }

      // Se o membro veio da API como desvinculado
      if (isLinkedOrUnlinked === 'unlinked') {
        const newChangedMembersAttach = new Set(oldState.changedMembers.attach);

        // Se o membro já está no conjunto de anexação, remove-o, caso contrário, adiciona-o
        if (newChangedMembersAttach.has(id)) {
          newChangedMembersAttach.delete(id);
        } else {
          newChangedMembersAttach.add(id);
        }

        return {
          ...oldState,
          changedMembers: {
            attach: newChangedMembersAttach,
            detach: oldState.changedMembers.detach
          },
          all:
            newChangedMembersAttach.size +
              (totalLinkedMembers as number) -
              oldState.changedMembers.detach.size ===
            totalMembers,
          attachMembersIds: new Set(),
          detachMembersIds: new Set()
        };
      }

      // Retorna o estado antigo se nenhuma das condições acima for atendida
      return oldState;
    });
  }

  return {
    isShowingModalLoading:
      isLoadingVehicle ||
      isLoadingPaginatedLinkedMembers ||
      isLoadingPaginatedMembers,
    linkUnlinkMembersStepState,
    handleClickOnMember,
    handleClickSelectAllMembers,
    showConfirmModal,
    isLinkingMembersToVehicle: isLinkingOrUnlinkingMembersFromVehicle,
    showConfirmEmptyModal,
    handleConfirmLinkMember,
    handleConfirmSaveLinkMember,
    setShowConfirmEmptyModal,
    setShowConfirmModal,
    vehicle
  };
}
