import {
  type CurrencyCodeType,
  type CurrencyOptionsType,
  type CurrencyType
} from 'ds/types';

export class Numeric {
  format(
    currency: CurrencyType,
    value: number,
    showCurrencySymbol = false,
    decimalScale = 2,
    extraOptions: CurrencyOptionsType = null
  ): string {
    const currencyTypeToCode: Record<CurrencyType, CurrencyCodeType> = {
      BRL: 'pt-BR',
      EUR: 'en-US',
      USD: 'en-US'
    };

    if (typeof value !== 'number') return '';

    let params: CurrencyOptionsType = { minimumFractionDigits: decimalScale };

    // Parâmetros para adicionar o símbolo da moeda (Ex: R$ 1.000,00)
    if (showCurrencySymbol) {
      params = { ...params, currency, style: 'currency' };
    }

    // Opções extras de formatação
    if (extraOptions) {
      params = { ...params, ...extraOptions };
    }

    // Formata o valor de acordo com a moeda selecionada
    const formattedCurrency = new Intl.NumberFormat(
      currencyTypeToCode[currency],
      params
    ).format(value);

    return formattedCurrency === 'NaN' ? '' : formattedCurrency;
  }

  unformat(value: string, decimalScale = 2): number {
    return +value.replace(/\D/g, '') / 10 ** decimalScale;
  }

  parseToFloat(value: string, decimals: number = 2): number {
    if (!value) return 0;

    const formattedValue = value.replaceAll('.', '').replace(',', '.');

    return Number(parseFloat(formattedValue).toFixed(decimals));
  }

  // a função arredonda um número decimal que possui duas casas decimais e retorna o resultado com duas casas decimais
  roundToTwoDecimals(value: number): number {
    return Math.round(value * 100) / 100;
  }
}

export default new Numeric();
