import { DSIcons } from 'ds/icons';
import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type CardInfoStylesType } from './CardInfoContainer.types';
import { type AddPrefixToType } from 'ds/types';

export const ClickableIconIndicator = styled(DSIcons.ArrowRightIcon)`
  width: 3.2rem;
  height: 3.2rem;
`;

export const Container = styled.div<AddPrefixToType<CardInfoStylesType>>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.04rem;
  padding: ${tokens.spacingInsetXs};
  border-radius: ${tokens.borderRadiusMd};
  background-color: ${tokens.neutralColorHighPure};
  border: 1px solid ${tokens.neutralColorHighMedium};
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 4%);

  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;

      &:hover,
      &:focus-within {
        box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 10%);

        h1 {
          color: ${tokens.brandColorSecondaryMedium};
        }

        ${ClickableIconIndicator} {
          path {
            stroke: ${tokens.brandColorSecondaryPure};
          }
        }
      }

      &:focus-within {
        background-color: ${tokens.brandColorPrimaryLightest};

        p {
          color: ${tokens.brandColorSecondaryPure};
        }
      }
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetQuarck};
`;
