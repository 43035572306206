import { DefaultTable } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useLangContext, useValueSolicitationContext } from 'data/contexts';

import { type ISolicitationActionsTableHeadProps } from './SolicitationActionsTableHead.types';

export function SolicitationActionsTableHead({
  ...rest
}: ISolicitationActionsTableHeadProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  const [step, sortByDate, handleSortByDate] = useValueSolicitationContext(
    useShallow(state => [state.step, state.sortByDate, state.handleSortByDate])
  );

  return (
    <DefaultTable.Thead {...rest}>
      <DefaultTable.Tr>
        <DefaultTable.Th width='13.57%'>
          {lang.cards.valueSolicitation.table.users[currentLangKey]}
        </DefaultTable.Th>

        <DefaultTable.Th
          width='13.57%'
          isActive={sortByDate !== 'default'}
          order={sortByDate}
          onClick={() => {
            handleSortByDate();
          }}
        >
          {lang.cards.valueSolicitation.table.date[currentLangKey]}
        </DefaultTable.Th>

        <DefaultTable.Th width='13.57%'>
          {lang.cards.valueSolicitation.table.usage_prediction[currentLangKey]}
        </DefaultTable.Th>

        <DefaultTable.Th width='13.57%'>
          {lang.cards.valueSolicitation.table.requested_amount[currentLangKey]}
        </DefaultTable.Th>

        <DefaultTable.Th width='13.57%'>
          {lang.cards.valueSolicitation.table.justification[currentLangKey]}
        </DefaultTable.Th>

        <DefaultTable.Th width='13.57%'>Status</DefaultTable.Th>

        <DefaultTable.Th width='13.57%'>
          {step === 'transfer'
            ? lang.cards.valueSolicitation.amount_to_transfer[currentLangKey]
            : lang.cards.valueSolicitation.table.approved_value[currentLangKey]}
        </DefaultTable.Th>

        <DefaultTable.Th width='5%'></DefaultTable.Th>
      </DefaultTable.Tr>
    </DefaultTable.Thead>
  );
}
