import { type IPersistenceAccountHolders } from 'data/modules/cards/accountHolders';
import { type IVirtualMembers } from 'data/modules/cards/businessCard';

class GetVirtualMembersMapper {
  toPersistence(domain: IPersistenceAccountHolders): IVirtualMembers {
    return {
      uuid: domain.uuid,
      approvalFlowId: domain.approval_flow_id,
      email: domain.email,
      expensePolicyId: domain.expense_policy_id,
      routePolicyId: domain.route_policy_id,
      name: domain.name
    };
  }
}

export default new GetVirtualMembersMapper();
