import { useRef } from 'react';

import { parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale/en-US';
import { es } from 'date-fns/locale/es';
import { ptBR } from 'date-fns/locale/pt-BR';
import { DSIcons } from 'ds';
import { useDsGa4 } from 'ds/hooks/globals';
import { CustomDate } from 'ds/utils/index';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

import { useDefaultDatePicker } from './useDefaultDatePicker';

import { type IDefaultDatePickerProps } from './DefaultDatePicker.types';
import { type LocaleType } from 'ds/types/globals/language.types';

import {
  Caption,
  Container,
  DatePickerHeader,
  DatePickerMonth,
  DatePickerYear,
  Fieldset,
  InputComponent,
  Label,
  Legend,
  Wrapper
} from './DefaultDatePicker.styles';

registerLocale('en', enUS);
registerLocale('es', es);
registerLocale('pt', ptBR);

export function DefaultDatePicker({
  iconLeft,
  iconRight,
  errorMessage,
  label,
  disabled,
  variant,
  value,
  currentLangKey = 'pt',
  onChangeValue,
  placeholder,
  containerClassName,
  selectsRange = false,
  onChangeDateRange,
  ...rest
}: IDefaultDatePickerProps): JSX.Element {
  const IconLeft = iconLeft ? DSIcons[iconLeft] : null;
  const IconRight = iconRight ? DSIcons[iconRight] : null;
  const { sendDsGaEvent } = useDsGa4();

  const { startDate, endDate, handleChangeDate } = useDefaultDatePicker({
    onChangeValue,
    onChangeDateRange,
    selectsRange
  });

  const locale: Record<LocaleType, string> = {
    enUS: 'en-US',
    ptBR: 'pt-BR',
    es: 'es-ES'
  };

  const localeComponent = {
    enUS,
    ptBR,
    es
  };

  const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  const dateRangeMask = [...dateMask, ' ', '-', ' ', ...dateMask];

  const datePickerRef = useRef<ReactDatePicker<boolean, undefined>>(null);

  return (
    <div>
      <Wrapper
        className={containerClassName}
        $variant={variant}
      >
        <Container
          {...rest}
          data-testid='container'
          $disabled={disabled}
          $hasError={!!errorMessage}
          $variant={variant}
          $isTouched={!!value}
        >
          <Label>{label}</Label>

          <div>
            {IconLeft && (
              <IconLeft onClick={() => datePickerRef.current?.setOpen(true)} />
            )}

            <ReactDatePicker
              ref={datePickerRef}
              onChange={handleChangeDate}
              selectsRange={selectsRange}
              startDate={selectsRange ? startDate : undefined}
              endDate={selectsRange ? endDate : undefined}
              selected={
                selectsRange ? startDate : value ? parseISO(value) : null
              }
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
              }) => (
                <DatePickerHeader>
                  <DSIcons.ArrowLeftIcon
                    onClick={
                      prevMonthButtonDisabled
                        ? undefined
                        : () => {
                            sendDsGaEvent('components', 'date_picker', {
                              description: 'click no dia',
                              eventAction: 'date_picker_previous',
                              eventLabel: 'previous_date_picker'
                            });

                            decreaseMonth();
                          }
                    }
                  />

                  <div>
                    <DatePickerMonth>
                      {date.toLocaleString(
                        locale[CustomDate.localeTransform(currentLangKey)],
                        {
                          month: 'long'
                        }
                      )}
                    </DatePickerMonth>

                    <DatePickerYear>{date.getFullYear()}</DatePickerYear>
                  </div>

                  <DSIcons.ArrowRightIcon
                    onClick={
                      nextMonthButtonDisabled
                        ? undefined
                        : () => {
                            sendDsGaEvent('components', 'date_picker', {
                              description: 'click no dia',
                              eventAction: 'date_picker_next',
                              eventLabel: 'next_date_picker'
                            });

                            increaseMonth();
                          }
                    }
                  />
                </DatePickerHeader>
              )}
              autoComplete='off'
              calendarClassName='customDatePicker'
              locale={
                localeComponent[CustomDate.localeTransform(currentLangKey)]
              }
              dateFormat='dd/MM/yyyy'
              disabled={disabled}
              adjustDateOnChange={false}
              placeholderText={placeholder}
              customInput={
                <InputComponent
                  type='text'
                  mask={selectsRange ? dateRangeMask : dateMask}
                  onBeforeInput={e => {
                    if (selectsRange) {
                      e.preventDefault();
                    }
                  }}
                  $variant={variant}
                />
              }
            />
          </div>

          {IconRight && (
            <IconRight onClick={() => datePickerRef.current?.setOpen(true)} />
          )}
        </Container>

        <Fieldset>
          <Legend>
            <p>{label}</p>
          </Legend>
        </Fieldset>
      </Wrapper>

      {!!errorMessage && <Caption $hasError>{errorMessage}</Caption>}
    </div>
  );
}
