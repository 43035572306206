enum AnalyticsQueryKeys {
  GET_ANALYSIS = 'GET_ANALYSIS',
  GET_ANALYSIS_RESUME = 'GET_ANALYSIS_RESUME',
  GET_ANALYSIS_USER_LIST = 'GET_ANALYSIS_USER_LIST',
  GET_ANALYSIS_USER_HEADER = 'GET_ANALYSIS_USER_HEADER',
  GET_ANALYSIS_USER_FILTERS = 'GET_ANALYSIS_USER_FILTERS',
  GET_ANALYSIS_USER_REPORTS_ID = 'GET_ANALYSIS_USER_REPORTS_ID'
}

enum AnalyticsMutationKeys {
  SEND_SUGGESTION = 'SEND_SUGGESTION'
}

export { AnalyticsMutationKeys, AnalyticsQueryKeys };
