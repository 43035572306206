import {
  Checkbox,
  DragAndDropFile,
  DSIcons,
  SecondaryButton,
  Select,
  TextArea,
  TextInput
} from 'ds';
import { DatePicker } from 'ds/components/Input/DatePicker';
import { useTranslation } from 'react-i18next';

import { useLangContext } from 'data/contexts';

import { ApportionmentBlockForm } from 'presentation/pages/expenses/ExpensesList/components/ApportionmentBlock';
import { ObservationAttachments } from 'presentation/pages/expenses/ExpensesList/components/ObservationAttachments';
import { SelectDynamicCostsCenters } from 'presentation/pages/expenses/ExpensesList/components/SelectDynamicCases';

import { SelectOdometerImage } from '../SelectOdometerImage/SelectOdometerImage.component';

import { useFirstStepForm } from './useFirstStepForm';

import { type IFirstStepFormProps } from './FirstStepForm.types';

import {
  FormContent,
  LeftContent,
  RightContent,
  RowInputs
} from './FirstStepForm.styles';

export function FirstStepForm({
  onChangeFormExpenseCurrency,
  readOnlyFieldsWhenUserIsUpdatingExpense,
  expenseUuidToUpdate
}: IFirstStepFormProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  const { t } = useTranslation('expenses');

  const {
    isLoadingExpensePaymentMethods,
    paymentMethodsOptions,
    expenseParameters,
    apportionmentFields,
    apportionmentReplace,
    handleChangePaymentMethod,
    currenciesOptions,
    fuelSubTypesOptions,
    isLoadingTypesOfFuels,
    fuelTypesMenuOptions,
    handleChangeExpenseValue,
    handleChangeLicensePlate,
    fieldVehicleTypeDisabled,
    isLoadingLicensePlates,
    handleChangePricePerLiter,
    licensePlateOptions,
    handleInputFocus,
    isLoadingCurrencies,
    handleClickSelectProjectButton,
    fieldRefundableDisabled,
    inputRef,
    handleChangeDate,
    showObservationsAttachmentsIcon,
    isLoadingTypesOfVehicles,
    typesOfVehiclesOptions,
    handleChangeQuantityOfLiters
  } = useFirstStepForm({
    readOnlyFieldsWhenUserIsUpdatingExpense,
    expenseUuidToUpdate
  });

  const paymentMethodFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.expensePaymentMethod !== null
      ? expenseParameters.companyFields.expensePaymentMethod
      : lang.global.payment_form[currentLangKey];

  const costsCenterFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.expenseCostCenter !== null
      ? expenseParameters.companyFields.expenseCostCenter
      : lang.global.costs_center[currentLangKey];

  const observationFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.expenseNote !== null
      ? expenseParameters.companyFields.expenseNote
      : lang.expenses.modal.create_manual_expense.observations[currentLangKey];

  const projectFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.expenseProject !== null
      ? expenseParameters?.companyFields.expenseProject
      : lang.global.project[currentLangKey];

  return (
    <>
      <FormContent>
        <LeftContent className='first-step-form-left-content'>
          <TextInput.Controlled
            small
            label={
              lang.expenses.modal.create_manual_expense.expense_description[
                currentLangKey
              ]
            }
            name='description'
            placeholder={
              lang.expenses.modal.create_manual_expense.expense_description[
                currentLangKey
              ]
            }
            readOnly={readOnlyFieldsWhenUserIsUpdatingExpense.has(
              'description'
            )}
          />

          <RowInputs>
            <Select.Controlled
              small
              name='currency'
              menuPlacement='auto'
              hasArrowDownIndicator
              disabled={readOnlyFieldsWhenUserIsUpdatingExpense.has('currency')}
              isLoading={isLoadingCurrencies}
              options={currenciesOptions}
              onCustomChange={onChangeFormExpenseCurrency}
              executeOnlyOnCustomChange
              placeholder={
                lang.expenses.modal.create_manual_expense.currency[
                  currentLangKey
                ]
              }
              label={
                lang.expenses.modal.create_manual_expense.currency[
                  currentLangKey
                ]
              }
            />
            <TextInput.Controlled
              small
              label={t('modal.totalValue')}
              name='value'
              shouldPreventAutoComplete
              shouldPreventPaste
              mask='currency'
              onFocus={() => {
                handleInputFocus('expenseValue');
              }}
              placeholder={t('modal.totalValue')}
              onChangeValue={handleChangeExpenseValue}
              inputContainerAttributes={{
                className: 'modal-textinput'
              }}
            />
          </RowInputs>

          <RowInputs>
            <Select.Controlled
              small
              name='licensePlate'
              hasArrowDownIndicator
              menuPlacement='auto'
              isLoading={isLoadingLicensePlates}
              options={licensePlateOptions}
              onCustomChange={handleChangeLicensePlate}
              placeholder={t('modal.licensePlate')}
              label={t('modal.licensePlate')}
            />
            <Select.Controlled
              small
              name='vehicleType'
              hasArrowDownIndicator
              menuPlacement='auto'
              label={t('modal.vehicleType')}
              placeholder={t('modal.vehicleType')}
              options={typesOfVehiclesOptions}
              isLoading={isLoadingTypesOfVehicles}
              disabled={fieldVehicleTypeDisabled}
            />
          </RowInputs>

          <RowInputs>
            <TextInput.Controlled
              small
              label={t('modal.quantityOfLiters')}
              name='quantityOfLiters'
              onChangeValue={handleChangeQuantityOfLiters}
              shouldPreventAutoComplete
              shouldPreventPaste
              mask='currency'
              inputContainerAttributes={{
                className: 'modal-textinput'
              }}
              onFocus={() => {
                handleInputFocus('quantityOfLiters');
              }}
              placeholder={t('modal.quantityOfLiters')}
            />

            <TextInput.Controlled
              small
              label={t('modal.pricePerLiter')}
              name='pricePerLiter'
              onChangeValue={handleChangePricePerLiter}
              shouldPreventAutoComplete
              shouldPreventPaste
              mask='currency'
              inputContainerAttributes={{
                className: 'modal-textinput'
              }}
              onFocus={() => {
                handleInputFocus('pricePerLiter');
              }}
              placeholder={t('modal.pricePerLiter')}
            />
          </RowInputs>

          <RowInputs>
            <Select.SubMenuControlled
              small
              name='fuelType'
              hasArrowDownIndicator
              menuPlacement='auto'
              isLoading={isLoadingTypesOfFuels}
              options={{
                menuOptions: fuelTypesMenuOptions,
                options: fuelSubTypesOptions
              }}
              placeholder={t('modal.fuelType')}
              label={t('modal.fuelType')}
            />
            <DatePicker.Controlled
              variant='small'
              currentLangKey={currentLangKey}
              onChangeValue={handleChangeDate}
              label={lang.global.date[currentLangKey]}
              name='date'
              placeholder={lang.global.date[currentLangKey]}
            />
          </RowInputs>

          <RowInputs>
            <Select.Controlled
              small
              name='paymentMethod'
              disabled={readOnlyFieldsWhenUserIsUpdatingExpense.has(
                'paymentMethod'
              )}
              isLoading={isLoadingExpensePaymentMethods}
              options={paymentMethodsOptions}
              onCustomChange={handleChangePaymentMethod}
              placeholder={paymentMethodFormLabel}
              label={paymentMethodFormLabel}
              hasArrowDownIndicator
            />
            <SelectDynamicCostsCenters
              disabled={readOnlyFieldsWhenUserIsUpdatingExpense.has(
                'costsCenter'
              )}
              customLabel={costsCenterFormLabel}
            />
          </RowInputs>

          <RowInputs>
            <SelectOdometerImage />
            <TextInput.Controlled
              small
              label={t('createFuelExpenseModal.mileage')}
              name='mileage'
              shouldPreventAutoComplete
              shouldPreventPaste
              inputContainerAttributes={{
                className: 'modal-textinput'
              }}
              mask='integer'
              placeholder={t('createFuelExpenseModal.mileage')}
            />
          </RowInputs>

          <Checkbox.Controlled
            small
            label={
              lang.expenses.modal.create_manual_expense.refundable[
                currentLangKey
              ]
            }
            name='refundable'
            // campo desabilitado caso empresa utilize conciliação na fatura
            disabled={
              expenseParameters?.usesInvoiceReconciliation ||
              fieldRefundableDisabled
            }
          />

          <TextArea.Controlled
            small
            name='observation'
            className='textarea-notes'
            // se parâmetro permite anexo da observação, mostra o ícone e click
            {...(showObservationsAttachmentsIcon && {
              rightBottomIcon: 'PaperClipAttachIcon',
              onClickRightBottomIcon: () => {
                inputRef.current?.click();
              }
            })}
            label={observationFormLabel}
            textareaProps={{
              placeholder: observationFormLabel
            }}
          />

          {
            // parâmetro de anexo da observação
          }
          <ObservationAttachments inputRef={inputRef} />

          {apportionmentFields.length === 0 && (
            <SecondaryButton
              size='small'
              onClick={handleClickSelectProjectButton}
              className='select-project-button'
              type='button'
            >
              <DSIcons.AddCircleIcon />
              {t('modal.selectProject', {
                projectLabel: projectFormLabel
              })}
            </SecondaryButton>
          )}
        </LeftContent>
        <RightContent>
          <DragAndDropFile.Controlled
            showZoomTour
            langKey={currentLangKey}
            inputId='receipt'
            name='receipt'
            className='drag-and-drop-file-container'
          />
        </RightContent>
      </FormContent>

      {apportionmentFields.length > 0 && (
        <ApportionmentBlockForm
          onHideApportionmentBlock={() => {
            apportionmentReplace([]);
          }}
          expenseUuidToUpdate={expenseUuidToUpdate}
        />
      )}
    </>
  );
}
