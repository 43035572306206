import {
  type ICreateManualExpensePayload,
  type IPersistenceCreateManualExpensePayload
} from 'data/modules/expenses';

import { Currency } from 'shared/utils/format';

class CreateManualExpenseMapper {
  toPersistence(
    payload: ICreateManualExpensePayload
  ): IPersistenceCreateManualExpensePayload {
    return {
      date: payload.date,
      title: payload.description,
      is_reimbursable: payload.refundable
        ? `${payload.refundable}`
        : payload.refundable,
      receipt: payload.receipt,
      report_uuid: payload.report,
      observations: payload.observation,
      attachments:
        payload.observationAttachments &&
        payload.observationAttachments.length > 0
          ? payload.observationAttachments.map(item => {
              return item instanceof File ? item : item.url;
            })
          : undefined,
      apportionments:
        payload.apportionment?.map(item => {
          return {
            project_id: parseInt(item.project),
            percentage: item.percentage,
            amount: item.value
          };
        }) ?? [],
      amount: Currency.parseToFloat(payload.value),
      exchange_rate: payload.exchangeRate
        ? Currency.parseToFloat(payload.exchangeRate, 5)
        : undefined,
      converted_amount: payload.convertedValue
        ? Currency.parseToFloat(payload.convertedValue)
        : undefined,
      currency_id: parseInt(payload.currency),
      cost_center_id: payload.costsCenter,
      expense_type_id: parseInt(payload.type),
      payment_method_id: payload.paymentMethod ?? undefined,
      validated: payload.validated
    };
  }
}

export default new CreateManualExpenseMapper();
