import { useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useModalContext } from 'data/contexts';
import { AccountQueryKeys } from 'data/modules/cards/account';
import {
  CardGroupsQueryKeys,
  useAddManagersToGroup,
  useInfiniteAvailableToBeManager
} from 'data/modules/cards/cardGroups';

import { useDebounce, useInfiniteScroll } from 'shared/hooks/global';
import { ApiMessage } from 'shared/utils/global';

import { type IUseAddManagerToGroupModal } from './AddManagerToGroupModal.types';

export function useAddManagerToGroupModal(): IUseAddManagerToGroupModal {
  const { handleOpenModal } = useModalContext();
  const queryClient = useQueryClient();

  const infiniteScrollRef = useRef<null | HTMLDivElement>(null);

  const rootRef = useRef<null | HTMLDivElement>(null);

  const [
    paginationManagers,
    selectedAvailableToBeManager,
    selectedCardGroup,
    filters,
    ordenationType,
    pagination,
    handleResetSelectedAvailableToBeManager,
    { managersPagination }
  ] = useCardGroupsContext(
    useShallow(state => [
      state.paginationManagers,
      state.selectedAvailableToBeManager,
      state.selectedCardGroup,
      state.filters,
      state.ordenationType,
      state.pagination,
      state.handleResetSelectedAvailableToBeManager,
      state.paginationModalDetails,
      state.paginationModalDetails
    ])
  );

  const debouncedSearchManager = useDebounce(paginationManagers.userName, 650);

  const { addManagersToGroup, isAddingManagersToGroup } =
    useAddManagersToGroup();

  const {
    infiniteAvailableToBeManager,
    isLoadingInfiniteAvailableToBeManager,
    availableToBeManagerPagination
  } = useInfiniteAvailableToBeManager({
    params: {
      page: paginationManagers.page,
      limit: paginationManagers.limit,
      ...(debouncedSearchManager && {
        userName: debouncedSearchManager?.toLowerCase().trim()
      }),
      cardGroupId: selectedCardGroup?.id
    }
  });

  useInfiniteScroll({
    hasNextPage: availableToBeManagerPagination.hasNextPage,
    isFetchingNextPage: availableToBeManagerPagination.isFetchingNextPage,
    actionFn: availableToBeManagerPagination.fetchNextPage,
    infiniteScrollRef: infiniteScrollRef.current,
    rootRef: rootRef.current,
    rootMargin: '20%'
  });

  async function handleAddManagersToGroup(): Promise<void> {
    try {
      await addManagersToGroup({
        id: selectedCardGroup?.id as string,
        managers: selectedAvailableToBeManager
      });

      handleResetSelectedAvailableToBeManager();
      handleOpenModal('groupCardDetails');
      queryClient.invalidateQueries({
        queryKey: [
          CardGroupsQueryKeys.GET_GROUP_MANAGERS,
          { page: managersPagination.page, limit: managersPagination.limit }
        ]
      });

      queryClient.resetQueries({
        queryKey: [CardGroupsQueryKeys.GET_AVAILABLE_TO_BE_MANAGER]
      });

      queryClient.resetQueries({
        queryKey: [AccountQueryKeys.GET_ACCOUNTS]
      });

      queryClient.resetQueries({
        queryKey: [CardGroupsQueryKeys.GET_PARTIAL_MANAGERS]
      });

      if (
        filters.managers?.some(element =>
          selectedAvailableToBeManager.includes(element)
        )
      ) {
        queryClient.invalidateQueries({
          queryKey: [
            CardGroupsQueryKeys.GET_CARD_GROUPS,
            {
              page: pagination.page,
              limit: pagination.limit,
              ...(filters?.name && {
                name: filters?.name.trim().toLowerCase()
              }),
              ...(filters?.managers && { managers: filters.managers }),
              ...(ordenationType && { ordenationType })
            }
          ]
        });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(ApiMessage.error(error.response?.data.errors?.[0].code));

        return;
      }

      toast.error(ApiMessage.error('default'));
    }
  }

  return {
    paginatedAvailableToBeManager: infiniteAvailableToBeManager,
    isFetchingAvailableToBeManager: isLoadingInfiniteAvailableToBeManager,
    isAddingManagersToGroup,
    handleAddManagersToGroup,
    infiniteScrollRef,
    rootRef,
    availableToBeManagerPagination
  };
}
