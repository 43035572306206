import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { alertProps } from './constants/alertProps.constant';

import { type AlertStylesType } from './Alert.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<AddPrefixToType<AlertStylesType>>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${({ $type }) => alertProps[$type].background};
  border-radius: ${tokens.borderRadiusXs};
  padding: ${tokens.spacingXxs};
  padding-left: ${tokens.spacingXs};
  gap: ${tokens.spacingInsetXxs};

  div,
  h4,
  button {
    color: ${({ $type }) => alertProps[$type].font} !important;
    border-color: ${({ $type }) => alertProps[$type].font} !important;
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  #close {
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    * {
      fill: ${({ $type }) => alertProps[$type].close};
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacingInsetQuarck};
  }

  ${({ $outlined, $type }) =>
    $outlined &&
    css`
      border: 1px solid ${alertProps[$type].border};
    `}

  ${({ $title }) =>
    $title &&
    css`
      p {
        margin-left: 3.5rem;
      }
    `}
`;

export const Text = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  font-family: ${tokens.fontFamilyPoppins}, sans-serif;
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};
  column-gap: ${tokens.spacingInsetXxs};
`;

export const Title = styled.h4`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  font-family: ${tokens.fontFamilyPoppins}, sans-serif;
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightSemibold};
  gap: ${tokens.spacingInsetXxs};

  > svg {
    flex-shrink: 0;
  }
`;
