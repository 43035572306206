import { useQueryCache } from 'data/cache';
import {
  type IUseGetTeamMembersResume,
  TeamMembersQueryKeys,
  TeamMembersService
} from 'data/modules/teamMembers';

export function useGetTeamMembersResume(): IUseGetTeamMembersResume {
  const { data, isLoading } = useQueryCache({
    queryKey: [TeamMembersQueryKeys.GET_TEAM_MEMBERS_RESUME],
    queryFn: async () => await TeamMembersService.getTeamMembersResume()
  });

  return {
    teamMembersResume: data,
    isLoadingTeamMembersResume: isLoading
  };
}
