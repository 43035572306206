import { type MouseEvent } from 'react';

import { ImageFullScreen } from 'ds';
import { DefaultButton, SecondaryButton } from 'ds/components/Buttons';
import { zIndex } from 'ds/constants';
import { DSIcons } from 'ds/icons';
import dragAndDropErrorImg from 'ds/images/drag-and-drop-error.png';
import dragAndDropSelectImg from 'ds/images/drag-and-drop-select.png';

import { useDragAndDropFileDefault } from './useDragAndDropFileDefault';

import { type IDragAndDropFileDefaultProps } from './DragAndDropFileDefault.types';
import { type LanguageType } from 'ds/types';

import {
  Container,
  FileActions,
  FilenameText,
  ImageFile,
  IncompatibleFileMessage,
  InputFile,
  LabelFile,
  Overlay,
  StyledIconFile,
  Text,
  Title
} from './DragAndDropFileDefault.styles';

export function DragAndDropFileDefault({
  langKey = 'pt',
  acceptedFileTypes = ['pdf', 'jpeg', 'png', 'jpg'],
  inputId,
  selectedFile,
  onChangeFile,
  hasZoomControl,
  showZoomTour
}: IDragAndDropFileDefaultProps): JSX.Element {
  const {
    dragDocumentActive,
    dragInsideElementActive,
    handleChangeFile,
    handleDragInsideElement,
    handleDropFile,
    selectedFileIsImage,
    isImageEnlarged,
    blobImageFile,
    uploadError,
    setIsImageEnlarged,
    handleDeleteFile,
    handleOpenFileOrEnlargeImage,
    inputRef,
    sizeError
  } = useDragAndDropFileDefault({
    acceptedFileTypes,
    onChangeFile,
    selectedFile
  });

  const selectMessage: Record<LanguageType, string> = {
    pt: 'Selecione um comprovante',
    en: 'Select a proof',
    es: 'Seleccione un comprobante'
  };

  const acceptedFileTypesMessageTemplate = acceptedFileTypes
    .join(', ')
    .replace(/,(?=[^,]*$)/, ' {{or}}');

  const acceptedFileTypesMessage: Record<LanguageType, string> = {
    pt: acceptedFileTypesMessageTemplate.replace('{{or}}', 'ou'),
    en: acceptedFileTypesMessageTemplate.replace('{{or}}', 'or'),
    es: acceptedFileTypesMessageTemplate.replace('{{or}}', 'o')
  };

  const dragWithFileTypesMessage: Record<LanguageType, string> = {
    pt: `Clique para selecionar um arquivo ou arraste aqui (apenas ${acceptedFileTypesMessage.pt})`,
    en: `Click to select a file or drag here (only ${acceptedFileTypesMessage.en})`,
    es: `Haga clic para seleccionar un archivo o arrastre aquí (solo ${acceptedFileTypesMessage.es})`
  };

  const dragWithNoFileTypesMessage: Record<LanguageType, string> = {
    pt: `Clique para selecionar um arquivo ou arraste aqui`,
    en: `Click to select a file or drag here`,
    es: `Haga clic para seleccionar un archivo o arrastre aquí`
  };

  const dropFileMessage: Record<LanguageType, string> = {
    pt: 'Solte o arquivo aqui',
    en: 'Drop the file here',
    es: 'Suelta el archivo aquí'
  };

  const uploadErrorMessage: Record<LanguageType, string> = {
    pt: 'Falha no envio',
    en: 'Upload failed',
    es: 'Error al cargar'
  };

  const incompatibleFileMessage: Record<LanguageType, string> = {
    pt: 'Arquivo incompatível.',
    en: 'Incompatible file.',
    es: 'Archivo incompatible.'
  };

  const selectAnotherFileMessage: Record<LanguageType, string> = {
    pt: `Selecione um arquivo ${acceptedFileTypesMessage.pt}.`,
    en: `Select a ${acceptedFileTypesMessage.en} file.`,
    es: `Seleccione un archivo ${acceptedFileTypesMessage.es}.`
  };

  const enlargeLabel: Record<LanguageType, string> = {
    pt: 'Ampliar',
    en: 'Enlarge',
    es: 'Ampliar'
  };

  const openFileLabel: Record<LanguageType, string> = {
    pt: 'Abrir arquivo',
    en: 'Open file',
    es: 'Abrir archivo'
  };

  const deleteLabel: Record<LanguageType, string> = {
    pt: 'Excluir',
    en: 'Delete',
    es: 'Eliminar'
  };

  const replaceFileLabel: Record<LanguageType, string> = {
    pt: 'Substituir arquivo',
    en: 'Replace file',
    es: 'Reemplazar archivo'
  };

  const sizeErrorMessage: Record<LanguageType, string> = {
    pt: 'O arquivo deve ter menos que 16MB de tamanho. Tente novamente com outro arquivo.',
    en: 'The file must be less than 16MB in size. Try again with another file.',
    es: 'El archivo debe tener un tamaño inferior a 16 MB. Inténtalo de nuevo con otro archivo.'
  };

  const sizeSubErrorMessage: Record<LanguageType, string> = {
    pt: 'Tamanho de arquivo superior ao permitido.',
    es: 'Tamaño de archivo mayor al permitido.',
    en: 'File size larger than allowed.'
  };

  const errorMessage =
    !uploadError && sizeError
      ? sizeErrorMessage[langKey]
      : uploadErrorMessage[langKey];

  return (
    <Container>
      <InputFile
        type='file'
        id={inputId}
        onChange={handleChangeFile}
        ref={inputRef}
      />
      <LabelFile
        htmlFor={inputId}
        onClick={e => {
          if (selectedFile) {
            e.preventDefault();
          }
        }}
        onDragEnter={handleDragInsideElement}
        onDragLeave={handleDragInsideElement}
        onDragOver={handleDragInsideElement}
        onDrop={handleDropFile}
        $selectedFile={!!selectedFile}
        $selectedFileIsImage={selectedFileIsImage}
        $elementDragActive={dragInsideElementActive}
        $documentDragActive={dragDocumentActive}
      >
        {blobImageFile && selectedFileIsImage && (
          <ImageFile
            src={blobImageFile as string}
            alt='Imagem Alternativa'
          />
        )}

        <Overlay $highZIndex={dragInsideElementActive || dragDocumentActive} />

        {!!selectedFile && (
          <FileActions>
            <DefaultButton
              size='small'
              aria-label={
                selectedFileIsImage
                  ? enlargeLabel[langKey]
                  : openFileLabel[langKey]
              }
              tabIndex={1}
              onClick={handleOpenFileOrEnlargeImage}
            >
              <DSIcons.SearchZoomPlusIcon />
              {selectedFileIsImage
                ? enlargeLabel[langKey]
                : openFileLabel[langKey]}
            </DefaultButton>
            <SecondaryButton
              size='small'
              aria-label={deleteLabel[langKey]}
              onClick={handleDeleteFile}
              tabIndex={2}
            >
              <DSIcons.TrashDeleteIcon />
              {deleteLabel[langKey]}
            </SecondaryButton>

            <SecondaryButton
              size='small'
              onClick={() => {
                inputRef.current?.click();
              }}
              onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
              }}
              aria-label={replaceFileLabel[langKey]}
              tabIndex={3}
            >
              <DSIcons.FileBlankExchangeCrossDeleteIcon />
              {replaceFileLabel[langKey]}
            </SecondaryButton>
          </FileActions>
        )}

        {!(dragDocumentActive || dragInsideElementActive) && !selectedFile && (
          <img
            src={
              !uploadError || !sizeError
                ? dragAndDropSelectImg
                : dragAndDropErrorImg
            }
          />
        )}

        <Title
          $error={uploadError || sizeError}
          $elementDragActive={dragInsideElementActive}
          $documentDragActive={dragDocumentActive}
          aria-controls='filename'
          tabIndex={0}
          onKeyUp={e => {
            if (e.key === ' ' || e.key === 'Enter') {
              e.preventDefault();
              inputRef.current?.click();
            }
          }}
        >
          {dragInsideElementActive || dragDocumentActive
            ? dropFileMessage[langKey]
            : uploadError || sizeError
              ? errorMessage
              : selectedFile
                ? ''
                : selectMessage[langKey]}
        </Title>

        {!(dragDocumentActive || dragInsideElementActive) && uploadError && (
          <IncompatibleFileMessage>
            {incompatibleFileMessage[langKey]} <br />
            {selectAnotherFileMessage[langKey]}
          </IncompatibleFileMessage>
        )}

        {!(dragDocumentActive || dragInsideElementActive) && sizeError && (
          <IncompatibleFileMessage>
            {sizeSubErrorMessage[langKey]}
          </IncompatibleFileMessage>
        )}

        {!(dragDocumentActive || dragInsideElementActive) && !selectedFile && (
          <Text>
            {uploadError
              ? dragWithNoFileTypesMessage[langKey]
              : dragWithFileTypesMessage[langKey]}
          </Text>
        )}

        {!(dragDocumentActive || dragInsideElementActive) &&
          selectedFile &&
          !selectedFileIsImage && (
            <>
              <StyledIconFile />
              <FilenameText>{selectedFile.name}</FilenameText>
            </>
          )}
      </LabelFile>

      {selectedFile && selectedFileIsImage && isImageEnlarged && (
        <ImageFullScreen
          showZoomTour={showZoomTour}
          hasZoomControl={hasZoomControl}
          style={{
            zIndex: zIndex.imageViewer
          }}
          overlayStyle={{
            backgroundColor: 'rgba(0,0,0,0.75)'
          }}
          onClose={() => {
            setIsImageEnlarged(false);
          }}
          closeOnClickOutside
          url={blobImageFile as string}
        />
      )}
    </Container>
  );
}
