import { useLangContext } from 'data/contexts';
import { useChangeBusinessCardProjects } from 'data/modules/cards/businessCard';

import { Select } from 'presentation/components/base/Select';
import { Loader } from 'presentation/components/global/Loader';
import { Title } from 'presentation/components/global/Title';
import { ListItems } from 'presentation/pages/settings/Cards/ManageBusinessCard/components/List';

import { useProjectsManageBusinessCard } from './useProjectsManageBusinessCard';

import { type IProjectsManageBusinessCardProps } from './useProjectsManageBusinessCard.types';

import {
  Container,
  CustomButton,
  FormInputs,
  SelectProjectContainer
} from './ProjectsManageBusinessCard.styles';

export function ProjectsManageBusinessCard({
  card
}: IProjectsManageBusinessCardProps): JSX.Element {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const {
    control,
    errors,
    handleSubmit,
    isAttachingProjects,
    isRemovingRelatedProject,
    handleRemoveProject
  } = useChangeBusinessCardProjects({ uuid: card.uuid });

  const { isLoadingProjects, projectsOptions, relatedProjects } =
    useProjectsManageBusinessCard({
      uuid: card.uuid
    });

  return (
    <Container>
      {(isRemovingRelatedProject ||
        isAttachingProjects ||
        isLoadingProjects) && <Loader isLoading />}
      <SelectProjectContainer>
        <Title
          text={`${lang.settings.manageBusinessCard.relate_projects_with_card[currentLangKey]} ${card.name}`}
          color='white'
          backgroundColor='primary'
        />
        <FormInputs>
          <Select
            isMulti
            name='projects'
            options={projectsOptions}
            label={
              lang.settings.manageBusinessCard.relate_projects[currentLangKey]
            }
            placeholder={
              lang.settings.manageBusinessCard.search_projects[currentLangKey]
            }
            control={control}
            error={errors.projects?.message}
          />

          <CustomButton
            color='success'
            onClick={handleSubmit}
          >
            {lang.global.save[currentLangKey]}
          </CustomButton>
        </FormInputs>
      </SelectProjectContainer>

      <SelectProjectContainer>
        <Title
          text={`${lang.settings.manageBusinessCard.card_related_projects[currentLangKey]} ${card.name}`}
          color='white'
          backgroundColor='primary'
        />

        <ListItems
          items={relatedProjects}
          onRemoveItem={id => {
            handleRemoveProject({ id: Number(id) });
          }}
          infoMessage={
            lang.settings.manageBusinessCard.no_related_project[currentLangKey]
          }
          titleModal={
            lang.settings.manageBusinessCard.remove_relationship[currentLangKey]
          }
          isLoading={isRemovingRelatedProject || isAttachingProjects}
        />
      </SelectProjectContainer>
    </Container>
  );
}
