import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type TextAreaStylesType } from './TextArea.types';

export const Container = styled.div<AddPrefixToType<TextAreaStylesType>>`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid
    ${({ theme, $error }) =>
      $error ? theme.colors.danger : theme.colors.gallery};
  width: 100%;
  height: 5rem;
  padding: 0 1.2rem;
  transition: box-shadow 0.3s ease-in;

  ${({ $error, theme }) =>
    !$error &&
    css`
      &:focus-within {
        box-shadow: 0 0 2px 1px ${theme.colors.primary};
      }
    `}

  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      background-color: ${theme.colors.alabaster};
    `}
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  border: none;
  resize: none;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.mineShaft};
  background-color: transparent;

  &::placeholder {
    color: ${({ theme }) => theme.colors.dustyGray};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
