import { Outlet, useLocation } from 'react-router-dom';

import { useFlagsContext } from 'data/contexts';

import { Suggestion } from 'presentation/pages/analytics';
import { FallbackPage } from 'presentation/pages/global';

import { routesPathPrefix } from 'shared/constants/global';
import { CustomCookies } from 'shared/utils/global';

export function AnalyticsMiddleware(): JSX.Element {
  const { analyticsPageFlag } = useFlagsContext();
  const { pathname } = useLocation();

  if (!analyticsPageFlag) {
    if (pathname === routesPathPrefix.analytics) return <Suggestion />;

    return <FallbackPage />;
  }

  /** Colapsa sidebar automaticamente, ao acessar alguma rota de Análises */
  CustomCookies.set('sidebar', 'collapsed', {
    daysToExpire: 2
  });

  return <Outlet />;
}
