import { DefaultTable } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import {
  type ICardGroupsOrdenation,
  useCardGroupsContext
} from 'data/contexts';

import { type IGroupManagementTableHeadProps } from './GroupManagementTableHead.types';

export function GroupManagementTableHead({
  disabledFilters,
  ...rest
}: IGroupManagementTableHeadProps): JSX.Element {
  const { t } = useTranslation(['cards', 'global']);

  const [order, handleChangeFiltersGroupManagement] = useCardGroupsContext(
    useShallow(state => [
      state.filtersGroupManagement.order,
      state.handleChangeFiltersGroupManagement
    ])
  );

  return (
    <DefaultTable.Thead {...rest}>
      <DefaultTable.Tr>
        <DefaultTable.Th
          width='23%'
          order={
            order.toLocaleLowerCase() as Lowercase<
              ICardGroupsOrdenation['type']
            >
          }
          isActive={
            disabledFilters ? false : order === 'ASC' || order === 'DESC'
          }
          onClick={() => {
            !disabledFilters &&
              handleChangeFiltersGroupManagement({
                order: order === 'ASC' ? 'DESC' : 'ASC'
              });
          }}
        >
          {t('cards:cardName')}
        </DefaultTable.Th>

        <DefaultTable.Th width='18%'>{t('cards:cardType')}</DefaultTable.Th>

        <DefaultTable.Th width='18%'>{t('cards:card')}</DefaultTable.Th>

        <DefaultTable.Th width='18%'>
          {t('cards:valueAvailable')}
        </DefaultTable.Th>

        <DefaultTable.Th width='18%'>{t('global:status')}</DefaultTable.Th>

        <DefaultTable.Th width='18%'>{t('cards:details')}</DefaultTable.Th>
      </DefaultTable.Tr>
    </DefaultTable.Thead>
  );
}
