import { DefaultButton, SecondaryButton, TextButton, TextInput } from 'ds';
import { DatePicker } from 'ds/components/Input/DatePicker';
import { useShallow } from 'zustand/react/shallow';

import { useLangContext, useValueSolicitationContext } from 'data/contexts';

import { useValueSolicitationFilters } from './useValueSolicitationFilters';

import { type IValueSolicitationFiltersProps } from './ValueSolicitationFilters.types';

import {
  ButtonsContainer,
  Container,
  SearchbarContainer
} from './ValueSolicitationFilters.styles';

export function ValueSolicitationFilters({
  ...rest
}: IValueSolicitationFiltersProps): JSX.Element {
  const {
    lang: { cards },
    currentLangKey
  } = useLangContext();

  const [
    listType,
    searchInput,
    selectedSolicitationIdsList,
    handleChangeSearch,
    startDate,
    endDate,
    handleChangeDate
  ] = useValueSolicitationContext(
    useShallow(state => [
      state.listType,
      state.searchInput,
      state.selectedSolicitationIdsList,
      state.handleChangeSearch,
      state.startDate,
      state.endDate,
      state.handleChangeDate
    ])
  );

  const { handleConfirmValueSolicitation } = useValueSolicitationFilters();

  return (
    <Container {...rest}>
      <SearchbarContainer>
        <TextInput.Default
          label={cards.valueSolicitation.filters.search_request[currentLangKey]}
          placeholder={
            cards.valueSolicitation.filters.search_request[currentLangKey]
          }
          value={searchInput}
          onChangeValue={handleChangeSearch}
          onClearValue={() => {
            handleChangeSearch('');
          }}
          type='text'
          iconLeft='SearchIcon'
          small
        />

        {listType !== 'REJECTED' && (
          <>
            <DatePicker.Default
              label={cards.valueSolicitation.filters.in[currentLangKey]}
              placeholder={cards.valueSolicitation.filters.in[currentLangKey]}
              currentLangKey={currentLangKey}
              value={startDate}
              onChangeValue={value => {
                handleChangeDate('start', value as string);
              }}
              className='date-picker-solicitation'
              variant='small'
            />

            <DatePicker.Default
              label={cards.valueSolicitation.filters.until[currentLangKey]}
              placeholder={
                cards.valueSolicitation.filters.until[currentLangKey]
              }
              currentLangKey={currentLangKey}
              value={endDate}
              onChangeValue={value => {
                handleChangeDate('end', value as string);
              }}
              className='date-picker-solicitation'
              variant='small'
            />
          </>
        )}
      </SearchbarContainer>

      {(listType === 'ALL' || listType === 'SENT') && (
        <ButtonsContainer>
          <TextButton
            disabled={selectedSolicitationIdsList.length === 0}
            onClick={() => {
              handleConfirmValueSolicitation('transfer');
            }}
            className='value-solicitation-transfer-another-value-button'
            size='small'
          >
            {
              cards.valueSolicitation.filters.transfer_another_amount[
                currentLangKey
              ]
            }
          </TextButton>

          <SecondaryButton
            disabled={selectedSolicitationIdsList.length === 0}
            onClick={() => {
              handleConfirmValueSolicitation('disapprove');
            }}
            className='value-solicitation-disapprove-button'
            size='small'
          >
            {
              cards.valueSolicitation.filters.dissaprove_selected[
                currentLangKey
              ]
            }
          </SecondaryButton>

          <DefaultButton
            disabled={selectedSolicitationIdsList.length === 0}
            onClick={() => {
              handleConfirmValueSolicitation('approve');
            }}
            className='value-solicitation-approve-button'
            size='small'
          >
            {cards.valueSolicitation.filters.approve_selected[currentLangKey]}
          </DefaultButton>
        </ButtonsContainer>
      )}
    </Container>
  );
}
