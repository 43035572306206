import { useEffect, useState } from 'react';

import { useGetPixQrCode } from 'data/modules/cards/balance';

import { type IUseDepositModal } from './DepositModal.types';

export function useDepositModal(): IUseDepositModal {
  const [hasQrCode, setHasQrCode] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [enabledRequest, setEnabledRequest] = useState(false);
  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const [clickedOnce, setClickedOnce] = useState(false);

  const { data, isLoading, isFetchingPixQrCode, isError, mutate } =
    useGetPixQrCode({
      enabled: enabledRequest
    });

  useEffect(() => {
    if (isError) {
      mutate(null);
    }
  }, [isError, mutate]);

  useEffect(() => {
    if (isFakeLoading) {
      const fakeLoading = setTimeout(() => {
        setIsFakeLoading(false);
        setClickedOnce(true);
      }, 1000);

      return () => {
        clearTimeout(fakeLoading);
      };
    }
  }, [isFakeLoading]);

  function onClick(): void {
    if (!data) {
      setEnabledRequest(true);
      setHasQrCode(true);
      setClickedOnce(true);

      return;
    }

    if (data && !clickedOnce) setIsFakeLoading(true);

    handleCopyToClipboard();
  }

  function handleCopyToClipboard(): void {
    if (data?.emv && hasQrCode) {
      void navigator.clipboard.writeText(data.emv);

      setIsCopied(true);
    }

    setHasQrCode(true);
  }

  return {
    hasQrCode,
    onClick,
    isLoading: isLoading || isFakeLoading,
    isCopied,
    setIsCopied,
    setHasQrCode,
    setClickedOnce,
    isFetchingPixQrCode: isFetchingPixQrCode || isFakeLoading,
    data
  };
}
