import { useQueryCache } from 'data/cache';
import {
  CostCentersQueryKeys,
  CostCentersService,
  type IUseGetPaginatedCostsCenters,
  type IUseGetPaginatedCostsCentersParams
} from 'data/modules/costsCenters';

export function useGetPaginatedCostsCenters(
  params: IUseGetPaginatedCostsCentersParams
): IUseGetPaginatedCostsCenters {
  const {
    data: paginatedCostsCenters,
    isLoading: isLoadingPaginatedCostsCenters,
    isFetching: isFetchingPaginatedCostsCenters
  } = useQueryCache({
    queryKey: [CostCentersQueryKeys.GET_PAGINATED_COST_CENTERS, params],
    queryFn: async () =>
      await CostCentersService.getPaginatedCostsCenters(params)
  });

  return {
    paginatedCostsCenters,
    isLoadingPaginatedCostsCenters,
    isFetchingPaginatedCostsCenters
  };
}
