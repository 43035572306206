import { useEffect } from 'react';

import { driver } from 'driver.js';
import { useShallow } from 'zustand/react/shallow';

import { driverJsConfig } from 'data/config';
import { useLangContext, useTourContext } from 'data/contexts';

import { localStorageKeys } from 'shared/constants/global';

import { type IUseChargeCardsTour } from './useChargeCardsTour.types';

export function useChargeCardsTour({ enabled }: IUseChargeCardsTour): void {
  const [reset, handleReset] = useTourContext(
    useShallow(state => [state.reset, state.handleReset])
  );

  const {
    currentLangKey,
    lang: {
      cards: { tours },
      global
    }
  } = useLangContext();

  const chargeCardsTour = driver({
    ...driverJsConfig,
    nextBtnText: global.next[currentLangKey],
    prevBtnText: global.previous[currentLangKey],
    doneBtnText: global.finalize[currentLangKey],
    progressText: `{{current}} ${global.of[currentLangKey]} {{total}}`,
    onDestroyed: () => {
      chargeCardsTour.destroy();
      localStorage.setItem(localStorageKeys.chargeCardsTour, 'true');
      handleReset('');
    },
    steps: [
      {
        element: '.guided-tour',
        popover: {
          title: tours.chargeCardsTour.first_step.title[currentLangKey],
          description:
            tours.chargeCardsTour.first_step.description[currentLangKey]
        }
      },
      {
        element: '.charge-card-cards-breadcrumb',
        popover: {
          title: tours.chargeCardsTour.second_step.title[currentLangKey],
          description:
            tours.chargeCardsTour.second_step.description[currentLangKey]
        }
      },
      {
        element: '.charge-card-add-value-radio',
        popover: {
          title: tours.chargeCardsTour.third_step.title[currentLangKey],
          description:
            tours.chargeCardsTour.third_step.description[currentLangKey]
        }
      },
      {
        element: '.charge-card-remove-value-radio',
        popover: {
          title: tours.chargeCardsTour.fourth_step.title[currentLangKey],
          description:
            tours.chargeCardsTour.fourth_step.description[currentLangKey]
        }
      },
      {
        element: '.charge-card-movement-input',
        popover: {
          title: tours.chargeCardsTour.fifth_step.title[currentLangKey],
          description:
            tours.chargeCardsTour.fifth_step.description[currentLangKey],
          side: 'top'
        }
      },
      {
        element: '.charge-card-change-final-value',
        popover: {
          title: tours.chargeCardsTour.sixth_step.title[currentLangKey],
          description:
            tours.chargeCardsTour.sixth_step.description[currentLangKey]
        }
      },
      {
        element: '.charge-cards-card-details',
        popover: {
          title: tours.chargeCardsTour.seventh_step.title[currentLangKey],
          description:
            tours.chargeCardsTour.seventh_step.description[currentLangKey],
          side: 'top',
          align: 'end'
        }
      },
      {
        element: '.charge-cards-continue',
        popover: {
          title: tours.chargeCardsTour.eighth_step.title[currentLangKey],
          description:
            tours.chargeCardsTour.eighth_step.description[currentLangKey],
          side: 'top',
          align: 'end'
        }
      }
    ]
  });

  useEffect(() => {
    if (enabled || reset === localStorageKeys.chargeCardsTour) {
      chargeCardsTour.drive();
    }
  }, [chargeCardsTour, enabled, reset]);
}
