import { tokens } from 'ds';
import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  padding: ${tokens.spacingMd};
  background: ${tokens.neutralColorHighPure};
  border-radius: ${tokens.borderRadiusSm};
  border: ${tokens.borderWidthHairline} solid ${tokens.neutralColorHighDark};
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${tokens.spacingNano};
  align-self: stretch;
  justify-content: center;
  align-items: start;

  .react-select-custom__control,
  .react-select-custom__menu {
    max-width: unset;
    min-width: unset;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  gap: ${tokens.spacingLg};
  align-self: stretch;

  > div {
    max-width: unset;
    flex-grow: 1;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    gap: ${tokens.spacingXs};
  }
`;
