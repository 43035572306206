import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type AlertStylesType } from './Alert.types';

export const Container = styled.div<AddPrefixToType<AlertStylesType>>`
  display: flex;
  place-content: center center;
  align-items: center;
  gap: 1rem;
  padding: 0.6rem;
  border-radius: 5px;
  color: ${({ $fontColor, theme }) => theme.colors[$fontColor]};
  background-color: ${({ $backgroundColor, theme }) =>
    theme.colors[$backgroundColor]};
  font-size: ${({ $fontSize }) => $fontSize}rem;
  font-weight: ${({ $fontWeight }) => $fontWeight};
`;
