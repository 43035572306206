import { Outlet } from 'react-router-dom';

import { useGetAuthUser } from 'data/modules/auth';

import { SplashScreen } from 'presentation/components/global/SplashScreen';
import { FallbackPage } from 'presentation/pages/global';

export function FuelMiddleware(): JSX.Element {
  const { company, isFetchingAuthUser } = useGetAuthUser();

  if (isFetchingAuthUser) {
    return <SplashScreen />;
  }

  if (!company?.parameters.usesFuelsModule) {
    return <FallbackPage error='401' />;
  }

  return <Outlet />;
}
