import { useIsFetching } from '@tanstack/react-query';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useApportionmentContext, useModalContext } from 'data/contexts';
import { useGetCurrencies } from 'data/modules/currencies';
import {
  ExpensesQueryKeys,
  type ICreateManualExpenseFirstStepFieldsForm,
  type ICreateRouteByMapDefaultFieldsForm,
  type ICreateRouteExpenseFirstStepFieldsForm,
  type IValidateDateLimitsByProjectPayload,
  useGetExpenseParameters,
  useValidateDateLimitsByProject,
  useValidateMileagePolicyByProject
} from 'data/modules/expenses';
import { type IProject } from 'data/modules/projects';

import { Currency } from 'shared/utils/format';
import { Validate } from 'shared/utils/validation';

import {
  type IUseApportionmentBlockItem,
  type IUseApportionmentBlockItemParams
} from './ApportionmentBlockItem.types';

export function useApportionmentBlockItem({
  fieldArrayIndex,
  expenseUuidToUpdate
}: IUseApportionmentBlockItemParams): IUseApportionmentBlockItem {
  const { visibleModal } = useModalContext();

  const { expenseParameters } = useGetExpenseParameters();

  const { currencies } = useGetCurrencies();

  const { getEnsuredValidateDateLimitsByProject } =
    useValidateDateLimitsByProject();

  const { control, setValue, getValues, setError, clearErrors } =
    useFormContext<
      | ICreateManualExpenseFirstStepFieldsForm
      | ICreateRouteExpenseFirstStepFieldsForm
      | ICreateRouteByMapDefaultFieldsForm
    >();

  const { t } = useTranslation('expenses');

  const expenseValue = useWatch({
    control,
    name: 'value'
  });

  const expenseValueAsNumber = Currency.parseToFloat(expenseValue ?? '');

  const selectedCurrencyId = useWatch({
    control,
    name: 'currency'
  });

  const currencySymbol = selectedCurrencyId
    ? currencies?.find(currency => String(currency.id) === selectedCurrencyId)
        ?.symbol ?? 'R$'
    : 'R$';

  const watchedApportionmentArrayFields = useWatch({
    control,
    name: 'apportionment'
  });

  const projectIdsToNotShowAsOptions = watchedApportionmentArrayFields
    ? (watchedApportionmentArrayFields
        ?.filter((item, index) => index !== fieldArrayIndex)
        .map(item => item.project?.value)
        .filter(item => item !== undefined) as string[])
    : [];

  const {
    handleChangeDisabledKilometerInput,
    handleChangeRequiredReceiptInManualRoute
  } = useApportionmentContext();

  const isValidatingDateLimitsByProject =
    useIsFetching({
      predicate: query => {
        return (
          query.queryKey[0] ===
            ExpensesQueryKeys.VALIDATE_DATE_LIMITS_BY_PROJECT &&
          query.state.status === 'pending'
        );
      }
    }) > 0;

  const { getEnsuredValidateMileagePolicyByProject } =
    useValidateMileagePolicyByProject();

  async function checkIfDateIsValidByProjectPolicy(): Promise<void> {
    if (expenseParameters?.policyType !== 'PROJETO') {
      return;
    }

    const date = getValues('date');

    if (date === undefined || !Validate.isDate(date, 'yyyy-mm-dd')) {
      clearErrors('date');
      return;
    }

    const apportionment = getValues('apportionment');

    const filteredApportionment = apportionment
      ? apportionment.filter(item => item.project !== undefined)
      : [];

    if (filteredApportionment.length === 0) {
      clearErrors('date');
      return;
    }

    const policyByProjectQueryPayload: IValidateDateLimitsByProjectPayload = {
      date,
      projects: filteredApportionment.map(item => {
        return {
          id: item.project?.value as string,
          percentage: item.percentage
        };
      }),
      expenseUuid: expenseUuidToUpdate
    };

    const checkPolicyByProject = await getEnsuredValidateDateLimitsByProject(
      policyByProjectQueryPayload
    );

    if (checkPolicyByProject !== null && !checkPolicyByProject.success) {
      if (checkPolicyByProject.daysLimitAgo !== null) {
        setError('date', {
          type: 'validated-by-project',
          message: t('errors.expenseExceedsTheMaximumAmountOfDaysPassed', {
            days: checkPolicyByProject.daysLimitAgo
          })
        });
      }

      if (checkPolicyByProject.daysLimitAhead !== null) {
        setError('date', {
          type: 'validated-by-project',
          message: t('errors.expenseExceedsTheMaximumAmountOfFutureDays', {
            days: checkPolicyByProject.daysLimitAhead
          })
        });
      }

      return;
    }

    clearErrors('date');
  }

  function handleChangePercentage(percentage: number): void {
    const apportionmentItems = getValues('apportionment');

    // somar a porcentagem de todos os itens
    const sumOfPercentages =
      apportionmentItems?.reduce((acc, item) => acc + item.percentage, 0) ?? 0;

    // calcula o valor do item atual
    const value = (percentage / 100) * expenseValueAsNumber;

    // Porcentagem não pode passar de 100%
    if (percentage > 100) {
      setValue(`apportionment.${fieldArrayIndex}.percentage`, 100);
      setValue(`apportionment.${fieldArrayIndex}.value`, expenseValueAsNumber);
      return;
    }

    // se a soma das porcentagens for 100, o valor do item atual é o valor da despesa menos a soma dos valores dos outros itens
    // isso é necessário para que a soma dos valores seja o valor da despesa e não tenha diferença de arredondamento
    if (sumOfPercentages === 100) {
      // soma o valor de todos os itens menos o valor do item atual
      const sumOfValues =
        apportionmentItems?.reduce(
          (acc, item, index) =>
            index !== fieldArrayIndex ? acc + item.value : acc,
          0
        ) ?? 0;

      // calcula o valor do item atual, subtraindo o valor da despesa menos a soma dos valores dos outros itens
      const currentProjectValue = Currency.subtractTwoDecimals(
        expenseValueAsNumber,
        sumOfValues
      );

      setValue(`apportionment.${fieldArrayIndex}.value`, currentProjectValue);
      return;
    }

    setValue(
      `apportionment.${fieldArrayIndex}.value`,
      Currency.roundToTwoDecimals(value)
    );
  }

  function handleChangeValue(value: number): void {
    const apportionmentItems = getValues('apportionment');

    // soma o valor de todos os itens
    const sumOfValues =
      apportionmentItems?.reduce((acc, item) => acc + item.value, 0) ?? 0;

    // calcula a porcentagem do item atual
    const percentage = Math.abs((value / expenseValueAsNumber) * 100);

    // Valor não pode ser maior que o total da despesa
    if (Math.abs(value) > Math.abs(expenseValueAsNumber)) {
      setValue(`apportionment.${fieldArrayIndex}.value`, expenseValueAsNumber);
      setValue(`apportionment.${fieldArrayIndex}.percentage`, 100);
      return;
    }

    if (sumOfValues === expenseValueAsNumber) {
      // soma a porcentagem de todos os itens menos a porcentagem do item atual
      const sumOfPercentages =
        apportionmentItems?.reduce(
          (acc, item, index) =>
            index !== fieldArrayIndex ? acc + item.percentage : acc,
          0
        ) ?? 0;

      // calcula a porcentagem do item atual, subtraindo 100 menos a soma das porcentagens dos outros itens
      const currentProjectPercentage = Currency.subtractTwoDecimals(
        100,
        sumOfPercentages
      );

      setValue(
        `apportionment.${fieldArrayIndex}.percentage`,
        currentProjectPercentage
      );
      return;
    }

    setValue(
      `apportionment.${fieldArrayIndex}.percentage`,
      Currency.roundToTwoDecimals(percentage)
    );
  }

  async function validateMileagePolicyByProject(
    projectId: string
  ): Promise<boolean> {
    const mileage = getValues('mileage');

    if (!mileage || expenseParameters?.mileagePolicyType !== 'PROJETO') {
      return false;
    }

    const data = await getEnsuredValidateMileagePolicyByProject({
      mileage: Currency.parseToFloat(mileage),
      projectId
    });

    if (data !== null) {
      setValue(
        'mileagePaidValue',
        Currency.format('BRL', data.mileagePaidValue, false, 4)
      );
      setValue('value', Currency.format('BRL', data.value));
      handleChangeDisabledKilometerInput(!data.allowChangeAmountPerKm);
      handleChangeRequiredReceiptInManualRoute(
        data.requiredReceiptInManualRoute
      );
      return true;
    }

    return false;
  }

  function handleChangeProject(project: IProject): void {
    checkIfDateIsValidByProjectPolicy();

    if (
      project &&
      expenseParameters?.mileagePolicyType === 'PROJETO' &&
      (visibleModal === 'createRouteByMap' ||
        visibleModal === 'createManualRouteExpense')
    ) {
      validateMileagePolicyByProject(String(project.id));
    }
  }

  const blockAddAndRemoveProjectActions = isValidatingDateLimitsByProject;

  return {
    expenseValueAsNumber,
    handleChangePercentage,
    handleChangeValue,
    handleChangeProject,
    checkIfDateIsValidByProjectPolicy,
    projectIdsToNotShowAsOptions,
    blockAddAndRemoveProjectActions,
    currencySymbol
  };
}
