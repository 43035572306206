class CustomObject extends Object {
  // A função recebe um objeto e um array de chaves e retorna um novo objeto com as chaves passadas
  pick<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
    const objectPick = {} as Pick<T, K>;
    keys.forEach(key => {
      objectPick[key] = obj[key];
    });
    return objectPick;
  }

  omit<T extends object, P extends keyof T>(obj: T, keys: P[]): Omit<T, P> {
    const newObject = Object.fromEntries(
      Object.entries(obj).filter(([k, value]) => !keys.includes(k as P))
    );
    return newObject as Omit<T, P>;
  }

  removeUndefinedKeys(obj: object): object {
    const filteredEntries = Object.entries(obj).filter(
      ([, value]) => value !== undefined
    );
    return Object.fromEntries(filteredEntries);
  }

  exchangeNullValues<T extends object>(obj: T): T {
    const filteredEntries = Object.entries(obj).map(([key, value]) =>
      !value ? [key, undefined] : [key, value]
    );
    return Object.fromEntries(filteredEntries);
  }

  compareArraysOfObjects(arr1: object[], arr2: object[]): boolean {
    // Verifica se os arrays têm o mesmo comprimento
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Função para comparar dois objetos
    function compareObjects(obj1: object, obj2: object): boolean {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      // Verifica se os objetos têm o mesmo número de chaves
      if (keys1.length !== keys2.length) {
        return false;
      }

      // Verifica se os objetos têm as mesmas chaves e valores
      for (const key of keys1) {
        if (obj1[key as keyof typeof obj1] !== obj2[key as keyof typeof obj2]) {
          return false;
        }
      }

      return true;
    }

    // Compara cada objeto nos arrays
    for (let i = 0; i < arr1.length; i++) {
      if (!compareObjects(arr1[i], arr2[i])) {
        return false;
      }
    }

    return true;
  }
}

export default new CustomObject();
