import { CardInfo, tokens } from 'ds';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${tokens.spacingInsetXs};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: row wrap;
  }
`;

export const CardInfoContainer = styled(CardInfo.Container)`
  width: fit-content;

  .balance-card-feedback {
    color: ${tokens.feedbackColorSuccessPure};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 25rem;
    width: fit-content;
  }
`;

export const CardsContent = styled(motion.div)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  padding: ${tokens.spacingXxs};
  gap: ${tokens.spacingXs};
  background: ${tokens.neutralColorHighLight};
  border-radius: ${tokens.borderRadiusMd};
`;
