import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${tokens.spacingXs};

  margin: 0;
`;

export const IconContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  background-color: ${tokens.brandColorPrimaryLightest};
  border-radius: 50%;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  background-color: ${tokens.brandColorPrimaryPure};
  border-radius: 50%;
`;

export const Description = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightRegular};
  line-height: ${tokens.spacingMd};
  letter-spacing: 0.75px;
  text-align: center;
  color: ${tokens.neutralColorLowDark};
`;
