import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 4.7rem;

  h1 {
    margin-bottom: 2rem;
  }

  p {
    text-align: center;
  }

  button {
    margin-top: 2rem;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0 2rem;
    list-style: disc;
    width: fit-content;

    li {
      width: fit-content;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  margin-bottom: 3.2rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.tokens.highlightColorLight};
`;

export const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 2.5rem;
  }
`;

export const CustomLink = styled(NavLink)`
  background: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  width: 22rem;
  border-radius: 10px;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1.5rem 2.28rem;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
