import { DefaultButton, Modal, SecondaryButton } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useModalContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';
import {
  ListAndSearch,
  type ListSearchType
} from 'presentation/pages/cards/Management/CardGroups/components';

import { useAddManagerToGroupModal } from './useAddManagerToGroupModal';

import { ModalContent, ModalFooter } from './AddManagerToGroupModal.styles';

export function AddManagerToGroupModal(): JSX.Element {
  const { handleCloseModal, handleOpenModal } = useModalContext();
  const { t } = useTranslation(['cards', 'global']);

  const {
    paginatedAvailableToBeManager,
    isFetchingAvailableToBeManager,
    isAddingManagersToGroup,
    handleAddManagersToGroup,
    availableToBeManagerPagination
  } = useAddManagerToGroupModal();

  const [
    paginationManagers,
    handleChangePaginationManagers,
    selectedAvailableToBeManager,
    handleToggleSelectAvailableToBeManager
  ] = useCardGroupsContext(
    useShallow(state => [
      state.paginationManagers,
      state.handleChangePaginationManagers,
      state.selectedAvailableToBeManager,
      state.handleToggleSelectAvailableToBeManager
    ])
  );

  if (isAddingManagersToGroup) {
    return <Loader isLoading />;
  }

  return (
    <Modal.Container
      onClose={() => {
        if (isFetchingAvailableToBeManager) return;

        handleCloseModal();
        handleOpenModal('groupCardDetails');
      }}
      variant='medium'
    >
      <Modal.Header>
        <Modal.Title>{t('addNewGroupManager')}</Modal.Title>

        <Modal.IconClose
          onClick={() => {
            handleCloseModal();
            handleOpenModal('groupCardDetails');
          }}
        />
      </Modal.Header>

      <ModalContent>
        <p>{t('selectGroupManagers')}</p>

        <ListAndSearch
          items={
            paginatedAvailableToBeManager?.map(item => ({
              ...item,
              disabled: item.type === 'ACCOUNT_MANAGER'
            })) ?? []
          }
          searchValue={paginationManagers.userName ?? ''}
          onChangeSearch={(value: string) => {
            handleChangePaginationManagers({
              userName: value ?? ''
            });
          }}
          selectedItemIds={selectedAvailableToBeManager
            .filter(item => selectedAvailableToBeManager.includes(item))
            .map(user => ({ name: user, id: user }))}
          onChangeSelect={(value: ListSearchType) => {
            handleToggleSelectAvailableToBeManager(value.id);
          }}
          isLoading={isFetchingAvailableToBeManager}
          actionFn={availableToBeManagerPagination.fetchNextPage}
          hasNextPage={availableToBeManagerPagination.hasNextPage}
          isFetchingNextPage={availableToBeManagerPagination.isFetchingNextPage}
        />

        <ModalFooter>
          <SecondaryButton
            size='small'
            onClick={() => {
              handleCloseModal();
              handleOpenModal('groupCardDetails');
            }}
            type='button'
          >
            {t('global:cancel')}
          </SecondaryButton>

          <DefaultButton
            size='small'
            onClick={handleAddManagersToGroup}
            disabled={selectedAvailableToBeManager.length === 0}
          >
            {t('addGroupManager')}
          </DefaultButton>
        </ModalFooter>
      </ModalContent>
    </Modal.Container>
  );
}
