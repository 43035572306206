import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IAddLegalRepresentativePayload,
  type IAddLegalRepresentativosFieldsForm,
  type IUseAddLegalRepresentatives,
  type IUseAddLegalRepresentativesParams
} from 'data/modules/cards/companyOnboarding';

import { useFormWithSchema } from 'shared/hooks/forms';
import { useHandleQueryCache } from 'shared/hooks/global';

import { useAddLegalRepresentativesSchema } from './schemas';

export function useAddLegalRepresentatives({
  onAddLegalRepresentativesMutationSuccess
}: IUseAddLegalRepresentativesParams): IUseAddLegalRepresentatives {
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useFormWithSchema(useAddLegalRepresentativesSchema(), {
    defaultValues: {
      legalRepresentatives: [
        {
          name: '',
          email: ''
        }
      ]
    }
  });

  const { isLoading, mutateAsync } = useMutationCache({
    mutationKey: [CompanyOnboardingMutationKeys.ADD_LEGAL_REPRESENTATIVE],
    mutationFn: async (payload: IAddLegalRepresentativePayload) =>
      await CompanyOnboardingService.addLegalRepresentative(payload),
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  const { getEnsuredCache } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  async function onSubmit({
    legalRepresentatives
  }: IAddLegalRepresentativosFieldsForm): Promise<void> {
    const { onboardingCompanyId: cachedOnboardingCompanyId } =
      await getEnsuredCache();

    const promises =
      legalRepresentatives?.map(
        async representative =>
          await mutateAsync({
            name: representative.name,
            email: representative.email,
            uuidCompanyOnboarding: cachedOnboardingCompanyId ?? ''
          })
      ) ?? [];

    await Promise.all(promises);

    await queryClient.invalidateQueries({
      queryKey: [
        CompanyOnboardingQueryKeys.GET_LEGAL_REPRESENTATIVES,
        cachedOnboardingCompanyId
      ]
    });

    onAddLegalRepresentativesMutationSuccess();
  }

  return {
    errors,
    control,
    setValue,
    isAddingLegalRepresentatives: isLoading,
    handleAddLegalRepresentatives: handleSubmit(onSubmit)
  };
}
