import { useState } from 'react';

import { format, subMonths } from 'date-fns';
import { useShallow } from 'zustand/react/shallow';

import { useAuthContext } from 'data/contexts';
import {
  useGetOutsourcedUserAccounts,
  useGetUserAccountsByUserUuid
} from 'data/modules/cards/account';
import { useGetPendencies } from 'data/modules/cards/pendencies';
import {
  type IAccountStatement,
  type IAccountTransactionWithAdditionalInfo,
  type IUserStatementFilters,
  useGetAccountStatement
} from 'data/modules/cards/statement';

import { Statement } from 'shared/utils/cards';
import { CustomDate } from 'shared/utils/custom';
import { CustomCookies } from 'shared/utils/global';

import { type IUseUserStatement } from './UserStatement.types';

export function useUserStatement(): IUseUserStatement {
  const today = new Date();

  const [statementFilters, setStatementFilters] =
    useState<IUserStatementFilters>({
      dateFilter: {
        from: subMonths(today, 1),
        to: today
      },
      searchInput: '',
      selectedMovementType: 'all'
    });

  const [user, outsourcedUser] = useAuthContext(
    useShallow(state => [state.user, state.outsourcedUser])
  );

  const isSharedAccount = !!user?.cards?.sharedAccounts;

  const { userAccounts, isFetchingUserAccounts } = useGetUserAccountsByUserUuid(
    {
      userUuid: user?.cards?.id as string,
      enabled: !isSharedAccount
    }
  );

  const { outsourcedUserAccounts, isFetchingOutsourcedUserAccounts } =
    useGetOutsourcedUserAccounts({
      outsourcedUserUuid: user?.cards?.id as string,
      enabled: isSharedAccount
    });

  const statementPayload = {
    startDate: format(
      statementFilters.dateFilter.from as Date,
      'yyyy-MM-dd'
    ).concat('T00:00:00.000Z'),
    endDate: format(
      statementFilters.dateFilter.to as Date,
      'yyyy-MM-dd'
    ).concat('T00:00:00.000Z'),
    timezone: CustomDate.getTimezone()
  };

  const outsourcingUserUuid = CustomCookies.get('outsourcing') ?? '';

  const isGetOutsourcedUserRequestEnabled =
    !!outsourcingUserUuid && !!user?.parameters.hasOutsourcingAccess;

  const activeAccountBalancesIds = isSharedAccount
    ? outsourcedUserAccounts?.reduce<string[]>((acc, userAccount) => {
        const activeAccountBalanceId = userAccount.balances.find(
          balance => balance.inUse
        )?.id;

        if (activeAccountBalanceId) {
          return [...acc, activeAccountBalanceId];
        }

        return acc;
      }, []) ?? []
    : userAccounts?.reduce<string[]>((acc, userAccount) => {
        const activeAccountBalanceId = userAccount.balances.find(
          balance => balance.inUse
        )?.id;

        if (activeAccountBalanceId) {
          return [...acc, activeAccountBalanceId];
        }

        return acc;
      }, []) ?? [];

  const { accountsStatements, isLoadingAccountsStatements } =
    useGetAccountStatement({
      accountBalancesIds: activeAccountBalancesIds,
      ...statementPayload
    });

  const { pendencies, isLoadingPendencies } = useGetPendencies({
    accountBalancesIds: activeAccountBalancesIds
  });

  const filteredAccountStatement: IAccountStatement<IAccountTransactionWithAdditionalInfo>[] =
    accountsStatements
      ?.map(accountStatementRow => {
        const transactionsWithUserName = accountStatementRow.items.map(
          transaction => {
            const transactionUserName = isGetOutsourcedUserRequestEnabled
              ? outsourcedUser?.user.name ?? ''
              : (user?.name as string);

            const transactionPendency = pendencies?.find(
              pendency => pendency.transactionId === transaction.transactionId
            );

            return {
              ...transaction,
              userName: transactionUserName,
              pendency: transactionPendency
            };
          }
        );

        const filteredTransactions =
          Statement.filterAccountTransactionsWithUserName(
            transactionsWithUserName,
            [],
            statementFilters.selectedMovementType,
            statementFilters.searchInput
          );

        const adjustedAccountStatementRow = {
          ...accountStatementRow,
          items: filteredTransactions
        };

        return adjustedAccountStatementRow;
      })
      .filter(accountStatementRow => accountStatementRow.items.length > 0);

  return {
    filteredAccountStatement,
    isLoadingAccountStatement:
      isFetchingUserAccounts ||
      isFetchingOutsourcedUserAccounts ||
      isLoadingAccountsStatements ||
      isLoadingPendencies,
    setStatementFilters,
    statementFilters
  };
}
