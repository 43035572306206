import { OnboardingLoader } from 'presentation/pages/cards/Onboarding/components/General';

import { type IOnboardingStepProps } from './OnboardingStep.types';

import { Container, ProgressBar } from './OnboardingStep.styles';

export function OnboardingStep({
  percentage,
  currentStep,
  isLoading = false,
  children
}: IOnboardingStepProps): JSX.Element {
  return (
    <Container $currentStep={currentStep}>
      {isLoading ? (
        <OnboardingLoader />
      ) : (
        <>
          {percentage !== undefined && <ProgressBar $percentage={percentage} />}

          {children}
        </>
      )}
    </Container>
  );
}
