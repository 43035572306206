export const requestModalSelectCardQuantities = [
  '2 - 4',
  '5 - 10',
  '11 - 25',
  '26 - 100',
  '101 - 500',
  '501 - 2000',
  '2001 - 10.000',
  '10.000+'
];
