import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type AnalysisChartCardStyleType } from 'presentation/pages/analytics/Analytics/components/AnalysisChartCard/AnalysisChartCard.types';

export const Container = styled.div<
  AddPrefixToType<AnalysisChartCardStyleType>
>`
  display: flex;
  flex-direction: column;
  padding: ${tokens.spacingMd};
  border-radius: ${tokens.borderRadiusSm};
  background: ${tokens.neutralColorHighPure};
  border: 1px solid ${tokens.neutralColorHighDark};

  ${({ $disabled }) =>
    $disabled &&
    css`
      * :not(h3 + span),
      p {
        color: ${tokens.neutralColorLowLightest} !important;
        pointer-events: none;
      }

      text,
      .highcharts-series path,
      .highcharts-legend text + rect {
        fill: ${tokens.neutralColorLowLightest} !important;
      }
    `}
`;

export const ChartCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    padding: ${tokens.spacingNano} ${tokens.spacingSm};
  }
`;

export const ChartCardTitle = styled.h3`
  font-style: normal;
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightSemibold};
`;

export const ChartCardSubtitle = styled.p`
  font-style: normal;
  line-height: 2.8rem;
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowDarkest};
  font-weight: ${tokens.fontWeightMedium};
  font-family: ${tokens.fontFamilyPoppins};
`;

export const ChartCardInformation = styled.span`
  font-style: normal;
  line-height: normal;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
`;

export const ChartWrapper = styled.div`
  padding: ${tokens.spacingLg};

  @media screen and (max-width: 500px) {
    padding: 0;
    padding-top: ${tokens.spacingLg};
  }
`;

export const NoDataContainer = styled.div`
  flex: 1 0 0;
  display: flex;
  min-height: 28rem;
  text-align: center;
  font-style: normal;
  line-height: 2.8rem;
  flex-direction: column;
  justify-content: center;
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
`;
