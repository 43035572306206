import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10rem;
`;

export const Paragraph = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
`;
