import { ReactComponent as AnalyticsIcon } from 'shared/assets/icons/analytics.svg';
import { ReactComponent as ApprovalsIcon } from 'shared/assets/icons/approvals.svg';
import { ReactComponent as ArrowDownIcon } from 'shared/assets/icons/arrow_down.svg';
import { ReactComponent as BellIcon } from 'shared/assets/icons/bell.svg';
import { ReactComponent as BellAltIcon } from 'shared/assets/icons/bell_alt.svg';
import { ReactComponent as CardsIcon } from 'shared/assets/icons/cards.svg';
import { ReactComponent as CashBanknoteWarningIcon } from 'shared/assets/icons/cash_banknote_warning.svg';
import { ReactComponent as ReversalIcon } from 'shared/assets/icons/chargeback_expense.svg';
import { ReactComponent as ArrowLeftIcon } from 'shared/assets/icons/chevron_left.svg';
import { ReactComponent as CloseIcon } from 'shared/assets/icons/close.svg';
import { ReactComponent as CompanyIcon } from 'shared/assets/icons/company.svg';
import { ReactComponent as ConciliationIcon } from 'shared/assets/icons/conciliation.svg';
import { ReactComponent as CopyToClipboardIcon } from 'shared/assets/icons/copy_to_clipboard.svg';
import { ReactComponent as DotIcon } from 'shared/assets/icons/dot.svg';
import { ReactComponent as DotsVerticalIcon } from 'shared/assets/icons/dots_vertical.svg';
import { ReactComponent as DownloadIcon } from 'shared/assets/icons/download.svg';
import { ReactComponent as EarthIcon } from 'shared/assets/icons/earth.svg';
import { ReactComponent as EditPenIcon } from 'shared/assets/icons/edit_pen.svg';
import { ReactComponent as ExpensesIcon } from 'shared/assets/icons/expenses.svg';
import { ReactComponent as FilterIcon } from 'shared/assets/icons/filter.svg';
import { ReactComponent as HelpCenterIcon } from 'shared/assets/icons/help_center.svg';
import { ReactComponent as HomeIcon } from 'shared/assets/icons/home.svg';
import { ReactComponent as IllustrationAnalyticsSuggestionIcon } from 'shared/assets/icons/illustration_analytics_suggestion.svg';
import { ReactComponent as IllustrationAnalyticsSuggestionSentIcon } from 'shared/assets/icons/illustration_analytics_suggestion_sent.svg';
import { ReactComponent as IllustrationCardsIcon } from 'shared/assets/icons/Illustration_cards.svg';
import { ReactComponent as IllustrationChatIcon } from 'shared/assets/icons/illustration_chat.svg';
import { ReactComponent as IllustrationHelpCenterIcon } from 'shared/assets/icons/illustration_help_center.svg';
import { ReactComponent as IllustrationPhoneIcon } from 'shared/assets/icons/illustration_phone.svg';
import { ReactComponent as IllustrationUserGuidesIcon } from 'shared/assets/icons/illustration_user_guides.svg';
import { ReactComponent as InfoIcon } from 'shared/assets/icons/info.svg';
import { ReactComponent as InfoIconMini } from 'shared/assets/icons/info_icon.svg';
import { ReactComponent as ManagementIcon } from 'shared/assets/icons/management.svg';
import { ReactComponent as MenuIcon } from 'shared/assets/icons/menu.svg';
import { ReactComponent as NotificationBellIcon } from 'shared/assets/icons/notification_bell.svg';
import { ReactComponent as NotificationCardsIcon } from 'shared/assets/icons/notifications/cards.svg';
import { ReactComponent as NotificationCardsClockIcon } from 'shared/assets/icons/notifications/cards-clock.svg';
import { ReactComponent as NotificationCashCheckIcon } from 'shared/assets/icons/notifications/cash-check.svg';
import { ReactComponent as NotificationCashInIcon } from 'shared/assets/icons/notifications/cash-in.svg';
import { ReactComponent as NotificationCircleUserCheckIcon } from 'shared/assets/icons/notifications/circle-user-check.svg';
import { ReactComponent as NotificationCircleUserCrossIcon } from 'shared/assets/icons/notifications/circle-user-cross.svg';
import { ReactComponent as NotificationDocumentIcon } from 'shared/assets/icons/notifications/document.svg';
import { ReactComponent as NotificationFormIcon } from 'shared/assets/icons/notifications/form.svg';
import { ReactComponent as NotificationListIcon } from 'shared/assets/icons/notifications/list.svg';
import { ReactComponent as NotificationSheetIcon } from 'shared/assets/icons/notifications/sheet.svg';
import { ReactComponent as NotificationUserCheckIcon } from 'shared/assets/icons/notifications/user-check.svg';
import { ReactComponent as NotificationUserClockIcon } from 'shared/assets/icons/notifications/user-clock.svg';
import { ReactComponent as NotificationUserCrossIcon } from 'shared/assets/icons/notifications/user-cross.svg';
import { ReactComponent as QuestionCircleIcon } from 'shared/assets/icons/question_circle.svg';
import { ReactComponent as ReportsIcon } from 'shared/assets/icons/reports.svg';
import { ReactComponent as SearchEyeIcon } from 'shared/assets/icons/search-eye.svg';
import { ReactComponent as SettingsIcon } from 'shared/assets/icons/settings.svg';
import { ReactComponent as StoreIcon } from 'shared/assets/icons/store.svg';
import { ReactComponent as SubItemIcon } from 'shared/assets/icons/subitem.svg';
import { ReactComponent as TrophyIcon } from 'shared/assets/icons/trophy.svg';
import { ReactComponent as UserProfileAddIcon } from 'shared/assets/icons/user_profile_add.svg';
import { ReactComponent as UserProfileCogIcon } from 'shared/assets/icons/user_profile_cog.svg';
import { ReactComponent as UserProfileRemoveIcon } from 'shared/assets/icons/user_profile_remove.svg';
import { ReactComponent as VexpensesUniversityIcon } from 'shared/assets/icons/vexpenses_university.svg';
import { ReactComponent as WithdrawIcon } from 'shared/assets/icons/withdraw.svg';

export const Icons = {
  HomeIcon,
  ExpensesIcon,
  ReportsIcon,
  ApprovalsIcon,
  CardsIcon,
  ConciliationIcon,
  WithdrawIcon,
  ManagementIcon,
  SettingsIcon,
  UserProfileAddIcon,
  UserProfileRemoveIcon,
  UserProfileCogIcon,
  ArrowLeftIcon,
  CompanyIcon,
  EditPenIcon,
  HelpCenterIcon,
  SubItemIcon,
  ArrowDownIcon,
  TrophyIcon,
  EarthIcon,
  CloseIcon,
  MenuIcon,
  DotIcon,
  StoreIcon,
  BellIcon,
  BellAltIcon,
  NotificationBellIcon,
  DotsVerticalIcon,
  NotificationFormIcon,
  NotificationListIcon,
  NotificationCardsIcon,
  NotificationSheetIcon,
  NotificationCashInIcon,
  NotificationDocumentIcon,
  NotificationUserClockIcon,
  NotificationUserCrossIcon,
  NotificationCashCheckIcon,
  NotificationUserCheckIcon,
  NotificationCardsClockIcon,
  NotificationCircleUserCheckIcon,
  NotificationCircleUserCrossIcon,
  VexpensesUniversityIcon,
  IllustrationCardsIcon,
  IllustrationHelpCenterIcon,
  IllustrationUserGuidesIcon,
  IllustrationChatIcon,
  IllustrationPhoneIcon,
  CopyToClipboardIcon,
  SearchEyeIcon,
  IllustrationAnalyticsSuggestionIcon,
  IllustrationAnalyticsSuggestionSentIcon,
  FilterIcon,
  InfoIcon,
  CashBanknoteWarningIcon,
  ReversalIcon,
  AnalyticsIcon,
  QuestionCircleIcon,
  DownloadIcon,
  InfoIconMini
};
