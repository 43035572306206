import { useQueryCache } from 'data/cache';
import {
  ExpensesQueryKeys,
  ExpensesService,
  type IUseGetNumberOfExpensesByStatus,
  type IUseGetNumberOfExpensesByStatusParams
} from 'data/modules/expenses';

export function useGetNumberOfExpensesByStatus(
  params: IUseGetNumberOfExpensesByStatusParams
): IUseGetNumberOfExpensesByStatus {
  const {
    data: numberOfExpensesByStatus,
    isLoading: isLoadingNumberOfExpensesByStatus
  } = useQueryCache({
    queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS, params],
    queryFn: async () =>
      await ExpensesService.getNumberOfExpensesByStatus(params)
  });

  return {
    numberOfExpensesByStatus,
    isLoadingNumberOfExpensesByStatus
  };
}
