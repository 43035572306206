import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type ISendAnalysisSuggestionFieldsForm } from 'data/modules/analytics';

export function useSendAnalysisSuggestionSchema(): ObjectSchema<ISendAnalysisSuggestionFieldsForm> {
  const { lang, currentLangKey } = useLangContext();

  const sendAnalysisSuggestionSchema: ObjectSchema<ISendAnalysisSuggestionFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object({
        objective: yup
          .string()
          .trim()
          .required(fieldRequiredMessage)
          .max(1000, `${lang.schemas.max[currentLangKey]} 1000`),
        frequency: yup
          .string()
          .trim()
          .required(fieldRequiredMessage)
          .max(1000, `${lang.schemas.max[currentLangKey]} 1000`),
        howSee: yup
          .string()
          .trim()
          .required(fieldRequiredMessage)
          .max(1000, `${lang.schemas.max[currentLangKey]} 1000`),
        type: yup
          .string()
          .trim()
          .required(fieldRequiredMessage)
          .max(1000, `${lang.schemas.max[currentLangKey]} 1000`),
        contact: yup
          .string()
          .trim()
          .nullable()
          .defined()
          .transform(value => {
            return value === '' ? null : value;
          })
          .max(100, `${lang.schemas.max[currentLangKey]} 100`)
      });
    }, [lang, currentLangKey]);

  return sendAnalysisSuggestionSchema;
}
