import { useTranslation } from 'react-i18next';

import { DefaultLayout } from 'presentation/templates';

import { MyCardPageHeader } from '../../components';
import { MovementsTable } from '../components';

import { PageContainer, PageContent } from './MyMovements.styles';

export function MyMovements(): JSX.Element {
  const { t } = useTranslation('cards');

  document.title = `vexpenses | ${t('myCard')}`;

  return (
    <DefaultLayout
      pageNameAndBreadcrumbs={{
        pageName: t('myCard'),
        isBackButtonVisible: false,
        isBreadcrumbsVisible: false,
        isClassicMode: false
      }}
    >
      <PageContainer>
        <MyCardPageHeader />

        <PageContent>
          <MovementsTable />
        </PageContent>
      </PageContainer>
    </DefaultLayout>
  );
}
