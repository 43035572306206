import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8.8rem 6.2rem 12.9rem 7.7rem;
  padding-bottom: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 5rem 0 0;
  }
`;

export const OnboardingBoldTitle = styled.h1`
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto};
  color: ${({ theme }) => theme.colors.primary};
`;

export const PoliciesAndTermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1.5rem 0;
  margin-bottom: 8.4rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  word-break: break-all;
  margin-bottom: 0.8rem;
`;
