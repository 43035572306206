import { type IDefaultTableContainerProps } from './DefaultTableContainer.types';

import { Container } from './DefaultTableContainer.styles';

export function DefaultTableContainer({
  children,
  ...rest
}: IDefaultTableContainerProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
