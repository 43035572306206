import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import {
  useCardGroupsContext,
  useCardsContext,
  useModalContext
} from 'data/contexts';
import { AccountQueryKeys } from 'data/modules/cards/account';
import {
  CardGroupsQueryKeys,
  useRemoveCardFromGroup
} from 'data/modules/cards/cardGroups';

import { ApiMessage } from 'shared/utils/global';

import { type IUseRemoveCardFromGroupModal } from './RemoveCardFromGroupModal.types';

export function useRemoveCardFromGroupModal(): IUseRemoveCardFromGroupModal {
  const [selectedCardGroup, cardBeingExcluded, { usersPagination }] =
    useCardGroupsContext(
      useShallow(state => [
        state.selectedCardGroup,
        state.cardBeingExcluded,
        state.paginationModalDetails
      ])
    );

  const [{ searchTerm, order }, page, limit] = useCardsContext(
    useShallow(state => [
      state.filters,
      state.pagination.page,
      state.pagination.limit
    ])
  );

  const { handleCloseModal, handleOpenModal } = useModalContext();

  const { isRemovingCardFromGroup, removeCardFromGroup } =
    useRemoveCardFromGroup();

  const queryClient = useQueryClient();

  async function handleRemoveCardFromGroup(): Promise<void> {
    try {
      await removeCardFromGroup({
        id: selectedCardGroup?.id as string,
        users: [cardBeingExcluded?.userId!]
      });

      queryClient.invalidateQueries({
        queryKey: [
          AccountQueryKeys.GET_ACCOUNTS,
          {
            limit,
            page,
            ordenationType: order.cardName,
            ...(searchTerm && {
              name: searchTerm
            })
          }
        ]
      });

      queryClient.invalidateQueries({
        queryKey: [
          CardGroupsQueryKeys.GET_GROUP_USERS,
          {
            id: selectedCardGroup?.id,
            page: usersPagination.page,
            limit: usersPagination.limit
          }
        ]
      });

      queryClient.resetQueries({
        queryKey: [CardGroupsQueryKeys.GET_USERS_WITHOUT_CARD_GROUP]
      });

      handleCloseModal();
      handleOpenModal('groupCardDetails');
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(ApiMessage.error(error.response?.data.errors?.[0].code));

        return;
      }

      toast.error(ApiMessage.error('default'));
    }
  }

  return {
    handleRemoveCardFromGroup,
    isRemovingCardFromGroup
  };
}
