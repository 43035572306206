import {
  type ICheckIfCardsCanBeAddedPayload,
  type IPersistenceCheckIfCardsCanBeAddedPayload
} from '../../types/cardGroups.types';

class CheckIfCardsCanBeAdded {
  toPersistence(
    payload: ICheckIfCardsCanBeAddedPayload
  ): IPersistenceCheckIfCardsCanBeAddedPayload {
    return {
      user_ids: payload.usersIds
    };
  }
}

export default new CheckIfCardsCanBeAdded();
