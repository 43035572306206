import { useLangContext, useModalContext } from 'data/contexts';
import { type ICompanyAccount } from 'data/modules/cards/account';

import { RectButton } from 'presentation/components/base/Button';
import { ResponsiveCell } from 'presentation/components/global/Table';

import { type IBusinessCardsTableBodyProps } from './BusinessCardsBody.types';

import { Container, TdAction } from './BusinessCardsBody.styles';

export function BusinessCardsBody({
  businessCards,
  onChangeAccountId
}: IBusinessCardsTableBodyProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { handleOpenModal } = useModalContext();

  return (
    <>
      {businessCards.map(
        ({ name, id, accessAccounts, cardNumber }: ICompanyAccount, index) => {
          const idAccessAccounts = accessAccounts.find(item => item.id);
          return (
            <Container key={index}>
              <ResponsiveCell
                width={100}
                label={lang.global.corporate_name[currentLangKey]}
              >
                {name}
              </ResponsiveCell>

              <TdAction>
                <RectButton
                  iconProps={{
                    name: 'settings',
                    color: 'white',
                    type: 'fill',
                    size: 1.6
                  }}
                  linkProps={{
                    to: idAccessAccounts?.id
                      ? `/settings/cartoes/cartoes-empresariais/${idAccessAccounts.id}`
                      : ''
                  }}
                >
                  {
                    lang.cards.management.accounts_list.manage_card[
                      currentLangKey
                    ]
                  }
                </RectButton>
                <RectButton
                  color='puertoRico'
                  iconProps={{
                    name: 'lock',
                    color: 'white',
                    type: 'fill',
                    size: 1.6
                  }}
                  onClick={() => {
                    if (id) {
                      handleOpenModal('activateBusinessCard');
                      onChangeAccountId({ id, backOption: false });
                    }
                  }}
                  disabled={!!cardNumber}
                >
                  {
                    lang.cards.statement.sidebar.options.activate_card[
                      currentLangKey
                    ]
                  }
                </RectButton>
              </TdAction>
            </Container>
          );
        }
      )}
    </>
  );
}
