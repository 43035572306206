import { useState } from 'react';

import { type IUseAppliedFiltersContainer } from './AppliedFiltersContainer.types';

export function useAppliedFiltersContainer(): IUseAppliedFiltersContainer {
  const [isOpen, setIsOpen] = useState(false);

  function toggleSetIsOpen(): void {
    setIsOpen(prev => !prev);
  }

  return { isOpen, toggleSetIsOpen };
}
