import { useIsFetching, useQueryClient } from '@tanstack/react-query';

import { useQueryCache } from 'data/cache';
import {
  FuelQueryKeys,
  FuelService,
  type IGetPaginatedCompanyVehicles,
  type IGetPaginatedCompanyVehiclesPayload,
  type IUseGetPaginatedCompanyVehicles,
  type IUseGetPaginatedCompanyVehiclesParams
} from 'data/modules/fuel';

export function useGetPaginatedCompanyVehicles({
  enabled = true,
  ...params
}: IUseGetPaginatedCompanyVehiclesParams): IUseGetPaginatedCompanyVehicles {
  const queryClient = useQueryClient();

  const {
    data: paginatedCompanyVehicles,
    isLoading: isLoadingPaginatedCompanyVehicles
  } = useQueryCache({
    queryKey: [FuelQueryKeys.GET_COMPANY_VEHICLES, params],
    queryFn: async () => await FuelService.getPaginatedCompanyVehicles(params),
    enabled
  });

  async function getEnsuredPaginatedCompanyVehicles(
    params: IUseGetPaginatedCompanyVehiclesParams
  ): Promise<IGetPaginatedCompanyVehicles | undefined> {
    const data = await queryClient.ensureQueryData({
      queryKey: [FuelQueryKeys.GET_COMPANY_VEHICLES, params],
      queryFn: async () => await FuelService.getPaginatedCompanyVehicles(params)
    });

    return data;
  }

  function removeQueriesByPlate(plate: string): void {
    queryClient.removeQueries({
      predicate: query => {
        return (
          query.queryKey[0] === FuelQueryKeys.GET_COMPANY_VEHICLES &&
          (query.queryKey[1] as IGetPaginatedCompanyVehiclesPayload)?.plate ===
            plate
        );
      }
    });
  }

  const isFetchingAndPending =
    useIsFetching({
      predicate: query => {
        return (
          query.queryKey[0] === FuelQueryKeys.GET_COMPANY_VEHICLES &&
          query.state.status === 'pending'
        );
      }
    }) > 0;

  return {
    removeQueriesByPlate,
    paginatedCompanyVehicles,
    isLoadingPaginatedCompanyVehicles,
    getEnsuredPaginatedCompanyVehicles,
    isFetchingAndPending
  };
}
