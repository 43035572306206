import { useState } from 'react';

import { toast } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useModalContext, useMovementsContext } from 'data/contexts';
import { useExportMovementsInPdf } from 'data/modules/cards/movements';
import { useExportMovementsInExcel } from 'data/modules/cards/movements/useCases/export-movements-excel/useExportMovementsInExcel';

import { type IUseExportMovementsModal } from './ExportMovementsModal.types';

export function useExportMovementsModal(): IUseExportMovementsModal {
  const [exportationFileType, setExportationFileType] = useState<
    'PDF' | 'EXCEL' | null
  >(null);

  const { t } = useTranslation();

  const [movementsFilters] = useMovementsContext(
    useShallow(state => [state.movementsFilters])
  );

  const { handleCloseModal } = useModalContext();

  const filters = movementsFilters;

  const { exportMovementsInPdf, isExportingMovementsInPdf } =
    useExportMovementsInPdf();

  const { exportMovementsInExcel, isExportingMovementsInExcel } =
    useExportMovementsInExcel();

  async function handleExportMovements(
    fileType: 'PDF' | 'EXCEL'
  ): Promise<void> {
    try {
      if (fileType === 'PDF') {
        const { url } = await exportMovementsInPdf({
          all: true,
          balancesId: null,
          endDate: filters.endDate,
          startDate: filters.startDate,
          timezone: filters.timezone,
          transactionsId: null
        });

        window.open(url, '_blank');

        return;
      }

      const { url } = await exportMovementsInExcel({
        all: true,
        balancesId: null,
        endDate: filters.endDate,
        startDate: filters.startDate,
        timezone: filters.timezone,
        transactionsId: null
      });

      window.open(url, '_blank');
    } catch (err) {
      toast.error(t('errorWhenExporting'));
    } finally {
      handleCloseModal();
      setExportationFileType(null);
    }
  }

  function handleChangeExportationFileType(type: 'PDF' | 'EXCEL' | null): void {
    setExportationFileType(type);
  }

  return {
    isExportingMovementsInPdf,
    isExportingMovementsInExcel,
    exportationFileType,
    handleChangeExportationFileType,
    handleExportMovements
  };
}
