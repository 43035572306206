import { useQueryCache } from 'data/cache';
import {
  BalanceQueryKeys,
  BalanceService,
  type IUseGetUnallocatedBalance,
  type IUseGetUnallocatedBalancePayload
} from 'data/modules/cards/balance';

export function useGetUnallocatedBalance(
  payload: IUseGetUnallocatedBalancePayload
): IUseGetUnallocatedBalance {
  const { data: unallocatedBalance, isFetching: isFetchingUnallocatedBalance } =
    useQueryCache({
      queryKey: [
        BalanceQueryKeys.GET_UNALLOCATED_BALANCE,
        { cardGroupId: payload.cardGroupId || null }
      ],
      queryFn: async () =>
        await BalanceService.getUnallocatedBalance({
          ...(payload.cardGroupId && { cardGroupId: payload.cardGroupId })
        }),
      enabled: payload.enabled ?? true
    });

  return {
    unallocatedBalance,
    isFetchingUnallocatedBalance
  };
}
