import { useQueryCache } from 'data/cache';

import { CustomObject } from 'shared/utils/custom';

import { CardGroupsQueryKeys } from '../../keys/cardGroups.keys';
import CardGroupsService from '../../services/CardGroups.service';

import {
  type IUseGetManagersGroups,
  type IUseManagersGroupsParams
} from '../../types/cardGroups.types';

export function useGetGroupManagers(
  params: IUseManagersGroupsParams
): IUseGetManagersGroups {
  const {
    data: paginatedManagersGroups,
    isFetching: isFetchingManagersGroups,
    isSuccess: isSuccessGetManagersGroups
  } = useQueryCache({
    queryKey: [
      CardGroupsQueryKeys.GET_MANAGERS_GROUPS,
      CustomObject.omit(params, ['enabled'])
    ],
    queryFn: async () => {
      return await CardGroupsService.getManagersGroups(
        CustomObject.omit(params, ['enabled'])
      );
    },
    enabled: params.enabled
  });

  return {
    paginatedManagersGroups,
    isFetchingManagersGroups,
    isSuccessGetManagersGroups
  };
}
