import { DefaultTable, DSIcons } from 'ds';
import { useTranslation } from 'react-i18next';

import { CustomInfoMessage } from 'presentation/components/global/CustomInfoMessage';
import { TableLoader } from 'presentation/pages/cards/Management/components/TableLoader';
import {
  ValueSolicitationFilters,
  ValueSolicitationTabControl,
  ValueSolicitationTableHead,
  ValueSolicitationTablePagination,
  ValueSolicitationTableRow
} from 'presentation/pages/cards/Management/ValueSolicitation/components/ValueSolicitationList';

import { useValueSoliciationTable } from './useValueSoliciationTable';

import { type IValueSolicitationTableProps } from './ValueSolicitationTable.types';

import { Container, TableContainer } from './ValueSolicitationTable.styles';

export function ValueSolicitationTable({
  balanceSolicitations,
  isLoadingBalanceSolicitations,
  quantitySolicitationByStatus
}: IValueSolicitationTableProps): JSX.Element {
  const { t } = useTranslation('cards');

  const { solicitations } = useValueSoliciationTable({
    balanceSolicitations
  });

  return (
    <Container>
      <ValueSolicitationTabControl
        quantitySolicitationByStatus={quantitySolicitationByStatus}
      />

      <ValueSolicitationFilters />

      {isLoadingBalanceSolicitations ? (
        <TableLoader
          columns={6}
          rows={5}
        />
      ) : (
        <>
          <TableContainer>
            <DefaultTable.Container>
              <ValueSolicitationTableHead
                balanceSolicitations={solicitations}
              />

              <DefaultTable.Tbody>
                {solicitations.map(solicitation => (
                  <ValueSolicitationTableRow
                    solicitation={solicitation}
                    key={solicitation.id}
                  />
                ))}
              </DefaultTable.Tbody>
            </DefaultTable.Container>
          </TableContainer>

          {!solicitations.length && (
            <CustomInfoMessage
              icon={<DSIcons.EmptyFolderIcon />}
              title={t('emptyValueSolicitationsList')}
            />
          )}
        </>
      )}

      <ValueSolicitationTablePagination disable={!solicitations.length} />
    </Container>
  );
}
