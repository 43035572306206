import { api, apiWeb } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import { mockExpenseProjects } from 'data/mocks';
import { type IDefaultResponse } from 'data/modules/global';
import {
  type IAttachProjectsPayload,
  type IGetExpensesProjectsPayload,
  type IGetPaginatedProjectsPayload,
  type IGetProjectsPayload,
  type IGetRelatedProjectsPayload,
  type IPaginatedProjects,
  type IPersistenceWebProject,
  type IProjects,
  type IProjectWithPolicy
} from 'data/modules/projects';

import { PaginationMapper } from 'shared/mappers';
import { MockApi } from 'shared/utils/global';

class ProjectsService {
  async getProjects(payload: IGetProjectsPayload): Promise<IProjects[]> {
    const response = await api.get<IDefaultResponse<IProjects[]>>('/projects', {
      params: PaginationMapper.toPersistence(payload)
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async attachProjects(payload: IAttachProjectsPayload): Promise<null> {
    const response = await api.post<IDefaultResponse<null>>(
      `/team-members/${payload.uuid}/projects`,
      { projects: payload.projects }
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getRelatedProjects(
    payload: IGetRelatedProjectsPayload
  ): Promise<IProjects[]> {
    const { uuid, ...props } = payload;
    const response = await api.get<IDefaultResponse<IProjects[]>>(
      `/team-members/${uuid}/projects`,
      {
        params: PaginationMapper.toPersistence(props)
      }
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getExpensesProjects(
    payload: IGetExpensesProjectsPayload
  ): Promise<IProjectWithPolicy[]> {
    // const {
    //   data: { data }
    // } = await api.get<IDefaultResponse<IProjects[]>>('/expenses-projects', {
    //   params: PaginationMapper.toPersistence(payload)
    // });

    const data = await MockApi.getData(mockExpenseProjects);

    return data;
  }

  async getPaginatedProjects(
    payload: IGetPaginatedProjectsPayload
  ): Promise<IPaginatedProjects> {
    const response = await apiWeb.get<
      IDefaultResponse<IPersistenceWebProject[] | null>
    >(`/projects/list-by-user`, {
      params: {
        description: payload.description,
        per_page: payload.perPage,
        page: payload.page
      }
    });

    const data = ResponseAdapter.formatRegularResponse(response.data);

    return {
      data: data.data || [],
      total: data.total || 0
    };
  }
}

export default new ProjectsService();
