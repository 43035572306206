import { type ReactNode } from 'react';

import { isToday, isYesterday } from 'date-fns';
import { BadgeStatus, DSIcons } from 'ds';
import i18n from 'i18next';

import {
  type IAccountTransaction,
  type ICompanyTransaction,
  type ITransaction
} from 'data/modules/cards/statement';
import { type ReportStatusType } from 'data/modules/reports';

import { foodMCCs, transportMCCs } from '../constants';

class Movements {
  getTransactionIcon(
    transaction: ICompanyTransaction | IAccountTransaction
  ): ReactNode {
    if (transaction.isReversal !== 'NOT') {
      return <DSIcons.ArrowDownloadIcon className='mcc-icon' />;
    }

    if (transaction.mcc) {
      return transportMCCs.includes(transaction.mcc) ? (
        <DSIcons.CarTaxiIcon className='mcc-icon' />
      ) : foodMCCs.includes(transaction.mcc) ? (
        <DSIcons.FastFoodIcon className='mcc-icon' />
      ) : (
        <DSIcons.ShoppingIcon className='mcc-icon' />
      );
    }

    if (transaction.paymentDescription.includes('TAXA SAQUE -')) {
      return <DSIcons.AtmMoneyBanknoteIcon className='mcc-icon' />;
    }

    if (transaction.paymentDescription.includes('IOF -')) {
      return <DSIcons.MoneyBanknoteExchangeIcon className='mcc-icon' />;
    }

    if (!transaction.mcc) {
      return transaction.debitCredit === 'DEBIT' ? (
        <DSIcons.ArrowUpIcon className='mcc-icon' />
      ) : (
        <DSIcons.ArrowDownloadIcon className='mcc-icon' />
      );
    }

    return <DSIcons.ShoppingIcon className='mcc-icon' />;
  }

  getDateLabel(date: string): string {
    if (isToday(date + 'T00:00:00.000')) {
      return `${i18n.t('global:today')} - `;
    }

    if (isYesterday(date + 'T00:00:00.000')) {
      return `${i18n.t('global:yesterday')} - `;
    }

    return '';
  }

  getExpenseStatusIcon(status?: ReportStatusType): ReactNode {
    switch (status) {
      case 'UNREPORTED':
        return (
          <DSIcons.CreditCardInvoiceListIcon className='table-icon credit-card-invoice-list' />
        );
      case 'OPEN':
        return (
          <DSIcons.InvoiceMinusIcon className='table-icon invoice-minus' />
        );
      case 'SENT':
        return (
          <DSIcons.InvoiceAddPlusIcon className='table-icon invoice-add-plus' />
        );
      case 'APPROVED':
        return (
          <DSIcons.InvoiceCheckmarkIcon className='table-icon invoice-checkmark' />
        );
      case 'PAID':
        return (
          <DSIcons.InvoiceCheckmarkIcon className='table-icon invoice-checkmark' />
        );
      case 'REJECTED':
        return (
          <DSIcons.InvoiceRemoveCrossIcon className='table-icon invoice-remove-cross' />
        );
      default:
        return '-';
    }
  }

  getReportStatusIcon(status?: ReportStatusType): ReactNode {
    switch (status) {
      case 'OPEN':
        return (
          <BadgeStatus type='secondary'>
            {i18n.t('global:open_male_one')}
          </BadgeStatus>
        );
      case 'SENT':
        return <BadgeStatus>{i18n.t('global:sent_male_one')}</BadgeStatus>;
      case 'APPROVED':
        return (
          <BadgeStatus type='success'>
            {i18n.t('global:approved_male_one')}
          </BadgeStatus>
        );
      case 'PAID':
        return (
          <BadgeStatus type='general'>
            {i18n.t('global:paid_male_one')}
          </BadgeStatus>
        );
      case 'REJECTED':
        return (
          <BadgeStatus type='error'>
            {i18n.t('global:rejected_male_one')}
          </BadgeStatus>
        );
      case 'REOPENED':
        return (
          <BadgeStatus type='warning'>
            {i18n.t('global:reopened_male_one')}
          </BadgeStatus>
        );
      default:
        return '-';
    }
  }

  getTransactionDescription(transaction: ITransaction): ReactNode {
    switch (transaction.isReversal) {
      case 'FULLY':
        return (
          <span>
            <strong>Estorno:</strong>{' '}
            {transaction.paymentDescription || transaction.merchantName}
          </span>
        );
      case 'PARTIALLY':
        return (
          <span>
            <strong>Estorno parcial:</strong>{' '}
            {transaction.paymentDescription || transaction.merchantName}
          </span>
        );
      case 'NOT':
        return (
          <span>
            {transaction.paymentDescription || transaction.merchantName}
          </span>
        );
      default:
        return (
          <span>
            {transaction.paymentDescription || transaction.merchantName}
          </span>
        );
    }
  }
}

export default new Movements();
