import { api } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import { type IDefaultResponse } from 'data/modules/global';
import {
  type IChangeEmailPreferences,
  type IPersistenceChangeEmailPreferences
} from 'data/modules/settings/emailPreferences/types/settings.types';

import { ChangeEmailPreferencesMapper } from './mappers';

class EmailPreferencesService {
  async changeEmailPreferencesParam(
    payload: IChangeEmailPreferences
  ): Promise<IChangeEmailPreferences> {
    const response = await api.patch<
      IDefaultResponse<IPersistenceChangeEmailPreferences>
    >(
      '/settings/user/receives-management-summary-email',
      ChangeEmailPreferencesMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return ChangeEmailPreferencesMapper.toDomain(data);
  }
}

export default new EmailPreferencesService();
