import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type NotificationStylesType } from './Notifications.types';

export const DropdownContainer = styled.div`
  height: 5rem;
  display: flex;
  align-items: center;
`;

export const Notification = styled.div<AddPrefixToType<NotificationStylesType>>`
  display: flex;
  font-size: 1.2rem;
  text-align: left;
  border-radius: 50%;

  > svg {
    flex-shrink: 0;
    margin-right: 1rem;
    fill: ${({ $svgColor, theme }) => theme.colors[$svgColor]} !important;
  }
`;

export const NotificationContainer = styled.div`
  position: relative;

  > svg {
    width: 1.7rem;
    height: 2.1rem;
  }
`;

export const NotificationCounter = styled.span`
  position: absolute;
  top: -0.2rem;
  left: 1rem;
  min-width: 1.4rem;
  max-width: 1.4rem;
  max-height: 1.4rem;
  background: ${({ theme }) => theme.colors.carnation};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0.5rem;
  user-select: none;
`;

export const List = styled.ul`
  li {
    align-items: center;
    color: ${({ theme }) => theme.colors.doveGray};
    cursor: pointer;
    display: flex;
    border-radius: 12px;

    &:hover {
      background: ${({ theme }) => theme.colors.gallery};
    }

    button {
      align-items: center;
      background: none;
      border: 0;
      color: ${({ theme }) => theme.colors.doveGray};
      display: flex;
      font-size: 1.2rem;
      line-height: 1.8rem;
      padding: 1rem 1.5rem;
      width: 100%;
    }
  }
`;
