import styled from 'styled-components';

export const Container = styled.div`
  max-width: 27rem;
  width: 100%;
  padding: 0.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    min-width: 70rem;
  }
`;

export const Wrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    display: flex;
    flex-direction: row;
  }
`;

export const ActionsList = styled.ul`
  min-width: 25rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 1.5rem;
  margin-top: 2.5rem;

  li {
    list-style-type: disc;
    font-size: 1.2rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.emperor};
    margin: 0.4rem 0;

    &:first-child {
      list-style-type: none;
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-left: 10rem;
    margin-top: 0;
  }
`;
