import { toast } from 'ds';

import { useGetPasswordPolicy } from 'data/modules/settings/passwordPolicy';
import {
  type ICreateTeamMemberFieldsForm,
  useCreateTeamMemberSchema
} from 'data/modules/teamMembers';

import { useFormWithSchema } from 'shared/hooks/forms';

import { type IUseNewTeamMemberModal } from './NewTeamMemberModal.types';

export function useNewTeamMemberModal(): IUseNewTeamMemberModal {
  const { passwordPolicy } = useGetPasswordPolicy();

  const methods = useFormWithSchema(useCreateTeamMemberSchema(passwordPolicy), {
    defaultValues: {}
  });

  async function onCreateTeamMember(
    teamMember: ICreateTeamMemberFieldsForm
  ): Promise<void> {
    try {
      // Lógica será integrada depois
    } catch (error) {
      toast.error((error as Error)?.message);
    }
  }

  return {
    methods,
    handleCreateTeamMember: methods.handleSubmit(onCreateTeamMember)
  };
}
