enum AccountMutationKeys {
  BLOCK_CARD = 'BLOCK_CARD',
  UNBLOCK_CARD = 'UNBLOCK_CARD',
  RESET_CARD_PIN = 'RESET_CARD_PIN',
  DEPOSIT_ACCOUNT_BALANCE_AMOUNT = 'DEPOSIT_ACCOUNT_BALANCE_AMOUNT',
  WITHDRAWAL_ACCOUNT_BALANCE_AMOUNT = 'WITHDRAWAL_ACCOUNT_BALANCE_AMOUNT'
}

enum AccountQueryKeys {
  GET_USER_ACCOUNTS = 'GET_USER_ACCOUNTS',
  GET_COMPANY_ACCOUNTS = 'GET_COMPANY_ACCOUNTS',
  GET_USER_ACCOUNTS_BY_USER_UUID = 'GET_USER_ACCOUNTS_BY_USER_UUID',
  GET_OUTSOURCED_USER_ACCOUNTS = 'GET_OUTSOURCED_USER_ACCOUNTS',
  GET_CARD_DATA_BY_ACCOUNT_ID = 'GET_CARD_DATA_BY_ACCOUNT_ID',
  GET_ACCOUNT_BALANCE_AMOUNT = 'GET_ACCOUNT_BALANCE_AMOUNT',
  GET_ACCOUNTS = 'GET_ACCOUNTS'
}

export { AccountMutationKeys, AccountQueryKeys };
