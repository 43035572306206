import { useTranslation } from 'react-i18next';

import { Title } from 'presentation/components/global/Title/Title.component';

import { type ISettingsSidebarContainer } from './SettingsSidebarContainer.types';

import { Container } from './SettingsSidebarContainer.styles';

export function SettingsSidebarContainer({
  children,
  ...props
}: ISettingsSidebarContainer): JSX.Element {
  const { t } = useTranslation('settings');

  return (
    <Container {...props}>
      <Title text={t('sidebar.settings')} />

      {children}
    </Container>
  );
}
