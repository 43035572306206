import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  padding: ${tokens.spacingMd};
  border-radius: ${tokens.borderRadiusMd};
  background: ${tokens.neutralColorHighLight};
  border: 1px solid ${tokens.neutralColorHighMedium};
`;

export const ChartTitle = styled.h3`
  font-style: normal;
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.neutralColorLowDark};
  font-weight: ${tokens.fontWeightBold};
  font-family: ${tokens.fontFamiliesPoppins};
`;

export const ChartSubtitle = styled.p`
  font-style: normal;
  line-height: 2.2rem;
  text-align: justify;
  letter-spacing: 0.025rem;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowMedium};
  font-weight: ${tokens.fontWeightMedium};
  font-family: ${tokens.fontFamiliesPoppins};
`;

export const ChartWrapper = styled.div`
  overflow: hidden;
  padding: 3.2rem 0;
  border-radius: ${tokens.borderRadiusSm};
  box-shadow: 0 0 1px 0 rgb(15 34 67 / 8%);
  background: ${tokens.neutralColorHighPure};
`;

export const SkeletonContainer = styled(Container)`
  span {
    min-height: 2rem;
    border-radius: ${tokens.borderRadiusMd};
  }
`;
