import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useAuthContext, useLangContext, useModalContext } from 'data/contexts';
import {
  AccountQueryKeys,
  type ICardData,
  useBlockCard,
  useGetCardDataByAccountId,
  useGetOutsourcedUserAccounts,
  useGetUserAccountsByUserUuid,
  useUnblockCard
} from 'data/modules/cards/account';

import { type IUseCardActions } from './CardActions.types';

export function useCardActions(): IUseCardActions {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);
  const user = useAuthContext(state => state.user);
  const { handleOpenModal, handleCloseModal } = useModalContext();
  const [actionInProgress, setActionInProgress] = useState<'block' | 'unblock'>(
    'block'
  );

  const isSharedAccount = !!user?.cards?.sharedAccounts;

  const { userAccounts, isFetchingUserAccounts } = useGetUserAccountsByUserUuid(
    {
      userUuid: user?.cards?.id as string,
      enabled: !isSharedAccount
    }
  );

  const { outsourcedUserAccounts, isFetchingOutsourcedUserAccounts } =
    useGetOutsourcedUserAccounts({
      outsourcedUserUuid: user?.cards?.id as string,
      enabled: isSharedAccount
    });

  const queryClient = useQueryClient();

  function successOnBlockOrUnblockCard(
    action: 'block' | 'unblock',
    cardData: ICardData,
    accountId: string
  ): void {
    queryClient.setQueryData(
      [AccountQueryKeys.GET_CARD_DATA_BY_ACCOUNT_ID, { accountId }],
      cardData
    );

    if (user?.cards?.type === 'ACCOUNT_MANAGER') {
      queryClient.invalidateQueries({
        queryKey: [AccountQueryKeys.GET_USER_ACCOUNTS, null]
      });
    }

    toast.success(
      action === 'block'
        ? lang.cards.global.card_block_success[currentLangKey]
        : lang.cards.global.card_unblock_success[currentLangKey]
    );

    handleCloseModal();
  }

  const { blockCard, isBlockingCard } = useBlockCard({
    onAfterSuccess: (cardData, payload) => {
      successOnBlockOrUnblockCard('block', cardData, payload.accountId);
    }
  });

  const { unblockCard, isUnblockingCard } = useUnblockCard({
    onAfterSuccess: (cardData, payload) => {
      successOnBlockOrUnblockCard('unblock', cardData, payload.accountId);
    }
  });

  const { cardData, isFetchingCardData } = useGetCardDataByAccountId({
    accountId: isSharedAccount
      ? (outsourcedUserAccounts?.[0].account.id as string)
      : (userAccounts?.[0].account.id as string),
    enabled: isSharedAccount
      ? !!outsourcedUserAccounts?.[0].account.id
      : !!userAccounts?.[0].account.id
  });

  function handleConfirmBlockCard(): void {
    blockCard({
      accountId: isSharedAccount
        ? (outsourcedUserAccounts?.[0].account.id as string)
        : (userAccounts?.[0].account.id as string),
      blockLevel: 'USER'
    });
  }

  function handleConfirmUnblockCard(): void {
    unblockCard({
      accountId: isSharedAccount
        ? (outsourcedUserAccounts?.[0].account.id as string)
        : (userAccounts?.[0].account.id as string)
    });
  }

  function handleBlockUnblockCard(action: typeof actionInProgress): void {
    if (
      action === 'unblock' &&
      (cardData?.lockLevel === 'SYSTEM' ||
        (cardData?.lockLevel === 'MANAGER' &&
          user?.cards?.type !== 'ACCOUNT_MANAGER'))
    ) {
      handleOpenModal('unblockCardPermissionDenied');
      return;
    }

    handleOpenModal('confirmation');
    setActionInProgress(action);
  }

  const handleConfirmBlockOrUnblockAction =
    actionInProgress === 'block'
      ? handleConfirmBlockCard
      : handleConfirmUnblockCard;

  return {
    handleBlockUnblockCard,
    handleConfirmBlockOrUnblockAction,
    isBlockingOrUnblockingCard: isBlockingCard || isUnblockingCard,
    actionInProgress,
    cardData,
    isFetchingUserAccountsOrFetchingCardData:
      isFetchingOutsourcedUserAccounts ||
      isFetchingUserAccounts ||
      isFetchingCardData
  };
}
