import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  gap: ${tokens.spacingNano};
  padding: ${tokens.spacingXs};
  border-radius: ${tokens.borderRadiusMd};
  background: ${tokens.neutralColorHighLight};

  @media screen and (max-width: 768px) {
    .pagination-team-members-container {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
    }
  }
`;

export const Content = styled.div`
  overflow-x: auto;

  > table {
    table-layout: auto;
  }

  td {
    overflow: hidden;
    height: 6.8rem;

    div {
      width: fit-content;
    }

    svg {
      width: 2.4rem;
    }
  }

  th {
    height: 6.8rem;
  }

  tbody tr {
    cursor: pointer;
  }

  .team-members-tooltip {
    white-space: normal;
  }

  .virtual-user-tag {
    background-color: ${tokens.neutralColorLowDarkest};

    svg {
      width: 1.6rem;
    }

    &,
    svg * {
      color: ${tokens.neutralColorHighPure};
    }
  }
`;
