import { useLocation } from 'react-router-dom';

import { useModalContext } from 'data/contexts';
import {
  type IStoreProduct,
  StoreQueryKeys,
  StoreService
} from 'data/modules/store';

import { type StoreIdentifierToGaEventType } from 'presentation/pages/store/Store/Store.types';

import { useGa4 } from 'shared/hooks/global';
import { useHandleQueryCache } from 'shared/hooks/global/useHandleQueryCache/useHandleQueryCache';

import { type IUseProductBanner } from './ProductBanner.types';

export function useProductBanner(): IUseProductBanner {
  const { handleOpenModal } = useModalContext();
  const { pathname } = useLocation();
  const { sendGaEvent } = useGa4();
  const identifier =
    (pathname.split('/').pop() as IStoreProduct['identifier']) ?? 'cards';

  const { data: products } = useHandleQueryCache({
    query: {
      key: [StoreQueryKeys.GET_STORE_PRODUCTS],
      query: async () => await StoreService.getStoreProducts()
    }
  });

  const status =
    products?.find(product => product.identifier === identifier)?.status ??
    'not_owned';

  function handleProductBannerActionClick(): void {
    const events: StoreIdentifierToGaEventType = {
      horus: 'store_clickAgendeUmaDemonstracaoHorus',
      'active-directory': 'store_clickAgendeUmaDemonstracaoAD',
      cards: 'store_clickAgendeUmaDemonstracaoCartaoCorporativo',
      conciliation: 'store_clickAgendeUmaDemonstracaoConciliacao',
      outsourcing: 'store_clickAgendeUmaDemonstracaoTerceirizacao',
      'cards-pj': 'store_clickAgendeUmaDemonstracaoCartaoEmpresarial'
    };

    sendGaEvent('store', events[identifier]);

    handleOpenModal('storeRequestModal');
  }

  return {
    handleProductBannerActionClick,
    showActions: status === 'not_owned'
  };
}
