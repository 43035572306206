import { useAuthContext } from 'data/contexts';
import {
  useGetOutsourcedUserAccounts,
  useGetUserAccountsByUserUuid
} from 'data/modules/cards/account';

import { type IUseGetActiveBalanceIds } from './useGetActiveBalanceIds.types';

export function useGetActiveBalanceIds(): IUseGetActiveBalanceIds {
  const user = useAuthContext(state => state.user);

  const isSharedAccount = !!user?.cards?.sharedAccounts;

  const { userAccounts, isFetchingUserAccounts } = useGetUserAccountsByUserUuid(
    {
      userUuid: user?.cards?.id as string,
      enabled: !isSharedAccount
    }
  );

  const { outsourcedUserAccounts, isFetchingOutsourcedUserAccounts } =
    useGetOutsourcedUserAccounts({
      outsourcedUserUuid: user?.cards?.id as string,
      enabled: isSharedAccount
    });

  const activeAccountBalancesIds = isSharedAccount
    ? outsourcedUserAccounts?.reduce<string[]>((acc, userAccount) => {
        const activeAccountBalanceId = userAccount.balances.find(
          balance => balance.inUse
        )?.id;

        if (activeAccountBalanceId) {
          return [...acc, activeAccountBalanceId];
        }

        return acc;
      }, []) ?? []
    : userAccounts?.reduce<string[]>((acc, userAccount) => {
        const activeAccountBalanceId = userAccount.balances.find(
          balance => balance.inUse
        )?.id;

        if (activeAccountBalanceId) {
          return [...acc, activeAccountBalanceId];
        }

        return acc;
      }, []) ?? [];

  return {
    activeAccountBalancesIds,
    isFetchingActiveBalanceIds:
      isFetchingUserAccounts || isFetchingOutsourcedUserAccounts
  };
}
