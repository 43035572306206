import { useLangContext } from 'data/contexts';

import { CreditCard } from 'presentation/components/base/CreditCard';

import { useCardAndValues } from './useCardAndValues';

import { Container, ValuesWrapper } from './CardAndValues.styles';

export function CardAndValues(): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const {
    currentBalanceBlock,
    formattedTotalExpensesForTheLastThirtyDays,
    cardData,
    isFetchingUserAccountsOrFetchingCardData
  } = useCardAndValues();

  return (
    <Container>
      <ValuesWrapper>
        <div>
          <p>
            {
              lang.cards.statement.sidebar.card_and_values.total_spend[
                currentLangKey
              ]
            }
          </p>
          {formattedTotalExpensesForTheLastThirtyDays}
        </div>
        <div>
          <p>
            {
              lang.cards.statement.sidebar.card_and_values.current_balance[
                currentLangKey
              ]
            }
          </p>
          {currentBalanceBlock}
        </div>
      </ValuesWrapper>
      <CreditCard
        isLoading={isFetchingUserAccountsOrFetchingCardData}
        number={cardData?.panMasked ?? null}
        isBlocked={cardData?.status === 'BLOCKED'}
      />
    </Container>
  );
}
