import { useEffect, useRef, useState } from 'react';

import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetCurrencies } from 'data/modules/currencies';
import {
  type ICreateFuelExpenseFirstStepFieldsForm,
  type IValidateDateLimitsByProjectPayload,
  useGetExpenseParameters,
  useValidateDateLimitsByProject
} from 'data/modules/expenses';
import { useGetExpenseDetails } from 'data/modules/expenses/useCases/get-expense-details/useGetExpenseDetails';
import {
  useGetTypesOfFuels,
  useGetTypesOfVehicles,
  useGetVehiclesByCurrentUser
} from 'data/modules/fuel';
import { useGetPaymentMethods } from 'data/modules/paymentMethods';

import { type ISelectOption } from 'presentation/components/base/Select/Select.types';

import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';
import { TransformArray } from 'shared/utils/global';
import { Validate } from 'shared/utils/validation';

import {
  type IChangeValuesInputsWhenFocus,
  type IUseFirstStepForm,
  type IUseFirstStepFormParams
} from './FirstStepForm.types';

export function useFirstStepForm({
  readOnlyFieldsWhenUserIsUpdatingExpense,
  expenseUuidToUpdate
}: IUseFirstStepFormParams): IUseFirstStepForm {
  const { expenseParameters } = useGetExpenseParameters();

  const inputRef = useRef<HTMLInputElement>(null);

  const changeValuesInputsWhenFocus = useRef<IChangeValuesInputsWhenFocus>({
    inputFocus: '',
    isFilledExpenseValue: false,
    isFilledPricePerLiter: false,
    isFilledQuantityOfLiters: false
  });

  const { control, getValues, setValue, clearErrors, setError } =
    useFormContext<ICreateFuelExpenseFirstStepFieldsForm>();

  const { getEnsuredValidateDateLimitsByProject } =
    useValidateDateLimitsByProject();

  const { t } = useTranslation('expenses');

  const plateWatchValue = useWatch({
    control,
    name: 'licensePlate'
  });

  const {
    append: apportionmentAppend,
    fields: apportionmentFields,
    replace: apportionmentReplace
  } = useFieldArray({
    control,
    name: 'apportionment'
  });

  const { isLoadingVehiclesByCurrentUser, vehiclesByCurrentUser } =
    useGetVehiclesByCurrentUser();

  const licensePlateOptions = TransformArray.objectsToSelectOptions(
    vehiclesByCurrentUser,
    'id',
    'plate',
    true
  );

  const { paymentMethods, isLoadingPaymentMethods } = useGetPaymentMethods();

  const paymentMethodsOptions = TransformArray.objectsToSelectOptions(
    paymentMethods,
    'id',
    'description',
    true
  );

  const { getEnsuredExpenseDetails } = useGetExpenseDetails({
    expenseUuid: '',
    enabled: false
  });

  const fieldVehicleTypeDisabled = !!plateWatchValue;

  const paymentMethodIsSelected = !!getValues('paymentMethod');

  const [fieldRefundableDisabled, setFieldRefundableDisabled] = useState(false);

  useEffect(() => {
    setFieldRefundableDisabled(paymentMethodIsSelected);
  }, [paymentMethodIsSelected]);

  const { currencies, isLoadingCurrencies } = useGetCurrencies();

  const currenciesOptions = TransformArray.objectsToSelectOptions(
    currencies,
    'id',
    'isoCode',
    true
  );

  const { typesOfVehicles, isLoadingTypesOfVehicles } = useGetTypesOfVehicles();

  const typesOfVehiclesOptions = TransformArray.objectsToSelectOptions(
    typesOfVehicles,
    'id',
    'description',
    true
  );

  function handleClickSelectProjectButton(): void {
    const expenseValue = getValues('value');
    const expenseValueAsNumber = Currency.parseToFloat(expenseValue);

    apportionmentAppend({
      project: undefined,
      percentage: 100,
      value: expenseValueAsNumber
    });
  }

  function calculateApportionmentValues(
    expenseValueAsPositiveNumber: number
  ): void {
    const apportionmentValue = getValues('apportionment');
    const countExistingProjects = apportionmentValue?.length || 0;

    if (countExistingProjects === 0) {
      return;
    }

    if (countExistingProjects === 1) {
      setValue(
        'apportionment',
        apportionmentValue?.map(item => {
          return {
            ...item,
            percentage: 100,
            value: expenseValueAsPositiveNumber
          };
        })
      );
      return;
    }

    // se existir mais de um projeto, a porcentagem é 100 dividido pela quantidade de projetos
    const calculatedPercentage = 100 / countExistingProjects;
    const percentageForExistingProjects =
      Currency.roundToTwoDecimals(calculatedPercentage);

    // se existir mais de um projeto, o valor é o valor da despesa dividido pela quantidade de projetos
    const calculatedValue =
      expenseValueAsPositiveNumber / countExistingProjects;
    const valueForExistingProjects =
      Currency.roundToTwoDecimals(calculatedValue);

    // para cada projeto, o valor e a porcentagem são os resultados do cálculo acima
    const newFields = apportionmentValue?.map(item => {
      return {
        ...item,
        percentage: percentageForExistingProjects,
        value: valueForExistingProjects
      };
    });

    // para o último projeto, a porcentagem é o valor da despesa menos a soma das porcentagens dos outros projetos
    // isso é necessário para que a soma das porcentagens seja 100% e não tenha diferença de arredondamento
    const percentageDiff =
      percentageForExistingProjects * (countExistingProjects - 1);

    const percentageForLastProject = Currency.subtractTwoDecimals(
      100,
      percentageDiff
    );

    // para o último projeto, o valor é o valor da despesa menos a soma dos valores dos outros projetos
    // isso é necessário para que a soma dos valores seja o valor da despesa e não tenha diferença de arredondamento
    const valueDiff = valueForExistingProjects * (countExistingProjects - 1);

    const valueForLastProject = Currency.subtractTwoDecimals(
      expenseValueAsPositiveNumber,
      valueDiff
    );

    // aqui nunca vai ser undefined, mas o typescript não sabe disso
    if (newFields) {
      // coloca os valores calculados para o último projeto
      newFields[countExistingProjects - 1].percentage =
        percentageForLastProject;
      newFields[countExistingProjects - 1].value = valueForLastProject;
    }

    setValue('apportionment', newFields);
  }

  function changeLitersValuesByExpenseValue(expenseValue: number): void {
    if (expenseValue === 0) {
      return;
    }

    const pricePerLiterAsNumber = Currency.parseToFloat(
      getValues('pricePerLiter')
    );
    const quantityOfLitersAsNumber = Currency.parseToFloat(
      getValues('quantityOfLiters')
    );

    if (
      !changeValuesInputsWhenFocus.current.isFilledPricePerLiter &&
      !changeValuesInputsWhenFocus.current.isFilledQuantityOfLiters
    ) {
      return;
    }

    if (changeValuesInputsWhenFocus.current.isFilledQuantityOfLiters) {
      const calculatedValue = expenseValue / quantityOfLitersAsNumber;
      const valueForLiters = Currency.roundToTwoDecimals(calculatedValue);

      setValue('pricePerLiter', Currency.format('BRL', valueForLiters));
      clearErrors('pricePerLiter');
      return;
    }

    if (changeValuesInputsWhenFocus.current.isFilledPricePerLiter) {
      const calculatedValue = expenseValue / pricePerLiterAsNumber;
      const valueForLiters = Currency.roundToTwoDecimals(calculatedValue);

      setValue('quantityOfLiters', Currency.format('BRL', valueForLiters));
      clearErrors('quantityOfLiters');
    }
  }

  async function handleChangeExpenseValue(value: string): Promise<void> {
    const expenseValueAsNumber = Currency.parseToFloat(value);
    const expenseValueAsPositiveNumber = Math.abs(expenseValueAsNumber);

    // usuário está editando uma despesa
    if (expenseUuidToUpdate) {
      const expenseToUpdate = await getEnsuredExpenseDetails({
        expenseUuid: expenseUuidToUpdate
      });

      if (
        expenseToUpdate.transaction &&
        expenseToUpdate.transaction.amount &&
        expenseValueAsPositiveNumber > expenseToUpdate.transaction.amount
      ) {
        setError('value', {
          type: 'max-value',
          message: t('errors.expenseAmountCannotExceedTransactionValue', {
            currency: expenseToUpdate.currency.isoCode,
            value: Currency.format('BRL', expenseToUpdate.transaction.amount)
          })
        });
      }

      if (
        expenseToUpdate.transaction &&
        expenseToUpdate.transaction.amount &&
        expenseValueAsPositiveNumber <= expenseToUpdate.transaction.amount
      ) {
        clearErrors('value');
      }
    }

    changeLitersValuesByExpenseValue(expenseValueAsNumber);

    calculateApportionmentValues(expenseValueAsNumber);
  }

  async function validateDateLimitsPolicyByUser(
    selectedDate: string,
    futureDaysLimit: number | null,
    pastDaysLimit: number | null
  ): Promise<void> {
    if (expenseUuidToUpdate === undefined) {
      return;
    }

    const expenseToUpdate = await getEnsuredExpenseDetails({
      expenseUuid: expenseUuidToUpdate
    });

    // transforma a data de criação da despesa em ISO (yyyy-MM-dd)
    const createdAtDateInIsoFormat = CustomDate.formatFromISO(
      expenseToUpdate.createdAt,
      'yyyy-MM-dd'
    );

    // transforma yyyy-MM-dd em Date
    const expenseCreatedAtDate = CustomDate.parseIsoToDate(
      createdAtDateInIsoFormat
    );

    // pega a diferença de dias entre a data de criação da despesa e a data selecionada
    const differenceBetweenDates = CustomDate.differenceBetweenDates(
      expenseCreatedAtDate,
      CustomDate.parseIsoToDate(selectedDate)
    );

    // pega a diferença absoluta entre a data de criação da despesa e a data selecionada
    const absoluteDifferenceBetweenDates = Math.abs(differenceBetweenDates);

    // se a diferença de datas for um número negativo e a diferença absoluta for maior que o limite de dias futuros
    // então a despesa excede o limite de dias futuros
    if (
      futureDaysLimit !== null &&
      differenceBetweenDates < 0 &&
      absoluteDifferenceBetweenDates > futureDaysLimit
    ) {
      const exceedInDays = absoluteDifferenceBetweenDates - futureDaysLimit;

      setError('date', {
        type: 'validated-by-user-policy',
        message: t('errors.expenseExceedsTheMaximumAmountOfFutureDays', {
          days: exceedInDays
        })
      });
      return;
    }

    // se a diferença de datas for um número positivo e a diferença absoluta for maior que o limite de dias passados
    // então a despesa excede o limite de dias passados
    if (
      pastDaysLimit !== null &&
      absoluteDifferenceBetweenDates > pastDaysLimit
    ) {
      const exceedInDays = absoluteDifferenceBetweenDates - pastDaysLimit;

      setError('date', {
        type: 'validated-by-user-policy',
        message: t('errors.expenseExceedsTheMaximumAmountOfDaysPassed', {
          days: exceedInDays
        })
      });
      return;
    }

    clearErrors('date');
  }

  function handleChangeLicensePlate(vehicleId: string): void {
    const selectedVehicle = vehiclesByCurrentUser?.find(
      vehicle => String(vehicle.id) === vehicleId
    );

    setValue('fuelType', null);
    clearErrors('fuelType');

    const newVehicleType = selectedVehicle?.vehicleType.id;

    setValue('vehicleType', newVehicleType ? String(newVehicleType) : '');
    clearErrors('vehicleType');
  }

  function handleChangeQuantityOfLiters(value: string): void {
    const quantityOfLitersAsNumber = Currency.parseToFloat(value);

    if (quantityOfLitersAsNumber === 0) {
      return;
    }

    const totalValueAsNumber = Currency.parseToFloat(getValues('value'));
    const pricePerLiterAsNumber = Currency.parseToFloat(
      getValues('pricePerLiter')
    );

    if (
      !changeValuesInputsWhenFocus.current.isFilledExpenseValue &&
      !changeValuesInputsWhenFocus.current.isFilledPricePerLiter
    ) {
      return;
    }

    if (changeValuesInputsWhenFocus.current.isFilledExpenseValue) {
      const calculatedValue = totalValueAsNumber / quantityOfLitersAsNumber;
      const valueForLiters = Currency.roundToTwoDecimals(calculatedValue);

      setValue('pricePerLiter', Currency.format('BRL', valueForLiters));
      clearErrors('pricePerLiter');
      return;
    }

    if (changeValuesInputsWhenFocus.current.isFilledPricePerLiter) {
      const calculatedValue = pricePerLiterAsNumber * quantityOfLitersAsNumber;
      const valueForTotal = Currency.roundToTwoDecimals(calculatedValue);

      setValue('value', Currency.format('BRL', valueForTotal));
      clearErrors('value');
    }
  }

  function handleChangePricePerLiter(value: string): void {
    const pricePerLiterAsNumber = Currency.parseToFloat(value);

    if (pricePerLiterAsNumber === 0) {
      return;
    }

    const totalValueAsNumber = Currency.parseToFloat(getValues('value'));
    const quantityOfLitersAsNumber = Currency.parseToFloat(
      getValues('quantityOfLiters')
    );

    if (
      !changeValuesInputsWhenFocus.current.isFilledExpenseValue &&
      !changeValuesInputsWhenFocus.current.isFilledQuantityOfLiters
    ) {
      return;
    }

    if (changeValuesInputsWhenFocus.current.isFilledExpenseValue) {
      const calculatedValue = totalValueAsNumber / pricePerLiterAsNumber;
      const valueForLiters = Currency.roundToTwoDecimals(calculatedValue);

      setValue('quantityOfLiters', Currency.format('BRL', valueForLiters));
      clearErrors('quantityOfLiters');
      return;
    }

    if (changeValuesInputsWhenFocus.current.isFilledQuantityOfLiters) {
      const calculatedValue = pricePerLiterAsNumber * quantityOfLitersAsNumber;
      const valueForTotal = Currency.roundToTwoDecimals(calculatedValue);

      setValue('value', Currency.format('BRL', valueForTotal));
      clearErrors('value');
    }
  }

  async function handleChangeDate(value: string | undefined): Promise<void> {
    if (value === undefined) {
      return;
    }

    // se o parâmetro de política de despesa for USUARIO e estiver editando uma despesa e algum dos parâmetros de dias futuros ou passados for diferente de null
    if (
      expenseParameters?.policyType === 'USUARIO' &&
      expenseUuidToUpdate &&
      (expenseParameters.daysPolicy.futureDays !== null ||
        expenseParameters.daysPolicy.pastDays !== null)
    ) {
      await validateDateLimitsPolicyByUser(
        value,
        expenseParameters.daysPolicy.futureDays,
        expenseParameters.daysPolicy.pastDays
      );
    }

    if (expenseParameters?.policyType !== 'PROJETO') {
      return;
    }

    if (Validate.isDate(value, 'yyyy-mm-dd')) {
      const apportionment = getValues('apportionment');

      const filteredApportionment = apportionment
        ? apportionment.filter(item => item.project !== undefined)
        : [];

      if (filteredApportionment.length === 0) {
        clearErrors('date');
        return;
      }

      const policyByProjectQueryPayload: IValidateDateLimitsByProjectPayload = {
        date: value,
        projects: filteredApportionment.map(item => {
          return {
            id: item.project?.value as string,
            percentage: item.percentage
          };
        }),
        expenseUuid: expenseUuidToUpdate
      };

      const checkPolicyByProject = await getEnsuredValidateDateLimitsByProject(
        policyByProjectQueryPayload
      );

      if (checkPolicyByProject !== null && !checkPolicyByProject.success) {
        if (checkPolicyByProject.daysLimitAgo !== null) {
          setError('date', {
            type: 'validated-by-project',
            message: t('errors.expenseExceedsTheMaximumAmountOfDaysPassed', {
              days: checkPolicyByProject.daysLimitAgo
            })
          });
        }

        if (checkPolicyByProject.daysLimitAhead !== null) {
          setError('date', {
            type: 'validated-by-project',
            message: t('errors.expenseExceedsTheMaximumAmountOfFutureDays', {
              days: checkPolicyByProject.daysLimitAhead
            })
          });
        }

        return;
      }

      clearErrors('date');
    }
  }

  function handleChangePaymentMethod(paymentMethodId: string): void {
    if (!paymentMethodId) {
      setFieldRefundableDisabled(false);
      return;
    }

    const searchedPaymentMethod = paymentMethods?.find(
      paymentMethod => paymentMethod.id.toString() === paymentMethodId
    );

    if (searchedPaymentMethod === undefined) {
      setFieldRefundableDisabled(false);
      return;
    }

    const isPaymentMethodRefundable = searchedPaymentMethod?.refundable;

    setValue('refundable', isPaymentMethodRefundable ?? false);
    setFieldRefundableDisabled(true);
  }

  const updatedPaymentMethodsOptions =
    // se o campo de formas de pagamento estiver desabilitado, não remove a opção de Cartão VExpenses
    readOnlyFieldsWhenUserIsUpdatingExpense.has('paymentMethod')
      ? paymentMethodsOptions
      : paymentMethodsOptions.filter(
          paymentMethod => paymentMethod.label !== 'Cartão VExpenses'
        );

  const observationAttachments = getValues('observationAttachments');

  const showObservationsAttachmentsIcon =
    expenseParameters?.hasObservationAttachment ||
    (observationAttachments !== undefined && observationAttachments.length > 0);

  function handleInputFocus(
    input: IChangeValuesInputsWhenFocus['inputFocus']
  ): void {
    const quantityOfLitersAsNumber = Currency.parseToFloat(
      getValues('quantityOfLiters')
    );
    const totalValueAsNumber = Currency.parseToFloat(getValues('value'));
    const pricePerLiterAsNumber = Currency.parseToFloat(
      getValues('pricePerLiter')
    );

    changeValuesInputsWhenFocus.current = {
      inputFocus: input,
      isFilledExpenseValue: totalValueAsNumber !== 0,
      isFilledPricePerLiter: pricePerLiterAsNumber !== 0,
      isFilledQuantityOfLiters: quantityOfLitersAsNumber !== 0
    };
  }

  const { typesOfFuels, isLoadingTypesOfFuels } = useGetTypesOfFuels({
    vehicleId: plateWatchValue ? Number(plateWatchValue) : undefined,
    withSubtypes: true
  });

  const fuelTypesMenuOptions = TransformArray.objectsToSelectOptions(
    typesOfFuels,
    'id',
    'description',
    true
  );

  const fuelSubTypes = typesOfFuels?.map(item => item.subTypes || []) || [];

  const fuelSubTypesOptions: ISelectOption[][] =
    fuelSubTypes?.map(
      item =>
        item?.map(subType => {
          return {
            value: subType.id.toString(),
            label: subType.description
          };
        }) || []
    ) || [];

  return {
    paymentMethodsOptions: updatedPaymentMethodsOptions,
    isLoadingExpensePaymentMethods: isLoadingPaymentMethods,
    isLoadingTypesOfVehicles,
    typesOfVehiclesOptions,
    licensePlateOptions,
    isLoadingLicensePlates: isLoadingVehiclesByCurrentUser,
    expenseParameters,
    fuelTypesMenuOptions,
    fuelSubTypesOptions,
    isLoadingTypesOfFuels,
    handleChangeLicensePlate,
    handleChangePricePerLiter,
    handleChangeQuantityOfLiters,
    currenciesOptions,
    handleInputFocus,
    isLoadingCurrencies,
    handleClickSelectProjectButton,
    showObservationsAttachmentsIcon,
    fieldVehicleTypeDisabled,
    apportionmentFields,
    apportionmentReplace,
    handleChangeExpenseValue,
    handleChangePaymentMethod,
    fieldRefundableDisabled,
    handleChangeDate,
    inputRef
  };
}
