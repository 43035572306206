import { SkeletonLoader } from 'ds';
import { TooltipOverflowContent } from 'ds/components/TooltipOverflowContent';

import { useSelectDynamicControlled } from './useSelectDynamicControlled';

import {
  type ISelectDynamicControlledOption,
  type ISelectDynamicControlledProps
} from './SelectDynamicControlled.types';

import {
  Caption,
  Container,
  Fieldset,
  Label,
  Legend,
  StyledSelect,
  Wrapper
} from './SelectDynamicControlled.styles';

export function SelectDynamicControlled({
  name,
  options,
  disabled,
  className,
  placeholder,
  noOptionsMessage,
  hasArrowDownIndicator = false,
  isLoading = false,
  label,
  large = false,
  small = false,
  menuPlacement = 'bottom',
  isLoadingMoreOptions,
  onChangeSearchValue,
  onMenuScrollToBottom,
  searchValue,
  langKey = 'pt',
  onCustomChange
}: ISelectDynamicControlledProps): JSX.Element {
  const {
    DropdownIndicator,
    LoadingIndicator,
    placeholderMessage,
    loadingMessage,
    noMoreOptionsMessage,
    error,
    onChange,
    optionsToRender,
    ref,
    value: selectedValue
  } = useSelectDynamicControlled({
    langKey,
    placeholder,
    noOptionsMessage,
    name,
    options,
    searchValue
  });

  return (
    <Container>
      {isLoading ? (
        <SkeletonLoader height='3rem' />
      ) : (
        <div>
          <Wrapper
            $small={small}
            $large={large}
          >
            <StyledSelect
              $large={large}
              $error={!!error?.message}
              $small={small}
              $hasArrowDownIndicator={hasArrowDownIndicator}
              $isTouched={!!selectedValue}
              $disabled={disabled}
              components={{ DropdownIndicator, LoadingIndicator }}
              name={name}
              isLoading={isLoadingMoreOptions}
              captureMenuScroll
              ref={ref}
              classNamePrefix='react-select-custom'
              placeholder={placeholderMessage}
              menuPlacement={menuPlacement}
              isDisabled={disabled}
              options={optionsToRender}
              className={className}
              isOptionSelected={(option: unknown) => {
                const typedOption = option as ISelectDynamicControlledOption;
                return (
                  !!selectedValue && selectedValue.value === typedOption.value
                );
              }}
              // como o filtro é feito no backend, não é necessário filtrar aqui
              filterOption={() => true}
              value={selectedValue}
              onChange={(newValue: unknown) => {
                const typedNewValue =
                  newValue as ISelectDynamicControlledOption;
                onChange(typedNewValue);
                onCustomChange?.(typedNewValue);
              }}
              noOptionsMessage={() => noMoreOptionsMessage}
              loadingMessage={() => loadingMessage}
              onMenuScrollToBottom={onMenuScrollToBottom}
              inputValue={searchValue}
              isClearable
              onInputChange={(value: string) => {
                onChangeSearchValue(value);
              }}
            />

            <Fieldset>
              <Legend>
                <span>{label}</span>
              </Legend>
            </Fieldset>

            {label && (
              <TooltipOverflowContent
                tooltipMessage={label}
                placement='top'
                zIndex={3}
              >
                <Label>{label}</Label>
              </TooltipOverflowContent>
            )}
          </Wrapper>

          {error?.message && <Caption $error={true}>{error?.message}</Caption>}
        </div>
      )}
    </Container>
  );
}
