import { useEffect, useState } from 'react';

import { useCopyToClipboard } from 'shared/hooks/global';

import { type IUseCopyTransactionIdToClipboard } from './useCopyTransactionIdToClipboard.types';

export function useCopyTransactionIdToClipboard(): IUseCopyTransactionIdToClipboard {
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

  const [, handleCopyToClipboard] = useCopyToClipboard();

  async function handleCopyTransactionIdToClipboard(
    text: string
  ): Promise<void> {
    try {
      await handleCopyToClipboard(text);

      setShouldShowTooltip(true);
    } catch {
      setShouldShowTooltip(false);
    }
  }

  useEffect(() => {
    if (shouldShowTooltip) {
      setTimeout(() => {
        setShouldShowTooltip(false);
      }, 3000);
    }
  }, [shouldShowTooltip]);

  return {
    handleCopyTransactionIdToClipboard,
    shouldShowTooltip
  };
}
