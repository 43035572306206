import { useEffect, useState } from 'react';

import { Numeric } from 'ds';
import { useController, useFormContext } from 'react-hook-form';

import {
  type IUseCurrencyInputControlled,
  type IUseCurrencyInputControlledParams
} from './CurrencyInputControlled.types';

export function useCurrencyInputControlled({
  name,
  onCustomChange,
  allowNegative
}: IUseCurrencyInputControlledParams): IUseCurrencyInputControlled {
  const { control } = useFormContext();

  const {
    field: { value, onChange },
    fieldState: { error: errorMessage }
  } = useController({
    name,
    control
  });

  // controla se vai mostrar o hífen de valor negativo ou não
  const [showHyphen, setShowHyphen] = useState(false);

  useEffect(() => {
    // se o valor for menor que 0, o hífen não estiver sendo mostrado e for permitido valor negativo, mostra o hífen
    if (value < 0 && !showHyphen && allowNegative) {
      setShowHyphen(true);
    }
  }, [value, showHyphen, allowNegative]);

  function handleChangeValue(value: string): void {
    let isNegative = false;

    if (allowNegative) {
      if (value.length > 0 && value[0] === '-') {
        setShowHyphen(true);
        isNegative = true;
      } else {
        setShowHyphen(false);
        isNegative = false;
      }
    }

    // pega o valor do input e transforma em número
    const valueAsNumber = Numeric.unformat(value);
    // se o valor for negativo, o valor que vai ser salvo no estado é o valor * -1
    // só vai ser salvo o valor negativo se não for 0. Se for 0, o valor salvo é 0
    onChange(
      isNegative && valueAsNumber !== 0 ? -valueAsNumber : valueAsNumber
    );
    onCustomChange?.(
      isNegative && valueAsNumber !== 0 ? -valueAsNumber : valueAsNumber
    );
  }

  const formattedValue =
    value === 0 ? '' : Numeric.format('BRL', Math.abs(value));

  const valueToShowOnInput = showHyphen ? `-${formattedValue}` : formattedValue;

  return {
    errorMessage: errorMessage?.message ?? '',
    handleChangeValue,
    valueToShowOnInput
  };
}
