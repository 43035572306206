import {
  type IModifiedBalanceAllocationItem,
  type IPersistenceModifiedBalanceAllocationItem
} from 'data/modules/cards/balance';

class ModifiedBalanceAllocationItemMapper {
  toDomain(
    balanceAllocationItem: IPersistenceModifiedBalanceAllocationItem
  ): IModifiedBalanceAllocationItem {
    return {
      balanceAccountId: balanceAllocationItem.balance_account_id,
      amount: balanceAllocationItem.amount,
      type: balanceAllocationItem.type,
      cardNumber: balanceAllocationItem.card_number,
      userName: balanceAllocationItem.user_name,
      cardType: balanceAllocationItem.card_type,
      currentValue: balanceAllocationItem.current_value,
      status: balanceAllocationItem.status,
      accountId: balanceAllocationItem.account_id,
      userId: balanceAllocationItem.user_id
    };
  }

  toPersistence(
    balanceAllocationItem: IModifiedBalanceAllocationItem
  ): IPersistenceModifiedBalanceAllocationItem {
    return {
      balance_account_id: balanceAllocationItem.balanceAccountId,
      amount: balanceAllocationItem.amount,
      type: balanceAllocationItem.type,
      card_number: balanceAllocationItem.cardNumber,
      user_name: balanceAllocationItem.userName,
      card_type: balanceAllocationItem.cardType,
      current_value: balanceAllocationItem.currentValue,
      status: balanceAllocationItem.status,
      account_id: balanceAllocationItem.accountId,
      user_id: balanceAllocationItem.userId
    };
  }
}

export default new ModifiedBalanceAllocationItemMapper();
