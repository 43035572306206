import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useLangContext, useValueSolicitationContext } from 'data/contexts';
import {
  BalanceQueryKeys,
  type IBalanceSolicitationApproved,
  type IUnallocatedBalance,
  useApproveBalanceSolicitations,
  useGetUnallocatedBalance
} from 'data/modules/cards/balance';

import { type IUseSolicitationActionsList } from './SolicitationActionsList.types';

export function useSolicitationActionsList({
  onBalanceSolicitationApprovalsSummaryData
}: {
  onBalanceSolicitationApprovalsSummaryData: (
    data: IBalanceSolicitationApproved
  ) => void;
}): IUseSolicitationActionsList {
  const { groupId } = useParams();

  const [selectedSolicitationIdsList, step, requestedAmount, handleChangeStep] =
    useValueSolicitationContext(
      useShallow(state => [
        state.selectedSolicitationIdsList,
        state.step,
        state.requestedAmount,
        state.handleChangeStep
      ])
    );

  const {
    currentLangKey,
    lang: {
      cards: { valueSolicitation }
    }
  } = useLangContext();

  const queryClient = useQueryClient();

  const cachedUnnalocatedBalanceAmount =
    queryClient.getQueryData<IUnallocatedBalance>([
      BalanceQueryKeys.GET_UNALLOCATED_BALANCE,
      { ...(groupId ? { cardGroupId: groupId } : { cardGroupId: null }) }
    ])?.amount;

  const { isFetchingUnallocatedBalance, unallocatedBalance } =
    useGetUnallocatedBalance({
      enabled: !cachedUnnalocatedBalanceAmount,
      ...(groupId && { cardGroupId: groupId })
    });

  const {
    handleApproveBalanceSolicitations,
    isSendingBalanceSolicationApproved
  } = useApproveBalanceSolicitations({
    onBalanceSolicitationApprovalsSummaryData
  });

  function handleSubmit(): void {
    const currentBalance =
      cachedUnnalocatedBalanceAmount || unallocatedBalance?.amount || 0;
    if (
      (step === 'approve' || step === 'transfer') &&
      currentBalance - requestedAmount < 0
    ) {
      toast.error(
        valueSolicitation.value_greater_than_available[currentLangKey]
      );

      return;
    }

    const mapToSend = selectedSolicitationIdsList?.map(balanceSolicitation => {
      return balanceSolicitation.managerJustify
        ? {
            id: balanceSolicitation.id,
            amount: null,
            approve: step !== 'disapprove',
            manager_justify: balanceSolicitation.managerJustify
          }
        : {
            id: balanceSolicitation.id,
            amount:
              step === 'disapprove'
                ? null
                : balanceSolicitation.requestedAmount,
            approve: step !== 'disapprove'
          };
    });

    if (mapToSend?.length) {
      handleApproveBalanceSolicitations(mapToSend);
    }
  }

  useEffect(() => {
    if (selectedSolicitationIdsList.length === 0) {
      handleChangeStep('solicitation');
    }
  }, [selectedSolicitationIdsList.length, handleChangeStep]);

  return {
    handleSubmit,
    isSendingBalanceSolicationApproved,
    isFetchingUnallocatedBalance
  };
}
