import { useQueryCache } from 'data/cache';
import {
  type IUseGetPasswordPolicy,
  PasswordPolicyQueryKeys,
  PasswordPolicyService
} from 'data/modules/settings/passwordPolicy';

export function useGetPasswordPolicy(): IUseGetPasswordPolicy {
  const { data, isLoading } = useQueryCache({
    queryKey: [PasswordPolicyQueryKeys.GET_PASSWORD_POLICY],
    queryFn: async () => await PasswordPolicyService.getPasswordPolicy()
  });

  return {
    passwordPolicy: data,
    isLoadingPasswordPolicy: isLoading
  };
}
