import { ActionButton, DefaultButton, Modal, SkeletonLoader } from 'ds';
import { useTranslation } from 'react-i18next';

import { useLangContext, useModalContext } from 'data/contexts';
import { useGetAuthUser } from 'data/modules/auth';
import { useGetExpenseParameters } from 'data/modules/expenses';

import { type ISelectExpenseToAddModalProps } from './SelectExpenseToAddModal.types';

import { ButtonsContainer } from './SelectExpenseToAddModal.styles';

export function SelectExpenseToAddModal({
  onCreateExpense
}: ISelectExpenseToAddModalProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  const { company, isFetchingAuthUser } = useGetAuthUser();

  const { expenseParameters, isLoadingExpenseParameters } =
    useGetExpenseParameters();

  const { t } = useTranslation('global');

  const { handleCloseModal } = useModalContext();

  return (
    <Modal.Container onClose={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>
          {
            lang.expenses.modal.select_expense_to_add
              .select_what_you_want_to_add[currentLangKey]
          }
        </Modal.Title>
        <Modal.IconClose onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Content>
        <ButtonsContainer>
          <ActionButton
            icon='PaymentsFinanceIcon'
            label={
              lang.expenses.modal.select_expense_to_add.manual_expense[
                currentLangKey
              ]
            }
            onClick={() => {
              onCreateExpense('createManualExpense');
            }}
          />

          {isLoadingExpenseParameters || isFetchingAuthUser ? (
            <SkeletonLoader
              rows={2}
              height='7rem'
              multipleLoadersContainerClassName='skeleton-loader-container'
            />
          ) : (
            <>
              {expenseParameters?.allowAddManualRoute && (
                <ActionButton
                  icon='PinLocationAddPlusCircleIcon'
                  label={
                    lang.expenses.modal.select_expense_to_add.manual_route[
                      currentLangKey
                    ]
                  }
                  onClick={() => {
                    onCreateExpense('createManualRouteExpense');
                  }}
                />
              )}

              {expenseParameters?.allowAddRouteWithMap && (
                <ActionButton
                  icon='PinLocationMapIcon'
                  label={
                    lang.expenses.modal.select_expense_to_add.route_by_map[
                      currentLangKey
                    ]
                  }
                  onClick={() => {
                    onCreateExpense('createRouteByMap');
                  }}
                />
              )}
            </>
          )}

          {company?.parameters.usesFuelsModule && (
            <ActionButton
              icon='CarGasStationIcon'
              label={t('fuel')}
              onClick={() => {
                onCreateExpense('createFuelExpense');
              }}
            />
          )}

          {/* <ActionButton
            icon='BarcodeScanSelectIcon'
            label={`${lang.expenses.modal.select_expense_to_add.receipt_reading[currentLangKey]} - Intelliscan`}
            onClick={() => {
              // abrir modal de adicionar despesa pelo intelliscan
            }}
          /> */}
        </ButtonsContainer>
      </Modal.Content>
      <Modal.Footer>
        <DefaultButton
          size='small'
          onClick={handleCloseModal}
        >
          {lang.global.close[currentLangKey]}
        </DefaultButton>
      </Modal.Footer>
    </Modal.Container>
  );
}
