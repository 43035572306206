import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useAuthContext, useFlagsContext } from 'data/contexts';

import { type ISettingsSidebarItemProps } from 'presentation/components/base/SettingsSidebar/SettingsSidebarItem/SettingsSidebarItem.types';

import {
  type ISidebarRoutesItem,
  type IUseSettingsSidebar
} from './SettingsSidebar.types';

export default function useSettingsSidebar(): IUseSettingsSidebar {
  const [company, user] = useAuthContext(
    useShallow(state => [state.company, state.user])
  );
  const { t } = useTranslation(['settings', 'fuel']);
  const { teamMembersFlag } = useFlagsContext();

  const isAccountManager = user?.cards?.type === 'ACCOUNT_MANAGER';
  const accessToCards = company?.parameters.usesCards && isAccountManager;
  const isAdmin = user?.userType === 'ADMINISTRADOR';
  const companyAccessTeamMembers =
    company?.parameters.usesManagerAccessTeamMember;

  // Verifica as rotas que o usuário pode acessar
  function createAllowedRoutes(
    routes: ISidebarRoutesItem[]
  ): ISettingsSidebarItemProps[] {
    return routes
      .filter(({ condition }) => condition)
      .map(({ condition, ...rest }) => ({ ...rest }));
  }

  // Lista de Dados Pessoais
  const personalDataSubItems: ISidebarRoutesItem[] = [
    {
      condition: true,
      title: t('sidebar.myData'),
      url: '/settings/meus-dados'
    },
    {
      condition: !!(company && !company?.parameters.usesActiveDirectory),
      title: t('sidebar.changePassword'),
      url: '/settings/mudar-senha'
    },
    {
      condition: isAdmin,
      title: t('sidebar.emailPreferences'),
      url: '/settings/preferencias-de-email',
      isReactRoute: true
    }
  ];

  // Cria a lista de rotas de dados pessoais que o usuário pode acessar
  const personalDataRoutesAllowed = createAllowedRoutes(personalDataSubItems);

  // Lista de itens de cartões
  const cardsSubItems: ISidebarRoutesItem[] = [
    {
      condition: company?.cards?.parameters?.useSharedBusinessAccount,
      title: t('sidebar.sharedAccounts'),
      tooltip: t('sidebar.sharedAccountsTooltip'),
      url: '/settings/cartoes/cartoes-empresariais',
      isReactRoute: true
    },
    {
      condition: company?.cards?.parameters?.useSharedBusinessAccount,
      title: t('sidebar.businessAccountHolders'),
      tooltip: t('sidebar.businessAccountHoldersTooltip'),
      url: '/settings/cartoes/portadores-pj',
      isReactRoute: true
    },
    {
      condition: accessToCards,
      title: t('sidebar.tariffs'),
      tooltip: t('sidebar.tariffsTooltip'),
      url: '/settings/cartoes/tarifas',
      isReactRoute: true
    },
    {
      condition: accessToCards,
      title: t('sidebar.termsOfUse'),
      tooltip: t('sidebar.termsOfUseTooltip'),
      url: '/settings/cartoes/termos-de-uso',
      isReactRoute: true
    }
  ];

  // Cria a lista de rotas de cartões que o usuário pode acessar
  const cardsRoutesAllowed = createAllowedRoutes(cardsSubItems);

  // Lista de itens da empresa
  const companySubItems: ISidebarRoutesItem[] = [
    {
      condition: isAdmin,
      title: t('sidebar.company'),
      url: '/settings/empresa'
    },
    {
      condition: company?.parameters.showCustomFields,
      title: t('sidebar.customFields'),
      url: '/settings/campos-personalizados'
    },
    {
      condition: isAdmin,
      title: t('sidebar.departmentsAndPositions'),
      url: '/settings/departamentos'
    },
    {
      condition:
        (company && !companyAccessTeamMembers) ??
        (companyAccessTeamMembers && !!user?.parameters.allowAccessTeamMember),
      title: t('sidebar.teamMembers'),
      url: '/settings/membros-de-equipe',
      isReactRoute: teamMembersFlag
    },
    {
      condition: company?.parameters.showCostCenterSettings,
      title: t('sidebar.costCenters'),
      url: '/settings/centros-de-custos'
    },
    {
      condition: company?.parameters.showProjectSettings,
      title: t('sidebar.projects'),
      url: '/settings/projetos'
    },
    {
      condition: isAdmin,
      title: t('sidebar.kmPolicy'),
      url: '/settings/trechos'
    },
    {
      condition: company?.parameters.showExpenseTypeSettings,
      title: t('sidebar.expenseTypes'),
      url: '/settings/tipos'
    },
    {
      condition: isAdmin,
      title: t('sidebar.expensePolicies'),
      url: '/settings/limites-de-despesa'
    },
    {
      condition: company?.parameters.showPaymentMethodSettings,
      title: t('sidebar.paymentMethods'),
      url: '/settings/formas-pagamento'
    },
    {
      condition: company?.parameters.showApprovalFlowSettings,
      title: t('sidebar.approvalFlows'),
      url: '/settings/fluxos-aprovacao'
    },
    {
      condition: isAdmin,
      title: t('sidebar.integrations'),
      url: '/settings/integracoes'
    },
    {
      condition: company?.parameters.usesHorus,
      title: t('sidebar.horus'),
      url: '/settings/horus'
    },
    {
      condition: accessToCards,
      title: t('sidebar.cards'),
      subItems: cardsRoutesAllowed
    },
    {
      condition: !company?.testPeriod,
      title: t('sidebar.financial'),
      url: '/settings/financeiro',
      warning: !!(company && company?.planDaysRemaining <= -13)
    }
  ];

  // Cria a lista de rotas de dados da empresa que o usuário pode acessar
  const companyRoutesAllowed = createAllowedRoutes(companySubItems);

  // Todas as possíveis rotas de Settings
  const settingsSidebarRoutes: ISidebarRoutesItem[] = [
    {
      condition: isAdmin,
      title: t('sidebar.settingsOverview'),
      url: '/settings'
    },
    {
      condition: true,
      title: t('sidebar.myCompanyExpensePolicy'),
      url: '/settings/documento-politicas'
    },
    {
      condition: true,
      title: t('sidebar.personalData'),
      subItems: personalDataRoutesAllowed
    },
    {
      condition:
        !isAdmin &&
        isAccountManager &&
        companyAccessTeamMembers &&
        !!user?.parameters.allowAccessTeamMember,
      title: t('sidebar.teamMembers'),
      url: '/settings/membros-de-equipe'
    },
    {
      condition: accessToCards && !isAdmin,
      title: t('sidebar.cards'),
      subItems: cardsRoutesAllowed
    },
    {
      condition: isAdmin,
      title: t('sidebar.companyData'),
      subItems: companyRoutesAllowed
    },
    {
      condition: isAdmin && company?.parameters.usesFuelsModule,
      title: t('fuel:fuel'),
      subItems: [
        {
          title: t('fuel:vehicleRegistration'),
          isReactRoute: true,
          url: '/settings/combustivel/veiculos'
        },
        {
          title: t('fuel:policies'),
          isReactRoute: true,
          url: '/settings/combustivel/politicas'
        }
      ]
    }
  ];

  // Cria a lista de rotas que o usuário pode acessar
  const allowedSettingsSidebarRoutes = createAllowedRoutes(
    settingsSidebarRoutes
  );

  return { allowedSettingsSidebarRoutes };
}
