import { useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { useLangContext, useModalContext } from 'data/contexts';
import {
  AccountHoldersQueryKeys,
  type IAccountHolder,
  type IGetAccountHolders,
  useGetAccountHolders
} from 'data/modules/cards/accountHolders';
import { type PaginationType } from 'data/modules/global';

import { RectButton } from 'presentation/components/base/Button';

import { localStorageKeys } from 'shared/constants/global';
import { Mask } from 'shared/utils/format';

import {
  type IStateAccountHoldersPayload,
  type IUseBusinessAccountHolders
} from './BusinessAccountHolders.types';

import { ButtonContainer } from './BusinessAccountHolders.styles';

export function useBusinessAccountHolders(): IUseBusinessAccountHolders {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { handleOpenModal } = useModalContext();

  const queryClient = useQueryClient();

  const pageSizeStorage = localStorage.getItem(localStorageKeys.pageSize);

  const [accountHoldersPayload, setAccountHoldersPayload] =
    useState<IStateAccountHoldersPayload>({
      currentPage: 1,
      pageSize:
        pageSizeStorage === '10' || pageSizeStorage === '50'
          ? (Number(pageSizeStorage) as PaginationType)
          : 10
    });

  const [accountHolderToBeUpdated, setAccountHolderToBeUpdated] =
    useState<IAccountHolder | null>(null);

  const adjustedAccountHoldersPayload = {
    page: accountHoldersPayload.currentPage,
    limit: Number(accountHoldersPayload.pageSize)
  };

  const { accountHolders, isLoadingAccountHolders, totalAccountHolders } =
    useGetAccountHolders({
      ...adjustedAccountHoldersPayload,
      type: 'BUSINESS'
    });

  const accountHoldersTableHeader = useMemo(() => {
    return [
      {
        key: 'corporateName',
        label: lang.global.corporate_name[currentLangKey]
      },
      {
        key: 'surname',
        label: lang.global.surname[currentLangKey]
      },
      {
        key: 'documentNumber',
        label: lang.forms.cnpj[currentLangKey]
      },
      {
        key: 'status',
        label: lang.global.status[currentLangKey]
      },
      {
        key: 'buttons',
        label: ''
      }
    ];
  }, [lang, currentLangKey]);

  const accountHoldersTableData = accountHolders.map(accountHolder => {
    return {
      corporateName: accountHolder.legalName,
      surname: accountHolder.name ?? '-',
      documentNumber: Mask.apply('cnpj', accountHolder.documentNumber),
      status:
        lang.cards.kycStatus[
          accountHolder.kycStatus
            ? (accountHolder.kycStatus.toLowerCase() as keyof typeof lang.cards.kycStatus)
            : 'reproved'
        ][currentLangKey],
      buttons: (
        <ButtonContainer>
          <RectButton
            iconProps={{
              name: 'pencil',
              color: 'white',
              type: 'fill',
              size: 1.6
            }}
            onClick={() => {
              handleOpenModal('manageBusinessAccountHolder');
              setAccountHolderToBeUpdated(accountHolder);
            }}
          >
            {lang.global.update[currentLangKey]}
          </RectButton>
        </ButtonContainer>
      )
    };
  });

  function handleChangePayloadState<
    T extends keyof IStateAccountHoldersPayload
  >(type: T, value: IStateAccountHoldersPayload[T]): void {
    switch (type) {
      case 'currentPage':
        setAccountHoldersPayload(oldState => ({
          ...oldState,
          currentPage: value as number
        }));
        return;

      case 'pageSize':
        setAccountHoldersPayload(oldState => ({
          ...oldState,
          pageSize: value as PaginationType,
          currentPage: 1
        }));
    }
  }

  function handleAfterUpdateAccountHolder(
    updatedAccountHolder: IAccountHolder
  ): void {
    queryClient.setQueryData<IGetAccountHolders>(
      [
        AccountHoldersQueryKeys.GET_ACCOUNT_HOLDERS,
        {
          ...adjustedAccountHoldersPayload,
          type: 'BUSINESS'
        }
      ],
      oldData => {
        if (!oldData) return oldData;

        const updatedAccountHolders = oldData.accountHoldersPaginated.map(
          accountHolder => {
            if (accountHolder.id === updatedAccountHolder.id) {
              return updatedAccountHolder;
            }

            return accountHolder;
          }
        );

        return {
          ...oldData,
          accountHoldersPaginated: updatedAccountHolders
        };
      }
    );
  }

  return {
    accountHoldersTableData,
    accountHoldersTableHeader,
    isLoadingAccountHolders,
    totalAccountHolders,
    handleChangePayloadState,
    accountHoldersPayload,
    accountHolderToBeUpdated,
    setAccountHolderToBeUpdated,
    handleAfterUpdateAccountHolder
  };
}
