import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  padding: ${tokens.spacingMd} ${tokens.spacingLg};

  table {
    min-width: 126rem;

    td {
      overflow: hidden;
      height: 6.8rem;
    }

    th {
      height: 6.8rem;
    }
  }
`;
