import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type DropdownStylesType } from './Dropdown.types';

export const Container = styled.div`
  height: fit-content;
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  cursor: pointer;
  width: fit-content;
  background-color: transparent;
  border: 0;
`;

export const Content = styled.div<AddPrefixToType<DropdownStylesType>>`
  width: max-content;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gallery};
  box-shadow: 0 2px 1px 0
    ${({ theme }) => transparentize(0.85, theme.colors.black)};
  display: ${({ $opened }) => ($opened ? 'block' : 'none')};
  margin-bottom: 1rem;
  position: absolute;
  top: calc(100% + 0.1rem);
  max-width: 40rem;
  z-index: ${({ theme }) => theme.zIndex.dropdown};
  padding: ${({ $padding }) => `${$padding}rem`};
  border-radius: 12px;

  &::before {
    border-bottom: 7px solid ${({ theme }) => theme.colors.white};
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: '';
    height: 0;
    position: absolute;
    top: -0.7rem;
    width: 0;
    z-index: 1;
  }

  ${({ $direction }) =>
    $direction === 'center' &&
    css`
      margin: 0;

      &::before {
        left: calc(50% - 0.7rem);
      }
    `}

  ${({ $direction }) =>
    $direction === 'right' &&
    css`
      left: 0;

      &::before {
        left: 0.7rem;
      }
    `}

  ${({ $direction }) =>
    $direction === 'left' &&
    css`
      right: -1rem;

      &::before {
        right: 0.7rem;
      }
    `}
`;
