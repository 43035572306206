import { useQueryCache } from 'data/cache';
import {
  AccountHoldersQueryKeys,
  AccountHoldersService,
  type IGetAccountHoldersParams,
  type IUseGetAccountHolders
} from 'data/modules/cards/accountHolders';

export function useGetAccountHolders({
  enabled,
  ...payload
}: IGetAccountHoldersParams): IUseGetAccountHolders {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [AccountHoldersQueryKeys.GET_ACCOUNT_HOLDERS, payload],
    queryFn: async () => await AccountHoldersService.getAccountHolders(payload),
    enabled
  });

  return {
    accountHolders: data?.accountHoldersPaginated ?? [],
    isLoadingAccountHolders: isLoading || isFetching,
    totalAccountHolders: data?.total ?? 0
  };
}
