import { useLangContext } from 'data/contexts';
import { useChangeVirtualMembers } from 'data/modules/cards/businessCard';

import { InputContainer, TextInput } from 'presentation/components/base/Input';
import { Loader } from 'presentation/components/global/Loader';

import { type IDetailsManageBusinessCardProps } from './DetailsManageBusinessCard.types';

import {
  Container,
  CustomButton,
  FormContainer
} from './DetailsManageBusinessCard.styles';

export function DetailsManageBusinessCard({
  virtualMembers,
  isLoadingVirtualMembers
}: IDetailsManageBusinessCardProps): JSX.Element {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const { control, errors, handleSubmit, isChangingVirtualMembers } =
    useChangeVirtualMembers({
      virtualMembers
    });

  return (
    <Container>
      <FormContainer>
        <Loader
          isLoading={isChangingVirtualMembers || isLoadingVirtualMembers}
        />

        <InputContainer
          label={lang.global.surname[currentLangKey]}
          error={errors.name?.message}
        >
          <TextInput
            name='name'
            control={control}
            error={!!errors.name?.message}
          />
        </InputContainer>

        <InputContainer
          label={lang.forms.email[currentLangKey]}
          error={errors.email?.message}
        >
          <TextInput
            name='email'
            type='email'
            control={control}
            error={!!errors.email?.message}
          />
        </InputContainer>

        <CustomButton
          color='success'
          onClick={handleSubmit}
        >
          {lang.global.save[currentLangKey]}
        </CustomButton>
      </FormContainer>
    </Container>
  );
}
