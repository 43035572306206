import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: ${tokens.spacingXxs};
  transition: all 0.25s ease-in-out;
  border-radius: ${tokens.borderRadiusXs};
  background: ${tokens.neutralColorHighPure};
  border: 1px solid ${tokens.neutralColorHighDark};
  padding: ${tokens.spacingNano} ${tokens.spacingXs};

  &:hover {
    box-shadow:
      2px 2px 8px 0 rgb(0 0 0 / 5%),
      3px 3px 4px 0 rgb(0 0 0 / 10%);
  }

  &:active,
  &:focus {
    background: ${tokens.highlightColorLightest};
  }

  svg {
    max-width: 2.4rem;
    min-width: 2.4rem;
    max-height: 2.4rem;
    min-height: 2.4rem;
    color: ${tokens.highlightColorPure};
  }

  p {
    font-style: normal;
    line-height: 2.2rem;
    font-size: ${tokens.fontSizeXxss};
    color: ${tokens.neutralColorLowDark};
    font-weight: ${tokens.fontWeightMedium};
    font-family: ${tokens.fontFamiliesPoppins};
    letter-spacing: 0.025rem;
  }

  button {
    margin-left: auto;
    white-space: nowrap;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
    padding: ${tokens.spacingXs};

    svg {
      display: none;
    }
  }
`;
