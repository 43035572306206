import { tokens } from 'ds';
import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import {
  type TitleStylesPropType,
  type ValueStylesPropType
} from './SummaryCard.types';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.tokens.neutralColorHighPure};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusMd};
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 0.8rem 5rem;
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 4%);
  width: 100%;
  max-width: 50rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 24rem;
    padding: 0.8rem 2rem;
  }
`;

export const Title = styled.span<AddPrefixToType<TitleStylesPropType>>`
  color: ${({ theme, $disabled }) =>
    $disabled
      ? tokens.neutralColorLowLight
      : theme.colors.neutralColorLowMedium};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  text-align: center;
  line-height: 2.2rem;
  letter-spacing: 0.025rem;
`;

export const Value = styled.span<AddPrefixToType<ValueStylesPropType>>`
  color: ${({ theme, $variant, $disabled }) =>
    $disabled
      ? tokens.neutralColorLowLight
      : $variant === 'danger'
        ? theme.colors.feedbackColorErrorLight
        : $variant === 'success'
          ? theme.colors.feedbackColorSuccessPure
          : theme.colors.outerSpace};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-size: ${({ theme }) => theme.tokens.fontSizeXs};
  font-weight: ${({ theme }) => theme.tokens.fontWeightSemibold};
  text-align: center;
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
  max-width: 100%;
  overflow: auto;
`;
