import { faker } from '@faker-js/faker';

import { type ICardsAddPermissions } from 'data/modules/cards/cardGroups';

import { MockApi } from 'shared/utils/global';

export async function checkIfCardsCanBeAddedMock(): Promise<ICardsAddPermissions> {
  const data = {
    successes: [
      {
        id: faker.string.uuid(),
        name: faker.person.fullName()
      }
    ],
    errors: []
  };

  await MockApi.sleep(2000);

  return data;
}
