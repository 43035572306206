import { apiWeb } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import {
  type IChargedUser,
  type IPersistenceChargedUser
} from 'data/modules/financial';
import { GetChargedUsersMapper } from 'data/modules/financial/services/mappers';
import { type IDefaultResponse } from 'data/modules/global';

class FinancialService {
  async getChargedUsers(): Promise<IChargedUser[]> {
    const response = await apiWeb.get<
      IDefaultResponse<IPersistenceChargedUser[]>
    >('/financial/charged-users');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data.map(GetChargedUsersMapper.toDomain);
  }
}

export default new FinancialService();
