import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${tokens.spacingXs};
  background: ${tokens.neutralColorHighMedium};
  border-radius: ${tokens.borderRadiusMd};
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};

  h3 {
    color: ${tokens.neutralColorLowDark};
    font-size: ${tokens.fontSizeXxs};
    font-weight: ${tokens.fontWeightSemibold};
    line-height: 2.4rem;
  }
`;
