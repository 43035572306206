import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import {
  type LabelStylesType,
  type SwitchBaseStylesType,
  type SwitchContainerStylesType,
  type SwitchPointerStylesType
} from './Switch.types';

export const Container = styled.div`
  input {
    display: none;
  }
`;

export const Label = styled.label<AddPrefixToType<LabelStylesType>>`
  width: fit-content;
  font-size: 1.2rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ $position }) =>
    $position === 'left' &&
    css`
      flex-direction: row-reverse;
    `}

  ${({ $position }) =>
    $position === 'top' &&
    css`
      flex-direction: column-reverse;
    `}

  ${({ $position }) =>
    $position === 'bottom' &&
    css`
      flex-direction: column;
    `}
`;

export const SwitchContainer = styled.div<
  AddPrefixToType<SwitchContainerStylesType>
>`
  position: relative;
  width: 2.7rem;
  height: 1.5rem;
  margin: 0.5rem 0.9rem;
  border: 0;
  background: transparent;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      filter: opacity(0.5) grayscale(1);
    `}
`;

export const SwitchBase = styled.div<AddPrefixToType<SwitchBaseStylesType>>`
  position: absolute;
  top: 0.3rem;
  left: 0.1rem;
  z-index: 1;
  width: 2.5rem;
  height: 1rem;
  border-radius: 5px;
  background: ${({ $isActive, theme: { colors } }) =>
    $isActive ? transparentize(0.5, colors.secondary) : colors.alto};
  transition: background-color 0.3s ease;
`;

export const SwitchPointer = styled.div<
  AddPrefixToType<SwitchPointerStylesType>
>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: ${({ $isActive, theme: { colors } }) =>
    $isActive ? colors.primary : colors.gallery};
  box-shadow: 0 2px 1px
    ${({ theme: { colors } }) => transparentize(0.85, colors.black)};
  transition: all 0.3s ease;

  ${({ $isActive }) =>
    $isActive &&
    css`
      transform: translateX(1.2rem);
    `}
`;
