import { Outlet } from 'react-router-dom';

import { useFlagsContext } from 'data/contexts';

import { FallbackPage } from 'presentation/pages/global';

export function TeamMembersMiddleware(): JSX.Element {
  const { teamMembersFlag } = useFlagsContext();

  if (!teamMembersFlag) {
    return <FallbackPage />;
  }

  return <Outlet />;
}
