import { type IPullSerproInformations } from 'data/modules/cards/companyOnboarding';

import { Mask } from 'shared/utils/format';

class PullSerproInformationsMapper {
  toPersistence(domainDocument: string): IPullSerproInformations {
    return {
      cnpj: Mask.remove(domainDocument)
    };
  }
}

export default new PullSerproInformationsMapper();
