import { useCallback } from 'react';

import { useSearchParams } from 'react-router-dom';

import {
  type IUseGetSearchParams,
  type IUseGetSearchParamsProps
} from './useGetSearchParams.types';

export function useGetSearchParams<T, K extends string = string>({
  values,
  sort = true
}: IUseGetSearchParamsProps<K>): IUseGetSearchParams<T> {
  const [searchParams, setSearchParams] = useSearchParams();
  let params = {};

  values.forEach(value => {
    const param = searchParams.get(value as string);

    params = {
      ...params,
      [value as string]: param
    };
  });

  const sortedParams = Object.keys(params)
    .sort()
    .reduce((obj, key) => {
      obj[key as keyof typeof params] = params[key as keyof typeof params];
      return obj;
    }, {});

  const setParams = useCallback(
    (params: Partial<T>, replace: boolean = false): void => {
      setSearchParams(prevParams => {
        return new URLSearchParams({
          ...(replace ? {} : Object.fromEntries(prevParams.entries())),
          ...params
        });
      });
    },
    [setSearchParams]
  );

  const removeSearchParam = useCallback(
    (key: keyof T) => {
      searchParams.delete(key as string);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return {
    searchParams: sort ? (sortedParams as T) : (params as T),
    setSearchParams: setParams,
    removeSearchParam
  };
}
