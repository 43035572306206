import { useState } from 'react';

import { useModalContext } from 'data/contexts';
import {
  type INpsSurveyResponsePayload,
  useCreateNpsSurveyResponse
} from 'data/modules/surveys';

import {
  type ISendNpsSurveyResponseParams,
  type IUseNpsModal,
  type IUseNpsModalParams
} from './NpsModal.types';

export function useNpsModal({ npsSurveyId }: IUseNpsModalParams): IUseNpsModal {
  const [obsValue, setObsValue] = useState('');
  const [npsValue, setNpsValue] = useState<null | number>(null);
  const { handleCloseModal } = useModalContext();
  const { createNpsSurveyResponse, isCreatingNpsSurveyResponse } =
    useCreateNpsSurveyResponse();

  function sendNpsSurveyResponse({
    answerLater,
    dontWantToAnswer
  }: ISendNpsSurveyResponseParams): void {
    const data: INpsSurveyResponsePayload = {
      comments: dontWantToAnswer ? null : obsValue,
      grade: dontWantToAnswer ? null : npsValue,
      npsSurveyId,
      postpone: dontWantToAnswer ? false : answerLater ?? false
    };

    createNpsSurveyResponse(data);
    handleCloseModal();
  }

  return {
    obsValue,
    setObsValue,
    npsValue,
    setNpsValue,
    sendNpsSurveyResponse,
    isCreatingNpsSurveyResponse
  };
}
