import styled from 'styled-components';

export const Container = styled.tr`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.alto};

  td,
  small,
  strong {
    color: ${({ theme }) => theme.colors.doveGray};
    font-weight: 300;
  }

  td {
    font-size: 1.4rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    td {
      border-bottom: 1px solid ${({ theme }) => theme.colors.gallery};
    }
  }
`;

export const TdAction = styled.td`
  display: flex;
  justify-content: flex-end;
  width: 35rem;

  > a {
    margin-right: 1rem;
    max-width: 35rem;
    width: 35rem;
  }

  button {
    max-width: 16rem;
    margin-left: 1rem;
  }
`;
