import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';

import {
  Container,
  MessageEmptyList,
  TitleMessage
} from './SolicitationActionsEmptyList.styles';

export function SolicitationActionsEmptyList(): JSX.Element {
  const {
    lang: { cards },
    currentLangKey
  } = useLangContext();

  return (
    <Container>
      <TitleMessage>
        <BaseIcon
          name='folder-open'
          type='line'
          color='neutralColorLowMedium'
          size={7}
        />
        <span>
          {cards.valueSolicitation.not_found_solicitation[currentLangKey]}
        </span>
      </TitleMessage>
      <MessageEmptyList>
        {
          cards.valueSolicitation.no_approved_request_found_message[
            currentLangKey
          ]
        }
      </MessageEmptyList>
    </Container>
  );
}
