import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.spacingXs};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;

  button {
    padding: ${tokens.spacingNano} 0;

    svg {
      max-width: 2.4rem;
      min-width: 2.4rem;
      max-height: 2.4rem;
      min-height: 2.4rem;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${tokens.spacingMd};
    padding-bottom: ${tokens.spacingXs};
    border-bottom: 1px solid ${tokens.neutralColorHighDark};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingMd};

  > span {
    text-transform: capitalize;
    border-radius: ${tokens.borderRadiusXxl};
    border: 1px solid ${tokens.brandColorPrimaryPure};
  }

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${tokens.spacingXs};
  }
`;

export const ReportTitle = styled.div`
  display: flex;
  white-space: pre;
  font-style: normal;
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightSemibold};

  > span {
    font-style: normal;
    line-height: 3.2rem;
    letter-spacing: 0.075rem;
    font-size: ${tokens.fontSizeXs};
    color: ${tokens.neutralColorLowDark};
    font-family: ${tokens.fontFamilyPoppins};
    font-weight: ${tokens.fontWeightRegular};

    &:last-of-type {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: ${tokens.spacingNano};

    > span {
      &:first-of-type {
        display: none;
      }

      &:last-of-type {
        display: block;
      }
    }
  }
`;

export const ReportContent = styled.div`
  display: flex;
  align-self: stretch;
  align-items: flex-end;
  gap: ${tokens.spacingXs};
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const ReportInfoContent = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.spacingNano};
`;

export const ReportInfoRow = styled.div`
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  gap: ${tokens.spacingQuarck};

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const ReportInfoLabel = styled.label`
  line-height: 150%;
  font-style: normal;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowMedium};
  font-weight: ${tokens.fontWeightMedium};
  font-family: ${tokens.fontFamilyPoppins};
`;

export const ReportInfoText = styled.p`
  line-height: 150%;
  font-style: normal;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowMedium};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamilyPoppins};
`;

export const ReportFilterNotice = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${tokens.spacingXxs};

  svg {
    max-width: 2.4rem;
    min-width: 2.4rem;
    max-height: 2.4rem;
    min-height: 2.4rem;
  }

  span {
    line-height: 150%;
    font-style: normal;
    letter-spacing: 0.025rem;
    font-size: ${tokens.fontSizeXxss};
    color: ${tokens.neutralColorLowMedium};
    font-family: ${tokens.fontFamilyPoppins};
    font-weight: ${tokens.fontWeightSemibold};
  }
`;

export const ReportInfoTotalWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingNano};
  border-radius: ${tokens.borderRadiusMd};
  padding: ${tokens.spacingXs} ${tokens.spacingMd};
  border: 1px solid ${tokens.neutralColorHighDarkest};

  @media screen and (max-width: 800px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    > p,
    > label {
      white-space: normal;
    }
  }
`;

export const ReportInfoTotalLabel = styled.label`
  font-style: normal;
  white-space: nowrap;
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowDark};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamilyPoppins};
`;

export const ReportInfoTotalValue = styled.p`
  font-style: normal;
  white-space: nowrap;
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowDark};
  font-weight: ${tokens.fontWeightBold};
  font-family: ${tokens.fontFamilyPoppins};

  span {
    font-weight: ${tokens.fontWeightRegular};
  }
`;
