import { tokens } from 'ds/tokens';
import styled from 'styled-components';

import {
  type ContainerIconStylesType,
  type TimelineIconStylesType
} from './TimelineItemIconAndInfo.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<AddPrefixToType<TimelineIconStylesType>>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  &::after {
    content: '';
    width: 0.1rem;
    background: ${({ $hideFinalLine }) =>
      $hideFinalLine ? 'transparent' : tokens.neutralColorHighDarkest};
    flex: 1 0 2rem;
  }

  > p {
    font-family: ${tokens.fontFamilyPoppins};
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8rem;
    color: ${tokens.neutralColorLowDark};
  }
`;

export const ContainerIcon = styled.div<
  AddPrefixToType<ContainerIconStylesType>
>`
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 50%;
  padding: 1rem;
  background: ${({ $iconBackgroundColor }) => $iconBackgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ $iconColor }) => $iconColor};
`;
