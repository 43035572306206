import { useMutationCache } from 'data/cache';
import {
  CompanyFormCacheDomains,
  CompanyOnboardingMutationKeys,
  CompanyOnboardingService,
  type IUseUpdateCompanyOnboardingConfigurationUsersCache,
  type IUseUpdateCompanyOnboardingConfigurationUsersCacheParams,
  type OnboardingUserType,
  type UpdateCompanyFormCachePayloadType
} from 'data/modules/cards/companyOnboarding';

export function useUpdateCompanyOnboardingConfigurationUsersCache({
  onAfterUpdatingCacheSuccessfully
}: IUseUpdateCompanyOnboardingConfigurationUsersCacheParams): IUseUpdateCompanyOnboardingConfigurationUsersCache {
  const { mutate, isLoading } = useMutationCache({
    mutationKey: [CompanyOnboardingMutationKeys.UPDATE_COMPANY_FORM_CACHE],
    mutationFn: async (payload: UpdateCompanyFormCachePayloadType) =>
      await CompanyOnboardingService.updateCompanyFormCache(payload),
    onSuccess: (data, variables) => {
      onAfterUpdatingCacheSuccessfully(variables.value as OnboardingUserType[]);
    }
  });

  async function updateCompanyOnboardingConfigurationUsersCache(
    newUsersConfiguration: OnboardingUserType[]
  ): Promise<void> {
    mutate({
      domain: CompanyFormCacheDomains.onboardingUsers,
      lifeCycle: 360,
      value: newUsersConfiguration
    });
  }

  return {
    updateCompanyOnboardingConfigurationUsersCache,
    isUpdatingCompanyOnboardingConfigurationUsersCache: isLoading
  };
}
