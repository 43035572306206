import { Checkbox, CurrencyInput, DefaultTable, DSIcons } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import {
  useCardDetailsContext,
  useChargeCardsContext,
  useLangContext,
  useModalContext
} from 'data/contexts';

import { ChargeCardsSteps } from 'presentation/pages/cards/Management/ChargeCards/ChargeCards.types';

import { useGa4 } from 'shared/hooks/global';
import { CardNumber, Currency } from 'shared/utils/format';

import { useChargeCardsTableRow } from './useChargeCardsTableRow';

import { type IChargeCardsTableRowProps } from './ChargeCardsTableRow.types';

import {
  CardDetailsButton,
  OperationValue
} from './ChargeCardsTableRow.styles';

export function ChargeCardsTableRow({
  card,
  ...rest
}: IChargeCardsTableRowProps): JSX.Element {
  const { sendGaEvent } = useGa4();

  const {
    lang: {
      cards: { global: cardsGlobal }
    },
    currentLangKey
  } = useLangContext();

  const { handleOpenModal } = useModalContext();

  const [
    hasSelectedCard,
    chargeCardsOperation,
    currentStep,
    handleToggleSelectCard,
    handleChangeChargeCardsOperationsMap
  ] = useChargeCardsContext(
    useShallow(state => [
      state.selectedCardsMap.has(`${card.balanceId}-${card.number}`),
      state.chargeCardsOperationsMap.get(`${card.balanceId}-${card.number}`),
      state.currentStep,
      state.handleToggleSelectCard,
      state.handleChangeChargeCardsOperationsMap
    ])
  );

  const handleSetSelectedAccount = useCardDetailsContext(
    state => state.handleSetSelectedAccount
  );

  const {
    isDeletingModifiedBalanceAllocationItemsFromCache,
    isSendingModifiedBalanceAllocationItemsToCache,
    handleDeleteCachedOperation
  } = useChargeCardsTableRow(card);

  const isLoadingCardData =
    isDeletingModifiedBalanceAllocationItemsFromCache ||
    isSendingModifiedBalanceAllocationItemsToCache ||
    (!card.currentValue && card.currentValue !== 0);

  return (
    <DefaultTable.Tr {...rest}>
      {currentStep === ChargeCardsSteps.CHARGE_CARDS && (
        <DefaultTable.Td
          isLoading={
            isDeletingModifiedBalanceAllocationItemsFromCache ||
            isSendingModifiedBalanceAllocationItemsToCache
          }
        >
          <Checkbox.Default
            name='selectCard'
            small
            value={hasSelectedCard}
            onChangeValue={() => {
              handleToggleSelectCard(card);
            }}
          />
        </DefaultTable.Td>
      )}

      <DefaultTable.Td
        isLoading={
          isDeletingModifiedBalanceAllocationItemsFromCache ||
          isSendingModifiedBalanceAllocationItemsToCache
        }
        title={card.name}
      >
        {card.name}
      </DefaultTable.Td>

      <DefaultTable.Td
        isLoading={
          isDeletingModifiedBalanceAllocationItemsFromCache ||
          isSendingModifiedBalanceAllocationItemsToCache
        }
      >
        {card.type === 'BUSINESS'
          ? cardsGlobal.account_type.business[currentLangKey]
          : cardsGlobal.account_type.individual[currentLangKey]}
      </DefaultTable.Td>

      <DefaultTable.Td
        isLoading={
          isDeletingModifiedBalanceAllocationItemsFromCache ||
          isSendingModifiedBalanceAllocationItemsToCache
        }
      >
        {card.number
          ? CardNumber.format(card.number)
          : cardsGlobal.not_bound[currentLangKey]}
      </DefaultTable.Td>

      <DefaultTable.Td isLoading={isLoadingCardData}>
        {`R$ ${Currency.format('BRL', card.currentValue ?? 0)}`}
      </DefaultTable.Td>

      <DefaultTable.Td isLoading={isLoadingCardData}>
        {currentStep === ChargeCardsSteps.CHARGE_CARDS ? (
          <CurrencyInput.Default
            inputId={`amount-card-${card.name}-${card.number}`}
            variant='small'
            type='number'
            onChangeValue={value => {
              handleChangeChargeCardsOperationsMap({
                card,
                value
              });
            }}
            onChangeOperation={operation => {
              handleChangeChargeCardsOperationsMap({
                card,
                operationType: operation
              });
            }}
            onBlur={() => {
              if (chargeCardsOperation?.value === 0) {
                handleChangeChargeCardsOperationsMap({
                  card,
                  operationType: undefined
                });
              }
            }}
            operation={chargeCardsOperation?.operationType}
            value={chargeCardsOperation?.value}
            leftElement='R$'
          />
        ) : (
          chargeCardsOperation?.value && (
            <OperationValue $type={chargeCardsOperation.operationType}>
              {chargeCardsOperation.operationType === 'CREDIT' ? '+ ' : '- '}
              {Currency.format('BRL', chargeCardsOperation.value ?? 0, true)}
            </OperationValue>
          )
        )}
      </DefaultTable.Td>

      <DefaultTable.Td isLoading={isLoadingCardData}>
        {Currency.format(
          'BRL',
          chargeCardsOperation?.operationType === 'CREDIT'
            ? (card.currentValue ?? 0) + (chargeCardsOperation?.value ?? 0)
            : (card.currentValue ?? 0) - (chargeCardsOperation?.value ?? 0),
          true
        )}
      </DefaultTable.Td>

      <DefaultTable.Td
        isLoading={isLoadingCardData}
        onClick={() => {
          if (currentStep === ChargeCardsSteps.CHARGE_CARDS) {
            handleOpenModal('cardDetails');
            handleSetSelectedAccount({
              accountId: card.accountId,
              balanceId: card.balanceId,
              name: card.name,
              availableBalance: Currency.format(
                'BRL',
                card.currentValue as number,
                true
              ),
              number: card.number as string,
              status: card.status,
              type: card.type,
              userId: card.userId
            });

            sendGaEvent('cards', 'table_open_card_details');

            return;
          }

          sendGaEvent('cards', 'table_card_delete_operation');

          handleDeleteCachedOperation();
        }}
      >
        <CardDetailsButton>
          {currentStep === ChargeCardsSteps.CHARGE_CARDS && (
            <DSIcons.CreditCardInfoIcon />
          )}

          {currentStep === ChargeCardsSteps.CONFIRM_OPERATIONS && (
            <DSIcons.TrashDeleteBinIcon />
          )}
        </CardDetailsButton>
      </DefaultTable.Td>
    </DefaultTable.Tr>
  );
}
