import { tokens } from 'ds';
import styled from 'styled-components';

export const TextContent = styled.p`
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightRegular};
  line-height: ${tokens.spacingInsetMdd};
  letter-spacing: 0.075rem;
  font-family: ${tokens.fontFamiliesPoppins};
  color: ${tokens.neutralColorLowDark};
`;
