import styled from 'styled-components';

import { RectButton } from 'presentation/components/base/Button';

export const ManageShareHolderFormTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 200;
  gap: 1rem;
`;

export const StyledRectButton = styled(RectButton)`
  max-width: 30rem;
`;

export const PartnerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;

  h2 {
    font-size: 2rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.emperor};
  }
`;

export const ShareholderFormsContainer = styled.div`
  max-height: 50rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 1.2rem;
  padding: 0.5rem 1rem;
  margin-top: 1.6rem;
`;
