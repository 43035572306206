import { usePagination } from 'ds/components/Table/Pagination/PaginationContainer/PaginationContainer.context';

import { type IPaginationLabelProps } from './PaginationLabel.types';
import { type LanguageType } from 'ds/types';

import { Container } from './PaginationLabel.styles';

export function PaginationLabel({
  langKey = 'pt'
}: IPaginationLabelProps): JSX.Element {
  const { currentPage, totalPage } = usePagination();

  const paginationMessage: Record<LanguageType, string> = {
    pt: `Página ${currentPage} de ${totalPage}`,
    en: `Page ${currentPage} of ${totalPage}`,
    es: `Página ${currentPage} de ${totalPage}`
  };

  return <Container>{paginationMessage[langKey]}</Container>;
}
