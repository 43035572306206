import { useMemo } from 'react';

import { useModalContext } from 'data/contexts';
import { useGetApprovalFlows } from 'data/modules/approvalFlows';
import { useGetCostsCenters } from 'data/modules/costsCenters';
import { useGetExpensePolicies } from 'data/modules/expensePolicies';
import { useGetProjects } from 'data/modules/projects';

import { type ISelectOption } from 'presentation/components/base/Select/Select.types';

import {
  type IRequestForSelectionFields,
  type IUseSetUpAccountabilityModal
} from './SetUpAccountabilityModal.types';

export function useSetUpAccountabilityModal(): IUseSetUpAccountabilityModal {
  const { visibleModal } = useModalContext();

  const payload = {
    page: 1,
    perPage: 500,
    enabled: visibleModal === 'setUpAccountability'
  } as IRequestForSelectionFields;

  const { approvalFlows, isLoadingApprovalFlows } =
    useGetApprovalFlows(payload);

  const { costsCenters, isLoadingCostsCenters } = useGetCostsCenters(payload);

  const { projects, isLoadingProjects } = useGetProjects(payload);

  const { expensePolicies, isLoadingExpensePolicies } =
    useGetExpensePolicies(payload);

  const approvalFlowsOptions: ISelectOption[] = useMemo(() => {
    return approvalFlows?.map(el => ({
      value: el.id.toString(),
      label: `${el.description}`
    }));
  }, [approvalFlows]);

  const costsCentersOptions: ISelectOption[] = useMemo(() => {
    return costsCenters?.map(el => ({
      value: el.id.toString(),
      label: `${el.name} `
    }));
  }, [costsCenters]);

  const costsCentersIds: Array<number> = useMemo(() => {
    return costsCenters?.map(el => el.id);
  }, [costsCenters]);

  const projectsOptions: ISelectOption[] = useMemo(() => {
    return projects?.map(el => ({
      value: el.id.toString(),
      label: `${el.name} `
    }));
  }, [projects]);

  const projectsIds: Array<number> = useMemo(() => {
    return projects?.map(el => el.id);
  }, [projects]);

  const expensePoliciesOptions: ISelectOption[] = useMemo(() => {
    return expensePolicies?.map(el => ({
      value: el.id.toString(),
      label: `${el.description} `
    }));
  }, [expensePolicies]);

  return {
    approvalFlowsOptions,
    isLoadingApprovalFlows,
    costsCentersOptions,
    isLoadingCostsCenters,
    projectsOptions,
    isLoadingProjects,
    expensePoliciesOptions,
    isLoadingExpensePolicies,
    costsCentersIds,
    projectsIds
  };
}
