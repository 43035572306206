import { useQueryCache } from 'data/cache';
import {
  AccountQueryKeys,
  AccountService,
  type IUseGetUserAccountsByUserUuid,
  type IUseGetUserAccountsByUserUuidParams
} from 'data/modules/cards/account';

export function useGetUserAccountsByUserUuid({
  userUuid,
  enabled
}: IUseGetUserAccountsByUserUuidParams): IUseGetUserAccountsByUserUuid {
  const { data, isLoading, isFetching } = useQueryCache({
    enabled,
    queryKey: [AccountQueryKeys.GET_USER_ACCOUNTS_BY_USER_UUID, userUuid],
    queryFn: async () =>
      await AccountService.getUserAccountsByUserUuid({ userUuid })
  });

  return {
    userAccounts: data,
    isLoadingUserAccounts: isLoading,
    isFetchingUserAccounts: isFetching
  };
}
