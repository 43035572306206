import {
  addDays,
  differenceInDays,
  differenceInMinutes,
  format,
  formatRelative,
  isDate,
  isSameDay,
  isToday,
  type Locale,
  parse,
  parseISO,
  startOfMonth,
  sub
} from 'date-fns';
import { enUS, es, ptBR } from 'date-fns/locale';

import { type LanguageKeyType } from 'data/contexts/lang/useLangContext.types';

class CustomDate extends Date {
  formatFromISO(date: string | undefined, outFormat = 'dd/MM/yyyy'): string {
    if (!date) return '';

    return format(parseISO(date), outFormat);
  }

  getTimezone(): string {
    const timezoneInt = -this.getTimezoneOffset() / 60;
    const timezoneHours = Math.trunc(timezoneInt);
    const timezoneMinutes = Math.abs((timezoneInt % 1) * 60)
      .toString()
      .padStart(2, '0');

    return `${timezoneHours}:${timezoneMinutes}`;
  }

  parseIsoToDate(date: string): Date {
    return parseISO(date);
  }

  formatToIso(date: string, formatString = 'dd/MM/yyyy'): string {
    const parsed = parse(date, formatString, new Date());

    return format(parsed, 'yyyy-MM-dd');
  }

  formatDateToIso(date: Date): string {
    return format(date, 'yyyy-MM-dd');
  }

  differenceBetweenDates(from: Date, to: Date): number {
    return differenceInDays(from, to);
  }

  isDateOlderThanHours(date?: Date | string, hours: number = 24): boolean {
    const parsed = new Date(date ?? '');

    return (
      isDate(parsed) && differenceInMinutes(new Date(), parsed) / 60 >= hours
    );
  }

  formatRelativeDateLocale(
    currentLangKey: LanguageKeyType,
    date: Date
  ): string {
    const currentLocale =
      currentLangKey === 'en' ? enUS : currentLangKey === 'es' ? es : ptBR;

    const formatRelativeLocale: Record<string, string> = {
      other: 'dd MMMM',
      lastWeek: 'dd MMMM'
    };

    const locale: Locale = {
      ...currentLocale,
      formatRelative: (token, ...args) =>
        formatRelativeLocale[token] ??
        currentLocale.formatRelative?.(token, ...args)?.split(' ')?.[0]
    };

    return formatRelative(date, new Date(), {
      locale
    });
  }

  getDateAddingDays(currentDate: Date, daysToAdd: number): Date {
    return addDays(currentDate, daysToAdd);
  }

  /**
   * Retorna a data subtraindo um número específico de dias da data fornecida.
   * @param date A data inicial.
   * @param days O número de dias a subtrair da data inicial.
   * @returns Uma nova data resultante da subtração do número especificado de dias da data inicial.
   */
  getDateSubtractingDays(date: Date, days: number): Date {
    return sub(date, { days });
  }

  getDateMonthName(
    date: Date | undefined,
    currentLangKey: 'pt' | 'en' | 'es'
  ): string {
    if (!date) return '';

    const locales = {
      pt: ptBR,
      en: enUS,
      es
    };

    return format(date, 'MMMM', {
      locale: locales[currentLangKey]
    });
  }

  /**
   * Retorna a data do primeiro dia do mês da data fornecida no formato 'yyyy-MM-01'.
   * @param date A data para a qual se deseja obter o primeiro dia do mês.
   * @returns Uma string representando o primeiro dia do mês no formato 'yyyy-MM-01'.
   */
  getFistDayOfMonth(date: Date): string {
    return format(date, 'yyyy-MM-01');
  }

  /**
   * Verifica se todas as datas fornecidas têm o mesmo dia.
   * @param dates Um array de objetos Date a serem verificados.
   * @returns true se todas as datas tiverem o mesmo dia, false caso contrário.
   */
  isEveryDateSameDay(...dates: Date[]): boolean {
    if (dates.length === 0) return false;

    const firstDate = dates[0];
    return dates.every(date => isSameDay(date, firstDate));
  }

  /**
   * Verifica se todas as datas fornecidas são do mesmo dia que o dia atual.
   * @param dates Um array de objetos Date a serem verificados.
   * @returns true se todas as datas forem do mesmo dia que o dia atual, false caso contrário.
   */
  isEveryDateSameToday(...dates: Date[]): boolean {
    if (dates.length === 0) return false;

    return dates.every(date => isSameDay(date, new Date()));
  }

  /**
   * Verifica se o intervalo entre duas datas é exatamente os últimos sete dias, a contar do dia atual.
   * @param initialDate A data inicial do intervalo.
   * @param endDate A data final do intervalo.
   * @returns Um valor booleano indicando se o intervalo de datas é exatamente os últimos sete dias, a contar do dia atual.
   */
  isLastSevenDays(initialDate: Date, endDate: Date): boolean {
    return isToday(endDate) && differenceInDays(endDate, initialDate) === 7;
  }

  /**
   * Verifica se o intervalo entre duas datas é exatamente o os últimos trinta dias, a contar do dia atual.
   * @param initialDate A data inicial do intervalo.
   * @param endDate A data final do intervalo.
   * @returns Um valor booleano indicando se o intervalo de datas é exatamente o os últimos trinta dias, a contar do dia atual.
   */
  isLastThirtyDays(initialDate: Date, endDate: Date): boolean {
    return isToday(endDate) && differenceInDays(endDate, initialDate) === 30;
  }

  /**
   * Verifica se o intervalo entre duas datas está dentro do mês atual, a contar do dia atual.
   * @param initialDate A data inicial do intervalo.
   * @param endDate A data final do intervalo.
   * @returns Um valor booleano indicando se o intervalo de datas está dentro do mês atual, a contar do dia atual.
   */
  isThisMonth(initialDate: Date, endDate: Date): boolean {
    return isToday(endDate) && isSameDay(initialDate, startOfMonth(endDate));
  }
}

export default new CustomDate();
