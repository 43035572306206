import { type MutableRefObject, useMemo, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { vexDomain } from 'data/config';
import { useAuthContext, useFlagsContext } from 'data/contexts';
import { useGetOutsourcedUsersAndPermissions } from 'data/modules/auth';

import { cookiesKeys, Icons } from 'shared/constants/global';
import { sidebarRoutes } from 'shared/constants/global/sidebarRoutes.constant';
import { langJSON } from 'shared/lang';
import { CustomCookies } from 'shared/utils/global';

import {
  type IRoutes,
  type ISidebarContainerProps,
  type IUseSidebarContainer
} from './SidebarContainer.types';

export function useSidebarContainer({
  isCollapsed,
  onSetIsCollapsed
}: ISidebarContainerProps): IUseSidebarContainer {
  const { pathname: route } = useLocation();
  const [user, company, outsourcedUser, isAuthenticating] = useAuthContext(
    useShallow(state => [
      state.user,
      state.company,
      state.outsourcedUser,
      state.isAuthenticating
    ])
  );

  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const [subItemActive, setSubItemActive] = useState<string>(route);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const accountManager = user?.cards?.type === 'ACCOUNT_MANAGER';
  const { redesignReportsFlag } = useFlagsContext();

  const outsourcingUserUuid = CustomCookies.get('outsourcing') ?? '';

  const {
    outsourcedUsersAndPermissions,
    isFetchingOutsourcedUsersAndPermissions
  } = useGetOutsourcedUsersAndPermissions({
    enabled: user?.parameters.hasOutsourcingAccess
  });

  // Verifica as rotas que o usuário pode acessar
  function createAllowedRoutes(routes: IRoutes[]): IRoutes[] {
    return routes
      .filter(({ condition }) => condition)
      .map(({ condition, ...rest }) => ({ ...rest }));
  }

  const expenseRoute: IRoutes = useMemo(() => {
    const showOldExpensesPage = CustomCookies.get(
      cookiesKeys.redesignExpensesOldVersion
    );

    const usesRedesignExpenses = company?.parameters.usesRedesignExpenses;

    const isExpensesOldVersionAllowed =
      company?.parameters.isExpensesOldVersionAllowed;

    if (
      usesRedesignExpenses === null ||
      (isExpensesOldVersionAllowed && showOldExpensesPage === 'true')
    ) {
      return {
        name: langJSON.sidebar.routes.expenses,
        icon: Icons.ExpensesIcon,
        route: `${vexDomain}/despesas`,
        isReactRoute: false
      };
    }

    return {
      name: langJSON.sidebar.routes.expenses,
      icon: Icons.ExpensesIcon,
      route: '/despesas',
      isReactRoute: true
    };
  }, [
    company?.parameters.usesRedesignExpenses,
    company?.parameters.isExpensesOldVersionAllowed
  ]);

  const availableRoutes = useMemo(() => {
    // se existir cookie de terceirização, então entra no IF
    if (outsourcingUserUuid !== '') {
      if (
        outsourcedUser !== undefined &&
        !isFetchingOutsourcedUsersAndPermissions &&
        outsourcedUsersAndPermissions !== undefined
      ) {
        // só vai entrar nesse trecho de código se o usuário estiver com usuário terceirizado
        // e se já tiver carregado as permissões dos usuários terceirizados e o usuário terceirizado
        const outsourcedUserRoutesPermissions =
          outsourcedUsersAndPermissions?.filter(
            ({ directorUuid }) => directorUuid === outsourcedUser?.user.uuid
          );

        const sidebarRoutesValidator: IRoutes[] = [
          {
            ...expenseRoute,
            condition: !!outsourcedUserRoutesPermissions?.find(
              ({ permissions }) => permissions.includes('EXPENSES')
            )
          },
          {
            condition: !!outsourcedUserRoutesPermissions?.find(
              ({ permissions }) =>
                permissions.includes('REPORTS') ||
                permissions.includes('SEND_REPORT_FOR_APPROVAL')
            ),
            name: sidebarRoutes.reports.name,
            icon: sidebarRoutes.reports.icon,
            route: redesignReportsFlag
              ? sidebarRoutes.reports.route
              : `${vexDomain}/relatorios`,
            isReactRoute: redesignReportsFlag
          },
          {
            condition:
              !!outsourcedUserRoutesPermissions?.find(({ permissions }) =>
                permissions.includes('CONCILIATION')
              ) && company?.parameters.usesInvoiceConciliation,
            name: sidebarRoutes.conciliations.name,
            icon: sidebarRoutes.conciliations.icon,
            route: sidebarRoutes.conciliations.route,
            isReactRoute: sidebarRoutes.conciliations.isReactRoute
          },
          {
            condition:
              !!outsourcedUserRoutesPermissions?.find(({ permissions }) =>
                permissions.includes('WITHDRAWALS_ADVANCE')
              ) && company?.parameters.usesCheckingAccount,
            name: sidebarRoutes.withdraw.name,
            icon: sidebarRoutes.withdraw.icon,
            route: sidebarRoutes.withdraw.route,
            isReactRoute: sidebarRoutes.withdraw.isReactRoute
          },
          {
            condition:
              (!!outsourcedUserRoutesPermissions?.find(({ permissions }) =>
                permissions.includes('CARDS')
              ) &&
                company?.parameters.usesCards) ||
              user?.cards?.isCardGroupManagerWithoutAccount,
            name: sidebarRoutes.cards.name,
            icon: sidebarRoutes.cards.icon,
            route: sidebarRoutes.cards.route,
            isReactRoute: sidebarRoutes.cards.isReactRoute
          }
        ];

        return createAllowedRoutes(sidebarRoutesValidator);
      } else {
        // se não tiver carregado as permissões dos usuários terceirizados, retorna vazio
        return [];
      }
    }

    // caso não tenha usuário terceirizado, então segue esse código abaixo
    const isAdmin = user?.userType === 'ADMINISTRADOR';

    // Validação das rotas da sidebar
    const sidebarRoutesValidator: IRoutes[] = [
      {
        condition: isAdmin,
        name: sidebarRoutes.home.name,
        icon: sidebarRoutes.home.icon,
        route: sidebarRoutes.home.route,
        isReactRoute: sidebarRoutes.home.isReactRoute
      },
      {
        ...expenseRoute,
        condition: true
      },
      {
        condition: true,
        name: sidebarRoutes.reports.name,
        icon: sidebarRoutes.reports.icon,
        route: redesignReportsFlag
          ? sidebarRoutes.reports.route
          : `${vexDomain}/relatorios`,
        isReactRoute: redesignReportsFlag
      },
      {
        condition: company?.parameters.usesInvoiceConciliation,
        name: sidebarRoutes.conciliations.name,
        icon: sidebarRoutes.conciliations.icon,
        route: sidebarRoutes.conciliations.route,
        isReactRoute: sidebarRoutes.conciliations.isReactRoute
      },
      {
        condition: isAdmin || user?.userApproves,
        name: sidebarRoutes.approvals.name,
        icon: sidebarRoutes.approvals.icon,
        route: sidebarRoutes.approvals.route,
        subRouteActive: sidebarRoutes.approvalsRequest.route,
        subRouteCondition: (isAdmin || user?.userApproves) && accountManager,
        subRoute: [
          {
            name: sidebarRoutes.reports.name,
            route: sidebarRoutes.approvals.route,
            active: false
          },
          {
            name: sidebarRoutes.approvalsRequest.name,
            route: sidebarRoutes.approvalsRequest.route,
            active: route === sidebarRoutes.approvalsRequest.route
          }
        ],
        isReactRoute: sidebarRoutes.approvals.isReactRoute
      },
      {
        condition: !(isAdmin || user?.userApproves) && accountManager,
        name: sidebarRoutes.approvals.name,
        icon: sidebarRoutes.approvalsRequest.icon,
        route: sidebarRoutes.approvalsRequest.route,
        isReactRoute: sidebarRoutes.approvalsRequest.isReactRoute
      },
      {
        condition: company?.parameters.usesCheckingAccount,
        name: sidebarRoutes.withdraw.name,
        icon: sidebarRoutes.withdraw.icon,
        route: sidebarRoutes.withdraw.route,
        isReactRoute: sidebarRoutes.withdraw.isReactRoute
      },
      {
        condition:
          (company?.parameters.usesCards &&
            (!!user?.parameters.usesCards || user?.cards?.type !== 'USER')) ||
          user?.cards?.isCardGroupManagerWithoutAccount,
        name: sidebarRoutes.cards.name,
        icon: sidebarRoutes.cards.icon,
        route: sidebarRoutes.cards.route,
        isReactRoute: sidebarRoutes.cards.isReactRoute
      },
      {
        condition: isAdmin || user?.parameters.allowsManagementAccess,
        name: sidebarRoutes.management.name,
        icon: sidebarRoutes.management.icon,
        route: sidebarRoutes.management.route,
        isReactRoute: sidebarRoutes.management.isReactRoute
      }
    ];

    return createAllowedRoutes(sidebarRoutesValidator);
  }, [
    company,
    user,
    outsourcedUser,
    outsourcedUsersAndPermissions,
    route,
    accountManager,
    expenseRoute,
    redesignReportsFlag,
    isFetchingOutsourcedUsersAndPermissions,
    outsourcingUserUuid
  ]);

  /**
   * Salva o novo estado da sidebar nos cookies
   */
  function handleCollapseSidebar(): void {
    CustomCookies.set('sidebar', !isCollapsed ? 'collapsed' : '', {
      daysToExpire: 2
    });

    onSetIsCollapsed(!isCollapsed);
  }

  /**
   * Collapsa a sidebar caso seja mobile
   */
  function handleCollapseSidebarMobile(target: EventTarget): void {
    if (window.innerWidth >= 1280) return;

    if (target === (sidebarRef as MutableRefObject<HTMLDivElement>)?.current) {
      onSetIsCollapsed(!isCollapsed);
    }
  }

  const isOutsourcing = outsourcingUserUuid !== '';

  return {
    handleCollapseSidebar,
    availableRoutes,
    route,
    isOutsourcing,
    isFetchingData: isAuthenticating || isFetchingOutsourcedUsersAndPermissions,
    sidebarRef,
    company: company!,
    user: user!,
    subItemActive,
    setSubItemActive,
    handleCollapseSidebarMobile,
    isDropdownOpen,
    setIsDropdownOpen
  };
}
