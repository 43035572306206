import { DSIcons, tokens } from 'ds';
import styled from 'styled-components';

import { type ListStylesType } from './UsersAndManagersList.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
  font-family: ${tokens.fontFamilyPoppins};
  padding-bottom: ${tokens.spacingInsetXs};
  border-bottom: 1px solid ${tokens.neutralColorHighDark};

  &:last-child {
    padding-bottom: none;
    border: none;
  }

  button {
    span {
      display: flex;
      align-items: center;
      gap: 0.8rem;
    }
  }

  button.display-none {
    display: none;
  }
`;

export const Title = styled.h2`
  color: ${tokens.neutralColorLowDarkest};
  font-weight: ${tokens.fontWeightSemibold};
  font-size: ${tokens.fontSizeXxs};
  line-height: 2.4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const List = styled.div<AddPrefixToType<ListStylesType>>`
  padding: ${({ $isEmpty, $isUnique }) =>
    $isEmpty || $isUnique
      ? `0 ${tokens.spacingXs}`
      : `0 ${tokens.spacingXs} ${tokens.spacingXs} ${tokens.spacingXs}`};
  background-color: ${tokens.neutralColorHighLight};
  border-radius: ${tokens.borderRadiusMd};
  max-height: 39.9rem;
  overflow-y: auto;
`;

export const ListItem = styled.div<AddPrefixToType<ListStylesType>>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${tokens.spacingXs} 0;
  border-bottom: ${({ $isEmpty, $isUnique }) =>
    $isEmpty || $isUnique
      ? 'none'
      : `1px solid ${tokens.neutralColorHighDark}`};

  &:last-child {
    border: none;
  }
`;

export const ListItemInfo = styled.div`
  h3 {
    color: ${tokens.neutralColorLowDark};
    font-size: ${tokens.fontSizeXxss};
    line-height: 2.2rem;
    font-weight: ${tokens.fontWeightSemibold};
  }

  span {
    color: ${tokens.neutralColorLowMedium};
    font-size: ${tokens.fontSizeXxss};
    line-height: 2.2rem;
    font-weight: ${tokens.fontWeightMedium};
  }
`;

export const ListItemActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`;

export const ListItemLoading = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: ${tokens.spacingXs};
  color: ${tokens.neutralColorLowMedium};
  font-size: ${tokens.fontSizeXxss};
  line-height: 2.2rem;
  font-weight: ${tokens.fontWeightMedium};
`;

export const ArrowsIcon = styled(DSIcons.ArrowsIcon)`
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  min-height: 2.4rem;
  cursor: pointer;
`;

export const TrashIcon = styled(DSIcons.TrashDeleteBinIcon)`
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  cursor: pointer;

  path {
    stroke: ${tokens.feedbackColorErrorPure};
  }
`;

export const PlusIcon = styled(DSIcons.PlusSmallIcon)`
  width: 1.6rem;
  height: 1.6rem;
  min-width: 1.6rem;
  min-height: 1.6rem;
`;

export const CreditCardIcon = styled(DSIcons.CreditCardUserGroupIcon)`
  width: 1.6rem;
  height: 1.6rem;
  min-width: 1.6rem;
  min-height: 1.6rem;
`;

export const MoreItemsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${tokens.spacingXxs};
  border-top: 1px solid ${tokens.neutralColorHighDark};
`;
