import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const Title = styled.h1`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeSm};
  font-weight: ${tokens.fontWeightBold};
  line-height: 3.2rem;
  letter-spacing: 1px;
  text-align: left;
`;
