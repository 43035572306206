import { type DragEventHandler, useEffect } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Autocomplete } from '@react-google-maps/api';
import { DSIcons } from 'ds';
import { useDsGa4 } from 'ds/hooks/globals';

import { useRouteInputContext } from './RouteInput.context';
import { useRouteInput } from './useRouteInput';

import { type IRouteInputProps } from './RouteInput.types';

import {
  Caption,
  Container,
  DragAndDropButton,
  DragContainer,
  Fieldset,
  InputComponent,
  Label,
  Legend,
  StyledRemoveIcon,
  Wrapper,
  WrapperFieldset
} from './RouteInput.styles';

export function RouteInput({
  name,
  errorMessage,
  defaultValue,
  label,
  disabled,
  large,
  onChangeValue,
  showRemoveIcon,
  isFinalPoint,
  onRemoveItem,
  itemFormattedAddress,
  small,
  id,
  ...rest
}: IRouteInputProps): JSX.Element {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef
  } = useSortable({ id });

  const { onDragEnd } = useRouteInputContext();

  const { searchValue, setSearchValue } = useRouteInput({
    itemFormattedAddress,
    isDragging
  });

  const { sendDsGaEvent } = useDsGa4();

  useEffect(() => {
    if (isDragging) {
      document.body.style.cursor = 'grabbing';
    } else {
      document.body.style.cursor = 'unset';
    }
  }, [isDragging]);

  return (
    <DragContainer
      style={{
        transition: transition ?? 'unset',
        transform: CSS.Transform.toString(transform)
      }}
      ref={setNodeRef}
      onDragEnd={onDragEnd as unknown as DragEventHandler<HTMLDivElement>}
      $isDragging={isDragging}
      data-testid='dragcontainer'
    >
      <Wrapper>
        {isFinalPoint ? (
          <DSIcons.SecondaryPointIcon />
        ) : (
          <DSIcons.StartingPointIcon />
        )}

        <WrapperFieldset>
          <Container
            $disabled={disabled}
            $hasError={!!errorMessage}
            $large={large}
            $small={small}
            $isTouched={!!searchValue}
          >
            <Label>{label}</Label>

            <Autocomplete
              onLoad={autocomplete => {
                autocomplete &&
                  autocomplete.addListener('place_changed', function () {
                    const place = autocomplete.getPlace();

                    // O usuário pressionou enter sem selecionar um item do dropdown do google
                    // De acordo com a regra de negocio não devemos permitir isso
                    if (!place.geometry) {
                      onChangeValue(null);
                      return;
                    }

                    // Caso ele selecione um local valido do dropdown, atualiza os valores
                    setSearchValue(place.formatted_address || '');
                    onChangeValue(place);
                  });
              }}
            >
              <InputComponent
                {...rest}
                defaultValue={defaultValue}
                disabled={disabled}
                value={searchValue}
                name={name}
                onChange={event => {
                  onChangeValue(null);
                  setSearchValue(event.target.value);

                  sendDsGaEvent('components', 'rotas', {
                    eventValue: event.target.value
                  });
                }}
              />
            </Autocomplete>

            {!disabled && (
              <DragAndDropButton
                type='button'
                {...listeners}
                {...attributes}
                ref={setActivatorNodeRef}
              >
                <DSIcons.DragInputIcon />
              </DragAndDropButton>
            )}
          </Container>

          <Fieldset>
            <Legend>
              <span>{label}</span>
            </Legend>
          </Fieldset>
        </WrapperFieldset>

        {showRemoveIcon && (
          <StyledRemoveIcon
            onClick={() => {
              onRemoveItem?.();

              sendDsGaEvent('components', 'rotas', {
                description: 'click para remover',
                eventLabel: 'remove_rotas'
              });
            }}
          />
        )}
      </Wrapper>

      {!!errorMessage && <Caption $hasError>{errorMessage}</Caption>}
    </DragContainer>
  );
}
