import { SecondaryButton, tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.tokens.spacingXxs};
`;

export const DangerSecondaryButton = styled(SecondaryButton)`
  color: ${tokens.feedbackColorErrorPure};
  border-color: ${tokens.feedbackColorErrorPure};

  &:not(:disabled):hover {
    color: ${tokens.feedbackColorErrorMedium};
    border-color: ${tokens.feedbackColorErrorMedium};
  }

  &:focus {
    outline: ${tokens.borderWidthThick} solid ${tokens.feedbackColorErrorPure}B2;
    color: ${tokens.feedbackColorErrorPure};

    svg * {
      color: ${tokens.feedbackColorErrorPure} !important;
    }
  }

  &:active {
    background-color: ${tokens.feedbackColorErrorMedium};
    color: ${tokens.neutralColorHighPure};
    outline: none;

    svg * {
      color: ${tokens.neutralColorHighPure} !important;
    }
  }
`;
