import { useState } from 'react';

import { useController, useFormContext } from 'react-hook-form';

import {
  type IUseSubMenuSelectControlled,
  type IUseSubMenuSelectControlledParams
} from './SubMenuSelectControlled.types';

export function useSubMenuSelectControlled({
  name
}: IUseSubMenuSelectControlledParams): IUseSubMenuSelectControlled {
  const [menuIndex, setMenuIndex] = useState<undefined | number>(undefined);

  const { control } = useFormContext();

  const {
    fieldState: { error },
    field: { onChange, value, ref }
  } = useController({
    control,
    name
  });

  return {
    menuIndex,
    setMenuIndex,
    value,
    onChange,
    error,
    ref
  };
}
