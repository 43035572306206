import { useState } from 'react';

import { usePopper } from 'react-popper';

import { type IUseFloatingMenu } from './FloatingMenu.types';

export function useFloatingMenu(): IUseFloatingMenu {
  const [isVisible, setIsVisible] = useState(false);

  const [triggerElement, setTriggerElement] = useState<HTMLDivElement | null>(
    null
  );

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const [arrowEelement, setArrowElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles, attributes } = usePopper(triggerElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowEelement,
          padding: 0
        }
      }
    ]
  });

  return {
    setIsVisible,
    styles,
    attributes,
    isVisible,
    setTriggerElement,
    setPopperElement,
    setArrowElement
  };
}
