import {
  type IBalanceSolicitation,
  type IGetMyValueSolicitationsPayload,
  type IPersistenceBalanceSolicitation,
  type IPersistenceGetMyValueSolicitationsPayload
} from 'data/modules/cards/balance';

class MyValueSolicitationsMapper {
  toDomain(data: IPersistenceBalanceSolicitation): IBalanceSolicitation {
    return {
      userId: data.user_id,
      balanceId: data.balance_id,
      destinationAccount: data.destination_account,
      id: data.id,
      dateApproved: data.date_approved,
      justify: data.justify,
      datePrevision: data.date_prevision,
      requestedAmount: data.requested_amount,
      userName: data.user_name,
      status: data.status,
      transferAmount: data.transfer_amount as number,
      transferredAmount: data.transferred_amount,
      dateSent: data.date_sent,
      managerJustify: data.manager_justify ?? null,
      finishDate: data.finish_date,
      approverName: data.approver_name
    };
  }

  toPersistence(
    payload: IGetMyValueSolicitationsPayload
  ): IPersistenceGetMyValueSolicitationsPayload {
    return {
      page: payload.page,
      per_page: payload.limit,
      status:
        payload.status === 'ALL'
          ? ['SENT', 'APPROVED', 'REJECTED']
          : [payload.status],
      justify:
        payload.justify?.toLocaleLowerCase().trim() !== ''
          ? payload.justify?.toLocaleLowerCase().trim()
          : undefined
    };
  }
}

export default new MyValueSolicitationsMapper();
