import { type IModalHeaderProps } from './ModalHeader.types';

import { Container } from './ModalHeader.styles';

export function ModalHeader({
  children,
  gapDisabled,
  ...rest
}: IModalHeaderProps): JSX.Element {
  return (
    <Container
      $gapDisabled={gapDisabled}
      {...rest}
    >
      {children}
    </Container>
  );
}
