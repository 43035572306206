import { type BaseSyntheticEvent, Children, cloneElement } from 'react';

import { DefaultButton, DSIcons } from 'ds';
import { useTranslation } from 'react-i18next';

import { useAppliedFiltersContainer } from './useAppliedFiltersContainer';

import { type IAppliedFiltersContainerProps } from './AppliedFiltersContainer.types';

import {
  Container,
  Content,
  InfoText,
  Label,
  Wrapper
} from './AppliedFiltersContainer.styles';

export function AppliedFiltersContainer({
  children,
  onClick,
  filtersCount,
  onEditFiltersClick,
  ...props
}: IAppliedFiltersContainerProps): JSX.Element {
  const { t } = useTranslation('analytics');

  const { isOpen, toggleSetIsOpen } = useAppliedFiltersContainer();

  const Items = (Children.toArray(children) as JSX.Element[]).map(child =>
    cloneElement(child, {
      onClick: (e: BaseSyntheticEvent) => {
        child?.props?.onClick?.();
        onEditFiltersClick?.();
        e.stopPropagation();
      }
    })
  );

  return (
    <Container
      {...props}
      $isOpen={isOpen}
      onClick={e => {
        toggleSetIsOpen();
        onClick?.(e);
      }}
    >
      <Label>
        {t('filters.appliedFilters')}
        <DSIcons.ArrowDownIcon />
      </Label>
      <Wrapper>
        <InfoText>{`${filtersCount} ${t('filters.appliedFilters')}`}</InfoText>
        <Content>{Items}</Content>
        <DefaultButton
          size='small'
          onClick={e => {
            onEditFiltersClick?.();
            e.stopPropagation();
          }}
        >
          <DSIcons.FilterSortIcon />
          {t('filters.editFilters')}
        </DefaultButton>
      </Wrapper>
    </Container>
  );
}
