import {
  type IPersistenceUpdateAnswerableUserPayload,
  type IUpdateAnswerableUserPayload
} from 'data/modules/cards/companyOnboarding';

import { Mask } from 'shared/utils/format';

class UpdateAnswerableUserPayloadMapper {
  toPersistence(
    payload: Omit<IUpdateAnswerableUserPayload, 'uuidCompanyOnboarding'>
  ): IPersistenceUpdateAnswerableUserPayload {
    return {
      full_name: payload.fullName,
      document_number: Mask.remove(payload.documentNumber),
      phone_number: `55${Mask.remove(payload.phoneNumber)}`
    };
  }
}

export default new UpdateAnswerableUserPayloadMapper();
