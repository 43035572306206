import { useShallow } from 'zustand/react/shallow';

import { useCardDetailsContext, useModalContext } from 'data/contexts';

import {
  type IUseGroupManagementTable,
  type IUseGroupManagementTableProps
} from './GroupManagementTableRow.types';

export function useGroupManagementTableRow({
  user
}: IUseGroupManagementTableProps): IUseGroupManagementTable {
  const handleOpenModal = useModalContext(state => state.handleOpenModal);

  const [handleSetSelectedAccount, selectedAccount] = useCardDetailsContext(
    useShallow(state => [state.handleSetSelectedAccount, state.selectedAccount])
  );

  function handleOpenDetails(): void {
    if (selectedAccount === null) {
      handleOpenModal('cardDetails');

      handleSetSelectedAccount({
        balanceId: user.balanceId,
        name: user.name,
        userId: user.userId
      });
    }
  }

  return {
    handleOpenDetails
  };
}
