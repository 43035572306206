import { useState } from 'react';

import { Types } from 'ds';

import {
  type IMultiSelectDefaultGroupedOption,
  type IUseMultiSelectDefault,
  type OnChangeMultiSelectDefaultType,
  type UseMultiSelectDefaultParamsType
} from './MultiSelectDefault.types';

export function useMultiSelectDefault({
  options
}: UseMultiSelectDefaultParamsType): IUseMultiSelectDefault {
  const [searchValue, setSearchValue] = useState<string>('');

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchValue.toLowerCase())
  );

  function handleMultiSelectDefaultFilteredOptions(
    value: string[],
    onChange: OnChangeMultiSelectDefaultType
  ): void {
    onChange([
      ...value,
      ...filteredOptions
        .map(option =>
          Types.isType<IMultiSelectDefaultGroupedOption, typeof option>(
            option,
            'options'
          )
            ? option.options
            : [option]
        )
        .flat()
        .filter(option => !value.includes(option.value))
        .map(option => option.value)
    ]);
    setSearchValue('');
  }

  return {
    searchValue,
    setSearchValue,
    filteredOptions,
    handleMultiSelectDefaultFilteredOptions
  };
}
