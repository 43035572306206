import { type IDefaultTableFooterProps } from './DefaultTableFooter.types';

import { Container } from './DefaultTableFooter.styles';

export function DefaultTableFooter({
  children,
  ...rest
}: IDefaultTableFooterProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
