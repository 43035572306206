import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import { DsTextInput } from 'presentation/ds/DsTextInput';
import {
  OnboardingFooter,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { useUserData } from './useUserData';

import { Container, CustomForm } from './UserData.styles';

export function UserData(): JSX.Element {
  const navigate = useNavigate();

  const [userData, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.user.user_data,
    state.currentLangKey
  ]);

  const { control, updateUserOnboardingData, errors, setValue } = useUserData();

  return (
    <Container>
      <OnboardingTitle>{userData.title[currentLangKey]}</OnboardingTitle>

      <CustomForm onSubmit={updateUserOnboardingData}>
        <DsTextInput
          control={control}
          name='fullName'
          label={userData.form.full_name[currentLangKey]}
          error={errors.fullName?.message}
          mask='letters'
          onClear={() => {
            setValue('fullName', '');
          }}
        />

        <DsTextInput
          control={control}
          name='documentNumber'
          label='CPF'
          mask='cpf'
          error={errors.documentNumber?.message}
          onClear={() => {
            setValue('documentNumber', '');
          }}
        />

        <DsTextInput
          control={control}
          name='motherName'
          label={userData.form.mother_name[currentLangKey]}
          error={errors.motherName?.message}
          mask='letters'
          onClear={() => {
            setValue('motherName', '');
          }}
        />

        <DsTextInput
          control={control}
          name='birthDate'
          label={userData.form.birth_date[currentLangKey]}
          mask='date'
          error={errors.birthDate?.message}
          onClear={() => {
            setValue('birthDate', '');
          }}
        />

        <DsTextInput
          control={control}
          name='phoneNumber'
          label={userData.form.phone_number[currentLangKey]}
          mask='phone'
          error={errors.phoneNumber?.message}
          onClear={() => {
            setValue('phoneNumber', '');
          }}
        />

        <DsTextInput
          control={control}
          name='email'
          label={userData.form.email[currentLangKey]}
          error={errors.email?.message}
          onClear={() => {
            setValue('email', '');
          }}
        />

        <OnboardingFooter
          backButtonProps={{
            onClick: () => {
              navigate('/cartoes/onboarding/usuario/1');
            }
          }}
          continueButtonProps={{
            onClick: () => {}
          }}
        />
      </CustomForm>
    </Container>
  );
}
