import { faker } from '@faker-js/faker';

import { type IPendency } from 'data/modules/cards/pendencies';

import { MockApi } from 'shared/utils/global';

export async function getPendenciesListByAccountBalanceIdMock(): Promise<
  IPendency[]
> {
  const data = Array.from({ length: 5 }, () => ({
    id: faker.string.uuid(),
    type: 'WITHDRAW',
    amount: faker.number.float({ max: 500 }) * -1,
    transactionId: faker.string.uuid(),
    createdAt: faker.date.past().toISOString()
  }));

  await MockApi.sleep(2000);

  return data;
}
