import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useCardGroupsContext } from 'data/contexts';

import {
  informativeModalIcons,
  type IUseCardGroupInfoModal
} from './CardsGroupsInfoModal.types';

export function useCardGroupsInfoModal(): IUseCardGroupInfoModal {
  const { t } = useTranslation('cards');

  const currentStepOfGroupCreation = useCardGroupsContext(
    state => state.currentStepOfGroupCreation
  );

  const content = useMemo(() => {
    const descriptions = [
      t('cardsGroupsCreationExplication.0'),
      t('cardsGroupsCreationExplication.1'),
      t('cardsGroupsCreationExplication.2'),
      t('cardsGroupsCreationExplication.3'),
      t('cardsGroupsCreationExplication.4')
    ];

    const icon = informativeModalIcons[currentStepOfGroupCreation];
    const descriptionIndex = currentStepOfGroupCreation;
    const description = descriptions[descriptionIndex];

    return { Icon: icon, description };
  }, [currentStepOfGroupCreation, t]);

  return {
    content
  };
}
