import { Outlet } from 'react-router-dom';

import { useAuthContext } from 'data/contexts';

import { FallbackPage } from 'presentation/pages/global';

export function CompanyOnboardingMiddleware(): JSX.Element {
  const company = useAuthContext(state => state.company);

  if (company?.cards?.isOnboardingFinished) {
    return <FallbackPage error='401' />;
  }

  return <Outlet />;
}
