export const tokens = {
  borderRadiusNone: 0,
  borderRadiusNano: '0.4rem',
  borderRadiusXs: '0.8rem',
  borderRadiusSm: '1.2rem',
  borderRadiusMd: '1.6rem',
  borderRadiusLg: '2rem',
  borderRadiusXl: '2.4rem',
  borderRadiusXxl: '3.2rem',
  borderRadiusPill: '4.8rem',
  borderRadiusCircular: '20rem',
  borderWidthNone: 0,
  borderWidthHairline: '0.1rem',
  borderWidthThin: '0.2rem',
  borderWidthThick: '0.8rem',
  lineHeightDefault: '1.00rem',
  lineHeightXxss: '1.16rem',
  lineHeightXxs: '1.28rem',
  lineHeightXs: '1.32rem',
  lineHeightSm: '1.35rem',
  lineHeightMd: '1.38rem',
  lineHeightLg: '1.40rem',
  lineHeightXl: '1.50rem',
  lineHeightXxl: '1.60rem',
  lineHeightXxxl: '1.65rem',
  lineHeightDisplay: '1.70rem',
  fontWeightBold: 700,
  fontWeightSemibold: 600,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
  fontSizeXxss: '1.3rem',
  fontSizeXxs: '1.5rem',
  fontSizeXs: '1.8rem',
  fontSizeSm: '2rem',
  fontSizeMd: '2.4rem',
  fontSizeLg: '3.2rem',
  fontSizeXl: '3.6rem',
  fontSizeXxl: '4.8rem',
  fontSizeXxxl: '5.6rem',
  fontSizeDisplay: '6.4rem',
  spacingMini: '0.2rem',
  spacingQuarck: '0.4rem',
  spacingNano: '0.8rem',
  spacingXxs: '1.2rem',
  spacingXs: '1.6rem',
  spacingSm: '2rem',
  spacingMd: '2.4rem',
  spacingLg: '3.2rem',
  spacingXl: '4rem',
  spacingXxl: '4.8rem',
  spacingXxxl: '5.6rem',
  spacingHuge: '9.6rem',
  spacingGiant: '12.8rem',
  shadowLevel1: {
    color: '#000000',
    type: 'dropShadow',
    x: '0',
    y: '0',
    blur: '16px',
    spread: '0px'
  },
  shadowLevel2: {
    color: '#0000000a',
    type: 'dropShadow',
    x: '0',
    y: '0',
    blur: '32px',
    spread: '0'
  },
  shadowLevel3: {
    color: '#000000',
    type: 'dropShadow',
    x: '0',
    y: '0',
    blur: '72px',
    spread: '0'
  },
  shadowLeve4: {
    x: '0',
    y: '8px',
    blur: '16px',
    spread: '0',
    color: 'rgba(170,170,170,0.16)',
    type: 'dropShadow'
  },
  fontFamilyPoppins: 'Poppins',
  fontFamilyRoboto: 'Roboto',
  neutralColorLowPure: '#111111',
  neutralColorLowDarkest: '#222222',
  neutralColorLowDark: '#444444',
  neutralColorLowMedium: '#666666',
  neutralColorLowMediumLight: '#888888',
  neutralColorLowLight: '#AAAAAA',
  neutralColorLowLightest: '#BBBBBB',
  neutralColorHighDarkest: '#CCCCCC',
  neutralColorHighDark: '#DDDDDD',
  neutralColorHighMedium: '#EEEEEE',
  neutralColorHighLight: '#F7F7F7',
  neutralColorHighPure: '#fcfcfc',
  brandColorPrimaryDark: '#002040',
  brandColorPrimaryMedium: '#0072DF',
  brandColorPrimaryPure: '#3fa1ff',
  brandColorPrimaryLight: '#8CC7FF',
  brandColorPrimaryLightest: '#ECF6FF',
  brandColorSecondaryDark: '#02192D',
  brandColorSecondaryMedium: '#07589F',
  brandColorSecondaryPure: '#0a7ee3',
  brandColorSecondaryLight: '#62B2F8',
  brandColorSecondaryLightest: '#E5F2FE',
  highlightColorDark: '#351A02',
  highlightColorMedium: '#BB5C07',
  highlightColorPure: '#F7841E',
  highlightColorMediumLight: '#FAB578',
  highlightColorLight: '#f6ac3c80',
  highlightColorLightest: '#FEF3E9',
  feedbackColorSuccessDark: '#001C06',
  feedbackColorSuccessMedium: '#006216',
  feedbackColorSuccessPure: '#008C1F',
  feedbackColorSuccessLight: '#00D62F',
  feedbackColorSuccessLightest: '#DAFFE2',
  feedbackColorWarningDark: '#3B2403',
  feedbackColorWarningMedium: '#CD7F09',
  feedbackColorWarningMediumDark: '#844002',
  feedbackColorWarningPure: '#F6AC3C',
  feedbackColorWarningLight: '#FACD8A',
  feedbackColorWarningLightest: '#FEF7EB',
  feedbackColorErrorDark: '#2D0101',
  feedbackColorErrorMedium: '#9D0505',
  feedbackColorErrorPure: '#E00707',
  feedbackColorErrorMediumLight: '#FA5D5D',
  feedbackColorErrorLight: '#fa5d5db3',
  feedbackColorErrorLightest: '#FEE4E4',
  fontFamiliesPoppins: 'Poppins',
  textCaseNone: 'none',
  textDecorationNone: 'none',
  spacingInsetMini: '0.2rem',
  spacingInsetQuarck: '0.4rem',
  spacingInsetQuarckk: '0.5rem',
  spacingInsetNano: '0.8rem',
  spacingInsetXxxs: '1rem',
  spacingInsetXxs: '1.2rem',
  spacingInsetXs: '1.6rem',
  spacingInsetX: '1.8rem',
  spacingInsetSm: '2rem',
  spacingInsetMd: '2.2rem',
  spacingInsetMdd: '2.4rem',
  spacingInsetLg: '3.2rem',
  spacingInsetXl: '4rem',
  spacingInsetXxl: '4.8rem',
  spacingInsetHuge: '5.6rem',
  spacingInsetGiant: '7.2rem',
  spacingInsetDisplay: '12.8rem',
  borderRadiusXxxl: '4rem',
  baseColorBlack: '#081018',
  baseColorWhite: '#F7F7F7',
  deepColorBlack: '#000000',
  deepColorWhite: '#FFFFFF',
  borderRadiusThin: '0.2rem',
  supportsColorLowPure: '#102030',
  supportsColorLowDakest: '#183048',
  supportsColorLowDark: '#285078',
  supportsColorLowMedium: '#3870a7',
  supportsColorLowLight: '#588fc7',
  supportsColorLowLightest: '#709FCF',
  supportsColorHighPure: '#88afd7',
  supportsColorHighDark: '#9FBFDF',
  supportsColorHighMedium: '#B7CFE7',
  supportsColorHighLight: '#CFDFEF',
  supportsColorHighLightest: '#E7EFF7'
};
