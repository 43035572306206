import {
  type ICardGroup,
  type ICardGroupManager,
  type ICardGroupsDistributedValue,
  type IPersistenceCardGroup,
  type IPersistenceCardGroupsDistributedValue
} from 'data/modules/cards/cardGroups';

export const mockCardGroups: ICardGroup[] = [
  {
    id: '1',
    name: 'Comercial',
    balanceId: '123',
    availableValue: undefined,
    isManager: false
  },
  {
    id: '2',
    name: 'Customer Success',
    balanceId: '123456',
    availableValue: undefined,
    isManager: false
  },
  {
    id: '3',
    name: 'Customer Experience',
    balanceId: '12345',
    availableValue: undefined,
    isManager: false
  },
  {
    id: '4',
    name: 'Engenharia',
    balanceId: '1',
    availableValue: undefined,
    isManager: false
  },
  {
    id: '5',
    name: 'Financeiro',
    balanceId: '123455',
    availableValue: undefined,
    isManager: false
  }
];

export const persistenceMockCardGroups: IPersistenceCardGroup[] = [
  {
    id: '1',
    name: 'Comercial',
    balance_id: '213',
    is_manager: false
  },
  {
    id: '2',
    name: 'Customer Success',
    balance_id: '21asa3',

    is_manager: false
  },
  {
    id: '3',
    name: 'Customer Experience',
    balance_id: '2asasa13',
    is_manager: false
  },
  {
    id: '4',
    name: 'Engenharia',
    balance_id: '232323',
    is_manager: false
  },
  {
    id: '5',
    name: 'Financeiro',
    balance_id: '22423413',
    is_manager: false
  }
];

export const mockCardGroupsAvailableValue: Record<string, number> = {
  '1': 500,
  '2': 0,
  '3': 1000,
  '4': 100,
  '5': 0
};

export const mockCardGroupsDistributedValue: ICardGroupsDistributedValue = {
  amount: 1234,
  lastUpdateDate: '27/02/2024',
  lastUpdateTime: '16h59m'
};

export const persistenceMockCardGroupsDistributedValue: IPersistenceCardGroupsDistributedValue =
  {
    amount: 1234,
    date: '2024-02-27T16:59:12.509207Z'
  };

export const managersMock: ICardGroupManager[] = [
  {
    id: '123',
    name: 'Marcus Smith'
  },
  {
    id: '1234',
    name: 'Joana Jones'
  },
  {
    id: '1235',
    name: 'Nolan Hofford'
  },
  {
    id: '1236',
    name: 'Robbie Garryson'
  },
  {
    id: '54687',
    name: 'Marie Robert'
  }
];
