import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  differenceInMonths,
  format,
  isAfter,
  isFuture,
  subDays
} from 'date-fns';
import { toast } from 'ds';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useModalContext, useMovementsContext } from 'data/contexts';
import {
  useGetCompanyAccounts,
  useGetUserAccounts
} from 'data/modules/cards/account';
import { useGetCardGroup } from 'data/modules/cards/account/useCases/get-card-group/useGetCardGroup';
import { useGetCardGroupUsers } from 'data/modules/cards/cardGroups/useCases/get-card-group-users/useGetCardGroupUsers';
import { useExportMovementsInPdf } from 'data/modules/cards/movements';
import { useExportMovementsInExcel } from 'data/modules/cards/movements/useCases/export-movements-excel/useExportMovementsInExcel';

import { useGetActiveBalanceIds } from 'shared/hooks/cards';

import { type IUseMovementsTableFilters } from './MovementsTableFilters.types';

export function useMovementsTableFilters(): IUseMovementsTableFilters {
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [shouldBlurMultiSelect, setShouldBlurMultiselect] = useState(false);
  const { groupId, userId } = useParams();
  const { t } = useTranslation(['errors', 'cards', 'global']);

  const {
    activeAccountBalancesIds: activeAccountBalancesId,
    isFetchingActiveBalanceIds: isFetchingActiveBalanceId
  } = useGetActiveBalanceIds();

  const [
    movementsFilters,
    transactionsToExport,
    handleChangeMovementsFilters,
    handleChangeDateFilterValidatingStatus
  ] = useMovementsContext(
    useShallow(state => [
      state.movementsFilters,
      state.transactionsToExport,
      state.handleChangeMovementsFilters,
      state.handleChangeDateFilterValidatingStatus
    ])
  );

  const { handleOpenModal } = useModalContext();

  const filters = movementsFilters;
  const isCompanySelected = !!movementsFilters.accounts?.includes('COMPANY');

  const { paginatedUserAccounts, isFetchingUserAccounts } = useGetUserAccounts({
    limit: 1000,
    page: 1,
    status: ['ACTIVE'],
    enabled: !groupId
  });

  const { paginatedCompanyAccounts, isFetchingCompanyAccounts } =
    useGetCompanyAccounts({
      page: 1,
      limit: 1000,
      status: ['ACTIVE'],
      enabled: !groupId
    });

  const { cardGroupData, isFetchingCardGroupData } = useGetCardGroup({
    cardGroupId: groupId!,
    enabled: !!groupId
  });

  const { cardGroupUsers, isFetchingCardGroupUsers } = useGetCardGroupUsers(
    {
      id: groupId as string,
      limit: 1000,
      page: 1
    },
    !!groupId
  );

  const { exportMovementsInPdf, isExportingMovementsInPdf } =
    useExportMovementsInPdf();

  const { exportMovementsInExcel, isExportingMovementsInExcel } =
    useExportMovementsInExcel();

  const accounts = useMemo(() => {
    const userAccounts = paginatedUserAccounts?.data
      ? paginatedUserAccounts.data.map(user => ({
          label: user.userName,
          value: user.account.balances[0].id
        }))
      : [];

    const companyAccounts = paginatedCompanyAccounts?.data
      ? paginatedCompanyAccounts.data.map(company => ({
          label: company.name,
          value: company.balances[0].id
        }))
      : [];

    const cardGroupAccounts =
      cardGroupUsers?.data && groupId
        ? cardGroupUsers.data.map(company => ({
            label: company.name,
            value: company.balanceId
          }))
        : [];

    const concatenedArray = groupId
      ? cardGroupAccounts
      : userAccounts.concat(companyAccounts);

    return concatenedArray.sort((a, b) => {
      const nameA = a.label.toLowerCase();
      const nameB = b.label.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }, [
    cardGroupUsers?.data,
    groupId,
    paginatedCompanyAccounts?.data,
    paginatedUserAccounts?.data
  ]);

  function handleChangeUsersFilter(value: string[] | null): void {
    if (value?.length === 0) {
      handleChangeMovementsFilters({
        ...movementsFilters,
        accounts: ['COMPANY']
      });

      return;
    }

    if (value?.includes('ALL')) {
      handleChangeMovementsFilters({
        ...movementsFilters,
        accounts: ['COMPANY']
      });
      handleOpenModal('exportMovementsPdf');

      return;
    }

    if (value?.includes('COMPANY')) {
      handleChangeMovementsFilters({
        ...movementsFilters,
        accounts:
          value.length > 1 && value[0] === 'COMPANY'
            ? value.filter(item => item !== 'COMPANY')
            : ['COMPANY']
      });

      return;
    }

    handleChangeMovementsFilters({
      ...movementsFilters,
      accounts: value?.length === 0 ? ['COMPANY'] : value
    });
  }

  const validateDate = useCallback(() => {
    setStartDateError('');
    setEndDateError('');

    if (!filters.startDate || !filters.endDate) {
      handleChangeDateFilterValidatingStatus('ERROR');

      return;
    }

    const isStartDateInFuture = isFuture(filters.startDate);
    const isEndDateInFuture = isFuture(filters.endDate);

    if (isStartDateInFuture || isEndDateInFuture) {
      if (isStartDateInFuture) {
        setStartDateError(t('futureDate'));
      }

      if (isEndDateInFuture) {
        setEndDateError(t('futureDate'));
      }

      handleChangeDateFilterValidatingStatus('ERROR');

      return;
    }

    if (isAfter(filters.startDate, filters.endDate)) {
      setStartDateError(t('startDateLater'));

      setEndDateError(t('startDateLater'));

      handleChangeDateFilterValidatingStatus('ERROR');

      return;
    }

    const dateDifferenceInMonths = differenceInMonths(
      subDays(filters.endDate, 1),
      filters.startDate
    );

    if (dateDifferenceInMonths >= 3) {
      setEndDateError(t('threeMonthsDateLimit'));

      handleChangeDateFilterValidatingStatus('ERROR');

      return;
    }

    handleChangeDateFilterValidatingStatus('OK');
  }, [
    filters.endDate,
    filters.startDate,
    handleChangeDateFilterValidatingStatus,
    t
  ]);

  async function handleExportMovements(
    fileType: 'PDF' | 'EXCEL'
  ): Promise<void> {
    try {
      if (fileType === 'PDF') {
        const { url } = await exportMovementsInPdf({
          all: false,
          balancesId: userId
            ? activeAccountBalancesId
            : filters.accounts?.filter(
                account => account !== 'COMPANY' && account !== 'ALL'
              ) || [],
          endDate: filters.endDate,
          startDate: filters.startDate,
          timezone: filters.timezone,
          transactionsId: transactionsToExport,
          cardGroupId: groupId
        });

        window.open(url, '_blank');

        return;
      }

      const { url } = await exportMovementsInExcel({
        all: false,
        balancesId: userId
          ? activeAccountBalancesId
          : filters.accounts?.filter(
              account => account !== 'COMPANY' && account !== 'ALL'
            ) || [],
        endDate: filters.endDate,
        startDate: filters.startDate,
        timezone: filters.timezone,
        transactionsId: transactionsToExport,
        cardGroupId: groupId
      });

      window.open(url, '_blank');
    } catch (err) {
      toast.error(t('cards:errorWhenExporting'));
    }
  }

  function handleBlurMultiselect(shouldBlur: boolean): void {
    setShouldBlurMultiselect(shouldBlur);
  }

  useEffect(() => {
    validateDate();
  }, [validateDate]);

  useEffect(() => {
    handleChangeMovementsFilters({
      movementType: 'ALL',
      endDate: format(new Date(), 'yyyy-MM-dd'),
      startDate: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      timezone: '-3:00',
      accounts: ['COMPANY']
    });
  }, [handleChangeMovementsFilters]);

  const movementsTypeOptions = [
    {
      value: 'ALL',
      label: t('global:all_female_other')
    },
    {
      value: 'CREDIT',
      label: t('global:entries')
    },
    {
      value: 'DEBIT',
      label: t('global:exits')
    }
  ];

  return {
    accounts,
    isLoading:
      isFetchingUserAccounts ||
      isFetchingCompanyAccounts ||
      isFetchingCardGroupData ||
      isFetchingCardGroupUsers ||
      isFetchingActiveBalanceId,
    startDate: filters.startDate,
    endDate: filters.endDate,
    type: filters.movementType,
    startDateError,
    endDateError,
    isExportingMovementsInPdf,
    isExportingMovementsInExcel,
    cardGroupData,
    isCompanySelected,
    shouldBlurMultiSelect,
    movementsTypeOptions,
    handleChangeUsersFilter,
    handleExportMovements,
    handleBlurMultiselect
  };
}
