import {
  type IAccountHolder,
  type IPersistenceAccountHolder
} from 'data/modules/cards/accountHolders';

import { CustomObject } from 'shared/utils/custom';

class AccountHolderMapper {
  toDomain(data: IPersistenceAccountHolder): IAccountHolder {
    return {
      id: data.id,
      companyId: data.company_id,
      legalName: data.legal_name,
      tradingName: data.trading_name,
      businessType: data.business_type,
      documentNumber: data.document_number,
      phoneNumber: data.phone_number,
      postalCode: data.postal_code,
      foundingDate: data.founding_date,
      mainActivity: data.main_activity,
      kycStatus: data.kyc_status,
      shareholders: data.shareholders.map(persistenceShareholder => {
        return {
          phoneNumber: persistenceShareholder.phone_number,
          motherName: persistenceShareholder.mother_name,
          birthDate: persistenceShareholder.birth_date,
          postalCode: persistenceShareholder.postal_code,
          swapType: persistenceShareholder.swap_type,
          ...CustomObject.pick(persistenceShareholder, [
            'city',
            'complement',
            'country',
            'document',
            'email',
            'name',
            'neighborhood',
            'number',
            'state',
            'street',
            'id'
          ])
        };
      }),
      ...CustomObject.pick(data, [
        'city',
        'complement',
        'country',
        'email',
        'name',
        'neighborhood',
        'number',
        'state',
        'street',
        'website',
        'type',
        'subtype'
      ])
    };
  }
}

export default new AccountHolderMapper();
