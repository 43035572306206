import { useParams } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import { RectButton } from 'presentation/components/base/Button';
import { Checkbox } from 'presentation/components/base/Input';
import { Select } from 'presentation/components/base/Select';
import { Spinner } from 'presentation/components/base/Spinner';
import { InputSearchFilter } from 'presentation/components/global/InputSearchFilter';
import { Title } from 'presentation/components/global/Title';
import {
  DateFilter,
  MovementFilter
} from 'presentation/pages/cards/Statement/components/Filters';

import { useAdminStatementSidebar } from './useAdminStatementSidebar';

import { type IAdminStatementSidebarProps } from './AdminStatementSidebar.types';

import {
  ActionsButtonsContainer,
  Container,
  FiltersContent
} from './AdminStatementSidebar.styles';

export function AdminStatementSidebar({
  onFilter,
  initialDateFilter,
  initialSelectedUsers,
  checkedAllUsers,
  onToggleCheckedAllUsers,
  isGeneratingFile,
  onGenerateExcel,
  onGeneratePdf,
  cardsToBeFiltered
}: IAdminStatementSidebarProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const {
    isLoadingOptions,
    filters: {
      dateFilter,
      selectedMovementType,
      selectedUsers,
      selectedCards,
      setDateFilter,
      searchInputRef,
      setSelectedCards,
      setSelectedMovementType,
      setSelectedUsers
    },
    errors: { dateFilterFromError, dateFilterToError },
    handleFilter,
    selectUsersProps,
    cardsFilterDisabled
  } = useAdminStatementSidebar({
    onFilter,
    initialDateFilter,
    initialSelectedUsers,
    cardsToBeFiltered,
    checkedAllUsers
  });

  const { groupId } = useParams();

  return (
    <Container>
      {!groupId && (
        <Title
          backgroundColor='alabaster'
          text={lang.cards.statement.sidebar.full_statement[currentLangKey]}
        />
      )}

      <FiltersContent onSubmit={handleFilter}>
        <MovementFilter
          onChange={movementType => {
            setSelectedMovementType(movementType);
          }}
          selected={checkedAllUsers ? 'all' : selectedMovementType}
        />
        <InputSearchFilter
          disabled={checkedAllUsers}
          ref={searchInputRef}
        />
        <DateFilter
          from={dateFilter.from}
          to={dateFilter.to}
          onChangeFrom={date => {
            setDateFilter({
              ...dateFilter,
              from: date
            });
          }}
          onChangeTo={date => {
            setDateFilter({
              ...dateFilter,
              to: date
            });
          }}
          errors={{
            from: dateFilterFromError,
            to: dateFilterToError
          }}
        />

        {!groupId && (
          <Checkbox
            id='all-users-filter'
            label={lang.cards.statement.sidebar.all_users[currentLangKey]}
            checked={checkedAllUsers}
            onToggle={() => {
              if (!checkedAllUsers) {
                searchInputRef.current?.value &&
                  (searchInputRef.current.value = '');
              }
              onToggleCheckedAllUsers();
            }}
          />
        )}

        <Select
          hasCleanButtonOutsideInput
          hasSelectAllOptionsButton
          selectFilteredOptionsOnEnter
          isMulti
          label={lang.global.users[currentLangKey]}
          name='users'
          onChange={value => {
            setSelectedUsers(
              (value as typeof selectedUsers).filter(user => user !== 'company')
            );
          }}
          isLoading={!groupId ? isLoadingOptions : false}
          disabled={checkedAllUsers}
          options={selectUsersProps.options}
          value={checkedAllUsers ? [] : selectUsersProps.value}
          cleanButtonDisabled={selectedUsers.length === 0}
        />

        {!groupId && (
          <Select
            hasCleanButtonOutsideInput
            selectFilteredOptionsOnEnter
            isMulti
            name='cards'
            label={lang.global.cards[currentLangKey]}
            options={cardsToBeFiltered}
            onChange={value => {
              setSelectedCards(value as typeof selectedCards);
            }}
            value={checkedAllUsers ? [] : selectedCards}
            isLoading={isLoadingOptions}
            disabled={cardsFilterDisabled}
            cleanButtonDisabled={selectedCards.length === 0}
          />
        )}
        <ActionsButtonsContainer>
          {isGeneratingFile && <Spinner size={2.2} />}

          <RectButton
            color='primary'
            size='xl'
            disabled={
              !!(dateFilterFromError || dateFilterToError) ||
              checkedAllUsers ||
              isGeneratingFile
            }
            type='submit'
          >
            {lang.cards.statement.sidebar.action_filter[currentLangKey]}
          </RectButton>

          <RectButton
            color='carnation'
            size='xl'
            disabled={
              !!(dateFilterFromError || dateFilterToError) || isGeneratingFile
            }
            type='button'
            onClick={() => {
              onGeneratePdf({
                dateFilter,
                selectedMovementType,
                selectedUsers,
                selectedCards,
                searchInput: searchInputRef.current?.value ?? ''
              });
            }}
          >
            {lang.cards.statement.sidebar.action_generate_pdf[currentLangKey]}
          </RectButton>

          <RectButton
            color='success'
            size='xl'
            disabled={
              !!(dateFilterFromError || dateFilterToError) || isGeneratingFile
            }
            type='button'
            onClick={() => {
              onGenerateExcel({
                dateFilter,
                selectedMovementType,
                selectedUsers,
                selectedCards,
                searchInput: searchInputRef.current?.value ?? ''
              });
            }}
          >
            {lang.cards.statement.sidebar.action_generate_excel[currentLangKey]}
          </RectButton>
        </ActionsButtonsContainer>
      </FiltersContent>
    </Container>
  );
}
