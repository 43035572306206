import { type IChargeCardsTableEmptyProps } from './ChargeCardsTableEmpty.types';

import { Container } from './ChargeCardsTableEmpty.styles';

export function ChargeCardsTableEmpty({
  children,
  ...rest
}: IChargeCardsTableEmptyProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
