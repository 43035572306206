import { Outlet, useLocation } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useAuthContext, useFlagsContext } from 'data/contexts';

import { FallbackPage } from 'presentation/pages/global';

export function CardsMiddleware(): JSX.Element {
  const [company, user] = useAuthContext(
    useShallow(state => [state.company, state.user])
  );
  const { pathname } = useLocation();
  const adminMovementsFlag = useFlagsContext(state => state.adminMovementsFlag);

  if (!company?.cards?.isOnboardingFinished) {
    return <FallbackPage />;
  }

  if (
    !user?.cards?.isOnboardingFinished &&
    user?.cards?.type !== 'VIRTUAL' &&
    !user?.cards?.isManagerWithoutAccount &&
    user?.cards?.type !== 'ACCOUNT_MANAGER' &&
    !user?.cards?.isCardGroupManagerWithoutAccount
  ) {
    return <FallbackPage error='401' />;
  }

  if (
    user?.cards?.type === 'ACCOUNT_MANAGER' &&
    !user?.cards?.isOnboardingFinished &&
    pathname.includes('meu-cartao')
  ) {
    return <FallbackPage error='401' />;
  }

  if (
    !adminMovementsFlag &&
    pathname.includes('movimentacoes') &&
    !pathname.includes('gestao-de-grupos')
  ) {
    return <FallbackPage />;
  }

  return <Outlet />;
}
