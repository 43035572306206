import {
  type IPersistencePreRegisteredSection,
  type IPreRegisteredSection
} from 'data/modules/preRegisteredSections';

class PreRegisteredSectionsMapper {
  toDomain(
    persistenceSections: IPersistencePreRegisteredSection
  ): IPreRegisteredSection {
    return {
      mileage: persistenceSections.mileage,
      name: persistenceSections.description,
      id: persistenceSections.id
    };
  }
}

export default new PreRegisteredSectionsMapper();
