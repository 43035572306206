import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: ${tokens.spacingInsetXs};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mid}px) {
    gap: ${tokens.spacingXs};
    flex-wrap: nowrap;
  }

  .date-picker-solicitation {
    min-width: 100%;
  }
`;

export const SearchbarContainer = styled.div`
  display: flex;

  gap: ${tokens.spacingXs};

  > div {
    max-width: 30rem;
  }

  > div:first-child {
    max-width: 30rem;
  }

  svg {
    width: 3rem;
    height: 3rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    margin-right: 0;
    width: 100%;

    > div {
      max-width: 100%;
    }

    > div:first-child {
      max-width: 100%;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingMd};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;

    > button {
      width: 100%;
    }
  }
`;
