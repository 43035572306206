import { addDays, format } from 'date-fns';

import {
  type IExportMovementsInPdfPayload,
  type IPersistenceExportMovementsInPdfPayload
} from 'data/modules/cards/movements';

class ExportMovementsInPdfMapper {
  toPersistence(
    payload: IExportMovementsInPdfPayload
  ): IPersistenceExportMovementsInPdfPayload {
    return {
      all: payload.all,
      balances_id: payload.balancesId,
      start_date: `${format(addDays(payload.startDate, 1), 'yyyy-MM-dd').concat('T00:00:00.000Z')}`,
      end_date: `${format(addDays(payload.endDate, 1), 'yyyy-MM-dd').concat('T00:00:00.000Z')}`,
      timezone: payload.timezone,
      transactions_id: payload.transactionsId,
      card_group_id: payload.cardGroupId || undefined
    };
  }
}

export default new ExportMovementsInPdfMapper();
