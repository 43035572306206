import { DSIcons, TextButton, tokens } from 'ds';

import { Icons } from 'shared/constants/global';

import { type IInformationRowProps } from './InformationRow.types';

import { Container } from './InformationRow.styles';

export function InformationRow({
  onActionClick,
  buttonLabel,
  label
}: IInformationRowProps): JSX.Element {
  return (
    <Container>
      <div>
        <Icons.InfoIcon color={tokens.neutralColorLowLight} />
        <p>{label}</p>
      </div>
      <TextButton
        size='small'
        onClick={onActionClick}
      >
        {buttonLabel}
        <DSIcons.ArrowRightIcon />
      </TextButton>
    </Container>
  );
}
