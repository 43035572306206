import { DefaultButton } from 'ds';

import { useProductBanner } from './useProductBanner';

import { type IProductBannerProps } from './ProductBanner.types';

import { BannerImage, Container, Content } from './ProductBanner.styles';

export function ProductBanner({
  title,
  banner,
  buttonText,
  description,
  reverse = false,
  ...props
}: IProductBannerProps): JSX.Element {
  const { showActions, handleProductBannerActionClick } = useProductBanner();

  return (
    <Container
      {...props}
      $reverse={reverse}
    >
      <BannerImage
        src={banner}
        alt='product-banner'
      />

      <Content>
        <div>
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
        {showActions && (
          <DefaultButton
            className='banner-action'
            onClick={handleProductBannerActionClick}
          >
            {buttonText}
          </DefaultButton>
        )}
      </Content>
    </Container>
  );
}
