import { useQueryCache } from 'data/cache';
import {
  type IUseGetopenReports,
  ReportsQueryKeys,
  ReportsService
} from 'data/modules/reports';

export function useGetOpenReports(): IUseGetopenReports {
  const { data: openReports, isLoading: isLoadingOpenReports } = useQueryCache({
    queryKey: [ReportsQueryKeys.GET_OPEN_REPORTS],
    queryFn: async () => await ReportsService.getOpenReports()
  });

  return {
    isLoadingOpenReports,
    openReports
  };
}
