import {
  type IAnalysisUserFilters,
  type IPersistenceAnalysisUserFilters
} from 'data/modules/analytics/types/analytics.types';

class GetAnalysisUserFiltersMapper {
  toDomain(persistence: IPersistenceAnalysisUserFilters): IAnalysisUserFilters {
    return {
      users: persistence.users,
      projects: persistence.projects,
      costCenters: persistence.cost_centers,
      expenseTypes: persistence.expense_types,
      paymentForms: persistence.payment_forms
    };
  }
}

export default new GetAnalysisUserFiltersMapper();
