import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import { Spinner } from 'presentation/components/base/Spinner';
import { DsSelect } from 'presentation/ds/DsSelect';
import { DsTextInput } from 'presentation/ds/DsTextInput';
import {
  OnboardingFooter,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { states } from 'shared/constants/global';
import { Onboarding } from 'shared/utils/cards';

import { useCompanyAddress } from './useCompanyAddress';

import {
  Container,
  CustomLoading,
  FormContainer,
  RowDisposedFields
} from './CompanyAddress.styles';

export function CompanyAddress(): JSX.Element {
  const navigate = useNavigate();

  const [forms, onboarding, currentLangKey] = useLangContext(state => [
    state.lang.forms,
    state.lang.cards.onboarding,
    state.currentLangKey
  ]);

  const {
    control,
    errors,
    handleUpdateCompanyAddress,
    setValue,
    isFetchingAddress
  } = useCompanyAddress();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  return (
    <Container>
      <OnboardingTitle>
        {onboarding.company.company_address.title[currentLangKey]}
      </OnboardingTitle>

      {isFetchingAddress && (
        <CustomLoading>
          <Spinner
            color='royalBlue'
            size={4}
          />
        </CustomLoading>
      )}

      <FormContainer onSubmit={handleUpdateCompanyAddress}>
        <DsTextInput
          name='postalCode'
          label={forms.cep[currentLangKey]}
          control={control}
          onClear={() => {
            setValue('postalCode', '');
          }}
          error={errors.postalCode?.message}
          mask='cep'
        />

        <DsTextInput
          name='street'
          mask='letters'
          control={control}
          label={forms.street[currentLangKey]}
          onClear={() => {
            setValue('street', '');
          }}
          error={errors.street?.message}
        />

        <RowDisposedFields>
          <DsTextInput
            name='number'
            label={forms.number[currentLangKey]}
            control={control}
            error={errors.number?.message}
            onClear={() => {
              setValue('number', '');
            }}
            mask='alphanumeric'
          />

          <DsTextInput
            name='complement'
            mask='alphanumeric'
            label={forms.complement[currentLangKey]}
            control={control}
            error={errors.complement?.message}
            onClear={() => {
              setValue('complement', '');
            }}
          />
        </RowDisposedFields>

        <DsTextInput
          name='neighborhood'
          mask='letters'
          label={forms.neighborhood[currentLangKey]}
          control={control}
          error={errors.neighborhood?.message}
          maxLength={30}
          onClear={() => {
            setValue('neighborhood', '');
          }}
        />

        <RowDisposedFields>
          <DsTextInput
            name='city'
            mask='letters'
            label={forms.city[currentLangKey]}
            control={control}
            error={errors.city?.message}
            onClear={() => {
              setValue('city', '');
            }}
          />

          <DsSelect
            name='state'
            label={forms.state[currentLangKey]}
            options={states}
            control={control}
            error={errors.state?.message}
          />
        </RowDisposedFields>

        <DsTextInput
          name='country'
          label={forms.country[currentLangKey]}
          error={errors.country?.message}
          disabled
          value='Brasil'
        />

        <OnboardingFooter
          backButtonProps={{
            onClick: () => {
              navigate(`/cartoes/onboarding/empresa/${currentStep - 1}`);
            }
          }}
          continueButtonProps={{
            onClick: () => {}
          }}
        />
      </FormContainer>
    </Container>
  );
}
