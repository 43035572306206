import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';

import { ActionModal } from 'presentation/components/global/ActionModal';

import { useChangeCardStatusModal } from './useChangeCardStatusModal';

import { ContentContainer } from './ChangeCardStatusModal.styles';

export function ChangeCardStatusModal(): JSX.Element {
  const { handleCloseModal, handleOpenModal } = useModalContext();

  const { t } = useTranslation(['global', 'cards']);

  const { handleChangeCardStatus, isLoading, cardData } =
    useChangeCardStatusModal();

  return (
    <ActionModal
      title={
        cardData?.status === 'ACTIVE'
          ? t('global:blockCard')
          : t('global:unblockCard')
      }
      onClose={() => {
        handleCloseModal();
        handleOpenModal('cardDetails');
      }}
      onCancel={() => {
        handleCloseModal();
        handleOpenModal('cardDetails');
      }}
      onConfirm={handleChangeCardStatus}
      isLoading={isLoading}
      disabledFixedHeight
    >
      <ContentContainer>
        <p>
          {cardData?.status === 'ACTIVE'
            ? t('cards:aboutBlockCard')
            : t('cards:aboutUnblockCard')}
        </p>

        <p>
          {cardData?.status === 'ACTIVE'
            ? t('cards:confirmBlock')
            : t('cards:confirmUnblock')}
        </p>
      </ContentContainer>
    </ActionModal>
  );
}
