import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type ICompleteCompanyOnboardingPayload,
  type IUseCompleteCompanyOnboarding,
  type IUseCompleteCompanyOnboardingParams
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';

export function useCompleteCompanyOnboarding({
  onCompleteCompanyOnboardingMutationSuccess
}: IUseCompleteCompanyOnboardingParams): IUseCompleteCompanyOnboarding {
  const { getEnsuredCache } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const {
    isLoading: isCompletingCompanyOnboarding,
    mutate: completeCompanyOnboardingMutate
  } = useMutationCache({
    mutationKey: [CompanyOnboardingMutationKeys.COMPLETE_COMPANY_ONBOARDING],
    mutationFn: async (payload: ICompleteCompanyOnboardingPayload) =>
      await CompanyOnboardingService.completeCompanyOnboarding(payload),
    onSuccess: () => {
      onCompleteCompanyOnboardingMutationSuccess();
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  async function completeCompanyOnboarding(): Promise<void> {
    const uuidCompanyOnboarding =
      (await getEnsuredCache())?.onboardingCompanyId ?? '';

    completeCompanyOnboardingMutate({
      uuidCompanyOnboarding
    });
  }

  return {
    completeCompanyOnboarding,
    isCompletingCompanyOnboarding
  };
}
