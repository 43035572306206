import { type AxiosError } from 'axios';

import { useMutationCache } from 'data/cache';
import {
  ExpensesMutationKeys,
  ExpensesService,
  type IDeleteExpensesPayload,
  type IUseDeleteExpenses,
  type IUseDeleteExpensesParams
} from 'data/modules/expenses';
import { type IDefaultResponse } from 'data/modules/global';

export function useDeleteExpenses({
  onSuccess,
  onError
}: IUseDeleteExpensesParams): IUseDeleteExpenses {
  const { mutate: deleteExpenses, isLoading: isDeletingExpenses } =
    useMutationCache({
      mutationKey: [ExpensesMutationKeys.DELETE_EXPENSES],
      mutationFn: async (payload: IDeleteExpensesPayload) => {
        await ExpensesService.deleteExpenses(payload);
      },
      onSuccess: (data, variables) => {
        onSuccess?.(variables.expenses.length);
      },
      onError: data => {
        const error = data as AxiosError<IDefaultResponse<null>>;

        onError?.(error.response?.data.errors);
      }
    });

  return {
    deleteExpenses,
    isDeletingExpenses
  };
}
