import { tokens } from 'ds';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { type BlockedCardStylesType } from './FuelPolicy.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 5.1rem);
  flex-direction: column;
  background-color: #f8f8f8;
  padding: ${tokens.spacingLg};
  padding-top: ${tokens.spacingXs};
  padding-bottom: ${tokens.spacingXs};
  gap: ${tokens.spacingXs};

  * svg {
    flex-shrink: 0;
  }
`;

export const PageTitle = styled.h1`
  color: #000;
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 3.2rem;
  letter-spacing: 0.75px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-left: 1rem;
  }
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetXs};

  label {
    max-height: 3.2rem;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${tokens.spacingXs};
  gap: ${tokens.spacingInsetXs};
  border-radius: ${tokens.borderRadiusSm};
  border: 1px solid ${tokens.neutralColorHighMedium};
  background: ${tokens.deepColorWhite};
`;

export const CardTitle = styled.h3`
  color: ${tokens.neutralColorLowDark};
  text-align: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 2.2rem;
  letter-spacing: 0.25px;
`;

export const CardHeader = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetNano};

  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const CardSubTitle = styled.span`
  color: ${tokens.neutralColorLowMediumLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightMedium};
  line-height: 2.2rem;
  letter-spacing: 0.25px;
`;

export const CardOptionContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: ${tokens.borderRadiusSm};
  border: 1px solid ${tokens.neutralColorHighMedium};
  background: ${tokens.deepColorWhite};
  overflow: hidden;

  > div {
    padding: ${tokens.spacingXs};
  }

  p {
    font-style: normal;
    text-align: center;
    line-height: 2.2rem;
    letter-spacing: 0.25px;
    color: ${tokens.brandColorPrimaryMedium};
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxss};
    font-weight: ${tokens.fontWeightSemibold};
  }
`;

export const CardOptionLabel = styled.span`
  color: ${tokens.neutralColorLowMediumLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 2.2rem;
  letter-spacing: 0.25px;
`;

export const CardOptionLabelWithMargin = styled.span`
  color: ${tokens.neutralColorLowMediumLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 2.2rem;
  letter-spacing: 0.25px;
  margin-bottom: ${tokens.spacingInsetNano};
`;

export const ValueByFuelPolicyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingInsetXs};

  div:has(div.text-input-small) {
    width: 15rem;

    > div {
      min-width: unset;
    }
  }

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

export const BlockedCardContainer = styled.div<
  AddPrefixToType<BlockedCardStylesType>
>`
  display: flex;
  align-items: center;
  margin: ${tokens.spacingXs} 0;
  max-height: 3.2rem;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  ${({ $shouldHaveMarginBottom }) =>
    $shouldHaveMarginBottom &&
    css`
      margin-bottom: 0;
    `}

  @media screen and (max-width: 400px) {
    flex-wrap: wrap;
    max-height: unset;
  }
`;

export const BlockedCardExpensesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingInsetNano};

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

export const RequiredFieldsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingInsetNano};

  .checkbox-small span {
    color: ${tokens.neutralColorLowDark};
    font-family: ${tokens.fontFamiliesPoppins};
    font-size: ${tokens.fontSizeXxss};
    font-style: normal;
    font-weight: ${tokens.fontWeightRegular};
    line-height: 2.2rem;
  }

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
