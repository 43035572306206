import { useLangContext, useModalContext } from 'data/contexts';

import { Alert } from 'presentation/components/base/Alert';
import { Modal } from 'presentation/components/base/Modal';
import { SkeletonLoader } from 'presentation/components/global/Loader';
import {
  ExpenseAndReportDetails,
  Header,
  TransactionValue
} from 'presentation/components/global/Modal/TransactionDetails';
import { InternationalFinalValue } from 'presentation/components/global/Modal/TransactionDetails/InternationalFinalValue/InternationalFinalValue.component';

import { Currency } from 'shared/utils/format';

import { useTransactionDetailsModal } from './useTransactionDetailsModal';

import { type ITransactionDetailsModalProps } from './TransactionDetailsModal.types';

import {
  Content,
  ExpenseValue,
  ResponsibleContainer,
  ReverseValue,
  TransactionInfo,
  Values,
  ValuesContainer,
  ValuesDifference
} from './TransactionDetailsModal.styles';

export function TransactionDetailsModal({
  transaction,
  userName
}: ITransactionDetailsModalProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { visibleModal, handleCloseModal } = useModalContext();

  const {
    differenceBetweenTransactionAndExpense,
    expenseExceedsTransactionValue,
    modalExtraButtons,
    showExpenseStatusAlert,
    isLoadingExpense,
    expense
  } = useTransactionDetailsModal({
    transaction
  });

  return (
    <Modal
      isOpen={visibleModal === 'transactionDetails'}
      onClose={handleCloseModal}
      title={transaction !== null && <Header transaction={transaction} />}
      secondaryButton={{
        onClick: handleCloseModal,
        children: lang.global.close[currentLangKey],
        size: 'xl'
      }}
      extraButtons={modalExtraButtons}
    >
      {transaction !== null && (
        <Content>
          <TransactionInfo $isTransactionReversed={transaction.reversed}>
            <p>
              {(transaction.merchantName ?? transaction.paymentDescription) ||
                '-'}
            </p>

            <ValuesContainer>
              <Values $hasExpense={!!expense}>
                <TransactionValue transaction={transaction} />

                {transaction.isInternational && (
                  <InternationalFinalValue transaction={transaction} />
                )}

                {!!transaction.reversalAmount && (
                  <ReverseValue $isTransactionReversed={transaction.reversed}>
                    {`${lang.cards.statement.transaction_details_modal.reversal_amount[currentLangKey]}: `}
                    <span>{`BRL ${Currency.format(
                      'BRL',
                      transaction.reversalAmount
                    )}`}</span>
                  </ReverseValue>
                )}

                {expense && (
                  <ExpenseValue
                    $isNegative={
                      Math.abs(expense.value) >
                      Math.abs(transaction.finalBillingAmount)
                    }
                    $isTransactionReversed={transaction.reversed}
                  >
                    {`${lang.cards.statement.transaction_details_modal.expense_value[currentLangKey]}:`}
                    <span>
                      {`${expense.currencyIso} ${Currency.format(
                        expense.currencyIso,
                        expense.value
                      )}`}
                    </span>
                  </ExpenseValue>
                )}
              </Values>

              {expense && differenceBetweenTransactionAndExpense && (
                <ValuesDifference $isTransactionReversed={transaction.reversed}>
                  {`${expense.currencyIso} ${differenceBetweenTransactionAndExpense}`}
                </ValuesDifference>
              )}

              {showExpenseStatusAlert}
            </ValuesContainer>

            {transaction.reversed && expense && (
              <Alert backgroundColor='tango'>
                {
                  lang.cards.statement.transaction_details_modal
                    .exclude_expense[currentLangKey]
                }
              </Alert>
            )}

            {expenseExceedsTransactionValue}

            {transaction.cardNumber && (
              <p>
                {`${
                  lang.cards.global.end_of_card[currentLangKey]
                }: ${transaction.cardNumber.slice(-4)}`}
              </p>
            )}

            {userName && (
              <p>{`${lang.global.user[currentLangKey]}: ${userName}`}</p>
            )}

            {transaction.transferorName && (
              <ResponsibleContainer>
                <p>
                  {
                    lang.cards.statement.transaction_details_modal
                      .transfer_responsible[currentLangKey]
                  }
                  : <span>{transaction.transferorName}</span>
                </p>
              </ResponsibleContainer>
            )}
          </TransactionInfo>

          {expense && (
            <ExpenseAndReportDetails
              expense={expense}
              isTransactionReversed={transaction.reversed}
            />
          )}

          {isLoadingExpense && (
            <SkeletonLoader
              width='16.5rem'
              height='2rem'
              margin='0.45rem 0'
              rows={4}
            />
          )}
        </Content>
      )}
    </Modal>
  );
}
