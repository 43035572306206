import { useQueryCache } from 'data/cache';
import {
  CostCentersQueryKeys,
  CostCentersService,
  type IUseGetRelatedCostsCenters,
  type IUseGetRelatedCostsCentersParams
} from 'data/modules/costsCenters';

export function useGetRelatedCostsCenters({
  enabled,
  ...payload
}: IUseGetRelatedCostsCentersParams): IUseGetRelatedCostsCenters {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [CostCentersQueryKeys.GET_RELATED_COST_CENTERS, payload],
    queryFn: async () =>
      await CostCentersService.getRelatedCostCenters(payload),
    enabled
  });

  return {
    relatedCostsCenters: data ?? [],
    isLoadingRelatedCostsCenters: isLoading || isFetching
  };
}
