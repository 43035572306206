import { useEffect, useRef, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';
import { useFieldArray } from 'react-hook-form';

import { useMutationCache } from 'data/cache';
import { useAuthContext, useLangContext } from 'data/contexts';
import {
  AccountHoldersMutationKeys,
  AccountHoldersQueryKeys,
  AccountHoldersService,
  type IAccountHolderFieldsForm,
  type IShareholderFieldsForm,
  type IStoreAccountHolder,
  type IStoreAccountHolderPayload,
  type IUpdateAccountHolderPayload,
  type IUseStoreUpdateAccountHolder,
  type IUseStoreUpdateAccountHolderParams
} from 'data/modules/cards/accountHolders';
import { useGetViaCepAddressByPostalCode } from 'data/modules/global';

import { useFormWithSchema } from 'shared/hooks/forms';
import { CustomObject } from 'shared/utils/custom';

import { shareholdersFormInitialValue } from './constants';
import { useAccountHolderSchema, useShareholderSchema } from './schemas';

export function useStoreUpdateAccountHolder(
  params?: IUseStoreUpdateAccountHolderParams
): IUseStoreUpdateAccountHolder {
  const accountHolderFormData = useRef<IAccountHolderFieldsForm>();
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);
  const company = useAuthContext(state => state.company);
  const queryClient = useQueryClient();
  const [previousPostalCode, setPreviousPostalCode] = useState<
    string | undefined
  >(undefined);
  const [shouldRunPostalCodeRequest, setShouldRunPostalCodeRequest] =
    useState(false);

  const {
    handleSubmit: accountHolderFormHandleSubmit,
    control: accountHolderFormControl,
    formState: { errors: accountHolderFormErrors },
    setValue: accountHolderFormSetValue,
    reset: accountHolderFormReset,
    watch: accountHolderFormWatch,
    clearErrors: accountHolderFormClearErrors
  } = useFormWithSchema(useAccountHolderSchema());

  const {
    control: shareholderFormControl,
    setValue: shareholderFormSetValue,
    handleSubmit: shareholderFormHandleSubmit,
    formState: { errors: shareholderFormErrors },
    reset: shareholderFormReset,
    clearErrors: shareholderFormClearErrors
  } = useFormWithSchema(useShareholderSchema(), {
    defaultValues: shareholdersFormInitialValue
  });

  const {
    fields: shareholdersFields,
    append: shareholdersAppend,
    remove: shareholdersRemove
  } = useFieldArray({
    control: shareholderFormControl,
    name: 'shareholders'
  });

  const accountHolderFormPostalCodeWatch = accountHolderFormWatch('postalCode');

  const { address, isFetchingAddress } = useGetViaCepAddressByPostalCode({
    postalCode:
      accountHolderFormPostalCodeWatch?.length === 9
        ? accountHolderFormPostalCodeWatch
        : 'disabled',
    enabled: shouldRunPostalCodeRequest
  });

  useEffect(() => {
    if (!previousPostalCode) {
      setPreviousPostalCode(accountHolderFormPostalCodeWatch);
      return;
    }

    if (
      previousPostalCode &&
      accountHolderFormPostalCodeWatch !== previousPostalCode &&
      accountHolderFormPostalCodeWatch.length === 9
    ) {
      setShouldRunPostalCodeRequest(true);
      setPreviousPostalCode(accountHolderFormPostalCodeWatch);
      return;
    }

    setShouldRunPostalCodeRequest(false);
    setPreviousPostalCode(accountHolderFormPostalCodeWatch);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountHolderFormPostalCodeWatch]);

  useEffect(() => {
    if (address && !isFetchingAddress) {
      accountHolderFormSetValue('neighborhood', address.neighborhood);
      accountHolderFormSetValue('city', address.city);
      accountHolderFormSetValue('state', address.state);
      accountHolderFormSetValue('country', 'BR');
      accountHolderFormSetValue('street', address.street);
      accountHolderFormSetValue('complement', address.complement);
      accountHolderFormClearErrors('neighborhood');
      accountHolderFormClearErrors('city');
      accountHolderFormClearErrors('state');
      accountHolderFormClearErrors('country');
      accountHolderFormClearErrors('street');
      accountHolderFormClearErrors('complement');
      return;
    }

    if (address === null && !isFetchingAddress) {
      accountHolderFormSetValue('neighborhood', '');
      accountHolderFormSetValue('city', '');
      accountHolderFormSetValue('state', '');
      accountHolderFormSetValue('country', '');
      accountHolderFormSetValue('street', '');
      accountHolderFormSetValue('complement', '');
      toast.error(lang.global.postal_code_not_found[currentLangKey]);
    }
  }, [
    address,
    isFetchingAddress,
    accountHolderFormSetValue,
    accountHolderFormClearErrors,
    currentLangKey,
    lang.global.postal_code_not_found
  ]);

  const { mutate: storeAccountHolder, isLoading: isStoringAccountHolder } =
    useMutationCache({
      mutationKey: [AccountHoldersMutationKeys.STORE_ACCOUNT_HOLDERS],
      mutationFn: async (payload: IStoreAccountHolderPayload) =>
        await AccountHoldersService.storeAccountHolder(payload),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [AccountHoldersQueryKeys.GET_ACCOUNT_HOLDERS]
        });
        params?.onAfterSuccessStoreAccountHolderMutation?.();
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  const { mutate: updateAccountHolder, isLoading: isUpdatingAccountHolder } =
    useMutationCache({
      mutationKey: [AccountHoldersMutationKeys.UPDATE_ACCOUNT_HOLDER],
      mutationFn: async (payload: IUpdateAccountHolderPayload) =>
        await AccountHoldersService.updateAccountHolder(payload),
      onSuccess: data => {
        params?.onAfterSuccessUpdateAccountHolderMutation?.(data);
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  function onSubmitAccountHolderForm(data: IAccountHolderFieldsForm): void {
    accountHolderFormData.current = data;
    params?.onAfterAccountHolderFormSubmit?.();
  }

  function onSubmitShareholderForm(data: IShareholderFieldsForm): void {
    if (accountHolderFormData.current === undefined) {
      return;
    }

    const accountHolderToStoreOrUpdate = {
      ...accountHolderFormData.current,
      ...data,
      companyId: company?.uuid as string,
      type: 'BUSINESS',
      subtype: 'BRANCH' as IStoreAccountHolder['subtype']
    };

    if (params?.accountHolderIdToBeUpdated) {
      const updateAccountHolderPayload: IUpdateAccountHolderPayload = {
        accountHolder: {
          ...CustomObject.omit(accountHolderToStoreOrUpdate, [
            'documentNumber'
          ]),
          id: params.accountHolderIdToBeUpdated
        }
      };

      updateAccountHolder(updateAccountHolderPayload);
      return;
    }

    const storeAccountHolderPayload: IStoreAccountHolderPayload = {
      accountHolder: accountHolderToStoreOrUpdate
    };

    storeAccountHolder(storeAccountHolderPayload);
  }

  return {
    accountHolderFormMethods: {
      handleSubmit: accountHolderFormHandleSubmit(onSubmitAccountHolderForm),
      control: accountHolderFormControl,
      errors: accountHolderFormErrors,
      setValue: accountHolderFormSetValue,
      reset: accountHolderFormReset,
      isFetchingAddress
    },
    shareholderFormFieldsAndMethods: {
      control: shareholderFormControl,
      setValue: shareholderFormSetValue,
      handleSubmit: shareholderFormHandleSubmit(onSubmitShareholderForm),
      errors: shareholderFormErrors,
      reset: shareholderFormReset,
      clearErrors: shareholderFormClearErrors,
      shareholdersFields,
      shareholdersAppend,
      shareholdersRemove
    },
    isStoringAccountHolder,
    isUpdatingAccountHolder
  };
}
