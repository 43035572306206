import {
  type IAttachApprovalFlowsAndExpensePoliciesPayload,
  type IPersistenceAttachApprovalFlowsAndExpensePolicies
} from 'data/modules/cards/businessCard';

class AttachApprovalFlowsAndExpensePoliciesMapper {
  toPersistence(
    domain: IAttachApprovalFlowsAndExpensePoliciesPayload
  ): IPersistenceAttachApprovalFlowsAndExpensePolicies {
    if (!domain.expensePolicyId && !domain.routePolicyId) {
      return {
        approval_flow_id: domain.approvalFlowId ?? null
      };
    }

    if (!domain.approvalFlowId && !domain.routePolicyId) {
      return {
        expense_policy_id: domain.expensePolicyId ?? null
      };
    }

    if (!domain.approvalFlowId && !domain.expensePolicyId) {
      return {
        route_policy_id: domain.routePolicyId ?? null
      };
    }

    if (domain.expensePolicyId && domain.routePolicyId) {
      return {
        expense_policy_id: domain.expensePolicyId ?? null,
        route_policy_id: domain.routePolicyId ?? null
      };
    }

    return {
      expense_policy_id: domain.expensePolicyId ?? null,
      approval_flow_id: domain.approvalFlowId ?? null
    };
  }
}

export default new AttachApprovalFlowsAndExpensePoliciesMapper();
