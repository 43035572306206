import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 1.8rem 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gallery};
`;

export const BusinessCardsContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gallery};
  padding: 1.3rem 1.8rem;
`;
