import { Breadcrumbs } from 'ds';
import { useNavigate } from 'react-router';

import { type ITitleAndBreadcrumbsProps } from './TitleAndBreadcrumbs.types';

import {
  CurrentPage,
  GoBackButton,
  PageTitle,
  TitleAndBreadcrumbsContainer,
  TitleWrapper
} from './TitleAndBreadcrumbs.styles';

export function TitleAndBreadcrumbs({
  rightElement,
  pageName = '',
  breadcrumbs = [],
  onBackButtonClick,
  isBackButtonVisible = false,
  isBreadcrumbsVisible = false
}: ITitleAndBreadcrumbsProps): JSX.Element {
  const navigate = useNavigate();

  const lastPageLink = breadcrumbs
    .filter(item => item.pageLink)
    .pop()?.pageLink;

  function handleBackButtonClick(): void {
    if (onBackButtonClick) {
      onBackButtonClick();
    } else {
      navigate(lastPageLink ?? '');
    }
  }

  return (
    <>
      {isBackButtonVisible && <GoBackButton onClick={handleBackButtonClick} />}

      <TitleAndBreadcrumbsContainer>
        <TitleWrapper>
          <PageTitle>{pageName}</PageTitle>
          {rightElement}
        </TitleWrapper>

        {isBreadcrumbsVisible && (
          <Breadcrumbs.Container>
            {breadcrumbs.map(breadcrumb => {
              if (breadcrumb.pageLink) {
                return (
                  <Breadcrumbs.Link
                    key={breadcrumb.name}
                    to={breadcrumb.pageLink}
                    onClick={breadcrumb.onLinkClick}
                    className='charge-card-cards-breadcrumb value-solicitation-cards-breadcrumb'
                  >
                    {breadcrumb.name}
                  </Breadcrumbs.Link>
                );
              }

              return (
                <CurrentPage key={breadcrumb.name}>
                  {breadcrumb.name}
                </CurrentPage>
              );
            })}
          </Breadcrumbs.Container>
        )}
      </TitleAndBreadcrumbsContainer>
    </>
  );
}
