import { BaseIcon } from 'presentation/components/base/Icon';

import { type IContentPlaceholderProps } from './ContentPlaceholder.types';

import { Container } from './ContentPlaceholder.styles';

export function ContentPlaceholder({
  children,
  iconProps,
  title,
  justifyContent = 'center'
}: IContentPlaceholderProps): JSX.Element {
  return (
    <Container $justifyContent={justifyContent}>
      <BaseIcon {...iconProps} />
      <strong>{title}</strong>
      {children}
    </Container>
  );
}
