import { useQueryCache } from 'data/cache';
import {
  type IUseGetTeamMembers,
  type IUseGetTeamMembersParams,
  TeamMembersQueryKeys,
  TeamMembersService
} from 'data/modules/teamMembers';

export function useGetTeamMembers(
  payload: IUseGetTeamMembersParams
): IUseGetTeamMembers {
  const { data, isLoading } = useQueryCache({
    queryKey: [TeamMembersQueryKeys.GET_TEAM_MEMBERS, payload],
    queryFn: async () => await TeamMembersService.getTeamMembers(payload)
  });

  return {
    pagination: data?.pagination,
    isLoadingTeamMembers: isLoading,
    teamMembers: data?.teamMembers ?? []
  };
}
