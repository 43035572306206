import { Numeric } from 'ds/utils';

import { type ILabelUnityValueProps } from './LabelUnityValue.types';

import {
  Container,
  Label,
  UnityContainer,
  UnityLabel,
  UnityValue
} from './LabelUnityValue.styles';

export function LabelUnityValue({
  label,
  unity,
  value
}: ILabelUnityValueProps): JSX.Element {
  return (
    <Container
      data-testid='label-unity-value-container'
      $active={value > 0}
    >
      <Label>{label}</Label>

      <UnityContainer>
        <UnityValue>
          {Numeric.format('BRL', Numeric.roundToTwoDecimals(value))}
        </UnityValue>

        <UnityLabel>{unity}</UnityLabel>
      </UnityContainer>
    </Container>
  );
}
