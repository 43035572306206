import { useValueSolicitationContext } from 'data/contexts';

import { SolicitationActionsList } from 'presentation/pages/cards/Management/ValueSolicitation/components/SolicitationActions';
import { ValueSolicitationList } from 'presentation/pages/cards/Management/ValueSolicitation/components/ValueSolicitationList';

import { useValueSolicitationContainer } from './useValueSolicitationContainer';

export function ValueSolicitationContainer(): JSX.Element {
  const step = useValueSolicitationContext(state => state.step);

  const {
    balanceSolicitations,
    isLoadingBalanceSolicitations,
    balanceSolicitationsApproved,
    onBalanceSolicitationApprovalsSummaryData,
    quantitySolicitationByStatus
  } = useValueSolicitationContainer();

  return (
    <>
      {step === 'solicitation' ? (
        <ValueSolicitationList
          quantitySolicitationByStatus={quantitySolicitationByStatus}
          balanceSolicitations={balanceSolicitations}
          isLoadingBalanceSolicitations={isLoadingBalanceSolicitations}
        />
      ) : (
        <SolicitationActionsList
          balanceSolicitationsApproved={balanceSolicitationsApproved}
          onBalanceSolicitationApprovalsSummaryData={
            onBalanceSolicitationApprovalsSummaryData
          }
        />
      )}
    </>
  );
}
