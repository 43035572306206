import { useQueryCache } from 'data/cache';
import { BalanceQueryKeys, BalanceService } from 'data/modules/cards/balance';

import {
  type IUseGetValueSolicitationsQuantity,
  type IUseGetValueSolicitationsQuantityPayload
} from './useGetValueSolicitationsQuantityByBalanceId.types';

export function useGetValueSolicitationsQuantityByBalanceId(
  payload: IUseGetValueSolicitationsQuantityPayload
): IUseGetValueSolicitationsQuantity {
  const { data, isFetching } = useQueryCache({
    queryKey: [BalanceQueryKeys.GET_VALUE_SOLICITATIONS_QUANTITY, { payload }],
    queryFn: async () =>
      await BalanceService.getQuantityOfBalanceSolicitationByStatus(payload),
    enabled: payload.enabled ?? true
  });

  return {
    quantitySolicitationByStatus: data,
    isFetchingQuantitySolicitationByStatus: isFetching
  };
}
