import { useMemo } from 'react';

import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { yup } from 'data/config';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type ISendOnboardingFeedbackSchemaFields,
  type IUseSendOnboardingFeedback,
  type IUseSendOnboardingFeedbackParams,
  type UpdateCompanyOnboardingAnswersPayloadType
} from 'data/modules/cards/companyOnboarding';

import { useFormWithSchema } from 'shared/hooks/forms';
import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

export function useSendOnboardingFeedback({
  onUpdateCompanyOnboardingAnswersMutationSuccess
}: IUseSendOnboardingFeedbackParams): IUseSendOnboardingFeedback {
  const sendOnboardingFeedbackSchema: yup.ObjectSchema<ISendOnboardingFeedbackSchemaFields> =
    useMemo(
      () =>
        yup.object().shape({
          feedbackNote: yup.number(),
          feedbackComment: yup.string()
        }),
      []
    );

  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const { data: cachedCompanyOnboardingAnswers } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      ],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingAnswers({
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId as string
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    }
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isDirty }
  } = useFormWithSchema(sendOnboardingFeedbackSchema, {
    defaultValues: {
      feedbackComment:
        cachedCompanyOnboardingAnswers?.feedback?.feedbackComment ?? '',
      feedbackNote: cachedCompanyOnboardingAnswers?.feedback?.feedbackNote
    }
  });

  const {
    isLoading: isSendingOnboardingFeedback,
    mutate: updateCompanyOnboardingAnswers
  } = useMutationCache({
    mutationKey: [
      CompanyOnboardingMutationKeys.UPDATE_COMPANY_ONBOARDING_ANSWERS
    ],
    mutationFn: async (payload: UpdateCompanyOnboardingAnswersPayloadType) => {
      await CompanyOnboardingService.updateCompanyOnboardingAnswers(payload);
    },
    onSuccess: (data, variables) => {
      onUpdateCompanyOnboardingAnswersMutationSuccess({
        ...variables.answers
      } as ISendOnboardingFeedbackSchemaFields);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function onSubmit(data: ISendOnboardingFeedbackSchemaFields): void {
    if (isSendingOnboardingFeedback) return;

    updateCompanyOnboardingAnswers({
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '',
      step: currentStep,
      context: 'feedback',
      answers: {
        ...data,
        feedbackComment: data.feedbackComment ?? ''
      }
    });
  }

  return {
    errors,
    control,
    isDirty,
    setValue,
    isSendingOnboardingFeedback,
    handleSendOnboardingFeedback: handleSubmit(onSubmit)
  };
}
