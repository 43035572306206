import { type IUseSupport } from './Support.types';

export function useSupport(): IUseSupport {
  function handleOnHelpCenterButtonCardClick(): void {
    window.open('https://bit.ly/4a1I7ZY', '_blank', 'noreferrer');
  }

  function handleOnUserGuidesButtonCardClick(): void {
    window.open('https://bit.ly/415zdqa', '_blank', 'noreferrer');
  }

  function handleOnImageCardClick(): void {
    window.open('https://bit.ly/3Tjec9m', '_blank', 'noreferrer');
  }

  async function handleCopyServiceChannelToClipboard(
    text: string
  ): Promise<void> {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    }
  }

  function handleOnChatServiceChannelClick(): void {
    const iframe = document.getElementById('launcher') as HTMLIFrameElement;
    const chatButton =
      iframe?.contentWindow?.document?.getElementsByTagName('button')?.[0];

    chatButton?.click();
  }

  return {
    handleCopyServiceChannelToClipboard,
    handleOnHelpCenterButtonCardClick,
    handleOnUserGuidesButtonCardClick,
    handleOnChatServiceChannelClick,
    handleOnImageCardClick
  };
}
