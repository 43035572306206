enum UserOnboardingMutationKeys {
  'UPDATE_USER_ONBOARDING' = 'UPDATE_USER_ONBOARDING',
  'COMPLETE_USER_ONBOARDING' = 'COMPLETE_USER_ONBOARDING'
}

enum UserOnboardingQueryKeys {
  'GET_USER_ONBOARDING_PROGRESS' = 'GET_USER_ONBOARDING_PROGRESS',
  'GET_USER_ONBOARDING_ANSWERS' = 'GET_USER_ONBOARDING_ANSWERS'
}

export { UserOnboardingMutationKeys, UserOnboardingQueryKeys };
