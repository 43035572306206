import { faker } from '@faker-js/faker';

import { type IAllocatedBalance } from 'data/modules/cards/balance';

import { MockApi } from 'shared/utils/global';

export async function getAllocatedBalanceMock(): Promise<IAllocatedBalance> {
  const data = {
    amount: parseFloat(faker.finance.amount()),
    lastUpdateDate: faker.date.anytime().toISOString(),
    lastUpdateTime: faker.date.anytime().toISOString()
  };

  await MockApi.sleep(2000);

  return data;
}
