import { ModalContainer } from './ModalContainer/ModalContainer.component';
import { ModalContent } from './ModalContent/ModalContent.component';
import { ModalFooter } from './ModalFooter/ModalFooter.component';
import { ModalForm } from './ModalForm/ModalForm.component';
import { ModalHeader } from './ModalHeader/ModalHeader.component';
import { ModalIconClose } from './ModalIconClose/ModalIconClose.component';
import { ModalTitle } from './ModalTitle/ModalTitle.component';

export const Modal = {
  Container: ModalContainer,
  Header: ModalHeader,
  IconClose: ModalIconClose,
  Content: ModalContent,
  Footer: ModalFooter,
  Form: ModalForm,
  Title: ModalTitle
};
