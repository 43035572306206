import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type TableContainerHeadStylesType } from './SimpleTable.types';

export const Container = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const TableContainerHead = styled.thead<
  AddPrefixToType<TableContainerHeadStylesType>
>`
  padding-left: 3.6rem;

  ${({ $showHeaderOnMobile }) =>
    !$showHeaderOnMobile &&
    css`
      @media screen and (max-width: ${({ theme }) =>
          theme.breakpoints.tablet}px) {
        display: none;
      }
    `}
`;

export const TableContainer = styled.table`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-spacing: 0;
`;

export const TableHeadRow = styled.tr`
  border: 1px solid ${({ theme }) => theme.colors.alto};

  > th {
    padding: 1.2rem;
    text-align: left;
    border: 0;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.dustyGray};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const TableBodyRow = styled.tr`
  border: 1px solid ${({ theme }) => theme.colors.alto};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: block;
  }
`;

export const TableBodyRowCell = styled.td`
  color: ${({ theme }) => theme.colors.doveGray};
  padding: 1.2rem;
  font-size: 1.3rem;

  > span:first-child {
    display: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gallery};
    display: flex;
    gap: 1rem;

    > span {
      width: 50%;
    }

    > span:first-child {
      display: block;
      font-weight: 700;
    }
  }
`;
