import { useEffect, useState } from 'react';

import { type AnalysisFastDateFilterType } from 'data/modules/analytics';

import { CustomDate } from 'shared/utils/custom';

import {
  type IUseFastDates,
  type IUseFastDatesParams
} from './FastDates.types';

export function useFastDates({
  endDate,
  initialDate,
  onChangeFastDate
}: IUseFastDatesParams): IUseFastDates {
  const [fastDateActiveOption, setFastDateActiveOption] = useState<
    AnalysisFastDateFilterType | undefined
  >('TODAY');

  /** Lógica das opções de filtros rápidos nas datas */
  useEffect(() => {
    const isToday = CustomDate.isEveryDateSameToday(initialDate, endDate);

    const isLastWeek = CustomDate.isLastSevenDays(initialDate, endDate);

    const isLastMonth = CustomDate.isLastThirtyDays(initialDate, endDate);

    const isThisMonth = CustomDate.isThisMonth(initialDate, endDate);

    setFastDateActiveOption(
      isToday
        ? 'TODAY'
        : isLastWeek
          ? 'LAST_WEEK'
          : isLastMonth
            ? 'LAST_MONTH'
            : isThisMonth
              ? 'THIS_MONTH'
              : undefined
    );
  }, [initialDate, endDate]);

  function handleSetFastDates(option: AnalysisFastDateFilterType): () => void {
    return () => {
      setFastDateActiveOption(option);

      const today = CustomDate.formatDateToIso(new Date());

      switch (option) {
        case 'TODAY':
          onChangeFastDate?.('initialDate', today);
          onChangeFastDate?.('endDate', today);

          break;
        case 'LAST_WEEK':
          onChangeFastDate?.(
            'initialDate',
            CustomDate.formatDateToIso(
              CustomDate.getDateSubtractingDays(new Date(), 7)
            )
          );

          onChangeFastDate?.('endDate', today);
          break;
        case 'LAST_MONTH':
          onChangeFastDate?.(
            'initialDate',
            CustomDate.formatDateToIso(
              CustomDate.getDateSubtractingDays(new Date(), 30)
            )
          );

          onChangeFastDate?.('endDate', today);
          break;
        case 'THIS_MONTH':
          onChangeFastDate?.(
            'initialDate',
            CustomDate.getFistDayOfMonth(new Date())
          );

          onChangeFastDate?.('endDate', today);
          break;
      }
    };
  }

  return {
    fastDateActiveOption,
    handleSetFastDates
  };
}
