import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import { Onboarding } from 'shared/utils/cards';

import { type IUseNaturalPersonOrLegalEntity } from './NaturalPersonOrLegalEntity.types';

export function useNaturalPersonOrLegalEntity(): IUseNaturalPersonOrLegalEntity {
  const [error, setError] = useState('');
  const [selectedEntityType, setSelectedEntityType] = useState<string>();

  const navigate = useNavigate();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const [currentLangKey, schemas] = useLangContext(state => [
    state.currentLangKey,
    state.lang.schemas
  ]);

  function handleEntityTypeChange(entityType: string): void {
    if (error.length > 0) setError('');

    setSelectedEntityType(entityType);
  }

  function handleNavigateNextStep(): void {
    if (selectedEntityType) {
      setError('');

      navigate(
        selectedEntityType === 'natural_person'
          ? `/cartoes/onboarding/empresa/${currentStep}/classificacao-pessoa-fisica`
          : `/cartoes/onboarding/empresa/${currentStep}/dados-pessoa-juridica`
      );
    } else {
      setError(schemas.is_required[currentLangKey]);
    }
  }

  return {
    handleNavigateNextStep,
    handleEntityTypeChange,
    error
  };
}
