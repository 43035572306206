import { useState } from 'react';

import { useValueSolicitationContext } from 'data/contexts';
import { type IBalanceSolicitation } from 'data/modules/cards/balance';
import { type PaginationType } from 'data/modules/global';

import { useDebounce } from 'shared/hooks/global';

import {
  type IUseSolicitationActionsTable,
  type IValueSolicitationPaginationParams
} from './SolicitationActionsTable.types';

export function useSolicitationActionsTable(): IUseSolicitationActionsTable {
  let solicitation = [];

  const selectedSolicitationIdsList = useValueSolicitationContext(
    state => state.selectedSolicitationIdsList
  );

  const [
    balanceSolicitationConfirmParams,
    setBalanceSolicitationConfirmParams
  ] = useState<IValueSolicitationPaginationParams>({
    currentPage: 1,
    pageSize: 10,
    searchInput: ''
  });

  const totalOfSolicitationsStaged = selectedSolicitationIdsList?.length ?? 0;

  const debouncedSearchInputValue = useDebounce(
    balanceSolicitationConfirmParams.searchInput
  );

  function sliceIntoChunksBalanceSolicitationStaged(
    arr: IBalanceSolicitation[],
    chunkSize: number
  ): IBalanceSolicitation[][] {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  function sortBalanceSolicitationsBySearch(
    balanceSolicitations: IBalanceSolicitation[],
    searchString: string
  ): IBalanceSolicitation[] {
    const searchLowerCase = searchString.toLowerCase();

    return balanceSolicitations
      .filter(solicitation =>
        solicitation.userName.toLowerCase().includes(searchLowerCase)
      )
      .sort((solicitationA, solicitationB) => {
        const aIncludes = solicitationA.userName
          .toLowerCase()
          .includes(searchLowerCase);
        const bIncludes = solicitationB.userName
          .toLowerCase()
          .includes(searchLowerCase);

        return aIncludes && !bIncludes
          ? -1
          : !aIncludes && bIncludes
            ? 1
            : solicitationA.userName.localeCompare(solicitationB.userName);
      });
  }

  const sort = sortBalanceSolicitationsBySearch(
    selectedSolicitationIdsList ?? [],
    debouncedSearchInputValue
  );

  const pageSize = Number(balanceSolicitationConfirmParams.pageSize);

  solicitation =
    balanceSolicitationConfirmParams.searchInput !== ''
      ? sliceIntoChunksBalanceSolicitationStaged(sort, pageSize)
      : sliceIntoChunksBalanceSolicitationStaged(
          selectedSolicitationIdsList ?? [],
          pageSize
        );

  function handleChangeSearchParam<
    T extends keyof IValueSolicitationPaginationParams
  >(type: T, value: IValueSolicitationPaginationParams[T]): void {
    switch (type) {
      case 'currentPage':
        setBalanceSolicitationConfirmParams(oldState => ({
          ...oldState,
          currentPage: value as number
        }));
        return;
      case 'pageSize':
        setBalanceSolicitationConfirmParams(oldState => ({
          ...oldState,
          pageSize: value as PaginationType,
          currentPage: 1
        }));
        return;
      case 'searchInput':
        setBalanceSolicitationConfirmParams(oldState => ({
          ...oldState,
          currentPage: 1,
          searchInput: value as string
        }));
    }
  }

  function sortArrayByDate(
    array: IBalanceSolicitation[],
    order: 'asc' | 'desc' | 'default'
  ): IBalanceSolicitation[] {
    if (order === 'default') {
      return array;
    }

    const sortedArray = [...array];

    sortedArray.sort(function (a, b) {
      const dateA = new Date(a.dateSent);
      const dateB = new Date(b.dateSent);

      return order === 'asc'
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    });

    return sortedArray;
  }

  return {
    handleChangeSearchParam,
    balanceSolicitationConfirmParams,
    totalOfSolicitationsStaged,
    solicitation,
    sortArrayByDate
  };
}
