import { tokens } from 'ds';
import styled from 'styled-components';

import { type ISwitchMode } from './SearchAndFilters.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.6rem;
  width: 100%;
`;

export const SearchAndFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: fit-content;
  }
`;

export const ControllersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetXs};

  width: 100%;
  white-space: nowrap;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
    flex: 0;

    gap: 1.6rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 65.903rem;

    button {
      width: 100%;
    }
  }
`;

export const TableDisplayModeSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`;

export const DisplayModeLabel = styled.label`
  display: flex;
  position: relative;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  line-height: 2rem;
  letter-spacing: 0.75px;
  text-align: left;
  color: ${tokens.neutralColorLowMedium};
`;

export const DisplayModeLabelTourReference = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
`;

export const DisplayModeSwitchSelectorContainer = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: ${tokens.neutralColorHighDark};
`;

export const ListModeSwitch = styled.div<AddPrefixToType<ISwitchMode>>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 0.8rem;
  background-color: ${({ $tableDisplayMode }) =>
    $tableDisplayMode === 'LIST'
      ? tokens.brandColorPrimaryPure
      : tokens.neutralColorHighDark};
  cursor: pointer;

  path {
    stroke: ${({ $tableDisplayMode }) =>
      $tableDisplayMode === 'LIST'
        ? tokens.neutralColorHighPure
        : tokens.neutralColorLowMedium};
  }
`;

export const CardsModeSwitch = styled.div<AddPrefixToType<ISwitchMode>>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 0.8rem;
  background-color: ${({ $tableDisplayMode }) =>
    $tableDisplayMode === 'CARDS'
      ? tokens.brandColorPrimaryPure
      : tokens.neutralColorHighDark};
  cursor: pointer;

  path {
    stroke: ${({ $tableDisplayMode }) =>
      $tableDisplayMode === 'CARDS'
        ? tokens.neutralColorHighPure
        : tokens.neutralColorLowMedium};
  }
`;
