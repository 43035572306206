import { useCallback, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { type ILegalNatureOption } from 'data/modules/auth';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IPullSerproInformationsPayload,
  type ISerproInformations,
  type IUpdateCompanyDataFieldsForm,
  type IUploadCompanyDocumentPayload,
  type IUseUpdateCompanyData,
  type IUseUpdateCompanyDataParams,
  type UpdateCompanyOnboardingAnswersPayloadType,
  useGetSerproInformations
} from 'data/modules/cards/companyOnboarding';
import { type ICnae } from 'data/modules/global';

import { cnae } from 'shared/constants/global';
import { legalNature } from 'shared/constants/global/legalNature.constant';
import { useFormWithSchema } from 'shared/hooks/forms';
import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';
import { CustomObject } from 'shared/utils/custom';
import { Validate } from 'shared/utils/validation';

import { useUpdateCompanyDataSchema } from './schemas';

export function useUpdateCompanyData({
  onUpdateCompanyOnboardingAnswersMutationSuccess
}: IUseUpdateCompanyDataParams): IUseUpdateCompanyData {
  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const { data: cachedCompanyOnboardingAnswers } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      ],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingAnswers({
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId as string
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    }
  });

  const getCachedMainActivity = useCallback(
    (serproInformations?: ISerproInformations): ICnae | undefined => {
      return cachedCompanyOnboardingAnswers?.companyAccount?.mainActivity
        ? cnae.find(
            item =>
              item.value ===
              cachedCompanyOnboardingAnswers?.companyAccount?.mainActivity
          )
        : cnae.find(
            item => item.value === serproInformations?.mainActivity?.code
          );
    },
    [cachedCompanyOnboardingAnswers?.companyAccount?.mainActivity]
  );

  const getCachedLegalNature = useCallback(
    (
      serproInformations?: ISerproInformations
    ): ILegalNatureOption | undefined => {
      return cachedCompanyOnboardingAnswers?.companyAccount?.businessType
        ? legalNature.find(
            item =>
              item.value ===
              cachedCompanyOnboardingAnswers?.companyAccount?.businessType
          )
        : legalNature.find(
            item => item.value === serproInformations?.legalNature?.code
          );
    },
    [cachedCompanyOnboardingAnswers?.companyAccount?.businessType]
  );

  const {
    serproInformations,
    isLoadingSerproInformations,
    isGetSerproInformationsSuccess,
    isErrorSerproInformations,
    errorSerproInformations
  } = useGetSerproInformations({
    enabled: Boolean(cachedCompanyOnboardingProgress?.onboardingCompanyId)
  });

  useEffect(() => {
    if (isErrorSerproInformations) {
      toast.error(errorSerproInformations?.message as string);
    }
  }, [errorSerproInformations?.message, isErrorSerproInformations]);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isDirty }
  } = useFormWithSchema(useUpdateCompanyDataSchema(), {
    defaultValues: {
      cnpj: cachedCompanyOnboardingAnswers?.companyAccount?.documentNumber,
      legalName: cachedCompanyOnboardingAnswers?.companyAccount?.legalName,
      mainActivity: getCachedMainActivity(serproInformations),
      legalNature: getCachedLegalNature(serproInformations),
      foundingDate:
        cachedCompanyOnboardingAnswers?.companyAccount?.foundingDate,
      email: cachedCompanyOnboardingAnswers?.companyAccount?.email,
      phoneNumber: cachedCompanyOnboardingAnswers?.companyAccount?.phoneNumber,
      contract: cachedCompanyOnboardingAnswers?.companyAccount?.contract
    }
  });

  useEffect(() => {
    if (isGetSerproInformationsSuccess && serproInformations) {
      const cachedAnswers = cachedCompanyOnboardingAnswers?.companyAccount;
      const cachedMainActivity = getCachedMainActivity(serproInformations);
      const cachedLegalNature = getCachedLegalNature(serproInformations);

      !cachedAnswers?.documentNumber &&
        serproInformations.cnpj &&
        setValue('cnpj', serproInformations.cnpj);

      !cachedAnswers?.legalName &&
        serproInformations.corporateName &&
        setValue('legalName', serproInformations.corporateName);

      !cachedAnswers?.mainActivity &&
        cachedMainActivity &&
        setValue('mainActivity', cachedMainActivity);

      !cachedAnswers?.businessType &&
        cachedLegalNature &&
        setValue('legalNature', cachedLegalNature);

      !cachedAnswers?.foundingDate &&
        serproInformations.foundationDate &&
        setValue('foundingDate', serproInformations.foundationDate);

      !cachedAnswers?.email &&
        serproInformations.email &&
        setValue('email', serproInformations.email);

      !cachedAnswers?.phoneNumber &&
        serproInformations.phone &&
        setValue('phoneNumber', serproInformations.phone);
    }
  }, [
    cachedCompanyOnboardingAnswers?.companyAccount,
    getCachedLegalNature,
    getCachedMainActivity,
    isGetSerproInformationsSuccess,
    serproInformations,
    setValue
  ]);

  const queryClient = useQueryClient();

  const {
    mutate: pullSerproInformations,
    isLoading: isPullingSerproInformations
  } = useMutationCache({
    mutationKey: [CompanyOnboardingMutationKeys.PULL_SERPRO_INFORMATIONS],
    mutationFn: async (payload: IPullSerproInformationsPayload) =>
      await CompanyOnboardingService.pullSerproInformations(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          CompanyOnboardingQueryKeys.GET_SERPRO_INFORMATIONS,
          cachedCompanyOnboardingProgress?.onboardingCompanyId
        ]
      });
    }
  });

  const userEnteredCnpj = watch('cnpj');

  useEffect(() => {
    const isValidCnpj = Validate.cnpj(userEnteredCnpj);

    if (
      isValidCnpj &&
      userEnteredCnpj !==
        cachedCompanyOnboardingAnswers?.companyAccount?.documentNumber
    ) {
      pullSerproInformations({
        uuidCompanyOnboarding:
          cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '',
        cnpj: userEnteredCnpj
      });
    }
  }, [
    cachedCompanyOnboardingProgress,
    pullSerproInformations,
    userEnteredCnpj,
    cachedCompanyOnboardingAnswers,
    clearErrors
  ]);

  const { isLoading: isUpdatingCompanyData, mutate: updateCompanyData } =
    useMutationCache({
      mutationKey: [
        CompanyOnboardingMutationKeys.UPDATE_COMPANY_ONBOARDING_ANSWERS
      ],
      mutationFn: async (
        payload: UpdateCompanyOnboardingAnswersPayloadType
      ) => {
        await CompanyOnboardingService.updateCompanyOnboardingAnswers(payload);
      },
      onSuccess: (data, variables) => {
        onUpdateCompanyOnboardingAnswersMutationSuccess({
          ...variables.answers
        } as IUpdateCompanyDataFieldsForm);
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  const {
    isLoading: isUploadingCompanyDocument,
    mutate: uploadCompanyDocument
  } = useMutationCache({
    mutationKey: [CompanyOnboardingMutationKeys.UPLOAD_COMPANY_DOCUMENT],
    mutationFn: async (payload: IUploadCompanyDocumentPayload) =>
      await CompanyOnboardingService.uploadCompanyDocument(payload),
    onSuccess: data => {
      setValue('contract', data.contract);

      handleSubmit(onSubmitCompanyData)();
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  async function onSubmitCompanyData(
    userEnteredData: IUpdateCompanyDataFieldsForm
  ): Promise<void> {
    updateCompanyData({
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '',
      step: currentStep,
      context: 'company_account',
      answers: {
        ...CustomObject.omit(userEnteredData, ['cnpj']),
        documentNumber: userEnteredData.cnpj,
        mainActivity: userEnteredData.mainActivity as string,
        businessType: userEnteredData.legalNature as string,
        contract: userEnteredData.contract as string
      }
    });
  }

  async function onSubmit(
    userEnteredData: IUpdateCompanyDataFieldsForm
  ): Promise<void> {
    if (isUpdatingCompanyData || isUploadingCompanyDocument) {
      return;
    }

    if (
      !isDirty &&
      currentStep < Number(cachedCompanyOnboardingProgress?.currentStep)
    ) {
      onUpdateCompanyOnboardingAnswersMutationSuccess(userEnteredData);

      return;
    }

    if (userEnteredData.contract instanceof FileList) {
      uploadCompanyDocument({
        uuidCompanyOnboarding:
          cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '',
        document: userEnteredData.contract[0]
      });

      return;
    }

    onSubmitCompanyData(userEnteredData);
  }

  return {
    handleUpdateCompanyData: handleSubmit(onSubmit),
    isUpdatingCompanyData: isUploadingCompanyDocument,
    isPullingSerproInformations:
      isPullingSerproInformations || isLoadingSerproInformations,
    control,
    errors,
    isDirty,
    setValue,
    watch
  };
}
