import {
  type IBalancesAndAccount,
  type IPersistenceBalancesAndAccount
} from 'data/modules/cards/balance/types/balance.types';

class GetBalanceAndAccountMapper {
  toDomain(data: IPersistenceBalancesAndAccount): IBalancesAndAccount {
    return {
      account: {
        id: data.account.id,
        description: data.account.description,
        status: data.account.status
      },
      balances: data.balances.map(balance => ({
        id: balance.id,
        balances: balance.balances,
        description: balance.description,
        inUse: balance.in_use
      }))
    };
  }
}

export default new GetBalanceAndAccountMapper();
