import {
  type IChargedUser,
  type IPersistenceChargedUser
} from 'data/modules/financial';

class GetChargedUsersMapper {
  toDomain(persistence: IPersistenceChargedUser): IChargedUser {
    return {
      id: persistence.id,
      uuid: persistence.uuid,
      endDate: persistence.end_date,
      startDate: persistence.start_date
    };
  }
}

export default new GetChargedUsersMapper();
