import {
  DefaultButton,
  Modal,
  SecondaryButton,
  TextArea,
  TextButton
} from 'ds';
import { useTranslation } from 'react-i18next';

import { Loader } from '../Loader';

import { useNpsModal } from './useNpsModal';

import { type INpsModalProps } from './NpsModal.types';

import {
  Container,
  Content,
  Footer,
  Header,
  NpsItemVote,
  NpsItemVoteContainer,
  SubTitle
} from './NpsModal.styles';

export function NpsModal({
  onConfirm,
  onClose,
  title,
  npsSurveyId
}: INpsModalProps): JSX.Element {
  const { t } = useTranslation('expenses');
  const {
    npsValue,
    obsValue,
    setNpsValue,
    setObsValue,
    isCreatingNpsSurveyResponse,
    sendNpsSurveyResponse
  } = useNpsModal({ npsSurveyId });

  return (
    <>
      <Loader isLoading={isCreatingNpsSurveyResponse} />

      <Modal.Container
        variant='small'
        onClose={() => {
          onClose?.();
          sendNpsSurveyResponse({
            dontWantToAnswer: true
          });
        }}
      >
        <Container>
          <Header>
            <Modal.IconClose
              onClick={() => {
                onClose?.();
                sendNpsSurveyResponse({
                  dontWantToAnswer: true
                });
              }}
            />
            <Modal.Title>{title ?? t('nps.title')}</Modal.Title>
          </Header>

          <Content>
            <NpsItemVoteContainer>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => (
                <NpsItemVote
                  $active={npsValue === item}
                  onClick={() => {
                    setNpsValue(item);
                  }}
                  key={item}
                >
                  {item}
                </NpsItemVote>
              ))}
            </NpsItemVoteContainer>

            {npsValue !== null && (
              <>
                <SubTitle>
                  {npsValue <= 6 && t('nps.npsReasonsBad')}
                  {npsValue >= 7 && npsValue < 9 && t('nps.npsReasonsBetter')}
                  {npsValue >= 9 && npsValue <= 10 && t('nps.npsReasonsGood')}
                </SubTitle>

                <TextArea.Default
                  small
                  value={obsValue}
                  onChangeValue={setObsValue}
                />
              </>
            )}
          </Content>

          <Modal.Footer>
            <Footer>
              <DefaultButton
                disabled={npsValue === null}
                size='small'
                onClick={() => {
                  onConfirm?.();
                  sendNpsSurveyResponse({});
                }}
              >
                {t('nps.send')}
              </DefaultButton>

              <SecondaryButton
                size='small'
                onClick={() => {
                  onClose?.();
                  sendNpsSurveyResponse({
                    answerLater: true
                  });
                }}
              >
                {t('nps.answerLater')}
              </SecondaryButton>

              <TextButton
                size='small'
                onClick={() => {
                  onClose?.();
                  sendNpsSurveyResponse({
                    dontWantToAnswer: true
                  });
                }}
              >
                {t('nps.dontWantToAnswer')}
              </TextButton>
            </Footer>
          </Modal.Footer>
        </Container>
      </Modal.Container>
    </>
  );
}
