import { useGetUserExpensesPendingAccountability } from '../';

import {
  type ExpensesCardsType,
  type IExpensesPendingAccountability,
  type IUseGetUserExpensesInformation,
  type IUseGetUserExpensesInformationParams
} from '../../types/expenses.types';

export function useGetUserExpensesInformation(
  params: IUseGetUserExpensesInformationParams
): IUseGetUserExpensesInformation {
  const {
    userExpensesPendingAccountability,
    isFetchingUserExpensesPendingAccountability
  } = useGetUserExpensesPendingAccountability({
    userUuid: params.userUuid,
    status: params.status
  });

  const aggregatedUserExpensesDefault: ExpensesCardsType[] = [
    { status: 'AVULSA', value: 0, total: 0 },
    { status: 'ABERTO', value: 0, total: 0 },
    { status: 'ENVIADO', value: 0, total: 0 },
    { status: 'REPROVADO', value: 0, total: 0 }
  ];

  const userExpensesDetachedList = userExpensesPendingAccountability
    ? userExpensesPendingAccountability.reduce<ExpensesCardsType[]>(
        (aggregatedUserExpenses, { status, convertedValue, value }) => {
          const key: IExpensesPendingAccountability['status'] = status;
          const existingItem = aggregatedUserExpenses.find(
            item => item.status === key
          );

          if (existingItem) {
            existingItem.total++;
            existingItem.value +=
              convertedValue !== null ? convertedValue : value;
          }

          return aggregatedUserExpenses;
        },
        aggregatedUserExpensesDefault
      )
    : aggregatedUserExpensesDefault;

  const userExpensesDetached = userExpensesPendingAccountability
    ? userExpensesPendingAccountability.reduce<
        Record<string, ExpensesCardsType>
      >((aggregatedUserExpenses, { status, convertedValue, value }) => {
        const key: IExpensesPendingAccountability['status'] = status;

        if (!aggregatedUserExpenses[key]) {
          aggregatedUserExpenses[key] = { status: key, total: 0, value: 0 };
        }

        aggregatedUserExpenses[key].total++;
        aggregatedUserExpenses[key].value +=
          convertedValue !== null ? convertedValue : value;

        return aggregatedUserExpenses;
      }, {})
    : {};

  return {
    isLoadingUserExpensesInformation:
      isFetchingUserExpensesPendingAccountability,
    userExpensesDetachedList,
    userExpensesDetached
  };
}
