import { useLangContext, useModalContext } from 'data/contexts';

import { Spinner } from 'presentation/components/base/Spinner';
import { DsSelect } from 'presentation/ds/DsSelect';
import { DsTextInput } from 'presentation/ds/DsTextInput';
import {
  ContinueWithoutAskingModal,
  RequestCardsConfirmModal
} from 'presentation/pages/cards/Onboarding/components/Company/RequestCards';
import {
  OnboardingFooter,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { states } from 'shared/constants/global';

import { useRequestCardsForm } from './useRequestCardsForm';

import {
  Container,
  CustomLoading,
  FormContainer,
  RowDisposedFields
} from './RequestCardsForm.styles';

export function RequestCardsForm(): JSX.Element {
  const [forms, requestCards, currentLangKey] = useLangContext(state => [
    state.lang.forms,
    state.lang.cards.onboarding.company.request_cards,
    state.currentLangKey
  ]);

  const { handleCloseModal, visibleModal, handleOpenModal } = useModalContext();

  const {
    control,
    errors,
    handleUpdateRequestCards,
    handleCancelRequestCards,
    setValue,
    isFetchingAddress,
    handleClickContinueButton,
    watch
  } = useRequestCardsForm();

  return (
    <Container>
      <OnboardingTitle>{requestCards.title[currentLangKey]}</OnboardingTitle>

      {isFetchingAddress && (
        <CustomLoading>
          <Spinner
            color='royalBlue'
            size={4}
          />
        </CustomLoading>
      )}

      <FormContainer
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <RowDisposedFields>
          <DsTextInput
            name='receiver'
            control={control}
            label={forms.receiver[currentLangKey]}
            error={errors.receiver?.message}
            mask='letters'
            onClear={() => {
              setValue('receiver', '');
            }}
          />

          <DsTextInput
            name='postalCode'
            label={forms.cep[currentLangKey]}
            control={control}
            onClear={() => {
              setValue('postalCode', '');
            }}
            error={errors.postalCode?.message}
            mask='cep'
          />
        </RowDisposedFields>

        <DsTextInput
          name='street'
          mask='letters'
          control={control}
          label={forms.street[currentLangKey]}
          onClear={() => {
            setValue('street', '');
          }}
          error={errors.street?.message}
        />
        <RowDisposedFields>
          <DsTextInput
            name='number'
            label={forms.number[currentLangKey]}
            control={control}
            error={errors.number?.message}
            onClear={() => {
              setValue('number', '');
            }}
          />

          <DsTextInput
            name='city'
            mask='letters'
            control={control}
            error={errors.city?.message}
            label={forms.city[currentLangKey]}
            onClear={() => {
              setValue('city', '');
            }}
          />
        </RowDisposedFields>

        <DsTextInput
          name='neighborhood'
          maxLength={30}
          mask='letters'
          label={forms.neighborhood[currentLangKey]}
          control={control}
          error={errors.neighborhood?.message}
          onClear={() => {
            setValue('neighborhood', '');
          }}
        />

        <RowDisposedFields>
          <DsTextInput
            name='country'
            label={forms.country[currentLangKey]}
            disabled
            value='Brasil'
          />

          <DsSelect
            name='state'
            label={forms.state[currentLangKey]}
            options={states}
            control={control}
            error={errors.state?.message}
          />
        </RowDisposedFields>

        <DsTextInput
          name='complement'
          mask='alphanumeric'
          label={forms.complement[currentLangKey]}
          control={control}
          error={errors.complement?.message}
          onClear={() => {
            setValue('complement', '');
          }}
        />

        <RowDisposedFields>
          <DsTextInput
            mask='phone'
            name='phoneNumber'
            control={control}
            error={errors.phoneNumber?.message}
            label={forms.phone[currentLangKey]}
            onClear={() => {
              setValue('phoneNumber', '');
            }}
          />

          <DsTextInput
            mask='numeric'
            name='quantity'
            control={control}
            error={errors.quantity?.message}
            maxLength={5}
            label={requestCards.quantity[currentLangKey]}
            onClear={() => {
              setValue('quantity', '');
            }}
          />
        </RowDisposedFields>
        <OnboardingFooter
          backButtonProps={{
            onClick: () => {
              handleOpenModal('onboardingContinueWithoutAskingCards');
            },
            text: requestCards.continue_without_ask[currentLangKey]
          }}
          continueButtonProps={{
            onClick: handleClickContinueButton
          }}
        />

        <RequestCardsConfirmModal
          isOpen={visibleModal === 'confirmation'}
          onClose={handleCloseModal}
          onConfirm={() => {
            handleUpdateRequestCards();
            handleCloseModal();
          }}
          amountOfCards={watch('quantity') as string}
          isLoading={false}
        />

        <ContinueWithoutAskingModal
          isOpen={visibleModal === 'onboardingContinueWithoutAskingCards'}
          onClose={handleCloseModal}
          onConfirm={() => {
            handleCancelRequestCards();
            handleCloseModal();
          }}
        />
      </FormContainer>
    </Container>
  );
}
