import {
  type IPendency,
  type IPersistencePendency
} from 'data/modules/cards/pendencies';

import { CustomObject } from 'shared/utils/custom';

class GetPendenciesListByAccountBalanceIdMapper {
  toDomain(persistencePendency: IPersistencePendency): IPendency {
    return {
      createdAt: persistencePendency.created_at,
      transactionId: persistencePendency.transaction_id,
      ...CustomObject.pick(persistencePendency, ['amount', 'id', 'type'])
    };
  }
}

export default new GetPendenciesListByAccountBalanceIdMapper();
