import { Modal, tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { SideModal } from 'presentation/components/global/Modal';

import { type SelectionButtonStyleType } from './SideAnalysisFilterModal.types';

export const Container = styled(SideModal)``;

export const Content = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-content: flex-start;
  row-gap: ${tokens.spacingLg};
`;

export const Subtitle = styled.p`
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};

  a {
    color: ${tokens.brandColorSecondaryPure};
  }
`;

export const FormContent = styled(Modal.Form)`
  display: flex;
  overflow: hidden;
  overflow-y: auto;
  row-gap: ${tokens.spacingLg};
  padding-top: ${tokens.spacingNano};
  padding-right: ${tokens.spacingNano};

  > div:has(> input) {
    justify-content: flex-start;
  }

  .react-multiSelect-custom__control {
    min-width: unset;
    max-width: unset;
    min-height: unset;

    > div:first-of-type {
      max-width: unset;
      width: calc(100% - 8.1rem);
      flex: 1 0 0;
    }

    &:has(.react-multiSelect-custom__value-container--has-value) {
      padding-top: 0 !important;
    }
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${tokens.spacingXs};
`;

export const SelectionButton = styled.button<
  AddPrefixToType<SelectionButtonStyleType>
>`
  flex: 1 0 0;
  white-space: nowrap;
  align-items: center;
  line-height: 2.2rem;
  display: inline-flex;
  justify-content: center;
  letter-spacing: 0.025rem;
  gap: ${tokens.spacingNano};
  font-size: ${tokens.fontSizeXxss};
  transition: all 0.25s ease-in-out;
  color: ${tokens.neutralColorLowMedium};
  border-radius: ${tokens.borderRadiusXs};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
  background: ${tokens.neutralColorHighPure};
  border: 1px solid ${tokens.neutralColorLowLightest};
  padding: ${tokens.spacingNano} ${tokens.spacingXxs};

  &:hover {
    box-shadow:
      2px 2px 8px 0 rgb(0 0 0 / 5%),
      3px 3px 4px 0 rgb(0 0 0 / 10%);
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${tokens.brandColorSecondaryPure};
      background: ${tokens.brandColorPrimaryLightest};
      border-color: ${tokens.brandColorSecondaryLight};
    `}
`;

export const DatePickerRow = styled.div`
  display: flex;

  gap: ${tokens.spacingXs};

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;

    > * {
      width: 100%;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-top: -${tokens.spacingQuarck};
  }
`;

export const SelectPaymentFormsWrapper = styled.div`
  .react-multiSelect-custom__menu-list {
    max-height: 15rem;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${tokens.spacingXs};

  > div {
    display: flex;
  }

  button,
  div {
    flex: 1 0 0;
    white-space: nowrap;
  }
`;
