import { useState } from 'react';

import {
  type IUseViewImagePdf,
  type IUseViewImagePdfParams
} from './ViewImagePdf.types';

export function useViewImagePdf({
  url
}: IUseViewImagePdfParams): IUseViewImagePdf {
  const [isOpenedImage, setIsOpenedImage] = useState(false);

  const isFileImage =
    (url?.includes('.png') ||
      url?.includes('.jpeg') ||
      url?.includes('.jpg')) ??
    false;

  return {
    isOpenedImage,
    setIsOpenedImage,
    isFileImage
  };
}
