import { useEffect, useState } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { useCardDetailsContext, useModalContext } from 'data/contexts';
import { useGetCardDataByBalanceId } from 'data/modules/cards/balance';

import {
  type IUseValueSolicitationTableRow,
  type IUseValueSolicitationTableRowProps
} from './ValueSolicitationTableRow.types';

export function useValueSolicitationTableRow({
  solicitation
}: IUseValueSolicitationTableRowProps): IUseValueSolicitationTableRow {
  const [loadDataFromClick, setLoadDataFromClick] = useState(false);
  const { handleOpenModal } = useModalContext();

  const [handleSetSelectedAccount, selectedAccount] = useCardDetailsContext(
    useShallow(state => [state.handleSetSelectedAccount, state.selectedAccount])
  );

  const { cardData, isLoadingCardData } = useGetCardDataByBalanceId({
    cardInfo: {
      balanceId: solicitation.balanceId,
      name: solicitation.userName,
      userId: solicitation.userId
    },
    enable: loadDataFromClick
  });

  useEffect(() => {
    if (
      cardData &&
      !isLoadingCardData &&
      selectedAccount === null &&
      loadDataFromClick
    ) {
      handleSetSelectedAccount({ ...cardData });

      handleOpenModal('cardDetails');

      setLoadDataFromClick(false);
    }
  }, [
    cardData,
    handleSetSelectedAccount,
    isLoadingCardData,
    selectedAccount,
    handleOpenModal,
    loadDataFromClick
  ]);

  return {
    handleLoadDataFromClick: setLoadDataFromClick,
    isLoadingCardData
  };
}
