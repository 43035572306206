import { Modal, tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled(Modal.Form)`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};

  .textarea-request-value {
    padding: 0 ${tokens.spacingXs};
  }

  .date-picker-request-value {
    .react-datepicker-popper {
      z-index: 1050 !important;
      position: fixed !important;
    }

    .react-datepicker {
      z-index: 1050 !important;
    }
  }
`;

export const DescriptionContent = styled.p`
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightRegular};
  line-height: ${tokens.lineHeightLg};
  color: ${tokens.neutralColorLowDark};
  letter-spacing: 0.075rem;
`;

export const ForecastAndAlertContainer = styled.div`
  display: flex;

  gap: ${tokens.spacingXs};
  height: 4.1rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;
    height: auto;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    height: auto;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${tokens.spacingInsetXs};
  margin-bottom: 1rem;

  > button {
    width: 100%;
    max-width: 16rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;

    > button {
      max-width: 100%;
      height: fit-content;
    }
  }
`;
export const CurrencyInputContainer = styled.div`
  min-width: 16rem;

  > div {
    max-width: 100% !important;
    height: 4rem;

    position: relative;

    > p {
      position: absolute;
      margin-top: 4rem;
    }
  }
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
  gap: ${tokens.spacingInsetXs};
`;
