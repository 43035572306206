import {
  DefaultTabs,
  DSIcons,
  MultiSelect,
  Select,
  TextButton,
  TextInput
} from 'ds';
import { DatePicker } from 'ds/components/Input/DatePicker';
import { AnimatePresence } from 'framer-motion';

import { useLangContext } from 'data/contexts';

import { animations } from 'shared/constants/animations';

import { useTableFilters } from './useTableFilters';

import { type ITableFiltersProps } from './TableFilters.types';

import {
  FiltersContainer,
  FiltersFirstLine,
  FiltersSecondLine,
  TabContainer,
  TextButtonLabel
} from './TableFilters.styles';

export function TableFilters({
  onChangeFilters,
  filters,
  numberOfExpensesByStatus
}: ITableFiltersProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  const {
    expensesLabelsWithQuantity,
    typesOfExpensesOptions,
    moreFiltersActive,
    setMoreFiltersActive,
    paymentMethodsOptions,
    refundableOptions
  } = useTableFilters({ numberOfExpensesByStatus });

  return (
    <>
      <TabContainer>
        <DefaultTabs.Container
          className='table-filters-tabs-container'
          hasTrack
        >
          {expensesLabelsWithQuantity.map((item, index) => {
            return (
              <DefaultTabs.Item
                key={`expense-label-${index}`}
                size='small'
                badge={item.quantity}
                active={filters.statusTab === item.status}
                onClick={() => {
                  onChangeFilters('statusTab', item.status);
                }}
              >
                {item.label}
              </DefaultTabs.Item>
            );
          })}
        </DefaultTabs.Container>
      </TabContainer>
      <FiltersContainer>
        <FiltersFirstLine>
          <TextInput.Default
            small
            label={lang.expenses.table.filters.search_expense[currentLangKey]}
            onChangeValue={value => {
              onChangeFilters('search', value);
            }}
            value={filters.search}
            iconLeft='SearchBigIcon'
            placeholder={
              lang.expenses.table.filters.search_expense[currentLangKey]
            }
          />

          <DatePicker.Default
            variant='small'
            containerClassName='datepicker-container'
            label={lang.expenses.table.filters.initial_date[currentLangKey]}
            currentLangKey={currentLangKey}
            onChangeValue={value => {
              onChangeFilters('startDate', value);
            }}
            value={filters.startDate}
            iconRight='CalendarScheduleIcon'
            placeholder={
              lang.expenses.table.filters.initial_date[currentLangKey]
            }
          />

          <DatePicker.Default
            variant='small'
            containerClassName='datepicker-container'
            currentLangKey={currentLangKey}
            label={lang.expenses.table.filters.final_date[currentLangKey]}
            onChangeValue={value => {
              onChangeFilters('endDate', value);
            }}
            value={filters.endDate}
            iconRight='CalendarScheduleIcon'
            placeholder={lang.expenses.table.filters.final_date[currentLangKey]}
          />

          <Select.Default
            small
            name='typeOfExpense'
            onChange={value => {
              onChangeFilters('typeOfExpense', value as string);
            }}
            options={typesOfExpensesOptions}
            value={filters.typeOfExpense}
            hasArrowDownIndicator
            label={lang.expenses.table.filters.type_of_expense[currentLangKey]}
            placeholder={
              lang.expenses.table.filters.type_of_expense[currentLangKey]
            }
          />

          {/* ! Vamos remover temporariamente e quando produto definir vamos voltar com ele.
          <DefaultButton
            size='small'
            onClick={() => {
              onChangeFilters('clear', undefined);
            }}
          >
            {t('clearFilters')}

            <Icons.FilterIcon />
          </DefaultButton> */}
          <TextButton
            size='small'
            onClick={() => {
              setMoreFiltersActive(!moreFiltersActive);
            }}
            className='more-filters-button'
          >
            <TextButtonLabel $isActive={moreFiltersActive}>
              {moreFiltersActive
                ? lang.expenses.table.filters.fewer_filters[currentLangKey]
                : lang.expenses.table.filters.more_filters[currentLangKey]}

              <DSIcons.ArrowDownIcon />
            </TextButtonLabel>
          </TextButton>
        </FiltersFirstLine>

        <AnimatePresence>
          {moreFiltersActive && (
            <FiltersSecondLine {...animations.fadeInOut}>
              <Select.Default
                small
                name='formOfPayment'
                onChange={value => {
                  onChangeFilters('paymentMethod', value as string);
                }}
                options={paymentMethodsOptions}
                value={filters.paymentMethod}
                hasArrowDownIndicator
                label={
                  lang.expenses.table.filters.form_of_payment[currentLangKey]
                }
                placeholder={
                  lang.expenses.table.filters.form_of_payment[currentLangKey]
                }
              />

              <MultiSelect.Default
                small
                name='refundable'
                onChange={value => {
                  onChangeFilters('refundable', value as string[]);
                }}
                options={refundableOptions}
                value={filters.refundable}
                hasArrowDownIndicator
                label={lang.expenses.table.filters.refundable[currentLangKey]}
                placeholder={
                  lang.expenses.table.filters.refundable[currentLangKey]
                }
              />
            </FiltersSecondLine>
          )}
        </AnimatePresence>
      </FiltersContainer>
    </>
  );
}
