import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { Wrapper as NotificationTrigger } from 'presentation/components/base/Notification/NotificationTrigger/NotificationTrigger.styles';
import {
  List,
  NotificationContainer
} from 'presentation/components/global/OldNotifications/Notifications.styles';
import { StyledArrow } from 'presentation/ds/DsDropdown/DropdownContent/DropdownContent.styles';

import { type ContainerStylesType } from './Navbar.types';

export const NavbarLabel = styled.span`
  min-width: fit-content;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2.2rem;
  text-align: center;
  letter-spacing: 0.025rem;
  padding: 0.3rem 1rem;
  border-radius: 8px;
  color: ${({ theme }) => theme.tokens.neutralColorHighPure};
  background: ${({ theme }) => theme.colors.mako};
  white-space: nowrap;
`;

export const Container = styled.div<AddPrefixToType<ContainerStylesType>>`
  width: 100%;
  background: ${({ theme }) => theme.colors.alabaster};
  color: ${({ theme }) => theme.colors.alto};
  box-sizing: border-box;
  display: flex;
  position: sticky;
  top: 0;
  left: 0%;
  z-index: ${({ theme }) => theme.zIndex.topbar};
  justify-content: end;
  align-items: center;
  gap: 1rem;
  overflow: visible;
  padding-right: 3rem;
  padding-left: 2rem;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.tokens.neutralColorHighMedium}`};

  & > svg {
    display: none;

    * {
      fill: ${({ theme }) => theme.tokens.brandColorPrimaryDarkest} !important;
    }
  }

  ${({ $outsourced }) =>
    $outsourced &&
    css`
      button > div > p {
        color: ${({ theme }) => theme.colors.mineShaft};
      }

      svg :not(${List} svg *):not(${NotificationTrigger} svg *) {
        fill: ${({ theme }) => theme.colors.mineShaft};
        stroke: ${({ theme }) => theme.colors.mineShaft};
      }

      ${NotificationTrigger} svg * {
        stroke: ${({ theme }) => theme.colors.mineShaft};
      }

      ${StyledArrow} * {
        fill: ${({ theme }) => theme.colors.white} !important;
        stroke: ${({ theme }) => theme.colors.white} !important;
      }

      ${NavbarLabel} {
        background-color: ${({ theme }) => theme.colors.adamantineBlue};
        color: ${({ theme }) => theme.colors.white};
      }
    `}

  @media screen and (max-width: 800px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: none;
  }

  @media screen and (max-width: 1200px) {
    padding-left: 1rem;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;

  svg :not(${NotificationContainer} svg *) * {
    fill: ${({ theme }) => theme.colors.primary};
  }

  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 0.8rem;
  align-items: center;
  margin: 0 1.6rem;

  .notifications-dropdown {
    top: 5rem !important;
  }
`;

export const RightContent = styled.div`
  max-width: fit-content;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
`;

export const LeftContent = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: start;
  overflow: hidden;
  gap: 1.6rem;
  margin-right: 3.2rem;
`;

export const MobileContainer = styled(Container)`
  display: none;
  background-color: ${({ theme, $outsourced }) =>
    $outsourced ? theme.colors.codGray : theme.tokens.brandColorSecondaryPure};
  border: none;

  > svg {
    flex-shrink: 0;
    max-width: 1.8rem;
    height: 3rem;
    display: block;

    * {
      fill: ${({ theme }) => `${theme.colors.white}!important`};
      stroke: ${({ theme }) => `${theme.colors.white}!important`};
    }
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    > #logo {
      max-width: 15rem;
      flex-shrink: 0;
      height: 4rem;
      border-right: 1px solid white;
      padding-right: 2rem;
      margin-right: 1rem;

      * {
        fill: ${({ theme }) => `${theme.colors.white}!important`};
      }
    }

    > h1 {
      margin-bottom: 0.2rem;
      font-size: 1.8rem;
      line-height: 2rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.white};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: 800px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const MobileContent = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  justify-content: end;

  > svg {
    max-width: 1.8rem;
    height: 3rem;
    display: none;

    * {
      fill: ${({ theme }) => theme.colors.primary} !important;
    }

    @media screen and (max-width: 1200px) {
      display: block;
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 1rem;
    justify-content: space-between;
  }
`;
