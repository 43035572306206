import { faker } from '@faker-js/faker';

import { type ICreateTeamMembersVirtual } from 'data/modules/cards/businessCard';

import { MockApi } from 'shared/utils/global';

export async function createTeamMembersVirtualMock(): Promise<ICreateTeamMembersVirtual> {
  await MockApi.sleep(2000);

  return {
    id: faker.string.uuid()
  };
}
