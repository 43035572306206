import { useQueryCache } from 'data/cache';
import {
  type IUseGetRelatedProjects,
  type IUseGetRelatedProjectsParams,
  ProjectsQueryKeys,
  ProjectsService
} from 'data/modules/projects';

export function useGetRelatedProjects({
  enabled,
  ...payload
}: IUseGetRelatedProjectsParams): IUseGetRelatedProjects {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [ProjectsQueryKeys.GET_RELATED_PROJECTS, payload],
    queryFn: async () => await ProjectsService.getRelatedProjects(payload),
    enabled
  });

  return {
    relatedProjects: data ?? [],
    isLoadingRelatedProjects: isLoading || isFetching
  };
}
