import { faker } from '@faker-js/faker';

import {
  type IGetBalanceSolicitations,
  type StatusSolicitationType
} from 'data/modules/cards/balance';

import { MockApi } from 'shared/utils/global';

import { statusSolicitation } from '../balanceOptions.mock';

export async function getMyValueSolicitationsMock(): Promise<IGetBalanceSolicitations> {
  const data = [
    {
      id: faker.string.uuid(),
      approve: faker.datatype.boolean(),
      balanceId: faker.string.uuid(),
      userId: faker.string.uuid(),
      userName: faker.person.fullName(),
      status: faker.helpers.arrayElement(
        statusSolicitation
      ) as StatusSolicitationType,
      requestedAmount: parseFloat(faker.finance.amount()),
      transferredAmount: parseFloat(faker.finance.amount()),
      justify: faker.lorem.sentence(),
      destinationAccount: faker.finance.accountNumber(),
      transferAmount: parseFloat(faker.finance.amount()),
      dateSent: faker.date.recent().toISOString(),
      dateApproved: faker.date.recent().toISOString(),
      managerJustify: faker.lorem.sentence(),
      datePrevision: faker.date.future().toISOString(),
      approverName: faker.person.fullName(),
      finishDate: faker.date.recent().toISOString()
    }
  ];

  await MockApi.sleep(2000);

  return {
    data,
    total: data.length
  };
}
