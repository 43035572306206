import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  AccountMutationKeys,
  AccountService,
  type IUnblockCardPayload,
  type IUseUnblockCard,
  type IUseUnblockCardParams
} from 'data/modules/cards/account';

export function useUnblockCard(
  params?: IUseUnblockCardParams
): IUseUnblockCard {
  const { mutateAsync, isLoading } = useMutationCache({
    mutationKey: [AccountMutationKeys.UNBLOCK_CARD],
    mutationFn: async (payload: IUnblockCardPayload) =>
      await AccountService.unblockCard(payload),
    onSuccess: (cardData, payload) => {
      params?.onAfterSuccess?.(cardData, payload);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  return {
    unblockCard: mutateAsync,
    isUnblockingCard: isLoading
  };
}
