import { type BaseSyntheticEvent, useEffect, useState } from 'react';

import { useIsFetching } from '@tanstack/react-query';
import { toast } from 'ds/utils';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useLangContext, useModalContext } from 'data/contexts';
import { useGetAuthUser } from 'data/modules/auth';
import { CostCentersQueryKeys } from 'data/modules/costsCenters';
import { CurrenciesQueryKeys } from 'data/modules/currencies';
import {
  ExpensesQueryKeys,
  type ICreateFuelExpenseFirstStepFieldsForm,
  type IDetailedExpense,
  useCreateFuelExpense
} from 'data/modules/expenses';
import { useGetExpenseDetails } from 'data/modules/expenses/useCases/get-expense-details/useGetExpenseDetails';
import {
  FuelQueryKeys,
  useChangeExpenseType,
  useGetVehiclesByCurrentUser
} from 'data/modules/fuel';
import { PaymentMethodsQueryKeys } from 'data/modules/paymentMethods';
import {
  type ReportInformationType,
  useGetOpenReports
} from 'data/modules/reports';

import { routesPathPrefix } from 'shared/constants/global';
import { Currency } from 'shared/utils/format';

import {
  type IUseCreateFuelExpenseModal,
  type IUseCreateFuelExpenseModalParams,
  type ReadOnlyFieldsWhenUserIsUpdatingExpenseStateType,
  type StepType
} from './CreateFuelExpenseModal.types';

export function useCreateFuelExpenseModal({
  expenseUuidToUpdate
}: IUseCreateFuelExpenseModalParams): IUseCreateFuelExpenseModal {
  const [step, setStep] = useState<StepType>('defaultFields');

  const { handleCloseModal, handleChangeErrorMessage } = useModalContext();

  const [showGoBackModal, setShowGoBackModal] = useState(false);

  const [showChangeExpenseTypeAlert, setShowChangeExpenseTypeAlert] =
    useState(false);

  const [showSeparateReportWarningModal, setShowSeparateReportWarningModal] =
    useState(false);

  const { company, isFetchingAuthUser } = useGetAuthUser();

  const [showCreateReportForm, setShowCreateReportForm] = useState(false);

  const { handleOpenModal } = useModalContext();

  const { lang, currentLangKey } = useLangContext();

  const { t } = useTranslation('expenses');

  const { t: tFuel } = useTranslation('fuel');

  const [
    confirmedReportInformationBySelectedReport,
    setConfirmedReportInformationBySelectedReport
  ] = useState<ReportInformationType | null>(null);

  // o estado controla se os campos de câmbio serão exibidos ou não
  const [toggleShowHideExchangeFields, setToggleShowHideExchangeFields] =
    useState(false);

  function handleUpdateExpenseDataBySelectedReport(
    data: ReportInformationType | null
  ): void {
    setConfirmedReportInformationBySelectedReport(data);
  }

  const userAction = expenseUuidToUpdate ? 'update' : 'create';

  const { vehiclesByCurrentUser, isLoadingVehiclesByCurrentUser } =
    useGetVehiclesByCurrentUser({
      enabled: !!expenseUuidToUpdate
    });

  const [
    readOnlyFieldsWhenUserIsUpdatingExpense,
    setReadOnlyFieldsWhenUserIsUpdatingExpense
  ] = useState<ReadOnlyFieldsWhenUserIsUpdatingExpenseStateType>(new Set());

  const {
    firstStepForm,
    isLoadingExpenseParameters,
    secondStepForm,
    isCreatingFuelExpense,
    isUpdatingFuelExpense,
    allowUserChangeExpenseProjectByReportProject,
    expenseParameters
  } = useCreateFuelExpense({
    onAfterSubmitFirstStep: () => {
      setStep('selectReport');
    },
    onSuccessCreateFuelExpense: () => {
      handleCloseModal();
      toast.success(t('messages.fuelExpenseAddedSuccessfully'));
    },
    onSuccessUpdateFuelExpense: () => {
      handleCloseModal();
      toast.success(t('messages.expenseUpdatedSuccessfully'));
    },
    onErrorCreateOrUpdateFuelExpense: (message: string) => {
      showSeparateReportWarningModal &&
        setShowSeparateReportWarningModal(false);

      handleChangeErrorMessage({
        title: expenseUuidToUpdate
          ? t('modal.errorWhenUpdatingExpense')
          : t('modal.errorWhenCreatingExpense'),
        message
      });
    },
    confirmedReportInformationBySelectedReport,
    expenseUuidToUpdate
  });

  const { getEnsuredExpenseDetails, isFetchingAndPendingExpenseDetails } =
    useGetExpenseDetails({
      expenseUuid: '',
      enabled: false
    });

  function applyReadOnlyFieldsWhenUserIsUpdatingExpense(
    expenseToUpdate: IDetailedExpense
  ): void {
    if (userAction === 'create') {
      return;
    }

    const readOnlyFields: ReadOnlyFieldsWhenUserIsUpdatingExpenseStateType =
      new Set();

    if (expenseToUpdate.isFromCards) {
      readOnlyFields.add('description');
      readOnlyFields.add('paymentMethod');
      readOnlyFields.add('date');
      readOnlyFields.add('currency');
    }

    if (expenseToUpdate.conciliationBalance !== null) {
      readOnlyFields.add('value');
      readOnlyFields.add('currency');
      readOnlyFields.add('paymentMethod');
    }

    if (
      expenseToUpdate.isImported &&
      expenseParameters?.allowPaymentMethodChangeForImportedExpenses === false
    ) {
      readOnlyFields.add('paymentMethod');
    }

    if (
      expenseParameters?.companyApprovalType === 'CC' &&
      expenseToUpdate.report !== null
    ) {
      readOnlyFields.add('costsCenter');
    }

    setReadOnlyFieldsWhenUserIsUpdatingExpense(readOnlyFields);
  }

  async function loadExpenseAndPrePopulateFieldsFromUpdate(): Promise<void> {
    if (expenseUuidToUpdate === undefined) {
      return;
    }

    const expenseToUpdate = await getEnsuredExpenseDetails({
      expenseUuid: expenseUuidToUpdate
    });

    const isFuelingExpense =
      !!company?.fuelingTypeId &&
      Number(expenseToUpdate?.type?.id) === company?.fuelingTypeId &&
      company?.parameters.usesFuelsModule;

    if (!isFuelingExpense) {
      throw new Error('Fueling expense not found');
    }

    const licensePlateExists =
      expenseToUpdate.fueling?.vehicle?.id &&
      !!vehiclesByCurrentUser?.find(
        item => item.id === expenseToUpdate.fueling?.vehicle?.id
      );

    const valuesFromExpenseToUpdate:
      | ICreateFuelExpenseFirstStepFieldsForm
      | undefined =
      expenseToUpdate !== undefined
        ? {
            description: expenseToUpdate.title,
            currency: expenseToUpdate.currency.id,
            value: Currency.format('BRL', expenseToUpdate.value),
            date: expenseToUpdate.date,
            fuelType:
              licensePlateExists &&
              expenseToUpdate.fueling !== null &&
              expenseToUpdate.fueling?.fuel !== null
                ? {
                    value: String(expenseToUpdate.fueling?.fuel.id),
                    label: expenseToUpdate.fueling?.fuel.description
                  }
                : null,
            licensePlate:
              licensePlateExists && expenseToUpdate.fueling?.vehicle?.id
                ? String(expenseToUpdate.fueling?.vehicle.id)
                : '',
            mileage: expenseToUpdate.fueling?.odometerKilometrage
              ? Currency.format(
                  'BRL',
                  expenseToUpdate.fueling.odometerKilometrage,
                  false,
                  0
                )
              : '',
            pricePerLiter: expenseToUpdate.fueling?.amountPerLitre
              ? Currency.format('BRL', expenseToUpdate.fueling.amountPerLitre)
              : '',
            quantityOfLiters: expenseToUpdate.fueling?.volume
              ? Currency.format('BRL', expenseToUpdate.fueling.volume)
              : '',
            vehicleType:
              licensePlateExists && expenseToUpdate.fueling?.vehicleType?.id
                ? String(expenseToUpdate.fueling.vehicleType?.id)
                : '',
            costsCenter:
              expenseToUpdate.costsCenter !== null
                ? {
                    label: expenseToUpdate.costsCenter?.name,
                    value: expenseToUpdate.costsCenter?.id
                  }
                : undefined,
            paymentMethod: expenseToUpdate.paymentMethod?.id ?? '',
            refundable: expenseToUpdate.isReimbursable,
            observation: expenseToUpdate.observation ?? '',
            observationAttachments:
              expenseToUpdate.observationAttachments !== null
                ? expenseToUpdate.observationAttachments
                : undefined,
            receipt: expenseToUpdate?.receipt?.url ?? null,
            apportionment:
              expenseToUpdate.apportionments?.map(item => ({
                percentage: item.percentage,
                project: {
                  label: item.project.name,
                  value: item.project.id
                },
                value: item.value
              })) ?? [],
            odometerImage:
              expenseToUpdate.fueling?.odometerImage?.originalUrl ?? null
          }
        : undefined;

    firstStepForm.methods.reset(valuesFromExpenseToUpdate);
    secondStepForm.methods.reset({
      report: expenseToUpdate.report?.uuid ?? ''
    });

    applyReadOnlyFieldsWhenUserIsUpdatingExpense(expenseToUpdate);
  }

  useEffect(() => {
    if (
      expenseUuidToUpdate !== undefined &&
      !isLoadingExpenseParameters &&
      !isFetchingAuthUser &&
      !isLoadingVehiclesByCurrentUser
    ) {
      loadExpenseAndPrePopulateFieldsFromUpdate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    expenseUuidToUpdate,
    isLoadingExpenseParameters,
    isLoadingVehiclesByCurrentUser
  ]);

  const isValidatingDateLimitsByProject =
    useIsFetching({
      predicate: query => {
        return (
          query.queryKey[0] ===
            ExpensesQueryKeys.VALIDATE_DATE_LIMITS_BY_PROJECT &&
          query.state.status === 'pending'
        );
      }
    }) > 0;

  const isLoadingSomeData =
    useIsFetching({
      predicate: query => {
        return (
          (query.queryKey[0] === PaymentMethodsQueryKeys.GET_PAYMENT_METHODS ||
            query.queryKey[0] === CostCentersQueryKeys.GET_COST_CENTERS ||
            query.queryKey[0] === CurrenciesQueryKeys.GET_CURRENCIES ||
            query.queryKey[0] === FuelQueryKeys.GET_VEHICLES_BY_CURRENT_USER) &&
          query.state.status === 'pending'
        );
      }
    }) > 0 ||
    isLoadingExpenseParameters ||
    isValidatingDateLimitsByProject ||
    isFetchingAndPendingExpenseDetails;

  const { openReports } = useGetOpenReports();

  const selectedReport = useWatch({
    control: secondStepForm.methods.control,
    name: 'report'
  });

  const selectedReportCurrency = openReports?.find(
    ({ uuid }) => uuid === selectedReport
  )?.currency;

  const {
    methods: {
      formState: { isDirty, errors }
    }
  } = firstStepForm;

  const checkIfDateFieldHasError =
    errors.date !== undefined &&
    (errors.date.type === 'validated-by-project' ||
      errors.date.type === 'validated-by-user-policy');

  const checkIfValueFieldHasError =
    errors.value !== undefined && errors.value.type === 'max-value';

  const selectedExpenseCurrencyId = firstStepForm.selectedCurrencyId;

  const isExpenseCurrencyDifferentFromReportCurrency =
    selectedExpenseCurrencyId !== undefined &&
    selectedReportCurrency !== undefined &&
    selectedReportCurrency.id !== null &&
    String(selectedReportCurrency.id) !== selectedExpenseCurrencyId;

  const navigate = useNavigate();

  function handleClearSelection(): void {
    secondStepForm.methods.setValue('report', '');

    setConfirmedReportInformationBySelectedReport(null);

    // quando limpar seleção de relatório na edição de despesa, habilita novamente o campo de centro de custos
    userAction === 'update' &&
      setReadOnlyFieldsWhenUserIsUpdatingExpense(oldState => {
        const newState = new Set(oldState);

        newState.delete('costsCenter');

        return newState;
      });
  }

  async function handleFirstStepGoBackButton(): Promise<void> {
    if (userAction === 'update') {
      const expenseToUpdate = await getEnsuredExpenseDetails({
        expenseUuid: expenseUuidToUpdate as string
      });

      navigate(`${routesPathPrefix.expenses}/${expenseToUpdate.id}`);

      handleOpenModal('viewExpensesModal');
      return;
    }

    if (isDirty) {
      setShowGoBackModal(true);
    } else {
      handleOpenModal('selectExpenseToAdd');
    }
  }

  function handleSubmitReportForm(e: BaseSyntheticEvent): void {
    e.preventDefault();

    if (!selectedReport && userAction === 'create') {
      setShowSeparateReportWarningModal(true);
      return;
    }

    secondStepForm.handleSubmit();
  }

  async function handleConfirmGoBackModal(): Promise<void> {
    if (userAction === 'update') {
      const expenseToUpdate = await getEnsuredExpenseDetails({
        expenseUuid: expenseUuidToUpdate as string
      });

      navigate(`${routesPathPrefix.expenses}/${expenseToUpdate.id}`);

      handleOpenModal('viewExpensesModal');
      return;
    }

    handleOpenModal('selectExpenseToAdd');
  }

  function updateFormAfterReportCreated(reportUuid: string): void {
    setShowCreateReportForm(false);
    setStep('selectReport');
    secondStepForm.methods.setValue('report', reportUuid);
  }

  function handleGoBackButton(): void {
    step === 'defaultFields' && handleFirstStepGoBackButton();

    if (step === 'selectReport') {
      setStep('defaultFields');

      if (isExpenseCurrencyDifferentFromReportCurrency) {
        firstStepForm.methods.setError('currency', {
          type: 'custom',
          message: lang.expenses.modal.different_currency_error[currentLangKey]
        });
      }
    }
  }

  function handleClickOverlay(): void {
    if (isDirty) {
      setShowGoBackModal(true);
      return;
    }

    handleCloseModal();
  }

  function validateExpenseCurrencyAndReportCurrency(
    expenseCurrencyId: string,
    reportCurrencyId: string
  ): void {
    if (expenseCurrencyId === reportCurrencyId) {
      firstStepForm.methods.clearErrors('currency');
      return;
    }

    firstStepForm.methods.setError('currency', {
      type: 'custom',
      message: lang.expenses.modal.different_currency_error[currentLangKey]
    });
  }

  function handleChangeFormExpenseCurrency(currencyId: string): void {
    firstStepForm.methods.setValue('currency', currencyId);

    // trata a validação de moeda diferente da moeda do relatório
    if (
      selectedReportCurrency !== undefined &&
      selectedReportCurrency.id !== null
    ) {
      const reportCurrencyId = String(selectedReportCurrency.id);

      validateExpenseCurrencyAndReportCurrency(currencyId, reportCurrencyId);
    }
  }

  function handleShowExchangeFields(): void {
    setToggleShowHideExchangeFields(true);
  }

  const { changeExpenseType, isChangingExpenseType } = useChangeExpenseType({
    onErrorChangeExpenseType: () => {
      toast.error(tFuel('modal.errorExpenseTypeChanged'));
    },
    onSuccessChangeExpenseType: () => {
      toast.success(tFuel('modal.expenseTypeChanged'));
      handleOpenModal('createManualExpense');
    }
  });

  function handleChangeExpenseType(): void {
    changeExpenseType({
      newType: 'default',
      uuid: expenseUuidToUpdate as string
    });
  }

  const firstStepSelectedCostsCenterId =
    firstStepForm.methods.getValues('costsCenter')?.value ?? '';

  const firstStepSelectedPaymentMethodId =
    firstStepForm.methods.getValues('paymentMethod');

  const apportionmentValue = firstStepForm.methods.getValues('apportionment');

  const firstStepSelectedProjectId =
    apportionmentValue !== undefined && apportionmentValue.length === 1
      ? apportionmentValue[0]?.project?.value ?? ''
      : '';

  const submitButtonDisabled =
    isLoadingSomeData ||
    (step === 'selectReport' && isExpenseCurrencyDifferentFromReportCurrency) ||
    checkIfDateFieldHasError ||
    checkIfValueFieldHasError;

  const isShowingModalLoading =
    isCreatingFuelExpense || isUpdatingFuelExpense || isChangingExpenseType;

  return {
    step,
    setStep,
    submitButtonDisabled,
    firstStepForm,
    secondStepForm,
    selectedReport,
    handleClearSelection,
    handleUpdateExpenseDataBySelectedReport,
    isShowingModalLoading,
    showGoBackModal,
    setShowGoBackModal,
    handleSubmitReportForm,
    showSeparateReportWarningModal,
    setShowSeparateReportWarningModal,
    setShowCreateReportForm,
    showCreateReportForm,
    updateFormAfterReportCreated,
    allowUserChangeExpenseProjectByReportProject,
    isExpenseCurrencyDifferentFromReportCurrency,
    handleGoBackButton,
    handleChangeFormExpenseCurrency,
    toggleShowHideExchangeFields,
    handleShowExchangeFields,
    confirmedReportInformationBySelectedReport,
    userAction,
    readOnlyFieldsWhenUserIsUpdatingExpense,
    handleClickOverlay,
    firstStepSelectedCostsCenterId,
    firstStepSelectedPaymentMethodId,
    firstStepSelectedProjectId,
    handleConfirmGoBackModal,
    handleChangeExpenseType,
    showChangeExpenseTypeAlert,
    setShowChangeExpenseTypeAlert
  };
}
