import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue
} from 'react-hook-form';

import { type IDsRadioGroupProps } from './DsRadioGroup.types';

import { Container } from './DsRadioGroup.styles';

export const DsRadioGroup = <T extends FieldValues>({
  children,
  defaultValue = '',
  direction = 'row',
  justify,
  value,
  name,
  control,
  onChange
}: IDsRadioGroupProps<T>): JSX.Element => {
  const radioGroupToRender = (
    value?: string,
    onChange?: (value: string) => void,
    name?: string
  ): JSX.Element => (
    <Container
      name={name}
      value={value}
      $justify={justify}
      $direction={direction}
      onValueChange={onChange}
      data-testid='radio-group'
      defaultValue={defaultValue}
    >
      {children}
    </Container>
  );

  if (!control) {
    return radioGroupToRender(value, onChange, name);
  }

  return (
    <Controller
      control={control}
      name={name as Path<T>}
      defaultValue={defaultValue as PathValue<T, Path<T>>}
      render={({ field: { name, onChange, value } }) =>
        radioGroupToRender(
          value,
          e => {
            onChange(e as PathValue<T, Path<T>>);
          },
          name
        )
      }
    />
  );
};
