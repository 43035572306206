import { tokens } from 'ds';
import { styled } from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};

  .skeleton-loader-container {
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacingXs};
  }
`;
