import {
  type IAdvancePayment,
  type IPersistenceAdvancePayment
} from 'data/modules/advancePayments';

class GetAdvancePaymentsMapper {
  toDomain(advance: IPersistenceAdvancePayment): IAdvancePayment {
    return {
      title: advance.description,
      uuid: advance.id
    };
  }
}

export default new GetAdvancePaymentsMapper();
