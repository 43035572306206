import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useModalContext } from 'data/contexts';
import {
  CardGroupsQueryKeys,
  type ICardGroup,
  type IEditCardGroupNamePayload,
  useEditCardGroupName,
  useEditCardGroupNameSchema
} from 'data/modules/cards/cardGroups';

import { useFormWithSchema } from 'shared/hooks/forms';
import { useDebounce } from 'shared/hooks/global';

import { type IUseEditCardGroupNameModal } from './EditCardGroupNameModal.types';

export function useEditCardGroupNameModal(): IUseEditCardGroupNameModal {
  const { editCardGroupName, isEditingCardGroupName } = useEditCardGroupName();
  const { handleCloseModal, handleOpenModal } = useModalContext();
  const { t } = useTranslation('cards');

  const [
    { managers, name },
    page,
    limit,
    ordenationType,
    selectedCardGroup,
    handleSelectCardGroup
  ] = useCardGroupsContext(
    useShallow(state => [
      state.filters,
      state.pagination.page,
      state.pagination.limit,
      state.ordenationType,
      state.selectedCardGroup,
      state.handleSelectCardGroup
    ])
  );

  const debouncedName = useDebounce(name);

  const methods = useFormWithSchema(useEditCardGroupNameSchema(), {
    defaultValues: { name: selectedCardGroup?.name! }
  });

  const queryClient = useQueryClient();

  async function handleSubmit(
    payload: Pick<IEditCardGroupNamePayload, 'name'>
  ): Promise<void> {
    try {
      if (payload.name === selectedCardGroup?.name) {
        handleCloseModal();
        handleOpenModal('groupCardDetails');
        return;
      }

      await editCardGroupName({
        id: selectedCardGroup?.id as string,
        name: payload.name
      });

      queryClient.invalidateQueries({
        queryKey: [
          CardGroupsQueryKeys.GET_CARD_GROUPS,
          {
            page,
            limit,
            ...(debouncedName && { name: debouncedName.trim().toLowerCase() }),
            ...(managers && { managers }),
            ...(ordenationType && { ordenationType })
          }
        ]
      });

      handleSelectCardGroup({
        ...selectedCardGroup,
        name: payload.name
      } as ICardGroup);

      handleCloseModal();
      handleOpenModal('groupCardDetails');
    } catch {
      methods.setError('name', { message: t('errorWhileEditingGroupName') });
    }
  }

  return {
    methods,
    isEditingCardGroupName,
    handleSubmit: methods.handleSubmit(handleSubmit)
  };
}
