import { useEffect, useMemo } from 'react';

import { driver, type DriveStep } from 'driver.js';

import { driverJsConfig } from 'data/config';
import { useLangContext } from 'data/contexts';

import { localStorageKeys } from 'shared/constants/global';
import { Zendesk } from 'shared/utils/global';

import { type IUseViewExpensesModalTourParams } from './useViewExpensesModalTour.types';

export function useViewExpensesModalTour({
  expenseHasReceiptAsImage,
  showingModalRightButton,
  isLoadingExpense
}: IUseViewExpensesModalTourParams): void {
  const {
    lang: {
      expenses: { tours },
      global
    },
    currentLangKey
  } = useLangContext();

  const driverSteps: DriveStep[] = useMemo(() => {
    const driverSteps: DriveStep[] = [];

    if (showingModalRightButton) {
      driverSteps.push({
        element: '.right-arrow-button',
        popover: {
          title: tours.expenseViewModal.first_step.title[currentLangKey],
          description:
            tours.expenseViewModal.first_step.description[currentLangKey]
        }
      });
    }

    if (expenseHasReceiptAsImage) {
      driverSteps.push({
        element: '#view-image',
        popover: {
          title: tours.expenseViewModal.second_step.title[currentLangKey],
          description:
            tours.expenseViewModal.second_step.description[currentLangKey]
        }
      });
    }

    return driverSteps;
  }, [
    expenseHasReceiptAsImage,
    currentLangKey,
    tours,
    showingModalRightButton
  ]);

  useEffect(() => {
    if (isLoadingExpense) return;

    function startTour(): void {
      const viewExpensesModalTour = driver({
        ...driverJsConfig,
        nextBtnText: global.next[currentLangKey],
        prevBtnText: global.previous[currentLangKey],
        doneBtnText: global.finalize[currentLangKey],
        progressText: `{{current}} ${global.of[currentLangKey]} {{total}}`,
        onDestroyed: () => {
          viewExpensesModalTour.destroy();
          localStorage.setItem(localStorageKeys.viewExpensesModalTour, 'true');
          Zendesk.show();
        },
        steps: driverSteps
      });

      viewExpensesModalTour.drive();
      Zendesk.hide();
    }

    if (
      localStorage.getItem(localStorageKeys.viewExpensesModalTour) !== 'true' &&
      driverSteps.length > 0
    ) {
      // espera 400ms para iniciar o tour por conta da animação do modal
      const timer = setTimeout(() => {
        startTour();
        Zendesk.hide();
      }, 400);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [driverSteps, isLoadingExpense, currentLangKey, global]);
}
