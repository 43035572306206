import {
  type FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import { differenceInMonths, isAfter, isFuture, subDays } from 'date-fns';

import { useLangContext } from 'data/contexts';
import {
  type IStatementDateFilter,
  type StatementMovementType
} from 'data/modules/cards/statement';

import {
  type IUseUserStatementSidebar,
  type UseUserStatementSidebarParamsType
} from './UserStatementSidebar.types';

export function useUserStatementSidebar({
  initialDateFilter,
  onFilter
}: UseUserStatementSidebarParamsType): IUseUserStatementSidebar {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [selectedMovementType, setSelectedMovementType] =
    useState<StatementMovementType>('all');
  const [dateFilter, setDateFilter] =
    useState<IStatementDateFilter>(initialDateFilter);
  const [dateFilterFromError, setDateFilterFromError] = useState('');
  const [dateFilterToError, setDateFilterToError] = useState('');

  const checkDateFilterErrors = useCallback(() => {
    setDateFilterFromError('');
    setDateFilterToError('');

    if (dateFilter.from === null || dateFilter.to === null) {
      if (dateFilter.from === null) {
        setDateFilterFromError(
          lang.cards.statement.sidebar.date_errors
            .please_provide_a_valid_start_date[currentLangKey]
        );
      }

      if (dateFilter.to === null) {
        setDateFilterToError(
          lang.cards.statement.sidebar.date_errors
            .please_provide_a_valid_end_date[currentLangKey]
        );
      }

      return;
    }

    const isFromDateInFuture = isFuture(dateFilter.from);
    const isToDateInFuture = isFuture(dateFilter.to);

    if (isFromDateInFuture || isToDateInFuture) {
      if (isFromDateInFuture) {
        setDateFilterFromError(
          lang.cards.statement.sidebar.date_errors
            .initial_date_bigger_than_actual[currentLangKey]
        );
      }
      if (isToDateInFuture) {
        setDateFilterToError(
          lang.cards.statement.sidebar.date_errors
            .final_date_bigger_than_actual[currentLangKey]
        );
      }
      return;
    }

    if (isAfter(dateFilter.from, dateFilter.to)) {
      setDateFilterFromError(
        lang.cards.statement.sidebar.date_errors
          .initial_date_bigger_than_final_date[currentLangKey]
      );
      setDateFilterToError(
        lang.cards.statement.sidebar.date_errors
          .final_date_smaller_than_initial_date[currentLangKey]
      );
      return;
    }

    // Desconsidera o dia da data final
    const dateDifferenceInMonths = differenceInMonths(
      subDays(dateFilter.to, 1),
      dateFilter.from
    );

    if (dateDifferenceInMonths >= 3) {
      setDateFilterToError(
        lang.cards.statement.sidebar.date_errors.max_period_three_months[
          currentLangKey
        ]
      );
    }
  }, [
    dateFilter.from,
    dateFilter.to,
    currentLangKey,
    lang.cards.statement.sidebar.date_errors
  ]);

  useEffect(() => {
    checkDateFilterErrors();
  }, [checkDateFilterErrors]);

  function handleFilter(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    onFilter({
      dateFilter,
      selectedMovementType,
      searchInput: searchInputRef.current?.value ?? ''
    });
  }

  return {
    filters: {
      dateFilter,
      searchInputRef,
      selectedMovementType,
      setDateFilter,
      setSelectedMovementType
    },
    errors: {
      dateFilterFromError,
      dateFilterToError
    },
    handleFilter
  };
}
