import { tokens } from 'ds';
import styled from 'styled-components';

export const TextLink = styled.strong`
  line-height: 140%;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXs};
  transition: color 0.25s ease-in-out;
  color: ${tokens.neutralColorLowDark};
  font-weight: ${tokens.fontWeightBold};
  font-family: ${tokens.fontFamilyPoppins};
`;

export const Container = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: ${tokens.spacingLg};
  padding: ${tokens.spacingLg};
  justify-content: space-between;

  @media screen and (max-width: 560px) {
    flex-direction: column-reverse;
    gap: ${tokens.spacingMd};
    padding: ${tokens.spacingXs};
  }
`;

export const List = styled.div`
  display: grid;
  width: 100%;
  row-gap: ${tokens.spacingXs};
`;

export const ListContent = styled.ul`
  display: grid;
  list-style: outside;
  padding: ${tokens.spacingSm};
  row-gap: ${tokens.spacingXs};
  padding-top: 0;
`;

export const ListItem = styled.li`
  line-height: 140%;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXxs};
  transition: color 0.25s ease-in-out;
  color: ${tokens.neutralColorLowDark};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamilyPoppins};
`;

export const IconWrapper = styled.div`
  svg {
    height: 20rem;
    background-size: contain;

    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tablet}px) {
      height: 17rem;
    }

    @media screen and (max-width: 560px) {
      height: 14rem;
    }
  }
`;
