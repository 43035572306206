import { Checkbox, DefaultTable } from 'ds';

import { useLangContext } from 'data/contexts';
import { type ISortTable } from 'data/contexts/cards/chargeCards/useChargeCardsContext.types';

import { ChargeCardsSteps } from 'presentation/pages/cards/Management/ChargeCards/ChargeCards.types';

import {
  ChargeCardsTableEmpty,
  ChargeCardsTableLoader,
  ChargeCardsTableRow,
  SearchAndFilters,
  TablePagination
} from '..';

import { useChargeCardsTable } from './useChargeCardsTable';

import { type IChargeCardsTableProps } from './ChargeCardsTable.types';

import { Container } from './ChargeCardsTable.styles';

export function ChargeCardsTable({
  ...rest
}: IChargeCardsTableProps): JSX.Element {
  const {
    lang: {
      cards: { valueAllocation },
      global
    },
    currentLangKey
  } = useLangContext();

  const {
    currentStep,
    selectedCardsMap,
    sortTable,
    cardsList,
    isLoading,
    handleChangeSortTable,
    handleToggleSelectAllCards,
    areMapAndArrayEqual
  } = useChargeCardsTable();

  return (
    <>
      <SearchAndFilters />

      <Container {...rest}>
        {isLoading && (
          <ChargeCardsTableLoader
            columns={6}
            rows={5}
          />
        )}

        {!isLoading && cardsList && cardsList.length === 0 && (
          <ChargeCardsTableEmpty>
            {valueAllocation.no_card_found[currentLangKey]}
          </ChargeCardsTableEmpty>
        )}

        {!isLoading && cardsList && cardsList.length > 0 && (
          <DefaultTable.Container>
            <DefaultTable.Thead>
              <DefaultTable.Tr>
                {currentStep === ChargeCardsSteps.CHARGE_CARDS && (
                  <DefaultTable.Th width='5%'>
                    <Checkbox.Default
                      name='selectAllCards'
                      small
                      value={areMapAndArrayEqual(cardsList, selectedCardsMap)}
                      onChangeValue={() => {
                        handleToggleSelectAllCards(cardsList);
                      }}
                    />
                  </DefaultTable.Th>
                )}

                <DefaultTable.Th
                  width='25%'
                  order={
                    sortTable.cardName.toLowerCase() as Lowercase<
                      ISortTable['cardName']
                    >
                  }
                  isActive
                  onClick={() => {
                    handleChangeSortTable('cardName');
                  }}
                >
                  {valueAllocation.card_name[currentLangKey]}
                </DefaultTable.Th>

                <DefaultTable.Th width='18%'>
                  {valueAllocation.card_type[currentLangKey]}
                </DefaultTable.Th>

                <DefaultTable.Th width='18%'>
                  {global.cards[currentLangKey]}
                </DefaultTable.Th>

                <DefaultTable.Th width='18%'>
                  {valueAllocation.current_value[currentLangKey]}
                </DefaultTable.Th>

                <DefaultTable.Th
                  width='25%'
                  className='charge-card-movement-input'
                >
                  {valueAllocation.movement[currentLangKey]}
                </DefaultTable.Th>

                <DefaultTable.Th width='18%'>
                  {valueAllocation.final_value[currentLangKey]}
                </DefaultTable.Th>

                <DefaultTable.Th
                  width='10%'
                  className='charge-cards-card-details'
                >
                  {valueAllocation.details[currentLangKey]}
                </DefaultTable.Th>
              </DefaultTable.Tr>
            </DefaultTable.Thead>

            <DefaultTable.Tbody>
              {cardsList.map(card => (
                <ChargeCardsTableRow
                  key={`${card.balanceId}-${card.name}-${card.number}`}
                  card={card}
                />
              ))}
            </DefaultTable.Tbody>
          </DefaultTable.Container>
        )}
      </Container>

      {(isLoading || (cardsList && cardsList.length > 0)) && (
        <TablePagination />
      )}
    </>
  );
}
