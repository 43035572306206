import { useDsGa4 } from 'ds/hooks/globals';
import { DSIcons } from 'ds/icons';
import { useFormContext } from 'react-hook-form';

import { type ITextAreaControlledProps } from './TextAreaControlled.types';

import {
  Caption,
  Container,
  Fieldset,
  Label,
  Legend,
  TextAreaComponent,
  TextareaContainer,
  Wrapper
} from './TextAreaControlled.styles';

export function TextAreaControlled({
  name,
  defaultValue,
  label,
  disabled,
  registerOptions,
  textareaProps,
  rightBottomIcon,
  onClickRightBottomIcon,
  large = false,
  small = false,
  ...rest
}: ITextAreaControlledProps): JSX.Element {
  const RightBottomIcon = rightBottomIcon ? DSIcons[rightBottomIcon] : null;

  const { sendDsGaEvent } = useDsGa4();

  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext();

  return (
    <Wrapper>
      <Container>
        <TextareaContainer
          $disabled={disabled}
          data-testid='container'
          $hasError={!!errors[name]?.message}
          $large={large}
          $small={small}
          $isTouched={!!watch(name)}
          $hasRightBottomIcon={!!RightBottomIcon}
          $allowClickRightBottomIcon={!!onClickRightBottomIcon}
          {...rest}
        >
          <Label>{label}</Label>
          <TextAreaComponent
            {...textareaProps}
            {...register(name, {
              onChange: event => {
                sendDsGaEvent('components', 'textarea', {
                  eventValue: event.target.value
                });
              },
              ...registerOptions
            })}
            defaultValue={defaultValue}
            disabled={disabled}
          />

          {RightBottomIcon && (
            <RightBottomIcon
              onClick={onClickRightBottomIcon && onClickRightBottomIcon}
            />
          )}
        </TextareaContainer>

        <Fieldset>
          <Legend $hasLabel={!!label}>
            <span>{label}</span>
          </Legend>
        </Fieldset>
      </Container>

      {!!errors[name]?.message && (
        <Caption $hasError>{errors[name]?.message as string}</Caption>
      )}
    </Wrapper>
  );
}
