import styled from 'styled-components';

import { Container as OnboardingStepContainer } from 'presentation/pages/cards/Onboarding/components/General/OnboardingStep/OnboardingStep.styles';

export const Container = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  height: 100%;
  gap: 2.4rem;

  ${OnboardingStepContainer}:has(> &) {
    align-items: center;
    display: flex;
  }

  p {
    white-space: pre-line;
    text-align: center;
    padding: 0 2.4rem;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.tokens.brandColorPrimaryMedium};

  > svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;
