import { DefaultTable } from 'ds';
import { useTranslation } from 'react-i18next';

import { useCardGroupsContext } from 'data/contexts';

import { TableLoader } from 'presentation/pages/cards/Management/components/TableLoader';
import {
  GroupManagementCard,
  GroupManagementTableHead,
  GroupManagementTableRow,
  SearchAndFilters,
  TablePagination
} from 'presentation/pages/cards/Management/GroupManagement/components';

import { useGroupManagementTable } from './useGroupManagementTable';

import { type IGroupManagementTableProps } from './GroupManagementTable.types';

import {
  CardsContainer,
  Container,
  EmptySolicitationList,
  TableContainer
} from './GroupManagementTable.styles';

export function GroupManagementTable({
  ...rest
}: IGroupManagementTableProps): JSX.Element {
  const { cardGroupUsers, isFetchingCardGroupUsers } =
    useGroupManagementTable();

  const { t } = useTranslation();

  const tableViewMode = useCardGroupsContext(
    state => state.filtersGroupManagement.tableViewMode
  );

  return (
    <Container {...rest}>
      <SearchAndFilters />

      {isFetchingCardGroupUsers ? (
        <TableLoader
          columns={6}
          rows={4}
        />
      ) : (
        <>
          {tableViewMode === 'LIST' && !isFetchingCardGroupUsers && (
            <TableContainer>
              <DefaultTable.Container>
                <GroupManagementTableHead
                  disabledFilters={!cardGroupUsers?.length}
                />

                <DefaultTable.Tbody>
                  {cardGroupUsers?.map(cardGroup => (
                    <GroupManagementTableRow
                      user={cardGroup}
                      key={cardGroup.id}
                    />
                  ))}
                </DefaultTable.Tbody>
              </DefaultTable.Container>
            </TableContainer>
          )}

          {tableViewMode === 'CARDS' && !isFetchingCardGroupUsers && (
            <CardsContainer>
              {cardGroupUsers?.map(cardGroupUser => (
                <GroupManagementCard
                  key={cardGroupUser.id}
                  user={cardGroupUser}
                />
              ))}
            </CardsContainer>
          )}

          {!cardGroupUsers?.length && (
            <EmptySolicitationList>
              {t('cardGroupsManagementTableEmptyMessage')}
            </EmptySolicitationList>
          )}
        </>
      )}

      <TablePagination
        disabled={!cardGroupUsers?.length || isFetchingCardGroupUsers}
      />
    </Container>
  );
}
