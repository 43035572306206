import axios from 'axios';

import { api } from 'data/config';
import {
  type IGetFileBlobByUrlPayload,
  type IGetViaCepAddressByPostalCodePayload,
  type ILogNotTreatedErrorPayload,
  type IPersistenceViaCepAddress,
  type IViaCepAddress
} from 'data/modules/global';

import {
  GetViaCepAddressByPostalCodeMapper,
  LogNotTreatedErrorMapper
} from './mappers';

class GlobalService {
  async getViaCepAddressByPostalCode({
    postalCode
  }: IGetViaCepAddressByPostalCodePayload): Promise<IViaCepAddress | null> {
    const { data } = await axios.get<IPersistenceViaCepAddress>(
      `https://viacep.com.br/ws/${postalCode}/json/`
    );

    return data.erro ? null : GetViaCepAddressByPostalCodeMapper.toDomain(data);
  }

  async logNotTreatedError(payload: ILogNotTreatedErrorPayload): Promise<void> {
    await api.post('/chronos/log-mobile', [
      LogNotTreatedErrorMapper.toPersistence(payload)
    ]);
  }

  async getFileBlobByUrl({ url }: IGetFileBlobByUrlPayload): Promise<Blob> {
    const response = await fetch(url);

    const data = await response.blob();

    return data;
  }
}

export default new GlobalService();
