import { useLangContext, useModalContext } from 'data/contexts';

import { type IRectButtonProps } from 'presentation/components/base/Button/RectButton/RectButton.types';
import { Modal } from 'presentation/components/base/Modal';
import {
  AccountHolderForm,
  ShareholdersForm
} from 'presentation/pages/settings/Cards/BusinessAccountHolders/components/ManageBusinessAccountHolderModal/';

import { useManageBusinessAccountHolderModal } from './useManageBusinessAccountHolderModal';

import { type IManageBusinessAccountHolderModalProps } from './ManageBusinessAccountHolderModal.types';

import { Container } from './ManageBusinessAccountHolderModal.styles';

export function ManageBusinessAccountHolderModal({
  accountHolderToBeUpdated,
  onAfterUpdateAccountHolder
}: IManageBusinessAccountHolderModalProps): JSX.Element {
  const { visibleModal } = useModalContext();
  const [settings, global, currentLangKey] = useLangContext(state => [
    state.lang.settings,
    state.lang.global,
    state.currentLangKey
  ]);

  const {
    accountHolderFormMethods,
    handleSubmitByStep,
    manageModalFormStep,
    setManageModalFormStep,
    shareholderFormFieldsAndMethods,
    handleFillWithMainCompanyShareholders,
    isLoadingMainCompanyShareholders,
    isStoringAccountHolder,
    isUpdatingAccountHolder,
    handleCloseModalOnClick
  } = useManageBusinessAccountHolderModal({
    accountHolderToBeUpdated,
    onAfterUpdateAccountHolder
  });

  const secondStepExtraButtons: IRectButtonProps[] = [
    {
      type: 'button',
      color: 'oldRose',
      children: global.previous[currentLangKey],
      onClick: () => {
        setManageModalFormStep(1);
      }
    },
    {
      type: 'button',
      color: 'primary',
      children: settings.accountHolders.add_another_shareholder[currentLangKey],
      iconProps: { name: 'add', color: 'white' },
      onClick: () => {
        shareholderFormFieldsAndMethods.shareholdersAppend({
          name: '',
          document: '',
          email: '',
          phoneNumber: '',
          motherName: '',
          birthDate: '',
          street: '',
          number: '',
          neighborhood: '',
          complement: '',
          city: '',
          state: '',
          country: 'Brasil',
          postalCode: '',
          swapType: 'other'
        });
      }
    }
  ];

  return (
    <Modal
      isOpen={visibleModal === 'manageBusinessAccountHolder'}
      isLoading={
        isLoadingMainCompanyShareholders ||
        isStoringAccountHolder ||
        isUpdatingAccountHolder
      }
      onClose={handleCloseModalOnClick}
      title={
        accountHolderToBeUpdated === null
          ? settings.accountHolders.create_business_account_holders[
              currentLangKey
            ]
          : settings.accountHolders.update_business_account_holder[
              currentLangKey
            ]
      }
      primaryButton={{
        children:
          manageModalFormStep === 1
            ? global.continue[currentLangKey]
            : global.finalize[currentLangKey],
        color: 'success',
        type: 'submit'
      }}
      secondaryButton={{
        children: global.close[currentLangKey],
        color: 'danger',
        onClick: () => {
          handleCloseModalOnClick();
        }
      }}
      extraButtons={
        manageModalFormStep === 2 ? secondStepExtraButtons : undefined
      }
      onSubmit={handleSubmitByStep}
    >
      <Container>
        {manageModalFormStep === 1 ? (
          <AccountHolderForm
            control={accountHolderFormMethods.control}
            errors={accountHolderFormMethods.errors}
            isFetchingAddress={accountHolderFormMethods.isFetchingAddress}
            isUpdating={accountHolderToBeUpdated !== null}
          />
        ) : (
          <ShareholdersForm
            control={shareholderFormFieldsAndMethods.control}
            errors={shareholderFormFieldsAndMethods.errors}
            shareholdersFields={
              shareholderFormFieldsAndMethods.shareholdersFields
            }
            shareholdersRemove={
              shareholderFormFieldsAndMethods.shareholdersRemove
            }
            setValue={shareholderFormFieldsAndMethods.setValue}
            clearErrors={shareholderFormFieldsAndMethods.clearErrors}
            onFillWithMainCompanyShareholders={
              handleFillWithMainCompanyShareholders
            }
          />
        )}
      </Container>
    </Modal>
  );
}
