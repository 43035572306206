import { Children } from 'react';

import { useAuthContext } from 'data/contexts';

import { Dropdown } from 'presentation/components/base/Dropdown';
import { NotificationTrigger } from 'presentation/components/base/Notification/NotificationTrigger/NotificationTrigger.component';

import { type INotificationDropdownProps } from './NotificationDropdown.types';

import { Container } from './NotificationDropdown.styles';

export function NotificationDropdown({
  children
}: INotificationDropdownProps): JSX.Element {
  const user = useAuthContext(state => state.user);

  const Elements = Children.toArray(children) as JSX.Element[];

  const Trigger = Elements.find(
    child => child.type.name === NotificationTrigger.name
  );

  if (!Trigger) {
    throw new Error('NotificationDropdown: NotificationTrigger is required');
  }

  const ContentElements = Elements.filter(
    child => child.type.name !== NotificationTrigger.name
  );

  return (
    <Container
      $hasOutsourcingAccess={user?.parameters?.hasOutsourcingAccess ?? false}
    >
      <Dropdown
        direction='left'
        content={ContentElements}
        className='notifications-dropdown'
      >
        {Trigger && <Trigger.type {...Trigger.props} />}
      </Dropdown>
    </Container>
  );
}
