import { type FC, type ReactNode, useCallback } from 'react';

import { SkeletonLoader } from 'ds/components/SkeletonLoader/SkeletonLoader.component';
import { useDsGa4 } from 'ds/hooks/globals';
import { DSIcons } from 'ds/icons';
import {
  type ActionMeta,
  components,
  type DropdownIndicatorProps,
  type InputActionMeta,
  type MenuProps,
  type OptionProps
} from 'react-select';
import { type FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { useSubMenuSelectControlled } from './useSubMenuSelectControlled';

import {
  type ISubMenuSelectControlledOption,
  type ISubMenuSelectControlledProps
} from './SubMenuSelectControlled.types';

import {
  Caption,
  Container,
  Fieldset,
  Label,
  Legend,
  MenuInformation,
  StyledSelect,
  Wrapper
} from './SubMenuSelectControlled.styles';

const Menu: FC<MenuProps & { information?: string }> = ({
  children,
  information,
  ...props
}): ReactNode => {
  return (
    <components.Menu {...props}>
      {Boolean(information) && <MenuInformation>{information}</MenuInformation>}
      {children}
    </components.Menu>
  );
};

export function SubMenuSelectControlled({
  name,
  options,
  disabled,
  className,
  placeholder,
  information,
  noOptionsMessage,
  hasArrowDownIndicator = false,
  isLoading = false,
  label,
  large = false,
  small = false,
  menuPlacement = 'bottom'
}: ISubMenuSelectControlledProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  const { menuIndex, setMenuIndex, error, onChange, ref, value } =
    useSubMenuSelectControlled({
      name
    });

  const currentOptions =
    menuIndex !== undefined ? options.options[menuIndex] : options.menuOptions;

  const Option: FC<OptionProps> = useCallback(
    props => {
      const optionValue = (props.data as ISubMenuSelectControlledOption).value;
      const isVisible = !!value && value.value === optionValue;

      if (menuIndex === undefined) {
        return (
          <components.Option {...props}>
            {props.children} <DSIcons.ArrowRightIcon />
          </components.Option>
        );
      }

      // caso o menuIndex esteja definido, renderiza as opções do submenu
      // caso a opção seja a selecionada e ela não está presente na lista de opções atual, não renderiza
      if (
        isVisible &&
        currentOptions.find(option => option.value === optionValue) ===
          undefined
      ) {
        return <></>;
      }

      return <components.Option {...props}>{props.children}</components.Option>;
    },
    [value, menuIndex, currentOptions]
  );

  const DropdownIndicator: FC<DropdownIndicatorProps> = useCallback(props => {
    return (
      <components.DropdownIndicator {...props}>
        <DSIcons.ChevronIcon />
      </components.DropdownIndicator>
    );
  }, []);

  const componentToRender = (
    <div>
      <Wrapper
        $large={large}
        $small={small}
      >
        <StyledSelect
          $large={large}
          $error={!!error?.message}
          isClearable
          $small={small}
          $hasArrowDownIndicator={hasArrowDownIndicator}
          $isTouched={!!value}
          $disabled={disabled}
          $hasInformation={Boolean(information)}
          components={{
            DropdownIndicator,
            Option,
            Menu: props => (
              <Menu
                {...props}
                information={information}
              />
            )
          }}
          name={name}
          ref={ref}
          classNamePrefix={'react-select-custom'}
          closeMenuOnSelect={menuIndex !== undefined}
          isSearchable
          placeholder={!disabled ? placeholder ?? 'Selecione...' : placeholder}
          menuPlacement={menuPlacement}
          isDisabled={disabled}
          options={
            !!value && menuIndex ? [value, ...currentOptions] : currentOptions
          }
          className={className}
          isOptionSelected={(option: unknown) => {
            const typedOption = option as ISubMenuSelectControlledOption;
            return !!value && value.value === typedOption.value;
          }}
          onInputChange={(value: string, action: InputActionMeta) => {
            if (action?.action !== 'menu-close') {
              sendDsGaEvent('components', 'dropdown', {
                description: 'Digitar texto',
                eventAction: 'text_dropdown',
                eventLabel: label ?? 'text_dropdown',
                eventValue: value
              });
            }
          }}
          filterOption={(option: FilterOptionOption<unknown>, input: string) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          value={value}
          onChange={(newValue: unknown, action: ActionMeta<unknown>) => {
            if (action.action === 'clear') {
              onChange(null);
              return;
            }

            const typedNewValue = newValue as ISubMenuSelectControlledOption;

            if (menuIndex === undefined) {
              setMenuIndex(
                options.menuOptions.findIndex(
                  item => item.value === typedNewValue.value
                )
              );

              return;
            }

            const menuOptionLabel = options.menuOptions[menuIndex].label;

            setMenuIndex(undefined);
            onChange({
              label: `${menuOptionLabel} - ${typedNewValue.label}`,
              value: typedNewValue.value
            });

            const value = typedNewValue.value;

            value &&
              sendDsGaEvent('components', 'dropdown', {
                description: 'Click nas opções',
                eventAction: 'select_dropdown',
                eventLabel: label ?? 'dropdown_without_label',
                eventValue: value
              });
          }}
          noOptionsMessage={() => noOptionsMessage ?? 'Sem mais opções'}
          onMenuOpen={() => {
            sendDsGaEvent('components', 'dropdown', {
              description: 'Abrir a lista de opções',
              eventAction: 'list_dropdown',
              eventLabel: 'open_list_dropdown'
            });
          }}
          onMenuClose={() => {
            setMenuIndex(undefined);

            sendDsGaEvent('components', 'dropdown', {
              description: 'Fechar lista de opções',
              eventAction: 'list_dropdown',
              eventLabel: 'close_list_dropdown'
            });
          }}
        />

        <Fieldset>
          <Legend>
            <span>{label}</span>
          </Legend>
        </Fieldset>

        {label && <Label>{label}</Label>}
      </Wrapper>

      {error?.message && <Caption $error={true}>{error?.message}</Caption>}
    </div>
  );

  return (
    <Container>
      {isLoading ? <SkeletonLoader height='3rem' /> : componentToRender}
    </Container>
  );
}
