import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useModalContext } from 'data/contexts';

import { ActionModal } from 'presentation/components/global/ActionModal';

import { StepGraph } from '..';

import { useCardGroupsInfoModal } from './useCardsGroupInfoModal';

import { type ICardGroupsInfoModalProps } from './CardsGroupsInfoModal.types';

import {
  Container,
  Description,
  IconContainer,
  IconContent
} from './CardsGroupsInfoModal.styles';

export function CardGroupsInfoModal({
  ...rest
}: ICardGroupsInfoModalProps): JSX.Element {
  const { t } = useTranslation(['cards', 'global']);

  const {
    content: { Icon, description }
  } = useCardGroupsInfoModal();

  const { handleCloseModal } = useModalContext();

  const [currentStep, handleChangeStepOfGroupCreation] = useCardGroupsContext(
    useShallow(state => [
      state.currentStepOfGroupCreation,
      state.handleChangeStepOfGroupCreation
    ])
  );

  return (
    <ActionModal
      title={t('createNewGroupOfCards')}
      onClose={handleCloseModal}
      confirmLabel={t('global:next')}
      onConfirm={() => {
        handleChangeStepOfGroupCreation('next');
      }}
      cancelLabel={t('global:back')}
      {...(currentStep !== 0 && {
        onCancel: () => {
          handleChangeStepOfGroupCreation('prev');
        }
      })}
    >
      <Container {...rest}>
        <IconContent>
          <IconContainer>
            <Icon />
          </IconContainer>
        </IconContent>

        <Description>{description}</Description>

        <StepGraph
          currentStep={currentStep}
          totalSteps={4}
        />
      </Container>
    </ActionModal>
  );
}
