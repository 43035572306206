import { DefaultButton, SecondaryButton, TextInput } from 'ds';
import { useTranslation } from 'react-i18next';

import { NewTeamMemberModal } from 'presentation/pages/settings/companyData/TeamMembers/components/Modals';
import { TeamMembersCards } from 'presentation/pages/settings/companyData/TeamMembers/components/TeamMembersCards';
import { TeamMembersEmptyResults } from 'presentation/pages/settings/companyData/TeamMembers/components/TeamMembersEmptyResults';
import { TeamMembersTable } from 'presentation/pages/settings/companyData/TeamMembers/components/TeamMembersTable';
import { SettingsLayout } from 'presentation/templates';

import { useTeamMembers } from './useTeamMembers';

import { ActionsContainer, Container } from './TeamMembers.styles';

export function TeamMembers(): JSX.Element {
  const { t } = useTranslation(['settings', 'global']);

  const {
    filters,
    teamMembers,
    handleExcelExport,
    teamMembersResume,
    handleChangeSearch,
    isLoadingTeamMembers,
    isLoadingChargedUsers,
    handleSortTeamMembers,
    handleAddNewTeamMember,
    isLoadingTeamMembersResume
  } = useTeamMembers();

  return (
    <SettingsLayout
      pageNameAndBreadcrumbs={{
        isClassicMode: false,
        isBackButtonVisible: false,
        isBreadcrumbsVisible: false,
        pageName: t('sidebar.teamMembers')
      }}
      currentSettingsPage={
        <Container>
          <ActionsContainer>
            <SecondaryButton
              size='small'
              onClick={handleExcelExport}
            >
              {t('teamMembers.excelReport')}
            </SecondaryButton>

            <DefaultButton
              size='small'
              onClick={handleAddNewTeamMember}
            >
              {t('teamMembers.newTeamMember')}
            </DefaultButton>
          </ActionsContainer>

          <TeamMembersCards
            cards={teamMembersResume}
            isLoadingTeamMembersResume={isLoadingTeamMembersResume}
          />

          <TextInput.Default
            small
            iconLeft='SearchIcon'
            value={filters.search}
            onChangeValue={handleChangeSearch}
            label={t('teamMembers.searchMembers')}
            placeholder={`${t('teamMembers.searchMembers')}...`}
          />

          {!isLoadingTeamMembers && !teamMembers.length ? (
            <TeamMembersEmptyResults />
          ) : (
            <TeamMembersTable
              filters={filters}
              teamMembers={teamMembers}
              onSortTeamMembers={handleSortTeamMembers}
              isLoadingTeamMembers={isLoadingTeamMembers}
              isLoadingChargedUsers={isLoadingChargedUsers}
            />
          )}

          <NewTeamMemberModal />
        </Container>
      }
    />
  );
}
