import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import {
  CompanyFormCacheDomains,
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IAddMembersOrLegalRepresentativesFieldsForm,
  type IUseAddMembersOrLegalRepresentatives,
  type IUseAddMembersOrLegalRepresentativesParams,
  type MemberShareholderType,
  type OnboardingShareholderType,
  type UpdateCompanyFormCachePayloadType
} from 'data/modules/cards/companyOnboarding';

import { useFormWithSchema } from 'shared/hooks/forms';
import { useHandleQueryCache } from 'shared/hooks/global';
import { CustomObject } from 'shared/utils/custom';

import { useAddMembersOrLegalRepresentativesSchema } from './schemas';

export function useAddMembersOrLegalRepresentatives({
  shareholderType,
  onAfterAddingMembersOrLegalRepresentatives
}: IUseAddMembersOrLegalRepresentativesParams): IUseAddMembersOrLegalRepresentatives {
  const { data: cachedCompanyOnboardingShareholders } = useHandleQueryCache({
    query: {
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingShareholders(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_SHAREHOLDERS]
    }
  });

  const queryClient = useQueryClient();

  const cachedSelectedShareholder =
    queryClient.getQueryData<MemberShareholderType>(['selectedShareholder']);

  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useFormWithSchema<IAddMembersOrLegalRepresentativesFieldsForm>(
    useAddMembersOrLegalRepresentativesSchema(),
    {
      defaultValues: {
        ...(cachedSelectedShareholder &&
          CustomObject.omit(cachedSelectedShareholder, [
            'shareholderType',
            'type'
          ]))
      }
    }
  );

  const { mutate, isLoading } = useMutationCache({
    mutationKey: [CompanyOnboardingMutationKeys.UPDATE_COMPANY_FORM_CACHE],
    mutationFn: async (payload: UpdateCompanyFormCachePayloadType) =>
      await CompanyOnboardingService.updateCompanyFormCache(payload),
    onSuccess: (data, vairables) => {
      onAfterAddingMembersOrLegalRepresentatives(
        vairables.value as Required<OnboardingShareholderType>[]
      );
    }
  });

  async function onSubmit(
    userEnteredData: IAddMembersOrLegalRepresentativesFieldsForm
  ): Promise<void> {
    if (isLoading) {
      return;
    }

    const isEditMode =
      cachedSelectedShareholder && cachedCompanyOnboardingShareholders;

    if (isEditMode) {
      const filteredShareholders = cachedCompanyOnboardingShareholders.filter(
        shareholder =>
          shareholder.document !== cachedSelectedShareholder.document
      );

      const hasAlreadyAddedShareholderDocument =
        cachedCompanyOnboardingShareholders?.find(
          shareholder =>
            shareholder.document === userEnteredData.document &&
            shareholder.document !== cachedSelectedShareholder?.document
        );

      if (hasAlreadyAddedShareholderDocument) {
        toast.error(
          lang.cards.onboarding.company.add_partner
            .natural_person_or_legal_entity.errors.already_added_document[
            currentLangKey
          ]
        );

        return;
      }

      mutate({
        domain: CompanyFormCacheDomains.onboardingShareholders,
        lifeCycle: 360,
        value: [
          ...(filteredShareholders ?? []),
          {
            ...userEnteredData,
            shareholderType,
            type: 'INDIVIDUAL'
          }
        ]
      });

      return;
    }

    const hasAlreadyAddedShareholderDocument =
      cachedCompanyOnboardingShareholders?.find(
        shareholder =>
          shareholder.document === userEnteredData.document &&
          shareholder.document !== cachedSelectedShareholder?.document
      );

    if (hasAlreadyAddedShareholderDocument) {
      toast.error(
        lang.cards.onboarding.company.add_partner.natural_person_or_legal_entity
          .errors.already_added_document[currentLangKey]
      );

      return;
    }

    mutate({
      domain: CompanyFormCacheDomains.onboardingShareholders,
      lifeCycle: 360,
      value: [
        ...(cachedCompanyOnboardingShareholders ?? []),
        {
          ...userEnteredData,
          shareholderType,
          type: 'INDIVIDUAL'
        }
      ]
    });
  }

  return {
    handleAddMembersOrLegalRepresentatives: handleSubmit(onSubmit),
    control,
    errors,
    setValue
  };
}
