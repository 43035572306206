import { useEffect } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { useCardDetailsContext } from 'data/contexts';
import { useGetAccountBalancesAmounts } from 'data/modules/cards/account';

import { Currency } from 'shared/utils/format';

import { type IUseCardAndControllers } from './CardAndControllers.types';

export function useCardAndControllers(): IUseCardAndControllers {
  const [selectedAccount, handleSetAvailableBalance] = useCardDetailsContext(
    useShallow(state => [
      state.selectedAccount,
      state.handleSetAvailableBalance
    ])
  );

  const { accountBalancesAmounts, isFetchingAccountBalancesAmounts } =
    useGetAccountBalancesAmounts({
      enabled: !selectedAccount?.availableBalance,
      accountBalancesIds: [selectedAccount?.balanceId as string]
    });

  useEffect(() => {
    if (
      !selectedAccount?.availableBalance &&
      !isFetchingAccountBalancesAmounts &&
      accountBalancesAmounts[0]
    ) {
      handleSetAvailableBalance(
        Currency.format('BRL', accountBalancesAmounts[0], true)
      );
    }
  }, [
    selectedAccount?.availableBalance,
    isFetchingAccountBalancesAmounts,
    accountBalancesAmounts,
    handleSetAvailableBalance
  ]);

  return {
    isFetchingAccountBalancesAmounts
  };
}
