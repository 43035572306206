import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';

import { ActionModal } from 'presentation/components/global/ActionModal';

import { type IUnblockCardPermissionDeniedModalProps } from './UnblockCardPermissionDeniedModal.types';

import { TextContent } from './UnblockCardPermissionDeniedModal.styles';

export function UnblockCardPermissionDeniedModal({
  lockLevel
}: IUnblockCardPermissionDeniedModalProps): JSX.Element {
  const { handleCloseModal } = useModalContext();
  const { t } = useTranslation(['global', 'cards']);

  return (
    <ActionModal
      title={t('global:permissionDenied')}
      onClose={() => {
        handleCloseModal();
      }}
      onConfirm={() => {
        handleCloseModal();
      }}
      confirmLabel={t('global:understood')}
      disabledFixedHeight
    >
      <TextContent>
        {`${
          lockLevel === 'SYSTEM'
            ? t('cards:cardBlockedBySystem')
            : t('cards:cardBlockedByAdmin')
        } ${t('cards:contactYourManagerForMoreInformation')}`}
      </TextContent>
    </ActionModal>
  );
}
