import { useShallow } from 'zustand/react/shallow';

import { useAuthContext, useLangContext } from 'data/contexts';

import {
  type INotification,
  type IUseNotifications
} from './Notifications.types';

export function useNotifications(): IUseNotifications {
  const [company, user] = useAuthContext(
    useShallow(state => [state.company, state.user])
  );
  const notifications: INotification[] = [];
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  /**
   * Adiciona notificação de e-mail não confirmado
   */
  if (user && !user.confirmed) {
    notifications.push({
      color: 'warning',
      icon: 'error-warning',
      message: `
        ${lang.navbar.components.confirm_account_1[currentLangKey]}
        ${user.email ?? ''}.
      `
    });
  }

  /**
   * Adiciona notificação do período de testes
   */
  if (company && company.testDaysRemaining > 0) {
    notifications.push({
      color: 'success',
      icon: 'checkbox-circle',
      message: `
        ${lang.navbar.components.remains[currentLangKey]}
        ${company.testDaysRemaining}
        ${lang.navbar.components.days_of_use[currentLangKey]}
      `
    });
  }

  return {
    notifications
  };
}
