import { type ReactNode } from 'react';

import { type ISimpleTableProps } from './SimpleTable.types';

import {
  Container,
  TableBodyRow,
  TableBodyRowCell,
  TableContainer,
  TableContainerHead,
  TableHeadRow
} from './SimpleTable.styles';

export function SimpleTable<T extends object>({
  header,
  data,
  dataId,
  emptyFallback,
  showHeaderOnMobile = true
}: ISimpleTableProps<T>): JSX.Element {
  return (
    <Container>
      <TableContainer>
        <TableContainerHead $showHeaderOnMobile={showHeaderOnMobile}>
          <TableHeadRow>
            {header.map(item => (
              <th key={item.key}>{item.label}</th>
            ))}
          </TableHeadRow>
        </TableContainerHead>
        <tbody>
          {emptyFallback ??
            data.map(item => (
              <TableBodyRow key={String(item[dataId])}>
                {header.map(headerItem => (
                  <TableBodyRowCell key={String(item[dataId]) + headerItem.key}>
                    <span>{headerItem.label}</span>
                    <span>{item[headerItem.key as keyof T] as ReactNode}</span>
                  </TableBodyRowCell>
                ))}
              </TableBodyRow>
            ))}
        </tbody>
      </TableContainer>
    </Container>
  );
}
