import { useLangContext } from 'data/contexts';

import { Select } from 'presentation/components/base/Select';
import { SkeletonLoader } from 'presentation/components/global/Loader';

import { usePagination } from './usePagination';

import { type IPaginationProps } from './Pagination.types';

import {
  Container,
  CurrentPage,
  PageDetails,
  PagesContainer,
  PageSizeContainer,
  SwitchPageButton
} from './Pagination.styles';

export function Pagination({
  currentPage,
  total,
  onChangePage,
  pageSize,
  onChangePageSize,
  breakpointToBreakInColumns = 'mobile',
  isLoading = false
}: IPaginationProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { lastPage, handleChangePageSize, pageSizeOptions } = usePagination({
    onChangePageSize,
    pageSize,
    total
  });

  return (
    <Container $breakpointToBreakInColumns={breakpointToBreakInColumns}>
      {isLoading ? (
        <>
          <SkeletonLoader maxWidth='10rem' />
          <SkeletonLoader maxWidth='10rem' />
          <SkeletonLoader maxWidth='10rem' />
        </>
      ) : (
        <>
          <PageDetails>
            {lang.global.pagination.page[currentLangKey]}{' '}
            {total === 0
              ? `0 ${lang.global.pagination.of[currentLangKey]} 0`
              : `${currentPage} ${lang.global.pagination.of[currentLangKey]} ${lastPage}`}
          </PageDetails>
          <PagesContainer>
            <SwitchPageButton
              disabled={currentPage <= 1}
              iconProps={{
                name: 'skip-back',
                color: 'dustyGray',
                size: 3,
                type: 'line'
              }}
              onClick={() => {
                onChangePage(1);
              }}
            />

            <SwitchPageButton
              disabled={currentPage <= 1}
              iconProps={{
                name: 'arrow-left-s',
                color: 'dustyGray',
                size: 3,
                type: 'line'
              }}
              onClick={() => {
                onChangePage(currentPage - 1);
              }}
            />

            <CurrentPage>{currentPage}</CurrentPage>

            <SwitchPageButton
              disabled={currentPage >= lastPage}
              iconProps={{
                name: 'arrow-right-s',
                color: 'dustyGray',
                size: 3,
                type: 'line'
              }}
              onClick={() => {
                onChangePage(currentPage + 1);
              }}
            />

            <SwitchPageButton
              disabled={currentPage >= lastPage}
              iconProps={{
                name: 'skip-forward',
                color: 'dustyGray',
                size: 3,
                type: 'line'
              }}
              onClick={() => {
                onChangePage(lastPage);
              }}
            />
          </PagesContainer>

          <PageSizeContainer>
            <p>{lang.global.pagination.itens_per_page[currentLangKey]}:</p>
            <Select
              name='pageSize'
              onChange={selected => {
                handleChangePageSize(selected as string);
              }}
              options={pageSizeOptions}
              value={String(pageSize)}
              hasArrowDownIndicator
              isSearchable={false}
            />
          </PageSizeContainer>
        </>
      )}
    </Container>
  );
}
