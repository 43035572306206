import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type PartnersTableContainerStylesType } from './PartnersList.types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  padding: 1.6rem;
  padding-top: 2.4rem;
  align-items: center;
  display: flex;
  gap: 0.8rem;
  margin-bottom: 1.5rem;

  > h1 {
    text-align: left;
    font-size: 1.4rem;
  }
`;

export const TableBodyRowCell = styled.td`
  color: ${({ theme }) => theme.colors.doveGray};
  padding: 1.2rem;
  font-size: 1.3rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gallery};
    display: flex;
    gap: 1rem;

    > span {
      width: 50%;
    }

    > span:first-child {
      display: block;
      font-weight: 700;
    }
  }
`;

export const PartnersTableContainer = styled.div<
  AddPrefixToType<PartnersTableContainerStylesType>
>`
  padding: 0 1.6rem;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1.5rem;

  table {
    width: 100%;
    border: none;
    border-spacing: 0;
    border-collapse: separate;
    background-color: ${({ theme }) => theme.colors.white};

    thead tr {
      padding-left: 3.6rem;

      th {
        border-top: 1px solid
          ${({ theme }) => theme.tokens.neutralColorHighMedium};
        border-bottom: 1px solid
          ${({ theme }) => theme.tokens.neutralColorHighMedium};

        padding: 1.8rem 1rem;
        text-align: left;
        font-size: 1.6rem;
        text-transform: none;
        color: ${({ theme }) => theme.tokens.neutralColorLowDark};
        font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto};
        font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
      }
    }

    tbody td {
      padding: 1.8rem 1rem;
      border-bottom: 1px solid
        ${({ theme }) => theme.tokens.neutralColorHighMedium};

      &:first-child {
        padding-left: 3.2rem;
      }

      p,
      strong {
        display: block;
        overflow: hidden;
        max-width: 18rem;
        font-size: 1.6rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: ${({ theme }) => theme.tokens.neutralColorLowDark};
      }

      strong + p {
        font-size: 1.4rem;
        padding-top: 0.8rem;
        color: ${({ theme }) => theme.tokens.neutralColorLowMedium};
        font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto};
        font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
      }

      > span:first-child {
        display: none;
      }

      &:last-child svg {
        fill: ${({ theme }) => theme.tokens.neutralColorLowLight};
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 2.4rem;

    table {
      ${({ $showHeaderOnMobile }) =>
        $showHeaderOnMobile &&
        css`
          thead tr {
            display: none;
          }
        `}
      tbody tr {
        display: block;
        border: none;
        border-bottom: 1px solid
          ${({ theme }) => theme.tokens.neutralColorLowLight};

        td {
          padding: 0;
          height: 4rem;
          border: none;
          display: flex;

          > span {
            width: 50%;
            display: flex;
            padding: 0 0.8rem;
            align-items: center;
          }

          &:has(strong + p) {
            height: 6rem;

            > span {
              padding: 0.8rem;
              display: block;
            }
          }

          > span:first-child {
            display: flex;
            font-size: 1.6rem;
            text-transform: none;
            color: ${({ theme }) => theme.tokens.neutralColorLowDark};
            font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto};
            font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
            border-right: 1px solid
              ${({ theme }) => theme.tokens.neutralColorLowLight};
          }

          &:first-child {
            padding-left: 0;
          }

          p,
          strong {
            max-width: 14rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 2.4rem 0;

    table {
      tbody tr td {
        p,
        strong {
          max-width: 9rem;
        }
      }
    }
  }
`;

export const EmptyPartnersTable = styled.tr`
  td {
    font-size: 1.8rem;
    font-style: italic;
    text-align: center;
    padding: 12rem 0 !important;
    color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
    font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto};
    font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
  }
`;

export const ControllersContainer = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`;

export const CustomEditButton = styled.div`
  &:not(:disabled):hover {
    svg {
      fill: ${({ theme }) => theme.tokens.brandColorPrimaryPure};
    }
  }
`;

export const CustomDeleteButton = styled.div`
  &:not(:disabled):hover {
    svg {
      fill: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
    }
  }
`;

export const CustomLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighMediumest};
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
