import { tokens } from 'ds';
import styled from 'styled-components';

export const ExchangeSection = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: ${tokens.fontSizeXxs};
    line-height: 2.4rem;
    color: ${tokens.neutralColorLowMedium};
  }
`;

export const SectionWithFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${tokens.spacingXs};
  border-top: 1px solid ${tokens.neutralColorHighDark};

  .view-image {
    height: 26.6rem;
    width: 20rem;
  }
`;
