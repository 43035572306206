import { useLangContext } from 'data/contexts';

import { type ISuggestionFormLabelProps } from './SuggestionFormLabel.types';

import { CharacterLimit, Container } from './SuggestionFormLabel.styles';

export function SuggestionFormLabel({
  label,
  characterLimit
}: ISuggestionFormLabelProps): JSX.Element {
  const {
    currentLangKey,
    lang: { forms }
  } = useLangContext();

  return (
    <Container>
      {label}
      <CharacterLimit>{`(${characterLimit} ${forms.characters[currentLangKey]})`}</CharacterLimit>
    </Container>
  );
}
