import { faker } from '@faker-js/faker';

import { type IPixQrCode } from 'data/modules/cards/balance';

import { MockApi } from 'shared/utils/global';

export async function getPixQrCodeStringMock(): Promise<IPixQrCode | null> {
  const data = {
    amount: parseFloat(faker.finance.amount()),
    emv: faker.string.alpha(16),
    id: faker.string.uuid(),
    transactionIdentification: faker.string.alpha(10)
  };

  await MockApi.sleep(2000);

  return data;
}
