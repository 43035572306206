import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  type IUpdateUserOnboardingAddressFieldsForm,
  type IUpdateUserOnboardingAnswersPayload,
  type IUseUpdateUserOnboardingAddress,
  type IUseUpdateUserOnboardingAddressParams,
  UserOnboardingMutationKeys,
  UserOnboardingQueryKeys,
  UserOnboardingService
} from 'data/modules/cards/userOnboarding';

import { useFormWithSchema } from 'shared/hooks/forms';
import { useHandleQueryCache } from 'shared/hooks/global';
import { CustomObject } from 'shared/utils/custom';

import { useUpdateUserOnboardingAddressSchema } from './schemas';

export function useUpdateUserOnboardingAddress({
  onAfterSuccessCompleteUserOnboarding
}: IUseUpdateUserOnboardingAddressParams): IUseUpdateUserOnboardingAddress {
  const { data: cachedUserOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () =>
        await UserOnboardingService.getUserOnboardingProgress(),
      key: [UserOnboardingQueryKeys.GET_USER_ONBOARDING_PROGRESS]
    }
  });

  const { data: cachedUserOnboardingAnswers } = useHandleQueryCache({
    query: {
      key: [
        UserOnboardingQueryKeys.GET_USER_ONBOARDING_ANSWERS,
        cachedUserOnboardingProgress?.onboardingUserId ?? ''
      ],
      query: async () =>
        await UserOnboardingService.getUserOnboardingAnswers({
          onboardingUserId: cachedUserOnboardingProgress?.onboardingUserId ?? ''
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedUserOnboardingProgress?.onboardingUserId ?? ''
    }
  });

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch
  } = useFormWithSchema(useUpdateUserOnboardingAddressSchema(), {
    defaultValues: {
      ...(cachedUserOnboardingAnswers?.userAccount &&
        CustomObject.pick(cachedUserOnboardingAnswers?.userAccount, [
          'city',
          'neighborhood',
          'number',
          'postalCode',
          'state',
          'street',
          'complement'
        ])),
      country: cachedUserOnboardingAnswers?.userAccount?.country ?? 'BR'
    }
  });

  const { mutate, isLoading } = useMutationCache({
    mutationKey: [UserOnboardingMutationKeys.UPDATE_USER_ONBOARDING],
    mutationFn: async (payload: IUpdateUserOnboardingAnswersPayload) =>
      await UserOnboardingService.updateUserOnboardingAnswers(payload),
    onSuccess: () => {
      onAfterSuccessCompleteUserOnboarding();
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function onSubmit(data: IUpdateUserOnboardingAddressFieldsForm): void {
    if (!cachedUserOnboardingProgress?.onboardingUserId || isLoading) {
      return;
    }

    mutate({
      onboardingUserId: cachedUserOnboardingProgress?.onboardingUserId,
      context: 'user_account',
      step: 3,
      answers: {
        ...data,
        country: 'BR'
      }
    });
  }

  return {
    control,
    handleUpdateUserOnboardingAddress: handleSubmit(onSubmit),
    errors,
    setValue,
    watch
  };
}
