import { useEffect } from 'react';

import { driver } from 'driver.js';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { driverJsConfig } from 'data/config';
import { useLangContext, useTourContext } from 'data/contexts';

import { localStorageKeys } from 'shared/constants/global';

import { type IUseValueSolicitationTour } from './useValueSolicitationTour.types';

export function useValueSolicitationTour({
  enabled
}: IUseValueSolicitationTour): void {
  const [reset, handleReset] = useTourContext(
    useShallow(state => [state.reset, state.handleReset])
  );

  const { groupId } = useParams();

  const {
    currentLangKey,
    lang: {
      cards: { tours },
      global
    }
  } = useLangContext();

  const valueSolicitationTour = driver({
    ...driverJsConfig,
    nextBtnText: global.next[currentLangKey],
    prevBtnText: global.previous[currentLangKey],
    doneBtnText: global.finalize[currentLangKey],
    progressText: `{{current}} ${global.of[currentLangKey]} {{total}}`,
    onDestroyed: () => {
      valueSolicitationTour.destroy();
      localStorage.setItem(localStorageKeys.valueSolicitationTour, 'true');
      handleReset('');
    },
    steps: [
      {
        element: '.guided-tour',
        popover: {
          title: tours.valueSolicitationTour.first_step.title[currentLangKey],
          description:
            tours.valueSolicitationTour.first_step.description[currentLangKey]
        }
      },
      ...(groupId
        ? [
            {
              element: '.value-solicitation-cards-breadcrumb',
              popover: {
                title:
                  tours.valueSolicitationTour.second_step.title[currentLangKey],
                description:
                  tours.valueSolicitationTour.second_step.description[
                    currentLangKey
                  ]
              }
            }
          ]
        : []),
      {
        element: '.value-solicitation-select-user-checkbox',
        popover: {
          title: tours.valueSolicitationTour.third_step.title[currentLangKey],
          description:
            tours.valueSolicitationTour.third_step.description[currentLangKey]
        }
      },
      {
        element: '.value-solicitation-transfer-another-value-button',
        popover: {
          title: tours.valueSolicitationTour.fourth_step.title[currentLangKey],
          description:
            tours.valueSolicitationTour.fourth_step.description[currentLangKey]
        }
      },
      {
        element: '.value-solicitation-disapprove-button',
        popover: {
          title: tours.valueSolicitationTour.fifth_step.title[currentLangKey],
          description:
            tours.valueSolicitationTour.fifth_step.description[currentLangKey],
          side: 'top'
        }
      },
      {
        element: '.value-solicitation-approve-button',
        popover: {
          title: tours.valueSolicitationTour.sixth_step.title[currentLangKey],
          description:
            tours.valueSolicitationTour.sixth_step.description[currentLangKey]
        }
      },
      {
        element: '.value-solicitations-card-details',
        popover: {
          title: tours.valueSolicitationTour.seventh_step.title[currentLangKey],
          description:
            tours.valueSolicitationTour.seventh_step.description[
              currentLangKey
            ],
          side: 'top',
          align: 'end'
        }
      }
    ]
  });

  useEffect(() => {
    if (enabled || reset === localStorageKeys.valueSolicitationTour) {
      valueSolicitationTour.drive();
    }
  }, [valueSolicitationTour, enabled, reset]);
}
