import { Currency } from 'shared/utils/format';

class Exchange {
  /**
   * Este método irá realizar o cálculo do câmbio aplicando a operação que for
   * necessária de acordo com o argumento recebido na função, por padrão o JS
   * está usando uma precisão de 4 casas decimais.
   * O valor arredondado pode ser fixado em uma determinada quantidade de casas decimais
   * de acordo com o informado como parâmetro no método
   *
   * essa função foi retirada e adaptada do arquivo ConversaoCambio.js função calculate do repositório vexpenses-web
   */
  calculate(
    firstValue: number,
    secondValue: number,
    operator: '/' | '*',
    decimalPlaces = 4
  ): string {
    let value;
    let absValue;

    // valor de precisão para determinar quantas casas decimais teremos no final
    const precision = Math.pow(10, 8);

    // Determiando os valores usados no cálculo com a presição definida anteriormente
    firstValue *= precision;
    secondValue *= precision;

    if (operator === '/') {
      value = firstValue / secondValue;
      absValue = Math.abs(Math.round(value * precision));
    } else {
      value = secondValue * firstValue;
      absValue = Math.abs(Math.round(value / precision));
    }

    const result = (absValue / precision).toFixed(decimalPlaces);
    return Currency.format('BRL', parseFloat(result), false, decimalPlaces);
  }
}

export default new Exchange();
