import { useDsGa4 } from 'ds/hooks/globals';
import { DSIcons } from 'ds/icons';
import ReactModal from 'react-modal';

import { useModalContainer } from './useModalContainer';

import { type IModalContainerProps } from './ModalContainer.types';

import {
  ButtonArrow,
  ContainerArrows,
  Content,
  Overlay
} from './ModalContainer.styles';

export function ModalContainer({
  children,
  onClose,
  variant = 'medium',
  appRootElementId = 'root',
  onLeftArrowClick,
  onRightArrowClick,
  rightButtonHtmlAttributes,
  containerArrowsStyle,
  ...rest
}: IModalContainerProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  if (process.env.NODE_ENV !== 'test') {
    ReactModal.setAppElement(`#${appRootElementId}`);
  }

  useModalContainer();

  return (
    <ReactModal
      isOpen={true}
      onRequestClose={() => {
        sendDsGaEvent('components', 'modal', {
          description: 'fechar modal',
          eventAction: 'close_modal',
          eventLabel: 'close'
        });

        onClose();
      }}
      className='_'
      overlayClassName='_'
      contentElement={(props, children) => (
        <ContainerArrows style={containerArrowsStyle}>
          <ButtonArrow
            $isVisible={!!onLeftArrowClick}
            onClick={e => {
              e.stopPropagation();
              if (onLeftArrowClick) {
                onLeftArrowClick();

                sendDsGaEvent('components', 'modal', {
                  description: 'voltar modal',
                  eventAction: 'previous_modal',
                  eventLabel: 'previous'
                });
              }
            }}
          >
            <DSIcons.ArrowLeftIcon />
          </ButtonArrow>

          <Content
            $variant={variant}
            {...props}
            {...rest}
          >
            {children}
          </Content>

          <ButtonArrow
            $isVisible={!!onRightArrowClick}
            onClick={e => {
              e.stopPropagation();

              if (onRightArrowClick) {
                onRightArrowClick();

                sendDsGaEvent('components', 'modal', {
                  description: 'proxima modal',
                  eventAction: 'next_modal',
                  eventLabel: 'next'
                });
              }
            }}
            {...rightButtonHtmlAttributes}
          >
            <DSIcons.ArrowRightIcon />
          </ButtonArrow>
        </ContainerArrows>
      )}
      overlayElement={(props, contentElement) => (
        <Overlay {...props}>{contentElement}</Overlay>
      )}
    >
      {children}
    </ReactModal>
  );
}
