import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IUpdateAnswerableUserFieldsForm } from 'data/modules/cards/companyOnboarding';

import { Validate } from 'shared/utils/validation';

export function useUpdateAnswerableUserSchema(): ObjectSchema<IUpdateAnswerableUserFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const updateAnswerableUserSchema: ObjectSchema<IUpdateAnswerableUserFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object({
        fullName: yup.string().required(fieldRequiredMessage).test({
          name: 'fullName',
          message: lang.schemas.required_full_name[currentLangKey],
          test: Validate.fullName
        }),
        documentNumber: yup
          .string()
          .required(fieldRequiredMessage)
          .min(14, `${lang.schemas.min[currentLangKey]} 14`)
          .test({
            name: 'validCpf',
            message: lang.schemas.invalid_cpf[currentLangKey],
            test: Validate.cpf
          }),
        phoneNumber: yup
          .string()
          .required(fieldRequiredMessage)
          .min(15, `${lang.schemas.min[currentLangKey]} 15`)
      });
    }, [lang, currentLangKey]);

  return updateAnswerableUserSchema;
}
