import { faker } from '@faker-js/faker';

import { type IUserAccountByUserUuid } from 'data/modules/cards/account';

import { MockApi } from 'shared/utils/global';

import { statusOptions } from '..';

export async function getUserAccountsByUserUuidMock(): Promise<
  IUserAccountByUserUuid[]
> {
  const data = [
    {
      account: {
        id: faker.string.uuid(),
        status: faker.helpers.arrayElement(statusOptions),
        description: faker.lorem.sentence(2)
      },
      balances: [
        {
          id: faker.string.uuid(),
          balance: faker.number.float({ fractionDigits: 1, max: 500 }),
          inUse: true,
          description: faker.lorem.sentence(2)
        },
        {
          id: faker.string.uuid(),
          balance: faker.number.float({ fractionDigits: 1, max: 500 }),
          inUse: false,
          description: faker.lorem.sentence(2)
        }
      ]
    }
  ];

  await MockApi.sleep(2000);

  return data;
}
