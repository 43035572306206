import {
  differenceInDays,
  differenceInMinutes,
  format,
  isDate,
  parse,
  parseISO
} from 'date-fns';

import { type LanguageType } from 'ds/types';
import { type LocaleType } from 'ds/types/globals/language.types';

class CustomDate extends Date {
  formatFromISO(date: string | undefined): string {
    if (!date) return '';

    return format(parseISO(date), 'dd/MM/yyyy');
  }

  getTimezone(): string {
    const timezoneInt = -this.getTimezoneOffset() / 60;
    const timezoneHours = Math.trunc(timezoneInt);
    const timezoneMinutes = Math.abs((timezoneInt % 1) * 60)
      .toString()
      .padStart(2, '0');

    return `${timezoneHours}:${timezoneMinutes}`;
  }

  formatToIso(date: string, formatString = 'dd/MM/yyyy'): string {
    const parsed = parse(date, formatString, new Date());

    return format(parsed, 'yyyy-MM-dd');
  }

  formatDateToIso(date: Date): string {
    return format(date, 'yyyy-MM-dd');
  }

  differenceBetweenDates(from: Date, to: Date): number {
    return differenceInDays(from, to);
  }

  isDateOlderThanHours(date?: Date | string, hours: number = 24): boolean {
    const parsed = new Date(date ?? '');

    return (
      isDate(parsed) && differenceInMinutes(new Date(), parsed) / 60 >= hours
    );
  }

  localeTransform(locale: LanguageType): LocaleType {
    switch (locale) {
      case 'en':
        return 'enUS';
      case 'es':
        return 'es';
      case 'pt':
        return 'ptBR';
      default:
        return 'ptBR';
    }
  }
}

export default new CustomDate();
