import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';
import { useParams } from 'react-router-dom';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import { AccountQueryKeys } from 'data/modules/cards/account';
import {
  BusinessCardsMutationKeys,
  BusinessCardsQueryKeys,
  BusinessCardsService,
  type IChangeVirtualMembersFieldsForm,
  type IChangeVirtualMembersPayload,
  type IUseChangeVirtualMembers,
  type IUseChangeVirtualMembersParams
} from 'data/modules/cards/businessCard';

import { useFormWithSchema } from 'shared/hooks/forms';

import { useChangeVirtualMembersSchema } from './schemas';

export function useChangeVirtualMembers({
  virtualMembers
}: IUseChangeVirtualMembersParams): IUseChangeVirtualMembers {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors, isDirty },
    setValue
  } = useFormWithSchema(useChangeVirtualMembersSchema());

  const { accountId = '' } = useParams();

  const queryClient = useQueryClient();

  const { mutate: changeVirtualMembers, isLoading } = useMutationCache({
    mutationKey: [BusinessCardsMutationKeys.CHANGE_VIRTUAL_MEMBER],
    mutationFn: async (payload: IChangeVirtualMembersPayload) =>
      await BusinessCardsService.changeVirtualMembers(payload),
    onSuccess: () => {
      toast.success(
        lang.settings.manageBusinessCard.card_details_changed_successfully[
          currentLangKey
        ]
      );
      queryClient.invalidateQueries({
        queryKey: [AccountQueryKeys.GET_COMPANY_ACCOUNTS]
      });

      queryClient.invalidateQueries({
        queryKey: [BusinessCardsQueryKeys.GET_VIRTUAL_MEMBERS, accountId]
      });
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function onSubmit(data: IChangeVirtualMembersFieldsForm): void {
    if (isDirty) {
      changeVirtualMembers({
        virtualMembers: data,
        uuid: virtualMembers.uuid
      });
    }
  }

  /**
   * Necessário para atualizar os campos do formulário com a cache
   *
   */
  useEffect(() => {
    virtualMembers.email && setValue('email', virtualMembers.email);
    virtualMembers.name && setValue('name', virtualMembers.name);
  }, [virtualMembers, setValue]);

  return {
    control,
    errors,
    isChangingVirtualMembers: isLoading,
    handleSubmit: handleSubmit(onSubmit),
    clearErrors
  };
}
