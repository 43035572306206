import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 19.4rem;
  gap: 1.5rem;
  background-color: ${tokens.neutralColorHighPure};
  border-radius: ${tokens.borderRadiusMd};
`;

export const CustomInfoMessageTitle = styled.div`
  display: flex;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
  color: ${tokens.neutralColorLowMedium};
`;

export const CustomInfoMessageText = styled.div`
  display: flex;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  line-height: 2.2rem;
  letter-spacing: 0.025rem;
  color: ${tokens.neutralColorLowMedium};
`;

export const IconContainer = styled.div`
  display: flex;
`;
