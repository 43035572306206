import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.alabaster};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const FiltersContent = styled.form`
  padding: 1.6rem 1rem;

  > p {
    display: block;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors.mineShaft};
    font-size: 1.3rem;
    font-weight: 500;
  }

  > div {
    margin-bottom: 1.7rem;
  }
`;

export const ActionsButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 0.5rem;

  > div {
    align-self: center;
    margin-right: 0.5rem;
  }

  > button {
    width: auto;
  }
`;
