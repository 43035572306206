import { useQueryCache } from 'data/cache';
import {
  AnalyticsQueryKeys,
  AnalyticsService,
  type IUseGetAnalysisUserReportsId
} from 'data/modules/analytics';

export function useGetAnalysisUserReportsId(): IUseGetAnalysisUserReportsId {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [AnalyticsQueryKeys.GET_ANALYSIS_USER_REPORTS_ID],
    queryFn: async () => await AnalyticsService.getAnalysisUserReportsId()
  });

  return {
    analysisUserReports: data ?? [],
    isLoadingAnalysisUserReports: isLoading,
    isFetchingAnalysisUserReports: isFetching
  };
}
