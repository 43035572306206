import { faker } from '@faker-js/faker';

import { type IVirtualMembers } from 'data/modules/cards/businessCard';

import { MockApi } from 'shared/utils/global';

export async function getVirtualMembersMock(): Promise<IVirtualMembers> {
  await MockApi.sleep(2000);

  return {
    email: faker.internet.email(),
    name: faker.person.fullName(),
    uuid: faker.string.uuid(),
    approvalFlowId: null,
    expensePolicyId: null,
    routePolicyId: null
  };
}
