import { useTranslation } from 'react-i18next';

import { usePasswordRequirements } from './usePasswordRequirements';

import {
  Container,
  InformationText,
  PasswordSecurityLevel,
  PasswordSecurityLevelProgress,
  PasswordStatus
} from './PasswordRequirements.styles';

export function PasswordRequirements(): JSX.Element {
  const { t } = useTranslation('global');
  const { score, feedback } = usePasswordRequirements();

  return (
    <>
      {score >= 0 && (
        <Container>
          <PasswordSecurityLevel>
            <PasswordSecurityLevelProgress $score={score} />
          </PasswordSecurityLevel>

          <InformationText>
            {score < 2 && (
              <>
                <PasswordStatus>{t('attention')}</PasswordStatus>
                {': '}
              </>
            )}
            {feedback}
          </InformationText>
        </Container>
      )}
    </>
  );
}
