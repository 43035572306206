import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto hidden;
  padding: ${tokens.spacingInsetNano};
  width: min-content;

  > nav > ul {
    padding: 0;
    gap: ${tokens.spacingInsetXs};
  }

  /* Width */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const TabItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingNano};
  font-size: ${tokens.fontSizeXxss};

  > span {
    font-size: ${tokens.fontSizeXxss};
  }
`;
