import styled from 'styled-components';

export const ModalBody = styled.div`
  max-width: 50rem;
  padding: 2rem;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.emperor};
  margin-block: 3rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-width: 40rem;
  }

  > p {
    text-align: center;
  }
`;
