import { DefaultTable, tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  padding: ${tokens.spacingLg};
  padding-top: ${tokens.spacingXs};
  padding-bottom: ${tokens.spacingHuge};
  gap: ${tokens.spacingXs};
`;

export const PageTitle = styled.h1`
  color: #000;
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 3.2rem;
  letter-spacing: 0.75px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-left: 1rem;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  gap: ${tokens.spacingXs};
  justify-content: space-between;
  padding-right: ${tokens.spacingXxs};

  .new-vehicle-button {
    white-space: nowrap;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  padding: ${tokens.spacingXxs};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    .pagination-vehicles-container {
      display: flex;
      flex-direction: column;
      gap: ${tokens.spacingMd};
    }
  }
`;

export const TableAndPaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${tokens.neutralColorHighPure};
  border-bottom-left-radius: ${tokens.borderRadiusMd};
  border-bottom-right-radius: ${tokens.borderRadiusMd};
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: ${tokens.spacingXs};
  flex-wrap: wrap;

  .react-select-custom__control {
    min-width: unset;
  }

  > div {
    width: auto;
    min-width: unset;
  }

  > div:first-child {
    flex-grow: 1;
  }

  > div:not(:first-child) {
    flex-basis: 22rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-wrap: wrap;

    > div {
      flex-grow: 1;
      max-width: unset;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: column nowrap;

    > div:first-child {
      flex-grow: unset;
    }

    > div:not(:first-child) {
      flex-basis: unset;
    }

    > div:last-of-type {
      .react-select-custom__control,
      .react-select-custom__menu {
        max-width: unset;
      }
    }
  }
`;

export const TableOverflowContainer = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
    overflow-x: auto;

    > table {
      table-layout: auto;
    }
  }

  > table > thead > tr > th > div > svg {
    flex-shrink: 0;
  }

  tr td,
  tr th {
    padding: ${tokens.spacingMd} ${tokens.spacingXs};
  }
`;

export const StyledTableThead = styled(DefaultTable.Thead)`
  tr th {
    &:first-of-type {
      border-top-left-radius: ${tokens.borderRadiusMd};
    }

    &:last-of-type {
      border-top-right-radius: ${tokens.borderRadiusMd};
    }
  }
`;

export const StyledTableTbody = styled(DefaultTable.Tbody)`
  tr:last-child td {
    &:first-of-type {
      border-bottom-left-radius: ${tokens.borderRadiusMd};
    }

    &:last-of-type {
      border-bottom-right-radius: ${tokens.borderRadiusMd};
    }
  }

  > tr:hover {
    cursor: pointer;
  }
`;

export const TableNoCententContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${tokens.spacingLg};
  height: 50rem;
  margin: 0 auto;

  > div {
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacingXs};
  }
`;

export const TableNoCententTitle = styled.h3`
  color: ${tokens.neutralColorLowMedium};
  text-align: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXs};
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
`;

export const TableNoCententDescription = styled.span`
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  text-align: center;
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
`;
