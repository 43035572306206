import { Outlet } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useAuthContext } from 'data/contexts';

import { SplashScreen } from 'presentation/components/global/SplashScreen';
import { FallbackPage } from 'presentation/pages/global';

import { useAuthenticationMiddleware } from './useAuthenticationMiddleware';

import { type IAuthenticationMiddlewareProps } from './AuthenticationMiddleware.types';

export function AuthenticationMiddleware({
  isPublic = false
}: IAuthenticationMiddlewareProps): JSX.Element {
  const [isErrorOnAuthenticate, company, user] = useAuthContext(
    useShallow(state => [
      state.isErrorOnAuthenticate,
      state.company,
      state.user
    ])
  );

  const { isLoading } = useAuthenticationMiddleware({ isPublic });

  if (isPublic) {
    return <Outlet />;
  }

  if (isErrorOnAuthenticate) {
    return <FallbackPage error='DEFAULT' />;
  }

  if (isLoading || !company || !user) {
    return <SplashScreen />;
  }

  return <Outlet />;
}
