import { tokens } from 'ds';
import styled from 'styled-components';

import { SectionContainer } from './components/SectionContainer';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1rem;
  padding-bottom: 0;
  position: relative;
  min-height: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 1.6rem;
    padding-bottom: 0;
  }

  @media screen and (min-width: 1367px) {
    padding: 1.6rem ${tokens.spacingInsetLg};
    padding-bottom: 0;
  }
`;

export const CardsInfoOverviewContainer = styled(SectionContainer)`
  width: 100%;
`;

export const TableContainer = styled(SectionContainer)`
  margin-bottom: 3.6rem;
`;
