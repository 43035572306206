import { faker } from '@faker-js/faker';

import {
  type IsReversalType,
  type ITransaction
} from 'data/modules/cards/statement';
import { type CreditDebitType } from 'data/modules/global';

import { MockApi } from 'shared/utils/global';

export async function getTransactionsDetailsListByIdsMock(): Promise<
  ITransaction[]
> {
  const data = [
    {
      name: 'Roberta Cardoso',
      debitCredit: 'DEBIT' as CreditDebitType,
      type: 'TRANSFER_DEBIT',
      paymentDescription: 'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #71522C6A',
      datetime: '2024-05-18T14:47:01.793000Z',
      transactionId: '312576FBC58482795C1A27715647E298',
      finalBillingAmount: -2.03,
      finalBillingCurrency: '986',
      finalBillingCurrencyCode: 'BRL',
      billingAmountAuthorized: -2.03,
      amount: -2.03,
      currency: '986',
      currencyCode: 'BRL',
      reversalAmount: 0,
      cardNumber: null,
      merchantName: null,
      merchantCity: null,
      mcc: null,
      isReversal: 'NOT' as IsReversalType,
      reversed: false,
      visible: true,
      isInternational: false,
      relateds: [],
      transferorName: null,
      userId: faker.string.uuid(),
      originalTransactionId: faker.string.uuid()
    },
    {
      name: 'Roberta Cardoso',
      debitCredit: 'DEBIT' as CreditDebitType,
      type: 'GOODS_PURCHASE',
      paymentDescription: '',
      datetime: '2024-05-18T14:46:43.484000Z',
      transactionId: '71522C6AC3DF4D43F697309F76CCB3FC',
      finalBillingAmount: -48.1,
      finalBillingCurrency: '986',
      finalBillingCurrencyCode: 'BRL',
      billingAmountAuthorized: -46.25,
      amount: -9,
      currency: '840',
      currencyCode: 'USD',
      reversalAmount: 0,
      cardNumber: '7154',
      merchantName: 'WWW.MAKE.COM',
      merchantCity: '+12128470692',
      mcc: 5734,
      isReversal: 'NOT' as IsReversalType,
      reversed: false,
      visible: true,
      isInternational: true,
      relateds: [
        {
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'TRANSFER_DEBIT',
          paymentDescription:
            'TX INT - VEXPENSES S.A. - VEXPENSES S.A. - #71522C6A',
          datetime: '2024-05-18T14:47:01.882000Z',
          transactionId: '5FCCD6A94DF10F0985CFC84D31E62F88',
          finalBillingAmount: 0,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -1.85,
          amount: -1.85,
          currency: '986',
          currencyCode: 'BRL',
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: false,
          isInternational: false,
          userId: faker.string.uuid(),
          originalTransactionId: faker.string.uuid(),
          relateds: [],
          reversalAmount: 0,
          transferorName: '',
          name: ''
        },
        {
          debitCredit: 'DEBIT' as CreditDebitType,
          type: 'TRANSFER_DEBIT',
          paymentDescription:
            'IOF - VEXPENSES S.A. - VEXPENSES S.A. - #71522C6A',
          datetime: '2024-05-18T14:47:01.793000Z',
          transactionId: '312576FBC58482795C1A27715647E298',
          finalBillingAmount: -2.03,
          finalBillingCurrency: '986',
          finalBillingCurrencyCode: 'BRL',
          billingAmountAuthorized: -2.03,
          amount: -2.03,
          currency: '986',
          currencyCode: 'BRL',
          cardNumber: null,
          merchantName: null,
          merchantCity: null,
          mcc: null,
          isReversal: 'NOT' as IsReversalType,
          reversed: false,
          visible: true,
          isInternational: false,
          userId: faker.string.uuid(),
          originalTransactionId: faker.string.uuid(),
          relateds: [],
          reversalAmount: 0,
          transferorName: '',
          name: ''
        }
      ],
      transferorName: null,
      userId: faker.string.uuid(),
      originalTransactionId: faker.string.uuid()
    }
  ];

  await MockApi.sleep(2000);

  return data;
}
