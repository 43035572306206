import { type AxiosError } from 'axios';

import { useMutationCache } from 'data/cache';
import { type IDefaultResponse } from 'data/modules/global';
import {
  type ILinkExpensesToReportPayload,
  type IUseLinkExpensesToReport,
  type IUseLinkExpensesToReportParams,
  ReportsMutationKeys,
  ReportsService
} from 'data/modules/reports';

export function useLinkExpensesToReport(
  params: IUseLinkExpensesToReportParams
): IUseLinkExpensesToReport {
  const { mutate: linkExpensesToReport, isLoading: isLinkingExpensesToReport } =
    useMutationCache({
      mutationKey: [ReportsMutationKeys.LINK_EXPENSE_TO_REPORT],
      mutationFn: async (payload: ILinkExpensesToReportPayload) => {
        await ReportsService.linkExpensesToReport(payload);
      },
      onError: data => {
        const error = data as AxiosError<IDefaultResponse<null>>;

        params.onError?.(error.response?.data.errors);
      },
      onSuccess: () => {
        params.onSuccess?.();
      }
    });

  return {
    linkExpensesToReport,
    isLinkingExpensesToReport
  };
}
