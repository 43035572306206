import { useLangContext } from 'data/contexts';

import {
  OnboardingFooter,
  OnboardingText,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { usePixKeyCreation } from './usePixKeyCreation';

import { Container } from './PixKeyCreation.styles';

export function PixKeyCreation(): JSX.Element {
  const [pixKeyCreation, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.company.pix_key_creation,
    state.currentLangKey
  ]);

  const { handleUpdatePixKey } = usePixKeyCreation();

  return (
    <Container>
      <OnboardingTitle>{pixKeyCreation.title[currentLangKey]}</OnboardingTitle>
      <OnboardingText>{pixKeyCreation.first[currentLangKey]}</OnboardingText>

      <OnboardingText>{pixKeyCreation.second[currentLangKey]}</OnboardingText>

      <OnboardingText>{pixKeyCreation.third[currentLangKey]}</OnboardingText>
      <OnboardingFooter
        backButtonProps={{
          text: pixKeyCreation.footer.back[currentLangKey],
          onClick: () => {
            handleUpdatePixKey(false);
          }
        }}
        continueButtonProps={{
          text: pixKeyCreation.footer.continue[currentLangKey],
          onClick: () => {
            handleUpdatePixKey(true);
          }
        }}
      />
    </Container>
  );
}
