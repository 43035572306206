import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef
} from 'react';

import { animations } from 'ds/constants';
import { AnimatePresence } from 'framer-motion';
import { usePopperTooltip } from 'react-popper-tooltip';

import { type ITooltipProps } from './Tooltip.types';

import { Arrow, Container } from './Tooltip.styles';

export function Tooltip({
  children,
  maxHeight = 50,
  maxWidth = 35.5,
  withArrow = true,
  width,
  message,
  zIndex = 0,
  containerClassName,
  visible: forceVisibleState,
  ...rest
}: ITooltipProps): JSX.Element {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible
  } = usePopperTooltip({
    ...rest,
    offset: withArrow ? [0, 12] : [0, 6],
    visible: forceVisibleState
  });

  const triggerElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setTriggerRef(triggerElementRef.current);
  }, [triggerElementRef, setTriggerRef]);

  return (
    <>
      {visible && (
        <AnimatePresence>
          <Container
            className={containerClassName}
            data-testid='container'
            ref={setTooltipRef}
            $maxHeight={maxHeight}
            $maxWidth={maxWidth}
            $width={width}
            $zIndex={zIndex}
            {...animations.fadeInOut}
            {...getTooltipProps()}
          >
            <span>{message}</span>

            {withArrow && (
              <Arrow
                data-testid='arrow'
                {...getArrowProps()}
              />
            )}
          </Container>
        </AnimatePresence>
      )}

      {Children.map(children, element => {
        if (!isValidElement<HTMLElement>(element)) {
          return element;
        }

        return cloneElement(element, {
          ...{
            ...element.props,
            ref: triggerElementRef
          }
        });
      })}
    </>
  );
}
