import { type BaseSyntheticEvent } from 'react';

import { type UseMutateFunction } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import {
  type Control,
  type FieldErrors,
  type UseFormHandleSubmit,
  type UseFormSetValue,
  type UseFormTrigger,
  type UseFormWatch
} from 'react-hook-form';

import { type ILegalNatureOption } from 'data/modules/auth';
import {
  type ICnae,
  type IPaginatedResponse,
  type IPaginatePayload,
  type IPersistencePaginatePayload
} from 'data/modules/global';

export interface IUpdatePartnerOrRepresentativeFieldsForm {
  option: string;
  document?: FileList | string;
}

export interface IUseUpdatePartnerOrRepresentativeParams {
  onUpdateCompanyOnboardingAnswersMutationSuccess: (
    data: IUpdateCompanyOnboardingAnswersMutationSuccessParams
  ) => void;
}

export interface IUseUpdatePartnerOrRepresentative {
  isDirty: boolean;
  isUpdatingPartnerOrRepresentative: boolean;
  handleUpdatePartnerOrRepresentative: () => void;
  control: Control<IUpdatePartnerOrRepresentativeFieldsForm>;
  errors: FieldErrors<IUpdatePartnerOrRepresentativeFieldsForm>;
}

export interface IUpdateCompanyOnboardingAnswersMutationSuccessParams
  extends ILegalRepresentative {}

export interface IUpdateCompanyAddressFieldsForm {
  postalCode: string | undefined;
  street: string | undefined;
  number: string | undefined;
  complement: string | undefined;
  neighborhood: string | undefined;
  country: string | undefined;
  city: string | undefined;
  state: string | undefined;
}

export interface IUseUpdateCompanyAddressParams {
  onUpdateCompanyOnboardingAnswersMutationSuccess: (
    enteredUserData: IUpdateCompanyAddressFieldsForm
  ) => void;
}

export interface IUseUpdateCompanyAddress {
  handleUpdateCompanyAddress: () => void;
  control: Control<IUpdateCompanyAddressFieldsForm>;
  errors: FieldErrors<IUpdateCompanyAddressFieldsForm>;
  isDirty: boolean;
  setValue: UseFormSetValue<IUpdateCompanyAddressFieldsForm>;
  watch: UseFormWatch<IUpdateCompanyAddressFieldsForm>;
}

export interface IUpdateCardsFunctionsSchemaFields {
  useWithdraw?: boolean;
  useInternationalTransaction?: boolean;
}

export interface IUseUpdateCardsFunctions {
  isDirty: boolean;
  isUpdatingCardsFunctions: boolean;
  handleUpdateCardsFunctions: () => void;
  control: Control<IUpdateCardsFunctionsSchemaFields>;
  errors: FieldErrors<IUpdateCardsFunctionsSchemaFields>;
}

export interface IUseUpdateCardsFunctionsParams {
  onUpdateCompanyOnboardingAnswersMutationSuccess: (
    data: IUpdateCardsFunctionsSchemaFields
  ) => void;
}

export interface IPersistenceCompanyOnboardingProgress {
  onboarding_company_id: string;
  company_id: string;
  current_step: number;
  answerable_user_id: string;
  created_at: string;
  updated_at: string;
}

type PersistenceShareholderType =
  | 'partner'
  | 'proxyholder'
  | 'legal_representative'
  | 'other';

export interface IGetAnswerableUserPayload {
  uuidCompanyOnboarding: string;
}

export interface IUpdateAnswerableUserPayload {
  uuidCompanyOnboarding: string;
  fullName: string;
  documentNumber: string;
  phoneNumber: string;
}

export interface IIncrementCompanyStepPayload {
  uuidCompanyOnboarding: string;
  step: number;
}

export interface IPersistenceUpdateAnswerableUserPayload {
  full_name: string;
  document_number: string;
  phone_number: string;
}

export interface IPersistenceAnswerableUser {
  name: string;
  email: string;
  member_legal_representative: boolean;
}

export interface IGetCompanyOnboardingAnswersPayload
  extends IGetAnswerableUserPayload {}

export interface IGetSerproInformationsPayload
  extends IGetAnswerableUserPayload {}

export interface IGetLegalRepresentativesPayload
  extends IGetAnswerableUserPayload {}

export interface ICompleteAccountStepsPayload
  extends IGetAnswerableUserPayload {}

export interface IPullSerproInformationsPayload
  extends IGetAnswerableUserPayload {
  cnpj: string;
}

export interface IUploadAuxiliaryDocumentPayload
  extends IGetAnswerableUserPayload {
  document: File;
}

export interface IAuxiliaryDocument {
  contract: string;
}

export interface IAddLegalRepresentativePayload
  extends IGetAnswerableUserPayload {
  name: string;
  email: string;
}

export interface IDeleteLegalRepresentativePayload
  extends IGetAnswerableUserPayload {
  uuid: string;
}

export interface ICompleteCompanyOnboardingPayload
  extends IGetAnswerableUserPayload {}

export interface IResendLegalRepresentativesApprovalSolicitationPayload
  extends IDeleteLegalRepresentativePayload {}

interface ICompanyOnboardingContext {
  usageEstimation: 'usage_estimation';
  companyAccount: 'company_account';
  cardsOrder: 'cards_order';
  filler: 'filler';
  configuration: 'configuration';
  companyTermsOfUse: 'company_terms_of_use';
  feedback: 'feedback';
  legalRepresentative: 'legal_representative';
}

type PersistenceCompanyOnboardingShareholderAddressType = Pick<
  IPersistenceCompanyAccount,
  | 'street'
  | 'number'
  | 'complement'
  | 'neighborhood'
  | 'postal_code'
  | 'city'
  | 'state'
  | 'country'
>;

type PersistenceOnboardingShareholderType = Pick<
  IPersistenceCompanyAccount,
  | 'trading_name'
  | 'founding_date'
  | 'email'
  | 'legal_name'
  | 'main_activity'
  | 'business_type'
  | 'phone_number'
  | 'contract'
> & {
  shareholder_type?: PersistenceShareholderType;
  type?: 'BUSINESS' | 'INDIVIDUAL';
  name?: string;
  document?: string;
  mother_name?: string;
  birth_date?: string;
  addresses?: PersistenceCompanyOnboardingShareholderAddressType[];
};

interface IPersistenceCompanyAccount {
  trading_name?: string;
  country?: string;
  founding_date?: string;
  email?: string;
  number?: number;
  street?: string;
  state?: string;
  legal_name?: string;
  main_activity?: string;
  city?: string;
  postal_code?: string;
  business_type?: string;
  document_number?: string;
  phone_number?: string;
  contract?: string;
  shareholders?: PersistenceOnboardingShareholderType[];
  complement?: string;
  neighborhood?: string;
}

interface IPersistenceUsageEstimation {
  maximum_transaction_value_estimate?: number;
  most_transaction_types?: string;
  average_montly_spend?: {
    min: number;
    max: number;
  };
  dont_know_answer_average_spend?: boolean;
  transactions_over_fifteen_thousand?: boolean;
  cards_amount?: number;
}

interface IPersistenceConfiguration {
  use_international_transaction?: boolean;
  use_pix?: boolean;
  use_withdraw?: boolean;
  users_to_use_cards?: {
    name: string;
    user_id: string;
    company_id: string;
    must_have_account: boolean;
    type: UserToUseCardsType;
  }[];
}

interface IPersistenceFiller {
  email?: string;
  document?: string;
  answerable?: string;
  phone_number?: string;
}

interface IPersistenceCardsOrder {
  cards_order: {
    receiver?: string;
    postal_code?: string;
    street?: string;
    number?: string;
    city?: string;
    country?: string;
    neighborhood?: string;
    state?: string;
    complement?: string;
    phone_number?: string;
    quantity?: string;
  } | null;
}

interface IPersistenceCompanyTermsOfUse {
  accept_privacy_policy?: boolean;
  accept_terms_of_use?: boolean;
}

interface IPersistenceFeedback {
  feedback_note?: number;
  feedback_comment?: string;
}

interface IPersistenceLegalRepresentative {
  legal_procuration?: string;
  member_legal_representative?: boolean;
}

interface IPersistenceUsageEstimationAnswers {
  context: ICompanyOnboardingContext['usageEstimation'];
  answers: IPersistenceUsageEstimation;
}

interface IPersistenceCompanyAccountAnswers {
  context: ICompanyOnboardingContext['companyAccount'];
  answers: IPersistenceCompanyAccount;
}

interface IPersistenceCardsOrderAnswers {
  context: ICompanyOnboardingContext['cardsOrder'];
  answers: IPersistenceCardsOrder;
}

interface IPersistenceFillerAnswers {
  context: ICompanyOnboardingContext['filler'];
  answers: IPersistenceFiller;
}

interface IPersistenceConfigurationAnswers {
  context: ICompanyOnboardingContext['configuration'];
  answers: IPersistenceConfiguration;
}

interface IPersistenceCompanyTermsOfUseAnswers {
  context: ICompanyOnboardingContext['companyTermsOfUse'];
  answers: IPersistenceCompanyTermsOfUse;
}

interface IPersistenceFeedbackAnswers {
  context: ICompanyOnboardingContext['feedback'];
  answers: IPersistenceFeedback;
}

interface IPersistenceLegalRepresentativeAnswers {
  context: ICompanyOnboardingContext['legalRepresentative'];
  answers: IPersistenceLegalRepresentative;
}

interface IUsageEstimationAnswers {
  context: ICompanyOnboardingContext['usageEstimation'];
  answers: IUsageEstimation;
}

interface ICompanyAccountAnswers {
  context: ICompanyOnboardingContext['companyAccount'];
  answers: ICompanyAccount;
}

interface ICardsOrderAnswers {
  context: ICompanyOnboardingContext['cardsOrder'];
  answers: ICardsOrder;
}

interface IFillerAnswers {
  context: ICompanyOnboardingContext['filler'];
  answers: IFiller;
}

interface IConfigurationAnswers {
  context: ICompanyOnboardingContext['configuration'];
  answers: IConfiguration;
}

interface ICompanyTermsOfUseAnswers {
  context: ICompanyOnboardingContext['companyTermsOfUse'];
  answers: ICompanyTermsOfUse;
}

interface IFeedbackAnswers {
  context: ICompanyOnboardingContext['feedback'];
  answers: IFeedback;
}

interface ILegalRepresentativeAnswers {
  context: ICompanyOnboardingContext['legalRepresentative'];
  answers: ILegalRepresentative;
}

export type PersistenceCompanyOnboardingAnswersType = (
  | IPersistenceUsageEstimationAnswers
  | IPersistenceCompanyAccountAnswers
  | IPersistenceCardsOrderAnswers
  | IPersistenceFillerAnswers
  | IPersistenceConfigurationAnswers
  | IPersistenceCompanyTermsOfUseAnswers
  | IPersistenceFeedbackAnswers
  | IPersistenceLegalRepresentativeAnswers
)[];

export type CompanyOnboardingAnswersType = (
  | IUsageEstimationAnswers
  | ICompanyAccountAnswers
  | ICardsOrderAnswers
  | IFillerAnswers
  | IConfigurationAnswers
  | ICompanyTermsOfUseAnswers
  | IFeedbackAnswers
  | ILegalRepresentativeAnswers
)[];

export type UpdateCompanyOnboardingAnswersPayloadType = Pick<
  IGetAnswerableUserPayload,
  'uuidCompanyOnboarding'
> & {
  step: number;
} & CompanyOnboardingAnswersType[number];

export type UpdateCompanyOnboardingAnswersType = Pick<
  UpdateCompanyOnboardingAnswersPayloadType,
  'step'
> &
  CompanyOnboardingAnswersType[number];

export type PersistenceUpdateCompanyOnboardingAnswersBodyType = Pick<
  UpdateCompanyOnboardingAnswersPayloadType,
  'step'
> &
  PersistenceCompanyOnboardingAnswersType[number];

export interface IPersistenceSerproInformations {
  cnpj: string;
  nome_fantasia: string;
  razao_social: string;
  natureza_juridica?: {
    codigo: string;
    descricao: string;
  };
  cnae?: string;
  email: string;
  data_abertura: string;
  telefone: string;
  capital_social?: number;
  situacao?: {
    codigo: string;
    data: string;
    motivo: string;
  };
  tipo?: string;
  atividade_principal: {
    descricao: string;
    codigo: string;
  };
  endereco: {
    uf: string;
    municipio: string;
    cep: string;
    bairro: string;
    logradouro: string;
    numero: string;
    complemento: string;
    socios?: {
      tipoSocio: string;
      cpf: string;
      nome: string;
      qualificacao: string;
      dataInclusao: string;
      pais: string;
      representanteLegal: {
        cpf: string;
        nome: string;
        qualificacao: string;
      };
    }[];
  };
}

export interface IUploadCompanyDocumentPayload
  extends IGetAnswerableUserPayload {
  document: File;
}

export interface IPersistenceCompanyDocument extends ICompanyDocument {}

export interface IPersistenceLegalRepresentatives {
  id: string;
  onboarding_company_id: string;
  name: string;
  email: string;
  is_authorized: null | boolean;
  email_sent_at: Date;
}

export type PersistenceMemberShareholderType = {
  birth_date: string;
  document: string;
  email: string;
  shareholder_type: PersistenceShareholderType;
  name: string;
  phone_number: string;
  type: 'INDIVIDUAL';
};

export type PersistenceLegalPersonShareholderType = Pick<
  PersistenceMemberShareholderType,
  'document' | 'email'
> & {
  business_type: string;
  contract: string;
  founding_date: string;
  legal_name: string;
  main_activity: string;
  phone_number: string;
  type: 'BUSINESS';
};

export type PersistenceCompanyOnboardingShareholderType =
  | PersistenceMemberShareholderType
  | PersistenceLegalPersonShareholderType;

export type OnboardingUserType = {
  uuid: string;
  companyUuid: string;
  name: string;
  type: UserToUseCardsType;
  mustHaveAccount: boolean;
};

type PersistenceOnboardingUserType = Pick<
  OnboardingUserType,
  'name' | 'type' | 'uuid'
> & {
  company_uuid: string;
  must_have_account: boolean;
};

interface IPersistenceCompanyOnboardingUsersFormCachePayload {
  domain: CompanyFormCacheDomains.onboardingUsers;
  value: PersistenceOnboardingUserType[];
  life_cycle: number;
}

interface IPersistenceCompanyOnboardingShareholdersFormCachePayload {
  domain: CompanyFormCacheDomains.onboardingShareholders;
  value: PersistenceOnboardingShareholderType[];
  life_cycle: number;
}

interface ICompanyOnboardingUsersFormCachePayload {
  domain: CompanyFormCacheDomains.onboardingUsers;
  value: OnboardingUserType[];
  lifeCycle: number;
}

interface ICompanyOnboardingShareholdersFormCachePayload {
  domain: CompanyFormCacheDomains.onboardingShareholders;
  value: OnboardingShareholderType[];
  lifeCycle: number;
}

export type UpdateCompanyFormCachePayloadType =
  | ICompanyOnboardingUsersFormCachePayload
  | ICompanyOnboardingShareholdersFormCachePayload;

export type PersistenceUpdateCompanyFormCacheBodyType =
  | IPersistenceCompanyOnboardingUsersFormCachePayload
  | IPersistenceCompanyOnboardingShareholdersFormCachePayload;

export interface IPersistenceCompanyOnboardingTeamMembers {
  company_id: number;
  company_uuid: string;
  created_at: string;
  email: string;
  id: number;
  name: string;
  updated_at: string;
  user_type: string;
  uuid: string;
}

export interface IGetCompanyOnboardingTeamMembersPayload
  extends IPaginatePayload {
  users?: string[];
}

export interface IPersistenceGetCompanyOnboardingTeamMembersPayload
  extends IPersistencePaginatePayload {
  users?: string[];
}

export interface IGetDataToRepresentativeApprovalPayload {
  hashCode: string;
}

export interface IGetLegalRepresentativeToApprovalPayload
  extends IGetDataToRepresentativeApprovalPayload {}

export interface IApproveLegalRepresentativePayload
  extends IGetDataToRepresentativeApprovalPayload {
  approve: boolean;
}

export interface IPersistenceDataToRepresentativeApproval {
  answerable_name: string;
  legal_representative_name: string;
}

export interface IPersistenceApproveLegalRepresentativePayload {
  hash_code: string;
  approve: boolean;
}

export interface IPersistenceCompanyOnboardingConfigurationUsersCache {
  company_uuid: string;
  must_have_account: boolean;
  name: string;
  type: 'ACCOUNT_MANAGER' | 'FINANCIAL_MANAGER' | 'USER';
  uuid: string;
}

export type KYCStatusType = 'REPROVED' | 'IN_ANALYSIS' | 'APPROVED';

export type ShareholderType =
  | 'partner'
  | 'proxyholder'
  | 'legalRepresentative'
  | 'other';

interface IOnboardingProgress {
  currentStep: number;
  createdAt: string;
  updatedAt: string;
}

export interface ICompanyOnboardingProgress extends IOnboardingProgress {
  onboardingCompanyId: string;
  companyId: string;
  answerableUserId: string;
}

export interface IAnswerableUser {
  name: string;
  email: string;
  memberLegalRepresentative: boolean;
}

type CompanyOnboardingShareholderAddressType = Pick<
  ICompanyAccount,
  | 'street'
  | 'number'
  | 'complement'
  | 'neighborhood'
  | 'postalCode'
  | 'city'
  | 'state'
  | 'country'
>;

export type OnboardingShareholderType = Pick<
  ICompanyAccount,
  | 'tradingName'
  | 'foundingDate'
  | 'email'
  | 'legalName'
  | 'mainActivity'
  | 'businessType'
  | 'phoneNumber'
  | 'contract'
> & {
  shareholderType?: ShareholderType;
  type?: 'BUSINESS' | 'INDIVIDUAL';
  name?: string;
  document?: string;
  motherName?: string;
  birthDate?: string;
  addresses?: CompanyOnboardingShareholderAddressType[];
};

export interface ICompanyAccount {
  tradingName?: string;
  country?: string;
  foundingDate?: string;
  email?: string;
  number?: string;
  street?: string;
  state?: string;
  legalName?: string;
  mainActivity?: string;
  city?: string;
  postalCode?: string;
  businessType?: string;
  documentNumber?: string;
  phoneNumber?: string;
  contract?: string;
  shareholders?: OnboardingShareholderType[];
  complement?: string;
  neighborhood?: string;
}

export interface IUsageEstimation {
  maximumTransactionValueEstimate?: number;
  mostTransactionTypes?: string;
  averageMontlySpend?: {
    min: number;
    max: number;
  };
  dontKnowAnswerAverageSpend?: boolean;
  transactionsOverFifteenThousand?: boolean;
  cardsAmount?: number;
}

export interface ICardsOrder {
  cardsOrder: {
    receiver?: string;
    postalCode?: string;
    street?: string;
    number?: string;
    city?: string;
    country?: string;
    neighborhood?: string;
    state?: string;
    complement?: string;
    phoneNumber?: string;
    quantity?: string;
  } | null;
}

export interface IFiller {
  email?: string;
  document?: string;
  answerable?: string;
  phoneNumber?: string;
}

export type UserToUseCardsType =
  | 'ACCOUNT_MANAGER'
  | 'FINANCIAL_MANAGER'
  | 'USER';

export interface IConfiguration {
  useInternationalTransaction?: boolean;
  usePix?: boolean;
  useWithdraw?: boolean;
  usersToUseCards?: {
    name: string;
    type: 'ACCOUNT_MANAGER' | 'FINANCIAL_MANAGER' | 'USER';
    mustHaveAccount: boolean;
    companyUuid: string;
    uuid: string;
  }[];
}

export interface ICompanyTermsOfUse {
  acceptPrivacyPolicy?: boolean;
  acceptTermsOfUse?: boolean;
}

export interface IFeedback {
  feedbackNote?: number;
  feedbackComment?: string;
}

export interface ILegalRepresentative {
  legalProcuration?: string;
  memberLegalRepresentative?: boolean;
}

export interface ICompanyOnboardingAnswers {
  usageEstimation?: IUsageEstimation;
  companyAccount?: ICompanyAccount;
  cardsOrder?: ICardsOrder;
  filler?: IFiller;
  configuration?: IConfiguration;
  companyTermsOfUse?: ICompanyTermsOfUse;
  feedback?: IFeedback;
  legalRepresentative?: ILegalRepresentative;
}

export interface ISerproInformations {
  cnpj?: string;
  tradingName?: string;
  corporateName?: string;
  legalNature?: {
    code?: string;
    description?: string;
  };
  cnae?: string;
  email?: string;
  foundationDate?: string;
  phone?: string;
  capitalStock?: number;
  situation?: {
    code?: string;
    date?: string;
    reason?: string;
  };
  type?: string;
  mainActivity?: {
    description?: string;
    code?: string;
  };
  address?: {
    state?: string;
    city?: string;
    postalCode?: string;
    neighborhood?: string;
    street?: string;
    number?: string;
    complement?: string;
    partners?: {
      partnerType?: string;
      cpf?: string;
      name?: string;
      qualification?: string;
      inclusionDate?: string;
      country?: string;
      legalRepresentative?: {
        cpf?: string;
        name?: string;
        qualification?: string;
      };
    }[];
  };
}

export interface ICompanyDocument {
  contract: string;
}

export interface ILegalRepresentatives {
  id: string;
  name: string;
  email: string;
  emailSentAt: Date;
  onboardingCompanyId: string;
  isAuthorized: null | boolean;
}

export type MemberShareholderType = {
  birthDate: string;
  document: string;
  email: string;
  shareholderType?: ShareholderType;
  name: string;
  phoneNumber: string;
  type: 'INDIVIDUAL';
};

export type LegalPersonShareholderType = Pick<
  MemberShareholderType,
  'document' | 'email'
> & {
  businessType: string;
  contract: string;
  foundingDate: string;
  legalName: string;
  mainActivity: string;
  phoneNumber: string;
  type: 'BUSINESS';
};

export type CompanyOnboardingShareholderType =
  | MemberShareholderType
  | LegalPersonShareholderType;

export interface ICompanyOnboardingConfigurationUsersCache {
  companyUuid: string;
  mustHaveAccount: boolean;
  name: string;
  type: 'ACCOUNT_MANAGER' | 'FINANCIAL_MANAGER' | 'USER';
  uuid: string;
}

export enum CompanyFormCacheDomains {
  onboardingShareholders = 'onboarding_company_shareholders',
  onboardingUsers = 'onboarding_configuration_users'
}
export interface ICompanyOnboardingTeamMembers {
  companyId: number;
  companyUuid: string;
  createdAt: string;
  email: string;
  id: number;
  name: string;
  updatedAt: string;
  userType: string;
  uuid: string;
}

export interface IDataToRepresentativeApproval {
  answerableName: string;
  legalRepresentativeName: string;
}

export interface IPullSerproInformations {
  cnpj: string;
}

export interface IUseAddLegalEntityPartnerParams {
  onAfterAddingLegalEntityPartner: (
    newPartnersList: Required<OnboardingShareholderType>[]
  ) => void;
}

export interface IUseAddLegalEntityPartner {
  handleAddLegalEntityPartner: (
    newLegalEntityPartnerData: OnboardingShareholderType
  ) => void;
}

export interface IAddLegalRepresentativosFieldsForm {
  legalRepresentatives:
    | {
        email: string;
        name: string;
      }[]
    | undefined;
}

export interface IUseAddLegalRepresentativesParams {
  onAddLegalRepresentativesMutationSuccess: () => void;
}

export interface IUseAddLegalRepresentatives {
  isAddingLegalRepresentatives: boolean;
  handleAddLegalRepresentatives: () => void;
  setValue: UseFormSetValue<IAddLegalRepresentativosFieldsForm>;
  control: Control<IAddLegalRepresentativosFieldsForm>;
  errors: FieldErrors<IAddLegalRepresentativosFieldsForm>;
}

export interface IAddMembersOrLegalRepresentativesFieldsForm {
  birthDate: string;
  document: string;
  email: string;
  name: string;
  phoneNumber: string;
}

export interface IUseAddMembersOrLegalRepresentatives {
  control: Control<IAddMembersOrLegalRepresentativesFieldsForm>;
  errors: FieldErrors<IAddMembersOrLegalRepresentativesFieldsForm>;
  handleAddMembersOrLegalRepresentatives: () => void;
  setValue: UseFormSetValue<IAddMembersOrLegalRepresentativesFieldsForm>;
}

export interface IUseAddMembersOrLegalRepresentativesParams {
  shareholderType: ShareholderType;
  onAfterAddingMembersOrLegalRepresentatives: (
    newData: Required<OnboardingShareholderType>[]
  ) => void;
}

export interface IUseApproveLegalRepresentative {
  isApprovingLegalRepresentative: boolean;
  approveLegalRepresentative: (approve: boolean) => void;
}

export interface IUseApproveLegalRepresentativeParams {
  hashCode: string;
  onApproveLegalRepresentativeMutationSuccess: (approve: boolean) => void;
}

export interface IUseCompleteAccountStepsParams {
  onCompleteAccountStepsMutationSuccess: () => void;
}

export interface IUseCompleteAccountSteps {
  isCompletingAccountSteps: boolean;
  handleCompleteAccountSteps: () => void;
}

export interface IUseCompleteCompanyOnboarding {
  isCompletingCompanyOnboarding: boolean;
  completeCompanyOnboarding: () => Promise<void>;
}

export interface IUseCompleteCompanyOnboardingParams {
  onCompleteCompanyOnboardingMutationSuccess: () => void;
}

export interface IUseCreatePixKeyParamas {
  onUpdatePixKeyMutationSuccess: () => void;
}

export interface IUseCreatePixKey {
  handleUpdatePixKey: (value: boolean) => void;
  isUpdatingCompanyPoliciesAndTerms: boolean;
}

export interface IUseDeleteLegalRepresentativeParams {
  onDeleteLegalRepresentativeMutationSuccess: (uuid: string) => void;
}

export interface IUseDeleteLegalRepresentative {
  isDeletingLegalRepresentative: boolean;
  handleDeleteLegalRepresentative: (uuid: string) => void;
}

export interface IUseDeletePartnerParams {
  selectedPartnerDocument?: string;
  onAfterDeletingPartner: (
    newPartnersList: CompanyOnboardingShareholderType[]
  ) => void;
}

export interface IUseDeletePartner {
  handleDeletePartner: () => Promise<void>;
  isDeletingPartner: boolean;
}

export interface IUseGetAnswerableUser {
  isLoadingAnswerableUser: boolean;
  answerableUser?: IAnswerableUser;
}

export interface IUseGetCompanyOnboardingAnswersParams {
  enabled: boolean;
}

export interface IUseGetCompanyOnboardingAnswers {
  isCompanyOnboardingAnswersLoading: boolean;
  companyOnboardingAnswers: ICompanyOnboardingAnswers;
}

export interface IUseGetCompanyOnboardingConfigurationUsersCache {
  companyOnboardingConfigurationUsersCache: ICompanyOnboardingConfigurationUsersCache[];
  isCompanyOnboardingConfigurationUsersCacheLoading: boolean;
}

export interface IUseGetCompanyOnboardingProgress {
  isLoadingCompanyProgress: boolean;
  companyProgress?: ICompanyOnboardingProgress;
  isSuccessCompanyOnboardingProgress: boolean;
  isErrorCompanyOnboardingProgress: boolean;
}

export interface IUseGetCompanyOnboardingProgressParams {
  enabled: boolean;
}

export interface IGetCompanyOnboardingShareholders {
  companyOnboardingShareholders: CompanyOnboardingShareholderType[];
  isCompanyOnboardingShareholdersLoading: boolean;
}

export interface IUseGetCompanyOnboardingTeamMembersParams {
  queryPayload: IGetCompanyOnboardingTeamMembersPayload;
}

export interface IUseGetCompanyOnboardingTeamMembers {
  paginatedCompanyOnboardingTeamMembers:
    | IPaginatedResponse<ICompanyOnboardingTeamMembers>
    | undefined;
  isPaginatedCompanyOnboardingTeamMembersLoading: boolean;
}

export interface IUseGetDataToRepresentativeApprovalParams
  extends IGetDataToRepresentativeApprovalPayload {
  enabled?: boolean;
}

export interface IUseGetDataToRepresentativeApproval {
  dataToRepresentativeApproval?: IDataToRepresentativeApproval;
  isLoadingDataToRepresentativeApproval: boolean;
  isErrorRepresentativeApproval: boolean;
  representativeApprovalError: AxiosError | null;
}

export interface IUseGetLegalRepresentativeToApprovalParams
  extends IGetLegalRepresentativeToApprovalPayload {
  enabled?: boolean;
}

export interface IUseGetLegalRepresentativeToApproval {
  representativeToApproval?: ILegalRepresentatives;
  isLoadingRepresentativeToApproval: boolean;
  isErrorRepresentativeToApproval: boolean;
  errorRepresentativeToApproval: AxiosError | null;
}

export interface IUseGetLegalRepresentatives {
  isLoadingRepresentatives: boolean;
  representatives?: ILegalRepresentatives[];
  isErrorLegalRepresentatives: boolean;
  errorLegalRepresentatives: AxiosError | null;
}

export interface IUseGetSerproInformationsParams {
  enabled: boolean;
}

export interface IUseGetSerproInformations {
  isLoadingSerproInformations: boolean;
  serproInformations?: ISerproInformations;
  isGetSerproInformationsSuccess: boolean;
  isErrorSerproInformations: boolean;
  errorSerproInformations: AxiosError | null;
}

export interface IUseIncrementCompanyStep {
  incrementStep: UseMutateFunction<
    null,
    Error,
    IIncrementCompanyStepPayload,
    unknown
  >;
  isIncrementingStep: boolean;
}

export interface IPullSerproInformationsFieldsForm
  extends Pick<IPullSerproInformationsPayload, 'cnpj'> {}

export interface IUsePullSerproInformations {
  isError: boolean;
  isPullingSerproInformations: boolean;
  control: Control<IPullSerproInformationsFieldsForm>;
  errors: FieldErrors<IPullSerproInformationsFieldsForm>;
  handlePullSerproInformations: (
    value: IPullSerproInformationsFieldsForm
  ) => void;
  setValue: UseFormSetValue<IPullSerproInformationsFieldsForm>;
}

export interface IUseResendLegalRepresentativesApprovalSolicitationParams {
  onResendLegalRepresentativesApprovalSolicitationMutationSuccess: (
    uuid: string
  ) => void;
}

export interface IUseResendLegalRepresentativesApprovalSolicitation {
  isResendingLegalRepresentativesApprovalSolicitation: boolean;
  handleResendLegalRepresentativesApprovalSolicitation: (uuid: string) => void;
}

export interface ISelectLegalEntityBusinessTypeFields {
  businessType: string;
  otherOption: string | undefined;
}

export interface IUseSelectLegalEntityNatureParams {
  defaultValue?: string;
}

export interface IUseSelectLegalEntityNature {
  control: Control<ISelectLegalEntityBusinessTypeFields>;
  errors: FieldErrors<ISelectLegalEntityBusinessTypeFields>;
  watch: UseFormWatch<ISelectLegalEntityBusinessTypeFields>;
  handleSubmit: UseFormHandleSubmit<ISelectLegalEntityBusinessTypeFields>;
  setValue: UseFormSetValue<ISelectLegalEntityBusinessTypeFields>;
}

export interface ISendOnboardingFeedbackSchemaFields {
  feedbackNote?: number;
  feedbackComment?: string;
}

export interface IUseSendOnboardingFeedback {
  isDirty: boolean;
  isSendingOnboardingFeedback: boolean;
  handleSendOnboardingFeedback: () => void;
  control: Control<ISendOnboardingFeedbackSchemaFields>;
  errors: FieldErrors<ISendOnboardingFeedbackSchemaFields>;
  setValue: UseFormSetValue<ISendOnboardingFeedbackSchemaFields>;
}

export interface IUseSendOnboardingFeedbackParams {
  onUpdateCompanyOnboardingAnswersMutationSuccess: (
    data: ISendOnboardingFeedbackSchemaFields
  ) => void;
}

export interface IUpdateAnswerableUserFieldsForm {
  fullName: string;
  documentNumber: string;
  phoneNumber: string;
}

export interface IUseUpdateAnswerableUser {
  control: Control<IUpdateAnswerableUserFieldsForm>;
  isUpdatingAnswerableUser: boolean;
  errors: FieldErrors<IUpdateAnswerableUserFieldsForm>;
  isError: boolean;
  handleUpdateAnswerableUser: (e: BaseSyntheticEvent) => void;
  setValue: UseFormSetValue<IUpdateAnswerableUserFieldsForm>;
}

export interface IUpdateCompanyDataFieldsForm {
  cnpj: string;
  legalName: string;
  foundingDate: string;
  mainActivity: ICnae | string;
  legalNature: ILegalNatureOption | string;
  email: string;
  phoneNumber: string;
  contract: NonNullable<string | FileList | undefined>;
}

export interface IUseUpdateCompanyDataParams {
  onUpdateCompanyOnboardingAnswersMutationSuccess: (
    enteredUserData: IUpdateCompanyDataFieldsForm
  ) => void;
}

export interface IUseUpdateCompanyData {
  handleUpdateCompanyData: () => void;
  isUpdatingCompanyData: boolean;
  control: Control<IUpdateCompanyDataFieldsForm>;
  errors: FieldErrors<IUpdateCompanyDataFieldsForm>;
  isDirty: boolean;
  setValue: UseFormSetValue<IUpdateCompanyDataFieldsForm>;
  watch: UseFormWatch<IUpdateCompanyDataFieldsForm>;
  isPullingSerproInformations: boolean;
}

export interface IUseUpdateCompanyOnboardingConfigurationUsersCacheParams {
  onAfterUpdatingCacheSuccessfully: (
    newUsersConfiguration: ICompanyOnboardingConfigurationUsersCache[]
  ) => void;
}

export interface IUseUpdateCompanyOnboardingConfigurationUsersCache {
  updateCompanyOnboardingConfigurationUsersCache: (
    newUsersConfiguration: OnboardingUserType[]
  ) => Promise<void>;
  isUpdatingCompanyOnboardingConfigurationUsersCache: boolean;
}

export interface IUseUpdateCompanyOnboardingMembersConfigurationParams {
  onAfterUpdatingMembersConfigurationSuccessfully: (
    newConfiguratedMembersData: IConfiguration['usersToUseCards']
  ) => void;
}

export interface IUseUpdateCompanyOnboardingMembersConfiguration {
  handleUpdateCompanyOnboardingMembersConfiguration: (
    configuratedMembersData: IConfiguration['usersToUseCards']
  ) => Promise<void>;
  isUpdatingCompanyOnboardingMembersConfiguration: boolean;
}

export interface IUpdateCompanyPoliciesAndTermsFieldsForm {
  acceptPrivacyPolicy: boolean | undefined;
  acceptTermsOfUse: boolean | undefined;
}

export interface IUseUpdateCompanyPoliciesAndTerms {
  handleUpdateCompanyPoliciesAndTerms: () => void;
  isUpdatingCompanyPoliciesAndTerms: boolean;
  control: Control<IUpdateCompanyPoliciesAndTermsFieldsForm>;
  isDirty: boolean;
  errors: FieldErrors<IUpdateCompanyPoliciesAndTermsFieldsForm>;
}

export interface IUseUpdateCompanyPoliciesAndTermsParams {
  onUpdateCompanyOnboardingAnswersMutationSuccess: (
    data: IUpdateCompanyPoliciesAndTermsFieldsForm
  ) => void;
}

export interface IUpdateLegalNationalRegisterFieldsForm {
  cnpj: string | undefined;
}

export interface IUseUpdateLegalNationalRegister {
  handleUpdateLegalNationalRegister: () => void;
  isUpdatingLegalNationalRegister: boolean;
  control: Control<IUpdateLegalNationalRegisterFieldsForm>;
  isDirty: boolean;
  errors: FieldErrors<IUpdateLegalNationalRegisterFieldsForm>;
  setValue: UseFormSetValue<IUpdateLegalNationalRegisterFieldsForm>;
}

export interface IUseUpdateLegalNationalRegisterParams {
  onUpdateCompanyOnboardingAnswersMutationSuccess: (
    data: IUpdateLegalNationalRegisterFieldsForm
  ) => void;
}

export interface IUpdateRequestCardsFieldsForm {
  receiver: string | undefined;
  postalCode: string | undefined;
  street: string | undefined;
  number: string | undefined;
  city: string | undefined;
  country: string | undefined;
  neighborhood: string | undefined;
  state: string | undefined;
  complement: string | undefined;
  phoneNumber: string | undefined;
  quantity: string | undefined;
}

export interface IUseUpdateRequestCards {
  handleUpdateRequestCards: () => void;
  handleCancelRequestCards: () => void;
  control: Control<IUpdateRequestCardsFieldsForm>;
  errors: FieldErrors<IUpdateRequestCardsFieldsForm>;
  setValue: UseFormSetValue<IUpdateRequestCardsFieldsForm>;
  watch: UseFormWatch<IUpdateRequestCardsFieldsForm>;
  trigger: UseFormTrigger<IUpdateRequestCardsFieldsForm>;
}

export interface IUseUpdateRequestCardsParams {
  onUpdateCompanyOnboardingAnswersMutationSuccess: (
    enteredUserData: IUpdateRequestCardsFieldsForm
  ) => void;
}

export interface IUseUpdateShareholderLegalNatureParams {
  onAfterUpdateShareholderLegalNature: (
    selectedLegalEntityNature: ICompanyAccount
  ) => void;
}

export interface IUseUpdateShareholderLegalNature {
  handleUpdateShareholderLegalNature: (
    selectedLegalEntityNature: ISelectLegalEntityBusinessTypeFields
  ) => void;
  defaultValue: string;
}

export interface IUseUpdateShareholdersParams {
  onUpdateCompanyOnboardingAnswersMutationSuccess: (
    answers: ICompanyAccount
  ) => void;
}

export interface IUseUpdateShareholders {
  isUpdatingShareholders: boolean;
  handleUpdateShareholders: (
    shareholders: CompanyOnboardingShareholderType[]
  ) => void;
}

export type LegalEntityPartnerDataSchemaFieldsType = {
  document: string;
  legalName: string;
  mainActivity: ICnae | string;
  businessType: ILegalNatureOption | string;
  foundingDate: string;
  email: string;
  phoneNumber: string;
  contract: string | FileList | undefined;
};

export interface IUseAddLegalEntityDocument {
  control: Control<LegalEntityPartnerDataSchemaFieldsType>;
  setValue: UseFormSetValue<LegalEntityPartnerDataSchemaFieldsType>;
  errors: FieldErrors<LegalEntityPartnerDataSchemaFieldsType>;
  handleForwardDataToNextStep: () => void;
}
