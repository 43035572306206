import { useLangContext } from 'data/contexts';
import { useChangeBusinessCardExpensePolicies } from 'data/modules/cards/businessCard';

import { Select } from 'presentation/components/base/Select';
import { Loader } from 'presentation/components/global/Loader';

import { useExpensePoliciesManageBusinessCard } from './useExpensePoliciesManageBusinessCard';

import { type IExpensePoliciesManageBusinessCardProps } from './useExpensePoliciesManageBusinessCard.types';

import {
  Container,
  CustomButton,
  FormContainer
} from './ExpensePoliciesManageBusinessCard.styles';

export function ExpensePoliciesManageBusinessCard({
  card
}: IExpensePoliciesManageBusinessCardProps): JSX.Element {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const {
    expensePoliciesOptions,
    routePoliciesOptions,
    isLoadingExpensePolicies,
    isLoadingRoutePolicies
  } = useExpensePoliciesManageBusinessCard();

  const { control, errors, handleSubmit, isLoadingUseExpensePolicies } =
    useChangeBusinessCardExpensePolicies({ card });

  return (
    <Container>
      <Loader isLoading={isLoadingUseExpensePolicies} />
      <FormContainer>
        <Select
          name='expensePolicyId'
          options={expensePoliciesOptions}
          label={
            lang.settings.manageBusinessCard.expense_limit_policy[
              currentLangKey
            ]
          }
          placeholder={
            lang.settings.manageBusinessCard.select_user_policy[currentLangKey]
          }
          isLoading={isLoadingExpensePolicies}
          control={control}
          error={errors.expensePolicyId?.message}
        />

        <Select
          name='routePolicyId'
          options={routePoliciesOptions}
          label={
            lang.settings.manageBusinessCard.route_limits_policy[currentLangKey]
          }
          placeholder={
            lang.settings.manageBusinessCard.select_user_policy[currentLangKey]
          }
          isLoading={isLoadingRoutePolicies}
          control={control}
          error={errors.routePolicyId?.message}
        />

        <CustomButton
          color='success'
          onClick={handleSubmit}
        >
          {lang.global.save[currentLangKey]}
        </CustomButton>
      </FormContainer>
    </Container>
  );
}
