import { Title } from 'presentation/components/global/Title';

import {
  Container,
  Footer,
  Paragraph,
  SectionTitle
} from './TermsOfUse.styles';

export function TermsOfUse(): JSX.Element {
  return (
    <>
      <Title text='Termos de Uso' />

      <Container>
        <section>
          <SectionTitle $align='center'>TERMOS DE USO</SectionTitle>

          <Paragraph>
            <strong> VEXPENSES S.A.</strong>, pessoa jurídica de direito
            privado, inscrita no CNPJ sob n. 07.784.024/0001-28, responsável
            pela emissão e gestão dos cartões pré-pagos, denominado VEXPENSES ,
            com sede na Av. Wladimir Meirelles Ferreira, 1660, 19º Andar, CEP
            14.021-630, Ribeirão Preto, São Paulo- Brasil, vem apresentar os
            Termos e Condições Gerais de Uso do Cartão VExpenses, a seguir
            especificadas. Ao adquirir o cartão, o cliente concorda com todos os
            Termos e Condições deste instrumento. Ao utilizar o Cartão, o
            Cliente fica ciente e concorda com todas as disposições deste Termo,
            declarando para todos os fins de Direito, possuir capacidade
            jurídica para tanto.
          </Paragraph>

          <Paragraph>
            Este documento poderá ser modificado a qualquer momento e sua versão
            atualizada estará disponível no site{' '}
            <a
              href='http://www.vexpenses.com.br/'
              target='_blank'
              rel='noreferrer'
            >
              www.vexpenses.com.br
            </a>
          </Paragraph>
        </section>

        <section>
          <SectionTitle>1. DEFINIÇÕES</SectionTitle>

          <Paragraph>
            Os termos e expressões definidos no presente <strong> TERMO</strong>
            , sejam eles empregados no singular ou plural, na forma masculina ou
            feminina, deverão ser interpretados em conformidade com os
            significados a eles atribuídos abaixo:
          </Paragraph>

          <div>
            <Paragraph>
              <u>
                <strong>Bandeira:</strong>
              </u>{' '}
              Mastercard Brasil Soluções de Pagamento Ltda.
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Cartão:</strong>
              </u>{' '}
              Instrumento de pagamento do tipo pré-pago, oferecido pela
              <strong> VEXPENSES </strong> ao <strong> CLIENTE </strong>por meio
              de Parceiro, para realização das Transações pelos Portadores.
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Cartão Ativado:</strong>
              </u>{' '}
              Cartão desbloqueado pelo Portador e apto à realização de
              Transações.
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Cartão Bloqueado:</strong>
              </u>{' '}
              Cartão ativado que foi temporariamente bloqueado pelo
              <strong> CLIENTE </strong> ou pela <strong> VEXPENSES</strong>,
              impedindo-se a realização de Transações.
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Cartão Cancelado:</strong>
              </u>{' '}
              Cartão inativado pelo <strong> CLIENTE </strong> ou pela
              <strong> VEXPENSES</strong>, sendo definitivamente impedido seu
              uso para Transações, não podendo mais ser ativado.
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Conta Cliente</strong>
              </u>
              : Conta de pagamento do tipo pré-paga, detida exclusivamente em
              nome do <strong> CLIENTE</strong>, na qual serão aportados por ele
              os valores destinados ao aporte nos Cartões.
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Conta de Pagamento</strong>
              </u>
              : Conta de Pagamento do tipo pré-paga, detida em nome do Portador
              ou do Cliente, conforme o caso, vinculada a um Cartão, na qual
              serão registradas todas as Transações de Pagamento realizadas com
              o Cartão.
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Informações Confidenciais:</strong>
              </u>{' '}
              Definido na cláusula 12 deste <strong> TERMO</strong>.
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Módulo de Controle de Cartões: </strong>
              </u>
              Módulo adicional ao fornecimento da Plataforma que permite ao{' '}
              <strong> CLIENTE </strong> o controle dos seus Cartões, bem como
              solicitações de saldos, extratos e conciliação das despesas
              realizadas com o Cartão.
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Parceiros:</strong>
              </u>{' '}
              Empresas terceiras que, por meio da celebração de contrato com a
              <strong> VEXPENSES</strong>, participam da prestação dos serviços
              de maneira direta ou indireta.
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Plataforma:</strong>
              </u>
              <em> SaaS </em>desenvolvido pela <strong> VEXPENSES </strong>
              que permite que o <strong> CLIENTE </strong>realize o controle do
              seus Processos de Gestão de Despesas
              <strong>.</strong>
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Portador:</strong>
              </u>{' '}
              Pessoa física ou jurídica nomeada pelo <strong> CLIENTE</strong>,
              responsável pela utilização do Cartão como meio de pagamento das
              despesas de viagens corporativas.
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Processos de Gestão de Despesas:</strong>
              </u>{' '}
              Definido no primeiro considerando deste
              <strong> TERMO</strong>.
            </Paragraph>

            <Paragraph>
              <u>
                <strong>SaaS</strong>
              </u>
              : Definido no primeiro considerando deste <strong> TERMO </strong>
              .
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Saque</strong>
              </u>
              : Serviço que possibilita a retirada de recursos previamente
              aportados na Conta de Pagamento atrelada a um determinado Cartão,
              pelo Portador, no país ou no exterior, em terminais, conforme
              diretrizes estabelecidas na cláusula 5 deste{' '}
              <strong> TERMO</strong>.
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Transação: </strong>
              </u>
              Toda e qualquer operação relativa a aquisições de bens e/ou
              serviços ou retirada de recursos da Conta de Pagamento, realizada
              pelos Portadores com os Cartões.
            </Paragraph>

            <Paragraph>
              <u>
                <strong>Valor por Cartão Ativado: </strong>
              </u>
              Todo e qualquer Cartão ativado no período de apuração, ainda que
              na Data de Fechamento determinado(s) Cartão(ões) esteja com o
              status de Cartão Bloqueado.
            </Paragraph>
          </div>
        </section>

        <section>
          <SectionTitle>2. OBJETO </SectionTitle>

          <Paragraph>
            O presente <strong> TERMO </strong> tem por objeto o estabelecimento
            das condições pela
            <strong> VEXPENSES </strong> ao <strong> CLIENTE</strong>, dos
            serviços de gestão de despesas, que consistem em possibilitar ao{' '}
            <strong> CLIENTE </strong> a centralização e administração de todas
            as etapas relativas a seus Processos de Gestão de Despesas, bem como
            a possibilidade de oferta, ao <strong> CLIENTE</strong>, de Cartões
            destinados ao pagamento, pelos Portadores, de gastos e/ou realização
            de Saque em viagens corporativas.
          </Paragraph>
        </section>

        <section>
          <SectionTitle>3. UTILIZAÇÃO DO CARTÃO</SectionTitle>

          <Paragraph>
            O Cartão poderá ser utilizado para a realização de Transações
            relativas à prestação de serviço corporativa a que se destina, como
            gastos com viagem e manutenção pessoal do Portador, aquisição de
            bens e/ou serviços e retirada de recursos mediante o serviço de
            Saque, nos estritos limites de política de utilização do Cartão
            celebrada entre o <strong> CLIENTE </strong> e o Portador,
            observadas as disposições do presente <strong> TERMO </strong> e a
            legislação aplicável.
          </Paragraph>

          <Paragraph>
            No caso de transação ou contratação de produtos e/ou serviços pela
            internet, canais eletrônicos ou qualquer outro meio de contratação à
            distância, o fornecimento da senha ou do código de validação do
            Cartão caracteriza a concordância do <strong> CLIENTE </strong> com
            a operação e/ou contratação.
          </Paragraph>

          <Paragraph>
            No caso de compras realizadas pela internet ou telefone, poderá ser
            necessária a confirmação da transação por outro dispositivo de
            segurança.
          </Paragraph>

          <Paragraph>
            É de responsabilidade do <strong> CLIENTE </strong> informar ao
            Portador que este não poderá, na utilização do Cartão, realizar
            Transações:
          </Paragraph>

          <ol type='a'>
            <li>
              <Paragraph>
                Cujo objeto envolva bens e/ou serviços proibidos pela legislação
                vigente e/ou atentatórios à moral e aos bons costumes;
              </Paragraph>
            </li>

            <li>
              <Paragraph>
                Que envolvam atividades ilícitas ou que representem infração a
                leis ou TERMOs vigentes no Brasil ou nos demais países em que
                utilizado o Cartão;
              </Paragraph>
            </li>

            <li>
              <Paragraph>
                Que o Portador saiba ou deva saber estar impedido de realizar;
                ou
              </Paragraph>
            </li>

            <li>
              <Paragraph>Que constituam fraude ou simulação.</Paragraph>
            </li>
          </ol>

          <Paragraph>Competirá aos Portadores:</Paragraph>
          <ol type='a'>
            <li>
              <Paragraph>
                Definir a senha pessoal e intransferível do Cartão, através do
                QR Code disponibilizado para tanto
              </Paragraph>
            </li>

            <li>
              <Paragraph>
                Realizar o processo de desbloqueio do Cartão perante o parceiro
                emissor.
              </Paragraph>
            </li>
          </ol>
        </section>

        <section>
          <SectionTitle>4. USO INTERNACIONAL</SectionTitle>

          <Paragraph>
            O Cartão poderá ser utilizado no exterior para a realização de
            Transações conforme as diretrizes estabelecidas neste{' '}
            <strong> TERMO</strong>.
          </Paragraph>

          <Paragraph>
            Na hipótese de realização de Transações em moeda estrangeira, caberá
            à <strong> VEXPENSES </strong> a conversão dos valores, observadas
            as regras cambiais aplicáveis à operação. Na hipótese de operações
            realizadas no âmbito internacional em moeda distinta do dólar
            norte-americano, os valores em reais serão primeiramente convertidos
            em dólar norte-americano e, posteriormente, em moeda corrente
            nacional.
          </Paragraph>

          <Paragraph>
            Os impostos e demais encargos decorrentes da remessa de moeda ao
            exterior necessária para o pagamento de Transações realizadas com o
            Cartão serão de responsabilidade exclusiva do{' '}
            <strong> CLIENTE</strong>, e, portanto, serão repassados pela{' '}
            <strong> VEXPENSES </strong>ao <strong> CLIENTE </strong> por meio
            de débito direto no saldo do Cartão utilizado para operação
            internacional, isentando a<strong> VEXPENSES </strong> de quaisquer
            responsabilidades incidentes em razão da cobrança de tais tributos e
            encargos. Na hipótese de restrição que impeça momentaneamente a
            remessa de moeda ao exterior, o <strong> CLIENTE </strong> será
            responsável pelo valor da eventual variação cambial da remessa,
            quando autorizada. Na hipótese de o Cartão não ter saldo suficiente
            para o débito dos tributos e encargos, estes poderão ser descontados
            futuramente do Cartão ou diretamente da Conta Cliente.
          </Paragraph>

          <Paragraph>
            Além dos tributos incidentes nas operações internacionais, o{' '}
            <strong> CLIENTE </strong> declara a ciência da possibilidade de
            cobrança de tarifas pela <strong> VEXPENSES </strong> para cobrir os
            custos das transações internacionais, disponíveis para consulta no
            endereço eletrônico{' '}
            <a
              href='www.vexpenses.com.br'
              target='_blank'
            >
              www.vexpenses.com.br
            </a>
            , reservando-se a <strong> VEXPENSES </strong> o direito de alterar
            tais tarifas, mediante atualização no website.
          </Paragraph>

          <Paragraph>
            Por este instrumento, o <strong> CLIENTE </strong> autoriza o
            fornecimento de quaisquer informações sobre as transações efetuadas
            através do Cartão no exterior ao Banco Central do Brasil, bem como
            declara a ciência de eventuais comunicações pelo Banco Central do
            Brasil à Receita Federal ou outro órgão público competente.
          </Paragraph>
        </section>

        <section>
          <SectionTitle>5. SERVIÇO DE SAQUE</SectionTitle>

          <Paragraph>
            O serviço de Saque é disponibilizado no país e no exterior, estando
            o seu limite condicionado aos valores disponíveis na Conta de
            Pagamento no momento da sua realização.
          </Paragraph>

          <Paragraph>
            Saque é limitado ao valor máximo de R$ 200,00 por dia, conforme
            capacidade operacional dos terminais compatíveis com o Cartão.
          </Paragraph>

          <Paragraph>
            Ao utilizar o serviço de Saque, o <strong> CLIENTE </strong> declara
            estar ciente da incidência de tarifas próprias da{' '}
            <strong> VEXPENSES</strong>, disponíveis para consulta no endereço
            eletrônico{' '}
            <a
              href='www.vexpenses.com.br'
              target='_blank'
            >
              www.vexpenses.com.br
            </a>
            , sendo reservado à <strong> VEXPENSES </strong> o direito de
            alterar as tarifas mencionadas a qualquer momento, mediante
            atualização dessas em seu website e comunicação ao
            <strong> CLIENTE</strong>.
          </Paragraph>

          <Paragraph>
            Sobre as operações de Saque, poderá incidir, ainda, a cobrança de
            encargos que serão debitados diretamente do saldo dos Cartões,
            incluindo aqueles relativos à prestação de serviços de terceiros,
            tais como dos terminais utilizados para saque.
          </Paragraph>

          <Paragraph>
            Na hipótese de o Cartão não ter saldo suficiente para o débito das
            tarifas, estes poderão ser descontados futuramente do Cartão ou
            diretamente da Conta Cliente.
          </Paragraph>
        </section>

        <section>
          <SectionTitle>6. DECLARAÇÕES</SectionTitle>

          <Paragraph>
            Cada uma das <strong> PARTES </strong> declara à outra que:
          </Paragraph>

          <Paragraph>
            6.1 Possui capacidade legal, assim como obteve todas as autorizações
            societárias pertinentes ao assentimento e execução deste
            instrumento. Este <strong> TERMO </strong> constitui, assim,
            instrumento devido e validamente consentido, sendo, portanto,
            válido, obrigatório e exequível.
          </Paragraph>

          <Paragraph>
            6.2 O cumprimento deste <strong> TERMO </strong>:
          </Paragraph>

          <div>
            <Paragraph>
              (i) não viola qualquer disposição dos atos societários das{' '}
              <strong> PARTES </strong>;{' '}
            </Paragraph>

            <Paragraph>
              (ii) não infringe qualquer dispositivo contratual ou legal
              relativos às obrigações pactuadas com quaisquer terceiros, em
              especial, mas não somente, cláusulas de exclusividade e de não
              concorrência, pelo que cada <strong> PARTE</strong>, conforme
              aplicável, assume integral responsabilidade por toda e qualquer
              penalidade, seja esta pecuniária ou de qualquer outra natureza,
              que a eventual infração possa acarretar, sem prejuízo dos demais
              direitos legais e contratuais que assistam à outra{' '}
              <strong> PARTE</strong>, incluindo o direito de uma{' '}
              <strong> PARTE </strong> de exigir à outra indenização por perdas
              e danos a que der causa; e
            </Paragraph>

            <Paragraph>
              (iii) não infringe qualquer disposição de lei, decreto, norma ou
              TERMO, ordem administrativa ou judicial aplicáveis.
            </Paragraph>
          </div>

          <Paragraph>
            6.3 Se responsabiliza integralmente pelo cumprimento das obrigações
            trabalhistas, previdenciárias, fiscais e outras de qualquer natureza
            relativas a si e a seus empregados, prepostos, representantes ou
            quaisquer outros que lhes façam as vezes.
          </Paragraph>

          <Paragraph>
            6.4 É responsável pelo pagamento dos tributos que tenham por fato
            gerador única e exclusivamente os seus serviços, sendo que nenhuma
            das <strong> PARTES </strong> se responsabilizará pelo pagamento de
            tributos relativos à venda de bens e serviços pela outra{' '}
            <strong> PARTE</strong>.
          </Paragraph>

          <Paragraph>
            6.5 Adota política adequada de prevenção e combate à lavagem de
            dinheiro, corrupção e ao financiamento do terrorismo, desenvolvida
            em conformidade com as legislações aplicáveis, bem como observam, no
            decorrer de suas atividades, os mais elevados princípios de
            idoneidade, não praticando qualquer ato em desconformidade com as
            regulamentações aplicáveis.
          </Paragraph>
        </section>

        <section>
          <SectionTitle>7. OBRIGAÇÕES DO CLIENTE </SectionTitle>

          <Paragraph>
            Sem prejuízo de outras obrigações assumidas neste{' '}
            <strong> TERMO</strong>, assim como daquelas decorrentes de lei ou
            regulamentação específica, o <strong> CLIENTE </strong> se obriga a:
          </Paragraph>

          <Paragraph>
            7.1 Fornecer à <strong> VEXPENSES</strong>, correta e
            tempestivamente, sempre que solicitado por esta, todas as
            informações necessárias à prestação dos serviços objeto do presente{' '}
            <strong> TERMO</strong>.
          </Paragraph>

          <div>
            <Paragraph>
              <strong>Parágrafo único. </strong>A <strong> VEXPENSES </strong>{' '}
              não se responsabiliza por quaisquer falhas ocorridas na prestação
              dos serviços decorrentes da falta de informações, bem como da
              prestação de informações incorretas e/ou inverídicas fornecidas
              pelo <strong> CLIENTE .</strong>
            </Paragraph>
          </div>

          <Paragraph>
            7.2 Informar à <strong> VEXPENSES </strong> qualquer alteração dos
            seus dados cadastrais no prazo máximo de 15 (quinze) dias úteis,
            contados da alteração em questão.
          </Paragraph>

          <Paragraph>
            7.3 Disponibilizar, no prazo máximo de 15 (quinze) dias úteis,
            quaisquer documentos adicionais solicitados pela{' '}
            <strong> VEXPENSES </strong> para comprovação dos dados e
            informações fornecidos.
          </Paragraph>

          <div>
            <Paragraph>
              <strong>Parágrafo único.</strong> Esclarece-se que tal verificação
              não confere ao
              <strong> CLIENTE </strong> atestado de regularidade para qualquer
              fim, tampouco o exime do cumprimento de quaisquer das obrigações
              previstas neste <strong> TERMO</strong>.
            </Paragraph>
          </div>

          <Paragraph>
            7.4 Efetuar o pagamento de quaisquer valores devidos à{' '}
            <strong> VEXPENSES </strong> em razão deste
            <strong> TERMO</strong>, na forma e prazo aqui acordados.
          </Paragraph>

          <Paragraph>
            7.5 Indicar à <strong> VEXPENSES </strong>os Portadores devidamente
            autorizados pelo
            <strong> CLIENTE </strong> a utilizar os Cartões, bem como fornecer
            os dados completos dos Portadores e respectivos documentos
            solicitados, além de garantir que os Cartões sejam utilizados apenas
            pelos Portadores cadastrados perante a <strong> VEXPENSES</strong>,
            sob pena de bloqueio e/ou cancelamento do(s) Cartão(ões) do(s)
            Portador(es).
          </Paragraph>

          <div>
            <Paragraph>
              7.5.1. A indicação dos Portadores implica, para todos os fins de
              fato e de direito, na outorga de poderes a estes para utilização
              do Cartão, por conta, fiscalização e risco do{' '}
              <strong> CLIENTE</strong>, sendo este o único e exclusivo
              responsável pela utilização do(s) Cartão(ões) pelos Portadores
              indicados.
            </Paragraph>

            <Paragraph>
              7.5.2. Os dados cadastrais dos Portadores, incluindo seu CPF,
              deverão estar regulares perante a Receita Federal do Brasil, sendo
              certo que não será processado o desbloqueio de Cartão de
              Portadores com CPF cancelado, pendente de regularização ou
              suspenso.
            </Paragraph>
          </div>

          <Paragraph>
            7.6 Orientar os Portadores quanto ao correto uso do Cartão e a
            observância às regulamentações vigentes aplicáveis, especialmente
            aquelas emitidas pelo Banco Central do Brasil, sob pena de
            interrupção no fornecimento dos serviços e/ou negativa de Transação
            caso haja indícios ou seja constatado uso indevido do Cartão ou a
            violação dos dispositivos legais e regulatórios aplicáveis,
            especialmente aqueles que se referem à prevenção e à prática do
            crime de lavagem de dinheiro e financiamento ao terrorismo, sem
            prejuízo da possibilidade de bloqueio e/ou cancelamento do(s)
            Cartão(ões) do(s) Portador(es).
          </Paragraph>

          <Paragraph>
            7.7 Responsabilizar-se por quaisquer prejuízos decorrentes de ações
            criminosas relacionadas direta ou indiretamente com o objeto deste{' '}
            <strong> TERMO</strong>, sejam elas cometidas por seus
            administradores, empregados, prepostos, representantes ou quaisquer
            outros que lhes façam as vezes.
          </Paragraph>

          <div>
            <Paragraph>
              7.7.1 Responsabilizar-se por quaisquer penalidades decorrentes do
              uso indevido do Cartão pelos Portadores, sejam tais penalidades de
              cunho civil, criminal ou administrativo.
            </Paragraph>

            <Paragraph>
              7.7.2 Responsabilizar-se por quaisquer prejuízos decorrentes de
              condutas inadequadas efetuadas pelos Portadores relacionadas ao
              Cartão, como, mas não se limitando ao seu mal uso e a utilização
              em desconformidade com as Políticas da
              <strong> VEXPENSES </strong> e do <strong> CLIENTE</strong>.
            </Paragraph>

            <Paragraph>
              7.7.3 Responsabilizar-se pela nomeação e pelas ações dos usuários
              gestores do Módulo de Controle de Cartões, que farão a gestão dos
              recursos dentro da Plataforma da
              <strong> VEXPENSES</strong>, isentando, assim, a{' '}
              <strong> VEXPENSES </strong>de qualquer responsabilidade neste
              sentido.
            </Paragraph>
          </div>

          <Paragraph>
            Ao aceitar o presente <strong> TERMO</strong>, o{' '}
            <strong> CLIENTE </strong> reconhece e declara que concorda
            integralmente com todas as cláusulas, termos e condições
            estabelecidas neste instrumento e na Política de Privacidade da{' '}
            <strong> VEXPENSES</strong>, sobre a qual declara ter pleno
            conhecimento, subordinando-se, portanto, sem restrições, a todas as
            legislações e disposições aqui previstas e a quaisquer outras
            porventura instituídas pela <strong> VEXPENSES</strong>, bem como a
            cumprir com todas suas obrigações.
          </Paragraph>

          <Paragraph>
            O <strong> CLIENTE </strong> será o único e exclusivo responsável,
            perante a<strong> VEXPENSES </strong> e quaisquer terceiros
            envolvidos na prestação dos serviços objeto deste{' '}
            <strong> TERMO</strong>, pela utilização dos Cartões pelos
            Portadores por ele indicados.
          </Paragraph>

          <Paragraph>
            O <strong> CLIENTE </strong> poderá, a qualquer tempo e sob sua
            responsabilidade, solicitar à <strong> VEXPENSES </strong> o
            bloqueio e/ou o cancelamento de um determinado Cartão e, havendo
            saldo no Cartão Cancelado, a <strong> VEXPENSES </strong>{' '}
            providenciará o estorno para a Conta Cliente.
          </Paragraph>

          <Paragraph>
            O <strong> CLIENTE </strong>reconhece e declara ciência da
            possibilidade de interrupção no fornecimento dos serviços objeto
            deste <strong> TERMO </strong> por razões técnicas, em razão de
            manutenção preventiva ou corretiva ou, ainda, por motivos de caso
            fortuito ou força maior.A <strong> VEXPENSES</strong>, portanto,
            exime-se de responsabilidade por eventuais falhas ocorridas durante
            os períodos de indisponibilidade.Em casos de interrupções por
            manutenção preventiva ou corretiva, A <strong> VEXPENSES </strong>
            notificará o <strong> CLIENTE </strong>com 24 horas de antecedência.
          </Paragraph>
        </section>

        <section>
          <SectionTitle>8. OBRIGAÇÕES DA VEXPENSES </SectionTitle>

          <Paragraph>
            Sem prejuízo de outras obrigações assumidas neste{' '}
            <strong> TERMO</strong>, assim como daquelas decorrentes de lei ou
            regulamentação específica, a<strong> VEXPENSES </strong> se obriga
            a:
          </Paragraph>

          <Paragraph>
            8.1 Responsabilizar-se pela guarda e correta gestão dos recursos
            repassados pelo
            <strong> CLIENTE</strong>, destinados ao aporte em Cartão, até o
            momento da utilização destes recursos.
          </Paragraph>

          <Paragraph>
            8.2 Prestar os serviços por meio de equipe própria e de Parceiros
            contratados devidamente treinados e orientados para este fim,
            ofertando tais serviços dentro dos padrões de qualidade exigíveis
            pelo mercado.
          </Paragraph>

          <Paragraph>
            8.3 Manter a tecnologia e a infraestrutura adequadas para realizar
            as atividades previstas neste <strong> TERMO</strong>.
          </Paragraph>

          <Paragraph>
            8.4 Manter atualizadas todas as certificações e homologações
            técnicas necessárias à prestação dos serviços aqui descritos, bem
            como exigir que seus Parceiros assim também procedam.
          </Paragraph>

          <div>
            <Paragraph>
              8.4.1 Em caso de interrupção planejada, a{' '}
              <strong> VEXPENSES </strong> se compromete em notificar o{' '}
              <strong> CLIENTE </strong> com, no mínimo, 24 (vinte e quatro)
              horas de antecedência, por meio do endereço online (e-mail)
              previamente informado pelo
              <strong> CLIENTE</strong>.
            </Paragraph>
          </div>

          <Paragraph>
            No caso de indício e/ou suspeita de ilicitude, fraude ou violação
            pelo
            <strong> CLIENTE </strong> a este <strong> TERMO </strong> e/ou a
            quaisquer outros documentos e condições relacionados à prestação dos
            serviços pela <strong> VEXPENSES</strong>, esta poderá suspender
            quaisquer acessos, pagamentos e movimentações imediatamente, até que
            se esclareça a situação.
          </Paragraph>

          <Paragraph>
            A <strong> VEXPENSES </strong> esclarece, de antemão, que no
            fornecimento de seus produtos e serviços vale-se de parceria com
            outros prestadores de serviço, tais como as empresas responsáveis
            pelo fornecimento dos Cartões ou, ainda, aquelas que executam a
            logística de entrega de tais instrumentos.
          </Paragraph>

          <Paragraph>
            No processo de fornecimento dos Cartões, a{' '}
            <strong> VEXPENSES </strong> atua como intermediadora entre o{' '}
            <strong> CLIENTE </strong> e o parceiro emissor dos Cartões. Desta
            forma, todos os valores destinados ao aporte em Conta de Pagamento
            recebidos do <strong> CLIENTE </strong> pela{' '}
            <strong> VEXPENSES </strong> são repassados em até dois dias úteis
            ao parceiro, o qual exercerá a custódia dos valores até sua efetiva
            utilização, responsabilizando-se, também, pela liquidação das
            Transações com os usuários finais recebedores.
          </Paragraph>
        </section>

        <section>
          <SectionTitle>9. TARIFAS</SectionTitle>

          <Paragraph>
            O <strong> CLIENTE </strong> declara a ciência da possibilidade de
            cobrança de tarifas pela <strong> VEXPENSES </strong> em razão da
            disponibilização dos serviços que constituem o{' '}
            <strong> OBJETO </strong>
            do presente <strong> TERMO</strong>.Tais tarifas e seus respectivos
            fatos geradores encontram-se disponíveis para consulta no endereço
            eletrônico www.vexpenses.com.br, sendo reservado o direito da{' '}
            <strong> VEXPENSES </strong>de alterá-las mediante comunicação
            prévia ao <strong> CLIENTE</strong>, e serão atualização em seu
            website.
          </Paragraph>

          <Paragraph>
            Caso a <strong> VEXPENSES </strong> venha a disponibilizar novos
            serviços ou funcionalidades aos <strong>CLIENTES</strong>, poderá
            instituir tarifas adicionais que serão descontadas do saldo dos
            Cartões ou cobradas diretamente do
            <strong> CLIENTE</strong>, conforme valores, termos e condições que
            vierem a ser informados pela <strong> VEXPENSES </strong>.
          </Paragraph>
        </section>

        <section>
          <SectionTitle>10. PREÇO E CONDIÇÕES DE PAGAMENTO</SectionTitle>

          <Paragraph>
            O <strong> CLIENTE </strong> pagará à <strong> VEXPENSES</strong>,
            em contraprestação aos serviços aqui acordados, os valores relativos
            às tarifas incidentes sobre cada um dos serviços contratados,
            considerando os valores disponibilizados no endereço e letrônico{' '}
            <a
              href='http://www.vexpenses.com/'
              target='_blank'
              rel='noreferrer'
            >
              www.vexpenses.com.br
            </a>{' '}
            no momento da contratação (“Preço”), sem prejuízo da faculdade de
            modificação de tais valores pela <strong> VEXPENSES </strong>{' '}
            mediante prévio aviso ao <strong> CLIENTE</strong>.
          </Paragraph>

          <Paragraph>
            Caso o <strong> CLIENTE </strong>opte pela contratação do serviço
            adicional referente ao Módulo de Controle de Cartões,
            comprometer-se-á a realizar o pagamento referente à prestação deste
            serviço à <strong> VEXPENSES </strong>
            mensalmente, responsabilizando-se à <strong> VEXPENSES </strong>por
            informar,até a data de fechamento, o montante a ser pago.
          </Paragraph>

          <Paragraph>
            Por este <strong> TERMO,</strong> o <strong> CLIENTE </strong>{' '}
            concorda, desde já, que para adquirir seu Cartão VExpenses é
            necessário pagar uma taxa de R$6,50 referente à emissão do cartão
            corporativo.
          </Paragraph>

          <Paragraph>
            Eventuais questionamentos sobre os valores faturados não autoriza o
            <strong> CLIENTE </strong> a suspender ou atrasar o pagamento,
            sendo, para tanto, plenamente aplicáveis as cláusulas penais do
            presente <strong> TERMO</strong>.
          </Paragraph>

          <Paragraph>
            Os valores das tarifas previstas neste <strong> TERMO </strong>{' '}
            poderão ser livremente ajustados, para mais ou para menos, mediante
            comunicação prévia e expressa ao <strong> CLIENTE</strong>, com
            antecedência mínima de 90 (noventa) dias úteis, de acordo com
            reajustes realizados por terceiros.
          </Paragraph>

          <Paragraph>
            Na hipótese de atraso no pagamento incidirão, desde o primeiro dia
            do respectivo atraso até o efetivo pagamento:
          </Paragraph>

          <div>
            <Paragraph>
              (a) multa no valor de 2% (dois por cento) do valor em atraso;{' '}
            </Paragraph>

            <Paragraph>
              (b) correção monetária pelo IPCA/FGV, <em>pro rata die</em>; e
            </Paragraph>

            <Paragraph>
              (c) juros de mora de 1% (um por cento) ao mês, calculados{' '}
              <em>pro rata die.</em>
            </Paragraph>
          </div>

          <Paragraph>
            Caso o atraso ultrapasse 30 (trinta) dias corridos, contados da data
            do vencimento da parcela inadimplida, poderá ocorrer a suspensão da
            prestação dos serviços, mediante notificação prévia por escrito.
          </Paragraph>

          <Paragraph>
            O Preço será reajustado a cada 12 (doze) meses, contados da data de
            assinatura deste <strong> TERMO</strong>, pela variação positiva do
            IPCA/FGV acumulado no período.
          </Paragraph>

          <Paragraph>
            A falta de pagamento de quaisquer valores nas respectivas datas de
            vencimento não acarretará a rescisão automática do acordo firmado,
            resultando, contudo, na suspensão do acesso do{' '}
            <strong> CLIENTE </strong> à Plataforma, bem como o bloqueio
            temporário dos Cartões até que as pendências financeiras tenham sido
            regularizadas.
          </Paragraph>

          <Paragraph>
            O desbloqueio dos Cartões e o acesso à Plataforma somente será
            reestabelecido após a identificação, pela{' '}
            <strong> VEXPENSES</strong>, do pagamento integral de todos os
            valores devidos.Caso o <strong> CLIENTE </strong> não adimpla a(s)
            pendência(s) financeira(s) no prazo máximo de 60 (sessenta) dias
            contados do vencimento da primeira pendência financeira, a{' '}
            <strong> VEXPENSES </strong> se reserva o direito de rescindir o
            presente <strong> TERMO</strong>, comprometendo-se a eliminar de
            forma definitiva todas as informações do <strong> CLIENTE </strong>
            que por ventura estejam armazenadas na Plataforma, bem como proceder
            ao cancelamento definitivo todos os Cartões.
          </Paragraph>
        </section>

        <section>
          <SectionTitle>11. VIGÊNCIA E RESCISÃO</SectionTitle>

          <Paragraph>
            O presente instrumento vigerá pelo prazo inicial de 12 (doze) meses,
            renovando-se automática e sucessivamente por iguais períodos.
          </Paragraph>

          <Paragraph>
            Poderão as <strong> PARTES </strong> rescindir o{' '}
            <strong> TERMO </strong> imotivadamente, sem a incidência de
            quaisquer multas ou penalidades, mediante o envio de notificação
            prévia por escrito, encaminhada com 30 (trinta) dias corridos de
            antecedência.
          </Paragraph>

          <Paragraph>
            Sem prejuízo das demais cláusulas e condições estabelecidas no
            presente instrumento, esse poderá ser rescindido, de pleno direito,
            imediatamente e independentemente de notificação, nas seguintes
            hipóteses:
          </Paragraph>

          <ol type='a'>
            <li>
              <Paragraph>
                Quebra de qualquer das disposições ou declarações deste{' '}
                <strong> TERMO</strong>, por qualquer das{' '}
                <strong> PARTES</strong>, de forma a impedir a continuidade da
                execução de seu objeto;
              </Paragraph>
            </li>

            <li>
              <Paragraph>
                Inadimplência não sanada no prazo de 10 (dez) dias úteis,
                contados do recebimento de notificação neste sentido;
              </Paragraph>
            </li>

            <li>
              <Paragraph>
                Decretação de falência, pedido de recuperação judicial ou
                extrajudicial ou declaração de insolvência de qualquer das{' '}
                <strong> PARTES </strong>;
              </Paragraph>
            </li>

            <li>
              <Paragraph>
                Descumprimento dos deveres de confidencialidade estabelecidos
                neste instrumento;{' '}
              </Paragraph>
            </li>

            <li>
              <Paragraph>
                Hipótese de inviabilidade de execução do modelo de negócios ora
                pactuado por alteração das condições regulatórias, contratuais
                e/ou econômicas que viabilizam o objeto deste
                <strong> TERMO</strong>, a critério da{' '}
                <strong> VEXPENSES</strong>;
              </Paragraph>
            </li>

            <li>
              <Paragraph>
                Eventos de caso fortuito ou força maior que impeçam ou afetem de
                maneira significativa a execução dos serviços aqui descritos.
              </Paragraph>
            </li>
          </ol>

          <Paragraph>
            Todas as obrigações das<strong> PARTES</strong> que devam
            expressamente ou por sua natureza sobreviver à rescisão deste{' '}
            <strong> TERMO </strong> continuarão em pleno vigor e efeito mesmo
            após a sua rescisão por qualquer motivo, tais como, mas não se
            limitando a, obrigações tributárias, trabalhistas, de sigilo e
            confidencialidade, dentre outras.
          </Paragraph>
        </section>

        <section>
          <SectionTitle>12. CONFIDENCIALIDADE</SectionTitle>

          <Paragraph>
            Sem prejuízo das demais cláusulas e condições estabelecidas no
            presente instrumento, as <strong> PARTES </strong> se obrigam, por
            si e seus representantes, funcionários, empregados e contratados, em
            manter como sigilosas e confidenciais todas as informações, dados,
            documentos ou especificações técnicas ou comerciais a que tiverem
            acesso, conhecimento ou ciência, seja por meio escrito, verbal,
            digital ou qualquer outro, de modo que tais informações sejam
            utilizadas para os fins exclusivos da prestação de serviços
            previstos neste <strong> TERMO</strong>, exceto nos casos de
            divulgação já estabelecidas no presente instrumento.
          </Paragraph>

          <Paragraph>
            Não será considerada violação a esta cláusula a divulgação de
            informações que:{' '}
          </Paragraph>

          <div>
            <Paragraph>
              (i) sejam do conhecimento público em geral, sem desrespeitar
              qualquer obrigação devida à <strong> PARTE </strong> informante;
            </Paragraph>

            <Paragraph>
              (ii) sejam conhecidas da <strong> PARTE </strong> informada antes
              de sua revelação pela <strong> PARTE </strong> informante, sem
              desrespeitar qualquer obrigação devida à <strong> PARTE </strong>{' '}
              informante;
            </Paragraph>

            <Paragraph>
              (iii) sejam desenvolvidas de forma independente pela{' '}
              <strong> PARTE </strong> informada, sem desrespeitar qualquer
              obrigação devida à <strong> PARTE </strong> informante;
            </Paragraph>

            <Paragraph>
              (iv) sejam recebidas por meio de um terceiro, sem desrespeitar
              qualquer obrigação devida à <strong> PARTE </strong> informante;
              e/ou
            </Paragraph>

            <Paragraph>
              (v) sejam solicitadas por uma autoridade governamental competente
              com base em lei aplicável.
            </Paragraph>
          </div>

          <Paragraph>
            O <strong> CLIENTE </strong> autoriza a <strong> VEXPENSES</strong>,
            desde já, a compartilhar com quaisquer órgãos reguladores
            competentes todas as informações por eles solicitadas com relação às
            operações oriundas deste <strong> TERMO</strong>, desde que tal
            solicitação seja devidamente justificada e legalmente embasada.
          </Paragraph>

          <Paragraph>
            Caso uma das <strong> PARTES </strong> seja obrigada por lei ou por
            determinação judicial a revelar informações da{' '}
            <strong> PARTE </strong> informante, a <strong> PARTE </strong>{' '}
            informada deverá fornecer um aviso prévio de tal revelação
            compulsória no prazo de 48 (quarenta e oito) horas, contados do
            recebimento da intimação, se possível.
          </Paragraph>

          <Paragraph>
            A <strong> PARTE </strong> informada notificará a{' '}
            <strong> PARTE </strong> informante, no prazo de até 48 (quarenta e
            oito) horas, se tomar conhecimento de qualquer suspeita real ou
            razoável de quebra de sigilo das Informações Confidenciais da
            <strong> PARTE</strong> informante.
          </Paragraph>

          <Paragraph>
            O não cumprimento das obrigações de confidencialidade previstas no{' '}
            <strong> TERMO </strong>
            sujeitará a <strong>PARTE </strong>infratora o pagamento de perdas e
            danos, sem prejuízo das multas e penalidades previstas em lei, assim
            como da possibilidade de rescisão motivada do{' '}
            <strong> TERMO</strong>.
          </Paragraph>

          <Paragraph>
            As obrigações de sigilo aqui dispostas perdurarão mesmo após o
            término deste
            <strong> TERMO</strong>, pelo período de 5 (cinco) anos após a
            rescisão.
          </Paragraph>
        </section>

        <section>
          <SectionTitle>13. PROPRIEDADE INTELECTUAL</SectionTitle>

          <Paragraph>
            Cada <strong> PARTE </strong> declara e garante que é a legítima
            proprietária de sua propriedade intelectual, não havendo qualquer
            impedimento para o uso de tal.
          </Paragraph>

          <Paragraph>
            O presente <strong> TERMO </strong> não cede nem transfere quaisquer
            Direitos de Propriedade Intelectual da <strong> VEXPENSES</strong>,
            seja de propriedade ou de uso.Os Direitos de Propriedade Intelectual
            da <strong> VEXPENSES </strong> que forem revelados ao
            <strong> CLIENTE </strong>em razão da prestação dos serviços
            continuarão pertencendo à <strong> VEXPENSES</strong>, ficando o{' '}
            <strong> CLIENTE </strong> obrigado a mantê-los como Informações
            Confidenciais.
          </Paragraph>

          <Paragraph>
            Os resultados, parciais e finais que decorrerem da prestação dos
            serviços e da execução deste <strong> TERMO </strong> serão de
            propriedade exclusiva da <strong> VEXPENSES</strong>, que poderá
            deles fazer o uso que melhor lhe convier, inclusive divulgá-los, sob
            qualquer forma ou veículo, a nível nacional ou internacional, no
            meio científico/especializado, ou não.
          </Paragraph>

          <Paragraph>
            Através do presente instrumento, o <strong> CLIENTE </strong>{' '}
            expressamente autoriza a utilização de sua marca/logomarca pela{' '}
            <strong> VEXPENSES</strong>, para divulgação do trabalho realizado
            e/ou em razão da execução do objeto deste <strong> TERMO</strong>,
            não sendo considerada violação a este instrumento tal uso, mesmo
            após o término da vigência do presente <strong> TERMO</strong>,
            sendo, neste último caso, permitida a sua utilização como mera
            referência da qualidade do serviço prestado.
          </Paragraph>
        </section>

        <section>
          <SectionTitle>14. RESPONSABILIDADE TRABALHISTA</SectionTitle>

          <Paragraph>
            As <strong> PARTES </strong> se isentarão mutuamente de toda e
            qualquer responsabilidade com relação a reclamações, demandas e
            ações cíveis, trabalhistas, sociais ou previdenciárias, propostas
            perante quaisquer órgãos públicos ou sindicais, por
            empregados/contratados e/ou subcontratados da outra{' '}
            <strong> PARTE</strong>, bem como por atos praticados por seus
            empregados e/ou subcontratados.A <strong> PARTE </strong>{' '}
            responsável indenizará a<strong> PARTE </strong> inocente por
            eventuais perdas, danos e demais despesas, inclusive honorários
            advocatícios e custas judiciais, oriundos de tais reclamações,
            demandas ou ações que tiver de suportar.
          </Paragraph>
        </section>

        <section>
          <SectionTitle>
            15. INDENIZAÇÕES E LIMITAÇÃO DE RESPONSABILIDADE
          </SectionTitle>

          <Paragraph>
            Cada uma das <strong> PARTES </strong> manterá a outra indene de
            quaisquer pleitos, reivindicações, demandas judiciais,
            extrajudiciais, arbitrais e/ou administrativas a que der causa,
            responsabilizando-se por si, seus representantes, empregados,
            prepostos, contratados, terceiros, subcontratados ou quaisquer
            outros a ela ligados ou que participem da execução deste{' '}
            <strong> TERMO</strong>, obrigando-se, ainda, a requerer
            formalmente, perante a autoridade competente, judicial e/ou
            administrativa, a substituição e/ou exclusão da{' '}
            <strong> PARTE </strong> inocente do polo passivo da demanda. Cada{' '}
            <strong> PARTE </strong> assumirá ações propostas por seus
            empregados e/ou subcontratados, oriundas direta ou indiretamente do
            presente <strong> TERMO</strong>, sendo trabalhistas ou de qualquer
            natureza, isentando a <strong> PARTE </strong> inocente de qualquer
            responsabilidade e ressarcindo prontamente todos e quaisquer custos
            e despesas incorridos, incluídos no conceito de custos os valores
            razoáveis de honorários advocatícios que a <strong> PARTE </strong>{' '}
            inocente vier a pagar para a defesa de seus interesses, honorários
            de peritos, contadores, bem como qualquer outra despesa incorrida
            com a investigação, defesa, manutenção, preservação ou execução de
            quaisquer dos direitos aqui previstos, incluindo mas não se
            limitando a indenizações, acordos e condenações.
          </Paragraph>

          <Paragraph>
            Caso não seja possível excluir a <strong> PARTE </strong> inocente
            do polo passivo de qualquer demanda judicial, a{' '}
            <strong> PARTE </strong> inocente irá contratar advogados de sua
            escolha e a <strong> PARTE </strong> infratora ficará responsável
            por arcar com tais honorários e deverá disponibilizar informações,
            provas e/ou testemunhas para a correspondente defesa.
          </Paragraph>

          <Paragraph>
            A <strong> VEXPENSES </strong> não se responsabiliza por eventuais
            falhas, atrasos ou interrupções na sua prestação dos serviços, uma
            vez que, tratando-se de serviços de tecnologia, interrupções são
            possíveis, inclusive, mas não se limitando, decorrentes de:
          </Paragraph>

          <div>
            <Paragraph>(i) caso fortuito ou motivos de força maior; </Paragraph>

            <Paragraph>
              (ii) limitações impostas por parte do poder público;{' '}
            </Paragraph>

            <Paragraph>
              (iii) má utilização do serviço pelo <strong> CLIENTE</strong>;
              e/ou
            </Paragraph>

            <Paragraph>
              (iv) por qualquer outro fato alheio à <strong> VEXPENSES </strong>
              .
            </Paragraph>
          </div>

          <Paragraph>
            A responsabilidade da <strong> PARTES</strong>, assim como de suas
            controladoras, controladas, coligadas ou quaisquer sociedades a ela
            ligadas, para com o<strong> CLIENTE</strong>, seus prepostos,
            empregados ou terceiros em conexão com o presente{' '}
            <strong> TERMO </strong> estará, em qualquer hipótese, limitada ao
            valor do <strong> TERMO </strong>que será calculado da seguinte
            forma: 12 (doze vezes) vezes o valor da última mensalidade referente
            ao produto de cartões paga pelo
            <strong> CLIENTE</strong>.As <strong> PARTES </strong> em nenhuma
            hipótese se responsabilizará por danos indiretos, lucros cessantes,
            danos morais ou qualquer outra modalidade de indenização de tal
            natureza.
          </Paragraph>
        </section>

        <section>
          <SectionTitle>16. DISPOSIÇÕES GERAIS</SectionTitle>

          <Paragraph>
            Toda e qualquer alteração deste <strong> TERMO </strong> deverá ser
            formalizada através da celebração do respectivo termo aditivo
            assinado por ambas as <strong> PARTES </strong>
          </Paragraph>

          <Paragraph>
            Sem prejuízo das demais cláusulas e condições constantes no presente
            instrumento, quaisquer infrações ou descumprimentos de qualquer
            legislação aplicável ao
            <strong> CLIENTE </strong> no desenvolvimento de suas atividades
            serão de sua exclusiva responsabilidade.
          </Paragraph>

          <Paragraph>
            As <strong> PARTES </strong> reconhecem que o presente instrumento
            foi elaborado dentro dos mais rígidos princípios da boa-fé e da
            probidade, sendo fruto do mútuo consentimento expresso em cláusulas
            que atendem plenamente os seus respectivos interesses
            comerciais.Declaram, ainda, que leram e compreenderam integralmente
            o conteúdo ora avençado, tendo sido exercida em toda a sua plenitude
            a autonomia da vontade das <strong> PARTES</strong>, reconhecendo
            que o presente ajuste é equânime e livre de ambiguidades e
            contradições.
          </Paragraph>

          <Paragraph>
            As <strong> PARTES </strong> reconhecem sua completa independência,
            seja para fins regulatórios, consumeristas, trabalhistas,
            societários ou outros.Nada neste Instrumento pode ser interpretado
            como formação entre as <strong> PARTES </strong>
            de <em>joint venture</em> ou associação sob qualquer forma.
          </Paragraph>

          <Paragraph>
            Eventual omissão ou tolerância de uma <strong> PARTE </strong> no
            cumprimento das obrigações contratuais pela outra não constituirá
            novação, renúncia a direito ou qualquer modificação do acordado
            neste <strong>TERMO</strong>, tampouco afetará os direitos oriundos
            deste instrumento, que poderão ser exigidos a qualquer tempo.
          </Paragraph>

          <Paragraph>
            Se qualquer disposição do presente <strong> TERMO </strong> for
            considerada nula, ilegal ou inexequível nos termos da legislação
            pertinente, a disposição em questão será ineficaz tão somente na
            medida da nulidade, ilegalidade ou inexequibilidade daquela
            disposição, e não afetará quaisquer outras disposições aqui
            contidas.
          </Paragraph>

          <Paragraph>
            Com exceção das hipóteses previstas neste <strong> TERMO</strong>,
            nenhuma das
            <strong> PARTES </strong> poderá delegar, ceder ou transferir, total
            ou parcialmente, os direitos e obrigações oriundos deste instrumento
            sem a prévia e expressa anuência da outra <strong> PARTE</strong>, o
            qual não será negado sem motivo razoável.
          </Paragraph>

          <Paragraph>
            Os casos fortuitos ou de força maior, assim definidos no art.393 do
            Código Civil Brasileiro, serão excludentes da responsabilidade das{' '}
            <strong> PARTES</strong>, na exata medida em que afetem o
            cumprimento de suas respectivas obrigações.
          </Paragraph>

          <Paragraph>
            Em conformidade com as normas vigentes, especialmente o § 2º do
            art.10 da Medida Provisória nº 2.200-2, as <strong> PARTES </strong>{' '}
            admitem e concordam, para todos os fins e efeitos de direito, que
            este instrumento seja assinado digitalmente por meio de plataformas
            de assinatura digital, e através dos e-mails de seus representantes
            legais, pelo que reconhecem, desde já, a autoria, validade,
            eficácia, integridade e autenticidade deste instrumento assinado
            digitalmente, ainda que sem a aplicação de certificado digital.
          </Paragraph>

          <Paragraph>
            A VEXPENSES , por si e por seus colaboradores, obriga-se a atuar no
            presente <strong> TERMO </strong>
            em conformidade com a Legislação vigente sobre Proteção de Dados
            Pessoais e as determinações de órgãos reguladores/fiscalizadores
            sobre a matéria, em especial a Lei 13.709/2018, com redação dada
            pela Lei nº 13.853/2019, além das demais normas e políticas de
            proteção de dados de cada país onde houver qualquer tipo de
            tratamento de dados
          </Paragraph>
        </section>

        <section>
          <SectionTitle>17. LEI APLICÁVEL E DO FORO</SectionTitle>

          <Paragraph>
            EsteTERMOserá regido e interpretado de acordo com as Leis da
            República Federativa do Brasil.
          </Paragraph>

          <Paragraph>
            Fica eleito o foro da comarca de Ribeirão Preto – São Paulo para
            dirimir quaisquer dúvidas ou controvérsias a respeito deste
            instrumento, renunciando as <strong> PARTES</strong>, expressamente,
            qualquer outro foro, por mais privilegiado que seja.
          </Paragraph>

          <Paragraph>
            E por estarem acordadas com os termos e condições acima, as{' '}
            <strong> PARTES </strong>
            subscrevem via Docusign o presente <strong> TERMO</strong>, também
            validado pelas testemunhas abaixo identificadas, para que produza
            todos os seus legais efeitos.
          </Paragraph>
        </section>

        <Footer>
          <strong>ATUALIZAÇÃO DOS TERMOS DE USO </strong>: OUTUBRO DE 2021
        </Footer>
      </Container>
    </>
  );
}
