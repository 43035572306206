import { DefaultTable } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useMyValueSolicitationContext } from 'data/contexts';

import { type IMyValueSolicitationsTableHeadProps } from './MyValueSolicitationsTableHead.types';

export function MyValueSolicitationsTableHead({
  balanceSolicitations,
  ...rest
}: IMyValueSolicitationsTableHeadProps): JSX.Element {
  const { t } = useTranslation('cards');

  const [sortByDate, handleSortByDate] = useMyValueSolicitationContext(
    useShallow(state => [state.sortByDate, state.handleSortByDate])
  );

  return (
    <DefaultTable.Thead {...rest}>
      <DefaultTable.Tr>
        <DefaultTable.Th
          width='16.6%'
          isActive={sortByDate !== 'default'}
          order={sortByDate}
          onClick={() => {
            handleSortByDate();
          }}
        >
          {t('date')}
        </DefaultTable.Th>

        <DefaultTable.Th width='16.6%%'>{t('usagePrediction')}</DefaultTable.Th>

        <DefaultTable.Th width='16.6%%'>{t('requestedAmount')}</DefaultTable.Th>

        <DefaultTable.Th width='16.6%%'>{t('justification')}</DefaultTable.Th>

        <DefaultTable.Th width='16.6%%'>Status</DefaultTable.Th>

        <DefaultTable.Th width='16.6%%'>{t('approvedValue')}</DefaultTable.Th>

        <DefaultTable.Th
          width='10%'
          className='value-solicitations-card-details'
        >
          {t('details')}
        </DefaultTable.Th>
      </DefaultTable.Tr>
    </DefaultTable.Thead>
  );
}
