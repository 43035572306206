import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { Tooltip } from 'presentation/components/base/Tooltip';
import { SkeletonLoader } from 'presentation/components/global/Loader';

import { CustomDate } from 'shared/utils/custom';

import { useHeader } from './useHeader';

import { type IHeaderProps } from './Header.types';

import {
  Container,
  TitleContainer,
  TransactionIdContainer
} from './Header.styles';

export function Header({ transaction }: IHeaderProps): JSX.Element {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const { isTransactionPartiallyReversed, expense, isLoadingExpense } =
    useHeader({
      transaction
    });

  return (
    <Container>
      <TitleContainer>
        <h1>
          {lang.global.details[currentLangKey]}

          {/* TOOLTIP ESTORNO PARCIAL */}
          {isTransactionPartiallyReversed && (
            <Tooltip
              id='transactionDetailsModalHeaderPartialReversedIcon'
              maxWidth={22}
              content={
                lang.cards.statement.transaction_details_modal.partial_reverse[
                  currentLangKey
                ]
              }
            >
              <BaseIcon
                name='question'
                color='secondary'
                size={1.8}
              />
            </Tooltip>
          )}

          {/* TOOLTIP ESTORNO COMPLETO */}
          {transaction.reversed && (
            <Tooltip
              id='transactionDetailsModalHeaderFullyReversedIcon'
              maxWidth={22}
              content={
                lang.cards.statement.transaction_details_modal.reversed[
                  currentLangKey
                ]
              }
            >
              <BaseIcon
                name='question'
                color='secondary'
                size={1.8}
              />
            </Tooltip>
          )}
        </h1>

        <TransactionIdContainer $isReversed={transaction.reversed}>
          #{transaction.transactionId.slice(0, 8)}
        </TransactionIdContainer>
      </TitleContainer>

      <p>
        <span>{CustomDate.formatFromISO(transaction.datetime)}</span>

        {isLoadingExpense && (
          <SkeletonLoader
            height='1.2rem'
            maxWidth='8rem'
          />
        )}
        {expense?.expenseType && <span>{expense.expenseType}</span>}
      </p>
    </Container>
  );
}
