import { useMutationCache } from 'data/cache';
import {
  BalanceMutationKeys,
  BalanceService,
  type ITransferBalanceAllocationItemsPayload,
  type IUseTransferBalanceAllocationItems
} from 'data/modules/cards/balance';

export function useTransferBalanceAllocationItems(): IUseTransferBalanceAllocationItems {
  const {
    mutate: transferBalanceAllocationItems,
    isLoading: isTransferringBalanceAllocationItems
  } = useMutationCache({
    mutationKey: [BalanceMutationKeys.TRANSFER_BALANCE_ALLOCATION_ITEMS],
    mutationFn: async (payload: ITransferBalanceAllocationItemsPayload) =>
      await BalanceService.transferBalanceAllocationItems(payload)
  });

  return {
    transferBalanceAllocationItems,
    isTransferringBalanceAllocationItems
  };
}
