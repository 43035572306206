import { Children, Fragment } from 'react';

import { DSIcons } from 'ds/icons';

import { type IBreadcrumbsContainerProps } from './BreadcrumbsContainer.types';

import { Container } from './BreadcrumbsContainer.styles';

export function BreadcrumbsContainer({
  children
}: IBreadcrumbsContainerProps): JSX.Element {
  return (
    <Container>
      {Children.map(children, child => {
        return (
          <Fragment>
            {child}
            <DSIcons.ArrowRightIcon data-testid='separator' />
          </Fragment>
        );
      })}
    </Container>
  );
}
