import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type BadgeDotStylesType, type BadgeStylesType } from './Badge.types';

export const Container = styled.div<AddPrefixToType<BadgeStylesType>>`
  gap: 0.8rem;
  display: flex;
  font-size: 1.4rem;
  width: fit-content;
  align-items: center;
  border-radius: 30px;
  padding: 0.2rem 0.8rem;
  font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto};
  font-family: ${({ theme }) => theme.tokens.fontWeightMedium};

  ${({ theme, $color }) => css`
    color: ${theme.colors[$color]};
    background-color: ${transparentize(0.9, theme.colors[$color])};
  `};

  ${({ $size }) =>
    $size === 'sm'
      ? css`
          font-size: 1.2rem;
        `
      : $size === 'md'
        ? css`
            padding: 0.2rem 1rem;
          `
        : css`
            padding: 0.4rem 1.2rem;
          `};
`;

export const Dot = styled.span<AddPrefixToType<BadgeDotStylesType>>`
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;

  ${({ theme, $color }) => css`
    background-color: ${theme.colors[$color]};
  `};
`;
