import {
  type ApportionmentErrorType,
  type IApportionmentPayload,
  type IExpenseApportionment
} from 'data/modules/expenses/types/expenses.types';

import { Currency } from 'shared/utils/format';

class ExpensesValidate {
  // a função checkApportionment recebe um array de rateio e um valor de despesa
  // ela verifica se a soma das porcentagens é 100, se a soma dos valores é igual ao valor da despesa
  // e se não existem projetos duplicados no rateio
  // se algum dos casos for verdadeiro, retorna um erro
  // se todos os casos forem falsos, retorna null, o que significa que o rateio é válido
  checkApportionment(
    apportionment: IExpenseApportionment[],
    expenseValue: number
  ): ApportionmentErrorType | null {
    // verifica se a soma das porcentagens é 100
    const apportionmentItemsPercentageSum = apportionment.reduce(
      (acc, item) => Currency.sumTwoDecimals(acc, item.percentage),
      0
    );

    if (apportionmentItemsPercentageSum !== 100) {
      return 'percentage-is-not-100';
    }

    // verifica se a soma dos valores é igual ao valor da despesa
    const apportionmentItemsValueSum = apportionment.reduce(
      (acc, item) => Currency.sumTwoDecimals(acc, item.value),
      0
    );

    if (apportionmentItemsValueSum !== expenseValue) {
      return 'sum-of-values';
    }

    // verifica se tem projetos duplicados no rateio
    const apportionmentProjects = apportionment.map(
      item => item.project?.value as string
    );
    const apportionmentProjectsSet = new Set(apportionmentProjects);

    if (apportionmentProjects.length !== apportionmentProjectsSet.size) {
      return 'duplicate-projects';
    }

    return null;
  }

  // soma dois valores com 8 casas decimais
  sumValuesWithHeightDecimals(value1: number, value2: number): number {
    return parseFloat((value1 + value2).toFixed(8));
  }

  // Ajusta o valor da porcentagem de cada projeto para aceitar 8 casas decimais
  adjustApportionmentPercentageBeforeSendToApi(
    apportionment: IApportionmentPayload[] | undefined,
    expenseValue: number
  ): IApportionmentPayload[] | undefined {
    if (!apportionment) {
      return undefined;
    }

    if (apportionment.length === 1) {
      return apportionment;
    }

    const lastProject = apportionment.pop();

    if (!lastProject) {
      return undefined;
    }

    // calcula o valor de cada projeto, desconsiderando o último projeto
    const expenseApportionment = apportionment.map(item => {
      const projectValueAsFloat = item.value;

      return {
        ...item,
        percentage: parseFloat(
          ((100 * projectValueAsFloat) / expenseValue).toFixed(8)
        )
      };
    });

    // para o último projeto, calcula a porcentagem restante
    const lastProjectPercentage = this.sumValuesWithHeightDecimals(
      100,
      -expenseApportionment.reduce(
        (acc, item) => this.sumValuesWithHeightDecimals(acc, item.percentage),
        0
      )
    );

    return [
      ...expenseApportionment,
      {
        ...lastProject,
        percentage: lastProjectPercentage
      }
    ];
  }
}

export default new ExpensesValidate();
