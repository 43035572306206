import { useState } from 'react';

import {
  type IGeneralGetAccountsPayload,
  useGetCompanyAccounts
} from 'data/modules/cards/account';
import { type PaginationType } from 'data/modules/global';

import { localStorageKeys } from 'shared/constants/global';

import {
  type IAccountIdBusinessCardType,
  type IBusinessCardsSearchParams,
  type IUseBusinessCards
} from './BusinessCards.types';

export function useBusinessCards(): IUseBusinessCards {
  const pageSizeStorage = localStorage.getItem(localStorageKeys.pageSize);

  const [accountId, setAccountId] = useState<IAccountIdBusinessCardType>({
    id: '',
    backOption: false
  });

  const [businessCardsSearchParams, setBusinessCardsSearchParams] =
    useState<IBusinessCardsSearchParams>({
      currentPage: 1,
      pageSize:
        pageSizeStorage === '10' || pageSizeStorage === '50'
          ? (Number(pageSizeStorage) as PaginationType)
          : 10
    });

  const businessCardsSearchPayload = {
    page: businessCardsSearchParams.currentPage,
    limit: Number(businessCardsSearchParams.pageSize)
  } as IGeneralGetAccountsPayload;

  const { paginatedCompanyAccounts, isLoadingCompanyAccounts } =
    useGetCompanyAccounts({
      page: businessCardsSearchPayload.page,
      limit: businessCardsSearchPayload.limit,
      enabled: true
    });

  function handleChangeSearchParam<T extends keyof IBusinessCardsSearchParams>(
    type: T,
    value: IBusinessCardsSearchParams[T]
  ): void {
    switch (type) {
      case 'currentPage':
        setBusinessCardsSearchParams(oldState => ({
          ...oldState,
          currentPage: value as number
        }));
        return;

      case 'pageSize':
        setBusinessCardsSearchParams(oldState => ({
          ...oldState,
          pageSize: value as PaginationType,
          currentPage: 1
        }));
    }
  }

  return {
    businessCardsSearchParams,
    bussinessCardsList: paginatedCompanyAccounts?.data ?? [],
    isLoadingBusinessCards: isLoadingCompanyAccounts,
    totalBusinessCards: paginatedCompanyAccounts?.total ?? 0,
    handleChangeSearchParam,
    accountId,
    onChangeAccountId: setAccountId
  };
}
