import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CardsPageHeader } from 'presentation/pages/cards/components';

import { Container } from './AdminStatementPageHeader.styles';

export function AdminStatementPageHeader(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId } = useParams();

  return (
    <Container>
      <CardsPageHeader
        tabs={[
          {
            active: false,
            label: t('cards'),
            onClick: () => {
              navigate(`/cartoes/gestao-de-grupos/${groupId}`);
            }
          },
          {
            active: true,
            label: t('movements')
          },
          {
            active: false,
            label: t('valuesSolicitations'),
            onClick: () => {
              navigate(
                `/cartoes/gestao-de-grupos/${groupId}/solicitacoes-de-valores`
              );
            }
          }
        ]}
      />
    </Container>
  );
}
