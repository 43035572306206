import { useQueryCache } from 'data/cache';
import {
  CostCentersQueryKeys,
  CostCentersService,
  type IUseGetCostsCenters,
  type IUseGetCostsCentersParams
} from 'data/modules/costsCenters';

export function useGetCostsCenters({
  enabled,
  ...payload
}: IUseGetCostsCentersParams): IUseGetCostsCenters {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [CostCentersQueryKeys.GET_COST_CENTERS, payload],
    queryFn: async () => await CostCentersService.getCostCenters(payload),
    enabled
  });

  return {
    costsCenters: data ?? [],
    isLoadingCostsCenters: isLoading || isFetching
  };
}
