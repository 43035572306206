import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IChangeBusinessCardCostsCentersFieldsForm } from 'data/modules/cards/businessCard';

export function useChangeBusinessCardCostsCentersSchema(): ObjectSchema<IChangeBusinessCardCostsCentersFieldsForm> {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const changeBusinessCardCostsCentersSchema: ObjectSchema<IChangeBusinessCardCostsCentersFieldsForm> =
    useMemo(() => {
      return yup.object({
        costsCenters: yup
          .array(yup.number())
          .required(lang.schemas.is_required[currentLangKey])
      });
    }, [lang, currentLangKey]);

  return changeBusinessCardCostsCentersSchema;
}
