import { SkeletonLoader } from 'ds';

import { type IDefaultTableBodyCellProps } from './DefaultTableBodyCell.types';

import { Container } from './DefaultTableBodyCell.styles';

export function DefaultTableBodyCell({
  children,
  truncate = false,
  isLoading = false,
  colSpan,
  ...rest
}: IDefaultTableBodyCellProps): JSX.Element {
  return (
    <Container
      $truncate={truncate}
      colSpan={colSpan}
      {...rest}
    >
      {isLoading ? <SkeletonLoader color={'neutralColorHighDark'} /> : children}
    </Container>
  );
}
