import { Fragment } from 'react';

import { SecondaryButton } from 'ds';

import { useLangContext } from 'data/contexts';

import { SplashScreen } from 'presentation/components/global/SplashScreen';
import { Carrousel } from 'presentation/pages/store/components/Carrousel';
import { RequestModal } from 'presentation/pages/store/components/RequestModal';
import { DefaultLayout } from 'presentation/templates';

import slideBg from 'shared/assets/images/store/slide-background-blue.png';

import { ProductCard } from './components/ProductCard';
import { useStore } from './useStore';

import {
  Container,
  Grid,
  SlideContent,
  SlideImage,
  SlideWrapper
} from './Store.styles';

export function Store(): JSX.Element {
  const {
    currentLangKey,
    lang: {
      store: { global }
    }
  } = useLangContext();

  const {
    slides,
    identifier,
    groupedProducts,
    isLoadingStoreProducts,
    handleSlideActionClick,
    handleCloseRequestModal,
    handleRequestProductClick
  } = useStore();

  return isLoadingStoreProducts ? (
    <SplashScreen />
  ) : (
    <DefaultLayout
      pageNameAndBreadcrumbs={{
        pageName: 'Store',
        isClassicMode: false,
        isBackButtonVisible: false,
        isBreadcrumbsVisible: false
      }}
    >
      <Container>
        <Carrousel
          mousewheel
          navigation
          variant='white'
          slides={slides.map(({ key, ...slide }) => ({
            key,
            cover: slideBg,
            content: (
              <SlideContent key={key}>
                <SlideImage
                  src={slide.image}
                  alt='slide-image'
                />
                <SlideWrapper>
                  <h2>{slide.title}</h2>
                  <p>{slide.description}</p>
                  <SecondaryButton
                    className='slide-button'
                    onClick={() => {
                      handleSlideActionClick(key);
                    }}
                  >
                    {global.click_to_learn_more[currentLangKey]}
                  </SecondaryButton>
                </SlideWrapper>
              </SlideContent>
            )
          }))}
        />

        <Grid>
          {Object.entries(groupedProducts).map(([key, products]) => (
            <Fragment key={key}>
              <h3>
                {
                  global.group_subtitles[
                    key as keyof typeof global.group_subtitles
                  ][currentLangKey]
                }
              </h3>
              {products.map(product => (
                <ProductCard
                  key={product.identifier}
                  onRequestProductClick={handleRequestProductClick}
                  {...product}
                />
              ))}
            </Fragment>
          ))}
        </Grid>

        {Boolean(identifier) && (
          <RequestModal
            identifier={identifier!}
            onClose={handleCloseRequestModal}
          />
        )}
      </Container>
    </DefaultLayout>
  );
}
