import { useGetExpense } from 'data/modules/expenses';

import { type IUseHeader, type UseHeaderParamsType } from './Header.types';

export function useHeader({ transaction }: UseHeaderParamsType): IUseHeader {
  const expenseQueryEnabled =
    transaction !== null &&
    transaction.debitCredit === 'DEBIT' &&
    transaction.type !== 'TRANSFER_DEBIT';

  const { expense, isLoadingExpense } = useGetExpense({
    enabled: expenseQueryEnabled,
    transactionId:
      expenseQueryEnabled && transaction !== null
        ? transaction.transactionId
        : 'disabled'
  });

  const isTransactionPartiallyReversed =
    transaction !== null &&
    !transaction.isInternational &&
    transaction.finalBillingAmount !== 0 &&
    transaction.finalBillingAmount !== transaction.amount;

  return {
    isTransactionPartiallyReversed,
    expense,
    isLoadingExpense: expenseQueryEnabled && isLoadingExpense
  };
}
