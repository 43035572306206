enum ExpensesMutationKeys {
  CREATE_MANUAL_EXPENSE = 'CREATE_MANUAL_EXPENSE',
  CREATE_FUEL_EXPENSE = 'CREATE_FUEL_EXPENSE',
  UPDATE_FUEL_EXPENSE = 'UPDATE_FUEL_EXPENSE',
  UPDATE_MANUAL_EXPENSE = 'UPDATE_MANUAL_EXPENSE',
  UPDATE_ROUTE_BY_MAP_EXPENSE = 'UPDATE_ROUTE_BY_MAP_EXPENSE',
  CREATE_ROUTE_EXPENSE = 'CREATE_ROUTE_EXPENSE',
  UPDATE_ROUTE_EXPENSE = 'UPDATE_ROUTE_EXPENSE',
  CREATE_ROUTE_BY_MAP = 'CREATE_ROUTE_BY_MAP',
  DELETE_EXPENSES = 'DELETE_EXPENSES'
}

enum ExpensesQueryKeys {
  GET_EXPENSES_LIST = 'GET_EXPENSES_LIST',
  GET_EXPENSE = 'GET_EXPENSE',
  GET_USER_EXPENSES_ACCOUNTABILITY = 'GET_USER_EXPENSES_ACCOUNTABILITY',
  GET_EXPENSE_PARAMETERS = 'GET_EXPENSE_PARAMETERS',
  GET_PAGINATED_EXPENSES = 'GET_PAGINATED_EXPENSES',
  GET_TYPES_OF_EXPENSES = 'GET_TYPES_OF_EXPENSES',
  GET_EXPENSES_KM_POLICY = 'GET_EXPENSES_KM_POLICY',
  GET_NUMBER_OF_EXPENSES_BY_STATUS = 'GET_NUMBER_OF_EXPENSES_BY_STATUS',
  VALIDATE_DATE_LIMITS_BY_PROJECT = 'VALIDATE_DATE_LIMITS_BY_PROJECT',
  VALIDATE_MILEAGE_POLICY_BY_USER = 'VALIDATE_MILEAGE_POLICY_BY_USER',
  VALIDATE_MILEAGE_POLICY_BY_PROJECT = 'VALIDATE_MILEAGE_POLICY_BY_PROJECT'
}

export { ExpensesMutationKeys, ExpensesQueryKeys };
