import { GenericTable } from 'presentation/components/global/Table';
import { TariffsTableBody } from 'presentation/pages/settings/Cards/Tariffs/components/TariffsTableContent/TariffsTableBody/TariffsTableBody.component';
import { TariffsTableHeader } from 'presentation/pages/settings/Cards/Tariffs/components/TariffsTableContent/TariffsTableHeader/TariffsTableHeader.component';

import { Container } from './TariffsTable.styles';

export function TariffsTable(): JSX.Element {
  return (
    <Container>
      <GenericTable
        tableBackgroundColor='alabaster'
        thead={<TariffsTableHeader />}
        tbody={<TariffsTableBody />}
      />
    </Container>
  );
}
