import { useCallback, useMemo } from 'react';

import { CustomDate } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useAnalysisUserFiltersContext, useModalContext } from 'data/contexts';
import {
  AnalysisDateToConsiderFilter,
  AnalysisDefaultFilter,
  AnalysisOrderByFilter,
  AnalysisReimbursableNonReimbursableFilter,
  AnalysisUserFilter,
  useGetAnalysisUserFilters,
  useGetAnalysisUserReportsId
} from 'data/modules/analytics';
import { ExpenseStatus } from 'data/modules/expenses';

import { type IUseUserAnalysisAppliedFilters } from './UserAnalysisAppliedFilters.types';

export function useUserAnalysisAppliedFilters(): IUseUserAnalysisAppliedFilters {
  const { handleOpenModal } = useModalContext();

  const { t } = useTranslation(['analytics', 'global']);

  const { analysisUserFilters } = useGetAnalysisUserFilters();
  const { analysisUserReports } = useGetAnalysisUserReportsId();

  const [analysisFilters, getSelectedFastDateFilter] =
    useAnalysisUserFiltersContext(
      useShallow(state => [state.filters, state.getSelectedFastDateFilter])
    );

  const expensesStatusFallbackOptions: Record<string, string> = useMemo(
    () => ({
      [ExpenseStatus.SINGLE]: t('global:standalone', {
        context: 'female',
        count: 2
      }),
      [ExpenseStatus.OPEN]: t('global:open', { context: 'female', count: 2 }),
      [ExpenseStatus.SENT]: t('global:sent', { context: 'female', count: 2 }),
      [ExpenseStatus.APPROVED]: t('global:approved', {
        context: 'female',
        count: 2
      }),
      [ExpenseStatus.REPROVED]: t('global:rejected', {
        context: 'female',
        count: 2
      }),
      [ExpenseStatus.PAID]: t('global:paid', { context: 'female', count: 2 })
    }),
    [t]
  );

  /**
   * Retorna o texto que deve ser exibido no filtro aplicado, com base nas opções selecionadas
   *
   * @param {string[] | undefined} selectedOptions Um array opcional com as opções selecionadas.
   * @param {Record<string, string>} fallbackOptions Um objeto com pares de chave-valor com as opções possíveis.
   * @param {Record<string, string> | undefined} defaultOptions Um objeto opcional com pares de chave-valor das opções default.
   * @param  context Uma string com valores possíveis 'male' ou 'female', com valor padrão 'male', que define o contexto utilizado na tradução.
   */
  const getAppliedFilterValue = useCallback(
    (
      selectedOptions: string[] | undefined,
      fallbackOptions: Record<string, string>,
      defaultOptions?: Record<string, string>,
      context: 'male' | 'female' = 'male'
    ) => {
      const options = selectedOptions ?? [];

      const allWithIndex = options.findIndex(option =>
        option.includes(AnalysisDefaultFilter.ALL_WITH)
      );

      if (allWithIndex >= 0) {
        return t('analytics:filters.information.filterAllWith', {
          term: options[allWithIndex]?.replace(
            AnalysisDefaultFilter.ALL_WITH,
            ''
          ),
          context
        }).replace(':', '');
      }

      const lastSelectedOption = [...options].pop() ?? '';
      const defaultValue = defaultOptions?.[lastSelectedOption];

      if (defaultValue) return defaultValue;

      const fallbackValues =
        Object.entries(fallbackOptions)
          .filter(([key]) => options.includes(String(key)))
          .map(([key, value]) => value) ?? [];

      return (
        fallbackValues.length <= 4
          ? fallbackValues
          : [
              ...fallbackValues.slice(0, 4),
              `+${fallbackValues.slice(4, fallbackValues.length).length}`
            ]
      ).join(', ');
    },
    [t]
  );

  const dateToConsiderOptions: Record<string, string> = useMemo(
    () => ({
      [AnalysisDateToConsiderFilter.EXPENSE_CREATED_AT]: t(
        'analytics:filters.options.dateToConsider.expenseCreationDate'
      ),
      [AnalysisDateToConsiderFilter.EXPENSE_DATE]: t(
        'analytics:filters.options.dateToConsider.expenseExecutionDate'
      ),
      [AnalysisDateToConsiderFilter.REPORT_CREATED_AT]: t(
        'analytics:filters.options.dateToConsider.reportCreationDate'
      ),
      [AnalysisDateToConsiderFilter.SEND_TO_APPROVE_DATE]: t(
        'analytics:filters.options.dateToConsider.submissionDateForApproval'
      ),
      [AnalysisDateToConsiderFilter.APPROVE_DATE]: t(
        'analytics:filters.options.dateToConsider.approvalDate'
      ),
      [AnalysisDateToConsiderFilter.PAYMENT_DATE]: t(
        'analytics:filters.options.dateToConsider.paymentDate'
      )
    }),
    [t]
  );

  const orderByOptions: Record<string, string> = useMemo(
    () => ({
      [AnalysisOrderByFilter.NAME_ASC]: t(
        'analytics:filters.options.orderBy.ascendingAlphabeticalOrder'
      ),
      [AnalysisOrderByFilter.NAME_DESC]: t(
        'analytics:filters.options.orderBy.descendingAlphabeticalOrder'
      ),

      [AnalysisOrderByFilter.VALUE_ASC]: t(
        'analytics:filters.options.orderBy.ascendingExpenseAmount'
      ),
      [AnalysisOrderByFilter.VALUE_DESC]: t(
        'analytics:filters.options.orderBy.descendingExpenseAmount'
      )
    }),
    [t]
  );

  const reimbursableNonReimbursableOptions: Record<string, string> = useMemo(
    () => ({
      [AnalysisReimbursableNonReimbursableFilter.REIMBURSABLE_NON_REIMBURSABLE]:
        t('filters.reimbursableNonReimbursable'),
      [AnalysisReimbursableNonReimbursableFilter.ONLY_REIMBURSABLE]: t(
        'analytics:filters.options.reimbursableNonReimbursable.onlyReimbursable'
      ),
      [AnalysisReimbursableNonReimbursableFilter.ONLY_NON_REIMBURSABLE]: t(
        'analytics:filters.options.reimbursableNonReimbursable.onlyNonReimbursable'
      )
    }),
    [t]
  );

  const userDefaultOptions: Record<string, string> = useMemo(
    () => ({
      [AnalysisUserFilter.ACTIVE]: t(
        'analytics:filters.options.users.activeUsers'
      ),
      [AnalysisUserFilter.INACTIVE]: t(
        'analytics:filters.options.users.inactiveUsers'
      )
    }),
    [t]
  );

  const expensesTypesFallbackOptions: Record<string, string> = useMemo(
    () => ({
      [AnalysisDefaultFilter.NONE]: t(
        'analytics:filters.options.expensesTypes.noExpenseType'
      ),
      ...generateKeyValuePairs(analysisUserFilters?.expenseTypes)
    }),
    [t, analysisUserFilters?.expenseTypes]
  );

  const projectsFallbackOptions: Record<string, string> = useMemo(
    () => ({
      [AnalysisDefaultFilter.NONE]: t(
        'analytics:filters.options.projects.noProjects'
      ),
      ...generateKeyValuePairs(analysisUserFilters?.projects)
    }),
    [t, analysisUserFilters?.projects]
  );

  const paymentFormsFallbackOptions: Record<string, string> = useMemo(
    () => ({
      [AnalysisDefaultFilter.NONE]: t(
        'analytics:filters.options.paymentForms.noPaymentMethods'
      ),
      ...generateKeyValuePairs(analysisUserFilters?.paymentForms)
    }),
    [t, analysisUserFilters?.paymentForms]
  );

  const costCentersFallbackOptions: Record<string, string> = useMemo(
    () => ({
      [AnalysisDefaultFilter.NONE]: t(
        'analytics:filters.options.costCenters.noCostCenter'
      ),
      ...generateKeyValuePairs(analysisUserFilters?.costCenters)
    }),
    [analysisUserFilters?.costCenters, t]
  );

  /** Verifica se o filtro aplicado deve ser renderizado com base nas opções fornecidas. */
  function appliedFilterShouldBeRender(optionsToRender?: string[]): boolean {
    return (
      Boolean(optionsToRender?.length) &&
      !optionsToRender?.includes(AnalysisDefaultFilter.ALL)
    );
  }

  const appliedDateFilter = useMemo(() => {
    const fastDateKey = getSelectedFastDateFilter();

    if (!fastDateKey) return undefined;

    let appliedDateFilterValue;

    switch (fastDateKey) {
      case 'TODAY':
        appliedDateFilterValue = t('analytics:filters.today');
        break;
      case 'LAST_WEEK':
        appliedDateFilterValue = t('filters.lastDays', { days: '7' });
        break;
      case 'LAST_MONTH':
        appliedDateFilterValue = t('filters.lastDays', { days: '30' });
        break;
      case 'THIS_MONTH':
        appliedDateFilterValue = t('filters.thisMonth');
        break;
    }

    return { label: `${t('global:date')}:`, value: appliedDateFilterValue };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    analysisFilters.endDate,
    getSelectedFastDateFilter,
    analysisFilters.initialDate
  ]);

  const appliedFilters = useMemo(
    () =>
      [
        analysisFilters?.dateToConsider
          ? {
              label: `${t('analytics:filters.dateToConsider')}:`,
              value: dateToConsiderOptions[analysisFilters?.dateToConsider]
            }
          : null,
        ...(appliedDateFilter
          ? [appliedDateFilter]
          : [
              analysisFilters.initialDate
                ? {
                    label: `${t('analytics:filters.initialDate')}:`,
                    value: CustomDate.formatFromISO(analysisFilters.initialDate)
                  }
                : null,
              analysisFilters.endDate
                ? {
                    label: `${t('analytics:filters.finalDate')}:`,
                    value: CustomDate.formatFromISO(analysisFilters.endDate)
                  }
                : null
            ]),
        appliedFilterShouldBeRender(analysisFilters.users)
          ? {
              label: `${t('global:user', { count: 2 })}:`,
              value: getAppliedFilterValue(
                analysisFilters.users,
                generateKeyValuePairs(analysisUserFilters?.users),
                userDefaultOptions
              )
            }
          : null,
        analysisFilters.orderBy
          ? {
              label: `${t('analytics:filters.sortUsers')}:`,
              value: orderByOptions[analysisFilters.orderBy]
            }
          : null,
        appliedFilterShouldBeRender(analysisFilters.expensesStatus)
          ? {
              label: `${t('analytics:filters.expensesStatus')}:`,
              value: getAppliedFilterValue(
                analysisFilters.expensesStatus,
                expensesStatusFallbackOptions,
                undefined,
                'female'
              )
            }
          : null,
        appliedFilterShouldBeRender(analysisFilters.expensesTypes)
          ? {
              label: `${t('analytics:filters.expensesType')}:`,
              value: getAppliedFilterValue(
                analysisFilters.expensesTypes,
                expensesTypesFallbackOptions
              )
            }
          : null,
        appliedFilterShouldBeRender(analysisFilters.projects)
          ? {
              label: `${t('analytics:filters.projects')}:`,
              value: getAppliedFilterValue(
                analysisFilters.projects,
                projectsFallbackOptions
              )
            }
          : null,
        appliedFilterShouldBeRender(analysisFilters.paymentForms)
          ? {
              label: `${t('analytics:filters.paymentMethods')}:`,
              value: getAppliedFilterValue(
                analysisFilters.paymentForms,
                paymentFormsFallbackOptions
              )
            }
          : null,
        appliedFilterShouldBeRender(analysisFilters.costCenters)
          ? {
              label: `${t('analytics:filters.costCenters')}:`,
              value: getAppliedFilterValue(
                analysisFilters.costCenters,
                costCentersFallbackOptions
              )
            }
          : null,
        analysisFilters.reimbursableNonReimbursable
          ? {
              value:
                reimbursableNonReimbursableOptions[
                  analysisFilters.reimbursableNonReimbursable
                ]
            }
          : null,
        appliedFilterShouldBeRender(analysisFilters.reportCodes)
          ? {
              label: `${t('analytics:filters.reportCode')}:`,
              value: getAppliedFilterValue(
                analysisFilters.reportCodes,
                generateKeyValuePairs(analysisUserReports, 'id', 'id')
              )
            }
          : null
      ].filter(
        filter => !!filter
      ) as IUseUserAnalysisAppliedFilters['appliedFilters'],
    [
      t,
      orderByOptions,
      appliedDateFilter,
      userDefaultOptions,
      analysisUserReports,
      analysisFilters.users,
      dateToConsiderOptions,
      getAppliedFilterValue,
      analysisFilters.endDate,
      analysisFilters.orderBy,
      projectsFallbackOptions,
      analysisFilters.projects,
      analysisUserFilters?.users,
      costCentersFallbackOptions,
      analysisFilters.initialDate,
      analysisFilters.costCenters,
      analysisFilters.reportCodes,
      paymentFormsFallbackOptions,
      analysisFilters.paymentForms,
      expensesTypesFallbackOptions,
      analysisFilters.expensesTypes,
      expensesStatusFallbackOptions,
      analysisFilters.expensesStatus,
      analysisFilters?.dateToConsider,
      reimbursableNonReimbursableOptions,
      analysisFilters.reimbursableNonReimbursable
    ]
  );

  /**
   * Gera um objeto contendo pares de chave-valor a partir de um array de objetos,
   * utilizando as chaves especificadas para identificar o valor a ser associado a cada chave.
   *
   * @template T Tipo dos objetos no array, parcialmente estendendo um objeto com propriedades 'id' e 'name'.
   * @param {T[] | undefined} options Array de objetos a serem processados ou undefined.
   * @param {keyof T} [keyOut='id'] Chave do objeto de saída, padrão é 'id'.
   * @param {keyof T} [valueKey='name'] Chave do objeto de entrada a ser utilizada como valor, padrão é 'name'.
   * @returns {Record<string, string>} Objeto contendo pares de chave-valor.
   */
  function generateKeyValuePairs<
    T extends Partial<{ id: number; name: string }>
  >(
    options: T[] | undefined,
    keyOut: keyof T = 'id',
    valueKey: keyof T = 'name'
  ): Record<string, string> {
    return (options ?? []).reduce(
      (acc, option) => ({
        ...acc,
        [option[keyOut] as string]: option[valueKey]
      }),
      {}
    );
  }

  function handleEditFilters(): void {
    handleOpenModal('analysisFilter');
  }

  return { appliedFilters, handleEditFilters };
}
