import { Pagination } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useLangContext } from 'data/contexts';

import {
  Container,
  StyledControl,
  StyledPaginationContainer
} from './TablePagination.styles';

export function TablePagination(): JSX.Element {
  const [pagination, tableViewMode, handleChangePagination] =
    useCardGroupsContext(
      useShallow(state => [
        state.pagination,
        state.tableViewMode,
        state.handleChangePagination
      ])
    );

  const currentLangKey = useLangContext(state => state.currentLangKey);

  return (
    <Container>
      <StyledPaginationContainer
        $isCardsMode={tableViewMode === 'GROUP_CARDS'}
        currentPage={pagination.page}
        perPage={pagination.limit}
        totalPage={
          pagination.total ? Math.ceil(pagination.total / pagination.limit) : 1
        }
      >
        <Pagination.Label langKey={currentLangKey} />

        <StyledControl
          onChangePage={page => {
            handleChangePagination({ page });
          }}
        />

        <Pagination.Pages
          langKey={currentLangKey}
          onChangePerPage={perPage => {
            handleChangePagination({
              limit: perPage
            });
          }}
          options={[10, 20, 50]}
        />
      </StyledPaginationContainer>
    </Container>
  );
}
