import { DefaultButton, SecondaryButton } from 'ds';
import { useTranslation } from 'react-i18next';

import { Icons } from 'shared/constants/global';

import { type ISuggestionSentProps } from './SuggestionSent.types';

import { Actions, Container, Title } from './SuggestionSent.styles';

export function SuggestionSent({
  onSentAnotherClick,
  onBackToStartClick,
  ...props
}: ISuggestionSentProps): JSX.Element {
  const { t } = useTranslation('analytics');

  return (
    <Container {...props}>
      <Icons.IllustrationAnalyticsSuggestionSentIcon />
      <Title>{t('suggestionSent.title')}</Title>
      <Actions>
        <SecondaryButton
          size='small'
          onClick={onSentAnotherClick}
        >
          {t('suggestionSent.sentAnother')}
        </SecondaryButton>
        <DefaultButton
          size='small'
          onClick={onBackToStartClick}
        >
          {t('suggestionSent.backToStart')}
        </DefaultButton>
      </Actions>
    </Container>
  );
}
