import { useInfiniteQueryCache } from 'data/cache';
import {
  CardGroupsQueryKeys,
  CardGroupsService,
  type IInfiniteCardGroupManagersParams,
  type IUseInfiniteCardGroupManagers
} from 'data/modules/cards/cardGroups';

export function useInfiniteCardGroupManagers(
  params: IInfiniteCardGroupManagersParams
): IUseInfiniteCardGroupManagers {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQueryCache({
      queryKey: [CardGroupsQueryKeys.GET_GROUP_MANAGERS, params],
      initialPageParam: 1,
      queryFn: async ({ pageParam }) =>
        await CardGroupsService.getGroupManagers({
          ...params,
          page: pageParam
        }),
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        const totalPages = Math.ceil(lastPage.total) / params.limit;
        const isLastPage = allPages.length >= totalPages;

        if (isLastPage) {
          return null;
        }

        return lastPageParam + 1;
      }
    });

  return {
    infiniteCardGroupManagers: data?.pages.flatMap(page => page.data),
    isLoadingInfiniteCardGroupManagers: isLoading,
    cardGroupManagersPagination: {
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage
    }
  };
}
