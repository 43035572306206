import { useLangContext } from 'data/contexts';

import {
  type IUseTariffsTableBody,
  type TariffsItemType
} from './TariffsTableBody.types';

export default function useTariffsTableBody(): IUseTariffsTableBody {
  const [settings, currentLangKey] = useLangContext(state => [
    state.lang.settings,
    state.currentLangKey
  ]);

  const tariffs: TariffsItemType[] = [
    {
      tariff: settings.tariffs.withdrawal[currentLangKey],
      value: 'R$ 7,00',
      event: settings.tariffs.by_withdrawal[currentLangKey],
      charge: settings.tariffs.withdrawal_charge[currentLangKey]
    },
    {
      tariff: settings.tariffs.ted_out[currentLangKey],
      value: 'R$ 5,00',
      event: settings.tariffs.by_ted[currentLangKey],
      charge: settings.tariffs.ted_charge[currentLangKey]
    },
    {
      tariff: settings.tariffs.iof_it[currentLangKey],
      value: '4,38%',
      event: settings.tariffs.by_iof_it[currentLangKey],
      charge: settings.tariffs.iof_it_charge[currentLangKey]
    },
    {
      tariff: settings.tariffs.tariff_it[currentLangKey],
      value: '4%',
      event: settings.tariffs.by_tariff_it[currentLangKey],
      charge: settings.tariffs.tariff_it_charge[currentLangKey]
    }
  ];

  return {
    tariffs
  };
}
