import { useLangContext, useModalContext } from 'data/contexts';

import { Spinner } from 'presentation/components/base/Spinner';
import {
  AddMemberModal,
  FinishModal,
  ManagersAndCardholdersControllers,
  ManagersAndCardholdersTable
} from 'presentation/pages/cards/Onboarding/components/Company/ManagersAndCardholders/ManagersAndCardholdersList';
import { OnboardingSubtitle } from 'presentation/pages/cards/Onboarding/components/General';

import { useManagersAndCardholdersList } from './useManagersAndCardholdersList';

import {
  Container,
  CustomLoadingOverlay
} from './ManagersAndCardholdersList.styles';

export function ManagersAndCardholdersList(): JSX.Element {
  const { handleOpenModal, handleCloseModal, visibleModal } = useModalContext();

  const {
    managersAndCardholders,
    isManagersAndCardholdersLoading,
    paginationData,
    handleChangePage,
    handleChangePageSize,
    searchTerm,
    handleChangeSearchTerm,
    handleAddMemberRedirection,
    handleUpdateMembersList,
    handleChangeMemberSettings,
    handleToggleViewSelectedMembers,
    isViewSelectedMembersEnabled,
    handleConfirmMembersConfiguration
  } = useManagersAndCardholdersList();

  const [currentLangKey, managersAndCardholdersList] = useLangContext(state => [
    state.currentLangKey,
    state.lang.cards.onboarding.company.managers_and_cardholders
      .managers_and_cardholders_list
  ]);

  return (
    <Container>
      <ManagersAndCardholdersControllers
        searchTerm={searchTerm}
        onSearchTermChange={handleChangeSearchTerm}
        onAddMember={() => {
          handleOpenModal('companyOnboardingAddMember');
        }}
        isViewSelectedMembersEnabled={isViewSelectedMembersEnabled}
        onViewSelected={handleToggleViewSelectedMembers}
        onFinish={() => {
          handleOpenModal('confirmation');
        }}
      />

      {isManagersAndCardholdersLoading ? (
        <CustomLoadingOverlay>
          <Spinner
            color='royalBlue'
            size={4}
          />
        </CustomLoadingOverlay>
      ) : managersAndCardholders.length === 0 ? (
        <OnboardingSubtitle>
          {managersAndCardholdersList.no_users_found[currentLangKey]}
        </OnboardingSubtitle>
      ) : (
        <ManagersAndCardholdersTable
          managersAndCardholders={managersAndCardholders}
          pagination={{
            ...paginationData,
            onChangePage: handleChangePage,
            onChangePageSize: handleChangePageSize,
            isLoading: isManagersAndCardholdersLoading
          }}
          onChangeMemberSettings={handleChangeMemberSettings}
        />
      )}

      {visibleModal === 'companyOnboardingAddMember' && (
        <AddMemberModal
          isOpen
          onClose={handleCloseModal}
          onAddMemberRedirect={handleAddMemberRedirection}
          onUpdateMembersList={handleUpdateMembersList}
        />
      )}

      {visibleModal === 'confirmation' && (
        <FinishModal
          isOpen
          onClose={handleCloseModal}
          onConfirm={handleConfirmMembersConfiguration}
        />
      )}
    </Container>
  );
}
