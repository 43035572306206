import { useQueryCache } from 'data/cache';
import {
  type IUseGetTeamMembersPositionOptions,
  TeamMembersQueryKeys,
  TeamMembersService
} from 'data/modules/teamMembers';

export function useGetTeamMembersPositionOptions(): IUseGetTeamMembersPositionOptions {
  const { data, isLoading } = useQueryCache({
    queryKey: [TeamMembersQueryKeys.GET_TEAM_MEMBERS_POSITION_OPTIONS],
    queryFn: async () =>
      await TeamMembersService.getTeamMembersPositionOptions()
  });

  return {
    isLoadingPositionOptions: isLoading,
    positionOptions: data ?? []
  };
}
