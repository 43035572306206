import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { DsDefaultButton, DsGhostButton } from 'presentation/ds/DsButton';
import { DsModal } from 'presentation/ds/DsModal';
import { OnboardingText } from 'presentation/pages/cards/Onboarding/components/General';

import { type IFinishModalProps } from './FinishModal.types';

import {
  Container,
  Divider,
  FooterContainer,
  IconContainer
} from './FinishModal.styles';

export function FinishModal({
  isOpen,
  onClose,
  onConfirm
}: IFinishModalProps): JSX.Element {
  const [global, finishModal, currentLangKey] = useLangContext(state => [
    state.lang.global,
    state.lang.cards.onboarding.company.managers_and_cardholders
      .managers_and_cardholders_list.finish_modal,
    state.currentLangKey
  ]);

  return (
    <DsModal
      title=''
      isOpen={isOpen}
      onClose={onClose}
      hasCloseButton={false}
    >
      <Container>
        <IconContainer>
          <BaseIcon
            name='error-warning'
            size={4}
          />
        </IconContainer>

        <OnboardingText>
          {finishModal.message[currentLangKey]}
          <b>{finishModal.managers_label[currentLangKey]}</b>
          {finishModal.conjunction[currentLangKey]}
          <b>{finishModal.cardholders_label[currentLangKey]}</b>
        </OnboardingText>

        <Divider />

        <FooterContainer>
          <DsGhostButton
            text={global.back[currentLangKey]}
            onClick={onClose}
          />
          <DsDefaultButton
            text={global.confirm[currentLangKey]}
            onClick={onConfirm}
          />
        </FooterContainer>
      </Container>
    </DsModal>
  );
}
