import { useGetCardDataByAccountId } from 'data/modules/cards/account';
import { useGetBalancesAndAccount } from 'data/modules/cards/balance/useCases/get-balances-and-account/useGetBalancesAndAccount';

import {
  type IUseGetCardDataByBalanceId,
  type IUseGetCardDataByBalanceIdProps
} from './useGetCardDataByBalanceId.types';

export function useGetCardDataByBalanceId({
  cardInfo,
  enable
}: IUseGetCardDataByBalanceIdProps): IUseGetCardDataByBalanceId {
  const { balancesAndAccount, isFetchingBalancesAndAccount } =
    useGetBalancesAndAccount({
      balanceId: cardInfo.balanceId,
      enabled: enable
    });

  const { cardData, isFetchingCardData } = useGetCardDataByAccountId({
    accountId: balancesAndAccount?.account.id as string,
    enabled: !isFetchingBalancesAndAccount && enable
  });

  const data = {
    balanceId: cardInfo.balanceId,
    accountId: balancesAndAccount?.account?.id,
    name: cardInfo.name,
    number: cardData?.panMasked || '',
    status: cardData?.status,
    userId: cardInfo.userId
  };

  return {
    cardData: data,
    isLoadingCardData: isFetchingBalancesAndAccount || isFetchingCardData
  };
}
