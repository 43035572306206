import { faker } from '@faker-js/faker';

import {
  type CardLockLevelType,
  type ICardData,
  type StatusType
} from 'data/modules/cards/account';

import { MockApi } from 'shared/utils/global';

import { cardLockLevelOptions, statusOptions } from '..';

export async function getCardDataByAccountIdMock(): Promise<ICardData | null> {
  const data = {
    id: faker.string.uuid(),
    panMasked: '231024******1469',
    status: faker.helpers.arrayElement(statusOptions) as StatusType,
    lockLevel: faker.helpers.arrayElement(
      cardLockLevelOptions
    ) as CardLockLevelType
  };

  await MockApi.sleep(2000);

  return data;
}
