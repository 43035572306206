import { BaseIcon } from 'presentation/components/base/Icon';
import { SettingSidebar } from 'presentation/components/base/SettingsSidebar';
import useSidebarGroup from 'presentation/components/base/SettingsSidebar/SettingsSidebarGroup/useSidebarGroup';

import { useBoolean } from 'shared/hooks/global';

import { type ISettingsSidebarGroupProps } from './SettingsSidebarGroup.types';

import {
  Container,
  GroupTitle,
  SubItemsContainer
} from './SettingsSidebarGroup.styles';

export function SettingsSidebarGroup({
  title,
  subItems,
  listLevel = 0
}: ISettingsSidebarGroupProps): JSX.Element {
  const [isSubItemsOpen, handleToggleSubItems] = useBoolean(true);

  const { countSubItems } = useSidebarGroup();

  return (
    <Container>
      <GroupTitle
        $listLevel={listLevel}
        $isOpen={isSubItemsOpen}
        onClick={handleToggleSubItems}
      >
        <BaseIcon
          size={1.5}
          type='line'
          color='mineShaft'
          name='arrow-down-s'
        />

        <span>{title}</span>
      </GroupTitle>

      <SubItemsContainer
        $isOpen={isSubItemsOpen}
        // Multiplica pelo tamanho de cada item: 4rem
        $maxHeight={countSubItems(subItems) * 4}
      >
        {subItems?.map(subItem => (
          <SettingSidebar.SubItems
            {...subItem}
            key={subItem.title}
            listLevel={listLevel + 1}
          />
        ))}
      </SubItemsContainer>
    </Container>
  );
}
