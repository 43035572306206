import { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type MemberShareholderType,
  type ShareholderType
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import { type IUseNaturalPersonType } from './NaturalPersonType.types';

export function useNaturalPersonType(): IUseNaturalPersonType {
  const queryClient = useQueryClient();

  const { state } = useLocation();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const selectedShareholder = queryClient.getQueryData<MemberShareholderType>([
    'selectedShareholder'
  ]);

  const [error, setError] = useState('');
  const [selectedNaturalPersonType, setSelectedNaturalPersonType] = useState<
    ShareholderType | undefined
  >(state?.shareholderType ?? selectedShareholder?.shareholderType);

  const navigate = useNavigate();

  const { data: cachedCompanyOnboardingShareholders } = useHandleQueryCache({
    query: {
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_SHAREHOLDERS],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingShareholders()
    }
  });

  const [currentLangKey, schemas] = useLangContext(state => [
    state.currentLangKey,
    state.lang.schemas
  ]);

  function handleNavigateNextStep(): void {
    if (selectedNaturalPersonType) {
      setError('');

      navigate(
        `/cartoes/onboarding/empresa/${currentStep}/dados-pessoa-fisica`,
        {
          state: {
            shareholderType: selectedNaturalPersonType
          }
        }
      );
    } else {
      setError(schemas.is_required[currentLangKey]);
    }
  }

  useEffect(() => {
    if (!cachedCompanyOnboardingShareholders) {
      navigate(`/cartoes/onboarding/empresa/${currentStep}`, { replace: true });
    }
  }, [currentStep, navigate, cachedCompanyOnboardingShareholders]);

  return {
    handleSelectNaturalPersonType: setSelectedNaturalPersonType,
    isEditMode: Boolean(selectedShareholder),
    selectedNaturalPersonType:
      selectedNaturalPersonType ?? selectedShareholder?.shareholderType,
    handleNavigateNextStep,
    error
  };
}
