import { useLangContext } from 'data/contexts';

import { DsDefaultButton, DsGhostButton } from 'presentation/ds/DsButton';
import { DsSearch } from 'presentation/ds/DsSearch';

import { type IManagersAndCardholdersControllersProps } from './ManagersAndCardholdersControllers.types';

import {
  Container,
  ControllersContainer,
  SearchContainer
} from './ManagersAndCardholdersControllers.styles';

export function ManagersAndCardholdersControllers({
  searchTerm,
  isViewSelectedMembersEnabled,
  onSearchTermChange,
  onAddMember,
  onViewSelected,
  onFinish
}: IManagersAndCardholdersControllersProps): JSX.Element {
  const [controllers, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.company.managers_and_cardholders
      .managers_and_cardholders_list.controllers,
    state.currentLangKey
  ]);

  return (
    <Container>
      <SearchContainer>
        <DsSearch
          label={controllers.searchboxLabel[currentLangKey]}
          name='searchManagersAndCardholders'
          value={searchTerm}
          onChange={e => {
            onSearchTermChange(e.target.value);
          }}
          onClear={() => {
            onSearchTermChange(undefined);
          }}
        />
      </SearchContainer>

      <ControllersContainer>
        <DsGhostButton
          text={controllers.addMemberLabel[currentLangKey]}
          iconProps={{
            name: 'user-add',
            type: 'line',
            size: 2.4
          }}
          iconSide='left'
          onClick={onAddMember}
        />

        <DsGhostButton
          text={controllers.viewSelectedLabel[currentLangKey]}
          iconProps={{
            name: isViewSelectedMembersEnabled ? 'eye-off' : 'eye',
            type: 'line',
            size: 2.4
          }}
          iconSide='left'
          onClick={onViewSelected}
        />

        <DsDefaultButton
          text={controllers.finishLabel[currentLangKey]}
          iconProps={{
            name: 'save-3',
            size: 2.4
          }}
          iconSide='left'
          onClick={onFinish}
        />
      </ControllersContainer>
    </Container>
  );
}
