import { Controller, useFormContext } from 'react-hook-form';

import { type ISwitchControlledProps } from './SwitchControlled.types';

import {
  Container,
  ErrorMessage,
  Label,
  StyledSwitch,
  StyledThumb
} from './SwitchControlled.styles';

export function SwitchControlled({
  label,
  disabled = false,
  name
}: ISwitchControlledProps): JSX.Element {
  const {
    formState: { errors },
    control
  } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ field: { onChange, value } }) => (
          <Container $hasError={!!errors[name]?.message}>
            {label && <Label>{label}</Label>}

            <StyledSwitch
              checked={value}
              onCheckedChange={onChange}
              disabled={disabled}
              value={value ? 'on' : 'off'}
            >
              <StyledThumb />
            </StyledSwitch>
          </Container>
        )}
      />

      {errors[name]?.message && (
        <ErrorMessage>{errors[name]?.message as string}</ErrorMessage>
      )}
    </>
  );
}
