import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { vexDomain } from 'data/config';
import { useAnalysisUserFiltersContext, useAuthContext } from 'data/contexts';
import {
  type IAnalysisResumeCard,
  useGetAnalysisResume,
  useGetAnalysisUserHeader
} from 'data/modules/analytics';

import { useUserExpenses } from 'presentation/pages/analytics/Analysis/UserExpenses/useUserExpenses';

import { Currency } from 'shared/utils/format';

import {
  type IUseUserAnalysisResume,
  type IUseUserAnalysisResumeParams
} from './UserAnalysisResume.types';

export function useUserAnalysisResume({
  isFullResume
}: IUseUserAnalysisResumeParams): IUseUserAnalysisResume {
  const { t } = useTranslation(['analytics', 'global']);

  const company = useAuthContext(state => state.company);

  const [
    perPage,
    currentPage,
    handleChangePagination,
    handleChangeIsEmptyResults
  ] = useAnalysisUserFiltersContext(
    useShallow(state => [
      state.perPage,
      state.currentPage,
      state.handleChangePagination,
      state.handleChangeIsEmptyResults
    ])
  );

  const { payload } = useUserExpenses();

  const { resume, isLoadingAnalysisResume } = useGetAnalysisResume(payload);

  const { analysisUserHeader, pagination, isLoadingAnalysisUserHeader } =
    useGetAnalysisUserHeader({
      ...payload,
      perPage,
      page: currentPage
    });

  const currencyExchangeKey = useMemo(
    () =>
      company?.parameters.usesExchangeToInsertExpenses !== null &&
      company?.parameters.usesExchangeToInsertExpenses !== 'NAO_UTILIZA'
        ? company?.parameters.usesCards
          ? 'convertedSumWithCard'
          : 'convertedSum'
        : resume?.hasPendingConversion
          ? 'hasPendingConversion'
          : null,
    [
      company?.parameters.usesCards,
      company?.parameters.usesExchangeToInsertExpenses,
      resume?.hasPendingConversion
    ]
  );

  const resumeCards: IAnalysisResumeCard[] = useMemo(
    () => [
      {
        tooltip:
          currencyExchangeKey !== null
            ? t(`userSummaryCard.tooltips.${currencyExchangeKey}`)
            : undefined,
        showLeftIcon: currencyExchangeKey === 'hasPendingConversion',
        title: t('userSummaryCard.first'),
        value: Currency.format('BRL', resume?.sumExpenses ?? 0, true),
        caption: t('userSummaryCard.expensesCount', {
          count: resume?.totalExpenses ?? 0,
          counter: resume?.totalExpenses ?? 0
        })
      },
      {
        tooltip:
          currencyExchangeKey === 'hasPendingConversion'
            ? t('userSummaryCard.tooltips.unaccountedValues')
            : undefined,
        showLeftIcon: currencyExchangeKey === 'hasPendingConversion',
        title: t('userSummaryCard.second'),
        value: Currency.format('BRL', resume?.sumRefundable ?? 0, true),
        caption: t('userSummaryCard.expensesCount', {
          count: resume?.totalRefundable ?? 0,
          counter: resume?.totalRefundable ?? 0
        })
      },
      {
        tooltip:
          currencyExchangeKey === 'hasPendingConversion'
            ? t('userSummaryCard.tooltips.unaccountedValues')
            : undefined,
        showLeftIcon: currencyExchangeKey === 'hasPendingConversion',
        title: t('userSummaryCard.third'),
        value: Currency.format('BRL', resume?.sumNoRefundable ?? 0, true),
        caption: t('userSummaryCard.expensesCount', {
          count: resume?.totalNoRefundable ?? 0,
          counter: resume?.totalNoRefundable ?? 0
        })
      },
      {
        title: t('userSummaryCard.fourth'),
        value: `${resume?.userWithExpense ?? 0} ${t('global:user', { count: resume?.userWithExpense ?? 0 })}`,
        caption: t('userSummaryCard.registeredInCompany', {
          count: resume?.totalUsers ?? 0,
          counter: resume?.totalUsers ?? 0
        })
      },
      {
        title: t('userSummaryCard.fifth'),
        value: Currency.format('BRL', resume?.averagePerExpense ?? 0, true),
        caption: t('userSummaryCard.inReports', {
          count: resume?.totalReports ?? 0,
          counter: resume?.totalReports ?? 0
        })
      }
    ],
    [
      resume?.averagePerExpense,
      resume?.sumExpenses,
      resume?.sumNoRefundable,
      resume?.sumRefundable,
      resume?.totalExpenses,
      resume?.totalNoRefundable,
      resume?.totalRefundable,
      resume?.totalReports,
      resume?.totalUsers,
      resume?.userWithExpense,
      t,
      currencyExchangeKey
    ]
  );

  const isResumeCardsEmpty = useMemo(
    () =>
      !resume?.sumExpenses &&
      !resume?.sumRefundable &&
      !resume?.sumNoRefundable &&
      !resume?.userWithExpense &&
      !resume?.averagePerExpense,
    [
      resume?.averagePerExpense,
      resume?.sumExpenses,
      resume?.sumNoRefundable,
      resume?.sumRefundable,
      resume?.userWithExpense
    ]
  );

  useEffect(() => {
    if (!isFullResume) handleChangePagination({ perPage: 10 });
  }, [handleChangePagination, isFullResume]);

  useEffect(() => {
    if (pagination?.lastPage)
      handleChangePagination({ totalPages: pagination?.lastPage });
  }, [handleChangePagination, pagination]);

  useEffect(() => {
    handleChangeIsEmptyResults(
      !isLoadingAnalysisResume &&
        !isLoadingAnalysisUserHeader &&
        !analysisUserHeader.length &&
        isResumeCardsEmpty
    );
  }, [
    isResumeCardsEmpty,
    isLoadingAnalysisResume,
    analysisUserHeader.length,
    handleChangeIsEmptyResults,
    isLoadingAnalysisUserHeader
  ]);

  function handleActionClick(): void {
    window.location.href = `${vexDomain}/admin/moeda-estrangeira`;
  }

  return {
    resumeCards,
    handleActionClick,
    isLoadingAnalysisResume,
    isLoadingAnalysisUserHeader,
    userAnalysisSummary: isFullResume
      ? analysisUserHeader
      : [...analysisUserHeader].slice(0, 5),
    showInformationRow: !isFullResume && (pagination?.total ?? 0) > 5,
    hasPendingConversion: currencyExchangeKey === 'hasPendingConversion'
  };
}
