import { Checkbox, SkeletonLoader, TextInput } from 'ds';
import { useTranslation } from 'react-i18next';

import { useInfiniteScrollSelectMembers } from './useInfiniteScrollSelectMembers';

import { type IInfiniteScrollSelectMembersProps } from './InfiniteScrollSelectMembers.types';

import {
  Container,
  ListItemLoading,
  MemberCountContainer,
  MemberListContainer,
  MembersCount,
  NoMembersMessage
} from './InfiniteScrollSelectMembers.styles';

export function InfiniteScrollSelectMembers({
  linkUnlinkMembersStepState,
  onClickOnMember,
  onClickSelectAllMembers
}: IInfiniteScrollSelectMembersProps): JSX.Element {
  const { t } = useTranslation(['fuel', 'global']);

  const {
    infiniteScrollRef,
    rootRef,
    infiniteMembers,
    isFetchingInfiniteMembersNextPage,
    handleChangeSearch,
    isLoadingInfiniteMembers,
    showSelectAll,
    search,
    selectedMembers,
    setSelectedMembers,
    totalMembers
  } = useInfiniteScrollSelectMembers();

  return (
    <Container>
      <TextInput.Default
        small
        label={t('modal.searchMembers')}
        onChangeValue={value => {
          handleChangeSearch(value);
        }}
        value={search}
        iconLeft='SearchBigIcon'
        placeholder={t('modal.searchMembers')}
      />

      <MemberListContainer ref={rootRef}>
        {showSelectAll && (
          <MemberCountContainer className='checkbox-container-select-all'>
            <Checkbox.Default
              small
              label={t('modal.selectAll')}
              value={linkUnlinkMembersStepState.all}
              onChangeValue={() => {
                onClickSelectAllMembers();
                setSelectedMembers(
                  linkUnlinkMembersStepState.all ? 0 : totalMembers
                );
              }}
            />

            <MembersCount>
              ({selectedMembers ?? 0} / {totalMembers})
            </MembersCount>
          </MemberCountContainer>
        )}

        {!isLoadingInfiniteMembers &&
          Array.isArray(infiniteMembers) &&
          infiniteMembers.length > 0 &&
          infiniteMembers.map(member => {
            // vai estar marcado se:
            // 1. O all está marcado
            // 2. O attachMembers possui o ID do membro
            // 3. O detachMembers tem algum elemento e não é o ID do membro em questão
            const checked =
              linkUnlinkMembersStepState.all ||
              linkUnlinkMembersStepState.attachMembersIds.has(member.id) ||
              (linkUnlinkMembersStepState.detachMembersIds.size > 0 &&
                !linkUnlinkMembersStepState.detachMembersIds.has(member.id));

            return (
              <div
                key={`member-${member.id}`}
                className='checkbox-container'
              >
                <Checkbox.Default
                  small
                  label={member.name}
                  value={checked}
                  onChangeValue={() => {
                    onClickOnMember(member.id);
                    setSelectedMembers(oldValue =>
                      checked ? oldValue - 1 : oldValue + 1
                    );
                  }}
                />
              </div>
            );
          })}

        {!isLoadingInfiniteMembers && infiniteMembers?.length === 0 && (
          <NoMembersMessage>{t('fuel:modal.noMembersFound')}</NoMembersMessage>
        )}

        {isLoadingInfiniteMembers &&
          Array.from({ length: 4 }).map((v, index) => (
            <SkeletonLoader
              key={index}
              height='41px'
              loaderClassName='skeleton-loader'
            />
          ))}

        {isFetchingInfiniteMembersNextPage && (
          <ListItemLoading>{t('global:loadingMoreItems')}...</ListItemLoading>
        )}

        <div ref={infiniteScrollRef} />
      </MemberListContainer>
    </Container>
  );
}
