import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IChangeBusinessCardTeamMembersFieldsForm } from 'data/modules/cards/businessCard';

export function useChangeBusinessCardTeamMembersSchema(): ObjectSchema<IChangeBusinessCardTeamMembersFieldsForm> {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const changeBusinessCardTeamMembersSchema: ObjectSchema<IChangeBusinessCardTeamMembersFieldsForm> =
    useMemo(() => {
      return yup.object({
        teamMembers: yup
          .array(yup.string())
          .required(lang.schemas.is_required[currentLangKey])
      });
    }, [lang, currentLangKey]);

  return changeBusinessCardTeamMembersSchema;
}
