import { Outlet } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useAuthContext } from 'data/contexts';

import { FallbackPage } from 'presentation/pages/global';

export function UserOnboardingMiddleware(): JSX.Element {
  const [company, user] = useAuthContext(
    useShallow(state => [state.company, state.user])
  );

  if (!company?.cards?.onboardingCompletedAt) {
    return <FallbackPage error='401' />;
  }

  if (
    user?.cards?.isManagerWithoutAccount ||
    user?.cards?.isOnboardingFinished ||
    user?.cards?.type === 'VIRTUAL' ||
    user?.cards?.isCardGroupManagerWithoutAccount
  ) {
    return <FallbackPage error='401' />;
  }

  return <Outlet />;
}
