import { DefaultTable, tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  td.center {
    text-align: center;
  }

  .header-icon {
    width: 2rem;
    height: 2rem;
    color: ${tokens.brandColorPrimaryPure};
  }

  .table-icon {
    width: 2.4rem;
    height: 2.4rem;
  }

  .table-icon.invoice-minus {
    color: ${tokens.highlightColorMedium};
  }

  .table-icon.credit-card-invoice-list {
    color: ${tokens.neutralColorLowDark};
  }

  .table-icon.invoice-add-plus {
    color: ${tokens.brandColorPrimaryPure};
  }

  .table-icon.invoice-checkmark {
    color: ${tokens.feedbackColorSuccessPure};
  }

  .table-icon.invoice-remove-cross {
    color: ${tokens.feedbackColorErrorPure};
  }

  .no-rounded-thead {
    tr th {
      &:first-of-type {
        border-top-left-radius: 0;
      }

      &:last-of-type {
        border-top-right-radius: 0;
      }
    }
  }

  .rounded-tr {
    &:last-child td:first-child {
      border-bottom-left-radius: ${tokens.borderRadiusMd};
    }

    &:last-child td:last-child {
      border-bottom-right-radius: ${tokens.borderRadiusMd};
    }
  }

  .mcc-icon {
    width: 2.4rem;
    height: 2.4rem;
    color: ${tokens.neutralColorLowDark} !important;
  }

  .centralized-td {
    div {
      display: flex;
      align-items: center;
      gap: ${tokens.spacingXs};
      height: fit-content;
    }

    span {
      max-width: 70%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .pendency-td {
    div {
      display: flex;
      align-items: center;
      gap: ${tokens.spacingNano};
      height: fit-content;
    }
  }

  .underlined {
    text-decoration: line-through;
    color: ${tokens.neutralColorLowMedium};
  }

  .hidden-th {
    visibility: hidden;
  }

  span {
    strong {
      font-size: ${tokens.fontSizeXxss};
      font-weight: ${tokens.fontWeightSemibold};
    }
  }
`;

export const TableContainer = styled.div`
  max-width: 100%;
  overflow: auto;
`;

export const StyledTh = styled.div`
  display: flex;
  gap: 0.8rem;

  div {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    span {
      color: ${tokens.neutralColorLowMedium};
      font-weight: ${tokens.fontWeightSemibold};
    }

    strong {
      font-size: ${tokens.fontSizeXxs};
      font-weight: ${tokens.fontWeightSemibold};
    }
  }
`;

export const StyledTableContainer = styled(DefaultTable.Container)`
  table-layout: auto;
`;

export const StyledTd = styled(DefaultTable.Td)`
  color: ${tokens.neutralColorLowMedium};
  font-weight: ${tokens.fontWeightMedium};
`;

export const StyledLimitedWidthTd = styled(DefaultTable.Td)`
  max-width: 45rem;
`;

export const MessageContainer = styled.div`
  background: ${tokens.neutralColorHighPure};
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  padding: ${tokens.spacingXs};
  border-radius: ${tokens.borderRadiusMd};
  align-items: center;
  color: ${tokens.neutralColorLowMedium};
  text-align: center;

  p {
    font-size: ${tokens.fontSizeXs};
    font-weight: ${tokens.fontWeightSemibold};
  }

  span {
    font-size: ${tokens.fontSizeXxss};
    font-weight: ${tokens.fontWeightMedium};
  }
`;
