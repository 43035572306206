import { useQueryClient } from '@tanstack/react-query';

import { ExpensesQueryKeys } from 'data/modules/expenses/keys/expenses.keys';
import ExpensesService from 'data/modules/expenses/services/Expenses.service';
import {
  type IUseValidateMileagePolicyByProject,
  type IValidateMileagePolicyByProjectPayload,
  type IValidateMileagePolicyByProjectResponse
} from 'data/modules/expenses/types/expenses.types';

export function useValidateMileagePolicyByProject(): IUseValidateMileagePolicyByProject {
  const queryClient = useQueryClient();

  async function getEnsuredValidateMileagePolicyByProject(
    payload: IValidateMileagePolicyByProjectPayload
  ): Promise<IValidateMileagePolicyByProjectResponse | null> {
    try {
      const response = await queryClient.ensureQueryData({
        queryKey: [
          ExpensesQueryKeys.VALIDATE_MILEAGE_POLICY_BY_PROJECT,
          payload
        ],
        queryFn: async () =>
          await ExpensesService.validateMileagePolicyByProject(payload)
      });

      return response;
    } catch (e) {
      return null;
    }
  }

  return {
    getEnsuredValidateMileagePolicyByProject
  };
}
