enum CardGroupsQueryKeys {
  GET_CARD_GROUPS = 'GET_CARD_GROUPS',
  GET_CARD_GROUP = 'GET_CARD_GROUP',
  GET_USERS_WITHOUT_CARD_GROUP = 'GET_USERS_WITHOUT_CARD_GROUP',
  GET_CARD_GROUPS_DISTRIBUTED_VALUE = 'GET_CARD_GROUPS_DISTRIBUTED_VALUE',
  GET_ALL_MANAGERS = 'GET_ALL_MANAGERS',
  GET_AVAILABLE_TO_BE_MANAGER = 'GET_AVAILABLE_TO_BE_MANAGER',
  GET_PARTIAL_MANAGERS = 'GET_PARTIAL_MANAGERS',
  GET_GROUP_MANAGERS = 'GET_GROUP_MANAGERS',
  GET_GROUP_USERS = 'GET_GROUP_USERS',
  CHECK_IF_CARD_CAN_BE_REMOVED = 'CHECK_IF_CARD_CAN_BE_REMOVED',
  GET_ALLOCATE_BALANCE_CARD_GROUP = 'GET_ALLOCATE_BALANCE_CARD_GROUP',
  GET_MANAGERS_GROUPS = 'GET_MANAGERS_GROUPS',
  CHECK_IF_CARDS_CAN_BE_ADDED = 'CHECK_IF_CARDS_CAN_BE_ADDED'
}

enum CardGroupsMutationKeys {
  REMOVE_CARD_FROM_GROUP = 'REMOVE_CARD_FROM_GROUP',
  CREATE_CARD_GROUP = 'CREATE_CARD_GROUP',
  EDIT_CARD_GROUP_NAME = 'EDIT_CARD_GROUP_NAME',
  REMOVE_MANAGER_FROM_GROUP = 'REMOVE_MANAGER_FROM_GROUP',
  ADD_MANAGERS_TO_GROUP = 'ADD_MANAGERS_TO_GROUP',
  ADD_CARDS_TO_GROUP = 'ADD_CARDS_TO_GROUP'
}

export { CardGroupsMutationKeys, CardGroupsQueryKeys };
