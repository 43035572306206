import { useEffect, useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { vexDomain } from 'data/config';
import { useLangContext, useModalContext, useTourContext } from 'data/contexts';
import { useGetAuthUser } from 'data/modules/auth';
import {
  ExpensesQueryKeys,
  type IExpensePaginatedResponse,
  type IUseGetPaginatedExpensesParams,
  type StatusTabType,
  type StatusTabTypePersistenceType,
  useDeleteExpenses,
  useGetNumberOfExpensesByStatus,
  useGetPaginatedExpenses
} from 'data/modules/expenses';
import { ReportsQueryKeys } from 'data/modules/reports';

import {
  cookiesKeys,
  localStorageKeys,
  routesPathPrefix
} from 'shared/constants/global';
import { useDebounce } from 'shared/hooks/global';
import { CustomDate, CustomObject } from 'shared/utils/custom';
import { CustomCookies } from 'shared/utils/global';

import { useReportsPageTour } from './tours';

import {
  type CreateExpenseType,
  type ExpenseActionModalDataType,
  type ExpenseActionModalType,
  type ExpenseOrderByType,
  type IExpenseActionModalState,
  type IFilterAction,
  type IFilters,
  type IUpdateReportAndCostsCenterOfSelectedExpensesInCacheParams,
  type IUseReportsList
} from './ReportsList.types';

export function useReportsList(): IUseReportsList {
  const [expenseIndex, setExpenseIndex] = useState(-1);
  const [expenseUuidToUpdate, setExpenseUuidToUpdate] = useState<
    undefined | string
  >(undefined);
  const { handleCloseModal, handleOpenModal } = useModalContext();
  const [expenseActionModalActive, setExpenseActionModalActive] =
    useState<IExpenseActionModalState>({
      expenseIndex: -1,
      action: 'deleteExpense'
    });
  const [checked, setChecked] = useState<number[]>([]);

  const { lang, currentLangKey } = useLangContext();

  const { company } = useGetAuthUser();

  const { id } = useParams();

  const dateInputStartValue = new Date();
  dateInputStartValue.setMonth(dateInputStartValue.getMonth() - 1);

  const [filters, setFilters] = useState<IFilters>({
    endDate: CustomDate.formatDateToIso(new Date()),
    startDate: CustomDate.formatDateToIso(dateInputStartValue),
    refundable: [],
    page: 1,
    perPage: 10,
    search: '',
    typeOfExpense: '',
    statusTab: 'all',
    paymentMethod: '',
    orderBy: 'date',
    order: 'desc'
  });

  const queryClient = useQueryClient();

  const [reset] = useTourContext(useShallow(state => [state.reset]));

  const pattern = /^\d+$/;

  const { t } = useTranslation('expenses');

  const navigate = useNavigate();

  const { deleteExpenses, isDeletingExpenses } = useDeleteExpenses({
    onSuccess: () => {
      // Deletou somente uma despesa
      // Se deletar e tiver mais de uma despesa tira ela da lista
      if (
        expenseActionModalActive.expenseIndex !== -1 ||
        checked.length === 1
      ) {
        // Se tiver mais de uma despesa remove ela da lista
        if (paginatedExpenses?.data && paginatedExpenses?.data.length > 1) {
          queryClient.setQueryData<IExpensePaginatedResponse>(
            [ExpensesQueryKeys.GET_PAGINATED_EXPENSES, debouncedFilters],
            oldData => {
              if (!oldData) return oldData;

              // Se tiver só uma despesa selecionada e for deleção em massa checked - 1
              const index =
                checked.length === 1 &&
                expenseActionModalActive.expenseIndex === -1
                  ? checked[0]
                  : expenseActionModalActive.expenseIndex;

              const expenseToDelete = paginatedExpenses.data[index].uuid;

              const updatedExpenseData = oldData.data.filter(
                expense => expense.uuid !== expenseToDelete
              );
              queryClient.invalidateQueries({
                queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
              });

              return {
                ...oldData,
                data: updatedExpenseData
              };
            }
          );
        } else {
          // Nesse momento só deletamos um item por vez, ou seja se só tiver um item no data é por que deletamos o ultimo
          const deletedAllItemsFromPage =
            paginatedExpenses?.data.length === 1 && debouncedFilters.page !== 1;

          const newFilters = {
            ...filters,
            page: filters.page === 1 ? 1 : filters.page - 1
          };

          // Se a página não tiver mais itens atualiza os filtros
          deletedAllItemsFromPage && setFilters(newFilters);

          queryClient.invalidateQueries({
            queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES]
          });

          queryClient.invalidateQueries({
            queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
          });
        }

        toast.success(
          lang.expenses.modal.delete_expenses_with_success[currentLangKey]
        );
      } else {
        // Se a quantidade de itens selecionados for igual ao total de itiens
        // E não tiver na primeira pagina e for uma deleção em massa, ele deletou todos os itens
        const deletedAllItemsFromPage =
          paginatedExpenses?.data.length === checked.length &&
          debouncedFilters.page !== 1 &&
          expenseActionModalActive.expenseIndex === -1;

        const newFilters = {
          ...filters,
          page:
            deletedAllItemsFromPage && filters.page !== 1
              ? filters.page - 1
              : filters.page
        };

        // Se a página não tiver mais itens atualiza os filtros
        deletedAllItemsFromPage && setFilters(newFilters);

        queryClient.invalidateQueries({
          queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES]
        });

        queryClient.invalidateQueries({
          queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
        });

        toast.success(
          lang.expenses.modal.delete_expense_with_success[currentLangKey]
        );
      }

      queryClient.removeQueries({
        queryKey: [ReportsQueryKeys.GET_OPEN_REPORTS]
      });

      setChecked([]);
      handleCloseModal();
    }
  });

  function handleChangeFilters<T extends keyof IFilterAction>(
    type: T,
    value: IFilterAction[T]
  ): void {
    setChecked([]);

    switch (type) {
      case 'startDate':
        setFilters(oldState => ({
          ...oldState,
          startDate: value as string
        }));
        return;

      case 'endDate':
        setFilters(oldState => ({
          ...oldState,
          endDate: value as string
        }));
        return;

      case 'page':
        setFilters(oldState => ({
          ...oldState,
          page: value as number
        }));
        return;

      case 'perPage':
        setFilters(oldState => ({
          ...oldState,
          perPage: value as number,
          page: 1
        }));
        return;

      case 'refundable':
        setFilters(oldState => ({
          ...oldState,
          refundable: value as string[]
        }));
        return;

      case 'search':
        setFilters(oldState => ({
          ...oldState,
          search: value as string
        }));
        return;

      case 'paymentMethod':
        setFilters(oldState => ({
          ...oldState,
          paymentMethod: value as string
        }));
        return;

      case 'statusTab':
        setFilters(oldState => ({
          ...oldState,
          page: 1,
          statusTab: value as StatusTabType
        }));
        return;

      case 'typeOfExpense':
        setFilters(oldState => ({
          ...oldState,
          typeOfExpense: value as string
        }));
        return;

      case 'orderBy':
        setFilters(oldState => ({
          ...oldState,
          order:
            value !== oldState.orderBy
              ? 'asc'
              : oldState.order === 'asc'
                ? 'desc'
                : 'asc',
          orderBy: value as ExpenseOrderByType
        }));
        return;

      case 'clear':
        setFilters({
          endDate: undefined,
          startDate: undefined,
          refundable: [],
          page: 1,
          perPage: 10,
          search: '',
          typeOfExpense: '',
          statusTab: 'all',
          paymentMethod: '',
          orderBy: 'date',
          order: 'desc'
        });
    }
  }

  function handleCheckAllCheckbox(): void {
    if (checked.length !== paginatedExpenses?.data.length) {
      const length = paginatedExpenses?.data.length ?? 0;
      setChecked(
        checked.length === length ? [] : Array.from({ length }, (v, k) => k)
      );
      return;
    }

    setChecked([]);
  }

  function handleChangeCheckbox(row: number): void {
    if (checked.includes(row)) {
      setChecked(prevState => prevState.filter(item => item !== row));
      return;
    }

    setChecked(prevState => [...prevState, row]);
  }

  function handleCancelActionExpense(type: ExpenseActionModalType): void {
    switch (type) {
      case 'deleteExpense':
        handleCloseModal();
        break;

      case 'deleteExpenseFromModal':
        handleCloseModal();
        handleOpenModal('viewExpensesModal');
        break;

      case 'deleteExpenses':
        handleCloseModal();
        break;
    }
  }

  function handleDeleteExpenses(): void {
    if (isDeletingExpenses) return;

    // Delete só uma despesa
    const index = expenseActionModalActive.expenseIndex;
    const expenseId =
      index === -1 ? undefined : paginatedExpenses?.data[index].uuid;

    if (index !== -1 && expenseId) {
      deleteExpenses({ expenses: [expenseId] });

      return;
    }

    const selectedExpensesFiltered = paginatedExpenses?.data
      ? checked.map(index => paginatedExpenses?.data[index].uuid)
      : [];

    deleteExpenses({ expenses: selectedExpensesFiltered });
  }

  function handleDeleteExpensesFromModal(expenseId: string): void {
    deleteExpenses({ expenses: [expenseId] });
  }

  function handleConfirmActionExpense(type: ExpenseActionModalType): void {
    switch (type) {
      case 'deleteExpense':
        handleDeleteExpenses();
        break;

      case 'deleteExpenses':
        handleDeleteExpenses();
        break;
    }
  }

  const expensesFiltersToApiValue: Record<
    StatusTabType,
    StatusTabTypePersistenceType | undefined
  > = {
    all: undefined,
    single: 'avulso',
    open: 'aberto',
    reproved: 'reprovado',
    sent: 'enviado',
    paid: 'pago',
    approved: 'aprovado',
    reopened: 'reaberto'
  };

  const debouncedOrder = useDebounce(filters.order);
  const debouncedOrderBy = useDebounce(filters.orderBy);
  const debouncedPaymentMethod = useDebounce(filters.paymentMethod);
  const debouncedRefundable = useDebounce(filters.refundable, 700);
  const debouncedSearch = useDebounce(filters.search, 1000);
  const debouncedTypeOfExpense = useDebounce(filters.typeOfExpense);

  const debouncedFilters: IUseGetPaginatedExpensesParams =
    CustomObject.exchangeNullValues({
      ...filters,
      statusTab: expensesFiltersToApiValue[filters.statusTab],
      startDate: filters.startDate,
      endDate: filters.endDate,
      page: filters.page,
      perPage: filters.perPage,
      order: debouncedOrder,
      orderBy: debouncedOrderBy,
      paymentMethod:
        debouncedPaymentMethod === 'ALL' ? '' : debouncedPaymentMethod,
      refundable: debouncedRefundable,
      search: debouncedSearch,
      typeOfExpense:
        debouncedTypeOfExpense === 'ALL' ? '' : debouncedTypeOfExpense
    });

  const {
    isFetchingPaginatedExpenses,
    isLoadingPaginatedExpenses,
    paginatedExpenses
  } = useGetPaginatedExpenses(debouncedFilters);

  const canAttatch = checked.some(index => {
    if (!paginatedExpenses?.data[index]?.report) {
      return false;
    }

    if (
      paginatedExpenses.data[index]?.report?.status === 'ABERTO' ||
      paginatedExpenses.data[index]?.report?.status === 'REABERTO'
    )
      return false;

    return true;
  });

  const checkedExpensesHasSameCoin = !checked
    .map(index => paginatedExpenses?.data[index].currency)
    .every((val, i, arr) => val === arr[0]);

  const canAttachExpense = checked.length > 0 ? canAttatch : true;

  const canDelete = checked.some(
    index => !paginatedExpenses?.data[index]?.canDelete
  );

  const canDeleteExpense = checked.length > 0 ? canDelete : true;

  const showAlert =
    (canAttachExpense || canDeleteExpense) && checked.length > 0;

  const expensesActionModalData: ExpenseActionModalDataType = {
    deleteExpenseFromModal: {
      children:
        lang.expenses.modal.action_modal.delete_single_description[
          currentLangKey
        ],
      title:
        lang.expenses.modal.action_modal.delete_single_title[currentLangKey]
    },
    deleteExpense: {
      children:
        lang.expenses.modal.action_modal.delete_single_description[
          currentLangKey
        ],
      title:
        lang.expenses.modal.action_modal.delete_single_title[currentLangKey]
    },
    deleteExpenses: {
      children:
        lang.expenses.modal.action_modal.delete_multiple_description[
          currentLangKey
        ],
      title:
        lang.expenses.modal.action_modal.delete_multiple_title[currentLangKey]
    }
  };

  const { numberOfExpensesByStatus, isLoadingNumberOfExpensesByStatus } =
    useGetNumberOfExpensesByStatus(debouncedFilters);

  const selectedExpenses = useMemo(() => {
    return paginatedExpenses?.data
      ? checked.map(index => paginatedExpenses?.data[index])
      : [];
  }, [checked, paginatedExpenses]);

  function updateReportAndCostsCenterOfSelectedExpensesInCache({
    costCenter,
    report,
    shouldUpdateCostCenter
  }: IUpdateReportAndCostsCenterOfSelectedExpensesInCacheParams): void {
    queryClient.setQueryData<IExpensePaginatedResponse>(
      [ExpensesQueryKeys.GET_PAGINATED_EXPENSES, debouncedFilters],
      oldData => {
        if (!oldData) return oldData;

        const updatedExpense = oldData.data.map((expense, index) => {
          if (checked.includes(index)) {
            return {
              ...expense,
              report,
              ...(shouldUpdateCostCenter && {
                costCenter
              })
            };
          }

          return expense;
        });

        return {
          ...oldData,
          data: updatedExpense
        };
      }
    );

    selectedExpenses.forEach(expense => {
      queryClient.removeQueries({
        queryKey: [ExpensesQueryKeys.GET_EXPENSE, expense.uuid]
      });
    });
  }

  const isAllCheckboxChecked =
    paginatedExpenses?.data.length === checked.length && checked.length > 0;

  const { startTour } = useReportsPageTour({
    hasExpenses: (paginatedExpenses?.data?.length ?? 0) > 0,
    hasExpenseWithReceiptImg:
      paginatedExpenses?.data?.some(
        expense => !!expense.attachment && expense.attachment.type === 'IMG'
      ) || false
  });

  const showExpensesPageModalTour =
    localStorage.getItem(localStorageKeys.expensesPageTour) !== 'true';

  useEffect(() => {
    if (
      showExpensesPageModalTour ||
      reset === localStorageKeys.expensesPageTour
    ) {
      handleOpenModal('expensesPageNews');
    }
  }, [showExpensesPageModalTour, handleOpenModal, reset]);

  function handleStartTour(): void {
    handleCloseModal();
    startTour();
  }

  function handleCreateExpense(type: CreateExpenseType): void {
    setExpenseIndex(-1);
    handleOpenModal(type);
  }

  function handleUpdateExpense(): void {
    const expense = paginatedExpenses?.data[expenseIndex];

    // se for percurso manual ou percurso do tipo GPS (mobile), abre o modal de percurso manual
    if (
      expense?.type === 'Percurso' &&
      (expense?.routeType === 'TRACKING' || expense.routeType === null)
    ) {
      handleOpenModal('createManualRouteExpense');
      return;
    }

    // se for percurso do tipo mapa, abre o modal de percurso por mapa
    if (expense?.routeType === 'MAPA') {
      handleOpenModal('createRouteByMap');
      return;
    }

    // se for despesa manual, abre o modal de despesa manual
    handleOpenModal('createManualExpense');
  }

  function handleClickGoToClassicPage(): void {
    CustomCookies.set(cookiesKeys.redesignExpensesOldVersion, 'true');

    window.location.href = `${vexDomain}/despesas`;
  }

  const showAlertToGoToClassicPage =
    company?.parameters.isExpensesOldVersionAllowed ?? false;

  useEffect(() => {
    if (
      !isLoadingNumberOfExpensesByStatus &&
      !isLoadingPaginatedExpenses &&
      id &&
      pattern.test(id)
    ) {
      const index =
        paginatedExpenses?.data.findIndex(item => item.id === parseInt(id)) ??
        -1;

      index >= 0 && setExpenseIndex(index);
      handleOpenModal('viewExpensesModal');
    }

    if (id && !pattern.test(id)) {
      toast.error(t('modal.expenseNotFound'));
      navigate(`${routesPathPrefix.expenses}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isLoadingPaginatedExpenses, isLoadingNumberOfExpensesByStatus]);

  return {
    paginatedExpenses,
    isLoadingPaginatedExpenses:
      isFetchingPaginatedExpenses || isLoadingPaginatedExpenses,
    expenseIndex,
    setExpenseIndex,
    filters,
    handleChangeFilters,
    handleChangeCheckbox,
    checked,
    canAttachExpense,
    canDeleteExpense,
    showAlert,
    expenseActionModalActive,
    setExpenseActionModalActive,
    expensesActionModalData,
    handleCancelActionExpense,
    handleConfirmActionExpense,
    numberOfExpensesByStatus,
    isLoadingNumberOfExpensesByStatus,
    handleClickGoToClassicPage,
    setChecked,
    updateReportAndCostsCenterOfSelectedExpensesInCache,
    handleCheckAllCheckbox,
    isAllCheckboxChecked,
    selectedExpenses,
    showExpensesPageModalTour,
    handleStartTour,
    handleDeleteExpensesFromModal,
    handleUpdateExpense,
    handleCreateExpense,
    isDeletingExpenses,
    checkedExpensesHasSameCoin,
    showAlertToGoToClassicPage,
    expenseUuidToUpdate,
    setExpenseUuidToUpdate
  };
}
