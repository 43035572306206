import { Fragment, useEffect, useMemo, useState } from 'react';

import { useModalContext } from 'data/contexts';
import { type IPendencyDetails } from 'data/modules/cards/pendencies';
import {
  type IAccountStatement,
  type IAccountTransactionWithAdditionalInfo,
  type ICompanyStatement,
  type ICompanyTransaction
} from 'data/modules/cards/statement';

import {
  RowBalanceOfDay,
  RowTransaction
} from 'presentation/pages/cards/Statement/components/Table';

import { Statement } from 'shared/utils/cards';

import {
  type IStateTableSort,
  type ITransactionShowingDetailsState,
  type IUseStatementTable,
  type UseStatementTableParamsType
} from './StatementTable.types';

export function useStatementTable({
  data,
  showDayBalance,
  type
}: UseStatementTableParamsType): IUseStatementTable {
  const [transactionShowingDetails, setTransactionShowingDetails] =
    useState<ITransactionShowingDetailsState>({
      transaction: null
    });
  const [pendencyShowingDetails, setPendencyShowingDetails] =
    useState<IPendencyDetails | null>(null);
  const [tableSort, setTableSort] = useState<IStateTableSort>({
    column: '',
    order: 'desc'
  });
  const [dayBalanceDateOrder, setDayBalanceDateOrder] = useState<
    'asc' | 'desc'
  >('desc');

  const { handleOpenModal } = useModalContext();

  useEffect(() => {
    if (showDayBalance) {
      setTableSort({
        column: '',
        order: 'desc'
      });
      setDayBalanceDateOrder('desc');
      return;
    }

    setTableSort({
      column: 'date',
      order: 'desc'
    });
  }, [data, showDayBalance]);

  const allAccountStatementTransactions = (
    data as IAccountStatement<IAccountTransactionWithAdditionalInfo>[]
  ).reduce<IAccountTransactionWithAdditionalInfo[]>(
    (acc, statementRow) => acc.concat(statementRow.items),
    []
  );

  function orderAccountStatementTransactions(
    transactions: IAccountTransactionWithAdditionalInfo[]
  ): IAccountTransactionWithAdditionalInfo[] {
    const orderedTransactions = [...transactions];

    if (showDayBalance && tableSort.column === '') {
      orderedTransactions.sort(
        dayBalanceDateOrder === 'asc'
          ? Statement.sortAscTransactionsByDatetime
          : Statement.sortDescTransactionsByDatetime
      );

      return orderedTransactions;
    }

    if (tableSort.column === 'date') {
      orderedTransactions.sort(
        tableSort.order === 'asc'
          ? Statement.sortAscTransactionsByDatetime
          : Statement.sortDescTransactionsByDatetime
      );
    }

    if (tableSort.column === 'card') {
      orderedTransactions.sort(
        tableSort.order === 'asc'
          ? Statement.sortAscTransactionsByCardNumber
          : Statement.sortDescTransactionsByCardNumber
      );
    }

    if (tableSort.column === 'description') {
      orderedTransactions.sort(
        tableSort.order === 'asc'
          ? Statement.sortAscTransactionsByDescription
          : Statement.sortDescTransactionsByDescription
      );
    }

    if (tableSort.column === 'value') {
      orderedTransactions.sort(
        tableSort.order === 'asc'
          ? Statement.sortAscTransactionsByAmount
          : Statement.sortDescTransactionsByAmount
      );
    }

    if (tableSort.column === 'user') {
      orderedTransactions.sort(
        tableSort.order === 'asc'
          ? Statement.sortAscAccountTransactionsByUserName
          : Statement.sortDescAccountTransactionsByUserName
      );
    }

    return orderedTransactions;
  }

  function orderCompanyStatementTransactions(
    transactions: ICompanyTransaction[]
  ): ICompanyTransaction[] {
    const orderedTransactions = [...transactions];

    if (showDayBalance && tableSort.column === '') {
      orderedTransactions.sort(
        dayBalanceDateOrder === 'asc'
          ? Statement.sortAscTransactionsByDatetime
          : Statement.sortDescTransactionsByDatetime
      );

      return orderedTransactions;
    }

    if (tableSort.column === 'date') {
      orderedTransactions.sort(
        tableSort.order === 'asc'
          ? Statement.sortAscTransactionsByDatetime
          : Statement.sortDescTransactionsByDatetime
      );
    }

    if (tableSort.column === 'card') {
      orderedTransactions.sort(
        tableSort.order === 'asc'
          ? Statement.sortAscTransactionsByCardNumber
          : Statement.sortDescTransactionsByCardNumber
      );
    }

    if (tableSort.column === 'description') {
      orderedTransactions.sort(
        tableSort.order === 'asc'
          ? Statement.sortAscTransactionsByDescription
          : Statement.sortDescTransactionsByDescription
      );
    }

    if (tableSort.column === 'value') {
      orderedTransactions.sort(
        tableSort.order === 'asc'
          ? Statement.sortAscTransactionsByAmount
          : Statement.sortDescTransactionsByAmount
      );
    }

    return orderedTransactions;
  }

  const orderedStatement = useMemo(() => {
    const statementToOrder = [...data];

    statementToOrder.sort(Statement.sortStatementByDate);

    if (dayBalanceDateOrder === 'desc') {
      return statementToOrder.reverse();
    } else {
      return statementToOrder;
    }
  }, [data, dayBalanceDateOrder]);

  function getRowAccountTransaction(
    transaction: IAccountTransactionWithAdditionalInfo
  ): JSX.Element {
    const originalTransactionId = transaction.originalTransactionId;
    const originalTransaction = originalTransactionId
      ? allAccountStatementTransactions.find(
          transaction => transaction.transactionId === originalTransactionId
        )
      : undefined;

    return (
      <RowTransaction
        pendency={transaction.pendency}
        userName={transaction.userName}
        originalTransactionProps={{
          id: originalTransactionId,
          data: originalTransaction
        }}
        key={transaction.transactionId}
        transaction={transaction}
        onShowTransactionDetails={() => {
          handleOpenModal('transactionDetails');
          setTransactionShowingDetails({
            transaction,
            userName: transaction.userName
          });
        }}
        onShowPendencyDetails={() => {
          handleOpenModal('pendencyDetails');
          setPendencyShowingDetails({
            ...transaction.pendency,
            cardNumber: transaction.cardNumber,
            userName: transaction.userName
          } as IPendencyDetails);
        }}
      />
    );
  }

  function tableBodyRows(): JSX.Element[] {
    if (type === 'account') {
      if (showDayBalance) {
        return (
          orderedStatement as IAccountStatement<IAccountTransactionWithAdditionalInfo>[]
        ).map(statementRow => (
          <Fragment
            key={`${statementRow.date}-${statementRow.items[0].transactionId}`}
          >
            <RowBalanceOfDay
              date={statementRow.date}
              value={statementRow.balance}
            />

            {orderAccountStatementTransactions(statementRow.items).map(
              getRowAccountTransaction
            )}
          </Fragment>
        ));
      }

      return orderAccountStatementTransactions(
        allAccountStatementTransactions
      ).map(getRowAccountTransaction);
    }

    return (orderedStatement as ICompanyStatement[]).map(statementRow => (
      <Fragment
        key={`${statementRow.date}-${statementRow.items[0].transactionId}`}
      >
        <RowBalanceOfDay
          date={statementRow.date}
          value={statementRow.balance}
        />

        {orderCompanyStatementTransactions(statementRow.items).map(
          transaction => (
            <RowTransaction
              key={transaction.transactionId}
              transaction={transaction}
              onShowTransactionDetails={() => {
                handleOpenModal('transactionDetails');
                setTransactionShowingDetails({
                  transaction
                });
              }}
            />
          )
        )}
      </Fragment>
    ));
  }

  function handleTableSort(column: IStateTableSort['column']): void {
    if (showDayBalance && column === 'date') {
      setDayBalanceDateOrder(dayBalanceDateOrder === 'asc' ? 'desc' : 'asc');
      return;
    }

    if (tableSort.column === column) {
      if (tableSort.order === 'desc') {
        setTableSort({
          ...tableSort,
          column: ''
        });
        return;
      }

      setTableSort({
        column,
        order: tableSort.order === 'asc' ? 'desc' : 'asc'
      });
      return;
    }

    setTableSort({
      column,
      order: 'asc'
    });
  }

  return {
    transactionShowingDetails,
    pendencyShowingDetails,
    tableBodyRows: tableBodyRows(),
    handleTableSort,
    tableSort,
    dayBalanceDateOrder
  };
}
