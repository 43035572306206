import { useEffect } from 'react';

import { type IUseInfiniteScrollProps } from './useInfiniteScroll.types';

/**
 * @param infiniteScrollRef Ref do elemento que será o gatilho para o dispardo da request.
 * @param rootRef Ref do elemento pai de onde o scroll está implementado.
 * @param rootMargin Gap para chamar a request antes de chegar no elemento gatilho.
 * @param isFetchingNextPage Indica se está sendo feita a requisição para a próxima página.
 * @param actionFn Função que será chamada quando o gatilho for acionado.
 * @returns void.
 */
export function useInfiniteScroll({
  infiniteScrollRef,
  rootRef,
  rootMargin = '0px',
  isFetchingNextPage,
  hasNextPage,
  actionFn
}: IUseInfiniteScrollProps): void {
  useEffect(() => {
    if (!infiniteScrollRef || !rootRef) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries, obs) => {
        const { isIntersecting } = entries[0];

        if (!hasNextPage) {
          obs.disconnect();

          return;
        }

        if (isIntersecting && !isFetchingNextPage) {
          actionFn();
        }
      },
      { rootMargin, root: rootRef }
    );

    observer.observe(infiniteScrollRef);

    return () => {
      observer.disconnect();
    };
  }, [
    actionFn,
    infiniteScrollRef,
    rootMargin,
    rootRef,
    isFetchingNextPage,
    hasNextPage
  ]);
}
