import { useEffect } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { useMyValueSolicitationContext } from 'data/contexts';
import {
  type BalanceSolicitationStatusType,
  useGetMyValueSolicitations
} from 'data/modules/cards/balance';

import { useDebounce } from 'shared/hooks/global';
import { Balance } from 'shared/utils/cards';

import { type IUseMyValueSoliciationsTable } from './MyValueSolicitationsTable.types';

export function useMyValueSoliciationsTable(): IUseMyValueSoliciationsTable {
  const [
    currentPage,
    pageSize,
    sortByDate,
    handleChangeMyValueSolicitationPagination,
    myValueSolicitationsSearchInput,
    myValueSolicitationsStatus
  ] = useMyValueSolicitationContext(
    useShallow(state => [
      state.paginationMyValueSolicitations.currentPage,
      state.paginationMyValueSolicitations.pageSize,
      state.sortByDate,
      state.handleChangeMyValueSolicitationPagination,
      state.myValueSolicitationsSearchInput,
      state.myValueSolicitationsStatus
    ])
  );

  const debouncedSearch = useDebounce(myValueSolicitationsSearchInput, 500);

  const { myValueSolicitations, isFetchingMyValueSolicitations } =
    useGetMyValueSolicitations({
      page: currentPage,
      limit: pageSize,
      status: myValueSolicitationsStatus as BalanceSolicitationStatusType,
      justify: debouncedSearch
    });

  useEffect(() => {
    const totalItems = myValueSolicitations?.total || 0;

    const totalPages = totalItems && Math.ceil(totalItems / pageSize);

    handleChangeMyValueSolicitationPagination({ totalPages, totalItems });
  }, [
    myValueSolicitations,
    pageSize,
    handleChangeMyValueSolicitationPagination
  ]);

  const solicitations = Balance.sortBalanceSolicitationsArrayByDate(
    myValueSolicitations?.data || [],
    sortByDate
  );

  return {
    solicitations,
    isFetchingMyValueSolicitations
  };
}
