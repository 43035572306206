import {
  type IAvailableToBeManager,
  type IGetAvailableToBeManagerPayload,
  type IGetAvailableToBeManagerPersistencePayload,
  type IPersistenceAvailableToBeManager
} from 'data/modules/cards/cardGroups/types/cardGroups.types';

class GetAvailableToBeManagerMapper {
  toDomain(
    persistenceAvailableToBeManager: IPersistenceAvailableToBeManager
  ): IAvailableToBeManager {
    return {
      id: persistenceAvailableToBeManager.user_id,
      name: persistenceAvailableToBeManager.user_name,
      type: persistenceAvailableToBeManager.user_type
    };
  }

  toPersistence(
    domainPayload: IGetAvailableToBeManagerPayload
  ): IGetAvailableToBeManagerPersistencePayload {
    if (!domainPayload.userName) {
      return {
        limit: domainPayload.limit,
        page: domainPayload.page,
        card_group_id: domainPayload.cardGroupId
      };
    }

    return {
      limit: domainPayload.limit,
      page: domainPayload.page,
      user_name: domainPayload.userName,
      card_group_id: domainPayload.cardGroupId
    };
  }
}

export default new GetAvailableToBeManagerMapper();
