import {
  Checkbox,
  DragAndDropFile,
  DSIcons,
  SecondaryButton,
  Select,
  TextArea,
  TextInput
} from 'ds';
import { DatePicker } from 'ds/components/Input/DatePicker';
import { useTranslation } from 'react-i18next';

import { useApportionmentContext, useLangContext } from 'data/contexts';

import { ApportionmentBlockForm } from 'presentation/pages/expenses/ExpensesList/components/ApportionmentBlock';
import { SelectDynamicCostsCenters } from 'presentation/pages/expenses/ExpensesList/components/SelectDynamicCases';

import { useFirstStepForm } from './useFirstStepForm';

import { type IFirstStepFormProps } from './FirstStepForm.types';

import {
  FormContent,
  LeftContent,
  RightContent,
  RowInputs
} from './FirstStepForm.styles';

export function FirstStepForm({
  onChangeFormExpenseCurrency,
  expenseUuidToUpdate,
  showPreRegisteredSectionsField,
  readOnlyFieldsWhenUserIsUpdatingRoute
}: IFirstStepFormProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  const { t } = useTranslation('expenses');

  const { disabledKilometerInput } = useApportionmentContext();

  const {
    currenciesOptions,
    isLoadingCurrencies,
    isLoadingExpensePaymentMethods,
    paymentMethodsOptions,
    expenseParameters,
    apportionmentFields,
    apportionmentReplace,
    handleClickSelectProjectButton,
    isLoadingPreRegisteredSections,
    preRegisteredSectionsOptions,
    handleChangeMileage,
    handleChangeMileagePaidValue,
    handleChangeDate,
    fieldRefundableDisabled,
    handleChangePaymentMethod,
    handleChangePreRegisteredSection,
    mileageFieldReadonly
  } = useFirstStepForm({
    expenseUuidToUpdate
  });

  const paymentMethodFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.expensePaymentMethod !== null
      ? expenseParameters.companyFields.expensePaymentMethod
      : lang.global.payment_form[currentLangKey];

  const costsCenterFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.expenseCostCenter !== null
      ? expenseParameters.companyFields.expenseCostCenter
      : lang.global.costs_center[currentLangKey];

  const observationFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.expenseNote !== null
      ? expenseParameters.companyFields.expenseNote
      : lang.expenses.modal.create_manual_expense.observations[currentLangKey];

  const projectFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.expenseProject !== null
      ? expenseParameters?.companyFields.expenseProject
      : lang.global.project[currentLangKey];

  return (
    <>
      <FormContent>
        <LeftContent>
          {showPreRegisteredSectionsField && (
            <Select.Controlled
              small
              className='select-full-width'
              name='preRegisteredSection'
              hasArrowDownIndicator
              menuPlacement='auto'
              isLoading={isLoadingPreRegisteredSections}
              options={preRegisteredSectionsOptions}
              onCustomChange={handleChangePreRegisteredSection}
              placeholder={
                lang.expenses.modal.create_manual_route_expense
                  .pre_registred_sections[currentLangKey]
              }
              label={
                lang.expenses.modal.create_manual_route_expense
                  .pre_registred_sections[currentLangKey]
              }
            />
          )}

          <RowInputs>
            <TextInput.Controlled
              small
              label={
                lang.expenses.modal.create_manual_expense.expense_description[
                  currentLangKey
                ]
              }
              name='description'
              placeholder={
                lang.expenses.modal.create_manual_expense.expense_description[
                  currentLangKey
                ]
              }
              inputContainerAttributes={{
                className: 'modal-textinput'
              }}
            />

            <DatePicker.Controlled
              variant='small'
              currentLangKey={currentLangKey}
              onChangeValue={handleChangeDate}
              label={lang.global.date[currentLangKey]}
              name='date'
              disabled={readOnlyFieldsWhenUserIsUpdatingRoute.has('date')}
              placeholder={lang.global.date[currentLangKey]}
            />
          </RowInputs>

          <RowInputs>
            <TextInput.Controlled
              small
              label={
                lang.expenses.modal.create_manual_route_expense.mileage[
                  currentLangKey
                ]
              }
              name='mileage'
              mask='currency'
              readOnly={
                readOnlyFieldsWhenUserIsUpdatingRoute.has('mileage') ||
                mileageFieldReadonly
              }
              placeholder={
                lang.expenses.modal.create_manual_route_expense.mileage[
                  currentLangKey
                ]
              }
              shouldPreventPaste
              shouldPreventAutoComplete
              onChangeValue={handleChangeMileage}
              inputContainerAttributes={{
                className: 'modal-textinput'
              }}
            />

            <TextInput.Controlled
              small
              label={
                lang.expenses.modal.create_manual_route_expense.value_by_km[
                  currentLangKey
                ]
              }
              name='mileagePaidValue'
              mask='four-decimal-currency'
              shouldPreventPaste
              shouldPreventAutoComplete
              readOnly={
                disabledKilometerInput ||
                !expenseParameters?.allowChangeAmountPerKm
              }
              placeholder={
                lang.expenses.modal.create_manual_route_expense.value_by_km[
                  currentLangKey
                ]
              }
              onChangeValue={handleChangeMileagePaidValue}
              inputContainerAttributes={{
                className: 'modal-textinput'
              }}
            />
          </RowInputs>

          <RowInputs>
            <Select.Controlled
              small
              name='currency'
              menuPlacement='auto'
              hasArrowDownIndicator
              isLoading={isLoadingCurrencies}
              options={currenciesOptions}
              disabled
              onCustomChange={onChangeFormExpenseCurrency}
              executeOnlyOnCustomChange
              placeholder={
                lang.expenses.modal.create_manual_expense.currency[
                  currentLangKey
                ]
              }
              label={
                lang.expenses.modal.create_manual_expense.currency[
                  currentLangKey
                ]
              }
            />

            <TextInput.Controlled
              small
              label={
                lang.expenses.modal.create_manual_expense.value[currentLangKey]
              }
              name='value'
              shouldPreventAutoComplete
              shouldPreventPaste
              readOnly
              mask='currency'
              placeholder={
                lang.expenses.modal.create_manual_expense.value[currentLangKey]
              }
              inputContainerAttributes={{
                className: 'modal-textinput'
              }}
            />
          </RowInputs>

          <RowInputs>
            <SelectDynamicCostsCenters
              disabled={readOnlyFieldsWhenUserIsUpdatingRoute.has(
                'costsCenter'
              )}
              customLabel={costsCenterFormLabel}
            />
            <Select.Controlled
              small
              name='paymentMethod'
              isLoading={isLoadingExpensePaymentMethods}
              options={paymentMethodsOptions}
              onCustomChange={handleChangePaymentMethod}
              placeholder={paymentMethodFormLabel}
              label={paymentMethodFormLabel}
              hasArrowDownIndicator
            />
          </RowInputs>

          <Checkbox.Controlled
            small
            label={
              lang.expenses.modal.create_manual_expense.refundable[
                currentLangKey
              ]
            }
            name='refundable'
            // campo desabilitado caso empresa utilize conciliação na fatura
            disabled={
              expenseParameters?.usesInvoiceReconciliation ||
              fieldRefundableDisabled
            }
          />

          <TextArea.Controlled
            small
            name='observation'
            className='textarea-notes'
            label={observationFormLabel}
            textareaProps={{
              placeholder: observationFormLabel
            }}
          />

          {apportionmentFields.length === 0 && (
            <SecondaryButton
              size='small'
              onClick={handleClickSelectProjectButton}
              className='select-project-button'
              type='button'
            >
              <DSIcons.AddCircleIcon />
              {t('modal.selectProject', {
                projectLabel: projectFormLabel
              })}
            </SecondaryButton>
          )}
        </LeftContent>
        <RightContent>
          <DragAndDropFile.Controlled
            showZoomTour
            hasZoomControl
            langKey={currentLangKey}
            inputId='receipt'
            name='receipt'
          />
        </RightContent>
      </FormContent>

      {apportionmentFields.length > 0 && (
        <ApportionmentBlockForm
          onHideApportionmentBlock={() => {
            apportionmentReplace([]);
          }}
          expenseUuidToUpdate={expenseUuidToUpdate}
        />
      )}
    </>
  );
}
