import {
  type IChangeEmailPreferences,
  type IPersistenceChangeEmailPreferences,
  type IPersistenceEmailPreferences
} from 'data/modules/settings/emailPreferences/types/settings.types';

export class ChangeEmailPreferencesMapper {
  toPersistence(
    payload: IChangeEmailPreferences
  ): IPersistenceEmailPreferences {
    return {
      recebeEmailResumoGerencial: payload.receivesManagementSummaryEmail
    };
  }

  toDomain(
    payload: IPersistenceChangeEmailPreferences
  ): IChangeEmailPreferences {
    return {
      receivesManagementSummaryEmail: payload.receives_management_summary_email
    };
  }
}

export default new ChangeEmailPreferencesMapper();
