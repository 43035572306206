import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  useCardGroupsContext,
  useClassicModeContext,
  useFlagsContext,
  useLangContext,
  useModalContext,
  useValueSolicitationContext
} from 'data/contexts';
import { useGetCardGroup } from 'data/modules/cards/account/useCases/get-card-group/useGetCardGroup';

import { ClassicModeAlert } from 'presentation/components/global/ClassicModeAlert';
import { NewDepositModal } from 'presentation/pages/cards/Management/components/ManagementSidebar';
import { SideCardDetailsModal } from 'presentation/pages/cards/Management/components/SideCardDetailsModal';
import { DefaultLayout } from 'presentation/templates';

import { localStorageKeys } from 'shared/constants/global';

import {
  ValueSolicitationContainer,
  ValueSolicitationPageHeader
} from './components/';
import { useValueSolicitationTour } from './tours';

import { PageContainer } from './ValueSolicitation.styles';

export function ValueSolicitation(): JSX.Element {
  const navigate = useNavigate();

  const { t } = useTranslation('cards');

  const { lang, currentLangKey } = useLangContext();

  const { visibleModal } = useModalContext();

  const [step, handleChangeStep] = useValueSolicitationContext(
    useShallow(state => [state.step, state.handleChangeStep])
  );

  useValueSolicitationTour({
    enabled:
      localStorage.getItem(localStorageKeys.valueSolicitationTour) !== 'true'
  });

  document.title = `vexpenses | ${lang.cards.valueSolicitation.value_solicitation_title[currentLangKey]}`;

  const { groupId } = useParams();

  const [
    isValueSolicitationClassicMode,
    valueSolicitationTrialAge,
    handleToggleClassicMode
  ] = useClassicModeContext(
    useShallow(state => [
      state.isValueSolicitationClassicMode,
      state.trialAge.valueSolicitation,
      state.handleToggleClassicMode
    ])
  );

  const contextCardGroup = useCardGroupsContext(
    state => state.selectedCardGroup
  );

  const cardGroupsFlag = useFlagsContext(state => state.cardGroupsFlag);

  const { cardGroupData } = useGetCardGroup({
    cardGroupId: groupId || '',
    enabled: !contextCardGroup
  });

  const selectedCardGroup = contextCardGroup || cardGroupData;

  return (
    <DefaultLayout
      pageNameAndBreadcrumbs={{
        pageName:
          step === 'solicitation'
            ? lang.cards.valueSolicitation.value_solicitation_title[
                currentLangKey
              ]
            : step === 'approve'
              ? lang.cards.valueSolicitation.filters.approve_selected[
                  currentLangKey
                ]
              : step === 'disapprove'
                ? lang.cards.valueSolicitation.filters.dissaprove_selected[
                    currentLangKey
                  ]
                : step === 'transfer'
                  ? lang.cards.valueSolicitation.filters
                      .transfer_another_amount[currentLangKey]
                  : '',
        breadcrumbs:
          step === 'solicitation' && !groupId
            ? []
            : groupId && selectedCardGroup
              ? [
                  {
                    name: t('cardsManagement'),
                    pageLink: '/cartoes/grupos-de-cartoes'
                  },
                  {
                    name: selectedCardGroup.name,
                    pageLink: `/cartoes/gestao-de-grupos/${selectedCardGroup?.id}`
                  },
                  {
                    name:
                      step === 'solicitation'
                        ? lang.cards.valueSolicitation.value_solicitation_title[
                            currentLangKey
                          ]
                        : step === 'approve'
                          ? lang.cards.valueSolicitation.filters
                              .approve_selected[currentLangKey]
                          : step === 'disapprove'
                            ? lang.cards.valueSolicitation.filters
                                .dissaprove_selected[currentLangKey]
                            : step === 'transfer'
                              ? lang.cards.valueSolicitation.filters
                                  .transfer_another_amount[currentLangKey]
                              : ''
                  }
                ]
              : [
                  {
                    name:
                      step === 'solicitation'
                        ? lang.global.cards[currentLangKey]
                        : lang.cards.valueSolicitation.value_solicitation_title[
                            currentLangKey
                          ],
                    pageLink: step === 'solicitation' ? '/cartoes/gestao' : '#',
                    onLinkClick: () => {
                      step === 'solicitation' && groupId
                        ? history.back()
                        : handleChangeStep('solicitation');
                    }
                  },
                  {
                    name:
                      step === 'solicitation'
                        ? lang.cards.valueSolicitation.value_solicitation_title[
                            currentLangKey
                          ]
                        : step === 'approve'
                          ? lang.cards.valueSolicitation.filters
                              .approve_selected[currentLangKey]
                          : step === 'disapprove'
                            ? lang.cards.valueSolicitation.filters
                                .dissaprove_selected[currentLangKey]
                            : step === 'transfer'
                              ? lang.cards.valueSolicitation.filters
                                  .transfer_another_amount[currentLangKey]
                              : ''
                  }
                ],
        onBackButtonClick: () => {
          step !== 'solicitation'
            ? handleChangeStep('solicitation')
            : navigate(`/cartoes/gestao-de-grupos/${groupId}`);
        },
        isBackButtonVisible: !!groupId,
        isBreadcrumbsVisible: true,
        isClassicMode: isValueSolicitationClassicMode,
        isTourButtonVisible: true
      }}
    >
      <PageContainer>
        <ValueSolicitationPageHeader />

        {valueSolicitationTrialAge !== null &&
          valueSolicitationTrialAge <= 30 &&
          !cardGroupsFlag && (
            <ClassicModeAlert
              pageName={
                lang.cards.valueSolicitation.value_solicitation_title[
                  currentLangKey
                ]
              }
              isClassicMode={isValueSolicitationClassicMode}
              onActionClick={() => {
                handleToggleClassicMode('VALUE_SOLICITATION');

                const feedbackValueSolicitationKey =
                  localStorageKeys.feedbackValueSolicitation;

                if (!localStorage.getItem(feedbackValueSolicitationKey)) {
                  localStorage.setItem(feedbackValueSolicitationKey, 'false');
                }
              }}
              trialEndDate={String(valueSolicitationTrialAge)}
            />
          )}
        <ValueSolicitationContainer />
      </PageContainer>

      <SideCardDetailsModal
        isOpen={visibleModal === 'cardDetails'}
        title={lang.global.card[currentLangKey]}
        side='right'
      />

      {visibleModal === 'deposit' && <NewDepositModal />}
    </DefaultLayout>
  );
}
