import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  margin-top: 3rem;

  h1 {
    text-align: left;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  > h1 {
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: ${({ theme }) => theme.tokens.fontWeightSemibold};
  }

  ul {
    margin-bottom: 2rem;
  }

  li {
    list-style-type: disc;
    margin-left: 2rem;
  }
`;
