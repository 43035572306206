import { CardInfo, SkeletonLoader } from 'ds';
import { useTranslation } from 'react-i18next';

import { type ITeamMembersCardsProps } from './TeamMembersCards.types';

import { Container, SkeletonLoaderContainer } from './TeamMembersCards.styles';

export function TeamMembersCards({
  cards,
  isLoadingTeamMembersResume
}: ITeamMembersCardsProps): JSX.Element {
  const { t } = useTranslation(['settings', 'global']);

  return isLoadingTeamMembersResume ? (
    <SkeletonLoaderContainer>
      <SkeletonLoader />
      <SkeletonLoader />
      <SkeletonLoader />
    </SkeletonLoaderContainer>
  ) : (
    <Container>
      <CardInfo.Container>
        <CardInfo.Title variant='small'>
          {t('teamMembers.registeredUsers')}
        </CardInfo.Title>
        <CardInfo.ValueContainer>
          <CardInfo.Value>{cards?.totalRegisteredUsers ?? 0}</CardInfo.Value>
        </CardInfo.ValueContainer>
      </CardInfo.Container>

      <CardInfo.Container>
        <CardInfo.Title variant='small'>
          {t('teamMembers.activeUsers')}
        </CardInfo.Title>
        <CardInfo.ValueContainer>
          <CardInfo.Value>{cards?.totalActiveUsers ?? 0}</CardInfo.Value>
        </CardInfo.ValueContainer>
      </CardInfo.Container>

      <CardInfo.Container>
        <CardInfo.Title variant='small'>
          {t('teamMembers.adminUsers')}
        </CardInfo.Title>
        <CardInfo.ValueContainer>
          <CardInfo.Value>{cards?.totalAdminUsers ?? 0}</CardInfo.Value>
        </CardInfo.ValueContainer>
      </CardInfo.Container>

      {/* Será exibido futuramente
      <CardInfo.Container>
        <CardInfo.Title variant='small'>
          {t('teamMembers.usersWithCards')}
        </CardInfo.Title>
        <CardInfo.ValueContainer>
          <CardInfo.Value>{usersWithCards}</CardInfo.Value>
        </CardInfo.ValueContainer>
      </CardInfo.Container>

      <CardInfo.Container>
        <CardInfo.Title variant='small'>
          {t('teamMembers.virtualUsers')}
        </CardInfo.Title>
        <CardInfo.ValueContainer>
          <CardInfo.Value>{virtualUsers}</CardInfo.Value>
        </CardInfo.ValueContainer>
      </CardInfo.Container> */}
    </Container>
  );
}
