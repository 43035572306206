import { DSIcons } from 'ds';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'presentation/components/base/Tooltip';

import { Container } from './TransactionPendency.styles';

export function TransactionPendency(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Container>
      <Tooltip
        id='pending-fee'
        content={t('pendingFee')}
      >
        <DSIcons.ReportTimeClockIcon />
      </Tooltip>
    </Container>
  );
}
