import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetXs};
  padding: ${tokens.spacingInsetXxs};
  background-color: ${({ theme }) => theme.tokens.neutralColorHighLight};
  border-radius: ${tokens.borderRadiusMd};
  overflow-y: auto;

  table {
    min-width: 126rem;
  }

  margin-bottom: ${tokens.spacingInsetGiant};

  .justify-tooltip > span {
    white-space: pre-line;
    overflow-wrap: break-word;
    max-width: 100%;
  }
`;

export const TableContainer = styled.div`
  max-width: 100%;
  overflow: auto;
`;

export const EmptySolicitationList = styled.div`
  width: 100%;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeSm};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
`;
