import styled from 'styled-components';

import { CreditCard } from 'presentation/components/global/CreditCard';

export const StyledCreditCard = styled(CreditCard)`
  width: 100%;
  height: 100%;
  max-width: 40rem;
  aspect-ratio: 1.587;
  background-size: cover;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.1s linear;

  &:hover {
    scale: 1.03;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 0.5rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 28.301rem;
    padding: 1.4rem 2rem;
    gap: 0.5rem;
  }
`;
