import { CardInfo, DefaultButton } from 'ds';
import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';

import moneyDeposit from 'shared/assets/images/cards/money-deposit.svg';

import { type IDepositValueCardInfoProps } from './DepositValueCardInfo.types';

import {
  CardInfoCaption,
  CardInfoContainer,
  ContentWrapper,
  CustomImage
} from './DepositValueCardInfo.styles';

export function DepositValueCardInfo({
  ...rest
}: IDepositValueCardInfoProps): JSX.Element {
  const { t } = useTranslation('cards');

  const { handleOpenModal } = useModalContext();

  return (
    <CardInfoContainer {...rest}>
      <CustomImage
        src={moneyDeposit}
        width={75}
      />

      <ContentWrapper>
        <CardInfo.Title variant='small'>
          {t('depositToVexpenses')}
        </CardInfo.Title>

        <CardInfoCaption variant='small'>
          {t('increaseTheValueText')}
        </CardInfoCaption>

        <DefaultButton
          onClick={() => {
            handleOpenModal('deposit');
          }}
          size='small'
        >
          {t('deposit')}
        </DefaultButton>
      </ContentWrapper>
    </CardInfoContainer>
  );
}
