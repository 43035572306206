import { NavLink } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useAuthContext, useFlagsContext, useLangContext } from 'data/contexts';

import { CloseAlert } from 'presentation/components/base/CloseAlert';
import { Navbar } from 'presentation/components/global/Navbar';
import { Navbar as OldNavbar } from 'presentation/components/global/OldNavbar';
import { SidebarContainer } from 'presentation/components/global/Sidebar';

import { useDefaultLayout } from './useDefaultLayout';

import { type IDefaultLayout } from './DefaultLayout.types';

import {
  Content,
  ContentContainer,
  DefaultContainer
} from './DefaultLayout.styles';

export function DefaultLayout({
  pageNameAndBreadcrumbs,
  children,
  onScroll
}: IDefaultLayout): JSX.Element {
  const { setSidebarIsCollapsed, sidebarIsCollapsed } = useDefaultLayout();

  const user = useAuthContext(state => state.user);

  const { notificationsFlag } = useFlagsContext();

  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const theme = useTheme();

  return (
    <DefaultContainer>
      <SidebarContainer
        onSetIsCollapsed={setSidebarIsCollapsed}
        isCollapsed={sidebarIsCollapsed}
      />

      <ContentContainer $isCollapsed={sidebarIsCollapsed}>
        {!pageNameAndBreadcrumbs || pageNameAndBreadcrumbs?.isClassicMode ? (
          <OldNavbar
            isTourButtonVisible={false}
            isClassicNotificationsVisible={!notificationsFlag}
            onToggleSidebar={() => {
              setSidebarIsCollapsed(!sidebarIsCollapsed);
            }}
          />
        ) : (
          <Navbar
            onToggleSidebar={() => {
              setSidebarIsCollapsed(!sidebarIsCollapsed);
            }}
            pageNameAndBreadcrumbs={{
              ...pageNameAndBreadcrumbs,
              isClassicNotificationsVisible: !notificationsFlag
            }}
          />
        )}

        {/* Alerta de email do layout desktop */}
        {user && !user.confirmed && (
          <CloseAlert color={theme.colors.tango}>
            <p>
              {lang.navbar.components.confirm_account_1[currentLangKey] +
                user.email +
                lang.navbar.components.confirm_account_2[currentLangKey]}

              <NavLink to='/meus-dados/enviar-confirmacao'>
                {lang.navbar.components.confirm_account_3[currentLangKey]}
              </NavLink>

              {lang.navbar.components.confirm_account_4[currentLangKey]}
            </p>
          </CloseAlert>
        )}

        <Content onScroll={onScroll}>{children}</Content>
      </ContentContainer>
    </DefaultContainer>
  );
}
