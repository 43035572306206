import { useQueryCache } from 'data/cache';
import {
  type IUseGetPaymentMethods,
  type IUseGetPaymentMethodsParams,
  PaymentMethodsQueryKeys,
  PaymentMethodsService
} from 'data/modules/paymentMethods';

export function useGetPaymentMethods(
  params?: IUseGetPaymentMethodsParams
): IUseGetPaymentMethods {
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } =
    useQueryCache({
      queryKey: [PaymentMethodsQueryKeys.GET_PAYMENT_METHODS],
      queryFn: async () => await PaymentMethodsService.getPaymentMethods()
    });

  return {
    paymentMethods: params?.withInactives
      ? paymentMethods
      : paymentMethods?.filter(item => (item.active ? item : null)),
    isLoadingPaymentMethods
  };
}
