import { darken, transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type CarrouselVariantStylesType } from './CarrouselContainer.types';

export const Container = styled.div<
  AddPrefixToType<CarrouselVariantStylesType>
>`
  width: 100%;
  position: relative;

  .custom-swiper {
    border-radius: ${({ theme }) => theme.tokens.borderRadiusMd};
    background-color: ${({ theme }) => theme.tokens.neutralColorHighLight};

    .swiper-slide {
      margin: auto 0;
    }

    .swiper-pagination {
      bottom: var(
        --swiper-pagination-bottom,
        ${({ theme }) => theme.tokens.spacingLg}
      );

      .swiper-pagination-bullet {
        opacity: 1;
        width: ${({ theme }) => theme.tokens.spacingXs};
        height: ${({ theme }) => theme.tokens.spacingXs};
        background: ${({ theme, $variant }) =>
          $variant === 'white'
            ? transparentize(0.6, theme.tokens.neutralColorHighMedium)
            : theme.tokens.neutralColorHighMedium};
        margin: 0 0.7rem;
      }

      .swiper-pagination-bullet-active {
        background: ${({ theme, $variant }) =>
          $variant === 'white'
            ? theme.tokens.neutralColorHighMedium
            : theme.tokens.brandColorPrimaryPure};
      }
    }
  }
`;

export const CustomNavigationWrapper = styled.div<
  AddPrefixToType<CarrouselVariantStylesType>
>`
  top: 50%;
  z-index: 1;
  position: absolute;
  display: inline-flex;
  transform: translate(0, -50%);
  justify-content: space-between;
  left: ${({ theme }) => theme.tokens.spacingXl};
  right: ${({ theme }) => theme.tokens.spacingXl};

  > button {
    border: none !important;
    padding: 0;

    ${({ $variant }) =>
      $variant === 'white' &&
      css`
        > svg {
          fill: ${({ theme }) => theme.tokens.neutralColorHighLight};
          transition: fill 0.3s ease-in-out;

          &:hover {
            fill: ${({ theme }) =>
              darken(0.1, theme.tokens.neutralColorHighLight)};
          }
        }
      `}
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    display: none;
    left: ${({ theme }) => theme.tokens.spacingXxs};
    right: ${({ theme }) => theme.tokens.spacingXxs};
  }
`;
