import { useLangContext } from 'data/contexts';

import { DatePicker } from 'presentation/components/base/Input';

import { type IDateFilterProps } from './DateFilter.types';

import { Container } from './DateFilter.styles';

export function DateFilter({
  from,
  to,
  onChangeFrom,
  onChangeTo,
  errors
}: IDateFilterProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  return (
    <Container>
      <DatePicker
        label={lang.cards.statement.sidebar.from[currentLangKey] + ':'}
        id='date-from'
        selected={from}
        onChange={date => {
          onChangeFrom(date);
        }}
        dateFormat='dd/MM/yyyy'
        error={errors?.from}
      />
      <DatePicker
        label={lang.cards.statement.sidebar.to[currentLangKey] + ':'}
        id='date-to'
        selected={to}
        onChange={date => {
          onChangeTo(date);
        }}
        dateFormat='dd/MM/yyyy'
        error={errors?.to}
      />
    </Container>
  );
}
