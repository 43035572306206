import { tokens } from 'ds';
import styled from 'styled-components';

export const RowInputs = styled.div`
  display: flex;
  gap: ${tokens.spacingXs};
  width: 100%;

  .react-select-custom__control,
  .react-select-custom__menu {
    max-width: unset !important;
    min-width: unset !important;
  }

  .modal-textinput {
    min-width: unset !important;
  }

  .react-select-custom__menu {
    z-index: 3;
    max-height: 23rem;
  }

  .react-select-custom__menu-list {
    max-height: 20rem;
  }

  > div {
    width: calc((100% - ${tokens.spacingXs}) / 2);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingMd};
  align-items: start;

  /* o tamanho do container é 100% da Modal, menos 40rem do componente de mapa e menos o gap */
  width: calc(100% - 40rem - ${tokens.spacingXs});
  flex-grow: 1;

  .textarea-notes > textarea {
    min-height: 6rem;
  }

  .select-project-button,
  .react-select-custom__control {
    width: 100%;
    max-width: unset;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
    width: 100%;

    ${RowInputs} {
      flex-direction: column;
      gap: ${tokens.spacingMd};

      > div {
        width: 100%;
      }
    }
  }
`;

export const RightContent = styled.div`
  flex: 1 0 20%;

  .map-container {
    height: 100%;
    width: 40rem;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
      height: 45.2rem;
    }
  }
`;
