import { Spinner } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useAnalysisUserFiltersContext } from 'data/contexts';

import { UserAnalysisItem } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisItem';
import { UserAnalysisItemSkeleton } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisItemSkeleton';

import { useUserAnalysisList } from './useUserAnalysisList';

import { SpinnerWrapper } from './UserAnalysisList.styles';

export function UserAnalysisList(): JSX.Element {
  const [isEmptyResults] = useAnalysisUserFiltersContext(
    useShallow(state => [state.isEmptyResults])
  );

  const {
    userAnalysisList,
    isLoadingUserAnalysisList,
    isFetchingUserAnalysisListNextPage
  } = useUserAnalysisList();

  return (
    <>
      {!isEmptyResults &&
        (isLoadingUserAnalysisList ? (
          <UserAnalysisItemSkeleton />
        ) : (
          <>
            {userAnalysisList.map(user => (
              <UserAnalysisItem
                key={`${user.name}-${user.positionDepartment}`}
                {...user}
              />
            ))}

            <SpinnerWrapper>
              {isFetchingUserAnalysisListNextPage && <Spinner />}
            </SpinnerWrapper>
          </>
        ))}
    </>
  );
}
