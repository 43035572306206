import { api, env } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import {
  generateStatementInExcelMock,
  generateStatementInPdfMock,
  getAccountStatementMock,
  getCompanyStatementMock,
  getTransactionsDetailsListByIdsMock
} from 'data/mocks/cards/statement';
import {
  type IAccountStatement,
  type ICompanyStatement,
  type IGenerateStatementInExcel,
  type IGenerateStatementInExcelPayload,
  type IGenerateStatementInPdf,
  type IGenerateStatementInPdfPayload,
  type IGetAccountStatementPayload,
  type IGetCompanyStatementPayload,
  type IGetTransactionsDetailsListByIdsPayload,
  type IPersistenceAccountStatement,
  type IPersistenceCompanyStatement,
  type IPersistenceTransaction,
  type ITransaction
} from 'data/modules/cards/statement';
import { type IDefaultResponse } from 'data/modules/global';

import {
  GetAccountStatementMapper,
  GetCompanyStatementMapper,
  TransactionMapper
} from './mappers';

class StatementService {
  async getCompanyStatement(
    payload: IGetCompanyStatementPayload
  ): Promise<ICompanyStatement[]> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getCompanyStatementMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceCompanyStatement[] | null>
    >('/pay/company/statement', {
      params: GetCompanyStatementMapper.toPersistence(payload)
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data?.map(GetCompanyStatementMapper.toDomain) ?? [];
  }

  async getAccountStatement({
    accountBalanceId,
    endDate,
    startDate,
    timezone,
    cardGroupId
  }: IGetAccountStatementPayload): Promise<IAccountStatement[]> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getAccountStatementMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceAccountStatement[] | null>
    >(`/pay/accounts/balances/${accountBalanceId}/statement/v2`, {
      params: GetAccountStatementMapper.toPersistence({
        accountBalanceId,
        endDate,
        startDate,
        timezone,
        cardGroupId
      })
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data?.map(GetAccountStatementMapper.toDomain) ?? [];
  }

  async generateStatementInPdf({
    accountBalancesIds,
    allUsers,
    endDate,
    startDate,
    timezone,
    transactionsIds,
    cardGroupId
  }: IGenerateStatementInPdfPayload): Promise<IGenerateStatementInPdf> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await generateStatementInPdfMock();
    }

    const response = await api.post<IDefaultResponse<IGenerateStatementInPdf>>(
      '/pay/company/statement/pdf',
      {
        start_date: startDate,
        end_date: endDate,
        balances_id: accountBalancesIds,
        transactions_id: transactionsIds,
        timezone,
        all: allUsers,
        ...(cardGroupId && { card_group_id: cardGroupId })
      }
    );

    const {
      data: { url }
    } = ResponseAdapter.formatRegularResponse(response.data);

    return {
      url
    };
  }

  async generateStatementInExcel({
    accountBalancesIds,
    allUsers,
    endDate,
    startDate,
    timezone,
    transactionsIds,
    cardGroupId
  }: IGenerateStatementInExcelPayload): Promise<IGenerateStatementInExcel> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await generateStatementInExcelMock();
    }

    const response = await api.post<
      IDefaultResponse<IGenerateStatementInExcel>
    >('/pay/company/statement/excel', {
      start_date: startDate,
      end_date: endDate,
      balances_id: accountBalancesIds,
      transactions_id: transactionsIds,
      timezone,
      all: allUsers,
      ...(cardGroupId && { card_group_id: cardGroupId })
    });

    const {
      data: { url }
    } = ResponseAdapter.formatRegularResponse(response.data);

    return {
      url
    };
  }

  async getTransactionsDetailsListByIds({
    transactionsIds
  }: IGetTransactionsDetailsListByIdsPayload): Promise<ITransaction[]> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getTransactionsDetailsListByIdsMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceTransaction[] | null>
    >('/pay/transactions/details', {
      params: {
        transaction_ids: transactionsIds.join(',')
      }
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data?.map(TransactionMapper.toDomain) ?? [];
  }
}

export default new StatementService();
