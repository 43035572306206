import { useCallback, useEffect, useRef } from 'react';

import { useIsMutating } from '@tanstack/react-query';
import { toast } from 'ds/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useAuthContext, useLangContext } from 'data/contexts';
import {
  CompanyFormCacheDomains,
  CompanyOnboardingMutationKeys,
  useGetCompanyOnboardingAnswers,
  useGetCompanyOnboardingProgress,
  useGetSerproInformations
} from 'data/modules/cards/companyOnboarding';
import {
  useGetUserOnboardingAnswers,
  useGetUserOnboardingProgress
} from 'data/modules/cards/userOnboarding';

import { CardsRoutes } from 'shared/constants/global';
import { Onboarding } from 'shared/utils/cards';

import { stepsWithoutProgressBar } from './constants';

import {
  type IUseOnboarding,
  type IUserOnboardingParams
} from './Onboarding.types';

export function useOnboarding({
  onboardingType
}: IUserOnboardingParams): IUseOnboarding {
  const [cards, currentLangKey] = useLangContext(state => [
    state.lang.cards,
    state.currentLangKey
  ]);

  const shouldChangeCompanyStep = useRef(true);
  const shouldChangeUserStep = useRef(true);

  const [user, company] = useAuthContext(
    useShallow(state => [state.user, state.company])
  );
  const navigate = useNavigate();
  const location = useLocation();

  const isMutating = useIsMutating({
    predicate: mutation =>
      mutation.state.variables?.domain !==
        CompanyFormCacheDomains.onboardingUsers &&
      mutation.options.mutationKey?.[0] !==
        CompanyOnboardingMutationKeys.PULL_SERPRO_INFORMATIONS
  });

  const isApproveRoute = location.pathname === CardsRoutes.ONBOARDING_APPROVE;

  const {
    isLoadingCompanyProgress,
    companyProgress,
    isSuccessCompanyOnboardingProgress,
    isErrorCompanyOnboardingProgress
  } = useGetCompanyOnboardingProgress({
    enabled: !isApproveRoute && onboardingType === 'COMPANY'
  });

  const navigateToCurrentStep = useCallback(
    (currentStep: number): void => {
      if (isApproveRoute) return;

      if (
        user?.cards?.onboardingCompletedAt &&
        user?.cards?.accountHolder?.kycStatus !== 'APPROVED'
      ) {
        navigate('/cartoes/onboarding/usuario/kyc');
        return;
      }

      if (
        company?.cards?.onboardingCompletedAt &&
        company?.cards?.accountHolder?.kycStatus !== 'APPROVED'
      ) {
        navigate('/cartoes/onboarding/empresa/kyc');
        return;
      }

      navigate(
        `/cartoes/onboarding/${
          onboardingType === 'COMPANY' ? 'empresa' : 'usuario'
        }/${currentStep === 1 ? 'inicio' : currentStep}`
      );
    },
    [user, company, isApproveRoute, navigate, onboardingType]
  );

  useEffect(() => {
    if (shouldChangeCompanyStep.current) {
      if (isSuccessCompanyOnboardingProgress) {
        navigateToCurrentStep(companyProgress?.currentStep as number);
        shouldChangeCompanyStep.current = false;
      }

      if (isErrorCompanyOnboardingProgress) {
        toast.error(
          cards.onboarding.company.fail_on_load_progress[currentLangKey]
        );
        shouldChangeCompanyStep.current = false;
      }
    }
  }, [
    cards.onboarding.company.fail_on_load_progress,
    companyProgress?.currentStep,
    currentLangKey,
    isErrorCompanyOnboardingProgress,
    isSuccessCompanyOnboardingProgress,
    navigateToCurrentStep
  ]);

  const {
    isUserOnboardingProgressLoading,
    userOnboardingProgress,
    isUserOnboardingProgressSuccess
  } = useGetUserOnboardingProgress({
    enabled: !isApproveRoute && onboardingType === 'USER'
  });

  useEffect(() => {
    if (shouldChangeUserStep.current) {
      if (isUserOnboardingProgressSuccess) {
        navigateToCurrentStep(userOnboardingProgress?.currentStep as number);
        shouldChangeUserStep.current = false;
      }
    }
  }, [
    userOnboardingProgress?.currentStep,
    isUserOnboardingProgressSuccess,
    navigateToCurrentStep
  ]);

  const currentStep = Onboarding.getCurrentStep(location.pathname);

  const { isUserOnboardingAnswersLoading } = useGetUserOnboardingAnswers({
    enabled:
      !isApproveRoute && Boolean(userOnboardingProgress?.onboardingUserId)
  });

  const { isCompanyOnboardingAnswersLoading } = useGetCompanyOnboardingAnswers({
    enabled:
      !isApproveRoute &&
      Boolean(companyProgress?.onboardingCompanyId) &&
      Number(currentStep) >= 2 &&
      !location.pathname.includes('/approve')
  });
  const {
    isLoadingSerproInformations,
    isErrorSerproInformations,
    errorSerproInformations
  } = useGetSerproInformations({
    enabled:
      !isApproveRoute &&
      Boolean(companyProgress?.onboardingCompanyId) &&
      Number(currentStep) < 10 &&
      Number(currentStep) >= 4
  });

  useEffect(() => {
    if (isErrorSerproInformations) {
      toast.error(errorSerproInformations?.message as string);
    }
  }, [errorSerproInformations?.message, isErrorSerproInformations]);

  const onboardingCompanyInProgress =
    !isApproveRoute &&
    onboardingType === 'COMPANY' &&
    companyProgress?.answerableUserId
      ? user?.uuid !== companyProgress?.answerableUserId
      : false;

  const totalOnboardingSteps = userOnboardingProgress ? 4 : 12;

  const onboardingProgressPercentage =
    currentStep === 'inicio'
      ? 0
      : currentStep === 'final'
        ? 100
        : (Number(currentStep) / totalOnboardingSteps) * 100;

  useEffect(() => {
    if (onboardingCompanyInProgress) {
      navigate('/cartoes/onboarding/empresa/em-progresso');
    }
  }, [navigate, onboardingCompanyInProgress]);

  return {
    onboardingProgressPercentage,
    onboardingProgress: companyProgress ?? userOnboardingProgress,
    currentStep,
    isLoadingProgress:
      (onboardingType === 'COMPANY' &&
        (isLoadingCompanyProgress ||
          isCompanyOnboardingAnswersLoading ||
          (isLoadingSerproInformations && Number(currentStep) !== 3))) ||
      (onboardingType === 'USER' &&
        (isUserOnboardingProgressLoading || isUserOnboardingAnswersLoading)) ||
      isMutating > 0,
    showPercentageBar: !stepsWithoutProgressBar.includes(location.pathname)
  };
}
