import {
  type IPersistenceValidateMileagePolicyByProjectPayload,
  type IPersistenceValidateMileagePolicyByProjectResponse,
  type IValidateMileagePolicyByProjectPayload,
  type IValidateMileagePolicyByProjectResponse
} from 'data/modules/expenses/types/expenses.types';

class ValidateMileagePolicyByProjectMapper {
  toPersistence(
    domain: IValidateMileagePolicyByProjectPayload
  ): IPersistenceValidateMileagePolicyByProjectPayload {
    return {
      kilometrage: domain.mileage,
      project_id:
        typeof domain.projectId === 'string'
          ? parseInt(domain.projectId)
          : domain.projectId
    };
  }

  toDomain(
    persistence: IPersistenceValidateMileagePolicyByProjectResponse
  ): IValidateMileagePolicyByProjectResponse {
    return {
      allowChangeAmountPerKm:
        persistence.is_amount_per_kilometer_change_allowed,
      mileagePaidValue: parseFloat(persistence.amount_per_kilometer),
      value: persistence.amount,
      requiredReceiptInManualRoute:
        persistence.is_manual_route_receipt_mandatory
    };
  }
}

export default new ValidateMileagePolicyByProjectMapper();
