import { usePagination } from 'ds/components/Table/Pagination/PaginationContainer/PaginationContainer.context';

import { type IUsePaginationController } from './PaginationController.types';

export function usePaginationController(): IUsePaginationController {
  const { totalPage, currentPage } = usePagination();

  function getPaginateInterval(): number[] {
    // Caso base não necessita dos ...
    if (totalPage <= 4) {
      return Array.from(Array(totalPage).keys()).map(i => i + 1);
    }

    // Se tiver próximo do fim mostra o ultimo intervalo TotalPage - 2 até TogalPage
    if (currentPage + 2 >= totalPage) {
      return [1, -1, totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
    }

    // Engloba os casos iniciais com ... até 1 2 3 ... N
    if (currentPage <= 3) {
      return [1, 2, 3, 4, -1, totalPage];
    }

    // Caso generico após currentPage ser maior que 3 e não estar proximo 2 casas do fim ou inicio
    return [
      1,
      -1,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      -2,
      totalPage
    ];
  }

  return {
    paginationItems: getPaginateInterval(),
    totalPage,
    currentPage
  };
}
