import { useQueryCache } from 'data/cache';
import {
  GlobalQueryKeys,
  GlobalService,
  type IUseGetViaCepAddressByPostalCode,
  type IUseGetViaCepAddressByPostalCodeParams
} from 'data/modules/global';

export function useGetViaCepAddressByPostalCode({
  postalCode,
  enabled
}: IUseGetViaCepAddressByPostalCodeParams): IUseGetViaCepAddressByPostalCode {
  const { data, isFetching } = useQueryCache({
    queryKey: [GlobalQueryKeys.GET_VIA_CEP_ADDRESS_BY_POSTAL_CODE, postalCode],
    queryFn: async () =>
      await GlobalService.getViaCepAddressByPostalCode({ postalCode }),
    enabled: enabled ?? true
  });

  return {
    address: data,
    isFetchingAddress: isFetching
  };
}
