import { tokens } from 'ds';
import { motion } from 'framer-motion';
import { css, styled } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type TextButtonLabelStylesType } from './TableFilters.types';

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
`;

export const FiltersFirstLine = styled.div`
  display: flex;
  gap: ${tokens.spacingXs};
  flex-wrap: wrap;

  > div {
    width: auto;
    min-width: unset;
  }

  > div:first-child {
    flex-grow: 1;
  }

  .datepicker-container {
    width: 25rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-wrap: wrap;

    > div {
      flex-grow: 1;
      max-width: unset;
    }

    > div:last-of-type {
      flex-grow: unset;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: column nowrap;

    .datepicker-container {
      width: 100%;
    }

    > div:first-child {
      flex-grow: unset;
    }

    > div:last-of-type {
      .react-select-custom__control,
      .react-select-custom__menu {
        max-width: unset;
      }
    }
  }
`;

export const FiltersSecondLine = styled(motion.div)`
  display: flex;
  gap: ${tokens.spacingXs};
  justify-content: start;

  > div {
    width: auto;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: column nowrap;

    > div {
      .react-select-custom__control,
      .react-select-custom__menu,
      .react-multiSelect-custom__control,
      .react-multiSelect-custom__menu {
        max-width: unset;
      }
    }
  }
`;

export const TextButtonLabel = styled.div<
  AddPrefixToType<TextButtonLabelStylesType>
>`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;

  > svg {
    transition: all 100ms ease-in-out;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      svg {
        transform: rotateZ(3.14rad);
      }
    `};
`;

export const TabContainer = styled.div`
  overflow: auto;
  padding-bottom: 1rem;
`;
