import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuthContext } from 'data/contexts';
import { useGetCardGroup } from 'data/modules/cards/account/useCases/get-card-group/useGetCardGroup';

import {
  AvailableValueCardInfo,
  CardsContainerPageHeader,
  CardsPageHeader,
  DistributedValueCardInfo
} from 'presentation/pages/cards/components';

export function MovementsPageHeader(): JSX.Element {
  const user = useAuthContext(state => state.user);

  const { t } = useTranslation();
  const { groupId } = useParams();
  const navigate = useNavigate();

  const { cardGroupData } = useGetCardGroup({
    cardGroupId: groupId!,
    enabled: !!groupId
  });

  return (
    <CardsPageHeader
      tabs={[
        {
          active: false,
          label: t('cardsManagement'),
          hidden: !groupId && user?.cards?.type === 'USER',
          onClick: () => {
            groupId
              ? navigate(`/cartoes/gestao-de-grupos/${groupId}`)
              : navigate('/cartoes/gestao');
          }
        },
        {
          active: true,
          onClick: () => {
            navigate(
              groupId
                ? `/cartoes/gestao-de-grupos/${groupId}/movimentacoes`
                : '/cartoes/movimentacoes'
            );
          },
          label: t('movements')
        },
        {
          active: false,
          onClick: () => {
            groupId
              ? navigate(
                  `/cartoes/gestao-de-grupos/${groupId}/solicitacoes-de-valores`
                )
              : navigate('/cartoes/solicitacoes-de-valores');
          },
          label: t('valuesSolicitations'),
          hidden: groupId
            ? !cardGroupData?.isManager
            : user?.cards?.type !== 'ACCOUNT_MANAGER'
        },
        {
          active: false,
          onClick: () => {
            navigate('/cartoes/meu-cartao');
          },
          hidden: !user?.cards?.hasAccount || !!groupId,
          label: t('myCard')
        }
      ]}
    >
      <CardsContainerPageHeader>
        <AvailableValueCardInfo />

        <DistributedValueCardInfo hasCardGroup />
      </CardsContainerPageHeader>
    </CardsPageHeader>
  );
}
