import { DSIcons } from 'ds/icons';

import { type IAccordionLabelProps } from './AccordionLabel.types';

import { Container } from './AccordionLabel.styles';

export function AccordionLabel({
  children,
  showIcon = true,
  ...props
}: IAccordionLabelProps): JSX.Element {
  return (
    <Container {...props}>
      {children}
      {showIcon && (
        <>
          <DSIcons.ActionsPlusIcon className='icon-close' />
          <DSIcons.ActionsMinusIcon className='icon-open' />
        </>
      )}
    </Container>
  );
}
