import { useQueryCache } from 'data/cache';
import {
  ExpensesQueryKeys,
  ExpensesService,
  type IGetUserExpensesPendingAccountabilityPayload,
  type IUseGetUserExpensesPendingAccountability
} from 'data/modules/expenses';

export function useGetUserExpensesPendingAccountability({
  ...payload
}: IGetUserExpensesPendingAccountabilityPayload): IUseGetUserExpensesPendingAccountability {
  const {
    data: userExpensesPendingAccountability,
    isFetching: isFetchingUserExpensesPendingAccountability
  } = useQueryCache({
    queryKey: [ExpensesQueryKeys.GET_USER_EXPENSES_ACCOUNTABILITY, payload],
    queryFn: async () =>
      await ExpensesService.getUserExpensesPendingAccountability(payload)
  });

  return {
    userExpensesPendingAccountability,
    isFetchingUserExpensesPendingAccountability
  };
}
