import { CardInfo } from 'ds';
import { useTranslation } from 'react-i18next';

import { useGetAllocatedBalanceCardGroup } from 'data/modules/cards/cardGroups';

import { Currency } from 'shared/utils/format';

import { type IDistributedCardsGroupValueCardInfoProps } from './DistributedCardsGroupValueCardInfo.types';

import { CardInfoContainer } from './DistributedCardsGroupValueCardInfo.styles';

export function DistributedCardsGroupValueCardInfo({
  ...rest
}: IDistributedCardsGroupValueCardInfoProps): JSX.Element {
  const { t } = useTranslation();

  const { allocatedBalanceCardGroup, isFetchingAllocatedBalanceCardGroup } =
    useGetAllocatedBalanceCardGroup();

  return (
    <CardInfoContainer {...rest}>
      <CardInfo.Title
        icon='MoneyWalletCheckMark'
        variant='small'
      >
        {t('amountDistributedOnCardsGruops')}
      </CardInfo.Title>

      <CardInfo.ValueContainer>
        <CardInfo.Value
          isLoading={
            isFetchingAllocatedBalanceCardGroup || !allocatedBalanceCardGroup
          }
          variant='small'
        >
          {allocatedBalanceCardGroup &&
            Currency.format('BRL', allocatedBalanceCardGroup?.amount, true)}
        </CardInfo.Value>

        {/* Necessita de ajustes a ser validado */}
        {/*  <CardInfo.RefreshButton
          onClick={() => {
            !isCardGroupsDistributedValueFetching &&
              refreshCardGroupsDistributedValue.mutate(1);
          }}
        /> */}
      </CardInfo.ValueContainer>
      <CardInfo.Caption variant='small'>&nbsp;</CardInfo.Caption>
      {/* Necessita de ajustes a ser validado */}
      {/* <CardInfo.Caption
        isLoading={
          isFetchingAllocatedBalanceCardGroup || !allocatedBalanceCardGroup
        }
        variant='small'
      ></CardInfo.Caption> */}
    </CardInfoContainer>
  );
}
