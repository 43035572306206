import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import {
  type ExpenseValueStylesType,
  type ReverseValueStylesType,
  type TransactionInfoStylesType,
  type ValuesDifferenceStylesType,
  type ValuesStylesType
} from './TransactionDetailsModal.types';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  min-width: 25rem;
  max-width: 72.7rem;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    align-items: flex-start;

    > button,
    > a {
      margin: 0 auto;
    }
  }
`;

export const TransactionInfo = styled.div<
  AddPrefixToType<TransactionInfoStylesType>
>`
  width: 100%;
  padding-right: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-right: 0;
  }

  > p {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.emperor};
    line-height: 3rem;

    ${({ $isTransactionReversed }) =>
      $isTransactionReversed &&
      css`
        text-decoration: line-through;
      `}
  }
`;

export const ValuesContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
  }
`;

export const Values = styled.div<AddPrefixToType<ValuesStylesType>>`
  min-width: 24rem;
  padding-right: 1rem;

  ${({ $hasExpense, theme }) =>
    $hasExpense &&
    css`
      border-right: 1px solid ${theme.colors.emperor};

      > p:last-child {
        > span {
          color: ${theme.colors.puertoRico};
        }
      }
    `}

  > p {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.emperor};
    line-height: 3rem;
    display: flex;
    justify-content: space-between;

    > span {
      font-size: 1.6rem;
      font-weight: 700;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    border-right: none;
  }
`;

export const ReverseValue = styled.p<AddPrefixToType<ReverseValueStylesType>>`
  color: ${({ theme }) => theme.colors.primary};

  span {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ $isTransactionReversed }) =>
    $isTransactionReversed &&
    css`
      text-decoration: line-through;

      > span {
        text-decoration: line-through;
      }
    `}
`;

export const ExpenseValue = styled.p<AddPrefixToType<ExpenseValueStylesType>>`
  color: ${({ $isNegative, theme }) =>
    $isNegative ? theme.colors.danger : theme.colors.puertoRico};

  ${({ $isTransactionReversed }) =>
    $isTransactionReversed &&
    css`
      text-decoration: line-through;

      > span {
        text-decoration: line-through;
      }
    `}
`;

export const ValuesDifference = styled.div<
  AddPrefixToType<ValuesDifferenceStylesType>
>`
  padding-left: 1rem;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.clementine};
  line-height: 3rem;
  font-weight: 500;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-right: 1rem;
    border-top: 1px solid black;
  }

  ${({ $isTransactionReversed }) =>
    $isTransactionReversed &&
    css`
      text-decoration: line-through;
    `}
`;

export const ResponsibleContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.alto};
  padding: 1rem 0;
  margin-top: 1rem;

  > p {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.emperor};

    > span {
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colors.secondary};
      font-weight: 500;
    }
  }
`;
