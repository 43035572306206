import { type IModalFooterProps } from './ModalFooter.types';

import { Container } from './ModalFooter.styles';

export function ModalFooter({
  children,
  ...rest
}: IModalFooterProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
