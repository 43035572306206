import { useQueryCache } from 'data/cache';
import {
  AnalyticsQueryKeys,
  AnalyticsService,
  type IUseGetAnalysisUserHeader,
  type IUseGetAnalysisUserHeaderParams
} from 'data/modules/analytics';

export function useGetAnalysisUserHeader(
  params: IUseGetAnalysisUserHeaderParams
): IUseGetAnalysisUserHeader {
  const { data, isLoading } = useQueryCache({
    queryKey: [AnalyticsQueryKeys.GET_ANALYSIS_USER_HEADER, params],
    queryFn: async () => await AnalyticsService.getAnalysisUserHeader(params)
  });

  return {
    pagination: data?.[1],
    analysisUserHeader: data?.[0] ?? [],
    isLoadingAnalysisUserHeader: isLoading
  };
}
