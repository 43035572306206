import { Outlet } from 'react-router-dom';

import { useAuthContext } from 'data/contexts';

import { FallbackPage } from 'presentation/pages/global';

export function ManagerMiddleware(): JSX.Element {
  const user = useAuthContext(state => state.user);

  if (user?.userType === 'ADMINISTRADOR') {
    return <Outlet />;
  }

  return <FallbackPage error='401' />;
}
