import { BadgeStatus, DSIcons, TextButton } from 'ds';
import { useTranslation } from 'react-i18next';

import { type IAnalysisChartCardProps } from './AnalysisChartCard.types';

import {
  ChartCardHeader,
  ChartCardInformation,
  ChartCardSubtitle,
  ChartCardTitle,
  ChartWrapper,
  Container,
  NoDataContainer
} from './AnalysisChartCard.styles';

export function AnalysisChartCard({
  title,
  subtitle,
  children,
  information,
  disabled = false,
  withNoData = false,
  onActionButtonClick
}: IAnalysisChartCardProps): JSX.Element {
  const { t } = useTranslation('analytics');

  return (
    <Container $disabled={disabled}>
      <ChartCardHeader>
        <ChartCardTitle>{title}</ChartCardTitle>
        {disabled ? (
          <BadgeStatus type='warning'>
            {t('fullAnalysisComingSoon')}
          </BadgeStatus>
        ) : (
          <TextButton onClick={onActionButtonClick}>
            {t('viewFullAnalysis')}
            <DSIcons.ArrowRightIcon />
          </TextButton>
        )}
      </ChartCardHeader>
      {!withNoData && (
        <>
          <ChartCardSubtitle>{subtitle}</ChartCardSubtitle>
          {information && (
            <ChartCardInformation>{information}</ChartCardInformation>
          )}
        </>
      )}
      {withNoData ? (
        <NoDataContainer>{t('noDataInFilteredPeriod')}</NoDataContainer>
      ) : (
        <ChartWrapper>{children}</ChartWrapper>
      )}
    </Container>
  );
}
