import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useLangContext } from 'data/contexts';
import { useGetAdvancePayments } from 'data/modules/advancePayments';
import { useGetPaymentMethods } from 'data/modules/paymentMethods';
import {
  type IOpenReport,
  ReportsQueryKeys,
  useCreateReport
} from 'data/modules/reports';

import { TransformArray } from 'shared/utils/global';

import {
  type IUseCreateReportForm,
  type IUseCreateReportFormParams
} from './CreateReportForm.types';

export function useCreateReportForm({
  onReportCreated
}: IUseCreateReportFormParams): IUseCreateReportForm {
  const { lang, currentLangKey } = useLangContext();

  const queryClient = useQueryClient();

  const { form, isCreatingReport } = useCreateReport({
    onAfterCreateReportSuccess: createdReport => {
      // aqui vai atualizar a lista dos relatórios que são exibidos na segunda etapa do modal de criação de despesa
      queryClient.setQueryData<IOpenReport[]>(
        [ReportsQueryKeys.GET_OPEN_REPORTS],
        oldData => {
          if (oldData) {
            return [createdReport, ...oldData];
          }

          return oldData;
        }
      );

      toast.success(
        lang.reports.modal.create_report.report_created_successfully[
          currentLangKey
        ]
      );

      onReportCreated(createdReport.uuid);
    }
  });

  const { paymentMethods, isLoadingPaymentMethods } = useGetPaymentMethods();

  const paymentMethodsOptions = TransformArray.objectsToSelectOptions(
    paymentMethods,
    'id',
    'description'
  );

  const { advancePayments, isLoadingAdvancePayments } = useGetAdvancePayments();

  const advancePaymentsOptions = TransformArray.objectsToSelectOptions(
    advancePayments,
    'uuid',
    'title'
  );

  return {
    isLoadingPaymentMethods,
    paymentMethodsOptions,
    advancePaymentsOptions,
    isLoadingAdvancePayments,
    form,
    isCreatingReport
  };
}
