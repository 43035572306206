import { useMutationCache } from 'data/cache';

import { SurveyQueryKeys } from '../../keys/SurveyResponse.keys';
import SurveyService from '../../services/Survey.service';

import {
  type INpsSurveyResponsePayload,
  type IUseCreateNpsSurveyResponse
} from '../../types/survey.types';

export function useCreateNpsSurveyResponse(): IUseCreateNpsSurveyResponse {
  const { isLoading, mutate: createNpsSurveyResponse } = useMutationCache({
    mutationKey: [SurveyQueryKeys.CREATE_NPS_SURVEY_RESPONSE],
    mutationFn: async (payload: INpsSurveyResponsePayload) => {
      await SurveyService.createNpsSurveyResponse(payload);
    }
  });

  return {
    isCreatingNpsSurveyResponse: isLoading,
    createNpsSurveyResponse
  };
}
