import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUpdateRequestCardsFieldsForm,
  type IUseUpdateRequestCards,
  type IUseUpdateRequestCardsParams,
  type UpdateCompanyOnboardingAnswersPayloadType
} from 'data/modules/cards/companyOnboarding';

import { useFormWithSchema } from 'shared/hooks/forms';
import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import { useUpdateRequestCardsSchema } from './schemas';

export function useUpdateRequestCards({
  onUpdateCompanyOnboardingAnswersMutationSuccess
}: IUseUpdateRequestCardsParams): IUseUpdateRequestCards {
  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const { data: cachedCompanyOnboardingAnswers } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      ],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingAnswers({
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId as string
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    }
  });
  const {
    control,
    formState: { errors, isDirty },
    setValue,
    handleSubmit,
    trigger,
    watch
  } = useFormWithSchema(useUpdateRequestCardsSchema(), {
    defaultValues: {
      postalCode:
        cachedCompanyOnboardingAnswers?.cardsOrder?.cardsOrder?.postalCode,
      city: cachedCompanyOnboardingAnswers?.cardsOrder?.cardsOrder?.city,
      state: cachedCompanyOnboardingAnswers?.cardsOrder?.cardsOrder?.state,
      complement:
        cachedCompanyOnboardingAnswers?.cardsOrder?.cardsOrder?.complement,
      country:
        cachedCompanyOnboardingAnswers?.cardsOrder?.cardsOrder?.country ?? 'BR',
      neighborhood:
        cachedCompanyOnboardingAnswers?.cardsOrder?.cardsOrder?.neighborhood,
      number: cachedCompanyOnboardingAnswers?.cardsOrder?.cardsOrder?.number,
      street: cachedCompanyOnboardingAnswers?.cardsOrder?.cardsOrder?.street,
      quantity:
        cachedCompanyOnboardingAnswers?.cardsOrder?.cardsOrder?.quantity,
      phoneNumber:
        cachedCompanyOnboardingAnswers?.cardsOrder?.cardsOrder?.phoneNumber,
      receiver: cachedCompanyOnboardingAnswers?.cardsOrder?.cardsOrder?.receiver
    }
  });

  const { isLoading: isUpdatingRequestCards, mutate: updateRequestCards } =
    useMutationCache({
      mutationKey: [
        CompanyOnboardingMutationKeys.UPDATE_COMPANY_ONBOARDING_ANSWERS
      ],
      mutationFn: async (
        payload: UpdateCompanyOnboardingAnswersPayloadType
      ) => {
        await CompanyOnboardingService.updateCompanyOnboardingAnswers(payload);
      },
      onSuccess: (data, variables) => {
        onUpdateCompanyOnboardingAnswersMutationSuccess({
          ...variables.answers
        } as IUpdateRequestCardsFieldsForm);
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  function onSubmit(userEnteredData: IUpdateRequestCardsFieldsForm): void {
    if (isUpdatingRequestCards) {
      return;
    }

    if (
      !isDirty &&
      currentStep < Number(cachedCompanyOnboardingProgress?.currentStep)
    ) {
      onUpdateCompanyOnboardingAnswersMutationSuccess(userEnteredData);

      return;
    }

    updateRequestCards({
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '',
      step: currentStep,
      context: 'cards_order',
      answers: {
        cardsOrder: {
          ...userEnteredData
        }
      }
    });
  }

  function handleCancelRequestCards(): void {
    updateRequestCards({
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '',
      step: currentStep,
      context: 'cards_order',
      answers: {
        cardsOrder: null
      }
    });
  }

  return {
    handleUpdateRequestCards: handleSubmit(onSubmit),
    handleCancelRequestCards,
    errors,
    setValue,
    control,
    watch,
    trigger
  };
}
