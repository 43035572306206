import { useLangContext, useModalContext } from 'data/contexts';

import { Alert } from 'presentation/components/base/Alert';
import { BaseIcon } from 'presentation/components/base/Icon';
import { Modal } from 'presentation/components/base/Modal';

import { CardNumber, Currency } from 'shared/utils/format';

import { usePendencyDetailsModal } from './usePendencyDetailsModal';

import { type IPendencyDetailsModalProps } from './PendencyDetailsModal.types';

import {
  Content,
  Description,
  PendencyInfo,
  Value
} from './PendencyDetailsModal.styles';

export function PendencyDetailsModal({
  pendency
}: IPendencyDetailsModalProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { visibleModal, handleCloseModal } = useModalContext();

  const { formattedPendencyDescription, modalHeader } = usePendencyDetailsModal(
    {
      pendency
    }
  );

  return (
    <Modal
      isOpen={visibleModal === 'pendencyDetails'}
      onClose={handleCloseModal}
      secondaryButton={{
        onClick: handleCloseModal,
        children: lang.global.close[currentLangKey],
        size: 'xl'
      }}
      title={modalHeader}
    >
      {pendency && (
        <Content>
          <PendencyInfo>
            <Description>
              {formattedPendencyDescription}
              <BaseIcon
                name='clock'
                type='line'
                color='clementine'
                size={1.6}
              />
            </Description>

            {/* VALOR */}
            <p>
              {`${lang.global.value[currentLangKey]}: `}
              <Value>
                <span>BRL </span>
                {Currency.format('BRL', pendency.amount)}
              </Value>
            </p>

            {/* FINAL DO CARTÃO */}
            {pendency.cardNumber && (
              <p>
                {`${
                  lang.cards.global.end_of_card[currentLangKey]
                }: ${CardNumber.format(pendency.cardNumber)}`}
              </p>
            )}

            {/* USUÁRIO */}
            <p>{`${lang.global.user[currentLangKey]}: ${pendency.userName}`}</p>
          </PendencyInfo>

          <Alert
            iconProps={{
              name: 'information'
            }}
            backgroundColor='tango'
          >
            {
              lang.cards.statement.pendency_details_modal.warning[
                currentLangKey
              ]
            }
          </Alert>
        </Content>
      )}
    </Modal>
  );
}
