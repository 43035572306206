import { useEffect } from 'react';

import { toast } from 'ds/utils';
import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';
import {
  useCompleteUserOnboarding,
  useUpdateUserOnboardingAddress
} from 'data/modules/cards/userOnboarding';
import { useGetViaCepAddressByPostalCode } from 'data/modules/global';

import { states } from 'shared/constants/global';
import { Mask } from 'shared/utils/format';

import { type IUseUserAddress } from './UserAddress.types';

export function useUserAddress(): IUseUserAddress {
  const [postalCodeNotFound, currentLangKey] = useLangContext(state => [
    state.lang.global.postal_code_not_found,
    state.currentLangKey
  ]);

  const { completeUserOnboarding } = useCompleteUserOnboarding({
    onNavigateToFinalStep: navigateToFinalStep
  });

  const {
    control,
    handleUpdateUserOnboardingAddress,
    errors,
    setValue,
    watch
  } = useUpdateUserOnboardingAddress({
    onAfterSuccessCompleteUserOnboarding: completeUserOnboarding
  });

  const navigate = useNavigate();

  function navigateToFinalStep(): void {
    navigate('/cartoes/onboarding/usuario/final');
  }

  const postalCode = watch('postalCode');

  const { isFetchingAddress, address } = useGetViaCepAddressByPostalCode({
    postalCode,
    enabled: postalCode?.length === 9
  });

  useEffect(() => {
    const foundState = states.find(state => state.value === address?.state);

    if (address === null) {
      toast.error(postalCodeNotFound[currentLangKey]);
      return;
    }

    if (address) {
      setValue('street', Mask.apply('letters', address.street), {
        shouldValidate: true
      });
      setValue(
        'complement',
        Mask.apply('alphanumericWithAccents', address.complement),
        {
          shouldValidate: true
        }
      );
      setValue('neighborhood', Mask.apply('letters', address.neighborhood), {
        shouldValidate: true
      });
      setValue('city', Mask.apply('letters', address.city), {
        shouldValidate: true
      });
    }

    if (foundState) {
      setValue('state', foundState as unknown as string, {
        shouldValidate: true
      });
    }
  }, [address, setValue, currentLangKey, postalCodeNotFound]);

  return {
    control,
    handleUpdateUserOnboardingAddress,
    errors,
    setValue,
    isFetchingAddress
  };
}
