import {
  type IBalanceSolicitation,
  type IQuantityOfBalanceSolicitationByStatus
} from 'data/modules/cards/balance';

import { ValueSolicitationTable } from 'presentation/pages/cards/Management/ValueSolicitation/components/ValueSolicitationList/ValueSolicitationTable/ValueSolicitationTable.component';

export function ValueSolicitationList({
  balanceSolicitations,
  isLoadingBalanceSolicitations,
  quantitySolicitationByStatus
}: {
  balanceSolicitations: IBalanceSolicitation[];
  isLoadingBalanceSolicitations: boolean;
  quantitySolicitationByStatus:
    | IQuantityOfBalanceSolicitationByStatus
    | undefined;
}): JSX.Element {
  return (
    <>
      <ValueSolicitationTable
        balanceSolicitations={balanceSolicitations}
        isLoadingBalanceSolicitations={isLoadingBalanceSolicitations}
        quantitySolicitationByStatus={quantitySolicitationByStatus}
      />
    </>
  );
}
