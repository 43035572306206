import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import {
  BusinessCardsMutationKeys,
  BusinessCardsQueryKeys,
  BusinessCardsService,
  type HandleFakeChangingApprovalFlowType,
  type IAttachApprovalFlowsAndExpensePoliciesPayload,
  type IChangeBusinessCardApprovalFlowFieldsForm,
  type IUseChangeBusinessCardApprovalFlow,
  type IUseChangeBusinessCardApprovalFlowParams,
  type IVirtualMembers
} from 'data/modules/cards/businessCard';

import { useFormWithSchema } from 'shared/hooks/forms';

import { useChangeBusinessCardApprovalFlowSchema } from './schemas';

export function useChangeBusinessCardApprovalFlow({
  card
}: IUseChangeBusinessCardApprovalFlowParams): IUseChangeBusinessCardApprovalFlow {
  const [isFakeChangingApprovalFlow, setIsFakeChangingApprovalFlow] =
    useState(false);
  const [currentApprovalFlowId, setCurrentApprovalFlowId] = useState<
    number | null
  >(card.approvalFlowId ?? null);

  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const {
    control,
    handleSubmit,
    reset: formReset,
    clearErrors,
    formState: { errors }
  } = useFormWithSchema(useChangeBusinessCardApprovalFlowSchema(), {
    defaultValues: {
      approvalFlow: card?.approvalFlowId ?? undefined
    }
  });

  const queryClient = useQueryClient();

  const {
    mutate: approvalFlowsAndExpensePoliciesMutation,
    isLoading: loadingApprovalFlowsAndExpensePoliciesMutation
  } = useMutationCache({
    mutationKey: [
      BusinessCardsMutationKeys.ATTACH_APPROVAL_FLOWS_AND_EXPENSES_POLICIES
    ],
    mutationFn: async (
      payload: IAttachApprovalFlowsAndExpensePoliciesPayload
    ) =>
      await BusinessCardsService.attachApprovalFlowsAndExpensePolicies(payload),
    onSuccess: (data, payload) => {
      if (data?.uuid) {
        setCurrentApprovalFlowId(payload.approvalFlowId ?? null);
        toast.success(
          lang.settings.manageBusinessCard.approval_flow_changed_successfully[
            currentLangKey
          ]
        );

        queryClient.setQueryData<IVirtualMembers>(
          [BusinessCardsQueryKeys.GET_VIRTUAL_MEMBERS, card.uuid],
          oldData =>
            ({
              ...oldData,
              approvalFlowId: payload.approvalFlowId
            }) as IVirtualMembers
        );
      }
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function handleFakeChangingApprovalFlow(): HandleFakeChangingApprovalFlowType {
    setIsFakeChangingApprovalFlow(true);

    const isFakeChangingTimeOut = setTimeout(() => {
      setIsFakeChangingApprovalFlow(false);
      toast.success(
        lang.settings.manageBusinessCard.approval_flow_changed_successfully[
          currentLangKey
        ]
      );
    }, 1000);

    return () => {
      clearTimeout(isFakeChangingTimeOut);
    };
  }

  function onSubmit(data: IChangeBusinessCardApprovalFlowFieldsForm): void {
    if (data.approvalFlow === currentApprovalFlowId) {
      handleFakeChangingApprovalFlow();
      return;
    }
    approvalFlowsAndExpensePoliciesMutation({
      uuid: card.uuid,
      approvalFlowId: data.approvalFlow ?? null
    });
  }

  return {
    control,
    errors,
    isLoadingApprovalFlow:
      isFakeChangingApprovalFlow ||
      loadingApprovalFlowsAndExpensePoliciesMutation,
    handleSubmit: handleSubmit(onSubmit),
    clearErrors,
    reset: formReset
  };
}
