import { tokens } from 'ds';
import styled from 'styled-components';

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  margin-right: -1.5rem;
  width: 100%;
`;

export const CustomSuccessIcon = styled.div`
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${tokens.feedbackColorSuccessLightest};
`;

export const CustomErrorIcon = styled.div`
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${tokens.feedbackColorErrorLightest};
`;

export const ModalMessage = styled.p`
  max-width: 38rem;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightRegular};
  color: ${tokens.neutralColorLowDark};
  line-height: 3.2rem;
  letter-spacing: 0.75px;
  text-align: center;
`;

export const FailedTransferListContainer = styled.ul`
  margin-top: 4.8rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-height: 30rem;
  padding-right: 1.5rem;
  width: 100%;
  overflow: auto;
`;

export const ListItemContainer = styled.li`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid ${tokens.neutralColorHighDark};
  ${ModalMessage} {
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const Value = styled.span`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  color: ${tokens.neutralColorLowDark};
  line-height: 3.2rem;
  letter-spacing: 0.75px;
  text-align: center;
`;
