import { useQueryCache } from 'data/cache';
import {
  BalanceQueryKeys,
  BalanceService,
  type IUseGetModifiedBalanceAllocationItemsFromCache,
  type IUseGetModifiedBalanceAllocationItemsFromCacheParams
} from 'data/modules/cards/balance';

export function useGetModifiedBalanceAllocationItemsFromCache(
  params?: IUseGetModifiedBalanceAllocationItemsFromCacheParams
): IUseGetModifiedBalanceAllocationItemsFromCache {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [BalanceQueryKeys.GET_CACHED_MODIFIED_BALANCE_ALLOCATION_ITEMS],
    queryFn: async () =>
      await BalanceService.getModifiedBalanceAllocationItemsFromCache(),
    ...(params?.enabled && { enabled: params.enabled })
  });

  return {
    modifiedBalanceAllocationItemsFromCache: data,
    isLoadingModifiedBalanceAllocationItemsFromCache: isLoading,
    isFetchingModifiedBalanceAllocationItemsFromCache: isFetching
  };
}
