import { create } from 'zustand';

import {
  type IErrorMessage,
  type IUseModalContext
} from './useModalContext.types';

export const useModalContext = create<IUseModalContext>()(set => ({
  visibleModal: 'closed',
  errorMessage: undefined,
  handleOpenModal: modal => {
    set({ visibleModal: modal });
  },
  handleCloseModal: () => {
    set({ visibleModal: 'closed' });
  },
  handleChangeErrorMessage: (errorMessage?: IErrorMessage) => {
    set({ errorMessage });
  }
}));
