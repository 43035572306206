import { useMemo } from 'react';

import { useLangContext } from 'data/contexts';
import { useGetTransactionDetails } from 'data/modules/cards/statement';
import { useGetExpense } from 'data/modules/expenses';

import { BaseIcon } from 'presentation/components/base/Icon';
import { TransactionValue } from 'presentation/pages/cards/Statement/components/Table';

import { CustomDate } from 'shared/utils/custom';
import { PendencyType } from 'shared/utils/format';
import { Lang } from 'shared/utils/global';

import {
  type IUseRowTransaction,
  type UseRowTransactionParamsType
} from './RowTransaction.types';

import { PendencyDescription } from './RowTransaction.styles';

export function useRowTransaction({
  transaction,
  originalTransactionProps,
  pendency
}: UseRowTransactionParamsType): IUseRowTransaction {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const expenseQueryEnabled =
    transaction.debitCredit === 'DEBIT' &&
    transaction.type !== 'TRANSFER_DEBIT';

  const { expense, isLoadingExpense } = useGetExpense({
    enabled: expenseQueryEnabled,
    transactionId: expenseQueryEnabled ? transaction.transactionId : 'disabled'
  });

  const originalTransactiondDetailsQueryEnabled =
    !!originalTransactionProps?.id &&
    originalTransactionProps.data === undefined;

  const {
    transaction: originalTransaction,
    isLoadingTransaction: isLoadingOriginalTransaction
  } = useGetTransactionDetails({
    transactionId: originalTransactiondDetailsQueryEnabled
      ? (originalTransactionProps?.id as string)
      : 'disabled',
    enabled: originalTransactiondDetailsQueryEnabled
  });

  const transactionTooltipContent = useMemo(() => {
    if (
      transaction.isReversal === 'PARTIALLY' ||
      transaction.isReversal === 'FULLY'
    ) {
      if (originalTransactionProps?.data) {
        return Lang.putValuesInString(
          lang.cards.statement.table.reversal_original_transaction_date[
            currentLangKey
          ],
          {
            date: CustomDate.formatFromISO(
              originalTransactionProps.data.datetime
            )
          }
        );
      }

      if (originalTransactiondDetailsQueryEnabled) {
        if (isLoadingOriginalTransaction) {
          return lang.global.loading[currentLangKey];
        }
        if (originalTransaction) {
          return Lang.putValuesInString(
            lang.cards.statement.table.reversal_original_transaction_date[
              currentLangKey
            ],
            {
              date: CustomDate.formatFromISO(originalTransaction.datetime)
            }
          );
        }
      }

      return lang.cards.statement.table.reversal_transaction[currentLangKey];
    }

    if (!transaction.isInternational) {
      // Data da transação de estorno que se encontra no relateds da transação
      const reversalDate = transaction.relateds[0]?.datetime;

      if (reversalDate) {
        return Lang.putValuesInString(
          lang.cards.statement.table.reversal_transaction_date[currentLangKey],
          {
            date: CustomDate.formatFromISO(reversalDate)
          }
        );
      }
    }

    if (transaction.isInternational) {
      // Data da transação de estorno que se encontra no relateds da transação internacional
      const reversalDate = transaction.relateds.find(
        related => related.isInternational
      )?.datetime;

      if (reversalDate) {
        return Lang.putValuesInString(
          lang.cards.statement.table.reversal_transaction_date[currentLangKey],
          {
            date: CustomDate.formatFromISO(reversalDate)
          }
        );
      }
    }

    return lang.cards.statement.table.reversed[currentLangKey];
  }, [
    transaction,
    currentLangKey,
    lang,
    originalTransactionProps,
    isLoadingOriginalTransaction,
    originalTransactiondDetailsQueryEnabled,
    originalTransaction
  ]);

  const formattedTransactionValue = useMemo(() => {
    if (transaction.isInternational) {
      return (
        <TransactionValue
          type='international'
          finalCurrencyCode={transaction.finalBillingCurrencyCode}
          currencyCode={transaction.currencyCode}
          finalValue={transaction.finalBillingAmount}
          value={transaction.amount}
        />
      );
    }

    return (
      <TransactionValue
        type='brazilian'
        value={transaction.amount}
      />
    );
  }, [transaction]);

  const formattedTransactionPendencyDescription = useMemo(() => {
    return (
      <PendencyDescription>
        {pendency && PendencyType.format(pendency.type, lang, currentLangKey)}
        <BaseIcon
          name='clock'
          type='line'
          color='clementine'
          size={1.4}
        />
      </PendencyDescription>
    );
  }, [pendency, lang, currentLangKey]);

  return {
    transactionTooltipContent,
    formattedTransactionValue,
    formattedTransactionPendencyDescription,
    expense,
    isLoadingExpense: expenseQueryEnabled && isLoadingExpense
  };
}
