import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useMutationCache, useQueryCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import {
  BalanceMutationKeys,
  BalanceQueryKeys,
  BalanceService,
  type IUseGetPixQrCodeString,
  type IUseGetPixQrCodeStringPayload
} from 'data/modules/cards/balance';

export function useGetPixQrCode({
  enabled
}: IUseGetPixQrCodeStringPayload): IUseGetPixQrCodeString {
  const queryClient = useQueryClient();
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { mutate, isLoading: isLoadingMutation } = useMutationCache({
    mutationKey: [BalanceMutationKeys.REQUEST_PIX_QR_CODE],
    mutationFn: async () => await BalanceService.requestNewPixQrCode(),
    onSuccess: data => {
      queryClient.setQueryData([BalanceQueryKeys.GET_PIX_QR_CODE_STRING], data);
    },
    onError: () => {
      toast.error(lang.cards.deposit.error_when_generate_qr[currentLangKey]);
    }
  });

  const {
    data,
    isLoading,
    isFetching: isFetchingPixQrCode,
    isError
  } = useQueryCache({
    queryKey: [BalanceQueryKeys.GET_PIX_QR_CODE_STRING],
    queryFn: async () => await BalanceService.getPixQrCodeString(),
    enabled
  });

  return {
    data,
    isLoading: isLoading || isLoadingMutation,
    isFetchingPixQrCode,
    isError,
    mutate
  };
}
