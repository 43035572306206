import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { Spinner } from 'presentation/components/base/Spinner';
import { DsDefaultButton, DsGhostButton } from 'presentation/ds/DsButton';
import { DsModal } from 'presentation/ds/DsModal';
import { OnboardingText } from 'presentation/pages/cards/Onboarding/components/General';

import { useDeletePartnerModal } from './useDeletePartnerModal';

import { type IDeletePartnerModalProps } from './DeletePartnerModal.types';

import {
  CustomLoadingOverlay,
  Divider,
  FooterContainer,
  IconContainer,
  ModalContentContainer
} from './DeletePartnerModal.styles';

export function DeletePartnerModal({
  isOpen,
  onClose
}: IDeletePartnerModalProps): JSX.Element {
  const [global, deletePartnerModal, currentLangKey] = useLangContext(state => [
    state.lang.global,
    state.lang.cards.onboarding.company.add_partner.partners_list
      .delete_partner_modal,
    state.currentLangKey
  ]);

  const { isDeletingPartner, handleDeletePartner, selectedShareholderName } =
    useDeletePartnerModal();

  return (
    <DsModal
      title=''
      isOpen={isOpen}
      onClose={onClose}
      hasCloseButton={false}
    >
      <ModalContentContainer>
        {isDeletingPartner && (
          <CustomLoadingOverlay>
            <Spinner
              color='royalBlue'
              size={4}
            />
          </CustomLoadingOverlay>
        )}

        <IconContainer>
          <BaseIcon
            name='error-warning'
            size={4}
          />
        </IconContainer>

        <OnboardingText>
          {deletePartnerModal.delete_message_part_A[currentLangKey]}
          <span>{selectedShareholderName}</span>
          {deletePartnerModal.delete_message_part_B[currentLangKey]}
        </OnboardingText>

        <Divider />

        <FooterContainer>
          <DsGhostButton
            text={global.back[currentLangKey]}
            onClick={onClose}
          />
          <DsDefaultButton
            text={global.delete[currentLangKey]}
            onClick={handleDeletePartner}
          />
        </FooterContainer>
      </ModalContentContainer>
    </DsModal>
  );
}
