import { AccordionCaption } from './AccordionCaption/AccordionCaption.component';
import { AccordionProvider } from './AccordionContainer/AccordionContainer.context';
import { AccordionItem } from './AccordionItem/AccordionItem.component';
import { AccordionLabel } from './AccordionLabel/AccordionLabel.component';

export const Accordion = {
  Container: AccordionProvider,
  Item: AccordionItem,
  Label: AccordionLabel,
  Caption: AccordionCaption
};
