import {
  type IPersistenceTeamMemberPositionOption,
  type ITeamMemberPositionOption
} from 'data/modules/teamMembers';

class GetTeamMembersPositionOptionsMapper {
  toDomain(
    persistence: IPersistenceTeamMemberPositionOption
  ): ITeamMemberPositionOption {
    return {
      positionId: persistence.position_id,
      departmentId: persistence.department_id,
      positionName: persistence.position_name,
      departmentName: persistence.department_name
    };
  }
}

export default new GetTeamMembersPositionOptionsMapper();
