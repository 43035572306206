import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext } from 'data/contexts';
import { useGetAccountBalancesAmounts } from 'data/modules/cards/account';
import { useGetCardGroupUsers } from 'data/modules/cards/cardGroups/useCases/get-card-group-users/useGetCardGroupUsers';

import { type IUseGroupManagementTable } from './GroupManagementTable.types';

export function useGroupManagementTable(): IUseGroupManagementTable {
  const { groupId } = useParams();

  const [page, limit, selectedCardGroup, name, order] = useCardGroupsContext(
    useShallow(state => [
      state.paginationGroupManagement.page,
      state.paginationGroupManagement.limit,
      state.selectedCardGroup,
      state.filtersGroupManagement.name,
      state.filtersGroupManagement.order
    ])
  );

  const { cardGroupUsers, isFetchingCardGroupUsers } = useGetCardGroupUsers({
    id: (selectedCardGroup?.id as string) || (groupId as string),
    limit,
    page,
    name,
    ordernationType: order
  });

  function getAccountsIds(): string[] {
    if (!cardGroupUsers) {
      return [];
    }

    return cardGroupUsers.data.map(item => {
      return item.balanceId;
    });
  }

  const { accountBalancesAmounts, isFetchingAccountBalancesAmounts } =
    useGetAccountBalancesAmounts({
      enabled: getAccountsIds().length > 0 && !isFetchingCardGroupUsers,
      accountBalancesIds: getAccountsIds()
    });

  const data = cardGroupUsers?.data.map((cardGroup, index) => ({
    ...cardGroup,
    amount: accountBalancesAmounts[index]
  }));

  return {
    cardGroupUsers: data,
    isFetchingCardGroupUsers:
      isFetchingCardGroupUsers || isFetchingAccountBalancesAmounts
  };
}
