import { useQueryClient } from '@tanstack/react-query';

import {
  type IQueryCache,
  type IUseHandleQueryCache,
  type IUseHandleQueryCacheParamsType,
  type UpdateCacheParamsType
} from './useHandleQueryCache.types';

/**
Hook personalizado do React para gerenciar e interagir com dados armazenados em cache de consultas.
Este hook fornece métodos para lidar eficientemente com operações de cache de consultas com react-query:
updateCache: Atualiza partes específicas dos dados armazenados em cache.
getEnsuredCache: Recupera e garante que o cache esteja atualizado.
@template K - Tipo de chaves usadas no cache.
@template T - Tipo de dados armazenados no cache.
@param {IUseHandleQueryCacheParamsType<K, T>} params - Parâmetros para o hook.
@returns {IUseHandleQueryCache<T>} Um objeto contendo as funções relacionadas ao cache e os dados em cache.
*/
export function useHandleQueryCache<K, T>(
  params: IUseHandleQueryCacheParamsType<K, T>
): IUseHandleQueryCache<T> {
  const queryClient = useQueryClient();

  // Determina se a query utilizada recebe ou não parâmetros
  function isQueryWithoutParams(
    params: IUseHandleQueryCacheParamsType<K, T>
  ): params is IQueryCache<T> {
    return 'query' in params.query;
  }

  // Determina se os novos dados são um objeto
  function isObject(newData: unknown): boolean {
    return Object.prototype.toString.call(newData) === '[object Object]';
  }

  // Determina as chaves para os dados em cache.
  const keys: (string | K | null)[] = isQueryWithoutParams(params)
    ? params.query.key
    : params.query(params.params).key;

  // Recupera os dados em cache.
  const cachedData = queryClient.getQueryData<T>(keys);

  /**
  Garante que os dados em cache estejam atualizados.
  @returns {Promise<T>} Os dados em cache atualizados.
  */
  async function getEnsuredCache(): Promise<T> {
    return await queryClient.ensureQueryData<T>({ queryKey: keys });
  }

  /**
  Atualiza partes específicas dos dados em cache.
  @param {UpdateCacheParamsType<T>} params - Parâmetros para atualização do cache.
  */
  function updateCache(params: UpdateCacheParamsType<T>): void {
    queryClient.setQueryData<T>(
      keys,
      'key' in params
        ? old =>
            ({
              ...old,
              [params.key]: isObject(params.newData)
                ? {
                    ...old?.[params.key],
                    ...params.newData
                  }
                : params.newData
            }) as T
        : () => params.newData
    );
  }

  return {
    updateCache,
    getEnsuredCache,
    data: cachedData
  };
}
