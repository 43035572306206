import { type IResponsiveCellProps } from './ResponsiveCell.types';

import { Container } from './ResponsiveCell.styles';

export function ResponsiveCell({
  label,
  width,
  children
}: IResponsiveCellProps): JSX.Element {
  return (
    <Container $width={width}>
      <span>{label}</span>
      <span>{children}</span>
    </Container>
  );
}
