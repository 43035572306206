import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import {
  OnboardingFooter,
  OnboardingSubtitle,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { Onboarding } from 'shared/utils/cards';
import { Lang } from 'shared/utils/global';

import { useCompanyOwners } from './useCompanyOwners';

import { Container, InfoList } from './CompanyOwners.styles';

export function CompanyOwners(): JSX.Element {
  const navigate = useNavigate();

  const [companyOwners, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.company.company_owners,
    state.currentLangKey
  ]);

  const { businessType, legalName, instructions, handleNavigateNextStep } =
    useCompanyOwners();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  return (
    <Container>
      <OnboardingTitle>
        {Lang.putValuesInString(companyOwners.title[currentLangKey], {
          company: legalName
        })}
      </OnboardingTitle>

      <OnboardingSubtitle>
        {businessType === '205-4'
          ? companyOwners.read_instructions.sa_closed[currentLangKey]
          : companyOwners.read_instructions.general[currentLangKey]}
      </OnboardingSubtitle>

      <InfoList>
        {instructions.map((instruction, index) => (
          <li key={`${instruction}-${index}`}>{instruction}</li>
        ))}
      </InfoList>

      <OnboardingFooter
        backButtonProps={{
          onClick: () => {
            navigate(`/cartoes/onboarding/empresa/${currentStep - 1}`);
          }
        }}
        continueButtonProps={{
          onClick: handleNavigateNextStep
        }}
      />
    </Container>
  );
}
