import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: ${tokens.neutralColorLowDark};
    font-size: ${tokens.fontSizeXxs};
    line-height: 2.4rem;
    max-width: 22rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    color: ${tokens.neutralColorLowMedium};
    font-size: ${tokens.fontSizeXxss};
    line-height: 2.2rem;
    font-weight: ${tokens.fontWeightMedium};
  }
`;
