import { DSIcons, MenuTabs, tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetXs};

  .date-picker {
    max-width: 25rem;

    @media screen and (max-width: 480px) {
      max-width: 40rem;
    }
  }
`;

export const TabsAndExport = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${tokens.spacingInsetXs};
  align-items: center;
`;

export const StyledMenuTabsContainer = styled(MenuTabs.Container)`
  width: fit-content;

  ul {
    padding: 0;
    padding-bottom: 0.4rem;
    gap: 1.6rem;

    &::before {
      bottom: 0.1rem;
    }
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  gap: ${tokens.spacingInsetMdd};

  @media screen and (max-width: 1500px) {
    flex-wrap: wrap;
  }
`;

export const InputWrapper = styled.div`
  width: fit-content;
  max-width: 40rem;
  height: fit-content;

  @media screen and (max-width: 690px) {
    width: 100%;
  }
`;

export const StyledFilePdfIcon = styled(DSIcons.FilePdfIcon)`
  width: 2.4rem;
  height: 2.4rem;
  color: ${tokens.brandColorPrimaryMedium};
`;

export const StyledFileExcelIcon = styled(DSIcons.FileExcelIcon)`
  width: 2.4rem;
  height: 2.4rem;
  color: ${tokens.brandColorPrimaryMedium};
`;
