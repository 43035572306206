import {
  type PersistenceUpdateCompanyFormCacheBodyType,
  type UpdateCompanyFormCachePayloadType
} from 'data/modules/cards/companyOnboarding';

import { CustomDate, CustomObject } from 'shared/utils/custom';
import { Mask } from 'shared/utils/format';

class UpdateCompanyFormCacheMapper {
  toPersistence(
    payload: UpdateCompanyFormCachePayloadType
  ): PersistenceUpdateCompanyFormCacheBodyType {
    if (payload.domain === 'onboarding_configuration_users') {
      return {
        domain: payload.domain,
        life_cycle: payload.lifeCycle,
        value: payload.value.map(user => ({
          ...CustomObject.pick(user, ['name', 'type', 'uuid']),
          company_uuid: user.companyUuid,
          must_have_account: user.mustHaveAccount
        }))
      };
    }

    return {
      domain: payload.domain,
      life_cycle: payload.lifeCycle,
      value: payload.value.map(shareholder => ({
        ...CustomObject.pick(shareholder, [
          'contract',
          'email',
          'type',
          'name'
        ]),
        ...(shareholder.document && {
          document: Mask.remove(shareholder.document)
        }),
        ...(shareholder.birthDate && {
          birth_date: CustomDate.formatToIso(shareholder.birthDate)
        }),
        ...(shareholder.foundingDate && {
          founding_date: CustomDate.formatToIso(shareholder.foundingDate)
        }),
        ...(shareholder.phoneNumber && {
          phone_number: `55${Mask.remove(shareholder.phoneNumber)}`
        }),
        business_type: shareholder.businessType,
        shareholder_type:
          shareholder.shareholderType === 'legalRepresentative'
            ? 'legal_representative'
            : shareholder.shareholderType,
        legal_name: shareholder.legalName,
        main_activity: shareholder.mainActivity,
        trading_name: shareholder.tradingName
      }))
    };
  }
}

export default new UpdateCompanyFormCacheMapper();
