import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { toast } from 'ds';
import { useTranslation } from 'react-i18next';

import { useAuthContext, useModalContext } from 'data/contexts';
import {
  BalanceQueryKeys,
  type IQuantityOfBalanceSolicitationByStatus,
  type IRequestBalanceFieldsForm,
  useRequestBalance
} from 'data/modules/cards/balance';
import { useRequestBalanceSchema } from 'data/modules/cards/balance/useCases/request-balance/schemas';

import {
  useGetActiveBalanceIds,
  useGetUserLoggedAndCardData
} from 'shared/hooks/cards';
import { useFormWithSchema } from 'shared/hooks/forms';
import { Currency } from 'shared/utils/format';

import { type IUseRequestValueModal } from './RequestValueModal.types';

export function useRequestValueModal(): IUseRequestValueModal {
  const { userAccounts, outsourcedUserAccounts } = useGetUserLoggedAndCardData(
    {}
  );
  const { activeAccountBalancesIds } = useGetActiveBalanceIds();
  const user = useAuthContext(state => state.user);
  const isSharedAccount = !!user?.cards?.sharedAccounts;
  const { handleCloseModal } = useModalContext();
  const queryClient = useQueryClient();
  const { t } = useTranslation(['errors', 'cards']);

  const accountId = isSharedAccount
    ? (outsourcedUserAccounts?.[0].balances.find(balance => balance.inUse)
        ?.id as string)
    : (userAccounts?.[0].balances.find(balance => balance.inUse)?.id as string);

  const { requestBalance, isLoadingRequestingBalance } = useRequestBalance({
    accountBalanceId: accountId || ''
  });

  const methods = useFormWithSchema(useRequestBalanceSchema(), {
    defaultValues: {
      currency: 'BRL',
      amount: ''
    }
  });

  async function handleSubmit(
    payload: IRequestBalanceFieldsForm
  ): Promise<void> {
    try {
      await requestBalance({
        justify: payload.justify,
        amount: Currency.parseToFloat(payload.amount.toString()),
        accountBalanceId: accountId || '',
        datePrevision: payload.datePrevision
          ? format(payload.datePrevision, 'yyyy-MM-dd')
          : null
      });

      queryClient.resetQueries({
        queryKey: [BalanceQueryKeys.GET_BALANCE_SOLICITATIONS]
      });

      queryClient.resetQueries({
        queryKey: [BalanceQueryKeys.GET_MY_VALUE_SOLICITATIONS]
      });

      queryClient.setQueryData<IQuantityOfBalanceSolicitationByStatus>(
        [
          BalanceQueryKeys.GET_VALUE_SOLICITATIONS_QUANTITY,
          {
            payload: {
              balanceId: activeAccountBalancesIds[0],
              enabled: true
            }
          }
        ],
        oldData => ({
          ...oldData,
          sent: oldData?.sent as number,
          approved: oldData?.approved ?? 0,
          rejected: oldData?.rejected ?? 0,
          all: (oldData?.all as number) + 1
        })
      );

      queryClient.resetQueries({
        queryKey: [
          BalanceQueryKeys.GET_QUANTITY_OF_BALANCE_SOLICITATION_BY_STATUS
        ]
      });

      toast.success(t('cards:amountRequestedSuccessfully'));
      handleCloseModal();
    } catch (error) {
      if (error) {
        toast.error(t('errors:unableToMakeRequest'));
      }
    }
  }

  function handleAddAmount(quantity: number): void {
    const { amount } = methods.getValues();

    const updatedAmount = amount ? amount + quantity : quantity;

    methods.setValue('amount', updatedAmount as string);
  }

  return {
    methods,
    isRequestingBalance: isLoadingRequestingBalance,
    handleSubmit: methods.handleSubmit(handleSubmit),
    handleAddAmount
  };
}
