import { CardInfo, DefaultButton } from 'ds';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { useCardGroupsContext } from 'data/contexts';

import carryLetter from 'shared/assets/images/cards/carry-letter.svg';

import { type IChargeCardsCardInfoProps } from './ChargeCardsCardInfo.types';

import {
  CardInfoCaption,
  CardInfoContainer,
  ContentWrapper,
  CustomImage
} from './ChargeCardsCardInfo.styles';

export function ChargeCardsCardInfo({
  isCardGroup,
  ...rest
}: IChargeCardsCardInfoProps): JSX.Element {
  const { t } = useTranslation('cards');
  const { groupId } = useParams();
  const selectedCardGroup = useCardGroupsContext(
    state => state.selectedCardGroup
  );

  return (
    <CardInfoContainer {...rest}>
      <CustomImage
        src={carryLetter}
        height={65}
      />

      <ContentWrapper>
        <CardInfo.Title variant='small'>{t('loadCardsInMass')}</CardInfo.Title>

        <CardInfoCaption variant='small'>
          {t('addOrChangeText')}
        </CardInfoCaption>

        <Link
          to={
            groupId
              ? `/cartoes/gestao-de-grupos/${selectedCardGroup?.id || groupId}/alocacao-de-valores`
              : '/cartoes/alocacao-de-valores'
          }
        >
          <DefaultButton
            size='small'
            className='cards-tour-charge-cards-link'
          >
            {t('loadCards')}
          </DefaultButton>
        </Link>
      </ContentWrapper>
    </CardInfoContainer>
  );
}
