import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { DsDefaultButton, DsGhostButton } from 'presentation/ds/DsButton';
import { DsModal } from 'presentation/ds/DsModal';
import { OnboardingText } from 'presentation/pages/cards/Onboarding/components/General';

import { type IContinueWithoutAskingModalProps } from './ContinueWithoutAskingModal.types';

import {
  Divider,
  FooterContainer,
  IconContainer,
  ModalContentContainer
} from './ContinueWithoutAskingModal.styles';

export function ContinueWithoutAskingModal({
  isOpen,
  onClose,
  onConfirm
}: IContinueWithoutAskingModalProps): JSX.Element {
  const [global, company, currentLangKey] = useLangContext(state => [
    state.lang.global,
    state.lang.cards.onboarding.company,
    state.currentLangKey
  ]);

  return (
    <DsModal
      title=''
      isOpen={isOpen}
      onClose={onClose}
      hasCloseButton={false}
    >
      <ModalContentContainer>
        <IconContainer>
          <BaseIcon
            name='information'
            size={4}
          />
        </IconContainer>

        <OnboardingText>
          {company.request_cards.dont_apply_cards_message[currentLangKey]}
        </OnboardingText>

        <Divider />

        <FooterContainer>
          <DsGhostButton
            text={global.back[currentLangKey]}
            onClick={onClose}
          />
          <DsDefaultButton
            type='submit'
            text={global.confirm[currentLangKey]}
            onClick={onConfirm}
          />
        </FooterContainer>
      </ModalContentContainer>
    </DsModal>
  );
}
