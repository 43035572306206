import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { Spinner } from 'presentation/components/base/Spinner';
import { DsDefaultButton, DsGhostButton } from 'presentation/ds/DsButton';
import { DsModal } from 'presentation/ds/DsModal';
import { OnboardingText } from 'presentation/pages/cards/Onboarding/components/General';

import { JsxHelper, Lang } from 'shared/utils/global';

import { type IRequestCardsConfirmModalProps } from './RequestCardsConfirmModal.types';

import {
  CustomLoadingOverlay,
  Divider,
  FooterContainer,
  IconContainer,
  ModalContentContainer
} from './RequestCardsConfirmModal.styles';

export function RequestCardsConfirmModal({
  isOpen,
  onClose,
  onConfirm,
  amountOfCards,
  isLoading
}: IRequestCardsConfirmModalProps): JSX.Element {
  const [global, company, currentLangKey] = useLangContext(state => [
    state.lang.global,
    state.lang.cards.onboarding.company,
    state.currentLangKey
  ]);

  return (
    <DsModal
      title=''
      isOpen={isOpen}
      onClose={onClose}
      hasCloseButton={false}
    >
      <ModalContentContainer>
        {isLoading && (
          <CustomLoadingOverlay>
            <Spinner
              color='royalBlue'
              size={4}
            />
          </CustomLoadingOverlay>
        )}

        <IconContainer>
          <BaseIcon
            name='checkbox-circle'
            color='green'
            size={4}
          />
        </IconContainer>

        <OnboardingText>
          {JsxHelper.putHtmlTag(
            Lang.putValuesInString(
              company.request_cards.confirm_modal_message[currentLangKey],
              {
                amountOfCards,
                cardsText:
                  parseInt(amountOfCards) === 1
                    ? global.card[currentLangKey].toLowerCase()
                    : global.cards[currentLangKey].toLowerCase()
              }
            )
          )}
        </OnboardingText>

        <Divider />

        <FooterContainer>
          <DsGhostButton
            text={global.back[currentLangKey]}
            onClick={onClose}
          />
          <DsDefaultButton
            type='submit'
            text={global.confirm[currentLangKey]}
            onClick={onConfirm}
          />
        </FooterContainer>
      </ModalContentContainer>
    </DsModal>
  );
}
