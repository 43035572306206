import {
  type ICardsCompany,
  type IPersistenceCardsCompany
} from 'data/modules/auth';

class GetAuthCardsCompanyMapper {
  toDomain(persistenceCardsCompany: IPersistenceCardsCompany): ICardsCompany {
    return {
      id: persistenceCardsCompany.id,
      onboardingCompletedAt: persistenceCardsCompany.onboarding_completed_at,
      defaultBalance: persistenceCardsCompany.default_balance,
      accountHolder: {
        kycStatus: persistenceCardsCompany.account_holder?.kyc_status,
        kycUpdatedAt: persistenceCardsCompany.account_holder?.kyc_updated_at
      },
      parameters: {
        useSharedBusinessAccount:
          persistenceCardsCompany.parameters.use_shared_business_account,
        useCentralizedSharedBusinessAccount:
          persistenceCardsCompany.parameters
            .use_distributed_shared_business_account,
        useDistributedSharedBusinessAccount:
          persistenceCardsCompany.parameters
            .use_distributed_shared_business_account
      },
      group: persistenceCardsCompany.group,
      isOnboardingFinished:
        persistenceCardsCompany.onboarding_completed_at !== null &&
        persistenceCardsCompany.account_holder?.kyc_status === 'APPROVED'
    };
  }
}

export default new GetAuthCardsCompanyMapper();
