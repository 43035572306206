import { faker } from '@faker-js/faker';

import { type IExportMovementsInExcel } from 'data/modules/cards/movements';

import { MockApi } from 'shared/utils/global';

export async function exportsMovementsInExcelMock(): Promise<IExportMovementsInExcel> {
  await MockApi.sleep(2000);

  return {
    url: faker.internet.url()
  };
}
