import { useQueryClient } from '@tanstack/react-query';

import { useMutationCache } from 'data/cache';
import {
  BalanceMutationKeys,
  BalanceQueryKeys,
  BalanceService,
  type IUseDeleteModifiedBalanceAllocationItemsFromCache,
  type IUseDeleteModifiedBalanceAllocationItemsFromCacheParams
} from 'data/modules/cards/balance';

export function useDeleteModifiedBalanceAllocationItemsFromCache(
  params?: IUseDeleteModifiedBalanceAllocationItemsFromCacheParams
): IUseDeleteModifiedBalanceAllocationItemsFromCache {
  const queryClient = useQueryClient();

  const {
    mutate: deleteModifiedBalanceAllocationItemsFromCache,
    isLoading: isDeletingModifiedBalanceAllocationItemsFromCache
  } = useMutationCache({
    mutationKey: [
      BalanceMutationKeys.DELETE_MODIFIED_BALANCE_ALLOCATION_ITEMS_FROM_CACHE
    ],
    mutationFn: async () =>
      await BalanceService.deleteModifiedBalanceAllocationItemsFromCache(),
    onSuccess: () => {
      queryClient.setQueryData(
        [BalanceQueryKeys.GET_CACHED_MODIFIED_BALANCE_ALLOCATION_ITEMS],
        null
      );

      params?.onAfterSuccess?.();
    }
  });

  return {
    deleteModifiedBalanceAllocationItemsFromCache,
    isDeletingModifiedBalanceAllocationItemsFromCache
  };
}
