import { useQueryCache } from 'data/cache';
import {
  type IUseGetUserOnboardingProgress,
  type IUseGetUserOnboardingProgressParams,
  UserOnboardingQueryKeys,
  UserOnboardingService
} from 'data/modules/cards/userOnboarding';

export function useGetUserOnboardingProgress({
  enabled
}: IUseGetUserOnboardingProgressParams): IUseGetUserOnboardingProgress {
  const { data, isLoading, isSuccess } = useQueryCache({
    queryKey: [UserOnboardingQueryKeys.GET_USER_ONBOARDING_PROGRESS],
    queryFn: async () =>
      await UserOnboardingService.getUserOnboardingProgress(),
    enabled
  });

  return {
    isUserOnboardingProgressLoading: enabled && isLoading,
    userOnboardingProgress: data,
    isUserOnboardingProgressSuccess: isSuccess
  };
}
