import { useQueryCache } from 'data/cache';
import {
  type IUseGetPaginatedProjects,
  type IUseGetPaginatedProjectsParams,
  ProjectsQueryKeys,
  ProjectsService
} from 'data/modules/projects';

export function useGetPaginatedProjects(
  params: IUseGetPaginatedProjectsParams
): IUseGetPaginatedProjects {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [ProjectsQueryKeys.GET_PAGINATED_PROJECTS, params],
    queryFn: async () => await ProjectsService.getPaginatedProjects(params)
  });

  return {
    paginatedProjects: data,
    isLoadingPaginatedProjects: isLoading,
    isFetchingPaginatedProjects: isFetching
  };
}
