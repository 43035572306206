import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IUpdateCompanyAddressFieldsForm } from 'data/modules/cards/companyOnboarding';

export function useUpdateCompanyAddressSchema(): ObjectSchema<IUpdateCompanyAddressFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const updateCompanyAddressSchema: ObjectSchema<IUpdateCompanyAddressFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object({
        postalCode: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'postalCode',
            message: lang.schemas.invalid_postal_code[currentLangKey],
            test: value => /^[0-9]{5}-[0-9]{3}$/.test(value)
          }),
        street: yup.string().required(fieldRequiredMessage),
        number: yup.string().required(fieldRequiredMessage),
        complement: yup
          .string()
          .transform(value => (value.trim().length === 0 ? undefined : value)),
        neighborhood: yup.string().required(fieldRequiredMessage),
        country: yup
          .string()
          .required(fieldRequiredMessage)
          .transform(({ value }) => value),
        city: yup.string().required(fieldRequiredMessage),
        state: yup
          .string()
          .required(fieldRequiredMessage)
          .transform(({ value }) => value)
      });
    }, [lang, currentLangKey]);

  return updateCompanyAddressSchema;
}
