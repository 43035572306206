import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { DsTextInput } from 'presentation/ds/DsTextInput';
import {
  OnboardingFooter,
  OnboardingText,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { useFeedback } from './useFeedback';

import {
  Container,
  Content,
  EmotionContainer,
  IconContainer
} from './Feedback.styles';

export function Feedback(): JSX.Element {
  const [global, feedback, currentLangKey] = useLangContext(state => [
    state.lang.global,
    state.lang.cards.onboarding.company.feedback,
    state.currentLangKey
  ]);

  const {
    errors,
    control,
    setValue,
    skippingFeedback,
    handleSendOnboardingFeedback,
    emotionOptionsToRender
  } = useFeedback();

  return (
    <Container onSubmit={handleSendOnboardingFeedback}>
      <Content>
        <IconContainer>
          <BaseIcon
            name='chat-smile-3'
            size={5}
          />
        </IconContainer>
        <OnboardingTitle>{feedback.title[currentLangKey]}</OnboardingTitle>
        <EmotionContainer>{emotionOptionsToRender()}</EmotionContainer>
        <OnboardingText>{feedback.informative[currentLangKey]}</OnboardingText>
        <DsTextInput
          control={control}
          name='feedbackComment'
          error={errors.feedbackComment?.message}
          label={feedback.comment[currentLangKey]}
          onClear={() => {
            setValue('feedbackComment', '');
          }}
        />
      </Content>
      <OnboardingFooter
        continueButtonProps={{
          text: skippingFeedback
            ? global.skip[currentLangKey]
            : global.continue[currentLangKey],
          onClick: () => {}
        }}
      />
    </Container>
  );
}
