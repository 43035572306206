import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { Tooltip } from 'presentation/components/base/Tooltip';
import { Pagination } from 'presentation/components/global/Pagination';
import { GenericTable } from 'presentation/components/global/Table';
import { DsCheckbox } from 'presentation/ds/DsCheckbox';

import { CustomObject } from 'shared/utils/custom';

import { type IManagersAndCardholdersTableProps } from './ManagersAndCardholdersTable.types';

import {
  Container,
  CustomRadioInput,
  CustomTd,
  CustomTh,
  CustomTr,
  TooltipLabelContainer
} from './ManagersAndCardholdersTable.styles';

export function ManagersAndCardholdersTable({
  managersAndCardholders,
  pagination,
  onChangeMemberSettings
}: IManagersAndCardholdersTableProps): JSX.Element {
  const [header, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.company.managers_and_cardholders
      .managers_and_cardholders_table.header,
    state.currentLangKey
  ]);

  return (
    <Container>
      <GenericTable
        thead={
          <CustomTr className='tableRow'>
            <CustomTh>{header.name[currentLangKey]}</CustomTh>

            <CustomTh>{header.email[currentLangKey]}</CustomTh>

            <CustomTh>
              <TooltipLabelContainer>
                {header.accountManager[currentLangKey]}
                <Tooltip
                  id='accountManager'
                  content={header.accountManagerTooltip[currentLangKey]}
                >
                  <BaseIcon
                    name='question'
                    type='fill'
                    size={2}
                  />
                </Tooltip>
              </TooltipLabelContainer>
            </CustomTh>

            <CustomTh>
              <TooltipLabelContainer>
                {header.finantialManager[currentLangKey]}
                <Tooltip
                  id='finantialManager'
                  content={header.finantialManagerTooltip[currentLangKey]}
                >
                  <BaseIcon
                    name='question'
                    type='fill'
                    size={2}
                  />
                </Tooltip>
              </TooltipLabelContainer>
            </CustomTh>

            <CustomTh>{header.cardHolder[currentLangKey]}</CustomTh>
          </CustomTr>
        }
        tbody={
          <>
            {managersAndCardholders.map(accountData => (
              <CustomTr
                className='tableRow'
                key={accountData.uuid}
              >
                <CustomTd>{accountData.name}</CustomTd>

                <CustomTd>{accountData.email}</CustomTd>

                <CustomTd>
                  <CustomRadioInput
                    type='radio'
                    name={accountData.name}
                    defaultChecked={accountData.type === 'ACCOUNT_MANAGER'}
                    onChange={() => {
                      onChangeMemberSettings({
                        ...CustomObject.pick(accountData, [
                          'companyUuid',
                          'name',
                          'uuid'
                        ]),
                        mustHaveAccount: accountData.mustHaveAccount ?? false,
                        type: 'ACCOUNT_MANAGER'
                      });
                    }}
                  />
                </CustomTd>

                <CustomTd>
                  <CustomRadioInput
                    type='radio'
                    name={accountData.name}
                    defaultChecked={accountData.type === 'FINANCIAL_MANAGER'}
                    onChange={() => {
                      onChangeMemberSettings({
                        ...CustomObject.pick(accountData, [
                          'companyUuid',
                          'name',
                          'uuid'
                        ]),
                        mustHaveAccount: accountData.mustHaveAccount ?? false,
                        type: 'FINANCIAL_MANAGER'
                      });
                    }}
                  />
                </CustomTd>

                <CustomTd>
                  {
                    <DsCheckbox
                      name={accountData.name}
                      defaultChecked={accountData.mustHaveAccount}
                      onChange={checked => {
                        onChangeMemberSettings({
                          ...CustomObject.pick(accountData, [
                            'companyUuid',
                            'name',
                            'uuid',
                            'type'
                          ]),
                          mustHaveAccount: checked
                        });
                      }}
                    />
                  }
                </CustomTd>
              </CustomTr>
            ))}
          </>
        }
      />

      <Pagination {...pagination} />
    </Container>
  );
}
