import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 5.6rem;
  flex-shrink: 0;
  align-items: center;
  gap: ${tokens.spacingLg};
  justify-content: space-between;
  background: ${tokens.neutralColorLowDark};
  padding: ${tokens.spacingNano} ${tokens.spacingMd};
  border-radius: ${tokens.borderRadiusMd} ${tokens.borderRadiusMd}
    ${tokens.borderRadiusNone} ${tokens.borderRadiusNone};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: ${tokens.spacingXs} ${tokens.spacingMd};
    gap: ${tokens.spacingXs};
    align-items: flex-start;
    flex-direction: column;
    height: auto;
  }
`;

export const Name = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  color: ${tokens.neutralColorHighPure};
  font-weight: ${tokens.fontWeightBold};
  font-size: ${tokens.fontSizeXs};
  line-height: 2.2rem;
  font-style: normal;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
`;

export const PositionDepartment = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightMedium};
  color: ${tokens.neutralColorHighPure};
  font-size: ${tokens.fontSizeXxs};
  letter-spacing: 0.075rem;
  line-height: 2.4rem;
  white-space: nowrap;
  font-style: normal;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 100%;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
  }
`;
