import {
  type IAccountTransaction,
  type IPersistenceAccountTransaction
} from 'data/modules/cards/statement';

import { CustomObject } from 'shared/utils/custom';

class AccountTransactionMapper {
  constructor() {
    this.toDomain = this.toDomain.bind(this);
  }

  toDomain(
    persistenceAccountTransaction: IPersistenceAccountTransaction
  ): IAccountTransaction {
    return {
      userId: persistenceAccountTransaction.user_id,
      originalTransactionId:
        persistenceAccountTransaction.original_transaction_id,
      cardNumber: persistenceAccountTransaction.card_number,
      currencyCode: persistenceAccountTransaction.currency_code,
      debitCredit: persistenceAccountTransaction.debit_credit,
      finalBillingAmount: persistenceAccountTransaction.final_billing_amount,
      finalBillingCurrencyCode:
        persistenceAccountTransaction.final_billing_currency_code,
      finalBillingCurrency:
        persistenceAccountTransaction.final_billing_currency,
      billingAmountAuthorized:
        persistenceAccountTransaction.billing_amount_authorized,
      isInternational: persistenceAccountTransaction.is_international,
      merchantName: persistenceAccountTransaction.merchant_name,
      isReversal: persistenceAccountTransaction.is_reversal,
      paymentDescription: persistenceAccountTransaction.payment_description,
      reversalAmount: persistenceAccountTransaction.reversal_amount,
      transactionId: persistenceAccountTransaction.transaction_id,
      transferorName: persistenceAccountTransaction.transferor_name,
      mcc: Number(persistenceAccountTransaction.mcc),
      merchantCity: persistenceAccountTransaction.merchant_city,
      relateds:
        persistenceAccountTransaction.relateds &&
        persistenceAccountTransaction.relateds.length > 0
          ? persistenceAccountTransaction.relateds.map(this.toDomain)
          : [],
      ...CustomObject.pick(persistenceAccountTransaction, [
        'amount',
        'currency',
        'datetime',
        'reversed',
        'type',
        'visible',
        'name'
      ])
    };
  }
}

export default new AccountTransactionMapper();
