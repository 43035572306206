enum CostCentersMutationKeys {
  ATTACH_COST_CENTERS_TO_BUSINESS_CARD = 'ATTACH_COST_CENTERS_TO_BUSINESS_CARD'
}

enum CostCentersQueryKeys {
  GET_COST_CENTERS = 'GET_COST_CENTERS',
  GET_RELATED_COST_CENTERS = 'GET_RELATED_COST_CENTERS',
  GET_PAGINATED_COST_CENTERS = 'GET_PAGINATED_COST_CENTERS'
}

export { CostCentersMutationKeys, CostCentersQueryKeys };
