import { useAuthContext, useLangContext } from 'data/contexts';
import { useUpdateAnswerableUser } from 'data/modules/cards/companyOnboarding';

import { DsTextInput } from 'presentation/ds/DsTextInput';
import {
  OnboardingFooter,
  OnboardingLoader,
  OnboardingSubtitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { Container, StyledForm } from './FillerUserData.styles';

export function FillerUserData(): JSX.Element {
  const user = useAuthContext(state => state.user);
  const [onboarding, forms, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding,
    state.lang.forms,
    state.currentLangKey
  ]);

  const {
    control,
    isUpdatingAnswerableUser,
    errors,
    setValue,
    handleUpdateAnswerableUser
  } = useUpdateAnswerableUser();

  if (isUpdatingAnswerableUser) {
    return <OnboardingLoader />;
  }

  return (
    <Container>
      <OnboardingSubtitle>
        {onboarding.company.filler_user.subtitle[currentLangKey]}
      </OnboardingSubtitle>

      <StyledForm onSubmit={handleUpdateAnswerableUser}>
        <DsTextInput
          name='email'
          label='Email'
          disabled
          defaultValue={user?.email as string}
        />

        <DsTextInput
          name='fullName'
          label={forms.full_name[currentLangKey]}
          control={control}
          mask='letters'
          error={errors.fullName?.message}
          onClear={() => {
            setValue('fullName', '');
          }}
        />

        <DsTextInput
          name='documentNumber'
          label='CPF'
          mask='cpf'
          control={control}
          error={errors.documentNumber?.message}
          onClear={() => {
            setValue('documentNumber', '');
          }}
        />

        <DsTextInput
          name='phoneNumber'
          label={forms.phone[currentLangKey]}
          control={control}
          error={errors.phoneNumber?.message}
          mask='phone'
          onClear={() => {
            setValue('phoneNumber', '');
          }}
        />

        <OnboardingFooter
          continueButtonProps={{
            onClick: () => {}
          }}
        />
      </StyledForm>
    </Container>
  );
}
