import { Pagination } from 'ds/components/Table/Pagination';
import { useShallow } from 'zustand/react/shallow';

import { useValueSolicitationContext } from 'data/contexts';
import { type PaginationType } from 'data/modules/global';

import { type IValueSolicitationTablePaginationProps } from './ValueSolicitationTablePagination.types';

import {
  Container,
  StyledControl,
  StyledPagination
} from './ValueSolicitationTablePagination.styles';

export function ValueSolicitationTablePagination({
  disable,
  ...rest
}: IValueSolicitationTablePaginationProps): JSX.Element {
  const [currentPage, pageSize, totalPages, handleChangePagination] =
    useValueSolicitationContext(
      useShallow(state => [
        state.currentPage,
        state.pageSize,
        state.totalPages,
        state.handleChangePagination
      ])
    );

  return (
    <Container {...rest}>
      {!disable && (
        <StyledPagination
          currentPage={currentPage}
          perPage={pageSize}
          totalPage={totalPages ?? 0}
        >
          <Pagination.Label langKey='pt' />

          <StyledControl
            onChangePage={page => {
              handleChangePagination({ currentPage: page });
            }}
          />

          <Pagination.Pages
            langKey='pt'
            onChangePerPage={pageSize => {
              handleChangePagination({ pageSize: pageSize as PaginationType });
            }}
            options={[10, 20, 50]}
          />
        </StyledPagination>
      )}
    </Container>
  );
}
