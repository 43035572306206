import {
  type AccountsType,
  type IGetAccountsPayload,
  type IPersistenceGetAccountsParams,
  type PersistenceAccountsType
} from 'data/modules/cards/account/types/accounts.types';

class AccountsMapper {
  toDomain(data: PersistenceAccountsType): AccountsType {
    if (data.business) {
      return {
        individual: null,
        business: {
          id: data.business.id,
          name: data.business.name,
          status: data.business.status,
          cardNumber: data.business.card_number,
          cardStatus: data.business.card_status,
          cardLockLevel: data.business.card_lock_level,
          type: data.business.type,
          description: data.business.description,
          accessAccounts: data.business.access_accounts,
          balances: data.business.balances
        }
      };
    }

    return {
      business: null,
      individual: {
        userId: data.individual.user_id,
        userName: data.individual.user_name,
        account: {
          id: data.individual.account.id,
          status: data.individual.account.status,
          cardNumber: data.individual.account.card_number,
          cardStatus: data.individual.account.card_status,
          cardLockLevel: data.individual.account.card_lock_level,
          balances: data.individual.account.balances
        }
      }
    };
  }

  toPersistence(payload: IGetAccountsPayload): IPersistenceGetAccountsParams {
    return {
      ordenation_type: payload.ordenationType,
      limit: payload.limit,
      page: payload.page,
      name: payload.name,
      status: payload.status,
      card_group_id: payload.cardGroupId,
      ...(payload.accountType && { account_type: payload.accountType })
    };
  }
}

export default new AccountsMapper();
