import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useModalContext, useValueSolicitationContext } from 'data/contexts';
import {
  BalanceQueryKeys,
  type IAllocatedBalance,
  type IUnallocatedBalance
} from 'data/modules/cards/balance';
import { useRemoveAllCacheBalanceSolicitations } from 'data/modules/cards/balance/useCases/remove-all-cache-balance-solicitations/useRemoveAllCacheBalanceSolicitations';

import { type IUseTransferFeedbackModal } from './TransferFeedbackModal.types';

export function useTransferFeedbackModal(): IUseTransferFeedbackModal {
  const { handleCloseModal } = useModalContext();
  const { groupId } = useParams();

  const [
    handleChangeStep,
    handleClearAllSelectSolicitation,
    currentPage,
    pageSize,
    startDate,
    endDate,
    dateType,
    searchInput,
    listType,
    requestedAmount
  ] = useValueSolicitationContext(
    useShallow(state => [
      state.handleChangeStep,
      state.handleClearAllSelectSolicitation,
      state.currentPage,
      state.pageSize,
      state.startDate,
      state.endDate,
      state.dateType,
      state.searchInput,
      state.listType,
      state.requestedAmount
    ])
  );

  const queryClient = useQueryClient();

  const { removeAllBalanceSolicitationsCache } =
    useRemoveAllCacheBalanceSolicitations(false);

  function handleConfirmFeedbackModal(): void {
    handleClearAllSelectSolicitation();

    removeAllBalanceSolicitationsCache(null);

    queryClient.invalidateQueries({
      queryKey: [
        BalanceQueryKeys.GET_BALANCE_SOLICITATIONS,
        {
          page: currentPage,
          limit: pageSize,
          dateType: startDate && endDate && dateType ? dateType : null,
          startDate: startDate && endDate ? startDate.toString() : null,
          endDate: endDate && startDate ? endDate.toString() : null,
          name:
            searchInput.toLocaleLowerCase().trim() !== ''
              ? searchInput.toLocaleLowerCase().trim()
              : undefined,
          status: listType
        }
      ]
    });

    queryClient.invalidateQueries({
      queryKey: [
        BalanceQueryKeys.GET_QUANTITY_OF_BALANCE_SOLICITATION_BY_STATUS
      ]
    });

    queryClient.setQueryData<IUnallocatedBalance>(
      [
        BalanceQueryKeys.GET_UNALLOCATED_BALANCE,
        { ...(groupId ? { cardGroupId: groupId } : { cardGroupId: null }) }
      ],
      oldData =>
        oldData && {
          amount: oldData.amount - requestedAmount
        }
    );

    queryClient.setQueryData<IAllocatedBalance>(
      [
        BalanceQueryKeys.GET_ALLOCATED_BALANCE,
        { ...(groupId ? { cardGroupId: groupId } : { cardGroupId: null }) }
      ],
      oldData =>
        oldData && {
          amount: oldData.amount + requestedAmount,
          lastUpdateDate: format(new Date(), 'dd/MM/yyyy'),
          lastUpdateTime: format(new Date(), "HH'h':mm'm':ss's'")
        }
    );

    handleChangeStep('solicitation');

    handleCloseModal();
  }

  return {
    handleConfirmFeedbackModal
  };
}
