import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 30rem;
  padding: 0.8rem 1rem;
  margin: 0 auto;

  > button {
    margin-bottom: 0.5rem;
  }
`;
