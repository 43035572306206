import { transparentize } from 'polished';
import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import CardBackground from 'shared/assets/images/cards/card-background.svg';

import { type CreditCardStylesType } from './CreditCard.types';

export const Container = styled.div<AddPrefixToType<CreditCardStylesType>>`
  position: relative;
  width: 19rem;
  height: 12rem;
  padding: 1.5rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.secondary};
  background-image: url(${CardBackground});
  background-size: cover;
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
`;

export const BlockedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => transparentize(0.3, theme.colors.dustyGray)};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCardNumber = styled.strong`
  overflow: hidden;
  margin-top: 3rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
`;
