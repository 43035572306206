import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  BalanceMutationKeys,
  BalanceService,
  type IBalanceSolicitationApprovedPayload,
  type IIUseApproveBalanceSolicitationsParams,
  type IUseApproveBalanceSolicitations
} from 'data/modules/cards/balance';

export function useApproveBalanceSolicitations({
  onAfterSubmitForm,
  onBalanceSolicitationApprovalsSummaryData
}: IIUseApproveBalanceSolicitationsParams): IUseApproveBalanceSolicitations {
  const {
    mutate: sendBalanceSolicitationApproved,
    isLoading: isSendingBalanceSolicationApproved,
    isSuccess,
    isError
  } = useMutationCache({
    mutationKey: [BalanceMutationKeys.SEND_BALANCE_SOLICITATION_APPROVED],
    mutationFn: async (payload: IBalanceSolicitationApprovedPayload[]) =>
      await BalanceService.sendBalanceSolicitationApproved(payload),
    onSuccess: data => {
      onBalanceSolicitationApprovalsSummaryData(data);
    },

    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function handleApproveBalanceSolicitations(
    data: IBalanceSolicitationApprovedPayload[]
  ): void {
    sendBalanceSolicitationApproved(data);
    onAfterSubmitForm?.();
  }

  return {
    handleApproveBalanceSolicitations,
    isSendingBalanceSolicationApproved,
    isApprovedBalanceSolicitationSuccess: isSuccess,
    isApprovedBalanceSolicitationError: isError
  };
}
