import styled, { css } from 'styled-components';

import {
  type AccordionStylesType,
  type TitleWrapperStylesType
} from './DsAccordion.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AccordionHeader = styled.button<
  AddPrefixToType<AccordionStylesType>
>`
  display: flex;
  width: 100%;
  padding: 2rem 2.4rem;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.ghostWhite};
  font-size: 2rem;
  font-weight: 600;
  border: 0;
  border-radius: 13px 13px ${({ $isOpen }) => ($isOpen ? '0 0' : '13px 13px')};
  place-content: space-between;
`;

export const TitleWrapper = styled.div<AddPrefixToType<TitleWrapperStylesType>>`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;

  ${({ $isBackgroundIcon }) =>
    $isBackgroundIcon &&
    css`
      svg {
        background-color: ${({ theme }) => theme.tokens.brandColorPrimaryLight};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
      }
    `}
`;

export const AccordionContent = styled.div<
  AddPrefixToType<AccordionStylesType>
>`
  background-color: ${({ theme }) => theme.colors.ghostWhite};
  overflow: hidden;
  word-break: break-all;
  font-size: 1.8rem;
  font-weight: 400;
  padding: 0;
  margin: 0;
  width: 100%;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  transition: all 0.2s ease-in-out;
  max-height: 0;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      max-height: 80rem;
    `};
`;
