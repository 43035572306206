import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  background-color: ${tokens.neutralColorHighPure};

  min-height: calc(100vh - 8rem);
  padding: ${tokens.spacingXs} ${tokens.spacingLg};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${tokens.spacingNano};

  @media screen and (max-width: 400px) {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
`;
