import { type IDefaultTableRowProps } from './DefaultTableRow.types';

import { Container } from './DefaultTableRow.styles';

export function DefaultTableRow({
  children,
  ...rest
}: IDefaultTableRowProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
