import { useState } from 'react';

import { useLangContext, useModalContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { type ISelectOption } from 'presentation/components/base/Select/Select.types';
import { ConfirmationModal } from 'presentation/components/global/ConfirmationModal';

import { type IListItemsProps } from './ListItems.types';

import { Container, Item } from './ListItems.styles';

export function ListItems({
  items,
  onRemoveItem,
  infoMessage,
  isLoading,
  titleModal
}: IListItemsProps): JSX.Element {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const { handleOpenModal, visibleModal } = useModalContext();

  const [idSelected, setIdSelected] = useState<number | string>();

  return (
    <Container>
      <Item>
        <span>{lang.forms.name[currentLangKey]}</span>
      </Item>
      {items.length > 0 ? (
        items.map(({ value, label }: ISelectOption) => (
          <Item key={value}>
            <span>{label}</span>
            <span
              onClick={() => {
                handleOpenModal('confirmation');
                setIdSelected(value);
              }}
            >
              <BaseIcon
                name='close'
                color='white'
              />
            </span>
          </Item>
        ))
      ) : (
        <Item>
          <span>{infoMessage}</span>
        </Item>
      )}

      {visibleModal === 'confirmation' && (
        <ConfirmationModal
          onConfirm={() => {
            if (idSelected) {
              onRemoveItem(idSelected);
            }
          }}
          title={titleModal}
          isLoading={isLoading}
        />
      )}
    </Container>
  );
}
