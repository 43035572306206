import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  padding: ${tokens.spacingMd};
  border-radius: ${tokens.borderRadiusNone} ${tokens.borderRadiusNone}
    ${tokens.borderRadiusMd} ${tokens.borderRadiusMd};
  background: ${tokens.neutralColorHighLight};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: ${tokens.spacingXs};
  }
`;
