import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue
} from 'react-hook-form';

import { BaseIcon } from 'presentation/components/base/Icon';

import { type IDsCheckboxProps } from './DsCheckbox.types';

import {
  Container,
  ErrorMessage,
  Label,
  StyledCheckbox,
  StyledIndicator
} from './DsCheckbox.styles';

export function DsCheckbox<T extends FieldValues>({
  name,
  defaultChecked = false,
  disabled = false,
  label = '',
  labelPosition = 'right',
  labelColor,
  iconSize,
  value,
  onChange,
  control,
  error
}: IDsCheckboxProps<T>): JSX.Element {
  const inputToRender = (
    value?: boolean,
    onChange?: (checked: boolean) => void,
    name?: string
  ): JSX.Element => {
    return (
      <Container>
        {label && labelPosition === 'left' && (
          <Label
            htmlFor={name}
            color={labelColor}
            $labelPosition={labelPosition}
            $disabled={disabled}
          >
            {label}
          </Label>
        )}

        <StyledCheckbox
          checked={value}
          onCheckedChange={onChange}
          disabled={disabled}
          defaultChecked={defaultChecked}
          id={name}
          name={name}
        >
          <StyledIndicator>
            <BaseIcon
              name='check'
              type='fill'
              size={iconSize}
            />
          </StyledIndicator>
        </StyledCheckbox>

        {label && labelPosition === 'right' && (
          <Label
            htmlFor={name}
            color={labelColor}
            $labelPosition={labelPosition}
            $disabled={disabled}
          >
            {label}
          </Label>
        )}
      </Container>
    );
  };

  if (!control) {
    return inputToRender(value, onChange, name);
  }

  return (
    <div>
      <Controller
        name={name as Path<T>}
        control={control}
        defaultValue={defaultChecked as PathValue<T, Path<T>>}
        render={({ field: { name, onChange, value } }) =>
          inputToRender(value, onChange as (val: unknown) => void, name)
        }
      />

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
}
