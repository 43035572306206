import { useLangContext } from 'data/contexts';

import { Spinner } from 'presentation/components/base/Spinner';
import {
  OnboardingFooter,
  OnboardingText,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { useRepresentativePublicApproval } from './useRepresentativePublicApproval';

import {
  Container,
  CustomLoading
} from './RepresentativePublicApproval.styles';

export function RepresentativePublicApproval(): JSX.Element {
  const [currentLangKey, global] = useLangContext(state => [
    state.currentLangKey,
    state.lang.global
  ]);

  const {
    isLoadingRepresentativeApproval,
    isAuthorized,
    handleApprove,
    handleDisapprove,
    content
  } = useRepresentativePublicApproval();

  return (
    <Container>
      {isLoadingRepresentativeApproval ? (
        <CustomLoading>
          <Spinner
            color='royalBlue'
            size={4}
          />
        </CustomLoading>
      ) : (
        <>
          <OnboardingTitle>{content.title}</OnboardingTitle>
          <OnboardingText>{content.first}</OnboardingText>

          <OnboardingText>{content.second}</OnboardingText>
          {isAuthorized === null && (
            <OnboardingFooter
              continueButtonProps={{
                text: global.approve[currentLangKey],
                onClick: handleApprove
              }}
              backButtonProps={{
                text: global.disapprove[currentLangKey],
                onClick: handleDisapprove
              }}
            />
          )}
        </>
      )}
    </Container>
  );
}
