import styled from 'styled-components';

import { RectButton } from 'presentation/components/base/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.white};
`;

export const FormContainer = styled.div`
  padding: 1.5rem;
  max-width: 36.5rem;

  > div {
    margin-bottom: 1rem;
  }

  form button {
    margin-top: 1.6rem;
    float: right;
  }
`;

export const CustomButton = styled(RectButton)`
  width: fit-content;
`;
