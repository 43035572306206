import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IChangeEmailPreferenceFieldsForm } from 'data/modules/settings/emailPreferences';

export function useChangeEmailPreferencesSchema(): ObjectSchema<IChangeEmailPreferenceFieldsForm> {
  const [langSchemaIsRequired, currentLangKey] = useLangContext(state => [
    state.lang.schemas.is_required,
    state.currentLangKey
  ]);

  const emailPreferencesSchema: ObjectSchema<IChangeEmailPreferenceFieldsForm> =
    useMemo(() => {
      return yup.object({
        receivesManagementSummaryEmail: yup
          .boolean()
          .required(langSchemaIsRequired[currentLangKey])
      });
    }, [langSchemaIsRequired, currentLangKey]);

  return emailPreferencesSchema;
}
