import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type OnboardingFooterStylesType } from './OnboardingFooter.types';

export const Container = styled.div<
  AddPrefixToType<OnboardingFooterStylesType>
>`
  display: flex;
  align-items: center;
  justify-content: ${({ $uniqueButton }) =>
    $uniqueButton ? 'flex-end' : 'space-between'};
  width: 100%;
  margin-top: 4rem;
`;

export const RightButtonContent = styled.div`
  display: flex;
  gap: 3.2rem;
`;
