import { useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';
import { useTranslation } from 'react-i18next';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import { type IErrorDefault } from 'data/contexts/modal/useModalContext.types';
import {
  type ApportionmentErrorType,
  ExpensesService,
  type IApportionmentPayload,
  type ICreateRouteByMapDefaultFieldsForm,
  type ICreateRouteByMapPayload,
  type ICreateRouteByMapReportSelectionFieldsForm,
  type IUpdateRouteByMapPayload,
  type IUseCreateRouteByMap,
  type IUseCreateRouteByMapParams,
  useGetExpenseParameters
} from 'data/modules/expenses';
import { ExpensesValidate } from 'data/modules/expenses';
import {
  ExpensesMutationKeys,
  ExpensesQueryKeys
} from 'data/modules/expenses/keys/expenses.keys';
import { useGetPaymentMethods } from 'data/modules/paymentMethods';
import { ReportsQueryKeys } from 'data/modules/reports';

import { useFormWithSchema } from 'shared/hooks/forms';
import { CustomDate } from 'shared/utils/custom';
import { ErrorHandle } from 'shared/utils/errors';
import { Currency } from 'shared/utils/format';

import { useDefaultFieldsSchema, useReportSelectionSchema } from './schemas';

export function useCreateRouteByMap({
  onAfterSubmitDefaultFieldsForm,
  onSuccessCreateRouteByMap,
  onSuccessUpdateRouteByMap,
  generateRouteImage,
  mapDirectionsResults,
  expenseUuidToUpdate,
  confirmedReportInformationBySelectedReport,
  onErrorCreateOrUpdateRouteByMapExpense,
  places
}: IUseCreateRouteByMapParams): IUseCreateRouteByMap {
  const { lang, currentLangKey } = useLangContext();

  const { t } = useTranslation('expenses');

  const queryClient = useQueryClient();

  const { expenseParameters, isLoadingExpenseParameters } =
    useGetExpenseParameters();

  const defaultFieldsStepData =
    useRef<ICreateRouteByMapDefaultFieldsForm | null>(null);

  const { paymentMethods } = useGetPaymentMethods();

  const defaultCurrencyFromParameter =
    expenseParameters?.defaultCurrency.user ??
    expenseParameters?.defaultCurrency.company;

  const valuesToCreateNewRouteMapExpense = {
    apportionment: [],
    costsCenter: undefined,
    currency: defaultCurrencyFromParameter
      ? String(defaultCurrencyFromParameter)
      : '',
    date: CustomDate.formatDateToIso(new Date()),
    mileage: '',
    mileagePaidValue: Currency.format(
      'BRL',
      expenseParameters?.amountPerKm ?? 0,
      false,
      4
    ),
    observation: '',
    refundable: false,
    description: '',
    paymentMethod: '',
    value: ''
  } as ICreateRouteByMapDefaultFieldsForm;

  const defaultFieldsFormMethods = useFormWithSchema(
    useDefaultFieldsSchema({
      userAction: expenseUuidToUpdate ? 'update' : 'create'
    }),
    {
      defaultValues: {
        currency: '',
        refundable: false,
        paymentMethod: ''
      },
      values: expenseUuidToUpdate ? undefined : valuesToCreateNewRouteMapExpense
    }
  );

  const reportSelectionFormMethods = useFormWithSchema(
    useReportSelectionSchema(),
    {
      defaultValues: {
        report: ''
      }
    }
  );

  const {
    mutateAsync: createRouteByMapAsync,
    isLoading: isCreatingRouteByMap
  } = useMutationCache({
    mutationKey: [ExpensesMutationKeys.CREATE_ROUTE_BY_MAP],
    mutationFn: async (payload: ICreateRouteByMapPayload) =>
      await ExpensesService.createRouteByMap(payload)
  });

  function onSubmitDefaultFieldsForm(
    data: ICreateRouteByMapDefaultFieldsForm
  ): void {
    if (
      expenseParameters?.isProjectFieldMandatory &&
      (data.apportionment === undefined || data.apportionment.length === 0)
    ) {
      toast.error(
        lang.expenses.modal.create_manual_expense.project_selection_error[
          currentLangKey
        ]
      );
      return;
    }

    if (data.apportionment && data.apportionment?.length > 0) {
      const checkApportionment = ExpensesValidate.checkApportionment(
        data.apportionment,
        Currency.parseToFloat(data.value)
      );

      if (checkApportionment !== null) {
        const errorMessages: Record<ApportionmentErrorType, string> = {
          'percentage-is-not-100':
            lang.expenses.modal.create_manual_expense
              .sum_apportionment_project_error[currentLangKey],
          'sum-of-values': t(
            'errors.theSumOfTheApportionmentValuesMustBeEqualToTheExpenseValue'
          ),
          'duplicate-projects':
            lang.expenses.modal.create_manual_expense
              .duplicate_projects_in_the_apportionment_error[currentLangKey]
        };

        toast.error(errorMessages[checkApportionment]);
        return;
      }
    }

    // salva a primeira etapa do formulário para depois enviar a request
    defaultFieldsStepData.current = data;
    onAfterSubmitDefaultFieldsForm?.();
  }

  const allowUserChangeExpenseProjectByReportProject =
    defaultFieldsStepData.current !== null &&
    (defaultFieldsStepData.current.apportionment === undefined ||
      defaultFieldsStepData.current.apportionment.length === 0 ||
      defaultFieldsStepData.current.apportionment.length === 1);

  const {
    mutateAsync: updateRouteByMapAsync,
    isLoading: isUpdatingRouteByMap
  } = useMutationCache({
    mutationKey: [ExpensesMutationKeys.UPDATE_ROUTE_BY_MAP_EXPENSE],
    mutationFn: async (payload: IUpdateRouteByMapPayload) =>
      await ExpensesService.updateRouteByMap(payload)
  });

  async function createRouteByMap(
    data: ICreateRouteByMapPayload
  ): Promise<void> {
    try {
      await createRouteByMapAsync(data);

      onSuccessCreateRouteByMap?.();

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES]
      });

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
      });

      queryClient.removeQueries({
        queryKey: [ReportsQueryKeys.GET_OPEN_REPORTS]
      });
    } catch (e) {
      const errorMessage =
        ErrorHandle.getErrorMessage(e as IErrorDefault) ??
        lang.expenses.modal.create_manual_route_expense
          .error_when_creating_route_expense[currentLangKey];

      onErrorCreateOrUpdateRouteByMapExpense?.(errorMessage);
    }
  }

  async function updateRouteByMap(
    data: IUpdateRouteByMapPayload
  ): Promise<void> {
    try {
      const updatedRouteByMapExpense = await updateRouteByMapAsync(data);

      onSuccessUpdateRouteByMap?.();

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES]
      });

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
      });

      queryClient.setQueryData(
        [ExpensesQueryKeys.GET_EXPENSE, expenseUuidToUpdate],
        updatedRouteByMapExpense
      );

      queryClient.removeQueries({
        queryKey: [ReportsQueryKeys.GET_OPEN_REPORTS]
      });
    } catch (e) {
      const errorMessage =
        ErrorHandle.getErrorMessage(e as IErrorDefault) ??
        t('messages.anErrorOccurredWhenUpdatingMapExpense');

      onErrorCreateOrUpdateRouteByMapExpense?.(errorMessage);
    }
  }

  async function onSubmitReportSelectionForm(
    data: ICreateRouteByMapReportSelectionFieldsForm
  ): Promise<void> {
    const apportionmentFromFirstStep = (
      defaultFieldsStepData.current as ICreateRouteByMapDefaultFieldsForm
    )?.apportionment;

    // para evitar sobrescrita do atributo apportionment, coloco no final do objeto
    const formData = {
      ...(defaultFieldsStepData.current as ICreateRouteByMapDefaultFieldsForm),
      ...data,
      apportionment: apportionmentFromFirstStep
    };

    const routeImageFile = await generateRouteImage();

    if (routeImageFile === null) {
      toast.error(
        lang.expenses.modal.create_route_by_map.error_generating_image[
          currentLangKey
        ]
      );
      return;
    }

    let apportionment: IApportionmentPayload[] | undefined;

    // se o usuário selecionou um relatório e confirmou a alteração dos dados, então eu preciso considerar o projeto do relatório no rateio
    if (
      allowUserChangeExpenseProjectByReportProject &&
      confirmedReportInformationBySelectedReport &&
      confirmedReportInformationBySelectedReport.project
    ) {
      // caso o usuário tenha colocado um projeto no rateio, então considera os dados do projeto do relatório
      if (
        formData.apportionment !== undefined &&
        formData.apportionment.length === 1
      ) {
        apportionment = [
          {
            ...formData.apportionment[0],
            project: String(
              confirmedReportInformationBySelectedReport.project.id
            )
          }
        ];
      } else {
        // caso o usuário não tenha definido rateio, então eu vou considerar o valor total da despesa para o projeto do relatório
        apportionment = [
          {
            project: String(
              confirmedReportInformationBySelectedReport.project.id
            ),
            percentage: 100,
            value: Currency.parseToFloat(formData.value)
          }
        ];
      }
    } else {
      apportionment = formData.apportionment?.map(item => ({
        ...item,
        project: item.project?.value ?? ''
      }));
    }

    const hasReportPaymentMethod =
      confirmedReportInformationBySelectedReport !== null &&
      confirmedReportInformationBySelectedReport.paymentMethod !== null;

    // caso o relatório tenha uma forma de pagamento, o valor do campo reembolsável é o da forma de pagamento do relatório
    const isReportPaymentMethodRefundable =
      (hasReportPaymentMethod &&
        paymentMethods !== undefined &&
        paymentMethods.find(
          item =>
            String(item.id) ===
            String(
              confirmedReportInformationBySelectedReport?.paymentMethod?.id
            )
        )?.refundable) ??
      false;

    const apportionmentAdjusted =
      ExpensesValidate.adjustApportionmentPercentageBeforeSendToApi(
        apportionment,
        Currency.parseToFloat(formData.value)
      );

    const mergedFormDataWithReportData: ICreateRouteByMapPayload = {
      ...formData,
      routeImage: routeImageFile,
      costsCenter: formData.costsCenter?.value ?? '',
      apportionment: apportionmentAdjusted,
      places,
      mapDirectionsResults,
      // se o usuário selecionou um relatório, confirmou a alteração dos dados e o relatório tem um centro de custo, então eu vou considerar o centro de custo do relatório
      ...(confirmedReportInformationBySelectedReport &&
      confirmedReportInformationBySelectedReport.costsCenter
        ? {
            costsCenter: String(
              confirmedReportInformationBySelectedReport.costsCenter?.id
            )
          }
        : {}),
      // se o usuário selecionou um relatório, confirmou a alteração dos dados e o relatório tem um método de pagamento, então eu vou considerar o método de pagamento do relatório
      ...(confirmedReportInformationBySelectedReport &&
      confirmedReportInformationBySelectedReport.paymentMethod
        ? {
            paymentMethod: String(
              confirmedReportInformationBySelectedReport.paymentMethod.id
            ),
            refundable: isReportPaymentMethodRefundable
          }
        : {})
    };

    if (expenseUuidToUpdate === undefined) {
      createRouteByMap(mergedFormDataWithReportData);
      return;
    }

    updateRouteByMap({
      ...mergedFormDataWithReportData,
      expenseUuid: expenseUuidToUpdate
    });
  }

  return {
    defaultFieldsForm: {
      methods: defaultFieldsFormMethods,
      handleSubmit: defaultFieldsFormMethods.handleSubmit(
        onSubmitDefaultFieldsForm
      ),
      selectedCurrencyId: defaultFieldsStepData.current?.currency
    },
    reportSelectionForm: {
      methods: reportSelectionFormMethods,
      handleSubmit: reportSelectionFormMethods.handleSubmit(
        onSubmitReportSelectionForm
      )
    },
    isLoadingExpenseParameters,
    isCreatingRouteByMap,
    allowUserChangeExpenseProjectByReportProject,
    isUpdatingRouteByMap
  };
}
