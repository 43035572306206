import { DefaultButton, SecondaryButton } from 'ds';

import { useLangContext } from 'data/contexts';

import { StatusPill } from 'presentation/pages/store/components/StatusPill';
import { StatusText } from 'presentation/pages/store/components/StatusText';

import { useProductCard } from './useProductCard';

import { type IProductCardProps } from './ProductCard.types';

import {
  Container,
  Content,
  Description,
  FooterContent,
  ProductName,
  StatusContent,
  ThumbNail,
  TitleContent,
  Wrapper
} from './ProductCard.styles';

export function ProductCard({
  status,
  className,
  parameter,
  identifier,
  onRequestProductClick,
  ...props
}: IProductCardProps): JSX.Element {
  const {
    currentLangKey,
    lang: {
      store: { productCards }
    }
  } = useLangContext();

  const {
    name,
    thumb,
    statusName,
    description,
    statusMessage,
    handleLearnMoreClick,
    handleProductCardClick,
    handleRequestProductClick
  } = useProductCard({
    identifier,
    status
  });

  return (
    <Container
      {...props}
      onClick={handleProductCardClick}
    >
      <Wrapper>
        <ThumbNail
          alt='thumb'
          src={thumb}
        />

        <Content>
          <TitleContent>
            <ProductName>{name}</ProductName>
            <StatusPill status={status}>{statusName}</StatusPill>
          </TitleContent>

          <Description>{description}</Description>
        </Content>
      </Wrapper>

      <StatusContent>
        {status !== 'not_owned' ? (
          <StatusText status={status}>{statusMessage}</StatusText>
        ) : (
          <FooterContent>
            <SecondaryButton
              onClick={e => {
                handleRequestProductClick(onRequestProductClick);
                e.stopPropagation();
              }}
            >
              {productCards.buttons.request_contact[currentLangKey]}
            </SecondaryButton>
            <DefaultButton
              onClick={e => {
                handleLearnMoreClick();
                e.stopPropagation();
              }}
            >
              {productCards.buttons.learn_more[currentLangKey]}
            </DefaultButton>
          </FooterContent>
        )}
      </StatusContent>
    </Container>
  );
}
