import { DSIcons } from 'ds';
import { useTranslation } from 'react-i18next';

import { Icons } from 'shared/constants/global';

import { type ISuggestionBannerProps } from './SuggestionBanner.types';

import {
  Container,
  Content,
  Description,
  Title
} from './SuggestionBanner.styles';

export function SuggestionBanner({
  ...props
}: ISuggestionBannerProps): JSX.Element {
  const { t } = useTranslation('analytics');

  return (
    <Container {...props}>
      <Icons.SearchEyeIcon />
      <Content>
        <Title>{t('suggestionBanner.title')}</Title>
        <Description>{t('suggestionBanner.description')}</Description>
      </Content>
      <DSIcons.ArrowRightIcon />
    </Container>
  );
}
