import { TransactionDetailsHeader } from './TransactionDetailsHeader/TransactionDetailsHeader.component';
import { TransactionDetailsRelatedsContainer } from './TransactionDetailsRelatedsContainer/TransactionDetailsRelatedsContainer.component';
import { TransactionDetailsRelatedsItem } from './TransactionDetailsRelatedsItem/TransactionDetailsRelatedsItem.component';
import { TransactionDetailsSection } from './TransactionDetailsSection/TransactionDetailsSection.component';
import { TransactionDetailsSectionItem } from './TransactionDetailsSectionItem/TransactionDetailsSectionItem';

export const TransactionDetails = {
  Header: TransactionDetailsHeader,
  Section: TransactionDetailsSection,
  SectionItem: TransactionDetailsSectionItem,
  RelatedsContainer: TransactionDetailsRelatedsContainer,
  RelatedsItem: TransactionDetailsRelatedsItem
};
