import { CardInfo, DefaultButton } from 'ds';
import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';

import moneyWithdraw from 'shared/assets/images/cards/money-withdraw.svg';

import { type IWithdrawValueCardInfoProps } from './WithdrawValueCardInfo.types';

import {
  CardInfoCaption,
  CardInfoContainer,
  ContentWrapper,
  CustomImage
} from './WithdrawValueCardInfo.styles';

export function WithdrawValueCardInfo({
  ...rest
}: IWithdrawValueCardInfoProps): JSX.Element {
  const { t } = useTranslation('cards');

  const { handleOpenModal } = useModalContext();

  return (
    <CardInfoContainer {...rest}>
      <CustomImage
        src={moneyWithdraw}
        width={75}
      />

      <ContentWrapper>
        <CardInfo.Title variant='small'>{t('withdrawValue')}</CardInfo.Title>

        <CardInfoCaption variant='small'>
          {t('withdrawValueText')}
        </CardInfoCaption>

        <DefaultButton
          onClick={() => {
            handleOpenModal('withdrawal');
          }}
          size='small'
        >
          {t('withdraw')}
        </DefaultButton>
      </ContentWrapper>
    </CardInfoContainer>
  );
}
