import { useLangContext, useModalContext } from 'data/contexts';

import {
  PendencyDetailsModal,
  TransactionDetailsModal
} from 'presentation/components/global/Modal';
import {
  GenericTable,
  OrderHeadCell
} from 'presentation/components/global/Table';

import { useStatementTable } from './useStatementTable';

import {
  type StatementPropsType,
  type StatementType
} from './StatementTable.types';

import { HeadCell, HeadRow } from './StatementTable.styles';

export function StatementTable<T extends StatementType>({
  data,
  showDayBalance,
  type
}: StatementPropsType<T>): JSX.Element {
  const {
    transactionShowingDetails,
    pendencyShowingDetails,
    tableBodyRows,
    handleTableSort,
    tableSort,
    dayBalanceDateOrder
  } = useStatementTable({
    type,
    data,
    showDayBalance
  });
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);
  const { visibleModal } = useModalContext();

  return (
    <>
      <GenericTable
        thead={
          <HeadRow>
            <OrderHeadCell
              isActive={tableSort.column === 'date' || showDayBalance}
              onClick={() => {
                handleTableSort('date');
              }}
              order={showDayBalance ? dayBalanceDateOrder : tableSort.order}
            >
              {lang.global.date[currentLangKey]}
            </OrderHeadCell>

            <OrderHeadCell
              isActive={tableSort.column === 'card'}
              onClick={() => {
                handleTableSort('card');
              }}
              order={tableSort.order}
            >
              {lang.global.card[currentLangKey]}
            </OrderHeadCell>

            <OrderHeadCell
              isActive={tableSort.column === 'user'}
              onClick={() => {
                handleTableSort('user');
              }}
              order={tableSort.order}
            >
              {lang.global.user[currentLangKey]}
            </OrderHeadCell>

            <OrderHeadCell
              isActive={tableSort.column === 'description'}
              onClick={() => {
                handleTableSort('description');
              }}
              order={tableSort.order}
            >
              {lang.global.description[currentLangKey]}
            </OrderHeadCell>

            <OrderHeadCell
              isActive={tableSort.column === 'value'}
              onClick={() => {
                handleTableSort('value');
              }}
              order={tableSort.order}
            >
              {lang.global.value[currentLangKey]}
            </OrderHeadCell>

            <HeadCell>{lang.global.status[currentLangKey]}</HeadCell>

            <th></th>
          </HeadRow>
        }
        tbody={tableBodyRows}
      />

      {visibleModal === 'transactionDetails' && (
        <TransactionDetailsModal
          transaction={transactionShowingDetails.transaction}
          userName={transactionShowingDetails.userName}
        />
      )}

      {visibleModal === 'pendencyDetails' && (
        <PendencyDetailsModal pendency={pendencyShowingDetails} />
      )}
    </>
  );
}
