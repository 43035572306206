import { useState } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { useValueSolicitationContext } from 'data/contexts';

import {
  type IUseSolicitationActionsRow,
  type IUseSolicitationActionsRowProps
} from './SolicitationActionsRow.types';

export function useSolicitationActionsRow({
  solicitation
}: IUseSolicitationActionsRowProps): IUseSolicitationActionsRow {
  const [handleToggleValueRequestedAmount, handleToggleManagerJustify] =
    useValueSolicitationContext(
      useShallow(state => [
        state.handleToggleValueRequestedAmount,
        state.handleToggleManagerJustify
      ])
    );

  const [valueRequestedAmount, setValueRequestedAmount] = useState<number>(
    solicitation.requestedAmount
  );

  const [valueManagerJustify, setValueManagerJustify] = useState<string>('');

  function handleValueRequestedAmount(value: number): void {
    handleToggleValueRequestedAmount(solicitation, value);
    setValueRequestedAmount(value);
  }

  function handleManagerJustifyValue(value: string): void {
    handleToggleManagerJustify(solicitation, value);
    setValueManagerJustify(value);
  }

  return {
    valueRequestedAmount,
    valueManagerJustify,
    handleValueRequestedAmount,
    handleManagerJustifyValue
  };
}
