import { useLangContext, useModalContext } from 'data/contexts';

import { SkeletonLoader } from 'presentation/components/global/Loader';
import { Pagination } from 'presentation/components/global/Pagination';
import { SimpleTable } from 'presentation/components/global/Table';
import { Title } from 'presentation/components/global/Title';

import { ManageBusinessAccountHolderModal } from './components/ManageBusinessAccountHolderModal';
import { useBusinessAccountHolders } from './useBusinessAccountHolders';

import {
  NotFoundMessage,
  TableContainer
} from './BusinessAccountHolders.styles';

export function BusinessAccountHolders(): JSX.Element {
  const [settings, currentLangKey] = useLangContext(state => [
    state.lang.settings,
    state.currentLangKey
  ]);
  const { handleOpenModal, visibleModal } = useModalContext();

  const {
    accountHoldersTableData,
    accountHoldersTableHeader,
    isLoadingAccountHolders,
    totalAccountHolders,
    accountHoldersPayload,
    handleChangePayloadState,
    accountHolderToBeUpdated,
    setAccountHolderToBeUpdated,
    handleAfterUpdateAccountHolder
  } = useBusinessAccountHolders();

  document.title = `vexpenses | ${settings.accountHolders.business_account_holders[currentLangKey]}`;

  return (
    <>
      <Title
        text={settings.accountHolders.business_account_holders[currentLangKey]}
        buttonProps={{
          children:
            settings.accountHolders.create_business_account_holders[
              currentLangKey
            ],
          color: 'success',
          onClick: () => {
            setAccountHolderToBeUpdated(null);
            handleOpenModal('manageBusinessAccountHolder');
          }
        }}
      />

      <TableContainer>
        {isLoadingAccountHolders ? (
          <>
            <SkeletonLoader height='6rem' />

            <SkeletonLoader height='4rem' />

            <SkeletonLoader height='4rem' />

            <SkeletonLoader height='4rem' />
          </>
        ) : (
          <>
            {accountHoldersTableData.length === 0 ? (
              <NotFoundMessage>
                {
                  settings.accountHolders.account_holders_not_found[
                    currentLangKey
                  ]
                }
              </NotFoundMessage>
            ) : (
              <>
                <SimpleTable
                  header={accountHoldersTableHeader}
                  data={accountHoldersTableData}
                  dataId='documentNumber'
                />
                <Pagination
                  currentPage={accountHoldersPayload.currentPage}
                  total={totalAccountHolders}
                  onChangePage={page => {
                    handleChangePayloadState('currentPage', page);
                  }}
                  pageSize={accountHoldersPayload.pageSize}
                  onChangePageSize={pageSize => {
                    handleChangePayloadState('pageSize', pageSize);
                  }}
                  breakpointToBreakInColumns='tablet'
                />
              </>
            )}
          </>
        )}
      </TableContainer>

      {visibleModal === 'manageBusinessAccountHolder' && (
        <ManageBusinessAccountHolderModal
          accountHolderToBeUpdated={accountHolderToBeUpdated}
          onAfterUpdateAccountHolder={handleAfterUpdateAccountHolder}
        />
      )}
    </>
  );
}
