import {
  type ICardGroup,
  type IGetManagersGroupsParams,
  type IGetManagersGroupsPayload,
  type IPersistenceManagerGroup
} from '../../types/cardGroups.types';

class GetManagersCardGroupsMapper {
  toDomain(persistenceManagerCardGroups: IPersistenceManagerGroup): ICardGroup {
    return {
      id: persistenceManagerCardGroups.id,
      balanceId: persistenceManagerCardGroups.balance_id,
      name: persistenceManagerCardGroups.name,
      isManager: persistenceManagerCardGroups.is_manager,
      availableValue: undefined
    };
  }

  toPersistence(payload: IGetManagersGroupsPayload): IGetManagersGroupsParams {
    return {
      page: payload.page,
      limit: payload.limit,
      ...(payload.name && { name: payload.name }),
      ordenation_type: payload.ordenationType
    };
  }
}

export default new GetManagersCardGroupsMapper();
