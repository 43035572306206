import { Currency } from 'shared/utils/format';

import { useGroupManagementTableRow } from '../GroupManagementTableRow/useGroupManagementTableRow';

import { type IGroupManagementTableRowProps } from './GroupManagementCard.types';

import { StyledCreditCard } from './GroupManagementCard.styles';

export function GroupManagementCard({
  user
}: IGroupManagementTableRowProps): JSX.Element {
  const { handleOpenDetails } = useGroupManagementTableRow({ user });

  return (
    <StyledCreditCard
      key={`${user.balanceId}-${user.name}-${user.cardNumber}`}
      cardInfo={{
        name: user.name,
        number: user.cardNumber,
        availableBalance: Currency.format('BRL', user.amount ?? 0, true),
        status: user.cardStatus,
        type: user.type === 'CORP_PERSONAL' ? 'INDIVIDUAL' : 'BUSINESS'
      }}
      onClick={() => {
        handleOpenDetails();
      }}
    />
  );
}
