import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: ${tokens.borderRadiusSm};
`;

export const TableWrapper = styled.div`
  overflow-x: auto;

  tbody tr td + td:last-of-type {
    font-weight: bold;
  }
`;
