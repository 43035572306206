enum ProjectsMutationKeys {
  CREATE_PROJECTS = 'CREATE_PROJECTS'
}

enum ProjectsQueryKeys {
  GET_PROJECTS = 'GET_PROJECTS',
  GET_RELATED_PROJECTS = 'GET_RELATED_PROJECTS',
  GET_PAGINATED_PROJECTS = 'GET_PAGINATED_PROJECTS'
}

export { ProjectsMutationKeys, ProjectsQueryKeys };
