import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import {
  type InputWrapperStylesType,
  type LabelStylesType
} from './DatePicker.types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.label<AddPrefixToType<LabelStylesType>>`
  color: ${({ $error, theme }) =>
    $error ? theme.colors.danger : theme.colors.mineShaft};
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  width: fit-content;
`;

export const InputWrapper = styled.div<AddPrefixToType<InputWrapperStylesType>>`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-color: ${({ $error, theme }) =>
    $error ? theme.colors.danger : theme.colors.gallery};
  border-style: solid;
  border-width: 1px;
  display: flex;
  height: 3.7rem;
  padding: 0 1.2rem;
  width: 100%;

  > label {
    cursor: pointer;
  }

  ${({ $error }) =>
    !$error &&
    css`
      &:focus-within {
        box-shadow: 0 0 2px 1px ${({ theme }) => theme.colors.primary};
      }
    `}

  > div:first-child {
    width: 100%;
  }

  input {
    width: 100%;
    color: ${({ theme }) => theme.colors.mineShaft};
    font-size: 1.3rem;
    border: 0;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      background: ${({ theme }) => theme.colors.alabaster};
      cursor: not-allowed;

      input {
        background: ${({ theme }) => theme.colors.alabaster};
        cursor: not-allowed;
      }
    `}

  .customDatePicker {
    > button {
      line-height: 2.7rem;
      border-width: 7.2px;
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
      > div {
        width: 2.7rem;
        font-size: 1.2rem;
        line-height: 2.7rem;
      }
    }

    .react-datepicker__current-month {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
    }

    .react-datepicker-time__caption {
      font-size: 1.1rem;
    }
  }
`;
