import { Outlet, useLocation } from 'react-router-dom';

import { vexDomain } from 'data/config';
import { useFlagsContext } from 'data/contexts';
import { useLoadCollaboratorInitialData } from 'data/global/features/load-collaborator-initial-data/useLoadCollaboratorInitialData';

import { SplashScreen } from 'presentation/components/global/SplashScreen';
import { FallbackPage } from 'presentation/pages/global';

import { routesPathPrefix } from 'shared/constants/global';

export function ReportsAuthMiddleware(): JSX.Element {
  const { isLoading, user } = useLoadCollaboratorInitialData();

  const { redesignReportsFlag } = useFlagsContext();

  const { pathname } = useLocation();

  if (!redesignReportsFlag && !window.location.host.includes('localhost')) {
    if (pathname === routesPathPrefix.reports) {
      window.location.href = `${vexDomain}/relatorios`;

      return <SplashScreen />;
    }

    return <FallbackPage />;
  }

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!isLoading && !user) {
    return <FallbackPage />;
  }

  return <Outlet />;
}
