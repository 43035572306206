import { useMemo } from 'react';

import { useGetApprovalFlows } from 'data/modules/approvalFlows';

import { type ISelectOption } from 'presentation/components/base/Select/Select.types';

import { TransformArray } from 'shared/utils/global';

import { type IUseApprovalFlowManageBusinessCard } from './useApprovalFlowManageBusinessCard.types';

export function useApprovalFlowManageBusinessCard(): IUseApprovalFlowManageBusinessCard {
  const payload = {
    page: 1,
    perPage: 500,
    enabled: true
  };

  const { approvalFlows, isLoadingApprovalFlows } =
    useGetApprovalFlows(payload);

  const approvalFlowsOptions: ISelectOption[] = useMemo(() => {
    return TransformArray.objectsToSelectOptions(
      approvalFlows,
      'id',
      'description'
    );
  }, [approvalFlows]);

  return {
    approvalFlowsOptions,
    isLoadingApprovalFlows
  };
}
