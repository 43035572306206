import { DefaultTable } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import {
  useAuthContext,
  useCardGroupsContext,
  useModalContext
} from 'data/contexts';

import {
  CardGroupsTableRow,
  SearchAndFilters,
  TablePagination
} from 'presentation/pages/cards/Management/CardGroups/components/CardGroupsTable';
import { CardGroupsOrSingleCardsTabs } from 'presentation/pages/cards/Management/components/CardGroupsOrSingleCardsTabs';
import { GroupCard } from 'presentation/pages/cards/Management/components/GroupCard';
import { TableLoader } from 'presentation/pages/cards/Management/components/TableLoader';

import { useCardGroupsTable } from './useCardGroupsTable';

import { type DefaultTableHeadCellSortType } from 'ds/components/Table/DefaultTable/DefaultTableHeadCell/DefaultTableHeadCell.types';

import {
  CardGroupsTableEmpty,
  CardsContainer,
  Container,
  TableContainer
} from './CardGroupsTable.styles';

export function CardGroupsTable(): JSX.Element {
  const { t } = useTranslation('cards');

  const user = useAuthContext(state => state.user);
  const [ordenationType, tableViewMode, handleChangeOrdenation] =
    useCardGroupsContext(
      useShallow(state => [
        state.ordenationType,
        state.tableViewMode,
        state.handleChangeOrdenation
      ])
    );

  const { cardGroups, isFetchingCardGroups, isFetchingAmounts } =
    useCardGroupsTable();

  const { handleOpenModal } = useModalContext();
  const handleSelectCardGroup = useCardGroupsContext(
    state => state.handleSelectCardGroup
  );

  return (
    <Container>
      {user?.cards?.type !== 'USER' && (
        <CardGroupsOrSingleCardsTabs selectedTab='CARD_GROUPS' />
      )}

      <SearchAndFilters />

      {isFetchingCardGroups && (
        <TableLoader
          columns={3}
          rows={6}
          layout={tableViewMode}
        />
      )}

      {!isFetchingCardGroups &&
        (cardGroups?.length === 0 || !cardGroups?.length) && (
          <CardGroupsTableEmpty>
            {t('cardGroupsTableEmptyMessage')}
          </CardGroupsTableEmpty>
        )}

      {!isFetchingCardGroups &&
        cardGroups &&
        cardGroups.length > 0 &&
        tableViewMode === 'LIST' && (
          <TableContainer>
            <DefaultTable.Container>
              <DefaultTable.Thead>
                <DefaultTable.Tr>
                  <DefaultTable.Th
                    width='30%'
                    order={
                      (ordenationType.toLowerCase() as DefaultTableHeadCellSortType) ||
                      'asc'
                    }
                    isActive
                    onClick={handleChangeOrdenation}
                  >
                    {t('cardGroups')}
                  </DefaultTable.Th>

                  <DefaultTable.Th width='30%'>
                    {t('valueAvailable')}
                  </DefaultTable.Th>

                  <DefaultTable.Th width='8%'>{t('details')}</DefaultTable.Th>
                </DefaultTable.Tr>
              </DefaultTable.Thead>

              {cardGroups && (
                <DefaultTable.Tbody>
                  {cardGroups.map(cardGroup => (
                    <CardGroupsTableRow
                      key={`${cardGroup.id}`}
                      cardGroup={cardGroup}
                      isFetchingAmounts={isFetchingAmounts}
                    />
                  ))}
                </DefaultTable.Tbody>
              )}
            </DefaultTable.Container>
          </TableContainer>
        )}

      {!isFetchingCardGroups &&
        cardGroups &&
        tableViewMode === 'GROUP_CARDS' && (
          <CardsContainer>
            {cardGroups.map(cardGroup => (
              <GroupCard
                key={`${cardGroup.id}`}
                groupInfo={{
                  name: cardGroup.name,
                  availableValue: cardGroup.availableValue
                }}
                onClick={() => {
                  handleSelectCardGroup(cardGroup);
                  handleOpenModal('groupCardDetails');
                }}
              />
            ))}
          </CardsContainer>
        )}

      {(isFetchingCardGroups || (cardGroups && cardGroups.length > 0)) && (
        <TablePagination />
      )}
    </Container>
  );
}
