import { useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useChargeCardsContext } from 'data/contexts';
import {
  useGetAccountBalancesAmounts,
  useGetAccounts
} from 'data/modules/cards/account';
import { useGetModifiedBalanceAllocationItemsFromCache } from 'data/modules/cards/balance';

import {
  ChargeCardsSteps,
  type IValueAllocationCardItem
} from 'presentation/pages/cards/Management/ChargeCards/ChargeCards.types';

import { useDebounce } from 'shared/hooks/global';
import { ChargeCards } from 'shared/utils/cards';
import { CustomObject } from 'shared/utils/custom';

import { type IUseChargeCardsTable } from './ChargeCardsTable.types';

export function useChargeCardsTable(): IUseChargeCardsTable {
  const [
    { search, cardType, isCardTypeVisible },
    { page, limit },
    currentStep,
    sortTable,
    selectedCardsMap,
    selectedCardsMapSize,
    handleChangeFilters,
    handleChangePagination,
    loadOperationsWithCachedData,
    handleToggleSelectAllCards,
    handleChangeSortTable
  ] = useChargeCardsContext(
    useShallow(state => [
      state.filters,
      state.pagination,
      state.currentStep,
      state.sortTable,
      state.selectedCardsMap,
      state.selectedCardsMap.size,
      state.handleChangeFilters,
      state.handleChangePagination,
      state.loadOperationsWithCachedData,
      state.handleToggleSelectAllCards,
      state.handleChangeSortTable
    ])
  );

  const debouncedSearch = useDebounce(search, 500);

  const { groupId } = useParams();

  const {
    modifiedBalanceAllocationItemsFromCache,
    isFetchingModifiedBalanceAllocationItemsFromCache
  } = useGetModifiedBalanceAllocationItemsFromCache();

  useEffect(() => {
    // Preenche valores de movimentações salvos no cache

    if (isFetchingModifiedBalanceAllocationItemsFromCache) return;

    if (modifiedBalanceAllocationItemsFromCache) {
      loadOperationsWithCachedData(modifiedBalanceAllocationItemsFromCache);
    }
  }, [
    isFetchingModifiedBalanceAllocationItemsFromCache,
    modifiedBalanceAllocationItemsFromCache,
    loadOperationsWithCachedData
  ]);

  const { paginatedAccounts, isFetchingAccounts } = useGetAccounts({
    page,
    limit,
    name: debouncedSearch?.toLowerCase().trim(),
    accountType: cardType?.length === 1 ? cardType[0] : undefined,
    ordenationType: sortTable.cardName,
    enabled: currentStep === ChargeCardsSteps.CHARGE_CARDS,
    cardGroupId: groupId
  });

  function getAccountsIds(): string[] {
    if (!paginatedAccounts) {
      return [];
    }

    return paginatedAccounts.data.map(item => {
      if (item.individual) {
        return item.individual.account.balances[0].id;
      } else {
        return item.business.balances[0].id;
      }
    });
  }

  function areMapAndArrayEqual(
    array: IValueAllocationCardItem[],
    map: Map<string, IValueAllocationCardItem>
  ): boolean {
    const mapToArray = Array.from(map, ([key, value]) => value);
    return CustomObject.compareArraysOfObjects(array, mapToArray);
  }

  const { accountBalancesAmounts } = useGetAccountBalancesAmounts({
    enabled:
      getAccountsIds().length > 0 &&
      !isFetchingAccounts &&
      currentStep === ChargeCardsSteps.CHARGE_CARDS,
    accountBalancesIds: getAccountsIds()
  });

  useEffect(() => {
    // Volta para a página 1 ao pesquisar cartão por nome
    handleChangePagination({ page: 1 });
  }, [debouncedSearch, handleChangePagination]);

  useEffect(() => {
    // Seleciona opções iniciais do filtro Tipo de Cartão na tela de Resumo

    if (currentStep === ChargeCardsSteps.CONFIRM_OPERATIONS) {
      if (!cardType) {
        const foundModifiedIndividualCard =
          modifiedBalanceAllocationItemsFromCache?.find(
            card => card.cardType === 'INDIVIDUAL'
          );

        const foundModifiedCompanyCard =
          modifiedBalanceAllocationItemsFromCache?.find(
            card => card.cardType === 'BUSINESS'
          );

        if (foundModifiedCompanyCard && foundModifiedIndividualCard) {
          handleChangeFilters({
            cardType: ['INDIVIDUAL', 'BUSINESS'],
            isCardTypeVisible: true
          });
          return;
        }

        if (foundModifiedCompanyCard) {
          handleChangeFilters({
            cardType: ['BUSINESS'],
            isCardTypeVisible: false
          });

          return;
        }

        if (foundModifiedIndividualCard) {
          handleChangeFilters({
            cardType: ['INDIVIDUAL'],
            isCardTypeVisible: false
          });
        }
      }
    }
  }, [
    cardType,
    currentStep,
    isCardTypeVisible,
    modifiedBalanceAllocationItemsFromCache,
    handleChangeFilters
  ]);

  const cachedCardsListFilteredSortedAndPaginated = useMemo(() => {
    if (currentStep === ChargeCardsSteps.CHARGE_CARDS) return;

    const cachedCardsList = modifiedBalanceAllocationItemsFromCache?.map(
      cachedCard => ({
        name: cachedCard.userName,
        type: cachedCard.cardType as 'INDIVIDUAL' | 'BUSINESS',
        number: cachedCard.cardNumber,
        currentValue: cachedCard.currentValue,
        balanceId: cachedCard.balanceAccountId,
        status: cachedCard.status as 'ACTIVE' | 'BLOCKED',
        accountId: cachedCard.accountId as string,
        userId: cachedCard.userId as string
      })
    );

    const cachedCardsListFiltered = ChargeCards.getFilteredList({
      list: cachedCardsList,
      searchTerm: debouncedSearch?.toLowerCase().trim() ?? '',
      cardType: cardType?.length === 1 ? cardType[0] : 'ALL'
    });

    const cachedCardsListFilteredAndSorted = ChargeCards.getSortedList({
      list: cachedCardsListFiltered,
      sortTable
    });

    return ChargeCards.getPaginatedList({
      list: cachedCardsListFilteredAndSorted,
      pageSize: limit
    });
  }, [
    limit,
    modifiedBalanceAllocationItemsFromCache,
    sortTable,
    cardType,
    debouncedSearch,
    currentStep
  ]);

  useEffect(() => {
    // Atualiza valor total de itens da lista de cartões após os filtros

    if (currentStep === ChargeCardsSteps.CHARGE_CARDS) {
      if (paginatedAccounts) {
        handleChangePagination({
          total: paginatedAccounts.total
        });
      }
    }

    if (currentStep === ChargeCardsSteps.CONFIRM_OPERATIONS) {
      handleChangePagination({
        total: cachedCardsListFilteredSortedAndPaginated?.reduce(
          (acc, curr) => {
            return acc + curr.length;
          },
          0
        )
      });
    }
  }, [
    cachedCardsListFilteredSortedAndPaginated,
    currentStep,
    paginatedAccounts,
    handleChangePagination
  ]);

  useEffect(() => {
    // Volta para a página anterior caso tenha excluído todos os itens da
    // página atual na tela de Resumo

    if (
      currentStep === ChargeCardsSteps.CONFIRM_OPERATIONS &&
      !cachedCardsListFilteredSortedAndPaginated?.[page - 1] &&
      page > 1
    ) {
      handleChangePagination({ page: page - 1 });
    }
  }, [
    cachedCardsListFilteredSortedAndPaginated,
    page,
    currentStep,
    handleChangePagination
  ]);

  const cardsList = useMemo(() => {
    if (isFetchingAccounts) return;

    if (!paginatedAccounts) return [];

    return paginatedAccounts.data.map((item, index) => {
      if (item.individual) {
        return {
          name: item.individual.userName,
          type: 'INDIVIDUAL' as 'INDIVIDUAL' | 'BUSINESS',
          number: item.individual.account.cardNumber,
          currentValue: accountBalancesAmounts[index],
          balanceId: item.individual.account.balances[0].id,
          status: item.individual.account.cardStatus,
          accountId: item.individual.account.id,
          userId: item.individual.userId
        };
      } else {
        return {
          name: item.business.name,
          type: 'BUSINESS' as 'INDIVIDUAL' | 'BUSINESS',
          number: item.business.cardNumber,
          currentValue: accountBalancesAmounts[index],
          balanceId: item.business.balances[0].id,
          status: item.business.status,
          accountId: item.business.id,
          userId: item.business.accessAccounts[0].id
        };
      }
    });
  }, [accountBalancesAmounts, isFetchingAccounts, paginatedAccounts]);

  return {
    selectedCardsMap,
    selectedCardsMapSize,
    currentStep,
    sortTable,
    cardsList:
      currentStep === ChargeCardsSteps.CHARGE_CARDS
        ? cardsList
        : cachedCardsListFilteredSortedAndPaginated?.[page - 1] ?? [],
    isLoading:
      isFetchingAccounts || isFetchingModifiedBalanceAllocationItemsFromCache,
    handleToggleSelectAllCards,
    handleChangeSortTable,
    areMapAndArrayEqual
  };
}
