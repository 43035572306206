import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${tokens.spacingXxl};
  padding: ${tokens.spacingLg};

  > svg {
    width: 20%;
    min-width: 25rem;
    max-width: 40rem;
  }
`;

export const Title = styled.strong`
  text-align: center;
  line-height: 3.2rem;
  letter-spacing: 0.1rem;
  font-size: ${tokens.fontSizeXs};
  padding-left: ${tokens.spacingNano};
  color: ${tokens.neutralColorLowDark};
  font-weight: ${tokens.fontWeightBold};
  font-family: ${tokens.fontFamilyPoppins};
`;

export const Actions = styled.div`
  min-width: 54rem;
  display: grid;
  gap: ${tokens.spacingLg};
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 560px) {
    width: 100%;
    display: flex;
    min-width: unset;
    flex-direction: column;
  }
`;
