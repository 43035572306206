import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue
} from 'react-hook-form';

import { type IDsSwitchProps } from './DsSwitch.types';

import {
  Container,
  ErrorMessage,
  Label,
  StyledSwitch,
  StyledThumb
} from './DsSwitch.styles';

export function DsSwitch<T extends FieldValues>({
  checked,
  onChange,
  label,
  name,
  defaultChecked = false,
  disabled = false,
  value,
  required = false,
  control,
  error
}: IDsSwitchProps<T>): JSX.Element {
  const inputToRender = (
    name: string,
    onChange?: (checked: boolean) => void,
    value?: boolean
  ): JSX.Element => {
    return (
      <Container>
        <Label htmlFor={name}>{label}</Label>
        <StyledSwitch
          checked={checked}
          onCheckedChange={onChange}
          disabled={disabled}
          defaultChecked={defaultChecked}
          id={name}
          value={value ? 'on' : 'off'}
          required={required}
        >
          <StyledThumb />
        </StyledSwitch>
      </Container>
    );
  };

  if (!control) {
    return inputToRender(name, onChange, value);
  }

  return (
    <div>
      <Controller
        name={name as Path<T>}
        control={control}
        defaultValue={defaultChecked as PathValue<T, Path<T>>}
        render={({ field: { name, onChange, value } }) =>
          inputToRender(name, onChange as (val: unknown) => void, value)
        }
      />

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
}
