import { DefaultButton, Modal, SecondaryButton } from 'ds';
import { Trans, useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useModalContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';

import { useConfirmAddUsersModal } from './useConfirmAddUsersModal';

import {
  StyledModalContent,
  StyledModalFooter
} from './ConfirmAddUsersModal.styles';

export function ConfirmAddUsersModal(): JSX.Element {
  const { handleOpenModal } = useModalContext();
  const [handleChangeStepOfGroupCreation, isInCardGroupDetails] =
    useCardGroupsContext(
      useShallow(state => [
        state.handleChangeStepOfGroupCreation,
        state.isInCardGroupDetails
      ])
    );

  const { t } = useTranslation(['global', 'cards']);

  const { handleAddCardsToGroup, isAddingCardsToGroup } =
    useConfirmAddUsersModal();

  return (
    <Modal.Container
      onClose={() => {
        if (isInCardGroupDetails) {
          handleOpenModal('addCardToGroup');
          return;
        }

        handleOpenModal('cardsGroupCreation');
      }}
      variant='small'
    >
      <Loader isLoading={isAddingCardsToGroup} />
      <Modal.Header>
        <Modal.Title>{t('cards:addCardInGroup')}</Modal.Title>

        <Modal.IconClose
          onClick={() => {
            if (isInCardGroupDetails) {
              handleOpenModal('addCardToGroup');
              return;
            }

            handleOpenModal('cardsGroupCreation');
          }}
        />
      </Modal.Header>

      <StyledModalContent>
        <span>
          <Trans
            i18nKey='deleteSolicitationsAutomatically'
            components={{ bold: <strong /> }}
          />
        </span>
      </StyledModalContent>

      <StyledModalFooter>
        <SecondaryButton
          size='small'
          onClick={() => {
            if (isInCardGroupDetails) {
              handleOpenModal('addCardToGroup');
              return;
            }

            handleOpenModal('cardsGroupCreation');
          }}
        >
          {t('global:cancel')}
        </SecondaryButton>

        <DefaultButton
          size='small'
          onClick={() => {
            if (isInCardGroupDetails) {
              handleAddCardsToGroup();
              return;
            }

            handleChangeStepOfGroupCreation('next');
            handleOpenModal('cardsGroupCreation');
          }}
        >
          {t('global:confirm')}
        </DefaultButton>
      </StyledModalFooter>
    </Modal.Container>
  );
}
