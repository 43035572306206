import { useQueryClient } from '@tanstack/react-query';

import { ExpensesQueryKeys } from 'data/modules/expenses/keys/expenses.keys';
import ExpensesService from 'data/modules/expenses/services/Expenses.service';
import {
  type IUseValidateDateLimitsByProject,
  type IValidateDateLimitsByProjectPayload,
  type IValidateDateLimitsByProjectResponse
} from 'data/modules/expenses/types/expenses.types';

export function useValidateDateLimitsByProject(): IUseValidateDateLimitsByProject {
  const queryClient = useQueryClient();

  async function getEnsuredValidateDateLimitsByProject(
    payload: IValidateDateLimitsByProjectPayload
  ): Promise<IValidateDateLimitsByProjectResponse | null> {
    try {
      const response = await queryClient.ensureQueryData({
        queryKey: [ExpensesQueryKeys.VALIDATE_DATE_LIMITS_BY_PROJECT, payload],
        queryFn: async () =>
          await ExpensesService.validateDateLimitsByProject(payload)
      });

      return response;
    } catch (e) {
      return null;
    }
  }

  return {
    getEnsuredValidateDateLimitsByProject
  };
}
