import {
  useLangContext,
  useModalContext,
  useValueSolicitationContext
} from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { ActionModal } from 'presentation/components/global/ActionModal';

import { Currency } from 'shared/utils/format';

import { useTransferFeedbackModal } from './useTransferFeedbackModal.component';

import { type ITransferFeedbackModalProps } from './TransferFeedbackModal.types';

import {
  CustomErrorIcon,
  CustomSuccessIcon,
  FailedTransferListContainer,
  ListItemContainer,
  MessageContainer,
  ModalMessage,
  Value
} from './TransferFeedbackModal.styles';

export function TransferFeedbackModal({
  balanceSolicitationApproved
}: ITransferFeedbackModalProps): JSX.Element {
  const {
    currentLangKey,
    lang: {
      global,
      cards: { valueSolicitation }
    }
  } = useLangContext();

  const step = useValueSolicitationContext(state => state.step);

  const { visibleModal } = useModalContext();

  const { handleConfirmFeedbackModal } = useTransferFeedbackModal();

  return (
    <>
      {visibleModal === 'valueSolicitationFeedback' && (
        <ActionModal
          title=''
          onClose={handleConfirmFeedbackModal}
          confirmLabel={
            balanceSolicitationApproved?.failure &&
            balanceSolicitationApproved?.failure.length > 0
              ? valueSolicitation.i_understood[currentLangKey]
              : global.confirm[currentLangKey]
          }
          onConfirm={handleConfirmFeedbackModal}
        >
          <MessageContainer>
            {balanceSolicitationApproved?.failure &&
            balanceSolicitationApproved?.failure.length > 0 ? (
              <>
                <CustomErrorIcon>
                  <BaseIcon
                    size={6}
                    name='close-circle'
                    color='feedbackColorErrorLight'
                  />
                </CustomErrorIcon>
                <ModalMessage>
                  {
                    valueSolicitation.failed_transfer_message_part_a[
                      currentLangKey
                    ]
                  }
                  <br />
                  {
                    valueSolicitation.failed_transfer_message_part_b[
                      currentLangKey
                    ]
                  }
                </ModalMessage>
              </>
            ) : (
              <>
                <CustomSuccessIcon>
                  <BaseIcon
                    size={6}
                    name='checkbox-circle'
                    color='feedbackColorSuccessPure'
                  />
                </CustomSuccessIcon>
                <ModalMessage>
                  {step === 'approve' || step === 'transfer'
                    ? valueSolicitation.success_transfer_message[currentLangKey]
                    : valueSolicitation.dissaprove_transfer_message[
                        currentLangKey
                      ]}
                </ModalMessage>
              </>
            )}
          </MessageContainer>
          {balanceSolicitationApproved?.failure && (
            <FailedTransferListContainer>
              {balanceSolicitationApproved?.failure.map(solicitation => {
                return (
                  <ListItemContainer key={solicitation.id}>
                    <ModalMessage>{solicitation.userName}</ModalMessage>
                    {solicitation?.amount && (
                      <Value>
                        {Currency.format('BRL', solicitation.amount, true)}
                      </Value>
                    )}
                  </ListItemContainer>
                );
              })}
            </FailedTransferListContainer>
          )}
        </ActionModal>
      )}
    </>
  );
}
