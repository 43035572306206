import { type IDefaultTableActionsCellProps } from './DefaultTableActionsCell.types';

import { Container } from './DefaultTableActionsCell.styles';

export function DefaultTableActionsCell({
  children,
  ...rest
}: IDefaultTableActionsCellProps): JSX.Element {
  return (
    <Container {...rest}>
      <div>{children}</div>
    </Container>
  );
}
