import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IRequestBalanceFieldsForm } from 'data/modules/cards/balance/types/balance.types';

import { Currency } from 'shared/utils/format';

export function useRequestBalanceSchema(): ObjectSchema<IRequestBalanceFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const requestBalanceSchema: ObjectSchema<IRequestBalanceFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object({
        currency: yup.string().required(fieldRequiredMessage),
        justify: yup.string().required(fieldRequiredMessage),
        amount: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'amount',
            message: lang.schemas.positive_number[currentLangKey],
            test: value => {
              return Currency.parseToFloat(value) > 0;
            }
          }),
        datePrevision: yup
          .date()
          .nullable()
          .test({
            name: 'isNotOldDate',
            message: lang.schemas.date_must_not_be_before_today[currentLangKey],
            test: date => {
              if (!date) return true;

              const today = new Date();
              today.setHours(0, 0, 0, 0);
              return date >= today;
            }
          })
      });
    }, [lang, currentLangKey]);

  return requestBalanceSchema;
}
