import { Modal } from 'ds';
import styled from 'styled-components';

export const ModalContent = styled(Modal.Content)`
  min-height: 60vh;
`;

export const ModalTitle = styled(Modal.Title)`
  text-transform: capitalize;
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
