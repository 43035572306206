import { useState } from 'react';

import { useModalContext } from 'data/contexts';

import { type IUseActivateCardModal } from './ActivateCardModal.types';

export function useActivateCardModal(): IUseActivateCardModal {
  const [step, setStep] = useState(1);
  const { handleCloseModal } = useModalContext();

  function handleCloseModalOnClick(): void {
    setStep(1);
    handleCloseModal();
  }

  function handleNextOrFinishButton(): void {
    if (step === 4) {
      handleCloseModal();
      return;
    }

    setStep(step + 1);
  }

  return {
    setStep,
    step,
    handleNextOrFinishButton,
    handleCloseModalOnClick
  };
}
