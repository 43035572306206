import {
  type IPersistenceRemoveRelatedCostsCenters,
  type IRemoveRelatedCostsCentersPayload
} from 'data/modules/cards/businessCard';

class RemoveRelatedCostsCentersMapper {
  toPersistence(
    domain: IRemoveRelatedCostsCentersPayload
  ): IPersistenceRemoveRelatedCostsCenters {
    return {
      costs_centers: domain.costsCenters
    };
  }
}

export default new RemoveRelatedCostsCentersMapper();
