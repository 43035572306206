import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import { DsRadioButton, DsRadioGroup } from 'presentation/ds/DsRadio';
import {
  OnboardingFooter,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { Onboarding } from 'shared/utils/cards';

import { useNaturalPersonOrLegalEntity } from './useNaturalPersonOrLegalEntity';

import {
  Container,
  Content,
  ErrorMessage
} from './NaturalPersonOrLegalEntity.styles';

export function NaturalPersonOrLegalEntity(): JSX.Element {
  const navigate = useNavigate();

  const [addPartner, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.company.add_partner,
    state.currentLangKey
  ]);

  const { handleNavigateNextStep, error, handleEntityTypeChange } =
    useNaturalPersonOrLegalEntity();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  return (
    <Container>
      <OnboardingTitle>
        {addPartner.natural_person_or_legal_entity.title[currentLangKey]}
      </OnboardingTitle>
      <Content>
        <DsRadioGroup
          justify='center'
          onChange={handleEntityTypeChange}
          direction='column'
        >
          <DsRadioButton
            value={'natural_person'}
            label={
              addPartner.natural_person_or_legal_entity.options.natural_person[
                currentLangKey
              ]
            }
          />
          <DsRadioButton
            value={'legal_entity'}
            label={
              addPartner.natural_person_or_legal_entity.options.legal_entity[
                currentLangKey
              ]
            }
          />
        </DsRadioGroup>

        {error.length > 0 && <ErrorMessage>{error}</ErrorMessage>}
      </Content>
      <OnboardingFooter
        continueButtonProps={{
          onClick: handleNavigateNextStep
        }}
        backButtonProps={{
          onClick: () => {
            navigate(`/cartoes/onboarding/empresa/${currentStep}`);
          }
        }}
      />
    </Container>
  );
}
