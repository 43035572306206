import { format } from 'date-fns';
import { Checkbox, DefaultTable, DSIcons, Tooltip } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useValueSolicitationContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';
import { SolicitationBadgeStatus } from 'presentation/pages/cards/Management/ValueSolicitation/components/SolicitationBadgeStatus/SolicitationBadgeStatus.component';

import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

import { useValueSolicitationTableRow } from './useValueSolicitationTableRow';

import { type IValueSolicitationTableRowProps } from './ValueSolicitationTableRow.types';

import {
  DetailsButton,
  JustifyRowContent,
  UserNameRowContent
} from './ValueSolicitationTableRow.styles';

export function ValueSolicitationTableRow({
  solicitation,
  ...rest
}: IValueSolicitationTableRowProps): JSX.Element {
  const [
    listType,
    selectedSolicitationIdsList,
    handleToggleSelectSolicitationId
  ] = useValueSolicitationContext(
    useShallow(state => [
      state.listType,
      state.selectedSolicitationIdsList,
      state.handleToggleSelectSolicitationId
    ])
  );

  const { handleLoadDataFromClick, isLoadingCardData } =
    useValueSolicitationTableRow({ solicitation });

  return (
    <>
      <Loader isLoading={isLoadingCardData} />

      <DefaultTable.Tr {...rest}>
        {listType === 'ALL' || listType === 'SENT' ? (
          <DefaultTable.Td>
            <Checkbox.Default
              disabled={solicitation.status !== 'SENT'}
              value={selectedSolicitationIdsList.some(
                valueSolicitation => valueSolicitation.id === solicitation.id
              )}
              onChangeValue={() => {
                handleToggleSelectSolicitationId({
                  ...solicitation,
                  transferAmount: solicitation.requestedAmount
                });
              }}
            />
          </DefaultTable.Td>
        ) : null}

        <DefaultTable.Td>
          <UserNameRowContent title={solicitation.userName}>
            {solicitation.userName}
          </UserNameRowContent>
        </DefaultTable.Td>
        <DefaultTable.Td>
          {CustomDate.formatFromISO(solicitation.dateSent)}
        </DefaultTable.Td>
        <DefaultTable.Td>
          {solicitation.datePrevision
            ? format(
                new Date(solicitation.datePrevision + 'T00:00:00'),
                'dd/MM/yyyy'
              )
            : '-'}
        </DefaultTable.Td>
        <DefaultTable.Td>
          {Currency.format('BRL', solicitation.requestedAmount, true)}
        </DefaultTable.Td>

        <DefaultTable.Td>
          {solicitation.status === 'REJECTED' ? (
            solicitation.managerJustify ? (
              <Tooltip
                containerClassName='justify-tooltip'
                message={solicitation.managerJustify}
              >
                <JustifyRowContent>
                  {solicitation.managerJustify}
                </JustifyRowContent>
              </Tooltip>
            ) : (
              <JustifyRowContent>
                {solicitation.managerJustify ?? '-'}
              </JustifyRowContent>
            )
          ) : (
            <Tooltip
              message={solicitation.justify}
              containerClassName='justify-tooltip'
            >
              <JustifyRowContent>{solicitation.justify}</JustifyRowContent>
            </Tooltip>
          )}
        </DefaultTable.Td>

        <DefaultTable.Td>
          <SolicitationBadgeStatus status={solicitation.status} />
        </DefaultTable.Td>

        <DefaultTable.Td>
          {solicitation.status === 'APPROVED'
            ? Currency.format(
                'BRL',
                Number(solicitation.transferredAmount),
                true
              )
            : '-'}
        </DefaultTable.Td>

        <DefaultTable.Td
          onClick={() => {
            handleLoadDataFromClick(true);
          }}
        >
          <DetailsButton>
            <DSIcons.ArrowRightIcon color='neutralColorLowDark' />
          </DetailsButton>
        </DefaultTable.Td>
      </DefaultTable.Tr>
    </>
  );
}
