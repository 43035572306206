import { type ISummaryCardProps } from './SummaryCard.types';

import { Container, Title, Value } from './SummaryCard.styles';

export function SummaryCard({
  variant = 'default',
  title,
  value,
  disabled = false,
  ...rest
}: ISummaryCardProps): JSX.Element {
  return (
    <Container {...rest}>
      <Title $disabled={disabled}>{title}</Title>

      <Value
        $variant={variant}
        $disabled={disabled}
      >
        {value}
      </Value>
    </Container>
  );
}
