import { CardInfo, tokens, Tooltip } from 'ds';

import { SkeletonLoader } from 'presentation/components/global/Loader';

import { Icons } from 'shared/constants/global';

import { type IAnalysisSummaryCardProps } from './AnalysisSummaryCard.types';

import {
  AnalysisSummaryTitle,
  CardsInfoContent,
  Container,
  IconWrapper,
  SkeletonLoaderContainer
} from './AnalysisSummaryCard.styles';

export function AnalysisSummaryCard({
  title,
  cards,
  children,
  isLoadingCards,
  ...props
}: IAnalysisSummaryCardProps): JSX.Element {
  return (
    <Container {...props}>
      <AnalysisSummaryTitle>{title}</AnalysisSummaryTitle>

      <CardsInfoContent>
        {isLoadingCards ? (
          <SkeletonLoaderContainer>
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
          </SkeletonLoaderContainer>
        ) : (
          cards.map(card => (
            <CardInfo.Container key={card.title}>
              <CardInfo.Title variant='small'>{card.title}</CardInfo.Title>
              <CardInfo.ValueContainer>
                {card.showLeftIcon && (
                  <IconWrapper>
                    <Icons.CashBanknoteWarningIcon />
                  </IconWrapper>
                )}
                <CardInfo.Value>{card.value}</CardInfo.Value>
                {card.tooltip && (
                  <Tooltip
                    message={card.tooltip}
                    placement='top'
                    containerClassName='analysis-summary-card-tooltip'
                  >
                    <div>
                      <Icons.InfoIcon color={tokens.neutralColorLowLight} />
                    </div>
                  </Tooltip>
                )}
              </CardInfo.ValueContainer>
              <CardInfo.Caption variant='small'>
                {card.caption}
              </CardInfo.Caption>
            </CardInfo.Container>
          ))
        )}
      </CardsInfoContent>

      {children}
    </Container>
  );
}
