import { useLangContext } from 'data/contexts';

import { type IMovementFilterProps } from './MovementFilter.types';

import { Container, MovementButton } from './MovementFilter.styles';

export function MovementFilter({
  selected,
  onChange
}: IMovementFilterProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  return (
    <Container
      role='radiogroup'
      aria-labelledby='filter-by-movement'
    >
      <p id='filter-by-movement'>
        {lang.cards.statement.sidebar.filter_by_movement[currentLangKey]}
      </p>
      <MovementButton
        role='radio'
        aria-checked={selected === 'all'}
        type='button'
        $selected={selected === 'all'}
        onClick={() => {
          onChange('all');
        }}
      >
        {lang.cards.statement.sidebar.movements.all[currentLangKey]}
      </MovementButton>
      <MovementButton
        role='radio'
        aria-checked={selected === 'entries'}
        type='button'
        $selected={selected === 'entries'}
        onClick={() => {
          onChange('entries');
        }}
      >
        {lang.cards.statement.sidebar.movements.entries[currentLangKey]}
      </MovementButton>
      <MovementButton
        role='radio'
        aria-checked={selected === 'exits'}
        type='button'
        $selected={selected === 'exits'}
        onClick={() => {
          onChange('exits');
        }}
      >
        {lang.cards.statement.sidebar.movements.exits[currentLangKey]}
      </MovementButton>
    </Container>
  );
}
