import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  useAuthContext,
  useCardGroupsContext,
  useFlagsContext
} from 'data/contexts';

import { FallbackPage } from 'presentation/pages/global';

export function CardGroupsMiddleware(): JSX.Element {
  const location = useLocation();

  const cardGroupsFlag = useFlagsContext(state => state.cardGroupsFlag);

  const user = useAuthContext(state => state.user);

  const [handleSetHasCardGroups, handleSetGroupCreationStep] =
    useCardGroupsContext(
      useShallow(state => [
        state.handleSetHasCardGroups,
        state.handleSetGroupCreationStep
      ])
    );

  if (
    !cardGroupsFlag &&
    (location.pathname.includes('gestao-de-grupos') ||
      location.pathname.includes('grupos-de-cartoes'))
  ) {
    return <FallbackPage />;
  }

  if (user?.cards?.isCardGroupManager && cardGroupsFlag) {
    handleSetHasCardGroups(true);
    handleSetGroupCreationStep(4);

    <Navigate to='/cartoes/grupos-de-cartoes' />;

    return <Outlet />;
  }

  return <Outlet />;
}
