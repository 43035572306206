import { api, env } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import {
  exportsMovementsInExcelMock,
  exportsMovementsInPdfMock
} from 'data/mocks/cards/movements';
import {
  type IExportMovementsInExcel,
  type IExportMovementsInExcelPayload,
  type IExportMovementsInPdf,
  type IExportMovementsInPdfPayload
} from 'data/modules/cards/movements';
import { type IDefaultResponse } from 'data/modules/global';

import {
  ExportMovementsInExcelMapper,
  ExportMovementsInPdfMapper
} from './mappers';

class MovementsService {
  async exportMovementsInPdf(
    payload: IExportMovementsInPdfPayload
  ): Promise<IExportMovementsInPdf> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await exportsMovementsInPdfMock();
    }

    const response = await api.post<IDefaultResponse<IExportMovementsInPdf>>(
      '/pay/company/statement/pdf',
      ExportMovementsInPdfMapper.toPersistence(payload)
    );

    const {
      data: { url }
    } = ResponseAdapter.formatRegularResponse(response.data);

    return {
      url
    };
  }

  async exportMovementsInExcel(
    payload: IExportMovementsInExcelPayload
  ): Promise<IExportMovementsInExcel> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await exportsMovementsInExcelMock();
    }

    const response = await api.post<IDefaultResponse<IExportMovementsInExcel>>(
      '/pay/company/statement/excel',
      ExportMovementsInExcelMapper.toPersistence(payload)
    );

    const {
      data: { url }
    } = ResponseAdapter.formatRegularResponse(response.data);

    return {
      url
    };
  }
}

export default new MovementsService();
