export const businessTypes = [
  'CNAE',
  'EI',
  'EIRELI',
  'EPP',
  'ME',
  'MEI',
  'LTDA',
  'SA Aberta',
  'SA Fechada',
  'ONG',
  'Associação Privada'
] as const;
