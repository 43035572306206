import { api } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import { type IDefaultResponse } from 'data/modules/global';
import {
  type IPasswordPolicy,
  type IPersistencePasswordPolicy
} from 'data/modules/settings/passwordPolicy';
import { GetPasswordPolicyMapper } from 'data/modules/settings/passwordPolicy/services/mappers';

class PasswordPolicyService {
  async getPasswordPolicy(): Promise<IPasswordPolicy> {
    const response = await api.get<
      IDefaultResponse<IPersistencePasswordPolicy>
    >('/settings/company/password-policy');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetPasswordPolicyMapper.toDomain(data);
  }
}

export default new PasswordPolicyService();
