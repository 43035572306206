import React from 'react';

import { Accordion } from 'ds';

import { useLangContext } from 'data/contexts';

import {
  ProductBanner,
  ProductFaqSection,
  ProductSubtitle
} from 'presentation/pages/store/Product/components/General';

import banner1 from 'shared/assets/images/store/outsourcing/outsourcing-banner-1.png';
import banner2 from 'shared/assets/images/store/outsourcing/outsourcing-banner-2.png';
import banner3 from 'shared/assets/images/store/outsourcing/outsourcing-banner-3.png';

export function Outsourcing(): JSX.Element {
  const {
    currentLangKey,
    lang: {
      store: {
        product: { outsourcing }
      }
    }
  } = useLangContext();

  return (
    <React.Fragment>
      <ProductBanner
        banner={banner1}
        title={outsourcing.banners.first.title[currentLangKey]}
        buttonText={outsourcing.banners.first.button[currentLangKey]}
        description={outsourcing.banners.first.description[currentLangKey]}
      />

      <ProductBanner
        reverse
        banner={banner2}
        title={outsourcing.banners.second.title[currentLangKey]}
        buttonText={outsourcing.banners.second.button[currentLangKey]}
        description={outsourcing.banners.second.description[currentLangKey]}
      />

      <ProductBanner
        banner={banner3}
        title={outsourcing.banners.third.title[currentLangKey]}
        buttonText={outsourcing.banners.third.button[currentLangKey]}
        description={outsourcing.banners.third.description[currentLangKey]}
      />

      <ProductFaqSection>
        <ProductSubtitle>
          {outsourcing.faq_subtitle[currentLangKey]}
        </ProductSubtitle>

        <Accordion.Container multiple>
          {Object.entries(outsourcing.faq).map(([key, question]) => (
            <Accordion.Item key={key}>
              <Accordion.Label>
                {question.label[currentLangKey]}
              </Accordion.Label>

              <Accordion.Caption>
                {question.caption[currentLangKey]}
              </Accordion.Caption>
            </Accordion.Item>
          ))}
        </Accordion.Container>
      </ProductFaqSection>
    </React.Fragment>
  );
}
