class FreddySurvey {
  addScriptToDocumentHead(surveyId: string): void {
    const tagScriptId = 'ffWidgetScriptSurvey';
    const tagScriptIdThatInsertScript = 'ffWidgetScriptSurveyInsertScript';

    const tagScript = document.getElementById(tagScriptId);

    // remove o script antigo caso exista
    if (tagScript !== null) {
      (tagScript.parentNode as ParentNode).removeChild(tagScript);
    }

    const tagScriptThatInsertScript = document.getElementById(
      tagScriptIdThatInsertScript
    );

    // remove o script antigo caso exista
    if (tagScriptThatInsertScript !== null) {
      (tagScriptThatInsertScript.parentNode as ParentNode).removeChild(
        tagScriptThatInsertScript
      );
    }

    // insere o script que insere o script do freddyfeedback
    const scriptString = `
      var ffWidgetId = '${surveyId}';
      var ffWidgetScript = document.createElement('script');
      ffWidgetScript.type = 'text/javascript';
      ffWidgetScript.id = '${tagScriptId}';
      ffWidgetScript.src = 'https://freddyfeedback.com/widget/freddyfeedback.js';
      document.head.appendChild(ffWidgetScript);
    `;
    const scriptElement = document.createElement('script');
    scriptElement.text = scriptString;
    scriptElement.id = tagScriptIdThatInsertScript;

    document.head.appendChild(scriptElement);
  }

  show(): void {
    const freddyWidget = (
      window as unknown as {
        freddyWidget: {
          show: () => void;
        };
      }
    ).freddyWidget;
    if (freddyWidget) {
      freddyWidget.show();
    }
  }
}

export default new FreddySurvey();
