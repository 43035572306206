import { tokens } from 'ds';
import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type BannerStyleType } from './Banner.types';

export const Container = styled.div<AddPrefixToType<BannerStyleType>>`
  height: 17rem;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: ${tokens.spacingXs};
  border-radius: ${tokens.borderRadiusMd};
  background: ${({ $background }) =>
    `url(${$background}) no-repeat center right;`};
  background-size: cover;
  background-color: ${tokens.brandColorSecondaryPure};
`;

export const BannerText = styled.span`
  z-index: 1;
  line-height: 150%;
  text-align: center;
  font-size: ${tokens.fontSizeSm};
  color: ${tokens.neutralColorHighPure};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightSemibold};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: ${tokens.fontSizeXs};
  }
`;

export const BannerLogo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  background-size: contain;
`;
