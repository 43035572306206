import { useMutationCache } from 'data/cache';
import {
  GlobalMutationKeys,
  GlobalService,
  type ILogNotTreatedErrorPayload,
  type IUseLogNotTreatedError
} from 'data/modules/global';

export function useLogNotTreatedError(): IUseLogNotTreatedError {
  const { mutate } = useMutationCache({
    mutationKey: [GlobalMutationKeys.LOG_NOT_TREATED_ERROR],
    mutationFn: async (payload: ILogNotTreatedErrorPayload) => {
      await GlobalService.logNotTreatedError(payload);
    }
  });

  return { logNotTreatedError: mutate };
}
