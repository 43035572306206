import { useShallow } from 'zustand/react/shallow';

import { useTeamMembersContext } from 'data/contexts';

import { type TeamMemberOrderByType } from 'presentation/pages/settings/companyData/TeamMembers/TeamMembers.types';

import { useGetSearchParams } from 'shared/hooks/global/useGetSearchParams/useGetSearchParams';

import {
  type IUseTeamMembersTable,
  type IUseTeamMembersTableParams
} from './TeamMembersTable.types';
import { type IDefaultTableHeadCellProps } from 'ds/components/Table/DefaultTable/DefaultTableHeadCell/DefaultTableHeadCell.types';

export function useTeamMembersTable({
  filters,
  onSortTeamMembers
}: IUseTeamMembersTableParams): IUseTeamMembersTable {
  const [perPage, totalPage, handleChangePagination] = useTeamMembersContext(
    useShallow(state => [
      state.perPage,
      state.totalPage,
      state.handleChangePagination
    ])
  );

  const {
    setSearchParams,
    searchParams: { page }
  } = useGetSearchParams<{ page: number }>({
    values: ['page']
  });

  const currentPage = !page || isNaN(Number(page)) ? 1 : Number(page);

  function getThProps(key: TeamMemberOrderByType): IDefaultTableHeadCellProps {
    return {
      order: filters.order,
      isActive: filters.orderBy === key,
      onClick: () => {
        onSortTeamMembers(key);
      }
    };
  }

  function handleChangePage(page: number): void {
    setSearchParams({
      page
    });
  }

  function handleChangePerPage(perPage: number): void {
    setSearchParams({
      page: 1
    });

    handleChangePagination({ perPage });
  }

  return {
    perPage,
    getThProps,
    currentPage,
    handleChangePage,
    handleChangePerPage,
    totalPage: Math.max(totalPage, 1)
  };
}
