import { api, env } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import {
  attachApprovalFlowsAndExpensePoliciesMock,
  attachPasswordOnCardMock,
  attachTeamMembersToBusinessCardMock,
  attachTheCardMock,
  attachUserMock,
  changeVirtualMembersMock,
  createSharedAccountMock,
  createTeamMembersVirtualMock,
  getVirtualMembersMock,
  removeRelatedCostsCentersMock,
  removeRelatedProjectMock,
  removeRelatedTeamMembersMock,
  validatedPinCardMock
} from 'data/mocks/cards/businessCards';
import { type IPersistenceCompanyAccount } from 'data/modules/cards/account';
import { type IPersistenceAccountHolders } from 'data/modules/cards/accountHolders';
import {
  type IAttachApprovalFlowsAndExpensePoliciesPayload,
  type IAttachPasswordOnCardPayload,
  type IAttachTeamMembersToBusinessCardPayload,
  type IAttachTheCardPayload,
  type IAttachUserPayload,
  type IChangeVirtualMembersPayload,
  type ICreateSharedAccount,
  type ICreateSharedAccountPayload,
  type ICreateTeamMembersVirtual,
  type ICreateTeamMembersVirtualPayload,
  type IGetVirtualMembersPayload,
  type IPersistenceValidatedPinCard,
  type IRemoveRelatedCostsCentersPayload,
  type IRemoveRelatedProjectPayload,
  type IRemoveRelatedTeamMembersPayload,
  type IResponseAttachUser,
  type IResponseTeamMembersVirtual,
  type ITeamMembersVirtual,
  type IValidatedPinCard,
  type IValidatedPinCardPayload,
  type IVirtualMembers
} from 'data/modules/cards/businessCard';
import { type IDefaultResponse } from 'data/modules/global';
import { type ITeamMemberItem } from 'data/modules/teamMembers';

import {
  ActivateCardMapper,
  AttachApprovalFlowsAndExpensePoliciesMapper,
  AttachTheCardMapper,
  GetVirtualMembersMapper,
  RemoveRelatedCostsCentersMapper,
  SharedAccountMapper,
  TeamMembersVirtualMapper
} from './mappers';

class BusinessCardsService {
  async createSharedAccount(
    payload: ICreateSharedAccountPayload
  ): Promise<ICreateSharedAccount> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await createSharedAccountMock();
    }

    const response = await api.post<IDefaultResponse<ICreateSharedAccount>>(
      '/pay/shared-accounts',
      SharedAccountMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return { id: data.id };
  }

  async createTeamMembersVirtual(
    payload: ICreateTeamMembersVirtualPayload
  ): Promise<ICreateTeamMembersVirtual> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await createTeamMembersVirtualMock();
    }

    const response = await api.post<IDefaultResponse<ITeamMembersVirtual>>(
      '/team-members/virtual',
      TeamMembersVirtualMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return { id: data.uuid };
  }

  async attachUser(payload: IAttachUserPayload): Promise<IResponseAttachUser> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await attachUserMock();
    }

    await api.post<IDefaultResponse<IPersistenceCompanyAccount>>(
      `/pay/shared-accounts/${payload.uuidSharedAccounts}/user/attach`,
      payload.userAttach
    );

    return {
      uuid: payload.uuidBusinessCard,
      nameCard: payload.nameCard
    };
  }

  async attachApprovalFlowsAndExpensePolicies(
    payload: IAttachApprovalFlowsAndExpensePoliciesPayload
  ): Promise<IResponseTeamMembersVirtual> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await attachApprovalFlowsAndExpensePoliciesMock();
    }

    const response = await api.patch<
      IDefaultResponse<IResponseTeamMembersVirtual>
    >(
      `/team-members/virtual/${payload.uuid}`,
      AttachApprovalFlowsAndExpensePoliciesMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async validatedPinCard(
    payload: IValidatedPinCardPayload
  ): Promise<IValidatedPinCard> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await validatedPinCardMock();
    }

    const response = await api.post<
      IDefaultResponse<IPersistenceValidatedPinCard>
    >(`/pay/cards/pin-validate`, {
      digits: payload.digits
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return ActivateCardMapper.toPersistence(data);
  }

  async attachTheCard(payload: IAttachTheCardPayload): Promise<null> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await attachTheCardMock();
    }

    const response = await api.patch<IDefaultResponse<null>>(
      `/pay/accounts/${payload.uuid}/card/link`,
      AttachTheCardMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async attachPasswordOnCard(
    payload: IAttachPasswordOnCardPayload
  ): Promise<null> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await attachPasswordOnCardMock();
    }

    const response = await api.patch<IDefaultResponse<null>>(
      `/pay/accounts/${payload.uuid}/card/pin`,
      { digits: payload.digits }
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async attachTeamMembersToBusinessCard(
    payload: IAttachTeamMembersToBusinessCardPayload
  ): Promise<ITeamMemberItem[]> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await attachTeamMembersToBusinessCardMock();
    }

    const response = await api.patch<IDefaultResponse<ITeamMemberItem[]>>(
      '/outsourcing',
      payload
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getVirtualMembers(
    payload: IGetVirtualMembersPayload
  ): Promise<IVirtualMembers> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getVirtualMembersMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceAccountHolders>
    >(`/team-members/virtual/${payload.uuid}`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetVirtualMembersMapper.toPersistence(data);
  }

  async changeVirtualMembers(
    payload: IChangeVirtualMembersPayload
  ): Promise<null> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await changeVirtualMembersMock();
    }

    const response = await api.patch<IDefaultResponse<null>>(
      `/team-members/virtual/${payload.uuid}`,
      payload.virtualMembers
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async removeRelatedProject(
    payload: IRemoveRelatedProjectPayload
  ): Promise<boolean> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await removeRelatedProjectMock();
    }

    const response = await api.delete<IDefaultResponse<null>>(
      `/team-members/${payload.uuid}/projects`,
      { data: { projects: payload.projects } }
    );

    const data = ResponseAdapter.formatRegularResponse(response.data);

    const { success } = data;

    return success;
  }

  async removeRelatedCostsCenters(
    payload: IRemoveRelatedCostsCentersPayload
  ): Promise<boolean> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await removeRelatedCostsCentersMock();
    }

    const response = await api.delete<IDefaultResponse<null>>(
      `/team-members/${payload.uuid}/costs-centers`,
      {
        data: RemoveRelatedCostsCentersMapper.toPersistence(payload)
      }
    );

    const data = ResponseAdapter.formatRegularResponse(response.data);

    const { success } = data;

    return success;
  }

  async removeRelatedTeamMembers(
    payload: IRemoveRelatedTeamMembersPayload
  ): Promise<boolean> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await removeRelatedTeamMembersMock();
    }

    const response = await api.delete<IDefaultResponse<null>>(
      `/team-members/${payload.uuid}/outsourcers/${payload.uuidMember}`
    );

    const data = ResponseAdapter.formatRegularResponse(response.data);

    const { success } = data;

    return success;
  }
}

export default new BusinessCardsService();
