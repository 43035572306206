import { type BaseSyntheticEvent } from 'react';

import { useNavigate } from 'react-router-dom';

import { useLangContext, useModalContext } from 'data/contexts';
import {
  StoreQueryKeys,
  StoreService,
  useRequestProduct
} from 'data/modules/store';

import { type StoreIdentifierToGaEventType } from 'presentation/pages/store/Store/Store.types';

import { useGa4, useHandleQueryCache } from 'shared/hooks/global';

import {
  type IUseRequestModal,
  type IUseRequestModalParams
} from './RequestModal.types';

export function useRequestModal({
  identifier
}: IUseRequestModalParams): IUseRequestModal {
  const { sendGaEvent } = useGa4();
  const navigate = useNavigate();

  const {
    methods,
    handleRequestProduct: handleRequest,
    isRequestingProduct
  } = useRequestProduct({
    identifier,
    onAfterRequestProductSuccess: handleRequestProductSuccess
  });

  const { updateCache, getEnsuredCache } = useHandleQueryCache({
    query: {
      key: [StoreQueryKeys.GET_STORE_PRODUCTS],
      query: async () => await StoreService.getStoreProducts()
    }
  });

  const {
    currentLangKey,
    lang: {
      global,
      store: { productCards }
    }
  } = useLangContext();

  const { handleCloseModal } = useModalContext();

  function getTitle(): string {
    try {
      return `${global.request[currentLangKey]} ${productCards.name[identifier][currentLangKey]}`;
    } catch {
      return '';
    }
  }

  function handleRequestProduct(e: BaseSyntheticEvent): void {
    sendGaEvent('store', '-storeModal_Solicitar');
    handleRequest(e);
  }

  function handleSendSelectChangeValueGaEvent(
    input: Parameters<IUseRequestModal['handleSendSelectChangeValueGaEvent']>[0]
  ): ReturnType<IUseRequestModal['handleSendSelectChangeValueGaEvent']> {
    return (value: string): void => {
      sendGaEvent(
        'store',
        input === 'cardsQtt'
          ? 'storeModal_solicitarCartoesQuantidade'
          : 'storeModal_tipoDeUso',
        {
          eventValue: value
        }
      );
    };
  }

  function handleSendGaEvent(
    input: Parameters<IUseRequestModal['handleSendGaEvent']>[0]
  ): ReturnType<IUseRequestModal['handleSendGaEvent']> {
    return (): void => {
      const events: Record<typeof input, StoreIdentifierToGaEventType> = {
        email: {
          horus: 'storeModal_solicitarHorusEmail',
          'active-directory': 'storeModal_solicitarADEmail',
          cards: 'storeModal_solicitarCartoesEmail',
          conciliation: 'storeModal_solicitarConciliacaoEmail',
          outsourcing: 'storeModal_solicitarTerceirizacaoEmail',
          'cards-pj': 'storeModal_solicitarCartoesEmail'
        },
        phone: {
          horus: 'storeModal_solicitarHorusTelefone',
          'active-directory': 'storeModal_solicitarADTelefone',
          cards: 'storeModal_solicitarCartoesEmail',
          conciliation: 'storeModal_solicitarConciliacaoTelefone',
          outsourcing: 'storeModal_solicitarTerceirizacaoTelefone',
          'cards-pj': 'storeModal_solicitarCartoesTelefone'
        }
      };

      sendGaEvent('store', events[input][identifier]);
    };
  }

  async function handleRequestProductSuccess(): Promise<void> {
    const products = await getEnsuredCache();

    updateCache({
      newData: products.map(product => ({
        ...product,
        status: product.identifier === identifier ? 'requested' : product.status
      }))
    });

    handleCloseModal();

    navigate(`/store/${identifier}`);
  }

  return {
    methods,
    title: getTitle(),
    handleSendGaEvent,
    isRequestingProduct,
    handleRequestProduct,
    handleSendSelectChangeValueGaEvent
  };
}
