import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetXs};
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: fit-content;
  }
`;
