import { DSIcons } from 'ds/icons';

import { type ICardInfoRefreshButtonProps } from './CardInfoRefreshButton.types';

import { Container } from './CardInfoRefreshButton.styles';

export function CardInfoRefreshButton({
  children,
  ...rest
}: ICardInfoRefreshButtonProps): JSX.Element {
  return (
    <Container {...rest}>
      <DSIcons.ArrowGoBackIcon />
      {children}
    </Container>
  );
}
