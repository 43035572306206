import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  height: calc(100% - 5rem);
  padding: 2rem 3rem;
  overflow: auto;
  background: ${({ theme }) => theme.colors.alabaster};
  border: 1px solid ${({ theme }) => theme.colors.mercury};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  > button {
    max-width: 11rem;
  }
`;

export const NotFoundMessage = styled.p`
  font-size: 2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.doveGray};
`;
