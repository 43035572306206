import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0 0.5rem;

  > div:nth-child(2) {
    margin-top: 1rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-wrap: nowrap;

    > div:nth-child(2) {
      margin-top: 0;
      margin-left: -1.2rem;
    }
  }
`;

export const ValuesWrapper = styled.div`
  > div {
    width: 15rem;
    padding: 0.6rem 0.8rem;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 1px 1px ${({ theme }) => theme.colors.gallery};
    border-radius: 0 0 5px 0.5px;
    text-align: center;
    display: flex;
    flex-direction: column;

    + div {
      margin-top: 0.5rem;
    }

    > p {
      margin-top: 0.5rem;
      font-size: 1.2rem;
      color: ${({ theme }) => theme.colors.emperor};
    }

    > span {
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.neutral};

      margin-top: 0.5rem;
    }
  }
`;

export const Value = styled.div`
  margin: 0.3rem 0;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.mineShaft};

  > label {
    margin-right: 0.3rem;
    font-size: 1rem;
  }
`;
