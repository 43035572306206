import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type CompanyOnboardingShareholderType,
  type ICompanyAccount,
  type IUseUpdateShareholders,
  type IUseUpdateShareholdersParams,
  type UpdateCompanyOnboardingAnswersPayloadType
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

export function useUpdateShareholders({
  onUpdateCompanyOnboardingAnswersMutationSuccess
}: IUseUpdateShareholdersParams): IUseUpdateShareholders {
  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const { isLoading: isUpdatingShareholders, mutate: updateShareholders } =
    useMutationCache({
      mutationKey: [
        CompanyOnboardingMutationKeys.UPDATE_COMPANY_ONBOARDING_ANSWERS
      ],
      mutationFn: async (
        payload: UpdateCompanyOnboardingAnswersPayloadType
      ) => {
        await CompanyOnboardingService.updateCompanyOnboardingAnswers(payload);
      },
      onSuccess: (data, { answers }) => {
        onUpdateCompanyOnboardingAnswersMutationSuccess(
          answers as ICompanyAccount
        );
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  function handleUpdateShareholders(
    shareholders: CompanyOnboardingShareholderType[]
  ): void {
    updateShareholders({
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '',
      step: currentStep,
      context: 'company_account',
      answers: {
        shareholders
      }
    });
  }

  return {
    handleUpdateShareholders,
    isUpdatingShareholders
  };
}
