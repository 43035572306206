import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';
import { useNavigate } from 'react-router-dom';

import { useMutationCache } from 'data/cache';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type ICompanyOnboardingProgress,
  type IIncrementCompanyStepPayload,
  type IUseIncrementCompanyStep
} from 'data/modules/cards/companyOnboarding';

export function useIncrementCompanyStep(): IUseIncrementCompanyStep {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: incrementStep, isLoading: isIncrementingStep } =
    useMutationCache({
      mutationKey: [CompanyOnboardingMutationKeys.INCREMENT_COMPANY_STEP],
      mutationFn: async (payload: IIncrementCompanyStepPayload) =>
        await CompanyOnboardingService.incrementStep(payload),
      onSuccess: (data, { step }) => {
        navigate(`/cartoes/onboarding/empresa/${step + 1}`);

        queryClient.setQueryData<ICompanyOnboardingProgress>(
          [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS],
          companyProgress =>
            ({
              ...companyProgress,
              currentStep: step + 1
            }) as ICompanyOnboardingProgress
        );
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  return {
    incrementStep,
    isIncrementingStep
  };
}
