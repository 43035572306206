import { MenuTabs, tokens } from 'ds';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  padding-top: 4rem;
`;

export const TabsContainer = styled.div`
  position: absolute;
  width: calc(100% + 6.4rem);
  top: -3.2rem;
  left: -3.2rem;
  background-color: ${tokens.neutralColorHighPure};
  overflow-y: hidden;
  padding-bottom: 0.5rem;

  /* Width */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const MenuTabItemCustom = styled(MenuTabs.Item)`
  font-weight: ${tokens.fontWeightSemibold};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardsInfoWrapper = styled(motion.div)`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  width: 100%;
  gap: calc(${tokens.spacingXs} - 0.8rem);
`;
