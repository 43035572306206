import { useDsGa4 } from 'ds/hooks/globals';

import { useCurrencyInputControlled } from './useCurrencyInputControlled';

import { type ICurrencyInputControlledProps } from './CurrencyInputControlled.types';

import {
  Caption,
  Container,
  Input,
  InputContainer,
  Label
} from './CurrencyInputControlled.styles';

export function CurrencyInputControlled({
  leftElement,
  rightElement,
  variant = 'medium',
  disabled,
  inputId,
  success,
  name,
  onCustomChange,
  onBlur,
  allowNegative,
  ...rest
}: ICurrencyInputControlledProps): JSX.Element {
  const { errorMessage, handleChangeValue, valueToShowOnInput } =
    useCurrencyInputControlled({
      name,
      onCustomChange,
      allowNegative
    });

  const { sendDsGaEvent } = useDsGa4();

  if (!leftElement && !rightElement) {
    throw new Error(
      'CurrencyInputDefault: You must provide at least one element'
    );
  }

  return (
    <Container $variant={variant}>
      <InputContainer
        $disabled={disabled}
        $filled={!!valueToShowOnInput}
        $hasError={!!errorMessage}
        $hasSuccess={!!success}
      >
        {leftElement && (
          <Label
            $variant={variant}
            htmlFor={inputId}
            $hasRightBorderRadius
          >
            {leftElement}
          </Label>
        )}

        <Input
          {...rest}
          id={inputId}
          placeholder='0,00'
          type='text'
          value={valueToShowOnInput}
          onChange={e => {
            handleChangeValue(e.target.value);

            e.target.value &&
              sendDsGaEvent('components', 'input_currency', {
                eventValue: e.target.value
              });
          }}
          onBlur={onBlur}
          readOnly={disabled}
        />

        {rightElement && (
          <Label
            $variant={variant}
            htmlFor={inputId}
            $hasLeftBorderRadius
          >
            {rightElement}
          </Label>
        )}
      </InputContainer>

      {!!errorMessage && <Caption $hasError>{errorMessage}</Caption>}
    </Container>
  );
}
