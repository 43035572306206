import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useAuthContext } from 'data/contexts';

import { CardsRoutes } from 'shared/constants/global';

import { type ICardsManagerMiddlewareProps } from './CardsManager.middleware.types';

export function CardsManagerMiddleware({
  onlyAccountManager = false
}: ICardsManagerMiddlewareProps): JSX.Element {
  const user = useAuthContext(useShallow(state => state.user));
  const location = useLocation();

  if (user?.cards?.manager) {
    if (onlyAccountManager && user?.cards?.type === 'FINANCIAL_MANAGER') {
      return <Navigate to='gestao' />;
    }

    return <Outlet />;
  }

  if (user?.cards?.isCardGroupManagerWithoutAccount) {
    return location.pathname.includes('grupos') ? (
      <Outlet />
    ) : (
      <Navigate to='grupos-de-cartoes' />
    );
  }

  if (user?.cards?.type === 'USER' && user?.cards?.isCardGroupManager) {
    return location.pathname.includes('grupos') ? (
      <Outlet />
    ) : (
      <Navigate to={CardsRoutes.CARDS_MY_CARD} />
    );
  }

  return <Navigate to={CardsRoutes.CARDS_MY_CARD} />;
}
