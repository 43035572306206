import { type MouseEvent, useState } from 'react';

import { type IUseTooltipOverflowContent } from './TooltipOverflowContent.types';

export function useTooltipOverflowContent(): IUseTooltipOverflowContent {
  const [showTooltip, setShowTooltip] = useState(false);

  function handleMouseOver(e: MouseEvent<HTMLHeadingElement>): void {
    if (
      e.currentTarget.offsetWidth < e.currentTarget.scrollWidth ||
      e.currentTarget.offsetHeight < e.currentTarget.scrollHeight
    ) {
      setShowTooltip(true);
    }
  }

  function handleMouseLeave(): void {
    showTooltip && setShowTooltip(false);
  }

  return {
    showTooltip,
    handleMouseLeave,
    handleMouseOver
  };
}
