import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 2.4rem;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    color: ${tokens.highlightColorPure};
  }
`;
