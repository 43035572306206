import { DSIcons } from 'ds';
import { useDsGa4 } from 'ds/hooks/globals';
import { useFormContext } from 'react-hook-form';

import { type ICheckboxControlledProps } from './CheckboxControlled.types';

import {
  CheckboxComponent,
  Container,
  InputComponent,
  Label
} from './CheckboxControlled.styles';

export function CheckboxControlled({
  name,
  label,
  disabled,
  small,
  defaultChecked,
  registerOptions,
  ...rest
}: ICheckboxControlledProps): JSX.Element {
  const { register } = useFormContext();
  const { sendDsGaEvent } = useDsGa4();

  return (
    <Container
      $disabled={disabled}
      data-testid='container'
      $small={small}
      {...rest}
    >
      <InputComponent
        type='checkbox'
        {...register(name, {
          ...registerOptions,
          onChange: event => {
            sendDsGaEvent('components', 'checkbox', {
              description: event.target.checked
                ? 'Habilitar checkbox'
                : 'Desabilitar checkbox',
              eventAction: event.target.checked
                ? 'enable_checkbox'
                : 'disable_checkbox',
              eventLabel: label ?? 'checkbox_without_label'
            });

            registerOptions?.onChange?.(event);
          }
        })}
        disabled={disabled}
        defaultChecked={defaultChecked}
      />
      <CheckboxComponent>
        <DSIcons.CheckIcon />
        <DSIcons.IndeterminatedIcon />
      </CheckboxComponent>
      {label && <Label>{label}</Label>}
    </Container>
  );
}
