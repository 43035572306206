import { useIsFetching } from '@tanstack/react-query';
import { CurrencyInput, DefaultButton, Radio, SkeletonLoader } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useChargeCardsContext, useLangContext } from 'data/contexts';

import { InfoMessage } from 'presentation/components/global/InfoMessage';

import { type IChargeCardsControllersProps } from './ChargeCardsControllers.types';

import {
  Container,
  ControllersWrapper,
  InputContainer,
  OptionsContainer,
  Title
} from './ChargeCardsControllers.styles';

export function ChargeCardsControllers({
  ...rest
}: IChargeCardsControllersProps): JSX.Element {
  const {
    currentLangKey,
    lang: {
      cards: { valueAllocation }
    }
  } = useLangContext();

  const [
    handleChangeChargeCardsMassOperation,
    handleApplyMassOperationToSelected,
    chargeCardsMassOperation,
    selectedCardsMapSize
  ] = useChargeCardsContext(
    useShallow(state => [
      state.handleChangeChargeCardsMassOperation,
      state.handleApplyMassOperationToSelected,
      state.chargeCardsMassOperation,
      state.selectedCardsMap.size
    ])
  );

  const isFetching = useIsFetching();

  return (
    <Container {...rest}>
      <Title>{valueAllocation.what_do_you_want_to_do[currentLangKey]}</Title>

      <ControllersWrapper>
        <OptionsContainer>
          <Radio.Default
            name='action-type'
            label={valueAllocation.actions.add_value[currentLangKey]}
            onChangeValue={() => {
              handleChangeChargeCardsMassOperation({
                operationType: 'CREDIT'
              });
            }}
            small
            checked={chargeCardsMassOperation.operationType === 'CREDIT'}
            tourClass='charge-card-add-value-radio'
          />

          <Radio.Default
            name='action-type'
            label={valueAllocation.actions.remove_value[currentLangKey]}
            onChangeValue={() => {
              handleChangeChargeCardsMassOperation({
                operationType: 'DEBIT'
              });
            }}
            small
            checked={chargeCardsMassOperation.operationType === 'DEBIT'}
            tourClass='charge-card-remove-value-radio'
          />

          <Radio.Default
            name='action-type'
            label={valueAllocation.actions.change_final_value[currentLangKey]}
            onChangeValue={() => {
              handleChangeChargeCardsMassOperation({
                operationType: 'CHANGE_FINAL_VALUE'
              });
            }}
            small
            checked={
              chargeCardsMassOperation.operationType === 'CHANGE_FINAL_VALUE'
            }
            tourClass='charge-card-change-final-value'
          />
        </OptionsContainer>

        {chargeCardsMassOperation.operationType &&
          (isFetching ? (
            <SkeletonLoader height='4.8rem' />
          ) : selectedCardsMapSize > 0 ? (
            <InputContainer>
              <CurrencyInput.Default
                inputId='value'
                onChangeValue={value => {
                  handleChangeChargeCardsMassOperation({ value });
                }}
                operation={chargeCardsMassOperation.operationType}
                value={chargeCardsMassOperation.value}
                leftElement='R$'
                variant='small'
              />

              <DefaultButton
                disabled={
                  chargeCardsMassOperation.value === 0 &&
                  chargeCardsMassOperation.operationType !==
                    'CHANGE_FINAL_VALUE'
                }
                onClick={handleApplyMassOperationToSelected}
                size='small'
              >
                {valueAllocation.apply_to_selected[currentLangKey]}
              </DefaultButton>
            </InputContainer>
          ) : (
            <InfoMessage type='warning'>
              {
                valueAllocation.select_the_users_in_the_table_below[
                  currentLangKey
                ]
              }
            </InfoMessage>
          ))}
      </ControllersWrapper>
    </Container>
  );
}
