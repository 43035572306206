import {
  type IPersistenceRequestProductPayload,
  type IRequestProductPayload
} from 'data/modules/store';

class RequestProductMapper {
  toPersistence(
    payload: IRequestProductPayload
  ): IPersistenceRequestProductPayload {
    return {
      email: payload.email,
      phone: payload.phone,
      user_id: payload.userId,
      product: payload.identifier,
      cards_qtt: payload.cardsQtt,
      company_id: payload.companyId,
      cards_use_type: payload.cardsUseType,
      conversion_url: payload.conversionUrl
    };
  }
}

export default new RequestProductMapper();
