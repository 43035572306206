import { useState } from 'react';

import { type IUsePaginationPages } from './PaginationPages.types';

export function usePaginationPages(): IUsePaginationPages {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  return {
    showMenu,
    setShowMenu
  };
}
