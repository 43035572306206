import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 36.4rem;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.tokens.spacingLg};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusMd};
  background: ${({ theme }) => theme.tokens.neutralColorHighLight};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: auto;
  }
`;

export const Wrapper = styled.div`
  gap: 6.4rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.tokens.spacingXl};

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.desktop}px) {
    padding-left: ${({ theme }) => theme.tokens.spacingLg};
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.tokens.spacingXs};

    h2 {
      color: black;
      font-size: ${({ theme }) => theme.tokens.fontSizeMd};
      font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
      font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
    }

    p {
      font-size: ${({ theme }) => theme.tokens.fontSizeXs};
      color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
      font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
      font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    > div p {
      max-width: unset;
    }
  }
`;

export const BannerImage = styled.img`
  height: 100%;
  object-fit: contain;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 50%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 100%;
  }
`;
