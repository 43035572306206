import { type INavigationTabsContainerProps } from './NavigationTabsContainer.types';

import { Container, TabsList } from './NavigationTabsContainer.styles';

export function NavigationTabsContainer({
  children
}: INavigationTabsContainerProps): JSX.Element {
  return (
    <Container>
      <TabsList>{children}</TabsList>
    </Container>
  );
}
