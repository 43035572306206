enum AccountHoldersQueryKeys {
  'GET_ACCOUNT_HOLDERS' = 'GET_ACCOUNT_HOLDERS'
}

enum AccountHoldersMutationKeys {
  'STORE_ACCOUNT_HOLDERS' = 'STORE_ACCOUNT_HOLDERS',
  'UPDATE_ACCOUNT_HOLDER' = 'UPDATE_ACCOUNT_HOLDER'
}

export { AccountHoldersMutationKeys, AccountHoldersQueryKeys };
