/** Todos os MCCs relacionados a transações de transporte */
export const transportMCCs = [
  5541, 4121, 7523, 4784, 4131, 5983, 4789, 5172, 5542, 4112, 4011, 4411, 7519,
  9752
];

/** Todos os MCCs relacionados a transações de alimentação. */
export const foodMCCs = [
  5812, 5814, 5499, 5411, 5462, 5441, 5422, 5811, 5451, 5399
];
