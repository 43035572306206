import { useEffect, useState } from 'react';

import { toast } from 'ds';
import { useTranslation } from 'react-i18next';

import { useGetFuelPolicies, useUpdateFuelPolicies } from 'data/modules/fuel';

import { Currency } from 'shared/utils/format';

import {
  type IBlockedCard,
  type IUseFuelPolicy,
  type IValuesByFuel,
  type RequiredFieldsType
} from './FuelPolicy.types';

export function useFuelPolicy(): IUseFuelPolicy {
  const [isVisibleValueByFuel, setIsVisibleValueByFuel] = useState(false);
  const [isVisibleBlockedCard, setIsVisibleBlockedCard] = useState(false);
  const [isVisibleRequiredFields, setIsVisibleRequiredFields] = useState(false);

  const { isLoadingFuelPolicies, fuelPolicies } = useGetFuelPolicies();

  const { t } = useTranslation('fuel');

  const { isUpdatingFuelPolicies, updateFuelPolicies } = useUpdateFuelPolicies({
    onSuccessUpdateFuelPolicies: () => {
      toast.success(t('successSavingPolicy'));
    },
    onErrorUpdateFuelPolicies: () => {
      toast.error(t('errorSavingPolicy'));
    }
  });

  const [valuesByFuel, setValuesByFuel] = useState<IValuesByFuel>({
    amount: '',
    period: null
  });

  const [blockedCard, setBlockCard] = useState<IBlockedCard>({
    blockType: null,
    allowMoreExpenses: 0
  });

  const [requiredFields, setRequiredFields] = useState<RequiredFieldsType[]>(
    []
  );

  const isLoadingSomeData = isLoadingFuelPolicies || isUpdatingFuelPolicies;

  async function handleSaveFuelPolicies(): Promise<void> {
    const blockingCard = blockedCard.blockType === 'immediately';

    const blockingExpensesQuantity =
      isVisibleBlockedCard && blockedCard.blockType === 'allowMoreExpenses'
        ? blockedCard.allowMoreExpenses
        : null;

    const limitAmount = isVisibleValueByFuel ? valuesByFuel.amount : null;

    const limitType = isVisibleValueByFuel ? valuesByFuel.period : null;

    const requiredFieldsLocal = isVisibleRequiredFields ? requiredFields : [];

    await updateFuelPolicies({
      blockingCard: isVisibleBlockedCard,
      blockingExpensesQuantity: blockingCard ? 0 : blockingExpensesQuantity,
      limitAmount:
        limitAmount !== null ? Currency.parseToFloat(limitAmount) : limitAmount,
      limitType,
      requiredFields: requiredFieldsLocal
    });
  }

  function handleChangeRequiredFields(type: RequiredFieldsType): void {
    if (requiredFields.includes(type)) {
      setRequiredFields(oldFields => oldFields.filter(item => item !== type));
      return;
    }

    setRequiredFields(oldFields => [...oldFields, type]);
  }

  const isValidValueByFuel =
    (valuesByFuel.amount === '' || valuesByFuel.period === null) &&
    isVisibleValueByFuel;

  const isValidBlockedCard =
    ((blockedCard.allowMoreExpenses === 0 &&
      blockedCard.blockType === 'allowMoreExpenses') ||
      blockedCard.blockType === null) &&
    isVisibleBlockedCard;

  const isValidRequiredFields =
    requiredFields.length === 0 && isVisibleRequiredFields;

  const isSaveButtonDisabled =
    isValidValueByFuel || isValidBlockedCard || isValidRequiredFields;

  // Define o valor inicial das politicas e habilita as preenchidas

  useEffect(() => {
    if (!isLoadingFuelPolicies && fuelPolicies) {
      const blockType =
        fuelPolicies?.blockingCard &&
        fuelPolicies?.blockingExpensesQuantity === 0
          ? 'immediately'
          : fuelPolicies?.blockingExpensesQuantity &&
              fuelPolicies?.blockingCard &&
              fuelPolicies?.blockingExpensesQuantity > 0
            ? 'allowMoreExpenses'
            : null;

      const fuelValue = fuelPolicies?.limitAmount ?? 0;

      const fuelLimit = fuelPolicies?.limitType ?? null;

      const allowMoreExpenses = fuelPolicies?.blockingExpensesQuantity ?? 0;

      setValuesByFuel({
        amount: Currency.format('BRL', fuelValue),
        period: fuelLimit
      });

      setBlockCard({
        blockType,
        allowMoreExpenses
      });

      setRequiredFields(fuelPolicies?.requiredFields ?? []);

      setIsVisibleValueByFuel(!!fuelValue && !!fuelLimit);

      setIsVisibleBlockedCard(!!blockType || allowMoreExpenses > 0);

      setIsVisibleRequiredFields(fuelPolicies.requiredFields.length > 0);
    }
  }, [isLoadingFuelPolicies, fuelPolicies]);

  return {
    isVisibleBlockedCard,
    isVisibleRequiredFields,
    setIsVisibleRequiredFields,
    setIsVisibleValueByFuel,
    isVisibleValueByFuel,
    setIsVisibleBlockedCard,
    valuesByFuel,
    setValuesByFuel,
    blockedCard,
    setBlockCard,
    requiredFields,
    isLoadingSomeData,
    handleSaveFuelPolicies,
    handleChangeRequiredFields,
    isSaveButtonDisabled
  };
}
