import { useLangContext } from 'data/contexts';

import {
  OnboardingFooter,
  OnboardingSubtitle,
  OnboardingText,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { useInitialDirections } from './useInitialDirections';

import { Container, DescriptionContainer } from './InitialDirections.styles';

export function InitialDirections(): JSX.Element {
  const [initialDirections, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.company.managers_and_cardholders
      .initial_directions,
    state.currentLangKey
  ]);

  const { handleContinueToNextStep } = useInitialDirections();

  return (
    <Container>
      <OnboardingTitle>
        {initialDirections.title[currentLangKey]}
      </OnboardingTitle>

      <DescriptionContainer>
        <OnboardingSubtitle>
          {initialDirections.subtitle[currentLangKey]}
        </OnboardingSubtitle>

        <OnboardingText>
          {initialDirections.management_types_presentation[currentLangKey]}
        </OnboardingText>

        <ul>
          {initialDirections.functions_management_types.map(type => (
            <li key={type.name[currentLangKey]}>
              <OnboardingText>
                {type.article[currentLangKey]}
                <b>{type.name[currentLangKey]}</b>
                {type.description[currentLangKey]}
              </OnboardingText>
            </li>
          ))}
        </ul>

        <OnboardingText>
          {
            initialDirections.cardholders_type_description.article[
              currentLangKey
            ]
          }
          <b>
            {
              initialDirections.cardholders_type_description.highlight[
                currentLangKey
              ]
            }
          </b>
          {
            initialDirections.cardholders_type_description.description[
              currentLangKey
            ]
          }
        </OnboardingText>
      </DescriptionContainer>

      <OnboardingFooter
        continueButtonProps={{
          onClick: handleContinueToNextStep
        }}
      />
    </Container>
  );
}
