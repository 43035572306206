import { useLangContext, useModalContext } from 'data/contexts';

import { Modal } from 'presentation/components/base/Modal';

import { type IConfirmationModalProps } from './ConfirmationModal.types';

import { ModalBody } from './ConfirmationModal.styles';

export function ConfirmationModal({
  onClose,
  isLoading,
  onConfirm,
  title,
  message
}: IConfirmationModalProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);
  const { handleCloseModal, visibleModal } = useModalContext();

  return (
    <Modal
      isOpen={visibleModal === 'confirmation'}
      onClose={onClose ?? handleCloseModal}
      title={title ?? lang.global.confirmation[currentLangKey]}
      isLoading={isLoading}
      secondaryButton={{
        color: 'carnation',
        children: lang.global.close[currentLangKey],
        onClick: onClose ?? handleCloseModal
      }}
      primaryButton={{
        color: 'success',
        children: lang.global.confirm[currentLangKey],
        onClick: onConfirm
      }}
    >
      <ModalBody>
        <p>{message ?? lang.global.confirm_action[currentLangKey]}</p>
      </ModalBody>
    </Modal>
  );
}
