import { tokens } from 'ds';
import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type PasswordSecurityLevelProgressType } from './PasswordRequirements.types';

export const Container = styled.div`
  display: grid;
  gap: 1rem;
`;

export const PasswordSecurityLevel = styled.div`
  height: ${tokens.spacingQuarck};
  border-radius: ${tokens.borderRadiusNano};
  background: ${tokens.neutralColorHighMedium};
`;

const passwordProgressColors = [
  tokens.feedbackColorErrorPure,
  tokens.highlightColorPure,
  tokens.feedbackColorSuccessLight,
  tokens.feedbackColorSuccessPure,
  tokens.feedbackColorSuccessPure
];

export const PasswordSecurityLevelProgress = styled.div<
  AddPrefixToType<PasswordSecurityLevelProgressType>
>`
  height: 100%;
  border-radius: ${tokens.borderRadiusNano};
  width: ${({ $score }) => ($score + 1) * 20 + '%'};
  background: ${({ $score }) => passwordProgressColors[Math.max($score, 0)]};
  transition: all 0.25s ease;
`;

export const InformationText = styled.p`
  font-style: normal;
  line-height: normal;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
`;

export const PasswordStatus = styled.strong`
  font-style: normal;
  line-height: normal;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.feedbackColorErrorPure};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightSemibold};
`;
