import { forwardRef, type RefObject, useRef, useState } from 'react';

import { Elements } from 'ds';
import { ImageFullScreen } from 'ds/components/ImageFullscreen/ImageFullScreen.component';
import { Portal } from 'ds/components/Portal';
import { zIndex } from 'ds/constants';
import { useDsGa4 } from 'ds/hooks/globals';
import { DSIcons } from 'ds/icons';

import { type IAvatarProps } from './Avatar.types';

import { Container } from './Avatar.styles';

export const Avatar = forwardRef<HTMLDivElement, IAvatarProps>(function Avatar(
  {
    size,
    icon,
    image,
    viewImage = false,
    portalId = 'view-image-portal',
    iconAsImage = false,
    hasZoomControl,
    showZoomTour,
    ...rest
  },
  ref
): JSX.Element {
  const [isOpenedImage, setIsOpenedImage] = useState(false);

  const { sendDsGaEvent } = useDsGa4();

  const avatarRef = useRef<HTMLDivElement>(null);

  const containerRef =
    ref !== undefined ? (ref as RefObject<HTMLDivElement>) : avatarRef;

  if (icon) {
    const IconComponent = DSIcons[icon];

    return (
      <Container
        $icon={true}
        $size={size}
        $iconAsImage={iconAsImage}
        {...rest}
      >
        <IconComponent />
      </Container>
    );
  }

  return (
    <>
      <Container
        ref={containerRef}
        onClick={
          viewImage
            ? () => {
                setIsOpenedImage(true);

                const isAvatarInTable = Elements.isInTable(containerRef);

                sendDsGaEvent(
                  'components',
                  'avatar',
                  isAvatarInTable
                    ? {
                        eventName: 'tables',
                        objectName: 'tables',
                        eventAction: 'avatar_tables'
                      }
                    : {}
                );
              }
            : undefined
        }
        $size={size}
        {...rest}
      >
        <img {...image} />
      </Container>

      {image !== null && viewImage && (
        <Portal containerId={portalId}>
          {isOpenedImage && (
            <ImageFullScreen
              showZoomTour={showZoomTour}
              hasZoomControl={hasZoomControl}
              style={{
                zIndex: zIndex.imageViewerPortal
              }}
              overlayStyle={{
                backgroundColor: 'rgba(0,0,0,0.8)'
              }}
              onClose={() => {
                setIsOpenedImage(false);
              }}
              closeOnClickOutside
              url={image?.src as string}
            />
          )}
        </Portal>
      )}
    </>
  );
});
