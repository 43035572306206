import { DefaultTable } from 'ds';

import { Currency } from 'shared/utils/format';

import { type IUserAnalysisSummaryTableRowProps } from './UserAnalysisSummaryTableRow.types';

export function UserAnalysisSummaryTableRow({
  name,
  total,
  totalReports,
  totalExpenses,
  sumRefundable,
  sumNoRefundable,
  averagePerExpense
}: IUserAnalysisSummaryTableRowProps): JSX.Element {
  return (
    <DefaultTable.Tr>
      <DefaultTable.Td truncate>{name}</DefaultTable.Td>

      <DefaultTable.Td>{totalReports}</DefaultTable.Td>

      <DefaultTable.Td>{totalExpenses}</DefaultTable.Td>

      <DefaultTable.Td>
        {Currency.format('BRL', averagePerExpense, true)}
      </DefaultTable.Td>

      <DefaultTable.Td>
        {Currency.format('BRL', sumNoRefundable, true)}
      </DefaultTable.Td>

      <DefaultTable.Td>
        {Currency.format('BRL', sumRefundable, true)}
      </DefaultTable.Td>

      <DefaultTable.Td>{Currency.format('BRL', total, true)}</DefaultTable.Td>
    </DefaultTable.Tr>
  );
}
