import { useMemo } from 'react';

import { useGetProjects, useGetRelatedProjects } from 'data/modules/projects';

import { type ISelectOption } from 'presentation/components/base/Select/Select.types';

import { TransformArray } from 'shared/utils/global';

import {
  type IUseProjectsManageBusinessCard,
  type IUseProjectsManageBusinessCardParams
} from './useProjectsManageBusinessCard.types';

export function useProjectsManageBusinessCard({
  uuid
}: IUseProjectsManageBusinessCardParams): IUseProjectsManageBusinessCard {
  const payload = {
    page: 1,
    perPage: 500
  };

  const { projects, isLoadingProjects } = useGetProjects(payload);

  const { relatedProjects, isLoadingRelatedProjects } = useGetRelatedProjects({
    uuid,
    ...payload
  });

  const relatedProjectsOptions: ISelectOption[] = useMemo(() => {
    return TransformArray.objectsToSelectOptions(relatedProjects, 'id', 'name');
  }, [relatedProjects]);

  const projectsOptions: ISelectOption[] = useMemo(() => {
    const filterProjects = projects.filter(
      option => !relatedProjects.find(project => project.id === option.id)
    );

    return TransformArray.objectsToSelectOptions(filterProjects, 'id', 'name');
  }, [projects, relatedProjects]);

  return {
    projectsOptions,
    relatedProjects: relatedProjectsOptions,
    isLoadingProjects: isLoadingRelatedProjects || isLoadingProjects
  };
}
