import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type StatusPillStylesType } from './StatusPill.types';

export const Container = styled.span<AddPrefixToType<StatusPillStylesType>>`
  border-width: 1px;
  width: fit-content;
  height: fit-content;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  padding: 0.1rem ${({ theme }) => theme.tokens.spacingNano};
  font-size: ${({ theme }) => theme.tokens.fontSizeXs};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusLg};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-weight: ${({ theme }) => theme.tokens.fontWeightSemibold};

  ${({ $status }) =>
    $status === 'not_owned'
      ? css`
          display: none;
        `
      : $status === 'acquired'
        ? css`
            color: ${tokens.feedbackColorSuccessMedium};
            border: 1px solid ${tokens.feedbackColorSuccessMedium};
            background: ${tokens.feedbackColorSuccessLightest};
          `
        : css`
            color: ${tokens.highlightColorMedium};
            border: 1px solid ${tokens.highlightColorMedium};
            background: ${tokens.highlightColorLightest};
          `}
`;
