import { format } from 'date-fns';

import {
  type IAllocatedBalance,
  type IPersistenceAllocatedBalance
} from 'data/modules/cards/balance';

class GetAllocatedBalanceMapper {
  toDomain(
    persistenceAllocatedBalance: IPersistenceAllocatedBalance
  ): IAllocatedBalance {
    return {
      amount: persistenceAllocatedBalance.amount,
      lastUpdateDate: format(
        new Date(persistenceAllocatedBalance.date),
        'dd/MM/yyyy'
      ),
      lastUpdateTime: format(
        new Date(persistenceAllocatedBalance.date),
        "HH'h'mm'"
      )
    };
  }
}

export default new GetAllocatedBalanceMapper();
