import { toast } from 'ds/utils';

import { useQueryCache } from 'data/cache';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUseGetAnswerableUser
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';

export function useGetAnswerableUser(): IUseGetAnswerableUser {
  const { data: cachedCompanyProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const {
    data: answerableUser,
    isFetching: isLoadingAnswerableUser,
    isError,
    error
  } = useQueryCache({
    queryKey: [
      CompanyOnboardingQueryKeys.GET_ANSWERABLE_USER,
      cachedCompanyProgress?.onboardingCompanyId
    ],
    queryFn: async () =>
      await CompanyOnboardingService.getAnswerableUser({
        uuidCompanyOnboarding:
          cachedCompanyProgress?.onboardingCompanyId as string
      }),
    enabled: !!cachedCompanyProgress
  });

  if (isError) {
    toast.error(error.message);
  }

  return {
    answerableUser,
    isLoadingAnswerableUser
  };
}
