import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type ICreateReportFieldsForm } from 'data/modules/reports/types/reports.types';

import { Validate } from 'shared/utils/validation';

export function useCreateReportSchema(): ObjectSchema<ICreateReportFieldsForm> {
  const { lang, currentLangKey } = useLangContext();

  const createReportSchema: ObjectSchema<ICreateReportFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object({
        title: yup.string().required(fieldRequiredMessage),
        costsCenter: yup
          .object({
            value: yup.string(),
            label: yup.string()
          })
          .test({
            name: 'costsCenterRequired',
            message: fieldRequiredMessage,
            test: Validate.isOptionSelected
          }),
        paymentMethod: yup.string(),
        advancePayment: yup.string(),
        project: yup.object({
          value: yup.string(),
          label: yup.string()
        }),
        observation: yup.string().defined()
      });
    }, [lang, currentLangKey]);

  return createReportSchema;
}
