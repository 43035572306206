import {
  type IAnswerableUser,
  type IPersistenceAnswerableUser
} from 'data/modules/cards/companyOnboarding';

class GetAnswerableUserMapper {
  toDomain(
    persistenceAnswerableUser: IPersistenceAnswerableUser
  ): IAnswerableUser {
    return {
      email: persistenceAnswerableUser.email,
      name: persistenceAnswerableUser.name,
      memberLegalRepresentative:
        persistenceAnswerableUser.member_legal_representative
    };
  }
}

export default new GetAnswerableUserMapper();
