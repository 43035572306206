import { type IBank } from 'data/modules/global';

/**
 * Todos os bancos brasileiros de acordo com o Banco Central referente
 * a março de 2023: https://dadosabertos.bcb.gov.br/dataset/lista-de-participantes-do-str
 */
export const banks: IBank[] = [
  {
    name: 'BCO DO BRASIL S.A.',
    number: 1
  },
  {
    name: 'BRB - BCO DE BRASILIA S.A.',
    number: 70
  },
  {
    name: 'CCR SEARA',
    number: 430
  },
  {
    name: 'AGK CC S.A.',
    number: 272
  },
  {
    name: 'CONF NAC COOP CENTRAIS UNICRED',
    number: 136
  },
  {
    name: 'ÍNDIGO INVESTIMENTOS DTVM LTDA.',
    number: 407
  },
  {
    name: 'CAIXA ECONOMICA FEDERAL',
    number: 104
  },
  {
    name: 'BANCO INTER',
    number: 77
  },
  {
    name: 'COLUNA S.A. DTVM',
    number: 423
  },
  {
    name: 'BCO RIBEIRAO PRETO S.A.',
    number: 741
  },
  {
    name: 'BANCO BARI S.A.',
    number: 330
  },
  {
    name: 'BCO CETELEM S.A.',
    number: 739
  },
  {
    name: 'BANCO SEMEAR',
    number: 743
  },
  {
    name: 'PLANNER CV S.A.',
    number: 100
  },
  {
    name: 'FDO GARANTIDOR CRÉDITOS',
    number: 541
  },
  {
    name: 'BCO B3 S.A.',
    number: 96
  },
  {
    name: 'BCO RABOBANK INTL BRASIL S.A.',
    number: 747
  },
  {
    name: 'CIELO IP S.A.',
    number: 362
  },
  {
    name: 'CCR DE ABELARDO LUZ',
    number: 322
  },
  {
    name: 'BCO COOPERATIVO SICREDI S.A.',
    number: 748
  },
  {
    name: 'CREHNOR LARANJEIRAS',
    number: 350
  },
  {
    name: 'BCO BNP PARIBAS BRASIL S A',
    number: 752
  },
  {
    name: 'CECM COOPERFORTE',
    number: 379
  },
  {
    name: 'KIRTON BANK',
    number: 399
  },
  {
    name: 'BCO BRASILEIRO DE CRÉDITO S.A.',
    number: 378
  },
  {
    name: 'BCO BV S.A.',
    number: 413
  },
  {
    name: 'BANCO SICOOB S.A.',
    number: 756
  },
  {
    name: 'TRINUS CAPITAL DTVM',
    number: 360
  },
  {
    name: 'BCO KEB HANA DO BRASIL S.A.',
    number: 757
  },
  {
    name: 'XP INVESTIMENTOS CCTVM S/A',
    number: 102
  },
  {
    name: 'UNIPRIME DO BRASIL - COOP',
    number: 84
  },
  {
    name: 'CM CAPITAL MARKETS CCTVM LTDA',
    number: 180
  },
  {
    name: 'BCO MORGAN STANLEY S.A.',
    number: 66
  },
  {
    name: 'UBS BRASIL CCTVM S.A.',
    number: 15
  },
  {
    name: 'TREVISO CC S.A.',
    number: 143
  },
  {
    name: 'HIPERCARD BM S.A.',
    number: 62
  },
  {
    name: 'BCO. J.SAFRA S.A.',
    number: 74
  },
  {
    name: 'UNIPRIME COOPCENTRAL LTDA.',
    number: 99
  },
  {
    name: 'BCO TOYOTA DO BRASIL S.A.',
    number: 387
  },
  {
    name: 'PARATI - CFI S.A.',
    number: 326
  },
  {
    name: 'BCO ALFA S.A.',
    number: 25
  },
  {
    name: 'BCO ABN AMRO S.A.',
    number: 75
  },
  {
    name: 'BCO CARGILL S.A.',
    number: 40
  },
  {
    name: 'TERRA INVESTIMENTOS DTVM',
    number: 307
  },
  {
    name: 'CECM DOS TRAB.PORT. DA G.VITOR',
    number: 385
  },
  {
    name: 'SOCINAL S.A. CFI',
    number: 425
  },
  {
    name: 'SERVICOOP',
    number: 190
  },
  {
    name: 'OZ CORRETORA DE CÂMBIO S.A.',
    number: 296
  },
  {
    name: 'BANCO BRADESCARD',
    number: 63
  },
  {
    name: 'NOVA FUTURA CTVM LTDA.',
    number: 191
  },
  {
    name: 'FIDUCIA SCMEPP LTDA',
    number: 382
  },
  {
    name: 'GOLDMAN SACHS DO BRASIL BM S.A',
    number: 64
  },
  {
    name: 'CCM SERV. PÚBLICOS SP',
    number: 459
  },
  {
    name: 'CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.',
    number: 97
  },
  {
    name: 'CCM DESP TRÂNS SC E RS',
    number: 16
  },
  {
    name: 'BCO AFINZ S.A. - BM',
    number: 299
  },
  {
    name: 'CECM SERV PUBL PINHÃO',
    number: 471
  },
  {
    name: 'PORTOSEG S.A. CFI',
    number: 468
  },
  {
    name: 'BANCO INBURSA',
    number: 12
  },
  {
    name: 'BCO DA AMAZONIA S.A.',
    number: 3
  },
  {
    name: 'CONFIDENCE CC S.A.',
    number: 60
  },
  {
    name: 'BCO DO EST. DO PA S.A.',
    number: 37
  },
  {
    name: 'VIA CERTA FINANCIADORA S.A. - CFI',
    number: 411
  },
  {
    name: 'ZEMA CFI S/A',
    number: 359
  },
  {
    name: 'CASA CREDITO S.A. SCM',
    number: 159
  },
  {
    name: 'COOPCENTRAL AILOS',
    number: 85
  },
  {
    name: 'COOP CREDITAG',
    number: 400
  },
  {
    name: 'CREDIARE CFI S.A.',
    number: 429
  },
  {
    name: 'PLANNER SOCIEDADE DE CRÉDITO DIRETO',
    number: 410
  },
  {
    name: 'CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO',
    number: 114
  },
  {
    name: 'CECM FABRIC CALÇADOS SAPIRANGA',
    number: 328
  },
  {
    name: 'BCO BBI S.A.',
    number: 36
  },
  {
    name: 'LIGA INVEST DTVM LTDA.',
    number: 469
  },
  {
    name: 'BCO BRADESCO FINANC. S.A.',
    number: 394
  },
  {
    name: 'BCO DO NORDESTE DO BRASIL S.A.',
    number: 4
  },
  {
    name: 'HEDGE INVESTMENTS DTVM LTDA.',
    number: 458
  },
  {
    name: 'BCO CCB BRASIL S.A.',
    number: 320
  },
  {
    name: 'HS FINANCEIRA',
    number: 189
  },
  {
    name: 'LECCA CFI S.A.',
    number: 105
  },
  {
    name: 'BCO KDB BRASIL S.A.',
    number: 76
  },
  {
    name: 'BANCO TOPÁZIO S.A.',
    number: 82
  },
  {
    name: 'HSCM SCMEPP LTDA.',
    number: 312
  },
  {
    name: 'VALOR SCD S.A.',
    number: 195
  },
  {
    name: 'POLOCRED SCMEPP LTDA.',
    number: 93
  },
  {
    name: 'CCR DE IBIAM',
    number: 391
  },
  {
    name: 'CCR DE SÃO MIGUEL DO OESTE',
    number: 273
  },
  {
    name: 'BCO CSF S.A.',
    number: 368
  },
  {
    name: 'PAGSEGURO INTERNET IP S.A.',
    number: 290
  },
  {
    name: 'MONEYCORP BCO DE CÂMBIO S.A.',
    number: 259
  },
  {
    name: 'F D GOLD DTVM LTDA',
    number: 395
  },
  {
    name: 'EFÍ S.A. - IP',
    number: 364
  },
  {
    name: 'ICAP DO BRASIL CTVM LTDA.',
    number: 157
  },
  {
    name: 'SOCRED SA - SCMEPP',
    number: 183
  },
  {
    name: 'STATE STREET BR S.A. BCO COMERCIAL',
    number: 14
  },
  {
    name: 'CARUANA SCFI',
    number: 130
  },
  {
    name: 'MIDWAY S.A. - SCFI',
    number: 358
  },
  {
    name: 'CODEPE CVC S.A.',
    number: 127
  },
  {
    name: 'PICPAY BANK - BANCO MÚLTIPLO S.A',
    number: 79
  },
  {
    name: 'MASTER BI S.A.',
    number: 141
  },
  {
    name: 'SUPERDIGITAL I.P. S.A.',
    number: 340
  },
  {
    name: 'BANCOSEGURO S.A.',
    number: 81
  },
  {
    name: 'BCO YAMAHA MOTOR S.A.',
    number: 475
  },
  {
    name: 'CRESOL CONFEDERAÇÃO',
    number: 133
  },
  {
    name: 'MERCADO PAGO IP LTDA.',
    number: 323
  },
  {
    name: 'BCO AGIBANK S.A.',
    number: 121
  },
  {
    name: 'BCO DA CHINA BRASIL S.A.',
    number: 83
  },
  {
    name: 'GET MONEY CC LTDA',
    number: 138
  },
  {
    name: 'BCO BANDEPE S.A.',
    number: 24
  },
  {
    name: 'GLOBAL SCM LTDA',
    number: 384
  },
  {
    name: 'NEON FINANCEIRA - CFI S.A.',
    number: 426
  },
  {
    name: 'BANCO RANDON S.A.',
    number: 88
  },
  {
    name: 'OM DTVM LTDA',
    number: 319
  },
  {
    name: 'BMP SCMEPP LTDA',
    number: 274
  },
  {
    name: 'TRAVELEX BANCO DE CÂMBIO S.A.',
    number: 95
  },
  {
    name: 'BANCO FINAXIS',
    number: 94
  },
  {
    name: 'GAZINCRED S.A. SCFI',
    number: 478
  },
  {
    name: 'BCO SENFF S.A.',
    number: 276
  },
  {
    name: 'MIRAE ASSET CCTVM LTDA',
    number: 447
  },
  {
    name: 'BCO DO EST. DE SE S.A.',
    number: 47
  },
  {
    name: 'BEXS BCO DE CAMBIO S.A.',
    number: 144
  },
  {
    name: 'FITBANK IP',
    number: 450
  },
  {
    name: 'BR PARTNERS BI',
    number: 126
  },
  {
    name: 'ÓRAMA DTVM S.A.',
    number: 325
  },
  {
    name: 'DOCK IP S.A.',
    number: 301
  },
  {
    name: 'BRL TRUST DTVM SA',
    number: 173
  },
  {
    name: 'FRAM CAPITAL DTVM S.A.',
    number: 331
  },
  {
    name: 'BCO WESTERN UNION',
    number: 119
  },
  {
    name: 'HUB IP S.A.',
    number: 396
  },
  {
    name: 'CELCOIN IP S.A.',
    number: 509
  },
  {
    name: 'CAMBIONET CC LTDA',
    number: 309
  },
  {
    name: 'PARANA BCO S.A.',
    number: 254
  },
  {
    name: 'BARI CIA HIPOTECÁRIA',
    number: 268
  },
  {
    name: 'IUGU IP S.A.',
    number: 401
  },
  {
    name: 'BCO BOCOM BBM S.A.',
    number: 107
  },
  {
    name: 'BANCO BESA S.A.',
    number: 334
  },
  {
    name: 'SOCIAL BANK S/A',
    number: 412
  },
  {
    name: 'BCO WOORI BANK DO BRASIL S.A.',
    number: 124
  },
  {
    name: 'FACTA S.A. CFI',
    number: 149
  },
  {
    name: 'STONE IP S.A.',
    number: 197
  },
  {
    name: 'ID CTVM',
    number: 439
  },
  {
    name: 'AMAZÔNIA CC LTDA.',
    number: 313
  },
  {
    name: 'BROKER BRASIL CC LTDA.',
    number: 142
  },
  {
    name: 'PINBANK IP',
    number: 529
  },
  {
    name: 'BCO MERCANTIL DO BRASIL S.A.',
    number: 389
  },
  {
    name: 'BCO ITAÚ BBA S.A.',
    number: 184
  },
  {
    name: 'BCO TRIANGULO S.A.',
    number: 634
  },
  {
    name: 'SENSO CCVM S.A.',
    number: 545
  },
  {
    name: 'ICBC DO BRASIL BM S.A.',
    number: 132
  },
  {
    name: 'VIPS CC LTDA.',
    number: 298
  },
  {
    name: 'BMS SCD S.A.',
    number: 377
  },
  {
    name: 'CREFAZ SCMEPP LTDA',
    number: 321
  },
  {
    name: 'NU PAGAMENTOS - IP',
    number: 260
  },
  {
    name: 'CDC SCD S.A.',
    number: 470
  },
  {
    name: 'UBS BRASIL BI S.A.',
    number: 129
  },
  {
    name: 'MS BANK S.A. BCO DE CÂMBIO',
    number: 128
  },
  {
    name: 'LAMARA SCD S.A.',
    number: 416
  },
  {
    name: 'ASAAS IP S.A.',
    number: 461
  },
  {
    name: 'PARMETAL DTVM LTDA',
    number: 194
  },
  {
    name: 'NEON PAGAMENTOS S.A. IP',
    number: 536
  },
  {
    name: 'EBANX IP LTDA.',
    number: 383
  },
  {
    name: 'CARTOS SCD S.A.',
    number: 324
  },
  {
    name: 'VORTX DTVM LTDA.',
    number: 310
  },
  {
    name: 'PICPAY',
    number: 380
  },
  {
    name: 'COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO',
    number: 163
  },
  {
    name: 'WILL FINANCEIRA S.A.CFI',
    number: 280
  },
  {
    name: 'GUITTA CC LTDA',
    number: 146
  },
  {
    name: 'FFA SCMEPP LTDA.',
    number: 343
  },
  {
    name: 'COOP DE PRIMAVERA DO LESTE',
    number: 279
  },
  {
    name: 'BANCO DIGIO',
    number: 335
  },
  {
    name: 'AL5 S.A. CFI',
    number: 349
  },
  {
    name: 'CRED-UFES',
    number: 427
  },
  {
    name: 'REALIZE CFI S.A.',
    number: 374
  },
  {
    name: 'GENIAL INVESTIMENTOS CVM S.A.',
    number: 278
  },
  {
    name: 'IB CCTVM S.A.',
    number: 271
  },
  {
    name: 'BCO BANESTES S.A.',
    number: 21
  },
  {
    name: 'BCO ABC BRASIL S.A.',
    number: 246
  },
  {
    name: 'BS2 DTVM S.A.',
    number: 292
  },
  {
    name: 'SCOTIABANK BRASIL',
    number: 751
  },
  {
    name: 'TORO CTVM S.A.',
    number: 352
  },
  {
    name: 'BANCO BTG PACTUAL S.A.',
    number: 208
  },
  {
    name: 'NU FINANCEIRA S.A. CFI',
    number: 386
  },
  {
    name: 'BCO MODAL S.A.',
    number: 746
  },
  {
    name: 'BCO CLASSICO S.A.',
    number: 241
  },
  {
    name: 'IDEAL CTVM S.A.',
    number: 398
  },
  {
    name: 'BCO C6 S.A.',
    number: 336
  },
  {
    name: 'BCO GUANABARA S.A.',
    number: 612
  },
  {
    name: 'BCO INDUSTRIAL DO BRASIL S.A.',
    number: 604
  },
  {
    name: 'BCO CREDIT SUISSE S.A.',
    number: 505
  },
  {
    name: 'QI SCD S.A.',
    number: 329
  },
  {
    name: 'FAIR CC S.A.',
    number: 196
  },
  {
    name: 'CREDITAS SCD',
    number: 342
  },
  {
    name: 'BCO LA NACION ARGENTINA',
    number: 300
  },
  {
    name: 'CITIBANK N.A.',
    number: 477
  },
  {
    name: 'BCO CEDULA S.A.',
    number: 266
  },
  {
    name: 'BCO BRADESCO BERJ S.A.',
    number: 122
  },
  {
    name: 'BCO J.P. MORGAN S.A.',
    number: 376
  },
  {
    name: 'BCO XP S.A.',
    number: 348
  },
  {
    name: 'BCO CAIXA GERAL BRASIL S.A.',
    number: 473
  },
  {
    name: 'BCO CITIBANK S.A.',
    number: 745
  },
  {
    name: 'BCO RODOBENS S.A.',
    number: 120
  },
  {
    name: 'BCO FATOR S.A.',
    number: 265
  },
  {
    name: 'BNDES',
    number: 7
  },
  {
    name: 'ATIVA S.A. INVESTIMENTOS CCTVM',
    number: 188
  },
  {
    name: 'BGC LIQUIDEZ DTVM LTDA',
    number: 134
  },
  {
    name: 'BANCO ITAÚ CONSIGNADO S.A.',
    number: 29
  },
  {
    name: 'MASTER S/A CCTVM',
    number: 467
  },
  {
    name: 'BANCO MASTER',
    number: 243
  },
  {
    name: 'LISTO SCD S.A.',
    number: 397
  },
  {
    name: 'HAITONG BI DO BRASIL S.A.',
    number: 78
  },
  {
    name: 'INTERCAM CC LTDA',
    number: 525
  },
  {
    name: 'ÓTIMO SCD S.A.',
    number: 355
  },
  {
    name: 'VITREO DTVM S.A.',
    number: 367
  },
  {
    name: 'REAG DTVM S.A.',
    number: 528
  },
  {
    name: 'PLANTAE CFI',
    number: 445
  },
  {
    name: 'UP.P SEP S.A.',
    number: 373
  },
  {
    name: 'OLIVEIRA TRUST DTVM S.A.',
    number: 111
  },
  {
    name: 'FINVEST DTVM',
    number: 512
  },
  {
    name: 'QISTA S.A. CFI',
    number: 516
  },
  {
    name: 'BONUSPAGO SCD S.A.',
    number: 408
  },
  {
    name: 'MAF DTVM SA',
    number: 484
  },
  {
    name: 'COBUCCIO S.A. SCFI',
    number: 402
  },
  {
    name: 'SCFI EFÍ S.A.',
    number: 507
  },
  {
    name: 'SUMUP SCD S.A.',
    number: 404
  },
  {
    name: 'ZIPDIN SCD S.A.',
    number: 418
  },
  {
    name: 'LEND SCD S.A.',
    number: 414
  },
  {
    name: 'DM',
    number: 449
  },
  {
    name: 'MERCADO CRÉDITO SCFI S.A.',
    number: 518
  },
  {
    name: 'ACCREDITO SCD S.A.',
    number: 406
  },
  {
    name: 'CORA SCD S.A.',
    number: 403
  },
  {
    name: 'NUMBRS SCD S.A.',
    number: 419
  },
  {
    name: 'DELCRED SCD S.A.',
    number: 435
  },
  {
    name: 'FÊNIX DTVM LTDA.',
    number: 455
  },
  {
    name: 'CC LAR CREDI',
    number: 421
  },
  {
    name: 'CREDIHOME SCD',
    number: 443
  },
  {
    name: 'MARU SCD S.A.',
    number: 535
  },
  {
    name: 'UY3 SCD S/A',
    number: 457
  },
  {
    name: 'CREDSYSTEM SCD S.A.',
    number: 428
  },
  {
    name: 'HEMERA DTVM LTDA.',
    number: 448
  },
  {
    name: 'CREDIFIT SCD S.A.',
    number: 452
  },
  {
    name: 'FFCRED SCD S.A.',
    number: 510
  },
  {
    name: 'STARK SCD S.A.',
    number: 462
  },
  {
    name: 'CAPITAL CONSIG SCD S.A.',
    number: 465
  },
  {
    name: 'PORTOPAR DTVM LTDA',
    number: 306
  },
  {
    name: 'AZUMI DTVM',
    number: 463
  },
  {
    name: 'J17 - SCD S/A',
    number: 451
  },
  {
    name: 'TRINUS SCD S.A.',
    number: 444
  },
  {
    name: 'LIONS TRUST DTVM',
    number: 519
  },
  {
    name: 'MÉRITO DTVM LTDA.',
    number: 454
  },
  {
    name: 'UNAVANTI SCD S/A',
    number: 460
  },
  {
    name: 'RJI',
    number: 506
  },
  {
    name: 'SBCASH SCD',
    number: 482
  },
  {
    name: 'BNY MELLON BCO S.A.',
    number: 17
  },
  {
    name: 'PEFISA S.A. - C.F.I.',
    number: 174
  },
  {
    name: 'SUPERLÓGICA SCD S.A.',
    number: 481
  },
  {
    name: 'PEAK SEP S.A.',
    number: 521
  },
  {
    name: 'BR-CAPITAL DTVM S.A.',
    number: 433
  },
  {
    name: 'BCO LA PROVINCIA B AIRES BCE',
    number: 495
  },
  {
    name: 'HR DIGITAL SCD',
    number: 523
  },
  {
    name: 'ATICCA SCD S.A.',
    number: 527
  },
  {
    name: 'MAGNUM SCD',
    number: 511
  },
  {
    name: 'ATF CREDIT SCD S.A.',
    number: 513
  },
  {
    name: 'BANCO GENIAL',
    number: 125
  },
  {
    name: 'MICROCASH SCMEPP LTDA.',
    number: 537
  },
  {
    name: 'WNT CAPITAL DTVM',
    number: 524
  },
  {
    name: 'MONETARIE SCD',
    number: 526
  },
  {
    name: 'JPMORGAN CHASE BANK',
    number: 488
  },
  {
    name: 'RED SCD S.A.',
    number: 522
  },
  {
    name: 'SER FINANCE SCD S.A.',
    number: 530
  },
  {
    name: 'BCO ANDBANK S.A.',
    number: 65
  },
  {
    name: 'LEVYCAM CCV LTDA',
    number: 145
  },
  {
    name: 'BCV - BCO, CRÉDITO E VAREJO S.A.',
    number: 250
  },
  {
    name: 'BEXS CC S.A.',
    number: 253
  },
  {
    name: 'BCO HSBC S.A.',
    number: 269
  },
  {
    name: 'BCO ARBI S.A.',
    number: 213
  },
  {
    name: 'INTESA SANPAOLO BRASIL S.A. BM',
    number: 139
  },
  {
    name: 'BCO TRICURY S.A.',
    number: 18
  },
  {
    name: 'BCO SAFRA S.A.',
    number: 422
  },
  {
    name: 'BCO LETSBANK S.A.',
    number: 630
  },
  {
    name: 'BCO FIBRA S.A.',
    number: 224
  },
  {
    name: 'BCO VOLKSWAGEN S.A',
    number: 393
  },
  {
    name: 'BCO LUSO BRASILEIRO S.A.',
    number: 600
  },
  {
    name: 'BCO GM S.A.',
    number: 390
  },
  {
    name: 'BANCO PAN',
    number: 623
  },
  {
    name: 'BCO VOTORANTIM S.A.',
    number: 655
  },
  {
    name: 'BCO ITAUBANK S.A.',
    number: 479
  },
  {
    name: 'BCO MUFG BRASIL S.A.',
    number: 456
  },
  {
    name: 'BCO SUMITOMO MITSUI BRASIL S.A.',
    number: 464
  },
  {
    name: 'ITAÚ UNIBANCO S.A.',
    number: 341
  },
  {
    name: 'BCO BRADESCO S.A.',
    number: 237
  },
  {
    name: 'BCO MERCEDES-BENZ S.A.',
    number: 381
  },
  {
    name: 'OMNI BANCO S.A.',
    number: 613
  },
  {
    name: 'BCO SOFISA S.A.',
    number: 637
  },
  {
    name: 'BANCO VOITER',
    number: 653
  },
  {
    name: 'BCO CREFISA S.A.',
    number: 69
  },
  {
    name: 'BCO MIZUHO S.A.',
    number: 370
  },
  {
    name: 'BANCO INVESTCRED UNIBANCO S.A.',
    number: 249
  },
  {
    name: 'BCO BMG S.A.',
    number: 318
  },
  {
    name: 'BCO C6 CONSIG',
    number: 626
  },
  {
    name: 'AVENUE SECURITIES DTVM LTDA.',
    number: 508
  },
  {
    name: 'SAGITUR CC',
    number: 270
  },
  {
    name: 'BCO SOCIETE GENERALE BRASIL',
    number: 366
  },
  {
    name: 'NEON CTVM S.A.',
    number: 113
  },
  {
    name: 'TULLETT PREBON BRASIL CVC LTDA',
    number: 131
  },
  {
    name: 'C.SUISSE HEDGING-GRIFFO CV S/A',
    number: 11
  },
  {
    name: 'BCO PAULISTA S.A.',
    number: 611
  },
  {
    name: 'BOFA MERRILL LYNCH BM S.A.',
    number: 755
  },
  {
    name: 'CREDISAN CC',
    number: 89
  },
  {
    name: 'BCO PINE S.A.',
    number: 643
  },
  {
    name: 'NU INVEST CORRETORA DE VALORES S.A.',
    number: 140
  },
  {
    name: 'BCO DAYCOVAL S.A',
    number: 707
  },
  {
    name: 'CAROL DTVM LTDA.',
    number: 288
  },
  {
    name: 'SINGULARE CTVM S.A.',
    number: 363
  },
  {
    name: 'RENASCENCA DTVM LTDA',
    number: 101
  },
  {
    name: 'DEUTSCHE BANK S.A.BCO ALEMAO',
    number: 487
  },
  {
    name: 'BANCO CIFRA',
    number: 233
  },
  {
    name: 'GUIDE',
    number: 177
  },
  {
    name: 'TRUSTEE DTVM LTDA.',
    number: 438
  },
  {
    name: 'SIMPAUL',
    number: 365
  },
  {
    name: 'BCO RENDIMENTO S.A.',
    number: 633
  },
  {
    name: 'BCO BS2 S.A.',
    number: 218
  },
  {
    name: 'LASTRO RDV DTVM LTDA',
    number: 293
  },
  {
    name: 'FRENTE CC S.A.',
    number: 285
  },
  {
    name: 'B&T CC LTDA.',
    number: 80
  },
  {
    name: 'NOVO BCO CONTINENTAL S.A. - BM',
    number: 753
  },
  {
    name: 'BCO CRÉDIT AGRICOLE BR S.A.',
    number: 222
  },
  {
    name: 'CCR COOPAVEL',
    number: 281
  },
  {
    name: 'BANCO SISTEMA',
    number: 754
  },
  {
    name: 'DOURADA CORRETORA',
    number: 311
  },
  {
    name: 'CREDIALIANÇA CCR',
    number: 98
  },
  {
    name: 'BCO VR S.A.',
    number: 610
  },
  {
    name: 'BCO OURINVEST S.A.',
    number: 712
  },
  {
    name: 'BCO RNX S.A.',
    number: 720
  },
  {
    name: 'CREDICOAMO',
    number: 10
  },
  {
    name: 'CREDIBRF COOP',
    number: 440
  },
  {
    name: 'MAGNETIS - DTVM',
    number: 442
  },
  {
    name: 'RB INVESTIMENTOS DTVM LTDA.',
    number: 283
  },
  {
    name: 'BCO SANTANDER (BRASIL) S.A.',
    number: 33
  },
  {
    name: 'BANCO JOHN DEERE S.A.',
    number: 217
  },
  {
    name: 'BCO DO ESTADO DO RS S.A.',
    number: 41
  },
  {
    name: 'ADVANCED CC LTDA',
    number: 117
  },
  {
    name: 'BCO DIGIMAIS S.A.',
    number: 654
  },
  {
    name: 'WARREN CVMC LTDA',
    number: 371
  },
  {
    name: 'BANCO ORIGINAL',
    number: 212
  },
  {
    name: 'EFX CC LTDA.',
    number: 289
  }
];
