import { DSIcons } from 'ds/icons';

import { type ICardInfoTitleProps } from './CardInfoTitle.types';

import { Container } from './CardInfoTitle.styles';

export function CardInfoTitle({
  variant = 'medium',
  icon,
  children,
  ...rest
}: ICardInfoTitleProps): JSX.Element {
  if (icon) {
    const IconComponent = DSIcons[icon];

    return (
      <Container
        $variant={variant}
        {...rest}
      >
        <IconComponent /> {children}
      </Container>
    );
  }

  return (
    <Container
      $variant={variant}
      {...rest}
    >
      {children}
    </Container>
  );
}
