import { ApportionmentBlockForm } from 'presentation/pages/expenses/ExpensesList/components/ApportionmentBlock';
import { DefaultFieldsStep } from 'presentation/pages/expenses/ExpensesList/components/Modal/CreateRouteByMapModal';

import { useRecordRouteDefaultFieldsSteps } from './useRecordRouteDefaultFieldsSteps';

import { type IRecordRouteDefaultFieldsStepsProps } from './RecordRouteDefaultFieldsSteps.types';

import {
  Container,
  LeftContentWithRouteItems,
  MapContainerRightContent
} from './RecordRouteDefaultFieldsSteps.styles';

export function RecordRouteDefaultFieldsSteps({
  isMapLoaded,
  step,
  mapDirectionsResults,
  distance,
  mapRouteItems,
  setMapRouteItems,
  onChangeFormExpenseCurrency,
  readOnlyFieldsWhenUserIsUpdatingExpense,
  expenseUuidToUpdate
}: IRecordRouteDefaultFieldsStepsProps): JSX.Element {
  const {
    apportionmentFields,
    apportionmentReplace,
    handleClickSelectProjectButton,
    memoizedMap,
    memoizedRecordRouteStep
  } = useRecordRouteDefaultFieldsSteps({
    isMapLoaded,
    mapDirectionsResults,
    distance,
    mapRouteItems,
    setMapRouteItems,
    expenseUuidToUpdate
  });

  return (
    <>
      <Container $step={step}>
        {step === 'recordRoute' && (
          <LeftContentWithRouteItems>
            {memoizedRecordRouteStep}
          </LeftContentWithRouteItems>
        )}

        {step === 'defaultFields' && (
          <DefaultFieldsStep
            onChangeFormExpenseCurrency={onChangeFormExpenseCurrency}
            readOnlyFieldsWhenUserIsUpdatingExpense={
              readOnlyFieldsWhenUserIsUpdatingExpense
            }
            expenseUuidToUpdate={expenseUuidToUpdate}
            apportionmentFields={apportionmentFields}
            onClickSelectProjectButton={handleClickSelectProjectButton}
          />
        )}

        <MapContainerRightContent>{memoizedMap}</MapContainerRightContent>
      </Container>

      {step === 'defaultFields' && apportionmentFields.length > 0 && (
        <ApportionmentBlockForm
          onHideApportionmentBlock={() => {
            apportionmentReplace([]);
          }}
          expenseUuidToUpdate={expenseUuidToUpdate}
        />
      )}
    </>
  );
}
