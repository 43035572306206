import { type INavigationTabsTabProps } from './NavigationTabsTab.types';

import { Container, TabButton } from './NavigationTabsTab.styles';

export function NavigationTabsTab({
  active,
  children,
  containerWidth = '100%',
  ...rest
}: INavigationTabsTabProps): JSX.Element {
  return (
    <Container $containerWidth={containerWidth}>
      <TabButton
        $active={active}
        type='button'
        {...rest}
      >
        {children}
      </TabButton>
    </Container>
  );
}
