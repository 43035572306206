import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem 2rem;

  > h1 {
    font-weight: 300;
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
  }

  > div {
    margin-bottom: 1.2rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 90vw;
  }
`;
