import styled from 'styled-components';

export const Container = styled.ul`
  width: 100%;
  padding: 2rem;

  li {
    border-left: 1px solid ${({ theme }) => theme.colors.mercury};
    border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
    border-right: 1px solid ${({ theme }) => theme.colors.mercury};
    border-top: none;
  }

  li:first-child {
    border-top: 1px solid ${({ theme }) => theme.colors.mercury};
    text-transform: uppercase;
  }
`;

export const Item = styled.li`
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 2;
  color: ${({ theme }) => theme.colors.emperor};

  span:nth-child(2) {
    background: ${({ theme }) => theme.colors.danger};
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;
