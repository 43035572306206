import { useEffect, useRef } from 'react';

import { useAccordion } from 'ds/components/Accordion/AccordionContainer/AccordionContainer.context';
import { useDsGa4 } from 'ds/hooks/globals';

import {
  type IUseAccordionItem,
  type IUseAccordionItemParams
} from './AccordionItem.types';

export function useAccordionItem({
  onToggle
}: IUseAccordionItemParams): IUseAccordionItem {
  const { sendDsGaEvent } = useDsGa4();

  const context = useAccordion();

  const accordionItemRef = useRef<HTMLDetailsElement>(null);

  useEffect(() => {
    window?.addEventListener('accordion-toggle', (ev: Event) => {
      const isSame = accordionItemRef?.current === ev.target;

      if (!isSame && accordionItemRef?.current?.open) {
        (
          accordionItemRef?.current?.firstElementChild as
            | HTMLElement
            | undefined
        )?.click();
      }
    });
  }, []);

  if (context === null) {
    return {};
  }

  const { multiple } = context;

  function handleToggle(e: React.MouseEvent<HTMLElement, MouseEvent>): void {
    const isOpen = !e.currentTarget.parentElement?.hasAttribute('open');

    onToggle?.(isOpen);

    sendDsGaEvent('components', 'accordion', {
      description: isOpen ? 'Clicar para abrir' : 'Clicar para fechar',
      eventAction: isOpen ? 'open_accordion' : 'close_accordion',
      eventLabel: accordionItemRef.current?.firstElementChild?.textContent
        ? accordionItemRef.current?.firstElementChild?.textContent
        : 'accordion'
    });

    if (!multiple && isOpen) {
      const event = new Event('accordion-toggle', {
        bubbles: true
      });

      accordionItemRef?.current?.dispatchEvent(event);
    }
  }

  return {
    accordionItemRef,
    handleToggle
  };
}
