import { useState } from 'react';

import {
  type IUseSelect,
  type OnChangeSelectType,
  type UseSelectParamsType
} from './Select.types';

export function useSelect({ options }: UseSelectParamsType): IUseSelect {
  const [searchValue, setSearchValue] = useState<string>('');

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchValue.toLowerCase())
  );

  function handleSelectFilteredOptions(
    value: string | string[],
    onChange: OnChangeSelectType
  ): void {
    onChange([
      ...value,
      ...filteredOptions
        .filter(option => !value.includes(option.value))
        .map(option => option.value)
    ]);
    setSearchValue('');
  }

  return {
    searchValue,
    setSearchValue,
    filteredOptions,
    handleSelectFilteredOptions
  };
}
