/**
 * Todos os steps do onboarding que não devem possuir a barra de progresso.
 */
export const stepsWithoutProgressBar = [
  '/cartoes/onboarding/empresa/em-progresso',
  '/cartoes/onboarding/empresa/kyc',
  '/cartoes/onboarding/empresa/approve',
  '/cartoes/onboarding/usuario/kyc',
  '/cartoes/onboarding/empresa/final'
];
