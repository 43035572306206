import { Modal, tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type IActionModalStylesType } from './ActionModal.types';

export const ModalContent = styled(Modal.Content)<
  AddPrefixToType<IActionModalStylesType>
>`
  padding: 0;

  ${({ $disabledFixedHeight }) =>
    $disabledFixedHeight &&
    css`
      min-height: auto;
    `}
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${tokens.spacingXs};
`;
