import {
  type IPersistenceSendAnalysisSuggestionPayload,
  type ISendAnalysisSuggestionPayload
} from 'data/modules/analytics';

class SendAnalysisSuggestionMapper {
  toPersistence(
    payload: ISendAnalysisSuggestionPayload
  ): IPersistenceSendAnalysisSuggestionPayload {
    return {
      type: payload.type,
      how_see: payload.howSee,
      contact: payload.contact,
      frequency: payload.frequency,
      objective: payload.objective
    };
  }
}

export default new SendAnalysisSuggestionMapper();
