import {
  DangerButton,
  DefaultButton,
  SecondaryButton,
  SkeletonLoader
} from 'ds';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuthContext, useModalContext } from 'data/contexts';

import { CardsPageHeader } from 'presentation/pages/cards/components';

import { animations } from 'shared/constants/animations';
import { Currency } from 'shared/utils/format';

import {
  ActivateCardModal,
  BlockAndUnblockCardModal,
  ChangePinModal,
  ExpensesCards,
  RequestValueModal,
  UnblockCardPermissionDeniedModal
} from '../';

import { useMyCardPageHeader } from './useMyCardPageHeader';

import {
  ActionsContainer,
  Container,
  ContentCard,
  CreditCardCustom,
  DangerSecondaryButton
} from './MyCardPageHeader.styles';

export function MyCardPageHeader(): JSX.Element {
  const { t } = useTranslation('cards');
  const navigate = useNavigate();

  const user = useAuthContext(state => state.user);

  const { visibleModal, handleOpenModal } = useModalContext();

  const {
    availableValue,
    cardData,
    userHasCard,
    isLoading,
    handleBlockUnblockCard
  } = useMyCardPageHeader();

  return (
    <>
      {user?.cards?.type === 'USER' &&
      !user?.cards?.isCardGroupManager ? null : (
        <CardsPageHeader
          tabs={[
            {
              active: false,
              label: t('cardsManagement'),
              hidden:
                user?.cards?.type === 'USER' && !user?.cards.isCardGroupManager,
              onClick: () => {
                user?.cards?.isCardGroupManager
                  ? navigate('/cartoes/grupos-de-cartoes')
                  : navigate('/cartoes/gestao');
              }
            },
            {
              active: false,
              label: t('movements'),
              hidden: user?.cards?.type === 'USER',
              onClick: () => {
                navigate('/cartoes/movimentacoes');
              }
            },
            {
              active: false,
              label: t('valuesSolicitations'),
              hidden: user?.cards?.type !== 'ACCOUNT_MANAGER',
              onClick: () => {
                navigate('/cartoes/solicitacoes-de-valores');
              }
            },
            {
              active: true,
              label: t('myCard')
            }
          ]}
        />
      )}

      <Container {...animations.popFadeInOut}>
        <ContentCard>
          <CreditCardCustom
            cardInfo={{
              name: user?.cards?.name as string,
              number: (cardData?.panMasked as string) ?? null,
              availableBalance: Currency.format(
                'BRL',
                availableValue as number,
                true
              ),
              status: cardData?.status || null
            }}
            isLoading={isLoading}
            $notLinked={!userHasCard}
          />

          <ActionsContainer $notLinked={!userHasCard}>
            {isLoading ? (
              <>
                {Array.from({
                  length: 3
                }).map((item, index) => (
                  <SkeletonLoader
                    key={index}
                    height='4rem'
                    color='neutralColorLowLightest'
                    margin='0.3rem auto'
                  />
                ))}
              </>
            ) : (
              <>
                {!userHasCard ? (
                  <>
                    <DefaultButton
                      size='small'
                      onClick={() => {
                        handleOpenModal('activateCard');
                      }}
                    >
                      {t('activateCard')}
                    </DefaultButton>
                  </>
                ) : (
                  <>
                    <DefaultButton
                      size='small'
                      onClick={() => {
                        handleOpenModal('requestValueMyCard');
                      }}
                    >
                      {t('requestValue')}
                    </DefaultButton>

                    {cardData?.status === 'ACTIVE' ? (
                      <DangerButton
                        size='small'
                        onClick={() => {
                          handleBlockUnblockCard('block');
                        }}
                      >
                        {t('blockCard')}
                      </DangerButton>
                    ) : (
                      <DangerSecondaryButton
                        size='small'
                        onClick={() => {
                          handleBlockUnblockCard('unblock');
                        }}
                      >
                        {t('unblockCard')}
                      </DangerSecondaryButton>
                    )}

                    <SecondaryButton
                      size='small'
                      onClick={() => {
                        handleOpenModal('changeCardPin');
                      }}
                      disabled={cardData?.status === 'BLOCKED'}
                    >
                      {t('changePin')}
                    </SecondaryButton>
                  </>
                )}
              </>
            )}
          </ActionsContainer>
        </ContentCard>

        <ExpensesCards />

        {visibleModal === 'requestValueMyCard' && <RequestValueModal />}

        {visibleModal === 'blockCard' && <BlockAndUnblockCardModal />}

        {visibleModal === 'activateCard' && <ActivateCardModal />}

        {visibleModal === 'changeCardPin' && <ChangePinModal />}

        {visibleModal === 'unblockCardPermissionDenied' && (
          <UnblockCardPermissionDeniedModal
            lockLevel={cardData?.lockLevel as 'MANAGER' | 'SYSTEM'}
          />
        )}
      </Container>
    </>
  );
}
