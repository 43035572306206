import { Navigate, Route } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useAuthContext, useFlagsContext } from 'data/contexts';

import {
  AdminMovements,
  AdminStatement,
  CardGroups,
  Cards,
  ChargeCards,
  GroupManagement,
  MyMovements,
  MyValueSolicitations,
  UserStatement,
  ValueSolicitation
} from 'presentation/pages/cards';

import { CardsRoutes } from 'shared/constants/global';
import {
  AuthenticationMiddleware,
  CardGroupsMiddleware,
  CardsManagerMiddleware,
  CardsMiddleware,
  UserMiddleware
} from 'shared/middlewares';

export interface IUseCardsRoutes {
  managerRoutes: JSX.Element[];
  userRoutes: JSX.Element[];
}

export interface IGetShouldShowPage {
  pageEnabledAt: string | undefined | null;
  isClassicModeEnabled: boolean;
  pageTrialAge: number | null;
  pageVersion: 'REDESIGN' | 'OLD';
}

export function useCardsRoutes(): IUseCardsRoutes {
  const [adminMovementsFlag, redesignMyCardFlag] = useFlagsContext(
    useShallow(state => [state.adminMovementsFlag, state.redesignMyCardFlag])
  );

  const user = useAuthContext(state => state.user);

  const managerRoutes = [
    <Route
      key='gestao'
      element={<AuthenticationMiddleware />}
    >
      <Route element={<CardGroupsMiddleware />}>
        <Route element={<CardsMiddleware />}>
          <Route element={<CardsManagerMiddleware />}>
            <Route
              path={CardsRoutes.CARDS_MANAGEMENT}
              element={<Cards />}
            />
          </Route>
        </Route>
      </Route>
      <Route element={<CardGroupsMiddleware />}>
        <Route element={<CardsMiddleware />}>
          <Route element={<CardsManagerMiddleware />}>
            <Route
              path={CardsRoutes.CARDS_CARD_GROUPS}
              element={<CardGroups />}
            />
          </Route>
        </Route>
      </Route>
      <Route element={<CardGroupsMiddleware />}>
        <Route element={<CardsMiddleware />}>
          <Route element={<CardsManagerMiddleware />}>
            <Route
              path={CardsRoutes.CARDS_CARD_GROUPS_GROUP}
              element={<GroupManagement />}
            />

            <Route
              path={CardsRoutes.CARDS_CARD_GROUPS_VALUE_ALLOCATION}
              element={<ChargeCards />}
            />

            <Route element={<CardsManagerMiddleware />}>
              <Route
                path={CardsRoutes.CARDS_CARD_GROUPS_VALUE_SOLICITATION}
                element={<ValueSolicitation />}
              />
            </Route>

            <Route
              path={CardsRoutes.CARDS_CARD_GROUPS_MOVEMENTS}
              element={
                adminMovementsFlag ? <AdminMovements /> : <AdminStatement />
              }
            />

            <Route
              path={CardsRoutes.CARDS_CARD_GROUPS_STATEMENT}
              element={<AdminStatement />}
            />
          </Route>
        </Route>
      </Route>
      <Route element={<CardsMiddleware />}>
        <Route element={<CardsManagerMiddleware onlyAccountManager />}>
          <Route
            path={CardsRoutes.CARDS_VALUE_SOLICITATION}
            element={<ValueSolicitation />}
          />
        </Route>
      </Route>
      <Route element={<CardsMiddleware />}>
        <Route element={<CardsManagerMiddleware onlyAccountManager />}>
          <Route
            path={CardsRoutes.CARDS_BALANCE_SOLICITATION}
            element={<Navigate to='/cartoes/solicitacoes-de-valores' />}
          />
        </Route>
      </Route>
      <Route element={<CardsMiddleware />}>
        <Route element={<CardsManagerMiddleware onlyAccountManager />}>
          <Route
            path={CardsRoutes.CARDS_VALUE_ALLOCATION}
            element={<ChargeCards />}
          />
        </Route>
      </Route>
      <Route element={<CardsMiddleware />}>
        <Route element={<CardsManagerMiddleware onlyAccountManager />}>
          <Route
            path={CardsRoutes.CARDS_BALANCE_ALLOCATION}
            element={<Navigate to='/cartoes/alocacao-de-valores' />}
          />
        </Route>
      </Route>
      <Route element={<CardsMiddleware />}>
        <Route element={<CardsManagerMiddleware />}>
          <Route
            path={CardsRoutes.CARDS_MOVEMENTS}
            element={<AdminMovements />}
          />
        </Route>
      </Route>
      <Route element={<CardsMiddleware />}>
        <Route element={<CardsManagerMiddleware />}>
          <Route
            path={CardsRoutes.CARDS_STATEMENT}
            element={<AdminStatement />}
          />
        </Route>
      </Route>
      <Route element={<CardsMiddleware />}>
        <Route element={<CardsManagerMiddleware />}>
          <Route
            path={CardsRoutes.CARDS_STATEMENT_USER_ID}
            element={<AdminStatement />}
          />
        </Route>
      </Route>
    </Route>
  ];

  const userRoutes = [
    <Route
      element={<AuthenticationMiddleware />}
      key='user'
    >
      <Route element={<CardsMiddleware />}>
        <Route element={<UserMiddleware />}>
          <Route
            path={CardsRoutes.CARDS_MY_CARD}
            element={
              redesignMyCardFlag ? (
                <Navigate
                  to={`/cartoes/meu-cartao/minhas-movimentacoes/${user?.cards?.id}`}
                />
              ) : (
                <UserStatement />
              )
            }
          />
        </Route>
      </Route>

      <Route element={<CardsMiddleware />}>
        <Route element={<UserMiddleware />}>
          <Route
            path={CardsRoutes.CARDS_MY_CARD_MY_MOVEMENTS}
            element={redesignMyCardFlag && <MyMovements />}
          />
        </Route>
      </Route>

      <Route element={<CardsMiddleware />}>
        <Route element={<UserMiddleware />}>
          <Route
            path={CardsRoutes.CARDS_MY_CARD_MY_SOLICITATIONS}
            element={redesignMyCardFlag && <MyValueSolicitations />}
          />
        </Route>
      </Route>
    </Route>
  ];

  return {
    managerRoutes,
    userRoutes
  };
}
