import { useCallback, useEffect, useMemo, useState } from 'react';

import { type InfiniteData, useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds';
import useWebSocket from 'react-use-websocket';

import { env } from 'data/config';
import { useAuthContext, useLangContext } from 'data/contexts';
import {
  type INewNotification,
  type INotification,
  NotificationsQueryKeys,
  useGetNotifications,
  useReadAllNotifications,
  useReadNotification,
  useUnreadNotification,
  type WithNotificationsHeadersType
} from 'data/modules/notifications';

import { useGa4 } from 'shared/hooks/global';
import { CustomCookies } from 'shared/utils/global';

import { type IUseNotifications } from './Notifications.types';

export function useNotifications(): IUseNotifications {
  const user = useAuthContext(state => state.user);

  const { sendGaEvent } = useGa4();

  const {
    currentLangKey,
    lang: { global }
  } = useLangContext();

  const queryClient = useQueryClient();

  const {
    fetchNotificationsNextPage,
    hasNextPageNotifications,
    isFetchingNotificationsNextPage,
    isLoadingNotifications,
    notifications,
    countUnread
  } = useGetNotifications({
    userId: user?.id ?? 0,
    enabled: Boolean(user?.id)
  });

  const { readNotification, isReadingNotification } = useReadNotification();

  const { unreadNotification, isUnreadingNotification } =
    useUnreadNotification();

  const { readAllNotifications, isReadingAllNotifications } =
    useReadAllNotifications();

  const [newNotificationArrived, setNewNotificationArrived] = useState(false);

  const [
    notificationReadingStatusChangingIds,
    setNotificationReadingStatusChangingIds
  ] = useState<INotification['id'][]>([]);

  const websocketToken = CustomCookies.get('websocket_token');

  const { lastJsonMessage: lastNotificationJson } = useWebSocket(
    `${env.VITE_WEBSOCKET_URL}?token=${websocketToken}`,
    {
      share: true
    },
    Boolean(websocketToken)
  );

  /**
   * Manipula o evento de uma nova notificação. Esta função é utilizada como callback
   * para lidar com a exibição de notificações recebidas. Ela verifica se a última notificação
   * está presente e ainda não foi processada. Em caso afirmativo, exibe a notificação usando
   * o componente de toasts e atualiza os dados em cache de notificações para refletir a
   * chegada da nova notificação.
   *
   * @function
   * @memberof Notifications
   * @returns {void}
   * @inner
   *
   * @listens INewNotification
   * @listens ToastNotification
   *
   * @param {string} currentLangKey - A chave do idioma atual.
   * @param {Object} global.now - Tradução da palavra 'Agora'.
   * @param {Object} lastNotificationJson - Última notificação recebida no formato INewNotification.
   * @param {Array<INotification>} notifications - Lista atual de notificações.
   * @param {Object} queryClient - Instância do cliente de consulta para atualizar os dados em cache.
   *
   * @throws {Error} Lança um erro se a última notificação não estiver no formato esperado.
   */
  const handleOnNewNotification = useCallback(() => {
    if (!lastNotificationJson) return;

    const { notification, ...data } = lastNotificationJson as INewNotification;

    if (
      !data.id ||
      !notification?.title ||
      !notification?.message ||
      notifications.find(notification => notification.id === data.id)
    ) {
      return;
    }

    setNewNotificationArrived(true);

    toast.notification(
      String(data.id),
      notification.title,
      notification.message,
      global.now[currentLangKey],
      () => {
        if (!notification.url) return;

        window.location.href = notification.url;
      }
    );

    queryClient.setQueriesData<
      InfiniteData<WithNotificationsHeadersType<INotification[]>>
    >({ queryKey: [NotificationsQueryKeys.GET_NOTIFICATIONS] }, old => {
      const newFirstPage = [
        ...new Map(
          [
            {
              id: data.id,
              readAt: null,
              isRead: false,
              recipient: '',
              requestId: '',
              type: data.type,
              communicationChannel: 'websocket',
              createdAt: new Date().toISOString(),
              content: {
                notification,
                type: data.type,
                userId: data.userId
              }
            },
            ...(old?.pages[0]?.data ?? [])
          ].map(item => [item.id, item])
        ).values()
      ] as INotification[];

      return old
        ? {
            ...old,
            pages: old.pages.map((page, idx) => ({
              data: idx === 0 ? newFirstPage : page.data,
              headers: {
                ...page.headers,
                countUnread: page.headers.countUnread + 1
              }
            }))
          }
        : {
            pageParams: [],
            pages: [
              {
                data: newFirstPage,
                headers: {
                  count: 1,
                  totalPages: 1,
                  currentPage: 1,
                  countUnread: 1
                }
              }
            ]
          };
    });
  }, [
    lastNotificationJson,
    currentLangKey,
    notifications,
    queryClient,
    global.now
  ]);

  useEffect(() => {
    handleOnNewNotification();
  }, [handleOnNewNotification]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (newNotificationArrived) {
      timer = setTimeout(() => {
        setNewNotificationArrived(false);
      }, 2000);
    }

    return () => {
      timer && clearTimeout(timer);
    };
  }, [newNotificationArrived]);

  function updateNotificationIsReadCache(
    id: INotification['id'],
    isRead: boolean
  ): void {
    queryClient.setQueriesData<
      InfiniteData<WithNotificationsHeadersType<INotification[]>>
    >({ queryKey: [NotificationsQueryKeys.GET_NOTIFICATIONS] }, old => {
      if (!old) return undefined;

      return {
        ...old,
        pages: old.pages.map(page => ({
          headers: {
            ...page.headers,
            countUnread: page.headers.countUnread + (isRead ? -1 : 1)
          },
          data: page.data.map(notification => ({
            ...notification,
            isRead: notification.id === id ? isRead : notification.isRead
          }))
        }))
      };
    });
  }

  function updateAllNotificationIsReadCache(): void {
    queryClient.setQueriesData<
      InfiniteData<WithNotificationsHeadersType<INotification[]>>
    >({ queryKey: [NotificationsQueryKeys.GET_NOTIFICATIONS] }, old => {
      if (!old) return undefined;

      return {
        ...old,
        pages: old.pages.map(page => ({
          headers: {
            ...page.headers,
            countUnread: 0
          },
          data: page.data.map(notification => ({
            ...notification,
            isRead: true
          }))
        }))
      };
    });
  }

  function toggleNotificationLoading(id: INotification['id']): void {
    setNotificationReadingStatusChangingIds(prev =>
      prev.includes(id)
        ? prev.filter(notificationId => notificationId !== id)
        : [...new Set([...prev, id])]
    );
  }

  async function handleReadNotification(
    id: INotification['id']
  ): Promise<void> {
    const isRead = notifications.find(
      notification => notification.id === id
    )?.isRead;

    if (isRead || isReadingNotification) return;

    toggleNotificationLoading(id);

    try {
      await readNotification({ id });
      updateNotificationIsReadCache(id, true);
    } catch (error) {
      toast.error((error as Error)?.message);
    } finally {
      toggleNotificationLoading(id);
    }
  }

  async function handleUnreadNotification(
    id: INotification['id']
  ): Promise<void> {
    const isRead = notifications.find(
      notification => notification.id === id
    )?.isRead;

    if (!isRead || isUnreadingNotification) return;

    toggleNotificationLoading(id);

    try {
      await unreadNotification({ id });
      updateNotificationIsReadCache(id, false);

      sendGaEvent('notifications', '-notification_markUnread');
    } catch (error) {
      toast.error((error as Error)?.message);
    } finally {
      toggleNotificationLoading(id);
    }
  }

  function handleNotificationClick(id: INotification['id']): () => void {
    return () => {
      sendGaEvent('notifications', 'notification_click');

      handleReadNotification(id);

      const notification = notifications.find(
        notification => notification.id === id
      );

      if (!notification) return;

      const url = notification.content?.notification?.url;

      if (!url) return;
      window.location.href = url;
    };
  }

  function getMenuIsLoading(id: INotification['id']): boolean {
    return notificationReadingStatusChangingIds.includes(id);
  }

  async function handleReadAllNotificationsClick(): Promise<void> {
    try {
      await readAllNotifications({
        userId: user?.id ?? 0
      });
      updateAllNotificationIsReadCache();
    } catch (error) {
      toast.error((error as Error)?.message);
    }
  }

  function handleGetNextNotificationsPageClick(): void {
    if (isLoadingNotifications) return;

    fetchNotificationsNextPage();
  }

  const loadingMoreStatus = useMemo(
    () =>
      !hasNextPageNotifications
        ? 'loaded'
        : isFetchingNotificationsNextPage
          ? 'loading'
          : 'default',
    [hasNextPageNotifications, isFetchingNotificationsNextPage]
  );

  return {
    countUnread,
    getMenuIsLoading,
    loadingMoreStatus,
    handleReadNotification,
    handleNotificationClick,
    handleUnreadNotification,
    isReadingAllNotifications,
    handleReadAllNotificationsClick,
    handleGetNextNotificationsPageClick,
    notifications: notifications.map(notification => ({
      id: notification.id,
      type: notification.type,
      isRead: notification.isRead,
      createdAt: notification.createdAt,
      title: notification?.content?.notification?.title,
      url: notification?.content?.notification?.url ?? '',
      message: notification?.content?.notification?.message,
      subMessage: notification?.content?.notification?.subMessage,
      icon: notification?.content?.notification?.icon ?? 'default'
    })),
    newNotificationArrived
  };
}
