import { DSIcons, tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type RouteInputStylesType } from './RouteInput.types';
import { type AddPrefixToType } from 'ds/types';

export const Legend = styled.legend`
  opacity: 0;
  visibility: hidden;
  padding: 0;
  overflow: hidden;
  max-width: 0.001rem;
  white-space: nowrap;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};

  span {
    opacity: 0;
    visibility: visible;
  }
`;

export const Fieldset = styled.fieldset`
  text-align: left;
  position: absolute;
  inset: -5px 0 0;
  pointer-events: none;
  overflow: hidden;
  min-width: 0%;
  padding: 0 ${tokens.spacingXs};
  border-radius: ${tokens.borderRadiusXs};
  border-color: ${tokens.neutralColorLowLight};
  border-width: ${tokens.borderWidthHairline};
  border-style: solid;
  top: -1rem;
  transition: border 100ms ease-in-out;
`;

export const StyledRemoveIcon = styled(DSIcons.RemoveIcon)`
  cursor: pointer;

  * {
    color: ${tokens.neutralColorLowDark};
  }
`;

export const Caption = styled.span<AddPrefixToType<RouteInputStylesType>>`
  display: inline-block;
  color: ${tokens.neutralColorLowLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  margin-top: ${tokens.spacingInsetNano};
  margin-left: 4rem;

  ${({ $hasError }) =>
    $hasError &&
    css`
      color: ${tokens.feedbackColorErrorPure} !important;
    `}
`;

export const DragAndDropButton = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  cursor: grab;

  > svg {
    width: 2.2rem;
    height: 2.2rem;
    opacity: 0.3;
    transition: opacity 100ms ease-in-out;
  }
`;

export const DragContainer = styled.div<AddPrefixToType<RouteInputStylesType>>`
  ${({ $isDragging }) =>
    $isDragging &&
    css`
      button > svg {
        * {
          color: ${tokens.brandColorSecondaryPure} !important;
        }
      }

      ${StyledRemoveIcon} {
        visibility: hidden;
      }

      & > div,
      ${Caption} {
        transform: rotateZ(-2deg);
      }

      cursor: grabbing;

      * {
        cursor: grabbing;
      }

      ${DragAndDropButton} {
        cursor: grabbing;
      }
    `}
`;

export const Wrapper = styled.div<AddPrefixToType<RouteInputStylesType>>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${tokens.spacingXs};
  position: relative;

  * {
    letter-spacing: 0.075rem;
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
    flex-shrink: 0;
  }
`;

export const WrapperFieldset = styled.div`
  width: 100%;
  position: relative;
`;

export const Container = styled.div<AddPrefixToType<RouteInputStylesType>>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: border 100ms ease-in-out;
  gap: ${tokens.spacingInsetNano};
  padding: ${tokens.spacingNano} ${tokens.spacingXs};
  min-width: 25.2rem;
  width: 100%;
  background-color: ${tokens.neutralColorHighPure};

  div {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &:focus-within {
    background-color: ${tokens.neutralColorHighPure};

    ~ ${Fieldset} {
      border-color: ${tokens.brandColorSecondaryPure};
      border-width: ${tokens.borderWidthThin};

      ${Legend} {
        opacity: 1;
        visibility: visible;
        max-width: unset;
        overflow: visible;
        padding: 0 ${tokens.spacingQuarck};
      }
    }

    span {
      color: ${tokens.brandColorSecondaryPure};
      opacity: 1;
    }

    input {
      color: ${tokens.neutralColorLowDarkest};
    }

    svg {
      opacity: 1;
    }
  }

  ${({ $isTouched }) =>
    $isTouched &&
    css`
      ~ ${Fieldset} {
        border-color: ${tokens.neutralColorLowMedium};
        border-width: ${tokens.borderWidthHairline};

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      input {
        color: ${tokens.neutralColorLowDarkest};
      }

      span,
      svg {
        opacity: 1;
      }
    `}

  ${({ $large }) =>
    $large &&
    css`
      padding-top: ${tokens.spacingXxs} !important;
      padding-bottom: ${tokens.spacingXxs} !important;
      min-width: 25.2rem;

      input {
        font-size: ${tokens.fontSizeXs} !important;
      }

      span {
        font-size: ${tokens.fontSizeXxs};
      }

      ~ ${Fieldset} {
        ${Legend} span {
          font-size: ${tokens.fontSizeXxs};
        }
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${tokens.neutralColorHighLight} !important;

      ~ ${Fieldset} {
        border-color: ${tokens.neutralColorLowLightest} !important;
        border-width: ${tokens.borderWidthHairline} !important;

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      * {
        color: ${tokens.neutralColorLowMediumLight} !important;
      }

      span {
        opacity: 1;
      }
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      ~ ${Fieldset} {
        border-color: ${tokens.feedbackColorErrorPure} !important;
        border-width: ${tokens.borderWidthThin} !important;

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      span,
      svg {
        color: ${tokens.feedbackColorErrorPure} !important;
        opacity: 1;
      }
    `}

  ${({ $small }) =>
    $small &&
    `
    min-height: 4.1rem;
    max-height: 4.1rem;
    padding: 0.04rem 1.6rem;
    background-color: ${tokens.neutralColorHighPure};
  `}
`;

export const InputComponent = styled.input`
  border: none !important;
  outline: none !important;
  width: 100%;
  background-color: transparent !important;
  color: ${tokens.neutralColorLowLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
  padding: ${tokens.spacingNano};
  line-height: ${tokens.spacingInsetMd};
  font-size: ${tokens.fontSizeXxs};
`;

export const Label = styled.span`
  position: absolute;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  top: -1.5rem;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  padding: ${tokens.spacingQuarck};
`;
