import { useState, type WheelEvent } from 'react';

import { animations } from 'ds/constants';
import { useGrabAndScroll } from 'ds/hooks/globals/useGrabAndScroll/useGrabAndScroll';
import { DSIcons } from 'ds/icons';

import { useImageFullScreen } from './useImageFullScreen';

import { type IImageFullScreenProps } from './ImageFullScreen.types';

import {
  CloseButton,
  Container,
  Image,
  ImageContainer,
  ImageZoom,
  ImageZoomContainer,
  MenuContainer,
  Overlay,
  OverlayScroll,
  Percentage,
  Slide
} from './ImageFullScreen.styles';

export function ImageFullScreen({
  style,
  overlayStyle,
  closeOnClickOutside,
  onClose,
  hasZoomControl,
  showZoomTour,
  url
}: IImageFullScreenProps): JSX.Element {
  const {
    handleClickOutsideImage,
    handleWheelZoom,
    minusZoom,
    plusZoom,
    imageZoom
  } = useImageFullScreen({
    onClose,
    closeOnClickOutside,
    hasZoomControl,
    showZoomTour
  });

  const { ref: containerRef } = useGrabAndScroll<HTMLDivElement>();

  if (hasZoomControl) {
    return (
      <Container
        onClick={handleClickOutsideImage}
        style={style}
        onWheel={handleWheelZoom}
        data-testid='image-full-screen-with-zoom'
      >
        <OverlayScroll
          {...animations.fadeInOut}
          style={overlayStyle}
        >
          <MenuContainer className='zoom-menu-container'>
            <DSIcons.ZoomMinusIcon
              onClick={e => {
                e.stopPropagation();
                minusZoom();
              }}
            />

            <Percentage>{imageZoom * 100}%</Percentage>

            <DSIcons.ZoomPlusIcon
              onClick={e => {
                e.stopPropagation();
                plusZoom();
              }}
            />
          </MenuContainer>

          <CloseButton
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
          >
            ×
          </CloseButton>

          <ImageZoomContainer ref={containerRef}>
            <ImageZoom
              className='zoom-image'
              $zoom={imageZoom}
              onClick={e => {
                e.stopPropagation();
              }}
              src={url}
            />
          </ImageZoomContainer>
        </OverlayScroll>
      </Container>
    );
  }

  return (
    <Container
      onClick={handleClickOutsideImage}
      style={style}
      data-testid='image-full-screen'
    >
      <Overlay
        {...animations.fadeInOut}
        style={overlayStyle}
      >
        <CloseButton
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
        >
          ×
        </CloseButton>
        <ImageContainer>
          <Slide>
            <Image
              onClick={e => {
                e.stopPropagation();
              }}
              src={url}
            />
          </Slide>
        </ImageContainer>
      </Overlay>
    </Container>
  );
}
