import { Select, TextInput } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;
  height: fit-content;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
    gap: 2.4rem;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 40rem;
  }
`;

export const StyledSearch = styled(TextInput.Default)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 23rem;
    height: 3.6rem;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 27rem;
  }
`;

export const MultiSelectContainer = styled.div`
  width: 100%;

  .react-multiSelect-custom__control {
    max-width: unset;
    min-width: unset;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: fit-content;
    min-width: 27rem;
    max-width: 36.5rem;
  }
`;

export const StyledSelect = styled(Select.Default)`
  width: 100%;
  height: 4.1rem;

  .react-select-custom__control {
    height: 4.1rem;
    min-width: unset;
  }
`;
