import { useQueryCache } from 'data/cache';
import {
  FuelQueryKeys,
  FuelService,
  type IUseGetTypesOfVehicles
} from 'data/modules/fuel';

export function useGetTypesOfVehicles(): IUseGetTypesOfVehicles {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [FuelQueryKeys.GET_TYPES_OF_VEHICLES],
    queryFn: async () => await FuelService.getTypesOfVehicles()
  });

  return {
    typesOfVehicles: data ?? [],
    isLoadingTypesOfVehicles: isLoading || isFetching
  };
}
