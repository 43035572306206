import { useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds';
import { useTranslation } from 'react-i18next';

import { useLangContext, useModalContext } from 'data/contexts';
import {
  ExpensesQueryKeys,
  useGetExpenseParameters
} from 'data/modules/expenses';
import {
  ReportsQueryKeys,
  useGetOpenReports,
  useLinkExpensesToReport
} from 'data/modules/reports';

import { useDebounce } from 'shared/hooks/global';
import { ErrorHandle } from 'shared/utils/errors';

import {
  type IUseAddExpensesToReportModal,
  type IUseAddExpensesToReportModalParams
} from './AddExpensesToReportModal.types';

export function useAddExpensesToReportModal({
  selectedExpenses,
  onResetSelectedExpenses
}: IUseAddExpensesToReportModalParams): IUseAddExpensesToReportModal {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedReport, setSelectedReport] = useState('');
  const { currentLangKey, lang } = useLangContext();
  const { t } = useTranslation('expenses');

  const { handleCloseModal, handleChangeErrorMessage } = useModalContext();

  const queryClient = useQueryClient();

  const { expenseParameters, isLoadingExpenseParameters } =
    useGetExpenseParameters();

  const [showCreateReportForm, setShowCreateReportForm] = useState(false);

  const { isLoadingOpenReports, openReports } = useGetOpenReports();

  const debouncedSearchTerm = useDebounce(searchTerm);

  const filteredOpenReports = useMemo(() => {
    return (
      openReports?.filter(
        ({ title, uuid }) =>
          title.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          uuid.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      ) || []
    );
  }, [openReports, debouncedSearchTerm]);

  const { isLinkingExpensesToReport, linkExpensesToReport } =
    useLinkExpensesToReport({
      onSuccess: async () => {
        queryClient.invalidateQueries({
          queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES]
        });

        queryClient.invalidateQueries({
          queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
        });

        queryClient.invalidateQueries({
          queryKey: [ReportsQueryKeys.GET_OPEN_REPORTS]
        });

        selectedExpenses.forEach(expense => {
          queryClient.removeQueries({
            queryKey: [ExpensesQueryKeys.GET_EXPENSE, expense.uuid]
          });
        });

        toast.success(
          lang.expenses.modal.linked_expenses_with_success[currentLangKey]
        );

        onResetSelectedExpenses();
        handleCloseModal();
      },
      onError: e => {
        const message =
          ErrorHandle.getErrorMessage(undefined, e) ??
          t('modal.errorWhenLinkingExpenseToReport');

        handleChangeErrorMessage({
          title: t('modal.errorWhenLinkingExpenseToReport'),
          message
        });
      }
    });

  function handleChangeSearchTerm(value: string): void {
    setSearchTerm(value);
  }

  async function handleLinkExpensesToReport(): Promise<void> {
    if (isLinkingExpensesToReport || !selectedReport) return;

    linkExpensesToReport({
      expenses: selectedExpenses.map(item => item.uuid),
      uuid: selectedReport ?? ''
    });
  }

  const reportCurrencyIsoCode = openReports?.find(
    openReport => openReport.uuid === selectedReport
  )?.currency.isoCode;

  const selectedExpensesCurrencyIsoCode = selectedExpenses[0].currency;

  const checkIfSelectedExpensesCurrencyIsDifferentFromReportCurrency =
    !!reportCurrencyIsoCode &&
    selectedExpensesCurrencyIsoCode !== reportCurrencyIsoCode;

  const selectedReportCostsCenterUuid = openReports?.find(
    openReport => openReport.uuid === selectedReport
  )?.costsCenter?.uuid;

  const selectedReportCostsCenterName =
    openReports?.find(openReport => openReport.uuid === selectedReport)
      ?.costsCenter?.name ?? '';

  const expensesWithDifferentCostsCenter = selectedExpenses.filter(
    expense => expense.costCenter?.uuid !== selectedReportCostsCenterUuid
  );

  const checkIfSomeSelectedExpensesCostsCenterUuidIsDifferentFromReportCostsCenterUuid =
    !!selectedReportCostsCenterUuid &&
    expensesWithDifferentCostsCenter.length > 0;

  const allowCreateReport = openReports?.length === 0;

  function handleShowCreateReportFormOrLinkExpensesToReport(): void {
    if (allowCreateReport) {
      setShowCreateReportForm(true);
    } else {
      handleLinkExpensesToReport();
    }
  }

  return {
    searchTerm,
    filteredOpenReports,
    isLoadingOpenReports,
    companyApprovalType: expenseParameters?.companyApprovalType,
    isShowingModalLoading:
      isLoadingOpenReports ||
      isLinkingExpensesToReport ||
      isLoadingExpenseParameters,
    handleChangeSearchTerm,
    setShowCreateReportForm,
    selectedReportCostsCenterName,
    selectedReport,
    expensesWithDifferentCostsCenter,
    setSelectedReport: (value: string) => {
      setSelectedReport(value);
    },
    checkIfSelectedExpensesCurrencyIsDifferentFromReportCurrency,
    showCreateReportForm,
    allowCreateReport,
    reportCurrencyIsoCode: reportCurrencyIsoCode ?? '',
    checkIfSomeSelectedExpensesCostsCenterUuidIsDifferentFromReportCostsCenterUuid,
    handleShowCreateReportFormOrLinkExpensesToReport
  };
}
