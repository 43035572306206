import { cloneElement } from 'react';

import { Tooltip } from 'ds';

import { useTooltipOverflowContent } from './useTooltipOverflowContent';

import { type ITooltipOverflowContentProps } from './TooltipOverflowContent.types';

export function TooltipOverflowContent({
  tooltipMessage,
  children,
  tooltipContainerClassName
}: ITooltipOverflowContentProps): JSX.Element {
  const { handleMouseLeave, handleMouseOver, showTooltip } =
    useTooltipOverflowContent();

  return (
    <Tooltip
      message={tooltipMessage}
      placement='bottom'
      visible={showTooltip}
      containerClassName={tooltipContainerClassName}
    >
      {cloneElement(children, {
        onMouseOver: handleMouseOver,
        onMouseLeave: handleMouseLeave
      })}
    </Tooltip>
  );
}
