import { useState } from 'react';

import { DSIcons } from 'ds/icons';

import { type IUsePasswordInputDefault } from './PasswordInputDefault.types';

export function usePasswordInputDefault(): IUsePasswordInputDefault {
  const [inputType, setInputType] = useState<'password' | 'text'>('password');

  const IconRight =
    inputType === 'password' ? DSIcons.EyeShowIcon : DSIcons.EyeHiddenIcon;

  function handleTogglePasswordVisibility(): void {
    setInputType(prevType => (prevType === 'password' ? 'text' : 'password'));
  }

  return {
    inputType,
    handleTogglePasswordVisibility,
    IconRight
  };
}
