import {
  type IPersistenceUpdateAccountHolder,
  type IUpdateAccountHolder
} from 'data/modules/cards/accountHolders';

import { CustomDate, CustomObject } from 'shared/utils/custom';
import { Mask } from 'shared/utils/format';

class UpdateAccountHolderMapper {
  toPersistence(
    data: Omit<IUpdateAccountHolder, 'id'>
  ): IPersistenceUpdateAccountHolder {
    return {
      company_id: data.companyId,
      legal_name: data.legalName,
      business_type: data.businessType,
      phone_number: '55' + Mask.remove(data.phoneNumber),
      postal_code: Mask.remove(data.postalCode),
      founding_date: CustomDate.formatToIso(data.foundingDate),
      main_activity: data.mainActivity,
      number: Mask.remove(data.number),
      shareholders: data.shareholders.map(shareholder => {
        return {
          phone_number: '55' + Mask.remove(shareholder.phoneNumber),
          mother_name: shareholder.motherName,
          birth_date: CustomDate.formatToIso(shareholder.birthDate),
          postal_code: Mask.remove(shareholder.postalCode),
          document: Mask.remove(shareholder.document),
          number: shareholder.number ? Mask.remove(shareholder.number) : '',
          ...CustomObject.pick(shareholder, [
            'city',
            'country',
            'email',
            'name',
            'neighborhood',
            'state',
            'street'
          ]),
          swap_type: shareholder.swapType,
          ...(shareholder.complement !== null && {
            complement: shareholder.complement
          })
        };
      }),
      ...CustomObject.pick(data, [
        'city',
        'country',
        'email',
        'name',
        'neighborhood',
        'state',
        'street',
        'subtype',
        'type'
      ]),
      ...(data.complement !== null && { complement: data.complement }),
      ...(data.website !== null && { website: data.website })
    };
  }
}

export default new UpdateAccountHolderMapper();
