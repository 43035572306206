import { useLangContext } from 'data/contexts';

import { Title } from 'presentation/components/global/Title';

import { TariffsTable } from './components/TariffsTableContent';

export function Tariffs(): JSX.Element {
  const [settings, currentLangKey] = useLangContext(state => [
    state.lang.settings,
    state.currentLangKey
  ]);

  return (
    <>
      <Title text={settings.tariffs.title[currentLangKey]} />
      <TariffsTable />
    </>
  );
}
