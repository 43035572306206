import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import {
  BusinessCardsMutationKeys,
  BusinessCardsQueryKeys,
  BusinessCardsService,
  type HandleFakeChangingExpensePoliciesType,
  type IAttachApprovalFlowsAndExpensePoliciesPayload,
  type IChangeBusinessCardExpensePoliciesFieldsForm,
  type IUseChangeBusinessCardExpensePolicies,
  type IUseChangeBusinessCardExpensePoliciesParams,
  type IVirtualMembers
} from 'data/modules/cards/businessCard';

import { useFormWithSchema } from 'shared/hooks/forms';

import { useChangeBusinessCardExpensePoliciesSchema } from './schemas';

export function useChangeBusinessCardExpensePolicies({
  card
}: IUseChangeBusinessCardExpensePoliciesParams): IUseChangeBusinessCardExpensePolicies {
  const [isFakeChangingExpensePolicies, setIsFakeChangingExpensePolicies] =
    useState(false);

  const {
    control,
    handleSubmit,
    reset: formReset,
    clearErrors,
    formState: { errors }
  } = useFormWithSchema(useChangeBusinessCardExpensePoliciesSchema(), {
    defaultValues: {
      expensePolicyId: card.expensePolicyId ?? undefined,
      routePolicyId: card.routePolicyId ?? undefined
    }
  });

  const queryClient = useQueryClient();

  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const {
    mutate: approvalFlowsAndExpensePoliciesMutation,
    isLoading: loadingApprovalFlowsAndExpensePoliciesMutation
  } = useMutationCache({
    mutationKey: [
      BusinessCardsMutationKeys.ATTACH_APPROVAL_FLOWS_AND_EXPENSES_POLICIES
    ],
    mutationFn: async (
      payload: IAttachApprovalFlowsAndExpensePoliciesPayload
    ) =>
      await BusinessCardsService.attachApprovalFlowsAndExpensePolicies(payload),
    onSuccess: (data, payload) => {
      if (data?.uuid) {
        toast.success(
          lang.settings.manageBusinessCard.policies_changed_successfully[
            currentLangKey
          ]
        );

        queryClient.setQueryData<IVirtualMembers>(
          [BusinessCardsQueryKeys.GET_VIRTUAL_MEMBERS, card.uuid],
          oldData =>
            ({
              ...oldData,
              expensePolicyId: payload.expensePolicyId,
              routePolicyId: payload.routePolicyId
            }) as IVirtualMembers
        );
      }
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function handleFakeChangingExpensePolicies(): HandleFakeChangingExpensePoliciesType {
    setIsFakeChangingExpensePolicies(true);

    const isFakeChangingTimeOut = setTimeout(() => {
      setIsFakeChangingExpensePolicies(false);
      toast.success(
        lang.settings.manageBusinessCard.policies_changed_successfully[
          currentLangKey
        ]
      );
    }, 1000);

    return () => {
      clearTimeout(isFakeChangingTimeOut);
    };
  }

  function onSubmit(data: IChangeBusinessCardExpensePoliciesFieldsForm): void {
    if (
      data.expensePolicyId === card.expensePolicyId &&
      data.routePolicyId === card.routePolicyId
    ) {
      handleFakeChangingExpensePolicies();
      return;
    }

    approvalFlowsAndExpensePoliciesMutation({
      uuid: card.uuid,
      expensePolicyId: data.expensePolicyId,
      routePolicyId: data.routePolicyId
    });
  }

  return {
    control,
    errors,
    isLoadingUseExpensePolicies:
      isFakeChangingExpensePolicies ||
      loadingApprovalFlowsAndExpensePoliciesMutation,
    handleSubmit: handleSubmit(onSubmit),
    clearErrors,
    reset: formReset
  };
}
