import { Modal, tokens } from 'ds';
import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  flex-grow: 1;
  overflow: auto;

  padding: 0;
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
  gap: ${tokens.spacingXs};
  margin-top: ${tokens.spacingLg};

  button {
    width: 100%;
    white-space: nowrap;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    button {
      width: fit-content;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${tokens.spacingXs};
  margin-top: ${tokens.spacingLg};
`;
