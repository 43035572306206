import { useTranslation } from 'react-i18next';

import { useAuthContext, useModalContext } from 'data/contexts';

import {
  ConfirmAddUsersModal,
  CreateCardGroupsModal,
  UsersCantBeAddedModal
} from 'presentation/pages/cards/Management/components/CardsGroupsCreation';
import {
  NewDepositModal,
  NewWithdrawalModal,
  RequestCardsModal
} from 'presentation/pages/cards/Management/components/ManagementSidebar';
import { ManageCardValueModal } from 'presentation/pages/cards/Management/components/SideCardDetailsModal';
import { DefaultLayout } from 'presentation/templates';

import { CardGroupsTable } from './components/CardGroupsTable';
import {
  AddCardToGroupModal,
  AddManagerToGroupModal,
  CardGroupDetailsModal,
  CardGroupsPageHeader,
  EditCardGroupNameModal,
  RemoveCardFromGroupModal,
  RemoveManagerFromGroupModal
} from './components';

import { PageContainer } from './CardGroups.styles';

export function CardGroups(): JSX.Element {
  const { t } = useTranslation('cards');

  const visibleModal = useModalContext(state => state.visibleModal);
  const user = useAuthContext(state => state.user);

  return (
    <DefaultLayout
      pageNameAndBreadcrumbs={{
        pageName: t('cardsManagement'),
        isBackButtonVisible: false,
        isBreadcrumbsVisible: false,
        isClassicMode: false
      }}
    >
      <PageContainer>
        {(user?.cards?.manager ||
          (user?.cards?.isCardGroupManager &&
            !user.cards.isCardGroupManagerWithoutAccount)) && (
          <CardGroupsPageHeader />
        )}

        <CardGroupsTable />
      </PageContainer>

      {visibleModal === 'deposit' && <NewDepositModal />}

      {visibleModal === 'withdrawal' && <NewWithdrawalModal />}

      {visibleModal === 'requestCards' && <RequestCardsModal />}

      {visibleModal === 'groupCardDetails' && <CardGroupDetailsModal />}

      {visibleModal === 'editCardGroupName' && <EditCardGroupNameModal />}

      {visibleModal === 'removeCardFromGroup' && <RemoveCardFromGroupModal />}

      {visibleModal === 'removeManagerFromGroup' && (
        <RemoveManagerFromGroupModal />
      )}

      {visibleModal === 'addManagerToGroup' && <AddManagerToGroupModal />}

      {visibleModal === 'addCardToGroup' && <AddCardToGroupModal />}

      {visibleModal === 'cardsGroupCreation' && <CreateCardGroupsModal />}

      {visibleModal === 'usersCantBeAdded' && <UsersCantBeAddedModal />}

      {visibleModal === 'confirmAddUsers' && <ConfirmAddUsersModal />}

      {(visibleModal === 'addValueToCard' ||
        visibleModal === 'removeValueFromCard') && (
        <ManageCardValueModal
          type={visibleModal === 'addValueToCard' ? 'ADD' : 'REMOVE'}
          page='CARD_GROUPS'
        />
      )}
    </DefaultLayout>
  );
}
