import { useEffect, useMemo } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { useCardsContext } from 'data/contexts';
import {
  useGetAccountBalancesAmounts,
  useGetAccounts
} from 'data/modules/cards/account';

import { type IUseCardsTable } from 'presentation/pages/cards/Management/Cards/Cards.types';

import { useDebounce } from 'shared/hooks/global';

export function useCardsTable(): IUseCardsTable {
  const [{ searchTerm, cardType, order }, page, limit, handleChangePagination] =
    useCardsContext(
      useShallow(state => [
        state.filters,
        state.pagination.page,
        state.pagination.limit,
        state.handleChangePagination
      ])
    );

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    handleChangePagination({ page: 1 });
  }, [debouncedSearchTerm, handleChangePagination]);

  const { paginatedAccounts, isFetchingAccounts } = useGetAccounts({
    limit,
    page,
    ordenationType: order.cardName,
    ...(cardType?.length === 1 && { accountType: cardType[0] }),
    ...(debouncedSearchTerm && {
      name: debouncedSearchTerm.trim().toLowerCase()
    })
  });

  useEffect(() => {
    if (paginatedAccounts && !isFetchingAccounts) {
      handleChangePagination({
        total: paginatedAccounts.total
      });
    }
  }, [paginatedAccounts, isFetchingAccounts, handleChangePagination]);

  function getAccountsIds(): string[] {
    if (!paginatedAccounts) return [];

    return paginatedAccounts.data.map(account => {
      if (account.individual) {
        return account.individual.account.balances[0].id;
      } else {
        return account.business.balances[0].id;
      }
    });
  }

  const { accountBalancesAmounts } = useGetAccountBalancesAmounts({
    enabled: !isFetchingAccounts && !!paginatedAccounts?.data,
    accountBalancesIds: getAccountsIds()
  });

  const cardsList = useMemo(() => {
    if (!paginatedAccounts) return [];

    return paginatedAccounts.data.map((account, index) => {
      if (account.individual) {
        return {
          name: account.individual.userName,
          type: 'INDIVIDUAL' as 'INDIVIDUAL' | 'BUSINESS',
          number: account.individual.account.cardNumber,
          currentValue: accountBalancesAmounts[index],
          balanceId: account.individual.account.balances[0].id,
          status: account.individual.account.cardStatus,
          accountId: account.individual.account.id,
          userId: account.individual.userId
        };
      } else {
        return {
          name: account.business.name,
          type: 'BUSINESS' as 'INDIVIDUAL' | 'BUSINESS',
          number: account.business.cardNumber,
          currentValue: accountBalancesAmounts[index],
          balanceId: account.business.balances[0].id,
          status: account.business.status,
          accountId: account.business.id,
          userId: account.business.accessAccounts[0].id
        };
      }
    });
  }, [accountBalancesAmounts, paginatedAccounts]);

  return {
    cardsList,
    isLoadingCardsList: isFetchingAccounts
  };
}
