import { useEffect, useRef, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useModalContext } from 'data/contexts';
import {
  CardGroupsQueryKeys,
  useCheckIfCardsCanBeAdded
} from 'data/modules/cards/cardGroups';

import { type IUseConfirmAttachOfCardsOnGroup } from './useConfirmAttachOfCardsOnGroup.types';

export function useConfirmAttachOfCardsOnGroup(): IUseConfirmAttachOfCardsOnGroup {
  const [selectedUsersWithoutCardGroup, handleSetInconsisentUsersToAdd] =
    useCardGroupsContext(
      useShallow(state => [
        state.selectedUsersWithoutCardGroup,
        state.handleSetInconsisentUsersToAdd
      ])
    );

  const queryClient = useQueryClient();
  const { handleOpenModal } = useModalContext();
  const shouldCheckIfCardsCanBeAttached = useRef(false);
  const [
    shouldDispatchCardsAttachVerification,
    setShouldDispatchCardsAttachVerification
  ] = useState(false);

  const {
    cardsCanBeAdd,
    isCheckingIfCardsCanBeAdded,
    isCheckIfCardsCanBeAddedSuccess
  } = useCheckIfCardsCanBeAdded({
    usersIds: selectedUsersWithoutCardGroup || [],
    enabled: shouldCheckIfCardsCanBeAttached.current
  });

  function handleVerifyCardsAttach(): void {
    setShouldDispatchCardsAttachVerification(true);
  }

  useEffect(() => {
    if (shouldDispatchCardsAttachVerification) {
      shouldCheckIfCardsCanBeAttached.current = true;
    }

    return () => {
      setShouldDispatchCardsAttachVerification(false);
      shouldCheckIfCardsCanBeAttached.current = false;
    };
  }, [shouldDispatchCardsAttachVerification]);

  useEffect(() => {
    queryClient.resetQueries({
      queryKey: [CardGroupsQueryKeys.CHECK_IF_CARDS_CAN_BE_ADDED]
    });

    if (isCheckIfCardsCanBeAddedSuccess && shouldCheckIfCardsCanBeAttached) {
      if (cardsCanBeAdd?.errors && cardsCanBeAdd.errors.length > 0) {
        handleSetInconsisentUsersToAdd(cardsCanBeAdd.errors);
        handleOpenModal('usersCantBeAdded');

        return;
      }

      shouldCheckIfCardsCanBeAttached.current = false;

      handleOpenModal('confirmAddUsers');
    }
  }, [
    cardsCanBeAdd,
    handleOpenModal,
    isCheckIfCardsCanBeAddedSuccess,
    queryClient,
    handleSetInconsisentUsersToAdd,
    shouldCheckIfCardsCanBeAttached
  ]);

  return {
    isCheckingIfCardsCanBeAdded,
    handleVerifyCardsAttach
  };
}
