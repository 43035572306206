import { useEffect, useMemo, useState } from 'react';

import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnPtBrPackage from '@zxcvbn-ts/language-pt-br';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetPasswordPolicy } from 'data/modules/settings/passwordPolicy';

import { type IUsePasswordRequirements } from './PasswordRequirements.types';
import { type ZxcvbnResult } from '@zxcvbn-ts/core/dist/types';

const options = {
  translations: zxcvbnPtBrPackage.translations,
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnPtBrPackage.dictionary
  }
};

zxcvbnOptions.setOptions(options);

export function usePasswordRequirements(): IUsePasswordRequirements {
  const [indicator, setIndicator] = useState<ZxcvbnResult>();
  const { t } = useTranslation('settings');
  const { watch } = useFormContext();

  const { passwordPolicy } = useGetPasswordPolicy();

  const password: string = watch('password') ?? '';
  const name: string = watch('name') ?? '';

  useEffect(() => {
    setIndicator(zxcvbn(password ?? '', [name ?? '']));
  }, [name, password]);

  const score = indicator?.score ?? -1;

  const feedbacks = useMemo(
    () => [
      t('passwordRequirements.veryWeakPassword', {
        passwordLength: passwordPolicy?.passwordLength ?? 7
      }),
      t('passwordRequirements.weakPassword', {
        passwordLength: passwordPolicy?.passwordLength ?? 7
      }),
      t('passwordRequirements.mediumPassword'),
      t('passwordRequirements.strongPassword'),
      t('passwordRequirements.veryStrongPassword')
    ],
    [passwordPolicy?.passwordLength, t]
  );

  return {
    score,
    feedback: feedbacks[score]
  };
}
