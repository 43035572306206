import { CardInfo } from 'ds';

import { useLangContext } from 'data/contexts';

import { Currency } from 'shared/utils/format';
import { Lang } from 'shared/utils/global';

import { useCardExpensesInfoOverview } from './useCardExpensesInfoOverview';

import { type ICardExpensesInfoOverviewProps } from './CardExpensesInfoOverview.types';

import { Container } from './CardExpensesInfoOverview.styles';

export function CardExpensesInfoOverview({
  ...rest
}: ICardExpensesInfoOverviewProps): JSX.Element {
  const {
    currentLangKey,
    lang: {
      cards: {
        management: { card_details: cardDetails }
      }
    }
  } = useLangContext();

  const { getLabelByStatus, isLoadingExpenses, userExpensesDetachedList } =
    useCardExpensesInfoOverview();

  return (
    <Container {...rest}>
      {userExpensesDetachedList?.map(expense => (
        <CardInfo.Container
          tooltipMessage={cardDetails.tooltip_message[currentLangKey]}
          key={expense.status}
        >
          <CardInfo.Title variant='small'>
            {getLabelByStatus(expense.status)}
          </CardInfo.Title>

          <CardInfo.ValueContainer>
            <CardInfo.Value
              variant='small'
              isLoading={isLoadingExpenses}
            >
              {Currency.format('BRL', Math.abs(expense.value ?? 0), true)}
            </CardInfo.Value>
          </CardInfo.ValueContainer>

          <CardInfo.Caption
            variant='small'
            isLoading={isLoadingExpenses}
          >
            {expense.total === 1
              ? Lang.putValuesInString(
                  cardDetails.expense_amount_label[currentLangKey],
                  { amount: expense.total.toString() }
                )
              : Lang.putValuesInString(
                  cardDetails.expenses_amount_label[currentLangKey],
                  { amount: expense.total.toString() }
                )}
          </CardInfo.Caption>
        </CardInfo.Container>
      ))}
    </Container>
  );
}
