import {
  type IGetCardGroupManagersParams,
  type IPersistenceGetCardGroupManagersParams
} from 'data/modules/cards/cardGroups';

class GetGroupManagersMapper {
  toPersistence(
    params: Omit<IGetCardGroupManagersParams, 'id'>
  ): Omit<IPersistenceGetCardGroupManagersParams, 'id'> {
    if (params.name) {
      return {
        name: params.name,
        limit: params.limit,
        page: params.page,
        ordernation_type: params.ordernationType
      };
    }

    return {
      limit: params.limit,
      page: params.page,
      ordernation_type: params.ordernationType
    };
  }
}

export default new GetGroupManagersMapper();
