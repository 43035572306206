import { DefaultButton, DSIcons } from 'ds';
import { useNavigate, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  useCardDetailsContext,
  useLangContext,
  useModalContext
} from 'data/contexts';
import { useBlockCard } from 'data/modules/cards/account';

import { Loader } from 'presentation/components/global/Loader';
import { SideModal } from 'presentation/components/global/Modal';
import {
  CardAndControllers,
  CardExpensesInfoOverview,
  ChangeCardStatusModal,
  LatestTransactionsList,
  ManageCardValueModal
} from 'presentation/pages/cards/Management/components/SideCardDetailsModal';
import { Section } from 'presentation/pages/cards/Management/components/SideCardDetailsModal//Section/Section.component';

import { useSideCardDetailsModal } from './useSideCardDetailsModal';

import { type ISideCardDetailsModalProps } from './SideCardDetailsModal.types';

import { Divider } from './SideCardDetailsModal.styles';

export function SideCardDetailsModal({
  isOpen,
  title,
  side = 'right'
}: ISideCardDetailsModalProps): JSX.Element {
  const {
    currentLangKey,
    lang: {
      cards: { global }
    }
  } = useLangContext();

  const { groupId } = useParams();

  const { handleOpenModal, handleCloseModal, visibleModal } = useModalContext();

  const [handleSetSelectedAccount, selectedAccount] = useCardDetailsContext(
    useShallow(state => [state.handleSetSelectedAccount, state.selectedAccount])
  );

  const navigate = useNavigate();

  const { isBlockingCard } = useBlockCard();

  const { isFetchingBalancesAndAccount } = useSideCardDetailsModal();

  return (
    <>
      {(visibleModal === 'addValueToCard' ||
        visibleModal === 'removeValueFromCard') && (
        <ManageCardValueModal
          type={visibleModal === 'addValueToCard' ? 'ADD' : 'REMOVE'}
        />
      )}

      {visibleModal === 'actionModal' && <ChangeCardStatusModal />}

      <Loader isLoading={isBlockingCard} />

      {isOpen && (
        <SideModal
          isOpen={isOpen}
          title={title}
          side={side}
          onAfterClose={() => {
            handleSetSelectedAccount(null);
          }}
        >
          <CardAndControllers
            onAddValue={() => {
              handleOpenModal('addValueToCard');
            }}
            onRemoveValue={() => {
              handleOpenModal('removeValueFromCard');
            }}
            onChangeCardStatus={() => {
              handleOpenModal('actionModal');
            }}
            onManageCard={() => {
              navigate(
                `/settings/cartoes/cartoes-empresariais/${selectedAccount?.userId}`
              );
            }}
          />

          <Divider />

          <Section title={global.total_user_expenses[currentLangKey]}>
            <CardExpensesInfoOverview />
          </Section>

          <Divider />

          <Section title={global.card_latest_transactions[currentLangKey]}>
            <LatestTransactionsList />

            <DefaultButton
              size='small'
              onClick={() => {
                handleCloseModal();

                navigate(
                  groupId
                    ? `/cartoes/gestao-de-grupos/${groupId}/movimentacoes/${selectedAccount?.accountId}`
                    : `/cartoes/extrato/${
                        selectedAccount?.type === 'BUSINESS'
                          ? selectedAccount?.accountId
                          : selectedAccount?.userId
                      }`
                );

                handleSetSelectedAccount(null);
              }}
              disabled={isFetchingBalancesAndAccount}
            >
              {global.view_all_transactions[currentLangKey]}
              <DSIcons.ArrowRightIcon />
            </DefaultButton>
          </Section>
        </SideModal>
      )}
    </>
  );
}
