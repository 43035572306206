import { DefaultButton, DSIcons, SecondaryButton } from 'ds';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  useAuthContext,
  useCardGroupsContext,
  useModalContext
} from 'data/contexts';
import { useGetCardGroup } from 'data/modules/cards/account/useCases/get-card-group/useGetCardGroup';

import { GroupCard } from 'presentation/pages/cards/Management/components/GroupCard';

import {
  ButtonsContainer,
  CardAndActionsContainer,
  Container,
  CustomTextButton
} from './CardGroupCardAndActions.styles';

export function CardGroupCardAndActions(): JSX.Element {
  const { t } = useTranslation('cards');

  const user = useAuthContext(state => state.user);

  const [selectedCardGroup] = useCardGroupsContext(
    useShallow(state => [state.selectedCardGroup])
  );

  const { cardGroupData } = useGetCardGroup({
    cardGroupId: selectedCardGroup?.id as string,
    enabled: !!selectedCardGroup
  });

  const handleOpenModal = useModalContext(state => state.handleOpenModal);

  return (
    <Container>
      <CardAndActionsContainer>
        <GroupCard
          groupInfo={{
            name: selectedCardGroup?.name!,
            availableValue: selectedCardGroup?.availableValue
          }}
          isDetailsCard
        />

        <ButtonsContainer>
          {user?.cards?.type === 'ACCOUNT_MANAGER' && (
            <>
              <DefaultButton
                size='small'
                onClick={() => {
                  handleOpenModal('addValueToCard');
                }}
              >
                {t('addValue')}
              </DefaultButton>

              <SecondaryButton
                size='small'
                onClick={() => {
                  handleOpenModal('removeValueFromCard');
                }}
              >
                {t('removeValue')}
              </SecondaryButton>
            </>
          )}

          {(cardGroupData?.isManager ||
            user?.cards?.type === 'FINANCIAL_MANAGER') && (
            <Link to={`/cartoes/gestao-de-grupos/${selectedCardGroup?.id}`}>
              <CustomTextButton size='small'>
                <span>
                  {t('accessGroup', { group: selectedCardGroup?.name })}
                </span>

                <DSIcons.ArrowRightIcon />
              </CustomTextButton>
            </Link>
          )}
        </ButtonsContainer>
      </CardAndActionsContainer>
    </Container>
  );
}
