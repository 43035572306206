import { type IEnv } from './config.types';

export const env: IEnv = {
  VITE_GTM_ID: import.meta.env.VITE_GTM_ID,
  VITE_GA_ID: import.meta.env.VITE_GA_ID,
  VITE_VEX_BASE_DOMAIN: import.meta.env.VITE_VEX_BASE_DOMAIN,
  VITE_FIREBASE_API_KEY: import.meta.env.VITE_FIREBASE_API_KEY,
  VITE_FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  VITE_FIREBASE_DATABASE_URL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  VITE_FIREBASE_PROJECT_ID: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  VITE_FIREBASE_STORAGE_BUCKET: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  VITE_FIREBASE_MESSAGING_SENDER_ID: import.meta.env
    .VITE_FIREBASE_MESSAGING_SENDER_ID,
  VITE_FIREBASE_APP_ID: import.meta.env.VITE_FIREBASE_APP_ID,
  VITE_FIREBASE_MEASUREMENT_ID: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  VITE_NOTIFICATIONS_API: import.meta.env.VITE_NOTIFICATIONS_API,
  VITE_WEBSOCKET_URL: import.meta.env.VITE_WEBSOCKET_URL,
  VITE_API_LOCALHOST: import.meta.env.VITE_API_LOCALHOST,
  VITE_ENV: import.meta.env.VITE_ENV,
  VITE_MAPS_API: import.meta.env.VITE_MAPS_API,
  VITE_API_WEB_LOCALHOST: import.meta.env.VITE_API_WEB_LOCALHOST,
  VITE_MOCK_API: import.meta.env.VITE_MOCK_API,
  VITE_PERSONAL_TOKEN: import.meta.env.VITE_PERSONAL_TOKEN,
  VITE_ACCESS_TOKEN: import.meta.env.VITE_ACCESS_TOKEN,
  VITE_REFRESH_TOKEN: import.meta.env.VITE_REFRESH_TOKEN,
  VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN
};
