import styled from 'styled-components';

export const FilterButton = styled.button`
  display: flex;
  width: fit-content;
  align-items: center;
  background: none;
  border: 0;
  color: ${({ theme }) => theme.colors.dustyGray};
  padding: 0.2rem 0.3rem;
  font-size: 1.6rem;
  text-transform: uppercase;
  flex-direction: row-reverse;
  font-weight: 400;

  &:hover {
    color: ${({ theme }) => theme.colors.mineShaft};
  }

  svg {
    margin: 0.5rem;
  }
`;
