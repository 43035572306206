import { DefaultTable, SkeletonLoader as DsSkeletonLoader } from 'ds';

import { SkeletonLoader } from 'presentation/components/global/Loader';
import { AnalysisItem } from 'presentation/pages/analytics/Analysis/components/AnalysisItem';

import {
  CardsSkeleton,
  Container,
  ItemSectionSkeleton,
  ReportContainerSkeleton,
  ReportContentSkeleton,
  ReportHeaderSkeleton,
  ReportTitleSkeleton,
  ReportTotalSkeleton,
  SectionNameSkeleton,
  TableContainerSkeleton
} from './UserAnalysisItemSkeleton.styles';

export function UserAnalysisItemSkeleton(): JSX.Element {
  const tableSkeleton = (
    <TableContainerSkeleton>
      <DefaultTable.Container>
        <DefaultTable.Thead>
          <DefaultTable.Tr>
            <DefaultTable.Th width='5%' />

            <DefaultTable.Th>
              <SkeletonLoader />
            </DefaultTable.Th>

            <DefaultTable.Th>
              <SkeletonLoader />
            </DefaultTable.Th>

            <DefaultTable.Th>
              <SkeletonLoader />
            </DefaultTable.Th>

            <DefaultTable.Th>
              <SkeletonLoader />
            </DefaultTable.Th>

            <DefaultTable.Th>
              <SkeletonLoader />
            </DefaultTable.Th>

            <DefaultTable.Th>
              <SkeletonLoader />
            </DefaultTable.Th>

            <DefaultTable.Th>
              <SkeletonLoader />
            </DefaultTable.Th>

            <DefaultTable.Th>
              <SkeletonLoader />
            </DefaultTable.Th>

            <DefaultTable.Th width='5%'>
              <SkeletonLoader />
            </DefaultTable.Th>
          </DefaultTable.Tr>
        </DefaultTable.Thead>

        <DefaultTable.Tbody>
          {new Array(3).fill(0).map((row, idx) => (
            <DefaultTable.Tr key={`row-${idx}`}>
              <DefaultTable.Td>
                <DsSkeletonLoader />
              </DefaultTable.Td>

              <DefaultTable.Td>
                <DsSkeletonLoader />
              </DefaultTable.Td>

              <DefaultTable.Td>
                <DsSkeletonLoader />
              </DefaultTable.Td>

              <DefaultTable.Td>
                <DsSkeletonLoader />
              </DefaultTable.Td>

              <DefaultTable.Td>
                <DsSkeletonLoader />
              </DefaultTable.Td>

              <DefaultTable.Td>
                <DsSkeletonLoader />
              </DefaultTable.Td>

              <DefaultTable.Td>
                <DsSkeletonLoader />
              </DefaultTable.Td>

              <DefaultTable.Td>
                <DsSkeletonLoader />
              </DefaultTable.Td>

              <DefaultTable.Td>
                <DsSkeletonLoader />
              </DefaultTable.Td>
            </DefaultTable.Tr>
          ))}
        </DefaultTable.Tbody>
      </DefaultTable.Container>
    </TableContainerSkeleton>
  );

  return (
    <Container>
      <AnalysisItem.Container>
        <SectionNameSkeleton>
          <SkeletonLoader width='40%' />
          <SkeletonLoader width='20%' />
        </SectionNameSkeleton>
        <CardsSkeleton>
          <SkeletonLoader />
          <SkeletonLoader />
          <SkeletonLoader />
          <SkeletonLoader />
          <SkeletonLoader />
        </CardsSkeleton>
        <ItemSectionSkeleton>
          <SkeletonLoader width='40%' />
          {tableSkeleton}
        </ItemSectionSkeleton>
        <ItemSectionSkeleton>
          <ReportHeaderSkeleton>
            <ReportTitleSkeleton>
              <SkeletonLoader
                width='40%'
                height='3%'
              />
              <SkeletonLoader width='20%' />
            </ReportTitleSkeleton>
            <ReportContainerSkeleton>
              <ReportContentSkeleton>
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
              </ReportContentSkeleton>
              <ReportTotalSkeleton>
                <SkeletonLoader />
              </ReportTotalSkeleton>
            </ReportContainerSkeleton>
          </ReportHeaderSkeleton>
          {tableSkeleton}
        </ItemSectionSkeleton>
      </AnalysisItem.Container>
    </Container>
  );
}
