import { MenuTabs } from 'ds';
import styled from 'styled-components';

export const Container = styled(MenuTabs.Container)`
  width: fit-content;

  ul {
    padding: 0;
    padding-bottom: 0.4rem;
    gap: 1.6rem;

    &::before {
      bottom: 0.1rem;
    }
  }
`;
