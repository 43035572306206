import { tokens } from 'ds';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type SidebarItemStylesType } from './SidebarItem.types';

export const Tooltip = styled.span`
  transition: all 100ms ease-in-out;
  padding: 0.3rem 0.6rem;
  width: fit-content;
  background: ${({ theme }) => theme.colors.neutralColorDark};
  border-radius: 4px;
  position: absolute;
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: center;
  visibility: 0;
  opacity: 0;
  left: 0;
  white-space: nowrap;
  z-index: -100;
  pointer-events: none;
  margin-left: 10rem;

  &::before {
    content: '';
    position: absolute;
    top: 33%;
    left: -1rem;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: ${({ theme }) =>
      `8px solid ${theme.colors.neutralColorDark}`};
    border-radius: 8px;
    transform: rotate(269deg);
  }
`;

export const ExtraIconsWrapper = styled.div<
  AddPrefixToType<SidebarItemStylesType>
>`
  display: flex;
  justify-content: end;
  width: 100%;
  min-width: fit-content;

  svg {
    transition: all 200ms ease-in-out;
    width: 1.8rem !important;
    height: 1.8rem !important;
  }

  ${({ $isSubItemsActive }) =>
    $isSubItemsActive &&
    css`
      svg {
        transform: rotateZ(3.14rad);
      }
    `}
`;

export const Container = styled.div<AddPrefixToType<SidebarItemStylesType>>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 8px;
  transition: all 100ms ease-in-out;
  white-space: nowrap;
  width: 100%;

  ${({ $outsourced }) =>
    $outsourced &&
    css`
      #new-tag {
        color: ${({ theme }) =>
          `${theme.colors.neutralColorLowDark}!important`};
      }

      ${Tooltip} {
        background: ${({ theme }) => `${theme.colors.white}!important`};
        color: ${({ theme }) =>
          `${theme.colors.neutralColorLowDark}!important`};

        &::before {
          border-bottom-color: ${({ theme }) => theme.colors.white};
        }
      }
    `}

  p {
    font-size: 1.4rem;
    font-weight: 500;
    color: ${({ theme }) => theme.tokens.neutralColorHighPure};

    ${({ $isActive, $outsourced }) =>
      $isActive &&
      css`
        color: ${$outsourced
          ? ({ theme }) => theme.colors.neutralColorLowDark
          : ({ theme }) => theme.tokens.brandColorSecondaryPure};
      `}
  }

  svg {
    width: 2.2rem;
    height: 2.2rem;
  }

  ${({ $hasSubItems }) =>
    $hasSubItems
      ? css`
          a {
            width: fit-content;
          }
        `
      : css`
          div {
            display: none !important;
          }

          a {
            width: 100%;
          }
        `}

  a,
  div {
    display: flex;
    align-items: center;
    column-gap: 1.6rem;
    padding: 1.2rem 1.2rem 1.2rem 1.6rem;
    transition: all 200ms ease-in-out;

    > svg {
      flex-shrink: 0;
    }
  }

  &:hover {
    ${({ $isActive, $outsourced, theme }) =>
      !$isActive
        ? css`
            background-color: ${$outsourced
              ? theme.colors.neutralColorLowDark
              : transparentize(0.7, theme.colors.secondary)};
          `
        : css`
            opacity: '70%';
          `};
  }

  ${({ $isActive, $outsourced }) =>
    $isActive &&
    css`
      background-color: ${({ theme }) => theme.tokens.neutralColorHighPure};
      color: ${$outsourced
        ? ({ theme }) => theme.colors.mineShaft
        : ({ theme }) => theme.colors.primary};

      svg * {
        stroke: ${$outsourced
          ? ({ theme }) => theme.colors.mineShaft
          : ({ theme }) => theme.colors.primary};
        font-weight: 400;
      }
    `}

  @media screen and (min-width: 1200px) {
    ${({ $isCollapsed }) =>
      $isCollapsed &&
      css`
        justify-content: center;

        p {
          display: none;
        }

        #new-tag {
          display: none;
        }

        a,
        div {
          width: 100%;
          justify-content: center;
        }

        ${ExtraIconsWrapper} {
          display: none;
        }
      `}
  }

  @media screen and (max-width: 1680px) {
    p {
      font-size: 1.559rem;
    }

    svg {
      width: 2rem;
    }
  }

  @media screen and (max-width: 1366px) {
    svg {
      width: 2.45rem;
      height: 2.45rem;
    }
  }
`;

export const NewTag = styled.span<AddPrefixToType<SidebarItemStylesType>>`
  margin-left: auto;
  padding: 0.3rem 0.8rem;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighPure};
  color: ${tokens.brandColorPrimaryPure};
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${tokens.brandColorPrimaryLightest};
    `}

  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      display: none;
    `}
`;
