import { faker } from '@faker-js/faker';

import { type IUserOnboardingAnswers } from 'data/modules/cards/userOnboarding';

import { MockApi } from 'shared/utils/global';

export async function getUserOnboardingAnswersMock(): Promise<IUserOnboardingAnswers> {
  await MockApi.sleep(2000);

  return {
    userTermsOfUse: {
      acceptPrivacyPolicy: faker.datatype.boolean(),
      acceptTermsOfUse: faker.datatype.boolean()
    },
    userAccount: {
      birthDate: faker.date.past({ years: 30 }).toISOString(),
      documentNumber: '45671232040',
      motherName: faker.person.fullName({ sex: 'female' }),
      email: faker.internet.email(),
      fullName: faker.person.fullName(),
      phoneNumber: faker.string.numeric(11),
      street: faker.location.street(),
      number: faker.string.numeric(3),
      postalCode: '01153-000',
      city: 'São Paulo',
      state: 'SP',
      country: 'BR'
    }
  };
}
