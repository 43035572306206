import { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useModalContext } from 'data/contexts';
import { useGetAuthUser } from 'data/modules/auth';
import {
  ExpensesQueryKeys,
  type IDetailedExpense
} from 'data/modules/expenses';
import { useGetExpenseDetails } from 'data/modules/expenses/useCases/get-expense-details/useGetExpenseDetails';

import { useViewExpensesModalTour } from 'presentation/pages/expenses/ExpensesList/components/Modal/ViewExpensesModal/tours';

import { routesPathPrefix } from 'shared/constants/global';

import {
  type IUseViewExpensesModal,
  type IUseViewExpensesModalProps
} from './ViewExpensesModal.types';

export function useViewExpensesModal({
  expenseUuidOrId,
  expenseIndex,
  total,
  isExpenseOnList,
  preventRedirect
}: IUseViewExpensesModalProps): IUseViewExpensesModal {
  const { expense, isLoadingExpense } = useGetExpenseDetails({
    expenseUuid: expenseUuidOrId
  });

  const { company, isFetchingAuthUser } = useGetAuthUser();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { handleCloseModal } = useModalContext();

  const { t } = useTranslation('expenses');

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const showModalRightButton = expenseIndex + 1 <= total - 1;

  const expenseHasAttachmentAsImage =
    (expense?.receipt?.url !== null &&
      (expense?.receipt?.url?.includes('.png') ||
        expense?.receipt?.url?.includes('.jpeg') ||
        expense?.receipt?.url?.includes('.jpg'))) ??
    false;

  useViewExpensesModalTour({
    expenseHasReceiptAsImage: expenseHasAttachmentAsImage,
    showingModalRightButton: showModalRightButton,
    isLoadingExpense
  });

  useEffect(() => {
    if (!preventRedirect && !isLoadingExpense && expense) {
      navigate(`${routesPathPrefix.expenses}/${expense.id}`);

      if (!isExpenseOnList) {
        queryClient.setQueryData<IDetailedExpense>(
          [ExpensesQueryKeys.GET_EXPENSE, expense.uuid],
          expense
        );
      }
    }

    if (!isLoadingExpense && !expense) {
      toast.error(t('modal.expenseNotFound'));
      handleCloseModal();
      if (!preventRedirect) navigate(`${routesPathPrefix.expenses}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preventRedirect, isLoadingExpense, expense]);

  return {
    expense,
    isLoadingExpense: isLoadingExpense || isFetchingAuthUser,
    showModalRightButton,
    showDeleteModal,
    toggleDeleteModal: (state: boolean) => {
      setShowDeleteModal(state);
    },
    showRouteTimeParameter: company?.parameters.showRouteTime,
    isFuelingExpense:
      !!company?.fuelingTypeId &&
      Number(expense?.type?.id) === company?.fuelingTypeId &&
      company?.parameters.usesFuelsModule
  };
}
