import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'data/contexts';
import {
  type IExpensesPendingAccountability,
  useGetUserExpensesInformation
} from 'data/modules/expenses';

import { type IUseExpensesCards } from './ExpensesCards.types';

export function useExpensesCards(): IUseExpensesCards {
  const user = useAuthContext(state => state.user);
  const { t } = useTranslation('cards');

  const { userExpensesDetachedList, isLoadingUserExpensesInformation } =
    useGetUserExpensesInformation({
      userUuid: user?.cards?.id ?? ''
    });

  function getLabelByStatus(
    status: IExpensesPendingAccountability['status']
  ): string {
    switch (status.toUpperCase()) {
      case 'AVULSA':
        return t('standaloneExpenses');
      case 'ABERTO':
        return t('openExpenses');
      case 'ENVIADO':
        return t('expensesSent');
      case 'REPROVADO':
        return t('disapprovedExpenses');
      default:
        return '';
    }
  }

  return {
    isLoadingExpenses: isLoadingUserExpensesInformation,
    userExpensesDetachedList,
    getLabelByStatus
  };
}
