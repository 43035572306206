import { SecondaryButton, tokens } from 'ds';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { CreditCard } from 'presentation/components/global/CreditCard';

import { type MyCardPageHeaderStylesType } from './MyCardPageHeader.types';

export const Container = styled(motion.div)`
  display: flex;
  gap: ${tokens.spacingInsetXs};
  padding: ${tokens.spacingInsetXxs};
  background-color: ${({ theme }) => theme.tokens.neutralColorHighLight};
  border-radius: ${tokens.borderRadiusMd};
  overflow: hidden;
  flex-wrap: wrap;
  z-index: 12;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-wrap: nowrap;
  }
`;

export const ContentCard = styled.div`
  display: flex;
  gap: ${tokens.spacingInsetXs};
  max-height: 18.4rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: auto;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    max-height: 100%;
    flex-direction: column;
  }
`;

export const CreditCardCustom = styled(CreditCard)<
  AddPrefixToType<MyCardPageHeaderStylesType>
>`
  width: 100%;
  min-width: 21.536rem;
  max-width: 22.536rem;
  height: 15.5rem;
  aspect-ratio: 1.5;
  background-size: cover;
  border-radius: ${tokens.borderRadiusSm};
  transition: all 0.1s linear;
  background-position: center;
  padding: 1.102rem 1.103rem 1.12rem 1.752rem;

  .credit-card-name,
  .credit-card-value,
  .credit-card-status {
    font-feature-settings:
      'clig' off,
      'liga' off;
  }

  .credit-card-name,
  .credit-card-value {
    line-height: 2.5483rem;
    font-size: 1.4334rem;
    font-weight: 600;
    letter-spacing: 0.597px;
  }

  .credit-card-number {
    font-size: 1.1945rem;
    line-height: 1.9112rem;
  }

  .credit-card-status,
  .credit-card-label {
    font-size: 1.0352rem !important;
    line-height: 1.7519rem;
    letter-spacing: 0.199px;
  }

  ${({ $notLinked }) =>
    $notLinked &&
    css`
      .credit-card-name,
      .credit-card-value,
      .credit-card-label,
      .credit-card-number {
        display: none;
      }
    `}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    min-width: 25.5rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    height: 21.536rem;
    max-width: 100%;
    background-size: cover !important;
  }
`;

export const ActionsContainer = styled.div<
  AddPrefixToType<MyCardPageHeaderStylesType>
>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${tokens.spacingInsetXs};
  max-height: 15.5rem;
  width: 100%;

  ${({ $notLinked }) =>
    $notLinked &&
    css`
      > button {
        width: 18.2rem;
      }

      @media screen and (max-width: ${({ theme }) =>
          theme.breakpoints.mobile}px) {
        > button {
          width: 100%;
        }
      }
    `}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 20rem;
    justify-content: center;
    min-width: 18rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
  }
`;

export const DangerSecondaryButton = styled(SecondaryButton)`
  color: ${tokens.feedbackColorErrorPure};
  border-color: ${tokens.feedbackColorErrorPure};

  &:not(:disabled):hover {
    color: ${tokens.feedbackColorErrorMedium};
    border-color: ${tokens.feedbackColorErrorMedium};
  }

  &:focus {
    outline: ${tokens.borderWidthThick} solid ${tokens.feedbackColorErrorPure}B2;
    color: ${tokens.feedbackColorErrorPure};

    svg * {
      color: ${tokens.feedbackColorErrorPure} !important;
    }
  }

  &:active {
    background-color: ${tokens.feedbackColorErrorMedium};
    color: ${tokens.neutralColorHighPure};
    outline: none;

    svg * {
      color: ${tokens.neutralColorHighPure} !important;
    }
  }
`;
