import styled from 'styled-components';

import { IconButton } from 'presentation/components/base/Button';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
`;

export const Title = styled.h2`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.2rem;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.outerSpace};
`;

export const CloseButton = styled(IconButton)`
  align-self: self-start;
  color: ${({ theme }) => theme.colors.alto};

  &:hover {
    color: ${({ theme }) => theme.colors.neutral};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
