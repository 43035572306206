import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type OnboardingShareholderType
} from 'data/modules/cards/companyOnboarding';
import { useAddMembersOrLegalRepresentatives } from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import { type IUseNaturalPersonData } from './NaturalPersonData.types';

export function useNaturalPersonData(): IUseNaturalPersonData {
  const { state } = useLocation();
  const navigate = useNavigate();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const { updateCache, data: cachedCompanyOnboardingShareholders } =
    useHandleQueryCache({
      query: {
        key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_SHAREHOLDERS],
        query: async () =>
          await CompanyOnboardingService.getCompanyOnboardingShareholders()
      }
    });

  const { handleAddMembersOrLegalRepresentatives, control, errors, setValue } =
    useAddMembersOrLegalRepresentatives({
      shareholderType: state?.shareholderType,
      onAfterAddingMembersOrLegalRepresentatives: updateCacheAndNavigateNextStep
    });

  function updateCacheAndNavigateNextStep(
    newData: Required<OnboardingShareholderType>[]
  ): void {
    navigate(`/cartoes/onboarding/empresa/${currentStep}`, {
      replace: true
    });

    updateCache({
      newData
    });
  }

  function handleBackButtonClick(): void {
    navigate(
      `/cartoes/onboarding/empresa/${currentStep}/classificacao-pessoa-fisica`,
      {
        state: { shareholderType: state?.shareholderType }
      }
    );
  }

  useEffect(() => {
    if (!state?.shareholderType || !cachedCompanyOnboardingShareholders) {
      navigate(`/cartoes/onboarding/empresa/${currentStep}`, {
        replace: true
      });
    }
  }, [currentStep, state, navigate, cachedCompanyOnboardingShareholders]);

  return {
    handleAddMembersOrLegalRepresentatives,
    control,
    errors,
    setValue,
    handleBackButtonClick
  };
}
