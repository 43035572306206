import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IAddLegalRepresentativosFieldsForm } from 'data/modules/cards/companyOnboarding';

import { Validate } from 'shared/utils/validation';

export function useAddLegalRepresentativesSchema(): ObjectSchema<IAddLegalRepresentativosFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const addLegalRepresentativesSchema: ObjectSchema<IAddLegalRepresentativosFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object({
        legalRepresentatives: yup.array().of(
          yup.object({
            email: yup
              .string()
              .required(fieldRequiredMessage)
              .email(lang.schemas.invalid_email[currentLangKey]),
            name: yup
              .string()
              .required(fieldRequiredMessage)
              .test({
                name: 'name',
                message: lang.schemas.required_full_name[currentLangKey],
                test: (value: string) => Validate.fullName(value)
              })
          })
        )
      });
    }, [lang, currentLangKey]);

  return addLegalRepresentativesSchema;
}
