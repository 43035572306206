import { QrScanner } from '@yudiel/react-qr-scanner';

import { useLangContext, useModalContext } from 'data/contexts';
import { useActivateCard } from 'data/modules/cards/businessCard';

import { Alert } from 'presentation/components/base/Alert';
import { InputContainer, TextInput } from 'presentation/components/base/Input';
import { Modal } from 'presentation/components/base/Modal';

import { type IActivateBusinessCardModalProps } from './ActivateBusinessCardModal.types';

import {
  AlertContainer,
  Container,
  FormInputs,
  InputsWrapper,
  QrCodeContainer,
  ScanButton,
  Step,
  StepNumber,
  StepsContainer,
  StepText,
  Wrapper
} from './ActivateBusinessCardModal.styles';

export function ActivateBusinessCardModal({
  accountId,
  backOption
}: IActivateBusinessCardModalProps): JSX.Element {
  const { visibleModal, handleCloseModal, handleOpenModal } = useModalContext();

  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const {
    clearErrors,
    control,
    errors,
    handleSubmit,
    isLoading,
    openScan,
    setOpenScan,
    reset,
    getIdCardQrCode
  } = useActivateCard({ accountId });

  return (
    <Modal
      isOpen={visibleModal === 'activateBusinessCard'}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      onClose={() => {
        handleCloseModal();
        clearErrors();
        reset();
      }}
      title={lang.cards.statement.sidebar.options.activate_card[currentLangKey]}
      description={
        lang.settings.businessCards
          .activate_a_vexpenses_card_for_the_business_card[currentLangKey]
      }
      primaryButton={{
        children: lang.global.confirm[currentLangKey],
        color: 'success',
        onClick: () => {
          clearErrors();
        }
      }}
      secondaryButton={
        backOption
          ? {
              children: lang.forms.previous[currentLangKey],
              color: 'danger',
              onClick: () => {
                handleOpenModal('teamMember');
              }
            }
          : undefined
      }
      extraButtons={[
        {
          children: lang.forms.activate_later[currentLangKey],
          color: 'primary',
          onClick: () => {
            handleCloseModal();
            clearErrors();
            reset();
          }
        }
      ]}
    >
      <Container>
        <Wrapper>
          <FormInputs>
            <ScanButton
              type='button'
              onClick={() => {
                setOpenScan(!openScan);
              }}
            >
              {openScan
                ? lang.global.close[currentLangKey]
                : lang.settings.businessCards.read_qr_code[currentLangKey]}
            </ScanButton>

            <InputsWrapper>
              <InputContainer
                label={lang.settings.businessCards.card_id[currentLangKey]}
                error={errors.cardId?.message}
              >
                <TextInput
                  type='text'
                  name='cardId'
                  mask='alphanumeric'
                  maxLength={32}
                  control={control}
                  error={!!errors.cardId?.message}
                  placeholder={
                    lang.settings.businessCards.enter_the_32_character_card_id[
                      currentLangKey
                    ]
                  }
                />
              </InputContainer>

              <InputContainer
                label={
                  lang.settings.businessCards.card_password[currentLangKey]
                }
                error={errors.digits?.message}
              >
                <TextInput
                  type='password'
                  name='digits'
                  mask='numeric'
                  maxLength={4}
                  control={control}
                  error={!!errors.digits?.message}
                  placeholder={
                    lang.settings.businessCards.enter_a_4_digit_password[
                      currentLangKey
                    ]
                  }
                />
              </InputContainer>
            </InputsWrapper>

            {/* DICAS PARA A LEITURA DO QR CODE */}
            {openScan && (
              <AlertContainer>
                <Alert
                  iconProps={{
                    name: 'information'
                  }}
                >
                  <ul>
                    <li>
                      {
                        lang.settings.businessCards
                          .allow_webcam_access_in_browser[currentLangKey]
                      }
                    </li>
                    <li>
                      {
                        lang.settings.businessCards
                          .approach_the_qr_code_close_to_the_webcam[
                          currentLangKey
                        ]
                      }
                    </li>
                  </ul>
                </Alert>
              </AlertContainer>
            )}
          </FormInputs>

          <StepsContainer>
            {!openScan && (
              <>
                <Step>
                  <StepNumber>1</StepNumber>
                  <StepText>
                    {
                      lang.settings.businessCards
                        .the_qr_code_is_located_on_the_back_of_your_card[
                        currentLangKey
                      ]
                    }
                  </StepText>
                </Step>
                <Step>
                  <StepNumber>2</StepNumber>
                  <StepText>
                    {
                      lang.settings.businessCards
                        .center_the_qr_code_on_the_red_square[currentLangKey]
                    }
                  </StepText>
                </Step>
                <Step>
                  <StepNumber>3</StepNumber>
                  <StepText>
                    {
                      lang.settings.businessCards
                        .scan_the_qr_code_and_enter_a_password_for_your_card[
                        currentLangKey
                      ]
                    }
                  </StepText>
                </Step>
              </>
            )}

            {openScan && (
              <QrCodeContainer>
                <QrScanner
                  onDecode={result => {
                    getIdCardQrCode(result);
                  }}
                  onError={() => {}}
                />
              </QrCodeContainer>
            )}
          </StepsContainer>
        </Wrapper>
      </Container>
    </Modal>
  );
}
