import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.4rem 0.8rem;
  border-radius: 8px;
  background-color: rgb(63 161 255 / 16%);
  width: fit-content;

  ${({ theme }) => css`
    font-family: ${theme.tokens.fontFamilyPoppins};
    font-weight: ${theme.tokens.fontWeightMedium};
    color: ${theme.tokens.brandColorSecondaryPure};
  `}

  font-size: 1.2rem;
`;

export const IconContainer = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;

  svg {
    fill: #0a7ee3;
  }
`;
