import {
  type FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { differenceInMonths, isAfter, isFuture, subDays } from 'date-fns';
import { useParams } from 'react-router-dom';

import { useCardGroupsContext, useLangContext } from 'data/contexts';
import {
  AccountQueryKeys,
  type ICompanyAccount,
  useGetCompanyAccounts,
  useGetUserAccounts
} from 'data/modules/cards/account';
import { useGetCardGroup } from 'data/modules/cards/account/useCases/get-card-group/useGetCardGroup';
import { type ICardGroupUser } from 'data/modules/cards/cardGroups';
import { useGetCardGroupUsers } from 'data/modules/cards/cardGroups/useCases/get-card-group-users/useGetCardGroupUsers';
import {
  type IStatementDateFilter,
  type StatementMovementType
} from 'data/modules/cards/statement';

import { TransformArray } from 'shared/utils/global';

import {
  type IUseAdminStatementSidebar,
  type UseAdminStatementSidebarParamsType
} from './AdminStatementSidebar.types';

export function useAdminStatementSidebar({
  onFilter,
  initialDateFilter,
  initialSelectedUsers,
  cardsToBeFiltered,
  checkedAllUsers
}: UseAdminStatementSidebarParamsType): IUseAdminStatementSidebar {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { groupId } = useParams();
  const queryClient = useQueryClient();

  const contextCardGroup = useCardGroupsContext(
    state => state.selectedCardGroup
  );

  const { cardGroupData } = useGetCardGroup({
    cardGroupId: groupId || '',
    enabled: !contextCardGroup
  });

  const selectedCardGroup = contextCardGroup || cardGroupData;

  const [selectedMovementType, setSelectedMovementType] =
    useState<StatementMovementType>('all');
  const [dateFilter, setDateFilter] =
    useState<IStatementDateFilter>(initialDateFilter);
  const [dateFilterFromError, setDateFilterFromError] = useState('');
  const [dateFilterToError, setDateFilterToError] = useState('');
  const [selectedUsers, setSelectedUsers] =
    useState<string[]>(initialSelectedUsers);
  const [selectedCards, setSelectedCards] = useState<string[]>([]);

  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const [changedUsersFilter, setChangedUsersFilter] = useState(false);

  useEffect(() => {
    setChangedUsersFilter(true);
    setSelectedCards([]);
  }, [selectedUsers, dateFilter]);

  useEffect(() => {
    if (groupId) {
      queryClient.resetQueries({
        queryKey: [AccountQueryKeys.GET_USER_ACCOUNTS]
      });
      queryClient.resetQueries({
        queryKey: [AccountQueryKeys.GET_COMPANY_ACCOUNTS]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { paginatedUserAccounts, isLoadingUserAccounts } = useGetUserAccounts({
    limit: 1000,
    page: 1,
    status: ['ACTIVE'],
    enabled: !groupId
  });

  const { paginatedCompanyAccounts, isLoadingCompanyAccounts } =
    useGetCompanyAccounts({
      page: 1,
      limit: 1000,
      enabled: !groupId
    });

  const { cardGroupUsers, isFetchingCardGroupUsers } = useGetCardGroupUsers(
    {
      id: groupId as string,
      limit: 1000,
      page: 1
    },
    !!groupId
  );

  function transformICardGroupUserToICompanyAccount(
    cardGroupUser: ICardGroupUser
  ): ICompanyAccount {
    return {
      id: cardGroupUser.id,
      name: cardGroupUser.name,
      status: cardGroupUser.status === 'ACTIVE' ? 'ACTIVE' : 'BLOCKED',
      type: 'CORP_BUSINESS',
      description: 'Descrição da empresa',
      cardNumber: cardGroupUser.cardNumber || null,
      cardStatus: cardGroupUser.status === 'ACTIVE' ? 'ACTIVE' : 'BLOCKED',
      cardLockLevel: null,
      accessAccounts: [
        {
          id: cardGroupUser.userId,
          type: 'USER'
        }
      ],
      balances: [
        {
          id: cardGroupUser.balanceId,
          description: ''
        }
      ]
    };
  }

  function transformArrayICardGroupUserToICompanyAccount(
    cardGroupUsers: ICardGroupUser[]
  ): ICompanyAccount[] {
    return cardGroupUsers.map(transformICardGroupUserToICompanyAccount);
  }

  const userAccounts = paginatedUserAccounts?.data;

  const companyAccounts = !groupId
    ? paginatedCompanyAccounts?.data
    : cardGroupUsers?.data
      ? transformArrayICardGroupUserToICompanyAccount(cardGroupUsers?.data)
      : [];

  const usersSelectOptions = TransformArray.objectsToSelectOptions(
    userAccounts,
    'userId',
    'userName'
  ).concat(
    TransformArray.objectsToSelectOptions(companyAccounts, 'id', 'name')
  );

  const checkDateFilterErrors = useCallback(() => {
    setDateFilterFromError('');
    setDateFilterToError('');

    if (dateFilter.from === null || dateFilter.to === null) {
      if (dateFilter.from === null) {
        setDateFilterFromError(
          lang.cards.statement.sidebar.date_errors
            .please_provide_a_valid_start_date[currentLangKey]
        );
      }

      if (dateFilter.to === null) {
        setDateFilterToError(
          lang.cards.statement.sidebar.date_errors
            .please_provide_a_valid_end_date[currentLangKey]
        );
      }

      return;
    }

    const isFromDateInFuture = isFuture(dateFilter.from);
    const isToDateInFuture = isFuture(dateFilter.to);

    if (isFromDateInFuture || isToDateInFuture) {
      if (isFromDateInFuture) {
        setDateFilterFromError(
          lang.cards.statement.sidebar.date_errors
            .initial_date_bigger_than_actual[currentLangKey]
        );
      }
      if (isToDateInFuture) {
        setDateFilterToError(
          lang.cards.statement.sidebar.date_errors
            .final_date_bigger_than_actual[currentLangKey]
        );
      }
      return;
    }

    if (isAfter(dateFilter.from, dateFilter.to)) {
      setDateFilterFromError(
        lang.cards.statement.sidebar.date_errors
          .initial_date_bigger_than_final_date[currentLangKey]
      );
      setDateFilterToError(
        lang.cards.statement.sidebar.date_errors
          .final_date_smaller_than_initial_date[currentLangKey]
      );
      return;
    }

    // Desconsidera o dia da data final
    const dateDifferenceInMonths = differenceInMonths(
      subDays(dateFilter.to, 1),
      dateFilter.from
    );

    if (dateDifferenceInMonths >= 3) {
      setDateFilterToError(
        lang.cards.statement.sidebar.date_errors.max_period_three_months[
          currentLangKey
        ]
      );
    }
  }, [
    dateFilter.from,
    dateFilter.to,
    currentLangKey,
    lang.cards.statement.sidebar.date_errors
  ]);

  useEffect(() => {
    checkDateFilterErrors();
  }, [checkDateFilterErrors]);

  const cardsFilterDisabled =
    checkedAllUsers || changedUsersFilter
      ? true
      : cardsToBeFiltered.length === 0;

  function handleFilter(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    setChangedUsersFilter(false);
    onFilter({
      dateFilter,
      selectedMovementType,
      selectedUsers,
      selectedCards: cardsFilterDisabled ? [] : selectedCards,
      searchInput: searchInputRef.current?.value ?? ''
    });
  }

  const companySelectOption = {
    value: 'company',
    label:
      groupId && selectedCardGroup?.name
        ? selectedCardGroup?.name
        : lang.global.company[currentLangKey]
  };

  const selectUsersProps = {
    value:
      selectedUsers.length > 0 ? selectedUsers : [companySelectOption.value],
    options:
      selectedUsers.length > 0
        ? usersSelectOptions
        : [...usersSelectOptions, companySelectOption]
  };

  return {
    isLoadingOptions: groupId
      ? isFetchingCardGroupUsers
      : isLoadingUserAccounts || isLoadingCompanyAccounts,
    filters: {
      dateFilter,
      setDateFilter,
      selectedMovementType,
      setSelectedMovementType,
      searchInputRef,
      selectedUsers,
      setSelectedUsers,
      selectedCards,
      setSelectedCards
    },
    errors: {
      dateFilterFromError,
      dateFilterToError
    },
    handleFilter,
    selectUsersProps,
    changedUsersFilter,
    cardsFilterDisabled
  };
}
