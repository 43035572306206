import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import {
  OnboardingFooter,
  OnboardingText,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { Container, Content, IconContainer } from './LastScreen.styles';

export function LastScreen(): JSX.Element {
  const [lastScreen, finalize, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.user.last_screen,
    state.lang.global.finalize,
    state.currentLangKey
  ]);

  return (
    <Container>
      <Content>
        <IconContainer>
          <BaseIcon
            name='checkbox-circle'
            type='fill'
            size={4}
          />
        </IconContainer>

        <OnboardingTitle>{lastScreen.title[currentLangKey]}</OnboardingTitle>

        <OnboardingText>{lastScreen.message[currentLangKey]}</OnboardingText>
      </Content>

      <OnboardingFooter
        continueButtonProps={{
          text: finalize[currentLangKey],
          onClick: () => {
            window.open('/cartoes/inicio?finished', '_self');
          }
        }}
      />
    </Container>
  );
}
