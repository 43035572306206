import styled from 'styled-components';

export const Container = styled.form`
  flex-direction: column;
  display: flex;
`;

export const Content = styled.div`
  flex-direction: column;
  align-items: center;
  padding: 2.4rem 4.8rem;
  display: flex;
  gap: 2.4rem;

  p {
    text-align: center;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 2.4rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 2.4rem 0;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.tokens.brandColorPrimaryMedium};

  > svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const EmotionContainer = styled.div`
  justify-content: center;
  flex-wrap: wrap;
  display: flex;

  > button {
    border: none !important;
    padding: 1.6rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    > button {
      padding: 1.2rem;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    > button {
      padding: 0.6rem;
    }
  }
`;
