import { DefaultButton, Modal, SecondaryButton, Stepper } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useModalContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';
import {
  ListAndSearch,
  type ListSearchType
} from 'presentation/pages/cards/Management/CardGroups/components';

import { CardGroupsInfoModal, DescriptionModal, GroupConfiguration } from '..';

import { useCreateCardGroupsModal } from './useCreateCardGroupsModal';

import {
  Content,
  ModalContent,
  ModalFooter
} from './CreateCardGroupsModal.styles';

export function CreateCardGroupsModal(): JSX.Element {
  const { t } = useTranslation(['cards', 'global']);

  const [
    currentStepOfGroupCreation,
    handleChangeStepOfGroupCreation,
    cardGroupToBeCreated,
    paginationUsers,
    handleChangePaginationUsers,
    selectedUsersWithoutCardGroup,
    handleToggleSelectUsersWithoutCardGroup,
    paginationManagers,
    handleChangePaginationManagers,
    selectedAvailableToBeManager,
    handleToggleSelectAvailableToBeManager,
    hasCardGroups
  ] = useCardGroupsContext(
    useShallow(state => [
      state.currentStepOfGroupCreation,
      state.handleChangeStepOfGroupCreation,
      state.cardGroupToBeCreated,
      state.paginationUsers,
      state.handleChangePaginationUsers,
      state.selectedUsersWithoutCardGroup,
      state.handleToggleSelectUsersWithoutCardGroup,
      state.paginationManagers,
      state.handleChangePaginationManagers,
      state.selectedAvailableToBeManager,
      state.handleToggleSelectAvailableToBeManager,
      state.hasCardGroups
    ])
  );

  const {
    handleControlCreateGroup,
    paginatedUsersWithoutCardGroup,
    isFetchingUsersWithoutCardGroup,
    isFetchingAvailableToBeManager,
    paginatedAvailableToBeManager,
    isCreatingCardGroupName,
    availableToBeManagerPagination,
    usersWithoutCardGroupPagination
  } = useCreateCardGroupsModal();

  const { handleCloseModal } = useModalContext();

  if (currentStepOfGroupCreation <= 3 && !hasCardGroups)
    return <CardGroupsInfoModal />;

  return (
    <Modal.Container onClose={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>{t('createNewGroupOfCards')}</Modal.Title>

        <Modal.IconClose
          onClick={() => {
            handleCloseModal();
          }}
        />
      </Modal.Header>
      <ModalContent>
        <Loader isLoading={isCreatingCardGroupName} />

        <Stepper.Container>
          <Stepper.Item
            label={t('cards:configureGroup')}
            active={currentStepOfGroupCreation === 4}
            number={1}
            allowClick
          />

          <Stepper.Item
            label={t('cards:selectUsers')}
            active={currentStepOfGroupCreation === 5}
            number={2}
            allowClick
          />

          <Stepper.Item
            label={t('cards:selectManagers')}
            active={currentStepOfGroupCreation === 6}
            number={3}
            allowClick
          />
        </Stepper.Container>

        <Content>
          <DescriptionModal />

          {currentStepOfGroupCreation === 4 && <GroupConfiguration />}

          {currentStepOfGroupCreation === 5 && (
            <ListAndSearch
              items={paginatedUsersWithoutCardGroup ?? []}
              searchValue={paginationUsers.userName ?? ''}
              onChangeSearch={(value: string) => {
                handleChangePaginationUsers({
                  userName: value ?? ''
                });
              }}
              selectedItemIds={selectedUsersWithoutCardGroup
                .filter(item => selectedUsersWithoutCardGroup.includes(item))
                .map(user => ({ name: user, id: user }))}
              onChangeSelect={(value: ListSearchType) => {
                handleToggleSelectUsersWithoutCardGroup(value.id);
              }}
              isLoading={isFetchingUsersWithoutCardGroup}
              actionFn={usersWithoutCardGroupPagination.fetchNextPage}
              hasNextPage={usersWithoutCardGroupPagination.hasNextPage}
              isFetchingNextPage={
                usersWithoutCardGroupPagination.isFetchingNextPage
              }
            />
          )}

          {currentStepOfGroupCreation === 6 && (
            <ListAndSearch
              items={
                paginatedAvailableToBeManager?.map(item => ({
                  ...item,
                  disabled: item.type === 'ACCOUNT_MANAGER'
                })) ?? []
              }
              searchValue={paginationManagers.userName ?? ''}
              onChangeSearch={(value: string) => {
                handleChangePaginationManagers({
                  userName: value ?? ''
                });
              }}
              selectedItemIds={selectedAvailableToBeManager
                .filter(item => selectedAvailableToBeManager.includes(item))
                .map(user => ({ name: user, id: user }))}
              onChangeSelect={(value: ListSearchType) => {
                handleToggleSelectAvailableToBeManager(value.id);
              }}
              isLoading={isFetchingAvailableToBeManager}
              actionFn={availableToBeManagerPagination.fetchNextPage}
              hasNextPage={availableToBeManagerPagination.hasNextPage}
              isFetchingNextPage={
                availableToBeManagerPagination.isFetchingNextPage
              }
            />
          )}
        </Content>
      </ModalContent>
      <ModalFooter>
        <SecondaryButton
          size='small'
          onClick={() => {
            hasCardGroups && currentStepOfGroupCreation === 4
              ? handleCloseModal()
              : handleChangeStepOfGroupCreation('prev');
          }}
        >
          {t('global:back')}
        </SecondaryButton>

        <DefaultButton
          size='small'
          onClick={() => {
            handleControlCreateGroup();
          }}
          disabled={
            currentStepOfGroupCreation === 4 &&
            !cardGroupToBeCreated?.groupName &&
            true
          }
        >
          {currentStepOfGroupCreation !== 6
            ? t('global:continue')
            : t('cards:createNewGroup')}
        </DefaultButton>
      </ModalFooter>
    </Modal.Container>
  );
}
