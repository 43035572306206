import { type IShareholderFieldsForm } from 'data/modules/cards/accountHolders';

export const shareholdersFormInitialValue: IShareholderFieldsForm = {
  shareholders: [
    {
      name: '',
      document: '',
      email: '',
      phoneNumber: '',
      motherName: '',
      birthDate: '',
      street: '',
      number: '',
      neighborhood: '',
      complement: '',
      city: '',
      state: '',
      country: 'Brasil',
      postalCode: '',
      swapType: ''
    }
  ]
};
