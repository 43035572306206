import {
  type ICompanyTransaction,
  type IPersistenceCompanyTransaction
} from 'data/modules/cards/statement';

import { CustomObject } from 'shared/utils/custom';

class CompanyTransactionMapper {
  constructor() {
    this.toDomain = this.toDomain.bind(this);
  }

  toDomain(
    persistenceCompanyTransaction: IPersistenceCompanyTransaction
  ): ICompanyTransaction {
    return {
      cardNumber: persistenceCompanyTransaction.card_number,
      companyId: persistenceCompanyTransaction.company_id,
      currencyCode: persistenceCompanyTransaction.currency_code,
      debitCredit: persistenceCompanyTransaction.debit_credit,
      finalBillingAmount: persistenceCompanyTransaction.final_billing_amount,
      finalBillingCurrencyCode:
        persistenceCompanyTransaction.final_billing_currency_code,
      finalBillingCurrency:
        persistenceCompanyTransaction.final_billing_currency,
      billingAmountAuthorized:
        persistenceCompanyTransaction.billing_amount_authorized,
      isInternational: persistenceCompanyTransaction.is_international,
      merchantName: persistenceCompanyTransaction.merchant_name,
      isReversal: persistenceCompanyTransaction.is_reversal,
      paymentDescription: persistenceCompanyTransaction.payment_description,
      reversalAmount: persistenceCompanyTransaction.reversal_amount,
      transactionId: persistenceCompanyTransaction.transaction_id,
      transferorName: persistenceCompanyTransaction.transferor_name,
      mcc: Number(persistenceCompanyTransaction.mcc),
      merchantCity: persistenceCompanyTransaction.merchant_city,
      ...CustomObject.pick(persistenceCompanyTransaction, [
        'amount',
        'currency',
        'datetime',
        'reversed',
        'type',
        'visible'
      ]),
      relateds:
        persistenceCompanyTransaction.relateds &&
        persistenceCompanyTransaction.relateds.length > 0
          ? persistenceCompanyTransaction.relateds.map(this.toDomain)
          : []
    };
  }
}

export default new CompanyTransactionMapper();
