import styled, { css } from 'styled-components';

import { type DsTagStylesType } from './DsTag.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<AddPrefixToType<DsTagStylesType>>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.tokens.spacingInsetQuarck};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusXl};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  padding: ${({ theme }) =>
    `${theme.tokens.spacingInsetQuarck} ${theme.tokens.spacingInsetNano}`};

  > svg {
    flex-shrink: 0;
    margin-right: 1rem;
  }

  ${({ $type }) =>
    $type === 'primary' &&
    css<AddPrefixToType<DsTagStylesType>>`
      background-color: ${({ $outlined, theme }) =>
        $outlined ? 'transparent' : `${theme.tokens.brandColorPrimaryMedium}`};
      color: ${({ $outlined, theme }) =>
        $outlined
          ? `${theme.tokens.brandColorPrimaryPure}`
          : `${theme.tokens.brandColorSecondaryDark}`};
      border: ${({ $outlined, theme }) =>
        $outlined &&
        `${theme.tokens.borderWidthThin} solid ${theme.tokens.brandColorPrimaryPure}`};

      svg {
        color: ${({ $outlined, theme }) =>
          $outlined
            ? `${theme.tokens.brandColorPrimaryPure}`
            : `${theme.tokens.brandColorSecondaryDark}`};
      }
    `}

  ${({ $type }) =>
    $type === 'secondary' &&
    css<AddPrefixToType<DsTagStylesType>>`
      background-color: ${({ $outlined, theme }) =>
        $outlined ? 'transparent' : `${theme.tokens.brandColorSecondaryPure}`};
      color: ${({ $outlined, theme }) =>
        $outlined
          ? `${theme.tokens.brandColorPrimaryDark}`
          : `${theme.tokens.neutralColorHighMedium}`};
      border: ${({ $outlined, theme }) =>
        $outlined &&
        `${theme.tokens.borderWidthThin} solid ${theme.tokens.brandColorPrimaryDark}`};
    `}

    ${({ $type }) =>
    $type === 'general' &&
    css<AddPrefixToType<DsTagStylesType>>`
      background-color: ${({ $outlined, theme }) =>
        $outlined ? 'transparent' : `${theme.tokens.neutralColorHighMedium}`};
      color: ${({ theme }) => theme.tokens.neutralColorLowDark};
      border: ${({ $outlined, theme }) =>
        $outlined &&
        `${theme.tokens.borderWidthThin} solid ${theme.tokens.neutralColorLowMedium}`};
    `}

    ${({ $type }) =>
    $type === 'success' &&
    css<AddPrefixToType<DsTagStylesType>>`
      background-color: ${({ $outlined, theme }) =>
        $outlined
          ? 'transparent'
          : `${theme.tokens.feedbackColorSuccessLight}`};
      color: ${({ theme }) => theme.tokens.feedbackColorSuccessPure};
      border: ${({ $outlined, theme }) =>
        $outlined &&
        `${theme.tokens.borderWidthThin} solid ${theme.tokens.feedbackColorSuccessPure}`};
    `}

    ${({ $type }) =>
    $type === 'warning' &&
    css<AddPrefixToType<DsTagStylesType>>`
      background-color: ${({ $outlined, theme }) =>
        $outlined
          ? 'transparent'
          : `${theme.tokens.feedbackColorWarningMedium}`};
      color: ${({ theme }) => theme.tokens.feedbackColorWarningPure};
      border: ${({ $outlined, theme }) =>
        $outlined &&
        `${theme.tokens.borderWidthThin} solid ${theme.tokens.feedbackColorWarningPure}`};
    `}

    ${({ $type }) =>
    $type === 'error' &&
    css<AddPrefixToType<DsTagStylesType>>`
      background-color: ${({ $outlined, theme }) =>
        $outlined ? 'transparent' : `${theme.tokens.feedbackColorErrorLight}`};
      color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
      border: ${({ $outlined, theme }) =>
        $outlined &&
        `${theme.tokens.borderWidthThin} solid ${theme.tokens.feedbackColorErrorPure}`};
    `}
`;
