import { DefaultButton } from 'ds';

import { useLangContext, useModalContext } from 'data/contexts';

import { StatusText } from 'presentation/pages/store/components/StatusText';

import { useProductHeaderBanner } from './useProductHeaderBanner';

import { type IProductHeaderBannerProps } from './ProductHeaderBanner.types';

import {
  BannerImage,
  Container,
  Content,
  Wrapper
} from './ProductHeaderBanner.styles';

export function ProductHeaderBanner({
  identifier,
  status,
  ...props
}: IProductHeaderBannerProps): JSX.Element {
  const {
    currentLangKey,
    lang: {
      store: {
        productCards: { statusMessage },
        product: { product_header_banner: productHeaderBanner }
      }
    }
  } = useLangContext();

  const { handleOpenModal } = useModalContext();

  const { title, description, banner } = useProductHeaderBanner({
    identifier
  });

  return (
    <Container {...props}>
      <Wrapper>
        <Content>
          <div>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>

          {status === 'not_owned' ? (
            <DefaultButton
              onClick={() => {
                handleOpenModal('storeRequestModal');
              }}
            >
              {productHeaderBanner.button_start_using[currentLangKey]}
            </DefaultButton>
          ) : (
            <StatusText status={status}>
              {statusMessage[status][currentLangKey]}
            </StatusText>
          )}
        </Content>

        <BannerImage
          src={banner}
          alt='product-header-banner'
        />
      </Wrapper>
    </Container>
  );
}
