import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';

import { Currency } from 'shared/utils/format';
import { Lang } from 'shared/utils/global';

import {
  type IUseInternationalFinalValue,
  type UseInternationalFinalValueParamsType
} from './InternationalFinalValue.types';

import { PendingSpread } from './InternationalFinalValue.styles';

export function useInternationalFinalValue({
  transaction
}: UseInternationalFinalValueParamsType): IUseInternationalFinalValue {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const isPendingSpread =
    transaction.isReversal === 'NOT' &&
    !transaction.relateds.find(item =>
      item.paymentDescription?.startsWith('TX INT')
    );

  const tooltipContent =
    transaction.currencyCode === 'BRL' ? (
      lang.cards.statement.transaction_details_modal.international_transaction
        .final_value_tooltip_brl[currentLangKey]
    ) : (
      <>
        {/* COTAÇÃO MOEDA + SPREAD */}
        <p>
          {Lang.putValuesInString(
            lang.cards.statement.transaction_details_modal
              .international_transaction.final_value_tooltip_1[currentLangKey],
            {
              code: transaction.currencyCode,
              value: Currency.format(
                'BRL',
                transaction.finalBillingAmount / transaction.amount,
                false,
                4,
                { minimumFractionDigits: 4, maximumFractionDigits: 4 }
              )
            }
          )}
        </p>

        <p>
          {
            lang.cards.statement.transaction_details_modal
              .international_transaction.final_value_tooltip_2[currentLangKey]
          }
        </p>

        {/* COBRANÇA DE SPREAD PENDENTE */}
        {isPendingSpread && (
          <PendingSpread>
            <BaseIcon
              name='clock'
              color='clementine'
              size={1.4}
            />
            {
              lang.cards.statement.transaction_details_modal
                .international_transaction.pending_spread[currentLangKey]
            }
          </PendingSpread>
        )}
      </>
    );

  return {
    tooltipContent
  };
}
