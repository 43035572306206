import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.tokens.spacingXs};
  grid-template-columns: 1fr;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-columns: 1fr 1fr;
  }
`;
