import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type SelectionButtonStyleType } from './FastDates.types';

export const ButtonsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${tokens.spacingXs};
`;

export const SelectionButton = styled.button<
  AddPrefixToType<SelectionButtonStyleType>
>`
  flex: 1 0 0;
  white-space: nowrap;
  align-items: center;
  line-height: 2.2rem;
  display: inline-flex;
  justify-content: center;
  letter-spacing: 0.025rem;
  gap: ${tokens.spacingNano};
  font-size: ${tokens.fontSizeXxss};
  transition: all 0.25s ease-in-out;
  color: ${tokens.neutralColorLowMedium};
  border-radius: ${tokens.borderRadiusXs};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
  background: ${tokens.neutralColorHighPure};
  border: 1px solid ${tokens.neutralColorLowLightest};
  padding: ${tokens.spacingNano} ${tokens.spacingXxs};

  &:hover {
    box-shadow:
      2px 2px 8px 0 rgb(0 0 0 / 5%),
      3px 3px 4px 0 rgb(0 0 0 / 10%);
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${tokens.brandColorSecondaryPure};
      background: ${tokens.brandColorPrimaryLightest};
      border-color: ${tokens.brandColorSecondaryLight};
    `}
`;

export const DatePickerRow = styled.div`
  display: flex;

  gap: ${tokens.spacingXs};

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;

    > * {
      width: 100%;
    }
  }
`;
