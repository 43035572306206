import { useState } from 'react';

import {
  type ISubMenuSelectDefaultOption,
  type IUseSubMenuSelectDefault
} from './SubMenuSelectDefault.types';

export function useSubMenuSelectDefault(): IUseSubMenuSelectDefault {
  const [searchValue, setSearchValue] = useState<string>('');

  const [menuIndex, setMenuIndex] = useState<undefined | number>(undefined);

  const [selectedOption, setSelectedOption] = useState<
    ISubMenuSelectDefaultOption | undefined
  >();

  return {
    searchValue,
    setSearchValue,
    menuIndex,
    setMenuIndex,
    selectedOption,
    setSelectedOption
  };
}
