import { faker } from '@faker-js/faker';

import { type ITeamMemberItem } from 'data/modules/teamMembers';

import { MockApi } from 'shared/utils/global';

export async function attachTeamMembersToBusinessCardMock(): Promise<
  ITeamMemberItem[]
> {
  await MockApi.sleep(2000);

  return [
    {
      id: faker.number.int(100),
      uuid: faker.string.uuid(),
      name: faker.person.fullName()
    }
  ];
}
