import { useAccordionItem } from './useAccordionItem';

import { type IAccordionItemProps } from './AccordionItem.types';

import { Container } from './AccordionItem.styles';

export function AccordionItem({
  isOpen,
  onToggle,
  children,
  disabled = false
}: IAccordionItemProps): JSX.Element {
  const { handleToggle, accordionItemRef } = useAccordionItem({
    onToggle
  });

  const Label = children[0];
  const Caption = children[1];

  return (
    <Container
      open={isOpen}
      $disabled={disabled}
      ref={accordionItemRef}
      aria-disabled={disabled}
      data-testid='accordion-container'
    >
      <Label.type
        {...Label.props}
        onClick={handleToggle}
        tabIndex={disabled ? -1 : 0}
        data-testid='accordion-label'
      />
      <Caption.type
        {...Caption.props}
        data-testid='accordion-caption'
      />
    </Container>
  );
}
