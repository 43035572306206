import styled from 'styled-components';

export const Container = styled.div`
  max-width: 50rem;
  padding: 0 1.6rem;

  > p {
    color: ${({ theme }) => theme.colors.emperor};
    margin: 1rem 0;
    font-size: 1.2rem;
  }
`;
