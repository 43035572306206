import { useLangContext } from 'data/contexts';

import { Spinner } from 'presentation/components/base/Spinner';
import { SimpleTable } from 'presentation/components/global/Table';
import { DeleteApprovalRepresentativesModal } from 'presentation/pages/cards/Onboarding/components/Company/ApprovalRepresentatives/DeleteApprovalRepresentativesModal/DeleteApprovalRepresentativesModal.component';
import {
  OnboardingFooter,
  OnboardingText,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { useApprovalRepresentatives } from './useApprovalRepresentatives';

import {
  Container,
  Content,
  CustomLoadingOverlay,
  EmptyPartnersTable
} from './ApprovalRepresentatives.styles';

export function ApprovalRepresentatives(): JSX.Element {
  const [currentLangKey, approvalRepresentatives] = useLangContext(state => [
    state.currentLangKey,
    state.lang.cards.onboarding.company.approval_representatives
      .approval_representatives
  ]);

  const {
    handleAddRepresentative,
    isLoadingRepresentatives,
    approvalRepresentativeTableData,
    approvalRepresentativeTableHeader,
    handleDeleteSelectedLegalRepresentative,
    isAuthorized,
    handleContinue
  } = useApprovalRepresentatives();

  return (
    <Container>
      <Content>
        <OnboardingTitle>
          {approvalRepresentatives.title[currentLangKey]}
        </OnboardingTitle>
        <OnboardingText>
          {approvalRepresentatives.information[currentLangKey]}
        </OnboardingText>
        {isLoadingRepresentatives && (
          <CustomLoadingOverlay>
            <Spinner
              color='royalBlue'
              size={4}
            />
          </CustomLoadingOverlay>
        )}
        <SimpleTable
          dataId='id'
          showHeaderOnMobile={false}
          header={approvalRepresentativeTableHeader}
          data={approvalRepresentativeTableData}
          emptyFallback={
            approvalRepresentativeTableData.length === 0 ? (
              <EmptyPartnersTable>
                <td colSpan={approvalRepresentativeTableHeader.length}>
                  {approvalRepresentatives.empty[currentLangKey]}
                </td>
              </EmptyPartnersTable>
            ) : undefined
          }
        />
        <OnboardingFooter
          addButtonProps={{
            onClick: handleAddRepresentative
          }}
          {...(isAuthorized && {
            continueButtonProps: {
              onClick: handleContinue
            }
          })}
        />
      </Content>
      <DeleteApprovalRepresentativesModal
        onConfirm={handleDeleteSelectedLegalRepresentative}
      />
    </Container>
  );
}
