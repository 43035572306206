import { useRef } from 'react';

import { Checkbox, SkeletonLoader, TextInput } from 'ds';
import { useTranslation } from 'react-i18next';

import { useInfiniteScroll } from 'shared/hooks/global';

import { type IListAndSearchProps } from './ListAndSearch.types';

import {
  Container,
  EmptyList,
  List,
  ListContainer,
  ListItem,
  ListItemLoading
} from './ListAndSearch.styles';

export function ListAndSearch({
  items,
  searchValue,
  onChangeSearch,
  onChangeSelect,
  isLoading,
  selectedItemIds,
  actionFn,
  hasNextPage,
  isFetchingNextPage
}: IListAndSearchProps): JSX.Element {
  const { t } = useTranslation(['global', 'cards']);

  const rootRef = useRef<HTMLDivElement>(null);

  const infiniteScrollRef = useRef<HTMLDivElement>(null);

  useInfiniteScroll({
    hasNextPage,
    isFetchingNextPage,
    actionFn,
    infiniteScrollRef: infiniteScrollRef.current,
    rootRef: rootRef.current,
    rootMargin: '30%'
  });

  return (
    <Container>
      <TextInput.Default
        label={t('global:searchByUser')}
        placeholder={t('global:searchByUser')}
        value={searchValue}
        onChangeValue={(value: string) => {
          onChangeSearch(value ?? '');
        }}
        onClearValue={() => {
          onChangeSearch('');
        }}
        iconLeft='SearchIcon'
        small
      />

      <ListContainer>
        <List
          className='list-content-scroll'
          ref={rootRef}
        >
          {items?.map((item, index) => (
            <ListItem
              $disabled={item.disabled}
              key={index}
              title={
                item.disabled
                  ? t('cards:allAccountManagersAreAutomaticallyGroupManagers')
                  : ''
              }
            >
              <Checkbox.Default
                value={
                  item.disabled
                    ? true
                    : selectedItemIds
                      ? selectedItemIds.some(
                          selected => selected.id === item.id
                        )
                      : false
                }
                onChangeValue={() => {
                  onChangeSelect(item);
                }}
                disabled={item.disabled}
              />
              <span>{item.name}</span>
            </ListItem>
          ))}

          {isLoading &&
            Array.from({ length: 3 }).map((item, index) => (
              <ListItem key={`paging-skeleton-${index}`}>
                <SkeletonLoader
                  height='3rem'
                  margin='.8rem auto'
                />
              </ListItem>
            ))}

          {isFetchingNextPage && (
            <ListItemLoading>{t('global:loadingMoreItems')}...</ListItemLoading>
          )}

          <div ref={infiniteScrollRef}></div>
        </List>

        {items?.length === 0 && !isLoading && (
          <EmptyList>{t('cards:thereAreNoItems')}</EmptyList>
        )}
      </ListContainer>
    </Container>
  );
}
