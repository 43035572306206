import { DefaultButton, Modal, TextButton } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useLangContext, useModalContext, useTourContext } from 'data/contexts';

import newsImg from 'shared/assets/images/expenses/modal/news.svg';
import { localStorageKeys } from 'shared/constants/global';

import { type INewVersionExpensesModalProps } from './NewVersionExpensesModal.types';

import {
  ContentContainer,
  Description,
  ModalContent,
  ModalFooter
} from './NewVersionExpensesModal.styles';

export function NewVersionExpensesModal({
  onStartTour
}: INewVersionExpensesModalProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  const { handleCloseModal } = useModalContext();

  const [handleReset] = useTourContext(
    useShallow(state => [state.handleReset])
  );

  return (
    <Modal.Container
      variant='medium'
      onClose={() => {
        handleReset('');
        localStorage.setItem(localStorageKeys.expensesPageTour, 'true');
        handleCloseModal();
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {
            lang.expenses.modal.new_version_modal
              .new_version_of_the_expenses_screen[currentLangKey]
          }
        </Modal.Title>
        <Modal.IconClose
          onClick={() => {
            handleReset('');
            localStorage.setItem(localStorageKeys.expensesPageTour, 'true');
            handleCloseModal();
          }}
        />
      </Modal.Header>

      <ContentContainer>
        <Modal.Content className='no-padding-right'>
          <ModalContent>
            <img src={newsImg} />

            <Description>
              <p>
                {
                  lang.expenses.modal.new_version_modal
                    .description_first_paragraph[currentLangKey]
                }
              </p>
              <p>
                {
                  lang.expenses.modal.new_version_modal
                    .description_second_paragraph[currentLangKey]
                }
              </p>
              <p>
                {
                  lang.expenses.modal.new_version_modal
                    .description_third_paragraph[currentLangKey]
                }
              </p>
              <p>
                {
                  lang.expenses.modal.new_version_modal
                    .description_fourth_paragraph[currentLangKey]
                }
              </p>
            </Description>
          </ModalContent>
        </Modal.Content>
      </ContentContainer>

      <Modal.Footer>
        <ModalFooter>
          <TextButton
            size='small'
            onClick={() => {
              handleReset('');
              localStorage.setItem(localStorageKeys.expensesPageTour, 'true');
              handleCloseModal();
            }}
            type='button'
          >
            {lang.global.close[currentLangKey]}
          </TextButton>

          <DefaultButton
            size='small'
            type='button'
            onClick={onStartTour}
          >
            {lang.global.start[currentLangKey]}
          </DefaultButton>
        </ModalFooter>
      </Modal.Footer>
    </Modal.Container>
  );
}
