import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  padding-top: 2.4rem;

  > h1 {
    text-align: left;
  }
`;

export const StyledForm = styled.form`
  gap: 1.6rem;
  display: flex;
  margin-top: 4.6rem;
  flex-direction: column;

  & > :nth-last-child(3) {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const RepresentativeContent = styled.div`
  gap: 1.6rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid ${({ theme }) => theme.tokens.neutralColorHighMedium};
`;

export const AddButton = styled.button`
  border: none;
  background: none;
  font-size: 1.6rem;
  width: fit-content;
  padding-top: 1.6rem;
  text-decoration: underline;
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto};
  color: ${({ theme }) => theme.tokens.brandColorSecondaryPure};
`;
