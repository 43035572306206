import { useLangContext } from 'data/contexts';
import { useChangeBusinessCardCostsCenters } from 'data/modules/cards/businessCard';

import { Select } from 'presentation/components/base/Select';
import { Loader } from 'presentation/components/global/Loader';
import { Title } from 'presentation/components/global/Title';
import { ListItems } from 'presentation/pages/settings/Cards/ManageBusinessCard/components/List';

import { useCostsCentersManageBusinessCard } from './useCostsCentersManageBusinessCard';

import { type ICostsCentersManageBusinessCardProps } from './useCostsCentersManageBusinessCard.types';

import {
  Container,
  CustomButton,
  FormInputs,
  SelectProjectContainer
} from './CostsCentersManageBusinessCard.styles';

export function CostsCentersManageBusinessCard({
  card
}: ICostsCentersManageBusinessCardProps): JSX.Element {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const { costsCentersOptions, isLoadingCostsCenters, relatedCostsCenters } =
    useCostsCentersManageBusinessCard({ uuid: card.uuid });

  const {
    control,
    errors,
    handleRemoveCostsCenters,
    handleSubmit,
    isAttachingCostsCenters,
    isRemovingRelatedCostsCenters
  } = useChangeBusinessCardCostsCenters({ uuid: card.uuid });

  return (
    <Container>
      {(isLoadingCostsCenters || isAttachingCostsCenters) && (
        <Loader isLoading />
      )}
      <SelectProjectContainer>
        <Title
          text={`${lang.settings.manageBusinessCard.relate_cost_centers_with_card[currentLangKey]} ${card.name}`}
          color='white'
          backgroundColor='primary'
        />
        <FormInputs>
          <Select
            isMulti
            name='costsCenters'
            options={costsCentersOptions}
            label={
              lang.settings.manageBusinessCard.relate_cost_centers[
                currentLangKey
              ]
            }
            placeholder={
              lang.settings.manageBusinessCard.search_cost_center[
                currentLangKey
              ]
            }
            control={control}
            error={errors.costsCenters?.message}
          />

          <CustomButton
            color='success'
            onClick={handleSubmit}
          >
            {lang.global.save[currentLangKey]}
          </CustomButton>
        </FormInputs>
      </SelectProjectContainer>

      <SelectProjectContainer>
        <Title
          text={`${lang.settings.manageBusinessCard.card_related_cost_centers[currentLangKey]} ${card.name}`}
          color='white'
          backgroundColor='primary'
        />

        <ListItems
          items={relatedCostsCenters}
          onRemoveItem={value => {
            handleRemoveCostsCenters({ id: Number(value) });
          }}
          infoMessage={
            lang.settings.manageBusinessCard.no_related_cost_centers[
              currentLangKey
            ]
          }
          titleModal={
            lang.settings.manageBusinessCard.remove_relationship[currentLangKey]
          }
          isLoading={isRemovingRelatedCostsCenters}
        />
      </SelectProjectContainer>
    </Container>
  );
}
