import { useQueryCache } from 'data/cache';
import {
  FuelQueryKeys,
  FuelService,
  type IUseGetTypesOfFuels,
  type IUseGetTypesOfFuelsParams
} from 'data/modules/fuel';

export function useGetTypesOfFuels(
  params?: IUseGetTypesOfFuelsParams
): IUseGetTypesOfFuels {
  const { data, isLoading } = useQueryCache({
    queryKey: params
      ? [FuelQueryKeys.GET_TYPES_OF_FUELS, params]
      : [FuelQueryKeys.GET_TYPES_OF_FUELS],
    queryFn: async () =>
      await FuelService.getTypesOfFuels({
        vehicleId: params?.vehicleId,
        withSubtypes: params?.withSubtypes
      })
  });

  return {
    typesOfFuels: data ?? [],
    isLoadingTypesOfFuels: isLoading
  };
}
