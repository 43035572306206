import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { type IBaseIconProps } from 'presentation/components/base/Icon/BaseIcon/BaseIcon.types';
import { DsCheckbox } from 'presentation/ds/DsCheckbox';
import {
  OnboardingFooter,
  OnboardingText
} from 'presentation/pages/cards/Onboarding/components/General';

import { usePoliciesAndTerms } from './usePoliciesAndTerms';

import { RequiredDocumentIconName } from './PoliciesAndTerms.types';

import {
  Container,
  Content,
  CustomLink,
  DocumentItem,
  ErrorMessage,
  ItemContainer,
  OnboardingBoldTitle,
  PoliciesAndTermsContainer,
  RequiredDocumentsList
} from './PoliciesAndTerms.styles';

export function PoliciesAndTerms(): JSX.Element {
  const navigate = useNavigate();

  const [company, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.company,
    state.currentLangKey
  ]);

  const { control, errors, handleUpdateCompanyPoliciesAndTerms } =
    usePoliciesAndTerms();

  return (
    <Container onSubmit={handleUpdateCompanyPoliciesAndTerms}>
      <Content>
        <OnboardingBoldTitle>
          {company.policies_and_terms.title[currentLangKey]}
        </OnboardingBoldTitle>

        <RequiredDocumentsList>
          {company.policies_and_terms.required_documents.map(
            (document, index) => (
              <DocumentItem key={document.pt}>
                <BaseIcon
                  name={
                    RequiredDocumentIconName[index] as IBaseIconProps['name']
                  }
                  size={2.4}
                  type='line'
                  color='primary'
                />
                <OnboardingText>{document[currentLangKey]}</OnboardingText>
              </DocumentItem>
            )
          )}
        </RequiredDocumentsList>

        <PoliciesAndTermsContainer>
          <OnboardingText>
            {company.policies_and_terms.message[currentLangKey]}
            <b>{company.policies_and_terms.messageB[currentLangKey]}</b>
          </OnboardingText>

          <ItemContainer>
            <DsCheckbox
              name='acceptPrivacyPolicy'
              control={control}
            />

            <label htmlFor='acceptPrivacyPolicy'>
              <OnboardingText>
                {company.policies_and_terms.policies.message[currentLangKey]}

                <CustomLink
                  to='https://vexpenses.com.br/politica-privacidade'
                  target='_blank'
                >
                  {company.policies_and_terms.policies.link[currentLangKey]}
                </CustomLink>
              </OnboardingText>

              {errors.acceptPrivacyPolicy?.message && (
                <ErrorMessage>
                  {errors.acceptPrivacyPolicy?.message}
                </ErrorMessage>
              )}
            </label>
          </ItemContainer>

          <ItemContainer>
            <DsCheckbox
              name='acceptTermsOfUse'
              control={control}
            />

            <label htmlFor='acceptTermsOfUse'>
              <OnboardingText>
                {company.policies_and_terms.terms.message[currentLangKey]}

                <CustomLink
                  to='https://vexpenses.com.br/termos-de-uso-cartoes.pdf'
                  target='_blank'
                >
                  {company.policies_and_terms.terms.link[currentLangKey]}
                </CustomLink>
              </OnboardingText>

              {errors.acceptTermsOfUse?.message && (
                <ErrorMessage>{errors.acceptTermsOfUse?.message}</ErrorMessage>
              )}
            </label>
          </ItemContainer>
        </PoliciesAndTermsContainer>
      </Content>

      <OnboardingFooter
        continueButtonProps={{
          onClick: () => {}
        }}
        backButtonProps={{
          onClick: () => {
            navigate('/cartoes/onboarding/empresa/inicio');
          }
        }}
      />
    </Container>
  );
}
