import { addDays, format, startOfDay } from 'date-fns';

import {
  type ICompanyStatement,
  type IGetCompanyStatementPayload,
  type IGetPersistenceCompanyStatementPayload,
  type IPersistenceCompanyStatement
} from 'data/modules/cards/statement';

import { CompanyTransactionMapper } from '.';

class GetCompanyStatementMapper {
  toDomain(
    persistenceCompanyStatement: IPersistenceCompanyStatement
  ): ICompanyStatement {
    return {
      balance: persistenceCompanyStatement.balance,
      date: persistenceCompanyStatement.date,
      items: persistenceCompanyStatement.items.map(
        CompanyTransactionMapper.toDomain
      )
    };
  }

  toPersistence(
    payload: IGetCompanyStatementPayload
  ): IGetPersistenceCompanyStatementPayload {
    if (payload.cardGroupId) {
      return {
        end_date: `${format(addDays(startOfDay(payload.endDate), 1), "yyyy-MM-dd'T'HH:mm:ss'Z'")}`,
        start_date: `${format(addDays(startOfDay(payload.startDate), 1), "yyyy-MM-dd'T'HH:mm:ss'Z'")}`,
        timezone: payload.timezone,
        card_group_id: payload.cardGroupId
      };
    }

    return {
      end_date: `${format(addDays(startOfDay(payload.endDate), 1), "yyyy-MM-dd'T'HH:mm:ss'Z'")}`,
      start_date: `${format(addDays(startOfDay(payload.startDate), 1), "yyyy-MM-dd'T'HH:mm:ss'Z'")}`,
      timezone: payload.timezone
    };
  }
}

export default new GetCompanyStatementMapper();
