import { Navigate, Outlet } from 'react-router-dom';

import { useAuthContext } from 'data/contexts';

export function UserMiddleware(): JSX.Element {
  const user = useAuthContext(state => state.user);

  if (user?.cards?.isManagerWithoutAccount) {
    return <Navigate to='gestao' />;
  }

  if (user?.cards?.isCardGroupManagerWithoutAccount) {
    return <Navigate to='grupos-de-cartoes' />;
  }

  return <Outlet />;
}
