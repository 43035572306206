import { useEffect, useState } from 'react';

import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { useApportionmentContext } from 'data/contexts';
import { useGetCurrencies } from 'data/modules/currencies';
import {
  type ICreateRouteExpenseFirstStepFieldsForm,
  type IValidateDateLimitsByProjectPayload,
  useGetExpenseParameters,
  useValidateDateLimitsByProject,
  useValidateMileagePolicyByProject,
  useValidateMileagePolicyByUser
} from 'data/modules/expenses';
import { useGetPaymentMethods } from 'data/modules/paymentMethods';
import { useGetPreRegisteredSections } from 'data/modules/preRegisteredSections';

import { Currency } from 'shared/utils/format';
import { TransformArray } from 'shared/utils/global';
import { Validate } from 'shared/utils/validation';

import {
  type IUseFirstStepForm,
  type IUseFirstStepFormParams
} from './FirstStepForm.types';

export function useFirstStepForm({
  expenseUuidToUpdate
}: IUseFirstStepFormParams): IUseFirstStepForm {
  const { expenseParameters } = useGetExpenseParameters();

  const { control, getValues, setValue, clearErrors, setError } =
    useFormContext<ICreateRouteExpenseFirstStepFieldsForm>();

  const {
    handleChangeDisabledKilometerInput,
    handleChangeRequiredReceiptInManualRoute
  } = useApportionmentContext();

  const {
    append: apportionmentAppend,
    fields: apportionmentFields,
    replace: apportionmentReplace
  } = useFieldArray({
    control,
    name: 'apportionment'
  });

  const preRegisteredSectionIsSelected = !!getValues('preRegisteredSection');

  const [mileageFieldReadonly, setMileageFieldReadonly] = useState(false);

  useEffect(() => {
    setMileageFieldReadonly(preRegisteredSectionIsSelected);
  }, [preRegisteredSectionIsSelected]);

  const expenseValue = useWatch({
    control,
    name: 'value'
  });

  const expenseValueAsNumber = Currency.parseToFloat(expenseValue ?? '');

  const { preRegisteredSections, isLoadingPreRegisteredSections } =
    useGetPreRegisteredSections();

  const preRegisteredSectionsOptions =
    preRegisteredSections?.map(preRegisteredSection => ({
      value: String(preRegisteredSection.id),
      label:
        preRegisteredSection.name + ' - ' + preRegisteredSection.mileage + 'km'
    })) ?? [];

  const { paymentMethods, isLoadingPaymentMethods } = useGetPaymentMethods();

  const paymentMethodsOptions = TransformArray.objectsToSelectOptions(
    paymentMethods,
    'id',
    'description',
    true
  );

  const paymentMethodIsSelected = !!getValues('paymentMethod');

  const [fieldRefundableDisabled, setFieldRefundableDisabled] = useState(false);

  useEffect(() => {
    setFieldRefundableDisabled(paymentMethodIsSelected);
  }, [paymentMethodIsSelected]);

  const { getEnsuredValidateDateLimitsByProject } =
    useValidateDateLimitsByProject();

  const { t } = useTranslation('expenses');

  const { currencies, isLoadingCurrencies } = useGetCurrencies();

  const { getEnsuredValidateMileagePolicyByUser } =
    useValidateMileagePolicyByUser();

  const { getEnsuredValidateMileagePolicyByProject } =
    useValidateMileagePolicyByProject();

  const currenciesOptions = TransformArray.objectsToSelectOptions(
    currencies,
    'id',
    'isoCode',
    true
  );

  function handleClickSelectProjectButton(): void {
    apportionmentAppend({
      project: undefined,
      percentage: 100,
      value: expenseValueAsNumber
    });
  }

  async function handleChangeMileage(value: string): Promise<void> {
    const date = getValues('date');

    const updatedValuesWithPolicyByUser = await validateMileagePolicyByUser(
      date,
      value
    );

    if (updatedValuesWithPolicyByUser) {
      return;
    }

    const updatedValuesWithPolicyByProject =
      await validateMileagePolicyByProject(value);

    if (updatedValuesWithPolicyByProject) {
      return;
    }

    const mileagePaidValueAsNumber = Currency.parseToFloat(
      getValues('mileagePaidValue'),
      4
    );

    const mileage = Currency.parseToFloat(value);

    const maskedValue = Currency.format(
      'BRL',
      Currency.roundToTwoDecimals(mileagePaidValueAsNumber * mileage)
    );

    setValue('value', maskedValue);
  }

  async function checkIfDateIsValidByProjectPolicy(
    date: string | undefined
  ): Promise<void> {
    if (date === undefined || expenseParameters?.policyType !== 'PROJETO') {
      return;
    }

    if (Validate.isDate(date, 'yyyy-mm-dd')) {
      const apportionment = getValues('apportionment');

      const filteredApportionment = apportionment
        ? apportionment.filter(item => item.project !== undefined)
        : [];

      if (filteredApportionment.length === 0) {
        clearErrors('date');
        return;
      }

      const policyByProjectQueryPayload: IValidateDateLimitsByProjectPayload = {
        date,
        projects: filteredApportionment.map(item => {
          return {
            id: item.project?.value as string,
            percentage: item.percentage
          };
        }),
        expenseUuid: expenseUuidToUpdate
      };

      const checkPolicyByProject = await getEnsuredValidateDateLimitsByProject(
        policyByProjectQueryPayload
      );

      if (checkPolicyByProject !== null && !checkPolicyByProject.success) {
        if (checkPolicyByProject.daysLimitAgo !== null) {
          setError('date', {
            type: 'validated-by-project',
            message: t('errors.expenseExceedsTheMaximumAmountOfDaysPassed', {
              days: checkPolicyByProject.daysLimitAgo
            })
          });
        }

        if (checkPolicyByProject.daysLimitAhead !== null) {
          setError('date', {
            type: 'validated-by-project',
            message: t('errors.expenseExceedsTheMaximumAmountOfFutureDays', {
              days: checkPolicyByProject.daysLimitAhead
            })
          });
        }

        return;
      }

      clearErrors('date');
    }
  }

  async function validateMileagePolicyByProject(
    mileage: string | undefined
  ): Promise<boolean> {
    if (!mileage || expenseParameters?.mileagePolicyType !== 'PROJETO') {
      return false;
    }

    const apportionmentValue = getValues('apportionment');

    if (apportionmentValue === undefined || apportionmentValue.length === 0) {
      return false;
    }

    const selectedProject = apportionmentValue[0].project?.value;

    if (selectedProject === undefined) {
      return false;
    }

    const data = await getEnsuredValidateMileagePolicyByProject({
      mileage: Currency.parseToFloat(mileage),
      projectId: parseInt(selectedProject)
    });

    if (data !== null) {
      setValue(
        'mileagePaidValue',
        Currency.format('BRL', data.mileagePaidValue, false, 4)
      );
      setValue('value', Currency.format('BRL', data.value));
      handleChangeDisabledKilometerInput(!data.allowChangeAmountPerKm);
      handleChangeRequiredReceiptInManualRoute(
        data.requiredReceiptInManualRoute
      );
      return true;
    }

    return false;
  }

  async function validateMileagePolicyByUser(
    date: string | undefined,
    mileage: string | undefined
  ): Promise<boolean> {
    if (
      !date ||
      !mileage ||
      expenseParameters?.mileagePolicyType !== 'USUARIO'
    ) {
      return false;
    }

    const data = await getEnsuredValidateMileagePolicyByUser({
      mileage: Currency.parseToFloat(mileage),
      date,
      expenseUuid: expenseUuidToUpdate
    });

    if (data !== null) {
      setValue(
        'mileagePaidValue',
        Currency.format('BRL', data.mileagePaidValue, false, 4)
      );
      setValue('value', Currency.format('BRL', data.value));
      setValue('observation', data.observations);
      return true;
    }

    return false;
  }

  function handleChangeDate(value: string | undefined): void {
    checkIfDateIsValidByProjectPolicy(value);

    validateMileagePolicyByUser(value, getValues('mileage'));
  }

  function handleChangeMileagePaidValue(value: string): void {
    const mileage = Currency.parseToFloat(getValues('mileage'));

    const mileagePaidValue = Currency.parseToFloat(value, 4);

    const maskedValue = Currency.format(
      'BRL',
      Currency.roundToTwoDecimals(mileagePaidValue * mileage)
    );

    setValue('value', maskedValue);
  }

  function handleChangePaymentMethod(paymentMethodId: string): void {
    if (!paymentMethodId) {
      setFieldRefundableDisabled(false);
      return;
    }

    const isPaymentMethodRefundable = paymentMethods?.find(
      paymentMethod => paymentMethod.id.toString() === paymentMethodId
    )?.refundable;

    if (isPaymentMethodRefundable === undefined) {
      setFieldRefundableDisabled(false);
      return;
    }

    setValue('refundable', isPaymentMethodRefundable ?? false);
    setFieldRefundableDisabled(true);
  }

  function handleChangePreRegisteredSection(value: string): void {
    if (!value) {
      setMileageFieldReadonly(false);
      return;
    }

    const preRegisteredSection = preRegisteredSections?.find(
      section => String(section.id) === value
    );

    if (!preRegisteredSection) {
      setMileageFieldReadonly(false);
      return;
    }

    setValue(
      'description',
      preRegisteredSection.name + ' - ' + preRegisteredSection.mileage + 'km'
    );

    const preRegisteredSectionMileage = preRegisteredSection.mileage;

    const mileage = parseFloat(preRegisteredSectionMileage);

    setValue('mileage', Currency.format('BRL', mileage));

    const mileagePaidValueAsNumber = Currency.parseToFloat(
      getValues('mileagePaidValue'),
      4
    );

    const maskedValue = Currency.format(
      'BRL',
      Currency.roundToTwoDecimals(mileagePaidValueAsNumber * mileage)
    );

    setValue('value', maskedValue);

    setMileageFieldReadonly(true);
  }

  const paymentMethodsOptionsWithoutVExpensesCards =
    paymentMethodsOptions?.filter(
      paymentMethod => paymentMethod.label !== 'Cartão VExpenses'
    );

  const debouncedHandleChangeDate = useDebouncedCallback(handleChangeDate, 500);

  const deboucedHandleChangeMileage = useDebouncedCallback(
    handleChangeMileage,
    1000
  );

  return {
    paymentMethodsOptions: paymentMethodsOptionsWithoutVExpensesCards,
    fieldRefundableDisabled,
    handleChangePaymentMethod,
    isLoadingExpensePaymentMethods: isLoadingPaymentMethods,
    currenciesOptions,
    isLoadingCurrencies,
    expenseParameters,
    handleClickSelectProjectButton,
    apportionmentFields,
    apportionmentReplace,
    preRegisteredSectionsOptions,
    isLoadingPreRegisteredSections,
    handleChangeMileage: deboucedHandleChangeMileage,
    handleChangeDate: debouncedHandleChangeDate,
    handleChangeMileagePaidValue,
    handleChangePreRegisteredSection,
    mileageFieldReadonly
  };
}
