import { useState } from 'react';

import { toast } from 'ds';

// import { useNavigate } from 'react-router-dom';
import { vexDomain } from 'data/config';
import {
  type ISendAnalysisSuggestionFieldsForm,
  useSendAnalysisSuggestion
} from 'data/modules/analytics';
import { useSendAnalysisSuggestionSchema } from 'data/modules/analytics/useCases/send-analysis-suggestion/schemas';

import { useFormWithSchema } from 'shared/hooks/forms';

import { type IUseSuggestion } from './Suggestion.types';

export function useSuggestion(): IUseSuggestion {
  const [isAnalysisSuggestionSent, setIsAnalysisSuggestionSent] =
    useState(false);

  const { isSendingSuggestion, sendAnalysisSuggestionAsync } =
    useSendAnalysisSuggestion();

  const methods = useFormWithSchema(useSendAnalysisSuggestionSchema(), {
    defaultValues: {
      contact: null
    }
  });

  // const navigate = useNavigate();

  function handleToggleAnalysisSuggestSent(): void {
    methods.reset();
    setIsAnalysisSuggestionSent(prev => !prev);
  }

  function handleBackToStart(): void {
    window.location.href = `${vexDomain}/inicio`;
    // navigate(routesPathPrefix.analytics);
  }

  async function onSubmitAnalysisSuggestion(
    suggestion: ISendAnalysisSuggestionFieldsForm
  ): Promise<void> {
    if (isSendingSuggestion) return;

    try {
      await sendAnalysisSuggestionAsync(suggestion);

      handleToggleAnalysisSuggestSent();
    } catch (error) {
      toast.error((error as Error)?.message);
    }
  }

  return {
    methods,
    handleBackToStart,
    isSendingSuggestion,
    isAnalysisSuggestionSent,
    handleToggleAnalysisSuggestSent,
    handleSendAnalysisSuggestion: methods.handleSubmit(
      onSubmitAnalysisSuggestion
    )
  };
}
