import { type ITypesOfOwners } from 'data/modules/fuel/types/fuel.types';

export const mockTypesOfOwners: ITypesOfOwners[] = [
  {
    id: 2,
    description: 'Empresa'
  },
  {
    id: 1,
    description: 'Colaborador'
  }
];
