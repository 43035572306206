import { apiWeb } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import { type IDefaultResponse } from 'data/modules/global';
import {
  type IPersistencePreRegisteredSection,
  type IPreRegisteredSection
} from 'data/modules/preRegisteredSections/types/preRegisteredSections.types';

import { GetPreRegisteredSectionsMapper } from './mappers';

class PreRegisteredSectionsService {
  async getPreRegisteredSections(): Promise<IPreRegisteredSection[]> {
    const response = await apiWeb.get<
      IDefaultResponse<IPersistencePreRegisteredSection[]>
    >('/routes/pre-registered/list-by-company');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data.map(item => GetPreRegisteredSectionsMapper.toDomain(item));
  }
}

export default new PreRegisteredSectionsService();
