import { DSIcons } from 'ds';
import { useTranslation } from 'react-i18next';

import { type IValueSolicitationTimelineProps } from './ValueSolicitationTimeline.types';

import {
  Container,
  JustifyText,
  StatusCircle,
  StatusCircleContainer,
  StatusContainer,
  StatusLine,
  StatusText,
  TextsContainer
} from './ValueSolicitationTimeline.styles';

export function ValueSolicitationTimeline({
  status,
  managerJustify,
  dateApproval,
  dateRejected,
  approverName
}: IValueSolicitationTimelineProps): JSX.Element {
  const { t } = useTranslation('cards');

  return (
    <Container>
      <StatusContainer>
        <StatusCircleContainer>
          <StatusCircle
            $size={'big'}
            $variant={'blue'}
          >
            <DSIcons.CheckIcon />
          </StatusCircle>
          <StatusLine $variant={'blue'} />
        </StatusCircleContainer>
        <StatusText $hasPaddingAdjustment={true}>{t('requestMade')}</StatusText>
      </StatusContainer>

      <StatusContainer>
        <StatusCircleContainer>
          <StatusCircle
            $size={'small'}
            $variant={'yellow'}
          />
          {status !== 'SENT' && <StatusLine $variant={'yellow'} />}
        </StatusCircleContainer>
        <StatusText>{t('waitingManagerAproval')}</StatusText>
      </StatusContainer>

      {status === 'APPROVED' && (
        <StatusContainer>
          <StatusCircleContainer>
            <StatusCircle
              $size={'small'}
              $variant={'green'}
            />
          </StatusCircleContainer>
          <StatusText>
            {t('requestAprovedIn', { dateApproval, approverName })}
          </StatusText>
        </StatusContainer>
      )}

      {status === 'REJECTED' && (
        <StatusContainer>
          <StatusCircleContainer>
            <StatusCircle
              $size={'small'}
              $variant={'red'}
            />
            <StatusLine $variant={'red'} />
          </StatusCircleContainer>
          <TextsContainer>
            <StatusText>{t('requestRejectedIn', { dateRejected })}</StatusText>
            <JustifyText>
              {t('requestJustify', { justify: managerJustify })}
            </JustifyText>
          </TextsContainer>
        </StatusContainer>
      )}
    </Container>
  );
}
