import { SkeletonLoader } from 'ds/components/SkeletonLoader/SkeletonLoader.component';

import { type ICardInfoCaptionProps } from './CardInfoCaption.types';

import { Container } from './CardInfoCaption.styles';

export function CardInfoCaption({
  variant = 'medium',
  children,
  isLoading = false,
  ...rest
}: ICardInfoCaptionProps): JSX.Element {
  const SkeletonSizes = {
    small: '13px',
    medium: '22px',
    large: '22px'
  };

  return (
    <Container
      $variant={variant}
      {...rest}
    >
      {!isLoading && children}

      {isLoading && (
        <SkeletonLoader
          width='12rem'
          height={SkeletonSizes[variant]}
          max-width='80%'
        />
      )}
    </Container>
  );
}
