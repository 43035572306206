import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 1.6rem;
  padding: ${tokens.spacingXs} ${tokens.spacingXxs};
  border-radius: 16px;
  background-color: ${tokens.neutralColorHighLight};
  margin-bottom: 3.6rem;

  table {
    min-width: 100rem;
  }

  tr {
    height: 6.8rem;
  }

  td {
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      cursor: pointer;
    }

    &:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const TableContainer = styled.div`
  max-width: 100%;
  overflow: auto;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.4rem;
  padding-bottom: 0.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: row wrap;
    justify-content: center;
    align-items: unset;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    justify-content: unset;
  }
`;

export const CardGroupsTableEmpty = styled.div`
  width: 100%;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeSm};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
`;
