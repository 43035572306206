import { Modal, tokens } from 'ds';
import styled from 'styled-components';

export const ModalForm = styled(Modal.Form)`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  flex-grow: 1;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  flex-grow: 1;
  overflow: auto;
  padding-top: ${tokens.spacingXs};
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
  gap: ${tokens.spacingMd};

  button {
    width: 100%;
    white-space: nowrap;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    button {
      width: fit-content;
    }
  }
`;
