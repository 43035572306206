import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import { AuthQueryKeys } from 'data/modules/auth';
import { type IAuthUser } from 'data/modules/auth';
import {
  EmailPreferencesService,
  type HandleFakeChangingEmailPreferencesType,
  type IChangeEmailPreferenceFieldsForm,
  type IChangeEmailPreferences,
  type IUseChangeEmailPreferences,
  SettingsMutationKeys
} from 'data/modules/settings/emailPreferences';

import { useFormWithSchema } from 'shared/hooks/forms';

import { useChangeEmailPreferencesSchema } from './schemas';

export function useChangeEmailPreferences(): IUseChangeEmailPreferences {
  const queryClient = useQueryClient();
  const { user } = queryClient.getQueryData([
    AuthQueryKeys.AUTH_USER
  ]) as IAuthUser;
  const currentReceivesManagementParam =
    user.parameters.receivesManagementSummaryEmail;

  const [isFakeChangingEmailPreferences, setIsFakeChangingEmailPreferences] =
    useState(false);

  const {
    currentLangKey,
    lang: {
      settings: { emailPreferences }
    }
  } = useLangContext();
  const preferenceChangeSuccessful =
    emailPreferences.preference_change_successful[currentLangKey];

  const changeEmailPreferences = useMutationCache({
    mutationKey: [SettingsMutationKeys.CHANGE_EMAIL_PREFERENCES],
    mutationFn: async (payload: IChangeEmailPreferences) =>
      await EmailPreferencesService.changeEmailPreferencesParam(payload),
    onSuccess: data => {
      toast.success(preferenceChangeSuccessful);

      // Modifica somente o parâmetro de preferências de email
      queryClient.setQueryData([AuthQueryKeys.AUTH_USER], authUser =>
        authUser
          ? {
              ...authUser,
              user: {
                ...authUser.user,
                parameters: {
                  ...authUser.user.parameters,
                  receivesManagementSummaryEmail:
                    data.receivesManagementSummaryEmail
                }
              }
            }
          : authUser
      ) as IAuthUser;
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  const { control, handleSubmit } = useFormWithSchema(
    useChangeEmailPreferencesSchema(),
    {
      defaultValues: {
        receivesManagementSummaryEmail: currentReceivesManagementParam
      }
    }
  );

  // Executa um loading falso quando o usuário salva sem alterar a preferência
  function handleFakeChangingEmailPreferences(): HandleFakeChangingEmailPreferencesType {
    setIsFakeChangingEmailPreferences(true);

    const isFakeChangingTimeOut = setTimeout(() => {
      setIsFakeChangingEmailPreferences(false);
      toast.success(preferenceChangeSuccessful);
    }, 1000);

    return () => {
      clearTimeout(isFakeChangingTimeOut);
    };
  }

  async function onSubmit(
    data: IChangeEmailPreferenceFieldsForm
  ): Promise<void> {
    if (
      data.receivesManagementSummaryEmail === currentReceivesManagementParam
    ) {
      handleFakeChangingEmailPreferences();

      return;
    }

    changeEmailPreferences.mutate(data);
  }

  return {
    changeEmailPreferencesControl: control,
    handleSubmit: handleSubmit(onSubmit),
    isChangingEmailPreferences:
      changeEmailPreferences.isLoading || isFakeChangingEmailPreferences
  };
}
