import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-top: 8rem;
`;

export const Advantage = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
