import { type ChangeEventHandler } from 'react';

import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue,
  type RefCallBack
} from 'react-hook-form';

import { BaseIcon } from 'presentation/components/base/Icon';

import { Mask } from 'shared/utils/format';

import { type IDsTextInputProps } from './DsTextInput.types';

import {
  CleanButton,
  Container,
  ErrorMessage,
  InputContainer,
  Label
} from './DsTextInput.styles';

export function DsTextInput<T extends FieldValues>({
  defaultValue = '',
  label,
  name,
  disabled = false,
  hint,
  error,
  underlined,
  inputClass = '',
  value,
  control,
  inputContainerClass = '',
  mask,
  onClear,
  onChange,
  ...rest
}: IDsTextInputProps<T>): JSX.Element {
  const inputToRender = (
    value: string,
    onChange: ChangeEventHandler<HTMLInputElement> | undefined,
    name?: string,
    ref?: RefCallBack
  ): JSX.Element => (
    <Container
      $hasValue={value ?? defaultValue}
      $disabled={disabled}
      $error={error}
      $underlined={underlined}
      className={inputClass}
    >
      {(value ?? defaultValue) && (
        <Label
          htmlFor={name}
          disabled={disabled}
          error={error}
        >
          {label}
        </Label>
      )}
      <InputContainer
        disabled={disabled}
        error={error}
        className={inputContainerClass}
      >
        <input
          {...rest}
          type='text'
          name={name}
          {...(ref && { ref })}
          value={value ?? defaultValue}
          onChange={onChange}
          disabled={disabled}
          placeholder={label}
        />

        {!disabled && (
          <CleanButton
            onClick={onClear}
            $hasValue={value}
            disabled={disabled}
            $error={error}
            type='button'
          >
            <BaseIcon
              name='close'
              type='line'
              color='alto'
              size={1.5}
            />
          </CleanButton>
        )}
      </InputContainer>
    </Container>
  );

  if (!control) {
    return inputToRender(value as string, onChange, name);
  }

  return (
    <div>
      <Controller
        name={name as Path<T>}
        control={control}
        defaultValue={defaultValue as PathValue<T, Path<T>>}
        render={({ field: { name, onChange, value, ref } }) =>
          inputToRender(
            value,
            e => {
              onChange(
                Mask.apply(mask, e.target.value) as PathValue<T, Path<T>>
              );
            },
            name,
            ref
          )
        }
      />

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
}
