import { useQueryCache } from 'data/cache';
import {
  CurrenciesQueryKeys,
  CurrenciesService,
  type IUseGetCurrencies
} from 'data/modules/currencies';

export function useGetCurrencies(): IUseGetCurrencies {
  const { data: currencies, isLoading: isLoadingCurrencies } = useQueryCache({
    queryKey: [CurrenciesQueryKeys.GET_CURRENCIES],
    queryFn: async () => await CurrenciesService.getCurrencies()
  });

  return {
    currencies,
    isLoadingCurrencies
  };
}
