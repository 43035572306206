import { TextButton } from 'ds';
import { DSIcons } from 'ds/icons';

import { Icons } from 'shared/constants/global';

import { type IConversionNoticeProps } from './ConversionNotice.types';

import { Container } from './ConversionNotice.styles';

export function ConversionNotice({
  children,
  actionText,
  onActionClick,
  ...props
}: IConversionNoticeProps): JSX.Element {
  return (
    <Container
      {...props}
      onClick={onActionClick}
    >
      <Icons.CashBanknoteWarningIcon />
      <p>{children}</p>
      <TextButton size='small'>
        {actionText}
        <DSIcons.ArrowRightIcon />
      </TextButton>
    </Container>
  );
}
