import {
  type FetchNextPageOptions,
  type InfiniteQueryObserverResult,
  type UseMutateAsyncFunction
} from '@tanstack/react-query';

export interface IPersistenceAnalysis {
  average_by_user: number | null;
  most_used_project: string | null;
  most_used_cost_center: string | null;
  average_approval_time: number | null;
  most_used_expense_type: string | null;
  most_used_payment_form: string | null;
}

export interface IAnalysis {
  averageByUser: number | null;
  mostUsedProject: string | null;
  mostUsedCostCenter: string | null;
  mostUsedExpenseType: string | null;
  mostUsedPaymentForm: string | null;
  averageApprovalTime: number | null;
}

export interface IUseGetAnalysis {
  analysis: IAnalysis | undefined;
  isLoadingAnalysis: boolean;
  isFetchingAnalysis: boolean;
}

export interface IPersistenceSendAnalysisSuggestionPayload {
  type: string;
  objective: string;
  how_see: string;
  frequency: string;
  contact: string | null;
}

export interface ISendAnalysisSuggestionPayload {
  type: string;
  objective: string;
  howSee: string;
  frequency: string;
  contact: string | null;
}

export interface IUseSendAnalysisSuggestion {
  isSendingSuggestion: boolean;
  sendAnalysisSuggestionAsync: UseMutateAsyncFunction<
    null,
    unknown,
    ISendAnalysisSuggestionFieldsForm,
    unknown
  >;
}

export interface ISendAnalysisSuggestionFieldsForm
  extends ISendAnalysisSuggestionPayload {}

export interface IAnalysisFilterUser {
  id: number;
  name: string;
  active: boolean;
}

export interface IAnalysisFilterExpenseType
  extends Omit<IAnalysisFilterUser, 'active'> {}

export interface IAnalysisFilterPaymentForm
  extends IAnalysisFilterExpenseType {}

export interface IAnalysisFilterCostCenter extends IAnalysisFilterExpenseType {}

export interface IAnalysisFilterProject extends IAnalysisFilterExpenseType {}

export interface IPersistenceAnalysisUserFilters {
  users: IAnalysisFilterUser[];
  projects: IAnalysisFilterProject[];
  cost_centers: IAnalysisFilterCostCenter[];
  expense_types: IAnalysisFilterExpenseType[];
  payment_forms: IAnalysisFilterPaymentForm[];
}

export interface IAnalysisUserFilters {
  users: IAnalysisFilterUser[];
  projects: IAnalysisFilterProject[];
  costCenters: IAnalysisFilterCostCenter[];
  expenseTypes: IAnalysisFilterExpenseType[];
  paymentForms: IAnalysisFilterPaymentForm[];
}

export interface IUseGetAnalysisUserFilters {
  isLoadingAnalysisUserFilters: boolean;
  isFetchingAnalysisUserFilters: boolean;
  analysisUserFilters: IAnalysisUserFilters | undefined;
}

export enum AnalysisDefaultFilter {
  NONE = 0,
  ALL = 'ALL',
  ALL_WITH = 'ALL_WITH_'
}

export enum AnalysisDateToConsiderFilter {
  EXPENSE_CREATED_AT = 'expense_created_at',
  EXPENSE_DATE = 'expense_date',
  REPORT_CREATED_AT = 'report_created_at',
  SEND_TO_APPROVE_DATE = 'send_to_approve_date',
  APPROVE_DATE = 'approve_date',
  PAYMENT_DATE = 'payment_date'
}

export enum AnalysisUserFilter {
  ACTIVE = 'ATIVOS',
  INACTIVE = 'DESATIVADOS'
}

export enum AnalysisOrderByFilter {
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
  VALUE_ASC = 'value_asc',
  VALUE_DESC = 'value_desc'
}

export enum AnalysisReimbursableNonReimbursableFilter {
  ONLY_NON_REIMBURSABLE = '0',
  ONLY_REIMBURSABLE = '1',
  REIMBURSABLE_NON_REIMBURSABLE = '2'
}

export type AnalysisFastDateFilterType =
  | 'TODAY'
  | 'LAST_WEEK'
  | 'LAST_MONTH'
  | 'THIS_MONTH';

export interface IAnalysisUserFilterFieldsForm {
  initialDate?: string;
  endDate?: string;
  users?: string[];
  dateToConsider?: string;
  orderBy?: string;
  costCenters?: string[];
  expensesStatus?: string[];
  expensesTypes?: string[];
  reimbursableNonReimbursable?: string;
  paymentForms?: string[];
  projects?: string[];
  reportCodes?: string[];
  saveFilterPreferences?: boolean;
}

export interface IAnalysisUserReportId {
  id: number;
}

export interface IUseGetAnalysisUserReportsId {
  isLoadingAnalysisUserReports: boolean;
  isFetchingAnalysisUserReports: boolean;
  analysisUserReports: IAnalysisUserReportId[];
}

export interface IAnalysisPagination {
  to?: number;
  from?: number;
  total?: number;
  lastPage?: number;
  currentPage?: number;
}

export interface IPersistenceAnalysisUserPayload {
  date_to_consider?: string;
  initial_date?: string;
  final_date?: string;
  payment_type?: string;
  users?: string[];
  status?: string[];
  expense_types?: string[];
  payment_form?: string[];
  projects?: string[];
  cost_centers?: string[];
  report_codes?: string[];
}

export interface IAnalysisUserPayload {
  dateToConsider?: string;
  initialDate?: string;
  finalDate?: string;
  paymentType?: string;
  users?: string[];
  status?: string[];
  expenseTypes?: string[];
  paymentForm?: string[];
  projects?: string[];
  costCenters?: string[];
  reportCodes?: string[];
}

export interface IPersistenceAnalysisUserPaginatedPayload
  extends IPersistenceAnalysisUserPayload {
  per_page: number;
  page: number;
}

export interface IAnalysisUserPaginatedPayload extends IAnalysisUserPayload {
  perPage: number;
  page: number;
}

export interface IPersistenceAnalysisResumePayload
  extends IPersistenceAnalysisUserPayload {}

export interface IAnalysisResumePayload extends IAnalysisUserPayload {}

export interface IPersistenceAnalysisResume {
  sum_expenses: string | null;
  sum_refundable: string | null;
  sum_no_refundable: string | null;
  user_with_expense: number | null;
  average_per_expense: string | null;
  total_users: number;
  total_reports: number;
  total_expenses: number;
  total_refundable: number;
  total_no_refundable: number;
  has_pending_conversion: boolean;
}

export interface IAnalysisResume {
  totalExpenses: number;
  sumExpenses: number | null;
  sumRefundable: number | null;
  sumNoRefundable: number | null;
  totalRefundable: number;
  totalNoRefundable: number;
  userWithExpense: number | null;
  totalUsers: number;
  averagePerExpense: number | null;
  totalReports: number;
  hasPendingConversion: boolean;
}

export interface IUseGetAnalyticsResumeParams extends IAnalysisResumePayload {}

export interface IUseGetAnalysisResume {
  resume?: IAnalysisResume;
  isLoadingAnalysisResume: boolean;
}

export interface IAnalysisCard {
  title: string;
  value: string;
  caption?: string;
  tooltip?: string;
  showLeftIcon?: boolean;
}

export type AnalysisExchangeKeyType =
  | 'hasPendingConversion'
  | 'convertedSumWithCard'
  | 'convertedSum'
  | null;

export interface IAnalysisResumeCard extends IAnalysisCard {}

export interface IPersistenceAnalysisUserHeader {
  name: string;
  total_reports: number;
  total_expenses: number;
  average_per_expense: string;
  sum_refundable: string;
  sum_no_refundable: string;
  total: string;
  department_position: string | null;
}

export interface IAnalysisUserHeader {
  name: string;
  totalReports: number;
  totalExpenses: number;
  averagePerExpense: number;
  sumRefundable: number;
  sumNoRefundable: number;
  total: number;
  departmentPosition: string | null;
}

export interface IAnalysisUserHeaderPagination extends IAnalysisPagination {}

export interface IPersistenceAnalysisUserHeaderPayload
  extends IPersistenceAnalysisUserPaginatedPayload {}

export interface IAnalysisUserHeaderPayload
  extends IAnalysisUserPaginatedPayload {}

export interface IUseGetAnalysisUserHeaderParams
  extends IAnalysisUserHeaderPayload {}

export interface IUseGetAnalysisUserHeader {
  analysisUserHeader: IAnalysisUserHeader[];
  pagination?: IAnalysisUserHeaderPagination;
  isLoadingAnalysisUserHeader: boolean;
}

export interface IPersistenceAnalysisUserResume {
  user_id: number;
  name: string;
  total: string;
  sum_refundable: string | null;
  sum_no_refundable: string | null;
  total_expenses: number;
  average_per_expense: string;
  has_pending_conversion: boolean;
  department_position: string | null;
}

export interface IAnalysisUserResume {
  userId: number;
  name: string;
  total: number;
  totalExpenses: number;
  sumRefundable: number;
  sumNoRefundable: number;
  averagePerExpense: number;
  departmentPosition: string;
  hasPendingConversion: boolean;
}

export interface IPersistenceAnalysisUserExpense {
  expense_id: number;
  expense_date: string;
  creator_name: string;
  expense_title: string;
  expense_type: string;
  has_pending_conversion: boolean | null;
  expense_costs_center: string | null;
  expense_project: string | null;
  expense_payment_method: string | null;
  refundable_payment_method: 0 | 1 | null;
  original_currency: string | null;
  expense_value: string;
  converted_currency: string | null;
  converted_value: string | null;
  observations_count: number;
  expense_image: string | null;
  expense_image_type: 'IMG' | 'PDF' | null;
}

export interface IAnalysisUserExpense {
  expenseId: number;
  expenseDate: string;
  creatorName: string;
  expenseTitle: string;
  expenseType: string;
  hasPendingConversion: boolean | null;
  expenseCostsCenter: string | null;
  expenseProject: string | null;
  expensePaymentMethod: string | null;
  refundablePaymentMethod: boolean | null;
  originalCurrency: string | null;
  expenseValue: number;
  convertedCurrency: string | null;
  convertedValue: number | null;
  observationsCount: number;
  expenseImage: string | null;
  expenseImageType: 'IMG' | 'PDF' | null;
}

export interface IPersistenceAnalysisUserReport {
  report_id: number | null;
  report_uuid: string | null;
  report_name: string | null;
  report_status: string | null;
  report_observation: string | null;
  approval_flow_name: string | null;
  report_sum_refundable: string | null;
  report_sum_refundable_converted: string | null;
  report_sum_no_refundable: string | null;
  report_sum_non_refundable_converted: string | null;
  report_total: string | null;
  report_total_converted: string | null;
  all_expenses_returned: boolean;
  original_currency: string | null;
  converted_currency: string | null;
  expenses?: IPersistenceAnalysisUserExpense[];
}

export interface IAnalysisUserReport {
  reportId: number | null;
  reportUuid: string | null;
  reportName: string | null;
  reportStatus: string | null;
  reportObservation: string | null;
  approvalFlowName: string | null;
  reportSumRefundable: number | null;
  reportSumRefundableConverted: number | null;
  reportSumNoRefundable: number | null;
  reportSumNoRefundableConverted: number | null;
  reportTotal: number | null;
  reportTotalConverted: number | null;
  allExpensesReturned: boolean;
  originalCurrency: string | null;
  convertedCurrency: string | null;
  expenses?: IAnalysisUserExpense[];
}

export interface IPersistenceAnalysisUserList
  extends IPersistenceAnalysisUserResume {
  reports?: Record<string, IPersistenceAnalysisUserReport>;
}

export interface IAnalysisUserList extends IAnalysisUserResume {
  reports?: Record<string, IAnalysisUserReport>;
}

export interface IPersistenceAnalysisUserListPayload
  extends IPersistenceAnalysisUserPaginatedPayload {}

export interface IAnalysisUserListPayload
  extends IAnalysisUserPaginatedPayload {}

export interface IAnalysisUserListPagination extends IAnalysisPagination {}

export interface IUseGetAnalysisUserListParams
  extends Omit<IAnalysisUserListPayload, 'page'> {}

export interface IUseGetAnalysisUserList {
  analysisUserList: IAnalysisUserList[];
  pagination?: IAnalysisUserListPagination;
  isLoadingAnalysisUserList: boolean;
  isFetchingAnalysisUserList: boolean;
  isGetAnalysisUserListError: boolean;
  hasNextPageAnalysisUserList: boolean;
  isFetchingAnalysisUserListNextPage: boolean;
  fetchAnalysisUserListNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<unknown, unknown>>;
}
