import {
  DefaultButton,
  DSIcons,
  Modal,
  MultiSelect,
  SecondaryButton,
  Select,
  Stepper,
  TextInput
} from 'ds';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useModalContext } from 'data/contexts';

import { AlertModal } from 'presentation/components/global/AlertModal';
import { type IAlertModalProps } from 'presentation/components/global/AlertModal/AlertModal.types';
import { Loader } from 'presentation/components/global/Loader';

import { routesPathPrefix } from 'shared/constants/global';

import { InfiniteScrollSelectMembers } from '../InfiniteScrollSelectMembers';
import { UpdateVehicleSelectMembers } from '../UpdateVehicleSelectMembers';

import { useCreateVehicleModal } from './useCreateVehicleModal';

import { type ICreateVehicleModalProps } from './CreateVehicleModal.types';

import {
  ModalContent,
  ModalFooter,
  RowInputs,
  StyledContainer,
  StyledModalContainer
} from './CreateVehicleModal.styles';

export function CreateVehicleModal({
  vehicleIdToUpdate
}: ICreateVehicleModalProps): JSX.Element {
  const { handleCloseModal } = useModalContext();

  const { t } = useTranslation(['fuel', 'global']);

  const navigate = useNavigate();

  const {
    formMethods,
    handleSubmitForm,
    step,
    isShowingModalLoading,
    submitButtonDisabled,
    typesOfFuelsOptions,
    typesOfOwnersOptions,
    typesOfVehiclesOptions,
    isLoadingTypesOfFuels,
    userAction,
    isLoadingTypesOfVehicles,
    vehicleModelFromFormValue,
    vehiclePlateFromFormValue,
    showConfirmEmptyModal,
    showConfirmModal,
    setShowConfirmEmptyModal,
    setShowConfirmModal,
    handleClickOnMemberOnCreate,
    handleClickSelectAllMembersOnCreate,
    handleClickOnMemberOnUpdate,
    handleClickSelectAllMembersOnUpdate,
    handleSubmitCreateOrUpdateVehicle,
    linkUnlinkMembersStepStateOnUpdate,
    linkUnlinkMembersStepStateOnCreate,
    handleGoBackButton
  } = useCreateVehicleModal({ vehicleIdToUpdate });

  const confirmModalData: Pick<IAlertModalProps, 'children' | 'title'> = {
    children: (
      <Trans
        i18nKey='fuel:modal.confirmLinkMemberToVehicleDescription'
        values={{
          plate: vehiclePlateFromFormValue,
          model: vehicleModelFromFormValue
        }}
        components={{ bold: <strong /> }}
      />
    ),
    title: t('fuel:modal.confirmLinkMemberToVehicleTitle')
  };

  const confirmEmptyModalData: Pick<IAlertModalProps, 'children' | 'title'> = {
    children:
      userAction === 'update' ? (
        <Trans
          i18nKey='fuel:modal.confirmLinkMemberToVehicleDescriptionEmpty'
          values={{
            model: vehicleModelFromFormValue
          }}
          components={{ bold: <strong /> }}
        />
      ) : (
        t('fuel:modal.confirmLinkMemberToVehicleDescriptionEmptyWhenCreate')
      ),
    title: t('fuel:modal.confirmLinkMemberToVehicleTitleEmpty')
  };

  return (
    <>
      <Loader isLoading={isShowingModalLoading} />

      <StyledModalContainer
        variant='medium'
        onClose={() => {
          navigate(`${routesPathPrefix.settings}/combustivel/veiculos`);
          handleCloseModal();
        }}
      >
        <StyledContainer>
          <Modal.Header>
            <Modal.Title>
              {userAction === 'create'
                ? t('newVehicle')
                : t('modal.editVehicle')}
            </Modal.Title>

            <Modal.IconClose
              onClick={() => {
                navigate(`${routesPathPrefix.settings}/combustivel/veiculos`);
                handleCloseModal();
              }}
            />
          </Modal.Header>

          <Modal.Form
            methods={formMethods}
            handleSubmit={handleSubmitForm}
          >
            <ModalContent>
              <Stepper.Container>
                <Stepper.Item
                  active={step === 'defaultFields'}
                  checked={step !== 'defaultFields'}
                  allowClick={step !== 'defaultFields'}
                  onClick={() => {
                    handleGoBackButton();
                  }}
                  label={
                    userAction === 'create'
                      ? t('fuel:modal.addVehicle')
                      : t('fuel:modal.editVehicle')
                  }
                  number={1}
                />
                <Stepper.Item
                  active={step === 'linkMembers'}
                  allowClick={false}
                  label={t('fuel:modal.linkMembers')}
                  number={2}
                />
              </Stepper.Container>

              {step === 'defaultFields' && (
                <>
                  <TextInput.Controlled
                    label={t('vehicleModel')}
                    placeholder={t('vehicleModel')}
                    small
                    name='model'
                  />

                  <RowInputs>
                    <TextInput.Controlled
                      label={t('plate')}
                      placeholder={t('plate')}
                      small
                      name='plate'
                      inputContainerAttributes={{
                        className: 'modal-textinput'
                      }}
                    />

                    <Select.Controlled
                      hasArrowDownIndicator
                      usePortal
                      isLoading={isLoadingTypesOfVehicles}
                      options={typesOfVehiclesOptions}
                      portalContainerClassName='react-select-portal-with-menu-limited-height'
                      label={t('typeOfVehicle')}
                      placeholder={t('typeOfVehicle')}
                      small
                      name='type'
                    />
                  </RowInputs>

                  <RowInputs>
                    <MultiSelect.Controlled
                      hasArrowDownIndicator
                      usePortal
                      isLoading={isLoadingTypesOfFuels}
                      options={typesOfFuelsOptions}
                      noOptionsMessage={t('global:noMoreOptions')}
                      label={t('fuel')}
                      placeholder={t('fuel')}
                      small
                      name='fuel'
                    />

                    <Select.Controlled
                      hasArrowDownIndicator
                      usePortal
                      options={typesOfOwnersOptions}
                      noOptionsMessage={t('global:noMoreOptions')}
                      label={t('proprietary')}
                      placeholder={t('proprietary')}
                      portalContainerClassName='react-select-portal-with-menu-limited-height'
                      small
                      name='owner'
                    />
                  </RowInputs>
                </>
              )}

              {step === 'linkMembers' && userAction === 'create' && (
                <InfiniteScrollSelectMembers
                  onClickOnMember={handleClickOnMemberOnCreate}
                  linkUnlinkMembersStepState={
                    linkUnlinkMembersStepStateOnCreate
                  }
                  onClickSelectAllMembers={handleClickSelectAllMembersOnCreate}
                />
              )}

              {step === 'linkMembers' && userAction === 'update' && (
                <UpdateVehicleSelectMembers
                  onClickOnMember={handleClickOnMemberOnUpdate}
                  linkUnlinkMembersStepState={
                    linkUnlinkMembersStepStateOnUpdate
                  }
                  vehicleId={vehicleIdToUpdate as number}
                  onClickSelectAllMembers={handleClickSelectAllMembersOnUpdate}
                />
              )}
            </ModalContent>

            <ModalFooter>
              <SecondaryButton
                size='small'
                type='button'
                onClick={() => {
                  handleGoBackButton();
                }}
              >
                {step === 'defaultFields'
                  ? t('global:cancel')
                  : t('global:back')}
              </SecondaryButton>

              <DefaultButton
                size='small'
                type='submit'
                disabled={submitButtonDisabled}
              >
                {step === 'defaultFields'
                  ? t('global:continue')
                  : t('global:save')}
              </DefaultButton>
            </ModalFooter>
          </Modal.Form>
        </StyledContainer>
      </StyledModalContainer>

      {showConfirmModal && (
        <AlertModal
          {...confirmModalData}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          confirmButtonProps={{
            onConfirm: () => {
              handleSubmitCreateOrUpdateVehicle();
            }
          }}
          icon={<DSIcons.WarningConfirmationIcon />}
        />
      )}

      {showConfirmEmptyModal && (
        <AlertModal
          {...confirmEmptyModalData}
          onClose={() => {
            setShowConfirmEmptyModal(false);
          }}
          confirmButtonProps={{
            onConfirm: () => {
              handleSubmitCreateOrUpdateVehicle();
            }
          }}
          icon={<DSIcons.WarningConfirmationIcon />}
        />
      )}
    </>
  );
}
