import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.spacingXs};
  padding: ${tokens.spacingMd};
  border-radius: ${tokens.borderRadiusMd};
  background: ${tokens.neutralColorHighLight};
  border: 1px solid ${tokens.neutralColorHighDark};
`;

export const AnalysisSummaryTitle = styled.h3`
  flex: 1 0 0;
  font-style: normal;
  line-height: 2.2rem;
  font-size: ${tokens.fontSizeSm};
  color: ${tokens.neutralColorLowDark};
  font-weight: ${tokens.fontWeightBold};
  font-family: ${tokens.fontFamiliesPoppins};
`;

export const CardsInfoContent = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: ${tokens.spacingMd};

  > div {
    flex: 1 0 0;
  }

  h1,
  p {
    white-space: nowrap;
  }

  :not(.analysis-summary-card-tooltip) > span {
    font-size: 1.8rem;
  }
`;

export const IconWrapper = styled.div`
  svg {
    width: 2.4rem;
    height: 2.4rem;
    color: ${tokens.highlightColorPure};
  }
`;

export const SkeletonLoaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${tokens.spacingMd};

  span {
    flex: 1 0 20rem;
    height: 11.6rem;
    border-radius: ${tokens.borderRadiusMd};
  }
`;
