import {
  type ICreateFuelExpensePayload,
  type IPersistenceCreateFuelExpensePayload
} from 'data/modules/expenses';

import { CustomString } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

class CreateFuelExpenseMapper {
  toPersistence(
    payload: ICreateFuelExpensePayload
  ): IPersistenceCreateFuelExpensePayload {
    return {
      date: payload.date,
      title: payload.description,
      is_reimbursable: payload.refundable
        ? `${payload.refundable}`
        : payload.refundable,
      receipt: payload.receipt,
      report_uuid: payload.report,
      observations: payload.observation,
      attachments:
        payload.observationAttachments &&
        payload.observationAttachments.length > 0
          ? payload.observationAttachments.map(item => {
              return item instanceof File ? item : item.url;
            })
          : undefined,
      apportionments:
        payload.apportionment?.map(item => {
          return {
            project_id: parseInt(item.project),
            percentage: item.percentage,
            amount: item.value
          };
        }) ?? [],
      amount: Currency.parseToFloat(payload.value),
      currency_id: parseInt(payload.currency),
      cost_center_id: payload.costsCenter,
      payment_method_id: payload.paymentMethod,
      fuel_id: payload.fuelType,
      odometer_kilometrage: payload.mileage
        ? CustomString.removeDotsAndParseInt(payload.mileage)
        : undefined,
      odometer_image: payload.odometerImage,
      amount_per_litre: Currency.parseToFloat(payload.pricePerLiter),
      volume_in_litres: Currency.parseToFloat(payload.quantityOfLiters),
      vehicle_id: payload.licensePlate,
      vehicle_type_id: payload.vehicleType
    };
  }
}

export default new CreateFuelExpenseMapper();
