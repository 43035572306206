import { useMemo } from 'react';

import { useModalContext } from 'data/contexts';
import {
  type IUseGetTeamMembersItemsParams,
  useGetTeamMembersItems
} from 'data/modules/teamMembers';

import { type ISelectOption } from 'presentation/components/base/Select/Select.types';

import { type IUseAttachTeamMembersToBusinessCardModal } from './AttachTeamMembersToBusinessCardModal.types';

export function useAttachTeamMembersToBusinessCardModal(): IUseAttachTeamMembersToBusinessCardModal {
  const { visibleModal } = useModalContext();

  const payload = {
    page: 1,
    perPage: 500,
    enabled: visibleModal === 'teamMember'
  } as IUseGetTeamMembersItemsParams;

  const { teamMembers, isLoadingTeamMembers } = useGetTeamMembersItems(payload);

  const teamMembersOptions: ISelectOption[] = useMemo(() => {
    return teamMembers?.map(el => ({
      value: el.id.toString(),
      label: `${el.name}`
    }));
  }, [teamMembers]);

  return {
    teamMembersOptions,
    isLoadingTeamMembers
  };
}
