import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;
  overflow: auto;

  table {
    min-width: 126rem;

    td {
      overflow: hidden;
      height: 6.8rem;
    }

    th {
      height: 6.8rem;
    }
  }
`;
