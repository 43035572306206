import { tokens } from 'ds';
import styled from 'styled-components';

import { Loader as SkeletonLoader } from 'ds/components/SkeletonLoader/SkeletonLoader.styles';

export const Container = styled.div`
  display: grid;
  gap: ${tokens.spacingMd};

  .react-multiSelect-custom__control {
    max-width: 43rem;
  }

  @media screen and (max-width: 800px) {
    .react-multiSelect-custom__control {
      max-width: 80vw;
    }
  }
`;

export const FormSection = styled.div`
  display: grid;
  width: 100%;
  align-content: start;
  gap: ${tokens.spacingXs};

  ${SkeletonLoader} {
    min-height: 4.1rem;
    width: 100%;
  }
`;

export const TwoColumnFormSection = styled.div`
  display: grid;
  gap: ${tokens.spacingLg};
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    gap: ${tokens.spacingXs};
  }
`;

export const FormSectionTitle = styled.h3`
  font-style: normal;
  line-height: 2.4rem;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightSemibold};
`;

export const FieldWrapper = styled.div`
  display: grid;
  gap: ${tokens.spacingNano};
`;

export const TwoFieldRow = styled.div`
  display: grid;
  gap: ${tokens.spacingLg};
  grid-template-columns: 1fr 1fr;

  div {
    min-width: unset;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: ${tokens.spacingXs};
  }
`;

export const FieldNotice = styled.span`
  font-style: normal;
  line-height: normal;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
`;
