import {
  Avatar,
  BadgeStatus,
  Checkbox,
  DangerButton,
  DefaultButton,
  DefaultTable,
  DSIcons,
  Pagination,
  tokens,
  Tooltip
} from 'ds';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  useClassicModeContext,
  useFlagsContext,
  useLangContext,
  useModalContext
} from 'data/contexts';
import { type ExpenseStatusType } from 'data/modules/expenses';

import { AlertModal } from 'presentation/components/global/AlertModal';
import { ClassicModeAlert } from 'presentation/components/global/ClassicModeAlert';
import { Loader } from 'presentation/components/global/Loader';
import {
  AddExpensesToReportModal,
  CreateFuelExpenseModal,
  CreateManualExpenseModal,
  CreateManualRouteExpenseModal,
  CreateRouteByMapModal,
  SelectExpenseToAddModal,
  ViewExpensesModal
} from 'presentation/pages/expenses/ExpensesList/components/Modal';
import { type IBadgeStatus } from 'presentation/pages/expenses/ExpensesList/components/Modal/ViewExpensesModal/ViewExpensesModal/ViewExpensesModal.types';
import { DefaultLayout } from 'presentation/templates';

import blueAddFolder from 'shared/assets/images/expenses/table/folder_blue.png';
import { Icons } from 'shared/constants/global';
import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

import { TableFilters } from './components/TableFilters';
import { useReportsList } from './useReportsList';

import {
  ActionsContainer,
  AvatarContainer,
  ButtonsContainer,
  Container,
  CustomTdContainer,
  TableFiltersContainer,
  TableNoCententContainer,
  TableNoCententDescription,
  TableNoCententImage,
  TableNoCententTitle,
  TableOverflowContainer,
  TablePaginationContainer
} from './ReportsList.styles';

export function ReportsList(): JSX.Element {
  const { handleOpenModal, visibleModal } = useModalContext();
  const { lang, currentLangKey } = useLangContext();

  const { t } = useTranslation('expenses');

  const { t: reportsTranslation } = useTranslation('reports');
  const cardGroupsFlag = useFlagsContext(state => state.cardGroupsFlag);

  const { id } = useParams();

  const {
    expenseIndex,
    setExpenseIndex,
    filters,
    handleChangeFilters,
    paginatedExpenses,
    isLoadingPaginatedExpenses,
    checked,
    handleChangeCheckbox,
    canAttachExpense,
    canDeleteExpense,
    showAlert,
    expenseActionModalActive,
    setExpenseActionModalActive,
    handleCancelActionExpense,
    handleConfirmActionExpense,
    expensesActionModalData,
    numberOfExpensesByStatus,
    setChecked,
    handleCheckAllCheckbox,
    isAllCheckboxChecked,
    selectedExpenses,
    showAlertToGoToClassicPage,
    handleClickGoToClassicPage,
    handleUpdateExpense,
    handleCreateExpense,
    handleDeleteExpensesFromModal,
    isDeletingExpenses,
    checkedExpensesHasSameCoin,
    expenseUuidToUpdate,
    setExpenseUuidToUpdate
  } = useReportsList();

  const statusBadge: Record<ExpenseStatusType, IBadgeStatus> = {
    APROVADO: {
      status: 'success',
      message: lang.expenses.status.approved[currentLangKey]
    },
    ENVIADO: {
      message: lang.expenses.status.sent[currentLangKey]
    },
    PAGO: {
      status: 'general',
      message: lang.expenses.status.paid[currentLangKey]
    },
    ABERTO: {
      status: 'warning',
      message: lang.expenses.status.open[currentLangKey]
    },
    REPROVADO: {
      status: 'error',
      message: lang.expenses.status.rejected[currentLangKey]
    },
    AVULSA: {
      status: 'general',
      message: lang.expenses.status.unreported[currentLangKey]
    },
    REABERTO: {
      status: 'warning',
      message: lang.expenses.status.open[currentLangKey]
    }
  };

  const isExpensesClassicMode = useClassicModeContext(
    state => state.isExpensesClassicMode
  );

  document.title = `vexpenses | ${reportsTranslation('pageName')}`;

  const isValidExpenseIndex =
    expenseIndex !== -1 &&
    !!paginatedExpenses?.data &&
    !isLoadingPaginatedExpenses &&
    expenseIndex < paginatedExpenses.data.length;

  const selectedExpenseUuidOrId = isValidExpenseIndex
    ? paginatedExpenses.data[expenseIndex].uuid
    : String(id);

  return (
    <>
      <DefaultLayout
        pageNameAndBreadcrumbs={{
          pageName: reportsTranslation('pageName'),
          isBackButtonVisible: false,
          isBreadcrumbsVisible: false,
          isClassicMode: isExpensesClassicMode,
          isTourButtonVisible: true
        }}
      >
        <Container>
          {showAlertToGoToClassicPage && !cardGroupsFlag && (
            <ClassicModeAlert
              isClassicMode={false}
              pageName={reportsTranslation('pageName')}
              onActionClick={handleClickGoToClassicPage}
            />
          )}

          <ButtonsContainer>
            <ActionsContainer className='action-buttons-container'>
              <Tooltip
                trigger='hover'
                placement='bottom'
                message={
                  showAlert
                    ? lang.expenses.table.filters.alert_message[currentLangKey]
                    : lang.global.delete[currentLangKey]
                }
              >
                <div>
                  <DangerButton
                    className='delete-expenses-button'
                    size='small'
                    onClick={() => {
                      setExpenseActionModalActive({
                        expenseIndex: -1,
                        action: 'deleteExpenses'
                      });

                      handleOpenModal('actionModal');
                    }}
                    disabled={canDeleteExpense}
                  >
                    <DSIcons.TrashDeleteBinIcon />
                  </DangerButton>
                </div>
              </Tooltip>

              <Tooltip
                trigger='hover'
                placement='bottom'
                message={
                  showAlert
                    ? lang.expenses.table.filters.alert_message[currentLangKey]
                    : lang.expenses.labels.add_to_report[currentLangKey]
                }
              >
                <div>
                  <DefaultButton
                    size='small'
                    onClick={() => {
                      handleOpenModal('addExpenseToReport');
                    }}
                    disabled={canAttachExpense || checkedExpensesHasSameCoin}
                    className='add-expenses-button'
                  >
                    <DSIcons.FolderFileCopyIcon />
                  </DefaultButton>
                </div>
              </Tooltip>
            </ActionsContainer>

            {checked.length > 0 ? (
              <Tooltip
                trigger='hover'
                placement='left'
                message={t('actionNotAllowed')}
              >
                <div>
                  <DefaultButton
                    className='add-to-expense-button'
                    size='small'
                    disabled
                  >
                    {lang.global.add[currentLangKey]}
                  </DefaultButton>
                </div>
              </Tooltip>
            ) : (
              <DefaultButton
                className='add-to-expense-button'
                size='small'
                onClick={() => {
                  handleOpenModal('selectExpenseToAdd');
                  setExpenseUuidToUpdate(undefined);
                }}
              >
                {lang.global.add[currentLangKey]}
              </DefaultButton>
            )}
          </ButtonsContainer>

          <TableFiltersContainer>
            <TableFilters
              numberOfExpensesByStatus={numberOfExpensesByStatus}
              onChangeFilters={handleChangeFilters}
              filters={filters}
            />

            <Loader
              isLoading={isLoadingPaginatedExpenses || isDeletingExpenses}
            />

            {!isLoadingPaginatedExpenses && (
              <TablePaginationContainer>
                <TableOverflowContainer>
                  <DefaultTable.Container>
                    <DefaultTable.Thead>
                      <DefaultTable.Tr>
                        <DefaultTable.Th width='5%'>
                          <Checkbox.Default
                            value={isAllCheckboxChecked}
                            onChangeValue={handleCheckAllCheckbox}
                          />
                        </DefaultTable.Th>

                        <DefaultTable.Th width='7%'></DefaultTable.Th>

                        <DefaultTable.Th
                          order={filters.order}
                          isActive={filters.orderBy === 'date'}
                          onClick={() => {
                            handleChangeFilters('orderBy', 'date');
                          }}
                          width='10%'
                        >
                          {lang.expenses.table.columns.date[currentLangKey]}
                        </DefaultTable.Th>

                        <DefaultTable.Th
                          order={filters.order}
                          isActive={filters.orderBy === 'title'}
                          onClick={() => {
                            handleChangeFilters('orderBy', 'title');
                          }}
                          width='20%'
                        >
                          {
                            lang.expenses.table.columns.description[
                              currentLangKey
                            ]
                          }
                        </DefaultTable.Th>

                        <DefaultTable.Th
                          order={filters.order}
                          isActive={filters.orderBy === 'type'}
                          onClick={() => {
                            handleChangeFilters('orderBy', 'type');
                          }}
                          width='10%'
                        >
                          {lang.expenses.table.columns.type[currentLangKey]}
                        </DefaultTable.Th>

                        <DefaultTable.Th
                          order={filters.order}
                          onClick={() => {
                            handleChangeFilters('orderBy', 'report');
                          }}
                          isActive={filters.orderBy === 'report'}
                          width='20%'
                        >
                          {lang.expenses.table.columns.report[currentLangKey]}
                        </DefaultTable.Th>

                        <DefaultTable.Th
                          order={filters.order}
                          onClick={() => {
                            handleChangeFilters('orderBy', 'amount');
                          }}
                          isActive={filters.orderBy === 'amount'}
                          width='10%'
                        >
                          {lang.expenses.table.columns.value[currentLangKey]}
                        </DefaultTable.Th>

                        <DefaultTable.Th width='20%'>
                          {
                            lang.expenses.table.columns.form_of_payment[
                              currentLangKey
                            ]
                          }
                        </DefaultTable.Th>

                        <DefaultTable.Th
                          order={filters.order}
                          onClick={() => {
                            handleChangeFilters('orderBy', 'status');
                          }}
                          isActive={filters.orderBy === 'status'}
                          width='10%'
                        >
                          {lang.expenses.table.columns.status[currentLangKey]}
                        </DefaultTable.Th>

                        <DefaultTable.Th width='8%'>
                          {lang.expenses.table.columns.action[currentLangKey]}
                        </DefaultTable.Th>
                      </DefaultTable.Tr>
                    </DefaultTable.Thead>

                    {!!paginatedExpenses?.data && (
                      <DefaultTable.Tbody>
                        {paginatedExpenses?.data.map((expense, index) => {
                          const status = expense?.report?.status ?? 'AVULSA';

                          return (
                            <DefaultTable.Tr
                              onClick={() => {
                                setExpenseIndex(index);
                                handleOpenModal('viewExpensesModal');
                              }}
                              key={expense?.uuid}
                              className='expenses-table-row'
                            >
                              <DefaultTable.Td
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                              >
                                <Checkbox.Default
                                  value={checked.includes(index)}
                                  onChangeValue={() => {
                                    handleChangeCheckbox(index);
                                  }}
                                />
                              </DefaultTable.Td>

                              <DefaultTable.Td
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                              >
                                {!!expense?.attachment &&
                                  expense?.attachment?.type === 'IMG' && (
                                    <AvatarContainer>
                                      <Avatar
                                        hasZoomControl
                                        viewImage
                                        size='44'
                                        className='expense-attachment-img'
                                        image={{
                                          src: expense?.attachment?.url ?? ''
                                        }}
                                      />
                                    </AvatarContainer>
                                  )}

                                {!!expense?.attachment &&
                                  expense?.attachment?.type === 'PDF' && (
                                    <AvatarContainer
                                      onClick={() =>
                                        window.open(
                                          expense?.attachment?.url,
                                          '_blank'
                                        )
                                      }
                                    >
                                      <Avatar
                                        size='40'
                                        icon='FileIcon'
                                      />
                                    </AvatarContainer>
                                  )}

                                {/* Se não tem anexo */}
                                {!expense?.attachment && (
                                  <Tooltip
                                    message={
                                      expense.type === 'Percurso'
                                        ? lang.expenses.table.items.route[
                                            currentLangKey
                                          ]
                                        : lang.expenses.table.items
                                            .expenseWithoutReceipt[
                                            currentLangKey
                                          ]
                                    }
                                    placement='bottom'
                                    trigger='hover'
                                    containerClassName='attachment-tooltip'
                                  >
                                    <AvatarContainer>
                                      <Avatar
                                        size='44'
                                        iconAsImage={
                                          expense.type === 'Percurso'
                                        }
                                        icon={
                                          expense.type === 'Percurso'
                                            ? 'RouteIcon'
                                            : 'ImagePictureIcon'
                                        }
                                      />
                                    </AvatarContainer>
                                  </Tooltip>
                                )}
                              </DefaultTable.Td>

                              <DefaultTable.Td>
                                {CustomDate.formatFromISO(expense?.date)}
                              </DefaultTable.Td>

                              <DefaultTable.Td
                                title={expense?.title ?? ''}
                                truncate
                              >
                                {expense?.title ??
                                  lang.expenses.labels.untitled[currentLangKey]}
                              </DefaultTable.Td>

                              <DefaultTable.Td
                                title={expense?.type ?? ''}
                                truncate
                              >
                                {expense?.type
                                  ? expense?.type
                                  : lang.expenses.table.items.no_type[
                                      currentLangKey
                                    ]}
                              </DefaultTable.Td>

                              <DefaultTable.Td
                                title={expense?.report?.label ?? ''}
                                truncate
                              >
                                {expense?.report?.label ??
                                  lang.expenses.table.items.no_report[
                                    currentLangKey
                                  ]}
                              </DefaultTable.Td>

                              <DefaultTable.Td>
                                {Currency.format(
                                  expense?.currency ?? 'BRL',
                                  expense?.value ?? 0,
                                  true
                                )}
                              </DefaultTable.Td>

                              <DefaultTable.Td
                                title={expense?.formOfPayment ?? ''}
                                truncate
                              >
                                <CustomTdContainer>
                                  {expense?.formOfPayment
                                    ? expense.formOfPayment
                                    : lang.expenses.table.items
                                        .no_form_of_payment[currentLangKey]}
                                </CustomTdContainer>
                              </DefaultTable.Td>

                              <DefaultTable.Td>
                                <BadgeStatus type={statusBadge[status].status}>
                                  {statusBadge[status].message}
                                </BadgeStatus>
                              </DefaultTable.Td>

                              <DefaultTable.Actions
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                              >
                                {expense.isFromCards &&
                                  expense.isReversal !== 'not_reversed' &&
                                  expense.isReversal !== null && (
                                    <Tooltip
                                      trigger='hover'
                                      placement='left'
                                      message={
                                        expense.isReversal === 'reversed'
                                          ? t('isReversalExpense')
                                          : t('partiallyReversedExpense')
                                      }
                                    >
                                      <div>
                                        <Icons.ReversalIcon />
                                      </div>
                                    </Tooltip>
                                  )}

                                {expense?.canDelete ? (
                                  <Tooltip
                                    trigger='hover'
                                    placement='bottom'
                                    containerClassName='tooltip-delete'
                                    message={lang.global.delete[currentLangKey]}
                                  >
                                    <div>
                                      <DSIcons.TrashDeleteBinIcon
                                        onClick={() => {
                                          setExpenseActionModalActive({
                                            expenseIndex: index,
                                            action: 'deleteExpense'
                                          });
                                          handleOpenModal('actionModal');
                                        }}
                                        color={tokens.feedbackColorErrorPure}
                                      />
                                    </div>
                                  </Tooltip>
                                ) : null}
                              </DefaultTable.Actions>
                            </DefaultTable.Tr>
                          );
                        })}
                      </DefaultTable.Tbody>
                    )}
                  </DefaultTable.Container>
                </TableOverflowContainer>

                {(!paginatedExpenses?.data ||
                  paginatedExpenses.data.length === 0) && (
                  <TableNoCententContainer>
                    <TableNoCententImage src={blueAddFolder} />

                    <TableNoCententTitle>
                      {
                        lang.expenses.table.items.noExpensesTitle[
                          currentLangKey
                        ]
                      }
                    </TableNoCententTitle>

                    <TableNoCententDescription>
                      {
                        lang.expenses.table.items.noExpensesDescriptionOne[
                          currentLangKey
                        ]
                      }
                      <br />
                      {
                        lang.expenses.table.items.noExpensesDescriptionTwo[
                          currentLangKey
                        ]
                      }
                      <br />
                      {
                        lang.expenses.table.items.noExpensesDescriptionThree[
                          currentLangKey
                        ]
                      }
                    </TableNoCententDescription>

                    <br />
                    <DefaultButton
                      size='small'
                      onClick={() => {
                        handleOpenModal('selectExpenseToAdd');
                      }}
                    >
                      {lang.global.addExpense[currentLangKey]}
                    </DefaultButton>
                  </TableNoCententContainer>
                )}

                {!!paginatedExpenses?.data &&
                  paginatedExpenses?.data.length > 0 && (
                    <Pagination.Container
                      currentPage={filters.page}
                      perPage={filters.perPage}
                      totalPage={paginatedExpenses.lastPage}
                      className='pagination-expenses-container'
                    >
                      <Pagination.Label langKey='pt' />

                      <Pagination.Control
                        onChangePage={page => {
                          handleChangeFilters('page', page);
                        }}
                      />

                      <Pagination.Pages
                        langKey='pt'
                        onChangePerPage={perPage => {
                          handleChangeFilters('perPage', perPage);
                        }}
                        options={[50, 20, 10].filter(
                          item => item !== filters.perPage
                        )}
                        className='pagination-items-per-page-container'
                      />
                    </Pagination.Container>
                  )}
              </TablePaginationContainer>
            )}
          </TableFiltersContainer>
        </Container>
      </DefaultLayout>

      {visibleModal === 'selectExpenseToAdd' && (
        <SelectExpenseToAddModal onCreateExpense={handleCreateExpense} />
      )}

      {visibleModal === 'viewExpensesModal' && (
        <ViewExpensesModal
          onDeleteExpense={handleDeleteExpensesFromModal}
          onUpdateExpense={uuid => {
            setExpenseUuidToUpdate(uuid);
            handleUpdateExpense();
          }}
          expenseIndex={expenseIndex}
          total={paginatedExpenses?.data ? paginatedExpenses?.data.length : 0}
          onChangeExpense={action => {
            if (action === 'previous') {
              setExpenseIndex(i => i - 1);
            }

            if (action === 'next') {
              setExpenseIndex(i => i + 1);
            }
          }}
          expenseUuidOrId={selectedExpenseUuidOrId}
          isExpenseOnList={
            id
              ? !!paginatedExpenses?.data.find(item => item.id === parseInt(id))
              : true
          }
        />
      )}

      {visibleModal === 'createManualExpense' && (
        <CreateManualExpenseModal expenseUuidToUpdate={expenseUuidToUpdate} />
      )}

      {visibleModal === 'createManualRouteExpense' && (
        <CreateManualRouteExpenseModal
          expenseUuidToUpdate={expenseUuidToUpdate}
        />
      )}

      {visibleModal === 'createFuelExpense' && (
        <CreateFuelExpenseModal expenseUuidToUpdate={expenseUuidToUpdate} />
      )}

      {visibleModal === 'createRouteByMap' && (
        <CreateRouteByMapModal expenseUuidToUpdate={expenseUuidToUpdate} />
      )}

      {visibleModal === 'actionModal' && (
        <AlertModal
          {...expensesActionModalData[expenseActionModalActive.action]}
          cancelButtonProps={{
            onCancel: () => {
              handleCancelActionExpense(expenseActionModalActive.action);
            }
          }}
          confirmButtonProps={{
            onConfirm: () => {
              handleConfirmActionExpense(expenseActionModalActive.action);
            }
          }}
          icon={<DSIcons.WarningConfirmationIcon />}
        />
      )}

      {visibleModal === 'addExpenseToReport' && (
        <AddExpensesToReportModal
          onResetSelectedExpenses={() => {
            setChecked([]);
          }}
          selectedExpenses={selectedExpenses}
        />
      )}
    </>
  );
}
