import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useLangContext } from 'data/contexts';
import { useGetVirtualMembers } from 'data/modules/cards/businessCard';

import { NavigationTabs } from 'presentation/components/base/NavigationTabs';
import { Title } from 'presentation/components/global/Title';

import { ApprovalFlowManageBusinessCard } from './components/ApprovalFlowManageBusinessCard';
import { CostsCentersManageBusinessCard } from './components/CostsCentersManageBusinessCard';
import { DetailsManageBusinessCard } from './components/DetailsManageBusinessCard';
import { ExpensePoliciesManageBusinessCard } from './components/ExpensePoliciesManageBusinessCard';
import { MembersWithAccess } from './components/MembersWithAccess';
import { ProjectsManageBusinessCard } from './components/ProjectsManageBusinessCard';

import { type ManageBusinessCardType } from './ManageBusinessCard.types';

import { Container } from './ManageBusinessCard.styles';

export function ManageBusinessCard(): JSX.Element {
  const { t } = useTranslation('settings');

  const [currentLangKey, manageBusinessCard, cards, global] = useLangContext(
    state => [
      state.currentLangKey,
      state.lang.settings.manageBusinessCard,
      state.lang.cards,
      state.lang.global
    ]
  );

  const { accountId = '' } = useParams();

  const { virtualMembers, isLoadingVirtualMembers } = useGetVirtualMembers({
    uuid: accountId,
    enabled: !!accountId
  });

  const [pageSelected, setPageSelected] =
    useState<ManageBusinessCardType>('details');

  return (
    <Container>
      <Title
        text={virtualMembers.name}
        buttonProps={{
          iconProps: {
            name: 'arrow-left-double',
            size: 2
          },
          children: manageBusinessCard.back_to_business_cards[currentLangKey],
          color: 'primary',
          size: 'md',
          linkProps: {
            to: '/settings/cartoes/cartoes-empresariais'
          }
        }}
      />

      <NavigationTabs.Container>
        <NavigationTabs.Tab
          active={pageSelected === 'details'}
          onClick={() => {
            setPageSelected('details');
          }}
          containerWidth='auto'
        >
          {global.details[currentLangKey]}
        </NavigationTabs.Tab>

        <NavigationTabs.Tab
          active={pageSelected === 'projects'}
          onClick={() => {
            setPageSelected('projects');
          }}
          containerWidth='auto'
        >
          {t('sidebar.projects')}
        </NavigationTabs.Tab>
        <NavigationTabs.Tab
          active={pageSelected === 'costCenter'}
          onClick={() => {
            setPageSelected('costCenter');
          }}
          containerWidth='auto'
        >
          {
            cards.statement.transaction_details_modal.cost_center[
              currentLangKey
            ]
          }
        </NavigationTabs.Tab>
        <NavigationTabs.Tab
          active={pageSelected === 'approvalFlow'}
          onClick={() => {
            setPageSelected('approvalFlow');
          }}
          containerWidth='auto'
        >
          {manageBusinessCard.approval_flow[currentLangKey]}
        </NavigationTabs.Tab>
        <NavigationTabs.Tab
          active={pageSelected === 'expensePolicies'}
          onClick={() => {
            setPageSelected('expensePolicies');
          }}
          containerWidth='auto'
        >
          {manageBusinessCard.expense_policies[currentLangKey]}
        </NavigationTabs.Tab>
        <NavigationTabs.Tab
          active={pageSelected === 'membersWithAccess'}
          onClick={() => {
            setPageSelected('membersWithAccess');
          }}
          containerWidth='auto'
        >
          {manageBusinessCard.members_with_access[currentLangKey]}
        </NavigationTabs.Tab>
      </NavigationTabs.Container>

      {pageSelected === 'details' && (
        <DetailsManageBusinessCard
          virtualMembers={virtualMembers}
          isLoadingVirtualMembers={isLoadingVirtualMembers}
        />
      )}

      {pageSelected === 'projects' && (
        <ProjectsManageBusinessCard card={virtualMembers} />
      )}

      {pageSelected === 'costCenter' && (
        <CostsCentersManageBusinessCard card={virtualMembers} />
      )}

      {pageSelected === 'approvalFlow' && (
        <ApprovalFlowManageBusinessCard card={virtualMembers} />
      )}

      {pageSelected === 'expensePolicies' && (
        <ExpensePoliciesManageBusinessCard card={virtualMembers} />
      )}

      {pageSelected === 'membersWithAccess' && (
        <MembersWithAccess card={virtualMembers} />
      )}
    </Container>
  );
}
