import { useEffect } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { useAuthContext } from 'data/contexts';
import {
  useGetAuthCardsCompany,
  useGetAuthCardsUser,
  useGetAuthUser,
  useGetOutsourcedUser
} from 'data/modules/auth';

import { CustomCookies } from 'shared/utils/global';

import {
  type IUseAuthenticationMiddleware,
  type IUseAuthenticationMiddlewareParams
} from './AuthenticationMiddleware.types';

export function useAuthenticationMiddleware({
  isPublic = false
}: IUseAuthenticationMiddlewareParams): IUseAuthenticationMiddleware {
  const [
    setAuthenticatedCompany,
    setAuthenticatedUser,
    setOutsourcedUserWithoutCard,
    setOutsourcedUser,
    setIsAuthenticating,
    setIsErrorOnAuthenticate,
    outsourcedUserWithoutCard,
    isErrorOnAuthenticate
  ] = useAuthContext(
    useShallow(state => [
      state.setAuthenticatedCompany,
      state.setAuthenticatedUser,
      state.setOutsourcedUserWithoutCard,
      state.setOutsourcedUser,
      state.setIsAuthenticating,
      state.setIsErrorOnAuthenticate,
      state.outsourcedUserWithoutCard,
      state.isErrorOnAuthenticate
    ])
  );

  const outsourcingUserUuid = CustomCookies.get('outsourcing') ?? '';

  const { user, company, isFetchingAuthUser, isErrorAuthUser } = useGetAuthUser(
    {
      enabled: !isPublic && !isErrorOnAuthenticate
    }
  );

  const isGetOutsourcedUserRequestEnabled =
    !!outsourcingUserUuid && !!user?.parameters.hasOutsourcingAccess;

  const { outsourcedUser, isFetchingOutsourcedUser } = useGetOutsourcedUser({
    userId: outsourcingUserUuid,
    enabled: isGetOutsourcedUserRequestEnabled && !isPublic
  });

  const { cardsCompany, cardsCompanyError, isFetchingCardsCompany } =
    useGetAuthCardsCompany({
      enabled:
        !isFetchingAuthUser &&
        !!company?.parameters.usesCards &&
        !isPublic &&
        !window.location.pathname.includes('store') &&
        !window.location.pathname.includes('suporte') &&
        !window.location.pathname.includes('despesas') &&
        !window.location.pathname.includes('analises')
    });

  const { cardsUser, cardsUserError, isFetchingCardsUser } =
    useGetAuthCardsUser({
      enabled:
        !isFetchingAuthUser &&
        !cardsCompanyError &&
        !isErrorAuthUser &&
        !isPublic &&
        !!company?.parameters.usesCards &&
        !!cardsCompany?.isOnboardingFinished &&
        !window.location.pathname.includes('store') &&
        !window.location.pathname.includes('suporte') &&
        !window.location.pathname.includes('despesas') &&
        !window.location.pathname.includes('analises')
    });

  const isLoading =
    isFetchingOutsourcedUser ||
    isFetchingAuthUser ||
    isFetchingCardsCompany ||
    isFetchingCardsUser;

  useEffect(() => {
    if (cardsCompanyError || cardsUserError || isErrorAuthUser) {
      setIsErrorOnAuthenticate(true);
    }
  }, [
    cardsCompanyError,
    cardsUserError,
    isErrorAuthUser,
    setIsErrorOnAuthenticate
  ]);

  useEffect(() => {
    if (!isLoading && !isErrorOnAuthenticate && company && user) {
      setAuthenticatedCompany({
        ...company,
        cards: cardsCompany || null
      });

      setAuthenticatedUser({
        ...user,
        cards: cardsUser || null
      });

      setOutsourcedUser(outsourcedUser || null);
      setIsAuthenticating(false);
    }
  }, [
    cardsCompany,
    cardsUser,
    company,
    isErrorOnAuthenticate,
    isLoading,
    outsourcedUser,
    setAuthenticatedCompany,
    setAuthenticatedUser,
    setOutsourcedUser,
    setIsAuthenticating,
    user
  ]);

  useEffect(() => {
    if (
      !isFetchingAuthUser &&
      !isGetOutsourcedUserRequestEnabled &&
      !outsourcedUser &&
      !user?.parameters.usesCards &&
      !user?.parameters.cardsUserType
    ) {
      CustomCookies.remove('outsourcing');
      sessionStorage.removeItem('idUsuarioTerceirizado');

      setOutsourcedUserWithoutCard(true);
    }
  }, [
    isFetchingAuthUser,
    isGetOutsourcedUserRequestEnabled,
    outsourcedUser,
    user,
    setOutsourcedUserWithoutCard
  ]);

  if (
    outsourcedUserWithoutCard &&
    company?.cards?.isOnboardingFinished &&
    user?.cards?.isOnboardingFinished
  ) {
    window.location.href = '/despesas';
  }

  return {
    isLoading
  };
}
