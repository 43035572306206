import { useAuthContext } from 'data/contexts';
import { useGetPartialManagers } from 'data/modules/cards/cardGroups';

import { type IUseSearchAndFilters } from './SearchAndFilters.types';

export function useSearchAndFilters(): IUseSearchAndFilters {
  const user = useAuthContext(state => state.user);

  const { partialManagers, isGetPartialManagersFetching } =
    useGetPartialManagers({
      enabled: user?.cards?.type !== 'USER' && user?.cards?.isCardGroupManager
    });

  const selectOptions = partialManagers?.data.map(manager => ({
    value: manager.id,
    label: manager.name
  }));

  return {
    selectOptions,
    isGetPartialManagersFetching
  };
}
