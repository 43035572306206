import { Fragment } from 'react';

import { DefaultButton, Modal, SecondaryButton } from 'ds';
import { toast } from 'ds/utils';

import {
  useChargeCardsContext,
  useLangContext,
  useModalContext
} from 'data/contexts';
import { useDeleteModifiedBalanceAllocationItemsFromCache } from 'data/modules/cards/balance';

import { BaseIcon } from 'presentation/components/base/Icon';
import { Loader } from 'presentation/components/global/Loader';

import { type IClearValuesConfirmationModalProps } from './ClearValuesConfirmationModal.types';

import {
  ContentContainer,
  FooterContainer,
  WarningDescription,
  WarningIconContainer,
  WarningIconWrapper
} from './ClearValuesConfirmationModal.styles';

export function ClearValuesConfirmationModal({
  isOpen
}: IClearValuesConfirmationModalProps): JSX.Element {
  const {
    currentLangKey,
    lang: {
      cards: { valueAllocation },
      global
    }
  } = useLangContext();

  const handleCloseModal = useModalContext(state => state.handleCloseModal);

  const handleClearValues = useChargeCardsContext(
    state => state.handleClearValues
  );

  const {
    deleteModifiedBalanceAllocationItemsFromCache,
    isDeletingModifiedBalanceAllocationItemsFromCache
  } = useDeleteModifiedBalanceAllocationItemsFromCache({
    onAfterSuccess: () => {
      handleClearValues();

      toast.success(
        valueAllocation.changes_successfully_undone[currentLangKey]
      );
    }
  });

  return (
    <Fragment>
      {isOpen && (
        <Modal.Container
          variant='small'
          onClose={handleCloseModal}
        >
          <Modal.Header>
            <Modal.IconClose
              style={{ marginLeft: 'auto' }}
              onClick={handleCloseModal}
            />
          </Modal.Header>

          <Modal.Content>
            <ContentContainer>
              <WarningIconWrapper>
                <WarningIconContainer>
                  <BaseIcon
                    name='error-warning'
                    type='line'
                    color='white'
                    size={3.2}
                  />
                </WarningIconContainer>
              </WarningIconWrapper>

              <WarningDescription>
                {valueAllocation.clear_values[currentLangKey]}
              </WarningDescription>
            </ContentContainer>
          </Modal.Content>

          <Modal.Footer>
            <FooterContainer>
              <SecondaryButton onClick={handleCloseModal}>
                {global.cancel[currentLangKey]}
              </SecondaryButton>

              <DefaultButton
                onClick={() => {
                  deleteModifiedBalanceAllocationItemsFromCache(null);

                  handleCloseModal();
                }}
              >
                {global.clear[currentLangKey]}
              </DefaultButton>
            </FooterContainer>
          </Modal.Footer>
        </Modal.Container>
      )}

      <Loader isLoading={isDeletingModifiedBalanceAllocationItemsFromCache} />
    </Fragment>
  );
}
