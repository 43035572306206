class MockApi {
  /** Retorna um dado mockado simulando um tempo de request com o setTimeout */
  async getData<T extends object | null>(value: T, delay = 1000): Promise<T> {
    return await new Promise((resolve, reject) => {
      setTimeout(() => {
        if (value) {
          resolve(value);
        } else {
          reject(
            new Error(
              'Nenhum valor atribuído para esta chave dos dados de mockados.'
            )
          );
        }
      }, delay);
    });
  }

  /** Retorna um uuid fake */
  fakeUuid(): string {
    return (Math.floor(Math.random() * (20000 - 10000 + 1)) + 10000).toString();
  }

  async sleep(time = 500): Promise<unknown> {
    return await new Promise(resolve => setTimeout(resolve, time));
  }
}

export default new MockApi();
