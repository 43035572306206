import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  CompanyFormCacheDomains,
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type CompanyOnboardingShareholderType,
  type IUseDeletePartner,
  type IUseDeletePartnerParams,
  type UpdateCompanyFormCachePayloadType
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';

export function useDeletePartner({
  selectedPartnerDocument,
  onAfterDeletingPartner
}: IUseDeletePartnerParams): IUseDeletePartner {
  const { data: cachedCompanyOnboardingShareholders = [] } =
    useHandleQueryCache({
      query: {
        query: async () =>
          await CompanyOnboardingService.getCompanyOnboardingShareholders(),
        key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_SHAREHOLDERS]
      }
    });

  const { mutate, isLoading } = useMutationCache({
    mutationKey: [CompanyOnboardingMutationKeys.UPDATE_COMPANY_FORM_CACHE],
    mutationFn: async (payload: UpdateCompanyFormCachePayloadType) =>
      await CompanyOnboardingService.updateCompanyFormCache(payload),
    onSuccess: (data, variables) => {
      onAfterDeletingPartner(
        variables.value as CompanyOnboardingShareholderType[]
      );
    }
  });

  const { mutate: clearShareholdersCache } = useMutationCache({
    mutationKey: [
      CompanyOnboardingMutationKeys.CLEAR_COMPANY_ONBOARDING_SHAREHOLDERS_CACHE
    ],
    mutationFn: async () =>
      await CompanyOnboardingService.clearCompanyOnboardingShareholdersCache(),
    onSuccess: () => {
      onAfterDeletingPartner([]);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  async function handleDeletePartner(): Promise<void> {
    if (isLoading) {
      return;
    }

    const filteredShareholders = cachedCompanyOnboardingShareholders?.filter(
      shareholder => shareholder.document !== selectedPartnerDocument
    );

    if (filteredShareholders.length === 0) {
      clearShareholdersCache(null);

      return;
    }

    mutate({
      domain: CompanyFormCacheDomains.onboardingShareholders,
      lifeCycle: 360,
      value: filteredShareholders
    });
  }

  return {
    handleDeletePartner,
    isDeletingPartner: isLoading
  };
}
