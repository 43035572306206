import { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useModalContext, useValueSolicitationContext } from 'data/contexts';
import {
  BalanceQueryKeys,
  type IBalanceSolicitationApproved,
  useGetBalanceSolicitations,
  useGetBalanceSolicitationsStaged,
  useGetQuantityOfBalanceSolicitationByStatus
} from 'data/modules/cards/balance';

import { useDebounce } from 'shared/hooks/global';

import { type IUseValueSolicitationContainer } from './ValueSolicitationContainer.types';

export function useValueSolicitationContainer(): IUseValueSolicitationContainer {
  const { groupId } = useParams();

  const queryClient = useQueryClient();

  const [
    currentPage,
    pageSize,
    search,
    listType,
    handleChangePagination,
    handleSetAllSelectSolicitation,
    startDate,
    endDate,
    dateType
  ] = useValueSolicitationContext(
    useShallow(state => [
      state.currentPage,
      state.pageSize,
      state.searchInput,
      state.listType,
      state.handleChangePagination,
      state.handleSetAllSelectSolicitation,
      state.startDate,
      state.endDate,
      state.dateType
    ])
  );

  const [
    isFirstTimeStaggeredBalanceRequest,
    setIsFirstTimeStaggeredBalanceRequest
  ] = useState(true);

  const debouncedSearch = useDebounce(search, 500);

  const { paginatedBalanceSolicitations, isFetchingBalanceSolicitations } =
    useGetBalanceSolicitations({
      page: currentPage,
      limit: pageSize,
      dateType: startDate && endDate && dateType ? dateType : null,
      startDate:
        startDate && endDate ? new Date(startDate).toISOString() : null,
      endDate: endDate && startDate ? new Date(endDate).toISOString() : null,
      name:
        debouncedSearch.toLocaleLowerCase().trim() !== ''
          ? debouncedSearch.toLocaleLowerCase().trim()
          : undefined,
      status: listType,
      ...(groupId &&
        groupId && {
          uuidCardGroup: groupId
        })
    });

  const { balanceSolicitationStaged, isFetchingBalanceSolicitationsStaged } =
    useGetBalanceSolicitationsStaged();

  useEffect(() => {
    const totalItems = paginatedBalanceSolicitations?.total;

    const totalPages = totalItems && Math.ceil(totalItems / pageSize);

    handleChangePagination({ totalPages, totalItems });

    if (balanceSolicitationStaged && isFirstTimeStaggeredBalanceRequest) {
      setIsFirstTimeStaggeredBalanceRequest(false);

      handleSetAllSelectSolicitation(balanceSolicitationStaged);
    }
  }, [
    handleChangePagination,
    handleSetAllSelectSolicitation,
    isFirstTimeStaggeredBalanceRequest,
    pageSize,
    paginatedBalanceSolicitations,
    balanceSolicitationStaged
  ]);

  const { handleOpenModal } = useModalContext();

  const [balanceSolicitationsApproved, setBalanceSolicitationsApproved] =
    useState<IBalanceSolicitationApproved | null>(null);

  const { quantitySolicitationByStatus } =
    useGetQuantityOfBalanceSolicitationByStatus({
      ...(groupId && { cardGroupId: groupId })
    });

  function onBalanceSolicitationApprovalsSummaryData(
    data: IBalanceSolicitationApproved
  ): void {
    setBalanceSolicitationsApproved(data);

    queryClient.resetQueries({
      queryKey: [BalanceQueryKeys.GET_MY_VALUE_SOLICITATIONS]
    });

    handleOpenModal('valueSolicitationFeedback');
  }

  return {
    balanceSolicitations: paginatedBalanceSolicitations?.data ?? [],
    isLoadingBalanceSolicitations:
      isFetchingBalanceSolicitations || isFetchingBalanceSolicitationsStaged,
    balanceSolicitationsApproved,
    onBalanceSolicitationApprovalsSummaryData,
    quantitySolicitationByStatus
  };
}
