import { useState } from 'react';

import { type IUseSelectControlled } from './SelectControlled.types';

export function useSelectControlled(): IUseSelectControlled {
  const [searchValue, setSearchValue] = useState<string>('');

  return {
    searchValue,
    setSearchValue
  };
}
