import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IAccountHolderFieldsForm } from 'data/modules/cards/accountHolders/types/accountHolders.types';

import { businessTypes } from 'shared/constants/company';
import { Mask } from 'shared/utils/format';
import { Validate } from 'shared/utils/validation';

export function useAccountHolderSchema(): ObjectSchema<IAccountHolderFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const accountHolderSchema: ObjectSchema<IAccountHolderFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object({
        legalName: yup.string().required(fieldRequiredMessage).test({
          name: 'legalName',
          message: lang.schemas.write_two_name[currentLangKey],
          test: Validate.fullName
        }),
        name: yup
          .string()
          .nullable()
          .defined()
          .transform(value => {
            return value === '' ? null : value;
          }),
        businessType: yup
          .string()
          .oneOf(businessTypes)
          .required(fieldRequiredMessage),
        documentNumber: yup.string().required(fieldRequiredMessage).test({
          name: 'documentNumber',
          message: lang.schemas.invalid_CNPJ[currentLangKey],
          test: Validate.cnpj
        }),
        foundingDate: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'foundingDate',
            message: lang.schemas.invalid_date[currentLangKey],
            test: (value: string) => Validate.isDate(value)
          })
          .test({
            name: 'foundingDate',
            message: lang.schemas.date_must_not_be_future[currentLangKey],
            test: (value: string) => !Validate.isFutureDate(value)
          })
          .matches(
            /(\d{2})[-./](\d{2})[-./](\d{4})/,
            lang.schemas.invalid_date[currentLangKey]
          ),
        mainActivity: yup.string().required(fieldRequiredMessage),
        email: yup
          .string()
          .email(lang.schemas.invalid_email[currentLangKey])
          .required(fieldRequiredMessage),
        phoneNumber: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'phoneNumber',
            message:
              lang.schemas.phone_number_must_have_8_and_9_digits[
                currentLangKey
              ],
            test: value => {
              const unmaskedValue = Mask.remove(value);
              return unmaskedValue.length === 10 || unmaskedValue.length === 11;
            }
          }),
        website: yup
          .string()
          .nullable()
          .defined()
          .transform(value => {
            return value === '' ? null : value;
          }),
        postalCode: yup
          .string()
          .required(fieldRequiredMessage)
          .matches(
            /(\d{5})[-./](\d{3})/,
            lang.schemas.invalid_postal_code[currentLangKey]
          ),
        neighborhood: yup.string().required(fieldRequiredMessage),
        street: yup.string().required(fieldRequiredMessage),
        country: yup.string().required(fieldRequiredMessage),
        state: yup.string().required(fieldRequiredMessage),
        city: yup.string().required(fieldRequiredMessage),
        number: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'number',
            message: lang.schemas.positive_number[currentLangKey],
            test: value => {
              return (
                Validate.isInteger(value) && Validate.isBiggerThanZero(value)
              );
            }
          }),
        complement: yup
          .string()
          .nullable()
          .defined()
          .transform(value => {
            return value === '' ? null : value;
          })
      });
    }, [lang, currentLangKey]);

  return accountHolderSchema;
}
