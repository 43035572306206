import {
  type ChangeEvent,
  type DragEvent,
  useEffect,
  useRef,
  useState
} from 'react';

import {
  type IUseDragAndDropFileDefault,
  type IUseDragAndDropFileDefaultParams
} from './DragAndDropFileDefault.types';

export function useDragAndDropFileDefault({
  acceptedFileTypes,
  onChangeFile,
  selectedFile
}: IUseDragAndDropFileDefaultParams): IUseDragAndDropFileDefault {
  const [dragInsideElementActive, setDragInsideElementActive] = useState(false);
  const [dragDocumentActive, setDragDocumentActive] = useState(false);

  const [blobImageFile, setBlobImageFile] = useState<
    string | ArrayBuffer | null | undefined
  >(null);
  const [uploadError, setUploadError] = useState(false);
  const [sizeError, setSizeError] = useState(false);

  useEffect(() => {
    const currentFile: File | null = selectedFile;

    if (currentFile && currentFile.type.toLowerCase().includes('image')) {
      const fileReader = new FileReader();
      const isCancel = false;

      fileReader.onload = e => {
        const result: string | ArrayBuffer | null | undefined =
          e.target?.result;

        if (result && !isCancel) {
          setBlobImageFile(result);
        }
      };

      fileReader.readAsDataURL(currentFile);
      return;
    }

    if (currentFile && currentFile.type.toLowerCase().includes('pdf')) {
      setBlobImageFile(URL.createObjectURL(currentFile));
      return;
    }

    setBlobImageFile(null);
  }, [selectedFile]);

  const [isImageEnlarged, setIsImageEnlarged] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  function handleDragInsideElement(e: DragEvent<HTMLElement>): void {
    e.preventDefault();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragInsideElementActive(true);
      return;
    }

    if (e.type === 'dragleave') {
      setDragInsideElementActive(false);
    }
  }

  function updateSelectedFile(file: File): void {
    const extension = file.name.split('.').pop();
    const size = Math.round(+file.size / 1024) / 1000;

    if (size > 16) {
      setSizeError(true);
      onChangeFile(null);
      return;
    }

    if (extension === undefined || !acceptedFileTypes.includes(extension)) {
      onChangeFile(null);
      setUploadError(true);
      return;
    }

    onChangeFile(file);
    setUploadError(false);
  }

  function handleOpenFileOrEnlargeImage(): void {
    if (selectedFile?.type.toLowerCase().includes('image')) {
      setIsImageEnlarged(true);
      return;
    }

    window.open(blobImageFile as string, '_blank');
  }

  function handleDeleteFile(): void {
    inputRef.current?.value && (inputRef.current.value = '');
    onChangeFile(null);
    setUploadError(false);
  }

  function handleDropFile(e: DragEvent<HTMLElement>): void {
    e.preventDefault();
    e.stopPropagation();
    setDragInsideElementActive(false);
    setDragDocumentActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];

      updateSelectedFile(file);
    }
  }

  function handleChangeFile(e: ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      updateSelectedFile(file);
    }
  }

  useEffect(() => {
    function handleDocumentDrag(e: Event): void {
      e.preventDefault();
      e.stopPropagation();

      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragDocumentActive(true);
        return;
      }

      const completeEvent = e as unknown as DragEvent<HTMLElement>;
      if (
        e.type === 'dragleave' &&
        (completeEvent.clientX <= 0 ||
          completeEvent.clientY <= 0 ||
          completeEvent.clientX >= window.innerWidth ||
          completeEvent.clientY >= window.innerHeight)
      ) {
        setDragDocumentActive(false);
      }
    }

    function handleDocumentDrop(e: Event): void {
      e.preventDefault();
      e.stopPropagation();
      setDragDocumentActive(false);
    }

    window.addEventListener('dragenter', handleDocumentDrag);
    window.addEventListener('dragover', handleDocumentDrag);
    window.addEventListener('dragleave', handleDocumentDrag);
    window.addEventListener('drop', handleDocumentDrop);

    return () => {
      window.removeEventListener('dragenter', handleDocumentDrag);
      window.removeEventListener('dragleave', handleDocumentDrag);
      window.removeEventListener('dragover', handleDocumentDrag);
      window.removeEventListener('drop', handleDocumentDrop);
    };
  }, []);

  const selectedFileIsImage =
    selectedFile?.type.toLowerCase().includes('image') || false;

  return {
    dragDocumentActive,
    dragInsideElementActive,
    handleChangeFile,
    handleDragInsideElement,
    handleDropFile,
    blobImageFile,
    uploadError,
    selectedFileIsImage,
    isImageEnlarged,
    setIsImageEnlarged,
    handleDeleteFile,
    handleOpenFileOrEnlargeImage,
    inputRef,
    sizeError
  };
}
