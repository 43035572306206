import { BadgeStatus, DSIcons, TextButton, tokens } from 'ds';
import { useTranslation } from 'react-i18next';

import { vexDomain } from 'data/config';
import { type ExpenseStatusType } from 'data/modules/expenses';

import { Icons } from 'shared/constants/global';
import { Currency } from 'shared/utils/format';

import { type IUserAnalysisReportHeaderProps } from './UserAnalysisReportHeader.types';

import {
  Container,
  HeaderWrapper,
  ReportContent,
  ReportFilterNotice,
  ReportInfoContent,
  ReportInfoLabel,
  ReportInfoRow,
  ReportInfoText,
  ReportInfoTotalLabel,
  ReportInfoTotalValue,
  ReportInfoTotalWrapper,
  ReportTitle,
  TitleWrapper
} from './UserAnalysisReportHeader.styles';

export function UserAnalysisReportHeader({
  reportId,
  reportName,
  reportTotal,
  reportStatus,
  approvalFlowName,
  originalCurrency,
  reportObservation,
  convertedCurrency,
  reportSumRefundable,
  allExpensesReturned,
  reportTotalConverted,
  reportSumNoRefundable,
  reportSumRefundableConverted,
  reportSumNoRefundableConverted
}: IUserAnalysisReportHeaderProps): JSX.Element {
  const { t } = useTranslation(['analytics', 'global']);

  const statusBadge: Record<ExpenseStatusType, string> = {
    PAGO: t('global:paid_male_one'),
    ABERTO: t('global:open_male_one'),
    ENVIADO: t('global:sent_male_one'),
    APROVADO: t('global:approved_male_one'),
    REABERTO: t('global:reopened_male_one'),
    REPROVADO: t('global:rejected_male_one'),
    AVULSA: t('global:standalone_female_one')
  };

  const sumReimbursable = reportSumRefundableConverted
    ? `${Currency.format(
        convertedCurrency ?? 'BRL',
        reportSumRefundableConverted ?? 0,
        true
      )} (${Currency.format(originalCurrency ?? 'BRL', reportSumRefundable ?? 0, true)})`
    : Currency.format('BRL', reportSumRefundable ?? 0, true);

  const sumNoReimbursable = reportSumNoRefundableConverted
    ? `${Currency.format(
        convertedCurrency ?? 'BRL',
        reportSumNoRefundableConverted ?? 0,
        true
      )} (${Currency.format(originalCurrency ?? 'BRL', reportSumNoRefundable ?? 0, true)})`
    : Currency.format('BRL', reportSumNoRefundable ?? 0, true);

  return (
    <Container>
      <HeaderWrapper>
        <TitleWrapper>
          <ReportTitle>
            <span>#{reportId} - </span>
            <p>{reportName}</p>
            <span>{`(${reportId})`}</span>
          </ReportTitle>
          <BadgeStatus>
            {statusBadge[reportStatus as ExpenseStatusType]}
          </BadgeStatus>
        </TitleWrapper>
        <TextButton
          size='small'
          onClick={() => {
            window.open(`${vexDomain}/relatorios/${reportId}`);
          }}
        >
          {t('userResume.viewReport')}
          <DSIcons.ArrowRightIcon />
        </TextButton>
      </HeaderWrapper>

      <ReportContent>
        <ReportInfoContent>
          <ReportInfoRow>
            <ReportInfoLabel>{t('userResume.reportNote')}:</ReportInfoLabel>
            <ReportInfoText>{reportObservation}</ReportInfoText>
          </ReportInfoRow>

          <ReportInfoRow>
            <ReportInfoLabel>
              {t('userResume.reportApprovalFlow')}:
            </ReportInfoLabel>
            <ReportInfoText>{approvalFlowName}</ReportInfoText>
          </ReportInfoRow>

          <ReportInfoRow>
            <ReportInfoLabel>
              {t('userResume.reimbursableAmount')}:
            </ReportInfoLabel>
            <ReportInfoText>{sumReimbursable}</ReportInfoText>
          </ReportInfoRow>

          <ReportInfoRow>
            <ReportInfoLabel>
              {t('userResume.nonReimbursableAmount')}:
            </ReportInfoLabel>
            <ReportInfoText>{sumNoReimbursable}</ReportInfoText>
          </ReportInfoRow>

          {!allExpensesReturned && (
            <ReportFilterNotice>
              <Icons.InfoIcon color={tokens.neutralColorLowLight} />
              <span>{t('userResume.filtersAppliedMessage')}</span>
            </ReportFilterNotice>
          )}
        </ReportInfoContent>

        <ReportInfoTotalWrapper>
          <ReportInfoTotalLabel>
            {t('userResume.reportTotalAmount')}:
          </ReportInfoTotalLabel>

          <ReportInfoTotalValue>
            {Currency.format(originalCurrency ?? 'BRL', reportTotal ?? 0, true)}{' '}
            {reportTotalConverted && (
              <span>
                {`(${Currency.format(
                  convertedCurrency ?? 'BRL',
                  reportTotalConverted ?? 0,
                  true
                )})`}
              </span>
            )}
          </ReportInfoTotalValue>
        </ReportInfoTotalWrapper>
      </ReportContent>
    </Container>
  );
}
