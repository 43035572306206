import { useEffect, useMemo } from 'react';

import { driver, type DriveStep } from 'driver.js';

import { driverJsConfig } from 'data/config';
import { useLangContext } from 'data/contexts';

import { localStorageKeys } from 'shared/constants/global';
import { Zendesk } from 'shared/utils/global';

import { type IUseManualExpenseModalTourParams } from './useManualExpenseModalTour.types';

export function useManualExpenseModalTour({
  step
}: IUseManualExpenseModalTourParams): void {
  const {
    lang: {
      expenses: { tours },
      global
    },
    currentLangKey
  } = useLangContext();

  const driverSteps: DriveStep[] = useMemo(() => {
    if (step === 'defaultFields') {
      return [
        {
          element: '.stepper-container',
          popover: {
            title: tours.manualExpenseModal.first_step.title[currentLangKey],
            description:
              tours.manualExpenseModal.first_step.description[currentLangKey]
          }
        },
        {
          element: '.first-step-form-left-content',
          popover: {
            title: tours.manualExpenseModal.second_step.title[currentLangKey],
            description:
              tours.manualExpenseModal.second_step.description[currentLangKey]
          }
        },
        {
          element: '.drag-and-drop-file-container',
          popover: {
            title: tours.manualExpenseModal.third_step.title[currentLangKey],
            description:
              tours.manualExpenseModal.third_step.description[currentLangKey]
          }
        }
      ];
    }

    if (step === 'selectReport') {
      return [
        {
          element: '.search-select-report-container',
          popover: {
            title: tours.reportSelection.first_step.title[currentLangKey],
            description:
              tours.reportSelection.first_step.description[currentLangKey]
          }
        },
        {
          element: '.create-report-button',
          popover: {
            title: tours.reportSelection.second_step.title[currentLangKey],
            description:
              tours.reportSelection.second_step.description[currentLangKey]
          }
        },
        {
          element: '.create-expense-button-submit',
          popover: {
            title: tours.reportSelection.third_step.title[currentLangKey],
            description:
              tours.reportSelection.third_step.description[currentLangKey]
          }
        },
        {
          element: '.report-radio-item',
          popover: {
            title: tours.reportSelection.fourth_step.title[currentLangKey],
            description:
              tours.reportSelection.fourth_step.description[currentLangKey]
          }
        }
      ];
    }

    return [];
  }, [step, tours, currentLangKey]);

  useEffect(() => {
    function startTour(): void {
      const manualExpenseModalTour = driver({
        ...driverJsConfig,
        nextBtnText: global.next[currentLangKey],
        prevBtnText: global.previous[currentLangKey],
        doneBtnText: global.finalize[currentLangKey],
        progressText: `{{current}} ${global.of[currentLangKey]} {{total}}`,
        onDestroyed: () => {
          manualExpenseModalTour.destroy();
          Zendesk.show();

          if (step === 'defaultFields') {
            localStorage.setItem(
              localStorageKeys.manualExpenseModalTour,
              'true'
            );
          }

          if (step === 'selectReport') {
            localStorage.setItem(localStorageKeys.reportSelectionTour, 'true');
          }
        },
        steps: driverSteps
      });

      manualExpenseModalTour.drive();
    }

    if (
      localStorage.getItem(localStorageKeys.manualExpenseModalTour) !==
        'true' &&
      step === 'defaultFields'
    ) {
      // espera 400ms para iniciar o tour por conta da animação do modal
      const timer = setTimeout(() => {
        startTour();
        Zendesk.hide();
      }, 400);

      return () => {
        clearTimeout(timer);
      };
    }

    if (
      localStorage.getItem(localStorageKeys.reportSelectionTour) !== 'true' &&
      step === 'selectReport'
    ) {
      // espera 400ms para iniciar o tour por conta da animação da div
      const timer = setTimeout(() => {
        startTour();
        Zendesk.hide();
      }, 400);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [step, currentLangKey, global, driverSteps]);
}
