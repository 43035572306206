import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1.5rem;
  flex-wrap: wrap;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 2.4rem;
  }
`;

export const SearchbarContainer = styled.div`
  display: flex;

  align-items: center;
  gap: ${tokens.spacingXs};

  > div:first-child {
    min-width: 40rem;
  }

  svg {
    width: 3rem;
    height: 3rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    gap: 2.4rem;
    width: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 100%;

    > div:first-child {
      min-width: 100%;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingMd};

  > button {
    height: 4rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    justify-content: space-between;
  }
`;
