import {
  type IPersistenceTransferBalanceAllocationItem,
  type IPersistenceTransferredBalanceAllocationItems,
  type ITransferBalanceAllocationItem,
  type ITransferredBalanceAllocationItems
} from 'data/modules/cards/balance';

export class TransferBalanceAllocationItemsMapper {
  toDomain(
    persistence: IPersistenceTransferredBalanceAllocationItems
  ): ITransferredBalanceAllocationItems {
    return {
      success: persistence.success.map(item => ({
        balanceAccountId: item.balance_account_id,
        userName: item.user_name,
        responseCodeMessage: item.response_code_message,
        amount: item.amount,
        type: item.type
      })),
      failure: persistence.failure.map(item => ({
        balanceAccountId: item.balance_account_id,
        userName: item.user_name,
        responseCodeMessage: item.response_code_message,
        amount: item.amount,
        type: item.type
      }))
    };
  }

  toPersistence(
    domain: ITransferBalanceAllocationItem
  ): IPersistenceTransferBalanceAllocationItem {
    return {
      balance_account_id: domain.balanceAccountId,
      amount: domain.amount,
      type: domain.type
    };
  }
}

export default new TransferBalanceAllocationItemsMapper();
