import styled, { css } from 'styled-components';

import { type DsStyleType } from './DsSearch.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<AddPrefixToType<DsStyleType>>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 5.6rem;
  min-width: 25rem;
  width: 100%;
  padding: ${({ theme }) =>
    `${theme.tokens.spacingInsetXs} ${theme.tokens.spacingInsetXs}`};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusMd};
  background-color: ${({ theme }) => theme.tokens.neutralColorHighMedium};
  border: ${({ theme }) => theme.tokens.borderWidthThin} solid transparent;

  svg {
    fill: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
  }

  ${({ $hasValue }) =>
    $hasValue &&
    css`
      padding: 0.6rem ${({ theme }) => theme.tokens.spacingInsetMd};
    `}

  &:focus-within {
    background-color: ${({ theme }) => theme.tokens.neutralColorHighPure};
    border-color: ${({ theme }) => theme.tokens.brandColorPrimaryPure};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${({ theme }) =>
        theme.tokens.neutralColorHighMediumest};
      opacity: 0.5;
    `}
`;

export const Label = styled.label<AddPrefixToType<DsStyleType>>`
  color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.tokens.neutralColorLowLightest};
    `}
`;

export const InputContainer = styled.div<AddPrefixToType<DsStyleType>>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.tokens.spacingXs};

  ${({ $disabled }) =>
    $disabled &&
    css`
      i {
        color: ${({ theme }) => theme.tokens.neutralColorLowLightest};
      }
    `}
`;

export const Input = styled.input<AddPrefixToType<DsStyleType>>`
  height: 100%;
  width: ${({ $hasValue }) => ($hasValue ? '80%' : '100%')};
  background: transparent;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.tokens.neutralColorLowPure};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxs};
  font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  margin: 0;
  padding: 0;

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.tokens.neutralColorLowLightest};

      &::placeholder {
        color: ${({ theme }) => theme.tokens.neutralColorLowPure};
      }
    `}
`;

export const CleanButton = styled.button<AddPrefixToType<DsStyleType>>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2rem;
  right: 2rem;
  visibility: ${({ $hasValue }) => !$hasValue && 'hidden'};
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: default;

      svg {
        color: ${({ theme }) => theme.tokens.neutralColorLowLightest};
      }
    `}
`;
