import {
  type IPersistenceUserAccountByUserUuid,
  type IUserAccountByUserUuid
} from 'data/modules/cards/account';

import { CustomObject } from 'shared/utils/custom';

class UserAccountsByUserUuidMapper {
  toDomain(data: IPersistenceUserAccountByUserUuid): IUserAccountByUserUuid {
    return {
      account: CustomObject.pick(data.account, ['description', 'id', 'status']),
      balances: data.balances.map(balance => ({
        inUse: balance.in_use,
        ...CustomObject.pick(balance, ['balance', 'description', 'id'])
      }))
    };
  }
}

export default new UserAccountsByUserUuidMapper();
