import {
  type IPersistenceUnallocatedBalance,
  type IUnallocatedBalance
} from 'data/modules/cards/balance';

class GetUnallocatedBalanceMapper {
  toDomain(
    persistenceUnallocatedBalance: IPersistenceUnallocatedBalance
  ): IUnallocatedBalance {
    return {
      amount: persistenceUnallocatedBalance.amount
    };
  }
}

export default new GetUnallocatedBalanceMapper();
