import { Portal } from 'ds/components/Portal';
import { DSIcons } from 'ds/icons';
import OutsideClickHandler from 'react-outside-click-handler';

import { useFloatingMenu } from './useFloatingMenu';

import { type IFloatingMenuProps } from './FloatingMenu.types';

import {
  Arrow,
  DropdownContainer,
  DropdownItem,
  PopperContainer,
  TriggerContainer
} from './FloatingMenu.styles';

export function FloatingMenu({
  options,
  trigger,
  ...rest
}: IFloatingMenuProps): JSX.Element {
  const {
    attributes,
    isVisible,
    styles,
    setIsVisible,
    setArrowElement,
    setPopperElement,
    setTriggerElement
  } = useFloatingMenu();

  return (
    <>
      <TriggerContainer
        ref={setTriggerElement}
        onMouseDown={() => {
          setIsVisible(!isVisible);
        }}
      >
        {trigger}
      </TriggerContainer>

      {isVisible && (
        <Portal>
          <PopperContainer
            {...rest}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <Arrow
              style={styles.arrow}
              ref={setArrowElement}
              {...attributes.arrow}
            >
              <DSIcons.ArrowUpDropdown />
            </Arrow>

            <OutsideClickHandler
              onOutsideClick={() => {
                setIsVisible(false);
              }}
            >
              <DropdownContainer
                $hasScroll={options.length >= 5}
                style={styles.offset}
              >
                {options.map((item, index) => {
                  return (
                    <DropdownItem
                      onClick={e => {
                        e.stopPropagation();

                        item.onClick?.();
                        setIsVisible(false);
                      }}
                      key={index}
                    >
                      {item.icon} {item.label}
                    </DropdownItem>
                  );
                })}
              </DropdownContainer>
            </OutsideClickHandler>
          </PopperContainer>
        </Portal>
      )}
    </>
  );
}
