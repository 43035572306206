import { Modal, tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type LinkMembersContainerStylesType } from './ViewVehicleModal.types';
import { type AddPrefixToType } from 'ds/types';

export const RowInputs = styled.div`
  display: flex;
  gap: ${tokens.spacingXs};
  width: 100%;

  > div {
    width: calc((100% - ${tokens.spacingXs}) / 2);
  }

  .modal-textinput {
    min-width: unset !important;
  }
`;

export const ModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  margin-bottom: ${tokens.spacingXs};
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-height: 70vh;
  padding-right: 0;

  .react-select-custom__control,
  .react-select-custom__menu,
  .react-multiSelect-custom__control {
    min-width: unset !important;
    max-width: unset !important;
  }

  .react-multiSelect-custom__control > div:first-child {
    flex-shrink: 1;
    overflow: auto;
    max-width: unset;
  }

  .react-select-custom__menu {
    z-index: 3;
    max-height: 23rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
    width: 100%;

    ${RowInputs} {
      flex-direction: column;

      > div {
        width: 100%;
      }
    }
  }
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Divider = styled.div`
  height: 0.1rem;
  width: 100%;
  background-color: ${tokens.neutralColorHighDark};
`;

export const Label = styled.h3`
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightMedium};
  line-height: 2.2rem;
  letter-spacing: 0.25px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: sticky;
  width: 100%;
  bottom: 0;
`;

export const SelectedMembersContainer = styled.div<
  AddPrefixToType<LinkMembersContainerStylesType>
>`
  width: 100%;
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  max-height: 10rem;
  overflow: hidden;
  flex-direction: row;
  gap: ${tokens.spacingNano};
  transition: max-height 100ms ease-in-out;

  ${({ $showMoreMembers }) =>
    $showMoreMembers &&
    css`
      max-height: 16rem;
      overflow: auto;

      ~ button > svg {
        transform: rotate(180deg);
      }
    `}
`;

export const StyledModalContainer = styled(Modal.Container)`
  @media screen and (max-width: 1300px) {
    max-width: 50rem;
    width: 50rem;
  }

  @media screen and (max-width: 500px) {
    width: 40rem;
    max-width: 40rem;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  > form {
    overflow: auto;
  }
`;

export const VehicleDetailsContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  gap: ${tokens.spacingXs};
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      width: 100%;
    }
  }
`;

export const VehicleDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-content: center;
  white-space: nowrap;
  word-wrap: keep-all;
  gap: ${tokens.spacingXs};
  width: 50%;
`;

export const VehicleDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VehicleDetailTitle = styled.span`
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightMedium};
  font-size: ${tokens.fontSizeXxss};
  line-height: 2.2rem;
  letter-spacing: 0.025rem;
  color: ${tokens.neutralColorLowLight};
`;

export const VehicleDetailValue = styled.span`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  letter-spacing: 0.075rem;
  color: ${tokens.neutralColorLowDark};
`;

export const FooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${tokens.spacingXs};
`;
