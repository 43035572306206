import { type ICustomInfoMessageProps } from './CustomInfoMessage.types';

import {
  Container,
  CustomInfoMessageText,
  CustomInfoMessageTitle,
  IconContainer
} from './CustomInfoMessage.styles';

export function CustomInfoMessage({
  icon,
  title,
  message
}: ICustomInfoMessageProps): JSX.Element {
  return (
    <Container>
      {icon && <IconContainer>{icon}</IconContainer>}
      {title && <CustomInfoMessageTitle>{title}</CustomInfoMessageTitle>}
      {message && <CustomInfoMessageText>{message}</CustomInfoMessageText>}
    </Container>
  );
}
