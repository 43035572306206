import {
  Alert,
  CustomRadioItem,
  DangerButton,
  DefaultButton,
  DSIcons,
  Modal,
  SecondaryButton,
  SkeletonLoader,
  TextInput
} from 'ds';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { useLangContext, useModalContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';
import { CreateReportForm } from 'presentation/pages/expenses/ExpensesList/components/CreateReportForm';

import reportNoItemsImg from 'shared/assets/images/expenses/modal/illustration_booking_no_items.png';
import reportNotFoundImg from 'shared/assets/images/expenses/modal/illustration_booking_not_found.png';
import { animations } from 'shared/constants/animations';
import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

import { useAddExpensesToReportModal } from './useAddExpensesToReportModal';

import { type IAddExpensesToReportModalProps } from './AddExpensesToReportModal.types';

import {
  ButtonContainer,
  Container,
  ContainerWithMotion,
  ReportInformationList,
  ReportItemSubtitle,
  ReportNotFoundContainer,
  SearchSelectReportContainer,
  SelectReportContainer
} from './AddExpensesToReportModal.styles';

export function AddExpensesToReportModal({
  selectedExpenses,
  onResetSelectedExpenses
}: IAddExpensesToReportModalProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  const { t } = useTranslation('expenses');

  const { handleCloseModal } = useModalContext();

  const {
    filteredOpenReports,
    isShowingModalLoading,
    searchTerm,
    checkIfSomeSelectedExpensesCostsCenterUuidIsDifferentFromReportCostsCenterUuid,
    isLoadingOpenReports,
    handleChangeSearchTerm,
    selectedReportCostsCenterName,
    selectedReport,
    expensesWithDifferentCostsCenter,
    setSelectedReport,
    allowCreateReport,
    reportCurrencyIsoCode,
    checkIfSelectedExpensesCurrencyIsDifferentFromReportCurrency,
    companyApprovalType,
    handleShowCreateReportFormOrLinkExpensesToReport,
    showCreateReportForm,
    setShowCreateReportForm
  } = useAddExpensesToReportModal({
    selectedExpenses,
    onResetSelectedExpenses
  });

  return (
    <>
      <Loader isLoading={isShowingModalLoading} />

      <Modal.Container
        onClose={handleCloseModal}
        variant='large'
      >
        {showCreateReportForm && (
          <AnimatePresence>
            <ContainerWithMotion {...animations.fadeInOut}>
              <CreateReportForm
                onClickGoBackButton={() => {
                  setShowCreateReportForm(false);
                }}
                onReportCreated={uuid => {
                  setSelectedReport(uuid);
                  setShowCreateReportForm(false);
                }}
                stepperComponent={<></>}
              />
            </ContainerWithMotion>
          </AnimatePresence>
        )}

        {!showCreateReportForm && (
          <AnimatePresence>
            <ContainerWithMotion {...animations.fadeInOut}>
              <Modal.Header>
                <Modal.Title>
                  {lang.expenses.modal.select_report[currentLangKey]}
                </Modal.Title>
                <Modal.IconClose onClick={handleCloseModal} />
              </Modal.Header>

              <Modal.Content>
                <Container>
                  {!checkIfSelectedExpensesCurrencyIsDifferentFromReportCurrency &&
                    checkIfSomeSelectedExpensesCostsCenterUuidIsDifferentFromReportCostsCenterUuid &&
                    companyApprovalType === 'CC' && (
                      <AnimatePresence>
                        <motion.div {...animations.fadeInOut}>
                          <Alert
                            type='warning'
                            title={t(
                              'allSelectedExpensesWillHaveTheirCostCentersChanged',
                              {
                                costsCenter: selectedReportCostsCenterName
                              }
                            )}
                          >
                            <ReportInformationList>
                              {expensesWithDifferentCostsCenter.map(expense => (
                                <li key={expense.uuid}>{expense.title}</li>
                              ))}
                            </ReportInformationList>
                          </Alert>
                        </motion.div>
                      </AnimatePresence>
                    )}

                  {checkIfSelectedExpensesCurrencyIsDifferentFromReportCurrency && (
                    <AnimatePresence>
                      <motion.div {...animations.fadeInOut}>
                        <Alert
                          type='error'
                          title={t('currencySelectionReportAndExpenses', {
                            currency: reportCurrencyIsoCode
                          })}
                          actionText={lang.global.edit[currentLangKey]}
                        />
                      </motion.div>
                    </AnimatePresence>
                  )}

                  <SearchSelectReportContainer>
                    <TextInput.Default
                      label={lang.global.search[currentLangKey]}
                      onChangeValue={handleChangeSearchTerm}
                      value={searchTerm}
                      iconLeft='SearchIcon'
                      placeholder={
                        lang.expenses.modal.create_manual_expense
                          .search_by_report_id_or_title[currentLangKey]
                      }
                    />

                    <SelectReportContainer>
                      <div>
                        {isLoadingOpenReports && (
                          <SkeletonLoader
                            height='7rem'
                            rows={3}
                            multipleLoadersContainerClassName='multiple-loaders-container'
                          />
                        )}

                        {!isLoadingOpenReports &&
                          filteredOpenReports.length === 0 &&
                          searchTerm !== '' && (
                            <ReportNotFoundContainer>
                              <img src={reportNotFoundImg} />
                              <p>
                                {
                                  lang.expenses.modal.report_not_found[
                                    currentLangKey
                                  ]
                                }
                                <br />
                                {t(
                                  'pleaseCheckIfTheDataIsEnteredCorrectlyAndTryAgain'
                                )}
                              </p>
                            </ReportNotFoundContainer>
                          )}

                        {!isLoadingOpenReports &&
                          filteredOpenReports.length === 0 &&
                          searchTerm === '' && (
                            <ReportNotFoundContainer>
                              <img src={reportNoItemsImg} />
                              <p>
                                {t(
                                  'atTheMomentYouDoNotHaveAnyReportsAvailableToSelect'
                                )}
                                <br />
                                {t('clickOnTheButtonBelowCreateReport')}
                              </p>
                            </ReportNotFoundContainer>
                          )}

                        {!isLoadingOpenReports &&
                          filteredOpenReports.length > 0 &&
                          filteredOpenReports.map(report => (
                            <CustomRadioItem.Default.Container
                              key={report.uuid}
                              name='report'
                              inputId={report.uuid + '-radio'}
                              className='report-radio-item'
                              checked={selectedReport === report.uuid}
                              onClick={() => {
                                setSelectedReport(report.uuid);
                              }}
                            >
                              <CustomRadioItem.Default.Title
                                htmlFor={report.uuid + '-radio'}
                              >
                                #{report.uuid.slice(0, 6)} - {report.title}
                              </CustomRadioItem.Default.Title>
                              <CustomRadioItem.Default.Subtitle>
                                <ReportItemSubtitle>
                                  <div>
                                    <DSIcons.ShoppingListReceipt3Icon />
                                    {report.amountOfExpenses}{' '}
                                    {lang.global.expenses[currentLangKey]}
                                  </div>
                                  <div>
                                    <DSIcons.CalendarDateIcon />
                                    {
                                      lang.global.created_at[currentLangKey]
                                    }{' '}
                                    {CustomDate.formatFromISO(report.createdAt)}
                                  </div>
                                  <div>
                                    <DSIcons.CurrencyDollarMediunCircleIcon />{' '}
                                    {Currency.format(
                                      report.currency.isoCode ?? 'BRL',
                                      report.totalAmountOfExpenses,
                                      report.totalAmountOfExpenses > 0
                                    )}
                                  </div>
                                </ReportItemSubtitle>
                              </CustomRadioItem.Default.Subtitle>
                            </CustomRadioItem.Default.Container>
                          ))}
                      </div>
                    </SelectReportContainer>
                  </SearchSelectReportContainer>
                </Container>
              </Modal.Content>

              <Modal.Footer>
                <ButtonContainer>
                  <SecondaryButton
                    size='small'
                    onClick={handleCloseModal}
                  >
                    {lang.global.cancel[currentLangKey]}
                  </SecondaryButton>

                  <div>
                    {selectedReport && (
                      <DangerButton
                        size='small'
                        onClick={() => {
                          setSelectedReport('');
                        }}
                      >
                        {lang.global.clear_selection[currentLangKey]}
                      </DangerButton>
                    )}

                    <DefaultButton
                      size='small'
                      onClick={handleShowCreateReportFormOrLinkExpensesToReport}
                      disabled={
                        !allowCreateReport &&
                        (!selectedReport ||
                          checkIfSelectedExpensesCurrencyIsDifferentFromReportCurrency)
                      }
                    >
                      {allowCreateReport
                        ? lang.expenses.modal.create_report[currentLangKey]
                        : lang.expenses.modal.link_expenses[currentLangKey]}
                    </DefaultButton>
                  </div>
                </ButtonContainer>
              </Modal.Footer>
            </ContainerWithMotion>
          </AnimatePresence>
        )}
      </Modal.Container>
    </>
  );
}
