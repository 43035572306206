class Lang {
  putValuesInString(str: string, values: Record<string, string>): string {
    let result = str;
    for (const key in values) {
      result = result.replaceAll(`{{${key}}}`, values[key]);
    }
    return result;
  }
}

export default new Lang();
