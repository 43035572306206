import { useQueryCache } from 'data/cache';
import {
  AccountQueryKeys,
  AccountService,
  type IUseGetCardDataByAccountId,
  type IUseGetCardDataByAccountIdParams
} from 'data/modules/cards/account';

export function useGetCardDataByAccountId({
  accountId,
  enabled
}: IUseGetCardDataByAccountIdParams): IUseGetCardDataByAccountId {
  const { data, isFetching } = useQueryCache({
    queryKey: [AccountQueryKeys.GET_CARD_DATA_BY_ACCOUNT_ID, { accountId }],
    queryFn: async () =>
      await AccountService.getCardDataByAccountId({ accountId }),
    enabled
  });

  return {
    cardData: data,
    isFetchingCardData: isFetching
  };
}
