import {
  type IFuelPolicies,
  type IFuelPoliciesPersistence
} from 'data/modules/fuel/types/fuel.types';

class UpdateFuelPoliciesMapper {
  toPersistence(payload: IFuelPolicies): IFuelPoliciesPersistence {
    return {
      blocking_card: payload.blockingCard,
      blocking_expenses_quantity: payload.blockingExpensesQuantity,
      limit_amount: payload.limitAmount,
      limit_type: payload.limitType,
      required_fields: payload.requiredFields
    };
  }
}

export default new UpdateFuelPoliciesMapper();
