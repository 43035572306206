import styled, { css } from 'styled-components';

import { type DsTextInputStylesType } from './DsTextInput.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<AddPrefixToType<DsTextInputStylesType>>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: ${({ $hasValue }) => ($hasValue ? 'flex-start' : 'center')};
  flex-direction: ${({ $hasValue }) => ($hasValue ? 'column' : 'row')};
  height: 6.4rem;
  min-width: 25rem;
  width: 100%;
  padding: ${({ theme }) => theme.tokens.spacingInsetXs};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusMd};
  background-color: ${({ theme }) => theme.tokens.neutralColorHighMedium};
  border: ${({ theme }) => theme.tokens.borderWidthThin} solid transparent;

  &:focus-within {
    ${({ $error }) =>
      !$error &&
      css`
        background-color: ${({ theme }) => theme.tokens.neutralColorHighPure};
        border-color: ${({ theme }) => theme.tokens.brandColorPrimaryPure};
      `}
  }

  ${({ $error }) =>
    $error &&
    css`
      background-color: ${({ theme }) => theme.tokens.feedbackColorErrorLight};
      border-color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
    `}

  ${({ $underlined }) =>
    $underlined &&
    css`
      background-color: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-color: ${({ theme }) => theme.tokens.neutralColorLowLight};
      border-radius: ${({ theme }) => theme.tokens.borderRadiusNone};
    `}

  ${({ $error, $underlined, $hasValue }) =>
    $error &&
    $hasValue &&
    $underlined &&
    css`
      background-color: ${({ theme }) => theme.tokens.feedbackColorErrorLight};
      border-right: none;
      border-color: ${({ theme }) => theme.tokens.feedbackColorErrorPurest};
      border-radius: ${({ theme }) => theme.tokens.borderRadiusNone};
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${({ theme }) => theme.tokens.neutralColorHighMedium};
      opacity: 0.5;
      cursor: not-allowed;

      input {
        cursor: not-allowed;
      }
    `}
`;

export const Label = styled.label<DsTextInputStylesType>`
  color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.tokens.neutralColorLowDark};
    `}
`;

export const InputContainer = styled.div<DsTextInputStylesType>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      svg {
        fill: ${({ theme }) => theme.tokens.neutralColorLowLightest};
      }
    `}

  input {
    height: 100%;
    width: 90%;
    background: transparent;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.tokens.neutralColorLowPure};
    font-size: ${({ theme }) => theme.tokens.fontSizeXxs};
    font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
    font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
    margin: 0;
    padding: 0;

    ${({ error }) =>
      error &&
      css`
        &::placeholder {
          color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
        }
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${({ theme }) => theme.tokens.neutralColorLowDark};

        &::placeholder {
          color: ${({ theme }) => theme.tokens.neutralColorLowPure};
        }
      `}
  }
`;

export const CleanButton = styled.button<
  AddPrefixToType<DsTextInputStylesType>
>`
  position: absolute;
  right: 1.6rem;
  top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${({ $hasValue }) => !$hasValue && 'hidden'};
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  ${({ $error }) =>
    $error &&
    css`
      svg {
        fill: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;

      svg {
        fill: ${({ theme }) => theme.tokens.neutralColorLowLightest};
      }
    `}
`;

export const ErrorMessage = styled.div<DsTextInputStylesType>`
  color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  word-break: break-all;
  margin-top: 0.5rem;
  margin-left: 0.3rem;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;
