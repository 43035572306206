import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import {
  type ICreateFuelExpenseFirstStepFieldsForm,
  type IObservationAttachment,
  type IUseFirstStepFormSchemaParams
} from 'data/modules/expenses/types/expenses.types';
import { useGetExpenseParameters } from 'data/modules/expenses/useCases/get-expense-parameters/useGetExpenseParameters';

import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';
import { Validate } from 'shared/utils/validation';

export function useFirstStepFormSchema({
  userAction
}: IUseFirstStepFormSchemaParams): ObjectSchema<ICreateFuelExpenseFirstStepFieldsForm> {
  const { t } = useTranslation(['expenses', 'schemas']);

  const { expenseParameters } = useGetExpenseParameters();

  const createFuelExpenseFirstStepSchema: ObjectSchema<ICreateFuelExpenseFirstStepFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = t('schemas:isRequired');
      const fieldInvalidMessage = t('schemas:invalidValue');

      return yup.object({
        // se o parâmetro preferencias_descricaoDespesaObrigatoria for true, então o campo description é obrigatório
        description: expenseParameters?.isExpenseDescriptionMandatory
          ? yup.string().required(fieldRequiredMessage)
          : yup.string().defined(),
        currency: yup.string().required(fieldRequiredMessage),
        value: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'notANumber',
            message: fieldInvalidMessage,
            test: value => {
              const numberValue = Currency.parseToFloat(value);
              return !isNaN(numberValue);
            }
          }),
        licensePlate: expenseParameters?.fuelsPolicies?.isPlateMandatory
          ? yup.string().required(fieldRequiredMessage)
          : yup.string().defined(),
        vehicleType: expenseParameters?.fuelsPolicies?.isTypeMandatory
          ? yup.string().required(fieldRequiredMessage)
          : yup.string().defined(),
        quantityOfLiters: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'notANumber',
            message: fieldInvalidMessage,
            test: value => {
              const numberValue = Currency.parseToFloat(value);
              return !isNaN(numberValue);
            }
          }),
        pricePerLiter: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'notANumber',
            message: fieldInvalidMessage,
            test: value => {
              const numberValue = Currency.parseToFloat(value);
              return !isNaN(numberValue);
            }
          }),
        fuelType: yup
          .object({
            value: yup.string(),
            label: yup.string()
          })
          .test({
            name: 'costsCenterRequired',
            message: fieldRequiredMessage,
            test: Validate.isOptionSelected
          })
          .nullable(),
        date: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'date',
            message: t('schemas:invalidDate'),
            test: (value: string) => Validate.isDate(value, 'yyyy-MM-dd')
          })
          .test(
            'validate-future-days',
            (value: string, { createError, path }) => {
              const expensesPolicyType = expenseParameters?.policyType;

              if (expensesPolicyType === 'USUARIO' && userAction === 'create') {
                const amountOfFutureDaysThatIsAllowToCreateExpense =
                  expenseParameters?.daysPolicy.futureDays;

                if (!amountOfFutureDaysThatIsAllowToCreateExpense) {
                  return true;
                }

                const todayIsoString = CustomDate.formatDateToIso(new Date());
                const selectedDate = CustomDate.parseIsoToDate(value);

                const differenceBetweenDates =
                  CustomDate.differenceBetweenDates(
                    CustomDate.parseIsoToDate(todayIsoString),
                    selectedDate
                  );

                if (
                  differenceBetweenDates <
                  -amountOfFutureDaysThatIsAllowToCreateExpense
                ) {
                  const exceedInDays =
                    Math.abs(differenceBetweenDates) -
                    amountOfFutureDaysThatIsAllowToCreateExpense;

                  return createError({
                    path,
                    message: t(
                      'errors.expenseExceedsTheMaximumAmountOfFutureDays',
                      {
                        days: exceedInDays
                      }
                    )
                  });
                }
              }

              return true;
            }
          )
          .test(
            'validate-past-days',
            (value: string, { createError, path }) => {
              const expensesPolicyType = expenseParameters?.policyType;

              if (expensesPolicyType === 'USUARIO' && userAction === 'create') {
                const amountOfPastDaysThatIsAllowToCreateExpense =
                  expenseParameters?.daysPolicy.pastDays;

                if (!amountOfPastDaysThatIsAllowToCreateExpense) {
                  return true;
                }

                const todayIsoString = CustomDate.formatDateToIso(new Date());
                const selectedDate = CustomDate.parseIsoToDate(value);

                const differenceBetweenDates =
                  CustomDate.differenceBetweenDates(
                    CustomDate.parseIsoToDate(todayIsoString),
                    selectedDate
                  );

                if (
                  differenceBetweenDates >
                  amountOfPastDaysThatIsAllowToCreateExpense
                ) {
                  const exceedInDays =
                    Math.abs(differenceBetweenDates) -
                    amountOfPastDaysThatIsAllowToCreateExpense;

                  return createError({
                    path,
                    message: t(
                      'errors.expenseExceedsTheMaximumAmountOfDaysPassed',
                      {
                        days: exceedInDays
                      }
                    )
                  });
                }
              }

              return true;
            }
          ),
        // se o parâmetro preferencias_formaPagamentoObrigatoria for true, então o campo paymentMethod é obrigatório
        paymentMethod: expenseParameters?.isPaymentMethodMandatory
          ? yup.string().required(fieldRequiredMessage)
          : yup.string().defined(),
        costsCenter: yup
          .object({
            value: yup.string(),
            label: yup.string()
          })
          .test({
            name: 'costsCenterRequired',
            message: fieldRequiredMessage,
            test: Validate.isOptionSelected
          }),
        odometerImage: yup
          .mixed<File | string>()
          .test({
            name: 'odometerImageSize',
            message: t('schemas:fileMustBeLessThan16mb'),
            test: value => {
              if (value && value instanceof File) {
                return value?.size <= 16000000;
              }

              return true;
            }
          })
          .defined()
          .nullable(),
        mileage: expenseParameters?.fuelsPolicies?.isKilometrageMandatory
          ? yup.string().required(fieldRequiredMessage).test({
              name: 'notANumber',
              message: fieldInvalidMessage,
              test: Validate.isInteger
            })
          : yup
              .string()
              .defined()
              .test({
                name: 'notANumber',
                message: fieldInvalidMessage,
                test: value => {
                  if (value !== '') {
                    return Validate.isInteger(value);
                  }

                  return true;
                }
              }),
        refundable: yup.boolean().required(fieldRequiredMessage),
        observation: expenseParameters?.isExpensesObsMandatory
          ? yup.string().required(fieldRequiredMessage)
          : yup.string().defined(),
        observationAttachments: yup
          .mixed<(File | IObservationAttachment)[]>()
          .test({
            name: 'observationAttachmentsSize',
            message: t('schemas:fileMustBeLessThan16mb'),
            test: value => {
              if (value && Array.isArray(value)) {
                const validateFiles = Array.from(value).filter(
                  file => file instanceof File
                );

                if (validateFiles.length === 0) {
                  return true;
                }

                return validateFiles.every(
                  file => (file as File).size <= 16000000
                );
              }

              return true;
            }
          }),
        receipt: yup
          .mixed<File | string>()
          .test({
            name: 'receiptSize',
            message: t('schemas:fileMustBeLessThan16mb'),
            test: value => {
              if (value && value instanceof File) {
                return value?.size <= 16000000;
              }

              return true;
            }
          })
          .defined()
          .nullable(),
        apportionment: yup.array().of(
          yup.object({
            project: yup
              .object({
                value: yup.string(),
                label: yup.string()
              })
              .test({
                name: 'projectRequired',
                message: fieldRequiredMessage,
                test: Validate.isOptionSelected
              }),
            percentage: yup.number().required(fieldRequiredMessage),
            value: yup.number().required(fieldRequiredMessage)
          })
        )
      });
    }, [expenseParameters, t, userAction]);

  return createFuelExpenseFirstStepSchema;
}
