import { useQueryCache } from 'data/cache';
import {
  CardGroupsQueryKeys,
  CardGroupsService,
  type IUseGetPartialManagers,
  type IUseGetPartialManagersParams
} from 'data/modules/cards/cardGroups';

export function useGetPartialManagers({
  enabled
}: IUseGetPartialManagersParams): IUseGetPartialManagers {
  const { data, isError, isFetching } = useQueryCache({
    queryKey: [CardGroupsQueryKeys.GET_PARTIAL_MANAGERS],
    queryFn: async () => await CardGroupsService.getPartialManagers(),
    enabled
  });

  return {
    partialManagers: data,
    isGetPartialManagersErrro: isError,
    isGetPartialManagersFetching: isFetching
  };
}
