import { Pagination } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -1.5rem;
`;

export const StyledPagination = styled(Pagination.Container)`
  flex-wrap: wrap;
  row-gap: 2rem;

  svg {
    min-width: fit-content;
  }
`;

export const StyledControl = styled(Pagination.Control)`
  width: 100%;
  gap: 2.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: fit-content;
    justify-content: center;
  }
`;
