import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IResendLegalRepresentativesApprovalSolicitationPayload,
  type IUseResendLegalRepresentativesApprovalSolicitation,
  type IUseResendLegalRepresentativesApprovalSolicitationParams
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';
import { CustomDate } from 'shared/utils/custom';

export function useResendLegalRepresentativesApprovalSolicitation(
  params?: IUseResendLegalRepresentativesApprovalSolicitationParams
): IUseResendLegalRepresentativesApprovalSolicitation {
  const [currentLangKey, errorMessage] = useLangContext(state => [
    state.currentLangKey,
    state.lang.cards.onboarding.company.approval_representatives
      .resend_legal_representative_error
  ]);

  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const cachedOnboardingCompanyId =
    cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '';

  const { data: cachedRepresentatives, updateCache } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_LEGAL_REPRESENTATIVES,
        cachedCompanyOnboardingProgress?.onboardingCompanyId as string
      ],
      query: async () =>
        await CompanyOnboardingService.getLegalRepresentatives({
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId as string
        })
    },
    params: {
      uuidCompanyOnboarding: cachedOnboardingCompanyId
    }
  });

  const {
    isLoading: isResendingLegalRepresentativesApprovalSolicitation,
    mutate
  } = useMutationCache({
    mutationKey: [
      CompanyOnboardingMutationKeys.RESEND_LEGAL_REPRESENTATIVES_APPROVAL_SOLICITATION
    ],
    mutationFn: async (
      payload: IResendLegalRepresentativesApprovalSolicitationPayload
    ) =>
      await CompanyOnboardingService.resendLegalRepresentativesApprovalSolicitation(
        payload
      ),
    onSuccess: (data, { uuid }) => {
      updateCache({
        newData:
          cachedRepresentatives?.map(representative => {
            if (representative.id === uuid) {
              return {
                ...representative,
                emailSentAt: new Date()
              };
            }

            return representative;
          }) ?? []
      });
      params?.onResendLegalRepresentativesApprovalSolicitationMutationSuccess?.(
        uuid
      );
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  async function handleResendLegalRepresentativesApprovalSolicitation(
    uuid: string
  ): Promise<void> {
    if (isResendingLegalRepresentativesApprovalSolicitation) return;

    const cachedRepresentative = cachedRepresentatives?.find(
      cachedRepresentative => cachedRepresentative.id === uuid
    );

    if (!CustomDate.isDateOlderThanHours(cachedRepresentative?.emailSentAt)) {
      toast.error(errorMessage[currentLangKey]);

      return;
    }

    mutate({
      uuid,
      uuidCompanyOnboarding: cachedOnboardingCompanyId
    });
  }

  return {
    isResendingLegalRepresentativesApprovalSolicitation,
    handleResendLegalRepresentativesApprovalSolicitation
  };
}
