import { SettingsSidebarContainer } from './SettingsSidebarContainer/SettingsSidebarContainer.component';
import { SettingsSidebarGroup } from './SettingsSidebarGroup/SettingsSidebarGroup.component';
import { SettingsSidebarItem } from './SettingsSidebarItem/SettingsSidebarItem.component';
import { SettingsSidebarSubItems } from './SettingsSidebarSubItems/SettingsSidebarSubItems.component';

export const SettingSidebar = {
  Container: SettingsSidebarContainer,
  Item: SettingsSidebarItem,
  Group: SettingsSidebarGroup,
  SubItems: SettingsSidebarSubItems
};
