import DataLoader from 'dataloader';

import { useQueryCache } from 'data/cache';
import {
  ExpensesQueryKeys,
  ExpensesService,
  type ICardsExpense,
  type IUseGetExpense,
  type IUseGetExpenseParams
} from 'data/modules/expenses';

export function useGetExpense({
  transactionId,
  enabled
}: IUseGetExpenseParams): IUseGetExpense {
  const expensesListByTransactionIdsLoader = new DataLoader(
    async (
      transactionsIds: readonly string[]
    ): Promise<(ICardsExpense | null)[]> => {
      const transactionsIdsChunksSize = 10;
      const transactionsIdsChunks = [];

      // Separa os ids em chunks de 10 ids
      for (
        let i = 0;
        i < transactionsIds.length;
        i += transactionsIdsChunksSize
      ) {
        const chunk = transactionsIds.slice(i, i + transactionsIdsChunksSize);
        if (chunk.length > 0) transactionsIdsChunks.push(chunk);
      }

      // Executa uma query a cada chunk de ids
      const expensesListByTransactionsIdsChunks = await Promise.all(
        transactionsIdsChunks.map(async chunk => {
          const expenses =
            await ExpensesService.getExpensesListByTransactionsIds({
              transactionsIds: chunk
            });
          return expenses;
        })
      );

      const expensesList = expensesListByTransactionsIdsChunks.reduce(
        (acc, expenses) => [...acc, ...expenses],
        []
      );

      return transactionsIds.map(
        transactionId =>
          expensesList.find(
            expense => expense.transactionId === transactionId
          ) ?? null
      );
    },
    {
      cache: false
    }
  );

  const { data, isLoading, isFetching, refetch, isError } = useQueryCache({
    queryKey: [ExpensesQueryKeys.GET_EXPENSES_LIST, transactionId],
    queryFn: async () =>
      await expensesListByTransactionIdsLoader.load(transactionId),
    enabled
  });

  return {
    expense: data,
    isLoadingExpense: isLoading,
    isErrorExpense: isError,
    isFetchingExpense: isFetching,
    refetchExpense: refetch
  };
}
