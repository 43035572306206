import { tokens } from 'ds';
import styled from 'styled-components';

export const Content = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
  color: ${tokens.neutralColorLowDark};
`;

export const Header = styled.div`
  #header {
    align-items: start;
  }

  h3 {
    text-align: left;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  gap: ${tokens.spacingXxl};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  align-items: start;
  justify-content: end;
  gap: ${tokens.spacingMd};
`;
