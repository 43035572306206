import { faker } from '@faker-js/faker';

import {
  type AccountHolderSubtypeType,
  type IAccountHolder
} from 'data/modules/cards/accountHolders';
import { type KYCStatusType } from 'data/modules/cards/companyOnboarding';

import { businessTypes } from 'shared/constants/company';
import { MockApi } from 'shared/utils/global';

import {
  accountHolderSubtypeOptions,
  kycStatusOptions,
  swapTypeOptions
} from '..';

export async function updateAccountHolderMock(): Promise<IAccountHolder> {
  await MockApi.sleep(2000);

  return {
    id: faker.string.uuid(),
    companyId: faker.string.uuid(),
    legalName: faker.company.name(),
    tradingName: faker.company.name(),
    name: faker.company.name(),
    businessType: faker.helpers.arrayElement(businessTypes),
    documentNumber: '71504592000127',
    email: faker.internet.email(),
    phoneNumber: faker.string.numeric(11),
    country: 'BR',
    state: 'SP',
    city: faker.location.city(),
    street: faker.location.street(),
    postalCode: '01153000',
    number: faker.location.buildingNumber(),
    complement: null,
    neighborhood: 'Teste',
    foundingDate: faker.date.past().toString(),
    mainActivity: 'Teste',
    website: null,
    shareholders: [
      {
        id: faker.string.uuid(),
        name: faker.person.fullName(),
        document: '45671232040',
        email: faker.internet.email(),
        phoneNumber: faker.string.numeric(11),
        motherName: faker.person.fullName(),
        birthDate: faker.date.past({ years: 30 }).toString(),
        street: faker.location.street(),
        number: faker.location.buildingNumber(),
        neighborhood: 'Teste',
        complement: null,
        city: faker.location.city(),
        state: 'SP',
        country: 'BR',
        postalCode: '01153000',
        swapType: faker.helpers.arrayElement(swapTypeOptions)
      }
    ],
    type: 'Teste',
    subtype: faker.helpers.arrayElement(
      accountHolderSubtypeOptions
    ) as AccountHolderSubtypeType,
    kycStatus: faker.helpers.arrayElement(kycStatusOptions) as KYCStatusType
  };
}
