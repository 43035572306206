import { useQueryCache } from 'data/cache';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUseGetCompanyOnboardingConfigurationUsersCache
} from 'data/modules/cards/companyOnboarding';

export function useGetCompanyOnboardingConfigurationUsersCache(): IUseGetCompanyOnboardingConfigurationUsersCache {
  const { data, isLoading } = useQueryCache({
    queryKey: [
      CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_CONFIGURATIONS_USERS_CACHE
    ],
    queryFn: async () =>
      await CompanyOnboardingService.getCompanyOnboardingConfigurationUsersCache()
  });

  return {
    companyOnboardingConfigurationUsersCache: data ?? [],
    isCompanyOnboardingConfigurationUsersCacheLoading: isLoading
  };
}
