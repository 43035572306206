import { useQueryCache } from 'data/cache';
import {
  CardGroupsQueryKeys,
  CardGroupsService,
  type IUseGetCardGroups,
  type IUseGetCardGroupsParams
} from 'data/modules/cards/cardGroups';

import { CustomObject } from 'shared/utils/custom';

export function useGetCardGroups(
  params: IUseGetCardGroupsParams
): IUseGetCardGroups {
  const {
    data: paginatedCardGroups,
    isFetching: isFetchingCardGroups,
    isSuccess: isSuccessGetCardGroups,
    isInitialLoading: isGetCardGroupsInitialLoading
  } = useQueryCache({
    queryKey: [
      CardGroupsQueryKeys.GET_CARD_GROUPS,
      CustomObject.omit(params, ['enabled'])
    ],
    queryFn: async () =>
      await CardGroupsService.getCardGroups(
        CustomObject.omit(params, ['enabled'])
      ),
    enabled: params.enabled
  });

  return {
    paginatedCardGroups,
    isFetchingCardGroups,
    isSuccessGetCardGroups,
    isGetCardGroupsInitialLoading
  };
}
