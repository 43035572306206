import { Accordion, Alert, DefaultButton, DSIcons, Modal } from 'ds';
import QRCode from 'react-qr-code';

import { useLangContext, useModalContext } from 'data/contexts';

import { useDepositModal } from './useDepositModal';

import {
  AccordionContentContainer,
  AccordionItemContainer,
  ContainerLoader,
  IconTitleContainer,
  LabelAboveButton,
  LoadingSpin,
  QRCodeContainer
} from './DepositModal.styles';

export function DepositModal(): JSX.Element {
  const { handleCloseModal } = useModalContext();

  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const {
    data,
    hasQrCode,
    onClick,
    setIsCopied,
    setHasQrCode,
    isLoading,
    setClickedOnce,
    isCopied,
    isFetchingPixQrCode
  } = useDepositModal();

  return (
    <Modal.Container
      onClose={() => {
        handleCloseModal();
        setIsCopied(false);
        setHasQrCode(false);
        setClickedOnce(false);
      }}
    >
      <Modal.Header>
        <Modal.Title>{lang.cards.deposit.deposit[currentLangKey]}</Modal.Title>

        <Modal.IconClose
          onClick={() => {
            handleCloseModal();
            setIsCopied(false);
            setHasQrCode(false);
            setClickedOnce(false);
          }}
        />
      </Modal.Header>

      <Modal.Content>
        <Accordion.Container>
          <AccordionItemContainer isOpen>
            <Accordion.Label>
              <IconTitleContainer>
                <DSIcons.PixIcon />
                PIX
              </IconTitleContainer>
            </Accordion.Label>

            <AccordionContentContainer>
              {!data?.emv || !hasQrCode ? (
                <LabelAboveButton>
                  <p>
                    {lang.cards.deposit.create_an[currentLangKey]}
                    {lang.cards.deposit.qr_code_pix[currentLangKey]}
                    {lang.cards.deposit.copy_and_paste_deposit[currentLangKey]}
                  </p>
                </LabelAboveButton>
              ) : (
                <Alert type='info'>
                  {lang.cards.deposit.beneficiary[currentLangKey]}{' '}
                  <strong>SWAP MEIOS DE PAGAMENTOS S.A</strong>
                </Alert>
              )}

              {(isLoading || data?.emv) && hasQrCode && (
                <QRCodeContainer>
                  {isLoading && (
                    <ContainerLoader>
                      <LoadingSpin />
                    </ContainerLoader>
                  )}

                  <QRCode
                    size={256}
                    value={isLoading ? '' : data?.emv ? data.emv : ''}
                    viewBox={`0 0 256 256`}
                  />
                </QRCodeContainer>
              )}

              <DefaultButton
                color='secondary'
                onClick={onClick}
                size='small'
              >
                {isCopied && !isLoading ? (
                  <DSIcons.CheckIcon />
                ) : hasQrCode && !isCopied && !isLoading ? (
                  <DSIcons.FileCopyIcon />
                ) : undefined}

                {hasQrCode && !isLoading
                  ? lang.cards.deposit.copy_qr_code[currentLangKey]
                  : !isFetchingPixQrCode
                    ? lang.cards.deposit.generate_qr_code[currentLangKey]
                    : lang.cards.deposit.generating_pix_key[currentLangKey]}
              </DefaultButton>

              <Alert type='info'>
                {lang.cards.deposit.alert_qr_code_modal[currentLangKey]}
              </Alert>
            </AccordionContentContainer>
          </AccordionItemContainer>

          <Accordion.Item>
            <Accordion.Label>
              <IconTitleContainer>
                <DSIcons.ArrowsIcon />
                TED
              </IconTitleContainer>
            </Accordion.Label>
            <AccordionContentContainer>
              <Alert type='info'>
                {lang.cards.deposit.alert_ted_modal[currentLangKey]}
              </Alert>

              <p>
                <b>{lang.cards.global.bank[currentLangKey]}:</b> 033 (Santander)
              </p>

              <p>
                <b>{lang.cards.global.agency[currentLangKey]}:</b> 0019
              </p>

              <p>
                <b>{lang.cards.global.current_acount[currentLangKey]}:</b>{' '}
                13012720-6
              </p>

              <p>
                <b>{lang.forms.cnpj[currentLangKey]}:</b> 07.784.024/0001-28
              </p>
            </AccordionContentContainer>
          </Accordion.Item>
        </Accordion.Container>
      </Modal.Content>
    </Modal.Container>
  );
}
