import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  type ICreateManualExpenseFirstStepFieldsForm,
  type ICreateRouteByMapDefaultFieldsForm,
  type ICreateRouteExpenseFirstStepFieldsForm,
  type IValidateDateLimitsByProjectPayload,
  useGetExpenseParameters,
  useValidateDateLimitsByProject
} from 'data/modules/expenses';

import { Currency } from 'shared/utils/format';
import { Validate } from 'shared/utils/validation';

import {
  type IUseApportionmentBlockForm,
  type IUseApportionmentBlockFormParams
} from './ApportionmentBlockForm.types';

export function useApportionmentBlockForm({
  onHideApportionmentBlock,
  expenseUuidToUpdate
}: IUseApportionmentBlockFormParams): IUseApportionmentBlockForm {
  const { control, setValue, getValues, setError, clearErrors } =
    useFormContext<
      | ICreateManualExpenseFirstStepFieldsForm
      | ICreateRouteExpenseFirstStepFieldsForm
      | ICreateRouteByMapDefaultFieldsForm
    >();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'apportionment'
  });

  const { t } = useTranslation('expenses');

  const { expenseParameters } = useGetExpenseParameters();

  const { getEnsuredValidateDateLimitsByProject } =
    useValidateDateLimitsByProject();

  function handleAddNewProject(): void {
    // transforma o valor do campo value em float
    const expenseValue = getValues('value');
    const expenseValueAsNumber = Currency.parseToFloat(expenseValue);

    // a porcentagem de cada projeto vai passar a ser 100 dividido pela quantidade de projetos + 1
    const calculatedPercentage = 100 / (fields.length + 1);
    const percentageForExistingProjects =
      Currency.roundToTwoDecimals(calculatedPercentage);

    // o valor de cada projeto vai passar a ser o valor da despesa dividido pela quantidade de projetos + 1
    const calculatedValue = expenseValueAsNumber / (fields.length + 1);
    const valueForExistingProjects =
      Currency.roundToTwoDecimals(calculatedValue);

    // para cada projeto, o valor e a porcentagem são os resultados do cálculo acima
    for (let i = 0; i < fields.length; i++) {
      setValue(`apportionment.${i}.percentage`, percentageForExistingProjects);
      setValue(`apportionment.${i}.value`, valueForExistingProjects);
    }

    // para o último projeto, a porcentagem é o valor da despesa menos a soma das porcentagens dos outros projetos
    // isso é necessário para que a soma das porcentagens seja 100% e não tenha diferença de arredondamento
    const percentageDiff = percentageForExistingProjects * fields.length;
    const percentageForNewProject = Currency.subtractTwoDecimals(
      100,
      percentageDiff
    );

    // para o último projeto, o valor é o valor da despesa menos a soma dos valores dos outros projetos
    // isso é necessário para que a soma dos valores seja o valor da despesa e não tenha diferença de arredondamento
    const valueDiff = valueForExistingProjects * fields.length;

    const valueForNewProject = Currency.subtractTwoDecimals(
      expenseValueAsNumber,
      valueDiff
    );

    append({
      project: undefined,
      percentage: percentageForNewProject,
      value: valueForNewProject
    });
    checkIfDateIsValidByProjectPolicy();
  }

  async function checkIfDateIsValidByProjectPolicy(): Promise<void> {
    if (expenseParameters?.policyType !== 'PROJETO') {
      return;
    }

    const date = getValues('date');

    if (date === undefined || !Validate.isDate(date, 'yyyy-mm-dd')) {
      clearErrors('date');
      return;
    }

    const apportionment = getValues('apportionment');

    const filteredApportionment = apportionment
      ? apportionment.filter(item => item.project !== undefined)
      : [];

    if (filteredApportionment.length === 0) {
      clearErrors('date');
      return;
    }

    const policyByProjectQueryPayload: IValidateDateLimitsByProjectPayload = {
      date,
      projects: filteredApportionment.map(item => {
        return {
          id: item.project?.value as string,
          percentage: item.percentage
        };
      }),
      expenseUuid: expenseUuidToUpdate
    };

    const checkPolicyByProject = await getEnsuredValidateDateLimitsByProject(
      policyByProjectQueryPayload
    );

    if (checkPolicyByProject !== null && !checkPolicyByProject.success) {
      if (checkPolicyByProject.daysLimitAgo !== null) {
        setError('date', {
          type: 'validated-by-project',
          message: t('errors.expenseExceedsTheMaximumAmountOfDaysPassed', {
            days: checkPolicyByProject.daysLimitAgo
          })
        });
      }

      if (checkPolicyByProject.daysLimitAhead !== null) {
        setError('date', {
          type: 'validated-by-project',
          message: t('errors.expenseExceedsTheMaximumAmountOfFutureDays', {
            days: checkPolicyByProject.daysLimitAhead
          })
        });
      }

      return;
    }

    clearErrors('date');
  }

  function handleRemoveProject(index: number): void {
    if (fields.length === 1) {
      onHideApportionmentBlock();
      expenseParameters?.policyType === 'PROJETO' && clearErrors('date');
      return;
    }

    remove(index);

    const apportionmentValue = getValues('apportionment');
    const countExistingProjects = apportionmentValue?.length || 0;

    // apenas uma verificaçao para remover o undefined
    if (countExistingProjects === 0) {
      return;
    }

    // transforma o valor do campo value em float
    const expenseValue = getValues('value');
    const expenseValueAsNumber = Currency.parseToFloat(expenseValue);

    // se existir apenas um projeto, o valor é o valor da despesa e a porcentagem é 100%
    // só vai ter um elemento no array
    if (countExistingProjects === 1) {
      setValue(
        'apportionment',
        apportionmentValue?.map(item => {
          return {
            ...item,
            percentage: 100,
            value: expenseValueAsNumber
          };
        })
      );
      checkIfDateIsValidByProjectPolicy();
      return;
    }

    // se existir mais de um projeto, a porcentagem é 100 dividido pela quantidade de projetos
    const calculatedPercentage = 100 / countExistingProjects;
    const percentageForExistingProjects =
      Currency.roundToTwoDecimals(calculatedPercentage);

    // se existir mais de um projeto, o valor é o valor da despesa dividido pela quantidade de projetos
    const calculatedValue = expenseValueAsNumber / countExistingProjects;
    const valueForExistingProjects =
      Currency.roundToTwoDecimals(calculatedValue);

    // para cada projeto, o valor e a porcentagem são os resultados do cálculo acima
    const newFields = apportionmentValue?.map(item => {
      return {
        ...item,
        percentage: percentageForExistingProjects,
        value: valueForExistingProjects
      };
    });

    // para o último projeto, a porcentagem é o valor da despesa menos a soma das porcentagens dos outros projetos
    // isso é necessário para que a soma das porcentagens seja 100% e não tenha diferença de arredondamento
    const percentageDiff =
      percentageForExistingProjects * (countExistingProjects - 1);

    const percentageForLastProject = Currency.subtractTwoDecimals(
      100,
      percentageDiff
    );

    // para o último projeto, o valor é o valor da despesa menos a soma dos valores dos outros projetos
    // isso é necessário para que a soma dos valores seja o valor da despesa e não tenha diferença de arredondamento
    const valueDiff = valueForExistingProjects * (countExistingProjects - 1);

    const valueForLastProject = Currency.subtractTwoDecimals(
      expenseValueAsNumber,
      valueDiff
    );

    // aqui nunca vai ser undefined, mas o typescript não sabe disso
    if (newFields) {
      // coloca os valores calculados para o último projeto
      newFields[countExistingProjects - 1].percentage =
        percentageForLastProject;
      newFields[countExistingProjects - 1].value = valueForLastProject;
    }

    setValue('apportionment', newFields);
    checkIfDateIsValidByProjectPolicy();
  }

  return {
    fields,
    handleAddNewProject,
    handleRemoveProject
  };
}
