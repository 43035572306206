import { type IVehicle, type IVehiclePersistence } from 'data/modules/fuel';

class GetVehicleMapper {
  toDomain(payload: IVehiclePersistence): IVehicle {
    return {
      id: payload.id,
      fuels: payload.fuels.map(fuel => ({
        id: fuel.id,
        description: fuel.description
      })),
      companyId: payload.company_id,
      vehicleType: {
        id: payload.vehicle_type.id,
        description: payload.vehicle_type.description
      },
      isActive: payload.is_active,
      model: payload.model,
      plate: payload.plate,
      owner: payload.owner,
      registrationDate: payload.created_at
    };
  }
}

export default new GetVehicleMapper();
