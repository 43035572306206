import { useQueryClient } from '@tanstack/react-query';

import { useMutationCache } from 'data/cache';
import {
  type ICompleteUserOnboardingPayload,
  type IUseCompleteUserOnboarding,
  type IUseCompleteUserOnboardingParams,
  type IUserOnboardingProgress,
  UserOnboardingMutationKeys,
  UserOnboardingQueryKeys,
  UserOnboardingService
} from 'data/modules/cards/userOnboarding';

export function useCompleteUserOnboarding({
  onNavigateToFinalStep
}: IUseCompleteUserOnboardingParams): IUseCompleteUserOnboarding {
  const { mutate } = useMutationCache({
    mutationKey: [UserOnboardingMutationKeys.COMPLETE_USER_ONBOARDING],
    mutationFn: async (payload: ICompleteUserOnboardingPayload) =>
      await UserOnboardingService.completeUserOnboarding(payload),
    onSuccess: () => {
      onNavigateToFinalStep();
    }
  });

  const queryClient = useQueryClient();

  const cachedOnboardingUserId =
    queryClient.getQueryData<IUserOnboardingProgress>([
      UserOnboardingQueryKeys.GET_USER_ONBOARDING_PROGRESS
    ])?.onboardingUserId as string;

  function completeUserOnboarding(): void {
    mutate({
      onboardingUserId: cachedOnboardingUserId
    });
  }

  return {
    completeUserOnboarding
  };
}
