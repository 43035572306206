import { useDsGa4 } from 'ds/hooks/globals';
import { Numeric } from 'ds/utils';

import { type ICurrencyInputDefaultProps } from './CurrencyInputDefault.types';

import {
  Caption,
  Container,
  Input,
  InputContainer,
  Label
} from './CurrencyInputDefault.styles';

export function CurrencyInputDefault({
  onChangeValue,
  onChangeOperation,
  operation,
  value,
  errorMessage,
  leftElement,
  rightElement,
  variant = 'medium',
  disabled,
  success,
  inputId,
  ...rest
}: ICurrencyInputDefaultProps): JSX.Element {
  if (!leftElement && !rightElement) {
    throw new Error(
      'CurrencyInputDefault: You must provide at least one element'
    );
  }

  const { sendDsGaEvent } = useDsGa4();

  return (
    <Container $variant={variant}>
      <InputContainer
        $disabled={disabled}
        $filled={!!value}
        $hasError={!!errorMessage}
        $hasSuccess={!!success}
      >
        {leftElement && (
          <Label
            $variant={variant}
            htmlFor={inputId}
            $hasRightBorderRadius
            $operation={operation}
          >
            {operation === 'CREDIT' && '+'}
            {operation === 'DEBIT' && '-'}
            {leftElement}
          </Label>
        )}

        <Input
          {...rest}
          id={inputId}
          placeholder='0,00'
          type='text'
          value={Numeric.format('BRL', value as number)}
          onChange={e => {
            if (e.target.value.length > 0 && e.target.value.includes('-')) {
              onChangeOperation?.('DEBIT');
              return;
            }

            if (e.target.value.length > 0 && e.target.value.includes('+')) {
              onChangeOperation?.('CREDIT');
              return;
            }

            const value = Numeric.unformat(e.target.value);
            onChangeValue(value);

            e.target.value &&
              sendDsGaEvent('components', 'input_currency', {
                eventValue: e.target.value
              });
          }}
          readOnly={disabled}
        />

        {rightElement && (
          <Label
            $variant={variant}
            htmlFor={inputId}
            $hasLeftBorderRadius
          >
            {rightElement}
          </Label>
        )}
      </InputContainer>

      {!!errorMessage && <Caption $hasError>{errorMessage}</Caption>}
    </Container>
  );
}
