import { useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useAddLegalRepresentatives } from 'data/modules/cards/companyOnboarding';

import { Onboarding } from 'shared/utils/cards';

import { type IUseFillerRepresentativeData } from './FillerRepresentativeData.types';

export function useFillerRepresentativeData(): IUseFillerRepresentativeData {
  const navigate = useNavigate();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const { control, errors, handleAddLegalRepresentatives, setValue } =
    useAddLegalRepresentatives({
      onAddLegalRepresentativesMutationSuccess: navigateNextStep
    });

  const { fields, append } = useFieldArray({
    control,
    name: 'legalRepresentatives'
  });

  function navigateNextStep(): void {
    navigate(
      `/cartoes/onboarding/empresa/${currentStep}/aprovacao/representantes`
    );
  }

  function handleAddPartner(): void {
    append({
      name: '',
      email: ''
    });
  }

  return {
    errors,
    fields,
    control,
    setValue,
    handleAddPartner,
    handleAddLegalRepresentatives
  };
}
