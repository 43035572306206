import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuthContext, useFlagsContext } from 'data/contexts';

import {
  AvailableValueCardInfo,
  CardsContainerPageHeader,
  CardsPageHeader,
  DepositValueCardInfo,
  DistributedCardsGroupValueCardInfo,
  WithdrawValueCardInfo
} from 'presentation/pages/cards/components';

export function CardGroupsPageHeader(): JSX.Element {
  const { t } = useTranslation('cards');
  const navigate = useNavigate();

  const adminMovementsFlag = useFlagsContext(state => state.adminMovementsFlag);
  const user = useAuthContext(state => state.user);

  return (
    <CardsPageHeader
      tabs={[
        { active: true, label: t('cardsManagement') },
        {
          active: false,
          label: t('movements'),
          hidden: user?.cards?.type === 'USER',
          onClick: () => {
            adminMovementsFlag
              ? navigate('/cartoes/movimentacoes')
              : navigate('/cartoes/extrato');
          }
        },
        {
          active: false,
          label: t('valuesSolicitations'),
          hidden: user?.cards?.type !== 'ACCOUNT_MANAGER',
          onClick: () => {
            navigate('/cartoes/solicitacoes-de-valores');
          }
        },
        {
          active: false,
          label: t('myCard'),
          hidden: !user?.cards?.hasAccount,
          onClick: () => {
            navigate('/cartoes/meu-cartao');
          }
        }
      ]}
    >
      {user?.cards?.type !== 'USER' && (
        <CardsContainerPageHeader>
          <AvailableValueCardInfo />

          <DistributedCardsGroupValueCardInfo />
        </CardsContainerPageHeader>
      )}

      {user?.cards?.type === 'ACCOUNT_MANAGER' && (
        <CardsContainerPageHeader>
          <DepositValueCardInfo />

          <WithdrawValueCardInfo />
        </CardsContainerPageHeader>
      )}
    </CardsPageHeader>
  );
}
