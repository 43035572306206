import {
  type IBalanceSolicitationApproved,
  type IPersistenceBalanceSolicitationApproved
} from 'data/modules/cards/balance';

class BalanceSolicitationApprovedMapper {
  toDomain(
    data: IPersistenceBalanceSolicitationApproved
  ): IBalanceSolicitationApproved {
    return {
      success: data.success.map(itemStatus => ({
        id: itemStatus.id,
        userName: itemStatus.user_name,
        amount: itemStatus.amount,
        responseCode: itemStatus.response_code,
        responseCodeMessage: itemStatus.response_code_message,
        transactionId: itemStatus.transaction_id
      })),
      failure: data.failure.map(itemStatus => ({
        id: itemStatus.id,
        userName: itemStatus.user_name,
        amount: itemStatus.amount,
        responseCode: itemStatus.response_code,
        responseCodeMessage: itemStatus.response_code_message,
        transactionId: itemStatus.transaction_id
      }))
    };
  }
}

export default new BalanceSolicitationApprovedMapper();
