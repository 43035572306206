import { type ISwitchDefaultProps } from './SwitchDefault.types';

import {
  Container,
  ErrorMessage,
  Label,
  StyledSwitch,
  StyledThumb
} from './SwitchDefault.styles';

export function SwitchDefault({
  onChange,
  label,
  disabled = false,
  value,
  error
}: ISwitchDefaultProps): JSX.Element {
  return (
    <>
      <Container $hasError={!!error}>
        {label && <Label>{label}</Label>}

        <StyledSwitch
          checked={value}
          onCheckedChange={onChange}
          disabled={disabled}
          value={value ? 'on' : 'off'}
        >
          <StyledThumb />
        </StyledSwitch>
      </Container>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
}
