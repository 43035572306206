import { useRef } from 'react';

import { useDsGa4 } from 'ds/hooks/globals';
import { DSIcons } from 'ds/icons';

import { type IActionButtonProps } from './ActionButton.types';

import { Container } from './ActionButton.styles';

export function ActionButton({
  icon,
  label,
  onClick,
  ...rest
}: IActionButtonProps): JSX.Element {
  const IconComponent = DSIcons[icon];

  const { sendDsGaEvent } = useDsGa4();

  return (
    <Container
      onClick={e => {
        onClick &&
          sendDsGaEvent('components', 'list_action', {
            eventLabel: label
          });

        onClick?.(e);
      }}
      {...rest}
    >
      <IconComponent />

      <span>{label}</span>
    </Container>
  );
}
