import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  useCardGroupsContext,
  useChargeCardsContext,
  useModalContext
} from 'data/contexts';
import { useGetCardGroup } from 'data/modules/cards/account/useCases/get-card-group/useGetCardGroup';

import { SideCardDetailsModal } from 'presentation/pages/cards/Management/components/SideCardDetailsModal';
import { DefaultLayout } from 'presentation/templates';

import { localStorageKeys } from 'shared/constants/global';

import { CardsInfoOverview } from './components/CardsInfoOverview';
import { ChargeCardsControllers } from './components/ChargeCardsControllers';
import { ChargeCardsSummary } from './components/ChargeCardsSummary';
import { ChargeCardsTable } from './components/ChargeCardsTable';
import { ClearValuesConfirmationModal } from './components/ClearValuesConfirmationModal';
import { useChargeCardsTour } from './tours';

import { ChargeCardsSteps } from './ChargeCards.types';

import {
  CardsInfoOverviewContainer,
  PageContainer,
  TableContainer
} from './ChargeCards.styles';

export function ChargeCards(): JSX.Element {
  const { t } = useTranslation('cards');
  const { groupId } = useParams();

  const visibleModal = useModalContext(state => state.visibleModal);

  const currentStep = useChargeCardsContext(state => state.currentStep);
  const contextCardGroup = useCardGroupsContext(
    state => state.selectedCardGroup
  );

  document.title = `vexpenses | ${t('chargeCards')}`;

  useChargeCardsTour({
    enabled: localStorage.getItem(localStorageKeys.chargeCardsTour) !== 'true'
  });

  const { cardGroupData } = useGetCardGroup({
    cardGroupId: groupId || '',
    enabled: !contextCardGroup
  });

  const selectedCardGroup = contextCardGroup || cardGroupData;

  return (
    <DefaultLayout
      pageNameAndBreadcrumbs={{
        pageName: t('chargeCards'),
        breadcrumbs:
          groupId && selectedCardGroup
            ? [
                {
                  name: t('cardsManagement'),
                  pageLink: '/cartoes/grupos-de-cartoes'
                },
                {
                  name: selectedCardGroup.name,
                  pageLink: `/cartoes/gestao-de-grupos/${selectedCardGroup?.id}`
                },
                {
                  name: t('chargeCards')
                }
              ]
            : [
                {
                  name: t('cardGroups'),
                  pageLink: '/cartoes/gestao'
                },
                {
                  name: t('chargeCards')
                }
              ],
        isBackButtonVisible: true,
        isBreadcrumbsVisible: true,
        isClassicMode: false,
        isTourButtonVisible: true
      }}
    >
      <PageContainer>
        <CardsInfoOverviewContainer>
          <CardsInfoOverview />
        </CardsInfoOverviewContainer>

        <TableContainer>
          {currentStep === ChargeCardsSteps.CHARGE_CARDS && (
            <ChargeCardsControllers />
          )}

          <ChargeCardsTable />
        </TableContainer>

        <ChargeCardsSummary />
      </PageContainer>

      <SideCardDetailsModal
        isOpen={visibleModal === 'cardDetails'}
        title={t('card')}
        side='right'
      />

      <ClearValuesConfirmationModal isOpen={visibleModal === 'confirmation'} />
    </DefaultLayout>
  );
}
