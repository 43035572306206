import { useNavigate } from 'react-router-dom';

import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  useCreatePixKey
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import { type IUsePixKeyCreation } from './PixKeyCreation.types';

export function usePixKeyCreation(): IUsePixKeyCreation {
  const navigate = useNavigate();
  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const {
    data: cachedCompanyOnboardingProgress,
    updateCache: updateCurrentStepCache
  } = useHandleQueryCache({
    query: {
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS],
      query: async () => await CompanyOnboardingService.getProgress()
    }
  });

  const { handleUpdatePixKey } = useCreatePixKey({
    onUpdatePixKeyMutationSuccess: () => {
      navigate(`/cartoes/onboarding/empresa/${currentStep + 1}`);

      if (
        currentStep === Number(cachedCompanyOnboardingProgress?.currentStep)
      ) {
        updateCurrentStepCache({
          key: 'currentStep',
          newData: currentStep + 1
        });
      }
    }
  });

  return {
    handleUpdatePixKey
  };
}
