import styled from 'styled-components';

export const Container = styled.section`
  details {
    max-width: unset;

    p {
      white-space: pre-wrap;
    }
  }
`;
