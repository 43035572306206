import { transparentize } from 'polished';
import styled, { css, keyframes } from 'styled-components';
import type Keyframes from 'styled-components/dist/models/Keyframes';

import { type AddPrefixToType } from 'data/modules/global';

import { OutsourcedDropdownWrapper } from 'presentation/components/global/OldOutsourced/Outsourced.styles';
import {
  DropdownContent,
  DropdownItem,
  DropdownLabel,
  DropdownTrigger
} from 'presentation/ds/DsDropdown';

import {
  type SidebarContainerStylesType,
  type SidebarDropdownStylesType,
  type SidebarSubItemStylesType
} from './SidebarContainer.types';

export const CompanyInfo = styled.a<
  AddPrefixToType<SidebarContainerStylesType>
>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.3rem 1.6rem;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.tokens.brandColorPrimaryMedium};
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    width: 100%;
  }

  p {
    font-size: 1.4rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.white};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 15rem;
  }

  svg {
    font-weight: 600;
    width: 2.2rem;
  }

  svg:last-child {
    color: ${({ theme }) => theme.colors.white};
    transition: all 200ms ease-in-out;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      > svg {
        transform: rotateZ(3.14rad);
      }
    `}

  ${({ $outsourced, theme }) =>
    $outsourced &&
    css`
      background-color: ${theme.tokens.neutralColorHigh} !important;

      p {
        color: ${theme.colors.alabaster} !important;
      }

      > svg {
        color: ${theme.colors.alabaster} !important;
      }
    `}
  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      background-color: transparent !important;
      cursor: auto;

      div {
        justify-content: center;
        cursor: auto;
        width: 100%;
      }

      p {
        color: ${theme.colors.white} !important;
      }
    `}
    ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      padding: 1.2rem;

      div {
        justify-content: center;
      }
    `}
    @media
    screen
    and
    (min-width: 1200px) {
    ${({ $isCollapsed }) =>
      $isCollapsed &&
      css`
        p {
          display: none;
        }

        svg:last-child {
          display: none;
        }
      `}
  }

  @media screen and (max-width: 1680px) {
    p {
      font-size: 1.559rem;
    }

    svg {
      width: 1.779rem;
      height: 1.779rem;
    }
  }

  @media screen and (max-width: 1366px) {
    svg {
      width: 2.45rem;
      height: 2.45rem;
    }
  }
`;

export const Tooltip = styled.span`
  transition: all 100ms ease-in-out;
  padding: 0.3rem 0.6rem;
  width: fit-content;
  background: ${({ theme }) => theme.colors.neutralColorDark};
  border-radius: 4px;
  position: absolute;
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: center;
  visibility: 0;
  opacity: 0;
  left: 0;
  white-space: nowrap;
  z-index: -100;
  pointer-events: none;
  margin-left: 29rem;
  margin-top: 1.6rem;

  &::before {
    content: '';
    position: absolute;
    top: 33%;
    left: -1rem;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: ${({ theme }) =>
      `8px solid ${theme.colors.neutralColorDark}`};
    border-radius: 8px;
    transform: rotate(269deg);
  }
`;

export const CollapseIcon = styled.div<
  AddPrefixToType<SidebarContainerStylesType>
>`
  position: absolute;
  top: 3.8rem;
  right: -1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 10px ${({ theme }) => transparentize(0.9, theme.colors.black)};
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  svg {
    width: 1.6rem !important;
    fill: ${({ $outsourced, theme }) =>
      $outsourced ? theme.colors.neutralColorLowDark : theme.colors.primary};
  }

  ${Tooltip} {
    left: 100%;
    right: unset;
    top: -1rem;
    margin-left: 1rem;
  }

  &:hover {
    overflow: visible;

    ${Tooltip} {
      opacity: 1;
      visibility: 1;
      left: 100%;
      right: unset;
      margin-left: 1rem;
    }
  }

  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const Wrapper = styled.div<AddPrefixToType<SidebarContainerStylesType>>`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 27.4rem;
  max-width: 27.2rem;
  min-width: 8.6rem;
  height: 100vh;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.colors.adamantineBlue};
  transition: all 250ms ease-in-out;

  #collapsed-logo {
    display: none;
    margin-bottom: 1rem;
    height: 4.8rem;
    width: 100%;
    color: white;
    margin-left: 18%;
  }

  #logo {
    display: block;
    margin-bottom: 2rem;
    max-width: 26rem;
    height: 3.8rem;
  }

  ${({ $outsourced }) =>
    $outsourced &&
    css`
      background-color: ${({ theme }) => theme.colors.mineShaft};

      ${Tooltip} {
        color: ${({ theme }) => `${theme.colors.neutralColorDark}!important`};
        background-color: ${({ theme }) => `${theme.colors.white}!important`};
        box-shadow: 5px 0 20px 0
          ${({ theme }) => transparentize(0.9, theme.colors.black)};

        &::before {
          border-bottom-color: ${({ theme }) =>
            `${theme.colors.white}!important`};
        }
      }
    `}

  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      min-width: 8.6rem;
      width: 8.6rem;
      padding: 1.8rem;

      #collapsed-logo {
        display: block;
      }

      #logo {
        display: none;
      }

      a {
        justify-content: space-between;
      }
    `}

  @media screen and (min-width: 1281px) and (max-width: 1369px) {
    max-width: 27.2rem;
    width: 27.2rem;
    ${({ $isCollapsed }) =>
      $isCollapsed &&
      css`
        min-width: 8.6rem;
        width: 8.6rem;
      `}
  }

  @media only screen and (min-width: 1370px) {
    width: 27.2rem;
    ${({ $isCollapsed }) =>
      $isCollapsed &&
      css`
        width: 8.6rem;
      `}
  }

  @media screen and (max-width: 700px) {
    #logo {
      margin-bottom: 2rem;
    }
  }
`;

export const SidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.4rem;
  width: 100%;
`;

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  width: 100%;
  height: 100%;
  position: unset;
  overflow: auto;
`;

export const Container = styled.div<
  AddPrefixToType<SidebarContainerStylesType>
>`
  position: fixed;
  background-color: ${({ theme }) => transparentize(0.8, theme.colors.black)};
  z-index: ${({ theme }) => theme.zIndex.sidebarMobile};
  height: 100vh;
  width: 0;
  top: 0;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      width: 100vw;
      z-index: ${({ theme }) => theme.zIndex.sidebarMobile};
    `}

  @media screen and (max-width: 1200px) {
    ${({ $isOpen }) =>
      $isOpen
        ? css`
            width: 100vw;
            pointer-events: all;
            opacity: 1;

            ${Wrapper} {
              opacity: 1;
            }
          `
        : css`
            width: 0;
            opacity: 0;
            z-index: -90;

            ${Wrapper} {
              opacity: 0;
            }
          `}
  }

  &:has(div[role='menu']) {
    z-index: 11;

    ${Wrapper} {
      @media screen and (max-width: 1200px) {
        width: 50%;
      }

      @media screen and (max-width: 600px) {
        width: 90%;
      }

      #collapsed-logo {
        display: none;
      }

      #logo {
        display: block;
      }

      #new-tag {
        display: block;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    background-color: ${({ theme }) => transparentize(0.8, theme.colors.black)};
    width: 100vw;
    z-index: -90;

    ${({ $isOpen }) =>
      $isOpen &&
      css`
        z-index: ${({ theme }) => theme.zIndex.sidebar};
        opacity: 1;
      `}
  }

  @media screen and (max-width: 700px) {
    ${OutsourcedDropdownWrapper} {
      div {
        max-width: 24rem !important;
      }
    }
  }
`;

export const CompanyDropdownWrapper = styled.div<
  AddPrefixToType<SidebarDropdownStylesType>
>`
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  &:hover {
    overflow: unset;
  }

  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      span {
        margin-left: 8.6rem !important;
      }
    `}

  div[role='separator'] {
    margin-bottom: 0;
  }

  > button {
    width: 100%;
  }

  ${({ $disabled, $isCollapsed }) =>
    ($disabled ?? $isCollapsed) &&
    css`
      ${CompanyInfo} {
        justify-content: ${$isCollapsed
          ? 'center'
          : 'space-between'} !important;
      }
    `}
`;

export const DropdownCompanyLabel = styled(DropdownLabel)`
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  column-gap: 1rem;
  font-weight: 500;
  padding: 1.5rem;
  text-align: left;
  font-size: 1.4rem;
  margin: 0;
  cursor: pointer;
  transition: all 250ms ease-in;
  pointer-events: none;

  &:hover {
    background-color: ${({ theme }) => theme.tokens.neutralColorLowDarkTwo};
  }

  svg {
    flex-shrink: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.independence};
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const DropdownCompanyItem = styled(DropdownItem)`
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  column-gap: 1rem;
  font-weight: 500;
  font-size: 1.4rem;
  padding: 1.5rem;
  text-align: left;
  margin: 0;
  cursor: pointer;
  transition: all 250ms ease-in;
  pointer-events: none;

  &:hover {
    background-color: ${({ theme }) => theme.tokens.neutralColorLowDarkTwo};
  }

  svg {
    flex-shrink: 0;
  }

  p {
    color: ${({ theme }) => theme.tokens.neutralColorLowDark};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const DropdownCompanyScroll = styled.div`
  height: fit-content;
  max-height: 14.3rem;
  overflow-y: auto;
  width: 100%;
  z-index: 40 !important;

  ${DropdownCompanyItem} {
    pointer-events: all;
  }

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    border: solid 3px transparent;
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.lightSilver};
  }
`;

const slideUp = (height: number): Keyframes => keyframes`
    0% {
      height: 0;
      overflow: hidden;
    }
    100% {
      height: ${height}rem;
    }
`;

const slideDown = (height: number): Keyframes => keyframes`
    0% {
      height: ${height}rem;
      overflow: hidden;
    }
    100% {
      height: 0;
      overflow: hidden;
    }
`;

export const DropdownCompanyContent = styled(DropdownContent)`
  padding: 0;
  width: 24rem;
  position: relative;
  overflow: hidden;
  margin-top: 1rem;

  &[data-state='open'] {
    animation: ${({ height }) => slideUp(height ?? 0)} 250ms ease-in-out;
  }

  &[data-state='closed'] {
    animation: ${({ height }) => slideDown(height ?? 0)} 250ms ease-in-out;
  }
`;

export const NavbarDataMobile = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  margin-bottom: 2rem;

  button p {
    color: ${({ theme }) => theme.colors.white};
  }

  @media screen and (max-width: 800px) {
    display: flex;
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const DropdownCompanyTrigger = styled(DropdownTrigger)`
  position: relative;
  &:hover ${Tooltip} {
    opacity: 1;
    visibility: 1;
  }
`;

export const SidebarSubItem = styled.a<
  AddPrefixToType<SidebarSubItemStylesType>
>`
  display: flex;
  flex-direction: row;
  font-weight: normal;
  padding: 1.2rem 1.2rem 1.2rem 2.3rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 100ms ease-in-out;
  font-size: 1.4rem;
  gap: 2rem;
  align-items: center;
  margin-top: 0.5rem;
  line-height: 2.2rem;
  width: 100%;

  svg {
    width: 0.4rem;
    height: 0.4rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;

    * {
      color: ${({ theme }) =>
        transparentize(0.45, theme.colors.white)} !important;
    }
  }

  ${({ $isActive, $outsourced, theme }) =>
    $isActive &&
    css`
      color: ${$outsourced
        ? theme.colors.outSourced
        : theme.tokens.brandColorSecondaryPure};
    `}

  &:hover {
    background-color: ${({ $outsourced, theme }) =>
      $outsourced
        ? theme.colors.outSourced
        : theme.tokens.brandColorPrimaryMedium};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      font-weight: 600;

      svg {
        width: 0.8rem;
        height: 0.8rem;
        margin: 0;

        * {
          color: ${({ theme }) => theme.colors.white} !important;
        }
      }
    `}
`;

const fadeIn = keyframes`
    0% {
        opacity: 0;
        height: 0;
    }
    100% {
        opacity: 1;
        height: 100%;
    }
`;

const fadeOut = keyframes`
    0% {
        height: 100%;
        opacity: 1;
    }
    100% {
        opacity: 0;
        height: 0;
    }
`;

export const SidebarSubItemsContainer = styled.div<
  AddPrefixToType<SidebarSubItemStylesType>
>`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  height: 0;
  overflow: hidden;
  width: 100%;

  ${({ $isActive }) =>
    $isActive
      ? css`
          height: 100%;
          opacity: 1;
          animation: ${fadeIn} 300ms ease-in;
        `
      : css`
          height: 0;
          opacity: 1;
          animation: ${fadeOut} 300ms ease-out;
        `}

  * {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;

export const SidebarSubSection = styled.div<
  AddPrefixToType<SidebarSubItemStylesType>
>`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 0.4rem;
  width: 100%;

  ${SidebarSubItemsContainer} {
    ${({ $isCollapsed }) =>
      $isCollapsed &&
      css`
        display: none;
      `}
  }

  .sidebar-loader-container {
    gap: 0.4rem;
    display: flex;
    flex-direction: column;
  }

  .item-loader {
    background: transparent;
  }
`;

export const LogoCompany = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 0.8rem;
  overflow: hidden;
  height: 7.6rem;
  margin-bottom: 2.4rem;

  img {
    max-height: 6rem;
    max-width: 10rem;
  }
`;
