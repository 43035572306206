import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  type IUpdateUserOnboardingAnswersPayload,
  type IUpdateUserOnboardingDataFieldsForm,
  type IUseUpdateUserOnboardingData,
  type IUseUpdateUserOnboardingDataParams,
  UserOnboardingMutationKeys,
  UserOnboardingQueryKeys,
  UserOnboardingService
} from 'data/modules/cards/userOnboarding';

import { useFormWithSchema } from 'shared/hooks/forms';
import { useHandleQueryCache } from 'shared/hooks/global';

import { useUpdateUserOnboardingDataSchema } from './schemas';

export function useUpdateUserOnboardingData({
  onUpdateUserOnboardingDataCache
}: IUseUpdateUserOnboardingDataParams): IUseUpdateUserOnboardingData {
  const { data: cachedUserOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () =>
        await UserOnboardingService.getUserOnboardingProgress(),
      key: [UserOnboardingQueryKeys.GET_USER_ONBOARDING_PROGRESS]
    }
  });

  const { data: cachedUserOnboardingAnswers } = useHandleQueryCache({
    query: {
      key: [
        UserOnboardingQueryKeys.GET_USER_ONBOARDING_ANSWERS,
        cachedUserOnboardingProgress?.onboardingUserId ?? ''
      ],
      query: async () =>
        await UserOnboardingService.getUserOnboardingAnswers({
          onboardingUserId: cachedUserOnboardingProgress?.onboardingUserId ?? ''
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedUserOnboardingProgress?.onboardingUserId ?? ''
    }
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty }
  } = useFormWithSchema(useUpdateUserOnboardingDataSchema(), {
    defaultValues: {
      birthDate: cachedUserOnboardingAnswers?.userAccount?.birthDate ?? '',
      documentNumber:
        cachedUserOnboardingAnswers?.userAccount?.documentNumber ?? '',
      email: cachedUserOnboardingAnswers?.userAccount?.email ?? '',
      fullName: cachedUserOnboardingAnswers?.userAccount?.fullName ?? '',
      motherName: cachedUserOnboardingAnswers?.userAccount?.motherName ?? '',
      phoneNumber: cachedUserOnboardingAnswers?.userAccount?.phoneNumber ?? ''
    }
  });

  const { mutate, isLoading } = useMutationCache({
    mutationKey: [UserOnboardingMutationKeys.UPDATE_USER_ONBOARDING],
    mutationFn: async (payload: IUpdateUserOnboardingAnswersPayload) =>
      await UserOnboardingService.updateUserOnboardingAnswers(payload),
    onSuccess: () => {
      onUpdateUserOnboardingDataCache(getValues());
    },

    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function onSubmit(data: IUpdateUserOnboardingDataFieldsForm): void {
    if (!cachedUserOnboardingProgress?.onboardingUserId || isLoading) {
      return;
    }

    if (!isDirty) {
      onUpdateUserOnboardingDataCache(getValues());
      return;
    }

    mutate({
      onboardingUserId: cachedUserOnboardingProgress?.onboardingUserId,
      context: 'user_account',
      step: 2,
      answers: data
    });
  }

  return {
    control,
    isUpdatingUserData: isLoading,
    updateUserOnboardingData: handleSubmit(onSubmit),
    errors,
    setValue
  };
}
