import { useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { useGetCurrencies } from 'data/modules/currencies';
import {
  type ICreateRouteByMapDefaultFieldsForm,
  type IValidateDateLimitsByProjectPayload,
  useGetExpenseParameters,
  useValidateDateLimitsByProject,
  useValidateMileagePolicyByUser
} from 'data/modules/expenses';
import { useGetPaymentMethods } from 'data/modules/paymentMethods';

import { Currency } from 'shared/utils/format';
import { TransformArray } from 'shared/utils/global';
import { Validate } from 'shared/utils/validation';

import {
  type IUseDefaultFieldsStep,
  type IUseDefaultFieldsStepProps
} from './DefaultFieldsStep.types';

export function useDefaultFieldsStep({
  expenseUuidToUpdate
}: IUseDefaultFieldsStepProps): IUseDefaultFieldsStep {
  const { expenseParameters } = useGetExpenseParameters();

  const { getValues, setValue, clearErrors, setError } =
    useFormContext<ICreateRouteByMapDefaultFieldsForm>();

  const { paymentMethods, isLoadingPaymentMethods } = useGetPaymentMethods();

  const paymentMethodsOptions = TransformArray.objectsToSelectOptions(
    paymentMethods,
    'id',
    'description',
    true
  );

  const paymentMethodIsSelected = !!getValues('paymentMethod');

  const [fieldRefundableDisabled, setFieldRefundableDisabled] = useState(
    paymentMethodIsSelected
  );

  const { currencies, isLoadingCurrencies } = useGetCurrencies();

  const currenciesOptions = TransformArray.objectsToSelectOptions(
    currencies,
    'id',
    'isoCode',
    true
  );

  const { getEnsuredValidateDateLimitsByProject } =
    useValidateDateLimitsByProject();

  const { getEnsuredValidateMileagePolicyByUser } =
    useValidateMileagePolicyByUser();

  const { t } = useTranslation('expenses');

  async function checkIfDateIsValidByProjectPolicy(
    date: string | undefined
  ): Promise<void> {
    if (date === undefined || expenseParameters?.policyType !== 'PROJETO') {
      return;
    }

    if (Validate.isDate(date, 'yyyy-mm-dd')) {
      const apportionment = getValues('apportionment');

      const filteredApportionment = apportionment
        ? apportionment.filter(item => item.project !== undefined)
        : [];

      if (filteredApportionment.length === 0) {
        clearErrors('date');
        return;
      }

      const policyByProjectQueryPayload: IValidateDateLimitsByProjectPayload = {
        date,
        projects: filteredApportionment.map(item => {
          return {
            id: item.project?.value as string,
            percentage: item.percentage
          };
        }),
        expenseUuid: expenseUuidToUpdate
      };

      const checkPolicyByProject = await getEnsuredValidateDateLimitsByProject(
        policyByProjectQueryPayload
      );

      if (checkPolicyByProject !== null && !checkPolicyByProject.success) {
        if (checkPolicyByProject.daysLimitAgo !== null) {
          setError('date', {
            type: 'validated-by-project',
            message: t('errors.expenseExceedsTheMaximumAmountOfDaysPassed', {
              days: checkPolicyByProject.daysLimitAgo
            })
          });
        }

        if (checkPolicyByProject.daysLimitAhead !== null) {
          setError('date', {
            type: 'validated-by-project',
            message: t('errors.expenseExceedsTheMaximumAmountOfFutureDays', {
              days: checkPolicyByProject.daysLimitAhead
            })
          });
        }

        return;
      }

      clearErrors('date');
    }
  }

  async function validateMileagePolicyByUser(
    date: string | undefined,
    mileage: string | undefined
  ): Promise<boolean> {
    if (
      !date ||
      !mileage ||
      expenseParameters?.mileagePolicyType !== 'USUARIO'
    ) {
      return false;
    }

    const data = await getEnsuredValidateMileagePolicyByUser({
      mileage: Currency.parseToFloat(mileage),
      date,
      expenseUuid: expenseUuidToUpdate
    });

    if (data !== null) {
      setValue(
        'mileagePaidValue',
        Currency.format('BRL', data.mileagePaidValue, false, 4)
      );
      setValue('value', Currency.format('BRL', data.value));
      setValue('observation', data.observations);
      return true;
    }

    return false;
  }

  function handleChangeDate(value: string | undefined): void {
    checkIfDateIsValidByProjectPolicy(value);

    validateMileagePolicyByUser(value, getValues('mileage'));
  }

  const debouncedHandleChangeDate = useDebouncedCallback(handleChangeDate, 500);

  function handleChangeMileagePaidValue(value: string): void {
    const mileage = Currency.parseToFloat(getValues('mileage'));

    const mileagePaidValue = Currency.parseToFloat(value, 4);

    const maskedValue = Currency.format(
      'BRL',
      Currency.roundToTwoDecimals(mileagePaidValue * mileage)
    );

    setValue('value', maskedValue);
  }

  function handleChangePaymentMethod(paymentMethodId: string): void {
    if (!paymentMethodId) {
      setFieldRefundableDisabled(false);
      return;
    }

    const isPaymentMethodRefundable = paymentMethods?.find(
      paymentMethod => paymentMethod.id.toString() === paymentMethodId
    )?.refundable;

    if (isPaymentMethodRefundable === undefined) {
      setFieldRefundableDisabled(false);
      return;
    }

    setValue('refundable', isPaymentMethodRefundable ?? false);
    setFieldRefundableDisabled(true);
  }

  const paymentMethodsOptionsWithoutVExpensesCards =
    paymentMethodsOptions?.filter(
      paymentMethod => paymentMethod.label !== 'Cartão VExpenses'
    );

  return {
    currenciesOptions,
    isLoadingCurrencies,
    isLoadingExpensePaymentMethods: isLoadingPaymentMethods,
    paymentMethodsOptions: paymentMethodsOptionsWithoutVExpensesCards,
    fieldRefundableDisabled,
    handleChangePaymentMethod,
    expenseParameters,
    handleChangeDate: debouncedHandleChangeDate,
    handleChangeMileagePaidValue
  };
}
