import { useMemo } from 'react';

import {
  BadgeStatus,
  DangerButton,
  DefaultButton,
  DSIcons,
  Modal,
  SecondaryButton,
  SkeletonLoader,
  Spinner
} from 'ds';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useModalContext } from 'data/contexts';

import { AlertModal } from 'presentation/components/global/AlertModal';
import { type IAlertModalProps } from 'presentation/components/global/AlertModal/AlertModal.types';
import { Loader } from 'presentation/components/global/Loader';

import { routesPathPrefix } from 'shared/constants/global';

import { useViewVehicleModal } from './useViewVehicleModal';

import { type IViewVehicleModalProps } from './ViewVehicleModal.types';

import {
  FooterButtons,
  ModalContent,
  ModalFooter,
  SelectedMembersContainer,
  SpinnerContainer,
  StyledContainer,
  StyledModalContainer,
  VehicleDetailContainer,
  VehicleDetailsContainer,
  VehicleDetailsContent,
  VehicleDetailTitle,
  VehicleDetailValue
} from './ViewVehicleModal.styles';

export function ViewVehicleModal({
  onClose,
  onChangeVehicle,
  onDeleteVehicle,
  onEditVehicle,
  total,
  vehicleId,
  vehicleIndex,
  isVehicleOnList,
  isLinkingMembers
}: IViewVehicleModalProps): JSX.Element {
  const { handleCloseModal } = useModalContext();

  const { t } = useTranslation(['fuel', 'global']);

  const navigate = useNavigate();

  const {
    isShowingModalLoading,
    vehicleToShow,
    memberToUnlink,
    setMemberToUnlink,
    setShowDeleteModal,
    showDeleteModal,
    setShowMoreMembers,
    showMoreDivRef,
    showMoreMembers,
    infiniteScrollRef,
    isFetchingInfiniteMembersNextPage,
    isLoadingLinkedInfiniteMembers,
    linkedInfiniteMembers,
    handleUnlinkMember,
    isLinkingOrUnlinkingMembersFromVehicle,
    membersUnlinked
  } = useViewVehicleModal({
    vehicleId,
    isVehicleOnList
  });

  const vehicleDeleteActionModalData: Pick<
    IAlertModalProps,
    'children' | 'title'
  > = {
    children: t('fuel:modal.deleteVehicleDescription'),
    title: t('fuel:modal.deleteVehicleTitle')
  };

  const vehicleUnlinkActionModalData: Pick<
    IAlertModalProps,
    'children' | 'title'
  > = {
    children: t('fuel:modal.unlinkMemberVehicleDescription'),
    title: t('fuel:modal.unlinkMemberVehicleTitle')
  };

  const showModalRightButton = vehicleIndex + 1 <= total - 1;

  const isMembersOverflown = useMemo(() => {
    return (
      !isLoadingLinkedInfiniteMembers &&
      showMoreDivRef.current &&
      showMoreDivRef.current?.clientHeight <
        showMoreDivRef.current?.scrollHeight
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMoreDivRef.current, isLoadingLinkedInfiniteMembers]);

  return (
    <>
      <Loader isLoading={isLinkingOrUnlinkingMembersFromVehicle} />

      <StyledModalContainer
        variant='small'
        onClose={() => {
          navigate(`${routesPathPrefix.settings}/combustivel/veiculos/`);
          handleCloseModal();
          onClose();
        }}
        onLeftArrowClick={
          vehicleIndex - 1 >= 0 && isVehicleOnList && !isLinkingMembers
            ? () => {
                onChangeVehicle('previous');
              }
            : undefined
        }
        onRightArrowClick={
          showModalRightButton && isVehicleOnList && !isLinkingMembers
            ? () => {
                onChangeVehicle('next');
              }
            : undefined
        }
      >
        {isShowingModalLoading && (
          <StyledContainer>
            <Modal.Header>
              <SkeletonLoader
                height='2rem'
                maxWidth='80rem'
              />

              <Modal.IconClose
                onClick={() => {
                  handleCloseModal();
                  onClose();
                }}
              />
            </Modal.Header>

            <ModalContent>
              <VehicleDetailsContainer>
                <VehicleDetailsContent>
                  <VehicleDetailContainer>
                    <SkeletonLoader
                      height='2rem'
                      maxWidth='35rem'
                    />
                  </VehicleDetailContainer>

                  <VehicleDetailContainer>
                    <SkeletonLoader
                      height='2rem'
                      maxWidth='35rem'
                    />
                  </VehicleDetailContainer>
                </VehicleDetailsContent>

                <VehicleDetailsContent>
                  <VehicleDetailContainer>
                    <SkeletonLoader
                      height='2rem'
                      maxWidth='35rem'
                    />
                  </VehicleDetailContainer>

                  <VehicleDetailContainer>
                    <SkeletonLoader
                      height='2rem'
                      maxWidth='35rem'
                    />
                  </VehicleDetailContainer>
                </VehicleDetailsContent>
              </VehicleDetailsContainer>

              <SelectedMembersContainer>
                <SkeletonLoader
                  height='2rem'
                  maxWidth='80rem'
                />
              </SelectedMembersContainer>
            </ModalContent>

            <ModalFooter>
              <SkeletonLoader
                height='2rem'
                maxWidth='10rem'
              />

              <SkeletonLoader
                height='2rem'
                maxWidth='10rem'
              />
            </ModalFooter>
          </StyledContainer>
        )}

        {!isShowingModalLoading && (
          <StyledContainer>
            <Modal.Header>
              <Modal.Title> {vehicleToShow?.model}</Modal.Title>

              <Modal.IconClose
                onClick={() => {
                  navigate(
                    `${routesPathPrefix.settings}/combustivel/veiculos/`
                  );
                  handleCloseModal();
                  onClose();
                }}
              />
            </Modal.Header>

            <ModalContent>
              <VehicleDetailsContainer>
                <VehicleDetailsContent>
                  <VehicleDetailContainer>
                    <VehicleDetailTitle>{t('fuel:plate')}</VehicleDetailTitle>
                    <VehicleDetailValue>
                      {vehicleToShow?.plate}
                    </VehicleDetailValue>
                  </VehicleDetailContainer>

                  <VehicleDetailContainer>
                    <VehicleDetailTitle>{t('fuel:fuel')}</VehicleDetailTitle>
                    <VehicleDetailValue>
                      {Array.isArray(vehicleToShow?.fuels) &&
                        vehicleToShow?.fuels
                          .map(fuel => fuel.description)
                          .join(', ')}
                    </VehicleDetailValue>
                  </VehicleDetailContainer>
                </VehicleDetailsContent>

                <VehicleDetailsContent>
                  <VehicleDetailContainer>
                    <VehicleDetailTitle>
                      {t('fuel:typeOfVehicle')}
                    </VehicleDetailTitle>
                    <VehicleDetailValue>
                      {vehicleToShow?.vehicleType.description}
                    </VehicleDetailValue>
                  </VehicleDetailContainer>

                  <VehicleDetailContainer>
                    <VehicleDetailTitle>
                      {t('fuel:proprietary')}
                    </VehicleDetailTitle>
                    <VehicleDetailValue>
                      {vehicleToShow?.owner}
                    </VehicleDetailValue>
                  </VehicleDetailContainer>
                </VehicleDetailsContent>
              </VehicleDetailsContainer>

              {Array.isArray(linkedInfiniteMembers) &&
                !isLoadingLinkedInfiniteMembers &&
                linkedInfiniteMembers.length > 0 && (
                  <>
                    <VehicleDetailTitle>
                      {t('fuel:linkedMembers')}
                    </VehicleDetailTitle>

                    <SelectedMembersContainer
                      $showMoreMembers={showMoreMembers}
                      ref={showMoreDivRef}
                    >
                      {linkedInfiniteMembers.map(member => {
                        return (
                          !membersUnlinked.includes(member.id) && (
                            <BadgeStatus
                              key={`member-${member?.id}`}
                              onClose={() => {
                                setMemberToUnlink(member?.id);
                              }}
                            >
                              {member.name}
                            </BadgeStatus>
                          )
                        );
                      })}

                      <div ref={infiniteScrollRef} />

                      {isFetchingInfiniteMembersNextPage && (
                        <SpinnerContainer>
                          <Spinner size='medium' />
                        </SpinnerContainer>
                      )}
                    </SelectedMembersContainer>

                    {isMembersOverflown && (
                      <SecondaryButton
                        onClick={() => {
                          setShowMoreMembers(!showMoreMembers);
                          if (showMoreDivRef.current) {
                            showMoreDivRef.current.scrollTop = 0;
                          }
                        }}
                        size='small'
                      >
                        {showMoreMembers
                          ? t('global:viewLess')
                          : t('global:viewMore')}

                        <DSIcons.ArrowDownIcon />
                      </SecondaryButton>
                    )}
                  </>
                )}
            </ModalContent>

            <ModalFooter>
              <SecondaryButton
                size='small'
                onClick={() => {
                  handleCloseModal();
                  navigate(
                    `${routesPathPrefix.settings}/combustivel/veiculos/`
                  );
                  onClose();
                }}
              >
                {t('global:close')}
              </SecondaryButton>

              <FooterButtons>
                <DangerButton
                  size='small'
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  {t('global:delete')}
                </DangerButton>

                <DefaultButton
                  size='small'
                  onClick={() => {
                    onEditVehicle(vehicleId);
                  }}
                >
                  {t('global:edit')}
                </DefaultButton>
              </FooterButtons>
            </ModalFooter>
          </StyledContainer>
        )}
      </StyledModalContainer>

      {showDeleteModal && (
        <AlertModal
          {...vehicleDeleteActionModalData}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          cancelButtonProps={{
            onCancel: () => {
              setShowDeleteModal(false);
            }
          }}
          confirmButtonProps={{
            onConfirm: () => {
              setShowDeleteModal(false);
              navigate(`${routesPathPrefix.settings}/combustivel/veiculos`);
              onDeleteVehicle(vehicleId);
            }
          }}
          icon={<DSIcons.WarningConfirmationIcon />}
        />
      )}

      {memberToUnlink && (
        <AlertModal
          {...vehicleUnlinkActionModalData}
          onClose={() => {
            setMemberToUnlink(undefined);
          }}
          cancelButtonProps={{
            onCancel: () => {
              setMemberToUnlink(undefined);
            }
          }}
          confirmButtonProps={{
            onConfirm: () => {
              handleUnlinkMember();
            }
          }}
          icon={<DSIcons.WarningConfirmationIcon />}
        />
      )}
    </>
  );
}
