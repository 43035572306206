import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: ${tokens.spacingInsetXs};
  padding: ${tokens.spacingXs} ${tokens.spacingXxs};
  border-radius: 16px;
  background-color: ${tokens.neutralColorHighLight};
  margin-bottom: 3.6rem;

  table {
    min-width: 100rem;
  }

  tr {
    height: 6.8rem;
  }

  td {
    &:last-child {
      cursor: pointer;
    }

    &:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
