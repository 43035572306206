import { useEffect } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { useCardDetailsContext } from 'data/contexts';
import { useGetBalancesAndAccount } from 'data/modules/cards/balance';

import { type IUseSideCardDetailsModal } from './SideCardDetailsModal.types';

export function useSideCardDetailsModal(): IUseSideCardDetailsModal {
  const [handleSetSelectedAccount, selectedAccount] = useCardDetailsContext(
    useShallow(state => [state.handleSetSelectedAccount, state.selectedAccount])
  );
  const { balancesAndAccount, isFetchingBalancesAndAccount } =
    useGetBalancesAndAccount({
      balanceId: selectedAccount?.balanceId as string,
      enabled: !selectedAccount?.accountId && !!selectedAccount?.balanceId
    });

  useEffect(() => {
    if (balancesAndAccount && !selectedAccount?.accountId) {
      handleSetSelectedAccount({
        ...selectedAccount,
        accountId: balancesAndAccount.account.id
      });
    }
  }, [balancesAndAccount, handleSetSelectedAccount, selectedAccount]);

  return {
    isFetchingBalancesAndAccount
  };
}
