import { useQueryClient } from '@tanstack/react-query';

import { useMutationCache } from 'data/cache';
import {
  BalanceMutationKeys,
  BalanceQueryKeys,
  BalanceService,
  type IBalanceSolicitationCachePayload,
  type IUseChangeBalanceSolicitationCache,
  type IUseChangeBalanceSolicitationCacheParams
} from 'data/modules/cards/balance';

export function useChangeBalanceSolicitationCache({
  onAfterSuccess
}: IUseChangeBalanceSolicitationCacheParams): IUseChangeBalanceSolicitationCache {
  const queryClient = useQueryClient();

  const {
    mutate: sendBalanceSolicitationToCache,
    isLoading: isSendingBalanceSolicitationCache
  } = useMutationCache({
    mutationKey: [BalanceMutationKeys.SEND_BALANCE_SOLICITATION_CACHE],
    mutationFn: async (payload: IBalanceSolicitationCachePayload) =>
      await BalanceService.sendBalanceSolicitationToCache(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [BalanceQueryKeys.GET_CACHED_BALANCE_SOLICITATIONS]
      });
    }
  });

  function onSubmit(data: IBalanceSolicitationCachePayload): void {
    sendBalanceSolicitationToCache({
      domain: data.domain,
      lifeCycle: data.lifeCycle,
      value: data.value
    });

    onAfterSuccess?.();
  }

  return {
    sendBalanceSolicitationToCache: onSubmit,
    isSendingBalanceSolicitationCache
  };
}
