import { type ChangeEvent } from 'react';

import { Mask } from 'ds';
import { useDsGa4 } from 'ds/hooks/globals';
import { useFormContext } from 'react-hook-form';

import { usePasswordInputControlled } from './usePasswordInputControlled';

import { type IPasswordInputControlledProps } from './PasswordInputControlled.types';

import {
  Caption,
  ClearButton,
  Container,
  Fieldset,
  InputComponent,
  InputContainer,
  Label,
  Legend,
  Wrapper
} from './PasswordInputControlled.styles';

export function PasswordInputControlled({
  name,
  errorMessage,
  defaultValue,
  label,
  disabled,
  large,
  small = true,
  mask,
  readOnly,
  onChangeValue,
  onClearValue,
  inputContainerAttributes,
  registerOptions,
  ...rest
}: IPasswordInputControlledProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  const { IconRight, handleTogglePasswordVisibility, inputType } =
    usePasswordInputControlled();

  const {
    register,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();

  const IconClear = onClearValue && watch().length > 0 ? ClearButton : null;

  return (
    <Wrapper>
      <Container
        $large={large}
        $small={small}
        $disabled={disabled || readOnly}
      >
        <InputContainer
          data-testid='container'
          $disabled={disabled}
          $hasError={!!errors[name]?.message}
          $large={large}
          $small={small}
          $isTouched={!!watch(name)}
          {...inputContainerAttributes}
        >
          <Label>{label}</Label>
          <div>
            <InputComponent
              {...rest}
              readOnly={readOnly}
              type={inputType}
              {...register(name, {
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                  const value = mask
                    ? Mask.apply(mask, event.target.value)
                    : event.target.value;
                  mask && setValue(name, value);
                  onChangeValue && onChangeValue(value);
                },
                onBlur: event => {
                  event.target.value.trim() &&
                    sendDsGaEvent('components', 'input', {
                      eventLabel: label,
                      eventValue: event.target.value
                    });

                  registerOptions?.onBlur?.(event);
                },
                ...registerOptions
              })}
            />
          </div>

          {IconClear && <IconClear onClick={onClearValue} />}

          <IconRight
            onClick={() => {
              if (disabled) return;
              handleTogglePasswordVisibility();
            }}
          />
        </InputContainer>

        <Fieldset $hasLabel={!!label}>
          <Legend>
            <span>{label}</span>
          </Legend>
        </Fieldset>
      </Container>

      {!!errors[name]?.message && (
        <Caption $hasError>{errors[name]?.message as string}</Caption>
      )}
    </Wrapper>
  );
}
