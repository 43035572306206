import { format } from 'date-fns';
import { DSIcons, Tooltip } from 'ds';
import { useTranslation } from 'react-i18next';

import { useMovementsContext } from 'data/contexts';

import { useCopyTransactionIdToClipboard } from 'presentation/pages/cards/Movements/hooks';

import { CustomString } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

import { TransactionDetails } from '../TransactionDetailsModal';

import { LocalItem } from './Withdraw.styles';

export function Withdraw(): JSX.Element {
  const { t } = useTranslation(['cards', 'global']);
  const transactionDetails = useMovementsContext(
    state => state.transactionDetails
  );
  const { handleCopyTransactionIdToClipboard, shouldShowTooltip } =
    useCopyTransactionIdToClipboard();

  return (
    <>
      <TransactionDetails.Header
        description={t('global:withdraw')}
        datetime={`${format(transactionDetails?.datetime!, 'dd/MM/yyyy')} ${t('global:at')} ${format(transactionDetails?.datetime!, 'HH')}h${format(transactionDetails?.datetime!, 'mm')}`}
        value={Currency.format(
          'BRL',
          Math.abs(transactionDetails?.finalBillingAmount!),
          true
        )}
      />

      <TransactionDetails.Section title={t('transactionDetails')}>
        <TransactionDetails.SectionItem>
          <p>{t('cardName')}</p>

          <p className='value'>
            {CustomString.capitalizeWord(
              transactionDetails?.name ||
                transactionDetails?.transferorName ||
                '-'
            )}
          </p>
        </TransactionDetails.SectionItem>

        <TransactionDetails.SectionItem>
          <p>{t('finalOfCard')}</p>

          <p className='value'>{transactionDetails?.cardNumber || '-'}</p>
        </TransactionDetails.SectionItem>

        <TransactionDetails.SectionItem>
          <p>{t('global:local')}</p>

          {transactionDetails?.merchantCity && (
            <p className='value'>
              {CustomString.capitalizeWord(transactionDetails.merchantCity)}
            </p>
          )}

          {!transactionDetails?.merchantCity && (
            <LocalItem>
              <p className='value'>{t('global:uninformed')}</p>
            </LocalItem>
          )}
        </TransactionDetails.SectionItem>

        <TransactionDetails.SectionItem>
          <p>{t('transactionId')}</p>

          <div className='value'>
            {transactionDetails?.transactionId}

            <Tooltip
              trigger='focus'
              placement='top'
              message={t('global:copyed')}
              visible={shouldShowTooltip}
            >
              <div>
                <DSIcons.StickerCopyIcon
                  className='icon'
                  onClick={() => {
                    handleCopyTransactionIdToClipboard(
                      transactionDetails?.transactionId!
                    );
                  }}
                />
              </div>
            </Tooltip>
          </div>
        </TransactionDetails.SectionItem>

        {transactionDetails?.relateds &&
          transactionDetails.relateds.length > 0 && (
            <TransactionDetails.RelatedsContainer>
              {transactionDetails.relateds.map(relatedTransaction => (
                <TransactionDetails.RelatedsItem
                  key={relatedTransaction.transactionId}
                  description={relatedTransaction?.paymentDescription}
                  date={`${format(relatedTransaction?.datetime!, 'dd/MM/yyyy')} ${t('global:at')} ${format(relatedTransaction?.datetime!, 'HH')}h${format(relatedTransaction?.datetime!, 'mm')}`}
                  value={Currency.format(
                    'BRL',
                    relatedTransaction?.finalBillingAmount!,
                    true
                  )}
                />
              ))}
            </TransactionDetails.RelatedsContainer>
          )}
      </TransactionDetails.Section>
    </>
  );
}
