import {
  DangerButton,
  DefaultButton,
  DSIcons,
  Modal,
  SecondaryButton,
  Stepper,
  TextButton
} from 'ds';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { useLangContext, useModalContext } from 'data/contexts';

import { AlertModal } from 'presentation/components/global/AlertModal';
import { Loader } from 'presentation/components/global/Loader';
import { CreateReportForm } from 'presentation/pages/expenses/ExpensesList/components/CreateReportForm';
import {
  RecordRouteDefaultFieldsSteps,
  ReportSelectionStep
} from 'presentation/pages/expenses/ExpensesList/components/Modal/CreateRouteByMapModal';

import { animations } from 'shared/constants/animations';

import { useCreateRouteByMapModal } from './useCreateRouteByMapModal';

import { type ICreateRouteByMapExpenseModalProps } from './CreateRouteByMapModal.types';

import {
  ContainerWithMotion,
  CreateReportButtonContainer,
  ModalContent,
  ModalFooter
} from './CreateRouteByMapModal.styles';

export function CreateRouteByMapModal({
  expenseUuidToUpdate
}: ICreateRouteByMapExpenseModalProps): JSX.Element {
  const { t } = useTranslation('expenses');

  const { lang, currentLangKey } = useLangContext();

  const { handleCloseModal } = useModalContext();

  const {
    setShowCreateReportForm,
    setShowGoBackModal,
    setShowSeparateReportWarningModal,
    setStep,
    showCreateReportForm,
    showGoBackModal,
    showSeparateReportWarningModal,
    step,
    handleClickGoBackButton,
    isMapLoaded,
    mapDirectionsResults,
    distance,
    mapRouteItems,
    setMapRouteItems,
    advanceButtonDisabled,
    allowUserChangeExpenseProjectByReportProject,
    defaultFieldsForm,
    handleChangeFormExpenseCurrency,
    confirmedReportInformationBySelectedReport,
    isExpenseCurrencyDifferentFromReportCurrency,
    selectedReport,
    handleClearSelection,
    handleUpdateExpenseDataBySelectedReport,
    isCreatingRouteByMap,
    reportSelectionForm,
    handleSubmitAllStepsForm,
    updateFormAfterReportCreated,
    readOnlyFieldsWhenUserIsUpdatingExpense,
    userAction,
    showLoadingModal,
    handleClickOverlay,
    defaultStepSelectedCostsCenterId,
    defaultStepSelectedPaymentMethodId,
    defaultStepSelectedProjectId,
    handleConfirmGoBackModal
  } = useCreateRouteByMapModal({ expenseUuidToUpdate });

  const stepperContainerToShowOnCreateReportForm = (
    <Stepper.Container>
      <Stepper.Item
        active={false}
        checked={true}
        allowClick={true}
        onClick={() => {
          setShowCreateReportForm(false);
          setStep('recordRoute');
        }}
        label={
          lang.expenses.modal.create_route_by_map.record_route[currentLangKey]
        }
        number={1}
      />

      <Stepper.Item
        active={false}
        checked={true}
        allowClick={true}
        onClick={() => {
          setShowCreateReportForm(false);
          setStep('defaultFields');
        }}
        label={
          lang.expenses.modal.create_manual_expense.create_expense[
            currentLangKey
          ]
        }
        number={2}
      />

      <Stepper.Item
        active={true}
        allowClick={false}
        label={lang.reports.modal.create_report.create_report[currentLangKey]}
        number={3}
      />
    </Stepper.Container>
  );

  return (
    <>
      <Loader isLoading={showLoadingModal} />
      <Modal.Container
        variant='large'
        onClose={handleClickOverlay}
        containerArrowsStyle={{
          maxWidth: '100%'
        }}
        style={{
          maxWidth: '100%'
        }}
      >
        {showCreateReportForm && (
          <AnimatePresence>
            <ContainerWithMotion {...animations.fadeInOut}>
              <CreateReportForm
                onClickGoBackButton={() => {
                  setShowCreateReportForm(false);
                  setStep('selectReport');
                }}
                onReportCreated={updateFormAfterReportCreated}
                stepperComponent={stepperContainerToShowOnCreateReportForm}
              />
            </ContainerWithMotion>
          </AnimatePresence>
        )}

        {!showCreateReportForm && (
          <AnimatePresence>
            <ContainerWithMotion {...animations.fadeInOut}>
              <Modal.Header>
                <Modal.Title>
                  {userAction === 'create'
                    ? lang.expenses.modal.create_route_by_map.new_route[
                        currentLangKey
                      ]
                    : t('modal.editRoute')}
                </Modal.Title>
                <Modal.IconClose
                  onClick={handleCloseModal}
                  style={{
                    marginRight: '0'
                  }}
                />
              </Modal.Header>
              <Modal.Form
                methods={
                  // nesse caso, para o step de recordRoute, o form não faz diferença, porque não tem campos para serem validados
                  // e a função que trata o submit é a mesma para todos os steps
                  step === 'defaultFields' || step === 'recordRoute'
                    ? defaultFieldsForm.methods
                    : reportSelectionForm.methods
                }
                handleSubmit={handleSubmitAllStepsForm}
              >
                <Modal.Content className='modal-content'>
                  <ModalContent>
                    <Stepper.Container>
                      <Stepper.Item
                        active={step === 'recordRoute'}
                        checked={step !== 'recordRoute'}
                        allowClick={step !== 'recordRoute'}
                        onClick={() => {
                          setStep('recordRoute');
                        }}
                        label={
                          lang.expenses.modal.create_route_by_map.record_route[
                            currentLangKey
                          ]
                        }
                        number={1}
                      />

                      <Stepper.Item
                        active={step === 'defaultFields'}
                        checked={step === 'selectReport'}
                        allowClick={step === 'selectReport'}
                        onClick={() => {
                          setStep('defaultFields');
                        }}
                        label={
                          userAction === 'create'
                            ? lang.expenses.modal.create_manual_expense
                                .create_expense[currentLangKey]
                            : t('modal.editExpense')
                        }
                        number={2}
                      />

                      <Stepper.Item
                        active={step === 'selectReport'}
                        allowClick={false}
                        label={
                          lang.reports.global.select_report[currentLangKey]
                        }
                        number={3}
                      />
                    </Stepper.Container>

                    <RecordRouteDefaultFieldsSteps
                      expenseUuidToUpdate={expenseUuidToUpdate}
                      isMapLoaded={isMapLoaded}
                      mapDirectionsResults={mapDirectionsResults}
                      onChangeFormExpenseCurrency={
                        handleChangeFormExpenseCurrency
                      }
                      readOnlyFieldsWhenUserIsUpdatingExpense={
                        readOnlyFieldsWhenUserIsUpdatingExpense
                      }
                      step={step}
                      distance={distance}
                      mapRouteItems={mapRouteItems}
                      setMapRouteItems={setMapRouteItems}
                    />

                    {step === 'selectReport' && (
                      <ReportSelectionStep
                        onUpdateExpenseDataBySelectedReport={
                          handleUpdateExpenseDataBySelectedReport
                        }
                        allowUserChangeExpenseProjectByReportProject={
                          allowUserChangeExpenseProjectByReportProject
                        }
                        expenseUuidToUpdate={expenseUuidToUpdate}
                        confirmedReportInformationBySelectedReport={
                          confirmedReportInformationBySelectedReport
                        }
                        isExpenseCurrencyDifferentFromReportCurrency={
                          isExpenseCurrencyDifferentFromReportCurrency
                        }
                        onClickToEditExpenseCurrency={handleClickGoBackButton}
                        defaultStepSelectedCostsCenterId={
                          defaultStepSelectedCostsCenterId
                        }
                        defaultStepSelectedPaymentMethodId={
                          defaultStepSelectedPaymentMethodId
                        }
                        defaultStepSelectedProjectId={
                          defaultStepSelectedProjectId
                        }
                      />
                    )}
                  </ModalContent>
                </Modal.Content>
                <Modal.Footer className='modal-footer'>
                  {step === 'selectReport' && (
                    <CreateReportButtonContainer>
                      <TextButton
                        size='small'
                        type='button'
                        onClick={() => {
                          setShowCreateReportForm(true);
                        }}
                        className='create-report-button'
                      >
                        <DSIcons.DocumentFileAddPlusCenter />
                        {lang.expenses.modal.create_report[currentLangKey]}
                      </TextButton>
                    </CreateReportButtonContainer>
                  )}

                  <ModalFooter>
                    <SecondaryButton
                      size='small'
                      onClick={handleClickGoBackButton}
                      type='button'
                    >
                      {lang.global.back[currentLangKey]}
                    </SecondaryButton>
                    <div>
                      {step === 'selectReport' && !!selectedReport && (
                        <DangerButton
                          size='small'
                          type='button'
                          onClick={handleClearSelection}
                        >
                          {lang.global.clear_selection[currentLangKey]}
                        </DangerButton>
                      )}
                      <DefaultButton
                        size='small'
                        type='submit'
                        disabled={advanceButtonDisabled}
                        className='create-expense-button-submit'
                      >
                        {(step === 'defaultFields' || step === 'recordRoute') &&
                          lang.global.advance[currentLangKey]}

                        {step === 'selectReport' &&
                          userAction === 'create' &&
                          !!selectedReport &&
                          lang.expenses.modal.create_manual_expense
                            .create_expense[currentLangKey]}

                        {step === 'selectReport' &&
                          userAction === 'create' &&
                          !selectedReport &&
                          lang.expenses.modal.create_manual_expense
                            .create_separate_expense[currentLangKey]}

                        {step === 'selectReport' &&
                          userAction === 'update' &&
                          t('modal.save')}
                      </DefaultButton>
                    </div>
                  </ModalFooter>
                </Modal.Footer>
              </Modal.Form>
            </ContainerWithMotion>
          </AnimatePresence>
        )}
      </Modal.Container>

      {showGoBackModal && (
        <AlertModal
          onClose={() => {
            setShowGoBackModal(false);
          }}
          cancelButtonProps={{
            onCancel: () => {
              setShowGoBackModal(false);
            }
          }}
          confirmButtonProps={{
            onConfirm: handleConfirmGoBackModal
          }}
          icon={<DSIcons.WarningConfirmationIcon />}
        >
          {expenseUuidToUpdate
            ? t('modal.ifYouGoBackNowUpdate')
            : lang.expenses.modal.create_manual_expense.if_you_go_back_now[
                currentLangKey
              ]}
        </AlertModal>
      )}

      {showSeparateReportWarningModal && (
        <>
          <Loader isLoading={isCreatingRouteByMap} />

          <AlertModal
            onClose={() => {
              setShowSeparateReportWarningModal(false);
            }}
            cancelButtonProps={{
              onCancel: () => {
                setShowSeparateReportWarningModal(false);
              }
            }}
            confirmButtonProps={{
              onConfirm: () => {
                reportSelectionForm.handleSubmit();
              }
            }}
            icon={<DSIcons.WarningConfirmationIcon />}
          >
            {
              lang.expenses.modal.create_manual_expense
                .are_you_sure_to_create_separate_expense[currentLangKey]
            }
          </AlertModal>
        </>
      )}
    </>
  );
}
