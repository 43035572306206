import { useQueryCache } from 'data/cache';
import {
  AccountQueryKeys,
  AccountService,
  type IUseGetOutsourcedUserAccounts,
  type IUseGetOutsourcedUserAccountsParams
} from 'data/modules/cards/account';

export function useGetOutsourcedUserAccounts({
  outsourcedUserUuid,
  enabled
}: IUseGetOutsourcedUserAccountsParams): IUseGetOutsourcedUserAccounts {
  const { data, isFetching } = useQueryCache({
    enabled,
    queryKey: [
      AccountQueryKeys.GET_OUTSOURCED_USER_ACCOUNTS,
      outsourcedUserUuid
    ],
    queryFn: async () =>
      await AccountService.getOutsourcedUserAccounts({ outsourcedUserUuid })
  });

  return {
    outsourcedUserAccounts: data,
    isFetchingOutsourcedUserAccounts: isFetching
  };
}
