import { useQueryCache } from 'data/cache';
import { CardGroupsQueryKeys } from 'data/modules/cards/cardGroups/keys/cardGroups.keys';
import CardGroupsService from 'data/modules/cards/cardGroups/services/CardGroups.service';
import { type IUseGetAllocatedBalanceCardGroup } from 'data/modules/cards/cardGroups/types/cardGroups.types';

export function useGetAllocatedBalanceCardGroup(): IUseGetAllocatedBalanceCardGroup {
  const {
    data: allocatedBalanceCardGroup,
    isFetching: isFetchingAllocatedBalanceCardGroup
  } = useQueryCache({
    queryKey: [CardGroupsQueryKeys.GET_ALLOCATE_BALANCE_CARD_GROUP],
    queryFn: async () => await CardGroupsService.getAllocatedBalanceCardGroup()
  });

  return {
    allocatedBalanceCardGroup,
    isFetchingAllocatedBalanceCardGroup
  };
}
