import { useLangContext } from 'data/contexts';
import { useChangeEmailPreferences } from 'data/modules/settings/emailPreferences';

import { Switch } from 'presentation/components/base/Input';
import { Loader } from 'presentation/components/global/Loader';
import { Title } from 'presentation/components/global/Title';

import {
  Container,
  CustomButton,
  FormContainer
} from './EmailPreferences.styles';

export function EmailPreferences(): JSX.Element {
  const [currentLangKey, emailPreferences, global] = useLangContext(state => [
    state.currentLangKey,
    state.lang.settings.emailPreferences,
    state.lang.global
  ]);

  const {
    changeEmailPreferencesControl,
    handleSubmit,
    isChangingEmailPreferences
  } = useChangeEmailPreferences();

  return (
    <Container>
      <Loader isLoading={isChangingEmailPreferences} />

      <Title text={emailPreferences.page_title[currentLangKey]} />

      <FormContainer>
        <form onSubmit={handleSubmit}>
          <Switch
            control={changeEmailPreferencesControl}
            name='receivesManagementSummaryEmail'
            label={
              emailPreferences.receives_management_summary_email[currentLangKey]
            }
            labelPosition='right'
          />

          <CustomButton
            type='submit'
            color='success'
            size='xl'
          >
            {global.save[currentLangKey]}
          </CustomButton>
        </form>
      </FormContainer>
    </Container>
  );
}
