import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import {
  type ICreateManualExpenseFirstStepFieldsForm,
  type IObservationAttachment,
  type IUseFirstStepFormSchemaParams
} from 'data/modules/expenses/types/expenses.types';
import { useGetExpenseParameters } from 'data/modules/expenses/useCases/get-expense-parameters/useGetExpenseParameters';

import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';
import { Validate } from 'shared/utils/validation';

export function useFirstStepFormSchema({
  userAction
}: IUseFirstStepFormSchemaParams): ObjectSchema<ICreateManualExpenseFirstStepFieldsForm> {
  const { lang, currentLangKey } = useLangContext();

  const { t } = useTranslation('expenses');

  const { expenseParameters } = useGetExpenseParameters();

  const validateExchangeField = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        const launchMulticurrencyCheckingAccount =
          expenseParameters?.checkingAccountMulticurrency;

        const companyParameterMulticurrencyBlocked =
          expenseParameters?.usesCheckingAccount &&
          !launchMulticurrencyCheckingAccount;

        if (companyParameterMulticurrencyBlocked && value === '') {
          return false;
        }
      }

      return true;
    },
    [
      expenseParameters?.usesCheckingAccount,
      expenseParameters?.checkingAccountMulticurrency
    ]
  );

  const createManualExpenseFirstStepSchema: ObjectSchema<ICreateManualExpenseFirstStepFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];
      const fieldInvalidMessage = lang.schemas.invalid_value[currentLangKey];

      return yup.object({
        // se o parâmetro preferencias_descricaoDespesaObrigatoria for true, então o campo description é obrigatório
        description: expenseParameters?.isExpenseDescriptionMandatory
          ? yup.string().required(fieldRequiredMessage)
          : yup.string(),
        currency: yup.string().required(fieldRequiredMessage),
        value: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'notANumber',
            message: fieldInvalidMessage,
            test: value => {
              if (value) {
                const numberValue = Currency.parseToFloat(value);

                return !isNaN(numberValue);
              }

              return true;
            }
          }),
        exchangeRate: yup
          .string()
          .test({
            name: 'exchangeRate',
            message: fieldRequiredMessage,
            test: validateExchangeField
          })
          .test({
            name: 'notANumber',
            message: fieldInvalidMessage,
            test: value => {
              if (value) {
                const numberValue = Currency.parseToFloat(value);

                return !isNaN(numberValue);
              }

              return true;
            }
          }),
        convertedValue: yup
          .string()
          .test({
            name: 'convertedValue',
            message: fieldRequiredMessage,
            test: validateExchangeField
          })
          .test({
            name: 'notANumber',
            message: fieldInvalidMessage,
            test: value => {
              if (value) {
                const numberValue = Currency.parseToFloat(value);

                return !isNaN(numberValue);
              }

              return true;
            }
          }),
        date: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'date',
            message: lang.schemas.invalid_date[currentLangKey],
            test: (value: string) => Validate.isDate(value, 'yyyy-MM-dd')
          })
          .test(
            'validate-future-days',
            (value: string, { createError, path }) => {
              const expensesPolicyType = expenseParameters?.policyType;

              if (expensesPolicyType === 'USUARIO' && userAction === 'create') {
                const amountOfFutureDaysThatIsAllowToCreateExpense =
                  expenseParameters?.daysPolicy.futureDays;

                if (!amountOfFutureDaysThatIsAllowToCreateExpense) {
                  return true;
                }

                const todayIsoString = CustomDate.formatDateToIso(new Date());
                const selectedDate = CustomDate.parseIsoToDate(value);

                const differenceBetweenDates =
                  CustomDate.differenceBetweenDates(
                    CustomDate.parseIsoToDate(todayIsoString),
                    selectedDate
                  );

                if (
                  differenceBetweenDates <
                  -amountOfFutureDaysThatIsAllowToCreateExpense
                ) {
                  const exceedInDays =
                    Math.abs(differenceBetweenDates) -
                    amountOfFutureDaysThatIsAllowToCreateExpense;

                  return createError({
                    path,
                    message: t(
                      'errors.expenseExceedsTheMaximumAmountOfFutureDays',
                      {
                        days: exceedInDays
                      }
                    )
                  });
                }
              }

              return true;
            }
          )
          .test(
            'validate-past-days',
            (value: string, { createError, path }) => {
              const expensesPolicyType = expenseParameters?.policyType;

              if (expensesPolicyType === 'USUARIO' && userAction === 'create') {
                const amountOfPastDaysThatIsAllowToCreateExpense =
                  expenseParameters?.daysPolicy.pastDays;

                if (!amountOfPastDaysThatIsAllowToCreateExpense) {
                  return true;
                }

                const todayIsoString = CustomDate.formatDateToIso(new Date());
                const selectedDate = CustomDate.parseIsoToDate(value);

                const differenceBetweenDates =
                  CustomDate.differenceBetweenDates(
                    CustomDate.parseIsoToDate(todayIsoString),
                    selectedDate
                  );

                if (
                  differenceBetweenDates >
                  amountOfPastDaysThatIsAllowToCreateExpense
                ) {
                  const exceedInDays =
                    Math.abs(differenceBetweenDates) -
                    amountOfPastDaysThatIsAllowToCreateExpense;

                  return createError({
                    path,
                    message: t(
                      'errors.expenseExceedsTheMaximumAmountOfDaysPassed',
                      {
                        days: exceedInDays
                      }
                    )
                  });
                }
              }

              return true;
            }
          ),
        type: yup.string().required(fieldRequiredMessage),
        costsCenter: yup
          .object({
            value: yup.string(),
            label: yup.string()
          })
          .test({
            name: 'costsCenterRequired',
            message: fieldRequiredMessage,
            test: Validate.isOptionSelected
          }),
        // se o parâmetro preferencias_formaPagamentoObrigatoria for true, então o campo paymentMethod é obrigatório
        paymentMethod: expenseParameters?.isPaymentMethodMandatory
          ? yup.string().required(fieldRequiredMessage)
          : yup.string().defined(),
        refundable: yup.boolean().required(fieldRequiredMessage),
        observation: expenseParameters?.isExpensesObsMandatory
          ? yup.string().required(fieldRequiredMessage)
          : yup.string().defined(),
        observationAttachments: yup
          .mixed<(File | IObservationAttachment)[]>()
          .test({
            name: 'observationAttachmentsSize',
            message: lang.schemas.files_must_be_less_than_16mb[currentLangKey],
            test: value => {
              if (value && Array.isArray(value)) {
                const validateFiles = Array.from(value).filter(
                  file => file instanceof File
                );

                if (validateFiles.length === 0) {
                  return true;
                }

                return validateFiles.every(
                  file => (file as File).size <= 16000000
                );
              }

              return true;
            }
          }),
        apportionment: yup.array().of(
          yup.object({
            project: yup
              .object({
                value: yup.string(),
                label: yup.string()
              })
              .test({
                name: 'projectRequired',
                message: fieldRequiredMessage,
                test: Validate.isOptionSelected
              }),
            percentage: yup.number().required(fieldRequiredMessage),
            value: yup.number().required(fieldRequiredMessage)
          })
        ),
        receipt: yup
          .mixed<File | string>()
          .test({
            name: 'receiptSize',
            message: lang.schemas.file_must_be_less_than_16mb[currentLangKey],
            test: value => {
              if (value && value instanceof File) {
                return value?.size <= 16000000;
              }

              return true;
            }
          })
          .defined()
          .nullable()
      });
    }, [
      lang,
      currentLangKey,
      expenseParameters,
      validateExchangeField,
      t,
      userAction
    ]);

  return createManualExpenseFirstStepSchema;
}
