import {
  type IAnalysisResume,
  type IAnalysisResumePayload,
  type IPersistenceAnalysisResume,
  type IPersistenceAnalysisResumePayload
} from 'data/modules/analytics';

import { Currency } from 'shared/utils/format';

class GetAnalysisResumeMapper {
  toPersistence(
    payload: IAnalysisResumePayload
  ): IPersistenceAnalysisResumePayload {
    return {
      cost_centers: payload.costCenters,
      date_to_consider: payload.dateToConsider,
      expense_types: payload.expenseTypes,
      final_date: payload.finalDate,
      initial_date: payload.initialDate,
      payment_form: payload.paymentForm,
      payment_type: payload.paymentType,
      projects: payload.projects,
      report_codes: payload.reportCodes,
      status: payload.status,
      users: payload.users
    };
  }

  toDomain(persistence: IPersistenceAnalysisResume): IAnalysisResume {
    return {
      averagePerExpense: Currency.parseToFloat(
        persistence?.average_per_expense ?? '0'
      ),
      hasPendingConversion: persistence.has_pending_conversion,
      sumExpenses: Currency.parseToFloat(persistence?.sum_expenses ?? '0'),
      sumNoRefundable: Currency.parseToFloat(
        persistence?.sum_no_refundable ?? '0'
      ),
      sumRefundable: Currency.parseToFloat(persistence?.sum_refundable ?? '0'),
      totalExpenses: persistence.total_expenses,
      totalNoRefundable: persistence.total_no_refundable,
      totalRefundable: persistence.total_refundable,
      totalReports: persistence.total_reports,
      totalUsers: persistence.total_users,
      userWithExpense: persistence.user_with_expense
    };
  }
}

export default new GetAnalysisResumeMapper();
