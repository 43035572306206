import { type IAppliedFiltersItemProps } from './AppliedFiltersItem.types';

import {
  Container,
  FilterItemLabel,
  FilterItemValue
} from './AppliedFiltersItem.styles';

export function AppliedFiltersItem({
  label,
  children,
  ...props
}: IAppliedFiltersItemProps): JSX.Element {
  return (
    <Container {...props}>
      <FilterItemLabel>{label}</FilterItemLabel>
      <FilterItemValue>{children}</FilterItemValue>
    </Container>
  );
}
