import { tokens } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useAnalysisUserFiltersContext } from 'data/contexts';
import { useGetAnalysisUserHeader } from 'data/modules/analytics';

import { type IUseAnalytics } from 'presentation/pages/analytics/Analytics/Analytics.types';

import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

export function useAnalytics(): IUseAnalytics {
  const defaultFilters = useAnalysisUserFiltersContext(
    useShallow(state => state.defaultFilters)
  );

  const { analysisUserHeader, isLoadingAnalysisUserHeader } =
    useGetAnalysisUserHeader({
      ...defaultFilters,
      paymentType: defaultFilters.reimbursableNonReimbursable,
      finalDate: CustomDate.formatDateToIso(new Date()),
      initialDate: CustomDate.formatDateToIso(
        CustomDate.getDateSubtractingDays(new Date(), 7)
      ),
      users: [],
      perPage: 5,
      page: 1
    });

  const userAnalysisChartData =
    analysisUserHeader?.map(user => ({
      name: user.name,
      total: user.total
    })) ?? [];

  const userAnalysisChartValues = userAnalysisChartData.map(
    ({ total }) => total
  );

  const hasHigherValues = Math.max(...userAnalysisChartValues) > 15;

  return {
    isLoadingChartsData: isLoadingAnalysisUserHeader,
    charts: {
      userAnalysis: {
        withNoData: !userAnalysisChartData.length,
        data: {
          pointWidth: 24,
          height: '240px',
          enableLegend: false,
          enableTooltip: false,
          colors: [tokens.brandColorPrimaryMedium],
          label: {
            enabled: true,
            formatter: options => {
              return Currency.format('BRL', options.point.y ?? 0, true);
            }
          },
          yAxios: {
            tickAmount: 6,
            enabled: true,
            showGridLine: true,
            format: 'R$ {value}',
            formatter: hasHigherValues
              ? undefined
              : options => {
                  return Currency.format('BRL', Number(options.value), true);
                }
          },
          xAxios: {
            enabled: true,
            categories: userAnalysisChartData.map(({ name }) => name)
          },
          series: [
            {
              name: 'user-analysis',
              data: userAnalysisChartValues
            }
          ],
          responsiveOptions: [
            {
              condition: {
                maxWidth: 450
              },
              chartOptions: {
                yAxis: {
                  tickAmount: undefined
                }
              }
            }
          ]
        }
      }
    }
  };
}
