import { useQueryCache } from 'data/cache';
import {
  type IUseGetCompanyStatement,
  type IUseGetCompanyStatementParams,
  StatementQueryKeys,
  StatementService
} from 'data/modules/cards/statement';

export function useGetCompanyStatement({
  enabled,
  ...payload
}: IUseGetCompanyStatementParams): IUseGetCompanyStatement {
  const { data, isLoading, isFetching, isError } = useQueryCache({
    queryKey: [StatementQueryKeys.GET_COMPANY_STATEMENT, payload],
    queryFn: async () => await StatementService.getCompanyStatement(payload),
    enabled
  });

  return {
    companyStatement: data,
    isLoadingCompanyStatement: isLoading,
    isFetchingCompanyStatement: isFetching,
    isErrorCompanyStatement: isError
  };
}
