import { api, apiWeb } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import {
  type ICardsExpense,
  type ICreateFuelExpensePayload,
  type ICreateManualExpensePayload,
  type ICreateRouteByMapPayload,
  type ICreateRouteExpensePayload,
  type IDeleteExpensesPayload,
  type IDetailedExpense,
  type IExpensePaginatedResponse,
  type IExpenseParameters,
  type IGetExpensePayload,
  type IGetExpensesListByTransactionsPayload,
  type IGetNumberOfExpensesByStatusPayload,
  type IGetPaginatedExpensesPayload,
  type IGetUserExpensesPendingAccountability,
  type IGetUserExpensesPendingAccountabilityPayload,
  type IPersistenceExpense,
  type IPersistenceExpenseList,
  type IPersistenceShowExpense,
  type IPersistenceUserExpensesPendingAccountability,
  type IPersistenceValidateDateLimitsByProjectResponse,
  type IPersistenceValidateMileagePolicyByProjectResponse,
  type IPersistenceValidateMileagePolicyByUserResponse,
  type IPersistenceWebExpenseParameters,
  type IStatusTabObjectPersistence,
  type ITypesOfExpense,
  type IUpdateFuelExpensePayload,
  type IUpdateManualExpensePayload,
  type IUpdateRouteByMapPayload,
  type IUpdateRouteExpensePayload,
  type IValidateDateLimitsByProjectPayload,
  type IValidateDateLimitsByProjectResponse,
  type IValidateMileagePolicyByProjectPayload,
  type IValidateMileagePolicyByProjectResponse,
  type IValidateMileagePolicyByUserPayload,
  type IValidateMileagePolicyByUserResponse,
  type StatusTabObjectType
} from 'data/modules/expenses';
import { type IDefaultResponse } from 'data/modules/global';

import {
  CreateFuelExpenseMapper,
  CreateManualExpenseMapper,
  CreateRouteByMapMapper,
  CreateRouteExpenseMapper,
  ExpensesPendingAccountabilityMapper,
  GetExpenseMapper,
  GetExpenseParametersMapper,
  GetExpensesListByTransactionsIdsMapper,
  GetNumberOfExpensesByStatusMapper,
  GetPaginatedExpensesMapper,
  UpdateFuelExpenseMapper,
  UpdateManualExpenseMapper,
  UpdateRouteByMapMapper,
  UpdateRouteExpenseMapper,
  ValidateDateLimitsByProjectMapper,
  ValidateMileagePolicyByProjectMapper,
  ValidateMileagePolicyByUserMapper
} from './mappers';

class ExpensesService {
  async getExpensesListByTransactionsIds({
    transactionsIds
  }: IGetExpensesListByTransactionsPayload): Promise<ICardsExpense[]> {
    const response = await api.get<
      IDefaultResponse<IPersistenceExpense[] | null>
    >(`/pay/transactions/expenses`, {
      params: {
        transactions_ids: transactionsIds.join(',')
      }
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data?.map(GetExpensesListByTransactionsIdsMapper.toDomain) ?? [];
  }

  async getUserExpensesPendingAccountability(
    payload: IGetUserExpensesPendingAccountabilityPayload
  ): Promise<IGetUserExpensesPendingAccountability[]> {
    const response = await api.get<
      IDefaultResponse<IPersistenceUserExpensesPendingAccountability[] | null>
    >('/expenses/pending-accountability', {
      params: {
        user_uuid: payload.userUuid,
        status: ['ABERTO', 'AVULSA', 'ENVIADO', 'REABERTO', 'REPROVADO']
      }
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data ? ExpensesPendingAccountabilityMapper.toDomain(data) : [];
  }

  async getExpenseParameters(): Promise<IExpenseParameters> {
    const response =
      await apiWeb.get<IDefaultResponse<IPersistenceWebExpenseParameters>>(
        `/parameters/expenses`
      );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetExpenseParametersMapper.toDomain(data);
  }

  async getExpense({
    expenseUuid
  }: IGetExpensePayload): Promise<IDetailedExpense> {
    const response = await apiWeb.get<
      IDefaultResponse<IPersistenceShowExpense>
    >(`/expenses/${expenseUuid}`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetExpenseMapper.toDomain(data);
  }

  async getPaginatedExpenses({
    endDate,
    paymentMethod,
    page,
    perPage,
    refundable,
    search,
    startDate,
    typeOfExpense,
    statusTab,
    order,
    orderBy
  }: Partial<IGetPaginatedExpensesPayload>): Promise<IExpensePaginatedResponse> {
    const isRefundable =
      refundable?.length === 2 || refundable?.length === 0
        ? undefined
        : refundable?.includes('yes') || !refundable?.includes('no');

    const response = await apiWeb.get<
      IDefaultResponse<IPersistenceExpenseList[] | null>
    >(`/expenses/list-by-user`, {
      params: {
        order,
        order_by: orderBy,
        start_date: startDate,
        final_date: endDate,
        description: search,
        type: typeOfExpense,
        payment_method: paymentMethod,
        is_reimbursable: isRefundable,
        status: statusTab,
        per_page: perPage,
        page
      }
    });

    const data = ResponseAdapter.formatRegularResponse(response.data);

    return {
      total: data.total ?? 0,
      lastPage: data.lastPage ?? 0,
      data: data.data ? data.data?.map(GetPaginatedExpensesMapper.toDomain) : []
    };
  }

  async getNumberOfExpensesByStatus({
    endDate,
    paymentMethod,
    refundable,
    search,
    startDate,
    typeOfExpense,
    statusTab
  }: IGetNumberOfExpensesByStatusPayload): Promise<StatusTabObjectType | null> {
    const isRefundable =
      refundable?.length === 2 || refundable?.length === 0
        ? undefined
        : refundable?.includes('yes') || !refundable?.includes('no');

    const response = await apiWeb.get<
      IDefaultResponse<IStatusTabObjectPersistence[]>
    >(`/expenses/quantity-per-status`, {
      params: {
        start_date: startDate,
        final_date: endDate,
        description: search,
        type: typeOfExpense,
        payment_method: paymentMethod,
        is_reimbursable: isRefundable,
        status: statusTab
      }
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetNumberOfExpensesByStatusMapper.toDomain(data);
  }

  async getTypesOfExpenses(): Promise<ITypesOfExpense[]> {
    const response = await apiWeb.get<IDefaultResponse<ITypesOfExpense[]>>(
      `/expense-types/list-by-user`
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async createManualExpense(
    payload: ICreateManualExpensePayload
  ): Promise<void> {
    await apiWeb.post<IDefaultResponse<null>>(
      `/expenses`,
      CreateManualExpenseMapper.toPersistence(payload),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }

  async createFuelExpense(payload: ICreateFuelExpensePayload): Promise<void> {
    await apiWeb.post<IDefaultResponse<null>>(
      `/fueling`,
      CreateFuelExpenseMapper.toPersistence(payload),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }

  async updateFuelExpense(
    payload: IUpdateFuelExpensePayload
  ): Promise<IDetailedExpense> {
    const {
      data: { data }
    } = await apiWeb.post<IDefaultResponse<IPersistenceShowExpense>>(
      `/fueling/${payload.expenseUuid}`,
      UpdateFuelExpenseMapper.toPersistence(payload),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return GetExpenseMapper.toDomain(data);
  }

  async updateManualExpense(
    payload: IUpdateManualExpensePayload
  ): Promise<IDetailedExpense> {
    const response = await apiWeb.post<
      IDefaultResponse<IPersistenceShowExpense>
    >(
      `/expenses/${payload.expenseUuid}`,
      UpdateManualExpenseMapper.toPersistence(payload),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetExpenseMapper.toDomain(data);
  }

  async createRouteExpense(payload: ICreateRouteExpensePayload): Promise<null> {
    const response = await apiWeb.post<IDefaultResponse<null>>(
      `/routes/manual`,
      CreateRouteExpenseMapper.toPersistence(payload),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async updateRouteExpense(
    payload: IUpdateRouteExpensePayload
  ): Promise<IDetailedExpense> {
    const response = await apiWeb.post<
      IDefaultResponse<IPersistenceShowExpense>
    >(
      `/routes/manual/${payload.expenseUuid}`,
      UpdateRouteExpenseMapper.toPersistence(payload),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetExpenseMapper.toDomain(data);
  }

  async createRouteByMap(payload: ICreateRouteByMapPayload): Promise<null> {
    const response = await apiWeb.post<IDefaultResponse<null>>(
      `/routes/map`,
      CreateRouteByMapMapper.toPersistence(payload),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async updateRouteByMap(
    payload: IUpdateRouteByMapPayload
  ): Promise<IDetailedExpense> {
    const response = await apiWeb.post<
      IDefaultResponse<IPersistenceShowExpense>
    >(
      `/routes/map/${payload.expenseUuid}`,
      UpdateRouteByMapMapper.toPersistence(payload),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetExpenseMapper.toDomain(data);
  }

  async validateDateLimitsByProject(
    payload: IValidateDateLimitsByProjectPayload
  ): Promise<IValidateDateLimitsByProjectResponse | null> {
    const response = await apiWeb.post<
      IDefaultResponse<IPersistenceValidateDateLimitsByProjectResponse | null>
    >(
      `/expenses/validate-date-limits-by-project`,
      ValidateDateLimitsByProjectMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data !== null
      ? ValidateDateLimitsByProjectMapper.toDomain(data)
      : null;
  }

  async validateMileagePolicyByUser(
    payload: IValidateMileagePolicyByUserPayload
  ): Promise<IValidateMileagePolicyByUserResponse | null> {
    const response = await apiWeb.post<
      IDefaultResponse<IPersistenceValidateMileagePolicyByUserResponse | null>
    >(
      `/routes/validate-kilometrage-by-user`,
      ValidateMileagePolicyByUserMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data !== null
      ? ValidateMileagePolicyByUserMapper.toDomain(data)
      : null;
  }

  async validateMileagePolicyByProject(
    payload: IValidateMileagePolicyByProjectPayload
  ): Promise<IValidateMileagePolicyByProjectResponse | null> {
    const response = await apiWeb.post<
      IDefaultResponse<IPersistenceValidateMileagePolicyByProjectResponse | null>
    >(
      `/routes/validate-kilometrage-by-project`,
      ValidateMileagePolicyByProjectMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data !== null
      ? ValidateMileagePolicyByProjectMapper.toDomain(data)
      : null;
  }

  async deleteExpenses(payload: IDeleteExpensesPayload): Promise<void> {
    await apiWeb.delete<IDefaultResponse<null>>(`/expenses`, {
      params: payload
    });
  }
}

export default new ExpensesService();
