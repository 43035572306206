import {
  DefaultButton,
  DefaultTable,
  DSIcons,
  FloatingMenu,
  Pagination,
  Select,
  TextInput,
  tokens
} from 'ds';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useLangContext, useModalContext } from 'data/contexts';

import { AlertModal } from 'presentation/components/global/AlertModal';
import { type IAlertModalProps } from 'presentation/components/global/AlertModal/AlertModal.types';
import { ErrorModal } from 'presentation/components/global/ErrorModal';
import { Loader } from 'presentation/components/global/Loader';

import noVehicleImage from 'shared/assets/images/fuel/no_vehicle.png';
import { Icons } from 'shared/constants/global';
import { CustomDate } from 'shared/utils/custom';

import { CreateVehicleModal } from './components/CreateVehicleModal';
import { LinkOrUnlinkMembersModal } from './components/LinkOrUnlinkMembersModal ';
import { ViewVehicleModal } from './components/ViewVehicleModal ';
import { useVehiclesList } from './useVehiclesList';

import {
  Container,
  FiltersContainer,
  HeaderContainer,
  PageTitle,
  StyledTableTbody,
  StyledTableThead,
  TableAndPaginationContainer,
  TableContainer,
  TableNoCententContainer,
  TableNoCententDescription,
  TableNoCententTitle,
  TableOverflowContainer
} from './VehiclesList.styles';

export function VehiclesList(): JSX.Element {
  const { t } = useTranslation(['fuel', 'global']);

  const { currentLangKey } = useLangContext();

  const { handleOpenModal, visibleModal, handleCloseModal, errorMessage } =
    useModalContext();

  const {
    filters,
    handleChangeFilters,
    isLoadingPaginatedCompanyVehicles,
    isLoadingTypesOfFuels,
    isLoadingTypesOfOwners,
    isLoadingTypesOfVehicles,
    paginatedCompanyVehicles,
    typesOfFuelsOptions,
    typesOfOwnersOptions,
    typesOfVehiclesOptions,
    setVehicleIndex,
    vehicleIndex,
    setVehicleIdToUpdate,
    vehicleIdToUpdate,
    handleDeleteVehicle,
    isDeletingVehicle
  } = useVehiclesList();

  const { id } = useParams();

  const isValidvehicleIndex =
    vehicleIndex !== -1 &&
    !!paginatedCompanyVehicles?.data &&
    !isLoadingPaginatedCompanyVehicles &&
    vehicleIndex < paginatedCompanyVehicles.data.length;

  const selectedVehicleId = isValidvehicleIndex
    ? paginatedCompanyVehicles.data[vehicleIndex].id
    : parseInt(id ?? '');

  const vehicleDeleteActionModalData: Pick<
    IAlertModalProps,
    'children' | 'title'
  > = {
    children: t('fuel:modal.deleteVehicleDescription'),
    title: t('fuel:modal.deleteVehicleTitle')
  };

  return (
    <>
      <Loader
        isLoading={isLoadingPaginatedCompanyVehicles || isDeletingVehicle}
      />

      <Container>
        <HeaderContainer>
          <PageTitle>{t('vehicleRegistration')}</PageTitle>

          <DefaultButton
            size='small'
            className='new-vehicle-button'
            onClick={() => {
              handleOpenModal('createVehicle');
              setVehicleIdToUpdate(undefined);
            }}
          >
            {t('vehiclePage.newVehicle')}
          </DefaultButton>
        </HeaderContainer>

        <TableContainer>
          <FiltersContainer>
            <TextInput.Default
              small
              label={t('vehiclePage.searchByPlateOrModel')}
              onChangeValue={value => {
                handleChangeFilters('search', value);
              }}
              value={filters.search}
              iconLeft='SearchBigIcon'
              placeholder={t('vehiclePage.searchByPlateOrModel')}
            />

            <Select.Default
              small
              isLoading={isLoadingTypesOfVehicles}
              name='vehicleType'
              onChange={value => {
                handleChangeFilters('vehicleType', value as string);
              }}
              options={typesOfVehiclesOptions}
              value={filters.vehicleType}
              hasArrowDownIndicator
              label={t('vehiclePage.vehicleType')}
              placeholder={t('vehiclePage.vehicleType')}
            />

            <Select.Default
              small
              isLoading={isLoadingTypesOfFuels}
              name='fuelType'
              onChange={value => {
                handleChangeFilters('fuelType', value as string);
              }}
              options={typesOfFuelsOptions}
              value={filters.fuelType}
              hasArrowDownIndicator
              label={t('vehiclePage.fuelType')}
              placeholder={t('vehiclePage.fuelType')}
            />

            <Select.Default
              small
              name='owner'
              isLoading={isLoadingTypesOfOwners}
              onChange={value => {
                handleChangeFilters('owner', value as string);
              }}
              options={typesOfOwnersOptions}
              value={filters.owner}
              hasArrowDownIndicator
              label={t('vehiclePage.owner')}
              placeholder={t('vehiclePage.owner')}
            />
          </FiltersContainer>

          {!isLoadingPaginatedCompanyVehicles && (
            <TableAndPaginationContainer>
              <TableOverflowContainer>
                <DefaultTable.Container>
                  <StyledTableThead>
                    <DefaultTable.Tr>
                      <DefaultTable.Th
                        order={filters.order}
                        isActive={filters.orderBy === 'data_criacao'}
                        onClick={() => {
                          handleChangeFilters('orderBy', 'data_criacao');
                        }}
                        width='15%'
                      >
                        {t('vehiclePage.registrationDate')}
                      </DefaultTable.Th>

                      <DefaultTable.Th
                        order={filters.order}
                        isActive={filters.orderBy === 'modelo'}
                        onClick={() => {
                          handleChangeFilters('orderBy', 'modelo');
                        }}
                        width='20%'
                      >
                        {t('vehiclePage.vehicleModel')}
                      </DefaultTable.Th>

                      <DefaultTable.Th
                        order={filters.order}
                        isActive={filters.orderBy === 'placa'}
                        onClick={() => {
                          handleChangeFilters('orderBy', 'placa');
                        }}
                      >
                        {t('vehiclePage.plate')}
                      </DefaultTable.Th>

                      <DefaultTable.Th>
                        {t('vehiclePage.vehicleTypeTable')}
                      </DefaultTable.Th>

                      <DefaultTable.Th>
                        {t('vehiclePage.fuelTypeTable')}
                      </DefaultTable.Th>

                      <DefaultTable.Th>
                        {t('vehiclePage.owner')}
                      </DefaultTable.Th>

                      <DefaultTable.Th width='8%'>
                        {t('global:actions')}
                      </DefaultTable.Th>
                    </DefaultTable.Tr>
                  </StyledTableThead>
                  <StyledTableTbody>
                    {paginatedCompanyVehicles?.data &&
                      paginatedCompanyVehicles.data.length > 0 &&
                      paginatedCompanyVehicles.data.map((vehicle, index) => (
                        <DefaultTable.Tr
                          onClick={() => {
                            setVehicleIndex(index);
                            handleOpenModal('viewVehicle');
                          }}
                          key={vehicle.id}
                        >
                          <DefaultTable.Td>
                            {CustomDate.formatFromISO(vehicle.registrationDate)}
                          </DefaultTable.Td>
                          <DefaultTable.Td truncate>
                            {vehicle.model}
                          </DefaultTable.Td>

                          <DefaultTable.Td truncate>
                            {vehicle.plate}
                          </DefaultTable.Td>

                          <DefaultTable.Td truncate>
                            {vehicle.vehicleType.description}
                          </DefaultTable.Td>

                          <DefaultTable.Td truncate>
                            {vehicle.fuels
                              .map(item => item.description)
                              .join(', ')}
                          </DefaultTable.Td>

                          <DefaultTable.Td truncate>
                            {vehicle.owner}
                          </DefaultTable.Td>

                          <DefaultTable.Td
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            <FloatingMenu
                              options={[
                                {
                                  label: t('vehiclePage.editVehicle'),
                                  icon: (
                                    <Icons.EditPenIcon
                                      color={tokens.brandColorPrimaryMedium}
                                    />
                                  ),
                                  onClick: () => {
                                    setVehicleIdToUpdate(vehicle.id);
                                    handleOpenModal('createVehicle');
                                  }
                                },
                                {
                                  label: t('linkOrUnlinkMember'),
                                  icon: <Icons.UserProfileCogIcon />,
                                  onClick: () => {
                                    setVehicleIdToUpdate(vehicle.id);
                                    handleOpenModal('linkMemberToVehicle');
                                  }
                                },
                                {
                                  label: t('vehiclePage.deleteVehicle'),
                                  icon: (
                                    <DSIcons.TrashDeleteBinIcon
                                      color={tokens.feedbackColorErrorPure}
                                    />
                                  ),
                                  onClick: () => {
                                    setVehicleIdToUpdate(vehicle.id);
                                    handleOpenModal('deleteVehicle');
                                  }
                                }
                              ]}
                              trigger={<DSIcons.DotsActionIcon />}
                            />
                          </DefaultTable.Td>
                        </DefaultTable.Tr>
                      ))}
                  </StyledTableTbody>
                </DefaultTable.Container>
              </TableOverflowContainer>

              {(!paginatedCompanyVehicles?.data ||
                paginatedCompanyVehicles.data.length === 0) && (
                <TableNoCententContainer>
                  <img src={noVehicleImage} />
                  <div>
                    <TableNoCententTitle>
                      {t('vehiclePage.noRegisteredVehicles')}
                    </TableNoCententTitle>

                    <TableNoCententDescription>
                      {t('vehiclePage.clickNewVehicleToRegister')}
                    </TableNoCententDescription>
                  </div>

                  <DefaultButton
                    size='small'
                    onClick={() => {
                      handleOpenModal('createVehicle');
                    }}
                  >
                    {t('vehiclePage.newVehicle')}
                  </DefaultButton>
                </TableNoCententContainer>
              )}

              {!!paginatedCompanyVehicles?.data &&
                paginatedCompanyVehicles?.data.length > 0 && (
                  <Pagination.Container
                    currentPage={filters.page}
                    perPage={filters.perPage}
                    totalPage={paginatedCompanyVehicles.lastPage ?? 0}
                    className='pagination-vehicles-container'
                  >
                    <Pagination.Label langKey={currentLangKey} />

                    <Pagination.Control
                      onChangePage={page => {
                        handleChangeFilters('page', page);
                      }}
                    />

                    <Pagination.Pages
                      langKey={currentLangKey}
                      onChangePerPage={perPage => {
                        handleChangeFilters('perPage', perPage);
                      }}
                      options={[50, 20, 10].filter(
                        item => item !== filters.perPage
                      )}
                    />
                  </Pagination.Container>
                )}
            </TableAndPaginationContainer>
          )}
        </TableContainer>
      </Container>

      {visibleModal === 'createVehicle' && (
        <CreateVehicleModal vehicleIdToUpdate={vehicleIdToUpdate} />
      )}

      {visibleModal === 'viewVehicle' && (
        <ViewVehicleModal
          onDeleteVehicle={id => {
            handleDeleteVehicle(id);
          }}
          onEditVehicle={id => {
            setVehicleIdToUpdate(id);
            handleOpenModal('createVehicle');
          }}
          vehicleIndex={vehicleIndex}
          onClose={() => {
            setVehicleIndex(-1);
          }}
          total={
            paginatedCompanyVehicles?.data
              ? paginatedCompanyVehicles?.data.length
              : 0
          }
          onChangeVehicle={action => {
            if (action === 'previous') {
              setVehicleIndex(i => i - 1);
            }

            if (action === 'next') {
              setVehicleIndex(i => i + 1);
            }
          }}
          vehicleId={selectedVehicleId}
          isVehicleOnList={
            id
              ? !!paginatedCompanyVehicles?.data.find(
                  item => item.id === parseInt(id)
                )
              : true
          }
        />
      )}

      {visibleModal === 'linkMemberToVehicle' && vehicleIdToUpdate && (
        <LinkOrUnlinkMembersModal vehicleId={vehicleIdToUpdate} />
      )}

      {visibleModal === 'deleteVehicle' && vehicleIdToUpdate && (
        <AlertModal
          {...vehicleDeleteActionModalData}
          onClose={() => {
            handleCloseModal();
          }}
          cancelButtonProps={{
            onCancel: () => {
              handleCloseModal();
            }
          }}
          confirmButtonProps={{
            onConfirm: () => {
              handleCloseModal();
              handleDeleteVehicle(vehicleIdToUpdate);
            }
          }}
          icon={<DSIcons.WarningConfirmationIcon />}
        />
      )}

      {errorMessage && <ErrorModal />}
    </>
  );
}
