import { type Control, type FieldErrors } from 'react-hook-form';

import { type IUpdateCompanyPoliciesAndTermsFieldsForm } from 'data/modules/cards/companyOnboarding';

export interface IUsePoliciesAndTerms {
  control: Control<IUpdateCompanyPoliciesAndTermsFieldsForm>;
  errors: FieldErrors<IUpdateCompanyPoliciesAndTermsFieldsForm>;
  handleUpdateCompanyPoliciesAndTerms: () => void;
}

export interface IUpdateCacheAndNavigateNextStepParams
  extends IUpdateCompanyPoliciesAndTermsFieldsForm {}

export enum RequiredDocumentIconName {
  'file-text',
  'file-user',
  'file-add'
}
