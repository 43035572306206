import { DefaultTable, SkeletonLoader } from 'ds';

export function TeamMembersTableSkeleton(): JSX.Element {
  return (
    <>
      {new Array(10).fill(0).map((row, idx) => (
        <DefaultTable.Tr key={`row-${idx}`}>
          <DefaultTable.Td>
            <SkeletonLoader />
          </DefaultTable.Td>

          <DefaultTable.Td>
            <SkeletonLoader />
          </DefaultTable.Td>

          <DefaultTable.Td>
            <SkeletonLoader />
          </DefaultTable.Td>

          <DefaultTable.Td>
            <SkeletonLoader />
          </DefaultTable.Td>

          <DefaultTable.Td>
            <SkeletonLoader />
          </DefaultTable.Td>

          <DefaultTable.Td>
            <SkeletonLoader />
          </DefaultTable.Td>

          <DefaultTable.Td>
            <SkeletonLoader />
          </DefaultTable.Td>
        </DefaultTable.Tr>
      ))}
    </>
  );
}
