import { useMemo } from 'react';

import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { yup } from 'data/config';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUpdateCardsFunctionsSchemaFields,
  type IUseUpdateCardsFunctions,
  type IUseUpdateCardsFunctionsParams,
  type UpdateCompanyOnboardingAnswersPayloadType
} from 'data/modules/cards/companyOnboarding';

import { useFormWithSchema } from 'shared/hooks/forms';
import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

export function useUpdateCardsFunctions({
  onUpdateCompanyOnboardingAnswersMutationSuccess
}: IUseUpdateCardsFunctionsParams): IUseUpdateCardsFunctions {
  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const updateCardsFunctionsSchema: yup.ObjectSchema<IUpdateCardsFunctionsSchemaFields> =
    useMemo(
      () =>
        yup.object().shape({
          useWithdraw: yup.bool(),
          useInternationalTransaction: yup.bool()
        }),
      []
    );

  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const { data: cachedCompanyOnboardingAnswers } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      ],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingAnswers({
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId as string
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    }
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = useFormWithSchema(updateCardsFunctionsSchema, {
    defaultValues: {
      useWithdraw:
        cachedCompanyOnboardingAnswers?.configuration?.useWithdraw ?? false,
      useInternationalTransaction:
        cachedCompanyOnboardingAnswers?.configuration
          ?.useInternationalTransaction ?? false
    }
  });

  const {
    isLoading: isUpdatingCardsFunctions,
    mutate: updateCompanyOnboardingAnswers
  } = useMutationCache({
    mutationKey: [
      CompanyOnboardingMutationKeys.UPDATE_COMPANY_ONBOARDING_ANSWERS
    ],
    mutationFn: async (payload: UpdateCompanyOnboardingAnswersPayloadType) => {
      await CompanyOnboardingService.updateCompanyOnboardingAnswers(payload);
    },
    onSuccess: (data, variables) => {
      onUpdateCompanyOnboardingAnswersMutationSuccess({
        ...variables.answers
      } as IUpdateCardsFunctionsSchemaFields);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function onSubmit(data: IUpdateCardsFunctionsSchemaFields): void {
    if (isUpdatingCardsFunctions) return;

    const uuidCompanyOnboarding =
      cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '';

    if (
      !isDirty &&
      currentStep < Number(cachedCompanyOnboardingProgress?.currentStep)
    ) {
      onUpdateCompanyOnboardingAnswersMutationSuccess(data);

      return;
    }

    updateCompanyOnboardingAnswers({
      uuidCompanyOnboarding,
      step: currentStep,
      context: 'configuration',
      answers: {
        ...data
      }
    });
  }

  return {
    errors,
    control,
    isDirty,
    isUpdatingCardsFunctions,
    handleUpdateCardsFunctions: handleSubmit(onSubmit)
  };
}
