import { Alert } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useMyValueSolicitationContext } from 'data/contexts';

import { SideModal } from 'presentation/components/global/Modal';
import { ValueSolicitationTimeline } from 'presentation/pages/cards/components';

import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

import { type ISideCardDetailsModalProps } from './MyValueSolicitationsDetailsSideModal.types';

import {
  Container,
  DashedLine,
  DetailsContainer,
  InfoWrapper,
  RegularText,
  Subtitle,
  TopAmount
} from './MyValueSolicitationsDetailsSideModal.styles';

export function MyValueSolicitationsDetailsSideModal({
  isOpen,
  title,
  side = 'right'
}: ISideCardDetailsModalProps): JSX.Element {
  const { t } = useTranslation('cards');

  const [selectedSolicitation, handleSetSelectedSolicitation] =
    useMyValueSolicitationContext(
      useShallow(state => [
        state.selectedSolicitation,
        state.handleSetSelectedSolicitation
      ])
    );

  return (
    <>
      {isOpen && selectedSolicitation && (
        <SideModal
          isOpen={isOpen}
          title={title}
          side={side}
          onAfterClose={() => {
            handleSetSelectedSolicitation(null);
          }}
        >
          <Container>
            {selectedSolicitation.status === 'SENT' && (
              <Alert type='warning'>{t('pendingSolicitation')}</Alert>
            )}

            {selectedSolicitation.status === 'APPROVED' && (
              <Alert type='success'>{t('approvedSolicitation')}</Alert>
            )}

            {selectedSolicitation.status === 'REJECTED' && (
              <Alert type='error'>{t('rejectedSolicitation')}</Alert>
            )}

            <DetailsContainer>
              {selectedSolicitation.status !== 'APPROVED' && (
                <>
                  <InfoWrapper>
                    <RegularText>
                      {t('requestedAmountIn', {
                        requestDate: CustomDate.formatFromISO(
                          selectedSolicitation.dateSent
                        )
                      })}
                    </RegularText>
                    <TopAmount>
                      {Currency.format(
                        'BRL',
                        selectedSolicitation.requestedAmount,
                        true
                      )}
                    </TopAmount>
                  </InfoWrapper>

                  <InfoWrapper>
                    <Subtitle>{t('justification')}</Subtitle>
                    <RegularText>{selectedSolicitation.justify}</RegularText>
                  </InfoWrapper>
                </>
              )}

              {selectedSolicitation.status === 'APPROVED' && (
                <>
                  <InfoWrapper>
                    <RegularText>
                      {selectedSolicitation.requestedAmount ===
                      selectedSolicitation.transferredAmount
                        ? t('requestedAmountIn', {
                            requestDate: CustomDate.formatFromISO(
                              selectedSolicitation.dateSent
                            )
                          })
                        : t('approvedValue')}
                    </RegularText>
                    <TopAmount>
                      {Currency.format(
                        'BRL',
                        selectedSolicitation.transferredAmount || 0,
                        true
                      )}
                    </TopAmount>
                  </InfoWrapper>

                  {selectedSolicitation.requestedAmount !==
                    selectedSolicitation.transferredAmount && (
                    <InfoWrapper>
                      <Subtitle>
                        {t('originalRequestedAmountIn', {
                          requestDate: CustomDate.formatFromISO(
                            selectedSolicitation.dateSent
                          )
                        })}
                      </Subtitle>
                      <RegularText>
                        {Currency.format(
                          'BRL',
                          selectedSolicitation.requestedAmount,
                          true
                        )}
                      </RegularText>
                    </InfoWrapper>
                  )}

                  <InfoWrapper>
                    <Subtitle>{t('justification')}</Subtitle>
                    <RegularText>{selectedSolicitation.justify}</RegularText>
                  </InfoWrapper>
                </>
              )}
            </DetailsContainer>
            <DashedLine />
            <ValueSolicitationTimeline
              status={selectedSolicitation.status}
              dateApproval={CustomDate.formatFromISO(
                selectedSolicitation.finishDate || undefined
              )}
              dateRejected={CustomDate.formatFromISO(
                selectedSolicitation.finishDate || undefined
              )}
              approverName={selectedSolicitation.approverName || ''}
              managerJustify={selectedSolicitation.managerJustify || ''}
            />
          </Container>
        </SideModal>
      )}
    </>
  );
}
