import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.4rem 1.6rem;
  background: ${tokens.neutralColorHighPure};
  border-radius: 0 0 ${tokens.borderRadiusMd} ${tokens.borderRadiusMd};

  &,
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    gap: ${tokens.spacingXxs};
  }

  svg {
    max-width: 2.4rem;
    min-width: 2.4rem;
    max-height: 2.4rem;
    min-height: 2.4rem;
  }

  p {
    flex: 1 0 0;
    white-space: normal;
    font-style: normal;
    line-height: 2.2rem;
    letter-spacing: 0.025rem;
    font-size: ${tokens.fontSizeXxss};
    font-weight: ${tokens.fontWeightMedium};
    color: ${tokens.neutralColorLowMediumLight};
    font-family: ${tokens.fontFamiliesPoppins};
  }

  button {
    white-space: nowrap;
  }

  @media screen and (max-width: 1320px) {
    padding-top: 0.8rem;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-end;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    svg {
      display: none;
    }
  }
`;
