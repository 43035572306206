import styled from 'styled-components';

export const IconContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const TryAgainButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  fill: none;
`;
