import {
  type IExpensesPendingAccountability,
  type IPersistenceUserExpensesPendingAccountability
} from 'data/modules/expenses';

class ExpensesPendingAccountabilityMapper {
  toDomain(
    data: IPersistenceUserExpensesPendingAccountability[]
  ): IExpensesPendingAccountability[] {
    return data.map(data => ({
      value: data.value,
      convertedValue: data.converted_value,
      status: data.status
    }));
  }
}

export default new ExpensesPendingAccountabilityMapper();
