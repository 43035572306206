import { useLangContext } from 'data/contexts';
import { useGetAnswerableUser } from 'data/modules/cards/companyOnboarding';

import { BaseIcon } from 'presentation/components/base/Icon';
import { DsTag } from 'presentation/ds/DsTag';
import { OnboardingLoader } from 'presentation/pages/cards/Onboarding/components/General';

import {
  AnswerableUserContainer,
  Container,
  IconContainer,
  InfoContainer,
  UserInfo
} from './OnboardingInProgress.styles';

export function OnboardingInProgress(): JSX.Element {
  const { answerableUser, isLoadingAnswerableUser } = useGetAnswerableUser();
  const [cards, global, currentLangKey] = useLangContext(state => [
    state.lang.cards,
    state.lang.global,
    state.currentLangKey
  ]);

  if (isLoadingAnswerableUser) {
    return <OnboardingLoader />;
  }

  return (
    <Container>
      <InfoContainer>
        <IconContainer>
          <BaseIcon
            name='file-edit'
            size={3.5}
            color='primary'
          />
        </IconContainer>

        <h1>{cards.onboarding.onboardingInProgress.title[currentLangKey]}</h1>

        <p>{cards.onboarding.onboardingInProgress.subtitle[currentLangKey]}</p>
      </InfoContainer>

      <AnswerableUserContainer>
        <BaseIcon
          name='user-3'
          size={3}
          type='line'
          color='primary'
        />

        <UserInfo>
          <span
            className='name'
            title={answerableUser?.name}
          >
            {answerableUser?.name}
          </span>

          <span className='legal-representative'>
            {cards.onboarding.company.legal_representative[currentLangKey]}
          </span>
        </UserInfo>

        <p title={answerableUser?.email}>{answerableUser?.email}</p>

        <DsTag
          iconProps={{ name: 'checkbox-blank-circle', size: 0.8 }}
          text={global.in_progress[currentLangKey]}
          iconSide='left'
          type='primary'
        />
      </AnswerableUserContainer>
    </Container>
  );
}
