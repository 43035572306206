import React from 'react';

import { Accordion } from 'ds';

import { useLangContext } from 'data/contexts';

import { Carrousel } from 'presentation/pages/store/components/Carrousel';
import {
  ProductBanner,
  ProductFaqSection,
  ProductSubtitle,
  TestimonialSlide
} from 'presentation/pages/store/Product/components/General';

import banner1 from 'shared/assets/images/store/cards/cards-banner-1.png';
import banner2 from 'shared/assets/images/store/cards/cards-banner-2.png';
import banner3 from 'shared/assets/images/store/cards/cards-banner-3.png';
import banner4 from 'shared/assets/images/store/cards/cards-banner-4.png';
import testimonial1 from 'shared/assets/images/store/cards/testimonials/cards-testimonial-1.png';

export function Cards(): JSX.Element {
  const {
    currentLangKey,
    lang: {
      store: {
        product: { cards }
      }
    }
  } = useLangContext();

  return (
    <React.Fragment>
      <ProductBanner
        banner={banner1}
        title={cards.banners.first.title[currentLangKey]}
        buttonText={cards.banners.first.button[currentLangKey]}
        description={cards.banners.first.description[currentLangKey]}
      />

      <ProductBanner
        reverse
        banner={banner2}
        title={cards.banners.second.title[currentLangKey]}
        buttonText={cards.banners.second.button[currentLangKey]}
        description={cards.banners.second.description[currentLangKey]}
      />

      <ProductBanner
        banner={banner3}
        title={cards.banners.third.title[currentLangKey]}
        buttonText={cards.banners.third.button[currentLangKey]}
        description={cards.banners.third.description[currentLangKey]}
      />

      <ProductBanner
        reverse
        banner={banner4}
        title={cards.banners.fourth.title[currentLangKey]}
        buttonText={cards.banners.fourth.button[currentLangKey]}
        description={cards.banners.fourth.description[currentLangKey]}
      />

      <section>
        <ProductSubtitle>
          {cards.testimonials_subtitle[currentLangKey]}
        </ProductSubtitle>

        <Carrousel
          slides={Object.entries(cards.testimonials).map(
            ([key, testimonial]) => ({
              key,
              content: (
                <TestimonialSlide
                  thumbNail={testimonial1}
                  title={testimonial.title[currentLangKey]}
                  description={testimonial.description[currentLangKey]}
                  companyName={testimonial.company_name[currentLangKey]}
                  nameAndPosition={
                    testimonial.name_and_position[currentLangKey]
                  }
                />
              )
            })
          )}
        />
      </section>

      <ProductFaqSection>
        <ProductSubtitle>{cards.faq_subtitle[currentLangKey]}</ProductSubtitle>

        <Accordion.Container multiple>
          {Object.entries(cards.faq).map(([key, question]) => (
            <Accordion.Item key={key}>
              <Accordion.Label>
                {question.label[currentLangKey]}
              </Accordion.Label>

              <Accordion.Caption>
                {question.caption[currentLangKey]}
              </Accordion.Caption>
            </Accordion.Item>
          ))}
        </Accordion.Container>
      </ProductFaqSection>
    </React.Fragment>
  );
}
