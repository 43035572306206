import { apiWeb } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import {
  type ICurrency,
  type IPersistenceWebCurrency
} from 'data/modules/currencies';
import { type IDefaultResponse } from 'data/modules/global';

import { GetCurrenciesMapper } from './mappers';

class CurrenciesService {
  async getCurrencies(): Promise<ICurrency[]> {
    const response = await apiWeb.get<
      IDefaultResponse<IPersistenceWebCurrency[]>
    >(`/currencies/list-by-company`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data.map(GetCurrenciesMapper.toDomain);
  }
}

export default new CurrenciesService();
