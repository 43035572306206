import { useRef } from 'react';

import {
  Avatar,
  BadgeStatus,
  DangerButton,
  DefaultButton,
  DSIcons,
  Modal,
  PercentageList,
  SecondaryButton,
  SkeletonLoader,
  TextButton,
  Tooltip,
  ViewImagePdf
} from 'ds';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { vexDomain } from 'data/config';
import { useLangContext, useModalContext } from 'data/contexts';
import { useGetAuthUser } from 'data/modules/auth';
import { type ExpenseStatusType } from 'data/modules/expenses';

import { AlertModal } from 'presentation/components/global/AlertModal';
import { type IAlertModalProps } from 'presentation/components/global/AlertModal/AlertModal.types';
import { TooltipOverflowContent } from 'presentation/components/global/TooltipOverflowContent';

import { Icons, routesPathPrefix } from 'shared/constants/global';
import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

import { useViewExpensesModal } from './useViewExpensesModal';

import {
  type IBadgeStatus,
  type IViewExpensesModalProps
} from './ViewExpensesModal.types';

import {
  AttachmentContainer,
  AttachmentItem,
  AvatarContent,
  ButtonContainer,
  CoreContent,
  DateDivider,
  ExpenseAmount,
  ExpenseContent,
  ExpenseDetailContainer,
  ExpenseDetailsContainer,
  ExpenseDetailsContent,
  ExpenseDetailTitle,
  ExpenseDetailValue,
  ExpenseDetailValueWithOverflow,
  ExpenseExchangeValue,
  ExpenseObservationsContainer,
  ExpenseTitle,
  ExpenseValue,
  ExpenseValueContainer,
  ExpenseValueDate,
  Filename,
  GpsTimestampDate,
  GpsTimestampDateContainer,
  ModalContent,
  ModalFooter,
  ModalHeaderContent,
  ModalSubHeader,
  OdometerImageContainer,
  StyledClipIcon,
  StyledModalContainer,
  StyledModalContent,
  StyledModalHeader,
  StyledMotionDiv
} from './ViewExpensesModal.styles';

export function ViewExpensesModal({
  expenseUuidOrId,
  onChangeExpense,
  expenseIndex,
  total,
  isExpenseOnList,
  onDeleteExpense = () => {},
  onUpdateExpense = () => {},
  preventRedirect = false,
  isReadOnly = false
}: IViewExpensesModalProps): JSX.Element {
  const { handleCloseModal } = useModalContext();

  const { currentLangKey, lang } = useLangContext();

  const { company } = useGetAuthUser();

  const navigate = useNavigate();

  const {
    expense,
    isLoadingExpense,
    showModalRightButton,
    showDeleteModal,
    toggleDeleteModal,
    showRouteTimeParameter,
    isFuelingExpense
  } = useViewExpensesModal({
    expenseUuidOrId,
    expenseIndex,
    total,
    isExpenseOnList,
    preventRedirect
  });

  const { t } = useTranslation(['expenses', 'global']);

  const statusBadge: Record<ExpenseStatusType, IBadgeStatus> = {
    APROVADO: {
      status: 'success',
      message: lang.expenses.status.approved[currentLangKey]
    },
    ENVIADO: {
      message: lang.expenses.status.sent[currentLangKey]
    },
    PAGO: {
      status: 'general',
      message: lang.expenses.status.paid[currentLangKey]
    },
    ABERTO: {
      status: 'warning',
      message: lang.expenses.status.open[currentLangKey]
    },
    REPROVADO: {
      status: 'error',
      message: lang.expenses.status.rejected[currentLangKey]
    },
    AVULSA: {
      status: 'general',
      message: lang.expenses.status.unreported[currentLangKey]
    },
    REABERTO: {
      status: 'warning',
      message: lang.expenses.status.open[currentLangKey]
    }
  };

  const status = expense?.report?.status ?? 'AVULSA';

  const expensesActionModalData: Pick<IAlertModalProps, 'children' | 'title'> =
    {
      children:
        lang.expenses.modal.action_modal.delete_single_description[
          currentLangKey
        ],
      title:
        lang.expenses.modal.action_modal.delete_single_title[currentLangKey]
    };

  const isExchangeRateVisible =
    (!!expense?.exchangeRate && !expense.isFromCards) ||
    (!!expense?.exchangeRate &&
      expense?.isFromCards &&
      expense?.transaction?.isInternational);

  const expenseObservation = expense?.observation ?? t('modal.noObservations');

  const avatarContainerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <StyledModalContainer
        variant='large'
        onClose={() => {
          handleCloseModal();
          if (!preventRedirect) navigate(routesPathPrefix.expenses);
        }}
        onLeftArrowClick={
          expenseIndex - 1 >= 0 && isExpenseOnList
            ? () => {
                onChangeExpense('previous');
              }
            : undefined
        }
        onRightArrowClick={
          showModalRightButton && isExpenseOnList
            ? () => {
                onChangeExpense('next');
              }
            : undefined
        }
        rightButtonHtmlAttributes={{
          className: 'right-arrow-button'
        }}
        containerArrowsStyle={{
          width: '100%',
          justifyContent: 'center'
        }}
      >
        {isLoadingExpense && (
          <div>
            <Modal.Header>
              <ModalHeaderContent>
                <SkeletonLoader height='2rem' />

                <SkeletonLoader height='2rem' />
              </ModalHeaderContent>

              <Modal.IconClose
                onClick={() => {
                  handleCloseModal();
                  if (!preventRedirect) navigate(routesPathPrefix.expenses);
                }}
              />
            </Modal.Header>

            <ModalSubHeader>
              <SkeletonLoader
                height='2rem'
                maxWidth='10rem'
              />

              <SkeletonLoader
                height='2rem'
                maxWidth='10rem'
              />

              <SkeletonLoader
                height='2rem'
                maxWidth='10rem'
              />

              <SkeletonLoader
                height='2rem'
                maxWidth='10rem'
              />

              <SkeletonLoader
                height='2rem'
                maxWidth='10rem'
              />
            </ModalSubHeader>

            <Modal.Content>
              <ModalContent>
                <CoreContent>
                  <ExpenseContent>
                    <ExpenseValueContainer>
                      <SkeletonLoader
                        rows={3}
                        height='2rem'
                      />
                    </ExpenseValueContainer>

                    <ExpenseDetailsContainer>
                      <br />
                      <SkeletonLoader
                        rows={8}
                        height='3rem'
                      />
                    </ExpenseDetailsContainer>
                  </ExpenseContent>

                  <SkeletonLoader
                    height='40rem'
                    maxWidth='30rem'
                  />
                </CoreContent>

                <SkeletonLoader height='10rem' />
              </ModalContent>
            </Modal.Content>
          </div>
        )}

        {!isLoadingExpense && (
          <StyledMotionDiv key={expense?.uuid}>
            <StyledModalHeader>
              <ModalHeaderContent>
                <span>
                  <TooltipOverflowContent
                    tooltipMessage={
                      expense?.title ??
                      lang.expenses.labels.untitled[currentLangKey]
                    }
                    tooltipContainerClassName='tooltip-container'
                  >
                    <ExpenseTitle>
                      {expense?.title ??
                        lang.expenses.labels.untitled[currentLangKey]}
                    </ExpenseTitle>
                  </TooltipOverflowContent>
                  <p>#{expense?.id}</p>
                </span>

                {isExpenseOnList && (
                  <ExpenseAmount>
                    {`
                      ${lang.expenses.labels.expense[currentLangKey]} ${
                        expenseIndex + 1
                      } ${lang.global.of[currentLangKey]} ${total}
                    `}
                  </ExpenseAmount>
                )}
              </ModalHeaderContent>

              <Modal.IconClose
                onClick={() => {
                  handleCloseModal();
                  if (!preventRedirect) navigate(routesPathPrefix.expenses);
                }}
              />
            </StyledModalHeader>

            <ModalSubHeader>
              <BadgeStatus type={statusBadge[status].status}>
                {statusBadge[status].message}
              </BadgeStatus>

              {/* É uma despesa de cartões */}
              {expense?.isFromCards && (
                <BadgeStatus type='secondary'>
                  <DSIcons.CardIcon />
                  {lang.expenses.labels.vexCard[currentLangKey]}
                </BadgeStatus>
              )}

              {/* É uma despesa conciliável */}
              {expense?.isReconciled && (
                <BadgeStatus type='success'>
                  <DSIcons.ConcialiatedIcon />
                  {lang.expenses.labels.conciliatedExpense[currentLangKey]}
                </BadgeStatus>
              )}

              {/* É uma despesa conciliável e não está conciliada */}
              {expense?.isReconciled !== null && !expense?.isReconciled && (
                <BadgeStatus type='error'>
                  <DSIcons.NotConcialiatedIcon />
                  {lang.expenses.labels.notConciliatedExpense[currentLangKey]}
                </BadgeStatus>
              )}

              {/* É uma despesa importada */}
              {expense?.isImported && (
                <BadgeStatus type='warning'>
                  <DSIcons.ImportedExpenseIcon />
                  {lang.expenses.labels.importedExpense[currentLangKey]}
                </BadgeStatus>
              )}

              {/* É uma despesa de cartões estornada */}
              {expense?.isFromCards &&
                expense?.isReversal !== 'not_reversed' &&
                expense?.isReversal !== null && (
                  <BadgeStatus type='warning'>
                    <Icons.ReversalIcon />

                    {expense.isReversal === 'reversed'
                      ? t('reversedExpense')
                      : t('reversedExpensePartially')}
                  </BadgeStatus>
                )}
            </ModalSubHeader>

            <StyledModalContent>
              <ModalContent>
                <CoreContent>
                  <ExpenseContent>
                    <ExpenseValueContainer>
                      <div>
                        <ExpenseValue>
                          {Currency.format(
                            isExchangeRateVisible
                              ? expense.convertedCurrencyIso ?? 'BRL'
                              : expense?.currency.isoCode ?? 'BRL',
                            isExchangeRateVisible
                              ? expense?.convertedValue ?? 0
                              : expense?.value ?? 0,
                            true
                          )}
                        </ExpenseValue>

                        {isExchangeRateVisible && (
                          <ExpenseExchangeValue>
                            {Currency.format(
                              expense?.currency.isoCode ?? 'BRL',
                              expense?.value ?? 0,
                              true
                            )}
                            <Tooltip
                              trigger='hover'
                              placement='right'
                              message={`${expense.currency.isoCode} ${expense.exchangeRate} = ${expense.convertedCurrencyIso} 1
                          Cotação PTAX (Banco Central) + 4% de Spread`}
                            >
                              <div>
                                <DSIcons.InfoIcon />
                              </div>
                            </Tooltip>
                          </ExpenseExchangeValue>
                        )}
                      </div>

                      <ExpenseValueDate>
                        {CustomDate.formatFromISO(expense?.date)}
                      </ExpenseValueDate>

                      {expense?.routeType === 'TRACKING' &&
                        expense?.gpsRouteStartDateNaive &&
                        expense?.gpsRouteFinalDateNaive && (
                          <GpsTimestampDateContainer>
                            <GpsTimestampDate>
                              Data inicial:
                              <span>
                                {CustomDate.formatFromISO(
                                  expense?.gpsRouteStartDateNaive
                                )}
                              </span>
                              {showRouteTimeParameter && (
                                <>
                                  <span>
                                    {
                                      expense?.gpsRouteStartDateNaive.split(
                                        ' '
                                      )[1]
                                    }
                                  </span>
                                  ({expense?.gpsRouteStartTimezone})
                                </>
                              )}
                            </GpsTimestampDate>

                            <DateDivider />

                            <GpsTimestampDate>
                              Data final:
                              <span>
                                {CustomDate.formatFromISO(
                                  expense?.gpsRouteFinalDateNaive
                                )}
                              </span>
                              {showRouteTimeParameter && (
                                <>
                                  <span>
                                    {
                                      expense?.gpsRouteFinalDateNaive.split(
                                        ' '
                                      )[1]
                                    }
                                  </span>
                                  ({expense?.gpsRouteFinalTimezone})
                                </>
                              )}
                            </GpsTimestampDate>
                          </GpsTimestampDateContainer>
                        )}
                    </ExpenseValueContainer>

                    <ExpenseDetailsContainer>
                      <ExpenseDetailsContent>
                        {isFuelingExpense && (
                          <>
                            <ExpenseDetailContainer>
                              <ExpenseDetailTitle>
                                {t('modal.licensePlate')}
                              </ExpenseDetailTitle>
                              <ExpenseDetailValue>
                                {expense?.fueling?.vehicle?.plate ?? '-'}
                              </ExpenseDetailValue>
                            </ExpenseDetailContainer>

                            <ExpenseDetailContainer>
                              <ExpenseDetailTitle>
                                {t('modal.quantityOfLiters')}
                              </ExpenseDetailTitle>
                              <ExpenseDetailValue $lowercase>
                                {expense?.fueling?.volume
                                  ? `${Currency.format(
                                      'BRL',
                                      expense.fueling.volume,
                                      false,
                                      2
                                    )} ${t('modal.liters')}`
                                  : '-'}
                              </ExpenseDetailValue>
                            </ExpenseDetailContainer>
                          </>
                        )}

                        <ExpenseDetailContainer>
                          <ExpenseDetailTitle>
                            {lang.global.report[currentLangKey]}
                          </ExpenseDetailTitle>
                          <ExpenseDetailValue>
                            {expense?.report?.description ?? '-'}
                          </ExpenseDetailValue>
                        </ExpenseDetailContainer>

                        {!expense?.isFromCards && (
                          <ExpenseDetailContainer>
                            <ExpenseDetailTitle>
                              {lang.global.payment_form[currentLangKey]}
                            </ExpenseDetailTitle>
                            <ExpenseDetailValue>
                              {expense?.paymentMethod?.description ?? '-'}
                            </ExpenseDetailValue>
                          </ExpenseDetailContainer>
                        )}

                        {expense?.type?.description === 'Percurso' &&
                          expense?.kilometrage !== '0.00' &&
                          expense?.kilometrage !== null && (
                            <ExpenseDetailContainer>
                              <ExpenseDetailTitle>
                                {lang.global.distance[currentLangKey]}
                              </ExpenseDetailTitle>
                              <ExpenseDetailValue>
                                {Currency.format(
                                  'BRL',
                                  Currency.parseToFloat(expense.kilometrage)
                                )}
                                km
                              </ExpenseDetailValue>
                            </ExpenseDetailContainer>
                          )}

                        <ExpenseDetailContainer>
                          <ExpenseDetailTitle>
                            {lang.global.refundable[currentLangKey]}
                          </ExpenseDetailTitle>
                          <ExpenseDetailValue>
                            {expense?.isReimbursable
                              ? lang.global.yes[currentLangKey]
                              : lang.global.no[currentLangKey]}
                          </ExpenseDetailValue>
                        </ExpenseDetailContainer>

                        {isFuelingExpense &&
                          expense?.fueling?.odometerImage !== null && (
                            <OdometerImageContainer>
                              <AvatarContent>
                                <Avatar
                                  size='40'
                                  image={{
                                    src: expense?.fueling?.odometerImage
                                      .originalUrl as string
                                  }}
                                  viewImage={true}
                                  hasZoomControl={true}
                                  className='avatar-odometer-image'
                                  ref={avatarContainerRef}
                                />

                                <span>
                                  {t('createFuelExpenseModal.odometer')}
                                </span>
                              </AvatarContent>

                              <SecondaryButton
                                type='button'
                                size='small'
                                className='select-odometer-image-button'
                                onClick={() => {
                                  avatarContainerRef.current?.click();
                                }}
                              >
                                {t('global:view')}
                              </SecondaryButton>
                            </OdometerImageContainer>
                          )}
                      </ExpenseDetailsContent>

                      <ExpenseDetailsContent>
                        {isFuelingExpense && (
                          <>
                            <ExpenseDetailContainer>
                              <ExpenseDetailTitle>
                                {t('modal.vehicleType')}
                              </ExpenseDetailTitle>
                              <ExpenseDetailValue>
                                {expense?.fueling?.vehicleType?.description ??
                                  '-'}
                              </ExpenseDetailValue>
                            </ExpenseDetailContainer>

                            <ExpenseDetailContainer>
                              <ExpenseDetailTitle>
                                {t('modal.fuelType')}
                              </ExpenseDetailTitle>
                              <ExpenseDetailValue>
                                {expense?.fueling?.fuel?.description ?? '-'}
                              </ExpenseDetailValue>
                            </ExpenseDetailContainer>
                          </>
                        )}

                        <ExpenseDetailContainer>
                          <ExpenseDetailTitle>
                            {lang.global.cost_center[currentLangKey]}
                          </ExpenseDetailTitle>
                          <ExpenseDetailValue>
                            {expense?.costsCenter?.name ?? '-'}
                          </ExpenseDetailValue>
                        </ExpenseDetailContainer>

                        {company?.fuelingTypeId !==
                          parseInt(expense?.type?.id ?? '') && (
                          <ExpenseDetailContainer>
                            <ExpenseDetailTitle>Tipo</ExpenseDetailTitle>
                            <ExpenseDetailValue>
                              {expense?.type?.description ?? '-'}
                            </ExpenseDetailValue>
                          </ExpenseDetailContainer>
                        )}

                        {expense?.type?.description === 'Percurso' &&
                          expense?.amountPerKilometer && (
                            <ExpenseDetailContainer>
                              <ExpenseDetailTitle>
                                {
                                  lang.expenses.modal.amount_per_mileage[
                                    currentLangKey
                                  ]
                                }
                              </ExpenseDetailTitle>
                              <ExpenseDetailValue>
                                {Currency.format(
                                  expense?.currency.isoCode ?? 'BRL',
                                  Currency.parseToFloat(
                                    expense?.amountPerKilometer
                                  ),
                                  true,
                                  4
                                )}
                              </ExpenseDetailValue>
                            </ExpenseDetailContainer>
                          )}

                        {expense?.isFromCards && (
                          <ExpenseDetailContainer>
                            <ExpenseDetailTitle>
                              {lang.global.card[currentLangKey]}
                            </ExpenseDetailTitle>
                            <ExpenseDetailValue>
                              {expense?.cardNumber
                                ? `•••• •••• •••• ${expense?.cardNumber}`
                                : '-'}
                            </ExpenseDetailValue>
                          </ExpenseDetailContainer>
                        )}

                        {isExchangeRateVisible && expense.exchangeRate && (
                          <ExpenseDetailContainer>
                            <ExpenseDetailTitle>
                              {lang.global.exchange_rate[currentLangKey]}
                            </ExpenseDetailTitle>
                            <ExpenseDetailValue>
                              {Currency.format(
                                'BRL',
                                expense?.exchangeRate,
                                false,
                                5
                              )}
                            </ExpenseDetailValue>
                          </ExpenseDetailContainer>
                        )}

                        {expense?.fueling !== null && (
                          <ExpenseDetailContainer>
                            <ExpenseDetailTitle>
                              {t('createFuelExpenseModal.mileage')}
                            </ExpenseDetailTitle>
                            <ExpenseDetailValue>
                              {expense?.fueling.odometerKilometrage
                                ? Currency.format(
                                    'BRL',
                                    expense.fueling.odometerKilometrage,
                                    false,
                                    0
                                  )
                                : '-'}
                            </ExpenseDetailValue>
                          </ExpenseDetailContainer>
                        )}
                      </ExpenseDetailsContent>
                    </ExpenseDetailsContainer>

                    <ExpenseObservationsContainer>
                      <ExpenseDetailTitle>
                        {lang.global.observations[currentLangKey]}
                      </ExpenseDetailTitle>
                      <TooltipOverflowContent
                        tooltipMessage={expenseObservation}
                        tooltipContainerClassName='tooltip-observations-container'
                      >
                        <ExpenseDetailValueWithOverflow>
                          {expenseObservation}
                        </ExpenseDetailValueWithOverflow>
                      </TooltipOverflowContent>
                    </ExpenseObservationsContainer>
                    {!!expense?.observationAttachments &&
                      expense?.observationAttachments.length > 0 && (
                        <AttachmentContainer>
                          {expense?.observationAttachments.map(
                            (file, index) => {
                              const fileName = file.title.split('.');
                              const nameSize = fileName[0].length;

                              return (
                                <AttachmentItem
                                  href={file.url}
                                  target='_blank'
                                  key={index}
                                >
                                  <StyledClipIcon />
                                  <Filename>
                                    {nameSize >= 18
                                      ? `${fileName[0].slice(0, 10)}...${fileName[0].slice(nameSize - 8, nameSize)}.${fileName[1]}`
                                      : file.title}
                                  </Filename>
                                </AttachmentItem>
                              );
                            }
                          )}
                        </AttachmentContainer>
                      )}
                  </ExpenseContent>

                  <ViewImagePdf
                    showZoomTour
                    containerHasBorder
                    portalId='view-image-portal'
                    hasZoomControl
                    id='view-image'
                    langKey={currentLangKey}
                    url={expense?.receipt?.url ?? null}
                    fileLabel={expense?.receipt?.name ?? 'file'}
                  />
                </CoreContent>

                {!!expense?.apportionments && (
                  <PercentageList
                    title={lang.expenses.labels.apportioment[currentLangKey]}
                    noItemsMessage={
                      lang.expenses.labels.noApportioment[currentLangKey]
                    }
                    items={expense.apportionments.map(item => ({
                      percentage: item.percentage,
                      title: item.project.name,
                      value: Currency.format('BRL', item.value)
                    }))}
                  />
                )}
              </ModalContent>
            </StyledModalContent>

            <Modal.Footer>
              <ModalFooter $canDelete={!isReadOnly && expense?.canDelete}>
                {!isReadOnly && expense?.canDelete && (
                  <DangerButton
                    onClick={() => {
                      toggleDeleteModal(true);
                    }}
                    size='small'
                  >
                    {lang.global.delete[currentLangKey]}
                  </DangerButton>
                )}

                <ButtonContainer>
                  {!!expense?.report && (
                    <TextButton
                      onClick={() => {
                        window.open(
                          `${vexDomain}/relatorios/${expense?.report?.id}`
                        );
                      }}
                      size='small'
                    >
                      {lang.expenses.labels.goToReport[currentLangKey]}
                    </TextButton>
                  )}

                  {!isReadOnly && expense?.canEdit && (
                    <DefaultButton
                      size='small'
                      onClick={() => {
                        if (!preventRedirect) {
                          navigate(routesPathPrefix.expenses);
                        }

                        onUpdateExpense(expense.uuid);
                      }}
                    >
                      {lang.global.edit[currentLangKey]}
                    </DefaultButton>
                  )}
                </ButtonContainer>
              </ModalFooter>
            </Modal.Footer>
          </StyledMotionDiv>
        )}
      </StyledModalContainer>

      {showDeleteModal && (
        <AlertModal
          {...expensesActionModalData}
          cancelButtonProps={{
            onCancel: () => {
              toggleDeleteModal(false);
            }
          }}
          confirmButtonProps={{
            onConfirm: () => {
              handleCloseModal();
              toggleDeleteModal(false);
              onDeleteExpense(expense?.uuid as string);
            }
          }}
          icon={<DSIcons.WarningConfirmationIcon />}
        />
      )}
    </>
  );
}
