import { useInfiniteQueryCache } from 'data/cache';
import {
  AnalyticsQueryKeys,
  AnalyticsService,
  type IUseGetAnalysisUserList,
  type IUseGetAnalysisUserListParams
} from 'data/modules/analytics';

export function useGetAnalysisUserList(
  params: IUseGetAnalysisUserListParams
): IUseGetAnalysisUserList {
  const {
    isPending,
    data: analysisUserListPages,
    isError: isGetAnalysisUserListError,
    isFetching: isFetchingAnalysisUserList,
    hasNextPage: hasNextPageAnalysisUserList,
    fetchNextPage: fetchAnalysisUserListNextPage,
    isFetchingNextPage: isFetchingAnalysisUserListNextPage
  } = useInfiniteQueryCache({
    queryKey: [AnalyticsQueryKeys.GET_ANALYSIS_USER_LIST, params],
    queryFn: async ({ pageParam = 1 }) => {
      return await AnalyticsService.getAnalysisUserList({
        ...params,
        page: pageParam
      });
    },
    getNextPageParam: lastPage =>
      (lastPage[1]?.currentPage ?? 0) < (lastPage[1]?.lastPage ?? 0)
        ? (lastPage[1]?.currentPage ?? 0) + 1
        : undefined,
    initialPageParam: 1
  });

  return {
    pagination: analysisUserListPages?.pages[0][1],
    analysisUserList:
      analysisUserListPages?.pages?.flatMap(page => page[0]) ?? [],
    isLoadingAnalysisUserList: isPending,
    isGetAnalysisUserListError,
    isFetchingAnalysisUserList,
    hasNextPageAnalysisUserList,
    fetchAnalysisUserListNextPage,
    isFetchingAnalysisUserListNextPage
  };
}
