import { getBoolean, getString } from 'firebase/remote-config';
import { create } from 'zustand';

import { remoteConfig } from 'data/config/firebase.config';

import { featureFlagsKeys } from 'shared/constants/global';

import {
  type IFeatureObject,
  type IGetFlagParams,
  type IUseFlagsContext
} from './useFlagsContext.types';

function getFlag({ companyId, flag }: IGetFlagParams): boolean {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }

  const featureFlagValueAsString = getString(remoteConfig, flag);

  // se a string da feature flag incluir "ids", então é um array de IDs de empresa
  // em Staging, também será possível colocar IDs de empresas específicos para teste
  if (featureFlagValueAsString.includes('ids') && companyId) {
    return (
      JSON.parse(getString(remoteConfig, flag)) as IFeatureObject
    ).ids.includes(companyId);
  }

  // se não for o caso acima, retorna um boleano.
  // se o valor da feature flag for "true" ou "false", vai fazer a conversão de string para boleano
  // se for qualquer string que não seja "true" ou "false", vai retornar false
  return getBoolean(remoteConfig, flag);
}

export const useFlagsContext = create<IUseFlagsContext>()(set => ({
  cardGroupsFlag: false,
  analyticsPageFlag: false,
  notificationsFlag: false,
  adminMovementsFlag: false,
  redesignReportsFlag: false,
  redesignMyCardFlag: false,
  teamMembersFlag: false,

  getRemoteFlags: companyId => {
    set({
      cardGroupsFlag: getFlag({
        companyId,
        flag: featureFlagsKeys.cardGroupsFlag
      }),
      analyticsPageFlag: getFlag({
        companyId,
        flag: featureFlagsKeys.analyticsPageFlag
      }),
      notificationsFlag: getFlag({
        companyId,
        flag: featureFlagsKeys.notificationsFlag
      }),
      adminMovementsFlag: getFlag({
        companyId,
        flag: featureFlagsKeys.adminMovementsFlag
      }),
      redesignReportsFlag: getFlag({
        companyId,
        flag: featureFlagsKeys.redesignReportsFlag
      }),
      redesignMyCardFlag: getFlag({
        companyId,
        flag: featureFlagsKeys.redesignMyCardFlag
      }),
      teamMembersFlag: getFlag({
        companyId,
        flag: featureFlagsKeys.teamMembersFlag
      })
    });
  }
}));
