import { useEffect } from 'react';

import { driver } from 'driver.js';

import { driverJsConfig } from 'data/config';
import { useLangContext } from 'data/contexts';

import { localStorageKeys } from 'shared/constants/global';
import { Zendesk } from 'shared/utils/global';

import { type IUseManualRouteExpenseModalTourParams } from './useManualRouteExpenseModalTour.types';

export function useManualRouteExpenseModalTour({
  enabled
}: IUseManualRouteExpenseModalTourParams): void {
  const {
    lang: {
      expenses: { tours },
      global
    },
    currentLangKey
  } = useLangContext();

  useEffect(() => {
    function startTour(): void {
      const reportSelectionTour = driver({
        ...driverJsConfig,
        nextBtnText: global.next[currentLangKey],
        prevBtnText: global.previous[currentLangKey],
        doneBtnText: global.finalize[currentLangKey],
        progressText: `{{current}} ${global.of[currentLangKey]} {{total}}`,
        onDestroyed: () => {
          reportSelectionTour.destroy();
          localStorage.setItem(localStorageKeys.reportSelectionTour, 'true');
          Zendesk.show();
        },
        steps: [
          {
            element: '.search-select-report-container',
            popover: {
              title: tours.reportSelection.first_step.title[currentLangKey],
              description:
                tours.reportSelection.first_step.description[currentLangKey]
            }
          },
          {
            element: '.create-report-button',
            popover: {
              title: tours.reportSelection.second_step.title[currentLangKey],
              description:
                tours.reportSelection.second_step.description[currentLangKey]
            }
          },
          {
            element: '.create-expense-button-submit',
            popover: {
              title: tours.reportSelection.third_step.title[currentLangKey],
              description:
                tours.reportSelection.third_step.description[currentLangKey]
            }
          },
          {
            element: '.report-radio-item',
            popover: {
              title: tours.reportSelection.fourth_step.title[currentLangKey],
              description:
                tours.reportSelection.fourth_step.description[currentLangKey]
            }
          }
        ]
      });

      reportSelectionTour.drive();
    }

    if (
      localStorage.getItem(localStorageKeys.reportSelectionTour) !== 'true' &&
      enabled
    ) {
      // espera 400ms para iniciar o tour por conta da animação da div
      const timer = setTimeout(() => {
        startTour();
        Zendesk.hide();
      }, 400);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [enabled, currentLangKey, global, tours]);
}
