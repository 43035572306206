import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type ICreateBusinessCardsFieldsForm } from 'data/modules/cards/businessCard';

export function useCreateBusinessCardSchema(): ObjectSchema<ICreateBusinessCardsFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const createBusinessCardSchema: ObjectSchema<ICreateBusinessCardsFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object().shape({
        accountHolderId: yup.string().required(fieldRequiredMessage),
        name: yup.string().required(fieldRequiredMessage),
        email: yup.string().email(lang.schemas.invalid_email[currentLangKey])
      });
    }, [lang, currentLangKey]);

  return createBusinessCardSchema;
}
