import { useQueryCache } from 'data/cache';
import {
  ExpensePoliciesQueryKeys,
  ExpensePoliciesService,
  type IUseGetExpensePolicies,
  type IUseGetExpensePoliciesParams
} from 'data/modules/expensePolicies';

export function useGetExpensePolicies({
  enabled,
  ...payload
}: IUseGetExpensePoliciesParams): IUseGetExpensePolicies {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [ExpensePoliciesQueryKeys.GET_EXPENSE_POLICIES, payload],
    queryFn: async () =>
      await ExpensePoliciesService.getExpensePolicies(payload),
    enabled
  });

  return {
    expensePolicies: data ?? [],
    isLoadingExpensePolicies: isLoading || isFetching
  };
}
