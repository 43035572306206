import { useLangContext } from 'data/contexts';

import { ResponsiveCell } from 'presentation/components/global/Table/ResponsiveCell/ResponsiveCell.component';

import useTariffsTableBody from './useTariffsTableBody';

export function TariffsTableBody(): JSX.Element {
  const { tariffs } = useTariffsTableBody();
  const [settings, currentLangKey] = useLangContext(state => [
    state.lang.settings,
    state.currentLangKey
  ]);

  return (
    <>
      {tariffs.map(item => (
        <tr key={item.tariff}>
          <ResponsiveCell
            width={20}
            label={settings.tariffs.tariff[currentLangKey]}
          >
            {item.tariff}
          </ResponsiveCell>

          <ResponsiveCell
            width={10}
            label={settings.tariffs.value[currentLangKey]}
          >
            {item.value}
          </ResponsiveCell>

          <ResponsiveCell
            width={30}
            label={settings.tariffs.event[currentLangKey]}
          >
            {item.event}
          </ResponsiveCell>

          <ResponsiveCell
            width={40}
            label={settings.tariffs.charge[currentLangKey]}
          >
            {item.charge}
          </ResponsiveCell>
        </tr>
      ))}
    </>
  );
}
