import { type MouseEvent, useEffect, useRef, useState } from 'react';

import { type IUseDraggableScrollComponent } from './DraggableScrollComponent.types';

export function useDraggableScrollComponent(): IUseDraggableScrollComponent {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const mouseCoords = useRef({
    x: 0,
    y: 0,
    scrollLeft: 0,
    scrollTop: 0
  });

  function handleDragStart(e: MouseEvent): void {
    if (!containerRef.current || isMouseDown) return;

    const containerScroll = containerRef.current.children[0] as HTMLElement;
    const x = e.pageX - containerScroll.offsetLeft;
    const y = e.pageY - containerScroll.offsetTop;
    const scrollLeft = containerScroll.scrollLeft;
    const scrollTop = containerScroll.scrollTop;

    mouseCoords.current = { x, y, scrollLeft, scrollTop };
    setIsMouseDown(true);
    setIsDragging(false);
  }

  function handleDragEnd(): void {
    setIsMouseDown(false);

    if (!containerRef.current) return;

    setIsDragging(true);
  }

  function handleDrag(e: MouseEvent): void {
    e.preventDefault();

    if (!isMouseDown || !containerRef.current) return;

    const containerScroll = containerRef.current.children[0] as HTMLElement;

    const x = e.pageX - containerScroll.offsetLeft;
    const y = e.pageY - containerScroll.offsetTop;
    const walkX = (x - mouseCoords.current.x) * 1.5;
    const walkY = (y - mouseCoords.current.y) * 1.5;

    containerScroll.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    containerScroll.scrollTop = mouseCoords.current.scrollTop - walkY;
  }

  useEffect(() => {
    const toggleIsMouseDown = (): void => {
      setIsMouseDown(false);
    };

    document.body.addEventListener('mouseup', toggleIsMouseDown);

    return () => {
      document.body.removeEventListener('mouseup', toggleIsMouseDown);
    };
  }, []);

  return {
    handleDrag,
    handleDragEnd,
    handleDragStart,
    isDragging,
    containerRef
  };
}
