import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  gap: ${({ theme }) => theme.tokens.spacingMd};
  transition: background-color ease-in-out 100ms;
  padding: ${({ theme }) => theme.tokens.spacingXl};
  background: ${({ theme }) => theme.tokens.neutralColorHighLight};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusXxl};

  &:hover {
    background: ${({ theme }) =>
      darken(0.02, theme.tokens.neutralColorHighLight)};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: ${({ theme }) => theme.tokens.spacingMd};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.tokens.spacingMd};
`;

export const ThumbNail = styled.img`
  width: 14.8rem;
  aspect-ratio: 1;
  min-width: 14.8rem;
  max-height: 14.8rem;
  background-size: cover;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.tokens.spacingMd};
`;

export const TitleContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.tokens.spacingXs};
`;

export const ProductName = styled.h2`
  font-size: ${({ theme }) => theme.tokens.fontSizeSm};
  color: ${({ theme }) => theme.tokens.neutralColorLowPure};
  font-weight: ${({ theme }) => theme.tokens.fontWeightBold};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.tokens.fontSizeXs};
  color: ${({ theme }) => theme.tokens.neutralColorLowDark};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
`;

export const StatusContent = styled.div`
  margin-top: auto;
  padding-top: ${({ theme }) => theme.tokens.spacingNano};
`;

export const FooterContent = styled.div`
  > button:first-of-type {
    margin-bottom: ${({ theme }) => theme.tokens.spacingMd};
    transition: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: ${({ theme }) => theme.tokens.spacingMd};
    display: flex;

    > button:first-of-type {
      margin-bottom: 0;
      transition: none;
    }
  }
`;
