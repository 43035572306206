import {
  type IPersistenceSerproInformations,
  type ISerproInformations
} from 'data/modules/cards/companyOnboarding';

import { CustomDate } from 'shared/utils/custom';
import { Mask } from 'shared/utils/format';

class GetSerproInformationsMapper {
  toDomain(
    persistenceSerproInformations: IPersistenceSerproInformations
  ): ISerproInformations {
    return {
      cnae: persistenceSerproInformations?.cnae,
      type: persistenceSerproInformations?.tipo,
      email: persistenceSerproInformations?.email,
      tradingName: persistenceSerproInformations?.nome_fantasia,
      corporateName: persistenceSerproInformations?.razao_social,
      capitalStock: persistenceSerproInformations?.capital_social,
      cnpj: Mask.apply('cnpj', persistenceSerproInformations?.cnpj),
      phone: persistenceSerproInformations?.telefone
        ? Mask.apply('phone', persistenceSerproInformations?.telefone)
        : '',
      foundationDate: CustomDate.formatFromISO(
        persistenceSerproInformations?.data_abertura
      ),
      address: {
        state: persistenceSerproInformations?.endereco?.uf,
        number: persistenceSerproInformations?.endereco?.numero,
        city: persistenceSerproInformations?.endereco?.municipio,
        street: persistenceSerproInformations?.endereco?.logradouro,
        neighborhood: persistenceSerproInformations?.endereco?.bairro,
        complement: persistenceSerproInformations?.endereco?.complemento,
        postalCode: Mask.apply(
          'cep',
          persistenceSerproInformations?.endereco?.cep
        ),
        partners: persistenceSerproInformations?.endereco?.socios?.map(
          socio => ({
            name: socio.nome,
            country: socio.pais,
            partnerType: socio.tipoSocio,
            cpf: Mask.apply('cpf', socio.cpf),
            qualification: socio.qualificacao,
            inclusionDate: CustomDate.formatFromISO(socio.dataInclusao),
            legalRepresentative: {
              name: socio.representanteLegal.nome,
              qualification: socio.representanteLegal.qualificacao,
              cpf: Mask.apply('cpf', socio.representanteLegal.cpf)
            }
          })
        )
      },
      situation: persistenceSerproInformations?.situacao && {
        code: persistenceSerproInformations?.situacao?.codigo,
        reason: persistenceSerproInformations?.situacao?.motivo,
        date: CustomDate.formatFromISO(
          persistenceSerproInformations?.situacao?.data
        )
      },
      legalNature: persistenceSerproInformations?.natureza_juridica && {
        code: Mask.apply(
          'legalNature',
          persistenceSerproInformations?.natureza_juridica?.codigo
        ),
        description: persistenceSerproInformations?.natureza_juridica?.descricao
      },
      mainActivity: {
        code: Mask.apply(
          'cnae',
          persistenceSerproInformations?.atividade_principal?.codigo
        ),
        description:
          persistenceSerproInformations?.atividade_principal?.descricao
      }
    };
  }
}

export default new GetSerproInformationsMapper();
