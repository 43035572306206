import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeSm};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
`;
