import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;

  padding: ${({ theme }) => theme.tokens.spacingXxl};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: ${({ theme }) =>
      `${theme.tokens.spacingXxl} ${theme.tokens.spacingMd}`};
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.tokens.spacingLg};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
  }
`;

export const ThumbNail = styled.img`
  width: 16rem;
  aspect-ratio: 1;
  min-width: 16rem;
  max-height: 16rem;
  border-radius: 160px;
  background-color: ${({ theme }) => theme.colors.lightSilver};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.tokens.spacingXs};

  > strong {
    text-align: justify;
    font-size: ${({ theme }) => theme.tokens.fontSizeLg};
    font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
    font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
    color: ${({ theme }) => theme.tokens.brandColorSecondaryPure};
  }

  > p {
    text-align: justify;
    font-size: ${({ theme }) => theme.tokens.fontSizeSm};
    color: ${({ theme }) => theme.tokens.neutralColorLowMedium};
    font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
    font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    > strong {
      font-size: ${({ theme }) => theme.tokens.fontSizeMd};
    }

    > p {
      font-size: ${({ theme }) => theme.tokens.fontSizeXs};
    }
  }
`;

export const Deponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.tokens.spacingXs};

  > strong {
    font-size: ${({ theme }) => theme.tokens.fontSizeMd};
    color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
    font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
    font-weight: ${({ theme }) => theme.tokens.fontWeightSemibold};
  }

  > span {
    font-size: ${({ theme }) => theme.tokens.fontSizeSm};
    color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
    font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
    font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
  }
`;
