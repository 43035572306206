import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  useIncrementCompanyStep
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import { type IUseInitialDirections } from './InitialDirections.types';

export function useInitialDirections(): IUseInitialDirections {
  const { incrementStep } = useIncrementCompanyStep();
  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const {
    data: cachedCompanyOnboardingProgress,
    updateCache: updateCurrentStepCache
  } = useHandleQueryCache({
    query: {
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS],
      query: async () => await CompanyOnboardingService.getProgress()
    }
  });

  function handleContinueToNextStep(): void {
    incrementStep({
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '',
      step: currentStep
    });

    if (currentStep === Number(cachedCompanyOnboardingProgress?.currentStep)) {
      updateCurrentStepCache({
        key: 'currentStep',
        newData: currentStep + 1
      });
    }
  }

  return {
    handleContinueToNextStep
  };
}
