import { Modal, tokens } from 'ds';
import styled from 'styled-components';

export const Content = styled(Modal.Content)`
  gap: ${tokens.spacingXs};
  font-family: ${tokens.fontFamilyPoppins};
`;

export const Footer = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
  gap: ${tokens.spacingXs};
`;
