import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  > div:first-child {
    margin-right: 0.5rem;
  }

  @media (max-width: 1080px) {
    flex-wrap: wrap;

    > div:first-child {
      margin-bottom: 0.5rem;
      margin-right: 0;
    }
  }
`;
