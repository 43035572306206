import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuthContext } from 'data/contexts';
import { useGetCardGroup } from 'data/modules/cards/account/useCases/get-card-group/useGetCardGroup';

import { CardsPageHeader } from 'presentation/pages/cards/components';

import { CardsSection } from '..';

export function GroupManagementPageHeader(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId } = useParams();

  const user = useAuthContext(state => state.user);
  const { cardGroupData } = useGetCardGroup({
    cardGroupId: groupId!,
    enabled: !!groupId
  });

  return (
    <CardsPageHeader
      tabs={[
        {
          active: true,
          label: t('cardsManagement')
        },
        {
          active: false,
          label: t('movements'),
          onClick: () => {
            navigate(`/cartoes/gestao-de-grupos/${groupId}/movimentacoes`);
          }
        },
        {
          active: false,
          label: t('valuesSolicitations'),
          hidden:
            user?.cards?.type !== 'ACCOUNT_MANAGER' &&
            !cardGroupData?.isManager,
          onClick: () => {
            navigate(
              `/cartoes/gestao-de-grupos/${groupId}/solicitacoes-de-valores`
            );
          }
        }
      ]}
    >
      <CardsSection />
    </CardsPageHeader>
  );
}
