import { Fragment, useRef } from 'react';

import { Elements } from 'ds';
import { useDsGa4 } from 'ds/hooks/globals';

import { type IRadioDefaultProps } from './RadioDefault.types';

import {
  CheckIndicator,
  Container,
  InputComponent,
  Label,
  RadioComponent,
  RadioWrapper
} from './RadioDefault.styles';

export function RadioDefault({
  small,
  label,
  disabled,
  id,
  tourClass,
  onChangeValue,
  name,
  ...rest
}: IRadioDefaultProps): JSX.Element {
  const radioRef = useRef(null);
  const { sendDsGaEvent } = useDsGa4();

  return (
    <Fragment>
      <Container
        data-testid='container'
        $disabled={disabled}
        $small={small}
        htmlFor={id}
        className={tourClass}
      >
        <RadioWrapper>
          <InputComponent
            type='radio'
            ref={radioRef}
            disabled={disabled}
            onChange={event => {
              const isRadioInTable = Elements.isInTable(radioRef);

              if (isRadioInTable) {
                sendDsGaEvent('components', 'radio', {
                  objectName: 'tables',
                  description: 'radio',
                  eventName: 'tables',
                  eventAction:
                    event.target.value === 'on'
                      ? 'enable_radio_tables'
                      : 'disable_radio_tables',
                  eventLabel: label ?? name ?? 'radio_without_label'
                });
              } else {
                sendDsGaEvent('components', 'radio', {
                  description:
                    event.target.value === 'on'
                      ? 'Habilitar radio'
                      : 'Desabilitar radio',
                  eventAction:
                    event.target.value === 'on'
                      ? 'enable_radio'
                      : 'disable_radio',
                  eventLabel: label ?? name ?? 'radio_without_label'
                });
              }

              onChangeValue?.(event.target.value);
            }}
            id={id}
            {...rest}
          />

          <RadioComponent>
            <CheckIndicator />
          </RadioComponent>
        </RadioWrapper>

        {label && <Label>{label}</Label>}
      </Container>
    </Fragment>
  );
}
