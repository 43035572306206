import { useQueryClient } from '@tanstack/react-query';

import { useMutationCache, useQueryCache } from 'data/cache';
import {
  BalanceQueryKeys,
  BalanceService,
  type IUseGetAllocatedBalance,
  type IUseGetAllocatedBalanceParams
} from 'data/modules/cards/balance';

export function useGetAllocatedBalance(
  params: IUseGetAllocatedBalanceParams
): IUseGetAllocatedBalance {
  const queryClient = useQueryClient();

  const { data: allocatedBalance, isLoading: isFetchingAllocatedBalance } =
    useQueryCache({
      queryKey: [
        BalanceQueryKeys.GET_ALLOCATED_BALANCE,
        { cardGroupId: params.cardGroupId || null }
      ],
      queryFn: async () =>
        await BalanceService.getAllocatedBalance({
          refresh: 1,
          ...(params.cardGroupId && { cardGroupId: params.cardGroupId })
        })
    });

  const refreshAllocatedBalance = useMutationCache({
    mutationKey: [BalanceQueryKeys.GET_ALLOCATED_BALANCE],
    mutationFn: async () =>
      await BalanceService.getAllocatedBalance({
        refresh: 1,
        ...(params.cardGroupId && { cardGroupId: params.cardGroupId })
      }),
    onSuccess: data => {
      queryClient.setQueryData([BalanceQueryKeys.GET_ALLOCATED_BALANCE], data);
    }
  });

  return {
    allocatedBalance,
    isLoadingAllocatedBalance:
      isFetchingAllocatedBalance || refreshAllocatedBalance.isLoading,
    refreshAllocatedBalance
  };
}
