import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  useCardDetailsContext,
  useCardGroupsContext,
  useModalContext
} from 'data/contexts';
import { useChangeAccountBalanceAmount } from 'data/modules/cards/account';
import {
  BalanceQueryKeys,
  type IUnallocatedBalance
} from 'data/modules/cards/balance';
import {} from 'data/modules/cards/cardGroups';

import { Currency } from 'shared/utils/format';

import {
  type IUseManageCardValueModal,
  type IUseManageCardValueModalParams
} from './ManageCardValueModal.types';

export function useManageCardValueModal({
  type,
  page = 'CARDS'
}: IUseManageCardValueModalParams): IUseManageCardValueModal {
  const queryClient = useQueryClient();

  const { groupId } = useParams();

  const [selectedAccount, handleSetSelectedAccount] = useCardDetailsContext(
    useShallow(state => [state.selectedAccount, state.handleSetSelectedAccount])
  );

  const [selectedCardGroup, handleSelectCardGroup] = useCardGroupsContext(
    useShallow(state => [state.selectedCardGroup, state.handleSelectCardGroup])
  );

  const { handleOpenModal } = useModalContext();

  const isCardGroups = page === 'CARD_GROUPS';

  const {
    methods,
    isChangingAccountBalanceAmount,
    handleChangeAccountBalanceAmount
  } = useChangeAccountBalanceAmount({
    accountBalanceId: isCardGroups
      ? selectedCardGroup?.balanceId || ''
      : selectedAccount?.balanceId || '',
    action: type === 'ADD' ? 'deposit' : 'withdraw',
    onAfterChangeAccountBalanceAmount: amount => {
      if (groupId) {
        queryClient.setQueryData<IUnallocatedBalance>(
          [
            BalanceQueryKeys.GET_UNALLOCATED_BALANCE,
            {
              cardGroupId: groupId
            }
          ],
          oldData => ({
            ...oldData,
            amount:
              type === 'ADD'
                ? (oldData?.amount as number) - amount
                : (oldData?.amount as number) + amount
          })
        );
      }

      isCardGroups
        ? handleOpenModal('groupCardDetails')
        : handleOpenModal('cardDetails');

      if (isCardGroups) {
        handleSelectCardGroup({
          id: selectedCardGroup?.id as string,
          name: selectedCardGroup?.name as string,
          balanceId: selectedCardGroup?.balanceId as string,
          isManager: (selectedCardGroup?.isManager as boolean) ?? false,
          availableValue:
            type === 'ADD'
              ? amount + (selectedCardGroup?.availableValue as number)
              : (selectedCardGroup?.availableValue as number) - amount
        });

        queryClient.setQueryData<IUnallocatedBalance>(
          [
            BalanceQueryKeys.GET_UNALLOCATED_BALANCE,
            {
              cardGroupId: selectedCardGroup?.id
            }
          ],
          oldData => ({
            ...oldData,
            amount:
              type === 'ADD'
                ? amount + (selectedCardGroup?.availableValue as number)
                : (selectedCardGroup?.availableValue as number) - amount
          })
        );
        return;
      }

      handleSetSelectedAccount({
        ...selectedAccount,
        availableBalance:
          type === 'ADD'
            ? Currency.format(
                'BRL',
                amount + Currency.unformat(selectedAccount?.availableBalance)
              )
            : Currency.format(
                'BRL',
                Currency.unformat(selectedAccount?.availableBalance) - amount
              )
      });
    }
  });

  function handleChangeValueFromButtons(quantity: number): void {
    const { amount } = methods.getValues();

    const updatedAmount = amount ? amount + quantity : quantity;

    methods.setValue('amount', updatedAmount as string);
  }

  return {
    methods,
    isChangingAccountBalanceAmount,
    floatAvailableBalance: isCardGroups
      ? (selectedCardGroup?.availableValue as number)
      : Currency.unformat(selectedAccount?.availableBalance),
    handleChangeAccountBalanceAmount,
    handleChangeValueFromButtons,
    isCardGroups
  };
}
