import { useQueryCache } from 'data/cache';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUseGetSerproInformations,
  type IUseGetSerproInformationsParams
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';

export function useGetSerproInformations({
  enabled
}: IUseGetSerproInformationsParams): IUseGetSerproInformations {
  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const {
    data: serproInformations,
    isFetching: isLoadingSerproInformations,
    isSuccess,
    isError: isErrorSerproInformations,
    error: errorSerproInformations
  } = useQueryCache({
    queryKey: [
      CompanyOnboardingQueryKeys.GET_SERPRO_INFORMATIONS,
      cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    ],
    queryFn: async () =>
      await CompanyOnboardingService.getSerproInformations({
        uuidCompanyOnboarding:
          cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      }),
    enabled
  });

  return {
    serproInformations,
    isLoadingSerproInformations,
    isGetSerproInformationsSuccess: isSuccess,
    isErrorSerproInformations,
    errorSerproInformations
  };
}
