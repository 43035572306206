import { Pagination } from 'presentation/components/global/Pagination';
import { GenericTable } from 'presentation/components/global/Table';

import { BusinessCardsBody, BusinessCardsHead } from '..';

import { type IBusinessCardsTableProps } from './BusinessCardsTable.types';

import { Container } from './BusinessCardsTable.styles';

export function BusinessCardsTable({
  businessCards,
  handleChangeSearchParam,
  businessCardsSearchParams,
  totalBusinessCards,
  onChangeAccountId
}: IBusinessCardsTableProps): JSX.Element {
  return (
    <Container>
      <GenericTable
        thead={<BusinessCardsHead />}
        tbody={
          <BusinessCardsBody
            businessCards={businessCards}
            onChangeAccountId={onChangeAccountId}
          />
        }
      />

      <Pagination
        currentPage={businessCardsSearchParams.currentPage}
        total={totalBusinessCards}
        onChangePage={page => {
          handleChangeSearchParam('currentPage', page);
        }}
        pageSize={businessCardsSearchParams.pageSize}
        onChangePageSize={pageSize => {
          handleChangeSearchParam('pageSize', pageSize);
        }}
        breakpointToBreakInColumns='tablet'
      />
    </Container>
  );
}
