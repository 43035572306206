import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 2.5rem;
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Content = styled.div`
  padding: 0.3rem;
  min-height: 18rem;
  flex-grow: 1;
  width: 26rem;

  > p {
    color: ${({ theme }) => theme.colors.emperor};
    margin: 1.5rem 0;
    font-size: 1.2rem;
  }

  > div:last-child {
    margin-top: 1.4rem;
  }
`;

export const CreditCardContainer = styled.div`
  flex-grow: 1;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
