import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IUpdatePartnerOrRepresentativeFieldsForm } from 'data/modules/cards/companyOnboarding';

export function useUpdatePartnerOrRepresentativeSchema(): ObjectSchema<IUpdatePartnerOrRepresentativeFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const updatePartnerOrRepresentative: ObjectSchema<IUpdatePartnerOrRepresentativeFieldsForm> =
    useMemo(() => {
      return yup.object({
        option: yup
          .string()
          .required(lang.schemas.is_required[currentLangKey])
          .test({
            name: 'option',
            message: lang.schemas.is_required[currentLangKey],
            test: (input: string) => ['yes', 'no'].includes(input)
          }),
        document: yup
          .mixed<FileList | string>()
          .test({
            name: 'size',
            message: lang.schemas.file_must_be_less_than_16mb[currentLangKey],
            test: value => {
              if (value instanceof FileList) {
                return value[0]?.size <= 16000000;
              }

              return true;
            }
          })
          .test({
            name: 'type',
            message: lang.schemas.file_must_be_pdf_type[currentLangKey],
            test: value => {
              if (value instanceof FileList) {
                return value && value[0].type === 'application/pdf';
              }

              return true;
            }
          })
      });
    }, [lang, currentLangKey]);

  return updatePartnerOrRepresentative;
}
