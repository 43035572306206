import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import {
  type IUserOnboardingAnswers,
  UserOnboardingQueryKeys,
  UserOnboardingService,
  useUpdateUserOnboardingData
} from 'data/modules/cards/userOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';

import {
  type IUpdateUserOnboardingDataCachePayload,
  type IUseUserData
} from './UserData.types';

export function useUserData(): IUseUserData {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: cachedUserOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () =>
        await UserOnboardingService.getUserOnboardingProgress(),
      key: [UserOnboardingQueryKeys.GET_USER_ONBOARDING_PROGRESS]
    }
  });

  const { control, updateUserOnboardingData, errors, setValue } =
    useUpdateUserOnboardingData({
      onUpdateUserOnboardingDataCache: updateUserOnboardingDataCache
    });

  function updateUserOnboardingDataCache(
    data: IUpdateUserOnboardingDataCachePayload
  ): void {
    queryClient.setQueryData<IUserOnboardingAnswers>(
      [
        UserOnboardingQueryKeys.GET_USER_ONBOARDING_ANSWERS,
        cachedUserOnboardingProgress?.onboardingUserId ?? ''
      ],
      oldAnswersCache => ({
        ...oldAnswersCache,
        userAccount: {
          ...(oldAnswersCache?.userAccount && oldAnswersCache.userAccount),
          ...data
        }
      })
    );

    navigate('/cartoes/onboarding/usuario/3');
  }

  return {
    control,
    updateUserOnboardingData,
    errors,
    setValue
  };
}
