import { zIndex } from 'ds/constants';
import { tokens } from 'ds/tokens';
import { transparentize } from 'polished';
import styled, { css, keyframes } from 'styled-components';

import {
  type ArrowStylesType,
  type ContentContainerStylesType
} from './ModalContainer.types';
import { type AddPrefixToType } from 'ds/types';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 1.6rem;
  background: ${transparentize(0.2, tokens.deepColorBlack)};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndex.modal};

  * {
    &::-webkit-scrollbar {
      width: 1.2rem;
    }

    &::-webkit-scrollbar-track {
      border: solid 6px ${tokens.neutralColorHighDark};
      border-radius: ${tokens.borderRadiusXs};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${tokens.borderRadiusXs};
      background-color: ${tokens.neutralColorLowLightest};
    }
  }
`;

const opacityKeyframes = keyframes`
  from {
    opacity: 0;
    transform: scale(0.3);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const ButtonArrow = styled.button<AddPrefixToType<ArrowStylesType>>`
  background-color: ${tokens.deepColorWhite};
  flex-shrink: 0;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 0;
  opacity: 0;
  user-select: none;
  pointer-events: none;

  > svg {
    width: 4rem;
    height: 4rem;
    color: ${tokens.brandColorPrimaryPure};
  }

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      opacity: 1;
      user-select: unset;
      pointer-events: unset;
    `}
`;

export const Content = styled.div<AddPrefixToType<ContentContainerStylesType>>`
  padding: ${tokens.spacingLg};
  display: flex;
  flex-direction: column;
  background-color: ${tokens.neutralColorHighPure};
  border-radius: ${tokens.borderRadiusMd};
  animation: ${opacityKeyframes} 100ms forwards;
  flex-grow: 1;
  max-height: 100%;

  ${({ $variant }) =>
    $variant === 'small' &&
    css`
      width: 50rem;
      max-width: 50rem;
    `}

  ${({ $variant }) =>
    $variant === 'medium' &&
    css`
      width: 80rem;
      max-width: 80rem;
    `}

  ${({ $variant }) =>
    $variant === 'large' &&
    css`
      width: 98.4rem;
      max-width: 98.4rem;
    `}


  @media screen and (max-width: 1300px) {
    max-width: unset;
    width: auto;
  }
`;

export const ContainerArrows = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${tokens.spacingXs};
  height: 100%;
`;
