import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUpdateCompanyAddressFieldsForm,
  type IUseUpdateCompanyAddress,
  type IUseUpdateCompanyAddressParams,
  type UpdateCompanyOnboardingAnswersPayloadType
} from 'data/modules/cards/companyOnboarding';

import { useFormWithSchema } from 'shared/hooks/forms';
import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import { useUpdateCompanyAddressSchema } from './schemas';

export function useUpdateCompanyAddress({
  onUpdateCompanyOnboardingAnswersMutationSuccess
}: IUseUpdateCompanyAddressParams): IUseUpdateCompanyAddress {
  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const { data: cachedCompanyOnboardingAnswers } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      ],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingAnswers({
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId as string
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    }
  });

  const { data: cachedSerproInformations } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_SERPRO_INFORMATIONS,
        cachedCompanyOnboardingProgress?.onboardingCompanyId as string
      ],
      query: async () =>
        await CompanyOnboardingService.getSerproInformations({
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId as string
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    }
  });

  const {
    control,
    formState: { errors, isDirty },
    setValue,
    handleSubmit,
    watch
  } = useFormWithSchema(useUpdateCompanyAddressSchema(), {
    defaultValues: {
      postalCode:
        cachedCompanyOnboardingAnswers?.companyAccount?.postalCode ??
        cachedSerproInformations?.address?.postalCode,
      street:
        cachedCompanyOnboardingAnswers?.companyAccount?.street ??
        cachedSerproInformations?.address?.street,
      number:
        cachedCompanyOnboardingAnswers?.companyAccount?.number?.toString() ??
        cachedSerproInformations?.address?.number,
      complement: cachedCompanyOnboardingAnswers?.companyAccount?.complement,
      neighborhood:
        cachedCompanyOnboardingAnswers?.companyAccount?.neighborhood ??
        cachedSerproInformations?.address?.neighborhood,
      country: cachedCompanyOnboardingAnswers?.companyAccount?.country ?? 'BR',
      state:
        cachedCompanyOnboardingAnswers?.companyAccount?.state ??
        cachedSerproInformations?.address?.state,
      city:
        cachedCompanyOnboardingAnswers?.companyAccount?.city ??
        cachedSerproInformations?.address?.city
    }
  });

  const { isLoading: isUpdatingCompanyAddress, mutate: updateCompanyAddress } =
    useMutationCache({
      mutationKey: [
        CompanyOnboardingMutationKeys.UPDATE_COMPANY_ONBOARDING_ANSWERS
      ],
      mutationFn: async (
        payload: UpdateCompanyOnboardingAnswersPayloadType
      ) => {
        await CompanyOnboardingService.updateCompanyOnboardingAnswers(payload);
      },
      onSuccess: (data, variables) => {
        onUpdateCompanyOnboardingAnswersMutationSuccess({
          ...variables.answers
        } as IUpdateCompanyAddressFieldsForm);
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  async function onSubmitCompanyAddress(
    userEnteredData: IUpdateCompanyAddressFieldsForm
  ): Promise<void> {
    updateCompanyAddress({
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '',
      step: currentStep,
      context: 'company_account',
      answers: {
        ...userEnteredData,
        number: userEnteredData.number
      }
    });
  }

  async function onSubmit(
    userEnteredData: IUpdateCompanyAddressFieldsForm
  ): Promise<void> {
    if (isUpdatingCompanyAddress) {
      return;
    }

    if (
      !isDirty &&
      currentStep < Number(cachedCompanyOnboardingProgress?.currentStep)
    ) {
      onUpdateCompanyOnboardingAnswersMutationSuccess(userEnteredData);

      return;
    }

    onSubmitCompanyAddress(userEnteredData);
  }

  return {
    handleUpdateCompanyAddress: handleSubmit(onSubmit),
    errors,
    isDirty,
    setValue,
    control,
    watch
  };
}
