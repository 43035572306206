import {
  type IUpdateUserOnboardingAnswersPayload,
  type PersistenceUpdateUserOnboardingAnswersBodyType
} from 'data/modules/cards/userOnboarding';

import { CustomDate } from 'shared/utils/custom';
import { Mask } from 'shared/utils/format';

class UpdateUserOnboardingAnswersMapper {
  toPersistence(
    payload: IUpdateUserOnboardingAnswersPayload
  ): PersistenceUpdateUserOnboardingAnswersBodyType {
    return {
      step: payload.step,
      context: payload.context,
      answers:
        payload.context === 'user_terms_of_use'
          ? {
              accept_privacy_policy: payload.answers.acceptPrivacyPolicy,
              accept_terms_of_use: payload.answers.acceptTermsOfUse
            }
          : payload.context === 'user_account' && payload.step === 2
            ? {
                birth_date: CustomDate.formatToIso(
                  payload.answers.birthDate as string
                ),
                document_number: Mask.remove(
                  payload.answers.documentNumber as string
                ),
                email: payload.answers.email,
                full_name: payload.answers.fullName,
                phone_number: `55${Mask.remove(
                  payload.answers.phoneNumber as string
                )}`,
                mother_name: payload.answers.motherName
              }
            : {
                city: payload.answers.city,
                country: payload.answers.country,
                neighborhood: payload.answers.neighborhood,
                number: payload.answers.number,
                postal_code: Mask.remove(payload.answers.postalCode as string),
                state: payload.answers.state,
                street: payload.answers.street,
                ...(payload.answers.complement && {
                  complement: payload.answers.complement
                })
              }
    };
  }
}

export default new UpdateUserOnboardingAnswersMapper();
