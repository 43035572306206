import { useQueryCache } from 'data/cache';
import {
  BalanceQueryKeys,
  BalanceService,
  type IGetBalanceSolicitationsPayload,
  type IUseGetBalanceSolicitations
} from 'data/modules/cards/balance';

export function useGetBalanceSolicitations({
  ...payload
}: IGetBalanceSolicitationsPayload): IUseGetBalanceSolicitations {
  const {
    data: paginatedBalanceSolicitations,
    isFetching: isFetchingBalanceSolicitations
  } = useQueryCache({
    queryKey: [BalanceQueryKeys.GET_BALANCE_SOLICITATIONS, payload],
    queryFn: async () => await BalanceService.getBalanceSolicitations(payload)
  });

  return {
    paginatedBalanceSolicitations,
    isFetchingBalanceSolicitations
  };
}
