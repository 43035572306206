import { useMutationCache } from 'data/cache';
import {
  AnalyticsMutationKeys,
  AnalyticsService,
  type ISendAnalysisSuggestionPayload,
  type IUseSendAnalysisSuggestion
} from 'data/modules/analytics';

export function useSendAnalysisSuggestion(): IUseSendAnalysisSuggestion {
  const { mutateAsync: sendAnalysisSuggestionAsync, isLoading } =
    useMutationCache({
      mutationKey: [AnalyticsMutationKeys.SEND_SUGGESTION],
      mutationFn: async (payload: ISendAnalysisSuggestionPayload) => {
        return await AnalyticsService.sendAnalysisSuggestion(payload);
      }
    });

  return {
    sendAnalysisSuggestionAsync,
    isSendingSuggestion: isLoading
  };
}
