import styled from 'styled-components';

import { Container as MultipleInputsContainer } from 'presentation/components/base/Input/MultipleInputs/MultipleInputs.styles';

export const ManageBusinessAccountHolderFormTitle = styled.p`
  margin-bottom: 1rem;
`;

export const FirstLineMultipleInputs = styled(MultipleInputsContainer)`
  > div:nth-child(-n + 3) {
    flex: 1 1 6rem;
  }

  > div:nth-child(4) {
    width: 16rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    > div:nth-child(-n + 3) {
      flex: 1 1 auto;
    }

    > div:nth-child(4) {
      width: auto;
    }
  }
`;
