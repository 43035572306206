import {
  type CSSProperties,
  type FC,
  Fragment,
  type KeyboardEvent,
  type ReactNode,
  useEffect,
  useRef
} from 'react';

import { Types } from 'ds';
import { DraggableScrollComponent } from 'ds/components/DraggableScrollComponent/DraggableScrollComponent.component';
import { Checkbox } from 'ds/components/Input/Checkbox';
import { SkeletonLoader } from 'ds/components/SkeletonLoader/SkeletonLoader.component';
import { DSIcons } from 'ds/icons';
import {
  type ClearIndicatorProps,
  components,
  type DropdownIndicatorProps,
  type GroupBase,
  type InputActionMeta,
  type MenuProps,
  type MultiValueProps,
  type MultiValueRemoveProps,
  type OptionProps,
  type SelectInstance,
  type ValueContainerProps
} from 'react-select';
import { type MenuPortalProps } from 'react-select/dist/declarations/src/components/Menu';
import { type FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { useMultiSelectDefault } from './useMultiSelectDefault';

import {
  type IMultiSelectDefaultGroupedOption,
  type IMultiSelectDefaultOption,
  type IMultiSelectDefaultProps,
  type OnChangeMultiSelectDefaultType
} from './MultiSelectDefault.types';

import {
  Caption,
  Container,
  Fieldset,
  Label,
  Legend,
  MenuInformation,
  MenuPortalContainer,
  StyledMultiSelect,
  StyledOption,
  Wrapper
} from './MultiSelectDefault.styles';

const DropdownIndicator: FC<DropdownIndicatorProps> = (props): ReactNode => {
  return (
    <components.DropdownIndicator {...props}>
      <DSIcons.ChevronIcon />
    </components.DropdownIndicator>
  );
};

const ClearIndicator: FC<ClearIndicatorProps> = (props): ReactNode => {
  const {
    children = <DSIcons.ClearIcon id='clear-icon' />,
    getStyles,
    innerProps: { ref, ...restInnerProps }
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props) as CSSProperties}
    >
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  );
};

const MultiValueRemove: FC<MultiValueRemoveProps> = (props): ReactNode => {
  return (
    <components.MultiValueRemove {...props}>
      <DSIcons.ClearIcon />
    </components.MultiValueRemove>
  );
};

const Option: FC<
  OptionProps & {
    searchValue: string;
    hasHighlight?: boolean;
    selectionMode: IMultiSelectDefaultProps['selectionMode'];
  }
> = ({
  children,
  innerRef,
  isSelected,
  innerProps,
  searchValue,
  hasHighlight,
  selectionMode
}): ReactNode => {
  const parts = hasHighlight
    ? String(children).split(new RegExp(`(${searchValue})`, 'gi'))
    : [];

  return (
    <StyledOption
      ref={innerRef}
      {...innerProps}
    >
      <Checkbox.Default
        onChangeValue={() => {}}
        value={selectionMode === 'checkbox' && isSelected}
        className='checkbox-container'
      />

      {hasHighlight ? (
        <span>
          {parts.map((part, i) => (
            <Fragment key={i}>
              {part.toLowerCase() === searchValue.toLowerCase() ? (
                <strong>{part}</strong>
              ) : (
                <Fragment>{part}</Fragment>
              )}
            </Fragment>
          ))}
        </span>
      ) : (
        children
      )}
    </StyledOption>
  );
};

const ValueContainer: FC<ValueContainerProps> = ({ children, ...props }) => {
  return (
    <DraggableScrollComponent maxWidth='69%'>
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    </DraggableScrollComponent>
  );
};

const MultiValue: FC<
  MultiValueProps & {
    optionsToRender?: string[];
  }
> = ({ optionsToRender, children, ...props }): ReactNode => {
  return !optionsToRender?.length ||
    optionsToRender?.includes(
      (props.data as IMultiSelectDefaultOption)?.value
    ) ? (
    <components.MultiValue {...props}>{children}</components.MultiValue>
  ) : null;
};

const Menu: FC<MenuProps & { information?: string }> = ({
  children,
  information,
  ...props
}): ReactNode => {
  return (
    <components.Menu {...props}>
      {Boolean(information) && <MenuInformation>{information}</MenuInformation>}
      {children}
    </components.Menu>
  );
};

export function MultiSelectDefault({
  name,
  options,
  value,
  error,
  disabled,
  className,
  placeholder,
  information,
  noOptionsMessage,
  onChange,
  onInputChange,
  onSelectOption,
  onDeselectOption,
  optionsToRender,
  clearInputOnBlur,
  selectionMode = 'default',
  hasHighlight = false,
  hasArrowDownIndicator = false,
  isLoading = false,
  label,
  large = false,
  small = false,
  usePortal,
  portalZIndex = 9999,
  allowClearValues = true,
  shouldBlur = false
}: IMultiSelectDefaultProps): JSX.Element {
  const selectRef = useRef<SelectInstance>(null);

  useEffect(() => {
    if (shouldBlur) {
      selectRef.current?.blur();
    }
  }, [shouldBlur]);

  const {
    searchValue,
    setSearchValue,
    handleMultiSelectDefaultFilteredOptions
  } = useMultiSelectDefault({
    options
  });

  const MenuPortal: FC<
    MenuPortalProps<unknown, boolean, GroupBase<unknown>>
  > = (props): ReactNode => {
    return (
      <MenuPortalContainer
        $disabled={disabled}
        $large={large}
        $small={small}
        $hasInformation={Boolean(information)}
        {...props}
      >
        {props.children}
      </MenuPortalContainer>
    );
  };

  const componentToRender = (
    value: string[],
    onChange: OnChangeMultiSelectDefaultType
  ): JSX.Element => (
    <div>
      <Wrapper
        $large={large}
        $small={small}
      >
        <StyledMultiSelect
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={selectRef as any}
          $large={large}
          $error={error}
          $small={small}
          $hasArrowDownIndicator={hasArrowDownIndicator}
          $isTouched={(value.length !== 0 || !!searchValue) && !disabled}
          $hasInformation={Boolean(information)}
          $disabled={disabled}
          $allowClearValues={allowClearValues}
          styles={{ menuPortal: base => ({ ...base, zIndex: portalZIndex }) }}
          isMulti
          isClearable
          openMenuOnFocus
          isSearchable
          components={{
            DropdownIndicator,
            ClearIndicator,
            MultiValueRemove,
            ValueContainer,
            MenuPortal,
            MultiValue: props => (
              <MultiValue
                {...props}
                optionsToRender={optionsToRender}
              />
            ),
            Option: props => (
              <Option
                {...props}
                searchValue={searchValue}
                hasHighlight={hasHighlight}
                selectionMode={selectionMode}
              />
            ),
            Menu: props => (
              <Menu
                {...props}
                information={information}
              />
            )
          }}
          name={name}
          classNamePrefix={'react-multiSelect-custom'}
          placeholder={!disabled ? placeholder ?? 'Selecione...' : placeholder}
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleMultiSelectDefaultFilteredOptions(value, onChange);
            }
          }}
          isDisabled={disabled}
          options={options}
          className={className}
          isOptionSelected={(option: unknown) => {
            const typedOption = option as IMultiSelectDefaultOption;
            const typedValue = value;
            return typedValue.includes(typedOption.value);
          }}
          inputValue={searchValue}
          onInputChange={(value: string, action: InputActionMeta) => {
            if (
              (action?.action === 'input-blur' && clearInputOnBlur) ||
              (action?.action !== 'input-blur' &&
                action?.action !== 'menu-close')
            ) {
              const inputValue = onInputChange?.(value) ?? value;

              setSearchValue(inputValue);
            }
          }}
          hideSelectedOptions={selectionMode === 'default'}
          filterOption={(option: FilterOptionOption<unknown>, input: string) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          value={options
            .map(option =>
              Types.isType<IMultiSelectDefaultGroupedOption, typeof option>(
                option,
                'options'
              )
                ? option.options
                : [option]
            )
            .flat()
            .filter(option => value.includes(option.value))}
          onChange={(newValue: unknown, action) => {
            const typedNewValue = newValue as IMultiSelectDefaultOption[];
            onChange(typedNewValue.map(typedNewValue => typedNewValue.value));

            if (action.action === 'select-option') {
              onSelectOption?.(
                action.name,
                (action?.option as IMultiSelectDefaultOption)?.value
              );

              return;
            }

            if (
              action.action === 'deselect-option' ||
              action.action === 'remove-value'
            ) {
              onDeselectOption?.(
                action.name,
                (action?.option as IMultiSelectDefaultOption)?.value
              );
            }
          }}
          closeMenuOnSelect={false}
          menuPortalTarget={usePortal ? document.body : null}
          noOptionsMessage={() => noOptionsMessage ?? 'Sem mais opções'}
        />

        <Fieldset>
          <Legend>
            <span>{label}</span>
          </Legend>
        </Fieldset>

        {label && <Label>{label}</Label>}
      </Wrapper>

      {error && <Caption $error={error}>{error}</Caption>}
    </div>
  );

  return (
    <Container
      onClick={() => {
        selectRef.current?.focus();
      }}
    >
      {isLoading ? (
        <SkeletonLoader width='32.2rem' />
      ) : (
        componentToRender(value as string[], onChange)
      )}
    </Container>
  );
}
