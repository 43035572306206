import { SkeletonLoader } from 'presentation/components/global/Loader';

import {
  BusinessCardsContainer,
  Container,
  HeaderContainer
} from './BusinessCardsSkeleton.styles';

export function BusinessCardsSkeleton(): JSX.Element {
  return (
    <Container>
      <HeaderContainer>
        <SkeletonLoader height='4rem' />
      </HeaderContainer>

      {Array.from({ length: 5 }, (value, i) => (
        <BusinessCardsContainer key={i}>
          <SkeletonLoader height='4rem' />
        </BusinessCardsContainer>
      ))}
    </Container>
  );
}
