import styled from 'styled-components';

export const RadioInput = styled.div`
  display: flex;
  align-items: center;
  width: 1.3rem;
  height: 1.3rem;
  position: relative;

  > label {
    cursor: pointer;
    padding-left: 0.8rem;
    font-size: 1.2rem;
  }

  > input {
    cursor: pointer;
  }
`;
