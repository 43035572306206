import { type BaseSyntheticEvent, useEffect, useState } from 'react';

import { toast } from 'ds';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useModalContext } from 'data/contexts';
import { type ICreateVehicleForm } from 'data/modules/fuel';
import {
  useCreateVehicle,
  useGetPaginatedMembers,
  useGetTypesOfFuels,
  useGetTypesOfVehicles,
  useGetVehicle
} from 'data/modules/fuel/useCases';

import { type ISelectOption } from 'presentation/components/base/Select/Select.types';

import { routesPathPrefix } from 'shared/constants/global';
import { TransformArray } from 'shared/utils/global';

import {
  type ILinkUnlinkMembersStepStateOnCreate,
  type ILinkUnlinkMembersStepStateOnUpdate,
  type IUseCreateVehicleModal,
  type IUseCreateVehicleModalProps,
  type StepType
} from './CreateVehicleModal.types';

export function useCreateVehicleModal({
  vehicleIdToUpdate
}: IUseCreateVehicleModalProps): IUseCreateVehicleModal {
  const [
    linkUnlinkMembersStepStateOnCreate,
    setLinkUnlinkMembersStepStateOnCreate
  ] = useState<ILinkUnlinkMembersStepStateOnCreate>({
    all: false,
    attachMembersIds: new Set(),
    detachMembersIds: new Set()
  });

  const [
    linkUnlinkMembersStepStateOnUpdate,
    setLinkUnlinkMembersStepStateOnUpdate
  ] = useState<ILinkUnlinkMembersStepStateOnUpdate>({
    clickedOnSelectAllMembers: false,
    all: false,
    attachMembersIds: new Set(),
    detachMembersIds: new Set(),
    changedMembers: {
      attach: new Set(),
      detach: new Set()
    }
  });

  const userAction = vehicleIdToUpdate ? 'update' : 'create';

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [showConfirmEmptyModal, setShowConfirmEmptyModal] = useState(false);

  const { typesOfFuels, isLoadingTypesOfFuels } = useGetTypesOfFuels();

  // usa a request somente para pegar o total de membros
  const { paginatedMembers, isLoadingPaginatedMembers } =
    useGetPaginatedMembers({
      page: 1
    });

  const totalMembers = paginatedMembers?.total;

  const {
    paginatedMembers: paginatedLinkedMembers,
    isInitialLoadingPaginatedMembers: isInitialLoadingPaginatedLinkedMembers
  } = useGetPaginatedMembers({
    linked: true,
    vehicleId: vehicleIdToUpdate,
    enabled: vehicleIdToUpdate !== undefined
  });

  const totalLinkedMembers = paginatedLinkedMembers?.total;

  function handleConfirmLinkMember(): void {
    const showConfirmEmptyModalOnCreate = // se a ação do usuário for de criação
      userAction === 'create' &&
      // se não está marcado o "Selecionar todos"
      // E se o tamanho do detach for igual a 0
      // E se o tamanho do attach for igual a 0
      !linkUnlinkMembersStepStateOnCreate.all &&
      linkUnlinkMembersStepStateOnCreate.detachMembersIds.size === 0 &&
      linkUnlinkMembersStepStateOnCreate.attachMembersIds.size === 0;

    const changedMembersDetachOrAttachEmpty =
      // se o tamanho do detach for igual ao número de membros previamente vinculados
      // e o tamanho do attach for igual a 0, então não será vinculado membros ao veículo
      !linkUnlinkMembersStepStateOnUpdate.clickedOnSelectAllMembers &&
      linkUnlinkMembersStepStateOnUpdate.changedMembers.detach.size ===
        totalLinkedMembers &&
      linkUnlinkMembersStepStateOnUpdate.changedMembers.attach.size === 0;

    const clickedOnSelectAllMembersEmpty =
      // ou então se o usuário manipulou o botão de "Selecionar todos"
      // e o all não está marcado
      // e (número de membros desvinculados é igual ao total de membros ou número de membros vinculados é igual a 0)
      // então, não será vinculado membros ao veículo
      linkUnlinkMembersStepStateOnUpdate.clickedOnSelectAllMembers &&
      !linkUnlinkMembersStepStateOnUpdate.all &&
      (linkUnlinkMembersStepStateOnUpdate.detachMembersIds.size ===
        totalMembers ||
        linkUnlinkMembersStepStateOnUpdate.attachMembersIds.size === 0);

    const showConfirmEmptyModalOnUpdate = // se a ação do usuário for de atualização
      userAction === 'update' &&
      (changedMembersDetachOrAttachEmpty || clickedOnSelectAllMembersEmpty);

    if (showConfirmEmptyModalOnCreate || showConfirmEmptyModalOnUpdate) {
      setShowConfirmEmptyModal(true);
      return;
    }

    userAction === 'update' && setShowConfirmModal(true);
    userAction === 'create' && handleSubmitCreateOrUpdateVehicle();
  }

  useEffect(() => {
    // se não estiver carregando membros paginados vinculados e desvinculados
    // e estiver em atualização de veículo
    // então, verifica se todos os membros estão vinculados
    if (
      !isInitialLoadingPaginatedLinkedMembers &&
      !isLoadingPaginatedMembers &&
      userAction === 'update'
    ) {
      setLinkUnlinkMembersStepStateOnUpdate(oldState => ({
        ...oldState,
        all: totalLinkedMembers === totalMembers
      }));
    }
  }, [
    totalMembers,
    totalLinkedMembers,
    isInitialLoadingPaginatedLinkedMembers,
    isLoadingPaginatedMembers,
    userAction
  ]);

  const { typesOfVehicles, isLoadingTypesOfVehicles } = useGetTypesOfVehicles();

  const { handleChangeErrorMessage, handleCloseModal, handleOpenModal } =
    useModalContext();

  const [step, setStep] = useState<StepType>('defaultFields');

  const { t } = useTranslation('fuel');

  const { id } = useParams();

  const navigate = useNavigate();

  const {
    isCreatingVehicle,
    isUpdatingVehicle,
    defaultFields: { formMethods, handleSubmit: handleSubmitDefaultFields },
    handleSubmitCreateOrUpdateVehicle,
    isSearchingPlate
  } = useCreateVehicle({
    vehicleIdToUpdate,
    linkMembersOnCreate: {
      linkAllMembers: linkUnlinkMembersStepStateOnCreate.all,
      attachMembersIds: Array.from(
        linkUnlinkMembersStepStateOnCreate.attachMembersIds
      ),
      detachMembersIds: Array.from(
        linkUnlinkMembersStepStateOnCreate.detachMembersIds
      )
    },
    linkMembersOnUpdate: {
      ...linkUnlinkMembersStepStateOnUpdate
    },
    onErrorCreateOrUpdateVehicle: (message: string) => {
      handleChangeErrorMessage({
        title: vehicleIdToUpdate
          ? t('modal.errorUpdatingVehicle')
          : t('modal.errorCreatingVehicle'),
        message
      });
    },
    onAfterSubmitDefaultFields: () => {
      setStep('linkMembers');
    },
    onSuccessCreateVehicle: () => {
      handleCloseModal();

      toast.success(t('modal.successCreatingVehicle'));

      navigate(`${routesPathPrefix.settings}/combustivel/veiculos`);
    },
    onSuccessUpdateVehicle: () => {
      handleCloseModal();

      toast.success(t('modal.successUpdatingVehicle'));

      navigate(`${routesPathPrefix.settings}/combustivel/veiculos`);
    }
  });

  const { getVehicle, isFetchingAndPendingVehicle } = useGetVehicle({
    vehicleId: vehicleIdToUpdate as number,
    enabled: vehicleIdToUpdate !== undefined
  });

  async function loadVehicleAndPrePopulateFieldsFromUpdate(): Promise<void> {
    if (vehicleIdToUpdate === undefined) {
      return;
    }

    const vehicleToUpdate = await getVehicle({
      vehicleId: vehicleIdToUpdate
    });

    const vehicleValues: ICreateVehicleForm = {
      fuel: vehicleToUpdate.fuels.map(item => item.id),
      model: vehicleToUpdate.model,
      owner: vehicleToUpdate.owner,
      plate: vehicleToUpdate.plate,
      type: vehicleToUpdate.vehicleType.id
    };

    formMethods.reset(vehicleValues);
  }

  useEffect(() => {
    if (vehicleIdToUpdate !== undefined && !isFetchingAndPendingVehicle) {
      loadVehicleAndPrePopulateFieldsFromUpdate();
      return;
    }

    formMethods.reset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleIdToUpdate, formMethods]);

  const {
    formState: { isDirty }
  } = formMethods;

  const submitButtonDisabled =
    step === 'defaultFields' && !isDirty && userAction === 'create';

  const isShowingModalLoading =
    isCreatingVehicle ||
    isUpdatingVehicle ||
    isFetchingAndPendingVehicle ||
    isLoadingPaginatedMembers ||
    isInitialLoadingPaginatedLinkedMembers ||
    isSearchingPlate;

  const typesOfFuelsOptions = TransformArray.objectsToSelectOptions(
    typesOfFuels,
    'id',
    'description'
  );

  const typesOfOwnersOptions: ISelectOption[] = [
    {
      value: 'Empresa',
      label: 'Empresa'
    },
    {
      value: 'Colaborador',
      label: 'Colaborador'
    }
  ];

  function handleGoBackButton(): void {
    if (step === 'linkMembers') {
      setStep('defaultFields');
      return;
    }

    if (vehicleIdToUpdate && !!id) {
      navigate(
        `${routesPathPrefix.settings}/combustivel/veiculos/${vehicleIdToUpdate}`
      );

      handleOpenModal('viewVehicle');
      return;
    }

    navigate(`${routesPathPrefix.settings}/combustivel/veiculos`);
    handleCloseModal();
  }

  const typesOfVehiclesOptions = TransformArray.objectsToSelectOptions(
    typesOfVehicles,
    'id',
    'description'
  );

  function handleClickOnMemberOnCreate(id: number): void {
    setLinkUnlinkMembersStepStateOnCreate(oldState => {
      const oldSelectAll = oldState.all;
      const oldAttachMembersIds = new Set([...oldState.attachMembersIds]);
      const oldDetachMembersIds = new Set([...oldState.detachMembersIds]);

      // se o selecionar todos estava previamente marcado
      if (oldSelectAll) {
        return {
          all: false,
          attachMembersIds: new Set(),
          detachMembersIds: new Set([id])
        };
      }

      // se o detach tiver algum elemento, cai nesse IF
      if (oldDetachMembersIds.size > 0) {
        // se o ID estava em detachMembersIds, remove
        if (oldDetachMembersIds.has(id)) {
          oldDetachMembersIds.delete(id);

          return {
            all: oldDetachMembersIds.size === 0,
            attachMembersIds: oldAttachMembersIds,
            detachMembersIds: oldDetachMembersIds
          };
        }

        // se não estava, adiciona no Set
        oldDetachMembersIds.add(id);
        return {
          all: false,
          attachMembersIds: oldAttachMembersIds,
          detachMembersIds: oldDetachMembersIds
        };
      }

      // se o ID estava em attachMembersIds, remove
      if (oldAttachMembersIds.has(id)) {
        oldAttachMembersIds.delete(id);

        return {
          all: false,
          attachMembersIds: oldAttachMembersIds,
          detachMembersIds: oldDetachMembersIds
        };
      }

      // se não estava em nenhum, adiciona em attachMembersIds
      oldAttachMembersIds.add(id);
      return {
        all: oldAttachMembersIds.size === totalMembers,
        attachMembersIds: oldAttachMembersIds,
        detachMembersIds: oldDetachMembersIds
      };
    });
  }

  function handleClickSelectAllMembersOnCreate(): void {
    setLinkUnlinkMembersStepStateOnCreate(oldState => {
      const oldSelectAll = oldState.all;

      return {
        all: !oldSelectAll,
        attachMembersIds: new Set(),
        detachMembersIds: new Set()
      };
    });
  }

  function handleClickSelectAllMembersOnUpdate(): void {
    setLinkUnlinkMembersStepStateOnUpdate(prevState => {
      return {
        changedMembers: {
          attach: new Set(),
          detach: new Set()
        },
        // registra que a opção "Selecionar Todos os Membros" foi clicada
        clickedOnSelectAllMembers: true,
        // inverte o estado de "Selecionar Todos os Membros"
        all: !prevState.all,
        attachMembersIds: new Set(),
        detachMembersIds: new Set()
      };
    });
  }

  function handleClickOnMemberOnUpdate(
    isLinkedOrUnlinked: 'linked' | 'unlinked',
    id: number
  ): void {
    setLinkUnlinkMembersStepStateOnUpdate(oldState => {
      // Verifica se a opção "Selecionar Todos os Membros" foi clicada
      const clickedOnSelectAllMembers = oldState.clickedOnSelectAllMembers;

      // Se, em algum momento, o "Selecionar todos" foi clicado, só entra nesse IF
      // isso acontece porque o "Selecionar todos" é uma ação que sobrescreve as outras
      if (clickedOnSelectAllMembers) {
        // Obtém o estado anterior da opção "Selecionar Todos" e os conjuntos de membros anexados e desanexados
        const oldSelectAll = oldState.all;
        const newAttachMembersIds = new Set([...oldState.attachMembersIds]);
        const newDetachMembersIds = new Set([...oldState.detachMembersIds]);

        // Se a opção "Selecionar Todos" estava previamente marcada
        if (oldSelectAll) {
          // Retorna um novo estado onde o membro clicado é adicionado ao conjunto de desanexação
          return {
            ...oldState,
            changedMembers: {
              attach: new Set(),
              detach: new Set()
            },
            all: false,
            attachMembersIds: new Set(),
            detachMembersIds: new Set([id])
          };
        }

        // Se houver algum membro no conjunto de desanexação
        if (newDetachMembersIds.size > 0) {
          // Se o membro está no conjunto de desanexação, remova-o
          if (newDetachMembersIds.has(id)) {
            newDetachMembersIds.delete(id);

            return {
              ...oldState,
              changedMembers: {
                attach: new Set(),
                detach: new Set()
              },
              all: newDetachMembersIds.size === 0,
              attachMembersIds: newAttachMembersIds,
              detachMembersIds: newDetachMembersIds
            };
          }

          // Se o membro não estava no conjunto de desanexação, adiciona-o
          newDetachMembersIds.add(id);
          return {
            ...oldState,
            changedMembers: {
              attach: new Set(),
              detach: new Set()
            },
            all: false,
            attachMembersIds: newAttachMembersIds,
            detachMembersIds: newDetachMembersIds
          };
        }

        // Se o ID estava em attachMembersIds, remove-o
        if (newAttachMembersIds.has(id)) {
          newAttachMembersIds.delete(id);

          return {
            ...oldState,
            changedMembers: {
              attach: new Set(),
              detach: new Set()
            },
            all: false,
            attachMembersIds: newAttachMembersIds,
            detachMembersIds: newDetachMembersIds
          };
        }

        // Se o ID não estava em nenhum dos conjuntos, adiciona-o em attachMembersIds
        newAttachMembersIds.add(id);
        return {
          ...oldState,
          changedMembers: {
            attach: new Set(),
            detach: new Set()
          },
          all: newAttachMembersIds.size === totalMembers,
          attachMembersIds: newAttachMembersIds,
          detachMembersIds: newDetachMembersIds
        };
      }

      // entra nos códigos abaixo se a opção "Selecionar Todos os Membros" não foi clicada
      // nesse caso, o usuário está clicando em membros individualmente
      // Se o membro veio da API como vinculado
      if (isLinkedOrUnlinked === 'linked') {
        const newChangedMembersDetach = new Set(oldState.changedMembers.detach);

        // Se o membro já está no conjunto de desanexação, remove-o, caso contrário, adiciona-o
        if (newChangedMembersDetach.has(id)) {
          newChangedMembersDetach.delete(id);
        } else {
          newChangedMembersDetach.add(id);
        }

        return {
          ...oldState,
          changedMembers: {
            attach: oldState.changedMembers.attach,
            detach: newChangedMembersDetach
          },
          all:
            oldState.changedMembers.attach.size +
              (totalLinkedMembers as number) -
              newChangedMembersDetach.size ===
            totalMembers,
          attachMembersIds: new Set(),
          detachMembersIds: new Set()
        };
      }

      // Se o membro veio da API como desvinculado
      if (isLinkedOrUnlinked === 'unlinked') {
        const newChangedMembersAttach = new Set(oldState.changedMembers.attach);

        // Se o membro já está no conjunto de anexação, remove-o, caso contrário, adiciona-o
        if (newChangedMembersAttach.has(id)) {
          newChangedMembersAttach.delete(id);
        } else {
          newChangedMembersAttach.add(id);
        }

        return {
          ...oldState,
          changedMembers: {
            attach: newChangedMembersAttach,
            detach: oldState.changedMembers.detach
          },
          all:
            newChangedMembersAttach.size +
              (totalLinkedMembers as number) -
              oldState.changedMembers.detach.size ===
            totalMembers,
          attachMembersIds: new Set(),
          detachMembersIds: new Set()
        };
      }

      // Retorna o estado antigo se nenhuma das condições acima for atendida
      return oldState;
    });
  }

  function handleSubmitForm(e: BaseSyntheticEvent): void {
    e.preventDefault();

    if (step === 'defaultFields') {
      handleSubmitDefaultFields(e);
      return;
    }

    handleConfirmLinkMember();
  }

  const vehicleModelFromFormValue = formMethods.getValues('model');
  const vehiclePlateFromFormValue = formMethods.getValues('plate');

  return {
    isShowingModalLoading,
    submitButtonDisabled,
    handleGoBackButton,
    handleClickOnMemberOnUpdate,
    handleClickSelectAllMembersOnUpdate,
    vehicleModelFromFormValue,
    vehiclePlateFromFormValue,
    showConfirmEmptyModal,
    showConfirmModal,
    linkUnlinkMembersStepStateOnUpdate,
    typesOfFuelsOptions,
    step,
    setShowConfirmEmptyModal,
    setShowConfirmModal,
    handleSubmitForm,
    handleSubmitCreateOrUpdateVehicle,
    typesOfOwnersOptions,
    typesOfVehiclesOptions,
    formMethods,
    userAction,
    isLoadingTypesOfFuels,
    isLoadingTypesOfVehicles,
    handleClickSelectAllMembersOnCreate,
    handleClickOnMemberOnCreate,
    linkUnlinkMembersStepStateOnCreate
  };
}
