import { CardInfo } from 'ds';
import { useParams } from 'react-router-dom';

import { useChargeCardsContext, useLangContext } from 'data/contexts';
import {
  useGetAllocatedBalance,
  useGetUnallocatedBalance
} from 'data/modules/cards/balance';

import { Currency } from 'shared/utils/format';
import LangUtil from 'shared/utils/global/Lang.util';

import { type ICardsInfoOverviewProps } from './CardsInfoOverview.types';

import { CardInfoContainer, Container } from './CardsInfoOverview.styles';

export function CardsInfoOverview({
  ...rest
}: ICardsInfoOverviewProps): JSX.Element {
  const { groupId } = useParams();

  const {
    currentLangKey,
    lang: {
      cards: {
        valueAllocation: { cards_info: cardsInfo }
      }
    }
  } = useLangContext();

  const operationsSummary = useChargeCardsContext(
    state => state.operationsSummary
  );

  const {
    isLoadingAllocatedBalance,
    allocatedBalance,
    refreshAllocatedBalance
  } = useGetAllocatedBalance({ ...(groupId && { cardGroupId: groupId }) });

  const { isFetchingUnallocatedBalance, unallocatedBalance } =
    useGetUnallocatedBalance({ ...(groupId && { cardGroupId: groupId }) });

  return (
    <Container {...rest}>
      <CardInfoContainer>
        <CardInfo.Title variant='small'>
          {cardsInfo.amount_available_to_allocate[currentLangKey]}
        </CardInfo.Title>

        <CardInfo.ValueContainer>
          <CardInfo.Value
            variant='small'
            isLoading={
              isFetchingUnallocatedBalance ||
              (!unallocatedBalance?.amount && unallocatedBalance?.amount !== 0)
            }
          >
            {unallocatedBalance &&
              Currency.format('BRL', unallocatedBalance?.amount, true)}
          </CardInfo.Value>
        </CardInfo.ValueContainer>

        <CardInfo.Caption variant='small'>
          {cardsInfo.amount_not_allocated_to_cards[currentLangKey]}
        </CardInfo.Caption>
      </CardInfoContainer>

      <CardInfoContainer>
        <CardInfo.Title variant='small'>
          {cardsInfo.amount_distributed_on_cards[currentLangKey]}
        </CardInfo.Title>

        <CardInfo.ValueContainer>
          <CardInfo.Value
            variant='small'
            isLoading={
              isLoadingAllocatedBalance ||
              (!allocatedBalance?.amount && allocatedBalance?.amount !== 0)
            }
          >
            {allocatedBalance &&
              Currency.format('BRL', allocatedBalance?.amount, true)}
          </CardInfo.Value>

          <CardInfo.RefreshButton
            onClick={() => {
              !isLoadingAllocatedBalance && refreshAllocatedBalance.mutate(1);
            }}
          />
        </CardInfo.ValueContainer>

        <CardInfo.Caption
          variant='small'
          isLoading={
            isLoadingAllocatedBalance ||
            (!allocatedBalance?.amount && allocatedBalance?.amount !== 0)
          }
        >
          {allocatedBalance &&
            LangUtil.putValuesInString(cardsInfo.updated_on[currentLangKey], {
              date: allocatedBalance.lastUpdateDate,
              hours: allocatedBalance.lastUpdateTime
            })}
        </CardInfo.Caption>
      </CardInfoContainer>

      <CardInfoContainer>
        <CardInfo.Title variant='small'>
          {cardsInfo.amount_available_after_allocating[currentLangKey]}
        </CardInfo.Title>

        <CardInfo.ValueContainer>
          <CardInfo.Value
            variant='small'
            isLoading={
              isFetchingUnallocatedBalance ||
              (!unallocatedBalance?.amount && unallocatedBalance?.amount !== 0)
            }
          >
            {unallocatedBalance &&
              Currency.format(
                'BRL',
                unallocatedBalance.amount -
                  operationsSummary.totalToAdd +
                  operationsSummary.totalToRemove,
                true
              )}
          </CardInfo.Value>
        </CardInfo.ValueContainer>

        <CardInfo.Caption variant='small'>
          {cardsInfo.amount_not_allocated_to_cards[currentLangKey]}
        </CardInfo.Caption>
      </CardInfoContainer>

      <CardInfoContainer>
        <CardInfo.Title variant='small'>
          {cardsInfo.amount_distributed_after_allocating[currentLangKey]}
        </CardInfo.Title>

        <CardInfo.ValueContainer>
          <CardInfo.Value
            variant='small'
            isLoading={
              isLoadingAllocatedBalance ||
              (!allocatedBalance?.amount && allocatedBalance?.amount !== 0)
            }
          >
            {allocatedBalance &&
              Currency.format(
                'BRL',
                allocatedBalance.amount +
                  operationsSummary.totalToAdd -
                  operationsSummary.totalToRemove,
                true
              )}
          </CardInfo.Value>
        </CardInfo.ValueContainer>

        <CardInfo.Caption variant='small'>
          {cardsInfo.total_value_of_transfers[currentLangKey]}
        </CardInfo.Caption>
      </CardInfoContainer>
    </Container>
  );
}
