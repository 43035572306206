import { Checkbox, DefaultTable } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useLangContext, useValueSolicitationContext } from 'data/contexts';

import { type IValueSolicitationTableHeadProps } from './ValueSolicitationTableHead.types';

export function ValueSolicitationTableHead({
  balanceSolicitations,
  ...rest
}: IValueSolicitationTableHeadProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  const [
    handleToggleSelectAllSolicitationActions,
    selectedSolicitationIdsList,
    sortByDate,
    handleSortByDate,
    listType
  ] = useValueSolicitationContext(
    useShallow(state => [
      state.handleToggleSelectAllSolicitationActions,
      state.selectedSolicitationIdsList,
      state.sortByDate,
      state.handleSortByDate,
      state.listType
    ])
  );

  return (
    <DefaultTable.Thead {...rest}>
      <DefaultTable.Tr>
        {listType === 'ALL' || listType === 'SENT' ? (
          <DefaultTable.Th width='5%'>
            <Checkbox.Default
              disabled={!balanceSolicitations.length}
              onChangeValue={() => {
                handleToggleSelectAllSolicitationActions(balanceSolicitations);
              }}
              value={
                selectedSolicitationIdsList.length ===
                balanceSolicitations.length
              }
              className='value-solicitation-select-user-checkbox'
            />
          </DefaultTable.Th>
        ) : null}

        <DefaultTable.Th width='12.14%'>
          {lang.cards.valueSolicitation.table.users[currentLangKey]}
        </DefaultTable.Th>

        <DefaultTable.Th
          width='12.14%'
          isActive={sortByDate !== 'default'}
          order={sortByDate}
          onClick={() => {
            handleSortByDate();
          }}
        >
          {lang.cards.valueSolicitation.table.date[currentLangKey]}
        </DefaultTable.Th>

        <DefaultTable.Th width='12.14%'>
          {lang.cards.valueSolicitation.table.usage_prediction[currentLangKey]}
        </DefaultTable.Th>

        <DefaultTable.Th width='12.14%'>
          {lang.cards.valueSolicitation.table.requested_amount[currentLangKey]}
        </DefaultTable.Th>

        <DefaultTable.Th width='12.4%'>
          {lang.cards.valueSolicitation.table.justification[currentLangKey]}
        </DefaultTable.Th>

        <DefaultTable.Th width='12.14%'>Status</DefaultTable.Th>

        <DefaultTable.Th width='12.14%'>
          {lang.cards.valueSolicitation.table.approved_value[currentLangKey]}
        </DefaultTable.Th>

        <DefaultTable.Th
          width='10%'
          className='value-solicitations-card-details'
        >
          {lang.cards.valueSolicitation.table.details[currentLangKey]}
        </DefaultTable.Th>
      </DefaultTable.Tr>
    </DefaultTable.Thead>
  );
}
