import {
  type ICompanyOnboardingTeamMembers,
  type IGetCompanyOnboardingTeamMembersPayload,
  type IPersistenceCompanyOnboardingTeamMembers,
  type IPersistenceGetCompanyOnboardingTeamMembersPayload
} from 'data/modules/cards/companyOnboarding';

import { CustomObject } from 'shared/utils/custom';

class GetCompanyOnboardingTeamMembersMapper {
  toDomain(
    data: IPersistenceCompanyOnboardingTeamMembers
  ): ICompanyOnboardingTeamMembers {
    return {
      ...CustomObject.pick(data, ['name', 'email', 'id', 'uuid']),
      companyId: data.company_id,
      companyUuid: data.company_uuid,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      userType: data.user_type
    };
  }

  toPersistence(
    payload: IGetCompanyOnboardingTeamMembersPayload
  ): IPersistenceGetCompanyOnboardingTeamMembersPayload {
    return {
      page: payload.page,
      per_page: payload.perPage,
      ...(payload.search && { search: payload.search }),
      ...(payload.users && { users: payload.users })
    };
  }
}

export default new GetCompanyOnboardingTeamMembersMapper();
