import { useCallback, useRef } from 'react';

import { type SwiperRef } from 'swiper/react';

import { type IUseCarrouselContainer } from './CarrouselContainer.types';

export function useCarrouselContainer(): IUseCarrouselContainer {
  const sliderRef = useRef<SwiperRef | null>(null);

  const handleSlideToPrev = useCallback(() => {
    if (!sliderRef.current) return;

    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleSlideToNext = useCallback(() => {
    if (!sliderRef.current) return;

    sliderRef.current.swiper.slideNext();
  }, []);

  return { handleSlideToPrev, handleSlideToNext, sliderRef };
}
