import { apiWeb } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import { type IDefaultResponse } from 'data/modules/global';
import { type IUserPaymentMethod } from 'data/modules/paymentMethods';

class PaymentMethodsService {
  async getPaymentMethods(): Promise<IUserPaymentMethod[]> {
    const response = await apiWeb.get<IDefaultResponse<IUserPaymentMethod[]>>(
      `/payment-methods/list-by-user`
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }
}

export default new PaymentMethodsService();
