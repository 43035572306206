import { type IImageCardProps } from './ImageCard.types';

import {
  Container,
  IconWrapper,
  List,
  ListContent,
  ListItem,
  TextLink
} from './ImageCard.styles';

export function ImageCard({
  title,
  items,
  icon,
  ...props
}: IImageCardProps): JSX.Element {
  return (
    <Container {...props}>
      <List>
        <TextLink>{title}</TextLink>
        <ListContent>
          {items.map(item => (
            <ListItem key={item}>{item}</ListItem>
          ))}
        </ListContent>
      </List>
      <IconWrapper>{icon}</IconWrapper>
    </Container>
  );
}
