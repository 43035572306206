import {
  type IAttachCostCentersPayload,
  type IPersistenceChangeCostCenters
} from 'data/modules/costsCenters';

class AttachCostCentersMapper {
  toPersistence(
    domainCostCenters: IAttachCostCentersPayload
  ): IPersistenceChangeCostCenters {
    return {
      costs_centers: domainCostCenters.costsCenters
    };
  }
}

export default new AttachCostCentersMapper();
