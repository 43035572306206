import { useDsGa4 } from 'ds/hooks/globals';

import { type IModalIconCloseProps } from './ModalIconClose.types';

import { StyledIcon } from './ModalIconClose.styles';

export function ModalIconClose({
  onClick,
  ...rest
}: IModalIconCloseProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  return (
    <StyledIcon
      data-testid='icon-close'
      onClick={e => {
        if (onClick) {
          sendDsGaEvent('components', 'modal', {
            description: 'fechar modal',
            eventAction: 'close_modal',
            eventLabel: 'close'
          });

          onClick(e);
        }
      }}
      {...rest}
    />
  );
}
