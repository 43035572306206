import { toast } from 'ds';
import { useTranslation } from 'react-i18next';

import { useAuthContext, useModalContext } from 'data/contexts';
import {
  type IResetCardPinFieldsForm,
  useResetCardPin
} from 'data/modules/cards/account';
import { useResetCardPinSchema } from 'data/modules/cards/account/useCases/reset-card-pin/schemas';

import { useGetUserLoggedAndCardData } from 'shared/hooks/cards';
import { useFormWithSchema } from 'shared/hooks/forms';

import { type IUseChangePinModal } from './ChangePinModal.types';

export function useChangePinModal(): IUseChangePinModal {
  const { t } = useTranslation(['errors', 'cards']);
  const { handleCloseModal } = useModalContext();

  const user = useAuthContext(state => state.user);
  const { userAccounts, outsourcedUserAccounts } = useGetUserLoggedAndCardData(
    {}
  );
  const isSharedAccount = !!user?.cards?.sharedAccounts;

  const accountId = isSharedAccount
    ? (outsourcedUserAccounts?.[0].account.id as string)
    : (userAccounts?.[0].account.id as string);

  const { changePin, isResetingCardPin } = useResetCardPin({
    accountId: accountId || ''
  });

  const methods = useFormWithSchema(useResetCardPinSchema());

  async function handleSubmit(payload: IResetCardPinFieldsForm): Promise<void> {
    try {
      if (!accountId) {
        toast.error(t('errors:itWasNotPossibleToAlter'));
        return;
      }

      await changePin({ accountId, pin: payload.password });

      methods.reset();

      toast.success(t('cards:pinChangedSuccessfully'));

      handleCloseModal();
    } catch (error) {
      toast.error(t('errors:itWasNotPossibleToAlter'));
    }
  }

  return {
    isLoading: isResetingCardPin,
    methods,
    handleSubmit: methods.handleSubmit(handleSubmit)
  };
}
