import { format } from 'date-fns';
import { Alert, DSIcons, Tooltip, ViewImagePdf } from 'ds';
import { useTranslation } from 'react-i18next';

import { useMovementsContext } from 'data/contexts';

import { useCopyTransactionIdToClipboard } from 'presentation/pages/cards/Movements/hooks';
import { Movements } from 'presentation/pages/cards/Movements/utils';

import { CustomString } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

import { TransactionDetails } from '../TransactionDetailsModal';

import { type IInternationalPurchaseProps } from './InternationalPurchase.types';
import { type LanguageType } from 'ds/types';

import {
  ExchangeSection,
  SectionWithFile
} from './InternationalPurchase.styles';

export function InternationalPurchase({
  expense,
  hasErrorInExpense
}: IInternationalPurchaseProps): JSX.Element {
  const { t, i18n } = useTranslation(['cards', 'global']);
  const transactionDetails = useMovementsContext(
    state => state.transactionDetails
  );
  const { handleCopyTransactionIdToClipboard, shouldShowTooltip } =
    useCopyTransactionIdToClipboard();

  return (
    <>
      <TransactionDetails.Header
        description={
          transactionDetails?.merchantName! ||
          transactionDetails?.paymentDescription!
        }
        datetime={`${format(transactionDetails?.datetime!, 'dd/MM/yyyy')} ${t('global:at')} ${format(transactionDetails?.datetime!, 'HH')}h${format(transactionDetails?.datetime!, 'mm')}`}
        value={Currency.format(
          'BRL',
          Math.abs(transactionDetails?.finalBillingAmount!),
          true
        )}
        internationalValue={Currency.format(
          transactionDetails?.currencyCode || 'BRL',
          Math.abs(transactionDetails?.amount!),
          true
        )}
      />

      <TransactionDetails.Section>
        <ExchangeSection>
          <span>{`${Currency.format(transactionDetails?.currencyCode!, 1, true)} = ${Currency.format(
            'BRL',
            transactionDetails?.finalBillingAmount! /
              transactionDetails?.amount!,
            true,
            4,
            { minimumFractionDigits: 4, maximumFractionDigits: 4 }
          )}`}</span>

          <span>Cotação PTAX (Banco Central) + 4% de Spread</span>
        </ExchangeSection>
      </TransactionDetails.Section>

      <TransactionDetails.Section title={t('transactionDetails')}>
        <TransactionDetails.SectionItem>
          <p>{t('cardName')}</p>

          <p className='value'>
            {CustomString.capitalizeWord(
              transactionDetails?.name ||
                transactionDetails?.transferorName ||
                '-'
            )}
          </p>
        </TransactionDetails.SectionItem>

        <TransactionDetails.SectionItem>
          <p>{t('finalOfCard')}</p>

          <p className='value'>{transactionDetails?.cardNumber! || '-'}</p>
        </TransactionDetails.SectionItem>

        <TransactionDetails.SectionItem>
          <p>{t('global:description')}</p>

          <p className='value'>{transactionDetails?.merchantName || '-'}</p>
        </TransactionDetails.SectionItem>

        <TransactionDetails.SectionItem>
          <p>{t('transactionId')}</p>

          <div className='value'>
            {transactionDetails?.transactionId}

            <Tooltip
              trigger='focus'
              placement='top'
              message={t('global:copyed')}
              visible={shouldShowTooltip}
            >
              <div>
                <DSIcons.StickerCopyIcon
                  className='icon'
                  onClick={() => {
                    handleCopyTransactionIdToClipboard(
                      transactionDetails?.transactionId!
                    );
                  }}
                />
              </div>
            </Tooltip>
          </div>
        </TransactionDetails.SectionItem>

        {transactionDetails?.relateds &&
          transactionDetails.relateds.length > 0 && (
            <TransactionDetails.RelatedsContainer>
              {transactionDetails.relateds.map(relatedTransaction => (
                <TransactionDetails.RelatedsItem
                  key={relatedTransaction.transactionId}
                  description={relatedTransaction.paymentDescription}
                  date={`${format(relatedTransaction?.datetime!, 'dd/MM/yyyy')} ${t('global:at')} ${format(relatedTransaction?.datetime!, 'HH')}h${format(relatedTransaction?.datetime!, 'mm')}`}
                  value={Currency.format(
                    'BRL',
                    relatedTransaction?.finalBillingAmount!,
                    true
                  )}
                />
              ))}
            </TransactionDetails.RelatedsContainer>
          )}
      </TransactionDetails.Section>

      {!hasErrorInExpense && expense && expense?.report && (
        <SectionWithFile>
          <TransactionDetails.Section
            title={t('global:expense')}
            hasDivider={false}
          >
            <TransactionDetails.SectionItem>
              <p>{t('global:expenseType')}</p>

              <p className='value'>{expense.expenseType || '-'}</p>
            </TransactionDetails.SectionItem>

            <TransactionDetails.SectionItem>
              <p>{t('global:report')}</p>

              <p className='value'>
                {expense.report ? expense.report.title : '-'}
              </p>
            </TransactionDetails.SectionItem>

            <TransactionDetails.SectionItem>
              <p>{t('global:costCenter')}</p>

              <p className='value'>{expense.costsCenter}</p>
            </TransactionDetails.SectionItem>

            <TransactionDetails.SectionItem>
              <p>{t('global:projects')}</p>

              <p className='value'>
                {expense.apportionments
                  ? expense.apportionments
                      .map(apportionment => apportionment.description)
                      .join(', ')
                  : '-'}
              </p>
            </TransactionDetails.SectionItem>

            <TransactionDetails.SectionItem>
              <p>{t('global:reportStatus')}</p>

              {Movements.getReportStatusIcon(expense.report?.status)}
            </TransactionDetails.SectionItem>
          </TransactionDetails.Section>

          <ViewImagePdf
            url={expense.receiptUrl}
            hasZoomControl
            showZoomTour
            className='view-image'
            portalId='view-image-portal'
            langKey={i18n.language as LanguageType}
            fileLabel={transactionDetails?.merchantName || 'file'}
          />
        </SectionWithFile>
      )}

      {hasErrorInExpense && (
        <TransactionDetails.Section>
          <Alert type='error'>{t('errorOnLoadExpense')}</Alert>
        </TransactionDetails.Section>
      )}
    </>
  );
}
