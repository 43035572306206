import { Checkbox, SkeletonLoader, TextInput } from 'ds';
import { useTranslation } from 'react-i18next';

import { useUpdateVehicleSelectMembers } from './useUpdateVehicleSelectMembers';

import { type IUpdateVehicleSelectMembersProps } from './UpdateVehicleSelectMembers.types';

import {
  Container,
  LoadingItems,
  MemberCountContainer,
  MemberListContainer,
  MembersCount,
  NoMembersMessage,
  SearchContainer
} from './UpdateVehicleSelectMembers.styles';

export function UpdateVehicleSelectMembers({
  vehicleId,
  linkUnlinkMembersStepState,
  onClickOnMember,
  onClickSelectAllMembers
}: IUpdateVehicleSelectMembersProps): JSX.Element {
  const { t } = useTranslation(['fuel', 'global']);

  const {
    infiniteScrollRef,
    rootRef,
    linkedInfiniteMembers,
    unlinkedInfiniteMembers,
    isFetchingInfiniteMembersNextPage,
    handleChangeSearch,
    isLoadingLinkedInfiniteMembers,
    isLoadingUnlinkedInfiniteMembers,
    showSelectAll,
    search,
    totalMembers,
    selectedMembers,
    setSelectedMembers
  } = useUpdateVehicleSelectMembers({
    vehicleId
  });

  return (
    <Container>
      <SearchContainer>
        <TextInput.Default
          small
          label={t('modal.searchMembers')}
          onChangeValue={value => {
            handleChangeSearch(value);
          }}
          value={search}
          iconLeft='SearchBigIcon'
          placeholder={t('modal.searchMembers')}
        />
      </SearchContainer>

      <MemberListContainer ref={rootRef}>
        {!isLoadingLinkedInfiniteMembers &&
          !isLoadingUnlinkedInfiniteMembers &&
          showSelectAll && (
            <MemberCountContainer className='checkbox-container-select-all'>
              <Checkbox.Default
                small
                label={t('modal.selectAll')}
                value={linkUnlinkMembersStepState.all}
                onChangeValue={() => {
                  onClickSelectAllMembers();
                  setSelectedMembers(
                    linkUnlinkMembersStepState.all ? 0 : totalMembers
                  );
                }}
              />

              <MembersCount>
                ({selectedMembers ?? 0} / {totalMembers})
              </MembersCount>
            </MemberCountContainer>
          )}

        {isLoadingLinkedInfiniteMembers ? (
          <LoadingItems>
            {Array.from({ length: 2 }).map((v, index) => (
              <SkeletonLoader
                key={index}
                height='41px'
                loaderClassName='skeleton-loader'
              />
            ))}
          </LoadingItems>
        ) : (
          <>
            {Array.isArray(linkedInfiniteMembers) &&
              linkedInfiniteMembers.length > 0 &&
              linkedInfiniteMembers.map(member => {
                // check será verdadeiro se
                // o usuário clicou em "Selecionar todos os membros"
                // e o membro atual atende a qualquer uma das condições especificadas,
                // OU
                // se o usuário não clicou em "Selecionar todos os membros"
                // e o membro atual não está na lista de membros a serem desvinculados.
                const checked =
                  (linkUnlinkMembersStepState.clickedOnSelectAllMembers &&
                    (linkUnlinkMembersStepState.all ||
                      linkUnlinkMembersStepState.attachMembersIds.has(
                        member.id
                      ) ||
                      (linkUnlinkMembersStepState.detachMembersIds.size > 0 &&
                        !linkUnlinkMembersStepState.detachMembersIds.has(
                          member.id
                        )))) ||
                  (!linkUnlinkMembersStepState.clickedOnSelectAllMembers &&
                    !linkUnlinkMembersStepState.changedMembers.detach.has(
                      member.id
                    ));

                return (
                  <div
                    key={`member-${member.id}`}
                    className='checkbox-container'
                  >
                    <Checkbox.Default
                      small
                      label={member.name}
                      value={checked}
                      onChangeValue={() => {
                        onClickOnMember('linked', member.id);
                        setSelectedMembers(oldValue =>
                          checked ? oldValue - 1 : oldValue + 1
                        );
                      }}
                    />
                  </div>
                );
              })}
          </>
        )}

        {isLoadingUnlinkedInfiniteMembers ? (
          <LoadingItems>
            {Array.from({ length: 2 }).map((v, index) => (
              <SkeletonLoader
                key={index}
                height='41px'
                loaderClassName='skeleton-loader'
              />
            ))}
          </LoadingItems>
        ) : (
          <>
            {Array.isArray(unlinkedInfiniteMembers) &&
              unlinkedInfiniteMembers.length > 0 &&
              unlinkedInfiniteMembers.map(member => {
                // checked será verdadeiro se
                // o usuário clicou em "Selecionar todos os membros"
                // e o membro atual atende a qualquer uma das condições especificadas,
                // OU
                // se o usuário não clicou em "Selecionar todos os membros",
                // e o membro atual está na lista de membros a serem vinculados
                const checked =
                  (linkUnlinkMembersStepState.clickedOnSelectAllMembers &&
                    (linkUnlinkMembersStepState.all ||
                      linkUnlinkMembersStepState.attachMembersIds.has(
                        member.id
                      ) ||
                      (linkUnlinkMembersStepState.detachMembersIds.size > 0 &&
                        !linkUnlinkMembersStepState.detachMembersIds.has(
                          member.id
                        )))) ||
                  (!linkUnlinkMembersStepState.clickedOnSelectAllMembers &&
                    linkUnlinkMembersStepState.changedMembers.attach.has(
                      member.id
                    ));

                return (
                  <div
                    key={`member-${member.id}`}
                    className='checkbox-container'
                  >
                    <Checkbox.Default
                      small
                      label={member.name}
                      value={checked}
                      onChangeValue={() => {
                        onClickOnMember('unlinked', member.id);
                        setSelectedMembers(oldValue =>
                          checked ? oldValue - 1 : oldValue + 1
                        );
                      }}
                    />
                  </div>
                );
              })}
          </>
        )}

        {!isLoadingLinkedInfiniteMembers &&
          !isLoadingUnlinkedInfiniteMembers &&
          linkedInfiniteMembers?.length === 0 &&
          unlinkedInfiniteMembers?.length === 0 && (
            <NoMembersMessage>
              {t('fuel:modal.noMembersFound')}
            </NoMembersMessage>
          )}

        {isFetchingInfiniteMembersNextPage && (
          <LoadingItems>
            {Array.from({ length: 2 }).map((v, index) => (
              <SkeletonLoader
                key={index}
                height='41px'
                loaderClassName='skeleton-loader'
              />
            ))}
          </LoadingItems>
        )}

        <div ref={infiniteScrollRef} />
      </MemberListContainer>
    </Container>
  );
}
