import { DefaultButton, Modal, SecondaryButton } from 'ds';
import { Trans, useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useModalContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';

import { useRemoveManagerFromGroupModal } from './useRemoveManagerFromGroupModal';

import {
  ModalContent,
  ModalFooter
} from './RemoveManagerFromGroupModal.styles';

export function RemoveManagerFromGroupModal(): JSX.Element {
  const { handleCloseModal, handleOpenModal } = useModalContext();
  const [
    managerBeingExcluded,
    selectedCardGroup,
    handleSetManagerBeingExcluded
  ] = useCardGroupsContext(
    useShallow(state => [
      state.managerBeingExcluded,
      state.selectedCardGroup,
      state.handleSetManagerBeingExcluded
    ])
  );
  const { t } = useTranslation(['cards', 'global']);
  const { handleRemoveManagerFromGroup, isRemovingManagerFromGroup } =
    useRemoveManagerFromGroupModal();

  if (isRemovingManagerFromGroup) {
    return <Loader isLoading />;
  }

  return (
    <Modal.Container
      onClose={() => {
        handleCloseModal();
        handleOpenModal('groupCardDetails');
        handleSetManagerBeingExcluded(null);
      }}
      variant='small'
    >
      <Modal.Header>
        <Modal.Title>{t('excludeManagerFromGroup')}</Modal.Title>

        <Modal.IconClose
          onClick={() => {
            handleCloseModal();
            handleOpenModal('groupCardDetails');
          }}
        />
      </Modal.Header>

      <ModalContent>
        <p>
          <Trans
            i18nKey='excludeManagerFromGroupDescription'
            values={{
              card: managerBeingExcluded?.name,
              group: selectedCardGroup?.name
            }}
            components={{ bold: <strong /> }}
          />
        </p>

        <ModalFooter>
          <SecondaryButton
            size='small'
            onClick={() => {
              handleCloseModal();
              handleOpenModal('groupCardDetails');
              handleSetManagerBeingExcluded(null);
            }}
          >
            {t('global:cancel')}
          </SecondaryButton>

          <DefaultButton
            size='small'
            onClick={handleRemoveManagerFromGroup}
          >
            {t('global:confirm')}
          </DefaultButton>
        </ModalFooter>
      </ModalContent>
    </Modal.Container>
  );
}
