import { useCallback, useEffect, useMemo, useState } from 'react';

import { type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useAnalysisUserFiltersContext, useModalContext } from 'data/contexts';
import {
  AnalysisDateToConsiderFilter,
  AnalysisDefaultFilter,
  AnalysisOrderByFilter,
  AnalysisReimbursableNonReimbursableFilter,
  AnalysisUserFilter,
  type IAnalysisUserFilterFieldsForm,
  useGetAnalysisUserFilters,
  useGetAnalysisUserReportsId
} from 'data/modules/analytics';
import { useAnalysisUserFiltersSchema } from 'data/modules/analytics/useCases/get-analysis-user-filters/schemas';
import { ExpenseStatus } from 'data/modules/expenses';

import { useFormWithSchema } from 'shared/hooks/forms';
import { CustomDate } from 'shared/utils/custom';
import { Mask } from 'shared/utils/format';

import {
  type IAnalysisFiltersOptions,
  type IUseSideAnalysisFilterModal
} from './SideAnalysisFilterModal.types';
import {
  type ISelectControlledGroupedOption,
  type ISelectControlledOption
} from 'ds/components/Select/Controlled/SelectControlled.types';

export function useSideAnalysisFilterModal(): IUseSideAnalysisFilterModal {
  const { handleCloseModal } = useModalContext();

  const [appliedFilters, defaultFilters, handleChangeFilters] =
    useAnalysisUserFiltersContext(
      useShallow(state => [
        state.filters,
        state.defaultFilters,
        state.handleChangeFilters
      ])
    );

  const [customFilterTerms, setCustomFilterTerms] = useState<
    IUseSideAnalysisFilterModal['customFilterTerms']
  >({});

  const [analysisUserOptionsToRender, setAnalysisUserOptionsToRender] =
    useState<IUseSideAnalysisFilterModal['analysisUserOptionsToRender']>({});

  const { t } = useTranslation(['analytics', 'global']);

  const methods = useFormWithSchema(useAnalysisUserFiltersSchema(), {
    defaultValues: {
      users: appliedFilters.users,
      orderBy: appliedFilters.orderBy,
      endDate: appliedFilters.endDate,
      projects: appliedFilters.projects,
      initialDate: appliedFilters.initialDate,
      reportCodes: appliedFilters.reportCodes,
      costCenters: appliedFilters.costCenters,
      paymentForms: appliedFilters.paymentForms,
      expensesTypes: appliedFilters.expensesTypes,
      expensesStatus: appliedFilters.expensesStatus,
      dateToConsider: appliedFilters.dateToConsider,
      reimbursableNonReimbursable: appliedFilters.reimbursableNonReimbursable
    }
  });

  const currentUsers = methods.watch('users');
  const currentProjects = methods.watch('projects');
  const currentReportCodes = methods.watch('reportCodes');
  const currentCostCenters = methods.watch('costCenters');
  const currentPaymentForms = methods.watch('paymentForms');
  const currentExpensesTypes = methods.watch('expensesTypes');
  const currentExpensesStatus = methods.watch('expensesStatus');
  const currentEndDate = CustomDate.parseIsoToDate(
    methods.watch('endDate') ?? ''
  );
  const currentInitialDate = CustomDate.parseIsoToDate(
    methods.watch('initialDate') ?? ''
  );

  const { analysisUserFilters } = useGetAnalysisUserFilters();

  const { analysisUserReports } = useGetAnalysisUserReportsId();

  const analysisUserReportsOptions = useMemo(
    () =>
      analysisUserReports.map(({ id }) => ({
        label: String(id),
        value: String(id)
      })),
    [analysisUserReports]
  );

  const analysisUserCostCentersOptions = useMemo(
    () => [
      {
        label: t('global:all', { context: 'male', count: 2 }),
        value: String(AnalysisDefaultFilter.ALL)
      },
      {
        label: t('analytics:filters.options.costCenters.noCostCenter'),
        value: String(AnalysisDefaultFilter.NONE)
      },
      ...(analysisUserFilters?.costCenters.map(costCenter => ({
        label: costCenter.name,
        value: String(costCenter.id)
      })) ?? [])
    ],
    [analysisUserFilters?.costCenters, t]
  );

  const analysisUserExpenseStatusOptions = useMemo(
    () => [
      {
        label: t('global:all', { context: 'male', count: 2 }),
        value: String(AnalysisDefaultFilter.ALL)
      },
      {
        label: t('global:standalone', { context: 'female', count: 2 }),
        value: String(ExpenseStatus.SINGLE)
      },
      {
        label: t('global:open', { context: 'female', count: 2 }),
        value: String(ExpenseStatus.OPEN)
      },
      {
        label: t('global:sent', { context: 'female', count: 2 }),
        value: String(ExpenseStatus.SENT)
      },
      {
        label: t('global:approved', { context: 'female', count: 2 }),
        value: String(ExpenseStatus.APPROVED)
      },
      {
        label: t('global:rejected', { context: 'female', count: 2 }),
        value: String(ExpenseStatus.REPROVED)
      },
      {
        label: t('global:paid', { context: 'female', count: 2 }),
        value: String(ExpenseStatus.PAID)
      }
    ],
    [t]
  );

  const analysisUserExpenseTypesOptions = useMemo(
    () => [
      {
        label: t('global:all', { context: 'male', count: 2 }),
        value: String(AnalysisDefaultFilter.ALL)
      },
      {
        label: t('analytics:filters.options.expensesTypes.noExpenseType'),
        value: String(AnalysisDefaultFilter.NONE)
      },
      ...(analysisUserFilters?.expenseTypes.map(expenseType => ({
        label: expenseType.name,
        value: String(expenseType.id)
      })) ?? [])
    ],
    [analysisUserFilters?.expenseTypes, t]
  );

  const analysisUserProjectsOptions = useMemo(
    () => [
      {
        label: t('global:all', { context: 'male', count: 2 }),
        value: String(AnalysisDefaultFilter.ALL)
      },
      {
        label: t('analytics:filters.options.projects.noProjects'),
        value: String(AnalysisDefaultFilter.NONE)
      },
      ...(analysisUserFilters?.projects.map(expenseType => ({
        label: expenseType.name,
        value: String(expenseType.id)
      })) ?? [])
    ],
    [analysisUserFilters?.projects, t]
  );

  const analysisUserPaymentFormsOptions = useMemo(
    () => [
      {
        label: t('global:all', { context: 'female', count: 2 }),
        value: String(AnalysisDefaultFilter.ALL)
      },
      {
        label: t('analytics:filters.options.paymentForms.noPaymentMethods'),
        value: String(AnalysisDefaultFilter.NONE)
      },
      ...(analysisUserFilters?.paymentForms.map(paymentForm => ({
        label: paymentForm.name,
        value: String(paymentForm.id)
      })) ?? [])
    ],
    [analysisUserFilters?.paymentForms, t]
  );

  const analysisUserFilterOptions: (
    | ISelectControlledOption
    | ISelectControlledGroupedOption
  )[] = useMemo(
    () => [
      {
        label: t('global:all', { context: 'male', count: 2 }),
        value: String(AnalysisDefaultFilter.ALL)
      },
      {
        label: t('analytics:filters.options.users.activeUsers'),
        value: String(AnalysisUserFilter.ACTIVE)
      },
      {
        label: t('analytics:filters.options.users.inactiveUsers'),
        value: String(AnalysisUserFilter.INACTIVE)
      },
      {
        label: t('global:active', { context: 'male', count: 2 }),
        options:
          analysisUserFilters?.users
            .filter(user => user.active)
            .map(user => ({
              label: user.name,
              value: String(user.id)
            })) ?? []
      },
      {
        label: t('global:disabled', { count: 2 }),
        options:
          analysisUserFilters?.users
            .filter(user => !user.active)
            .map(user => ({
              label: user.name,
              value: String(user.id)
            })) ?? []
      }
    ],
    [analysisUserFilters?.users, t]
  );

  const getOptionsWithInputFilter = useCallback(
    (
      key: keyof typeof customFilterTerms,
      currentValues: string[] | undefined,
      options: ISelectControlledOption[],
      context: 'male' | 'female' = 'male'
    ): ISelectControlledOption[] => {
      return customFilterTerms?.[key]
        ? [
            {
              label: t('analytics:filters.information.filterAllWith', {
                term: customFilterTerms?.[key],
                context
              }),
              value: `ALL_WITH_${customFilterTerms?.[key]}`
            },
            ...options
          ]
        : currentValues?.some(optionValue =>
              optionValue.includes(AnalysisDefaultFilter.ALL_WITH)
            )
          ? [
              ...currentValues
                .filter(optionValue =>
                  optionValue.includes(AnalysisDefaultFilter.ALL_WITH)
                )
                .map(optionValue => ({
                  label: t('analytics:filters.information.filterAllWith', {
                    term: optionValue.replace(
                      AnalysisDefaultFilter.ALL_WITH,
                      ''
                    ),
                    context
                  }),
                  value: optionValue
                })),
              ...options
            ]
          : options;
    },
    [customFilterTerms, t]
  );

  const analysisFilters: IAnalysisFiltersOptions = useMemo(
    () => ({
      dateToConsider: [
        {
          label: t(
            'analytics:filters.options.dateToConsider.expenseCreationDate'
          ),
          value: String(AnalysisDateToConsiderFilter.EXPENSE_CREATED_AT)
        },
        {
          label: t(
            'analytics:filters.options.dateToConsider.expenseExecutionDate'
          ),
          value: String(AnalysisDateToConsiderFilter.EXPENSE_DATE)
        },
        {
          label: t(
            'analytics:filters.options.dateToConsider.reportCreationDate'
          ),
          value: String(AnalysisDateToConsiderFilter.REPORT_CREATED_AT)
        },
        {
          label: t(
            'analytics:filters.options.dateToConsider.submissionDateForApproval'
          ),
          value: String(AnalysisDateToConsiderFilter.SEND_TO_APPROVE_DATE)
        },
        {
          label: t('analytics:filters.options.dateToConsider.approvalDate'),
          value: String(AnalysisDateToConsiderFilter.APPROVE_DATE)
        },
        {
          label: t('analytics:filters.options.dateToConsider.paymentDate'),
          value: String(AnalysisDateToConsiderFilter.PAYMENT_DATE)
        }
      ],
      orderBy: [
        {
          label: t(
            'analytics:filters.options.orderBy.ascendingAlphabeticalOrder'
          ),
          value: String(AnalysisOrderByFilter.NAME_ASC)
        },
        {
          label: t(
            'analytics:filters.options.orderBy.descendingAlphabeticalOrder'
          ),
          value: String(AnalysisOrderByFilter.NAME_DESC)
        },
        {
          label: t('analytics:filters.options.orderBy.ascendingExpenseAmount'),
          value: String(AnalysisOrderByFilter.VALUE_ASC)
        },
        {
          label: t('analytics:filters.options.orderBy.descendingExpenseAmount'),
          value: String(AnalysisOrderByFilter.VALUE_DESC)
        }
      ],
      costCenters: getOptionsWithInputFilter(
        'costCenters',
        currentCostCenters,
        analysisUserCostCentersOptions
      ),
      expensesStatus: getOptionsWithInputFilter(
        'expensesStatus',
        currentExpensesStatus,
        analysisUserExpenseStatusOptions
      ),
      expensesTypes: getOptionsWithInputFilter(
        'expensesTypes',
        currentExpensesTypes,
        analysisUserExpenseTypesOptions
      ),
      reimbursableNonReimbursable: [
        {
          label: t('filters.reimbursableNonReimbursable'),
          value: String(
            AnalysisReimbursableNonReimbursableFilter.REIMBURSABLE_NON_REIMBURSABLE
          )
        },
        {
          label: t(
            'analytics:filters.options.reimbursableNonReimbursable.onlyReimbursable'
          ),
          value: String(
            AnalysisReimbursableNonReimbursableFilter.ONLY_REIMBURSABLE
          )
        },
        {
          label: t(
            'analytics:filters.options.reimbursableNonReimbursable.onlyNonReimbursable'
          ),
          value: String(
            AnalysisReimbursableNonReimbursableFilter.ONLY_NON_REIMBURSABLE
          )
        }
      ],
      paymentForms: getOptionsWithInputFilter(
        'paymentForms',
        currentPaymentForms,
        analysisUserPaymentFormsOptions,
        'female'
      ),
      projects: getOptionsWithInputFilter(
        'projects',
        currentProjects,
        analysisUserProjectsOptions
      ),
      users: customFilterTerms?.users
        ? [
            {
              label: t('analytics:filters.information.filterAllWith', {
                term: customFilterTerms?.users,
                context: 'male'
              }),
              value: `ALL_WITH_${customFilterTerms?.users}`
            },
            ...analysisUserFilterOptions
          ]
        : currentUsers?.some(optionValue =>
              optionValue.includes(AnalysisDefaultFilter.ALL_WITH)
            )
          ? [
              ...currentUsers
                .filter(optionValue =>
                  optionValue.includes(AnalysisDefaultFilter.ALL_WITH)
                )
                .map(optionValue => ({
                  label: t('analytics:filters.information.filterAllWith', {
                    term: optionValue.replace(
                      AnalysisDefaultFilter.ALL_WITH,
                      ''
                    ),
                    context: 'male'
                  }),
                  value: optionValue
                })),
              ...analysisUserFilterOptions
            ]
          : analysisUserFilterOptions,
      reportCodes: getOptionsWithInputFilter(
        'reportCodes',
        currentReportCodes,
        analysisUserReportsOptions
      )
    }),
    [
      t,
      currentUsers,
      currentProjects,
      currentCostCenters,
      currentReportCodes,
      currentPaymentForms,
      currentExpensesTypes,
      currentExpensesStatus,
      customFilterTerms?.users,
      getOptionsWithInputFilter,
      analysisUserFilterOptions,
      analysisUserReportsOptions,
      analysisUserProjectsOptions,
      analysisUserCostCentersOptions,
      analysisUserExpenseTypesOptions,
      analysisUserPaymentFormsOptions,
      analysisUserExpenseStatusOptions
    ]
  );

  const handleDefaultOptions = useCallback(
    (
      key: keyof IAnalysisUserFilterFieldsForm,
      options: string[] | undefined,
      defaultOptionsValues: string[]
    ): void => {
      const lastSelectedOption = [...(options ?? [])].pop() ?? '';

      const defaultOptions = [AnalysisDefaultFilter.ALL] as string[];

      const isDefaultOptionSelected =
        defaultOptions.includes(lastSelectedOption);

      if (isDefaultOptionSelected) {
        const newOptions: string[] = [
          ...defaultOptionsValues.filter(
            option => option !== lastSelectedOption
          ),
          lastSelectedOption
        ];

        setAnalysisUserOptionsToRender(prev => ({
          ...prev,
          [key]: [lastSelectedOption]
        }));

        methods.setValue(key, newOptions);

        return;
      }

      setAnalysisUserOptionsToRender(prev => ({
        ...prev,
        [key]: undefined
      }));

      methods.setValue(
        key,
        options?.filter(option => !defaultOptions.includes(option))
      );
    },
    [methods]
  );

  /** Lógica multiselect de usuários */
  useEffect(() => {
    const lastSelectedOption = [...(currentUsers ?? [])].pop() ?? '';

    const defaultOptions = [
      AnalysisDefaultFilter.ALL,
      AnalysisUserFilter.ACTIVE,
      AnalysisUserFilter.INACTIVE
    ] as string[];

    const isDefaultOptionSelected = defaultOptions.includes(lastSelectedOption);

    if (isDefaultOptionSelected) {
      let newOptions: string[] = [lastSelectedOption];

      switch (lastSelectedOption) {
        case AnalysisDefaultFilter.ALL:
          newOptions = [
            ...(analysisUserFilters?.users.map(user => String(user.id)) ?? []),
            ...newOptions
          ];
          break;
        case AnalysisUserFilter.ACTIVE:
          newOptions = [
            ...(analysisUserFilters?.users
              .filter(user => user.active)
              .map(user => String(user.id)) ?? []),
            ...newOptions
          ];
          break;
        case AnalysisUserFilter.INACTIVE:
          newOptions = [
            ...(analysisUserFilters?.users
              .filter(user => !user.active)
              .map(user => String(user.id)) ?? []),
            ...newOptions
          ];
          break;
      }

      setAnalysisUserOptionsToRender(prev => ({
        ...prev,
        users: [lastSelectedOption]
      }));

      methods.setValue('users', newOptions);

      return;
    }

    setAnalysisUserOptionsToRender(prev => ({
      ...prev,
      users: undefined
    }));

    methods.setValue(
      'users',
      currentUsers?.filter(option => !defaultOptions.includes(option))
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDefaultOptions, currentUsers?.length]);

  /** Lógica multiselect de status da despesa */
  useEffect(() => {
    handleDefaultOptions(
      'expensesStatus',
      currentExpensesStatus,
      analysisFilters.expensesStatus?.map(option => option.value)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDefaultOptions, currentExpensesStatus?.length]);

  /** Lógica multiselect de tipos de despesa */
  useEffect(() => {
    handleDefaultOptions(
      'expensesTypes',
      currentExpensesTypes,
      analysisFilters.expensesTypes?.map(option => option.value)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDefaultOptions, currentExpensesTypes?.length]);

  /** Lógica multiselect de formas de pagamento */
  useEffect(() => {
    handleDefaultOptions(
      'paymentForms',
      currentPaymentForms,
      analysisFilters.paymentForms?.map(option => option.value)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDefaultOptions, currentPaymentForms?.length]);

  /** Lógica multiselect de projetos */
  useEffect(() => {
    handleDefaultOptions(
      'projects',
      currentProjects,
      analysisFilters.projects?.map(option => option.value)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDefaultOptions, currentProjects?.length]);

  /** Lógica multiselect de centro de custos */
  useEffect(() => {
    handleDefaultOptions(
      'costCenters',
      currentCostCenters,
      analysisFilters.costCenters?.map(option => option.value)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDefaultOptions, currentCostCenters?.length]);

  function onApplyFiltersSubmit(data: FieldValues): void {
    handleChangeFilters(data);
    handleCloseModal();
  }

  function handleResetFilters(): void {
    methods.reset(defaultFilters);
  }

  function handleSelectInputChange(length: number): (value: string) => string {
    return (value: string) => {
      return Mask.crop(value, length);
    };
  }

  function handleCustomFilterChange(key: string): (searchTerm: string) => void {
    return (searchTerm: string) => {
      if (customFilterTerms === searchTerm) {
        return;
      }

      setCustomFilterTerms(prev => ({
        ...prev,
        [key]: searchTerm
      }));
    };
  }

  function handleDeselectOption(name: string | undefined, value: string): void {
    if (!name) return;

    try {
      const key = name as keyof IAnalysisUserFilterFieldsForm;

      const currentValue = methods.getValues(key) as string[];

      if (
        currentValue.length === 1 &&
        currentValue.includes(AnalysisDefaultFilter.ALL)
      ) {
        methods.setValue(key, []);
      }
    } catch {}
  }

  return {
    methods,
    currentEndDate,
    analysisFilters,
    customFilterTerms,
    currentInitialDate,
    handleResetFilters,
    handleDeselectOption,
    handleSelectInputChange,
    isApplyingFilters: false,
    handleCustomFilterChange,
    analysisUserOptionsToRender,
    handleApplyFilters: methods.handleSubmit(onApplyFiltersSubmit)
  };
}
