import {
  type IPersistenceValidateMileagePolicyByUserPayload,
  type IPersistenceValidateMileagePolicyByUserResponse,
  type IValidateMileagePolicyByUserPayload,
  type IValidateMileagePolicyByUserResponse
} from 'data/modules/expenses/types/expenses.types';

class ValidateMileagePolicyByUserMapper {
  toPersistence(
    domain: IValidateMileagePolicyByUserPayload
  ): IPersistenceValidateMileagePolicyByUserPayload {
    return {
      date: domain.date,
      kilometrage: domain.mileage,
      route_uuid: domain.expenseUuid
    };
  }

  toDomain(
    persistence: IPersistenceValidateMileagePolicyByUserResponse
  ): IValidateMileagePolicyByUserResponse {
    return {
      mileagePaidValue: persistence.amount_per_kilometer,
      observations: persistence.observations,
      value: persistence.amount
    };
  }
}

export default new ValidateMileagePolicyByUserMapper();
