import { CurrencyInput, DangerButton, DSIcons, SuccessButton } from 'ds';

import { useLangContext, useModalContext } from 'data/contexts';
import { useGetExpenseParameters } from 'data/modules/expenses';

import { SelectDynamicProjects } from 'presentation/pages/expenses/ExpensesList/components/SelectDynamicCases';

import { useApportionmentBlockItem } from './useApportionmentBlockItem';

import { type IApportionmentBlockItemProps } from './ApportionmentBlockItem.types';

import {
  Container,
  InputContainer,
  SelectContainer
} from './ApportionmentBlockItem.styles';

export function ApportionmentBlockItem({
  fieldArrayIndex,
  onClickActionMinusButton,
  onClickActionPlusButton,
  actionPlusButtonDisabled,
  expenseUuidToUpdate
}: IApportionmentBlockItemProps): JSX.Element {
  const { visibleModal } = useModalContext();

  const {
    expenseValueAsNumber,
    handleChangePercentage,
    handleChangeValue,
    handleChangeProject,
    projectIdsToNotShowAsOptions,
    checkIfDateIsValidByProjectPolicy,
    blockAddAndRemoveProjectActions,
    currencySymbol
  } = useApportionmentBlockItem({
    fieldArrayIndex,
    expenseUuidToUpdate
  });

  const { currentLangKey, lang } = useLangContext();

  const { expenseParameters } = useGetExpenseParameters();

  const projectFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.expenseProject !== null
      ? expenseParameters?.companyFields.expenseProject
      : lang.global.project[currentLangKey];

  // se estiver no Modal de despesa manual e a politica de despesa permitir valores negativos
  const allowNegativeValues =
    visibleModal === 'createManualExpense' &&
    expenseParameters?.allowExpenseWithNegativeValue;

  return (
    <Container>
      <SelectContainer>
        <SelectDynamicProjects
          name={`apportionment.${fieldArrayIndex}.project`}
          onChangeProject={handleChangeProject}
          projectIdsToNotShowAsOptions={projectIdsToNotShowAsOptions}
          customLabel={projectFormLabel}
        />

        <DangerButton
          size='small'
          type='button'
          onClick={() => {
            onClickActionMinusButton();
          }}
          disabled={blockAddAndRemoveProjectActions}
        >
          <DSIcons.ActionsMinusIcon />
        </DangerButton>

        <SuccessButton
          size='small'
          type='button'
          disabled={actionPlusButtonDisabled || blockAddAndRemoveProjectActions}
          onClick={() => {
            onClickActionPlusButton();
          }}
        >
          <DSIcons.ActionsPlusIcon />
        </SuccessButton>
      </SelectContainer>

      <InputContainer>
        <CurrencyInput.Controlled
          inputId={`apportionment-percentage-${fieldArrayIndex}`}
          name={`apportionment.${fieldArrayIndex}.percentage`}
          rightElement='%'
          variant='small'
          onCustomChange={handleChangePercentage}
          onBlur={checkIfDateIsValidByProjectPolicy}
        />

        <CurrencyInput.Controlled
          inputId={`apportionment-value-${fieldArrayIndex}`}
          name={`apportionment.${fieldArrayIndex}.value`}
          rightElement={currencySymbol}
          variant='small'
          allowNegative={allowNegativeValues}
          onCustomChange={handleChangeValue}
          onBlur={checkIfDateIsValidByProjectPolicy}
          disabled={!expenseValueAsNumber}
        />
      </InputContainer>
    </Container>
  );
}
