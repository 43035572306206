import {
  type ICardGroupUser,
  type IGetCardGroupUsersParams,
  type IPersistenceCardGroupUser,
  type IPersistenceGetCardGroupUsersParams
} from 'data/modules/cards/cardGroups';

class GetCardGroupUsersMapper {
  toPersistence(
    params: Omit<IGetCardGroupUsersParams, 'id'>
  ): Omit<IPersistenceGetCardGroupUsersParams, 'id'> {
    if (!params.name) {
      return {
        limit: params.limit,
        page: params.page,
        ordernation_type: params.ordernationType
      };
    }

    return {
      limit: params.limit,
      page: params.page,
      name: params.name,
      ordernation_type: params.ordernationType
    };
  }

  toDomain(response: IPersistenceCardGroupUser): ICardGroupUser {
    return {
      id: response.id,
      cardNumber: response.card_number,
      name: response.name,
      balanceId: response.balance_id,
      status: response.status,
      type: response.type,
      userId: response.user_id,
      cardStatus: response.card_status
    };
  }
}

export default new GetCardGroupUsersMapper();
