import { faker } from '@faker-js/faker';

import { type IGetUsersWithoutCardGroup } from 'data/modules/cards/cardGroups';

import { MockApi } from 'shared/utils/global';

export async function getUsersWithoutCardGroupMock(): Promise<IGetUsersWithoutCardGroup> {
  const data = [
    {
      id: faker.string.uuid(),
      name: faker.person.fullName()
    },
    {
      id: faker.string.uuid(),
      name: faker.person.fullName()
    },
    {
      id: faker.string.uuid(),
      name: faker.person.fullName()
    },
    {
      id: faker.string.uuid(),
      name: faker.person.fullName()
    },
    {
      id: faker.string.uuid(),
      name: faker.person.fullName()
    }
  ];

  await MockApi.sleep(2000);

  return {
    data,
    total: data.length
  };
}
