import styled from 'styled-components';

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 0 1.6rem;
`;

export const SummaryModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  padding: 0 1.8rem;
  margin-bottom: 1.6rem;

  span {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.emperor};
  }
`;

export const Row = styled.div`
  width: 100%;
`;

export const MultipleRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;
