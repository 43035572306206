import {
  type ILegalRepresentatives,
  type IPersistenceLegalRepresentatives
} from 'data/modules/cards/companyOnboarding';

class GetLegalRepresentativeToApprovalMapper {
  toDomain(
    persistenceLegalRepresentativeToApproval: IPersistenceLegalRepresentatives
  ): ILegalRepresentatives {
    return {
      id: persistenceLegalRepresentativeToApproval.id,
      name: persistenceLegalRepresentativeToApproval.name,
      email: persistenceLegalRepresentativeToApproval.email,
      emailSentAt: persistenceLegalRepresentativeToApproval.email_sent_at,
      isAuthorized: persistenceLegalRepresentativeToApproval.is_authorized,
      onboardingCompanyId:
        persistenceLegalRepresentativeToApproval.onboarding_company_id
    };
  }
}

export default new GetLegalRepresentativeToApprovalMapper();
