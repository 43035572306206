import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 4.8rem 1.5rem 0;
  }

  > h1 {
    text-align: left;
  }
`;

export const CustomForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;
