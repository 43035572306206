import { useLangContext } from 'data/contexts';

import { DsDefaultButton, DsGhostButton } from 'presentation/ds/DsButton';
import { DsModal } from 'presentation/ds/DsModal';
import {
  OnboardingText,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { type IAddMemberModalProps } from './AddMemberModal.types';

import {
  Container,
  DescriptionContainer,
  Divider,
  FooterContainer
} from './AddMemberModal.styles';

export function AddMemberModal({
  isOpen,
  onClose,
  onAddMemberRedirect,
  onUpdateMembersList
}: IAddMemberModalProps): JSX.Element {
  const [addMemberModal, global, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.company.managers_and_cardholders
      .managers_and_cardholders_list.add_member_modal,
    state.lang.global,
    state.currentLangKey
  ]);

  return (
    <DsModal
      title=''
      isOpen={isOpen}
      onClose={onClose}
      hasCloseButton={false}
    >
      <Container>
        <OnboardingTitle>
          {addMemberModal.title[currentLangKey]}
        </OnboardingTitle>

        <DescriptionContainer>
          <OnboardingText>
            {addMemberModal.description_a[currentLangKey]}
          </OnboardingText>
          <OnboardingText>
            {addMemberModal.description_b[currentLangKey]}
          </OnboardingText>
        </DescriptionContainer>

        <Divider />

        <FooterContainer>
          <DsGhostButton
            text={global.update[currentLangKey]}
            onClick={onUpdateMembersList}
          />
          <DsDefaultButton
            text={global.continue[currentLangKey]}
            onClick={onAddMemberRedirect}
          />
        </FooterContainer>
      </Container>
    </DsModal>
  );
}
