import { Outlet } from 'react-router-dom';

import { useAuthContext, useLangContext } from 'data/contexts';

import { DsGhostButton } from 'presentation/ds/DsButton';
import { DefaultLayout } from 'presentation/templates';

import circularDetail from 'shared/assets/images/cards/textura-circular.png';
import rectangularDetail from 'shared/assets/images/cards/textura-retangular.png';
import logo from 'shared/assets/images/global/logotipo-horizontal-branco.svg';

import { OnboardingStep } from './components/General';
import { useOnboarding } from './useOnboarding';

import { type IOnboardingProps } from './Onboarding.types';

import {
  CircularDetail,
  Container,
  RetangularDetail
} from './Onboarding.styles';

export function Onboarding({
  isPublic = false,
  onboardingType = 'COMPANY'
}: IOnboardingProps): JSX.Element {
  const {
    onboardingProgressPercentage,
    onboardingProgress,
    isLoadingProgress,
    currentStep,
    showPercentageBar
  } = useOnboarding({ onboardingType, isPublic });

  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const user = useAuthContext(state => state.user);

  const content = (
    <Container $isLoggedIn={Boolean(user)}>
      <div className='circle'></div>

      <img
        className='logo'
        src={logo}
        alt='VExpenses'
      />

      <OnboardingStep
        currentStep={currentStep}
        percentage={
          showPercentageBar
            ? onboardingProgress && onboardingProgressPercentage
            : undefined
        }
        isLoading={isLoadingProgress}
      >
        <Outlet />
      </OnboardingStep>

      {!user && (
        <DsGhostButton
          text={
            lang.cards.onboarding.container.back_to_vexpenses[currentLangKey]
          }
          variant='pill'
          iconProps={{
            name: 'arrow-go-back',
            size: 1.6
          }}
          onClick={() => window.open('/', '_self')}
          className='back-btn'
        />
      )}

      <CircularDetail src={circularDetail} />
      <RetangularDetail src={rectangularDetail} />
    </Container>
  );

  if (user) {
    return <DefaultLayout>{content}</DefaultLayout>;
  }

  return content;
}
