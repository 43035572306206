import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type ILegalNatureOption } from 'data/modules/auth';
import { type LegalEntityPartnerDataSchemaFieldsType } from 'data/modules/cards/companyOnboarding';
import { type ICnae } from 'data/modules/global';

import { Validate } from 'shared/utils/validation';

export function useHandleLegalEntityPartnerDataSchema(): ObjectSchema<LegalEntityPartnerDataSchemaFieldsType> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const legalEntityPartnerDataSchema: ObjectSchema<LegalEntityPartnerDataSchemaFieldsType> =
    useMemo(() => {
      const requiredErrorMessage = lang.schemas.is_required[currentLangKey];

      return yup.object().shape({
        document: yup.string().required(requiredErrorMessage).test({
          name: 'document',
          test: Validate.cnpj,
          message: lang.schemas.invalid_CNPJ[currentLangKey]
        }),
        legalName: yup.string().required(requiredErrorMessage),
        mainActivity: yup
          .mixed<ICnae | string>()
          .required(lang.schemas.is_required[currentLangKey])
          .transform(({ value }) => value),
        businessType: yup
          .mixed<ILegalNatureOption | string>()
          .required(lang.schemas.is_required[currentLangKey])
          .transform(({ value }) => value),
        foundingDate: yup
          .string()
          .required(requiredErrorMessage)
          .test({
            name: 'foundingDate',
            message: lang.schemas.invalid_date[currentLangKey],
            test: (enteredValue: string) => Validate.isDate(enteredValue)
          })
          .test({
            name: 'foundingDate',
            message: lang.schemas.date_must_not_be_future[currentLangKey],
            test: (value: string) => !Validate.isFutureDate(value)
          }),
        email: yup
          .string()
          .required(requiredErrorMessage)
          .email(lang.schemas.invalid_email[currentLangKey]),
        phoneNumber: yup.string().required(requiredErrorMessage).min(15),
        contract: yup
          .mixed<FileList | string>()
          .test({
            name: 'contractSize',
            message: lang.schemas.file_must_be_less_than_16mb[currentLangKey],
            test: value => {
              if (value instanceof FileList) {
                return value[0]?.size <= 16000000;
              }

              return true;
            }
          })
          .test({
            name: 'contractFileType',
            message: lang.schemas.file_must_be_pdf_type[currentLangKey],
            test: value => {
              if (value instanceof FileList) {
                return value && value[0].type === 'application/pdf';
              }

              return true;
            }
          })
      });
    }, [currentLangKey, lang]);

  return legalEntityPartnerDataSchema;
}
