import { Tooltip } from 'ds';

import { useLangContext } from 'data/contexts';

import { Icons } from 'shared/constants/global';

import { type IServiceChannelProps } from './ServiceChannel.types';

import {
  ChannelDescription,
  ChannelInformation,
  ChannelName,
  Container,
  Content,
  Wrapper
} from './ServiceChannel.styles';

export function ServiceChannel({
  icon,
  name,
  channel,
  onClick,
  information,
  contentToCopy,
  onCopyButtonClick,
  withCopyButton = false,
  ...props
}: IServiceChannelProps): JSX.Element {
  const {
    currentLangKey,
    lang: { global }
  } = useLangContext();

  return (
    <Container
      {...props}
      onClick={onClick}
      $clickable={!!onClick}
    >
      {icon}
      <Content>
        <ChannelName>{name}</ChannelName>
        <Wrapper>
          <ChannelDescription>{channel}</ChannelDescription>
          {withCopyButton && (
            <Tooltip
              message={`${name} ${global.copied[currentLangKey]}!`}
              trigger='click'
              placement='top'
            >
              <button
                type='button'
                onClick={() => {
                  if (contentToCopy) {
                    onCopyButtonClick?.(contentToCopy);
                  }
                }}
              >
                <Icons.CopyToClipboardIcon />
              </button>
            </Tooltip>
          )}
        </Wrapper>
        <ChannelInformation>{information}</ChannelInformation>
      </Content>
    </Container>
  );
}
