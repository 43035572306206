import { DefaultButton, SkeletonLoader, TextInput } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import {
  useAuthContext,
  useCardGroupsContext,
  useModalContext
} from 'data/contexts';

import { Spinner } from 'presentation/components/base/Spinner';

import { CardNumber } from 'shared/utils/format';

import { useUsersAndManagersList } from './useUsersAndManagersList';

import { type IUsersAndManagersListProps } from './UsersAndManagersList.types';

import {
  Container,
  CreditCardIcon,
  List,
  ListItem,
  ListItemActions,
  ListItemInfo,
  ListItemLoading,
  PlusIcon,
  Title,
  TrashIcon
} from './UsersAndManagersList.styles';

export function UsersAndManagersList({
  listContent
}: IUsersAndManagersListProps): JSX.Element {
  const { t } = useTranslation(['global', 'cards']);

  const user = useAuthContext(state => state.user);

  const [
    groupCardsSearchTerm,
    handleSearchCardInGroup,
    groupManagerSearchTerm,
    handleSearchManagerInGroup,
    handleSetManagerBeingExcluded,
    cardBeingExcluded,
    handleSetCardBeingExcluded
  ] = useCardGroupsContext(
    useShallow(state => [
      state.groupCardsSearchTerm,
      state.handleSearchCardInGroup,
      state.groupManagerSearchTerm,
      state.handleSearchManagerInGroup,
      state.handleSetManagerBeingExcluded,
      state.cardBeingExcluded,
      state.handleSetCardBeingExcluded,
      state.paginationModalDetails
    ])
  );

  const {
    cardGroupManagers,
    cardGroupUsers,
    hasNoManagers,
    hasNoUsers,
    isFetchingCardGroupManagers,
    isFetchingCardGroupUsers,
    isCheckingIfCardCanBeRemoved,
    infiniteScrollManagersRef,
    rootManagersRef,
    isFetchingManagersNextPage,
    infiniteScrollUsersRef,
    rootUsersRef,
    handleCheckIfCardCanBeRemoved,
    isFetchingUsersNextPage
  } = useUsersAndManagersList();

  const { handleOpenModal } = useModalContext();

  return (
    <Container>
      <Title>
        {listContent === 'USERS'
          ? t('cards:groupCards')
          : t('cards:groupManagers')}

        {user?.cards?.type === 'ACCOUNT_MANAGER' && (
          <DefaultButton
            size='small'
            onClick={() => {
              listContent === 'MANAGERS'
                ? handleOpenModal('addManagerToGroup')
                : handleOpenModal('addCardToGroup');
            }}
          >
            {listContent === 'USERS' ? (
              <span>
                <CreditCardIcon />

                {t('cards:addCardInGroup')}
              </span>
            ) : (
              <span>
                <PlusIcon />

                {t('cards:addManagerInGroup')}
              </span>
            )}
          </DefaultButton>
        )}
      </Title>

      <TextInput.Default
        small
        label={
          listContent === 'USERS'
            ? t('cards:searchCard')
            : t('cards:searchManagers')
        }
        placeholder={
          listContent === 'USERS'
            ? t('cards:searchCard')
            : t('cards:searchManagers')
        }
        value={
          listContent === 'USERS'
            ? groupCardsSearchTerm
            : groupManagerSearchTerm
        }
        iconLeft='SearchIcon'
        onChangeValue={value => {
          listContent === 'USERS'
            ? handleSearchCardInGroup(value)
            : handleSearchManagerInGroup(value);
        }}
        onClearValue={() => {
          listContent === 'USERS'
            ? handleSearchCardInGroup('')
            : handleSearchManagerInGroup('');
        }}
      />

      {listContent === 'MANAGERS' && (
        <List
          $isEmpty={hasNoManagers}
          $isUnique={cardGroupManagers?.length === 1}
          ref={rootManagersRef}
        >
          {hasNoManagers &&
            !groupManagerSearchTerm &&
            !isFetchingCardGroupManagers && (
              <ListItem
                $isEmpty
                $isUnique={false}
              >
                <ListItemInfo>
                  <h3>{t('cards:noManagersInGroup')}</h3>
                </ListItemInfo>
              </ListItem>
            )}

          {hasNoManagers &&
            groupManagerSearchTerm &&
            !isFetchingCardGroupManagers && (
              <ListItem
                $isEmpty
                $isUnique={false}
              >
                <ListItemInfo>
                  <h3>{t('cards:managersSearchNotFound')}</h3>
                </ListItemInfo>
              </ListItem>
            )}

          {isFetchingCardGroupManagers && (
            <>
              <ListItem
                $isEmpty={false}
                $isUnique={false}
              >
                <SkeletonLoader />
              </ListItem>

              <ListItem
                $isEmpty={false}
                $isUnique={false}
              >
                <SkeletonLoader />
              </ListItem>

              <ListItem
                $isEmpty={false}
                $isUnique={false}
              >
                <SkeletonLoader />
              </ListItem>
            </>
          )}

          {!isFetchingCardGroupManagers &&
            cardGroupManagers?.map((item, index) => (
              <ListItem
                $isEmpty={false}
                key={`${index}${item.id}`}
                $isUnique={cardGroupManagers.length === 1}
              >
                <ListItemInfo>
                  <h3>{item.name}</h3>
                </ListItemInfo>

                <ListItemActions>
                  {user?.cards?.type === 'ACCOUNT_MANAGER' && (
                    <TrashIcon
                      onClick={() => {
                        handleSetManagerBeingExcluded(item);
                        handleOpenModal('removeManagerFromGroup');
                      }}
                    />
                  )}
                </ListItemActions>
              </ListItem>
            ))}

          {isFetchingManagersNextPage && (
            <ListItemLoading>{t('global:loadingMoreItems')}...</ListItemLoading>
          )}

          <div ref={infiniteScrollManagersRef} />
        </List>
      )}

      {listContent === 'USERS' && (
        <List
          $isEmpty={hasNoUsers}
          $isUnique={cardGroupUsers?.length === 1}
          ref={rootUsersRef}
        >
          {hasNoUsers && !groupCardsSearchTerm && !isFetchingCardGroupUsers && (
            <ListItem
              $isEmpty
              $isUnique={false}
            >
              <ListItemInfo>
                <h3>{t('cards:noCardsInGroup')}</h3>
              </ListItemInfo>
            </ListItem>
          )}

          {hasNoUsers && groupCardsSearchTerm && !isFetchingCardGroupUsers && (
            <ListItem
              $isEmpty
              $isUnique={false}
            >
              <ListItemInfo>
                <h3>{t('cards:cardsSearchNotFound')}</h3>
              </ListItemInfo>
            </ListItem>
          )}

          {isFetchingCardGroupUsers && (
            <>
              <ListItem
                $isEmpty={false}
                $isUnique={false}
              >
                <SkeletonLoader />
              </ListItem>

              <ListItem
                $isEmpty={false}
                $isUnique={false}
              >
                <SkeletonLoader />
              </ListItem>

              <ListItem
                $isEmpty={false}
                $isUnique={false}
              >
                <SkeletonLoader />
              </ListItem>
            </>
          )}

          {!isFetchingCardGroupUsers &&
            cardGroupUsers?.map((item, index) => (
              <ListItem
                $isEmpty={false}
                $isUnique={cardGroupUsers.length === 1}
                key={`${index}${item.id}`}
              >
                <ListItemInfo>
                  <h3>{item.name}</h3>

                  {item.cardNumber && (
                    <span>{CardNumber.format(item.cardNumber)}</span>
                  )}
                </ListItemInfo>

                <ListItemActions>
                  {isCheckingIfCardCanBeRemoved &&
                    cardBeingExcluded?.userId === item.userId && (
                      <Spinner size={1.5} />
                    )}

                  {user?.cards?.type === 'ACCOUNT_MANAGER' && (
                    <TrashIcon
                      onClick={() => {
                        if (!isCheckingIfCardCanBeRemoved) {
                          handleSetCardBeingExcluded(item);
                          handleCheckIfCardCanBeRemoved();
                        }
                      }}
                    />
                  )}
                </ListItemActions>
              </ListItem>
            ))}

          {isFetchingUsersNextPage && (
            <ListItemLoading>{t('global:loadingMoreItems')}...</ListItemLoading>
          )}

          <div ref={infiniteScrollUsersRef} />
        </List>
      )}
    </Container>
  );
}
