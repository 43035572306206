import { type ISettingsSidebarItemProps } from 'presentation/components/base/SettingsSidebar/SettingsSidebarItem/SettingsSidebarItem.types';

import { type IUseSettingsSidebarGroup } from './SettingsSidebarGroup.types';

export default function useSidebarGroup(): IUseSettingsSidebarGroup {
  /**
   * Retorna a quantidade de itens e subitens incluindo o item pai
   */
  function countSubItems(subItems?: ISettingsSidebarItemProps[]): number {
    const totalItems = subItems?.reduce<number>(
      (total, subItem) => total + countSubItems(subItem.subItems),
      1
    );

    return totalItems ?? 1;
  }

  return {
    countSubItems
  };
}
