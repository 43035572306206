import { DefaultTable, Pagination } from 'ds';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useAnalysisUserFiltersContext, useLangContext } from 'data/contexts';

import { InformationRow } from 'presentation/pages/analytics/Analysis/components/InformationRow';
import { UserAnalysisSummarySkeleton } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisSummarySkeleton';
import { UserAnalysisSummaryTableRow } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisSummaryTableRow';

import { analysisPagesPaths } from 'shared/constants/analytics';

import { type IUserAnalysisSummaryTableProps } from './UserAnalysisSummaryTable.tyles';

import { Container, TableWrapper } from './UserAnalysisSummaryTable.styles';

export function UserAnalysisSummaryTable({
  isWithPagination,
  showInformationRow,
  userAnalysisSummary,
  isLoadingAnalysisUserSummary
}: IUserAnalysisSummaryTableProps): JSX.Element {
  const { t } = useTranslation(['global', 'analytics']);
  const navigate = useNavigate();

  const { currentLangKey } = useLangContext();

  const [perPage, totalPages, currentPage, handleChangePagination] =
    useAnalysisUserFiltersContext(
      useShallow(state => [
        state.perPage,
        state.totalPages,
        state.currentPage,
        state.handleChangePagination
      ])
    );

  return (
    <Container>
      <TableWrapper>
        <DefaultTable.Container>
          <DefaultTable.Thead>
            <DefaultTable.Tr>
              <DefaultTable.Th>{t('user', { count: 1 })}</DefaultTable.Th>

              <DefaultTable.Th>{t('report', { count: 2 })}</DefaultTable.Th>

              <DefaultTable.Th>{t('expenses', { count: 2 })}</DefaultTable.Th>

              <DefaultTable.Th>
                {t('analytics:userSummaryCard.averagePerExpense')}
              </DefaultTable.Th>

              <DefaultTable.Th>{t('nonReimbursable')}</DefaultTable.Th>

              <DefaultTable.Th>{t('reimbursable')}</DefaultTable.Th>

              <DefaultTable.Th>Total</DefaultTable.Th>
            </DefaultTable.Tr>
          </DefaultTable.Thead>

          <DefaultTable.Tbody>
            {isLoadingAnalysisUserSummary ? (
              <UserAnalysisSummarySkeleton
                rows={isWithPagination ? perPage : 5}
              />
            ) : (
              userAnalysisSummary.map(row => (
                <UserAnalysisSummaryTableRow
                  key={row.name}
                  {...row}
                />
              ))
            )}
          </DefaultTable.Tbody>
        </DefaultTable.Container>
      </TableWrapper>

      {showInformationRow && (
        <InformationRow
          label={t('analytics:userSummaryCard.aboveAreTop5')}
          buttonLabel={t('analytics:userSummaryCard.viewAllUsers')}
          onActionClick={() => {
            navigate(analysisPagesPaths.resume);
          }}
        />
      )}

      {isWithPagination && (
        <Pagination.Container
          currentPage={currentPage}
          totalPage={totalPages}
          perPage={perPage}
        >
          <Pagination.Label langKey={currentLangKey} />
          <Pagination.Control
            onChangePage={currentPage => {
              handleChangePagination({ currentPage });
            }}
          />
          <Pagination.Pages
            onChangePerPage={perPage => {
              handleChangePagination({ perPage, currentPage: 1 });
            }}
            langKey={currentLangKey}
            options={[10, 20, 50]}
          />
        </Pagination.Container>
      )}
    </Container>
  );
}
