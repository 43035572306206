import { type MaskType } from 'ds/types';

class Mask {
  apply(mask?: MaskType, value?: string | null): string {
    if (!value || value === null) {
      return '';
    }

    if (mask === 'negative-currency') {
      const hasNegative = value[0] === '-';

      value = hasNegative ? value.slice(1) : value;

      if (value.length > 14) {
        return (
          (hasNegative ? '-' : '') +
          value
            .slice(0, -1)
            .replace(/\D/g, '')
            .replace(/(\d)(\d{2})$/, '$1,$2')
            .replace(/(?=(\d{3})+(\D))\B/g, '.')
        );
      }

      return (
        (hasNegative ? '-' : '') +
        value
          .toString()
          .replace(/\D/g, '')
          .replace(/(\d)(\d{2})$/, '$1,$2')
          .replace(/(?=(\d{3})+(\D))\B/g, '.')
      );
    }

    switch (mask) {
      case 'phone':
        if (value.length > 16) {
          return value
            .slice(0, -1)
            .replace(/\D/g, '')
            .replace(/^(\d{2})\B/, '($1) ')
            .replace(/(\d{1})?(\d{4})(\d{4})/, '$1 $2-$3');
        }

        return value
          .toString()
          .replace(/\D/g, '')
          .replace(/^(\d{2})\B/, '($1) ')
          .replace(/(\d{1})?(\d{4})(\d{4})/, '$1 $2-$3');

      case 'cpf':
        if (value.length > 14) {
          return value
            .slice(0, -1)
            .replace(/\D/g, '')
            .replace(/^(\d{3})\B/, '$1.')
            .replace(/(\d{3})\B/, '$1.')
            .replace(/(\d{3})\B/, '$1-')
            .replace(/(\d{2})\B/, '$1');
        }

        return value
          .toString()
          .replace(/\D/g, '')
          .replace(/^(\d{3})\B/, '$1.')
          .replace(/(\d{3})\B/, '$1.')
          .replace(/(\d{3})\B/, '$1-')
          .replace(/(\d{2})\B/, '$1');

      case 'cep':
        if (value.length > 9) {
          return value
            .slice(0, -1)
            .replace(/\D/g, '')
            .replace(/^(\d{5})\B/, '$1-')
            .replace(/(\d{3})\B/, '$1');
        }

        return value
          .toString()
          .replace(/\D/g, '')
          .replace(/^(\d{5})\B/, '$1-')
          .replace(/(\d{3})\B/, '$1');

      case 'cnpj':
        if (value.length > 18) {
          return value
            .slice(0, -1)
            .replace(/\D/g, '')
            .replace(/^(\d{2})\B/, '$1.')
            .replace(/(\d{3})\B/, '$1.')
            .replace(/(\d{3})\B/, '$1/')
            .replace(/(\d{4})\B/, '$1-')
            .replace(/(\d{2})\B/, '$1');
        }

        return value
          .toString()
          .replace(/\D/g, '')
          .replace(/^(\d{2})\B/, '$1.')
          .replace(/(\d{3})\B/, '$1.')
          .replace(/(\d{3})\B/, '$1/')
          .replace(/(\d{4})\B/, '$1-')
          .replace(/(\d{2})\B/, '$1');

      case 'date':
        if (value.length > 10) {
          return value
            .slice(0, -1)
            .replace(/\D/g, '')
            .replace(/^(\d{2})\B/, '$1/')
            .replace(/(\d{2})\B/, '$1/')
            .replace(/(\d{4})\B/, '$1');
        }

        return value
          .toString()
          .replace(/\D/g, '')
          .replace(/^(\d{2})\B/, '$1/')
          .replace(/(\d{2})\B/, '$1/')
          .replace(/(\d{4})\B/, '$1');

      case 'currency':
        if (value.length > 14) {
          return value
            .slice(0, -1)
            .replace(/\D/g, '')
            .replace(/(\d)(\d{2})$/, '$1,$2')
            .replace(/(?=(\d{3})+(\D))\B/g, '.');
        }

        return value
          .toString()
          .replace(/\D/g, '')
          .replace(/(\d)(\d{2})$/, '$1,$2')
          .replace(/(?=(\d{3})+(\D))\B/g, '.');

      case 'five-decimal-currency':
        if (value.length > 14) {
          return value
            .slice(0, -1)
            .replace(/\D/g, '')
            .replace(/(\d)(\d{5})$/, '$1,$2')
            .replace(/(?=(\d{3})+(\D))\B/g, '.');
        }

        return value
          .toString()
          .replace(/\D/g, '')
          .replace(/(\d)(\d{5})$/, '$1,$2')
          .replace(/(?=(\d{3})+(\D))\B/g, '.');

      case 'four-decimal-currency':
        if (value.length > 14) {
          return value
            .slice(0, -1)
            .replace(/\D/g, '')
            .replace(/(\d)(\d{4})$/, '$1,$2')
            .replace(/(?=(\d{3})+(\D))\B/g, '.');
        }

        return value
          .toString()
          .replace(/\D/g, '')
          .replace(/(\d)(\d{4})$/, '$1,$2')
          .replace(/(?=(\d{3})+(\D))\B/g, '.');

      case 'integer':
        return value
          .toString()
          .replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      case 'numeric':
        return value.toString().replace(/\D/g, '');

      case 'letters':
        return value
          .toString()
          .replace(/[;,!@#&$"%*"(){}+=^_/[\]'^~\\/|<>?^.:|0-9]/, '');

      case 'alphanumeric':
        return value.toString().replace(/[^a-zA-Z0-9 ]/g, '');

      case 'cpf-cnpj':
        if (value.replace(/\D/g, '').length > 11) {
          return this.apply('cnpj', value);
        }

        return this.apply('cpf', value);

      default:
        return value;
    }
  }

  remove(value: string): string {
    if (!value) return '';

    return value.toString().replace(/\D/g, '');
  }
}

export default new Mask();
