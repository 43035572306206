import { tokens } from 'ds';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  padding: 2.5rem 1.5rem;

  padding-bottom: 0;
  overflow: hidden !important;

  > div:first-child {
    margin-bottom: -1.8rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: ${tokens.spacingInsetLg};
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighLight};
  border-radius: ${tokens.borderRadiusMd};

  table {
    min-width: 126rem;
  }

  .justify-tooltip > span {
    white-space: pre-line;
    overflow-wrap: break-word;
    max-width: 100%;
  }
`;
