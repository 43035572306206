import { Children } from 'react';

import { type IAnalysisItemContainerProps } from './AnalysisItemContainer.types';

import { Container, Content } from './AnalysisItemContainer.styles';

export function AnalysisItemContainer({
  children,
  ...props
}: IAnalysisItemContainerProps): JSX.Element {
  const SectionName = Children.toArray(children)[0];
  const ContentItems = Children.toArray(children).slice(1);

  return (
    <Container {...props}>
      {SectionName}
      <Content>{ContentItems}</Content>
    </Container>
  );
}
