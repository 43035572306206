import { CardInfo } from 'ds';
import { useTranslation } from 'react-i18next';

import { Currency } from 'shared/utils/format';

import { useExpensesCards } from './useExpensesCards';

import { CardInfoContainer, Container } from './ExpensesCards.styles';

export function ExpensesCards(): JSX.Element {
  const { t } = useTranslation('cards');

  const { getLabelByStatus, userExpensesDetachedList, isLoadingExpenses } =
    useExpensesCards();

  return (
    <Container>
      {userExpensesDetachedList?.map(expense => (
        <CardInfoContainer
          key={expense.status}
          tooltipMessage={t('explanationOfExpensesAmounts')}
        >
          <CardInfo.Title variant='small'>
            {getLabelByStatus(expense.status)}
          </CardInfo.Title>

          <CardInfo.ValueContainer>
            <CardInfo.Value
              variant='small'
              isLoading={isLoadingExpenses}
            >
              {Currency.format('BRL', Math.abs(expense.value ?? 0), true)}
            </CardInfo.Value>
          </CardInfo.ValueContainer>

          <CardInfo.Caption
            variant='small'
            isLoading={isLoadingExpenses}
          >
            {t('totalExpenses', { total: expense.total })}
          </CardInfo.Caption>
        </CardInfoContainer>
      ))}
    </Container>
  );
}
