import { useModalContext } from 'data/contexts';

import { AppliedFilters } from 'presentation/pages/analytics/Analysis/components/AppliedFilters';
import { SideAnalysisFilterModal } from 'presentation/pages/analytics/Analysis/components/SideAnalysisFilterModal';

import { useUserAnalysisAppliedFilters } from './useUserAnalysisAppliedFilters';

export function UserAnalysisAppliedFilters(): JSX.Element {
  const { visibleModal } = useModalContext();

  const { appliedFilters, handleEditFilters } = useUserAnalysisAppliedFilters();

  return (
    <>
      <AppliedFilters.Container
        filtersCount={appliedFilters.length}
        onEditFiltersClick={handleEditFilters}
      >
        {appliedFilters.map(({ label, value }) => (
          <AppliedFilters.Item
            key={`${label}-${value}`}
            label={label || value}
          >
            {label ? value : null}
          </AppliedFilters.Item>
        ))}
      </AppliedFilters.Container>
      {visibleModal === 'analysisFilter' && (
        <SideAnalysisFilterModal isOpen={visibleModal === 'analysisFilter'} />
      )}
    </>
  );
}
