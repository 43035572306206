import { type IStepGraphProps } from './StepGraph.types';

import { Container, StepIndicator } from './StepGraph.styles';

export function StepGraph({
  currentStep,
  totalSteps,
  ...rest
}: IStepGraphProps): JSX.Element {
  const stepsArray = Array.from(Array(totalSteps).keys());

  return (
    <Container {...rest}>
      {stepsArray.map(step => (
        <StepIndicator
          key={step}
          $isCurrentStep={currentStep === step}
        />
      ))}
    </Container>
  );
}
