import { SettingSidebar } from 'presentation/components/base/SettingsSidebar';

import useSettingsSidebar from './useSettingsSidebar';

export function SettingsSidebar(): JSX.Element {
  const { allowedSettingsSidebarRoutes } = useSettingsSidebar();

  return (
    <SettingSidebar.Container>
      {allowedSettingsSidebarRoutes.map(item =>
        item.url ? (
          <SettingSidebar.Item
            key={item.title}
            {...item}
          />
        ) : (
          <SettingSidebar.Group
            key={item.title}
            {...item}
          />
        )
      )}
    </SettingSidebar.Container>
  );
}
