import { useMemo } from 'react';

import { useLangContext } from 'data/contexts';
import { useGetExpense } from 'data/modules/expenses';

import { Alert } from 'presentation/components/base/Alert';
import { type IRectButtonProps } from 'presentation/components/base/Button/RectButton/RectButton.types';
import { ExpenseStatusAlert } from 'presentation/components/global/Modal/TransactionDetails';

import { Currency } from 'shared/utils/format';

import {
  type IUseTransactionDetailsModal,
  type UseTransactionDetailsModalParamsType
} from './TransactionDetailsModal.types';

export function useTransactionDetailsModal({
  transaction
}: UseTransactionDetailsModalParamsType): IUseTransactionDetailsModal {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const expenseQueryEnabled =
    transaction !== null &&
    transaction.debitCredit === 'DEBIT' &&
    transaction.type !== 'TRANSFER_DEBIT';

  const { expense, isLoadingExpense } = useGetExpense({
    enabled: expenseQueryEnabled,
    transactionId:
      expenseQueryEnabled && transaction !== null
        ? transaction.transactionId
        : 'disabled'
  });

  const expenseExceedsTransactionValue = transaction !== null &&
    !transaction.isInternational &&
    transaction.finalBillingAmount !== 0 &&
    transaction.finalBillingAmount !== transaction.amount &&
    expense &&
    Math.abs(expense.value) > Math.abs(transaction.finalBillingAmount) && (
      <Alert backgroundColor='tango'>
        {
          lang.cards.statement.transaction_details_modal.expense_exceeding[
            currentLangKey
          ]
        }
      </Alert>
    );

  const modalExtraButtons = useMemo(() => {
    if (transaction === null) return [];
    if (!expense) return [];

    const buttons: IRectButtonProps[] = [];

    if (expense.report?.url) {
      buttons.push({
        color: 'primary',
        children:
          lang.cards.statement.transaction_details_modal.view_report[
            currentLangKey
          ],
        size: 'xl',
        iconProps: {
          name: 'eye',
          size: 1.6,
          type: 'line'
        },
        linkProps: {
          to: expense.report.url,
          target: '_blank'
        }
      });
    }

    buttons.push({
      color: 'greenHaze',
      children:
        lang.cards.statement.transaction_details_modal.view_expense[
          currentLangKey
        ],
      size: 'xl',
      iconProps: {
        name: 'eye',
        size: 1.6,
        type: 'line'
      },
      linkProps: {
        to: expense.url,
        target: '_blank'
      }
    });

    return buttons;
  }, [lang, transaction, currentLangKey, expense]);

  const differenceBetweenTransactionAndExpense = useMemo(() => {
    if (transaction === null) return '';
    if (!expense) return '';

    if (transaction.isInternational) {
      const differenceResult = Currency.format(
        transaction.currencyCode,
        Math.abs(Math.abs(transaction.amount) - expense.value)
      );

      return differenceResult;
    }

    const differenceResult = Currency.format(
      transaction.finalBillingCurrencyCode,
      Math.abs(Math.abs(transaction.finalBillingAmount) - expense.value)
    );

    return differenceResult;
  }, [transaction, expense]);

  function getShowExpensesStatusAlert(): JSX.Element | undefined {
    if (
      transaction !== null &&
      !transaction.reversed &&
      expense &&
      (expense.report?.status === 'SENT' ||
        expense.report?.status === 'REOPENED' ||
        expense.report?.status === 'OPEN') &&
      expense.value >
        Math.abs(transaction.finalBillingAmount + transaction.reversalAmount)
    ) {
      return <ExpenseStatusAlert status={expense.report.status} />;
    }

    if (
      transaction !== null &&
      !transaction.reversed &&
      expense &&
      expense.report === null &&
      expense.value >
        Math.abs(transaction.finalBillingAmount + transaction.reversalAmount)
    ) {
      return <ExpenseStatusAlert status='UNREPORTED' />;
    }
  }

  return {
    expenseExceedsTransactionValue,
    modalExtraButtons,
    differenceBetweenTransactionAndExpense,
    showExpenseStatusAlert: getShowExpensesStatusAlert(),
    expense,
    isLoadingExpense: expenseQueryEnabled && isLoadingExpense
  };
}
