import { useLangContext } from 'data/contexts';

import { ReceiptViewer } from 'presentation/components/base/ReceiptViewer';

import { type IExpenseAndReportDetailsProps } from './ExpenseAndReportDetails.types';

import { ReportInfo, ReportStatus } from './ExpenseAndReportDetails.styles';

export const ExpenseAndReportDetails = ({
  expense,
  isTransactionReversed
}: IExpenseAndReportDetailsProps): JSX.Element => {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  return (
    <>
      {/* IMAGEM DO RECIBO */}
      {expense.receiptUrl && (
        <ReceiptViewer
          images={[expense.receiptUrl]}
          thumbnailUrl={expense.receiptUrl}
          isPdf={expense.receiptType === 'PDF'}
        />
      )}

      {/* RELATÓRIO */}
      {expense.report && (
        <ReportInfo $isTransactionReversed={isTransactionReversed}>
          <p>
            {lang.global.report[currentLangKey]}
            {': '}
            {expense.report?.title}
          </p>

          {/* STATUS */}
          {expense.report?.status && (
            <p>
              {`${lang.global.status[currentLangKey]}: `}
              <ReportStatus status={expense.report.status}>
                {
                  lang.expenses.status[
                    expense.report.status.toLowerCase() as keyof typeof lang.expenses.status
                  ][currentLangKey]
                }
              </ReportStatus>
            </p>
          )}

          {/* CENTRO DE CUSTO */}
          {expense.costsCenter && (
            <p>
              {`${lang.cards.statement.transaction_details_modal.cost_center[currentLangKey]}: ${expense.costsCenter}`}
            </p>
          )}

          {/* PROJETO(S) */}
          {expense.apportionments !== null &&
            expense.apportionments.length > 0 && (
              <p>{`${
                lang.cards.statement.transaction_details_modal.projects[
                  currentLangKey
                ]
              }: ${expense.apportionments
                .map(item => item.description)
                .join(', ')}`}</p>
            )}
        </ReportInfo>
      )}
    </>
  );
};
