import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useModalContext } from 'data/contexts';
import {
  BusinessCardsMutationKeys,
  BusinessCardsService,
  type IAttachTeamMembersToBusinessCardFieldsForm,
  type IAttachTeamMembersToBusinessCardPayload,
  type IUseAttachTeamMembersToBusinessCard,
  type IUseAttachTeamMembersToBusinessCardParams
} from 'data/modules/cards/businessCard';

import { useFormWithSchema } from 'shared/hooks/forms';

import { useAttachTeamMembersToBusinessCardSchema } from './schemas';

export function useAttachTeamMembersToBusinessCard({
  uuid
}: IUseAttachTeamMembersToBusinessCardParams): IUseAttachTeamMembersToBusinessCard {
  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset: formReset
  } = useFormWithSchema(useAttachTeamMembersToBusinessCardSchema(), {
    defaultValues: {
      teamMembers: []
    }
  });
  const { handleOpenModal } = useModalContext();

  const {
    mutate: attachTeamMembersToBusinessCard,
    isLoading: loadingAttachTeamMembersToBusinessCard
  } = useMutationCache({
    mutationKey: [BusinessCardsMutationKeys.ATTACH_TEAM_MEMBERS],
    mutationFn: async (payload: IAttachTeamMembersToBusinessCardPayload) =>
      await BusinessCardsService.attachTeamMembersToBusinessCard(payload),
    onSuccess: () => {
      handleOpenModal('activateBusinessCard');
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function onSubmit(data: IAttachTeamMembersToBusinessCardFieldsForm): void {
    if (!data.teamMembers?.length) {
      handleOpenModal('activateBusinessCard');
    }

    if (data.teamMembers && data.teamMembers.length > 0) {
      attachTeamMembersToBusinessCard({
        outsourcers: data.teamMembers,
        outsourced: [uuid],
        permissions: [
          'EXPENSES',
          'REPORTS',
          'CONCILIATION',
          'SEND_REPORT_FOR_APPROVAL',
          'WITHDRAWALS_ADVANCE',
          'CARDS'
        ]
      });
    }

    formReset({
      teamMembers: []
    });
  }

  return {
    control,
    errors,
    isLoading: loadingAttachTeamMembersToBusinessCard,
    handleSubmit: handleSubmit(onSubmit),
    clearErrors,
    reset: formReset
  };
}
