import { format } from 'date-fns';
import { CurrencyInput, DefaultTable, DSIcons, TextInput, Tooltip } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useLangContext, useValueSolicitationContext } from 'data/contexts';

import { SolicitationBadgeStatus } from 'presentation/pages/cards/Management/ValueSolicitation/components/SolicitationBadgeStatus/SolicitationBadgeStatus.component';

import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

import { useSolicitationActionsRow } from './useSolicitationActionsRow';

import { type ISolicitationActionsRowProps } from './SolicitationActionsRow.types';

import {
  JustifyDissaproveSolicitationContent,
  JustifyRowContent,
  RemoveButton,
  UserNameRowContent
} from './SolicitationActionsRow.styles';

export function SolicitationActionsRow({
  solicitation,
  ...rest
}: ISolicitationActionsRowProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  const [step, handleToggleSelectSolicitationId] = useValueSolicitationContext(
    useShallow(state => [state.step, state.handleToggleSelectSolicitationId])
  );

  const {
    valueRequestedAmount,
    handleValueRequestedAmount,
    valueManagerJustify,
    handleManagerJustifyValue
  } = useSolicitationActionsRow({
    solicitation
  });

  return (
    <>
      <DefaultTable.Tr {...rest}>
        <DefaultTable.Td>
          <UserNameRowContent title={solicitation.userName}>
            {solicitation.userName}
          </UserNameRowContent>
        </DefaultTable.Td>
        <DefaultTable.Td>
          {CustomDate.formatFromISO(solicitation.dateSent)}
        </DefaultTable.Td>
        <DefaultTable.Td>
          {solicitation.datePrevision
            ? format(
                new Date(solicitation.datePrevision + 'T00:00:00'),
                'dd/MM/yyyy'
              )
            : '-'}
        </DefaultTable.Td>
        <DefaultTable.Td>
          {step === 'transfer'
            ? Currency.format('BRL', solicitation.transferAmount, true)
            : Currency.format('BRL', solicitation.requestedAmount, true)}
        </DefaultTable.Td>
        <DefaultTable.Td>
          <Tooltip
            message={solicitation.justify}
            containerClassName='justify-tooltip'
          >
            <JustifyRowContent>{solicitation.justify}</JustifyRowContent>
          </Tooltip>
        </DefaultTable.Td>

        <DefaultTable.Td>
          <SolicitationBadgeStatus status={solicitation.status} />
        </DefaultTable.Td>

        <DefaultTable.Td>
          {step === 'transfer' ? (
            <CurrencyInput.Default
              variant='small'
              type='number'
              inputId={`solicitation-${solicitation.userName}-${solicitation.id}`}
              onChangeValue={value => {
                handleValueRequestedAmount(value);
              }}
              value={valueRequestedAmount}
              leftElement='R$'
            />
          ) : solicitation.status === 'APPROVED' ? (
            Currency.format(
              'BRL',
              solicitation?.transferredAmount as number,
              true
            )
          ) : (
            '-'
          )}
        </DefaultTable.Td>

        <DefaultTable.Td
          onClick={() => {
            handleToggleSelectSolicitationId(solicitation);
          }}
        >
          <RemoveButton>
            <DSIcons.TrashDeleteBinIcon />
          </RemoveButton>
        </DefaultTable.Td>
      </DefaultTable.Tr>

      {step === 'disapprove' && (
        <JustifyDissaproveSolicitationContent>
          <td>
            <TextInput.Default
              label={
                lang.cards.valueSolicitation.table.justification[currentLangKey]
              }
              placeholder={
                lang.cards.valueSolicitation.table.justification[currentLangKey]
              }
              value={valueManagerJustify}
              onChangeValue={value => {
                handleManagerJustifyValue(value);
              }}
              onClearValue={() => {
                handleManagerJustifyValue('');
              }}
              type='text'
              small
            />
          </td>
        </JustifyDissaproveSolicitationContent>
      )}
    </>
  );
}
