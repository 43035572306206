import styled from 'styled-components';

import { Container as OnboardingFooterContainer } from 'presentation/pages/cards/Onboarding/components/General/OnboardingFooter/OnboardingFooter.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0.8rem;
  }

  > h1 {
    text-align: left;
    margin-top: 2.4rem;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-top: 3.2rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    ${OnboardingFooterContainer} {
      flex-direction: column;
      gap: 2.4rem;

      button,
      div,
      span {
        width: 100%;
      }
    }
  }
`;

export const RowDisposedFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  > div {
    flex-grow: 1;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
    gap: 3.2rem;
  }
`;

export const CustomLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighMediumest};
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
