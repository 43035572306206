import { faker } from '@faker-js/faker';

import {
  type IGetAvailableToBeManager,
  type UserType
} from 'data/modules/cards/cardGroups';

import { MockApi } from 'shared/utils/global';

import { userTypeOptions } from '..';

export async function getAvailableToBeManagerMock(): Promise<IGetAvailableToBeManager> {
  const data = [
    {
      id: faker.string.uuid(),
      name: faker.person.fullName(),
      type: faker.helpers.arrayElement(userTypeOptions) as UserType
    },
    {
      id: faker.string.uuid(),
      name: faker.person.fullName(),
      type: faker.helpers.arrayElement(userTypeOptions) as UserType
    },
    {
      id: faker.string.uuid(),
      name: faker.person.fullName(),
      type: faker.helpers.arrayElement(userTypeOptions) as UserType
    },
    {
      id: faker.string.uuid(),
      name: faker.person.fullName(),
      type: faker.helpers.arrayElement(userTypeOptions) as UserType
    },
    {
      id: faker.string.uuid(),
      name: faker.person.fullName(),
      type: faker.helpers.arrayElement(userTypeOptions) as UserType
    }
  ];

  await MockApi.sleep(2000);

  return {
    data,
    total: data.length
  };
}
