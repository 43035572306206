import {
  type IFuelPolicies,
  type IFuelPoliciesPersistence
} from 'data/modules/fuel/types/fuel.types';

class GetFuelPoliciesMapper {
  toDomain(payload: IFuelPoliciesPersistence): IFuelPolicies {
    return {
      blockingCard: payload.blocking_card,
      blockingExpensesQuantity: payload.blocking_expenses_quantity ?? 0,
      limitAmount: payload.limit_amount,
      limitType: payload.limit_type,
      requiredFields: payload.required_fields
    };
  }
}

export default new GetFuelPoliciesMapper();
