import { useMutationCache } from 'data/cache';
import {
  CardGroupsMutationKeys,
  CardGroupsService,
  type IAddManagersToGroupPayload,
  type IUseAddManagersToGroup
} from 'data/modules/cards/cardGroups';

export function useAddManagersToGroup(): IUseAddManagersToGroup {
  const { mutateAsync, isPending } = useMutationCache({
    mutationKey: [CardGroupsMutationKeys.ADD_MANAGERS_TO_GROUP],
    mutationFn: async (payload: IAddManagersToGroupPayload) => {
      await CardGroupsService.addManagersToGroup(payload);
    }
  });

  return {
    addManagersToGroup: mutateAsync,
    isAddingManagersToGroup: isPending
  };
}
