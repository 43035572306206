import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: row;
    gap: 2rem;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 49rem;
  }
`;

export const ControllersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  button {
    justify-content: center;
    white-space: nowrap;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-flow: row wrap;

    button {
      flex-grow: 1;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-grow: 1;
    gap: 2rem;
    flex-direction: row;
    justify-content: space-between;
  }
`;
