import { Route } from 'react-router-dom';

import { Support } from 'presentation/pages/support';

import { SupportRoutes } from 'shared/constants/global';
import { AuthenticationMiddleware, FlagMiddleware } from 'shared/middlewares';

export const supportRoutes = [
  <Route
    key='suporte'
    element={<AuthenticationMiddleware />}
  >
    <Route element={<FlagMiddleware />}>
      <Route
        path={SupportRoutes.SUPPORT}
        element={<Support />}
      />
    </Route>
  </Route>
];
