import { CardInfo, tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex: 1;

  gap: ${tokens.spacingInsetXs};
`;

export const CardInfoContainer = styled(CardInfo.Container)`
  flex: 1;
  min-height: 14.2rem;
  min-width: 23rem;
  border: 1px solid ${tokens.neutralColorHighDark};
  max-width: 100%;

  @media screen and (min-width: 1920px) {
    max-width: 28rem;
  }
`;
