import {
  type IGetPaginatedMembersPayload,
  type IGetPaginatedMembersPayloadPersistence
} from 'data/modules/fuel';

class GetPaginatedMembersMapper {
  toPersistence(
    data: IGetPaginatedMembersPayload
  ): IGetPaginatedMembersPayloadPersistence {
    return {
      linked: data.linked,
      page: data.page,
      per_page: data.perPage,
      search: data.search,
      vehicle_id: data.vehicleId
    };
  }
}

export default new GetPaginatedMembersMapper();
