import {
  CurrencyInput,
  DangerButton,
  DefaultButton,
  DSIcons,
  Modal,
  SecondaryButton,
  TextArea
} from 'ds';
import { CardInfo } from 'ds/components/CardInfo';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  useCardDetailsContext,
  useCardGroupsContext,
  useLangContext,
  useModalContext
} from 'data/contexts';
import {
  useGetAllocatedBalance,
  useGetUnallocatedBalance
} from 'data/modules/cards/balance';

import { Loader } from 'presentation/components/global/Loader';

import { Currency } from 'shared/utils/format';
import LangUtil from 'shared/utils/global/Lang.util';

import { useManageCardValueModal } from './useManageCardValueModal';

import { type IManageCardValueModalProps } from './ManageCardValueModal.types';

import {
  ActionsContainer,
  CardsContainer,
  CurrencyInputContainer,
  CurrentValue,
  FinalValue,
  InputsContainer,
  ModalContent,
  ModalFooter,
  ModalForm,
  Receiver
} from './ManageCardValueModal.styles';

export function ManageCardValueModal({
  type,
  page = 'CARDS'
}: IManageCardValueModalProps): JSX.Element {
  const { handleOpenModal } = useModalContext();

  const {
    currentLangKey,
    lang: {
      global,
      cards: { valueAllocation }
    }
  } = useLangContext();

  const { groupId } = useParams();

  const { unallocatedBalance } = useGetUnallocatedBalance({
    cardGroupId: groupId
  });

  const { allocatedBalance } = useGetAllocatedBalance({ cardGroupId: groupId });

  const [selectedAccount] = useCardDetailsContext(
    useShallow(state => [state.selectedAccount])
  );

  const [selectedCardGroup] = useCardGroupsContext(
    useShallow(state => [state.selectedCardGroup])
  );

  const {
    methods,
    isChangingAccountBalanceAmount,
    floatAvailableBalance,
    isCardGroups,
    handleChangeAccountBalanceAmount,
    handleChangeValueFromButtons
  } = useManageCardValueModal({ type, page });

  return (
    <Modal.Container
      onClose={() => {
        page === 'CARDS'
          ? handleOpenModal('cardDetails')
          : handleOpenModal('groupCardDetails');
      }}
    >
      <Loader isLoading={isChangingAccountBalanceAmount} />
      <Modal.Header>
        <Modal.Title>
          {type === 'ADD' ? 'Adicionar Valor' : 'Remover Valor'}
        </Modal.Title>

        <Modal.IconClose
          onClick={() => {
            handleOpenModal('cardDetails');
          }}
        />
      </Modal.Header>

      <ModalForm
        methods={methods}
        handleSubmit={handleChangeAccountBalanceAmount}
      >
        <ModalContent>
          <CardsContainer>
            <CardInfo.Container>
              <CardInfo.Title variant='small'>
                <DSIcons.MoneyWalletAdd />

                {
                  valueAllocation.cards_info.amount_available_to_allocate[
                    currentLangKey
                  ]
                }
              </CardInfo.Title>

              <CardInfo.ValueContainer>
                <CardInfo.Value variant='small'>
                  {unallocatedBalance?.amount
                    ? Currency.format('BRL', unallocatedBalance.amount, true)
                    : 'R$ 0,00'}
                </CardInfo.Value>
              </CardInfo.ValueContainer>

              <CardInfo.Caption variant='small'>
                {
                  valueAllocation.cards_info.amount_not_allocated_to_cards[
                    currentLangKey
                  ]
                }
              </CardInfo.Caption>
            </CardInfo.Container>

            <CardInfo.Container>
              <CardInfo.Title variant='small'>
                <DSIcons.MoneyWalletCheckMark />

                {
                  valueAllocation.cards_info.amount_distributed_on_cards[
                    currentLangKey
                  ]
                }
              </CardInfo.Title>

              <CardInfo.ValueContainer>
                <CardInfo.Value variant='small'>
                  {allocatedBalance?.amount
                    ? Currency.format('BRL', allocatedBalance.amount, true)
                    : 'R$ 0,00'}
                </CardInfo.Value>
              </CardInfo.ValueContainer>

              <CardInfo.Caption variant='small'>
                {LangUtil.putValuesInString(
                  valueAllocation.cards_info.updated_on[currentLangKey],
                  {
                    date: allocatedBalance?.lastUpdateDate || '',
                    hours: allocatedBalance?.lastUpdateTime || ''
                  }
                )}
              </CardInfo.Caption>
            </CardInfo.Container>
          </CardsContainer>

          <Receiver>
            {type === 'ADD'
              ? valueAllocation.inform_value_to_add[currentLangKey]
              : valueAllocation.inform_value_to_remove[currentLangKey]}
            <strong>
              {isCardGroups ? selectedCardGroup?.name : selectedAccount?.name}
            </strong>
            .
          </Receiver>

          <ActionsContainer>
            <CurrentValue>
              {valueAllocation.current_value[currentLangKey]}:{' '}
              <strong>
                {isCardGroups
                  ? Currency.format(
                      'BRL',
                      (selectedCardGroup?.availableValue as number) || 0,
                      true
                    )
                  : selectedAccount?.availableBalance || 'R$ 0,00'}
              </strong>
            </CurrentValue>
            <InputsContainer>
              <CurrencyInputContainer>
                <CurrencyInput.Controlled
                  inputId={`add-amount-to-card`}
                  variant='small'
                  leftElement='R$'
                  name='amount'
                  autoComplete='off'
                />
              </CurrencyInputContainer>

              <SecondaryButton
                type='button'
                onClick={() => {
                  handleChangeValueFromButtons(100);
                }}
                size='small'
              >
                {`${type === 'ADD' ? '+' : '-'} 100`}
              </SecondaryButton>

              <SecondaryButton
                type='button'
                onClick={() => {
                  handleChangeValueFromButtons(1000);
                }}
                size='small'
              >
                {`${type === 'ADD' ? '+' : '-'} 1.000`}
              </SecondaryButton>

              <SecondaryButton
                type='button'
                onClick={() => {
                  handleChangeValueFromButtons(10000);
                }}
                size='small'
              >
                {`${type === 'ADD' ? '+' : '-'} 10.000`}
              </SecondaryButton>
            </InputsContainer>
            <FinalValue>
              {valueAllocation.final_value[currentLangKey]}:{' '}
              <strong>
                {type === 'ADD'
                  ? Currency.format(
                      'BRL',
                      Number(methods.watch('amount')) + floatAvailableBalance,
                      true
                    )
                  : Currency.format(
                      'BRL',
                      floatAvailableBalance - Number(methods.watch('amount')),
                      true
                    )}
              </strong>
            </FinalValue>

            <TextArea.Controlled
              label='Motivo'
              textareaProps={{ placeholder: 'Motivo' }}
              name='description'
            />
          </ActionsContainer>
        </ModalContent>

        <ModalFooter>
          <SecondaryButton
            type='button'
            onClick={() => {
              page === 'CARDS'
                ? handleOpenModal('cardDetails')
                : handleOpenModal('groupCardDetails');
            }}
            size='small'
          >
            {global.cancel[currentLangKey]}
          </SecondaryButton>

          {type === 'ADD' && (
            <DefaultButton
              type='submit'
              size='small'
            >
              {valueAllocation.actions.add_value[currentLangKey]}
            </DefaultButton>
          )}

          {type === 'REMOVE' && (
            <DangerButton
              type='submit'
              size='small'
            >
              {valueAllocation.actions.remove_value[currentLangKey]}
            </DangerButton>
          )}
        </ModalFooter>
      </ModalForm>
    </Modal.Container>
  );
}
