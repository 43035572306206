import { format, subDays } from 'date-fns';
import { create } from 'zustand';

import { type IUseMovementsContext } from './useMovementsContext.types';

export const useMovementsContext = create<IUseMovementsContext>()(
  (set, get) => ({
    movementsFilters: {
      movementType: 'ALL',
      endDate: format(new Date(), 'yyyy-MM-dd'),
      startDate: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      timezone: '-3:00',
      accounts: ['COMPANY']
    },
    movementsSearch: '',
    transactionDetails: null,
    dateFilterValidatingStatus: 'OK',
    transactionsToExport: [],

    handleSetTransactionsToExport: transactionsIds => {
      set({
        transactionsToExport: transactionsIds
      });
    },

    handleChangeDateFilterValidatingStatus: status => {
      set({ dateFilterValidatingStatus: status });
    },

    handleSetTransactionDetails: details => {
      set({ transactionDetails: details });
    },

    handleChangeMovementsSearch: value => {
      set({ movementsSearch: value });
    },

    handleChangeMovementsFilters: filters => {
      set({ movementsFilters: filters });
    }
  })
);
