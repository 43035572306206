import { DefaultTable, DSIcons } from 'ds';
import { useTranslation } from 'react-i18next';

import { CustomInfoMessage } from 'presentation/components/global/CustomInfoMessage';
import { TableLoader } from 'presentation/pages/cards/Management/components/TableLoader';

import {
  MyValueSolicitationsFilters,
  MyValueSolicitationsTableHead,
  MyValueSolicitationsTablePagination,
  MyValueSolicitationsTableRow
} from '..';

import { useMyValueSoliciationsTable } from './useMyValueSoliciationsTable';

import { Container, TableContainer } from './MyValueSolicitationsTable.styles';

export function MyValueSolicitationsTable(): JSX.Element {
  const { t } = useTranslation('cards');

  const { solicitations, isFetchingMyValueSolicitations } =
    useMyValueSoliciationsTable();

  return (
    <Container>
      <MyValueSolicitationsFilters />

      {isFetchingMyValueSolicitations ? (
        <TableLoader
          columns={6}
          rows={5}
        />
      ) : (
        <>
          <TableContainer>
            <DefaultTable.Container>
              <MyValueSolicitationsTableHead
                balanceSolicitations={solicitations}
              />

              <DefaultTable.Tbody>
                {solicitations.map((solicitation, index) => (
                  <MyValueSolicitationsTableRow
                    solicitation={solicitation}
                    key={`${solicitation.id}-${index}-${solicitation.justify}`}
                  />
                ))}
              </DefaultTable.Tbody>
            </DefaultTable.Container>
          </TableContainer>

          {!solicitations.length && (
            <CustomInfoMessage
              icon={<DSIcons.EmptyFolderIcon />}
              title={t('emptyValueSolicitationsList')}
            />
          )}
        </>
      )}

      <MyValueSolicitationsTablePagination disable={!solicitations.length} />
    </Container>
  );
}
