import { tokens } from 'ds';
import styled from 'styled-components';

export const DetailsButton = styled.div`
  display: flex;
  align-items: center;

  background: none;
  border: none;

  > svg {
    cursor: pointer;
  }
`;

export const UserNameRowContent = styled.div`
  max-width: 38rem;
  text-align: left;
  font-size: ${tokens.fontSizeXxss};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const JustifyRowContent = styled.p`
  max-width: 38rem;
  text-align: left;
  font-size: ${tokens.fontSizeXxss};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
