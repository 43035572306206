import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.strong`
  line-height: 2.4rem;
  display: inline-flex;
  letter-spacing: 0.075rem;
  justify-content: space-between;
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowDark};
  font-weight: ${tokens.fontWeightBold};
  font-family: ${tokens.fontFamilyPoppins};
`;

export const CharacterLimit = styled.span`
  white-space: nowrap;
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXxs};
  padding-left: ${tokens.spacingNano};
  color: ${tokens.neutralColorLowLight};
  font-weight: ${tokens.fontWeightMedium};
  font-family: ${tokens.fontFamilyPoppins};
`;
