import { useMutationCache } from 'data/cache';
import {
  CardGroupsMutationKeys,
  CardGroupsService,
  type IAddCardsToGroupPayload,
  type IUseAddCardsToGroup
} from 'data/modules/cards/cardGroups';

export function useAddCardsToGroup(): IUseAddCardsToGroup {
  const { mutateAsync, isPending } = useMutationCache({
    mutationKey: [CardGroupsMutationKeys.ADD_CARDS_TO_GROUP],
    mutationFn: async (payload: IAddCardsToGroupPayload) => {
      await CardGroupsService.addCardsToGroup(payload);
    }
  });

  return {
    addCardsToGroup: mutateAsync,
    isAddingCardsToGroup: isPending
  };
}
