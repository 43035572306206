import { BadgeStatus, SkeletonLoader } from 'ds';
import { MenuTabs } from 'ds/components/Tabs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from 'data/contexts';

import { useMyCardTabs } from './useMyCardTabs';

import { type IMyCardTabsProps } from './MyCardTabs.types';

import { Container, TabItemContent } from './MyCardTabs.styles';

export function MyCardTabs({ selectedTab }: IMyCardTabsProps): JSX.Element {
  const { t } = useTranslation('cards');
  const navigate = useNavigate();

  const user = useAuthContext(state => state.user);

  const {
    quantitySolicitationByStatus,
    isLoadingQuantitySolicitationByStatus
  } = useMyCardTabs();

  return (
    <Container $movements={selectedTab === 'MY_MOVEMENTS'}>
      <MenuTabs.Container hasTrack>
        <MenuTabs.Item
          active={selectedTab === 'MY_MOVEMENTS'}
          onClick={() => {
            navigate(
              `/cartoes/meu-cartao/minhas-movimentacoes/${user?.cards?.id}`
            );
          }}
        >
          <TabItemContent>{t('myMovements')}</TabItemContent>
        </MenuTabs.Item>

        <MenuTabs.Item
          active={selectedTab === 'MY_SOLICITATIONS'}
          onClick={() => {
            navigate('/cartoes/meu-cartao/minhas-solicitacoes');
          }}
        >
          <TabItemContent>
            {t('myValueSolicitations')}
            {isLoadingQuantitySolicitationByStatus && (
              <SkeletonLoader
                width='3rem'
                color='neutralColorHighDark'
              />
            )}
            {!isLoadingQuantitySolicitationByStatus &&
              quantitySolicitationByStatus &&
              quantitySolicitationByStatus.all > 0 && (
                <BadgeStatus
                  type={
                    selectedTab === 'MY_SOLICITATIONS' ? 'secondary' : 'general'
                  }
                >
                  {quantitySolicitationByStatus.all}
                </BadgeStatus>
              )}
          </TabItemContent>
        </MenuTabs.Item>
      </MenuTabs.Container>
    </Container>
  );
}
