import { BaseIcon } from 'presentation/components/base/Icon';

import { type IDsSearchProps } from './DsSearch.types';

import {
  CleanButton,
  Container,
  Input,
  InputContainer,
  Label
} from './DsSearch.styles';

export function DsSearch({
  defaultValue = '',
  label,
  name,
  disabled = false,
  iconSize = 2.4,
  inputClass = '',
  value,
  onChange,
  onClear,
  ...rest
}: IDsSearchProps): JSX.Element {
  return (
    <Container
      $hasValue={value ?? defaultValue}
      $disabled={disabled}
      className={inputClass}
    >
      {(value ?? defaultValue) && (
        <Label
          htmlFor={name}
          $disabled={disabled}
        >
          {label}
        </Label>
      )}
      <InputContainer
        $disabled={disabled}
        $hasValue={value}
      >
        <BaseIcon
          name='search'
          type='line'
          size={iconSize}
        />

        <Input
          name={name}
          value={value ?? defaultValue}
          $hasValue={value ?? defaultValue}
          onChange={onChange}
          placeholder={label}
          disabled={disabled}
          {...rest}
        />

        <CleanButton
          onClick={onClear}
          $hasValue={value ?? defaultValue}
          $disabled={disabled}
        >
          <BaseIcon
            name='close'
            type='line'
            size={1.7}
          />
        </CleanButton>
      </InputContainer>
    </Container>
  );
}
