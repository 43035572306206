import { Alert, Checkbox, DefaultButton, Radio, Switch, TextInput } from 'ds';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { useGetAuthUser } from 'data/modules/auth';

import { Loader } from 'presentation/components/global/Loader';

import { animations } from 'shared/constants/animations';

import { useFuelPolicy } from './useFuelPolicy';

import {
  BlockedCardContainer,
  BlockedCardExpensesContainer,
  CardContainer,
  CardHeader,
  CardOptionContainer,
  CardOptionLabel,
  CardOptionLabelWithMargin,
  CardsContainer,
  CardSubTitle,
  CardTitle,
  Container,
  FlexContainer,
  HeaderContainer,
  PageTitle,
  RequiredFieldsContainer,
  ValueByFuelPolicyContainer
} from './FuelPolicy.styles';

export function FuelPolicy(): JSX.Element {
  const {
    isVisibleBlockedCard,
    isVisibleRequiredFields,
    isVisibleValueByFuel,
    setIsVisibleBlockedCard,
    setIsVisibleRequiredFields,
    setIsVisibleValueByFuel,
    blockedCard,
    requiredFields,
    setBlockCard,
    setValuesByFuel,
    valuesByFuel,
    handleSaveFuelPolicies,
    isLoadingSomeData,
    handleChangeRequiredFields,
    isSaveButtonDisabled
  } = useFuelPolicy();

  const { t } = useTranslation(['fuel', 'global']);

  const { company } = useGetAuthUser();

  return (
    <Container>
      <Loader isLoading={isLoadingSomeData} />

      <HeaderContainer>
        <PageTitle>{t('fuel:policies')}</PageTitle>

        <DefaultButton
          disabled={isSaveButtonDisabled}
          onClick={handleSaveFuelPolicies}
          size='small'
        >
          {t('global:save')}
        </DefaultButton>
      </HeaderContainer>

      <CardsContainer>
        <CardContainer>
          <CardHeader>
            <div>
              <CardTitle>{t('fuel:valueByFillTitle')}</CardTitle>

              <Switch.Default
                value={isVisibleValueByFuel}
                onChange={() => {
                  setIsVisibleValueByFuel(!isVisibleValueByFuel);
                }}
              />
            </div>

            <CardSubTitle>{t('fuel:valueByFillSubTitle')}</CardSubTitle>
          </CardHeader>

          <AnimatePresence>
            {isVisibleValueByFuel && (
              <CardOptionContainer
                key='valor-por-abestecimento'
                {...animations.slideUpAndDown}
              >
                <ValueByFuelPolicyContainer>
                  <p>{t('global:amount')}</p>

                  <TextInput.Default
                    label=''
                    value={valuesByFuel.amount}
                    placeholder='0,00'
                    mask='currency'
                    onChangeValue={value => {
                      setValuesByFuel({
                        amount: value,
                        period: valuesByFuel.period
                      });
                    }}
                    small
                    inputContainerAttributes={{
                      className: 'text-input-small'
                    }}
                  />

                  <Radio.Default
                    onChangeValue={() => {
                      setValuesByFuel({
                        amount: valuesByFuel.amount,
                        period: 'day'
                      });
                    }}
                    label={t('fuel:daily')}
                    checked={valuesByFuel.period === 'day'}
                    small
                  />

                  <Radio.Default
                    label={t('fuel:weekly')}
                    onChangeValue={() => {
                      setValuesByFuel({
                        amount: valuesByFuel.amount,
                        period: 'week'
                      });
                    }}
                    checked={valuesByFuel.period === 'week'}
                    small
                  />

                  <Radio.Default
                    label={t('fuel:monthly')}
                    onChangeValue={() => {
                      setValuesByFuel({
                        amount: valuesByFuel.amount,
                        period: 'month'
                      });
                    }}
                    checked={valuesByFuel.period === 'month'}
                    small
                  />
                </ValueByFuelPolicyContainer>
              </CardOptionContainer>
            )}
          </AnimatePresence>

          {isVisibleValueByFuel && valuesByFuel.period === 'week' && (
            <Alert type='warning'>{t('fuel:valueByFillWarning')}</Alert>
          )}
        </CardContainer>

        {company?.parameters.usesCards && (
          <CardContainer>
            <CardHeader>
              <div>
                <CardTitle>{t('fuel:blockedCardTitle')}</CardTitle>

                <Switch.Default
                  value={isVisibleBlockedCard}
                  onChange={() => {
                    setIsVisibleBlockedCard(!isVisibleBlockedCard);
                  }}
                />
              </div>

              <CardSubTitle>{t('fuel:blockedCardSubTitle')}</CardSubTitle>
            </CardHeader>

            <AnimatePresence>
              {isVisibleBlockedCard && (
                <CardOptionContainer
                  key='bloqueio-de-cartao-por-despesa'
                  {...animations.slideUpAndDown}
                >
                  <div>
                    <FlexContainer>
                      <CardOptionLabel>
                        {t('fuel:blockedCardLabel')}
                      </CardOptionLabel>

                      <BlockedCardContainer
                        $shouldHaveMarginBottom={
                          blockedCard.blockType !== 'allowMoreExpenses'
                        }
                      >
                        <Radio.Default
                          label={t('fuel:blockImediatly')}
                          onChangeValue={() => {
                            setBlockCard({
                              allowMoreExpenses: 0,
                              blockType: 'immediately'
                            });
                          }}
                          checked={blockedCard.blockType === 'immediately'}
                          small
                        />

                        <Radio.Default
                          label={t('fuel:allowMoreExpenses')}
                          onChangeValue={() => {
                            setBlockCard({
                              allowMoreExpenses: 0,
                              blockType: 'allowMoreExpenses'
                            });
                          }}
                          checked={
                            blockedCard.blockType === 'allowMoreExpenses'
                          }
                          small
                        />
                      </BlockedCardContainer>
                    </FlexContainer>

                    {blockedCard.blockType === 'allowMoreExpenses' && (
                      <FlexContainer>
                        <CardOptionLabelWithMargin>
                          {t('blockImediatlyWithDays')}
                        </CardOptionLabelWithMargin>

                        <BlockedCardExpensesContainer>
                          <Radio.Default
                            label={`1 ${t('global:expenses_one')}`}
                            onChangeValue={() => {
                              setBlockCard({
                                allowMoreExpenses: 1,
                                blockType: blockedCard.blockType
                              });
                            }}
                            checked={blockedCard.allowMoreExpenses === 1}
                            small
                          />

                          <Radio.Default
                            label={`2 ${t('global:expenses')}`}
                            onChangeValue={() => {
                              setBlockCard({
                                allowMoreExpenses: 2,
                                blockType: blockedCard.blockType
                              });
                            }}
                            checked={blockedCard.allowMoreExpenses === 2}
                            small
                          />

                          <Radio.Default
                            label={`3 ${t('global:expenses')}`}
                            onChangeValue={() => {
                              setBlockCard({
                                allowMoreExpenses: 3,
                                blockType: blockedCard.blockType
                              });
                            }}
                            checked={blockedCard.allowMoreExpenses === 3}
                            small
                          />

                          <Radio.Default
                            label={`5 ${t('global:expenses')}`}
                            onChangeValue={() => {
                              setBlockCard({
                                allowMoreExpenses: 5,
                                blockType: blockedCard.blockType
                              });
                            }}
                            checked={blockedCard.allowMoreExpenses === 5}
                            small
                          />

                          <Radio.Default
                            label={`10 ${t('global:expenses_other')}`}
                            onChangeValue={() => {
                              setBlockCard({
                                allowMoreExpenses: 10,
                                blockType: blockedCard.blockType
                              });
                            }}
                            checked={blockedCard.allowMoreExpenses === 10}
                            small
                          />
                        </BlockedCardExpensesContainer>
                      </FlexContainer>
                    )}
                  </div>
                </CardOptionContainer>
              )}
            </AnimatePresence>

            {isVisibleBlockedCard &&
              blockedCard.blockType === 'immediately' && (
                <Alert type='warning'>{t('fuel:blockImediatlyWarning')}</Alert>
              )}

            {isVisibleBlockedCard &&
              blockedCard.blockType === 'allowMoreExpenses' &&
              blockedCard.allowMoreExpenses > 0 && (
                <Alert type='warning'>
                  {t('fuel:allowMoreExpensesWarning')}
                </Alert>
              )}
          </CardContainer>
        )}

        <CardContainer>
          <CardHeader>
            <div>
              <CardTitle>{t('fuel:requiredFieldsTitle')}</CardTitle>

              <Switch.Default
                value={isVisibleRequiredFields}
                onChange={() => {
                  setIsVisibleRequiredFields(!isVisibleRequiredFields);
                }}
              />
            </div>

            <CardSubTitle>{t('fuel:requiredFieldsSubTitle')}</CardSubTitle>
          </CardHeader>

          <AnimatePresence>
            {isVisibleRequiredFields && (
              <CardOptionContainer
                key='campos-obrigatorios'
                {...animations.slideUpAndDown}
              >
                <RequiredFieldsContainer>
                  <Checkbox.Default
                    className='checkbox-small'
                    label={t('fuel:plate')}
                    value={requiredFields.includes('plate')}
                    onChangeValue={() => {
                      handleChangeRequiredFields('plate');
                    }}
                    small
                  />

                  <Checkbox.Default
                    className='checkbox-small'
                    label={t('fuel:typeOfVehicle')}
                    value={requiredFields.includes('vehicle_type')}
                    onChangeValue={() => {
                      handleChangeRequiredFields('vehicle_type');
                    }}
                    small
                  />

                  <Checkbox.Default
                    className='checkbox-small'
                    label={t('fuel:odometer')}
                    value={requiredFields.includes('odometer')}
                    onChangeValue={() => {
                      handleChangeRequiredFields('odometer');
                    }}
                    small
                  />

                  <Checkbox.Default
                    className='checkbox-small'
                    label={t('fuel:kilometrage')}
                    value={requiredFields.includes('kilometrage')}
                    onChangeValue={() => {
                      handleChangeRequiredFields('kilometrage');
                    }}
                    small
                  />
                </RequiredFieldsContainer>
              </CardOptionContainer>
            )}
          </AnimatePresence>
        </CardContainer>
      </CardsContainer>
    </Container>
  );
}
