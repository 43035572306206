import { type ISelectOption } from 'presentation/components/base/Select/Select.types';

class TransformArray {
  objectsToSelectOptions<T extends object>(
    array: T[] | undefined,
    valueKey: keyof T,
    labelKey: keyof T,
    convertToString?: boolean
  ): ISelectOption[] {
    if (!array) {
      return [];
    }

    return array.map(item => ({
      value: convertToString
        ? String(item[valueKey])
        : // lembrando que o as string não faz a conversão para string, apenas diz para o typescript que o valor é uma string
          (item[valueKey] as string),
      label: item[labelKey] as string
    }));
  }

  stringsToSelectOptions(
    array: string[] | readonly string[] | undefined
  ): ISelectOption[] {
    if (!array) {
      return [];
    }

    return array.map(item => ({
      value: item,
      label: item
    }));
  }

  sliceIntoChunks<T extends object>(arr: T[], chunkSize: number): T[][] {
    const chunkResult = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      chunkResult.push(chunk);
    }

    return chunkResult;
  }
}

export default new TransformArray();
