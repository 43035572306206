import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  AccountMutationKeys,
  AccountService,
  type IBlockCardPayload,
  type IUseBlockCard,
  type IUseBlockCardParams
} from 'data/modules/cards/account';

export function useBlockCard(params?: IUseBlockCardParams): IUseBlockCard {
  const { mutateAsync, isLoading } = useMutationCache({
    mutationKey: [AccountMutationKeys.BLOCK_CARD],
    mutationFn: async (payload: IBlockCardPayload) =>
      await AccountService.blockCard(payload),
    onSuccess: (cardData, payload) => {
      params?.onAfterSuccess?.(cardData, payload);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  return {
    blockCard: mutateAsync,
    isBlockingCard: isLoading
  };
}
