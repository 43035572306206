import {
  type IPasswordPolicy,
  type IPersistencePasswordPolicy
} from 'data/modules/settings/passwordPolicy';

class GetPasswordPolicyMapper {
  toDomain(persistence: IPersistencePasswordPolicy): IPasswordPolicy {
    return {
      number: persistence.number,
      lowercase: persistence.lowercase,
      uppercase: persistence.uppercase,
      specialCharacter: persistence.special_character,
      passwordLength: Number(persistence.password_length)
    };
  }
}

export default new GetPasswordPolicyMapper();
