import {
  type IAllocatedBalanceCardGroup,
  type IPersistenceAllocatedBalanceCardGroup
} from 'data/modules/cards/cardGroups/types/cardGroups.types';

class GetAllocatedBalanceCardGroupMapper {
  toDomain(
    persistenceAllocatedBalanceCardGroup: IPersistenceAllocatedBalanceCardGroup
  ): IAllocatedBalanceCardGroup {
    return {
      amount: persistenceAllocatedBalanceCardGroup.amount
    };
  }
}

export default new GetAllocatedBalanceCardGroupMapper();
