import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as EmptyAnalysisIcon } from 'shared/assets/images/analytics/empty_analysis.svg';
import { routesPathPrefix } from 'shared/constants/global';

import { Container, Content } from './EmptyAnalysisResults.styles';

export function EmptyAnalysisResults(): JSX.Element {
  const { t } = useTranslation('analytics');

  return (
    <Container>
      <EmptyAnalysisIcon />

      <Content>
        <p>{t('emptyResults.first')}</p>

        <p>{t('emptyResults.second')}</p>

        <p>
          {`${t('emptyResults.third')}, `}
          <Link
            to={routesPathPrefix.support}
          >{`${t('filters.clickHere').toLowerCase()}.`}</Link>
        </p>
      </Content>
    </Container>
  );
}
