import { DSIcons } from 'ds/icons';
import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const StyledIcon = styled(DSIcons.CloseIcon)`
  color: ${tokens.brandColorSecondaryPure};
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  margin-right: -0.6rem;
  flex-shrink: 0;
`;
