import { DefaultButton, Modal, SecondaryButton } from 'ds';
import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';

import {
  Fee,
  InternationalPurchase,
  InternationalReversal,
  NationalPurchase,
  NationalReversal,
  ReversalWithdraw,
  Transfer,
  Withdraw
} from '../';

import { useTransactionDetailsModal } from './useTransactionDetailsModal';

import { Content, Footer } from './TransactionDetailsModal.styles';

export function TransactionDetailsModal(): JSX.Element {
  const { t } = useTranslation('global');
  const { handleCloseModal } = useModalContext();
  const {
    isNationalPurchase,
    isNationalReversal,
    isWithdraw,
    isReversalWithdraw,
    isInternationalPurchase,
    isTransfer,
    isInternationalReversal,
    expense,
    isErrorExpense,
    isFetchingExpense,
    isFee
  } = useTransactionDetailsModal();

  if (isFetchingExpense) {
    return <Loader isLoading />;
  }

  return (
    <Modal.Container
      onClose={handleCloseModal}
      variant='small'
    >
      <Modal.Header>
        <Modal.Title>{t('details')}</Modal.Title>

        <Modal.IconClose onClick={handleCloseModal} />
      </Modal.Header>

      <Content>
        {isNationalPurchase && (
          <NationalPurchase
            expense={expense}
            hasErrorInExpense={isErrorExpense}
          />
        )}

        {isNationalReversal && <NationalReversal />}

        {isWithdraw && <Withdraw />}

        {isReversalWithdraw && <ReversalWithdraw />}

        {isInternationalReversal && <InternationalReversal />}

        {isInternationalPurchase && (
          <InternationalPurchase
            expense={expense}
            hasErrorInExpense={isErrorExpense}
          />
        )}

        {isTransfer && <Transfer />}

        {isFee && <Fee />}
      </Content>

      {(isNationalPurchase || isInternationalPurchase) && expense && (
        <Footer>
          {expense.url && expense.report?.url && (
            <>
              <SecondaryButton
                size='small'
                onClick={() => window.open(expense.url, '_blank')}
              >
                {t('viewExpense')}
              </SecondaryButton>

              <DefaultButton
                size='small'
                onClick={() => window.open(expense.report?.url, '_blank')}
              >
                {t('viewReport')}
              </DefaultButton>
            </>
          )}

          {expense.url && !expense.report?.url && (
            <DefaultButton
              size='small'
              onClick={() => window.open(expense.url, '_blank')}
            >
              {t('viewExpense')}
            </DefaultButton>
          )}

          {!expense.url && expense.report?.url && (
            <DefaultButton
              size='small'
              onClick={() => window.open(expense.report?.url, '_blank')}
            >
              {t('viewReport')}
            </DefaultButton>
          )}
        </Footer>
      )}
    </Modal.Container>
  );
}
