import { tokens } from 'ds';
import { motion } from 'framer-motion';
import { styled } from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${tokens.spacingLg};

  > div {
    display: flex;
    justify-content: end;
    gap: ${tokens.spacingMd};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column-reverse;
    gap: ${tokens.spacingXs};

    > div {
      flex-direction: row;
      gap: ${tokens.spacingXs};

      > button {
        flex-grow: 1;
      }
    }
  }
`;

export const CreateReportButtonContainer = styled.div`
  display: flex;
  justify-content: start;
`;

export const ContainerWithMotion = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  > form {
    overflow: auto;
  }

  .modal-form > .modal-content {
    padding-right: 0.4rem !important;
  }

  .modal-footer {
    display: flex;
    flex-direction: column;
    margin-top: ${tokens.spacingXs};
  }
`;
