import { useLangContext } from 'data/contexts';

import { DsDefaultButton, DsGhostButton } from 'presentation/ds/DsButton';

import { type IOnboardingFooterProps } from './OnboardingFooter.types';

import { Container, RightButtonContent } from './OnboardingFooter.styles';

export function OnboardingFooter({
  addButtonProps,
  backButtonProps,
  continueButtonProps,
  loading
}: IOnboardingFooterProps): JSX.Element {
  const [global, currentLangKey] = useLangContext(state => [
    state.lang.global,
    state.currentLangKey
  ]);

  return (
    <Container $uniqueButton={!backButtonProps}>
      {backButtonProps && (
        <DsGhostButton
          text={backButtonProps.text ?? global.return[currentLangKey]}
          {...backButtonProps}
        />
      )}

      <RightButtonContent>
        {addButtonProps && (
          <DsDefaultButton
            secondary
            loading={loading}
            text={addButtonProps.text ?? '+ ' + global.add[currentLangKey]}
            {...addButtonProps}
          />
        )}
        {continueButtonProps && (
          <DsDefaultButton
            type='submit'
            loading={loading}
            text={continueButtonProps.text ?? global.continue[currentLangKey]}
            {...continueButtonProps}
          />
        )}
      </RightButtonContent>
    </Container>
  );
}
