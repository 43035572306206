import { useEffect, useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { vexDomain } from 'data/config';
import { useLangContext, useModalContext, useTourContext } from 'data/contexts';
import { useGetAuthUser } from 'data/modules/auth';
import {
  ExpensesQueryKeys,
  type IExpensePaginatedResponse,
  type IUseGetPaginatedExpensesParams,
  type StatusTabType,
  type StatusTabTypePersistenceType,
  useDeleteExpenses,
  useGetNumberOfExpensesByStatus,
  useGetPaginatedExpenses
} from 'data/modules/expenses';
import { useGetExpenseDetails } from 'data/modules/expenses/useCases/get-expense-details/useGetExpenseDetails';
import { ReportsQueryKeys } from 'data/modules/reports';

import {
  cookiesKeys,
  localStorageKeys,
  routesPathPrefix
} from 'shared/constants/global';
import { useDebounce, useGa4 } from 'shared/hooks/global';
import { CustomDate, CustomObject } from 'shared/utils/custom';
import { ErrorHandle } from 'shared/utils/errors';
import { CustomCookies, FreddySurvey } from 'shared/utils/global';

import { useExpensesPageTour } from './tours';

import {
  type CreateExpenseType,
  type ExpenseActionModalDataType,
  type ExpenseActionModalType,
  type ExpenseOrderByType,
  type ExpenseType,
  type IExpenseActionModalState,
  type IFilterAction,
  type IFilters,
  type IUseExpensesList
} from './ExpensesList.types';

export function useExpensesList(): IUseExpensesList {
  const [expenseIndex, setExpenseIndex] = useState(-1);
  const [expenseUuidOrIdToUpdate, setExpenseUuidOrIdToUpdate] = useState<
    undefined | string
  >(undefined);
  const { handleCloseModal, handleOpenModal, handleChangeErrorMessage } =
    useModalContext();
  const [expenseActionModalActive, setExpenseActionModalActive] =
    useState<IExpenseActionModalState>({
      expenseIndex: -1,
      action: 'deleteExpense'
    });
  const [checked, setChecked] = useState<number[]>([]);

  const { getEnsuredExpenseDetails } = useGetExpenseDetails({
    expenseUuid: '',
    enabled: false
  });

  const { lang, currentLangKey } = useLangContext();

  const { company } = useGetAuthUser();

  const { sendGaEvent } = useGa4();

  const { id } = useParams();

  const dateInputStartValue = new Date();
  dateInputStartValue.setMonth(dateInputStartValue.getMonth() - 1);

  const [filters, setFilters] = useState<IFilters>({
    endDate: CustomDate.formatDateToIso(new Date()),
    startDate: CustomDate.formatDateToIso(dateInputStartValue),
    refundable: [],
    page: 1,
    perPage: 10,
    search: '',
    typeOfExpense: '',
    statusTab: 'all',
    paymentMethod: '',
    orderBy: 'date',
    order: 'desc'
  });

  const queryClient = useQueryClient();

  const [reset] = useTourContext(useShallow(state => [state.reset]));

  const { user } = useGetAuthUser();

  const pattern = /^\d+$/;

  const { t } = useTranslation('expenses');

  const navigate = useNavigate();

  const { deleteExpenses, isDeletingExpenses } = useDeleteExpenses({
    onSuccess: numberOfExpensesDeleted => {
      const checkedList = [...checked];

      setChecked([]);

      // Deletou somente uma despesa
      // Se deletar e tiver mais de uma despesa tira ela da lista
      if (
        expenseActionModalActive.expenseIndex !== -1 ||
        checkedList.length === 1
      ) {
        // Se tiver mais de uma despesa remove ela da lista
        if (paginatedExpenses?.data && paginatedExpenses?.data.length > 1) {
          queryClient.setQueryData<IExpensePaginatedResponse>(
            [ExpensesQueryKeys.GET_PAGINATED_EXPENSES, debouncedFilters],
            oldData => {
              if (!oldData) return oldData;

              // Se tiver só uma despesa selecionada e for deleção em massa checked - 1
              const index =
                checkedList.length === 1 &&
                expenseActionModalActive.expenseIndex === -1
                  ? checkedList[0]
                  : expenseActionModalActive.expenseIndex;

              const expenseToDelete = paginatedExpenses.data[index].uuid;

              const updatedExpenseData = oldData.data.filter(
                expense => expense.uuid !== expenseToDelete
              );
              queryClient.invalidateQueries({
                queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
              });

              return {
                ...oldData,
                data: updatedExpenseData
              };
            }
          );
        } else {
          // Nesse momento só deletamos um item por vez, ou seja se só tiver um item no data é por que deletamos o ultimo
          const deletedAllItemsFromPage =
            paginatedExpenses?.data.length === 1 && debouncedFilters.page !== 1;

          const newFilters = {
            ...filters,
            page: filters.page === 1 ? 1 : filters.page - 1
          };

          // Se a página não tiver mais itens atualiza os filtros
          deletedAllItemsFromPage && setFilters(newFilters);

          queryClient.invalidateQueries({
            queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES]
          });

          queryClient.invalidateQueries({
            queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
          });
        }

        toast.success(
          numberOfExpensesDeleted === 1
            ? lang.expenses.modal.delete_expense_with_success[currentLangKey]
            : lang.expenses.modal.delete_expenses_with_success[currentLangKey]
        );
      } else {
        // Se a quantidade de itens selecionados for igual ao total de itiens
        // E não tiver na primeira pagina e for uma deleção em massa, ele deletou todos os itens
        const deletedAllItemsFromPage =
          paginatedExpenses?.data.length === checkedList.length &&
          debouncedFilters.page !== 1 &&
          expenseActionModalActive.expenseIndex === -1;

        const newFilters = {
          ...filters,
          page:
            deletedAllItemsFromPage && filters.page !== 1
              ? filters.page - 1
              : filters.page
        };

        // Se a página não tiver mais itens atualiza os filtros
        deletedAllItemsFromPage && setFilters(newFilters);

        queryClient.invalidateQueries({
          queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES]
        });

        queryClient.invalidateQueries({
          queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
        });

        toast.success(
          numberOfExpensesDeleted === 1
            ? lang.expenses.modal.delete_expense_with_success[currentLangKey]
            : lang.expenses.modal.delete_expenses_with_success[currentLangKey]
        );
      }

      queryClient.removeQueries({
        queryKey: [ReportsQueryKeys.GET_OPEN_REPORTS]
      });

      handleCloseModal();
    },
    onError: e => {
      const message =
        ErrorHandle.getErrorMessage(undefined, e) ??
        t('modal.errorWhenDeletingExpense');

      handleChangeErrorMessage({
        title: t('modal.errorWhenDeletingExpense'),
        message
      });
    }
  });

  function handleChangeFilters<T extends keyof IFilterAction>(
    type: T,
    value: IFilterAction[T]
  ): void {
    setChecked([]);

    switch (type) {
      case 'startDate':
        setFilters(oldState => ({
          ...oldState,
          startDate: value as string
        }));
        return;

      case 'endDate':
        setFilters(oldState => ({
          ...oldState,
          endDate: value as string
        }));
        return;

      case 'page':
        setFilters(oldState => ({
          ...oldState,
          page: value as number
        }));
        return;

      case 'perPage':
        setFilters(oldState => ({
          ...oldState,
          perPage: value as number,
          page: 1
        }));
        return;

      case 'refundable':
        setFilters(oldState => ({
          ...oldState,
          refundable: value as string[]
        }));
        return;

      case 'search':
        setFilters(oldState => ({
          ...oldState,
          search: value as string
        }));
        return;

      case 'paymentMethod':
        setFilters(oldState => ({
          ...oldState,
          paymentMethod: value as string
        }));
        return;

      case 'statusTab':
        setFilters(oldState => ({
          ...oldState,
          page: 1,
          statusTab: value as StatusTabType
        }));
        return;

      case 'typeOfExpense':
        setFilters(oldState => ({
          ...oldState,
          typeOfExpense: value as string
        }));
        return;

      case 'orderBy':
        setFilters(oldState => ({
          ...oldState,
          order:
            value !== oldState.orderBy
              ? 'asc'
              : oldState.order === 'asc'
                ? 'desc'
                : 'asc',
          orderBy: value as ExpenseOrderByType
        }));
        return;

      case 'clear':
        setFilters({
          endDate: undefined,
          startDate: undefined,
          refundable: [],
          page: 1,
          perPage: 10,
          search: '',
          typeOfExpense: '',
          statusTab: 'all',
          paymentMethod: '',
          orderBy: 'date',
          order: 'desc'
        });
    }
  }

  function handleCheckAllCheckbox(): void {
    if (checked.length !== paginatedExpenses?.data.length) {
      const length = paginatedExpenses?.data.length ?? 0;
      setChecked(
        checked.length === length ? [] : Array.from({ length }, (v, k) => k)
      );
      return;
    }

    setChecked([]);
  }

  function handleChangeCheckbox(row: number): void {
    if (checked.includes(row)) {
      setChecked(prevState => prevState.filter(item => item !== row));
      return;
    }

    setChecked(prevState => [...prevState, row]);
  }

  function handleCancelActionExpense(type: ExpenseActionModalType): void {
    switch (type) {
      case 'deleteExpense':
        handleCloseModal();
        break;

      case 'deleteExpenseFromModal':
        handleCloseModal();
        handleOpenModal('viewExpensesModal');
        break;

      case 'deleteExpenses':
        handleCloseModal();
        break;
    }
  }

  function handleDeleteExpenses(): void {
    if (isDeletingExpenses) return;

    // Delete só uma despesa
    const index = expenseActionModalActive.expenseIndex;
    const expenseId =
      index === -1 ? undefined : paginatedExpenses?.data[index].uuid;

    if (index !== -1 && expenseId) {
      deleteExpenses({ expenses: [expenseId] });

      return;
    }

    const selectedExpensesFiltered = paginatedExpenses?.data
      ? checked.map(index => paginatedExpenses?.data[index].uuid)
      : [];

    deleteExpenses({ expenses: selectedExpensesFiltered });
  }

  function handleDeleteExpensesFromModal(expenseId: string): void {
    deleteExpenses({ expenses: [expenseId] });
  }

  function handleConfirmActionExpense(type: ExpenseActionModalType): void {
    switch (type) {
      case 'deleteExpense':
        handleDeleteExpenses();
        break;

      case 'deleteExpenses':
        handleDeleteExpenses();
        break;
    }
  }

  const expensesFiltersToApiValue: Record<
    StatusTabType,
    StatusTabTypePersistenceType | undefined
  > = {
    all: undefined,
    single: 'avulso',
    open: 'aberto',
    reproved: 'reprovado',
    sent: 'enviado',
    paid: 'pago',
    approved: 'aprovado',
    reopened: 'reaberto'
  };

  const debouncedOrder = useDebounce(filters.order);
  const debouncedOrderBy = useDebounce(filters.orderBy);
  const debouncedPaymentMethod = useDebounce(filters.paymentMethod);
  const debouncedRefundable = useDebounce(filters.refundable, 700);
  const debouncedSearch = useDebounce(filters.search, 1000);
  const debouncedTypeOfExpense = useDebounce(filters.typeOfExpense);

  const debouncedFilters: IUseGetPaginatedExpensesParams =
    CustomObject.exchangeNullValues({
      ...filters,
      statusTab: expensesFiltersToApiValue[filters.statusTab],
      startDate: filters.startDate,
      endDate: filters.endDate,
      page: filters.page,
      perPage: filters.perPage,
      order: debouncedOrder,
      orderBy: debouncedOrderBy,
      paymentMethod:
        debouncedPaymentMethod === 'ALL' ? '' : debouncedPaymentMethod,
      refundable: debouncedRefundable,
      search: debouncedSearch,
      typeOfExpense:
        debouncedTypeOfExpense === 'ALL' ? '' : debouncedTypeOfExpense
    });

  const {
    isFetchingPaginatedExpenses,
    isLoadingPaginatedExpenses,
    paginatedExpenses
  } = useGetPaginatedExpenses(debouncedFilters);

  const canAttatch = checked.some(index => {
    if (!paginatedExpenses?.data[index]?.report) {
      return false;
    }

    if (
      paginatedExpenses.data[index]?.report?.status === 'ABERTO' ||
      paginatedExpenses.data[index]?.report?.status === 'REABERTO'
    )
      return false;

    return true;
  });

  const checkedExpensesHasSameCoin =
    checked.length > 0 &&
    checked.every(index => !!paginatedExpenses?.data[index]) &&
    !checked
      .map(index => paginatedExpenses?.data[index].currency)
      .every((val, i, arr) => val === arr[0]);

  const canAttachExpense = checked.length > 0 ? canAttatch : true;

  const canDelete = checked.some(
    index => !paginatedExpenses?.data[index]?.canDelete
  );

  const canDeleteExpense = checked.length > 0 ? canDelete : true;

  const showAlert =
    (canAttachExpense || canDeleteExpense) && checked.length > 0;

  const expensesActionModalData: ExpenseActionModalDataType = {
    deleteExpenseFromModal: {
      children:
        lang.expenses.modal.action_modal.delete_single_description[
          currentLangKey
        ],
      title:
        lang.expenses.modal.action_modal.delete_single_title[currentLangKey]
    },
    deleteExpense: {
      children:
        lang.expenses.modal.action_modal.delete_single_description[
          currentLangKey
        ],
      title:
        lang.expenses.modal.action_modal.delete_single_title[currentLangKey]
    },
    deleteExpenses: {
      children:
        lang.expenses.modal.action_modal.delete_multiple_description[
          currentLangKey
        ],
      title:
        lang.expenses.modal.action_modal.delete_multiple_title[currentLangKey]
    }
  };

  const { numberOfExpensesByStatus, isLoadingNumberOfExpensesByStatus } =
    useGetNumberOfExpensesByStatus(debouncedFilters);

  const selectedExpenses = useMemo(() => {
    return paginatedExpenses?.data
      ? checked.map(index => paginatedExpenses?.data[index])
      : [];
  }, [checked, paginatedExpenses]);

  const isAllCheckboxChecked =
    paginatedExpenses?.data.length === checked.length && checked.length > 0;

  const { startTour } = useExpensesPageTour({
    hasExpenses: (paginatedExpenses?.data?.length ?? 0) > 0,
    hasExpenseWithReceiptImg:
      paginatedExpenses?.data?.some(
        expense => !!expense.attachment && expense.attachment.type === 'IMG'
      ) || false
  });

  const showExpensesPageModalTour =
    localStorage.getItem(localStorageKeys.expensesPageTour) !== 'true';

  useEffect(() => {
    if (
      showExpensesPageModalTour ||
      reset === localStorageKeys.expensesPageTour
    ) {
      handleOpenModal('expensesPageNews');
    }
  }, [showExpensesPageModalTour, handleOpenModal, reset]);

  function handleStartTour(): void {
    handleCloseModal();
    startTour();
  }

  function handleCreateExpense(type: CreateExpenseType): void {
    if (type === 'createManualExpense') {
      FreddySurvey.addScriptToDocumentHead(
        'c14ad28f-8b59-4043-ae57-2c74d2b9a49e'
      );
    }

    if (type === 'createRouteByMap') {
      FreddySurvey.addScriptToDocumentHead(
        '661843fd-3355-4364-964e-5a96b5d1ba0c'
      );
    }

    if (type === 'createManualRouteExpense') {
      FreddySurvey.addScriptToDocumentHead(
        '65c9cdf8-dba7-4b42-9c13-a2271d5f7459'
      );
    }

    setExpenseIndex(-1);
    handleOpenModal(type);
  }

  async function handleUpdateExpense(uuid: string): Promise<void> {
    const expenseInList = paginatedExpenses?.data[expenseIndex];

    if (expenseIndex >= 0 && expenseInList) {
      handleUpdateExpenseModal({
        routeType: expenseInList.routeType,
        type: expenseInList.type,
        typeId: expenseInList.typeId
      });
      return;
    }

    const expenseOutOfList = await getEnsuredExpenseDetails({
      expenseUuid: uuid
    });

    // Garante que vai ser o uuid que vai dar update na rota pro backend
    setExpenseUuidOrIdToUpdate(expenseOutOfList.uuid);

    handleUpdateExpenseModal({
      routeType: expenseOutOfList.routeType,
      type: expenseOutOfList.type?.description as string,
      typeId: Number(expenseOutOfList.type?.id)
    });
  }

  function handleUpdateExpenseModal({
    routeType,
    type,
    typeId
  }: ExpenseType): void {
    // se for percurso manual ou percurso do tipo GPS (mobile), abre o modal de percurso manual
    if (
      type === 'Percurso' &&
      (routeType === 'TRACKING' || routeType === null)
    ) {
      handleOpenModal('createManualRouteExpense');
      return;
    }

    // se for percurso do tipo mapa, abre o modal de percurso por mapa
    if (routeType === 'MAPA') {
      handleOpenModal('createRouteByMap');
      return;
    }

    // se o ID do tipo de combustível bater e utilizar o módulo de combustíveis, abre o modal de despesa de combustível
    if (
      !!company?.fuelingTypeId &&
      typeId === company?.fuelingTypeId &&
      company?.parameters.usesFuelsModule
    ) {
      handleOpenModal('createFuelExpense');
      return;
    }

    // se for despesa manual, abre o modal de despesa manual
    handleOpenModal('createManualExpense');
  }

  useEffect(() => {
    if (user?.parameters.shouldAnswerRecurringNps === 'NPS_VEX') {
      handleOpenModal('npsRecurringSurvey');
    }

    if (user?.parameters.shouldAnswerRecurringNps === 'NPS_CARDS') {
      handleOpenModal('npsRecurringSurveyCards');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.parameters.shouldAnswerRecurringNps]);

  function handleClickGoToClassicPage(): void {
    CustomCookies.set(cookiesKeys.redesignExpensesOldVersion, 'true');

    sendGaEvent('expenses', 'voltar_para_versao_antiga');

    window.location.href = `${vexDomain}/despesas`;
  }

  const showAlertToGoToClassicPage =
    company?.parameters.isExpensesOldVersionAllowed ?? false;

  useEffect(() => {
    const expenseHash = window.location.hash;

    if (
      !isLoadingNumberOfExpensesByStatus &&
      !isLoadingPaginatedExpenses &&
      id &&
      pattern.test(id)
    ) {
      if (expenseHash && expenseHash === '#modal-despesa') {
        setExpenseUuidOrIdToUpdate(id);
        handleUpdateExpense(id);
        navigate(`${routesPathPrefix.expenses}`);
        return;
      }

      const index =
        paginatedExpenses?.data.findIndex(item => item.id === parseInt(id)) ??
        -1;

      index >= 0 && setExpenseIndex(index);
      handleOpenModal('viewExpensesModal');
    }

    if (id && !pattern.test(id)) {
      toast.error(t('modal.expenseNotFound'));
      navigate(`${routesPathPrefix.expenses}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isLoadingPaginatedExpenses, isLoadingNumberOfExpensesByStatus]);

  return {
    paginatedExpenses,
    isLoadingPaginatedExpenses:
      isFetchingPaginatedExpenses || isLoadingPaginatedExpenses,
    expenseIndex,
    setExpenseIndex,
    filters,
    handleChangeFilters,
    handleChangeCheckbox,
    checked,
    canAttachExpense,
    canDeleteExpense,
    showAlert,
    expenseActionModalActive,
    setExpenseActionModalActive,
    expensesActionModalData,
    handleCancelActionExpense,
    handleConfirmActionExpense,
    numberOfExpensesByStatus,
    isLoadingNumberOfExpensesByStatus,
    handleClickGoToClassicPage,
    setChecked,
    handleCheckAllCheckbox,
    isAllCheckboxChecked,
    selectedExpenses,
    showExpensesPageModalTour,
    handleStartTour,
    handleDeleteExpensesFromModal,
    handleUpdateExpense,
    handleCreateExpense,
    isDeletingExpenses,
    checkedExpensesHasSameCoin,
    showAlertToGoToClassicPage,
    expenseUuidOrIdToUpdate,
    setExpenseUuidOrIdToUpdate
  };
}
