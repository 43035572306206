import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type ReceiptViewerStylesType } from './ReceiptViewer.types';

export const ClickableContainer = styled.button`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 9px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: zoom-in;
  margin: 1rem 0;

  &:hover {
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: rgb(0 0 0 / 10%);
    }
  }

  > span {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0.6rem;
    background: ${({ theme }) => theme.colors.mercury};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      transform: rotate(45deg);
    }
  }
`;

export const ClickablePdf = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 9px;
  width: 12rem;
  height: 12rem;
  background: ${({ theme }) => theme.colors.mercury};
  cursor: pointer;

  &:hover {
    background: rgb(0 0 0 / 10%);
  }
`;

export const ImagePreview = styled.img<
  AddPrefixToType<ReceiptViewerStylesType>
>`
  width: ${({ width }) => width}rem;
  height: ${({ height }) => height}rem;
`;
