import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  useAuthContext,
  useCardGroupsContext,
  useModalContext
} from 'data/contexts';
import { AuthQueryKeys, type ICardsUser } from 'data/modules/auth';
import {
  CardGroupsQueryKeys,
  type ICreateGroupCardPayload,
  useCreateCardGroup,
  useInfiniteAvailableToBeManager,
  useInfiniteUsersWithoutCardGroup
} from 'data/modules/cards/cardGroups';

import { useConfirmAttachOfCardsOnGroup } from 'presentation/pages/cards/Management/CardGroups/hooks';

import { useDebounce } from 'shared/hooks/global';

import { type IUseCreateCardGroupsModal } from './CreateCardGroupsModal.types';

export function useCreateCardGroupsModal(): IUseCreateCardGroupsModal {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const user = useAuthContext(state => state.user);

  const [
    cardGroupToBeCreated,
    handleCreateCardGroup,
    currentStepOfGroupCreation,
    handleChangeStepOfGroupCreation,
    paginationUsers,
    selectedUsersWithoutCardGroup,
    paginationManagers,
    selectedAvailableToBeManager,
    handleResetAfterCardGroupCreated,
    { managers, name },
    page,
    limit,
    ordenationType,
    handleSetIsInCardGroupDetails
  ] = useCardGroupsContext(
    useShallow(state => [
      state.cardGroupToBeCreated,
      state.handleCreateCardGroup,
      state.currentStepOfGroupCreation,
      state.handleChangeStepOfGroupCreation,
      state.paginationUsers,
      state.selectedUsersWithoutCardGroup,
      state.paginationManagers,
      state.selectedAvailableToBeManager,
      state.handleResetAfterCardGroupCreated,
      state.filters,
      state.pagination.page,
      state.pagination.limit,
      state.ordenationType,
      state.handleSetIsInCardGroupDetails
    ])
  );

  const { handleCloseModal } = useModalContext();

  const debouncedSearchUser = useDebounce(paginationUsers.userName);

  const debouncedSearchManager = useDebounce(paginationManagers.userName, 650);

  const {
    infiniteUsersWithoutCardGroup,
    isLoadingInfiniteUsersWithoutCardGroup,
    usersWithoutCardGroupPagination
  } = useInfiniteUsersWithoutCardGroup({
    params: {
      page: paginationUsers.page,
      limit: paginationUsers.limit,
      ...(debouncedSearchUser && {
        userName: debouncedSearchUser?.toLowerCase().trim()
      })
    },
    enabled: currentStepOfGroupCreation === 5
  });

  const {
    infiniteAvailableToBeManager,
    isLoadingInfiniteAvailableToBeManager,
    availableToBeManagerPagination
  } = useInfiniteAvailableToBeManager({
    params: {
      page: paginationManagers.page,
      limit: paginationManagers.limit,
      ...(debouncedSearchManager && {
        userName: debouncedSearchManager?.toLowerCase().trim()
      })
    },
    enabled: currentStepOfGroupCreation === 6
  });

  const { createCardGroup, isCreatingCardGroupName } = useCreateCardGroup();

  const { handleVerifyCardsAttach, isCheckingIfCardsCanBeAdded } =
    useConfirmAttachOfCardsOnGroup();

  async function handleSubmit(payload: ICreateGroupCardPayload): Promise<void> {
    try {
      await createCardGroup({ ...payload, name: payload.name.trim() });

      handleResetAfterCardGroupCreated();
      handleCloseModal();

      queryClient.invalidateQueries({
        queryKey: [
          CardGroupsQueryKeys.GET_CARD_GROUPS,
          {
            page,
            limit,
            ordenationType,
            ...(managers && { managers }),
            ...(name && { name })
          }
        ]
      });

      queryClient.resetQueries({
        queryKey: [CardGroupsQueryKeys.GET_USERS_WITHOUT_CARD_GROUP]
      });

      queryClient.resetQueries({
        queryKey: [CardGroupsQueryKeys.GET_AVAILABLE_TO_BE_MANAGER]
      });

      if (!user?.cards?.isCardGroupManager) {
        queryClient.setQueryData([AuthQueryKeys.AUTH_CARDS_USER], authUser =>
          authUser
            ? {
                ...authUser,
                isCardGroupManager: true
              }
            : authUser
        ) as ICardsUser;
      }

      navigate('/cartoes/grupos-de-cartoes');
    } catch (error) {
      toast.error((error as Error).message);
    }
  }

  async function handleControlCreateGroup(): Promise<void> {
    switch (currentStepOfGroupCreation) {
      case 4:
        handleChangeStepOfGroupCreation('next');
        break;
      case 5:
        if (selectedUsersWithoutCardGroup.length > 0 && cardGroupToBeCreated) {
          handleCreateCardGroup({
            groupName: cardGroupToBeCreated.groupName,
            users: selectedUsersWithoutCardGroup
          });

          handleVerifyCardsAttach();
          handleSetIsInCardGroupDetails(false);
          return;
        }

        if (selectedAvailableToBeManager.length === 0 && cardGroupToBeCreated) {
          handleCreateCardGroup({
            groupName: cardGroupToBeCreated.groupName,
            users: selectedUsersWithoutCardGroup
          });

          handleChangeStepOfGroupCreation('next');
        }
        break;
      case 6:
        if (cardGroupToBeCreated) {
          handleSubmit({
            name: cardGroupToBeCreated.groupName,
            ...(cardGroupToBeCreated.users && {
              users: cardGroupToBeCreated.users
            }),
            ...(selectedAvailableToBeManager && {
              managers: selectedAvailableToBeManager
            })
          });
        }
        break;
    }
  }

  return {
    handleControlCreateGroup,
    isFetchingUsersWithoutCardGroup: isLoadingInfiniteUsersWithoutCardGroup,
    paginatedUsersWithoutCardGroup: infiniteUsersWithoutCardGroup,
    paginatedAvailableToBeManager: infiniteAvailableToBeManager,
    isFetchingAvailableToBeManager: isLoadingInfiniteAvailableToBeManager,
    isCreatingCardGroupName:
      isCreatingCardGroupName || isCheckingIfCardsCanBeAdded,

    availableToBeManagerPagination,
    usersWithoutCardGroupPagination
  };
}
