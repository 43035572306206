import { tokens } from 'ds';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  padding: 2.5rem 1.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: ${tokens.spacingInsetLg};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 1.6rem;
  padding: ${tokens.spacingXs} ${tokens.spacingXxs};
  border-radius: 16px;
  background-color: ${tokens.neutralColorHighLight};
  margin-bottom: 3.6rem;

  table {
    min-width: 100rem;
  }

  tr {
    height: 6.8rem;
  }

  td {
    &:last-child {
      cursor: pointer;
    }

    &:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const TableContainer = styled.div`
  max-width: 100%;
  overflow: auto;
`;
