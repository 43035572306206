import { Mask } from 'ds';
import { useDsGa4 } from 'ds/hooks/globals';

import { usePasswordInputDefault } from './usePasswordInputDefault';

import { type IPasswordInputDefaultProps } from './PasswordInputDefault.types';

import {
  Caption,
  ClearButton,
  Container,
  Fieldset,
  InputComponent,
  InputContainer,
  Label,
  Legend,
  Wrapper
} from './PasswordInputDefault.styles';

export function PasswordInputDefault({
  name,
  errorMessage,
  defaultValue,
  label,
  disabled,
  large,
  small = true,
  mask,
  value,
  readOnly,
  onChangeValue,
  onClearValue,
  inputContainerAttributes,
  ...rest
}: IPasswordInputDefaultProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  const { IconRight, handleTogglePasswordVisibility, inputType } =
    usePasswordInputDefault();

  const IconClear = onClearValue && value.length > 0 ? ClearButton : null;

  return (
    <Wrapper>
      <Container
        $large={large}
        $small={small}
        $disabled={disabled || readOnly}
      >
        <InputContainer
          data-testid='container'
          $disabled={disabled}
          $hasError={!!errorMessage}
          $large={large}
          $small={small}
          $isTouched={!!value}
          {...inputContainerAttributes}
        >
          <Label>{label}</Label>
          <div>
            <InputComponent
              {...rest}
              readOnly={readOnly}
              defaultValue={defaultValue}
              disabled={disabled}
              value={value}
              name={name}
              type={inputType}
              onChange={event => {
                if (onChangeValue)
                  onChangeValue(
                    mask
                      ? Mask.apply(mask, event.target.value)
                      : event.target.value
                  );
              }}
              onBlur={event => {
                event.target.value.trim() &&
                  sendDsGaEvent('components', 'input', {
                    eventLabel: label,
                    eventValue: event.target.value
                  });
              }}
            />
          </div>

          {IconClear && <IconClear onClick={onClearValue} />}

          <IconRight
            onClick={() => {
              if (disabled) return;
              handleTogglePasswordVisibility();
            }}
          />
        </InputContainer>

        <Fieldset $hasLabel={!!label}>
          <Legend>
            <span>{label}</span>
          </Legend>
        </Fieldset>
      </Container>

      {!!errorMessage && <Caption $hasError>{errorMessage}</Caption>}
    </Wrapper>
  );
}
