import { DefaultButton, Modal } from 'ds';

import { useLangContext, useModalContext } from 'data/contexts';

import { Container, Content, Footer, Header } from './ErrorModal.styles';

export function ErrorModal(): JSX.Element {
  const { handleChangeErrorMessage, errorMessage } = useModalContext();
  const { currentLangKey, lang } = useLangContext();

  return (
    <Modal.Container
      variant='small'
      onClose={() => {
        handleChangeErrorMessage(undefined);
      }}
    >
      <Header>
        <Modal.Header id='header'>
          <Modal.Title>{errorMessage?.title}</Modal.Title>

          <Modal.IconClose
            onClick={() => {
              handleChangeErrorMessage(undefined);
            }}
          />
        </Modal.Header>
      </Header>

      <Container>
        <Content>{errorMessage?.message}</Content>

        <Footer>
          <DefaultButton
            size='small'
            onClick={() => {
              handleChangeErrorMessage(undefined);
            }}
          >
            {lang.global.close[currentLangKey]}
          </DefaultButton>
        </Footer>
      </Container>
    </Modal.Container>
  );
}
