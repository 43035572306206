import { useTranslation } from 'react-i18next';

import { useAuthContext, useModalContext } from 'data/contexts';

import { Spinner } from 'presentation/components/base/Spinner';

import { Currency } from 'shared/utils/format';

import { type IGroupCardProps } from './GroupCard.types';

import {
  CardHeader,
  Container,
  EditIcon,
  SettingsIcon,
  SkeletonContainer,
  Text,
  Title,
  Value
} from './GroupCard.styles';

export function GroupCard({
  groupInfo,
  isLoading = false,
  isDetailsCard = false,
  ...rest
}: IGroupCardProps): JSX.Element {
  const { t } = useTranslation('cards');
  const { handleOpenModal } = useModalContext();

  const user = useAuthContext(state => state.user);

  if (
    isLoading ||
    (!groupInfo.availableValue && groupInfo.availableValue !== 0)
  ) {
    return (
      <SkeletonContainer {...rest}>
        <Spinner
          size={4}
          color='adamantineBlue'
        />
      </SkeletonContainer>
    );
  }

  return (
    <Container
      $isDetailsCard={isDetailsCard}
      {...rest}
    >
      <CardHeader>
        <SettingsIcon className={isDetailsCard ? `settingsIcon` : ''} />

        <Title
          title={groupInfo.name}
          $isDetailsCard={isDetailsCard}
        >
          {groupInfo.name}
        </Title>

        {isDetailsCard && user?.cards?.type === 'ACCOUNT_MANAGER' && (
          <EditIcon
            onClick={() => {
              handleOpenModal('editCardGroupName');
            }}
          />
        )}
      </CardHeader>

      {/* TODO: COMENTÁRIO ADICIONADO TEMPORARIAMENTE, LÓGICA SERÁ FEITA EM TASKS FUTURAS */}

      {/* <Text title={groupInfo.managers.map(manager => manager.name).join(', ')}>
        {groupInfo.managers.map(manager => manager.name).join(', ')}
      </Text> */}

      <Value>{Currency.format('BRL', groupInfo.availableValue, true)}</Value>

      <Text>{t('cardGroupAvailableValue')}</Text>
    </Container>
  );
}
