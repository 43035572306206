import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IChangeAccountBalanceAmountFieldsForm } from 'data/modules/cards/account';

import { Currency } from 'shared/utils/format';

export function useChangeAccountBalanceAmountSchema(): ObjectSchema<IChangeAccountBalanceAmountFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const changeAccountBalanceAmountSchema: ObjectSchema<IChangeAccountBalanceAmountFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object({
        currency: yup.string().required(fieldRequiredMessage),
        description: yup
          .string()
          .nullable()
          .defined()
          .transform(value => {
            return value === '' ? null : value;
          }),
        amount: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'amount',
            message: lang.schemas.different_from_zero[currentLangKey],
            test: value => {
              return Currency.parseToFloat(value) > 0;
            }
          })
      });
    }, [lang, currentLangKey]);

  return changeAccountBalanceAmountSchema;
}
