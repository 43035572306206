import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.tokens.spacingXs};
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighLight};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusMd};
`;

export const NoTransactionsMessage = styled.p`
  color: ${tokens.neutralColorLowMedium};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamiliesPoppins};
  letter-spacing: 0.75px;
`;

export const CardTransactionItem = styled.li`
  display: flex;
  justify-content: space-between;
`;

export const TitleAndCardNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ValueAndDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

export const TransactionTitle = styled.h2`
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  font-weight: ${({ theme }) => theme.tokens.fontWeightSemibold};
  color: ${({ theme }) => theme.colors.outerSpace};
  letter-spacing: 0.075rem;
  line-height: 2.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40rem;
  white-space: nowrap;
`;

export const Value = styled.span`
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  font-weight: ${({ theme }) => theme.tokens.fontWeightSemibold};
  color: ${({ theme }) => theme.colors.outerSpace};
  letter-spacing: 0.075rem;
  line-height: 2.4rem;
`;

export const StyledCardNumber = styled.p`
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  color: ${({ theme }) => theme.colors.neutralColorLowMedium};
  letter-spacing: 0.025rem;
  line-height: 2.2rem;
`;

export const Date = styled.p`
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  color: #444;
  letter-spacing: 0.025rem;
  line-height: 2.2rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0;
  border: 0.9px solid ${({ theme }) => theme.colors.neutralColorHighMedium};

  &:last-child {
    display: none;
  }
`;
