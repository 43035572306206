import { type IModalContentProps } from './ModalContent.types';

import { Container } from './ModalContent.styles';

export function ModalContent({
  children,
  ...rest
}: IModalContentProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
