import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type LoadMoreButtonStyleType } from './NotificationList.types';

export const Container = styled.ul`
  display: flex;
  width: 46.4rem;
  overflow: auto;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.spacingNano};
  margin: ${tokens.spacingNano} 0;
  margin-left: ${tokens.spacingNano};
  padding-right: ${tokens.spacingNano};
  border-radius: ${tokens.borderRadiusXs};

  &::-webkit-scrollbar {
    width: 1.2rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 12px;
    background: ${tokens.neutralColorHighDark};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px !important;
    background-color: ${tokens.neutralColorLowLightest};
  }

  /* Aplicar scrollbar-gutter: stable; apenas para Firefox */
  @supports (-moz-appearance: none) {
    scrollbar-color: ${tokens.neutralColorLowLightest} transparent;
    scrollbar-gutter: stable;
  }

  @media screen and (max-width: 800px) {
    width: auto;
  }
`;

export const LoadMoreButton = styled.button<
  AddPrefixToType<LoadMoreButtonStyleType>
>`
  border: none;
  display: flex;
  text-align: center;
  font-style: normal;
  align-items: center;
  align-self: stretch;
  line-height: 2.4rem;
  pointer-events: none;
  letter-spacing: 0.75px;
  justify-content: center;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.brandColorPrimaryPure};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightSemibold};
  padding: ${tokens.spacingXs} ${tokens.spacingMd};

  ${({ $loadingStatus }) =>
    $loadingStatus === 'default'
      ? css`
          pointer-events: unset;

          &:hover {
            color: ${tokens.brandColorPrimaryDark};
          }

          &:active {
            color: ${tokens.brandColorSecondaryDark};
          }
        `
      : css`
          font-weight: ${tokens.fontWeightMedium};
          color: ${tokens.neutralColorLowMediumLight};
        `}
`;
