import { useTranslation } from 'react-i18next';

import { type IAnalysisItemSectionNameProps } from './AnalysisItemSectionName.types';

import {
  Container,
  Name,
  PositionDepartment
} from './AnalysisItemSectionName.styles';

export function AnalysisItemSectionName({
  name,
  positionDepartment,
  ...props
}: IAnalysisItemSectionNameProps): JSX.Element {
  const { t } = useTranslation('global');

  return (
    <Container {...props}>
      <Name>{name}</Name>
      <PositionDepartment>
        {`${t('position')}/${t('department')}: ${positionDepartment && positionDepartment !== '' ? positionDepartment : '-'}`}
      </PositionDepartment>
    </Container>
  );
}
