import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type MovementButtonStylesType } from './MovementFilter.types';

export const Container = styled.div`
  > p {
    display: block;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors.mineShaft};
    font-size: 1.3rem;
    font-weight: 500;
  }
`;

export const MovementButton = styled.button<
  AddPrefixToType<MovementButtonStylesType>
>`
  width: 8.3rem;
  margin: 0.7rem 1rem;
  padding: 0.5rem 0;
  background: none;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral};
  color: ${({ theme }) => theme.colors.neutral};
  font-size: 1.2rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ $selected }) =>
    $selected &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 700;
    `}
`;
