import { BasicBarChart } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useAnalysisUserFiltersContext } from 'data/contexts';

import { SkeletonLoader } from 'presentation/components/global/Loader';

import { useUserAnalysisBiggestSpendingChart } from './useUserAnalysisBiggestSpendingChart';

import {
  ChartSubtitle,
  ChartTitle,
  ChartWrapper,
  Container,
  SkeletonContainer
} from './UserAnalysisBiggestSpendingChart.styles';

export function UserAnalysisBiggestSpendingChart(): JSX.Element {
  const [isEmptyResults] = useAnalysisUserFiltersContext(
    useShallow(state => [state.isEmptyResults])
  );

  const { title, subtitle, isLoadingChart, chartProps } =
    useUserAnalysisBiggestSpendingChart();

  return isLoadingChart ? (
    <SkeletonContainer>
      <SkeletonLoader width='35rem' />
      <SkeletonLoader width='25rem' />
      <SkeletonLoader height='20rem' />
    </SkeletonContainer>
  ) : (
    <>
      {!isEmptyResults && (
        <Container>
          <ChartTitle>{title}</ChartTitle>
          <ChartSubtitle>{subtitle}</ChartSubtitle>

          <ChartWrapper>
            <BasicBarChart {...chartProps} />
          </ChartWrapper>
        </Container>
      )}
    </>
  );
}
