import { useCallback, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useAnalysisUserFiltersContext, useAuthContext } from 'data/contexts';
import {
  type AnalysisExchangeKeyType,
  type IAnalysisUserResume,
  useGetAnalysisUserList
} from 'data/modules/analytics';

import { type IUserAnalysisItem } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisItem/UserAnalysisItem.types';
import { type IUseUserAnalysisList } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisList/UserAnalysisList.types';
import { useUserExpenses } from 'presentation/pages/analytics/Analysis/UserExpenses/useUserExpenses';

import { Currency } from 'shared/utils/format';

export function useUserAnalysisList(): IUseUserAnalysisList {
  const { t } = useTranslation(['analytics', 'global']);
  const company = useAuthContext(state => state.company);

  const { payload } = useUserExpenses();

  const [handleChangeIsListBottomReached, isListBottomReached] =
    useAnalysisUserFiltersContext(
      useShallow(state => [
        state.handleChangeIsListBottomReached,
        state.isListBottomReached
      ])
    );

  const {
    analysisUserList,
    isLoadingAnalysisUserList,
    hasNextPageAnalysisUserList,
    fetchAnalysisUserListNextPage,
    isFetchingAnalysisUserListNextPage
  } = useGetAnalysisUserList({
    ...payload,
    perPage: 2
  });

  useEffect(() => {
    if (isListBottomReached) {
      handleChangeIsListBottomReached(false);

      if (
        !hasNextPageAnalysisUserList ||
        isLoadingAnalysisUserList ||
        isFetchingAnalysisUserListNextPage
      )
        return;

      fetchAnalysisUserListNextPage();
    }
  }, [
    isListBottomReached,
    isLoadingAnalysisUserList,
    hasNextPageAnalysisUserList,
    fetchAnalysisUserListNextPage,
    isFetchingAnalysisUserListNextPage,
    handleChangeIsListBottomReached
  ]);

  const getCurrencyExchangeKey = useCallback(
    (hasPendingConversion: boolean) =>
      company?.parameters.usesExchangeToInsertExpenses !== null &&
      company?.parameters.usesExchangeToInsertExpenses !== 'NAO_UTILIZA'
        ? company?.parameters.usesCards
          ? 'convertedSumWithCard'
          : 'convertedSum'
        : hasPendingConversion
          ? 'hasPendingConversion'
          : null,
    [
      company?.parameters.usesCards,
      company?.parameters.usesExchangeToInsertExpenses
    ]
  );

  const getListCards = useCallback(
    (
      user: IAnalysisUserResume,
      currencyExchangeKey: AnalysisExchangeKeyType
    ): IUserAnalysisItem['cards'] => {
      return [
        {
          title: t('analytics:userResume.expensesSum'),
          value: Currency.format('BRL', user.total ?? 0, true),
          tooltip:
            currencyExchangeKey !== null
              ? t(`userSummaryCard.tooltips.${currencyExchangeKey}`)
              : undefined,
          showLeftIcon: currencyExchangeKey === 'hasPendingConversion'
        },
        {
          title: t('analytics:userResume.reimbursableAmount'),
          value: Currency.format('BRL', user.sumRefundable ?? 0, true),
          tooltip:
            currencyExchangeKey === 'hasPendingConversion'
              ? t('userSummaryCard.tooltips.unaccountedValues')
              : undefined,
          showLeftIcon: currencyExchangeKey === 'hasPendingConversion'
        },
        {
          title: t('analytics:userResume.nonReimbursableAmount'),
          value: Currency.format('BRL', user.sumNoRefundable ?? 0, true),
          tooltip:
            currencyExchangeKey === 'hasPendingConversion'
              ? t('userSummaryCard.tooltips.unaccountedValues')
              : undefined,
          showLeftIcon: currencyExchangeKey === 'hasPendingConversion'
        },
        {
          title: t('global:expenses_other'),
          value: String(user.totalExpenses ?? 0)
        },
        {
          title: t('analytics:userResume.averageExpenseAmount'),
          value: Currency.format('BRL', user.averagePerExpense ?? 0, true)
        }
      ];
    },
    [t]
  );

  const userAnalysisList: IUserAnalysisItem[] = useMemo(
    () =>
      analysisUserList.map(({ reports, ...userResume }) => {
        const currencyExchangeKey = getCurrencyExchangeKey(
          userResume.hasPendingConversion
        );

        return {
          reports,
          id: userResume.userId,
          name: userResume.name,
          positionDepartment: userResume.departmentPosition,
          cards: getListCards(userResume, currencyExchangeKey),
          hasPendingConversion: currencyExchangeKey === 'hasPendingConversion'
        };
      }),
    [analysisUserList, getCurrencyExchangeKey, getListCards]
  );

  return {
    userAnalysisList,
    isLoadingUserAnalysisList: isLoadingAnalysisUserList,
    isFetchingUserAnalysisListNextPage: isFetchingAnalysisUserListNextPage
  };
}
