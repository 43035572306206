import { format } from 'date-fns';

import { useMutationCache } from 'data/cache';
import {
  BalanceMutationKeys,
  BalanceService,
  type IRequestBalanceFieldsForm,
  type IRequestBalancePayload,
  type IUseRequestBalance,
  type IUseRequestBalanceParams
} from 'data/modules/cards/balance';

import { useFormWithSchema } from 'shared/hooks/forms';
import { Currency } from 'shared/utils/format';

import { useRequestBalanceSchema } from './schemas';

export function useRequestBalance({
  accountBalanceId,
  onAfterSuccessUpdateBalanceSolicitations
}: IUseRequestBalanceParams): IUseRequestBalance {
  const {
    mutate,
    mutateAsync,
    isPending,
    isLoading,
    isSuccess,
    reset: mutationReset
  } = useMutationCache({
    mutationKey: [BalanceMutationKeys.REQUEST_BALANCE],
    mutationFn: async (payload: IRequestBalancePayload) =>
      await BalanceService.requestBalance(payload)
  });

  const {
    control,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    clearErrors
  } = useFormWithSchema(useRequestBalanceSchema(), {
    defaultValues: {
      currency: 'BRL'
    }
  });

  function onSubmit(data: IRequestBalanceFieldsForm): void {
    mutate({
      justify: data.justify,
      amount: Currency.parseToFloat(data.amount),
      accountBalanceId,
      datePrevision: data.datePrevision
        ? format(data.datePrevision, 'yyyy-MM-dd')
        : null
    });
    onAfterSuccessUpdateBalanceSolicitations?.();
  }

  return {
    requestBalance: mutateAsync,
    isLoadingRequestingBalance: isPending,
    isRequestBalanceSuccess: isSuccess,
    handleRequestBalance: handleSubmit(onSubmit),
    isRequestingBalance: isLoading,
    mutationReset,
    setValue,
    control,
    errors,
    getValues,
    reset,
    clearErrors
  };
}
