import { BaseIcon } from 'presentation/components/base/Icon';

import { type IDsGhostButtonProps } from './DsGhostButton.types';

import { Container } from './DsGhostButton.styles';

export function DsGhostButton({
  text = '',
  size = 'medium',
  disabled = false,
  variant = 'semi-rounded',
  secondary = false,
  iconSide = 'left',
  isIconOnly = false,
  iconProps,
  ...rest
}: IDsGhostButtonProps): JSX.Element {
  if (isIconOnly) {
    return (
      <Container
        aria-label={text}
        aria-disabled={disabled}
        $size={size}
        disabled={disabled}
        $variant={variant}
        $secondary={secondary}
        {...rest}
      >
        {iconProps && isIconOnly && <BaseIcon {...iconProps} />}
      </Container>
    );
  }

  return (
    <Container
      aria-label={text}
      aria-disabled={disabled}
      $size={size}
      disabled={disabled}
      $variant={variant}
      $secondary={secondary}
      {...rest}
    >
      {iconProps && iconSide === 'left' && <BaseIcon {...iconProps} />}

      {text && <span>{text}</span>}

      {iconProps && iconSide === 'right' && <BaseIcon {...iconProps} />}
    </Container>
  );
}
