import { api, env } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import {
  getAccountHoldersMock,
  updateAccountHolderMock
} from 'data/mocks/cards/accountHolders';
import {
  type IAccountHolder,
  type IGetAccountHolders,
  type IGetAccountHoldersPayload,
  type IPersistenceAccountHolder,
  type IStoreAccountHolderPayload,
  type IUpdateAccountHolderPayload
} from 'data/modules/cards/accountHolders';
import { type IDefaultResponse, type IObjectsArray } from 'data/modules/global';

import { CustomObject } from 'shared/utils/custom';

import {
  AccountHolderMapper,
  StoreAccountHolderMapper,
  UpdateAccountHolderMapper
} from './mappers';

class AccountHoldersService {
  async getAccountHolders(
    payload: IGetAccountHoldersPayload
  ): Promise<IGetAccountHolders> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getAccountHoldersMock();
    }

    const response = await api.get<
      IDefaultResponse<IObjectsArray<IPersistenceAccountHolder>>
    >('/pay/account-holders', {
      params: payload
    });

    const { data, total } = ResponseAdapter.formatObjectsArrayResponse(
      response.data
    );

    return {
      accountHoldersPaginated: data?.map(AccountHolderMapper.toDomain) ?? [],
      total: total ?? 0
    };
  }

  async storeAccountHolder(payload: IStoreAccountHolderPayload): Promise<null> {
    const response = await api.post<IDefaultResponse<null>>(
      'pay/account-holders',
      StoreAccountHolderMapper.toPersistence(payload.accountHolder)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async updateAccountHolder(
    payload: IUpdateAccountHolderPayload
  ): Promise<IAccountHolder> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await updateAccountHolderMock();
    }

    const response = await api.patch<
      IDefaultResponse<IPersistenceAccountHolder>
    >(
      `pay/account-holders/${payload.accountHolder.id}`,
      UpdateAccountHolderMapper.toPersistence(
        CustomObject.omit(payload.accountHolder, ['id'])
      )
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return AccountHolderMapper.toDomain(data);
  }
}

export default new AccountHoldersService();
