import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IResetCardPinFieldsForm } from 'data/modules/cards/account';

import { Validate } from 'shared/utils/validation';

export function useResetCardPinSchema(): ObjectSchema<IResetCardPinFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const resetCardPinSchema: ObjectSchema<IResetCardPinFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object({
        password: yup
          .string()
          .required(fieldRequiredMessage)
          .min(4, `${lang.schemas.min[currentLangKey]} 4`)
          .max(4, `${lang.schemas.max[currentLangKey]} 4`)
          .test({
            name: 'password',
            message:
              lang.schemas.do_not_use_sequential_numbers_or_repetitions[
                currentLangKey
              ],
            test: Validate.isPinStrong
          }),
        confirmPassword: yup
          .string()
          .required(fieldRequiredMessage)
          .oneOf(
            [yup.ref('password')],
            lang.schemas.password_do_not_match[currentLangKey]
          )
      });
    }, [lang, currentLangKey]);

  return resetCardPinSchema;
}
