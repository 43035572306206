import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IEditCardGroupNamePayload } from 'data/modules/cards/cardGroups';

export function useEditCardGroupNameSchema(): ObjectSchema<
  Pick<IEditCardGroupNamePayload, 'name'>
> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const editCardGroupNameSchema: ObjectSchema<
    Pick<IEditCardGroupNamePayload, 'name'>
  > = useMemo(() => {
    const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

    return yup.object({
      name: yup.string().required(fieldRequiredMessage).trim()
    });
  }, [lang, currentLangKey]);

  return editCardGroupNameSchema;
}
