import { addDays, format, startOfDay } from 'date-fns';

import {
  type IAccountStatement,
  type IGetAccountStatementPayload,
  type IGetPersistenceAccountStatementPayload,
  type IPersistenceAccountStatement
} from 'data/modules/cards/statement';

import { AccountTransactionMapper } from '.';

class GetAccountStatementMapper {
  toDomain(
    persistenceAccountStatement: IPersistenceAccountStatement
  ): IAccountStatement {
    return {
      balance: persistenceAccountStatement.balance,
      date: persistenceAccountStatement.date,
      items: persistenceAccountStatement.items.map(
        AccountTransactionMapper.toDomain
      )
    };
  }

  toPersistence(
    payload: IGetAccountStatementPayload
  ): IGetPersistenceAccountStatementPayload {
    return {
      account_balance_id: payload.accountBalanceId,
      end_date: `${format(addDays(startOfDay(payload.endDate), 1), "yyyy-MM-dd'T'HH:mm:ss'Z'")}`,
      start_date: `${format(addDays(startOfDay(payload.startDate), 1), "yyyy-MM-dd'T'HH:mm:ss'Z'")}`,
      timezone: payload.timezone,
      card_group_id: payload.cardGroupId
    };
  }
}

export default new GetAccountStatementMapper();
