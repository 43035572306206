import { DSIcons, SecondaryButton } from 'ds';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Icons, routesPathPrefix } from 'shared/constants/global';

import { type IAnalysisHeaderProps } from './AnalysisHeader.types';

import { Container } from './AnalysisHeader.styles';

export function AnalysisHeader({
  onExportClick
}: IAnalysisHeaderProps): JSX.Element {
  const navigate = useNavigate();

  const { t } = useTranslation('global');

  return (
    <Container>
      <SecondaryButton
        size='small'
        onClick={() => {
          navigate(routesPathPrefix.support);
        }}
      >
        <Icons.QuestionCircleIcon />
        {t('help')}
      </SecondaryButton>
      <SecondaryButton
        onClick={onExportClick}
        size='small'
        disabled
      >
        <DSIcons.DownloadIcon />
        {t('export')}
      </SecondaryButton>
    </Container>
  );
}
