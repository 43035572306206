import { Modal, tokens } from 'ds';
import styled from 'styled-components';

import { MultipleInputs } from 'presentation/components/base/Input';

export const ModalDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  margin-bottom: ${tokens.spacingXs};

  > p {
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxs};
    line-height: ${tokens.spacingLg};
    letter-spacing: 0.075rem;
    font-weight: ${tokens.fontWeightRegular};
    color: ${tokens.neutralColorLowDark};
  }
`;

export const ModalForm = styled(Modal.Form)`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  flex-grow: 1;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  flex-grow: 1;
  overflow: auto;
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
  gap: ${tokens.spacingMd};

  button {
    width: 100%;
    white-space: nowrap;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    button {
      width: fit-content;
    }
  }
`;

export const AgencyAndAccountContainer = styled(MultipleInputs)`
  gap: ${tokens.spacingXs};
`;
