import { useEffect } from 'react';

import { toast } from 'ds/utils';
import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  useUpdateCompanyAddress
} from 'data/modules/cards/companyOnboarding';
import { useGetViaCepAddressByPostalCode } from 'data/modules/global';

import { countries, states } from 'shared/constants/global';
import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';
import { Mask } from 'shared/utils/format';

import {
  type IUpdateCacheAndNavigateNextStepParams,
  type IUseCompanyAddress
} from './CompanyAddress.types';

export function useCompanyAddress(): IUseCompanyAddress {
  const navigate = useNavigate();
  const [postalCodeNotFound, currentLangKey] = useLangContext(state => [
    state.lang.global.postal_code_not_found,
    state.currentLangKey
  ]);

  const {
    data: cachedCompanyOnboardingProgress,
    updateCache: updateCurrentStepCache
  } = useHandleQueryCache({
    query: {
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS],
      query: async () => await CompanyOnboardingService.getProgress()
    }
  });

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const { updateCache, data: cachedCompanyOnboardingAnswers } =
    useHandleQueryCache({
      query: {
        key: [
          CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
          cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
        ],
        query: async () =>
          await CompanyOnboardingService.getCompanyOnboardingAnswers({
            uuidCompanyOnboarding:
              cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
          })
      },
      params: {
        uuidCompanyOnboarding:
          cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      }
    });

  const { control, errors, handleUpdateCompanyAddress, setValue, watch } =
    useUpdateCompanyAddress({
      onUpdateCompanyOnboardingAnswersMutationSuccess:
        updateCacheAndNavigateNextStep
    });

  const postalCode = watch('postalCode');

  const isGetViaCepAddressEnabled =
    (postalCode?.length === 9 &&
      postalCode !==
        cachedCompanyOnboardingAnswers?.companyAccount?.postalCode) ??
    false;

  const { isFetchingAddress, address } = useGetViaCepAddressByPostalCode({
    postalCode: postalCode ?? '',
    enabled: isGetViaCepAddressEnabled
  });

  useEffect(() => {
    const country = watch('country');
    const foundState = states.find(state =>
      address
        ? state.value === address?.state
        : state.value === cachedCompanyOnboardingAnswers?.companyAccount?.state
    );

    const foundCountry = countries.find(state => state.value === country);

    if (address === null && isGetViaCepAddressEnabled) {
      toast.error(postalCodeNotFound[currentLangKey]);
    }

    if (address) {
      setValue('street', Mask.apply('letters', address.street), {
        shouldValidate: true
      });
      setValue(
        'complement',
        Mask.apply('alphanumericWithAccents', address.complement),
        {
          shouldValidate: true
        }
      );
      setValue('neighborhood', Mask.apply('letters', address.neighborhood), {
        shouldValidate: true
      });
      setValue('city', Mask.apply('letters', address.city), {
        shouldValidate: true
      });
    }

    if (foundState) {
      setValue('state', foundState as unknown as string, {
        shouldValidate: true
      });
    }

    if (foundCountry) {
      setValue('country', foundCountry as unknown as string, {
        shouldValidate: true
      });
    }
  }, [
    address,
    setValue,
    currentLangKey,
    postalCodeNotFound,
    watch,
    isGetViaCepAddressEnabled,
    cachedCompanyOnboardingAnswers
  ]);

  function updateCacheAndNavigateNextStep(
    newCompanyAddress: IUpdateCacheAndNavigateNextStepParams
  ): void {
    navigate(`/cartoes/onboarding/empresa/${currentStep + 1}`);

    updateCache({
      key: 'companyAccount',
      newData: newCompanyAddress
    });

    if (currentStep === Number(cachedCompanyOnboardingProgress?.currentStep)) {
      updateCurrentStepCache({
        key: 'currentStep',
        newData: currentStep + 1
      });
    }
  }

  return {
    control,
    errors,
    handleUpdateCompanyAddress,
    setValue,
    watch,
    isFetchingAddress
  };
}
