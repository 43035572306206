import { MenuTabs } from 'ds';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { type ICardGroupsOrSingleCardsTabsProps } from './CardGroupsOrSingleCardsTabs.types';

import { Container } from './CardGroupsOrSingleCardsTabs.styles';

export function CardGroupsOrSingleCardsTabs({
  selectedTab
}: ICardGroupsOrSingleCardsTabsProps): JSX.Element {
  const { t } = useTranslation('cards');

  const navigate = useNavigate();

  return (
    <Container hasTrack>
      <MenuTabs.Item
        size='small'
        active={selectedTab === 'CARD_GROUPS'}
        onClick={() => {
          selectedTab === 'SINGLE_CARDS' &&
            navigate('/cartoes/grupos-de-cartoes');
        }}
      >
        {t('cardGroups')}
      </MenuTabs.Item>
      <MenuTabs.Item
        size='small'
        active={selectedTab === 'SINGLE_CARDS'}
        onClick={() => {
          selectedTab === 'CARD_GROUPS' && navigate('/cartoes/gestao');
        }}
      >
        {t('singleCards')}
      </MenuTabs.Item>
    </Container>
  );
}
