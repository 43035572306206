import { type ICustomRadioItemDefaultTitleProps } from './CustomRadioItemDefaultTitle.types';

import { Container } from './CustomRadioItemDefaultTitle.styles';

export function CustomRadioItemDefaultTitle({
  children,
  htmlFor,
  ...rest
}: ICustomRadioItemDefaultTitleProps): JSX.Element {
  return (
    <Container
      htmlFor={htmlFor}
      {...rest}
    >
      {children}
    </Container>
  );
}
