import { type ICustomRadioItemControlledTitleProps } from './CustomRadioItemControlledTitle.types';

import { Container } from './CustomRadioItemControlledTitle.styles';

export function CustomRadioItemControlledTitle({
  children,
  htmlFor,
  ...rest
}: ICustomRadioItemControlledTitleProps): JSX.Element {
  return (
    <Container
      htmlFor={htmlFor}
      {...rest}
    >
      {children}
    </Container>
  );
}
