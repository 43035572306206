import styled from 'styled-components';

import { CreditCard } from 'presentation/components/global/CreditCard';
import { GroupCard } from 'presentation/pages/cards/Management/components/GroupCard';

export const CardsLayoutLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
  padding-bottom: 0.5rem;

  & > div > div > div {
    width: 54%;
    flex-grow: 1;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: row wrap;
    justify-content: center;
    align-items: unset;
    gap: 2rem 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    justify-content: unset;
    column-gap: 2.724rem;
  }
`;

export const CreditCardSkeleton = styled(CreditCard)`
  width: 100%;
  height: 100%;
  max-width: 40rem;
  aspect-ratio: 1.587;
  background-size: cover;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 0.5rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 28.301rem;
    padding: 1.4rem 2rem;
    gap: 0.5rem;
  }
`;

export const GroupCardSkeleton = styled(GroupCard)`
  width: 100%;
  height: 100%;
  max-width: 40rem;
  aspect-ratio: 1.587;
  display: flex;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 0.5rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 28.301rem;
    padding: 1.4rem 2rem;
    gap: 0.5rem;
  }
`;
