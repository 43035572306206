import { DefaultButton, SecondaryButton } from 'ds';

import { useLangContext, useModalContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';
import { ChargeCardsSteps } from 'presentation/pages/cards/Management/ChargeCards/ChargeCards.types';
import { ValueTransferFeedbackModal } from 'presentation/pages/cards/Management/ChargeCards/components/ValueTransferFeedbackModal';

import { Currency } from 'shared/utils/format';

import { SummaryCard } from '..';

import { useChargeCardsSummary } from './useChargeCardsSummary';

import { type IChargeCardsSummaryProps } from './ChargeCardsSummary.types';

import {
  ButtonsContainer,
  CardsInfoContainer,
  Container
} from './ChargeCardsSummary.styles';

export function ChargeCardsSummary({
  ...rest
}: IChargeCardsSummaryProps): JSX.Element {
  const {
    currentLangKey,
    lang: {
      cards: { valueAllocation },
      global
    }
  } = useLangContext();

  const {
    disabled,
    totalToAdd,
    totalToRemove,
    impactedUsers,
    currentStep,
    isSendingModifiedBalanceAllocationItemsToCache,
    isTransferringBalanceAllocationItems,
    isDeletingModifiedBalanceAllocationItemsFromCache,
    transferredData,
    handleContinueToConfirmationStep,
    handleClearValues,
    handleConfirmAndTransferValues,
    handleGoBackToChargeCardsStep
  } = useChargeCardsSummary();

  const { visibleModal } = useModalContext();

  return (
    <Container {...rest}>
      <CardsInfoContainer>
        <SummaryCard
          disabled={disabled}
          title={valueAllocation.impacted_users[currentLangKey]}
          value={String(impactedUsers)}
        />

        <SummaryCard
          disabled={disabled}
          title={valueAllocation.value_to_add[currentLangKey]}
          value={Currency.format('BRL', totalToAdd, true)}
          variant='success'
        />

        <SummaryCard
          disabled={disabled}
          title={valueAllocation.value_to_remove[currentLangKey]}
          value={Currency.format('BRL', totalToRemove, true)}
          variant='danger'
        />

        <SummaryCard
          disabled={disabled}
          title={valueAllocation.total_movement[currentLangKey]}
          value={Currency.format('BRL', totalToAdd - totalToRemove, true)}
        />
      </CardsInfoContainer>

      <ButtonsContainer>
        <SecondaryButton
          size='small'
          onClick={() => {
            currentStep === ChargeCardsSteps.CHARGE_CARDS
              ? handleClearValues()
              : handleGoBackToChargeCardsStep();
          }}
          disabled={disabled}
        >
          {currentStep === ChargeCardsSteps.CHARGE_CARDS
            ? global.clear[currentLangKey]
            : global.back[currentLangKey]}
        </SecondaryButton>

        <DefaultButton
          size='small'
          onClick={() => {
            currentStep === ChargeCardsSteps.CHARGE_CARDS
              ? handleContinueToConfirmationStep()
              : handleConfirmAndTransferValues();
          }}
          disabled={disabled}
          className={
            currentStep === ChargeCardsSteps.CHARGE_CARDS
              ? 'charge-cards-continue'
              : ''
          }
        >
          {currentStep === ChargeCardsSteps.CHARGE_CARDS
            ? global.continue[currentLangKey]
            : global.transfer[currentLangKey]}
        </DefaultButton>
      </ButtonsContainer>

      <Loader
        isLoading={
          isSendingModifiedBalanceAllocationItemsToCache ||
          isTransferringBalanceAllocationItems ||
          isDeletingModifiedBalanceAllocationItemsFromCache
        }
      />

      <ValueTransferFeedbackModal
        transferredData={transferredData}
        isOpen={visibleModal === 'valueAllocationConfirmSummary'}
      />
    </Container>
  );
}
