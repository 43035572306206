import { api } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import {
  CompanyFormCacheDomains,
  type CompanyOnboardingShareholderType,
  type IAddLegalRepresentativePayload,
  type IAnswerableUser,
  type IApproveLegalRepresentativePayload,
  type IAuxiliaryDocument,
  type ICompanyDocument,
  type ICompanyOnboardingAnswers,
  type ICompanyOnboardingConfigurationUsersCache,
  type ICompanyOnboardingProgress,
  type ICompanyOnboardingTeamMembers,
  type ICompleteAccountStepsPayload,
  type ICompleteCompanyOnboardingPayload,
  type IDataToRepresentativeApproval,
  type IDeleteLegalRepresentativePayload,
  type IGetAnswerableUserPayload,
  type IGetCompanyOnboardingAnswersPayload,
  type IGetCompanyOnboardingTeamMembersPayload,
  type IGetDataToRepresentativeApprovalPayload,
  type IGetLegalRepresentativesPayload,
  type IGetLegalRepresentativeToApprovalPayload,
  type IGetSerproInformationsPayload,
  type IIncrementCompanyStepPayload,
  type ILegalRepresentatives,
  type IPersistenceAnswerableUser,
  type IPersistenceCompanyDocument,
  type IPersistenceCompanyOnboardingConfigurationUsersCache,
  type IPersistenceCompanyOnboardingProgress,
  type IPersistenceCompanyOnboardingTeamMembers,
  type IPersistenceDataToRepresentativeApproval,
  type IPersistenceLegalRepresentatives,
  type IPersistenceSerproInformations,
  type IPullSerproInformationsPayload,
  type IResendLegalRepresentativesApprovalSolicitationPayload,
  type ISerproInformations,
  type IUpdateAnswerableUserPayload,
  type IUploadAuxiliaryDocumentPayload,
  type IUploadCompanyDocumentPayload,
  type PersistenceCompanyOnboardingAnswersType,
  type PersistenceCompanyOnboardingShareholderType,
  type UpdateCompanyFormCachePayloadType,
  type UpdateCompanyOnboardingAnswersPayloadType
} from 'data/modules/cards/companyOnboarding';
import {
  type IDefaultResponse,
  type IPaginatedResponse
} from 'data/modules/global';

import {
  ApproveLegalRepresentativePayloadMapper,
  GetAnswerableUserMapper,
  GetCompanyOnboardingAnswersMapper,
  GetCompanyOnboardingConfigurationUsersCacheMapper,
  GetCompanyOnboardingProgressMapper,
  GetCompanyOnboardingShareholdersMapper,
  GetCompanyOnboardingTeamMembersMapper,
  GetDataToRepresentativeApprovalMapper,
  GetLegalRepresentativesMapper,
  GetLegalRepresentativeToApprovalMapper,
  GetSerproInformationsMapper,
  PullSerproInformationsMapper,
  UpdateAnswerableUserPayloadMapper,
  UpdateCompanyFormCacheMapper,
  UpdateCompanyOnboardingAnswersMapper
} from './mappers';

class CompanyOnboardingService {
  async getProgress(): Promise<ICompanyOnboardingProgress> {
    const response = await api.get<
      IDefaultResponse<IPersistenceCompanyOnboardingProgress>
    >('/pay/company/onboarding');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetCompanyOnboardingProgressMapper.toDomain(data);
  }

  async getAnswerableUser({
    uuidCompanyOnboarding
  }: IGetAnswerableUserPayload): Promise<IAnswerableUser> {
    const response = await api.get<
      IDefaultResponse<IPersistenceAnswerableUser>
    >(`/pay/onboarding/company/${uuidCompanyOnboarding}/answerable`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetAnswerableUserMapper.toDomain(data);
  }

  async updateAnswerableUser(
    payload: IUpdateAnswerableUserPayload
  ): Promise<null> {
    const response = await api.post<IDefaultResponse<null>>(
      `/pay/onboarding/company/${payload.uuidCompanyOnboarding}/answerable`,
      UpdateAnswerableUserPayloadMapper.toPersistence({
        documentNumber: payload.documentNumber,
        fullName: payload.fullName,
        phoneNumber: payload.phoneNumber
      })
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async incrementStep({
    uuidCompanyOnboarding,
    step
  }: IIncrementCompanyStepPayload): Promise<null> {
    const response = await api.patch<IDefaultResponse<null>>(
      `/pay/onboarding/company/${uuidCompanyOnboarding}/step/increment`,
      { step }
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getCompanyOnboardingAnswers({
    uuidCompanyOnboarding
  }: IGetCompanyOnboardingAnswersPayload): Promise<ICompanyOnboardingAnswers> {
    const response = await api.get<
      IDefaultResponse<PersistenceCompanyOnboardingAnswersType>
    >(`/pay/onboarding/company/${uuidCompanyOnboarding}/answers`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetCompanyOnboardingAnswersMapper.toDomain(data);
  }

  async updateCompanyOnboardingAnswers(
    payload: UpdateCompanyOnboardingAnswersPayloadType
  ): Promise<void> {
    const response = await api.patch<IDefaultResponse<void>>(
      `/pay/onboarding/company/${payload.uuidCompanyOnboarding}/step`,
      UpdateCompanyOnboardingAnswersMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async pullSerproInformations({
    cnpj,
    uuidCompanyOnboarding
  }: IPullSerproInformationsPayload): Promise<null> {
    const response = await api.post<IDefaultResponse<null>>(
      `/pay/onboarding/company/${uuidCompanyOnboarding}/cnpj-pull-informations`,
      PullSerproInformationsMapper.toPersistence(cnpj)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getSerproInformations({
    uuidCompanyOnboarding
  }: IGetSerproInformationsPayload): Promise<ISerproInformations> {
    const response = await api.get<
      IDefaultResponse<IPersistenceSerproInformations>
    >(`/pay/onboarding/company/${uuidCompanyOnboarding}/cnpj-informations`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetSerproInformationsMapper.toDomain(data);
  }

  async uploadCompanyDocument(
    payload: IUploadCompanyDocumentPayload
  ): Promise<ICompanyDocument> {
    const documentData = new FormData();
    documentData.append('document', payload.document);

    const response = await api.post<
      IDefaultResponse<IPersistenceCompanyDocument>
    >(
      `/pay/onboarding/company/${payload.uuidCompanyOnboarding}/document/social-contracts`,
      documentData
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getLegalRepresentatives({
    uuidCompanyOnboarding
  }: IGetLegalRepresentativesPayload): Promise<ILegalRepresentatives[]> {
    const response = await api.get<
      IDefaultResponse<IPersistenceLegalRepresentatives[] | null>
    >(`/pay/onboarding/company/${uuidCompanyOnboarding}/legal-representatives`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data ? GetLegalRepresentativesMapper.toDomain(data) : [];
  }

  async uploadAuxiliaryDocument({
    document,
    uuidCompanyOnboarding
  }: IUploadAuxiliaryDocumentPayload): Promise<IAuxiliaryDocument> {
    const documentData = new FormData();
    documentData.append('document', document);

    const response = await api.post<IDefaultResponse<IAuxiliaryDocument>>(
      `/pay/onboarding/company/${uuidCompanyOnboarding}/document/legal-procurations`,
      documentData
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async addLegalRepresentative({
    uuidCompanyOnboarding,
    ...payload
  }: IAddLegalRepresentativePayload): Promise<null> {
    const response = await api.post<IDefaultResponse<null>>(
      `/pay/onboarding/company/${uuidCompanyOnboarding}/legal-representatives`,
      payload
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getCompanyOnboardingShareholders(): Promise<
    CompanyOnboardingShareholderType[]
  > {
    const response = await api.get<
      IDefaultResponse<PersistenceCompanyOnboardingShareholderType[]>
    >(
      `/pay/company/form-cache/${CompanyFormCacheDomains.onboardingShareholders}`
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data
      ? data.map(persistenceShareholder =>
          GetCompanyOnboardingShareholdersMapper.toDomain(
            persistenceShareholder
          )
        )
      : [];
  }

  async getCompanyOnboardingConfigurationUsersCache(): Promise<
    ICompanyOnboardingConfigurationUsersCache[]
  > {
    const response = await api.get<
      IDefaultResponse<IPersistenceCompanyOnboardingConfigurationUsersCache[]>
    >(`/pay/company/form-cache/${CompanyFormCacheDomains.onboardingUsers}`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data
      ? data.map(userConfig =>
          GetCompanyOnboardingConfigurationUsersCacheMapper.toDomain(userConfig)
        )
      : [];
  }

  async clearCompanyOnboardingShareholdersCache(): Promise<null> {
    const response = await api.delete<IDefaultResponse<null>>(
      `/pay/company/form-cache/${CompanyFormCacheDomains.onboardingShareholders}`
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async deleteLegalRepresentative({
    uuidCompanyOnboarding,
    uuid
  }: IDeleteLegalRepresentativePayload): Promise<null> {
    const response = await api.delete<IDefaultResponse<null>>(
      `/pay/onboarding/company/${uuidCompanyOnboarding}/legal-representatives/${uuid}`
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async updateCompanyFormCache(
    payload: UpdateCompanyFormCachePayloadType
  ): Promise<null> {
    const response = await api.post<IDefaultResponse<null>>(
      'pay/company/form-cache',
      UpdateCompanyFormCacheMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async resendLegalRepresentativesApprovalSolicitation({
    uuidCompanyOnboarding,
    uuid
  }: IResendLegalRepresentativesApprovalSolicitationPayload): Promise<null> {
    const response = await api.patch<IDefaultResponse<null>>(
      `/pay/onboarding/company/${uuidCompanyOnboarding}/legal-representatives/${uuid}/resend-approve-solicitation`
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async completeCompanyOnboarding({
    uuidCompanyOnboarding
  }: ICompleteCompanyOnboardingPayload): Promise<null> {
    const response = await api.patch<IDefaultResponse<null>>(
      `/pay/onboarding/company/${uuidCompanyOnboarding}/account-settings/complete`
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getCompanyOnboardingTeamMembers(
    payload: IGetCompanyOnboardingTeamMembersPayload
  ): Promise<IPaginatedResponse<ICompanyOnboardingTeamMembers>> {
    const response = await api.get<
      IDefaultResponse<IPersistenceCompanyOnboardingTeamMembers[]>
    >('/team-members/filtered', {
      params: GetCompanyOnboardingTeamMembersMapper.toPersistence(payload)
    });

    const { data, total } = ResponseAdapter.formatRegularResponse(
      response.data
    );

    return {
      data: data?.map(teamMember =>
        GetCompanyOnboardingTeamMembersMapper.toDomain(teamMember)
      ),
      total: total ?? 0
    };
  }

  async getDataToRepresentativeApproval({
    hashCode
  }: IGetDataToRepresentativeApprovalPayload): Promise<IDataToRepresentativeApproval> {
    const response = await api.get<
      IDefaultResponse<IPersistenceDataToRepresentativeApproval>
    >(`/pay/onboarding/company/answerable?hash_code=${hashCode}`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetDataToRepresentativeApprovalMapper.toDomain(data);
  }

  async getLegalRepresentativeToApproval({
    hashCode
  }: IGetLegalRepresentativeToApprovalPayload): Promise<ILegalRepresentatives> {
    const response = await api.get<
      IDefaultResponse<IPersistenceLegalRepresentatives>
    >(`/pay/onboarding/company/legal-representative?hash_code=${hashCode}`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetLegalRepresentativeToApprovalMapper.toDomain(data);
  }

  async approveLegalRepresentative(
    payload: IApproveLegalRepresentativePayload
  ): Promise<null> {
    const response = await api.patch<IDefaultResponse<null>>(
      `/pay/onboarding/company/legal-representatives/approve`,
      ApproveLegalRepresentativePayloadMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async completeAccountSteps({
    uuidCompanyOnboarding
  }: ICompleteAccountStepsPayload): Promise<null> {
    const response = await api.patch<IDefaultResponse<null>>(
      `/pay/onboarding/company/${uuidCompanyOnboarding}/account-steps/complete`
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }
}

export default new CompanyOnboardingService();
