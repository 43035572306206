import {
  DefaultButton,
  DSIcons,
  MultiSelect,
  SecondaryButton,
  TextInput
} from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import {
  useAuthContext,
  useCardGroupsContext,
  useFlagsContext,
  useModalContext
} from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';

import { useSearchAndFilters } from './useSearchAndFilters';

import { type IMultiSelectDefaultOption } from 'ds/components/MultiSelect/Default/MultiSelectDefault.types';

import {
  CardsModeSwitch,
  Container,
  ControllersContainer,
  DisplayModeLabel,
  DisplayModeSwitchSelectorContainer,
  ListModeSwitch,
  SearchAndFiltersContainer,
  TableDisplayModeSelectorContainer
} from './SearchAndFilters.styles';

export function SearchAndFilters({ ...rest }): JSX.Element {
  const { t } = useTranslation('cards');

  const user = useAuthContext(state => state.user);

  const [
    tableViewMode,
    filters,
    handleChangeTableViewMode,
    handleChangeFilters
  ] = useCardGroupsContext(
    useShallow(state => [
      state.tableViewMode,
      state.filters,
      state.handleChangeTableViewMode,
      state.handleChangeFilters
    ])
  );

  const handleOpenModal = useModalContext(state => state.handleOpenModal);

  const { selectOptions, isGetPartialManagersFetching } = useSearchAndFilters();

  const cardGroupsFlag = useFlagsContext(state => state.cardGroupsFlag);

  return (
    <Container {...rest}>
      <SearchAndFiltersContainer>
        <TextInput.Default
          label={t('searchCardGroups')}
          placeholder={t('searchCardGroups')}
          value={filters.name ?? ''}
          onChangeValue={value => {
            handleChangeFilters({
              name: value.trim() === '' ? null : value
            });
          }}
          onClearValue={() => {
            handleChangeFilters({
              name: null
            });
          }}
          iconLeft='SearchIcon'
          small
        />

        {user?.cards?.type !== 'USER' && user?.cards?.isCardGroupManager && (
          <MultiSelect.Default
            hasArrowDownIndicator
            name='card-type'
            value={filters.managers ?? []}
            label={t('groupManagers')}
            placeholder={t('groupManagers')}
            isLoading={isGetPartialManagersFetching}
            onChange={value => {
              handleChangeFilters({
                managers: value?.length === 0 ? null : value
              });
            }}
            options={(selectOptions as IMultiSelectDefaultOption[]) ?? []}
            small
          />
        )}
      </SearchAndFiltersContainer>

      <ControllersContainer>
        <TableDisplayModeSelectorContainer className='cards-tour-preview-button'>
          <DisplayModeLabel>{t('viewMode')}</DisplayModeLabel>

          <DisplayModeSwitchSelectorContainer>
            <ListModeSwitch
              onClick={() => {
                handleChangeTableViewMode('LIST');
              }}
              $tableDisplayMode={tableViewMode}
            >
              <DSIcons.ListLayoutIcon />
            </ListModeSwitch>

            <CardsModeSwitch
              onClick={() => {
                handleChangeTableViewMode('GROUP_CARDS');
              }}
              $tableDisplayMode={tableViewMode}
            >
              <DSIcons.GridLayoutIcon />
            </CardsModeSwitch>
          </DisplayModeSwitchSelectorContainer>
        </TableDisplayModeSelectorContainer>

        {user?.cards?.type === 'ACCOUNT_MANAGER' &&
          user?.cards?.isCardGroupManager && (
            <SecondaryButton
              onClick={() => {
                handleOpenModal('requestCards');
              }}
              size='small'
            >
              {t('requestCards')}
            </SecondaryButton>
          )}

        {user?.cards?.type === 'ACCOUNT_MANAGER' &&
          cardGroupsFlag &&
          user?.cards?.isCardGroupManager && (
            <DefaultButton
              size='small'
              onClick={() => {
                handleOpenModal('cardsGroupCreation');
              }}
            >
              <BaseIcon name='add' />
              {t('createNewGroup')}
            </DefaultButton>
          )}
      </ControllersContainer>
    </Container>
  );
}
