import { Route } from 'react-router-dom';

import { InconsistentKyc, Onboarding } from 'presentation/pages/cards';
import {
  FirstScreen,
  LastScreen,
  PoliciesAndTerms,
  UserAddress,
  UserData
} from 'presentation/pages/cards/Onboarding/components/User';

import { CardsRoutes } from 'shared/constants/global';
import {
  AuthenticationMiddleware,
  UserOnboardingMiddleware
} from 'shared/middlewares';

export const userOnboardingRoutes = [
  <Route
    key='onboarding/usuario'
    element={<AuthenticationMiddleware />}
  >
    <Route element={<UserOnboardingMiddleware />}>
      <Route
        path={CardsRoutes.USER_ONBOARDING}
        element={<Onboarding onboardingType='USER' />}
      >
        <Route
          path={CardsRoutes.USER_ONBOARDING_BEGIN}
          element={<FirstScreen />}
        />
        ,
        <Route
          path={CardsRoutes.USER_ONBOARDING_1}
          element={<PoliciesAndTerms />}
        />
        ,
        <Route
          path={CardsRoutes.USER_ONBOARDING_2}
          element={<UserData />}
        />
        ,
        <Route
          path={CardsRoutes.USER_ONBOARDING_3}
          element={<UserAddress />}
        />
        ,
        <Route
          path={CardsRoutes.USER_ONBOARDING_FINAL}
          element={<LastScreen />}
        />
        ,
        <Route
          path={CardsRoutes.USER_ONBOARDING_KYC}
          element={<InconsistentKyc isCompany={false} />}
        />
      </Route>
    </Route>
  </Route>
];
