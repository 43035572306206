import { type IAnalysisDefaultContainerProps } from './AnalysisDefaultContainer.types';

import { Container } from './AnalysisDefaultContainer.styles';

export function AnalysisDefaultContainer({
  children,
  ...props
}: IAnalysisDefaultContainerProps): JSX.Element {
  return <Container {...props}>{children}</Container>;
}
