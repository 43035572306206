import { useState } from 'react';

import SimpleImageViewer from 'react-simple-image-viewer';

import { BaseIcon } from 'presentation/components/base/Icon';

import { type IReceiptViewerProps } from './ReceiptViewer.types';

import {
  ClickableContainer,
  ClickablePdf,
  ImagePreview
} from './ReceiptViewer.styles';

export function ReceiptViewer({
  images,
  currentImage = 0,
  isPdf = false,
  thumbnailUrl = null,
  thumbnailWidth = 12,
  thumbnailHeight = 12
}: IReceiptViewerProps): JSX.Element {
  const [isOpenedImage, setIsOpenedImage] = useState(false);

  return (
    <>
      {!isPdf && thumbnailUrl && (
        <ClickableContainer
          onClick={() => {
            setIsOpenedImage(true);
          }}
        >
          <ImagePreview
            src={thumbnailUrl}
            alt='receiptThumbnailImage'
            $width={thumbnailWidth}
            $height={thumbnailHeight}
          />

          <span>
            <BaseIcon
              name='drag-move-2'
              color='primary'
              size={2.2}
            />
          </span>
        </ClickableContainer>
      )}

      {isPdf && thumbnailUrl && (
        <ClickablePdf
          href={thumbnailUrl}
          target='_blank'
        >
          <BaseIcon
            name='file-3'
            color='primary'
            size={5}
          />
        </ClickablePdf>
      )}

      {isOpenedImage && (
        <SimpleImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={() => {
            setIsOpenedImage(false);
          }}
          backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
        />
      )}
    </>
  );
}
