import { type ITransactionDetailsSectionProps } from './TransactionDetailsSection.types';

import { Container } from './TransactionDetailsSection.styles';

export function TransactionDetailsSection({
  title,
  children,
  hasDivider = true
}: ITransactionDetailsSectionProps): JSX.Element {
  return (
    <Container $hasDivider={hasDivider}>
      {title && <strong>{title}</strong>}

      {children}
    </Container>
  );
}
