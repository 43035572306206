import { useQueries } from '@tanstack/react-query';

import {
  BalanceQueryKeys,
  BalanceService,
  type IUseGetUnallocatedBalances,
  type IUseGetUnallocatedBalancesPayload
} from 'data/modules/cards/balance';

export function useGetUnallocatedBalances({
  cardGroupIds,
  enabled = true
}: IUseGetUnallocatedBalancesPayload): IUseGetUnallocatedBalances {
  const queries = cardGroupIds.map(cardGroupId => ({
    queryKey: [
      BalanceQueryKeys.GET_UNALLOCATED_BALANCE,
      { cardGroupId: cardGroupId || null }
    ],
    queryFn: async () =>
      await BalanceService.getUnallocatedBalance({
        ...(cardGroupId && { cardGroupId })
      }),
    enabled
  }));

  const results = useQueries({
    queries: [...queries]
  });

  return {
    unallocatedBalances: results.map(result => result.data?.amount),
    isFetchingUnallocatedBalances: results.some(result => result.isFetching)
  };
}
