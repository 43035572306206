import { type BaseSyntheticEvent, useEffect, useState } from 'react';

import { useIsFetching } from '@tanstack/react-query';
import { toast } from 'ds/utils';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  useApportionmentContext,
  useLangContext,
  useModalContext
} from 'data/contexts';
import { CostCentersQueryKeys } from 'data/modules/costsCenters';
import { CurrenciesQueryKeys } from 'data/modules/currencies';
import {
  ExpensesQueryKeys,
  type ICreateRouteExpenseFirstStepFieldsForm,
  type IDetailedExpense,
  useCreateManualRouteExpense,
  useGetExpenseParameters
} from 'data/modules/expenses';
import { useGetExpenseDetails } from 'data/modules/expenses/useCases/get-expense-details/useGetExpenseDetails';
import { PaymentMethodsQueryKeys } from 'data/modules/paymentMethods';
import {
  type ReportInformationType,
  useGetOpenReports
} from 'data/modules/reports';

import { useManualRouteExpenseModalTour } from 'presentation/pages/expenses/ExpensesList/components/Modal/CreateManualRouteExpenseModal/tours';

import { routesPathPrefix } from 'shared/constants/global';
import { Currency } from 'shared/utils/format';
import { FreddySurvey } from 'shared/utils/global';

import {
  type IUseCreateManualRouteExpenseModal,
  type IUseCreateManualRouteExpenseParams,
  type ReadOnlyFieldsWhenUserIsUpdatingRouteStateType,
  type StepType
} from './CreateManualRouteExpenseModal.types';

export function useCreateManualRouteExpenseModal({
  expenseUuidToUpdate
}: IUseCreateManualRouteExpenseParams): IUseCreateManualRouteExpenseModal {
  const [step, setStep] = useState<StepType>('defaultFields');

  const { handleCloseModal } = useModalContext();

  const { t } = useTranslation('expenses');

  const [showGoBackModal, setShowGoBackModal] = useState(false);

  const [showPreRegisteredSectionsField, setShowPreRegisteredSectionsField] =
    useState(true);

  const { expenseParameters } = useGetExpenseParameters();

  const [
    readOnlyFieldsWhenUserIsUpdatingRoute,
    setReadOnlyFieldsWhenUserIsUpdatingRoute
  ] = useState<ReadOnlyFieldsWhenUserIsUpdatingRouteStateType>(new Set());

  const [showSeparateReportWarningModal, setShowSeparateReportWarningModal] =
    useState(false);

  const [showCreateReportForm, setShowCreateReportForm] = useState(false);

  const { handleOpenModal, handleChangeErrorMessage } = useModalContext();

  const { lang, currentLangKey } = useLangContext();

  const { requiredReceiptInManualRoute } = useApportionmentContext();

  const [
    confirmedReportInformationBySelectedReport,
    setConfirmedReportInformationBySelectedReport
  ] = useState<ReportInformationType | null>(null);

  function handleUpdateExpenseDataBySelectedReport(
    data: ReportInformationType | null
  ): void {
    setConfirmedReportInformationBySelectedReport(data);
  }

  const userAction = expenseUuidToUpdate ? 'update' : 'create';

  const navigate = useNavigate();

  function applyReadOnlyFieldsWhenUserIsUpdatingRoute(
    expenseToUpdate: IDetailedExpense
  ): void {
    if (userAction === 'create') {
      return;
    }

    const readOnlyFields: ReadOnlyFieldsWhenUserIsUpdatingRouteStateType =
      new Set();

    if (expenseToUpdate.routeType === 'TRACKING') {
      readOnlyFields.add('mileage');
      readOnlyFields.add('date');
    }

    if (
      expenseParameters?.companyApprovalType === 'CC' &&
      expenseToUpdate.report !== null
    ) {
      readOnlyFields.add('costsCenter');
    }

    setReadOnlyFieldsWhenUserIsUpdatingRoute(readOnlyFields);
  }

  const {
    firstStepForm,
    isLoadingExpenseParameters,
    secondStepForm,
    isCreatingRouteExpense,
    isUpdatingRouteExpense,
    allowUserChangeExpenseProjectByReportProject
  } = useCreateManualRouteExpense({
    onAfterSubmitFirstStep: () => {
      setStep('selectReport');
    },
    onSuccessCreateRouteExpense: () => {
      handleCloseModal();
      toast.success(
        lang.expenses.modal.create_manual_expense
          .manual_route_expense_added_successfully[currentLangKey]
      );

      FreddySurvey.show();
    },
    onErrorCreateOrUpdateManualRouteExpense: (message: string) => {
      handleChangeErrorMessage({
        title: expenseUuidToUpdate
          ? t('modal.errorWhenUpdatingExpense')
          : t('modal.errorWhenCreatingExpense'),
        message
      });
    },
    onSuccessUpdateRouteExpense: () => {
      handleCloseModal();
      toast.success(t('messages.routeUpdatedSuccessfully'));
    },
    confirmedReportInformationBySelectedReport,
    expenseUuidToUpdate,
    requiredReceiptInManualRoute
  });

  const { getEnsuredExpenseDetails, isFetchingAndPendingExpenseDetails } =
    useGetExpenseDetails({
      expenseUuid: expenseUuidToUpdate ?? '',
      enabled: false
    });

  async function loadExpenseAndPrePopulateFieldsFromUpdate(): Promise<void> {
    if (expenseUuidToUpdate === undefined) {
      return;
    }

    const expenseToUpdate = await getEnsuredExpenseDetails({
      expenseUuid: expenseUuidToUpdate
    });

    const formattedMileage = expenseToUpdate.kilometrage
      ? Currency.format(
          'BRL',
          Currency.parseToFloat(expenseToUpdate.kilometrage)
        )
      : '';

    const formattedMileagePaidValue = expenseToUpdate.amountPerKilometer
      ? Currency.format(
          'BRL',
          Currency.parseToFloat(expenseToUpdate.amountPerKilometer, 4),
          false,
          4
        )
      : '';

    if (expenseToUpdate.routeType === 'TRACKING') {
      setShowPreRegisteredSectionsField(false);
    }

    const valuesFromExpenseToUpdate:
      | ICreateRouteExpenseFirstStepFieldsForm
      | undefined =
      expenseToUpdate !== undefined
        ? {
            preRegisteredSection:
              expenseToUpdate.routeId !== null ? expenseToUpdate.routeId : '',
            mileage: formattedMileage,
            mileagePaidValue: formattedMileagePaidValue,
            description: expenseToUpdate.title,
            currency: expenseToUpdate.currency.id,
            value: Currency.format('BRL', expenseToUpdate.value),
            date: expenseToUpdate.date,
            costsCenter:
              expenseToUpdate.costsCenter !== null
                ? {
                    label: expenseToUpdate.costsCenter?.name,
                    value: expenseToUpdate.costsCenter?.id
                  }
                : undefined,
            paymentMethod: expenseToUpdate.paymentMethod?.id ?? '',
            refundable: expenseToUpdate.isReimbursable,
            observation: expenseToUpdate.observation ?? '',
            receipt: expenseToUpdate?.receipt?.url ?? null,
            apportionment:
              expenseToUpdate.apportionments?.map(item => ({
                percentage: item.percentage,
                project: {
                  label: item.project.name,
                  value: item.project.id
                },
                value: item.value
              })) ?? []
          }
        : undefined;

    firstStepForm.methods.reset(valuesFromExpenseToUpdate);
    secondStepForm.methods.reset({
      report: expenseToUpdate.report?.uuid ?? ''
    });

    applyReadOnlyFieldsWhenUserIsUpdatingRoute(expenseToUpdate);
  }

  useEffect(() => {
    if (expenseUuidToUpdate !== undefined && !isLoadingExpenseParameters) {
      loadExpenseAndPrePopulateFieldsFromUpdate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseUuidToUpdate, isLoadingExpenseParameters]);

  const isValidatingDateLimitsByProject =
    useIsFetching({
      predicate: query => {
        return (
          query.queryKey[0] ===
            ExpensesQueryKeys.VALIDATE_DATE_LIMITS_BY_PROJECT &&
          query.state.status === 'pending'
        );
      }
    }) > 0;

  const isValidatingMileagePolicy =
    useIsFetching({
      predicate: query => {
        return (
          (query.queryKey[0] ===
            ExpensesQueryKeys.VALIDATE_MILEAGE_POLICY_BY_PROJECT ||
            query.queryKey[0] ===
              ExpensesQueryKeys.VALIDATE_MILEAGE_POLICY_BY_USER) &&
          query.state.status === 'pending'
        );
      }
    }) > 0;

  const isLoadingSomeData =
    useIsFetching({
      predicate: query => {
        return (
          (query.queryKey[0] === ExpensesQueryKeys.GET_TYPES_OF_EXPENSES ||
            query.queryKey[0] === PaymentMethodsQueryKeys.GET_PAYMENT_METHODS ||
            query.queryKey[0] === CostCentersQueryKeys.GET_COST_CENTERS ||
            query.queryKey[0] === CurrenciesQueryKeys.GET_CURRENCIES) &&
          query.state.status === 'pending'
        );
      }
    }) > 0 ||
    isLoadingExpenseParameters ||
    isValidatingDateLimitsByProject ||
    isValidatingMileagePolicy ||
    isFetchingAndPendingExpenseDetails;

  const { openReports } = useGetOpenReports();

  const selectedReport = useWatch({
    control: secondStepForm.methods.control,
    name: 'report'
  });

  const selectedReportCurrency = openReports?.find(
    ({ uuid }) => uuid === selectedReport
  )?.currency;

  const {
    methods: {
      formState: { isDirty, errors }
    }
  } = firstStepForm;

  const checkIfDateFieldHasError =
    errors.date !== undefined && errors.date.type === 'validated-by-project';

  const selectedExpenseCurrencyId = firstStepForm.selectedCurrencyId;

  const isExpenseCurrencyDifferentFromReportCurrency =
    selectedExpenseCurrencyId !== undefined &&
    selectedReportCurrency !== undefined &&
    selectedReportCurrency.id !== null &&
    String(selectedReportCurrency.id) !== selectedExpenseCurrencyId;

  function handleClearSelection(): void {
    secondStepForm.methods.setValue('report', '');

    setConfirmedReportInformationBySelectedReport(null);

    // quando limpar seleção de relatório na edição de despesa, habilita novamente o campo de centro de custos
    userAction === 'update' &&
      setReadOnlyFieldsWhenUserIsUpdatingRoute(oldState => {
        const newState = new Set(oldState);

        newState.delete('costsCenter');

        return newState;
      });
  }

  async function handleFirstStepGoBackButton(): Promise<void> {
    if (isDirty) {
      setShowGoBackModal(true);
      return;
    }

    if (userAction === 'update') {
      const expenseToUpdate = await getEnsuredExpenseDetails({
        expenseUuid: expenseUuidToUpdate as string
      });

      navigate(`${routesPathPrefix.expenses}/${expenseToUpdate.id}`);

      handleOpenModal('viewExpensesModal');
      return;
    }

    handleOpenModal('selectExpenseToAdd');
  }

  async function handleConfirmGoBackModal(): Promise<void> {
    if (userAction === 'update') {
      const expenseToUpdate = await getEnsuredExpenseDetails({
        expenseUuid: expenseUuidToUpdate as string
      });

      navigate(`${routesPathPrefix.expenses}/${expenseToUpdate.id}`);

      handleOpenModal('viewExpensesModal');
      return;
    }

    handleOpenModal('selectExpenseToAdd');
  }

  function handleSubmitReportForm(e: BaseSyntheticEvent): void {
    e.preventDefault();

    if (!selectedReport && userAction === 'create') {
      setShowSeparateReportWarningModal(true);
      return;
    }

    secondStepForm.handleSubmit();
  }

  function updateFormAfterReportCreated(reportUuid: string): void {
    setShowCreateReportForm(false);
    setStep('selectReport');
    secondStepForm.methods.setValue('report', reportUuid);
  }

  function handleGoBackButton(): void {
    step === 'defaultFields' && handleFirstStepGoBackButton();

    if (step === 'selectReport') {
      setStep('defaultFields');

      if (isExpenseCurrencyDifferentFromReportCurrency) {
        firstStepForm.methods.setError('currency', {
          type: 'custom',
          message: lang.expenses.modal.different_currency_error[currentLangKey]
        });
      }
    }
  }

  function handleClickOverlay(): void {
    if (isDirty) {
      setShowGoBackModal(true);
      return;
    }

    handleCloseModal();
  }

  function handleChangeFormExpenseCurrency(currencyId: string): void {
    firstStepForm.methods.setValue('currency', currencyId);

    if (
      selectedReportCurrency !== undefined &&
      selectedReportCurrency.id !== null
    ) {
      const reportCurrencyId = String(selectedReportCurrency.id);

      if (currencyId === reportCurrencyId) {
        firstStepForm.methods.clearErrors('currency');
        return;
      }

      firstStepForm.methods.setError('currency', {
        type: 'custom',
        message: lang.expenses.modal.different_currency_error[currentLangKey]
      });
    }
  }

  const firstStepSelectedCostsCenterId =
    firstStepForm.methods.getValues('costsCenter')?.value ?? '';

  const firstStepSelectedPaymentMethodId =
    firstStepForm.methods.getValues('paymentMethod');

  const apportionmentValue = firstStepForm.methods.getValues('apportionment');

  const firstStepSelectedProjectId =
    apportionmentValue !== undefined && apportionmentValue.length === 1
      ? apportionmentValue[0]?.project?.value ?? ''
      : '';

  useManualRouteExpenseModalTour({
    enabled: step === 'selectReport'
  });

  const submitButtonDisabled =
    isLoadingSomeData ||
    (step === 'selectReport' && isExpenseCurrencyDifferentFromReportCurrency) ||
    checkIfDateFieldHasError;

  const isShowingModalLoading =
    isCreatingRouteExpense || isUpdatingRouteExpense;

  return {
    step,
    setStep,
    firstStepForm,
    secondStepForm,
    selectedReport,
    handleClearSelection,
    handleUpdateExpenseDataBySelectedReport,
    isShowingModalLoading,
    showGoBackModal,
    setShowGoBackModal,
    handleSubmitReportForm,
    showSeparateReportWarningModal,
    confirmedReportInformationBySelectedReport,
    setShowSeparateReportWarningModal,
    setShowCreateReportForm,
    showPreRegisteredSectionsField,
    showCreateReportForm,
    handleGoBackButton,
    readOnlyFieldsWhenUserIsUpdatingRoute,
    userAction,
    handleChangeFormExpenseCurrency,
    updateFormAfterReportCreated,
    allowUserChangeExpenseProjectByReportProject,
    submitButtonDisabled,
    isExpenseCurrencyDifferentFromReportCurrency,
    handleClickOverlay,
    firstStepSelectedCostsCenterId,
    firstStepSelectedPaymentMethodId,
    firstStepSelectedProjectId,
    handleConfirmGoBackModal
  };
}
