import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUpdateCompanyPoliciesAndTermsFieldsForm,
  type IUseUpdateCompanyPoliciesAndTerms,
  type IUseUpdateCompanyPoliciesAndTermsParams,
  type UpdateCompanyOnboardingAnswersPayloadType
} from 'data/modules/cards/companyOnboarding';

import { useFormWithSchema } from 'shared/hooks/forms';
import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import { useUpdateCompanyPoliciesAndTermsSchema } from './schemas';

export function useUpdateCompanyPoliciesAndTerms({
  onUpdateCompanyOnboardingAnswersMutationSuccess
}: IUseUpdateCompanyPoliciesAndTermsParams): IUseUpdateCompanyPoliciesAndTerms {
  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const { data: cachedCompanyOnboardingAnswers } = useHandleQueryCache({
    query: {
      key: [
        CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
      ],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingAnswers({
          uuidCompanyOnboarding:
            cachedCompanyOnboardingProgress?.onboardingCompanyId as string
        })
    },
    params: {
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    }
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = useFormWithSchema(useUpdateCompanyPoliciesAndTermsSchema(), {
    defaultValues: {
      acceptPrivacyPolicy:
        cachedCompanyOnboardingAnswers?.companyTermsOfUse?.acceptPrivacyPolicy,
      acceptTermsOfUse:
        cachedCompanyOnboardingAnswers?.companyTermsOfUse?.acceptTermsOfUse
    }
  });

  const { isLoading, mutate } = useMutationCache({
    mutationKey: [
      CompanyOnboardingMutationKeys.UPDATE_COMPANY_ONBOARDING_ANSWERS
    ],
    mutationFn: async (payload: UpdateCompanyOnboardingAnswersPayloadType) => {
      await CompanyOnboardingService.updateCompanyOnboardingAnswers(payload);
    },
    onSuccess: (data, variables) => {
      onUpdateCompanyOnboardingAnswersMutationSuccess({
        ...variables.answers
      } as IUpdateCompanyPoliciesAndTermsFieldsForm);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function onSubmit(
    userEnteredData: IUpdateCompanyPoliciesAndTermsFieldsForm
  ): void {
    if (isLoading) {
      return;
    }

    if (
      !isDirty &&
      currentStep < Number(cachedCompanyOnboardingProgress?.currentStep)
    ) {
      onUpdateCompanyOnboardingAnswersMutationSuccess(userEnteredData);
      return;
    }

    mutate({
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '',
      step: currentStep,
      context: 'company_terms_of_use',
      answers: {
        acceptPrivacyPolicy: userEnteredData.acceptPrivacyPolicy,
        acceptTermsOfUse: userEnteredData.acceptTermsOfUse
      }
    });
  }

  return {
    handleUpdateCompanyPoliciesAndTerms: handleSubmit(onSubmit),
    isUpdatingCompanyPoliciesAndTerms: isLoading,
    control,
    isDirty,
    errors
  };
}
