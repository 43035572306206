import { Route } from 'react-router-dom';

import {
  BusinessAccountHolders,
  BusinessCards,
  EmailPreferences,
  FuelPolicy,
  ManageBusinessCard,
  Tariffs,
  TeamMembers,
  TermsOfUse,
  VehiclesList
} from 'presentation/pages/settings';
import { SettingsLayout } from 'presentation/templates';

import { SettingRoutes } from 'shared/constants/global';
import {
  AuthenticationMiddleware,
  CardsManagerMiddleware,
  CardsMiddleware,
  FuelMiddleware,
  ManagerMiddleware,
  TeamMembersMiddleware
} from 'shared/middlewares';

export const settingsRoutes = [
  <Route
    key='settings'
    element={<AuthenticationMiddleware />}
  >
    <Route>
      <Route
        path={SettingRoutes.SETTINGS_EMAIL_PREFERENCES}
        element={<SettingsLayout currentSettingsPage={<EmailPreferences />} />}
      />
    </Route>

    <Route>
      <Route element={<TeamMembersMiddleware />}>
        <Route
          path={SettingRoutes.SETTINGS_TEAM_MEMBERS}
          element={<TeamMembers />}
        />
      </Route>
    </Route>

    <Route>
      <Route element={<CardsManagerMiddleware />}>
        <Route element={<CardsMiddleware />}>
          <Route
            path={SettingRoutes.SETTINGS_CARDS_PJ}
            element={<SettingsLayout currentSettingsPage={<BusinessCards />} />}
          />
        </Route>
      </Route>
    </Route>

    <Route>
      <Route element={<CardsManagerMiddleware />}>
        <Route element={<CardsMiddleware />}>
          <Route
            path={SettingRoutes.SETTINGS_CARDS_PJ_ACCOUNT_ID}
            element={
              <SettingsLayout currentSettingsPage={<ManageBusinessCard />} />
            }
          />
        </Route>
      </Route>
    </Route>

    <Route>
      <Route element={<CardsManagerMiddleware />}>
        <Route element={<CardsMiddleware />}>
          <Route
            path={SettingRoutes.SETTINGS_CARDS_SHAREHOLDERS}
            element={
              <SettingsLayout
                currentSettingsPage={<BusinessAccountHolders />}
              />
            }
          />
        </Route>
      </Route>
    </Route>

    <Route>
      <Route element={<CardsManagerMiddleware />}>
        <Route element={<CardsMiddleware />}>
          <Route
            path={SettingRoutes.SETTINGS_CARDS_TARIFFS}
            element={<SettingsLayout currentSettingsPage={<Tariffs />} />}
          />
        </Route>
      </Route>
    </Route>

    <Route>
      <Route element={<CardsManagerMiddleware />}>
        <Route element={<CardsMiddleware />}>
          <Route
            path={SettingRoutes.SETTINGS_CARDS_TERMS_OF_USE}
            element={<SettingsLayout currentSettingsPage={<TermsOfUse />} />}
          />
        </Route>
      </Route>
    </Route>

    <Route>
      <Route element={<ManagerMiddleware />}>
        <Route element={<FuelMiddleware />}>
          <Route
            path={SettingRoutes.SETTINGS_FUEL_VEHICLES}
            element={<SettingsLayout currentSettingsPage={<VehiclesList />} />}
          />
        </Route>
      </Route>
    </Route>

    <Route>
      <Route element={<ManagerMiddleware />}>
        <Route element={<FuelMiddleware />}>
          <Route
            path={SettingRoutes.SETTINGS_FUEL_VEHICLES_ID}
            element={<SettingsLayout currentSettingsPage={<VehiclesList />} />}
          />
        </Route>
      </Route>
    </Route>

    <Route>
      <Route element={<ManagerMiddleware />}>
        <Route element={<FuelMiddleware />}>
          <Route
            path={SettingRoutes.SETTINGS_FUEL_POLICIES}
            element={<SettingsLayout currentSettingsPage={<FuelPolicy />} />}
          />
        </Route>
      </Route>
    </Route>
  </Route>
];
