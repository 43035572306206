import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 5rem);
  padding: 2rem 3rem;
  overflow: auto;
  background: ${({ theme }) => theme.colors.alabaster};
  border: 1px solid ${({ theme }) => theme.colors.mercury};

  th,
  td {
    padding: 1.2rem;
  }
`;
