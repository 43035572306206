import styled, { css } from 'styled-components';

import { BaseIcon } from 'presentation/components/base/Icon';

import {
  type DsFileInputIconStylesType,
  type DsFileInputStylesType
} from './DsFileInput.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<AddPrefixToType<DsFileInputStylesType>>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
  height: 6.4rem;
  min-width: 25rem;
  width: 100%;
  padding: ${({ theme }) => theme.tokens.spacingInsetXxs};
  border-radius: ${({ theme }) => theme.tokens.borderRadiusMd};
  background-color: ${({ theme }) => theme.tokens.neutralColorHighMedium};
  border: ${({ theme }) => theme.tokens.borderWidthThin} solid transparent;
  cursor: pointer;
  position: relative;

  ${({ $error }) =>
    $error &&
    css`
      background-color: ${({ theme }) => theme.tokens.feedbackColorErrorLight};
      border-color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
    `}

  ${({ $underlined }) =>
    $underlined &&
    css`
      background-color: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-color: ${({ theme }) => theme.tokens.neutralColorLowLight};
      border-radius: ${({ theme }) => theme.tokens.borderRadiusNone};
    `}

  ${({ $error, $underlined }) =>
    $error &&
    $underlined &&
    css`
      background-color: ${({ theme }) => theme.tokens.feedbackColorErrorLight};
      border-right: none;
      border-color: ${({ theme }) => theme.tokens.feedbackColorErrorPurest};
      border-radius: ${({ theme }) => theme.tokens.borderRadiusNone};
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${({ theme }) =>
        theme.tokens.neutralColorHighMediumest};
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    `}

  span {
    color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
    font-size: ${({ theme }) => theme.tokens.fontSizeXxs};
    font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};

    ${({ $error }) =>
      $error &&
      css`
        color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
      `}

    ${({ $disabled }) =>
      $disabled &&
      css`
        color: ${({ theme }) => theme.tokens.neutralColorLowLightest};
      `}
  }
`;

export const Message = styled.div<AddPrefixToType<DsFileInputStylesType>>`
  color: ${({ theme }) => theme.tokens.neutralColorLowPure};
  font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  max-width: 30rem;
  margin-top: 0.5rem;
  margin-left: ${({ $underlined }) => ($underlined ? '0' : '0.3rem')};

  ${({ $error }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.tokens.feedbackColorErrorPure};
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const IconFileInput = styled(BaseIcon)<
  AddPrefixToType<DsFileInputIconStylesType>
>`
  ${({ $attached }) =>
    $attached
      ? css`
          color: ${({ theme }) => theme.colors.danger};
        `
      : css`
          color: ${({ theme }) => theme.colors.danger};
        `}
`;

export const CustomFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;
