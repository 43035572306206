import { useInfiniteQueryCache } from 'data/cache';
import {
  FuelQueryKeys,
  FuelService,
  type IUseInfinitePaginatedMembers,
  type IUseInfinitePaginatedMembersParams
} from 'data/modules/fuel';

import { CustomObject } from 'shared/utils/custom';

export function useInfinitePaginatedMembers(
  params: IUseInfinitePaginatedMembersParams
): IUseInfinitePaginatedMembers {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQueryCache({
      queryKey: [
        FuelQueryKeys.GET_MEMBERS,
        CustomObject.omit(params, ['enabled'])
      ],
      initialPageParam: 1,
      queryFn: async ({ pageParam }) => {
        return await FuelService.getPaginatedMembers({
          ...CustomObject.omit(params, ['enabled']),
          page: pageParam
        });
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        const totalPages = Math.ceil(lastPage.total) / params.perPage;
        const isLastPage = allPages.length >= totalPages;

        if (isLastPage) {
          return null;
        }

        return lastPageParam + 1;
      },
      enabled: params.enabled
    });

  return {
    infiniteMembers: data?.pages.flatMap(page => page.data),
    isLoadingInfiniteMembers: isLoading,
    membersPagination: {
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage
    }
  };
}
