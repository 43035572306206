/**
 * A classe JsxHelper fornece um método para envolver tags HTML com elementos JSX especificados.
 */
import { Fragment } from 'react';

class JsxHelper {
  /**
   * Envolve tags HTML com elementos JSX, mantendo sua posição original na string de entrada.
   *
   * @param {string} str - A string de entrada contendo tags HTML.
   * @param {keyof JSX.IntrinsicElements} tag - A tag HTML a ser buscada e substituída.
   * @param {keyof JSX.IntrinsicElements} Wrapper - O elemento JSX para envolver o conteúdo da tag encontrada.
   * @returns {JSX.Element} - Elemento JSX com as tags HTML substituídas envoltas nos elementos JSX especificados.
   */
  putHtmlTag(
    str: string,
    tag: keyof JSX.IntrinsicElements = 'strong',
    Wrapper: keyof JSX.IntrinsicElements = 'strong'
  ): JSX.Element {
    // Expressão regular para encontrar a tag HTML especificada
    const regex = new RegExp(`<${tag}[^>]*>[^{]*?</${tag}>`, 'gi');

    // Usando matchAll para iterar por todas as ocorrências da tag na string de entrada
    const iterator = str.matchAll(regex);
    const match = iterator.next();

    const arr = [];
    if (!match.done) {
      const { value } = match;
      const index = value.index ?? 0;
      const text = value[0].replaceAll(/<[^>]*>/g, ''); // Extraindo o conteúdo da tag

      arr.push(
        <Fragment key=''>
          {/* Conteúdo antes da tag correspondente */}
          {str.substring(0, index)}
          {/* Envolvendo o conteúdo extraído no elemento JSX especificado */}
          {<Wrapper>{text}</Wrapper>}
          {this.putHtmlTag(
            str.substring(index + value[0].length, str.length), // Processar recursivamente a string restante
            tag,
            Wrapper
          )}
        </Fragment>
      );
    }

    // Retorna o elemento JSX com as tags substituídas ou a string original se nenhuma tag for encontrada
    return <>{arr.length ? arr : str}</>;
  }
}

export default new JsxHelper();
