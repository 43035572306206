import { useDraggableScrollComponent } from './useDraggableScrollComponent';

import { type IDraggableScrollComponentProps } from './DraggableScrollComponent.types';

import { Container } from './DraggableScrollComponent.styles';

export function DraggableScrollComponent({
  children,
  maxWidth
}: IDraggableScrollComponentProps): JSX.Element {
  const {
    containerRef,
    handleDrag,
    handleDragEnd,
    handleDragStart,
    isDragging
  } = useDraggableScrollComponent();

  return (
    <Container
      ref={containerRef}
      $maxWidth={maxWidth}
      $isDragging={isDragging}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDrag}
    >
      {children}
    </Container>
  );
}
