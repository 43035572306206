import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type ILegalNatureOption } from 'data/modules/auth';
import { type IUpdateCompanyDataFieldsForm } from 'data/modules/cards/companyOnboarding';
import { type ICnae } from 'data/modules/global';

import { Validate } from 'shared/utils/validation';

export function useUpdateCompanyDataSchema(): ObjectSchema<IUpdateCompanyDataFieldsForm> {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const updateCompanyDataSchema: ObjectSchema<IUpdateCompanyDataFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object({
        cnpj: yup
          .string()
          .required(lang.schemas.is_required[currentLangKey])
          .test({
            name: 'cnpj',
            test: Validate.cnpj,
            message: lang.schemas.invalid_CNPJ[currentLangKey]
          }),
        legalName: yup.string().required(fieldRequiredMessage),
        mainActivity: yup
          .mixed<ICnae | string>()
          .required(fieldRequiredMessage)
          .transform(({ value }) => value),
        legalNature: yup
          .mixed<ILegalNatureOption | string>()
          .required(fieldRequiredMessage)
          .transform(({ value }) => value),
        foundingDate: yup
          .string()
          .required(fieldRequiredMessage)
          .test({
            name: 'foundingDate',
            message: lang.schemas.invalid_date[currentLangKey],
            test: (enteredValue: string) => Validate.isDate(enteredValue)
          })
          .test({
            name: 'foundingDate',
            message: lang.schemas.date_must_not_be_future[currentLangKey],
            test: (value: string) => !Validate.isFutureDate(value)
          }),
        email: yup
          .string()
          .required(fieldRequiredMessage)
          .email(lang.schemas.invalid_email[currentLangKey])
          .test({
            name: 'email',
            message: lang.schemas.invalid_email[currentLangKey],
            test: value =>
              // eslint-disable-next-line no-useless-escape
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                value
              )
          }),
        phoneNumber: yup
          .string()
          .required(fieldRequiredMessage)
          .min(15, `${lang.schemas.min[currentLangKey]} 15`),
        contract: yup
          .mixed<FileList | string>()
          .required(fieldRequiredMessage)
          .test({
            name: 'isThereAFile',
            message: lang.schemas.is_required[currentLangKey],
            test: value => value.length > 0
          })
          .test({
            name: 'contractSize',
            message: lang.schemas.file_must_be_less_than_16mb[currentLangKey],
            test: value => {
              if (value instanceof FileList) {
                return value[0]?.size <= 16000000;
              }

              return true;
            }
          })
          .test({
            name: 'contractFileType',
            message: lang.schemas.file_must_be_pdf_type[currentLangKey],
            test: value => {
              if (value instanceof FileList) {
                return value && value[0].type === 'application/pdf';
              }

              return true;
            }
          })
      });
    }, [lang, currentLangKey]);

  return updateCompanyDataSchema;
}
