import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  margin-right: -1.6rem;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  background-color: ${tokens.brandColorPrimaryLightest};
  border-radius: 50%;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  background-color: ${tokens.brandColorPrimaryPure};
  border-radius: 50%;
`;

export const Description = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightRegular};
  line-height: 3.2rem;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${tokens.neutralColorLowDark};
`;

export const CallToAction = styled(Description)`
  margin: 1rem 0;
  font-weight: ${tokens.fontWeightSemibold};
`;
