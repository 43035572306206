import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3rem 3rem;
  gap: 2.5rem;
  max-width: 70rem;
  text-align: center;
`;

export const Divider = styled.div`
  border-bottom: ${({ theme }) => theme.tokens.neutralColorHighPurest} solid 1px;
  width: 100%;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: left;
`;
