import { useQueryCache } from 'data/cache';
import {
  type IUseGetUserOnboardingAnswers,
  type IUseGetUserOnboardingAnswersParams,
  type IUserOnboardingAnswers,
  UserOnboardingQueryKeys,
  UserOnboardingService
} from 'data/modules/cards/userOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';

export function useGetUserOnboardingAnswers({
  enabled
}: IUseGetUserOnboardingAnswersParams): IUseGetUserOnboardingAnswers {
  const { data: cachedUserOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () =>
        await UserOnboardingService.getUserOnboardingProgress(),
      key: [UserOnboardingQueryKeys.GET_USER_ONBOARDING_PROGRESS]
    }
  });

  const { data, isLoading } = useQueryCache<IUserOnboardingAnswers>({
    queryKey: [
      UserOnboardingQueryKeys.GET_USER_ONBOARDING_ANSWERS,
      cachedUserOnboardingProgress?.onboardingUserId ?? ''
    ],
    queryFn: async () =>
      await UserOnboardingService.getUserOnboardingAnswers({
        onboardingUserId: cachedUserOnboardingProgress?.onboardingUserId ?? ''
      }),
    enabled
  });

  return {
    isUserOnboardingAnswersLoading: enabled && isLoading,
    userOnboardingAnswers: data
  };
}
