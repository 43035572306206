import { useEffect, useMemo, useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import {
  type ICreateManualExpenseSecondStepFieldsForm,
  useGetExpenseParameters
} from 'data/modules/expenses';
import { useGetExpenseDetails } from 'data/modules/expenses/useCases/get-expense-details/useGetExpenseDetails';
import {
  type ReportInformationType,
  useGetOpenReports
} from 'data/modules/reports';

import { useDebounce } from 'shared/hooks/global';
import { CustomObject } from 'shared/utils/custom';

import {
  type IUseSecondStepForm,
  type IUseSecondStepFormParams
} from './SecondStepForm.types';

export function useSecondStepForm({
  onUpdateExpenseDataBySelectedReport,
  confirmedReportInformationBySelectedReport,
  firstStepSelectedCostsCenterId,
  firstStepSelectedPaymentMethodId,
  firstStepSelectedProjectId,
  expenseUuidToUpdate
}: IUseSecondStepFormParams): IUseSecondStepForm {
  const [searchTerm, setSearchTerm] = useState('');

  const { isLoadingOpenReports, openReports } = useGetOpenReports();

  const debouncedSearchTerm = useDebounce(searchTerm);

  const [
    reportInformationBySelectedReport,
    setReportInformationBySelectedReport
  ] = useState<ReportInformationType | null>(null);

  const [showReportInformationAlert, setShowReportInformationAlert] =
    useState(false);

  const { expenseParameters, isLoadingExpenseParameters } =
    useGetExpenseParameters();

  const [
    reportUuidFromExpenseToUpdateInFirstLine,
    setReportUuidFromExpenseToUpdateInFirstLine
  ] = useState<string | undefined>(undefined);

  const filteredOpenReports = useMemo(() => {
    return (
      openReports?.filter(
        ({ title, uuid }) =>
          title.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          uuid
            .slice(0, 6)
            .toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase())
      ) || []
    );
  }, [openReports, debouncedSearchTerm]);

  const { getEnsuredExpenseDetails, isFetchingAndPendingExpenseDetails } =
    useGetExpenseDetails({
      expenseUuid: expenseUuidToUpdate ?? '',
      enabled: false
    });

  async function loadReportFromExpenseToUpdate(): Promise<void> {
    const expenseToUpdate = await getEnsuredExpenseDetails({
      expenseUuid: expenseUuidToUpdate as string
    });

    const reportUuid = expenseToUpdate?.report?.uuid;

    setReportUuidFromExpenseToUpdateInFirstLine(reportUuid);
  }

  const reportObjectFromExpenseToUpdateInFirstLine = openReports?.find(
    ({ uuid }) => uuid === reportUuidFromExpenseToUpdateInFirstLine
  );

  const adjustedFilteredReports =
    reportUuidFromExpenseToUpdateInFirstLine &&
    reportObjectFromExpenseToUpdateInFirstLine
      ? [
          reportObjectFromExpenseToUpdateInFirstLine,
          ...filteredOpenReports.filter(
            ({ uuid }) => uuid !== reportUuidFromExpenseToUpdateInFirstLine
          )
        ]
      : filteredOpenReports;

  useEffect(() => {
    if (expenseUuidToUpdate) {
      loadReportFromExpenseToUpdate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseUuidToUpdate]);

  const { control } =
    useFormContext<ICreateManualExpenseSecondStepFieldsForm>();

  const selectedReport = useWatch({
    control,
    name: 'report'
  });

  function handleChangeSearchTerm(value: string): void {
    setSearchTerm(value);
    setReportUuidFromExpenseToUpdateInFirstLine(undefined);
  }

  useEffect(() => {
    if (
      !!selectedReport &&
      confirmedReportInformationBySelectedReport === null
    ) {
      const reportInformation = filteredOpenReports?.find(
        ({ uuid }) => uuid === selectedReport
      );

      if (reportInformation) {
        setReportInformationBySelectedReport(
          CustomObject.pick(reportInformation, [
            'costsCenter',
            'paymentMethod',
            'project',
            'uuid'
          ])
        );

        setShowReportInformationAlert(true);
      }
      return;
    }

    setReportInformationBySelectedReport(null);
    setShowReportInformationAlert(false);
  }, [
    selectedReport,
    confirmedReportInformationBySelectedReport,
    filteredOpenReports
  ]);

  function handleClickReportItem(uuid: string): void {
    if (uuid === confirmedReportInformationBySelectedReport?.uuid) {
      return;
    }

    const reportInformation = filteredOpenReports?.find(
      ({ uuid: reportUuid }) => reportUuid === uuid
    );

    if (
      expenseParameters?.companyApprovalType === 'CC' &&
      reportInformation &&
      reportInformation?.costsCenter !== null
    ) {
      onUpdateExpenseDataBySelectedReport({
        costsCenter: reportInformation.costsCenter,
        paymentMethod: null,
        project: null,
        uuid: reportInformation.uuid
      });
      return;
    }

    onUpdateExpenseDataBySelectedReport(null);
  }

  const reportCurrencyIsoCode =
    openReports?.find(({ uuid }) => uuid === selectedReport)?.currency
      ?.isoCode || '';

  const isSelectedCostsCenterDifferentFromReportCostsCenter = useMemo(() => {
    return (
      reportInformationBySelectedReport !== null &&
      reportInformationBySelectedReport.costsCenter !== null &&
      String(reportInformationBySelectedReport.costsCenter.id) !==
        firstStepSelectedCostsCenterId
    );
  }, [firstStepSelectedCostsCenterId, reportInformationBySelectedReport]);

  const isSelectedPaymentMethodDifferentFromReportPaymentMethod =
    useMemo(() => {
      return (
        reportInformationBySelectedReport !== null &&
        reportInformationBySelectedReport.paymentMethod !== null &&
        String(reportInformationBySelectedReport.paymentMethod.id) !==
          firstStepSelectedPaymentMethodId
      );
    }, [firstStepSelectedPaymentMethodId, reportInformationBySelectedReport]);

  const isSelectedProjectDifferentFromReportProject = useMemo(() => {
    return (
      reportInformationBySelectedReport !== null &&
      reportInformationBySelectedReport.project !== null &&
      String(reportInformationBySelectedReport.project.id) !==
        firstStepSelectedProjectId
    );
  }, [firstStepSelectedProjectId, reportInformationBySelectedReport]);

  const userCanChangeExpenseDataBySelectedReport = useMemo(() => {
    return (
      reportInformationBySelectedReport !== null &&
      (isSelectedCostsCenterDifferentFromReportCostsCenter ||
        isSelectedPaymentMethodDifferentFromReportPaymentMethod ||
        isSelectedProjectDifferentFromReportProject)
    );
  }, [
    isSelectedCostsCenterDifferentFromReportCostsCenter,
    isSelectedPaymentMethodDifferentFromReportPaymentMethod,
    isSelectedProjectDifferentFromReportProject,
    reportInformationBySelectedReport
  ]);

  const showAlertInfoWithCompanyApprovalTypeByCostsCenter =
    expenseParameters?.companyApprovalType === 'CC' &&
    confirmedReportInformationBySelectedReport !== null &&
    confirmedReportInformationBySelectedReport.costsCenter !== null &&
    String(confirmedReportInformationBySelectedReport.costsCenter.id) !==
      firstStepSelectedCostsCenterId;

  return {
    searchTerm,
    filteredOpenReports: adjustedFilteredReports,
    isLoadingOpenReports:
      isLoadingOpenReports ||
      isFetchingAndPendingExpenseDetails ||
      isLoadingExpenseParameters,
    reportInformationBySelectedReport,
    showReportInformationAlert,
    setShowReportInformationAlert,
    showAlertInfoWithCompanyApprovalTypeByCostsCenter,
    userCanChangeExpenseDataBySelectedReport,
    isSelectedCostsCenterDifferentFromReportCostsCenter,
    isSelectedPaymentMethodDifferentFromReportPaymentMethod,
    isSelectedProjectDifferentFromReportProject,
    handleChangeSearchTerm,
    reportCurrencyIsoCode,
    handleClickReportItem
  };
}
