import { useLangContext } from 'data/contexts';

import { Container } from './BusinessCardsHead.styles';

export function BusinessCardsHead(): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  return (
    <Container>
      <th>{lang.global.surname[currentLangKey]}</th>
      <th></th>
    </Container>
  );
}
