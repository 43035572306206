import { type SetStateAction, useState } from 'react';

import { useLangContext, useModalContext } from 'data/contexts';
import { type IBusinessCardProps } from 'data/modules/cards/businessCard';

import { Title } from 'presentation/components/global/Title';

import { BusinessCardsSkeleton } from './components/BusinessCardsSkeleton';
import { BusinessCardsTable } from './components/BusinessCardsTable';
import {
  ActivateBusinessCardModal,
  AttachTeamMembersToBusinessCardModal,
  CreateBusinessCardModal,
  SetUpAccountabilityModal
} from './components/Modal';
import { useBusinessCards } from './useBusinessCards';

export function BusinessCards(): JSX.Element {
  const [settings, currentLangKey] = useLangContext(state => [
    state.lang.settings,
    state.currentLangKey
  ]);

  const { handleOpenModal, visibleModal } = useModalContext();

  const {
    businessCardsSearchParams,
    bussinessCardsList,
    isLoadingBusinessCards,
    totalBusinessCards,
    handleChangeSearchParam,
    accountId,
    onChangeAccountId
  } = useBusinessCards();

  const [businessCardProps, setBusinessCardProps] =
    useState<IBusinessCardProps>({
      nameCard: '',
      uuid: ''
    });

  return (
    <>
      <Title
        text={settings.businessCards.business_cards[currentLangKey]}
        buttonProps={{
          children: settings.businessCards.new_business_card[currentLangKey],
          color: 'success',
          size: 'md',
          onClick: () => {
            handleOpenModal('createBusinessCard');
          }
        }}
      />
      {isLoadingBusinessCards ? (
        <BusinessCardsSkeleton />
      ) : (
        <BusinessCardsTable
          businessCards={bussinessCardsList}
          handleChangeSearchParam={handleChangeSearchParam}
          businessCardsSearchParams={businessCardsSearchParams}
          totalBusinessCards={totalBusinessCards}
          onChangeAccountId={({ id, backOption }) => {
            onChangeAccountId({ id, backOption });
          }}
        />
      )}

      {visibleModal === 'createBusinessCard' && (
        <CreateBusinessCardModal
          onSetBusinessCardProps={(
            value: SetStateAction<IBusinessCardProps>
          ) => {
            setBusinessCardProps(value);
          }}
          onChangeAccountId={({ id, backOption }) => {
            onChangeAccountId({ id, backOption });
          }}
        />
      )}

      {visibleModal === 'setUpAccountability' && (
        <SetUpAccountabilityModal
          nameCard={businessCardProps?.nameCard}
          uuid={businessCardProps?.uuid}
        />
      )}

      {visibleModal === 'teamMember' && (
        <AttachTeamMembersToBusinessCardModal uuid={businessCardProps?.uuid} />
      )}

      {visibleModal === 'activateBusinessCard' && (
        <ActivateBusinessCardModal
          accountId={accountId.id}
          backOption={accountId.backOption}
        />
      )}
    </>
  );
}
