import { useState } from 'react';

import { CustomCookies } from 'shared/utils/global';

import { type IUseDefaultLayout } from './DefaultLayout.types';

export function useDefaultLayout(): IUseDefaultLayout {
  const controlSidebar = CustomCookies.get('sidebar');

  if (window.innerWidth < 1280) {
    CustomCookies.set('sidebar', 'collapsed', {
      daysToExpire: 2
    });
  }

  const [sidebarIsCollapsed, setSidebarIsCollapsed] = useState(
    controlSidebar === 'collapsed'
  );

  sidebarIsCollapsed &&
    CustomCookies.set('sidebar', sidebarIsCollapsed ? 'collapsed' : '', {
      daysToExpire: 2
    });

  return {
    sidebarIsCollapsed,
    setSidebarIsCollapsed
  };
}
