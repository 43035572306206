import { type FC, type ReactNode } from 'react';

import { SkeletonLoader } from 'ds/components/SkeletonLoader/SkeletonLoader.component';
import { useDsGa4 } from 'ds/hooks/globals';
import { DSIcons } from 'ds/icons';
import {
  type ActionMeta,
  components,
  type DropdownIndicatorProps,
  type InputActionMeta,
  type MenuProps,
  type OptionProps
} from 'react-select';
import { type FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { useSubMenuSelectDefault } from './useSubMenuSelectDefault';

import {
  type ISubMenuSelectDefaultOption,
  type ISubMenuSelectDefaultProps,
  type OnChangeSubMenuSelectDefaultType
} from './SubMenuSelectDefault.types';

import {
  Caption,
  Container,
  Fieldset,
  Label,
  Legend,
  MenuInformation,
  StyledSelect,
  Wrapper
} from './SubMenuSelectDefault.styles';

const Menu: FC<MenuProps & { information?: string }> = ({
  children,
  information,
  ...props
}): ReactNode => {
  return (
    <components.Menu {...props}>
      {Boolean(information) && <MenuInformation>{information}</MenuInformation>}
      {children}
    </components.Menu>
  );
};

export function SubMenuSelectDefault({
  name,
  options,
  value,
  error,
  disabled,
  className,
  placeholder,
  information,
  noOptionsMessage,
  onChange,
  onInputChange,
  hasArrowDownIndicator = false,
  isLoading = false,
  label,
  large = false,
  small = false,
  menuPlacement = 'bottom'
}: ISubMenuSelectDefaultProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  const {
    searchValue,
    setSearchValue,
    menuIndex,
    selectedOption,
    setMenuIndex,
    setSelectedOption
  } = useSubMenuSelectDefault();

  const currentOptions =
    menuIndex !== undefined ? options.options[menuIndex] : options.menuOptions;

  const Option: FC<OptionProps> = (props): ReactNode => {
    const option = (props.data as ISubMenuSelectDefaultOption).value;
    const isVisible = value === option;

    if (menuIndex === undefined) {
      return (
        <components.Option {...props}>
          {props.children} <DSIcons.ArrowRightIcon />
        </components.Option>
      );
    }

    if (isVisible) return <></>;

    return <components.Option {...props}>{props.children}</components.Option>;
  };

  const DropdownIndicator: FC<DropdownIndicatorProps> = (props): ReactNode => {
    return (
      <components.DropdownIndicator {...props}>
        <DSIcons.ChevronIcon />
      </components.DropdownIndicator>
    );
  };

  const componentToRender = (
    value: string,
    onChange: OnChangeSubMenuSelectDefaultType
  ): JSX.Element => (
    <div>
      <Wrapper
        $large={large}
        $small={small}
      >
        <StyledSelect
          $large={large}
          isClearable
          $error={error}
          $small={small}
          $isTouched={!!value && !disabled}
          $disabled={disabled}
          $hasInformation={Boolean(information)}
          components={{
            DropdownIndicator,
            Option,
            Menu: props => (
              <Menu
                {...props}
                information={information}
              />
            )
          }}
          name={name}
          classNamePrefix={'react-select-custom'}
          isSearchable
          placeholder={!disabled ? placeholder ?? 'Selecione...' : placeholder}
          menuPlacement={menuPlacement}
          isDisabled={disabled}
          options={
            selectedOption && menuIndex
              ? [selectedOption, ...currentOptions]
              : currentOptions
          }
          className={className}
          isOptionSelected={(option: unknown) => {
            const typedOption = option as ISubMenuSelectDefaultOption;
            const typedValue = value;
            return typedValue === typedOption.value;
          }}
          inputValue={searchValue}
          onInputChange={(value: string, action: InputActionMeta) => {
            if (action?.action !== 'menu-close') {
              const inputValue = onInputChange?.(value) ?? value;

              setSearchValue(inputValue);

              sendDsGaEvent('components', 'dropdown', {
                description: 'Digitar texto',
                eventAction: 'text_dropdown',
                eventLabel: label ?? 'text_dropdown',
                eventValue: value
              });
            }
          }}
          filterOption={(option: FilterOptionOption<unknown>, input: string) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          value={selectedOption ?? null}
          $hasArrowDownIndicator={hasArrowDownIndicator}
          onChange={(newValue: unknown, action: ActionMeta<unknown>) => {
            if (action.action === 'clear') {
              onChange('');
              setSelectedOption(undefined);
              return;
            }

            const typedNewValue = newValue as ISubMenuSelectDefaultOption;

            if (menuIndex === undefined) {
              setMenuIndex(
                options.menuOptions.findIndex(
                  item => item.value === typedNewValue.value
                )
              );

              return;
            }

            setMenuIndex(undefined);

            setSelectedOption(typedNewValue);

            onChange(typedNewValue?.value ? typedNewValue.value : null);

            typedNewValue?.value &&
              sendDsGaEvent('components', 'dropdown', {
                description: 'Click nas opções',
                eventAction: 'select_dropdown',
                eventLabel: label ?? 'dropdown_without_label',
                eventValue: typedNewValue.value
              });
          }}
          closeMenuOnSelect={menuIndex !== undefined}
          noOptionsMessage={() => noOptionsMessage ?? 'Sem mais opções'}
          onMenuOpen={() => {
            sendDsGaEvent('components', 'dropdown', {
              description: 'Abrir a lista de opções',
              eventAction: 'list_dropdown',
              eventLabel: 'open_list_dropdown'
            });
          }}
          onMenuClose={() => {
            setMenuIndex(undefined);

            sendDsGaEvent('components', 'dropdown', {
              description: 'Fechar lista de opções',
              eventAction: 'list_dropdown',
              eventLabel: 'close_list_dropdown'
            });
          }}
        />

        <Fieldset>
          <Legend>
            <span>{label}</span>
          </Legend>
        </Fieldset>

        {label && <Label>{label}</Label>}
      </Wrapper>

      {error && <Caption $error={error}>{error}</Caption>}
    </div>
  );

  return (
    <Container>
      {isLoading ? (
        <SkeletonLoader height='3rem' />
      ) : (
        componentToRender(value as string, onChange)
      )}
    </Container>
  );
}
