import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import {
  BalanceMutationKeys,
  BalanceQueryKeys,
  BalanceService,
  type IUseRemoveAllCacheBalanceSolicitations
} from 'data/modules/cards/balance';

export function useRemoveAllCacheBalanceSolicitations(
  showSuccessMesage: boolean = true
): IUseRemoveAllCacheBalanceSolicitations {
  const { lang, currentLangKey } = useLangContext();
  const queryClient = useQueryClient();

  const {
    mutate: removeAllBalanceSolicitationsCache,
    isSuccess,
    isLoading: isRemovingAllBalanceSolicitationsCache
  } = useMutationCache({
    mutationKey: [BalanceMutationKeys.REMOVE_ALL_BALANCE_SOLICITATION_CACHE],
    mutationFn: async () =>
      await BalanceService.removeAllBalanceSolicitationsCache(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [BalanceQueryKeys.GET_CACHED_BALANCE_SOLICITATIONS]
      });
      showSuccessMesage &&
        toast.success(
          lang.cards.valueSolicitation.changes_successfully_undone[
            currentLangKey
          ]
        );
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  return {
    removeAllBalanceSolicitationsCache,
    isRemovingAllBalanceSolicitationsCache,
    isSuccess
  };
}
