import {
  type IPersistenceTransaction,
  type ITransaction
} from 'data/modules/cards/statement';

import { CustomObject } from 'shared/utils/custom';

class TransactionMapper {
  constructor() {
    this.toDomain = this.toDomain.bind(this);
  }

  toDomain(persistenceTransaction: IPersistenceTransaction): ITransaction {
    return {
      cardNumber: persistenceTransaction.card_number,
      currencyCode: persistenceTransaction.currency_code,
      debitCredit: persistenceTransaction.debit_credit,
      finalBillingAmount: persistenceTransaction.final_billing_amount,
      finalBillingCurrencyCode:
        persistenceTransaction.final_billing_currency_code,
      finalBillingCurrency: persistenceTransaction.final_billing_currency,
      billingAmountAuthorized: persistenceTransaction.billing_amount_authorized,
      isInternational: persistenceTransaction.is_international,
      merchantName: persistenceTransaction.merchant_name,
      isReversal: persistenceTransaction.is_reversal,
      paymentDescription: persistenceTransaction.payment_description,
      reversalAmount: persistenceTransaction.reversal_amount,
      transactionId: persistenceTransaction.transaction_id,
      transferorName: persistenceTransaction.transferor_name,
      mcc: Number(persistenceTransaction.mcc),
      relateds:
        persistenceTransaction.relateds &&
        persistenceTransaction.relateds.length > 0
          ? persistenceTransaction.relateds.map(this.toDomain)
          : [],
      ...CustomObject.pick(persistenceTransaction, [
        'amount',
        'currency',
        'datetime',
        'reversed',
        'type',
        'visible'
      ])
    };
  }
}

export default new TransactionMapper();
