import { useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { toast } from 'ds/utils';
import { useTranslation } from 'react-i18next';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import { type IErrorDefault } from 'data/contexts/modal/useModalContext.types';
import {
  type ApportionmentErrorType,
  ExpensesService,
  type IApportionmentPayload,
  type ICreateManualExpenseFirstStepFieldsForm,
  type ICreateManualExpensePayload,
  type ICreateManualExpenseSecondStepFieldsForm,
  type ICreateOrUpdateManualExpenseResponseError,
  type IUpdateManualExpensePayload,
  type IUseCreateManualExpense,
  type IUseCreateManualExpenseParams,
  useGetExpenseParameters
} from 'data/modules/expenses';
import { ExpensesValidate } from 'data/modules/expenses';
import {
  ExpensesMutationKeys,
  ExpensesQueryKeys
} from 'data/modules/expenses/keys/expenses.keys';
import { useGetExpenseDetails } from 'data/modules/expenses/useCases/get-expense-details/useGetExpenseDetails';
import { type IDefaultResponse } from 'data/modules/global';
import { useGetPaymentMethods } from 'data/modules/paymentMethods';
import { ReportsQueryKeys } from 'data/modules/reports';

import { useFormWithSchema } from 'shared/hooks/forms';
import { CustomDate, CustomObject } from 'shared/utils/custom';
import { ErrorHandle } from 'shared/utils/errors';
import { Currency } from 'shared/utils/format';

import { useFirstStepFormSchema, useSecondStepFormSchema } from './schemas';

export function useCreateManualExpense({
  onAfterSubmitFirstStep,
  onSuccessCreateManualExpense,
  onSuccessUpdateManualExpense,
  onErrorCreateOrUpdateManualExpense,
  onPolicyErrorCreateManualExpense,
  onErrorUpdateManualExpense,
  confirmedReportInformationBySelectedReport,
  expenseUuidToUpdate
}: IUseCreateManualExpenseParams): IUseCreateManualExpense {
  const { lang, currentLangKey } = useLangContext();

  const { expenseParameters, isLoadingExpenseParameters } =
    useGetExpenseParameters();

  const { getEnsuredExpenseDetails } = useGetExpenseDetails({
    expenseUuid: expenseUuidToUpdate ?? '',
    enabled: false
  });

  const queryClient = useQueryClient();

  const createManualExpenseFirstStepData =
    useRef<ICreateManualExpenseFirstStepFieldsForm | null>(null);

  const { paymentMethods } = useGetPaymentMethods();

  const { t } = useTranslation('expenses');

  const manualExpensePayloadToSendAfterConfirmationOutOfPolicy =
    useRef<ICreateManualExpensePayload | null>(null);

  const defaultCurrencyFromParameter =
    expenseParameters?.defaultCurrency.user ??
    expenseParameters?.defaultCurrency.company;

  const valuesToCreateNewExpense = {
    currency: defaultCurrencyFromParameter
      ? String(defaultCurrencyFromParameter)
      : '',
    apportionment: [],
    costsCenter: undefined,
    date: CustomDate.formatDateToIso(new Date()),
    description: '',
    observation: '',
    paymentMethod: '',
    receipt: null,
    refundable: false,
    type: '',
    value: '',
    observationAttachments: []
  } as ICreateManualExpenseFirstStepFieldsForm;

  const firstStepMethods = useFormWithSchema(
    useFirstStepFormSchema({
      userAction: expenseUuidToUpdate ? 'update' : 'create'
    }),
    {
      defaultValues: {
        paymentMethod: undefined,
        receipt: null,
        refundable: false
      },
      values: expenseUuidToUpdate ? undefined : valuesToCreateNewExpense
    }
  );

  const secondStepMethods = useFormWithSchema(useSecondStepFormSchema(), {
    defaultValues: {
      report: ''
    }
  });

  const {
    isLoading: isCreatingManualExpense,
    mutateAsync: createManualExpenseAsync
  } = useMutationCache({
    mutationKey: [ExpensesMutationKeys.CREATE_MANUAL_EXPENSE],
    mutationFn: async (payload: ICreateManualExpensePayload) => {
      await ExpensesService.createManualExpense(payload);
    }
  });

  const {
    isLoading: isUpdatingManualExpense,
    mutateAsync: updateManualExpenseAsync
  } = useMutationCache({
    mutationKey: [ExpensesMutationKeys.UPDATE_MANUAL_EXPENSE],
    mutationFn: async (payload: IUpdateManualExpensePayload) =>
      await ExpensesService.updateManualExpense(payload)
  });

  async function onSubmitCreateManualExpenseFirstStep(
    data: ICreateManualExpenseFirstStepFieldsForm
  ): Promise<void> {
    if (
      expenseParameters?.isProjectFieldMandatory &&
      (data.apportionment === undefined || data.apportionment.length === 0)
    ) {
      toast.error(
        lang.expenses.modal.create_manual_expense.project_selection_error[
          currentLangKey
        ]
      );
      return;
    }

    if (data.apportionment && data.apportionment?.length > 0) {
      const checkApportionment = ExpensesValidate.checkApportionment(
        data.apportionment,
        Currency.parseToFloat(data.value)
      );

      if (checkApportionment !== null) {
        const errorMessages: Record<ApportionmentErrorType, string> = {
          'percentage-is-not-100':
            lang.expenses.modal.create_manual_expense
              .sum_apportionment_project_error[currentLangKey],
          'sum-of-values': t(
            'errors.theSumOfTheApportionmentValuesMustBeEqualToTheExpenseValue'
          ),
          'duplicate-projects':
            lang.expenses.modal.create_manual_expense
              .duplicate_projects_in_the_apportionment_error[currentLangKey]
        };

        toast.error(errorMessages[checkApportionment]);
        return;
      }
    }

    // usuário está editando uma despesa
    if (expenseUuidToUpdate) {
      const expenseToUpdate = await getEnsuredExpenseDetails({
        expenseUuid: expenseUuidToUpdate
      });

      const expenseValueAsNumber = Currency.parseToFloat(data.value);
      const expenseValueAsPositiveNumber = Math.abs(expenseValueAsNumber);

      const expenseTransactionValue: number =
        expenseToUpdate.transaction &&
        expenseToUpdate.transaction.isInternational
          ? expenseToUpdate.transaction.finalInternationalAmount ?? 0
          : expenseToUpdate.transaction?.finalBillingAmount ?? 0;

      if (
        expenseToUpdate.transaction &&
        expenseValueAsPositiveNumber > expenseTransactionValue
      ) {
        firstStepMethods.setError('value', {
          type: 'max-value',
          message: t('errors.expenseAmountCannotExceedTransactionValue', {
            currency: expenseToUpdate.currency.isoCode,
            value: Currency.format('BRL', expenseTransactionValue)
          })
        });

        return;
      }

      if (
        expenseToUpdate.transaction &&
        expenseTransactionValue &&
        expenseValueAsPositiveNumber <= expenseTransactionValue
      ) {
        firstStepMethods.clearErrors('value');
      }
    }

    // salva a primeira etapa do formulário para depois enviar a request
    createManualExpenseFirstStepData.current = data;

    onAfterSubmitFirstStep?.();
  }

  const allowUserChangeExpenseProjectByReportProject =
    createManualExpenseFirstStepData.current !== null &&
    (createManualExpenseFirstStepData.current.apportionment === undefined ||
      createManualExpenseFirstStepData.current.apportionment.length === 0 ||
      createManualExpenseFirstStepData.current.apportionment.length === 1);

  async function createManualExpense(
    data: ICreateManualExpensePayload
  ): Promise<void> {
    try {
      await createManualExpenseAsync(data);

      onSuccessCreateManualExpense?.();

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES]
      });

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
      });

      queryClient.removeQueries({
        queryKey: [ReportsQueryKeys.GET_OPEN_REPORTS]
      });
    } catch (e) {
      const responseError = e as AxiosError<
        IDefaultResponse<ICreateOrUpdateManualExpenseResponseError>
      >;

      const responseData = responseError.response?.data.data;

      // verifica se a resposta da API é sobre a despesa estar fora da política
      if (
        responseData !== undefined &&
        responseData.allowExceedLimit !== undefined
      ) {
        // salva os dados a serem enviados caso o usuário confirme que quer enviar a despesa fora da política
        manualExpensePayloadToSendAfterConfirmationOutOfPolicy.current = data;

        // chama a função de erro para mostrar o modal de despesa fora da política
        onPolicyErrorCreateManualExpense?.(
          responseData,
          data.type,
          !!data.observation
        );
        return;
      }

      const errorMessage =
        ErrorHandle.getErrorMessage(e as IErrorDefault) ??
        t('messages.anErrorOccurredWhenRegisteringManualExpense');

      onErrorCreateOrUpdateManualExpense?.(errorMessage);
    }
  }

  async function updateManualExpense(
    data: IUpdateManualExpensePayload
  ): Promise<void> {
    try {
      const updatedManualExpense = await updateManualExpenseAsync(data);

      onSuccessUpdateManualExpense?.();

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES]
      });

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
      });

      queryClient.setQueryData(
        [ExpensesQueryKeys.GET_EXPENSE, expenseUuidToUpdate],
        updatedManualExpense
      );

      queryClient.removeQueries({
        queryKey: [ReportsQueryKeys.GET_OPEN_REPORTS]
      });
    } catch (e) {
      const responseError = e as AxiosError<
        IDefaultResponse<ICreateOrUpdateManualExpenseResponseError>
      >;

      const responseData = responseError.response?.data.data;

      // verifica se a resposta da API é sobre a despesa estar fora da política
      if (
        responseData !== undefined &&
        responseData.allowExceedLimit !== undefined
      ) {
        // salva os dados a serem enviados caso o usuário confirme que quer enviar a despesa fora da política
        manualExpensePayloadToSendAfterConfirmationOutOfPolicy.current = data;

        // chama a função de erro para mostrar o modal de despesa fora da política
        onErrorUpdateManualExpense?.(
          responseData,
          data.type,
          !!data.observation
        );
        return;
      }

      const errorMessage =
        ErrorHandle.getErrorMessage(e as IErrorDefault) ??
        t('messages.anErrorOccurredWhenUpdatingManualExpense');

      onErrorCreateOrUpdateManualExpense?.(errorMessage);
    }
  }

  async function onSubmitCreateManualExpenseSecondStep(
    data: ICreateManualExpenseSecondStepFieldsForm
  ): Promise<void> {
    const formData = {
      ...(createManualExpenseFirstStepData.current as ICreateManualExpenseFirstStepFieldsForm),
      ...data
    };

    let apportionment: IApportionmentPayload[] | undefined;

    // se o usuário selecionou um relatório e confirmou a alteração dos dados, então eu preciso considerar o projeto do relatório no rateio
    if (
      allowUserChangeExpenseProjectByReportProject &&
      confirmedReportInformationBySelectedReport &&
      confirmedReportInformationBySelectedReport.project
    ) {
      // caso o usuário tenha colocado um projeto no rateio, então considera os dados do projeto do relatório
      if (
        formData.apportionment !== undefined &&
        formData.apportionment.length === 1
      ) {
        apportionment = [
          {
            ...formData.apportionment[0],
            project: String(
              confirmedReportInformationBySelectedReport.project.id
            )
          }
        ];
      } else {
        // caso o usuário não tenha definido rateio, então eu vou considerar o valor total da despesa para o projeto do relatório
        apportionment = [
          {
            project: String(
              confirmedReportInformationBySelectedReport.project.id
            ),
            percentage: 100,
            value: Currency.parseToFloat(formData.value)
          }
        ];
      }
    } else {
      apportionment = formData.apportionment?.map(item => ({
        ...item,
        project: item.project?.value ?? ''
      }));
    }

    const hasReportPaymentMethod =
      confirmedReportInformationBySelectedReport !== null &&
      confirmedReportInformationBySelectedReport.paymentMethod !== null;

    // caso o relatório tenha uma forma de pagamento, o valor do campo reembolsável é o da forma de pagamento do relatório
    const isReportPaymentMethodRefundable =
      (hasReportPaymentMethod &&
        paymentMethods !== undefined &&
        paymentMethods.find(
          item =>
            String(item.id) ===
            String(
              confirmedReportInformationBySelectedReport?.paymentMethod?.id
            )
        )?.refundable) ??
      false;

    const apportionmentAdjusted =
      ExpensesValidate.adjustApportionmentPercentageBeforeSendToApi(
        apportionment,
        Currency.parseToFloat(formData.value)
      );

    const mergedFormDataWithReportData: ICreateManualExpensePayload =
      CustomObject.exchangeNullValues({
        ...formData,
        costsCenter: formData.costsCenter?.value ?? '',
        receipt: formData.receipt ?? null,
        observationAttachments: formData.observationAttachments ?? undefined,
        refundable: formData.refundable ?? undefined,
        apportionment: apportionmentAdjusted,
        // se o usuário selecionou um relatório, confirmou a alteração dos dados e o relatório tem um centro de custo, então eu vou considerar o centro de custo do relatório
        ...(confirmedReportInformationBySelectedReport &&
        confirmedReportInformationBySelectedReport.costsCenter
          ? {
              costsCenter: String(
                confirmedReportInformationBySelectedReport.costsCenter?.id
              )
            }
          : {}),
        // se o usuário selecionou um relatório, confirmou a alteração dos dados e o relatório tem um método de pagamento, então eu vou considerar o método de pagamento do relatório
        ...(confirmedReportInformationBySelectedReport &&
        confirmedReportInformationBySelectedReport.paymentMethod
          ? {
              paymentMethod: String(
                confirmedReportInformationBySelectedReport.paymentMethod.id
              ),
              refundable: isReportPaymentMethodRefundable
            }
          : {})
      });

    if (expenseUuidToUpdate === undefined) {
      createManualExpense(mergedFormDataWithReportData);
      return;
    }

    updateManualExpense({
      ...mergedFormDataWithReportData,
      expenseUuid: expenseUuidToUpdate
    });
  }

  async function handleCreateManualExpenseAfterConfirmationOutOfPolicy(): Promise<void> {
    if (
      manualExpensePayloadToSendAfterConfirmationOutOfPolicy.current === null
    ) {
      return;
    }

    // envia com o atributo validated como N para indicar que a despesa está fora da política
    createManualExpense({
      ...manualExpensePayloadToSendAfterConfirmationOutOfPolicy.current,
      validated: 'N'
    });
  }

  async function handleUpdateManualExpenseAfterConfirmationOutOfPolicy(): Promise<void> {
    if (
      manualExpensePayloadToSendAfterConfirmationOutOfPolicy.current === null
    ) {
      return;
    }

    // envia com o atributo validated como N para indicar que a despesa está fora da política
    updateManualExpense({
      ...manualExpensePayloadToSendAfterConfirmationOutOfPolicy.current,
      validated: 'N',
      expenseUuid: expenseUuidToUpdate as string
    });
  }

  return {
    firstStepForm: {
      methods: firstStepMethods,
      handleSubmit: firstStepMethods.handleSubmit(
        onSubmitCreateManualExpenseFirstStep
      ),
      selectedCurrencyId: createManualExpenseFirstStepData.current?.currency
    },
    secondStepForm: {
      methods: secondStepMethods,
      handleSubmit: secondStepMethods.handleSubmit(
        onSubmitCreateManualExpenseSecondStep
      )
    },
    expenseParameters,
    isLoadingExpenseParameters,
    isCreatingManualExpense,
    isUpdatingManualExpense,
    allowUserChangeExpenseProjectByReportProject,
    handleCreateManualExpenseAfterConfirmationOutOfPolicy,
    handleUpdateManualExpenseAfterConfirmationOutOfPolicy
  };
}
