import { Fragment } from 'react';

import { useDsGa4 } from 'ds/hooks/globals';
import { useFormContext } from 'react-hook-form';

import { type IRadioControlledProps } from './RadioControlled.types';

import {
  CheckIndicator,
  Container,
  InputComponent,
  Label,
  RadioComponent,
  RadioWrapper
} from './RadioControlled.styles';

export function RadioControlled({
  small,
  label,
  disabled,
  id,
  registerOptions,
  name,
  ...rest
}: IRadioControlledProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();
  const { register } = useFormContext();

  return (
    <Fragment>
      <Container
        data-testid='container'
        $disabled={disabled}
        $small={small}
        htmlFor={id}
      >
        <RadioWrapper>
          <InputComponent
            type='radio'
            disabled={disabled}
            {...register(name, {
              ...registerOptions,
              onChange: event => {
                sendDsGaEvent('components', 'radio', {
                  description:
                    event.target.value === 'on'
                      ? 'Habilitar radio'
                      : 'Desabilitar radio',
                  eventAction:
                    event.target.value === 'on'
                      ? 'enable_radio'
                      : 'disable_radio',
                  eventLabel: label ?? name ?? 'radio_without_label'
                });

                registerOptions?.onChange?.(event);
              }
            })}
            id={id}
            {...rest}
          />

          <RadioComponent>
            <CheckIndicator />
          </RadioComponent>
        </RadioWrapper>

        {label && <Label>{label}</Label>}
      </Container>
    </Fragment>
  );
}
