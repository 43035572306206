import { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { useModalContext } from 'data/contexts';
import { FuelQueryKeys, type VehiclesListOrderByType } from 'data/modules/fuel';
import {
  useDeleteVehicle,
  useGetPaginatedCompanyVehicles,
  useGetTypesOfFuels,
  useGetTypesOfOwners,
  useGetTypesOfVehicles
} from 'data/modules/fuel/useCases';

import { routesPathPrefix } from 'shared/constants/global';
import { useDebounce } from 'shared/hooks/global';
import CustomObjectUtil from 'shared/utils/custom/CustomObject.util';
import { TransformArray } from 'shared/utils/global';

import { type IFilters, type IUseVehiclesList } from './VehiclesList.types';

export function useVehiclesList(): IUseVehiclesList {
  const [filters, setFilters] = useState<IFilters>({
    fuelType: 'ALL',
    order: 'desc',
    orderBy: 'data_criacao',
    owner: 'ALL',
    page: 1,
    perPage: 10,
    search: '',
    vehicleType: 'ALL'
  });

  const { id } = useParams();

  const navigate = useNavigate();

  const pattern = /^\d+$/;

  const { handleOpenModal, handleCloseModal } = useModalContext();

  const queryClient = useQueryClient();

  const { t } = useTranslation('fuel');

  const { t: tGlobal } = useTranslation('global');

  const [vehicleIdToUpdate, setVehicleIdToUpdate] = useState<
    undefined | number
  >(undefined);

  const [vehicleIndex, setVehicleIndex] = useState(-1);

  const { isLoadingTypesOfVehicles, typesOfVehicles } = useGetTypesOfVehicles();

  const { isLoadingTypesOfFuels, typesOfFuels } = useGetTypesOfFuels();

  const { isLoadingTypesOfOwners, typesOfOwners } = useGetTypesOfOwners();

  const { deleteVehicle, isDeletingVehicle } = useDeleteVehicle({
    onErrorDeleteVehicle: () => {
      toast.error(t('vehiclePage.errorDeletingVehicle'));
    },
    onSuccessDeleteVehicle: () => {
      queryClient.invalidateQueries({
        queryKey: [FuelQueryKeys.GET_COMPANY_VEHICLES]
      });

      toast.success(t('vehiclePage.successDeletingVehicle'));

      handleCloseModal();
      setVehicleIndex(-1);
      setVehicleIdToUpdate(undefined);
    }
  });

  const typesOfOwnersOptions = TransformArray.objectsToSelectOptions(
    typesOfOwners,
    'description',
    'description',
    true
  );

  const typesOfFuelsOptions = TransformArray.objectsToSelectOptions(
    typesOfFuels,
    'id',
    'description',
    true
  );

  const typesOfVehiclesOptions = TransformArray.objectsToSelectOptions(
    typesOfVehicles,
    'id',
    'description',
    true
  );

  typesOfFuelsOptions.unshift({
    label: tGlobal('all_male_other'),
    value: 'ALL'
  });

  typesOfOwnersOptions.unshift({
    label: tGlobal('all_male_other'),
    value: 'ALL'
  });

  typesOfVehiclesOptions.unshift({
    label: tGlobal('all_male_other'),
    value: 'ALL'
  });

  const debouncedOrder = useDebounce(filters.order);
  const debouncedOrderBy = useDebounce(filters.orderBy);
  const debouncedSearch = useDebounce(filters.search, 1000);
  const debouncedFuelType = useDebounce(filters.fuelType);
  const debouncedOwner = useDebounce(filters.owner);
  const debouncedVehicleType = useDebounce(filters.vehicleType);

  const { isLoadingPaginatedCompanyVehicles, paginatedCompanyVehicles } =
    useGetPaginatedCompanyVehicles(
      CustomObjectUtil.exchangeNullValues({
        fuelType: debouncedFuelType === 'ALL' ? '' : debouncedFuelType,
        order: debouncedOrder,
        orderBy: debouncedOrderBy,
        owner: debouncedOwner === 'ALL' ? '' : debouncedOwner,
        page: filters.page,
        perPage: filters.perPage,
        search: debouncedSearch,
        vehicleType: debouncedVehicleType === 'ALL' ? '' : debouncedVehicleType
      })
    );

  async function handleDeleteVehicle(id: number): Promise<void> {
    await deleteVehicle({ vehicleId: id });
  }

  function handleChangeFilters<T extends keyof IFilters>(
    type: T,
    value: IFilters[T]
  ): void {
    switch (type) {
      case 'page':
        setFilters(oldState => ({
          ...oldState,
          page: value as number
        }));
        return;

      case 'perPage':
        setFilters(oldState => ({
          ...oldState,
          perPage: value as number,
          page: 1
        }));
        return;

      case 'search':
        setFilters(oldState => ({
          ...oldState,
          search: value as string
        }));
        return;

      case 'orderBy':
        setFilters(oldState => ({
          ...oldState,
          order:
            value !== oldState.orderBy
              ? 'asc'
              : oldState.order === 'asc'
                ? 'desc'
                : 'asc',
          orderBy: value as VehiclesListOrderByType
        }));
        return;

      case 'fuelType':
        setFilters(oldState => ({
          ...oldState,
          fuelType: value as string
        }));
        return;

      case 'order':
        setFilters(oldState => ({
          ...oldState,
          order: value as 'asc' | 'desc'
        }));
        return;

      case 'owner':
        setFilters(oldState => ({
          ...oldState,
          owner: value as string
        }));
        return;

      case 'vehicleType':
        setFilters(oldState => ({
          ...oldState,
          vehicleType: value as string
        }));
    }
  }

  useEffect(() => {
    if (!isLoadingPaginatedCompanyVehicles && id && pattern.test(id)) {
      const index =
        paginatedCompanyVehicles?.data.findIndex(
          item => item.id === parseInt(id)
        ) ?? -1;

      index >= 0 && setVehicleIndex(index);
      handleOpenModal('viewVehicle');
    }

    if (id && !pattern.test(id)) {
      toast.error(t('messages.vehicleNotFound'));
      navigate(`${routesPathPrefix.settings}/combustivel/veiculos`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isLoadingPaginatedCompanyVehicles, paginatedCompanyVehicles]);

  return {
    filters,
    handleChangeFilters,
    isLoadingPaginatedCompanyVehicles,
    isLoadingTypesOfFuels,
    isLoadingTypesOfOwners,
    isLoadingTypesOfVehicles,
    paginatedCompanyVehicles,
    typesOfFuelsOptions,
    typesOfOwnersOptions,
    typesOfVehiclesOptions,
    vehicleIndex,
    setVehicleIndex,
    vehicleIdToUpdate,
    setVehicleIdToUpdate,
    handleDeleteVehicle,
    isDeletingVehicle
  };
}
