import { useNavigate } from 'react-router-dom';

import { useAuthContext, useLangContext } from 'data/contexts';

import { DsCheckbox } from 'presentation/ds/DsCheckbox';
import {
  OnboardingFooter,
  OnboardingText
} from 'presentation/pages/cards/Onboarding/components/General';

import { usePoliciesAndTerms } from './usePoliciesAndTerms';

import {
  Container,
  Content,
  CustomLink,
  ErrorMessage,
  ItemContainer,
  OnboardingBoldTitle,
  PoliciesAndTermsContainer
} from './PoliciesAndTerms.styles';

export function PoliciesAndTerms(): JSX.Element {
  const user = useAuthContext(state => state.user);

  const navigate = useNavigate();

  const [PoliciesAndTerms, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.user.policies_and_terms,
    state.currentLangKey
  ]);

  const { control, updatePoliciesAndTerms, errors } = usePoliciesAndTerms();

  return (
    <Container onSubmit={updatePoliciesAndTerms}>
      <Content>
        <OnboardingBoldTitle>{user?.name},</OnboardingBoldTitle>

        <OnboardingText>
          {PoliciesAndTerms.message[currentLangKey]}
        </OnboardingText>

        <PoliciesAndTermsContainer>
          <ItemContainer>
            <label htmlFor='privacyPolicy'>
              <OnboardingText>
                {PoliciesAndTerms.policies.message[currentLangKey]}

                <CustomLink
                  to='https://vexpenses.com.br/politica-privacidade'
                  target='_blank'
                >
                  {PoliciesAndTerms.policies.link[currentLangKey]}
                </CustomLink>
              </OnboardingText>
            </label>

            <DsCheckbox
              name='privacyPolicy'
              control={control}
            />
          </ItemContainer>
          {errors.privacyPolicy?.message && (
            <ErrorMessage>{errors.privacyPolicy.message}</ErrorMessage>
          )}

          <ItemContainer>
            <label htmlFor='termsOfUse'>
              <OnboardingText>
                {PoliciesAndTerms.terms.message[currentLangKey]}

                <CustomLink
                  to='https://vexpenses.com.br/termos-de-uso-cartoes.pdf'
                  target='_blank'
                >
                  {PoliciesAndTerms.terms.link[currentLangKey]}
                </CustomLink>
              </OnboardingText>
            </label>

            <DsCheckbox
              name='termsOfUse'
              control={control}
            />
          </ItemContainer>
          {errors.termsOfUse?.message && (
            <ErrorMessage>{errors.termsOfUse.message}</ErrorMessage>
          )}
        </PoliciesAndTermsContainer>
      </Content>

      <OnboardingFooter
        continueButtonProps={{
          onClick: () => {}
        }}
        backButtonProps={{
          onClick: () => {
            navigate('/cartoes/onboarding/usuario/inicio');
          }
        }}
      />
    </Container>
  );
}
