import { TextInput } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext } from 'data/contexts';

export function GroupConfiguration(): JSX.Element {
  const { t } = useTranslation('cards');

  const [cardGroupToBeCreated, handleCreateCardGroup] = useCardGroupsContext(
    useShallow(state => [
      state.cardGroupToBeCreated,
      state.handleCreateCardGroup
    ])
  );

  return (
    <>
      <TextInput.Default
        name='groupName'
        label={t('groupName')}
        placeholder={t('groupName')}
        value={cardGroupToBeCreated?.groupName ?? ''}
        onChangeValue={value => {
          handleCreateCardGroup({ groupName: value });
        }}
        small
      />
    </>
  );
}
