import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useAuthContext } from 'data/contexts';
import {
  type IRequestProductFieldsForm,
  type IRequestProductPayload,
  type IUseRequestProduct,
  type IUseRequestProductParams,
  StoreMutationKeys,
  StoreService
} from 'data/modules/store';

import { useFormWithSchema } from 'shared/hooks/forms';

import { useRequestProductSchema } from './schemas';

export function useRequestProduct(
  params: IUseRequestProductParams
): IUseRequestProduct {
  const user = useAuthContext(state => state.user);

  const methods = useFormWithSchema(
    useRequestProductSchema({
      identifier: params.identifier
    }),
    {
      defaultValues: {
        userId: user?.uuid ?? '',
        email: user?.email ?? '',
        identifier: params.identifier,
        companyId: user?.companyUuid ?? ''
      }
    }
  );

  const { mutate: requestProduct, isLoading: isRequestingProduct } =
    useMutationCache({
      mutationKey: [StoreMutationKeys.REQUEST_PRODUCT],
      mutationFn: async (payload: IRequestProductPayload) =>
        await StoreService.requestProduct(payload),
      onSuccess: () => {
        params.onAfterRequestProductSuccess?.();
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  function onSubmit(data: IRequestProductFieldsForm): void {
    if (isRequestingProduct) return;

    requestProduct({
      ...data,
      conversionUrl: window.location.href
    });
  }

  return {
    methods,
    isRequestingProduct,
    handleRequestProduct: methods.handleSubmit(onSubmit)
  };
}
