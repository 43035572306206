import { useQueryCache } from 'data/cache';
import {
  type IUseGetRoutePolicies,
  type IUseGetRoutePoliciesParams,
  RoutePoliciesQueryKeys,
  RoutePoliciesService
} from 'data/modules/routePolicies';

export function useGetRoutePolicies({
  enabled,
  ...payload
}: IUseGetRoutePoliciesParams): IUseGetRoutePolicies {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [RoutePoliciesQueryKeys.GET_ROUTE_POLICIES, payload],
    queryFn: async () => await RoutePoliciesService.getRoutePolicies(payload),
    enabled
  });

  return {
    routePolicies: data ?? [],
    isLoadingRoutePolicies: isLoading,
    isFetchingRoutePolicies: isFetching
  };
}
