enum BusinessCardsMutationKeys {
  'CREATE_SHARED_ACCOUNT' = 'CREATE_SHARED_ACCOUNT',
  'CREATE_VIRTUAL_USER' = 'CREATE_VIRTUAL_USER',
  'ATTACH_USER' = 'ATTACH_USER',
  'ATTACH_APPROVAL_FLOWS_AND_EXPENSES_POLICIES' = 'ATTACH_APPROVAL_FLOWS_AND_EXPENSES_POLICIES',
  'VALIDATE_PIN_CARD' = 'VALIDATE_PIN_CARD',
  'LINK_CARD' = 'LINK_CARD',
  'LINK_PASSWORD_TO_CARD' = 'LINK_PASSWORD_TO_CARD',
  'ATTACH_TEAM_MEMBERS' = 'ATTACH_TEAM_MEMBERS',
  'STORE_ACCOUNT_HOLDER' = 'STORE_ACCOUNT_HOLDER',
  'CHANGE_VIRTUAL_MEMBER' = 'CHANGE_VIRTUAL_MEMBER',
  'REMOVE_RELATED_PROJECT' = 'REMOVE_RELATED_PROJECT',
  'REMOVE_RELATED_COST_CENTER' = 'REMOVE_RELATED_COST_CENTER',
  'REMOVE_RELATED_TEAM_MEMBERS' = 'REMOVE_RELATED_TEAM_MEMBERS'
}

enum BusinessCardsQueryKeys {
  'GET_VIRTUAL_MEMBERS' = 'GET_VIRTUAL_MEMBERS'
}

export { BusinessCardsMutationKeys, BusinessCardsQueryKeys };
