import { useQueryCache } from 'data/cache';
import {
  type IUseGetTeamMembersProjectOptions,
  TeamMembersQueryKeys,
  TeamMembersService
} from 'data/modules/teamMembers';

export function useGetTeamMembersProjectOptions(): IUseGetTeamMembersProjectOptions {
  const { data, isLoading } = useQueryCache({
    queryKey: [TeamMembersQueryKeys.GET_TEAM_MEMBERS_PROJECT_OPTIONS],
    queryFn: async () => await TeamMembersService.getTeamMembersProjectOptions()
  });

  return {
    isLoadingProjectOptions: isLoading,
    projectOptions: data ?? []
  };
}
