import { useIsFetching, useQueryClient } from '@tanstack/react-query';

import { useQueryCache } from 'data/cache';
import {
  ExpensesQueryKeys,
  ExpensesService,
  type IDetailedExpense,
  type IGetExpensePayload,
  type IUseGetExpenseDetails,
  type IUseGetExpenseDetailsParams
} from 'data/modules/expenses';

export function useGetExpenseDetails({
  expenseUuid,
  enabled
}: IUseGetExpenseDetailsParams): IUseGetExpenseDetails {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQueryCache({
    queryKey: [ExpensesQueryKeys.GET_EXPENSE, expenseUuid],
    queryFn: async () => await ExpensesService.getExpense({ expenseUuid }),
    enabled
  });

  async function getEnsuredExpenseDetails({
    expenseUuid
  }: IGetExpensePayload): Promise<IDetailedExpense> {
    const data = await queryClient.ensureQueryData({
      queryKey: [ExpensesQueryKeys.GET_EXPENSE, expenseUuid],
      queryFn: async () => await ExpensesService.getExpense({ expenseUuid })
    });

    return data;
  }

  const isFetchingAndPendingExpenseDetails =
    useIsFetching({
      predicate: query => {
        return (
          query.queryKey[0] === ExpensesQueryKeys.GET_EXPENSE &&
          query.state.status === 'pending'
        );
      }
    }) > 0;

  return {
    expense: data,
    isLoadingExpense: isLoading,
    getEnsuredExpenseDetails,
    isFetchingAndPendingExpenseDetails
  };
}
