import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useAuthContext } from 'data/contexts';
import { useLogNotTreatedError } from 'data/modules/global';

import { Redirect } from 'presentation/components/global/Redirect';
import { FallbackPage } from 'presentation/pages/global';

import { CardsRoutes } from 'shared/constants/global';
import { FlagMiddleware } from 'shared/middlewares';

import {
  analyticsRoutes,
  companyOnboardingRoutes,
  expensesRoutes,
  globalRoutes,
  reportsRoutes,
  settingsRoutes,
  storeRoutes,
  supportRoutes,
  useCardsRoutes,
  userOnboardingRoutes
} from './';

export function Router(): JSX.Element {
  const { logNotTreatedError } = useLogNotTreatedError();

  const [company, user] = useAuthContext(
    useShallow(state => [state.company, state.user])
  );

  const { managerRoutes, userRoutes } = useCardsRoutes();

  return (
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={() => <FallbackPage error='DEFAULT' />}
        onError={error => {
          logNotTreatedError({
            companyId: company ? company.uuid : '',
            userId: user ? user.uuid : '',
            eventDescription: error.message,
            error: {
              name: error.name,
              message: error.message,
              stack: error.stack,
              cause: error.cause
            },
            platform: navigator.userAgent,
            timestamp: new Date(),
            url: window.location.pathname,
            web: true
          });
        }}
      >
        <Routes>
          <Route element={<FlagMiddleware />}>
            <Route
              key='notFound'
              path='*'
              element={<Redirect />}
            />

            <Route
              path={CardsRoutes.CARDS_WEBVIEW}
              element={<Navigate to={CardsRoutes.CARDS_USER_ONBOARDING} />}
            />

            {...expensesRoutes}

            {...analyticsRoutes}

            {...companyOnboardingRoutes}

            {...storeRoutes}

            {...supportRoutes}

            {...userOnboardingRoutes}

            {...managerRoutes}

            {...userRoutes}

            {...reportsRoutes}

            {...settingsRoutes}

            {...globalRoutes}
          </Route>
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}
