import {
  DefaultButton,
  DSIcons,
  Modal,
  SecondaryButton,
  SkeletonLoader
} from 'ds';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useModalContext } from 'data/contexts';

import { AlertModal } from 'presentation/components/global/AlertModal';
import { type IAlertModalProps } from 'presentation/components/global/AlertModal/AlertModal.types';
import { Loader } from 'presentation/components/global/Loader';

import { routesPathPrefix } from 'shared/constants/global';

import { UpdateVehicleSelectMembers } from '../UpdateVehicleSelectMembers';

import { useLinkOrUnlinkMembersModal } from './useLinkOrUnlinkMembersModal';

import { type ILinkOrUnlinkMembersModalProps } from './LinkOrUnlinkMembersModal.types';

import {
  MemberListContainer,
  ModalContent,
  ModalFooter,
  StyledContainer,
  StyledModalContainer
} from './LinkOrUnlinkMembersModal.styles';

export function LinkOrUnlinkMembersModal({
  vehicleId
}: ILinkOrUnlinkMembersModalProps): JSX.Element {
  const { handleCloseModal } = useModalContext();

  const { t } = useTranslation(['fuel', 'global']);

  const navigate = useNavigate();

  const {
    isShowingModalLoading,
    showConfirmEmptyModal,
    showConfirmModal,
    handleConfirmLinkMember,
    handleConfirmSaveLinkMember,
    handleClickOnMember,
    handleClickSelectAllMembers,
    linkUnlinkMembersStepState,
    isLinkingMembersToVehicle,
    setShowConfirmEmptyModal,
    setShowConfirmModal,
    vehicle
  } = useLinkOrUnlinkMembersModal({
    vehicleId
  });

  const confirmModalData: Pick<IAlertModalProps, 'children' | 'title'> = {
    children: (
      <Trans
        i18nKey='fuel:modal.confirmLinkMemberToVehicleDescription'
        values={{
          plate: vehicle?.plate,
          model: vehicle?.model
        }}
        components={{ bold: <strong /> }}
      />
    ),
    title: t('fuel:modal.confirmLinkMemberToVehicleTitle')
  };

  const confirmEmptyModalData: Pick<IAlertModalProps, 'children' | 'title'> = {
    children: (
      <Trans
        i18nKey='fuel:modal.confirmLinkMemberToVehicleDescriptionEmpty'
        values={{
          model: vehicle?.model
        }}
        components={{ bold: <strong /> }}
      />
    ),
    title: t('fuel:modal.confirmLinkMemberToVehicleTitleEmpty')
  };

  return (
    <>
      <Loader isLoading={isLinkingMembersToVehicle} />

      <StyledModalContainer
        variant='medium'
        onClose={handleCloseModal}
      >
        {isShowingModalLoading && (
          <StyledContainer>
            <Modal.Header>
              <SkeletonLoader
                height='2rem'
                maxWidth='80rem'
              />

              <Modal.IconClose
                onClick={() => {
                  handleCloseModal();
                }}
              />
            </Modal.Header>

            <SkeletonLoader
              height='4.1rem'
              maxWidth='96%'
              margin='0.5rem'
            />

            <ModalContent>
              <SkeletonLoader
                height='2rem'
                maxWidth='48%'
                margin='0.5rem'
              />

              <MemberListContainer>
                <SkeletonLoader
                  height='2rem'
                  maxWidth='48%'
                  margin='0.5rem'
                />
                <SkeletonLoader
                  height='2rem'
                  maxWidth='48%'
                  margin='0.5rem'
                />
                <SkeletonLoader
                  height='2rem'
                  maxWidth='48%'
                  margin='0.5rem'
                />
                <SkeletonLoader
                  height='2rem'
                  maxWidth='48%'
                  margin='0.5rem'
                />
                <SkeletonLoader
                  height='2rem'
                  maxWidth='48%'
                  margin='0.5rem'
                />
                <SkeletonLoader
                  height='2rem'
                  maxWidth='48%'
                  margin='0.5rem'
                />
                <SkeletonLoader
                  height='2rem'
                  maxWidth='48%'
                  margin='0.5rem'
                />
                <SkeletonLoader
                  height='2rem'
                  maxWidth='48%'
                  margin='0.5rem'
                />
              </MemberListContainer>
            </ModalContent>

            <ModalFooter>
              <SkeletonLoader
                height='2rem'
                maxWidth='10rem'
              />

              <SkeletonLoader
                height='2rem'
                maxWidth='10rem'
              />
            </ModalFooter>
          </StyledContainer>
        )}

        {!isShowingModalLoading && (
          <StyledContainer>
            <Modal.Header>
              <Modal.Title>{t('fuel:modal.linkOrUnlinkMembers')}</Modal.Title>

              <Modal.IconClose
                onClick={() => {
                  navigate(
                    `${routesPathPrefix.settings}/combustivel/veiculos/`
                  );
                  handleCloseModal();
                }}
              />
            </Modal.Header>

            <ModalContent>
              <UpdateVehicleSelectMembers
                linkUnlinkMembersStepState={linkUnlinkMembersStepState}
                onClickOnMember={handleClickOnMember}
                onClickSelectAllMembers={handleClickSelectAllMembers}
                vehicleId={vehicleId}
              />
            </ModalContent>

            <ModalFooter>
              <SecondaryButton
                size='small'
                onClick={handleCloseModal}
              >
                {t('global:cancel')}
              </SecondaryButton>

              <DefaultButton
                size='small'
                onClick={handleConfirmLinkMember}
              >
                {t('global:confirm')}
              </DefaultButton>
            </ModalFooter>
          </StyledContainer>
        )}
      </StyledModalContainer>

      {showConfirmModal && (
        <AlertModal
          {...confirmModalData}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          confirmButtonProps={{
            onConfirm: () => {
              handleConfirmSaveLinkMember();
            }
          }}
          icon={<DSIcons.WarningConfirmationIcon />}
        />
      )}

      {showConfirmEmptyModal && (
        <AlertModal
          {...confirmEmptyModalData}
          onClose={() => {
            setShowConfirmEmptyModal(false);
          }}
          confirmButtonProps={{
            onConfirm: () => {
              handleConfirmSaveLinkMember();
            }
          }}
          icon={<DSIcons.WarningConfirmationIcon />}
        />
      )}
    </>
  );
}
