import { Fragment, type MouseEvent } from 'react';

import { SkeletonLoader } from 'ds';
import { useNavigate } from 'react-router-dom';

import { vexDomain } from 'data/config';
import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { LanguageSelector } from 'presentation/components/global/LanguageSelector';
import { Outsourced } from 'presentation/components/global/OldOutsourced';
import { Profile } from 'presentation/components/global/OldProfile';
import { DropdownGroup, DropdownRoot } from 'presentation/ds/DsDropdown';

import { ReactComponent as CollapsedLogo } from 'shared/assets/images/global/logotipo-collapsado.svg';
import { ReactComponent as Logo } from 'shared/assets/images/global/logotipo-horizontal-branco.svg';
import { routesPathPrefix } from 'shared/constants/global';
import { Icons } from 'shared/constants/global/icons.constant';
import { sidebarRoutes } from 'shared/constants/global/sidebarRoutes.constant';

import { SidebarItem, SidebarSection } from '..';

import { useSidebarContainer } from './useSidebarContainer';

import { type ISidebarContainerProps } from './SidebarContainer.types';

import {
  CollapseIcon,
  CompanyDropdownWrapper,
  CompanyInfo,
  Container,
  DropdownCompanyContent,
  DropdownCompanyItem,
  DropdownCompanyLabel,
  DropdownCompanyScroll,
  DropdownCompanyTrigger,
  LogoCompany,
  NavbarDataMobile,
  SidebarContent,
  SidebarHeader,
  SidebarSubItem,
  SidebarSubItemsContainer,
  SidebarSubSection,
  Tooltip,
  Wrapper
} from './SidebarContainer.styles';

export function SidebarContainer({
  isCollapsed,
  onSetIsCollapsed
}: ISidebarContainerProps): JSX.Element {
  const navigate = useNavigate();

  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const {
    sidebarRef,
    company,
    isFetchingData,
    user,
    availableRoutes,
    handleCollapseSidebar,
    route,
    subItemActive,
    setSubItemActive,
    isOutsourcing,
    handleCollapseSidebarMobile,
    isDropdownOpen,
    setIsDropdownOpen
  } = useSidebarContainer({ isCollapsed, onSetIsCollapsed });

  // se o número de empresas for maior ou igual a 3, o menu de empresas deve ter 19.3rem de altura
  // se for menor, deve ter 5rem de altura por empresa + 5rem por conta da primeira opção
  const animatedMulticompanyMenuHeight =
    user?.userCompanies && user?.userCompanies?.length > 0
      ? user.userCompanies.length >= 3
        ? 19.3
        : user.userCompanies.length * 5 + 5
      : 0;

  return (
    <Container
      ref={sidebarRef}
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        handleCollapseSidebarMobile(e.target);
      }}
      $isCollapsed={isCollapsed}
      $isOpen={window.innerWidth <= 1200 && !isCollapsed}
    >
      <Wrapper
        $outsourced={isOutsourcing}
        $isCollapsed={isCollapsed}
        data-testid='sidebar'
      >
        <SidebarHeader>
          <CollapsedLogo />

          <Logo />

          <CollapseIcon
            role='button'
            tabIndex={0}
            onClick={() => {
              handleCollapseSidebar();
            }}
            $isCollapsed={isCollapsed}
            $outsourced={isOutsourcing}
          >
            <Icons.ArrowLeftIcon />
            <Tooltip>{lang.global.open_close[currentLangKey]}</Tooltip>
          </CollapseIcon>

          {company?.logoUrl &&
            !isCollapsed &&
            company.parameters.usesLogoOnInterface && (
              <LogoCompany>
                <img src={company.logoUrl} />
              </LogoCompany>
            )}

          {user && (
            <NavbarDataMobile>
              <Profile user={user} />

              {/* Info de Terceirização */}
              {user.parameters.hasOutsourcingAccess && <Outsourced />}
              <LanguageSelector />
            </NavbarDataMobile>
          )}

          <DropdownRoot
            open={isDropdownOpen}
            onOpenChange={() => {
              setIsDropdownOpen(old => !old);
            }}
          >
            <CompanyDropdownWrapper $isCollapsed={isCollapsed}>
              <DropdownCompanyTrigger
                disabled={
                  !!user?.userCompanies && user?.userCompanies?.length === 0
                }
              >
                <CompanyInfo
                  $disabled={
                    !!user?.userCompanies && user?.userCompanies?.length === 0
                  }
                  $isOpen={isDropdownOpen}
                  onClick={() => {
                    isCollapsed && onSetIsCollapsed(!isCollapsed);
                  }}
                  $isCollapsed={isCollapsed}
                  title={company?.name}
                  $outsourced={isOutsourcing}
                >
                  <div>
                    <Icons.CompanyIcon />
                    <p>{company?.name}</p>
                  </div>
                  {user?.userCompanies && user?.userCompanies?.length > 0 && (
                    <BaseIcon
                      name='arrow-down-s'
                      size={1.8}
                      type='line'
                    />
                  )}
                </CompanyInfo>
              </DropdownCompanyTrigger>

              <DropdownCompanyContent height={animatedMulticompanyMenuHeight}>
                <DropdownGroup>
                  <DropdownCompanyLabel>
                    <BaseIcon
                      name='company'
                      color='black'
                      size={2}
                    />

                    <p>{company?.name}</p>
                  </DropdownCompanyLabel>
                </DropdownGroup>

                <DropdownCompanyScroll>
                  {!!user?.userCompanies &&
                    user?.userCompanies?.length > 0 &&
                    user?.userCompanies?.map(
                      ({ companyId, companyName, userId }, index) => {
                        return (
                          <DropdownGroup
                            key={companyId}
                            lastGroup={
                              index + 1 === user?.userCompanies?.length
                            }
                          >
                            <DropdownCompanyItem asChild>
                              <a
                                href={`${vexDomain}/change-company-sidebar/${userId}`}
                              >
                                <BaseIcon
                                  name='company'
                                  size={2}
                                />
                                <p>{companyName}</p>
                              </a>
                            </DropdownCompanyItem>
                          </DropdownGroup>
                        );
                      }
                    )}
                </DropdownCompanyScroll>
              </DropdownCompanyContent>
            </CompanyDropdownWrapper>
          </DropdownRoot>
        </SidebarHeader>

        <SidebarContent>
          <SidebarSection isCollapsed={isCollapsed}>
            <SidebarSubSection $isCollapsed={isCollapsed}>
              {isFetchingData ? (
                <SkeletonLoader
                  height='4.6rem'
                  rows={3}
                  multipleLoadersContainerClassName='sidebar-loader-container'
                  loaderClassName='item-loader'
                />
              ) : (
                <>
                  {availableRoutes.map(page =>
                    page.subRouteCondition && page.subRoute ? (
                      <Fragment key={page.name[currentLangKey]}>
                        <SidebarItem
                          outsourced={isOutsourcing}
                          isCollapsed={isCollapsed}
                          icon={<page.icon />}
                          title={page.name[currentLangKey]}
                          isActive={route === page.subRouteActive}
                          link={undefined}
                          isSubItemsActive={
                            subItemActive === page.subRouteActive
                          }
                          setIsSubItemsActive={() => {
                            if (isCollapsed) {
                              onSetIsCollapsed(false);

                              if (subItemActive) {
                                return;
                              }
                            }

                            setSubItemActive(
                              subItemActive === page.subRouteActive
                                ? ''
                                : (page.subRouteActive as string)
                            );
                          }}
                          hasSubItems
                        />

                        <SidebarSubItemsContainer
                          $isActive={
                            subItemActive === page.subRouteActive &&
                            !isCollapsed
                          }
                        >
                          {page.subRoute.map(subRoute => {
                            const isCardRoute =
                              subRoute.route.includes('cards');

                            return (
                              <SidebarSubItem
                                key={`sub-route-${subRoute.name[currentLangKey]}`}
                                $outsourced={isOutsourcing}
                                onClick={
                                  isCardRoute
                                    ? () => {
                                        navigate(subRoute.route);
                                      }
                                    : () => undefined
                                }
                                href={isCardRoute ? '' : subRoute.route}
                                $isActive={route === subRoute.route}
                              >
                                <Icons.DotIcon />
                                {subRoute.name[currentLangKey]}
                              </SidebarSubItem>
                            );
                          })}
                        </SidebarSubItemsContainer>
                      </Fragment>
                    ) : (
                      <SidebarItem
                        key={page.name[currentLangKey]}
                        outsourced={isOutsourcing}
                        isCollapsed={isCollapsed}
                        icon={<page.icon />}
                        title={page.name[currentLangKey]}
                        isActive={
                          page.route === sidebarRoutes.cards.route &&
                          route !== sidebarRoutes.approvalsRequest.route
                            ? route.startsWith(routesPathPrefix.cards)
                            : route.startsWith(page.route)
                        }
                        link={page.route}
                        isReactRoute={page.isReactRoute}
                      />
                    )
                  )}

                  {user?.userType === 'ADMINISTRADOR' && (
                    <SidebarItem
                      isReactRoute
                      isCollapsed={isCollapsed}
                      outsourced={isOutsourcing}
                      link={sidebarRoutes.analytics.route}
                      icon={<sidebarRoutes.analytics.icon />}
                      title={lang.sidebar.routes.analytics[currentLangKey]}
                      isActive={route.startsWith(routesPathPrefix.analytics)}
                    />
                  )}

                  {/* Itens alinhados à direita */}
                  {!isOutsourcing &&
                    (user?.userType === 'USUARIO' ? (
                      <SidebarItem
                        isCollapsed={isCollapsed}
                        icon={<sidebarRoutes.settingsUser.icon />}
                        link={sidebarRoutes.settingsUser.route}
                        title={sidebarRoutes.settingsUser.name[currentLangKey]}
                        outsourced={isOutsourcing}
                      />
                    ) : (
                      <SidebarItem
                        outsourced={isOutsourcing}
                        icon={<sidebarRoutes.settingsAdmin.icon />}
                        isCollapsed={isCollapsed}
                        link={sidebarRoutes.settingsAdmin.route}
                        title={sidebarRoutes.settingsAdmin.name[currentLangKey]}
                        isActive={route.startsWith(routesPathPrefix.settings)}
                      />
                    ))}
                </>
              )}
            </SidebarSubSection>

            <SidebarSubSection>
              {isFetchingData ? (
                <SkeletonLoader
                  height='4.6rem'
                  loaderClassName='item-loader'
                />
              ) : (
                <>
                  {user?.userType === 'ADMINISTRADOR' && (
                    <SidebarItem
                      isNew
                      isReactRoute
                      isCollapsed={isCollapsed}
                      outsourced={isOutsourcing}
                      link={sidebarRoutes.store.route}
                      icon={<sidebarRoutes.store.icon />}
                      title={lang.sidebar.routes.store[currentLangKey]}
                      isActive={route.startsWith(routesPathPrefix.store)}
                    />
                  )}
                  <SidebarItem
                    isReactRoute
                    isCollapsed={isCollapsed}
                    outsourced={isOutsourcing}
                    link={sidebarRoutes.support.route}
                    icon={<sidebarRoutes.support.icon />}
                    title={lang.sidebar.routes.support[currentLangKey]}
                    isActive={route.startsWith(routesPathPrefix.support)}
                  />
                </>
              )}
            </SidebarSubSection>
          </SidebarSection>
        </SidebarContent>
      </Wrapper>
    </Container>
  );
}
