import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { toast } from 'ds/utils';
import { useParams } from 'react-router-dom';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import {
  AccountMutationKeys,
  AccountQueryKeys,
  AccountService,
  type IAccountBalanceAmount,
  type IChangeAccountBalanceAmountFieldsForm,
  type IDepositAccountBalanceAmountPayload,
  type IUseChangeAccountBalanceAmount,
  type IUseChangeAccountBalanceAmountParams,
  type IWithdrawAccountBalanceAmountPayload,
  useGetAccountBalancesAmounts
} from 'data/modules/cards/account';
import {
  BalanceQueryKeys,
  type IAllocatedBalance,
  type IUnallocatedBalance,
  useGetUnallocatedBalance
} from 'data/modules/cards/balance';
import {
  CardGroupsQueryKeys,
  type IAllocatedBalanceCardGroup
} from 'data/modules/cards/cardGroups';
import { StatementQueryKeys } from 'data/modules/cards/statement';

import { useFormWithSchema } from 'shared/hooks/forms';
import { Currency } from 'shared/utils/format';

import { useChangeAccountBalanceAmountSchema } from './schemas';

export function useChangeAccountBalanceAmount({
  accountBalanceId,
  action,
  onAfterChangeAccountBalanceAmount
}: IUseChangeAccountBalanceAmountParams): IUseChangeAccountBalanceAmount {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const queryClient = useQueryClient();

  const { groupId } = useParams();

  const { unallocatedBalance } = useGetUnallocatedBalance({});

  const { accountBalancesAmounts } = useGetAccountBalancesAmounts({
    accountBalancesIds: accountBalanceId ? [accountBalanceId] : []
  });

  const selectedAccountAmount = accountBalancesAmounts?.[0];

  const methods = useFormWithSchema(useChangeAccountBalanceAmountSchema(), {
    defaultValues: {
      currency: 'BRL',
      amount: '',
      description: ''
    }
  });

  const {
    mutate: depositAccountBalanceAmount,
    isLoading: isDepositingAccountBalanceAmount
  } = useMutationCache({
    mutationKey: [AccountMutationKeys.DEPOSIT_ACCOUNT_BALANCE_AMOUNT],
    mutationFn: async (payload: IDepositAccountBalanceAmountPayload) =>
      await AccountService.depositAccountBalanceAmount(payload),
    onError: (error: Error) => {
      toast.error(error.message);
    },
    onSuccess: (data, { amount, accountBalanceId }) => {
      methods.reset();

      queryClient.setQueryData<IAccountBalanceAmount>(
        [AccountQueryKeys.GET_ACCOUNT_BALANCE_AMOUNT, accountBalanceId],
        oldData => ({
          amount: (oldData?.amount as number) + amount
        })
      );

      queryClient.setQueryData<IAllocatedBalance>(
        [
          BalanceQueryKeys.GET_ALLOCATED_BALANCE,
          { ...(groupId ? { cardGroupId: groupId } : { cardGroupId: null }) }
        ],
        oldData => {
          return {
            amount: (oldData?.amount as number) + amount,
            lastUpdateDate: format(new Date(), 'dd/MM/yyyy'),
            lastUpdateTime: format(new Date(), "HH'h':mm'm':ss's'")
          };
        }
      );

      queryClient.invalidateQueries({
        queryKey: [StatementQueryKeys.GET_ACCOUNT_STATEMENT]
      });

      queryClient.setQueryData<IUnallocatedBalance>(
        [BalanceQueryKeys.GET_UNALLOCATED_BALANCE, { cardGroupId: null }],
        oldData => ({
          amount: (oldData?.amount as number) - amount
        })
      );

      queryClient.setQueryData<IAllocatedBalanceCardGroup>(
        [CardGroupsQueryKeys.GET_ALLOCATE_BALANCE_CARD_GROUP],
        oldData => ({
          amount: (oldData?.amount as number) + amount
        })
      );

      onAfterChangeAccountBalanceAmount?.(amount);
    }
  });

  const {
    mutate: withdrawAccountBalanceAmount,
    isLoading: isWithdrawingAccountBalanceAmount
  } = useMutationCache({
    mutationKey: [AccountMutationKeys.WITHDRAWAL_ACCOUNT_BALANCE_AMOUNT],
    mutationFn: async (payload: IWithdrawAccountBalanceAmountPayload) =>
      await AccountService.withdrawAccountBalanceAmount(payload),
    onError: (error: Error) => {
      toast.error(error.message);
    },
    onSuccess: (data, { amount, accountBalanceId }) => {
      queryClient.setQueryData<IAccountBalanceAmount>(
        [AccountQueryKeys.GET_ACCOUNT_BALANCE_AMOUNT, accountBalanceId],
        oldData => ({
          amount: (oldData?.amount as number) - amount
        })
      );

      queryClient.setQueryData<IAllocatedBalance>(
        [
          BalanceQueryKeys.GET_ALLOCATED_BALANCE,
          { ...(groupId ? { cardGroupId: groupId } : { cardGroupId: null }) }
        ],
        oldData => {
          return {
            amount: (oldData?.amount as number) - amount,
            lastUpdateDate: format(new Date(), 'dd/MM/yyyy'),
            lastUpdateTime: format(new Date(), "HH'h':mm'm':ss's'")
          };
        }
      );

      queryClient.invalidateQueries({
        queryKey: [StatementQueryKeys.GET_ACCOUNT_STATEMENT]
      });

      queryClient.setQueryData<IUnallocatedBalance>(
        [BalanceQueryKeys.GET_UNALLOCATED_BALANCE, { cardGroupId: null }],
        oldData => ({
          amount: (oldData?.amount as number) + amount
        })
      );

      queryClient.setQueryData<IAllocatedBalanceCardGroup>(
        [CardGroupsQueryKeys.GET_ALLOCATE_BALANCE_CARD_GROUP],
        oldData => ({
          amount: (oldData?.amount as number) - amount
        })
      );

      onAfterChangeAccountBalanceAmount?.(amount);
    }
  });

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      methods.reset();
    }
  }, [methods]);

  function onSubmit(data: IChangeAccountBalanceAmountFieldsForm): void {
    methods.clearErrors('amount');

    const amountParsedToFloat = Currency.parseToFloat(data.amount);

    const adjustedAmount = Object.is(amountParsedToFloat, -0)
      ? 0
      : amountParsedToFloat;

    if (
      unallocatedBalance?.amount &&
      action === 'deposit' &&
      adjustedAmount > unallocatedBalance.amount
    ) {
      methods.setError('amount', {
        type: 'custom',
        message:
          lang.cards.management.accounts_list.update_balance_modal
            .insufficient_funds[currentLangKey]
      });
      return;
    }

    if (
      action === 'withdraw' &&
      adjustedAmount > (selectedAccountAmount ?? 0)
    ) {
      methods.setError('amount', {
        type: 'custom',
        message:
          lang.cards.management.accounts_list.update_balance_modal
            .insufficient_funds[currentLangKey]
      });
      return;
    }

    if (action === 'deposit') {
      depositAccountBalanceAmount({
        amount: adjustedAmount,
        accountBalanceId,
        description: data.description
      });
      return;
    }

    withdrawAccountBalanceAmount({
      amount: adjustedAmount,
      accountBalanceId,
      description: data.description
    });
  }

  return {
    handleChangeAccountBalanceAmount: methods.handleSubmit(onSubmit),
    isChangingAccountBalanceAmount:
      isDepositingAccountBalanceAmount || isWithdrawingAccountBalanceAmount,
    methods
  };
}
