import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.2rem;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighLight};
  border-radius: 16px;
`;
