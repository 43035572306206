import { Alert, CustomRadioItem, DSIcons, SkeletonLoader, TextInput } from 'ds';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { useLangContext } from 'data/contexts';
import { useGetExpenseParameters } from 'data/modules/expenses';
import { type ReportInformationType } from 'data/modules/reports';

import reportNoItemsImg from 'shared/assets/images/expenses/modal/illustration_booking_no_items.png';
import reportNotFoundImg from 'shared/assets/images/expenses/modal/illustration_booking_not_found.png';
import { animations } from 'shared/constants/animations';
import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';
import { Lang } from 'shared/utils/global';

import { useSecondStepForm } from './useSecondStepForm';

import { type ISecondStepFormProps } from './SecondStepForm.types';

import {
  Container,
  ReportInformationList,
  ReportItemSubtitle,
  ReportNotFoundContainer,
  SearchSelectReportContainer,
  SelectReportContainer
} from './SecondStepForm.styles';

export function SecondStepForm({
  onUpdateExpenseDataBySelectedReport,
  allowUserChangeExpenseProjectByReportProject,
  isExpenseCurrencyDifferentFromReportCurrency,
  onClickToEditExpenseCurrency,
  confirmedReportInformationBySelectedReport,
  firstStepSelectedCostsCenterId,
  firstStepSelectedPaymentMethodId,
  firstStepSelectedProjectId,
  expenseUuidToUpdate
}: ISecondStepFormProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  const { t } = useTranslation('expenses');

  const {
    filteredOpenReports,
    isLoadingOpenReports,
    searchTerm,
    reportInformationBySelectedReport,
    showReportInformationAlert,
    setShowReportInformationAlert,
    handleChangeSearchTerm,
    reportCurrencyIsoCode,
    handleClickReportItem,
    isSelectedCostsCenterDifferentFromReportCostsCenter,
    isSelectedPaymentMethodDifferentFromReportPaymentMethod,
    isSelectedProjectDifferentFromReportProject,
    userCanChangeExpenseDataBySelectedReport,
    showAlertInfoWithCompanyApprovalTypeByCostsCenter
  } = useSecondStepForm({
    onUpdateExpenseDataBySelectedReport,
    confirmedReportInformationBySelectedReport,
    firstStepSelectedCostsCenterId,
    firstStepSelectedPaymentMethodId,
    firstStepSelectedProjectId,
    expenseUuidToUpdate
  });

  const { expenseParameters } = useGetExpenseParameters();

  const paymentMethodFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.reportPaymentMethod !== null
      ? expenseParameters.companyFields.reportPaymentMethod
      : lang.global.payment_form[currentLangKey];

  const costsCenterFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.reportCostCenter !== null
      ? expenseParameters.companyFields.reportCostCenter
      : lang.global.costs_center[currentLangKey];

  const projectFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.reportProject !== null
      ? expenseParameters?.companyFields.reportProject
      : lang.global.project[currentLangKey];

  return (
    <Container>
      {showAlertInfoWithCompanyApprovalTypeByCostsCenter &&
        !isExpenseCurrencyDifferentFromReportCurrency && (
          <AnimatePresence>
            <motion.div {...animations.fadeInOut}>
              <Alert
                type='info'
                title={t(
                  'reportHasACostCenterThatIsPartOfCompanyApprovalPolicy'
                )}
              />
            </motion.div>
          </AnimatePresence>
        )}

      {showReportInformationAlert &&
        !isExpenseCurrencyDifferentFromReportCurrency &&
        userCanChangeExpenseDataBySelectedReport &&
        !showAlertInfoWithCompanyApprovalTypeByCostsCenter &&
        expenseParameters?.companyApprovalType !== 'CC' && (
          <AnimatePresence>
            <motion.div {...animations.fadeInOut}>
              <Alert
                type='warning'
                title={
                  lang.expenses.modal.report_with_linked_information[
                    currentLangKey
                  ]
                }
                actionText={lang.global.confirm[currentLangKey]}
                onClickAction={() => {
                  onUpdateExpenseDataBySelectedReport(
                    reportInformationBySelectedReport as ReportInformationType
                  );
                  setShowReportInformationAlert(false);
                }}
              >
                <ReportInformationList>
                  {isSelectedCostsCenterDifferentFromReportCostsCenter && (
                    <li>
                      {costsCenterFormLabel}:{' '}
                      {reportInformationBySelectedReport?.costsCenter?.name}
                    </li>
                  )}
                  {isSelectedPaymentMethodDifferentFromReportPaymentMethod && (
                    <li>
                      {paymentMethodFormLabel}:{' '}
                      {
                        reportInformationBySelectedReport?.paymentMethod
                          ?.description
                      }
                    </li>
                  )}
                  {allowUserChangeExpenseProjectByReportProject &&
                    isSelectedProjectDifferentFromReportProject && (
                      <li>
                        {projectFormLabel}:{' '}
                        {reportInformationBySelectedReport?.project?.name}
                      </li>
                    )}
                </ReportInformationList>
              </Alert>
            </motion.div>
          </AnimatePresence>
        )}

      {isExpenseCurrencyDifferentFromReportCurrency && (
        <AnimatePresence>
          <motion.div {...animations.fadeInOut}>
            <Alert
              type='error'
              title={Lang.putValuesInString(
                lang.expenses.modal.currency_selection_report_error[
                  currentLangKey
                ],
                {
                  currency: reportCurrencyIsoCode
                }
              )}
              actionText={lang.global.edit[currentLangKey]}
              onClickAction={onClickToEditExpenseCurrency}
            ></Alert>
          </motion.div>
        </AnimatePresence>
      )}

      <SearchSelectReportContainer className='search-select-report-container'>
        <TextInput.Default
          small
          label={lang.global.search[currentLangKey]}
          onChangeValue={handleChangeSearchTerm}
          value={searchTerm}
          iconLeft='SearchIcon'
          placeholder={
            lang.expenses.modal.create_manual_expense
              .search_by_report_id_or_title[currentLangKey]
          }
        />

        <SelectReportContainer>
          <div>
            {isLoadingOpenReports && (
              <SkeletonLoader
                height='7rem'
                rows={3}
                multipleLoadersContainerClassName='multiple-loaders-container'
              />
            )}

            {!isLoadingOpenReports &&
              filteredOpenReports.length === 0 &&
              searchTerm !== '' && (
                <ReportNotFoundContainer>
                  <img src={reportNotFoundImg} />
                  <p>
                    {lang.expenses.modal.report_not_found[currentLangKey]}
                    <br />
                    {t('pleaseCheckIfTheDataIsEnteredCorrectlyAndTryAgain')}
                  </p>
                </ReportNotFoundContainer>
              )}

            {!isLoadingOpenReports &&
              filteredOpenReports.length === 0 &&
              searchTerm === '' && (
                <ReportNotFoundContainer>
                  <img src={reportNoItemsImg} />
                  <p>
                    {t('atTheMomentYouDoNotHaveAnyReportsAvailableToSelect')}
                    <br />
                    {t('clickOnTheButtonBelowCreateReport')}
                  </p>
                </ReportNotFoundContainer>
              )}

            {!isLoadingOpenReports &&
              filteredOpenReports.length > 0 &&
              filteredOpenReports.map(report => (
                <CustomRadioItem.Controlled.Container
                  key={report.uuid}
                  name='report'
                  inputId={report.uuid + '-radio'}
                  inputValue={report.uuid}
                  onCustomClick={() => {
                    handleClickReportItem(report.uuid);
                  }}
                  className='report-radio-item'
                >
                  <CustomRadioItem.Controlled.Title
                    htmlFor={report.uuid + '-radio'}
                  >
                    #{report.uuid.slice(0, 6)} - {report.title}
                  </CustomRadioItem.Controlled.Title>
                  <CustomRadioItem.Controlled.Subtitle>
                    <ReportItemSubtitle>
                      <div>
                        <DSIcons.ShoppingListReceipt3Icon />
                        {report.amountOfExpenses}{' '}
                        {lang.global.expenses[currentLangKey]}
                      </div>
                      <div>
                        <DSIcons.CalendarDateIcon />
                        {lang.global.created_at[currentLangKey]}{' '}
                        {CustomDate.formatFromISO(report.createdAt)}
                      </div>
                      <div>
                        <DSIcons.CurrencyDollarMediunCircleIcon />{' '}
                        {Currency.format(
                          report.currency.isoCode ?? 'BRL',
                          report.totalAmountOfExpenses,
                          report.totalAmountOfExpenses > 0
                        )}
                      </div>
                    </ReportItemSubtitle>
                  </CustomRadioItem.Controlled.Subtitle>
                </CustomRadioItem.Controlled.Container>
              ))}
          </div>
        </SelectReportContainer>
      </SearchSelectReportContainer>
    </Container>
  );
}
