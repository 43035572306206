import {
  type ICreateSharedAccountPayload,
  type IPersistenceSharedAccounts
} from 'data/modules/cards/businessCard';

class SharedAccountMapper {
  toPersistence(
    domainSharedAccount: ICreateSharedAccountPayload
  ): IPersistenceSharedAccounts {
    return {
      account_holder_id: domainSharedAccount.accountHolderId,
      name: domainSharedAccount.name,
      type: 'CORP_BUSINESS'
    };
  }
}

export default new SharedAccountMapper();
