import { useMemo } from 'react';

import { driver, type DriveStep } from 'driver.js';
import { useShallow } from 'zustand/react/shallow';

import { driverJsConfig } from 'data/config';
import { useLangContext, useTourContext } from 'data/contexts';

import { localStorageKeys } from 'shared/constants/global';
import { Zendesk } from 'shared/utils/global';

import {
  type IUseReportsPageTour,
  type IUseReportsPageTourParams
} from './useReportsPageTour.types';

export function useReportsPageTour({
  hasExpenseWithReceiptImg,
  hasExpenses
}: IUseReportsPageTourParams): IUseReportsPageTour {
  const {
    currentLangKey,
    lang: {
      expenses: { tours },
      global
    }
  } = useLangContext();

  const [handleReset] = useTourContext(
    useShallow(state => [state.handleReset])
  );

  const driverExpenseConditionalSteps = useMemo(() => {
    const steps: DriveStep[] = [];

    if (hasExpenses) {
      steps.push({
        element: '.expenses-table-row',
        popover: {
          title: tours.page.fifth_step.title[currentLangKey],
          description: tours.page.fifth_step.description[currentLangKey]
        }
      });
    }

    if (hasExpenseWithReceiptImg) {
      steps.push({
        element: '.expense-attachment-img',
        popover: {
          title: tours.page.sixty_step.title[currentLangKey],
          description: tours.page.sixty_step.description[currentLangKey]
        }
      });
    }

    if (hasExpenses) {
      steps.push(
        ...[
          {
            element: '.pagination-expenses-container',
            popover: {
              title: tours.page.seventh_step.title[currentLangKey],
              description: tours.page.seventh_step.description[currentLangKey]
            }
          },
          {
            element: '.pagination-items-per-page-container',
            popover: {
              title: tours.page.eighth_step.title[currentLangKey],
              description: tours.page.eighth_step.description[currentLangKey]
            }
          }
        ]
      );
    }

    return steps;
  }, [currentLangKey, tours.page, hasExpenses, hasExpenseWithReceiptImg]);

  function startTour(): void {
    const expensesPageTour = driver({
      ...driverJsConfig,
      nextBtnText: global.next[currentLangKey],
      prevBtnText: global.previous[currentLangKey],
      doneBtnText: global.finalize[currentLangKey],
      progressText: `{{current}} ${global.of[currentLangKey]} {{total}}`,
      onDestroyed: () => {
        expensesPageTour.destroy();
        localStorage.setItem(localStorageKeys.expensesPageTour, 'true');
        handleReset('');
        Zendesk.show();
      },
      steps: [
        {
          element: '.add-to-expense-button',
          popover: {
            title: tours.page.first_step.title[currentLangKey],
            description: tours.page.first_step.description[currentLangKey]
          }
        },
        {
          element: '.table-filters-tabs-container',
          popover: {
            title: tours.page.second_step.title[currentLangKey],
            description: tours.page.second_step.description[currentLangKey]
          }
        },
        {
          element: '.more-filters-button',
          popover: {
            title: tours.page.third_step.title[currentLangKey],
            description: tours.page.third_step.description[currentLangKey]
          }
        },
        {
          element: '.action-buttons-container',
          popover: {
            title: tours.page.fourth_step.title[currentLangKey],
            description: tours.page.fourth_step.description[currentLangKey]
          }
        },
        ...driverExpenseConditionalSteps
      ]
    });

    expensesPageTour.drive();
    Zendesk.hide();
  }

  return {
    startTour
  };
}
