import { create } from 'zustand';

import { type IUseCardDetailsContext } from './useCardDetailsContext.types';

export const useCardDetailsContext = create<IUseCardDetailsContext>()(
  (set, get) => ({
    selectedAccount: null,

    handleSetSelectedAccount: account => {
      set({ selectedAccount: account });
    },
    handleSetAvailableBalance: availableBalance => {
      set(state => ({
        selectedAccount: {
          ...state.selectedAccount,
          availableBalance
        }
      }));
    }
  })
);
