import { useQueryCache } from 'data/cache';
import {
  type IUseGetProjects,
  type IUseGetProjectsParams,
  ProjectsQueryKeys,
  ProjectsService
} from 'data/modules/projects';

export function useGetProjects({
  enabled,
  ...payload
}: IUseGetProjectsParams): IUseGetProjects {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [ProjectsQueryKeys.GET_PROJECTS, payload],
    queryFn: async () => await ProjectsService.getProjects(payload),
    enabled
  });

  return {
    projects: data ?? [],
    isLoadingProjects: isLoading || isFetching
  };
}
