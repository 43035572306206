import { BadgeStatus, DSIcons } from 'ds';
import { useTranslation } from 'react-i18next';

import { type ITransactionDetailsHeaderProps } from './TransactionDetailsHeader.types';

import { Container } from './TransactionDetailsHeader.styles';

export function TransactionDetailsHeader({
  description,
  datetime,
  value,
  internationalValue,
  reversalType
}: ITransactionDetailsHeaderProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Container>
      <span>{description}</span>

      <strong>{value}</strong>

      {internationalValue && <span>{internationalValue}</span>}

      {reversalType && (
        <BadgeStatus type='warning'>
          <DSIcons.WarningEssentialIcon />{' '}
          {reversalType === 'PARTIALLY'
            ? t('partialReversal')
            : t('totalReversal')}
        </BadgeStatus>
      )}

      <small>{datetime}</small>
    </Container>
  );
}
