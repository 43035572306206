import { useCardDetailsContext, useLangContext } from 'data/contexts';
import {
  type IExpensesPendingAccountability,
  useGetUserExpensesInformation
} from 'data/modules/expenses';

import { type IUseCardExpensesInfoOverview } from './CardExpensesInfoOverview.types';

export function useCardExpensesInfoOverview(): IUseCardExpensesInfoOverview {
  const { selectedAccount } = useCardDetailsContext();

  const {
    currentLangKey,
    lang: {
      cards: {
        management: { card_details: cardDetails }
      }
    }
  } = useLangContext();

  const { userExpensesDetachedList, isLoadingUserExpensesInformation } =
    useGetUserExpensesInformation({
      userUuid: selectedAccount?.userId || ''
    });

  function getLabelByStatus(
    status: IExpensesPendingAccountability['status']
  ): string {
    switch (status.toUpperCase()) {
      case 'AVULSA':
        return cardDetails.unreported_expenses[currentLangKey];
      case 'ABERTO':
        return cardDetails.opened_expenses[currentLangKey];
      case 'ENVIADO':
        return cardDetails.sent_expenses[currentLangKey];
      case 'REPROVADO':
        return cardDetails.rejected_expenses[currentLangKey];
      default:
        return '';
    }
  }

  return {
    isLoadingExpenses: isLoadingUserExpensesInformation,
    userExpensesDetachedList,
    getLabelByStatus
  };
}
