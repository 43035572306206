import { Avatar, BadgeStatus, DefaultTable, Tooltip } from 'ds';
import { useTranslation } from 'react-i18next';

import { useLangContext } from 'data/contexts';

import { TooltipOverflowContent } from 'presentation/components/global/TooltipOverflowContent';

import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

import { type IUserAnalysisExpensesTableRowProps } from './UserAnalysisExpensesTableRow.types';

import {
  AvatarContainer,
  ExpenseValueWrapper,
  TableCellWithOverflow,
  TableRowContainer,
  TextWithOverflow
} from './UserAnalysisExpensesTableRow.styles';

export function UserAnalysisExpensesTableRow({
  onRowClick,
  creatorName,
  expenseDate,
  expenseType,
  expenseTitle,
  expenseValue,
  expenseImage,
  showUserColumn,
  expenseProject,
  convertedValue,
  expenseImageType,
  originalCurrency,
  convertedCurrency,
  observationsCount,
  expenseCostsCenter,
  expensePaymentMethod,
  hasPendingConversion,
  refundablePaymentMethod
}: IUserAnalysisExpensesTableRowProps): JSX.Element {
  const { t } = useTranslation('global');
  const { lang, currentLangKey } = useLangContext();

  return (
    <TableRowContainer
      onClick={onRowClick}
      $hasPendingConversion={hasPendingConversion}
    >
      <DefaultTable.Td
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {expenseImage ? (
          expenseImageType === 'IMG' ? (
            <AvatarContainer>
              <Avatar
                size='44'
                viewImage
                hasZoomControl
                className='expense-attachment-img'
                image={{
                  src: expenseImage
                }}
              />
            </AvatarContainer>
          ) : (
            <AvatarContainer
              onClick={() => window.open(expenseImage, '_blank')}
            >
              <Avatar
                size='40'
                icon='FileIcon'
              />
            </AvatarContainer>
          )
        ) : (
          <Tooltip
            message={
              expenseType === 'Percurso'
                ? lang.expenses.table.items.route[currentLangKey]
                : lang.expenses.table.items.expenseWithoutReceipt[
                    currentLangKey
                  ]
            }
            placement='bottom'
            trigger='hover'
            containerClassName='attachment-tooltip'
          >
            <AvatarContainer>
              <Avatar
                size='44'
                iconAsImage={expenseType === 'Percurso'}
                icon={
                  expenseType === 'Percurso' ? 'RouteIcon' : 'ImagePictureIcon'
                }
              />
            </AvatarContainer>
          </Tooltip>
        )}
      </DefaultTable.Td>

      <DefaultTable.Td>{CustomDate.formatFromISO(expenseDate)}</DefaultTable.Td>
      {showUserColumn && (
        <DefaultTable.Td truncate>{creatorName}</DefaultTable.Td>
      )}
      <DefaultTable.Td truncate>{expenseTitle}</DefaultTable.Td>
      <DefaultTable.Td>{expenseType}</DefaultTable.Td>
      <DefaultTable.Td truncate>{expenseCostsCenter ?? '-'}</DefaultTable.Td>
      <DefaultTable.Td>
        <TableCellWithOverflow>
          <TooltipOverflowContent
            tooltipMessage={expenseProject ?? '-'}
            tooltipContainerClassName='tooltip-container'
          >
            <TextWithOverflow>{expenseProject ?? '-'}</TextWithOverflow>
          </TooltipOverflowContent>
        </TableCellWithOverflow>
      </DefaultTable.Td>
      <DefaultTable.Td>
        {expensePaymentMethod ? (
          <Tooltip
            trigger='hover'
            placement='top'
            message={`${expensePaymentMethod}: ${
              refundablePaymentMethod ? t('reimbursable') : t('nonReimbursable')
            }`}
          >
            <TextWithOverflow>{expensePaymentMethod}</TextWithOverflow>
          </Tooltip>
        ) : (
          '-'
        )}
      </DefaultTable.Td>
      <DefaultTable.Td>
        <ExpenseValueWrapper>
          <span>
            {Currency.format(
              originalCurrency ?? 'BRL',
              expenseValue ?? 0,
              true
            )}
          </span>
          {hasPendingConversion
            ? t('noConversion')
            : convertedCurrency !== null
              ? Currency.format(
                  convertedCurrency ?? 'BRL',
                  convertedValue ?? 0,
                  true
                )
              : ''}
        </ExpenseValueWrapper>
      </DefaultTable.Td>
      <DefaultTable.Td>
        <BadgeStatus type={observationsCount ? 'secondary' : 'general'}>
          {observationsCount}
        </BadgeStatus>
      </DefaultTable.Td>
    </TableRowContainer>
  );
}
