import { useQueryCache } from 'data/cache';
import {
  BalanceQueryKeys,
  BalanceService,
  type IGetMyValueSolicitationsPayload,
  type IUseGetMyValueSolicitations
} from 'data/modules/cards/balance';

export function useGetMyValueSolicitations({
  ...payload
}: IGetMyValueSolicitationsPayload): IUseGetMyValueSolicitations {
  const {
    data: myValueSolicitations,
    isFetching: isFetchingMyValueSolicitations
  } = useQueryCache({
    queryKey: [BalanceQueryKeys.GET_MY_VALUE_SOLICITATIONS, payload],
    queryFn: async () => await BalanceService.getMyValueSolicitations(payload)
  });

  return {
    myValueSolicitations,
    isFetchingMyValueSolicitations
  };
}
