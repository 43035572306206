import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingService,
  type IApproveLegalRepresentativePayload,
  type IUseApproveLegalRepresentative,
  type IUseApproveLegalRepresentativeParams
} from 'data/modules/cards/companyOnboarding';

export function useApproveLegalRepresentative({
  hashCode,
  onApproveLegalRepresentativeMutationSuccess
}: IUseApproveLegalRepresentativeParams): IUseApproveLegalRepresentative {
  const {
    isLoading: isApprovingLegalRepresentative,
    mutate: approveLegalRepresentativeMutate
  } = useMutationCache({
    mutationKey: [CompanyOnboardingMutationKeys.APPROVE_LEGAL_REPRESENTATIVE],
    mutationFn: async (payload: IApproveLegalRepresentativePayload) =>
      await CompanyOnboardingService.approveLegalRepresentative(payload),
    onSuccess: (data, { approve }) => {
      onApproveLegalRepresentativeMutationSuccess(approve);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function approveLegalRepresentative(approve: boolean): void {
    if (isApprovingLegalRepresentative) return;

    approveLegalRepresentativeMutate({
      hashCode,
      approve
    });
  }

  return {
    approveLegalRepresentative,
    isApprovingLegalRepresentative
  };
}
