import { faker } from '@faker-js/faker';

import { type IPaginatedCardGroup } from 'data/modules/cards/cardGroups';

import { MockApi } from 'shared/utils/global';

export async function getCardGroupsMock(): Promise<IPaginatedCardGroup> {
  const data = [
    {
      id: faker.string.uuid(),
      name: 'Comercial',
      balanceId: faker.string.uuid(),
      availableValue: undefined,
      isManager: false
    },
    {
      id: faker.string.uuid(),
      name: 'Customer Success',
      balanceId: faker.string.uuid(),
      availableValue: undefined,
      isManager: false
    },
    {
      id: faker.string.uuid(),
      name: 'Customer Experience',
      balanceId: faker.string.uuid(),
      availableValue: undefined,
      isManager: false
    },
    {
      id: faker.string.uuid(),
      name: 'Engenharia',
      balanceId: faker.string.uuid(),
      availableValue: undefined,
      isManager: false
    },
    {
      id: faker.string.uuid(),
      name: 'Financeiro',
      balanceId: faker.string.uuid(),
      availableValue: undefined,
      isManager: false
    }
  ];

  await MockApi.sleep(2000);

  return {
    data,
    total: data.length
  };
}
