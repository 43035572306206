import { type IAnalysisHeaderProps } from './AnalysisHeader.types';

import { Container, Description, Title } from './AnalysisHeader.styles';

export function AnalysisHeader({
  title,
  description,
  ...props
}: IAnalysisHeaderProps): JSX.Element {
  return (
    <Container {...props}>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
}
