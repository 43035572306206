import { DirectionsRenderer, GoogleMap } from '@react-google-maps/api';
import { tokens } from 'ds/tokens';

import './MapsDefault.css';

import { useMapsDefault } from './useMapsDefault';

import { type IMapsDefaultProps } from './MapsDefault.types';

import { Container } from './MapsDefault.styles';

export function MapsDefault({
  defaultCenter = {
    lat: -23.533773,
    lng: -46.62529
  },
  positions,
  isLoaded,
  langKey = 'pt',
  ...rest
}: IMapsDefaultProps): JSX.Element {
  const { markers } = useMapsDefault({
    positions
  });

  const loadingMessage = {
    pt: 'Carregando',
    en: 'Loading',
    es: 'Cargando'
  };

  if (!isLoaded) {
    return <h1>{loadingMessage[langKey]}...</h1>;
  }

  return (
    <Container {...rest}>
      <GoogleMap
        id='google-map'
        center={defaultCenter}
        options={{
          maxZoom: 15,
          scrollwheel: true,
          mapTypeId: 'roadmap',
          zoom: 13
        }}
        mapContainerStyle={{
          width: '100%',
          height: '100%'
        }}
      >
        {markers}

        {!!positions && (
          <>
            <DirectionsRenderer
              options={{
                polylineOptions: {
                  strokeColor: tokens.brandColorSecondaryMedium,
                  strokeWeight: 7
                },
                suppressMarkers: true
              }}
              directions={positions}
            />
            <DirectionsRenderer
              options={{
                polylineOptions: {
                  strokeColor: tokens.brandColorSecondaryLight,
                  strokeWeight: 5
                },
                suppressMarkers: true
              }}
              directions={positions}
            />
          </>
        )}
      </GoogleMap>
    </Container>
  );
}
