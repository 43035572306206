import { useQueryCache } from 'data/cache';
import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUseGetCompanyOnboardingAnswers,
  type IUseGetCompanyOnboardingAnswersParams
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';

export function useGetCompanyOnboardingAnswers({
  enabled
}: IUseGetCompanyOnboardingAnswersParams): IUseGetCompanyOnboardingAnswers {
  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const { data, isLoading } = useQueryCache({
    queryKey: [
      CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_ANSWERS,
      cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    ],
    queryFn: async () =>
      await CompanyOnboardingService.getCompanyOnboardingAnswers({
        uuidCompanyOnboarding:
          cachedCompanyOnboardingProgress?.onboardingCompanyId as string
      }),
    enabled
  });

  return {
    companyOnboardingAnswers: data ?? {},
    isCompanyOnboardingAnswersLoading: enabled && isLoading
  };
}
