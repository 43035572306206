import { useInfiniteQueryCache } from 'data/cache';

import { CardGroupsQueryKeys } from '../../keys/cardGroups.keys';
import CardGroupsService from '../../services/CardGroups.service';

import {
  type IInfiniteCardGroupUsersParams,
  type IUseInfiniteCardGroupUsers
} from '../../types/cardGroups.types';

export function useInfiniteCardGroupUsers(
  params: IInfiniteCardGroupUsersParams
): IUseInfiniteCardGroupUsers {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQueryCache({
      queryKey: [CardGroupsQueryKeys.GET_GROUP_USERS, params],
      initialPageParam: 1,
      queryFn: async ({ pageParam }) =>
        await CardGroupsService.getGroupUsers({
          ...params,
          page: pageParam
        }),
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        const totalPages = Math.ceil(lastPage.total) / params.limit;
        const isLastPage = allPages.length >= totalPages;

        if (isLastPage) {
          return null;
        }

        return lastPageParam + 1;
      }
    });

  return {
    infiniteCardGroupUsers: data?.pages.flatMap(page => page.data),
    isLoadingInfiniteCardGroupUsers: isLoading,
    cardGroupUsersPagination: {
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage
    }
  };
}
