import { useQueryCache } from 'data/cache';
import {
  type IUseGetRelatedTeamMembers,
  type IUseGetRelatedTeamMembersParams,
  TeamMembersQueryKeys,
  TeamMembersService
} from 'data/modules/teamMembers';

export function useGetRelatedTeamMembers({
  enabled,
  ...payload
}: IUseGetRelatedTeamMembersParams): IUseGetRelatedTeamMembers {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [TeamMembersQueryKeys.GET_RELATED_TEAM_MEMBERS, payload],
    queryFn: async () =>
      await TeamMembersService.getRelatedTeamMembers(payload),
    enabled
  });

  return {
    relatedTeamMembers: data ?? [],
    isLoadingRelatedTeamMembers: isLoading || isFetching
  };
}
