import { DefaultTable, Pagination } from 'ds';
import { useTranslation } from 'react-i18next';

import { useLangContext } from 'data/contexts';

import { TeamMembersTableRow } from 'presentation/pages/settings/companyData/TeamMembers/components/TeamMembersTableRow';
import { TeamMembersTableSkeleton } from 'presentation/pages/settings/companyData/TeamMembers/components/TeamMembersTableSkeleton';

import { useTeamMembersTable } from './useTeamMembersTable';

import { type ITeamMembersTableProps } from './TeamMembersTable.types';

import { Container, Content } from './TeamMembersTable.styles';

export function TeamMembersTable({
  filters,
  teamMembers,
  onSortTeamMembers,
  isLoadingTeamMembers,
  isLoadingChargedUsers
}: ITeamMembersTableProps): JSX.Element {
  const currentLangKey = useLangContext(state => state.currentLangKey);
  const { t } = useTranslation(['settings', 'global']);

  const {
    perPage,
    totalPage,
    getThProps,
    currentPage,
    handleChangePage,
    handleChangePerPage
  } = useTeamMembersTable({
    onSortTeamMembers,
    filters
  });

  return (
    <Container>
      <Content>
        <DefaultTable.Container>
          <DefaultTable.Thead>
            <DefaultTable.Tr>
              <DefaultTable.Th width='5%' />
              <DefaultTable.Th {...getThProps('name')}>
                {t('global:name')}
              </DefaultTable.Th>

              <DefaultTable.Th {...getThProps('safe_email')}>
                {t('global:email')}
              </DefaultTable.Th>

              <DefaultTable.Th {...getThProps('type_user')}>
                {t('teamMemberForm.userType')}
              </DefaultTable.Th>

              <DefaultTable.Th {...getThProps('cpf')}>CPF/CNPJ</DefaultTable.Th>

              <DefaultTable.Th
                width='15%'
                {...getThProps('active')}
              >
                Status
              </DefaultTable.Th>

              <DefaultTable.Th width='5%'>
                {t('global:actions')}
              </DefaultTable.Th>
            </DefaultTable.Tr>
          </DefaultTable.Thead>

          <DefaultTable.Tbody>
            {isLoadingTeamMembers ? (
              <TeamMembersTableSkeleton />
            ) : (
              teamMembers.map(member => (
                <TeamMembersTableRow
                  isLoadingChargedUsers={isLoadingChargedUsers}
                  key={member.id}
                  {...member}
                />
              ))
            )}
          </DefaultTable.Tbody>
        </DefaultTable.Container>
      </Content>
      {!isLoadingTeamMembers && (
        <Pagination.Container
          className='pagination-team-members-container'
          currentPage={currentPage}
          totalPage={totalPage}
          perPage={perPage}
        >
          <Pagination.Label langKey={currentLangKey} />
          <Pagination.Control onChangePage={handleChangePage} />
          <Pagination.Pages
            onChangePerPage={handleChangePerPage}
            langKey={currentLangKey}
            options={[10, 20, 50]}
          />
        </Pagination.Container>
      )}
    </Container>
  );
}
