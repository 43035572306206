import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  width: 100%;
`;

export const DetailsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${tokens.spacingXs};
`;

export const RegularText = styled.div`
  display: flex;
  color: ${tokens.neutralColorLowLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightRegular};
  max-width: 100%;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const TopAmount = styled.div`
  display: flex;
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightSemibold};
`;

export const Subtitle = styled.div`
  display: flex;
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${tokens.spacingNano};
  max-width: 100%;
`;

export const DashedLine = styled.div`
  display: flex;
  width: 100%;
  height: 0.1rem;
  background: repeating-linear-gradient(
    90deg,
    ${tokens.neutralColorHighDark} 0 1rem,
    #0000 0 2rem
  );
`;
