import { Modal, tokens } from 'ds';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  margin-top: 1rem;

  .react-select-custom__control,
  .react-select-custom__menu {
    max-width: unset;
    min-width: unset;
  }

  .react-select-custom__menu {
    z-index: 3;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    display: flex;
    justify-content: end;
    gap: ${tokens.spacingMd};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column-reverse;
    gap: ${tokens.spacingXs};

    > div {
      flex-direction: column;
      gap: ${tokens.spacingXs};
    }
  }
`;

export const MoreOptionsButtonContainer = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 0.8rem;

  button {
    padding: 0;
    pointer-events: none;
  }
`;

export const OptionalInputsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};

  .react-select-custom__control,
  .react-select-custom__menu {
    max-width: unset;
    min-width: unset;
  }

  .react-select-custom__menu {
    z-index: 3;
  }

  .textarea-observation > textarea {
    min-height: 6rem;
  }
`;

export const StyledModalContent = styled(Modal.Content)`
  padding-right: 0;
`;
