import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6.575rem;
  row-gap: 1.6rem;
  font-size: 2rem;
  padding: 3.2rem 2rem;
  align-items: center;
  bottom: 0;
  background-color: ${({ theme }) => theme.tokens.neutralColorHighLight};
  z-index: 2;
  box-shadow: 0 0 3px 2px rgb(0 0 0 / 6%);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 1.6rem 3.5rem;
    margin: 0 -1.6rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-flow: row wrap;
    position: sticky;
    column-gap: 0;
    margin-top: auto;
  }

  @media screen and (min-width: 1367px) {
    margin: 0 -3.2rem;
    margin-top: auto;
  }
`;

export const CardsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  row-gap: 2.5rem;
  width: 100%;
  white-space: nowrap;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2rem;
    max-width: 85rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    max-width: none;
    margin-right: 2rem;

    button {
      width: 13.2rem;
    }
  }

  @media screen and (min-width: 1690px) {
    width: 77%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 28.8rem;
  width: 100%;
  max-width: 50rem;

  button {
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 2.4rem;
    max-width: 85rem;

    button {
      width: 13.2rem;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    justify-content: flex-end;
    align-self: center;
    max-width: 28.8rem;
  }
`;
