import { useMemo } from 'react';

import { useLangContext, useModalContext } from 'data/contexts';
import {
  type IGetAccountHoldersParams,
  useGetAccountHolders
} from 'data/modules/cards/accountHolders';

import { type ISelectOption } from 'presentation/components/base/Select/Select.types';

import { type IUseCreateBusinessCardModal } from './CreateBusinessCardModal.types';

export function useCreateBusinessCardModal(): IUseCreateBusinessCardModal {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { visibleModal } = useModalContext();

  const payload = {
    page: 1,
    type: 'BUSINESS',
    limit: 1000,
    enabled: visibleModal === 'createBusinessCard'
  } as IGetAccountHoldersParams;

  const { accountHolders, isLoadingAccountHolders } =
    useGetAccountHolders(payload);

  const accountHoldersOptions: ISelectOption[] = useMemo(() => {
    return accountHolders
      ?.map(el => ({
        value: el.id,
        label: `${el.legalName} ${el.name ? ` - ${el.name}` : ''} - (${
          lang.cards.kycStatus[
            el.kycStatus
              ? (el.kycStatus.toLowerCase() as keyof typeof lang.cards.kycStatus)
              : 'reproved'
          ][currentLangKey]
        })`,
        isDisabled: el.kycStatus !== 'APPROVED'
      }))
      .sort((a, b) => Number(a.isDisabled) - Number(b.isDisabled));
  }, [accountHolders, currentLangKey, lang]);

  return { accountHoldersOptions, isLoadingAccountHolders };
}
