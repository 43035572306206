import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { reportStatusColors } from 'shared/constants/reports';

import {
  type ReportInfoStylesType,
  type ReportStatusStylesType
} from './ExpenseAndReportDetails.types';

export const ReportInfo = styled.div<AddPrefixToType<ReportInfoStylesType>>`
  padding-left: 1rem;
  margin-left: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-left: 0;
    margin-left: 0;
  }

  > p {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.emperor};
    line-height: 3rem;

    ${({ $isTransactionReversed }) =>
      $isTransactionReversed &&
      css`
        text-decoration: line-through;

        > span {
          text-decoration: line-through;
        }
      `}
  }
`;

export const ReportStatus = styled.span<ReportStatusStylesType>`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ status, theme }) =>
    theme.colors[reportStatusColors[status] || 'emperor']};
`;
