import { api } from 'data/config';
import {
  type IExpensePolicies,
  type IGetExpensePoliciesPayload
} from 'data/modules/expensePolicies';
import { type IDefaultResponse } from 'data/modules/global';

import { PaginationMapper } from 'shared/mappers';

class ExpensePoliciesService {
  async getExpensePolicies(
    payload: IGetExpensePoliciesPayload
  ): Promise<IExpensePolicies[]> {
    const {
      data: { data }
    } = await api.get<IDefaultResponse<IExpensePolicies[]>>(
      '/expense-policies',
      {
        params: PaginationMapper.toPersistence(payload)
      }
    );

    return data;
  }
}

export default new ExpensePoliciesService();
