import { Avatar, SecondaryButton } from 'ds';
import { useTranslation } from 'react-i18next';

import { useLangContext } from 'data/contexts';
import { type LanguageKeyType } from 'data/contexts/lang/useLangContext.types';

import { useSelectOdotometerImage } from './useSelectOdometerImage';

import {
  AvatarContent,
  Caption,
  Container,
  ContainerImage,
  InputFile
} from './SelectOdometerImage.styles';

export function SelectOdometerImage(): JSX.Element {
  const { t } = useTranslation('expenses');

  const { currentLangKey } = useLangContext();

  const {
    handleChangeFile,
    inputRef,
    blobImageFile,
    fileTypeError,
    hasError,
    hookFormError,
    sizeError,
    acceptedFileTypes
  } = useSelectOdotometerImage();

  const acceptedFileTypesMessageTemplate = acceptedFileTypes
    .join(', ')
    .replace(/,(?=[^,]*$)/, ' {{or}}');

  const acceptedFileTypesMessage: Record<LanguageKeyType, string> = {
    pt: acceptedFileTypesMessageTemplate.replace('{{or}}', 'ou'),
    en: acceptedFileTypesMessageTemplate.replace('{{or}}', 'or'),
    es: acceptedFileTypesMessageTemplate.replace('{{or}}', 'o')
  };

  return (
    <Container>
      <ContainerImage $hasImage={!!blobImageFile}>
        <AvatarContent>
          <InputFile
            type='file'
            onChange={handleChangeFile}
            ref={inputRef}
            accept='image/jpg, image/jpeg, image/png'
          />

          <Avatar
            size='32'
            {...(blobImageFile
              ? {
                  image: {
                    src: blobImageFile as string
                  },
                  viewImage: true,
                  hasZoomControl: true,
                  className: 'avatar-odometer-image'
                }
              : {
                  icon: 'ImagePictureIcon',
                  className: 'avatar-odometer-with-icon'
                })}
          />

          <span>{t('createFuelExpenseModal.odometer')}</span>
        </AvatarContent>

        <SecondaryButton
          type='button'
          size='small'
          className='select-odometer-image-button'
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          {blobImageFile
            ? t('createFuelExpenseModal.switch')
            : t('createFuelExpenseModal.image')}
        </SecondaryButton>
      </ContainerImage>

      {hasError && (
        <Caption>
          {hookFormError ||
            (fileTypeError &&
              t('createFuelExpenseModal.incompatibleFileSelectATypeFile', {
                type: acceptedFileTypesMessage[currentLangKey]
              })) ||
            (sizeError &&
              t(
                'createFuelExpenseModal.fileMustBeLessThan16MBTryAgainWithAnotherFile'
              ))}
        </Caption>
      )}
    </Container>
  );
}
