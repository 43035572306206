import styled from 'styled-components';

export const Header = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  padding-right: 0.5rem;

  > h1,
  > span {
    color: ${({ theme }) => theme.colors.emperor};
    font-size: 1.8rem;
    font-weight: 300;
  }
`;

export const Content = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  min-width: 25rem;
  max-width: 72.7rem;
  width: 100%;
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.emperor};
  text-transform: uppercase;

  > svg {
    margin-left: 0.8rem;
  }
`;

export const PendencyInfo = styled.div`
  margin-bottom: 1rem;

  > p {
    font-size: 1.2rem;
    line-height: 2.5rem;
    color: ${({ theme }) => theme.colors.emperor};
  }
`;

export const Value = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.clementine};

  span {
    font-size: 1.2rem;
  }
`;
