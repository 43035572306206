import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import {
  type StatusCircleStylesType,
  type StatusLineStylesType,
  type StatusTextStylesType
} from './ValueSolicitationTimeline.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatusContainer = styled.div`
  display: flex;

  svg {
    width: 1.2rem;

    path {
      stroke: ${tokens.neutralColorHighPure};
    }
  }
`;

export const StatusCircle = styled.div<AddPrefixToType<StatusCircleStylesType>>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: ${tokens.brandColorSecondaryPure};
  border-radius: 50%;

  ${({ $size }) =>
    $size === 'small' &&
    css`
      width: 1.6rem;
      height: 1.6rem;
    `}

  ${({ $variant }) =>
    $variant === 'yellow' &&
    css`
      background-color: ${tokens.highlightColorPure};
    `}

    ${({ $variant }) =>
    $variant === 'green' &&
    css`
      background-color: ${tokens.feedbackColorSuccessPure};
    `}

    ${({ $variant }) =>
    $variant === 'red' &&
    css`
      background-color: ${tokens.feedbackColorErrorPure};
    `}
`;

export const StatusCircleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
  width: 1.9rem;
`;

export const StatusLine = styled.div<AddPrefixToType<StatusLineStylesType>>`
  width: 0.1rem;
  background-color: ${tokens.brandColorSecondaryPure};
  height: 1.6rem;
  margin: 0.4rem 0;

  ${({ $variant }) =>
    $variant === 'yellow' &&
    css`
      background-color: ${tokens.highlightColorPure};
    `}

  ${({ $variant }) =>
    $variant === 'green' &&
    css`
      background-color: ${tokens.feedbackColorSuccessPure};
    `}

    ${({ $variant }) =>
    $variant === 'red' &&
    css`
      background-color: ${tokens.feedbackColorErrorPure};
    `}
`;

export const StatusText = styled.div<AddPrefixToType<StatusTextStylesType>>`
  display: flex;
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightRegular};
  letter-spacing: 0.075rem;
  line-height: 1.6rem;
  padding-top: 0.1rem;

  ${({ $hasPaddingAdjustment }) =>
    $hasPaddingAdjustment &&
    css`
      padding-top: 0.4rem;
    `}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: ${tokens.fontSizeXxss};
  }
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const JustifyText = styled.div`
  display: flex;
  color: ${tokens.neutralColorLowLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightRegular};
  letter-spacing: 0.075rem;
  line-height: 1.6rem;
  margin-top: 0.4rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: ${tokens.fontSizeXxss};
  }
`;
