import { Fragment } from 'react';

import { DefaultButton, Modal, PasswordInput, SecondaryButton } from 'ds';
import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';

import CreditCard from 'shared/assets/images/cards/card-background.svg';
import { animations } from 'shared/constants/animations';

import { useChangePinModal } from './useChangePinModal';

import {
  CardContainer,
  InputsContainer,
  ModalContainer,
  ModalFooter,
  ModalForm
} from './ChangePinModal.styles';

export function ChangePinModal(): JSX.Element {
  const { t } = useTranslation(['global', 'cards']);
  const { handleCloseModal } = useModalContext();

  const { methods, isLoading, handleSubmit } = useChangePinModal();

  return (
    <Fragment>
      <ModalContainer
        onClose={() => {
          handleCloseModal();
        }}
      >
        <Modal.Header>
          <Modal.Title>{t('cards:changeCardPin')}</Modal.Title>

          <Modal.IconClose
            onClick={() => {
              handleCloseModal();
            }}
          />
        </Modal.Header>

        <ModalForm
          methods={methods}
          handleSubmit={handleSubmit}
        >
          <p>{t('cards:setFourDigitNumericPassword')}</p>

          <CardContainer {...animations.popFadeInOut}>
            <img
              src={CreditCard}
              alt={t('cards:changeCardPin')}
            />
          </CardContainer>

          <InputsContainer>
            <PasswordInput.Controlled
              label={t('cards:newCardPassword')}
              name='password'
              mask='numeric'
              maxLength={4}
              placeholder={t('cards:newCardPassword')}
              onClearValue={() => {
                methods.setValue('password', '');
              }}
            />

            <PasswordInput.Controlled
              label={t('cards:confirmNewCardPassword')}
              name='confirmPassword'
              mask='numeric'
              maxLength={4}
              placeholder={t('cards:confirmNewCardPassword')}
              onClearValue={() => {
                methods.setValue('confirmPassword', '');
              }}
            />
          </InputsContainer>

          <ModalFooter>
            <SecondaryButton
              size='small'
              onClick={() => {
                handleCloseModal();
              }}
              type='button'
            >
              {t('global:cancel')}
            </SecondaryButton>

            <DefaultButton size='small'>{t('cards:changePin')}</DefaultButton>
          </ModalFooter>
        </ModalForm>
      </ModalContainer>

      <Loader isLoading={isLoading} />
    </Fragment>
  );
}
