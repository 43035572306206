import {
  type IPersistencePixQrCodeString,
  type IPixQrCode
} from 'data/modules/cards/balance';

class PixQrCodeMapper {
  toDomain(persistenceDeposit: IPersistencePixQrCodeString): IPixQrCode | null {
    if (persistenceDeposit === null) return null;

    return {
      amount: persistenceDeposit.amount,
      emv: persistenceDeposit.emv,
      id: persistenceDeposit.id,
      transactionIdentification: persistenceDeposit.transaction_identification
    };
  }
}

export default new PixQrCodeMapper();
