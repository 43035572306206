import { Outlet } from 'react-router-dom';

import { useGetStoreProducts } from 'data/modules/store';

import { SplashScreen } from 'presentation/components/global/SplashScreen';

import { CustomCookies } from 'shared/utils/global';

export function StoreMiddleware(): JSX.Element {
  const { isLoadingStoreProducts } = useGetStoreProducts();

  if (isLoadingStoreProducts) {
    return <SplashScreen />;
  }

  /** Colapsa sidebar automaticamente, ao acessar alguma rota da Store */
  CustomCookies.set('sidebar', 'collapsed', {
    daysToExpire: 2
  });

  return <Outlet />;
}
