import {
  type IGetUsersWithoutCardGroupPayload,
  type IGetUsersWithoutCardGroupPersistencePayload,
  type IPersistenceUserCardGroup,
  type IUserGroup
} from 'data/modules/cards/cardGroups/types/cardGroups.types';

class GetUsersWithoutCardGroupMapper {
  toDomain(
    persistenceUsersWithoutCardGroup: IPersistenceUserCardGroup
  ): IUserGroup {
    return {
      id: persistenceUsersWithoutCardGroup.user_id,
      name: persistenceUsersWithoutCardGroup.user_name
    };
  }

  toPersistence(
    domainPayload: IGetUsersWithoutCardGroupPayload
  ): IGetUsersWithoutCardGroupPersistencePayload {
    return {
      page: domainPayload.page,
      limit: domainPayload.limit,
      user_name: domainPayload.userName,
      card_group_id: domainPayload.cardGroupId
    };
  }
}
export default new GetUsersWithoutCardGroupMapper();
