import { DefaultButton, Modal, SecondaryButton, Select, TextInput } from 'ds';

import { useLangContext } from 'data/contexts';

import { Spinner } from 'presentation/components/base/Spinner';

import { useGa4 } from 'shared/hooks/global';

import { requestModalSelectCardQuantities } from './constants';
import { useRequestModal } from './useRequestModal';

import { type IRequestModalProps } from './RequestModal.types';

import {
  Container,
  Footer,
  LoadingContainer,
  Subtitle
} from './RequestModal.styles';

export function RequestModal({
  identifier,
  onClose
}: IRequestModalProps): JSX.Element {
  const { sendGaEvent } = useGa4();

  const {
    title,
    methods,
    handleSendGaEvent,
    isRequestingProduct,
    handleRequestProduct,
    handleSendSelectChangeValueGaEvent
  } = useRequestModal({
    identifier
  });

  const {
    currentLangKey,
    lang: {
      global,
      forms: { select },
      store: { requestModal }
    }
  } = useLangContext();

  return (
    <Modal.Container
      onClose={onClose}
      variant='medium'
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Form
        methods={methods}
        handleSubmit={handleRequestProduct}
      >
        <Modal.Content>
          <Container>
            <Subtitle>
              {['cards', 'cards-pj'].includes(identifier)
                ? requestModal.cards_subtitle[currentLangKey]
                : requestModal.default_subtitle[currentLangKey]}
            </Subtitle>
            <TextInput.Controlled
              name='email'
              onClick={handleSendGaEvent('email')}
              label={requestModal.email.label[currentLangKey]}
              placeholder={requestModal.email.placeholder[currentLangKey]}
            />
            <TextInput.Controlled
              name='phone'
              mask='phone'
              onClick={handleSendGaEvent('phone')}
              label={requestModal.phone.label[currentLangKey]}
              placeholder={requestModal.phone.placeholder[currentLangKey]}
            />
            {['cards', 'cards-pj'].includes(identifier) && (
              <>
                <Select.Controlled
                  name='cardsQtt'
                  hasArrowDownIndicator
                  noOptionsMessage={select.no_more_options[currentLangKey]}
                  label={requestModal.quantity.placeholder[currentLangKey]}
                  onCustomChange={handleSendSelectChangeValueGaEvent(
                    'cardsQtt'
                  )}
                  placeholder={
                    requestModal.quantity.placeholder[currentLangKey]
                  }
                  options={requestModalSelectCardQuantities.map(quantity => ({
                    label: quantity,
                    value: quantity
                  }))}
                />
                <Select.Controlled
                  name='cardsUseType'
                  hasArrowDownIndicator
                  label={requestModal.usage.placeholder[currentLangKey]}
                  noOptionsMessage={select.no_more_options[currentLangKey]}
                  placeholder={requestModal.usage.placeholder[currentLangKey]}
                  onCustomChange={handleSendSelectChangeValueGaEvent(
                    'cardsUseType'
                  )}
                  options={requestModal.select_usage_options.map(usage => ({
                    label: usage[currentLangKey],
                    value: usage.pt
                  }))}
                />
              </>
            )}
          </Container>
        </Modal.Content>
        <Modal.Footer>
          <Footer>
            {isRequestingProduct && (
              <LoadingContainer>
                <Spinner
                  color='primary'
                  size={2}
                />
                <p>{requestModal.loading_message[currentLangKey]}</p>
              </LoadingContainer>
            )}
            <SecondaryButton
              onClick={() => {
                sendGaEvent('store', '-storeModal_cancelarSolicitacao');
                onClose();
              }}
            >
              {global.cancel[currentLangKey]}
            </SecondaryButton>
            <DefaultButton>{global.request[currentLangKey]}</DefaultButton>
          </Footer>
        </Modal.Footer>
      </Modal.Form>
    </Modal.Container>
  );
}
