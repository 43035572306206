import { DefaultButton, Modal, SecondaryButton } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useModalContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';
import {
  ListAndSearch,
  type ListSearchType
} from 'presentation/pages/cards/Management/CardGroups/components';

import { useConfirmAttachOfCardsOnGroup } from '../../hooks';

import { useAddCardToGroupModal } from './useAddCardToGroupModal';

import { ModalContent, ModalFooter } from './AddCardToGroupModal.styles';

export function AddCardToGroupModal(): JSX.Element {
  const { handleCloseModal, handleOpenModal } = useModalContext();
  const { t } = useTranslation(['cards', 'global']);
  const {
    paginatedUsersWithoutCardGroup,
    isFetchingUsersWithoutCardGroup,
    isAddingCardsToGroup,
    usersWithoutCardGroupPagination
  } = useAddCardToGroupModal();

  const { isCheckingIfCardsCanBeAdded, handleVerifyCardsAttach } =
    useConfirmAttachOfCardsOnGroup();

  const [
    paginationUsers,
    handleChangePaginationUsers,
    selectedUsersWithoutCardGroup,
    handleToggleSelectUsersWithoutCardGroup,
    handleSetIsInCardGroupDetails
  ] = useCardGroupsContext(
    useShallow(state => [
      state.paginationUsers,
      state.handleChangePaginationUsers,
      state.selectedUsersWithoutCardGroup,
      state.handleToggleSelectUsersWithoutCardGroup,
      state.handleSetIsInCardGroupDetails
    ])
  );

  if (isAddingCardsToGroup || isCheckingIfCardsCanBeAdded) {
    return <Loader isLoading />;
  }

  return (
    <Modal.Container
      onClose={() => {
        if (isFetchingUsersWithoutCardGroup) return;

        handleCloseModal();
        handleOpenModal('groupCardDetails');
      }}
      variant='medium'
    >
      <Modal.Header>
        <Modal.Title>{t('addNewGroupCard')}</Modal.Title>

        <Modal.IconClose
          onClick={() => {
            handleCloseModal();
            handleOpenModal('groupCardDetails');
          }}
        />
      </Modal.Header>

      <ModalContent>
        <p>{t('selectGroupCards')}</p>

        <ListAndSearch
          items={
            paginatedUsersWithoutCardGroup?.map(item => ({
              ...item,
              disabled: false
            })) ?? []
          }
          searchValue={paginationUsers.userName ?? ''}
          onChangeSearch={(value: string) => {
            handleChangePaginationUsers({
              userName: value
            });
          }}
          selectedItemIds={selectedUsersWithoutCardGroup
            .filter(item => selectedUsersWithoutCardGroup.includes(item))
            .map(user => ({ name: user, id: user }))}
          onChangeSelect={(value: ListSearchType) => {
            handleToggleSelectUsersWithoutCardGroup(value.id);
          }}
          isLoading={isFetchingUsersWithoutCardGroup}
          actionFn={usersWithoutCardGroupPagination.fetchNextPage}
          hasNextPage={usersWithoutCardGroupPagination.hasNextPage}
          isFetchingNextPage={
            usersWithoutCardGroupPagination.isFetchingNextPage
          }
        />

        <ModalFooter>
          <SecondaryButton
            size='small'
            onClick={() => {
              handleCloseModal();
              handleOpenModal('groupCardDetails');
            }}
            type='button'
          >
            {t('global:cancel')}
          </SecondaryButton>

          <DefaultButton
            size='small'
            onClick={() => {
              handleSetIsInCardGroupDetails(true);
              handleVerifyCardsAttach();
            }}
            disabled={selectedUsersWithoutCardGroup.length === 0}
          >
            {t('addNewGroupCard')}
          </DefaultButton>
        </ModalFooter>
      </ModalContent>
    </Modal.Container>
  );
}
