import { useQueryCache } from 'data/cache';
import {
  ExpensesQueryKeys,
  ExpensesService,
  type IUseGetPaginatedExpenses,
  type IUseGetPaginatedExpensesParams
} from 'data/modules/expenses';

export function useGetPaginatedExpenses(
  params: IUseGetPaginatedExpensesParams
): IUseGetPaginatedExpenses {
  const {
    data: paginatedExpenses,
    isLoading: isLoadingPaginatedExpenses,
    isFetching: isFetchingPaginatedExpenses
  } = useQueryCache({
    queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES, params],
    queryFn: async () => await ExpensesService.getPaginatedExpenses(params)
  });

  return {
    paginatedExpenses,
    isLoadingPaginatedExpenses,
    isFetchingPaginatedExpenses
  };
}
