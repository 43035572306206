import { useQueryCache } from 'data/cache';
import {
  type IUseGetTeamMembersItems,
  type IUseGetTeamMembersItemsParams,
  TeamMembersQueryKeys,
  TeamMembersService
} from 'data/modules/teamMembers';

export function useGetTeamMembersItems({
  enabled,
  ...payload
}: IUseGetTeamMembersItemsParams): IUseGetTeamMembersItems {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [TeamMembersQueryKeys.GET_TEAM_MEMBERS_ITEMS, payload],
    queryFn: async () => await TeamMembersService.getTeamMembersItems(payload),
    enabled
  });

  return {
    teamMembers: data ?? [],
    isLoadingTeamMembers: isLoading || isFetching
  };
}
