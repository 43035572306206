import { toast } from 'ds/utils';
import { useController, useFormContext } from 'react-hook-form';

import { useLangContext } from 'data/contexts';
import {
  type ICreateFuelExpenseFirstStepFieldsForm,
  type ICreateManualExpenseFirstStepFieldsForm
} from 'data/modules/expenses';

import {
  type IUseObservationAttachments,
  type IUseObservationAttachmentsParams
} from './ObservationAttachments.types';

export function useObservationAttachments({
  inputRef
}: IUseObservationAttachmentsParams): IUseObservationAttachments {
  const { lang, currentLangKey } = useLangContext();

  const { control } = useFormContext<
    | ICreateFuelExpenseFirstStepFieldsForm
    | ICreateManualExpenseFirstStepFieldsForm
  >();

  const {
    field: { value: selectedFiles, onChange: onChangeFiles },
    fieldState: { error }
  } = useController({
    name: 'observationAttachments',
    control
  });

  function handleChangeFiles(e: React.ChangeEvent<HTMLInputElement>): void {
    if (!e.target.files) return;

    if (
      selectedFiles !== undefined &&
      e.target.files.length + selectedFiles.length > 3
    ) {
      toast.error(
        lang.expenses.modal.create_manual_expense
          .maximum_three_observation_attachments_error[currentLangKey]
      );
      return;
    }

    if (selectedFiles === undefined && e.target.files.length > 3) {
      toast.error(
        lang.expenses.modal.create_manual_expense
          .maximum_three_observation_attachments_error[currentLangKey]
      );
      return;
    }

    const newSelectedFiles = [
      ...(selectedFiles || []),
      ...Array.from(e.target.files)
    ];

    onChangeFiles(newSelectedFiles);
  }

  function handleDeleteFile(index: number): void {
    const filelistArray = [...(selectedFiles || [])];

    filelistArray.splice(index, 1);

    if (filelistArray.length === 0) {
      inputRef.current && (inputRef.current.value = '');
    }

    onChangeFiles(filelistArray);
  }

  return {
    selectedFiles: selectedFiles ?? [],
    handleChangeFiles,
    handleDeleteFile,
    error: error?.message
  };
}
