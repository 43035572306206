import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { Tooltip } from 'presentation/components/base/Tooltip';

import { Currency } from 'shared/utils/format';

import { useTransactionValue } from './useTransactionValue';

import { type ITransactionValueProps } from './TransactionValue.types';

import { Container, TooltipContainer } from './TransactionValue.styles';

export function TransactionValue({
  transaction
}: ITransactionValueProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const { isTransactionPartiallyReversed, transactionValue } =
    useTransactionValue({
      transaction
    });

  return (
    <Container
      $isTransactionReversed={transaction.reversed}
      $isTransactionPartiallyReversed={isTransactionPartiallyReversed}
    >
      {
        lang.cards.statement.transaction_details_modal.transaction_value[
          currentLangKey
        ]
      }
      : <span>{transactionValue}</span>
      {/* VALOR FINAL DE TRANSAÇÃO COM ESTORNO PARCIAL */}
      {isTransactionPartiallyReversed && (
        <span>
          {`${transaction.finalBillingCurrencyCode} ${Currency.format(
            transaction.finalBillingCurrencyCode,
            transaction.finalBillingAmount + transaction.reversalAmount
          )}`}
        </span>
      )}
      {/* TOOLTIP DE TRANSAÇÃO INTERNACIONAL COBRADA EM BRL */}
      {transaction.isInternational && transaction.currencyCode === 'BRL' && (
        <TooltipContainer>
          <Tooltip
            id='transactionValueTooltip'
            maxWidth={35}
            content={
              lang.cards.statement.transaction_details_modal
                .international_transaction.transaction_value_tooltip[
                currentLangKey
              ]
            }
          >
            <BaseIcon
              name='question'
              color='secondary'
              size={1.8}
            />
          </Tooltip>
        </TooltipContainer>
      )}
    </Container>
  );
}
