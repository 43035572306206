import { useQueryCache } from 'data/cache';
import {
  AnalyticsQueryKeys,
  AnalyticsService,
  type IUseGetAnalysisUserFilters
} from 'data/modules/analytics';

export function useGetAnalysisUserFilters(): IUseGetAnalysisUserFilters {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [AnalyticsQueryKeys.GET_ANALYSIS_USER_FILTERS],
    queryFn: async () => await AnalyticsService.getAnalysisUserFilters()
  });

  return {
    analysisUserFilters: data,
    isLoadingAnalysisUserFilters: isLoading,
    isFetchingAnalysisUserFilters: isFetching
  };
}
