import { format } from 'date-fns';
import { DSIcons, Tooltip } from 'ds';
import { useTranslation } from 'react-i18next';

import { useMovementsContext } from 'data/contexts';

import { useCopyTransactionIdToClipboard } from 'presentation/pages/cards/Movements/hooks';

import { CustomString } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

import { TransactionDetails } from '../TransactionDetailsModal';

export function Transfer(): JSX.Element {
  const { t } = useTranslation(['cards', 'global']);
  const transactionDetails = useMovementsContext(
    state => state.transactionDetails
  );
  const { handleCopyTransactionIdToClipboard, shouldShowTooltip } =
    useCopyTransactionIdToClipboard();

  return (
    <>
      <TransactionDetails.Header
        description={t('global:transfer')}
        datetime={`${format(transactionDetails?.datetime!, 'dd/MM/yyyy')} ${t('global:at')} ${format(transactionDetails?.datetime!, 'HH')}h${format(transactionDetails?.datetime!, 'mm')}`}
        value={Currency.format(
          'BRL',
          Math.abs(transactionDetails?.finalBillingAmount!),
          true
        )}
      />

      <TransactionDetails.Section title={t('transactionDetails')}>
        <TransactionDetails.SectionItem>
          <p>{t('global:origin')}</p>

          <p className='value'>
            {
              transactionDetails?.paymentDescription
                .split('Transf. ')[1]
                .split(' > ')[0]
            }
          </p>
        </TransactionDetails.SectionItem>

        <TransactionDetails.SectionItem>
          <p>{t('global:destiny')}</p>

          <p className='value'>
            {transactionDetails?.paymentDescription.split(' > ')[1]}
          </p>
        </TransactionDetails.SectionItem>

        <TransactionDetails.SectionItem>
          <p>{t('global:performedBy')}</p>

          <p className='value'>
            {CustomString.capitalizeWord(
              transactionDetails?.transferorName ||
                transactionDetails?.name ||
                '-'
            )}
          </p>
        </TransactionDetails.SectionItem>

        <TransactionDetails.SectionItem>
          <p>{t('transactionId')}</p>

          <div className='value'>
            {transactionDetails?.transactionId}

            <Tooltip
              trigger='focus'
              placement='top'
              message='Copiado'
              visible={shouldShowTooltip}
            >
              <div>
                <DSIcons.StickerCopyIcon
                  className='icon'
                  onClick={() => {
                    handleCopyTransactionIdToClipboard(
                      transactionDetails?.transactionId!
                    );
                  }}
                />
              </div>
            </Tooltip>
          </div>
        </TransactionDetails.SectionItem>
      </TransactionDetails.Section>
    </>
  );
}
