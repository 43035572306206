import { useNavigate } from 'react-router-dom';

import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  useUpdateCompanyPoliciesAndTerms
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';
import OnboardingUtil from 'shared/utils/cards/Onboarding.util';

import {
  type IUpdateCacheAndNavigateNextStepParams,
  type IUsePoliciesAndTerms
} from './PoliciesAndTerms.types';

export function usePoliciesAndTerms(): IUsePoliciesAndTerms {
  const navigate = useNavigate();
  const currentStep = Number(OnboardingUtil.getCurrentStep(location.pathname));

  const { data: cachedCompanyOnboardingProgress, updateCache } =
    useHandleQueryCache({
      query: {
        key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS],
        query: async () => await CompanyOnboardingService.getProgress()
      }
    });

  const { control, errors, handleUpdateCompanyPoliciesAndTerms } =
    useUpdateCompanyPoliciesAndTerms({
      onUpdateCompanyOnboardingAnswersMutationSuccess:
        updateCacheAndNavigateNextStep
    });

  function updateCacheAndNavigateNextStep(
    data: IUpdateCacheAndNavigateNextStepParams
  ): void {
    navigate(`/cartoes/onboarding/empresa/${currentStep + 1}`);

    if (currentStep === Number(cachedCompanyOnboardingProgress?.currentStep)) {
      updateCache({
        key: 'currentStep',
        newData: currentStep + 1
      });
    }
  }

  return {
    control,
    errors,
    handleUpdateCompanyPoliciesAndTerms
  };
}
