import {
  type IPersistenceTeamMembersResume,
  type ITeamMembersResume
} from 'data/modules/teamMembers';

class GetTeamMembersResumeMapper {
  toDomain(persistence: IPersistenceTeamMembersResume): ITeamMembersResume {
    return {
      totalAdminUsers: persistence.total_admin_users,
      totalActiveUsers: persistence.total_active_users,
      totalRegisteredUsers: persistence.total_registered_users
    };
  }
}

export default new GetTeamMembersResumeMapper();
