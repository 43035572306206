export enum RoutesPrefix {
  EXPENSES = '/despesas',
  CARDS = '/cartoes',
  ANALYSIS = '/analises',
  STORE = '/store',
  SUPPORT = '/suporte',
  REPORTS = '/relatorios',
  SETTINGS = '/settings'
}

export enum ExpensesRoutes {
  EXPENSES = RoutesPrefix.EXPENSES,
  EXPENSES_ID = `${RoutesPrefix.EXPENSES}/:id`
}

export enum CardsRoutes {
  CARDS_WEBVIEW = '/webview/cartoes/*',
  CARDS_USER_ONBOARDING = `${RoutesPrefix.CARDS}/onboarding/usuario`,
  COMPANY_ONBOARDING = `${RoutesPrefix.CARDS}/onboarding/empresa`,
  COMPANY_ONBOARDING_BEGIN = `${RoutesPrefix.CARDS}/onboarding/empresa/inicio`,
  COMPANY_ONBOARDING_1 = `${RoutesPrefix.CARDS}/onboarding/empresa/1`,
  COMPANY_ONBOARDING_2 = `${RoutesPrefix.CARDS}/onboarding/empresa/2`,
  COMPANY_ONBOARDING_3 = `${RoutesPrefix.CARDS}/onboarding/empresa/3`,
  COMPANY_ONBOARDING_3_AUTHORIZATION = `${RoutesPrefix.CARDS}/onboarding/empresa/3/autorizacao`,
  COMPANY_ONBOARDING_3_REPRESENTATIVES = `${RoutesPrefix.CARDS}/onboarding/empresa/3/representantes`,
  COMPANY_ONBOARDING_3_APPROVAL_REPRESENTATIVES = `${RoutesPrefix.CARDS}/onboarding/empresa/3/aprovacao-representantes`,
  COMPANY_ONBOARDING_4 = `${RoutesPrefix.CARDS}/onboarding/empresa/4`,
  COMPANY_ONBOARDING_5 = `${RoutesPrefix.CARDS}/onboarding/empresa/5`,
  COMPANY_ONBOARDING_6 = `${RoutesPrefix.CARDS}/onboarding/empresa/6`,
  COMPANY_ONBOARDING_7 = `${RoutesPrefix.CARDS}/onboarding/empresa/7`,
  COMPANY_ONBOARDING_7_REPRESENTATIVE_TYPE = `${RoutesPrefix.CARDS}/onboarding/empresa/7/tipo-representante`,
  COMPANY_ONBOARDING_7_FP_CLASSIFICATION = `${RoutesPrefix.CARDS}/onboarding/empresa/7/classificacao-pessoa-fisica`,
  COMPANY_ONBOARDING_7_FP_DATA = `${RoutesPrefix.CARDS}/onboarding/empresa/7/dados-pessoa-fisica`,
  COMPANY_ONBOARDING_7_JP_DATA = `${RoutesPrefix.CARDS}/onboarding/empresa/7/dados-pessoa-juridica`,
  COMPANY_ONBOARDING_8 = `${RoutesPrefix.CARDS}/onboarding/empresa/8`,
  COMPANY_ONBOARDING_9 = `${RoutesPrefix.CARDS}/onboarding/empresa/9`,
  COMPANY_ONBOARDING_10 = `${RoutesPrefix.CARDS}/onboarding/empresa/10`,
  COMPANY_ONBOARDING_11 = `${RoutesPrefix.CARDS}/onboarding/empresa/11`,
  COMPANY_ONBOARDING_12 = `${RoutesPrefix.CARDS}/onboarding/empresa/12`,
  COMPANY_ONBOARDING_13 = `${RoutesPrefix.CARDS}/onboarding/empresa/13`,
  COMPANY_ONBOARDING_FINAL = `${RoutesPrefix.CARDS}/onboarding/empresa/final`,
  COMPANY_ONBOARDING_IN_PROGRESS = `${RoutesPrefix.CARDS}/onboarding/empresa/em-progresso`,
  COMPANY_ONBOARDING_KYC = `${RoutesPrefix.CARDS}/onboarding/empresa/kyc`,
  COMPANY_ONBOARDING_APPROVE = `${RoutesPrefix.CARDS}/onboarding/empresa/approve`,
  ONBOARDING_APPROVE = `${RoutesPrefix.CARDS}/onboarding/approve`,
  ONBOARDING_REDIRECT = `${RoutesPrefix.CARDS}/redirect/onboarding`,
  USER_ONBOARDING = `${RoutesPrefix.CARDS}/onboarding/usuario`,
  USER_ONBOARDING_BEGIN = `${RoutesPrefix.CARDS}/onboarding/usuario/inicio`,
  USER_ONBOARDING_1 = `${RoutesPrefix.CARDS}/onboarding/usuario/1`,
  USER_ONBOARDING_2 = `${RoutesPrefix.CARDS}/onboarding/usuario/2`,
  USER_ONBOARDING_3 = `${RoutesPrefix.CARDS}/onboarding/usuario/3`,
  USER_ONBOARDING_FINAL = `${RoutesPrefix.CARDS}/onboarding/usuario/final`,
  USER_ONBOARDING_KYC = `${RoutesPrefix.CARDS}/onboarding/usuario/kyc`,
  CARDS_MANAGEMENT = `${RoutesPrefix.CARDS}/gestao`,
  CARDS_CARD_GROUPS = `${RoutesPrefix.CARDS}/grupos-de-cartoes`,
  CARDS_CARD_GROUPS_GROUP = `${RoutesPrefix.CARDS}/gestao-de-grupos/:groupId`,
  CARDS_CARD_GROUPS_VALUE_ALLOCATION = `${RoutesPrefix.CARDS}/gestao-de-grupos/:groupId/alocacao-de-valores`,
  CARDS_CARD_GROUPS_VALUE_SOLICITATION = `${RoutesPrefix.CARDS}/gestao-de-grupos/:groupId/solicitacoes-de-valores`,
  CARDS_CARD_GROUPS_MOVEMENTS = `${RoutesPrefix.CARDS}/gestao-de-grupos/:groupId/movimentacoes`,
  CARDS_CARD_GROUPS_STATEMENT = `${RoutesPrefix.CARDS}/gestao-de-grupos/:groupId/movimentacoes/:userId`,
  CARDS_VALUE_SOLICITATION = `${RoutesPrefix.CARDS}/solicitacoes-de-valores`,
  CARDS_BALANCE_SOLICITATION = `${RoutesPrefix.CARDS}/solicitacoes-de-saldo`,
  CARDS_VALUE_ALLOCATION = `${RoutesPrefix.CARDS}/alocacao-de-valores`,
  CARDS_BALANCE_ALLOCATION = `${RoutesPrefix.CARDS}/alocacao-de-saldo`,
  CARDS_MOVEMENTS = `${RoutesPrefix.CARDS}/movimentacoes`,
  CARDS_STATEMENT = `${RoutesPrefix.CARDS}/extrato`,
  CARDS_STATEMENT_USER_ID = `${RoutesPrefix.CARDS}/extrato/:userId`,
  CARDS_MY_CARD = `${RoutesPrefix.CARDS}/meu-cartao`,
  CARDS_MY_CARD_MY_MOVEMENTS = `${RoutesPrefix.CARDS}/meu-cartao/minhas-movimentacoes/:userId`,
  CARDS_MY_CARD_MY_SOLICITATIONS = `${RoutesPrefix.CARDS}/meu-cartao/minhas-solicitacoes`,
  CARDS_BEGIN = `${RoutesPrefix.CARDS}/inicio`,
  CARDS_REDIRECT = `${RoutesPrefix.CARDS}`
}

export enum AnalysisRoutes {
  ANALYSIS = `${RoutesPrefix.ANALYSIS}`,
  ANALYSIS_RESUME = `resumo`,
  ANALYSIS_SUGGESTION = `${RoutesPrefix.ANALYSIS}/sugestao`,
  ANALYSIS_EXPENSES_PER_USER = `${RoutesPrefix.ANALYSIS}/despesas-por-usuario`,
  ANALYSIS_EXPENSES_PER_PROJECT = `${RoutesPrefix.ANALYSIS}/despesas-por-projeto`
}

export enum StoreRoutes {
  STORE = `${RoutesPrefix.STORE}`,
  STORE_CARDS = `cards`,
  STORE_CARDS_PJ = `cards-pj`,
  STORE_HORUS = `horus`,
  STORE_CONCILIATION = `conciliation`,
  STORE_OUTSOURCING = `outsourcing`,
  STORE_ACTIVE_DIRECTORY = `active-directory`
}

export enum SupportRoutes {
  SUPPORT = `${RoutesPrefix.SUPPORT}`
}

export enum ReportsRoutes {
  REPORTS = `${RoutesPrefix.REPORTS}`,
  REPORTS_REPORT = `${RoutesPrefix.REPORTS}/:id`
}

export enum SettingRoutes {
  SETTINGS_EMAIL_PREFERENCES = `${RoutesPrefix.SETTINGS}/preferencias-de-email`,
  SETTINGS_TEAM_MEMBERS = `${RoutesPrefix.SETTINGS}/membros-de-equipe`,
  SETTINGS_CARDS_PJ = `${RoutesPrefix.SETTINGS}/cartoes/cartoes-empresariais`,
  SETTINGS_CARDS_PJ_ACCOUNT_ID = `${RoutesPrefix.SETTINGS}/cartoes/cartoes-empresariais/:accountId`,
  SETTINGS_CARDS_SHAREHOLDERS = `${RoutesPrefix.SETTINGS}/cartoes/portadores-pj`,
  SETTINGS_CARDS_TARIFFS = `${RoutesPrefix.SETTINGS}/cartoes/tarifas`,
  SETTINGS_CARDS_TERMS_OF_USE = `${RoutesPrefix.SETTINGS}/cartoes/termos-de-uso`,
  SETTINGS_FUEL_VEHICLES = `${RoutesPrefix.SETTINGS}/combustivel/veiculos`,
  SETTINGS_FUEL_VEHICLES_ID = `${RoutesPrefix.SETTINGS}/combustivel/veiculos/:id`,
  SETTINGS_FUEL_POLICIES = `${RoutesPrefix.SETTINGS}/combustivel/politicas`
}
