import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.tokens.neutralColorHighPure};
  padding: 1.6rem 2rem;
  border-radius: 16px;
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 4%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;

  @media screen and (min-width: 1570px) {
    padding: 1.6rem 2rem;
    flex-direction: row;
    align-items: center;
    column-gap: 3.3rem;
  }
`;

export const ControllersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  width: 100%;

  @media screen and (min-width: 935px) {
    flex-direction: row;
    gap: 2rem;
    align-items: center;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: row;
  }
`;

export const InputContainer = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 100%;
  max-width: 45rem;

  div {
    min-width: 100%;
  }

  input {
    min-width: 14.7rem;
  }

  @media screen and (min-width: 600px) {
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    div {
      min-width: unset;
    }
  }

  @media screen and (min-width: 935px) {
    flex-basis: 300px;
    flex-grow: 1;
    justify-content: space-between;
    max-width: unset;
  }
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.tokens.fontFamilyPoppins};
    font-weight: ${theme.tokens.fontWeightSemibold};
    font-size: ${theme.tokens.fontSizeXxs};
  `}

  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  text-align: left;
  white-space: nowrap;
  width: fit-content;
`;
