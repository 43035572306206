import {
  Checkbox,
  DefaultButton,
  MultiSelect,
  SecondaryButton,
  Select,
  tokens,
  Tooltip
} from 'ds';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { FastDates } from 'presentation/pages/analytics/Analysis/components/FastDates';

import { Icons, routesPathPrefix } from 'shared/constants/global';

import { useSideAnalysisFilterModal } from './useSideAnalysisFilterModal';

import { type ISideAnalysisFilterModalProps } from './SideAnalysisFilterModal.types';

import {
  CheckboxWrapper,
  Container,
  Content,
  FooterContent,
  FormContent,
  SelectPaymentFormsWrapper,
  Subtitle
} from './SideAnalysisFilterModal.styles';

export function SideAnalysisFilterModal({
  isOpen
}: ISideAnalysisFilterModalProps): JSX.Element {
  const {
    methods,
    currentEndDate,
    analysisFilters,
    handleApplyFilters,
    currentInitialDate,
    handleResetFilters,
    handleDeselectOption,
    handleSelectInputChange,
    handleCustomFilterChange,
    analysisUserOptionsToRender
  } = useSideAnalysisFilterModal();

  const maxSearchLength = 60;

  const { t } = useTranslation(['analytics', 'global']);

  return (
    <>
      {isOpen && (
        <Container
          isOpen={isOpen}
          title={t('analytics:filters.filters')}
        >
          <Content>
            <Subtitle>
              {t('analytics:filters.needHelpWithFilters')}{' '}
              <Link to={routesPathPrefix.support}>
                {t('analytics:filters.clickHere')}
              </Link>
            </Subtitle>
            <FormContent
              id='filters-form'
              methods={methods}
              handleSubmit={handleApplyFilters}
            >
              <Select.Controlled
                small
                name='dateToConsider'
                hasArrowDownIndicator
                options={analysisFilters.dateToConsider}
                noOptionsMessage={t('global:noMoreOptions')}
                label={t('analytics:filters.dateToConsider')}
                onInputChange={handleSelectInputChange(maxSearchLength)}
                placeholder={`${t('analytics:filters.dateToConsider')}`}
                information={t('analytics:filters.information.filterConflict', {
                  filter: t('analytics:filters.expensesStatus')
                })}
              />

              <FastDates
                endDate={currentEndDate}
                initialDate={currentInitialDate}
                onChangeFastDate={methods.setValue}
              />

              <MultiSelect.Controlled
                small
                name='users'
                hasHighlight
                clearInputOnBlur
                hasArrowDownIndicator
                selectionMode='checkbox'
                options={analysisFilters.users}
                label={t('analytics:filters.users')}
                onDeselectOption={handleDeselectOption}
                placeholder={t('analytics:filters.users')}
                noOptionsMessage={t('global:noMoreOptions')}
                onCustomChange={handleCustomFilterChange('users')}
                optionsToRender={analysisUserOptionsToRender.users}
                onInputChange={handleSelectInputChange(maxSearchLength)}
                information={t('analytics:filters.information.searchOption')}
              />

              <Select.Controlled
                small
                name='orderBy'
                hasArrowDownIndicator
                options={analysisFilters.orderBy}
                label={t('analytics:filters.sortUsers')}
                noOptionsMessage={t('global:noMoreOptions')}
                placeholder={t('analytics:filters.sortUsers')}
                onInputChange={handleSelectInputChange(maxSearchLength)}
              />

              <MultiSelect.Controlled
                small
                hasHighlight
                clearInputOnBlur
                name='expensesStatus'
                hasArrowDownIndicator
                selectionMode='checkbox'
                onDeselectOption={handleDeselectOption}
                options={analysisFilters.expensesStatus}
                noOptionsMessage={t('global:noMoreOptions')}
                label={t('analytics:filters.expensesStatus')}
                placeholder={t('analytics:filters.expensesStatus')}
                onInputChange={handleSelectInputChange(maxSearchLength)}
                onCustomChange={handleCustomFilterChange('expensesStatus')}
                optionsToRender={analysisUserOptionsToRender.expensesStatus}
                information={t('analytics:filters.information.filterConflict', {
                  filter: t('analytics:filters.dateToConsider')
                })}
              />

              <MultiSelect.Controlled
                small
                hasHighlight
                clearInputOnBlur
                name='expensesTypes'
                hasArrowDownIndicator
                selectionMode='checkbox'
                onDeselectOption={handleDeselectOption}
                options={analysisFilters.expensesTypes}
                label={t('analytics:filters.expensesType')}
                noOptionsMessage={t('global:noMoreOptions')}
                placeholder={t('analytics:filters.expensesType')}
                onInputChange={handleSelectInputChange(maxSearchLength)}
                onCustomChange={handleCustomFilterChange('expensesTypes')}
                optionsToRender={analysisUserOptionsToRender.expensesTypes}
                information={t('analytics:filters.information.searchOption')}
              />

              <Select.Controlled
                small
                hasArrowDownIndicator
                name='reimbursableNonReimbursable'
                noOptionsMessage={t('global:noMoreOptions')}
                options={analysisFilters.reimbursableNonReimbursable}
                onInputChange={handleSelectInputChange(maxSearchLength)}
                label={t('analytics:filters.reimbursableNonReimbursable')}
                placeholder={t('analytics:filters.reimbursableNonReimbursable')}
                information={t('analytics:filters.information.filterConflict', {
                  filter: t('analytics:filters.paymentMethods')
                })}
              />

              <SelectPaymentFormsWrapper>
                <MultiSelect.Controlled
                  small
                  hasHighlight
                  clearInputOnBlur
                  name='paymentForms'
                  hasArrowDownIndicator
                  selectionMode='checkbox'
                  options={analysisFilters.paymentForms}
                  onDeselectOption={handleDeselectOption}
                  noOptionsMessage={t('global:noMoreOptions')}
                  label={t('analytics:filters.paymentMethods')}
                  placeholder={t('analytics:filters.paymentMethods')}
                  onInputChange={handleSelectInputChange(maxSearchLength)}
                  onCustomChange={handleCustomFilterChange('paymentForms')}
                  optionsToRender={analysisUserOptionsToRender.paymentForms}
                  information={`${t('analytics:filters.information.searchOption')} ${t(
                    'analytics:filters.information.filterConflict',
                    {
                      filter: t('analytics:filters.reimbursableNonReimbursable')
                    }
                  )}`}
                />
              </SelectPaymentFormsWrapper>

              <MultiSelect.Controlled
                small
                hasHighlight
                name='projects'
                clearInputOnBlur
                hasArrowDownIndicator
                selectionMode='checkbox'
                options={analysisFilters.projects}
                onDeselectOption={handleDeselectOption}
                label={t('analytics:filters.projects')}
                noOptionsMessage={t('global:noMoreOptions')}
                placeholder={t('analytics:filters.projects')}
                onCustomChange={handleCustomFilterChange('projects')}
                optionsToRender={analysisUserOptionsToRender.projects}
                onInputChange={handleSelectInputChange(maxSearchLength)}
                information={t('analytics:filters.information.searchOption')}
              />

              <MultiSelect.Controlled
                small
                hasHighlight
                clearInputOnBlur
                name='costCenters'
                hasArrowDownIndicator
                selectionMode='checkbox'
                options={analysisFilters.costCenters}
                onDeselectOption={handleDeselectOption}
                label={t('analytics:filters.costCenters')}
                noOptionsMessage={t('global:noMoreOptions')}
                placeholder={t('analytics:filters.costCenters')}
                onInputChange={handleSelectInputChange(maxSearchLength)}
                onCustomChange={handleCustomFilterChange('costCenters')}
                optionsToRender={analysisUserOptionsToRender.costCenters}
                information={t('analytics:filters.information.searchOption')}
              />

              <MultiSelect.Controlled
                small
                hasHighlight
                clearInputOnBlur
                name='reportCodes'
                hasArrowDownIndicator
                selectionMode='checkbox'
                options={analysisFilters.reportCodes}
                onDeselectOption={handleDeselectOption}
                label={t('analytics:filters.reportCode')}
                noOptionsMessage={t('global:noMoreOptions')}
                placeholder={t('analytics:filters.reportCode')}
                onInputChange={handleSelectInputChange(maxSearchLength)}
                onCustomChange={handleCustomFilterChange('reportCodes')}
                information={t('analytics:filters.information.searchOption')}
              />

              <CheckboxWrapper>
                <Checkbox.Controlled
                  small
                  name='saveFilterPreferences'
                  label={t('analytics:filters.saveFilterPreferences')}
                />
                <Tooltip
                  message={t('analytics:filters.useSavedFiltersExplanation')}
                  placement='top'
                  zIndex={2}
                >
                  <div>
                    <Icons.InfoIcon color={tokens.neutralColorLowLight} />
                  </div>
                </Tooltip>
              </CheckboxWrapper>
            </FormContent>

            <FooterContent>
              <SecondaryButton
                size='small'
                onClick={handleResetFilters}
              >
                {t('analytics:filters.resetFilters')}
              </SecondaryButton>

              <Tooltip
                message={t('analytics:filters.noSavedFilters')}
                placement='top'
                zIndex={2}
              >
                <div>
                  <SecondaryButton
                    disabled
                    size='small'
                  >
                    {t('analytics:filters.useMySavedFilter')}
                  </SecondaryButton>
                </div>
              </Tooltip>
              <DefaultButton
                size='small'
                type='submit'
                form='filters-form'
              >
                {t('analytics:filters.applyFilters')}
              </DefaultButton>
            </FooterContent>
          </Content>
        </Container>
      )}
    </>
  );
}
