import { BasicBarChart } from 'ds';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Loader } from 'presentation/components/global/Loader';
import { AnalysisChartCard } from 'presentation/pages/analytics/Analytics/components/AnalysisChartCard';
import { DefaultLayout } from 'presentation/templates';

import { analysisPagesPaths } from 'shared/constants/analytics';

import { AnalysisHeader } from './components/AnalysisHeader';
import { SuggestionBanner } from './components/SuggestionBanner';
import { useAnalytics } from './useAnalytics';

import { Container, Content } from './Analytics.styles';

export function Analytics(): JSX.Element {
  const { t } = useTranslation('analytics');

  const { isLoadingChartsData, charts } = useAnalytics();

  const navigate = useNavigate();

  return (
    <DefaultLayout
      pageNameAndBreadcrumbs={{
        pageName: t('pageName.analytics'),
        isBreadcrumbsVisible: false,
        isBackButtonVisible: false,
        isClassicMode: false
      }}
    >
      <Loader isLoading={isLoadingChartsData} />
      <Container>
        <Content>
          <AnalysisHeader
            title={t('headers.first.title')}
            description={t('headers.first.description')}
          />

          <AnalysisChartCard
            onActionButtonClick={() => {
              navigate(analysisPagesPaths.userExpenses);
            }}
            title={t('chartCards.userExpenses.title')}
            withNoData={charts.userAnalysis.withNoData}
            subtitle={t('chartCards.userExpenses.subtitle')}
          >
            <BasicBarChart {...charts.userAnalysis.data} />
          </AnalysisChartCard>
        </Content>

        <SuggestionBanner
          onClick={() => {
            navigate(analysisPagesPaths.suggestion);
          }}
        />
      </Container>
    </DefaultLayout>
  );
}
