import {
  type IGetFilteredList,
  type IGetPaginatedListParams,
  type IGetSortedListParams,
  type IValueAllocationCardItem
} from 'presentation/pages/cards/Management/ChargeCards/ChargeCards.types';

export class ChargeCards {
  getFilteredList({
    list,
    searchTerm,
    cardType
  }: IGetFilteredList): IValueAllocationCardItem[] {
    if (!list) return [];

    const filteredList = list.filter(item => {
      if (searchTerm.length === 0) {
        if (cardType === 'ALL') return item;

        return item.type === cardType;
      }

      if (cardType === 'ALL') {
        return item.name
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase());
      }

      return (
        item.type === cardType &&
        item.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
      );
    });

    return filteredList;
  }

  getPaginatedList({
    list,
    pageSize
  }: IGetPaginatedListParams): IValueAllocationCardItem[][] {
    if (!list) return [];

    const paginatedList = [];

    for (let i = 0; i < list.length; i += pageSize) {
      const chunk = list.slice(i, i + pageSize);

      paginatedList.push(chunk);
    }

    return paginatedList;
  }

  getSortedList({
    list,
    sortTable
  }: IGetSortedListParams): IValueAllocationCardItem[] {
    if (!list || list.length === 0) return [];

    const sortBy = sortTable.cardName ? 'cardName' : 'currentValue';

    return list.sort((a, b) => {
      if (sortBy === 'cardName') {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return sortTable[sortBy] === 'ASC' ? -1 : 1;
        }

        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return sortTable[sortBy] === 'ASC' ? 1 : -1;
        }

        return 0;
      }

      if (sortBy === 'currentValue') {
        return sortTable[sortBy] === 'ASC'
          ? a.currentValue! - b.currentValue!
          : b.currentValue! - a.currentValue!;
      }

      return 0;
    });
  }
}

export default new ChargeCards();
