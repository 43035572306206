import { Outlet } from 'react-router-dom';

import { vexDomain } from 'data/config';
import { useLoadCollaboratorInitialData } from 'data/global/features/load-collaborator-initial-data/useLoadCollaboratorInitialData';

import { SplashScreen } from 'presentation/components/global/SplashScreen';
import { FallbackPage } from 'presentation/pages/global';

import { cookiesKeys } from 'shared/constants/global';
import { CustomCookies } from 'shared/utils/global';

export function ExpensesAuthMiddleware(): JSX.Element {
  const { isLoading, company, user } = useLoadCollaboratorInitialData();

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!isLoading && !user) {
    return <FallbackPage />;
  }

  // se não estiver em localhost, faz as devidas verificações para redirecionar para a tela antiga
  if (!window.location.host.includes('localhost')) {
    const usesRedesignExpenses = company?.parameters.usesRedesignExpenses;

    // se não tiver o parâmetro de redesign, redireciona para a tela antiga
    if (usesRedesignExpenses === null) {
      window.location.href = `${vexDomain}/despesas`;
      return <SplashScreen />;
    }

    const isExpensesOldVersionAllowed =
      company?.parameters.isExpensesOldVersionAllowed;

    const showOldExpensesPage = CustomCookies.get(
      cookiesKeys.redesignExpensesOldVersion
    );

    // se for permitido voltar para tela antiga e o valor do cookie for true, redireciona para a tela antiga
    if (isExpensesOldVersionAllowed && showOldExpensesPage === 'true') {
      window.location.href = `${vexDomain}/despesas`;
      return <SplashScreen />;
    }
  }

  return <Outlet />;
}
