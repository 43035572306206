import {
  type ICompanyOnboardingProgress,
  type IPersistenceCompanyOnboardingProgress
} from 'data/modules/cards/companyOnboarding';

class GetCompanyOnboardingProgressMapper {
  toDomain(
    persistenceProgress: IPersistenceCompanyOnboardingProgress
  ): ICompanyOnboardingProgress {
    return {
      answerableUserId: persistenceProgress.answerable_user_id,
      companyId: persistenceProgress.company_id,
      createdAt: persistenceProgress.created_at,
      currentStep: persistenceProgress.current_step,
      onboardingCompanyId: persistenceProgress.onboarding_company_id,
      updatedAt: persistenceProgress.updated_at
    };
  }
}

export default new GetCompanyOnboardingProgressMapper();
