import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${tokens.spacingLg};
  padding-top: ${tokens.spacingXs};
  padding-bottom: ${tokens.spacingHuge};
  gap: ${tokens.spacingXs};

  .tooltip-delete {
    z-index: 5;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    .pagination-expenses-container {
      display: flex;
      flex-direction: column;
      gap: ${tokens.spacingMd};
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: ${tokens.spacingMd};
  align-items: center;

  #add-to-expense-button {
    height: 5.6rem;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: ${tokens.spacingNano};

  .delete-expenses-button:disabled {
    background-color: ${tokens.feedbackColorErrorLightest};

    svg {
      opacity: 0.3;
    }
  }

  .add-expenses-button:disabled {
    background-color: ${tokens.brandColorPrimaryLightest};

    svg {
      opacity: 0.3;
    }
  }
`;

export const TableFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${tokens.spacingXs};
  gap: ${tokens.spacingXs};
  border-radius: ${tokens.borderRadiusSm};
  background-color: ${tokens.neutralColorHighLight};
`;

export const TablePaginationContainer = styled.div`
  display: flex;
  flex-direction: column;

  tr {
    cursor: pointer;
  }

  tr > th > div > svg {
    flex-shrink: 0;
  }
`;

export const TableOverflowContainer = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
    overflow-x: auto;

    > table {
      table-layout: auto;
    }
  }

  .attachment-tooltip {
    z-index: 1;
  }
`;

export const TableNoCententContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${tokens.spacingXs};
  height: 50rem;
  margin: 0 auto;
`;

export const TableNoCententTitle = styled.h3`
  color: ${tokens.neutralColorLowMedium};
  text-align: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXs};
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
`;

export const TableNoCententImage = styled.img`
  width: 8.5rem;
  height: 7.6rem;
`;

export const TableNoCententDescription = styled.span`
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  text-align: center;
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
`;

export const CustomTdContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${tokens.spacingInsetNano};

  .remove-background {
    background-color: transparent !important;

    > svg {
      width: 2.2rem;
      height: 2.2rem;
    }
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img,
  svg,
  div {
    flex-shrink: 0;
  }
`;
