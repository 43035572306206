import { styled } from 'styled-components';

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3rem 3rem;
  gap: 2.5rem;
  max-width: 70rem;
  text-align: center;
`;

export const IconContainer = styled.div`
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.tokens.feedbackColorWarningMedium};

  > svg {
    color: ${({ theme }) => theme.tokens.highlightColorPure};
  }
`;

export const Divider = styled.div`
  border-bottom: ${({ theme }) => theme.tokens.neutralColorHighPurest} solid 1px;
  width: 100%;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
