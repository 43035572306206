import { Pagination } from 'ds';

import { type PaginationType } from 'data/modules/global';

import { type ISolicitationActionsPagination } from './SolicitationActionsPagination.types';

import {
  Container,
  StyledControl,
  StyledPagination
} from './SolicitationActionsPagination.styles';

export function SolicitationActionsPagination({
  balanceSolicitationConfirmParams,
  handleChangeSearchParam,
  totalOfSolicitationsStaged,
  ...rest
}: ISolicitationActionsPagination): JSX.Element {
  return (
    <Container {...rest}>
      <StyledPagination
        currentPage={balanceSolicitationConfirmParams.currentPage}
        perPage={balanceSolicitationConfirmParams.pageSize}
        totalPage={Math.ceil(
          totalOfSolicitationsStaged / balanceSolicitationConfirmParams.pageSize
        )}
      >
        <Pagination.Label langKey='pt' />

        <StyledControl
          onChangePage={page => {
            handleChangeSearchParam('currentPage', page);
          }}
        />

        <Pagination.Pages
          langKey='pt'
          onChangePerPage={pageSize => {
            handleChangeSearchParam('pageSize', pageSize as PaginationType);
          }}
          options={[10, 20, 50]}
        />
      </StyledPagination>
    </Container>
  );
}
