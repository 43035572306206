import { type ChangeEvent } from 'react';

import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue
} from 'react-hook-form';

import { type ICheckboxProps } from './Checkbox.types';

import { Container } from './Checkbox.styles';

export function Checkbox<T extends FieldValues>({
  checked,
  onToggle,
  label,
  id,
  control,
  ...rest
}: ICheckboxProps<T>): JSX.Element {
  const checkboxToRender = (
    checked: boolean,
    onToggle: (e: ChangeEvent<Element>) => void
  ): JSX.Element => (
    <>
      <input
        id={id}
        type='checkbox'
        checked={checked}
        onChange={onToggle}
        {...rest}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </>
  );

  return (
    <Container>
      {control ? (
        <Controller
          name={id as Path<T>}
          control={control}
          defaultValue={false as PathValue<T, Path<T>>}
          render={({ field: { onChange, value } }) =>
            checkboxToRender(value, onChange)
          }
        />
      ) : (
        checkboxToRender(checked as boolean, onToggle as () => void)
      )}
    </Container>
  );
}
