import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  TeamMemberLinkDefaultOptions,
  type TeamMemberType,
  useGetTeamMembersApprovalFlowOptions,
  useGetTeamMembersCostCenterOptions,
  useGetTeamMembersLimitPolicyOptions,
  useGetTeamMembersPositionOptions,
  useGetTeamMembersProjectOptions
} from 'data/modules/teamMembers';

import { Mask } from 'shared/utils/format';
import { TransformArray } from 'shared/utils/global';

import { type IUseTeamMemberForm } from './TeamMemberForm.types';
import {
  type ISelectControlledGroupedOption,
  type ISelectControlledOption
} from 'ds/components/Select/Controlled/SelectControlled.types';

export function useTeamMemberForm(): IUseTeamMemberForm {
  const { t } = useTranslation('settings');

  const [optionsToRender, setOptionsToRender] =
    useState<IUseTeamMemberForm['optionsToRender']>();

  const { watch, setValue, getValues } = useFormContext();

  const currentType: TeamMemberType | undefined = watch('type');
  const currentCostCenter: string[] = watch('costCenter');
  const currentProjects: string[] = watch('projects');

  const { positionOptions, isLoadingPositionOptions } =
    useGetTeamMembersPositionOptions();

  const { costCenterOptions, isLoadingCostCenterOptions } =
    useGetTeamMembersCostCenterOptions();

  const { projectOptions, isLoadingProjectOptions } =
    useGetTeamMembersProjectOptions();

  const { limitPolicyOptions, isLoadingLimitPolicyOptions } =
    useGetTeamMembersLimitPolicyOptions();

  const { approvalFlowOptions, isLoadingApprovalFlowOptions } =
    useGetTeamMembersApprovalFlowOptions();

  const positionSelectOptions = useMemo(() => {
    const selectOptions: ISelectControlledGroupedOption[] = [];

    positionOptions.forEach(position => {
      const positionOption = {
        label: position.positionName,
        value: String(position.positionId)
      };

      const prevIndex = selectOptions.findIndex(
        option => option.label === position.departmentName
      );

      if (prevIndex >= 0) {
        selectOptions[prevIndex].options = [
          ...selectOptions[prevIndex].options,
          positionOption
        ];

        return;
      }

      selectOptions.push({
        label: position.departmentName,
        options: [positionOption]
      });
    });

    return selectOptions;
  }, [positionOptions]);

  const linkToAllOption = useMemo(
    () => ({
      label: t('teamMemberForm.linkToAll'),
      value: TeamMemberLinkDefaultOptions.ALL
    }),
    [t]
  );

  const costCenterSelectOptions = useMemo(
    () =>
      !costCenterOptions.length
        ? []
        : [
            linkToAllOption,
            ...TransformArray.objectsToSelectOptions(
              costCenterOptions,
              'id',
              'name'
            )
          ],
    [costCenterOptions, linkToAllOption]
  );

  const projectSelectOptions = !projectOptions.length
    ? []
    : [
        linkToAllOption,
        ...TransformArray.objectsToSelectOptions(projectOptions, 'id', 'name')
      ];

  const limitPolicySelectOptions = TransformArray.objectsToSelectOptions(
    limitPolicyOptions,
    'id',
    'name'
  );

  const approvalFlowSelectOptions = TransformArray.objectsToSelectOptions(
    approvalFlowOptions,
    'id',
    'name'
  );

  const handleDefaultOptions = useCallback(
    (
      key: string,
      options: ISelectControlledOption[],
      currentOptions: string[]
    ) => {
      const lastSelectedOption = [...(currentOptions ?? [])].pop() ?? '';

      if (lastSelectedOption === TeamMemberLinkDefaultOptions.ALL) {
        const newOptions = [
          ...options
            .filter(option => option.value !== TeamMemberLinkDefaultOptions.ALL)
            .map(option => option.value),
          lastSelectedOption
        ];

        setOptionsToRender(prev => ({ ...prev, [key]: [lastSelectedOption] }));

        setValue(key, newOptions);

        return;
      }

      setOptionsToRender(prev => ({ ...prev, [key]: undefined }));

      setValue(
        key,
        currentOptions?.filter(
          option => option !== (TeamMemberLinkDefaultOptions.ALL as string)
        )
      );
    },
    [setValue]
  );

  /** Lógica multiselect de centro de custo */
  useEffect(() => {
    handleDefaultOptions(
      'costCenter',
      costCenterSelectOptions,
      currentCostCenter
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCostCenter?.length]);

  /** Lógica multiselect de projetos */
  useEffect(() => {
    handleDefaultOptions('projects', projectSelectOptions, currentProjects);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjects?.length]);

  function handleSelectInputChange(length: number): (value: string) => string {
    return (value: string) => {
      return Mask.crop(value, length);
    };
  }

  function handleDeselectOption(key: string | undefined): void {
    if (!key) return;

    try {
      const currentValue = getValues(key) as string[];

      if (
        currentValue.length === 1 &&
        currentValue.includes(TeamMemberLinkDefaultOptions.ALL)
      ) {
        setValue(key, []);
      }
    } catch {}
  }

  return {
    optionsToRender,
    type: currentType,
    handleDeselectOption,
    handleSelectInputChange,
    isLoadingProjectOptions,
    isLoadingPositionOptions,
    isLoadingCostCenterOptions,
    isLoadingLimitPolicyOptions,
    isLoadingApprovalFlowOptions,
    projectOptions: projectSelectOptions,
    positionOptions: positionSelectOptions,
    costCenterOptions: costCenterSelectOptions,
    limitPolicyOptions: limitPolicySelectOptions,
    approvalFlowOptions: approvalFlowSelectOptions
  };
}
