import { useParams } from 'react-router-dom';

import { useAuthContext } from 'data/contexts';
import { useGetCardGroup } from 'data/modules/cards/account/useCases/get-card-group/useGetCardGroup';

import {
  AvailableValueCardInfo,
  ChargeCardsCardInfo,
  DistributedValueCardInfo
} from 'presentation/pages/cards/components';

import { animations } from 'shared/constants/animations';

import { CardsContent, Container } from './CardsSection.styles';

export function CardsSection(): JSX.Element {
  const user = useAuthContext(state => state.user);
  const { groupId } = useParams();

  const { cardGroupData } = useGetCardGroup({
    cardGroupId: groupId as string,
    enabled: !!groupId
  });

  return (
    <Container {...animations.popFadeInOut}>
      <CardsContent>
        <AvailableValueCardInfo notHasIcon />
      </CardsContent>

      <CardsContent>
        <DistributedValueCardInfo
          notHasIcon
          hasCardGroup
        />

        {user?.cards?.isCardGroupManager && cardGroupData?.isManager && (
          <ChargeCardsCardInfo isCardGroup />
        )}
      </CardsContent>
    </Container>
  );
}
