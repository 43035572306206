import { Navigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useAuthContext, useFlagsContext } from 'data/contexts';
import { useLoadInitialData } from 'data/modules/auth';

import { FallbackPage } from 'presentation/pages/global';

import { SplashScreen } from '../SplashScreen';

export function SetInitialRoute(): JSX.Element {
  const [company, user] = useAuthContext(
    useShallow(state => [state.company, state.user])
  );

  const { isLoading } = useLoadInitialData();
  const cardGroupsFlag = useFlagsContext(state => state.cardGroupsFlag);

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!isLoading && !company?.cards?.isOnboardingFinished) {
    return <Navigate to='/cartoes/onboarding/empresa' />;
  }

  if (
    !isLoading &&
    !user?.cards?.isOnboardingFinished &&
    user?.cards?.type !== 'ACCOUNT_MANAGER' &&
    !user?.cards?.isManagerWithoutAccount &&
    user?.cards?.type !== 'VIRTUAL' &&
    !user?.cards?.isCardGroupManagerWithoutAccount
  ) {
    return <Navigate to='/cartoes/onboarding/usuario' />;
  }

  if (
    !isLoading &&
    cardGroupsFlag &&
    (user?.cards?.isCardGroupManager ||
      user?.cards?.isCardGroupManagerWithoutAccount)
  ) {
    return <Navigate to='/cartoes/grupos-de-cartoes' />;
  }

  if (!isLoading && user?.cards?.manager) {
    return <Navigate to='/cartoes/gestao' />;
  }

  if (
    !isLoading &&
    (user?.cards?.type === 'USER' || user?.cards?.type === 'VIRTUAL')
  ) {
    return <Navigate to='/cartoes/meu-cartao' />;
  }

  return <FallbackPage />;
}
