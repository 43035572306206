import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${tokens.spacingInsetQuarck};
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  padding-bottom: ${tokens.spacingXs};

  svg {
    width: 2rem !important;
    height: 2rem !important;
  }

  span {
    font-size: ${tokens.fontSizeXxs};
    line-height: 2.4rem;
  }

  strong {
    font-size: ${tokens.fontSizeXs};
    font-weight: ${tokens.fontWeightSemibold};
    line-height: 3.2rem;
  }

  small {
    font-size: ${tokens.fontSizeXxss};
    line-height: 2.2rem;
  }
`;
