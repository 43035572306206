import {
  type ComponentProps,
  type FunctionComponent,
  type SVGProps
} from 'react';

import { DSIcons } from 'ds';

export interface ICardGroupsInfoModalProps extends ComponentProps<'div'> {}

type InformativeModalIconsType = Record<
  number,
  FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>
>;

export const informativeModalIcons: InformativeModalIconsType = {
  0: DSIcons.CreditCardsIcon,
  1: DSIcons.CreditCardClientIcon,
  2: DSIcons.CreditCardClientIcon,
  3: DSIcons.SendMoneyPersonIcon,
  4: DSIcons.WarningIcon
};

export interface IUseCardGroupInfoModal {
  content: {
    Icon: FunctionComponent<
      SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
    description: string;
  };
}
