import { useLangContext } from 'data/contexts';

import { ResponsiveCell } from 'presentation/components/global/Table/ResponsiveCell/ResponsiveCell.component';
import { TransactionValue } from 'presentation/pages/cards/Statement/components/Table';

import { CustomDate } from 'shared/utils/custom';

import { type IRowBalanceOfDayProps } from './RowBalanceOfDay.types';

import {
  BalanceOfDayContent,
  Container,
  DateContent
} from './RowBalanceOfDay.styles';

export function RowBalanceOfDay({
  date,
  value
}: IRowBalanceOfDayProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  return (
    <Container>
      <ResponsiveCell label={lang.global.date[currentLangKey]}>
        <DateContent>{CustomDate.formatFromISO(date)}</DateContent>
      </ResponsiveCell>

      <ResponsiveCell label={lang.global.card[currentLangKey]}>
        -
      </ResponsiveCell>

      <ResponsiveCell label={lang.global.user[currentLangKey]}>
        -
      </ResponsiveCell>

      <ResponsiveCell label={lang.global.description[currentLangKey]}>
        <BalanceOfDayContent>
          {lang.cards.statement.balance_of_the_day[currentLangKey]}
        </BalanceOfDayContent>
      </ResponsiveCell>

      <ResponsiveCell label={lang.global.value[currentLangKey]}>
        <TransactionValue
          type='brazilian'
          value={value}
        />
      </ResponsiveCell>

      <ResponsiveCell label={lang.global.status[currentLangKey]}>
        -
      </ResponsiveCell>

      <ResponsiveCell />
    </Container>
  );
}
