import { type ChangeEvent } from 'react';

import { useDsGa4 } from 'ds/hooks/globals';

import { type ITextAreaDefaultProps } from './TextAreaDefault.types';

import {
  Caption,
  Container,
  Fieldset,
  Label,
  Legend,
  TextAreaComponent,
  TextareaContainer,
  Wrapper
} from './TextAreaDefault.styles';

export function TextAreaDefault({
  errorMessage,
  defaultValue,
  label,
  disabled,
  value,
  onChangeValue,
  textareaProps,
  large = false,
  small = false,
  ...rest
}: ITextAreaDefaultProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  return (
    <Wrapper>
      <Container>
        <TextareaContainer
          data-testid='container'
          $disabled={disabled}
          $hasError={!!errorMessage}
          $isTouched={!!value}
          $large={large}
          $small={small}
          {...rest}
        >
          <Label>{label}</Label>
          <TextAreaComponent
            {...textareaProps}
            defaultValue={defaultValue}
            disabled={disabled}
            value={value}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              if (onChangeValue) onChangeValue(event.target.value);

              sendDsGaEvent('components', 'textarea', {
                eventValue: event.target.value
              });
            }}
          />
        </TextareaContainer>

        <Fieldset>
          <Legend>
            <span>{label}</span>
          </Legend>
        </Fieldset>
      </Container>

      {!!errorMessage && <Caption $hasError>{errorMessage}</Caption>}
    </Wrapper>
  );
}
