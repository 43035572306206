import { DangerButton, DefaultButton, SecondaryButton, TextInput } from 'ds';

import { useLangContext, useValueSolicitationContext } from 'data/contexts';

import { useSolicitationActionsFilter } from './useSolicitationActionsFilter';

import { type ISolicitationActionsFilterProps } from './SolicitationActionsFilter.types';

import {
  ButtonContainer,
  Container,
  SearchbarContainer
} from './SolicitationActionsFilter.styles';

export function SolicitationActionsFilter({
  handleSubmitSolicitations,
  balanceSolicitationConfirmParams,
  handleChangeSearchParam
}: ISolicitationActionsFilterProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  const { removeAllBalanceSolicitationApproved } =
    useSolicitationActionsFilter();

  const step = useValueSolicitationContext(state => state.step);

  return (
    <Container>
      <SearchbarContainer>
        <TextInput.Default
          label={
            lang.cards.valueSolicitation.filters.search_request[currentLangKey]
          }
          placeholder={
            lang.cards.valueSolicitation.filters.search_request[currentLangKey]
          }
          value={balanceSolicitationConfirmParams.searchInput}
          onChangeValue={value => {
            handleChangeSearchParam('searchInput', value);
          }}
          onClearValue={() => {
            handleChangeSearchParam('searchInput', '');
          }}
          type='text'
          iconLeft='SearchIcon'
          small
        />
      </SearchbarContainer>
      <ButtonContainer>
        <SecondaryButton
          onClick={removeAllBalanceSolicitationApproved}
          size='small'
        >
          {lang.global.clear[currentLangKey]}
        </SecondaryButton>

        {step === 'approve' && (
          <DefaultButton
            onClick={handleSubmitSolicitations}
            size='small'
          >
            {lang.cards.valueSolicitation.approve_requests[currentLangKey]}
          </DefaultButton>
        )}

        {step === 'disapprove' && (
          <DangerButton
            onClick={handleSubmitSolicitations}
            size='small'
          >
            {lang.cards.valueSolicitation.disapprove_requests[currentLangKey]}
          </DangerButton>
        )}

        {step === 'transfer' && (
          <DefaultButton
            onClick={handleSubmitSolicitations}
            size='small'
          >
            {lang.cards.valueSolicitation.transfer[currentLangKey]}
          </DefaultButton>
        )}
      </ButtonContainer>
    </Container>
  );
}
