import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import { Spinner } from 'presentation/components/base/Spinner';
import { DsSelect } from 'presentation/ds/DsSelect/DsSelect.component';
import { DsTextInput } from 'presentation/ds/DsTextInput';
import {
  OnboardingFooter,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { states } from 'shared/constants/global';

import { useUserAddress } from './useUserAddress';

import {
  Container,
  CustomLoadingOverlay,
  FormContainer,
  RowDisposedFields
} from './UserAddress.styles';

export function UserAddress(): JSX.Element {
  const navigate = useNavigate();

  const [userAddress, address, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.user.user_address,
    state.lang.forms.address,
    state.currentLangKey
  ]);

  const {
    control,
    errors,
    setValue,
    handleUpdateUserOnboardingAddress,
    isFetchingAddress
  } = useUserAddress();

  return (
    <Container>
      <OnboardingTitle>{userAddress.title[currentLangKey]}</OnboardingTitle>

      {isFetchingAddress && (
        <CustomLoadingOverlay>
          <Spinner
            color='royalBlue'
            size={4}
          />
        </CustomLoadingOverlay>
      )}

      <FormContainer onSubmit={handleUpdateUserOnboardingAddress}>
        <DsTextInput
          name='postalCode'
          label={address.zip_code[currentLangKey]}
          mask='cep'
          control={control}
          onClear={() => {
            setValue('postalCode', '');
          }}
          error={errors.postalCode?.message}
        />

        <DsTextInput
          name='street'
          mask='alphanumeric'
          label={address.street[currentLangKey]}
          control={control}
          onClear={() => {
            setValue('street', '');
          }}
          error={errors.street?.message}
        />

        <RowDisposedFields>
          <DsTextInput
            name='number'
            label={address.number[currentLangKey]}
            mask='alphanumeric'
            control={control}
            onClear={() => {
              setValue('number', '');
            }}
            error={errors.number?.message}
          />

          <DsTextInput
            name='complement'
            mask='alphanumeric'
            label={address.complement[currentLangKey]}
            control={control}
            onClear={() => {
              setValue('complement', '');
            }}
            error={errors.complement?.message}
          />
        </RowDisposedFields>

        <DsTextInput
          name='neighborhood'
          maxLength={30}
          mask='alphanumeric'
          label={address.neighborhood[currentLangKey]}
          control={control}
          onClear={() => {
            setValue('neighborhood', '');
          }}
          error={errors.neighborhood?.message}
        />

        <RowDisposedFields>
          <DsTextInput
            name='city'
            mask='alphanumeric'
            label={address.city[currentLangKey]}
            control={control}
            onClear={() => {
              setValue('city', '');
            }}
            error={errors.city?.message}
          />

          <DsSelect
            name='state'
            label={address.state[currentLangKey]}
            options={states}
            control={control}
            error={errors.state?.message}
          />
        </RowDisposedFields>

        <DsTextInput
          name='country'
          label={address.country[currentLangKey]}
          error={errors.country?.message}
          value='Brasil'
          disabled
        />

        <OnboardingFooter
          backButtonProps={{
            onClick: () => {
              navigate('/cartoes/onboarding/usuario/2');
            }
          }}
          continueButtonProps={{
            onClick: () => {}
          }}
        />
      </FormContainer>
    </Container>
  );
}
