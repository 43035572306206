import { Route } from 'react-router-dom';

import { ExpensesList } from 'presentation/pages/expenses';

import { ExpensesRoutes } from 'shared/constants/global';
import { AuthenticationMiddleware } from 'shared/middlewares';
import { ExpensesAuthMiddleware } from 'shared/middlewares/ExpensesAuth/ExpensesAuth.middleware';

export const expensesRoutes = [
  <Route
    key='expenses-middleware-id'
    element={<AuthenticationMiddleware />}
  >
    <Route element={<ExpensesAuthMiddleware />}>
      <Route
        path={ExpensesRoutes.EXPENSES_ID}
        element={<ExpensesList />}
      />
    </Route>
  </Route>,
  <Route
    key='expenses-middleware'
    element={<AuthenticationMiddleware />}
  >
    <Route element={<ExpensesAuthMiddleware />}>
      <Route
        path={ExpensesRoutes.EXPENSES}
        element={<ExpensesList />}
      />
    </Route>
  </Route>
];
