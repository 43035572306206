import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useAnalysisExpensesListContext, useModalContext } from 'data/contexts';

import { AnalysisDefaultContainer } from 'presentation/pages/analytics/Analysis/components/AnalysisDefaultContainer';
import { AnalysisHeader } from 'presentation/pages/analytics/Analysis/components/AnalysisHeader';
import { UserAnalysisAppliedFilters } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisAppliedFilters';
import { UserAnalysisBiggestSpendingChart } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisBiggestSpendingChart';
import { UserAnalysisList } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisList';
import { UserAnalysisResume } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisResume';
import { useUserExpenses } from 'presentation/pages/analytics/Analysis/UserExpenses/useUserExpenses';
import { ViewExpensesModal } from 'presentation/pages/expenses/ExpensesList/components/Modal';
import { DefaultLayout } from 'presentation/templates';

import { routesPathPrefix } from 'shared/constants/global';

export function UserExpenses(): JSX.Element {
  const { t } = useTranslation(['analytics', 'global']);
  const { handleInfinityScroll } = useUserExpenses();

  const { visibleModal } = useModalContext();

  const [expenseIndex, expenses, handleChangeExpenseIndex] =
    useAnalysisExpensesListContext(
      useShallow(state => [
        state.expenseIndex,
        state.expenses,
        state.handleChangeExpenseIndex
      ])
    );

  const expenseId = expenses?.[expenseIndex]?.expenseId ?? '';

  return (
    <DefaultLayout
      onScroll={handleInfinityScroll}
      pageNameAndBreadcrumbs={{
        pageName: t('pageName.userExpenses'),
        isBreadcrumbsVisible: true,
        isBackButtonVisible: true,
        isClassicMode: false,
        breadcrumbs: [
          {
            name: t('pageName.analytics'),
            pageLink: routesPathPrefix.analytics
          },
          { name: t('pageName.userExpenses') }
        ]
      }}
    >
      <AnalysisDefaultContainer>
        <AnalysisHeader />

        <UserAnalysisAppliedFilters />

        <UserAnalysisBiggestSpendingChart />

        <UserAnalysisResume />

        <UserAnalysisList />
      </AnalysisDefaultContainer>
      {visibleModal === 'viewExpensesModal' && (
        <ViewExpensesModal
          isReadOnly
          preventRedirect
          isExpenseOnList
          total={expenses.length}
          expenseIndex={expenseIndex}
          expenseUuidOrId={String(expenseId)}
          onChangeExpense={action => {
            if (action === 'previous') {
              handleChangeExpenseIndex(expenseIndex - 1);
            }

            if (action === 'next') {
              handleChangeExpenseIndex(expenseIndex + 1);
            }
          }}
        />
      )}
    </DefaultLayout>
  );
}
