import { useEffect, useState } from 'react';

import { type IProject, useGetPaginatedProjects } from 'data/modules/projects';

import { useDebounce } from 'shared/hooks/global';
import { TransformArray } from 'shared/utils/global';

import {
  type ISelectDynamicProjectsParams,
  type ISelectDynamicProjectsState,
  type IUseSelectDynamicProjects
} from './SelectDynamicProjects.types';
import { type ISelectDynamicControlledOption } from 'ds/components/Select/Dynamic/Controlled/SelectDynamicControlled.types';

export function useSelectDynamicProjects({
  onChangeProject,
  projectIdsToNotShowAsOptions
}: ISelectDynamicProjectsParams): IUseSelectDynamicProjects {
  const [payloadParams, setPayloadParams] =
    useState<ISelectDynamicProjectsState>({
      page: 1,
      perPage: 10,
      description: ''
    });

  const [projectsOptions, setProjectsOptions] = useState<IProject[]>([]);

  const debouncedSearch = useDebounce(payloadParams.description);

  const { paginatedProjects, isFetchingPaginatedProjects } =
    useGetPaginatedProjects({
      ...payloadParams,
      description: debouncedSearch !== '' ? debouncedSearch : undefined
    });

  useEffect(() => {
    if (paginatedProjects && !isFetchingPaginatedProjects) {
      if (payloadParams.page === 1) {
        setProjectsOptions(paginatedProjects.data);
      }

      if (payloadParams.page > 1) {
        setProjectsOptions(prevState => [
          ...prevState,
          ...paginatedProjects.data
        ]);
      }
    }
  }, [
    paginatedProjects,
    isFetchingPaginatedProjects,
    payloadParams.perPage,
    payloadParams.page
  ]);

  function handleChangeSearchValue(value: string): void {
    setPayloadParams(prevState => ({
      ...prevState,
      page: 1,
      description: value
    }));
  }

  function handleMenuScrollToBottom(): void {
    // Só vai carregar mais se a quantidade de itens retornados for igual ao perPage
    if (paginatedProjects?.data.length === payloadParams.perPage) {
      setPayloadParams(prevState => ({
        ...prevState,
        page: prevState.page + 1
      }));
    }
  }

  function handleChangeValue(value: ISelectDynamicControlledOption): void {
    const project = projectsOptions.find(
      item => String(item.id) === String(value.value)
    );

    if (project) {
      onChangeProject?.(project);
    }
  }

  const options = TransformArray.objectsToSelectOptions(
    projectsOptions,
    'id',
    'description',
    true
  );

  const filteredOptions = options.filter(
    item => !projectIdsToNotShowAsOptions?.includes(String(item.value))
  );

  return {
    handleChangeSearchValue,
    handleMenuScrollToBottom,
    handleChangeValue,
    isLoadingMoreOptions: isFetchingPaginatedProjects,
    options: filteredOptions,
    search: payloadParams.description
  };
}
