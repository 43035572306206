import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';

import { useLangContext } from 'data/contexts';
import { GlobalQueryKeys, GlobalService } from 'data/modules/global';

import {
  type IIsLoadingShareholderAddressByPostalCodeState,
  type IUseShareholdersForm,
  type IUseShareholdersFormParams
} from './ShareholdersForm.types';

export function useShareholdersForm({
  setValue,
  clearErrors
}: IUseShareholdersFormParams): IUseShareholdersForm {
  const [
    isLoadingShareholderAddressByPostalCode,
    setIsLoadingShareholderAddressByPostalCode
  ] = useState<IIsLoadingShareholderAddressByPostalCodeState>({
    isLoading: false,
    shareholderIndex: -1
  });

  const [lang, settings, currentLangKey] = useLangContext(state => [
    state.lang,
    state.lang.settings,

    state.currentLangKey
  ]);

  const selectOptionsShareHolderType = [
    {
      label:
        settings.accountHolders.natural_person_type.legal_representative[
          currentLangKey
        ],
      value: 'legal_representative'
    },
    {
      label:
        settings.accountHolders.natural_person_type.partner[currentLangKey],
      value: 'partner'
    },
    {
      label:
        settings.accountHolders.natural_person_type.proxyholder[currentLangKey],
      value: 'proxyholder'
    },
    {
      label: settings.accountHolders.natural_person_type.others[currentLangKey],
      value: 'other'
    }
  ];

  const queryClient = useQueryClient();

  async function handleBlurPostalCode(
    shareholderIndex: number,
    postalCode: string
  ): Promise<void> {
    if (postalCode.length < 9) {
      return;
    }

    setIsLoadingShareholderAddressByPostalCode({
      isLoading: true,
      shareholderIndex
    });

    try {
      const address = await queryClient.ensureQueryData({
        queryKey: [
          GlobalQueryKeys.GET_VIA_CEP_ADDRESS_BY_POSTAL_CODE,
          {
            postalCode
          }
        ],
        queryFn: async () =>
          await GlobalService.getViaCepAddressByPostalCode({ postalCode })
      });

      if (!address) {
        throw new Error();
      }

      setValue(`shareholders.${shareholderIndex}.street`, address.street);
      clearErrors(`shareholders.${shareholderIndex}.street`);
      setValue(
        `shareholders.${shareholderIndex}.complement`,
        address.complement
      );
      clearErrors(`shareholders.${shareholderIndex}.complement`);
      setValue(
        `shareholders.${shareholderIndex}.neighborhood`,
        address.neighborhood
      );
      clearErrors(`shareholders.${shareholderIndex}.neighborhood`);
      setValue(`shareholders.${shareholderIndex}.city`, address.city);
      clearErrors(`shareholders.${shareholderIndex}.city`);
      setValue(`shareholders.${shareholderIndex}.state`, address.state);
      clearErrors(`shareholders.${shareholderIndex}.state`);
    } catch {
      toast.error(lang.global.postal_code_not_found[currentLangKey]);
    } finally {
      setIsLoadingShareholderAddressByPostalCode({
        isLoading: false,
        shareholderIndex: -1
      });
    }
  }

  return {
    handleBlurPostalCode,
    isLoadingShareholderAddressByPostalCode,
    selectOptionsShareHolderType
  };
}
