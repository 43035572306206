import { useQueryCache } from 'data/cache';
import { BalanceQueryKeys } from 'data/modules/cards/balance/keys/balance.keys';
import BalanceService from 'data/modules/cards/balance/services/Balance.service';

import {
  type IUseGetQuantityOfBalanceSolicitationByStatus,
  type IUseGetQuantityOfBalanceSolicitationByStatusPayload
} from './useGetQuantityOfBalanceSolicitationByStatus.types';

export function useGetQuantityOfBalanceSolicitationByStatus(
  payload: IUseGetQuantityOfBalanceSolicitationByStatusPayload
): IUseGetQuantityOfBalanceSolicitationByStatus {
  const { data, isFetching } = useQueryCache({
    queryKey: [
      BalanceQueryKeys.GET_QUANTITY_OF_BALANCE_SOLICITATION_BY_STATUS,
      { payload }
    ],
    queryFn: async () =>
      await BalanceService.getQuantityOfBalanceSolicitationByStatus(payload)
  });

  return {
    quantitySolicitationByStatus: data,
    isFetchingQuantitySolicitationByStatus: isFetching
  };
}
