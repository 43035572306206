import { faker } from '@faker-js/faker';

import { type IQuantityOfBalanceSolicitationByStatus } from 'data/modules/cards/balance';

import { MockApi } from 'shared/utils/global';

export async function getQuantityOfBalanceSolicitationByStatusMock(): Promise<IQuantityOfBalanceSolicitationByStatus> {
  const data = {
    sent: faker.number.int({ min: 0, max: 100 }),
    approved: faker.number.int({ min: 0, max: 100 }),
    rejected: faker.number.int({ min: 0, max: 100 }),
    get all() {
      return this.sent + this.approved + this.rejected;
    }
  };

  await MockApi.sleep(2000);

  return data;
}
