import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import {
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  useHandleLegalEntityPartnerData
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

import { type IUseLegalEntityData } from './LegalEntityData.types';

export function useLegalEntityData(): IUseLegalEntityData {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const selectedShareholder = queryClient.getQueryData(['selectedShareholder']);

  const { data: cachedCompanyOnboardingShareholders } = useHandleQueryCache({
    query: {
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_SHAREHOLDERS],
      query: async () =>
        await CompanyOnboardingService.getCompanyOnboardingShareholders()
    }
  });

  const { control, setValue, errors, handleForwardDataToNextStep } =
    useHandleLegalEntityPartnerData();

  useEffect(() => {
    if (!cachedCompanyOnboardingShareholders) {
      navigate(`/cartoes/onboarding/empresa/${currentStep}`, {
        replace: true
      });
    }
  }, [navigate, cachedCompanyOnboardingShareholders, currentStep]);

  return {
    control,
    setValue,
    errors,
    handleForwardDataToNextStep,
    isEditMode: Boolean(selectedShareholder)
  };
}
