import { Select } from 'ds';
import { useTranslation } from 'react-i18next';

import { useLangContext } from 'data/contexts';

import { useSelectDynamicProjects } from './useSelectDynamicProjects';

import { type ISelectDynamicProjectsProps } from './SelectDynamicProjects.types';

export function SelectDynamicProjects({
  name,
  onChangeProject,
  projectIdsToNotShowAsOptions,
  customLabel
}: ISelectDynamicProjectsProps): JSX.Element {
  const { currentLangKey, lang } = useLangContext();

  const {
    handleChangeSearchValue,
    handleMenuScrollToBottom,
    isLoadingMoreOptions,
    options,
    search,
    handleChangeValue
  } = useSelectDynamicProjects({
    onChangeProject,
    projectIdsToNotShowAsOptions
  });

  const { t } = useTranslation('expenses');

  return (
    <Select.DynamicControlled
      small
      name={name}
      menuPlacement='top'
      hasArrowDownIndicator
      isLoading={false}
      options={options}
      placeholder={customLabel ?? lang.global.project[currentLangKey]}
      label={customLabel ?? lang.global.project[currentLangKey]}
      isLoadingMoreOptions={isLoadingMoreOptions}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      searchValue={search}
      onChangeSearchValue={handleChangeSearchValue}
      onCustomChange={handleChangeValue}
      noOptionsMessage={t('modal.noMoreOptions')}
    />
  );
}
