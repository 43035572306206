import { useValueSolicitationContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';
import { BalanceCardSection } from 'presentation/pages/cards/Management/ValueSolicitation/components/SolicitationActions/BalanceCardSection/BalanceCardSection.component';
import { SolicitationActionsTable } from 'presentation/pages/cards/Management/ValueSolicitation/components/SolicitationActions/SolicitationActionsTable/SolicitationActionsTable.component';
import { TransferFeedbackModal } from 'presentation/pages/cards/Management/ValueSolicitation/components/TransferFeedbackModal/TransferFeedbackModal.component';

import { useSolicitationActionsList } from './useSolicitationActionsList';

import { type ISolicitationActionsListProps } from './SolicitationActionsList.types';

export function SolicitationActionsList({
  balanceSolicitationsApproved,
  onBalanceSolicitationApprovalsSummaryData
}: ISolicitationActionsListProps): JSX.Element {
  const {
    handleSubmit,
    isSendingBalanceSolicationApproved,
    isFetchingUnallocatedBalance
  } = useSolicitationActionsList({ onBalanceSolicitationApprovalsSummaryData });

  const step = useValueSolicitationContext(state => state.step);
  return (
    <>
      <Loader
        isLoading={
          isSendingBalanceSolicationApproved || isFetchingUnallocatedBalance
        }
      />

      {step !== 'disapprove' && <BalanceCardSection />}

      <SolicitationActionsTable handleSubmitSolicitations={handleSubmit} />

      <TransferFeedbackModal
        balanceSolicitationApproved={balanceSolicitationsApproved}
      />
    </>
  );
}
