import { format } from 'date-fns';
import { Alert, DSIcons, Tooltip, ViewImagePdf } from 'ds';
import { useTranslation } from 'react-i18next';

import { useMovementsContext } from 'data/contexts';

import { useCopyTransactionIdToClipboard } from 'presentation/pages/cards/Movements/hooks';

import { CustomString } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

import { Movements } from '../../utils';
import { TransactionDetails } from '../TransactionDetailsModal';

import { type INationalPurchaseProps } from './NationalPurchase.types';
import { type LanguageType } from 'ds/types';

import { SectionWithFile } from './NationalPurchase.styles';

export function NationalPurchase({
  expense,
  hasErrorInExpense
}: INationalPurchaseProps): JSX.Element {
  const { t, i18n } = useTranslation(['cards', 'global']);
  const transactionDetails = useMovementsContext(
    state => state.transactionDetails
  );
  const { handleCopyTransactionIdToClipboard, shouldShowTooltip } =
    useCopyTransactionIdToClipboard();

  return (
    <>
      <TransactionDetails.Header
        description={
          transactionDetails?.merchantName! ||
          transactionDetails?.paymentDescription ||
          '-'
        }
        datetime={`${format(transactionDetails?.datetime!, 'dd/MM/yyyy')} ${t('global:at')} ${format(transactionDetails?.datetime!, 'HH')}h${format(transactionDetails?.datetime!, 'mm')}`}
        value={Currency.format(
          'BRL',
          Math.abs(transactionDetails?.finalBillingAmount!),
          true
        )}
      />

      <TransactionDetails.Section title={t('transactionDetails')}>
        <TransactionDetails.SectionItem>
          <p>{t('cardName')}</p>

          <p className='value'>
            {CustomString.capitalizeWord(
              transactionDetails?.name ||
                transactionDetails?.transferorName ||
                '-'
            )}
          </p>
        </TransactionDetails.SectionItem>

        <TransactionDetails.SectionItem>
          <p>{t('finalOfCard')}</p>

          <p className='value'>{transactionDetails?.cardNumber! || '-'}</p>
        </TransactionDetails.SectionItem>

        <TransactionDetails.SectionItem>
          <p>{t('global:description')}</p>

          <p className='value'>{transactionDetails?.merchantName || '-'}</p>
        </TransactionDetails.SectionItem>

        <TransactionDetails.SectionItem>
          <p>{t('transactionId')}</p>

          <div className='value'>
            {transactionDetails?.transactionId}

            <Tooltip
              trigger='focus'
              placement='top'
              message={t('global:copyed')}
              visible={shouldShowTooltip}
            >
              <div>
                <DSIcons.StickerCopyIcon
                  className='icon'
                  onClick={() => {
                    handleCopyTransactionIdToClipboard(
                      transactionDetails?.transactionId!
                    );
                  }}
                />
              </div>
            </Tooltip>
          </div>
        </TransactionDetails.SectionItem>
      </TransactionDetails.Section>

      {!hasErrorInExpense && expense && expense?.report && (
        <SectionWithFile>
          <TransactionDetails.Section
            title={t('global:expense')}
            hasDivider={false}
          >
            <TransactionDetails.SectionItem>
              <p>{t('global:expenseType')}</p>

              <p className='value'>{expense.expenseType || '-'}</p>
            </TransactionDetails.SectionItem>

            <TransactionDetails.SectionItem>
              <p>{t('global:report')}</p>

              <p className='value'>
                {expense.report ? expense.report.title : '-'}
              </p>
            </TransactionDetails.SectionItem>

            <TransactionDetails.SectionItem>
              <p>{t('global:costCenter')}</p>

              <p className='value'>{expense.costsCenter}</p>
            </TransactionDetails.SectionItem>

            <TransactionDetails.SectionItem>
              <p>{t('global:projects')}</p>

              <p className='value'>
                {expense.apportionments
                  ? expense.apportionments
                      .map(apportionment => apportionment.description)
                      .join(', ')
                  : '-'}
              </p>
            </TransactionDetails.SectionItem>

            <TransactionDetails.SectionItem>
              <p>{t('global:reportStatus')}</p>

              {Movements.getReportStatusIcon(expense.report?.status)}
            </TransactionDetails.SectionItem>
          </TransactionDetails.Section>

          <ViewImagePdf
            url={expense.receiptUrl}
            hasZoomControl
            showZoomTour
            className='view-image'
            portalId='view-image-portal'
            langKey={i18n.language as LanguageType}
            fileLabel={transactionDetails?.merchantName || 'file'}
          />
        </SectionWithFile>
      )}

      {hasErrorInExpense && (
        <TransactionDetails.Section>
          <Alert type='error'>{t('errorOnLoadExpense')}</Alert>
        </TransactionDetails.Section>
      )}
    </>
  );
}
