import { useMutationCache } from 'data/cache';
import {
  CardGroupsMutationKeys,
  CardGroupsService,
  type IRemoveManagerFromGroupPayload,
  type IUseRemoveManagerFromGroup
} from 'data/modules/cards/cardGroups';

export function useRemoveManagerFromGroup(): IUseRemoveManagerFromGroup {
  const { mutateAsync, isPending } = useMutationCache({
    mutationKey: [CardGroupsMutationKeys.REMOVE_MANAGER_FROM_GROUP],
    mutationFn: async (payload: IRemoveManagerFromGroupPayload) => {
      await CardGroupsService.removeManagerFromGroup(payload);
    }
  });

  return {
    removeManagerFromGroup: mutateAsync,
    isRemovingManagerFromGroup: isPending
  };
}
