import { BaseIcon } from 'presentation/components/base/Icon';

import { type IRectButtonProps } from './RectButton.types';

import {
  Container,
  ContainerContent,
  ContainerIcon,
  StyledLink
} from './RectButton.styles';

export function RectButton({
  color = 'primary',
  iconProps,
  size = 'md',
  variation = 'default',
  fontWeight = 400,
  children,
  linkProps,
  ...rest
}: IRectButtonProps): JSX.Element {
  const buttonContainer = (
    <Container
      $color={color}
      $fontWeight={fontWeight}
      $variation={variation}
      $size={size}
      aria-disabled={rest.disabled}
      {...rest}
    >
      {iconProps && (
        <>
          <ContainerIcon>
            <BaseIcon {...iconProps} />
          </ContainerIcon>
        </>
      )}
      <ContainerContent>{children}</ContainerContent>
    </Container>
  );

  if (linkProps) {
    return <StyledLink {...linkProps}>{buttonContainer}</StyledLink>;
  }

  return buttonContainer;
}
