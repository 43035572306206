import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type ICompleteAccountStepsPayload,
  type IUseCompleteAccountSteps,
  type IUseCompleteAccountStepsParams
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';

export function useCompleteAccountSteps({
  onCompleteAccountStepsMutationSuccess
}: IUseCompleteAccountStepsParams): IUseCompleteAccountSteps {
  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const { isLoading: isCompletingAccountSteps, mutate: completeAccountSteps } =
    useMutationCache({
      mutationKey: [CompanyOnboardingMutationKeys.COMPLETE_ACCOUNT_STEPS],
      mutationFn: async (payload: ICompleteAccountStepsPayload) =>
        await CompanyOnboardingService.completeAccountSteps(payload),
      onSuccess: onCompleteAccountStepsMutationSuccess,
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  function handleCompleteAccountSteps(): void {
    completeAccountSteps({
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? ''
    });
  }

  return {
    handleCompleteAccountSteps,
    isCompletingAccountSteps
  };
}
