import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  align-items: center;
  gap: ${tokens.spacingNano};
  justify-content: flex-start;
  border-radius: ${tokens.borderRadiusXxl};
  background: ${tokens.brandColorPrimaryLightest};
  padding: ${tokens.spacingNano} ${tokens.spacingXs};
  border: 1px solid ${tokens.neutralColorLowLightest};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 100%;
  }
`;

export const FilterItemLabel = styled.p`
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
`;

export const FilterItemValue = styled(FilterItemLabel)`
  font-weight: ${tokens.fontWeightMedium};
`;
