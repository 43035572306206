import { BaseIcon } from 'presentation/components/base/Icon';

import { type IDsTagProps } from './DsTag.types';

import { Container } from './DsTag.styles';

export const DsTag = ({
  text,
  type = 'primary',
  iconSide = 'right',
  iconProps,
  outlined = false
}: IDsTagProps): JSX.Element => {
  return (
    <Container
      $type={type}
      $outlined={outlined}
    >
      {iconSide === 'left' && iconProps && <BaseIcon {...iconProps} />}

      {text}

      {iconSide === 'right' && iconProps && (
        <BaseIcon
          {...iconProps}
          type='line'
        />
      )}
    </Container>
  );
};
