import { DefaultButton, Modal, SecondaryButton } from 'ds';

import { useLangContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';

import { type IActionModalProps } from './ActionModal.types';

import { Footer, ModalContent } from './ActionModal.styles';

export function ActionModal({
  children,
  title,
  isLoading = false,
  confirmLabel,
  cancelLabel,
  onCancel,
  onConfirm,
  onClose,
  disableConfirm,
  headerGapDisabled,
  disabledFixedHeight,
  variant
}: IActionModalProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  return (
    <Modal.Container
      variant={variant ?? 'small'}
      onClose={onClose}
    >
      <Loader isLoading={isLoading} />

      <Modal.Header
        gapDisabled={headerGapDisabled}
        id='header'
      >
        <Modal.Title>{title}</Modal.Title>

        <Modal.IconClose onClick={onClose} />
      </Modal.Header>

      <ModalContent $disabledFixedHeight={disabledFixedHeight}>
        {children}
      </ModalContent>

      {(onCancel || onConfirm) && (
        <Modal.Footer>
          <Footer>
            {onCancel && (
              <SecondaryButton
                onClick={onCancel}
                size='small'
              >
                {cancelLabel ?? lang.global.cancel[currentLangKey]}
              </SecondaryButton>
            )}

            {onConfirm && (
              <DefaultButton
                onClick={onConfirm}
                disabled={disableConfirm}
                size='small'
              >
                {confirmLabel ?? lang.global.confirm[currentLangKey]}
              </DefaultButton>
            )}
          </Footer>
        </Modal.Footer>
      )}
    </Modal.Container>
  );
}
