import { tokens } from 'ds';
import styled from 'styled-components';

import { InfoMessage } from 'presentation/components/global/InfoMessage';

export const CustomInfoMessage = styled(InfoMessage)`
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  flex-wrap: wrap;
  border: solid 1px ${tokens.brandColorPrimaryLight};

  button {
    height: 3rem;
    margin-left: auto;
    white-space: nowrap;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-wrap: nowrap;
  }
`;
