import { useLangContext } from 'data/contexts';
import { useChangeBusinessCardTeamMembers } from 'data/modules/cards/businessCard';

import { Select } from 'presentation/components/base/Select';
import { Loader } from 'presentation/components/global/Loader';
import { Title } from 'presentation/components/global/Title';
import { ListItems } from 'presentation/pages/settings/Cards/ManageBusinessCard/components/List';

import { useMembersWithAccessManageBusinessCard } from './useMembersWithAccess';

import { type IMembersWithAccessProps } from './MembersWithAccess.types';

import {
  ActionsList,
  Container,
  CustomButton,
  FormInputs,
  SelectProjectContainer
} from './MembersWithAccess.styles';

export function MembersWithAccess({
  card
}: IMembersWithAccessProps): JSX.Element {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const {
    teamMembersOptions,
    relatedTeamMembersOptions,
    isLoadingTeamMembers
  } = useMembersWithAccessManageBusinessCard({ uuid: card.uuid });

  const {
    control,
    handleRemoveTeamMembers,
    handleSubmit,
    isRemovingRelatedTeamMembers,
    loadingAttachTeamMembersToBusinessCard,
    errors
  } = useChangeBusinessCardTeamMembers({ uuid: card.uuid });

  return (
    <Container>
      <Loader isLoading={loadingAttachTeamMembersToBusinessCard} />
      <SelectProjectContainer>
        <Title
          text={`${lang.settings.manageBusinessCard.relate_members_with_card[currentLangKey]}${card.name}`}
          color='white'
          backgroundColor='primary'
        />
        <FormInputs>
          <Select
            isMulti
            name='teamMembers'
            options={teamMembersOptions}
            label={
              lang.settings.manageBusinessCard.relate_members[currentLangKey]
            }
            placeholder={
              lang.settings.manageBusinessCard.search_members[currentLangKey]
            }
            isLoading={isLoadingTeamMembers}
            control={control}
            error={errors.teamMembers?.message}
          />

          <ActionsList>
            <li>
              {
                lang.settings.businessCards.actions_allowed_for_execution[
                  currentLangKey
                ]
              }
            </li>
            <li>
              {lang.settings.businessCards.actions_expenses[currentLangKey]}
            </li>
            <li>
              {lang.settings.businessCards.actions_reports[currentLangKey]}
            </li>
            <li>
              {lang.settings.businessCards.actions_approvals[currentLangKey]}
            </li>
            <li>
              {lang.settings.businessCards.actions_advances[currentLangKey]}
            </li>
            <li>{lang.settings.businessCards.actions_cards[currentLangKey]}</li>
          </ActionsList>

          <CustomButton
            color='success'
            onClick={handleSubmit}
          >
            {lang.global.save[currentLangKey]}
          </CustomButton>
        </FormInputs>
      </SelectProjectContainer>

      <SelectProjectContainer>
        <Title
          text={`${lang.settings.manageBusinessCard.members_related_to_card[currentLangKey]}${card.name}`}
          color='white'
          backgroundColor='primary'
        />

        <ListItems
          items={relatedTeamMembersOptions}
          onRemoveItem={id => {
            handleRemoveTeamMembers({ id: id.toString() });
          }}
          infoMessage={
            lang.settings.manageBusinessCard.no_related_members[currentLangKey]
          }
          titleModal={
            lang.settings.manageBusinessCard.remove_relationship[currentLangKey]
          }
          isLoading={isRemovingRelatedTeamMembers}
        />
      </SelectProjectContainer>
    </Container>
  );
}
