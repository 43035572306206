import { type KeyboardEvent } from 'react';

import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue
} from 'react-hook-form';
import { type InputActionMeta } from 'react-select';
import { type FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { useLangContext } from 'data/contexts';

import { RectButton } from 'presentation/components/base/Button';
import { ErrorMessage } from 'presentation/components/base/Input';
import { SkeletonLoader } from 'presentation/components/global/Loader';

import { useSelect } from './useSelect';

import {
  type ISelectOption,
  type ISelectProps,
  type OnChangeSelectType
} from './Select.types';

import {
  Container,
  LabelAndButtonsContent,
  StyledSelect
} from './Select.styles';

export function Select<T extends FieldValues>({
  name,
  control,
  options,
  value,
  error,
  disabled,
  label,
  className,
  placeholder,
  noOptionsMessage,
  onChange,
  hasCleanButtonOutsideInput = false,
  hasArrowDownIndicator = false,
  isMulti = false,
  isClearable = false,
  closeMenuOnSelect = true,
  isSearchable = true,
  isLoading = false,
  hasSelectAllOptionsButton = false,
  selectFilteredOptionsOnEnter = false,
  cleanButtonDisabled = false
}: ISelectProps<T>): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);
  const {
    searchValue,
    setSearchValue,
    filteredOptions,
    handleSelectFilteredOptions
  } = useSelect({
    options
  });

  const componentToRender = (
    value: string | string[],
    onChange: OnChangeSelectType
  ): JSX.Element => (
    <>
      {(label || hasCleanButtonOutsideInput || hasSelectAllOptionsButton) && (
        <LabelAndButtonsContent
          $hasError={error !== undefined}
          $hasCleanButtonOutsideInput={hasCleanButtonOutsideInput}
          $hasSelectAllOptionsButton={hasSelectAllOptionsButton}
          $hasLabel={label !== undefined}
          $isDisabled={disabled ?? false}
        >
          {label && <label>{label}</label>}
          {(hasCleanButtonOutsideInput || hasSelectAllOptionsButton) && (
            <span>
              {hasCleanButtonOutsideInput && (
                <RectButton
                  variation='borderless'
                  onClick={() => {
                    onChange(isMulti ? [] : null);
                  }}
                  type='button'
                  disabled={isLoading || disabled || cleanButtonDisabled}
                >
                  {lang.forms.select.clean_selection[currentLangKey]}
                </RectButton>
              )}
              {isMulti && hasSelectAllOptionsButton && searchValue !== '' && (
                <RectButton
                  onClick={() => {
                    handleSelectFilteredOptions(value, onChange);
                  }}
                  type='button'
                  disabled={
                    filteredOptions.filter(
                      option => !value.includes(option.value)
                    ).length === 0 ||
                    isLoading ||
                    disabled
                  }
                >
                  {lang.forms.select.select_search[currentLangKey]}
                </RectButton>
              )}
            </span>
          )}
        </LabelAndButtonsContent>
      )}
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <StyledSelect
          name={name}
          classNamePrefix={'react-select-custom'}
          isMulti={isMulti}
          isClearable={isClearable}
          isSearchable={isSearchable}
          $error={error}
          placeholder={
            placeholder ?? lang.forms.select.placeholder_select[currentLangKey]
          }
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (!selectFilteredOptionsOnEnter) return;
            if (event.key === 'Enter') {
              event.preventDefault();
              handleSelectFilteredOptions(value, onChange);
            }
          }}
          isDisabled={disabled}
          options={options}
          className={className}
          isOptionSelected={(option: unknown) => {
            const typedOption = option as ISelectOption;
            if (isMulti) {
              const typedValue = value as string[];
              return typedValue.includes(typedOption.value);
            }
            const typedValue = value as string;
            return typedValue === typedOption.value;
          }}
          inputValue={searchValue}
          onInputChange={(value: string, action: InputActionMeta) => {
            if (
              action?.action !== 'input-blur' &&
              action?.action !== 'menu-close'
            ) {
              setSearchValue(value);
            }
          }}
          filterOption={(option: FilterOptionOption<unknown>, input: string) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          value={
            isMulti
              ? options.filter(option =>
                  (value as string[]).includes(option.value)
                )
              : options.find(option => option.value === (value as string)) ??
                null
          }
          $hasArrowDownIndicator={hasArrowDownIndicator}
          onChange={(newValue: unknown) => {
            if (isMulti) {
              const typedNewValue = newValue as ISelectOption[];
              onChange(typedNewValue.map(typedNewValue => typedNewValue.value));
              return;
            }
            const typedNewValue = newValue as ISelectOption;
            onChange(typedNewValue?.value ? typedNewValue.value : null);
          }}
          closeMenuOnSelect={closeMenuOnSelect}
          noOptionsMessage={() =>
            noOptionsMessage ??
            lang.forms.select.no_more_options[currentLangKey]
          }
        />
      )}
    </>
  );

  return (
    <Container>
      {control ? (
        <Controller
          name={name as Path<T>}
          control={control}
          defaultValue={(isMulti ? [] : null) as PathValue<T, Path<T>>}
          render={({ field: { onChange, value } }) =>
            componentToRender(value, onChange as OnChangeSelectType)
          }
        />
      ) : (
        componentToRender(
          value as string | string[],
          onChange as OnChangeSelectType
        )
      )}
      {error && <ErrorMessage message={error} />}
    </Container>
  );
}
