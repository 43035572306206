import {
  Alert,
  DefaultButton,
  Modal,
  SecondaryButton,
  Select,
  TextInput
} from 'ds';

import { useLangContext, useModalContext } from 'data/contexts';
import { useRequestWithdrawal } from 'data/modules/cards/balance';

import { Loader } from 'presentation/components/global/Loader';

import { useNewWithdrawalModal } from './useNewWithdrawalModal';

import {
  AgencyAndAccountContainer,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalForm
} from './NewWithdrawalModal.styles';

export function NewWithdrawalModal(): JSX.Element {
  const {
    currentLangKey,
    lang: { global, cards, forms }
  } = useLangContext();

  const { handleCloseModal } = useModalContext();

  const { methods, isLoading, handleSubmit } = useRequestWithdrawal();

  const { bankOptions } = useNewWithdrawalModal();

  if (isLoading) {
    return <Loader isLoading />;
  }

  return (
    <Modal.Container onClose={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>
          {cards.withdrawal.withdrawal_modal_title[currentLangKey]}
        </Modal.Title>

        <Modal.IconClose onClick={handleCloseModal} />
      </Modal.Header>

      <ModalForm
        id='withdrawn_value_form'
        methods={methods}
        handleSubmit={handleSubmit}
      >
        <ModalContent>
          <ModalDescription>
            <p>
              {cards.withdrawal.withdrawal_modal_description[currentLangKey]}
            </p>

            <Alert type='info'>
              {cards.withdrawal.receipt_period[currentLangKey]}
            </Alert>
          </ModalDescription>

          <TextInput.Controlled
            label={forms.amount[currentLangKey]}
            name='amount'
            placeholder={forms.amount[currentLangKey]}
            autoComplete='off'
            mask='currency'
            small
          />

          <Select.Controlled
            label={forms.bank[currentLangKey]}
            name='bank'
            placeholder={forms.bank[currentLangKey]}
            options={bankOptions}
            small
          />

          <AgencyAndAccountContainer>
            <TextInput.Controlled
              label={forms.agency[currentLangKey]}
              name='agency'
              placeholder={forms.agency[currentLangKey]}
              autoComplete='off'
              mask='numeric'
              small
            />

            <TextInput.Controlled
              label={forms.account[currentLangKey]}
              name='account'
              placeholder={forms.account[currentLangKey]}
              autoComplete='off'
              mask='numeric'
              small
            />
          </AgencyAndAccountContainer>

          <TextInput.Controlled
            label={forms.cnpj[currentLangKey]}
            name='documentNumber'
            placeholder={forms.cnpj[currentLangKey]}
            autoComplete='off'
            mask='cnpj'
            small
          />

          <TextInput.Controlled
            label={forms.pix[currentLangKey]}
            name='pixKey'
            placeholder={forms.pix[currentLangKey]}
            autoComplete='off'
            small
          />

          <TextInput.Controlled
            label={forms.reason[currentLangKey]}
            name='reason'
            placeholder={forms.reason[currentLangKey]}
            autoComplete='off'
            small
          />
        </ModalContent>

        <ModalFooter>
          <SecondaryButton
            onClick={handleCloseModal}
            size='small'
          >
            {global.cancel[currentLangKey]}
          </SecondaryButton>

          <DefaultButton
            type='submit'
            form='withdrawn_value_form'
            size='small'
          >
            {cards.withdrawal.withdrawal_modal_action[currentLangKey]}
          </DefaultButton>
        </ModalFooter>
      </ModalForm>
    </Modal.Container>
  );
}
