import { useQueryCache } from 'data/cache';
import {
  type IUseGetTeamMembersApprovalFlowOptions,
  TeamMembersQueryKeys,
  TeamMembersService
} from 'data/modules/teamMembers';

export function useGetTeamMembersApprovalFlowOptions(): IUseGetTeamMembersApprovalFlowOptions {
  const { data, isLoading } = useQueryCache({
    queryKey: [TeamMembersQueryKeys.GET_TEAM_MEMBERS_APPROVAL_FLOW_OPTIONS],
    queryFn: async () =>
      await TeamMembersService.getTeamMembersApprovalFlowOptions()
  });

  return {
    isLoadingApprovalFlowOptions: isLoading,
    approvalFlowOptions: data ?? []
  };
}
