import { useRef } from 'react';

import { Elements } from 'ds';
import { useDsGa4 } from 'ds/hooks/globals';

import { type IDangerButtonProps } from './DangerButton.types';

import { Container } from './DangerButton.styles';

export function DangerButton({
  children,
  variant = 'default',
  size = 'medium',
  onClick,
  ...rest
}: IDangerButtonProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  const buttonRef = useRef(null);

  return (
    <Container
      ref={buttonRef}
      onClick={e => {
        const isButtonInTable = Elements.isInTable(buttonRef);

        const defaultObj = {
          eventLabel: children?.toString()
        };

        const tableObj = {
          eventLabel: children?.toString(),
          eventName: 'tables',
          eventAction: 'action_button_tables'
        };

        sendDsGaEvent(
          'components',
          'buttons',
          isButtonInTable ? tableObj : defaultObj
        );

        onClick?.(e);
      }}
      {...rest}
      $size={size}
      $variant={variant}
    >
      {children}
    </Container>
  );
}
