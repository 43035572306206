import { PaginationProvider } from './PaginationContainer/PaginationContainer.context';
import { PaginationController } from './PaginationController/PaginationController.component';
import { PaginationLabel } from './PaginationLabel/PaginationLabel.component';
import { PaginationPages } from './PaginationPages/PaginationPages.component';

export const Pagination = {
  Container: PaginationProvider,
  Label: PaginationLabel,
  Pages: PaginationPages,
  Control: PaginationController
};
