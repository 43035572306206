enum CompanyOnboardingMutationKeys {
  'UPDATE_ANSWERABLE_USER' = 'UPDATE_ANSWERABLE_USER',
  'INCREMENT_COMPANY_STEP' = 'INCREMENT_COMPANY_STEP',
  'UPDATE_COMPANY_ONBOARDING_ANSWERS' = 'UPDATE_COMPANY_ONBOARDING_ANSWERS',
  'PULL_SERPRO_INFORMATIONS' = 'PULL_SERPRO_INFORMATIONS',
  'UPLOAD_COMPANY_DOCUMENT' = 'UPLOAD_COMPANY_DOCUMENT',
  'UPLOAD_AUXILIARY_DOCUMENT' = 'UPLOAD_AUXILIARY_DOCUMENT',
  'ADD_LEGAL_REPRESENTATIVE' = 'ADD_LEGAL_REPRESENTATIVE',
  'DELETE_LEGAL_REPRESENTATIVE' = 'DELETE_LEGAL_REPRESENTATIVE',
  'UPDATE_COMPANY_FORM_CACHE' = 'UPDATE_COMPANY_FORM_CACHE',
  'RESEND_LEGAL_REPRESENTATIVES_APPROVAL_SOLICITATION' = 'RESEND_LEGAL_REPRESENTATIVES_APPROVAL_SOLICITATION',
  'CLEAR_COMPANY_ONBOARDING_SHAREHOLDERS_CACHE' = 'CLEAR_COMPANY_ONBOARDING_SHAREHOLDERS_CACHE',
  'COMPLETE_COMPANY_ONBOARDING' = 'COMPLETE_COMPANY_ONBOARDING',
  'APPROVE_LEGAL_REPRESENTATIVE' = 'APPROVE_LEGAL_REPRESENTATIVE',
  'COMPLETE_ACCOUNT_STEPS' = 'COMPLETE_ACCOUNT_STEPS'
}

enum CompanyOnboardingQueryKeys {
  'GET_COMPANY_ONBOARDING_PROGRESS' = 'GET_COMPANY_ONBOARDING_PROGRESS',
  'GET_ANSWERABLE_USER' = 'GET_ANSWERABLE_USER',
  'GET_COMPANY_ONBOARDING_ANSWERS' = 'GET_COMPANY_ONBOARDING_ANSWERS',
  'GET_SERPRO_INFORMATIONS' = 'GET_SERPRO_INFORMATIONS',
  'GET_LEGAL_REPRESENTATIVES' = 'GET_LEGAL_REPRESENTATIVES',
  'GET_COMPANY_ONBOARDING_SHAREHOLDERS' = 'GET_COMPANY_ONBOARDING_SHAREHOLDERS',
  'GET_COMPANY_ONBOARDING_TEAM_MEMBERS' = 'GET_COMPANY_ONBOARDING_TEAM_MEMBERS',
  'GET_COMPANY_ONBOARDING_CONFIGURATIONS_USERS_CACHE' = 'GET_COMPANY_ONBOARDING_CONFIGURATIONS_USERS_CACHE',
  'GET_DATA_TO_REPRESENTATIVE_APPROVAL' = 'GET_DATA_TO_REPRESENTATIVE_APPROVAL',
  'GET_LEGAL_REPRESENTATIVE_TO_APPROVAL' = 'GET_LEGAL_REPRESENTATIVE_TO_APPROVAL'
}

export { CompanyOnboardingMutationKeys, CompanyOnboardingQueryKeys };
