import { BaseIcon } from 'presentation/components/base/Icon';

import { type IBadgeProps } from './Badge.types';

import { Container, Dot } from './Badge.styles';

export function Badge({
  label,
  iconProps,
  size = 'md',
  withDot = true,
  color = 'neutral'
}: IBadgeProps): JSX.Element {
  return (
    <Container
      $size={size}
      $color={color}
    >
      {iconProps ? (
        <BaseIcon {...iconProps} />
      ) : (
        withDot && <Dot $color={color} />
      )}
      <label>{label}</label>
    </Container>
  );
}
