import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { toast } from 'ds/utils';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  useChargeCardsContext,
  useLangContext,
  useModalContext
} from 'data/contexts';
import {
  AccountQueryKeys,
  type IAccountBalanceAmount
} from 'data/modules/cards/account';
import {
  BalanceQueryKeys,
  type IAllocatedBalance,
  type IModifiedBalanceAllocationItem,
  type ITransferredBalanceAllocationItems,
  type IUnallocatedBalance,
  useDeleteModifiedBalanceAllocationItemsFromCache,
  useSendModifiedBalanceAllocationItemsToCache,
  useTransferBalanceAllocationItems
} from 'data/modules/cards/balance';

import { ChargeCardsSteps } from 'presentation/pages/cards/Management/ChargeCards/ChargeCards.types';

import { Lang } from 'shared/utils/global';

import { type IUseChargeCardsSummary } from './ChargeCardsSummary.types';

export function useChargeCardsSummary(): IUseChargeCardsSummary {
  const {
    currentLangKey,
    lang: {
      cards: { valueAllocation }
    }
  } = useLangContext();

  const { groupId } = useParams();

  const queryClient = useQueryClient();

  const [
    { impactedUsers, totalToAdd, totalToRemove },
    chargeCardsOperationsMap,
    currentStep,
    operationsSummary,
    handleChangeFilters,
    handleChangeCurrentStep,
    handleGetOperationsList,
    handleClearStoreValues,
    handleChangeChargeCardsMassOperation
  ] = useChargeCardsContext(
    useShallow(state => [
      state.operationsSummary,
      state.chargeCardsOperationsMap,
      state.currentStep,
      state.operationsSummary,
      state.handleChangeFilters,
      state.handleChangeCurrentStep,
      state.handleGetOperationsList,
      state.handleClearValues,
      state.handleChangeChargeCardsMassOperation
    ])
  );

  const unallocatedBalanceCache = queryClient.getQueryData<IUnallocatedBalance>(
    [
      BalanceQueryKeys.GET_UNALLOCATED_BALANCE,
      { ...(groupId ? { cardGroupId: groupId } : { cardGroupId: null }) }
    ]
  );

  const handleOpenModal = useModalContext(state => state.handleOpenModal);

  const {
    sendModifiedBalanceAllocationItemsToCache,
    isSendingModifiedBalanceAllocationItemsToCache
  } = useSendModifiedBalanceAllocationItemsToCache({
    onAfterSuccess: () => {
      queryClient.setQueryData<IModifiedBalanceAllocationItem[]>(
        [BalanceQueryKeys.GET_CACHED_MODIFIED_BALANCE_ALLOCATION_ITEMS],
        handleGetOperationsList()
      );

      handleChangeCurrentStep(ChargeCardsSteps.CONFIRM_OPERATIONS);
    }
  });

  const {
    transferBalanceAllocationItems,
    isTransferringBalanceAllocationItems
  } = useTransferBalanceAllocationItems();

  const disabled = impactedUsers === 0;

  function handleContinueToConfirmationStep(): void {
    // Verifica se o valor final de alguma conta ficará negativo
    let foundNegativeFinalValue = false;

    chargeCardsOperationsMap.forEach(card => {
      if (
        (card.currentValue || card.currentValue === 0) &&
        card.operationType === 'DEBIT' &&
        card.currentValue - card.value < 0
      ) {
        toast.error(
          Lang.putValuesInString(
            valueAllocation.accounts_with_negative_balance[currentLangKey],
            {
              name: card.name
            }
          )
        );

        foundNegativeFinalValue = true;
      }
    });

    if (foundNegativeFinalValue) return;

    // Verifica se o valor não alocado futuro ficará negativo
    if (
      (unallocatedBalanceCache?.amount ||
        unallocatedBalanceCache?.amount === 0) &&
      unallocatedBalanceCache.amount -
        operationsSummary.totalToAdd +
        operationsSummary.totalToRemove <
        0
    ) {
      toast.error(
        valueAllocation.cant_continue_negative_balance[currentLangKey]
      );
      return;
    }

    sendModifiedBalanceAllocationItemsToCache({
      balanceAllocationItems: handleGetOperationsList()
    });
  }

  function handleConfirmAndTransferValues(): void {
    const operations = Array.from(chargeCardsOperationsMap.values()).map(
      item => ({
        balanceAccountId: item.balanceId,
        amount: item.value,
        type: item.operationType
      })
    );

    transferBalanceAllocationItems(
      {
        balanceAllocationItems: operations
      },
      {
        onSuccess(data) {
          setTransferredData(data);

          deleteModifiedBalanceAllocationItemsFromCache(null);
        }
      }
    );
  }

  function handleClearValues(): void {
    handleOpenModal('confirmation');
  }

  function handleGoBackToChargeCardsStep(): void {
    handleChangeCurrentStep(ChargeCardsSteps.CHARGE_CARDS);

    handleChangeChargeCardsMassOperation({
      operationType: undefined,
      value: 0
    });
  }

  const [transferredData, setTransferredData] =
    useState<ITransferredBalanceAllocationItems>();

  function handleUpdateDomainCache(): void {
    if (!transferredData) return;

    transferredData.success.forEach(item => {
      const cachedAccountBalanceAmount =
        queryClient.getQueryData<IAccountBalanceAmount>([
          AccountQueryKeys.GET_ACCOUNT_BALANCE_AMOUNT,
          item.balanceAccountId
        ]);

      if (cachedAccountBalanceAmount) {
        queryClient.setQueryData<IAccountBalanceAmount>(
          [AccountQueryKeys.GET_ACCOUNT_BALANCE_AMOUNT, item.balanceAccountId],
          oldData => ({
            amount:
              item.type === 'CREDIT'
                ? (oldData?.amount as number) + item.amount
                : (oldData?.amount as number) - item.amount
          })
        );
      }
    });

    const sumCreditsValues =
      transferredData.success
        .filter(item => item.type === 'CREDIT')
        .reduce((acc, item) => acc + item.amount, 0) ?? 0;

    const sumDebitsValues =
      transferredData.success
        .filter(item => item.type === 'DEBIT')
        .reduce((acc, item) => acc + item.amount, 0) ?? 0;

    const modifiedValue = sumCreditsValues - sumDebitsValues;

    queryClient.setQueryData<IUnallocatedBalance>(
      [
        BalanceQueryKeys.GET_UNALLOCATED_BALANCE,
        { ...(groupId ? { cardGroupId: groupId } : { cardGroupId: null }) }
      ],
      oldData => ({
        amount: (oldData?.amount as number) - modifiedValue
      })
    );

    queryClient.setQueryData<IAllocatedBalance>(
      [
        BalanceQueryKeys.GET_ALLOCATED_BALANCE,
        { ...(groupId ? { cardGroupId: groupId } : { cardGroupId: null }) }
      ],
      oldData => ({
        amount: (oldData?.amount as number) + modifiedValue,
        lastUpdateDate: format(new Date(), 'dd/MM/yyyy'),
        lastUpdateTime: format(new Date(), "HH'h':mm'm':ss's'")
      })
    );
  }

  const {
    deleteModifiedBalanceAllocationItemsFromCache,
    isDeletingModifiedBalanceAllocationItemsFromCache
  } = useDeleteModifiedBalanceAllocationItemsFromCache({
    onAfterSuccess: () => {
      handleClearStoreValues();

      handleChangeFilters({
        cardType: undefined,
        isCardTypeVisible: undefined
      });

      handleOpenModal('valueAllocationConfirmSummary');

      handleUpdateDomainCache();

      handleChangeCurrentStep(ChargeCardsSteps.CHARGE_CARDS);
    }
  });

  return {
    totalToAdd,
    totalToRemove,
    disabled,
    impactedUsers,
    currentStep,
    isSendingModifiedBalanceAllocationItemsToCache,
    isTransferringBalanceAllocationItems,
    isDeletingModifiedBalanceAllocationItemsFromCache,
    transferredData,
    handleContinueToConfirmationStep,
    handleConfirmAndTransferValues,
    handleClearValues,
    handleGoBackToChargeCardsStep
  };
}
