import styled from 'styled-components';

export const RouteContainer = styled.div`
  padding-bottom: 0.8rem;
`;

export const AddPlaceButtonContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;
