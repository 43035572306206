import { api } from 'data/config';
import {
  type IApprovalFlows,
  type IGetApprovalFlowsPayload
} from 'data/modules/approvalFlows';
import { type IDefaultResponse } from 'data/modules/global';

import { PaginationMapper } from 'shared/mappers';

class ApprovalFlowsService {
  async getApprovalFlows(
    payload: IGetApprovalFlowsPayload
  ): Promise<IApprovalFlows[]> {
    const {
      data: { data }
    } = await api.get<IDefaultResponse<IApprovalFlows[]>>('/approval-flows', {
      params: PaginationMapper.toPersistence(payload)
    });

    return data;
  }
}

export default new ApprovalFlowsService();
