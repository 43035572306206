import { useQueryClient } from '@tanstack/react-query';

import { useQueryCache } from 'data/cache';
import {
  CardGroupsQueryKeys,
  CardGroupsService,
  type IUseGetUsersWithoutCardGroup,
  type IUseGetUsersWithoutCardGroupParams
} from 'data/modules/cards/cardGroups';

export function useGetUsersWithoutCardGroup({
  params,
  enabled
}: IUseGetUsersWithoutCardGroupParams): IUseGetUsersWithoutCardGroup {
  const queryClient = useQueryClient();

  const {
    data: paginatedUsersWithoutCardGroup,
    isFetching: isFetchingUsersWithoutCardGroup
  } = useQueryCache({
    queryKey: [CardGroupsQueryKeys.GET_USERS_WITHOUT_CARD_GROUP, params],
    enabled: enabled ?? true,
    queryFn: async () =>
      await CardGroupsService.getUsersWithoutCardGroup(params)
  });

  async function prefetchGetUsersWithoutCardGroup(): Promise<void> {
    await queryClient.prefetchQuery({
      queryKey: [CardGroupsQueryKeys.GET_USERS_WITHOUT_CARD_GROUP, params],
      queryFn: async () =>
        await CardGroupsService.getUsersWithoutCardGroup(params)
    });
  }

  return {
    paginatedUsersWithoutCardGroup,
    isFetchingUsersWithoutCardGroup,
    prefetchGetUsersWithoutCardGroup
  };
}
