import {
  type IPersistenceValidateDateLimitsByProjectPayload,
  type IPersistenceValidateDateLimitsByProjectResponse,
  type IValidateDateLimitsByProjectPayload,
  type IValidateDateLimitsByProjectResponse
} from 'data/modules/expenses/types/expenses.types';

class ValidateDateLimitsByProjectMapper {
  toPersistence(
    domain: IValidateDateLimitsByProjectPayload
  ): IPersistenceValidateDateLimitsByProjectPayload {
    const persistenceProjects = domain.projects.reduce<Record<string, number>>(
      (acc, project) => {
        acc[project.id] = project.percentage;
        return acc;
      },
      {}
    );

    return {
      date: domain.date,
      expenseUuid: domain.expenseUuid,
      projects: persistenceProjects
    };
  }

  toDomain(
    persistence: IPersistenceValidateDateLimitsByProjectResponse
  ): IValidateDateLimitsByProjectResponse {
    return {
      daysLimitAgo: persistence.days_limit_ago,
      daysLimitAhead: persistence.days_limit_ahead,
      success: persistence.success
    };
  }
}

export default new ValidateDateLimitsByProjectMapper();
