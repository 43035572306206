import { useEffect } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { useValueSolicitationContext } from 'data/contexts';

export function useBalanceCardSection(): void {
  const [selectedSolicitationIdsList, handleChangeRequestedAmount] =
    useValueSolicitationContext(
      useShallow(state => [
        state.selectedSolicitationIdsList,
        state.handleChangeRequestedAmount
      ])
    );

  useEffect(() => {
    handleChangeRequestedAmount(
      selectedSolicitationIdsList.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.requestedAmount;
      }, 0)
    );
  }, [selectedSolicitationIdsList, handleChangeRequestedAmount]);
}
