import { useObservationAttachments } from './useObservationAttachments';

import { type IObservationAttachmentsProps } from './ObservationAttachments.types';

import {
  AttachmentContainer,
  AttachmentItem,
  Caption,
  Container,
  Filename,
  InputFile,
  StyledClipIcon,
  StyledCloseIcon
} from './ObservationAttachments.styles';

export function ObservationAttachments({
  inputRef
}: IObservationAttachmentsProps): JSX.Element {
  const { error, handleChangeFiles, selectedFiles, handleDeleteFile } =
    useObservationAttachments({
      inputRef
    });

  return (
    <>
      <InputFile
        type='file'
        onChange={handleChangeFiles}
        ref={inputRef}
        accept='application/pdf,image/jpg, image/jpeg, image/png'
        multiple
      />

      {selectedFiles.length > 0 && (
        <Container>
          <AttachmentContainer>
            {selectedFiles.map((file, index) => (
              <AttachmentItem key={index}>
                <StyledClipIcon />
                <Filename>
                  {file instanceof File ? file.name : file.title}
                </Filename>
                <StyledCloseIcon
                  onClick={() => {
                    handleDeleteFile(index);
                  }}
                />
              </AttachmentItem>
            ))}
          </AttachmentContainer>

          {error && <Caption>{error}</Caption>}
        </Container>
      )}
    </>
  );
}
