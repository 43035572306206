import { useMovementsContext } from 'data/contexts';
import { useGetExpense } from 'data/modules/expenses';

import { type IUseTransactionDetailsModal } from './TransactionDetailsModal.types';

export function useTransactionDetailsModal(): IUseTransactionDetailsModal {
  const transactionDetails = useMovementsContext(
    state => state.transactionDetails
  );

  const { expense, isFetchingExpense, isErrorExpense } = useGetExpense({
    enabled:
      transactionDetails?.type !== 'TRANSFER_CREDIT' &&
      transactionDetails?.type !== 'TRANSFER_DEBIT' &&
      transactionDetails?.type !== 'CASH_WITHDRAWAL' &&
      transactionDetails?.isReversal === 'NOT',
    transactionId:
      transactionDetails?.type !== 'TRANSFER_CREDIT' &&
      transactionDetails?.type !== 'TRANSFER_DEBIT' &&
      transactionDetails?.isReversal === 'NOT' &&
      transactionDetails?.type !== 'CASH_WITHDRAWAL' &&
      transactionDetails !== null
        ? transactionDetails.transactionId
        : 'disabled'
  });

  const isNationalPurchase =
    !transactionDetails?.isInternational &&
    transactionDetails?.type !== 'TRANSFER_DEBIT' &&
    transactionDetails?.type !== 'TRANSFER_CREDIT' &&
    transactionDetails?.type !== 'CASH_WITHDRAWAL' &&
    transactionDetails?.isReversal === 'NOT';

  const isNationalReversal =
    !transactionDetails?.isInternational &&
    transactionDetails?.isReversal !== 'NOT';

  const isWithdraw = transactionDetails?.type === 'CASH_WITHDRAWAL';

  const isReversalWithdraw =
    transactionDetails?.type === 'CASH_WITHDRAWAL' &&
    transactionDetails.isReversal !== 'NOT';

  const isTransfer = !!(
    ((transactionDetails?.type === 'TRANSFER_DEBIT' ||
      transactionDetails?.type === 'TRANSFER_CREDIT') &&
      !!transactionDetails?.transferorName) ||
    transactionDetails?.paymentDescription.includes('Transf.')
  );

  const isInternationalReversal =
    !!transactionDetails?.isInternational &&
    transactionDetails.isReversal !== 'NOT';

  const isInternationalPurchase =
    !!transactionDetails?.isInternational &&
    transactionDetails.isReversal === 'NOT';

  const isFee =
    (transactionDetails?.type === 'TRANSFER_DEBIT' ||
      transactionDetails?.type === 'TRANSFER_CREDIT') &&
    transactionDetails.isReversal === 'NOT' &&
    !transactionDetails?.transferorName &&
    transactionDetails.debitCredit === 'DEBIT';

  return {
    isNationalPurchase,
    isNationalReversal,
    isWithdraw,
    isReversalWithdraw,
    isInternationalPurchase,
    isTransfer,
    isInternationalReversal,
    expense,
    isFetchingExpense,
    isErrorExpense,
    isFee
  };
}
