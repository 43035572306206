import {
  type ChangeEvent,
  type DragEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useController, useFormContext } from 'react-hook-form';

import {
  type IUseDragAndDropFileControlled,
  type IUseDragAndDropFileControlledParams
} from './DragAndDropFileControlled.types';

export function useDragAndDropFileControlled({
  acceptedFileTypes,
  name
}: IUseDragAndDropFileControlledParams): IUseDragAndDropFileControlled {
  const { control } = useFormContext();

  const {
    field: { value: selectedFile, onChange: onChangeFile },
    fieldState: { error }
  } = useController({
    name,
    control
  });

  const [blobImageFile, setBlobImageFile] = useState<
    string | ArrayBuffer | null | undefined
  >(null);
  const [uploadError, setUploadError] = useState(false);
  const [sizeError, setSizeError] = useState(false);

  function isUrlImage(url: string): boolean {
    return /\.(jpg|jpeg|png)$/.test(url);
  }

  useEffect(() => {
    const currentFile: File | string | null = selectedFile;

    if (currentFile !== null && currentFile instanceof File) {
      if (currentFile.type.toLowerCase().includes('image')) {
        const fileReader = new FileReader();
        const isCancel = false;

        fileReader.onload = e => {
          const result: string | ArrayBuffer | null | undefined =
            e.target?.result;

          if (result && !isCancel) {
            setBlobImageFile(result);
          }
        };

        fileReader.readAsDataURL(currentFile);
        return;
      }

      setBlobImageFile(URL.createObjectURL(currentFile));
      return;
    }

    if (
      currentFile !== null &&
      typeof currentFile === 'string' &&
      currentFile !== ''
    ) {
      setBlobImageFile(currentFile);
      return;
    }

    setBlobImageFile(null);
  }, [selectedFile]);

  const [dragInsideElementActive, setDragInsideElementActive] = useState(false);
  const [dragDocumentActive, setDragDocumentActive] = useState(false);

  const [isImageEnlarged, setIsImageEnlarged] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  function handleDragInsideElement(e: DragEvent<HTMLElement>): void {
    e.preventDefault();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragInsideElementActive(true);
      return;
    }

    if (e.type === 'dragleave') {
      setDragInsideElementActive(false);
    }
  }

  const selectedFileIsImage =
    (selectedFile instanceof File &&
      selectedFile?.type.toLowerCase().includes('image')) ||
    (typeof selectedFile === 'string' && isUrlImage(selectedFile));

  function updateSelectedFile(file: File): void {
    const extension = file.name.split('.').pop();
    const size = Math.round(+file.size / 1024) / 1000;

    if (size > 16) {
      setSizeError(true);
      onChangeFile(null);
      return;
    }

    if (
      extension === undefined ||
      !acceptedFileTypes.includes(extension.toLowerCase())
    ) {
      onChangeFile(null);
      setUploadError(true);
      return;
    }

    onChangeFile(file);
    setUploadError(false);
  }

  function handleOpenFileOrEnlargeImage(): void {
    if (selectedFileIsImage) {
      setIsImageEnlarged(true);
      return;
    }

    window.open(blobImageFile as string, '_blank');
  }

  function handleDeleteFile(): void {
    inputRef.current?.value && (inputRef.current.value = '');
    onChangeFile(null);
    setUploadError(false);
  }

  function handleDropFile(e: DragEvent<HTMLElement>): void {
    e.preventDefault();
    e.stopPropagation();
    setDragInsideElementActive(false);
    setDragDocumentActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];

      updateSelectedFile(file);
    }
  }

  function handleChangeFile(e: ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      updateSelectedFile(file);
    }
  }

  useEffect(() => {
    function handleDocumentDrag(e: Event): void {
      e.preventDefault();
      e.stopPropagation();

      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragDocumentActive(true);
        return;
      }

      const completeEvent = e as unknown as DragEvent<HTMLElement>;
      if (
        e.type === 'dragleave' &&
        (completeEvent.clientX <= 0 ||
          completeEvent.clientY <= 0 ||
          completeEvent.clientX >= window.innerWidth ||
          completeEvent.clientY >= window.innerHeight)
      ) {
        setDragDocumentActive(false);
      }
    }

    function handleDocumentDrop(e: Event): void {
      e.preventDefault();
      e.stopPropagation();
      setDragDocumentActive(false);
    }

    window.addEventListener('dragenter', handleDocumentDrag);
    window.addEventListener('dragover', handleDocumentDrag);
    window.addEventListener('dragleave', handleDocumentDrag);
    window.addEventListener('drop', handleDocumentDrop);

    return () => {
      window.removeEventListener('dragenter', handleDocumentDrag);
      window.removeEventListener('dragleave', handleDocumentDrag);
      window.removeEventListener('dragover', handleDocumentDrag);
      window.removeEventListener('drop', handleDocumentDrop);
    };
  }, []);

  const hookFormError = error?.message ?? '';
  const hasError = uploadError || hookFormError !== '';

  const fileName = useMemo(() => {
    if (selectedFile !== null && typeof selectedFile === 'string') {
      return selectedFile.split('/').pop() ?? '';
    }

    if (selectedFile !== null && selectedFile instanceof File) {
      return selectedFile.name;
    }

    return '';
  }, [selectedFile]);

  return {
    dragDocumentActive,
    dragInsideElementActive,
    handleChangeFile,
    handleDragInsideElement,
    handleDropFile,
    uploadError,
    fileName,
    blobImageFile,
    selectedFileIsImage,
    isImageEnlarged,
    setIsImageEnlarged,
    handleDeleteFile,
    handleOpenFileOrEnlargeImage,
    inputRef,
    selectedFile,
    hookFormError,
    hasError: hasError || sizeError,
    sizeError
  };
}
