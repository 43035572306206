enum FuelMutationKeys {
  CREATE_VEHICLE = 'CREATE_VEHICLE',
  UPDATE_VEHICLE = 'UPDATE_VEHICLE',
  LINK_MEMBERS_TO_VEHICLE = 'LINK_MEMBERS_TO_VEHICLE',
  UNLINK_MEMBERS_FROM_VEHICLE = 'UNLINK_MEMBERS_FROM_VEHICLE',
  DELETE_VEHICLE = 'DELETE_VEHICLE',
  CHANGE_EXPENSE_TYPE = 'CHANGE_EXPENSE_TYPE',
  UPDATE_FUEL_POLICIES = 'UPDATE_FUEL_POLICIES'
}

enum FuelQueryKeys {
  GET_VEHICLE = 'GET_VEHICLE',
  GET_TYPES_OF_VEHICLES = 'GET_TYPES_OF_VEHICLES',
  GET_TYPES_OF_FUELS = 'GET_TYPES_OF_FUELS',
  GET_TYPES_OF_OWNERS = 'GET_TYPES_OF_OWNERS',
  GET_MEMBERS = 'GET_MEMBERS',
  GET_COMPANY_VEHICLES = 'GET_COMPANY_VEHICLES',
  GET_VEHICLES_BY_CURRENT_USER = 'GET_VEHICLES_BY_CURRENT_USER',
  GET_FUEL_POLICIES = 'GET_FUEL_POLICIES'
}

export { FuelMutationKeys, FuelQueryKeys };
