import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  gap: 1.6rem;
  display: flex;
  padding: 2.4rem 4rem;
  flex-direction: column;

  > h1 {
    text-align: left;
    font-size: 2rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 2.4rem;
  }
`;

export const Warning = styled.div`
  align-items: center;
  margin-top: 4.8rem;
  display: flex;
  gap: 0.8rem;

  p {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.tokens.brandColorPrimaryPure};
    font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
    font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;
