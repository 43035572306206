import { tokens } from 'ds';
import styled from 'styled-components';

import { type ExportButtonStylesType } from './ExportMovementsModal.types';
import { type AddPrefixToType } from 'ds/types';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${tokens.spacingInsetXs};
  max-width: 45rem;
  width: 100%;

  p {
    font-size: ${tokens.fontSizeXxs};
    color: ${tokens.neutralColorLowDark};
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${tokens.spacingInsetXs};

  span {
    font-size: ${tokens.fontSizeXxss};
    color: ${tokens.neutralColorLowMediumLight};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${tokens.spacingInsetXs};
`;

export const ExportButton = styled.button<
  AddPrefixToType<ExportButtonStylesType>
>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${tokens.spacingInsetNano};
  width: 5.8rem;
  height: 6.1rem;
  padding: ${tokens.spacingInsetNano};
  border: none;
  outline: none;
  border-radius: ${tokens.borderRadiusXs};
  background: ${({ $isActive }) =>
    $isActive ? tokens.brandColorSecondaryPure : tokens.neutralColorHighMedium};

  span {
    font-size: 1.1rem;
    color: ${({ $isActive }) =>
      $isActive ? tokens.neutralColorHighPure : tokens.neutralColorLowDark};
    font-weight: ${tokens.fontWeightSemibold};
  }

  .icon {
    width: 2.4rem;
    height: 2.4rem;
    color: ${({ $isActive }) =>
      $isActive ? tokens.neutralColorHighPure : tokens.neutralColorLowDark};
  }
`;
