import { faker } from '@faker-js/faker';

import { type IBalancesAndAccount } from 'data/modules/cards/balance';

import { MockApi } from 'shared/utils/global';

export async function getBalancesAndAccountMock(): Promise<IBalancesAndAccount> {
  const balances: IBalancesAndAccount = {
    account: {
      id: faker.string.uuid(),
      status: faker.helpers.arrayElement(['active', 'inactive', 'blocked']),
      description: faker.lorem.sentence()
    },
    balances: [
      {
        id: faker.string.uuid(),
        balances: parseFloat(faker.finance.amount()),
        inUse: faker.datatype.boolean(),
        description: faker.lorem.sentence()
      }
    ]
  };

  await MockApi.sleep(2000);

  return balances;
}
