import { DefaultTable, SkeletonLoader } from 'ds';

import { type IUserAnalysisSummarySkeletonProps } from './UserAnalysisSummarySkeleton.types';

export function UserAnalysisSummarySkeleton({
  rows
}: IUserAnalysisSummarySkeletonProps): JSX.Element {
  return (
    <>
      {new Array(rows).fill(0).map((row, idx) => (
        <DefaultTable.Tr key={`row-${idx}`}>
          <DefaultTable.Td>
            <SkeletonLoader />
          </DefaultTable.Td>

          <DefaultTable.Td>
            <SkeletonLoader />
          </DefaultTable.Td>

          <DefaultTable.Td>
            <SkeletonLoader />
          </DefaultTable.Td>

          <DefaultTable.Td>
            <SkeletonLoader />
          </DefaultTable.Td>

          <DefaultTable.Td>
            <SkeletonLoader />
          </DefaultTable.Td>

          <DefaultTable.Td>
            <SkeletonLoader />
          </DefaultTable.Td>

          <DefaultTable.Td>
            <SkeletonLoader />
          </DefaultTable.Td>
        </DefaultTable.Tr>
      ))}
    </>
  );
}
