import { useState } from 'react';

import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useModalContext } from 'data/contexts';
import {
  BusinessCardsMutationKeys,
  BusinessCardsService,
  type IAttachApprovalFlowsAndExpensePoliciesPayload,
  type ISetUpAccountabilityFieldsForm,
  type IUseSetUpAccountability,
  type IUseSetUpAccountabilityParams
} from 'data/modules/cards/businessCard';
import {
  CostCentersMutationKeys,
  CostCentersService,
  type IAttachCostCentersPayload
} from 'data/modules/costsCenters';
import {
  type IAttachProjectsPayload,
  ProjectsMutationKeys,
  ProjectsService
} from 'data/modules/projects';

import { useFormWithSchema } from 'shared/hooks/forms';

import { useSetUpAccountabilitySchema } from './schemas';

export function useSetUpAccountability({
  uuid
}: IUseSetUpAccountabilityParams): IUseSetUpAccountability {
  const {
    control,
    handleSubmit,
    reset: formReset,
    clearErrors,
    formState: { errors }
  } = useFormWithSchema(useSetUpAccountabilitySchema(), {
    defaultValues: {
      expensePolicies: undefined,
      approvalFlow: undefined,
      projects: [],
      costsCenters: []
    }
  });

  const { handleOpenModal } = useModalContext();

  const [allCostsCentersIds, setAllCostsCentersIds] = useState<number[]>([]);
  const [allProjectsIds, setAllProjectsIds] = useState<number[]>([]);

  const {
    mutate: costsCentersMutation,
    isLoading: loadingCostsCentersMutation
  } = useMutationCache({
    mutationKey: [CostCentersMutationKeys.ATTACH_COST_CENTERS_TO_BUSINESS_CARD],
    mutationFn: async (payload: IAttachCostCentersPayload) =>
      await CostCentersService.attachCostCenters(payload),
    onSuccess: () => {
      formReset({
        expensePolicies: undefined,
        approvalFlow: undefined,
        projects: [],
        costsCenters: []
      });
      setAllCostsCentersIds([]);
      handleOpenModal('teamMember');
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  const { mutate: projectsMutation, isLoading: loadingProjectsMutation } =
    useMutationCache({
      mutationKey: [ProjectsMutationKeys.CREATE_PROJECTS],
      mutationFn: async (payload: IAttachProjectsPayload) =>
        await ProjectsService.attachProjects(payload),
      onSuccess: () => {
        formReset({
          expensePolicies: undefined,
          approvalFlow: undefined,
          projects: [],
          costsCenters: []
        });
        setAllProjectsIds([]);
        handleOpenModal('teamMember');
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    });

  const {
    mutate: approvalFlowsAndExpensePoliciesMutation,
    isLoading: loadingApprovalFlowsAndExpensePoliciesMutation
  } = useMutationCache({
    mutationKey: [
      BusinessCardsMutationKeys.ATTACH_APPROVAL_FLOWS_AND_EXPENSES_POLICIES
    ],
    mutationFn: async (
      payload: IAttachApprovalFlowsAndExpensePoliciesPayload
    ) =>
      await BusinessCardsService.attachApprovalFlowsAndExpensePolicies(payload),
    onSuccess: () => {
      formReset({
        expensePolicies: undefined,
        approvalFlow: undefined,
        projects: [],
        costsCenters: []
      });
      handleOpenModal('teamMember');
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function onSubmit(data: ISetUpAccountabilityFieldsForm): void {
    if (
      data.costsCenters &&
      !data.costsCenters.length &&
      data.projects &&
      !data.projects.length &&
      !data.approvalFlow &&
      !data.expensePolicies
    ) {
      handleOpenModal('teamMember');
    }

    if (
      data.costsCenters &&
      (data.costsCenters.length > 0 || allCostsCentersIds.length > 0)
    ) {
      costsCentersMutation({
        uuid,
        costsCenters: !data.costsCenters.length
          ? allCostsCentersIds
          : data.costsCenters
      });
    }

    if (
      data.projects &&
      (data.projects.length > 0 || allProjectsIds.length > 0)
    ) {
      projectsMutation({
        uuid,
        projects: !data.projects.length ? allProjectsIds : data.projects
      });
    }

    if (data.approvalFlow ?? data.expensePolicies) {
      approvalFlowsAndExpensePoliciesMutation({
        uuid,
        approvalFlowId: data.approvalFlow ?? null,
        expensePolicyId: data.expensePolicies ?? null
      });
    }

    formReset({
      expensePolicies: undefined,
      approvalFlow: undefined,
      projects: [],
      costsCenters: []
    });
    setAllProjectsIds([]);
    setAllCostsCentersIds([]);
  }

  function handleSetValueCostsCentersOrProjects(
    values: number[],
    type: 'projects' | 'costsCenters'
  ): void {
    if (type === 'costsCenters') {
      formReset(formValues => ({ ...formValues, costsCenters: [] }));
      setAllCostsCentersIds((old: number[]) => (old.length > 0 ? [] : values));
    }
    if (type === 'projects') {
      formReset(formValues => ({ ...formValues, projects: [] }));
      setAllProjectsIds((old: number[]) => (old.length > 0 ? [] : values));
    }
  }

  return {
    control,
    errors,
    isLoading:
      loadingCostsCentersMutation ||
      loadingProjectsMutation ||
      loadingApprovalFlowsAndExpensePoliciesMutation,
    handleSubmit: handleSubmit(onSubmit),
    clearErrors,
    reset: formReset,
    disabledSelectCostsCenters: allCostsCentersIds.length > 0,
    disabledSelectProjects: allProjectsIds.length > 0,
    onChangeCheckbox: handleSetValueCostsCentersOrProjects
  };
}
