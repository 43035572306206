import {
  type IPersistenceRequestWithdrawal,
  type IRequestWithdrawalPayload
} from 'data/modules/cards/balance';

import { Currency, Mask } from 'shared/utils/format';

class RequestWithdrawalMapper {
  toPersistence(
    domainRequestWithdrawal: IRequestWithdrawalPayload
  ): IPersistenceRequestWithdrawal {
    return {
      account: domainRequestWithdrawal.account,
      agency: domainRequestWithdrawal.agency,
      amount: Currency.parseToFloat(domainRequestWithdrawal.amount),
      bank: domainRequestWithdrawal.bank,
      document_number: Mask.remove(domainRequestWithdrawal.documentNumber),
      pix_key: domainRequestWithdrawal.pixKey ?? '',
      reason: domainRequestWithdrawal.reason ?? ''
    };
  }
}

export default new RequestWithdrawalMapper();
