import { useAuthContext, useLangContext } from 'data/contexts';

import { RectButton } from 'presentation/components/base/Button';
import { InputSearchFilter } from 'presentation/components/global/InputSearchFilter';
import { Title } from 'presentation/components/global/Title';
import {
  DateFilter,
  MovementFilter
} from 'presentation/pages/cards/Statement/components/Filters';
import {
  CardActions,
  CardAndValues
} from 'presentation/pages/cards/Statement/UserStatement/components/Sidebar';

import { useUserStatementSidebar } from './useUserStatementSidebar';

import { type IUserStatementSidebarProps } from './UserStatementSidebar.types';

import {
  ActionsButtonsContainer,
  ButtonContainer,
  Container,
  CoreContainer,
  FiltersContent
} from './UserStatementSidebar.styles';

export function UserStatementSidebar({
  initialDateFilter,
  onFilter
}: IUserStatementSidebarProps): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const {
    filters: {
      dateFilter,
      searchInputRef,
      selectedMovementType,
      setDateFilter,
      setSelectedMovementType
    },
    errors: { dateFilterFromError, dateFilterToError },
    handleFilter
  } = useUserStatementSidebar({
    initialDateFilter,
    onFilter
  });

  const user = useAuthContext(state => state.user);

  return (
    <Container>
      <CoreContainer>
        <Title
          backgroundColor='alabaster'
          text={lang.cards.statement.sidebar.full_statement[currentLangKey]}
        />

        <CardAndValues />

        <CardActions />
        <Title
          backgroundColor='alabaster'
          text={lang.cards.statement.sidebar.filters[currentLangKey]}
        />
        <FiltersContent onSubmit={handleFilter}>
          <MovementFilter
            onChange={movementType => {
              setSelectedMovementType(movementType);
            }}
            selected={selectedMovementType}
          />

          <InputSearchFilter ref={searchInputRef} />

          <DateFilter
            from={dateFilter.from}
            to={dateFilter.to}
            onChangeFrom={date => {
              setDateFilter({
                ...dateFilter,
                from: date
              });
            }}
            onChangeTo={date => {
              setDateFilter({
                ...dateFilter,
                to: date
              });
            }}
            errors={{
              from: dateFilterFromError,
              to: dateFilterToError
            }}
          />

          <ActionsButtonsContainer>
            <RectButton
              color='primary'
              size='xl'
              disabled={!!(dateFilterFromError || dateFilterToError)}
              type='submit'
            >
              {lang.cards.statement.sidebar.action_filter[currentLangKey]}
            </RectButton>
          </ActionsButtonsContainer>
        </FiltersContent>
      </CoreContainer>

      {user?.cards?.manager && (
        <ButtonContainer>
          <RectButton
            color='primary'
            size='xl'
            iconProps={{
              name: 'bank-card',
              size: 1.6,
              color: 'white',
              type: 'line'
            }}
            linkProps={{
              to: '/cartoes/gestao'
            }}
          >
            {lang.cards.statement.sidebar.view_card_management[currentLangKey]}
          </RectButton>
        </ButtonContainer>
      )}
    </Container>
  );
}
