/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useModalContext } from 'data/contexts';
import { AccountQueryKeys } from 'data/modules/cards/account';
import {
  CardGroupsQueryKeys,
  useAddCardsToGroup,
  useInfiniteUsersWithoutCardGroup
} from 'data/modules/cards/cardGroups';

import { useDebounce } from 'shared/hooks/global';
import { ApiMessage } from 'shared/utils/global';

import { type IUseAddCardToGroupModal } from './AddCardToGroupModal.types';

export function useAddCardToGroupModal(): IUseAddCardToGroupModal {
  const { handleOpenModal } = useModalContext();
  const queryClient = useQueryClient();

  const [
    paginationUsers,
    selectedUsersWithoutCardGroup,
    selectedCardGroup,
    handleResetSelectedUsersWithoutCardGroup
  ] = useCardGroupsContext(
    useShallow(state => [
      state.paginationUsers,
      state.selectedUsersWithoutCardGroup,
      state.selectedCardGroup,
      state.handleResetSelectedUsersWithoutCardGroup
    ])
  );

  const debounceSearchUser = useDebounce(paginationUsers.userName, 650);

  const { addCardsToGroup, isAddingCardsToGroup } = useAddCardsToGroup();

  const {
    infiniteUsersWithoutCardGroup,
    isLoadingInfiniteUsersWithoutCardGroup,
    usersWithoutCardGroupPagination
  } = useInfiniteUsersWithoutCardGroup({
    params: {
      page: paginationUsers.page,
      limit: paginationUsers.limit,
      ...(debounceSearchUser && {
        userName: debounceSearchUser?.toLowerCase().trim()
      }),
      cardGroupId: selectedCardGroup?.id
    }
  });

  async function handleAddCardsToGroup(): Promise<void> {
    try {
      await addCardsToGroup({
        id: selectedCardGroup?.id as string,
        users: selectedUsersWithoutCardGroup
      });

      handleResetSelectedUsersWithoutCardGroup();
      handleOpenModal('groupCardDetails');

      queryClient.resetQueries({
        queryKey: [AccountQueryKeys.GET_ACCOUNTS]
      });

      queryClient.resetQueries({
        queryKey: [CardGroupsQueryKeys.GET_USERS_WITHOUT_CARD_GROUP]
      });

      queryClient.resetQueries({
        queryKey: [CardGroupsQueryKeys.GET_GROUP_USERS]
      });
    } catch (error: any) {
      toast.error(ApiMessage.error(error.response.data.errors?.[0].code));
    }
  }

  return {
    paginatedUsersWithoutCardGroup: infiniteUsersWithoutCardGroup,
    isFetchingUsersWithoutCardGroup: isLoadingInfiniteUsersWithoutCardGroup,
    isFetchingNextPage: usersWithoutCardGroupPagination.isFetchingNextPage,
    isAddingCardsToGroup,
    handleAddCardsToGroup,

    usersWithoutCardGroupPagination
  };
}
