import { useMutationCache } from 'data/cache';
import {
  CardGroupsMutationKeys,
  CardGroupsService,
  type ICreateGroupCardPayload,
  type IUseCreateCardGroup
} from 'data/modules/cards/cardGroups';

export function useCreateCardGroup(): IUseCreateCardGroup {
  const { mutateAsync, isPending } = useMutationCache({
    mutationKey: [CardGroupsMutationKeys.CREATE_CARD_GROUP],
    mutationFn: async (payload: ICreateGroupCardPayload) => {
      await CardGroupsService.createCardGroup(payload);
    }
  });

  return {
    isCreatingCardGroupName: isPending,
    createCardGroup: mutateAsync
  };
}
