import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type IListAndSeacrhStyleType } from './ListAndSearch.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};

  padding: ${tokens.spacingXs};
  background: ${tokens.neutralColorHighLight};
  border-radius: ${tokens.borderRadiusMd};
`;

export const ListContainer = styled.div`
  padding: ${tokens.spacingXs};
  background-color: ${tokens.deepColorWhite};
  border-radius: ${tokens.borderRadiusMd};
  max-height: 100%;
  border: 1px solid ${tokens.neutralColorLowLight};
`;

export const List = styled.div`
  overflow-y: auto;
  max-height: 30rem;

  &::-webkit-scrollbar {
    width: 0.8rem;
    height: 0;
  }
`;

export const ListItem = styled.div<AddPrefixToType<IListAndSeacrhStyleType>>`
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: ${tokens.spacingNano};

  > span {
    color: ${tokens.neutralColorLowDark};
    font-size: ${tokens.fontSizeXxs};
    line-height: 2.2rem;
    font-weight: ${tokens.fontWeightRegular};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      > span {
        color: ${tokens.neutralColorLowLightest};
      }
    `}
`;

export const ListItemLoading = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: ${tokens.neutralColorLowMedium};
  font-size: ${tokens.fontSizeXxss};
  line-height: 2.2rem;
  font-weight: ${tokens.fontWeightMedium};
`;

export const EmptyList = styled.div`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightMedium};
  line-height: ${tokens.spacingMd};
  letter-spacing: 0.75px;
  color: ${tokens.neutralColorLowDark};
  text-align: center;
  padding: ${tokens.spacingXxs};
`;
