import { tokens } from 'ds';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-weight: ${tokens.fontWeightRegular};
    font-size: ${tokens.fontSizeXxs};
    color: ${tokens.neutralColorLowDark};
    letter-spacing: 0.075rem;
    line-height: ${tokens.spacingMd};
  }
`;
