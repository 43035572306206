import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { type ICreateFuelExpenseSecondStepFieldsForm } from 'data/modules/expenses/types/expenses.types';

export function useSecondStepFormSchema(): ObjectSchema<ICreateFuelExpenseSecondStepFieldsForm> {
  const createManualExpenseSecondStepSchema: ObjectSchema<ICreateFuelExpenseSecondStepFieldsForm> =
    useMemo(() => {
      return yup.object({
        report: yup.string().defined()
      });
    }, []);

  return createManualExpenseSecondStepSchema;
}
