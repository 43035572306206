import { Currency } from 'shared/utils/format';

import {
  type TransactionType,
  type TransactionValuePropsType
} from './TransactionValue.types';

import {
  BrazilianContainer,
  InternationalContainer
} from './TransactionValue.styles';

export function TransactionValue<T extends TransactionType>(
  props: TransactionValuePropsType<T>
): JSX.Element {
  const { type } = props;

  if (type === 'international') {
    const { value, currencyCode, finalCurrencyCode, finalValue } = props;

    return (
      <InternationalContainer>
        <span>
          <small>{finalCurrencyCode}</small>
          <strong>{Currency.format(finalCurrencyCode, finalValue)}</strong>
        </span>

        <span>
          <small>{currencyCode}</small>
          <strong>{Currency.format(currencyCode, value)}</strong>
        </span>
      </InternationalContainer>
    );
  }

  const { value } = props;

  return (
    <BrazilianContainer>
      <small>BRL</small>
      <strong>{Currency.format('BRL', value)}</strong>
    </BrazilianContainer>
  );
}
