import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { type ISetUpAccountabilityFieldsForm } from 'data/modules/cards/businessCard';

export function useSetUpAccountabilitySchema(): ObjectSchema<ISetUpAccountabilityFieldsForm> {
  const setUpAccountabilitySchema: ObjectSchema<ISetUpAccountabilityFieldsForm> =
    useMemo(() => {
      return yup.object({
        costsCenters: yup.array(yup.number()),
        projects: yup.array(yup.number()),
        approvalFlow: yup.number().nullable(),
        expensePolicies: yup.number().nullable()
      });
    }, []);

  return setUpAccountabilitySchema;
}
