import {
  type ILinkOrUnlinkMembersFromVehiclePayload,
  type ILinkOrUnlinkMembersFromVehiclePayloadPersistence
} from 'data/modules/fuel';

class LinkOrUnlinkMembersFromVehicleMapper {
  toPersistence(
    payload: Omit<ILinkOrUnlinkMembersFromVehiclePayload, 'vehicleId'>
  ): ILinkOrUnlinkMembersFromVehiclePayloadPersistence {
    return {
      attach_users_ids: payload.membersIdsToAttach || [],
      detach_users_ids: payload.membersIdsToDetach || []
    };
  }
}

export default new LinkOrUnlinkMembersFromVehicleMapper();
