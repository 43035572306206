import { BadgeStatus } from 'ds';
import { MenuTabs } from 'ds/components/Tabs';
import { useShallow } from 'zustand/react/shallow';

import { useLangContext, useValueSolicitationContext } from 'data/contexts';

import { type IValueSolicitationTabControlProps } from './ValueSolicitationTabControl.types';

import {
  Container,
  TabItemContent
} from './ValueSolicitationTabControl.styles';

export function ValueSolicitationTabControl({
  quantitySolicitationByStatus,
  ...rest
}: IValueSolicitationTabControlProps): JSX.Element {
  const {
    lang: { cards },
    currentLangKey
  } = useLangContext();

  const [
    handleChangeListType,
    listType,
    handleChangeDateType,
    searchInput,
    startDate,
    endDate,
    totalItems
  ] = useValueSolicitationContext(
    useShallow(state => [
      state.handleChangeListType,
      state.listType,
      state.handleChangeDateType,
      state.searchInput,
      state.startDate,
      state.endDate,
      state.totalItems
    ])
  );

  return (
    <Container {...rest}>
      <MenuTabs.Container hasTrack>
        <MenuTabs.Item
          active={listType === 'ALL'}
          onClick={() => {
            handleChangeListType('ALL');
          }}
        >
          <TabItemContent>
            {cards.valueSolicitation.tab_control.all[currentLangKey]}

            <BadgeStatus type={listType === 'ALL' ? 'secondary' : 'general'}>
              {searchInput || (startDate && endDate)
                ? quantitySolicitationByStatus?.all && listType === 'ALL'
                  ? totalItems
                  : quantitySolicitationByStatus?.all
                : quantitySolicitationByStatus?.all}
            </BadgeStatus>
          </TabItemContent>
        </MenuTabs.Item>

        <MenuTabs.Item
          active={listType === 'SENT'}
          onClick={() => {
            handleChangeListType('SENT');
            handleChangeDateType('date_sent');
          }}
        >
          <TabItemContent>
            {cards.valueSolicitation.tab_control.pending[currentLangKey]}
            <BadgeStatus type={listType === 'SENT' ? 'warning' : 'general'}>
              {searchInput || (startDate && endDate)
                ? quantitySolicitationByStatus?.sent && listType === 'SENT'
                  ? totalItems
                  : quantitySolicitationByStatus?.sent
                : quantitySolicitationByStatus?.sent}
            </BadgeStatus>
          </TabItemContent>
        </MenuTabs.Item>

        <MenuTabs.Item
          active={listType === 'APPROVED'}
          onClick={() => {
            handleChangeListType('APPROVED');
            handleChangeDateType('date_approved');
          }}
        >
          <TabItemContent>
            {cards.valueSolicitation.tab_control.approved[currentLangKey]}

            <BadgeStatus
              type={listType === 'APPROVED' ? 'secondary' : 'general'}
            >
              {searchInput || (startDate && endDate)
                ? quantitySolicitationByStatus?.approved &&
                  listType === 'APPROVED'
                  ? totalItems
                  : quantitySolicitationByStatus?.approved
                : quantitySolicitationByStatus?.approved}
            </BadgeStatus>
          </TabItemContent>
        </MenuTabs.Item>

        <MenuTabs.Item
          active={listType === 'REJECTED'}
          onClick={() => {
            handleChangeListType('REJECTED');
          }}
        >
          <TabItemContent>
            {cards.valueSolicitation.tab_control.disapprove[currentLangKey]}
            <BadgeStatus
              type={listType === 'REJECTED' ? 'secondary' : 'general'}
            >
              {searchInput
                ? quantitySolicitationByStatus?.rejected &&
                  listType === 'REJECTED'
                  ? totalItems
                  : quantitySolicitationByStatus?.rejected
                : quantitySolicitationByStatus?.rejected}
            </BadgeStatus>
          </TabItemContent>
        </MenuTabs.Item>
      </MenuTabs.Container>
    </Container>
  );
}
