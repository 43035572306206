import { DefaultTableActionsCell } from './DefaultTableActionsCell/DefaultTableActionsCell.component';
import { DefaultTableBody } from './DefaultTableBody/DefaultTableBody.component';
import { DefaultTableBodyCell } from './DefaultTableBodyCell/DefaultTableBodyCell.component';
import { DefaultTableContainer } from './DefaultTableContainer/DefaultTableContainer.component';
import { DefaultTableFooter } from './DefaultTableFooter/DefaultTableFooter.component';
import { DefaultTableHead } from './DefaultTableHead/DefaultTableHead.component';
import { DefaultTableHeadCell } from './DefaultTableHeadCell/DefaultTableHeadCell.component';
import { DefaultTableRow } from './DefaultTableRow/DefaultTableRow.component';

export const DefaultTable = {
  Actions: DefaultTableActionsCell,
  Tbody: DefaultTableBody,
  Td: DefaultTableBodyCell,
  Tfoot: DefaultTableFooter,
  Th: DefaultTableHeadCell,
  Tr: DefaultTableRow,
  Thead: DefaultTableHead,
  Container: DefaultTableContainer
};
