import { enUS } from 'date-fns/locale/en-US';
import { es } from 'date-fns/locale/es';
import { ptBR } from 'date-fns/locale/pt-BR';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue
} from 'react-hook-form';
import MaskedTextInput from 'react-text-mask';

import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { ErrorMessage } from 'presentation/components/base/Input';

import {
  type IDatePickerProps,
  type OnChangeDatePickerType
} from './DatePicker.types';

import { Container, InputWrapper, Label } from './DatePicker.styles';

registerLocale('en', enUS);
registerLocale('es', es);
registerLocale('pt', ptBR);

export function DatePicker<T extends FieldValues>({
  error,
  label,
  disabled,
  control,
  onChange,
  id,
  ...rest
}: IDatePickerProps<T>): JSX.Element {
  const [currentLangKey] = useLangContext(state => [state.currentLangKey]);

  const datePickerToRender = (
    value: Date | null,
    onChange: OnChangeDatePickerType
  ): JSX.Element => (
    <ReactDatePicker
      autoComplete='off'
      calendarClassName='customDatePicker'
      id={id}
      locale={currentLangKey}
      disabled={disabled}
      onChange={onChange}
      selected={value}
      popperPlacement='bottom-end'
      customInput={
        <MaskedTextInput
          type='text'
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        />
      }
      {...rest}
    />
  );

  return (
    <Container>
      <Label
        $error={error}
        htmlFor={id}
      >
        {label}
      </Label>

      <InputWrapper
        $error={error}
        $disabled={disabled}
      >
        {control ? (
          <Controller
            name={id as Path<T>}
            control={control}
            defaultValue={null as PathValue<T, Path<T>>}
            render={({ field: { onChange, value } }) =>
              datePickerToRender(value, onChange as OnChangeDatePickerType)
            }
          />
        ) : (
          datePickerToRender(
            rest.selected as Date | null,
            onChange as OnChangeDatePickerType
          )
        )}
        <label htmlFor={id}>
          <BaseIcon
            name='arrow-down-s'
            color='neutral'
            size={1.6}
          />
        </label>
      </InputWrapper>

      {error && <ErrorMessage message={error} />}
    </Container>
  );
}
