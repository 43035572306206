import { useQueryCache } from 'data/cache';
import {
  CardGroupsQueryKeys,
  CardGroupsService,
  type IUseCheckIfCardsCanBeAdded,
  type IUseCheckIfCardsCanBeAddedParams
} from 'data/modules/cards/cardGroups';

export function useCheckIfCardsCanBeAdded({
  usersIds,
  enabled
}: IUseCheckIfCardsCanBeAddedParams): IUseCheckIfCardsCanBeAdded {
  const { data, isFetching, isSuccess, isError } = useQueryCache({
    queryKey: [CardGroupsQueryKeys.CHECK_IF_CARDS_CAN_BE_ADDED],
    queryFn: async () => {
      return await CardGroupsService.checkIfCardsCanBeAdded({ usersIds });
    },
    enabled
  });

  return {
    isCheckingIfCardsCanBeAdded: isFetching,
    isCheckIfCardsCanBeAddedSuccess: isSuccess,
    isCheckIfCardsCanBeAddedError: isError,
    cardsCanBeAdd: data
  };
}
