import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUpdateAnswerableUserFieldsForm,
  type IUpdateAnswerableUserPayload,
  type IUseUpdateAnswerableUser,
  useIncrementCompanyStep
} from 'data/modules/cards/companyOnboarding';

import { useFormWithSchema } from 'shared/hooks/forms';
import { useHandleQueryCache } from 'shared/hooks/global';
import OnboardingUtil from 'shared/utils/cards/Onboarding.util';

import { useUpdateAnswerableUserSchema } from './schemas';

export function useUpdateAnswerableUser(): IUseUpdateAnswerableUser {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useFormWithSchema(useUpdateAnswerableUserSchema());

  const { incrementStep, isIncrementingStep } = useIncrementCompanyStep();

  const { data: cachedCompanyOnboardingProgress, updateCache } =
    useHandleQueryCache({
      query: {
        query: async () => await CompanyOnboardingService.getProgress(),
        key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
      }
    });

  const currentStep = OnboardingUtil.getCurrentStep(location.pathname);

  const {
    mutate,
    isLoading: isUpdatingAnswerableUser,
    isError
  } = useMutationCache({
    mutationKey: [CompanyOnboardingMutationKeys.UPDATE_ANSWERABLE_USER],
    mutationFn: async (payload: IUpdateAnswerableUserPayload) =>
      await CompanyOnboardingService.updateAnswerableUser(payload),
    onSuccess: () => {
      incrementStep({
        uuidCompanyOnboarding:
          cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '',
        step: 1
      });

      if (currentStep === 'inicio') {
        updateCache({
          key: 'currentStep',
          newData: 1
        });
      }
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function onSubmit(payload: IUpdateAnswerableUserFieldsForm): void {
    mutate({
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '',
      ...payload
    });
  }

  return {
    control,
    isUpdatingAnswerableUser: isUpdatingAnswerableUser || isIncrementingStep,
    errors,
    isError,
    handleUpdateAnswerableUser: handleSubmit(onSubmit),
    setValue
  };
}
