import { faker } from '@faker-js/faker';

import { type IUserOnboardingProgress } from 'data/modules/cards/userOnboarding';

import { MockApi } from 'shared/utils/global';

export async function getUserOnboardingProgressMock(): Promise<IUserOnboardingProgress> {
  await MockApi.sleep(2000);

  return {
    onboardingUserId: faker.string.uuid(),
    userId: faker.string.uuid(),
    currentStep: faker.number.int({ min: 1, max: 4 }),
    createdAt: faker.date.past().toISOString(),
    updatedAt: faker.date.past().toISOString()
  };
}
