import { yupResolver } from '@hookform/resolvers/yup';
import {
  type Resolver,
  useForm,
  type UseFormProps,
  type UseFormReturn
} from 'react-hook-form';
import { type ObjectSchema } from 'yup';

export function useFormWithSchema<T extends object>(
  schema: ObjectSchema<T>,
  useFormProps?: UseFormProps<T>
): UseFormReturn<T> {
  return useForm({
    ...useFormProps,
    resolver: yupResolver(schema) as unknown as Resolver<T>
  });
}
