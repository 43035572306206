import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useModalContext } from 'data/contexts';
import {
  CardGroupsQueryKeys,
  useRemoveManagerFromGroup
} from 'data/modules/cards/cardGroups';

import { ApiMessage } from 'shared/utils/global';

import { type IUseRemoveManagerFromGroupModal } from './RemoveManagerFromGroupModal.types';

export function useRemoveManagerFromGroupModal(): IUseRemoveManagerFromGroupModal {
  const [
    managerBeingExcluded,
    selectedCardGroup,
    groupManagerSearchTerm,
    { managersPagination },
    filters,
    ordenationType,
    pagination
  ] = useCardGroupsContext(
    useShallow(state => [
      state.managerBeingExcluded,
      state.selectedCardGroup,
      state.groupManagerSearchTerm,
      state.paginationModalDetails,
      state.filters,
      state.ordenationType,
      state.pagination
    ])
  );

  const queryClient = useQueryClient();

  const { handleCloseModal, handleOpenModal } = useModalContext();

  const { isRemovingManagerFromGroup, removeManagerFromGroup } =
    useRemoveManagerFromGroup();

  async function handleRemoveManagerFromGroup(): Promise<void> {
    try {
      await removeManagerFromGroup({
        id: selectedCardGroup?.id as string,
        managers: [managerBeingExcluded?.id as string]
      });

      queryClient.invalidateQueries({
        queryKey: [
          CardGroupsQueryKeys.GET_GROUP_MANAGERS,
          {
            id: selectedCardGroup?.id as string,
            limit: managersPagination.limit,
            page: managersPagination.page,
            name: groupManagerSearchTerm.toLowerCase()
          }
        ]
      });

      queryClient.resetQueries({
        queryKey: [CardGroupsQueryKeys.GET_AVAILABLE_TO_BE_MANAGER]
      });

      queryClient.resetQueries({
        queryKey: [CardGroupsQueryKeys.GET_PARTIAL_MANAGERS]
      });

      if (filters.managers?.includes(managerBeingExcluded?.id || '')) {
        queryClient.invalidateQueries({
          queryKey: [
            CardGroupsQueryKeys.GET_CARD_GROUPS,
            {
              page: pagination.page,
              limit: pagination.limit,
              ...(filters?.name && {
                name: filters?.name.trim().toLowerCase()
              }),
              ...(filters?.managers && { managers: filters.managers }),
              ...(ordenationType && { ordenationType })
            }
          ]
        });
      }

      handleCloseModal();
      handleOpenModal('groupCardDetails');
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(ApiMessage.error(error.response?.data.errors?.[0].code));

        return;
      }

      toast.error(ApiMessage.error('default'));
    }
  }

  return {
    handleRemoveManagerFromGroup,
    isRemovingManagerFromGroup
  };
}
