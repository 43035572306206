import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 0 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: ${tokens.spacingMd};
  padding: ${tokens.spacingMd};
  border-radius: ${tokens.borderRadiusMd};
  background: ${tokens.neutralColorHighPure};
  border: 1px solid ${tokens.neutralColorHighDark};

  > svg:first-of-type {
    width: 4.4rem;
    height: 4.4rem;
    min-width: 4.4rem;
    min-height: 4.4rem;
    color: ${tokens.brandColorSecondaryPure};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;
    align-items: flex-start;

    > svg:last-of-type {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: grid;
  width: 100%;
  gap: ${tokens.spacingXs};

  & + svg {
    min-width: 3.2rem;
    min-height: 3.2rem;
    color: ${tokens.neutralColorLowLight};
  }
`;

export const Title = styled.strong`
  line-height: 3.2rem;
  letter-spacing: 0.1rem;
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightBold};
  color: ${tokens.brandColorSecondaryPure};
  font-family: ${tokens.fontFamilyPoppins};
`;

export const Description = styled.p`
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowMedium};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamilyPoppins};
`;
