import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.tokens.spacingXs};

  button {
    path {
      stroke: white;
    }
  }
`;

export const SectionTitle = styled.h1`
  font-size: ${({ theme }) => theme.tokens.fontSizeXxs};
  color: ${({ theme }) => theme.colors.neutralColorLowDark};
  letter-spacing: 0.075rem;
`;
