import { tokens } from 'ds';
import styled from 'styled-components';

import { TextLink } from './components/ImageCard/ImageCard.styles';

export const Container = styled.div`
  display: grid;
  row-gap: ${tokens.spacingLg};
  padding: ${tokens.spacingXl} ${tokens.spacingLg};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: ${tokens.spacingXs};
  }
`;

export const ButtonCardsContent = styled.div`
  display: grid;
  row-gap: ${tokens.spacingLg};
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media screen and (min-width: 1280px) {
    column-gap: ${tokens.spacingXs};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const ButtonCardWrapper = styled.div`
  display: grid;
  row-gap: ${tokens.spacingLg};
`;

export const ImageCardWrapper = styled.div`
  display: grid;
  transition: all 0.25s ease-in-out;
  border-radius: ${tokens.borderRadiusMd};
  background-color: ${tokens.neutralColorHighLight};

  &:hover {
    box-shadow:
      0 8px 16px 0 rgb(0 0 0 / 16%),
      2px 2px 4px 0 rgb(0 0 0 / 10%);

    ${TextLink} {
      color: ${tokens.brandColorSecondaryMedium};
    }
  }

  &:active {
    background-color: ${tokens.brandColorPrimaryLightest};
  }

  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const Content = styled.div`
  display: grid;
  row-gap: ${tokens.spacingLg};
`;

export const SectionTitle = styled.h3`
  line-height: 3.2rem;
  letter-spacing: 1px;
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightBold};
  text-shadow: 0 0 32px rgb(0 0 0 / 4%);
  font-family: ${tokens.fontFamilyPoppins};
  color: ${tokens.brandColorSecondaryMedium};
`;

export const ChannelsWrapper = styled.div`
  display: grid;
  row-gap: ${tokens.spacingMd};
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media screen and (min-width: 834px) {
    column-gap: ${tokens.spacingXs};
    grid-template-columns: repeat(3, minmax(0, 1fr));

    h3 {
      grid-column: span 3;
    }
  }
`;

export const ChannelIllustration = styled.img`
  width: 17.4rem;
`;
