import { useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';
import { useTranslation } from 'react-i18next';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import { type IErrorDefault } from 'data/contexts/modal/useModalContext.types';
import {
  type ApportionmentErrorType,
  ExpensesMutationKeys,
  ExpensesQueryKeys,
  ExpensesService,
  type IApportionmentPayload,
  type ICreateRouteExpenseFirstStepFieldsForm,
  type ICreateRouteExpensePayload,
  type ICreateRouteExpenseSecondStepFieldsForm,
  type IUpdateRouteExpensePayload,
  type IUseCreateRouteExpense,
  type IUseCreateRouteExpenseParams,
  useGetExpenseParameters
} from 'data/modules/expenses';
import { ExpensesValidate } from 'data/modules/expenses';
import { useGetPaymentMethods } from 'data/modules/paymentMethods';
import { ReportsQueryKeys } from 'data/modules/reports';

import { useFormWithSchema } from 'shared/hooks/forms';
import { CustomDate } from 'shared/utils/custom';
import { ErrorHandle } from 'shared/utils/errors';
import { Currency } from 'shared/utils/format';

import { useFirstStepFormSchema, useSecondStepFormSchema } from './schemas';

export function useCreateManualRouteExpense({
  onAfterSubmitFirstStep,
  onSuccessCreateRouteExpense,
  onSuccessUpdateRouteExpense,
  confirmedReportInformationBySelectedReport,
  expenseUuidToUpdate,
  requiredReceiptInManualRoute,
  onErrorCreateOrUpdateManualRouteExpense
}: IUseCreateRouteExpenseParams): IUseCreateRouteExpense {
  const { lang, currentLangKey } = useLangContext();

  const { expenseParameters, isLoadingExpenseParameters } =
    useGetExpenseParameters();

  const { t } = useTranslation('expenses');

  const queryClient = useQueryClient();

  const createManualRouteExpenseFirstStepData =
    useRef<ICreateRouteExpenseFirstStepFieldsForm | null>(null);

  const defaultCurrencyFromParameter =
    expenseParameters?.defaultCurrency.user ??
    expenseParameters?.defaultCurrency.company;

  const valuesToCreateNewManualRoute = {
    apportionment: [],
    costsCenter: undefined,
    currency: defaultCurrencyFromParameter
      ? String(defaultCurrencyFromParameter)
      : '',
    date: CustomDate.formatDateToIso(new Date()),
    mileage: '',
    mileagePaidValue: Currency.format(
      'BRL',
      expenseParameters?.amountPerKm ?? 0,
      false,
      4
    ),
    observation: '',
    receipt: null,
    refundable: false,
    description: '',
    paymentMethod: '',
    value: '',
    preRegisteredSection: ''
  } as ICreateRouteExpenseFirstStepFieldsForm;

  const firstStepMethods = useFormWithSchema(
    useFirstStepFormSchema({
      userAction: expenseUuidToUpdate ? 'update' : 'create'
    }),
    {
      defaultValues: {
        refundable: false,
        paymentMethod: '',
        receipt: null
      },
      values: expenseUuidToUpdate ? undefined : valuesToCreateNewManualRoute
    }
  );

  const { paymentMethods } = useGetPaymentMethods();

  const secondStepMethods = useFormWithSchema(useSecondStepFormSchema(), {
    defaultValues: {
      report: ''
    }
  });

  const {
    mutateAsync: createManualRouteExpenseAsync,
    isLoading: isCreatingRouteExpense
  } = useMutationCache({
    mutationKey: [ExpensesMutationKeys.CREATE_MANUAL_EXPENSE],
    mutationFn: async (payload: ICreateRouteExpensePayload) =>
      await ExpensesService.createRouteExpense(payload)
  });

  const {
    mutateAsync: updateRouteExpenseAsync,
    isLoading: isUpdatingRouteExpense
  } = useMutationCache({
    mutationKey: [ExpensesMutationKeys.UPDATE_ROUTE_EXPENSE],
    mutationFn: async (payload: IUpdateRouteExpensePayload) =>
      await ExpensesService.updateRouteExpense(payload)
  });

  async function createManualRouteExpense(
    data: ICreateRouteExpensePayload
  ): Promise<void> {
    try {
      await createManualRouteExpenseAsync(data);

      onSuccessCreateRouteExpense?.();

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES]
      });

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
      });

      queryClient.removeQueries({
        queryKey: [ReportsQueryKeys.GET_OPEN_REPORTS]
      });
    } catch (e) {
      const errorMessage =
        ErrorHandle.getErrorMessage(e as IErrorDefault) ??
        lang.expenses.modal.create_manual_route_expense
          .error_when_creating_route_expense[currentLangKey];

      onErrorCreateOrUpdateManualRouteExpense?.(errorMessage);
    }
  }

  async function updateManualRouteExpense(
    data: IUpdateRouteExpensePayload
  ): Promise<void> {
    try {
      const updatedRouteExpense = await updateRouteExpenseAsync(data);

      onSuccessUpdateRouteExpense?.();

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES]
      });

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
      });

      queryClient.setQueryData(
        [ExpensesQueryKeys.GET_EXPENSE, expenseUuidToUpdate],
        updatedRouteExpense
      );

      queryClient.removeQueries({
        queryKey: [ReportsQueryKeys.GET_OPEN_REPORTS]
      });
    } catch (e) {
      const errorMessage =
        ErrorHandle.getErrorMessage(e as IErrorDefault) ??
        t('messages.anErrorOccurredWhenUpdatingManualRoute');

      onErrorCreateOrUpdateManualRouteExpense?.(errorMessage);
    }
  }

  function onSubmitCreateManualRouteExpenseFirstStep(
    data: ICreateRouteExpenseFirstStepFieldsForm
  ): void {
    if (
      (expenseParameters?.requiredReceiptInManualRoute ||
        requiredReceiptInManualRoute) &&
      data.receipt === null
    ) {
      toast.error(t('errors.thisTypeOfExpenseRequiresAReceiptToBeSent'));
      firstStepMethods.setError('receipt', {
        type: 'required',
        message: t('errors.thisTypeOfExpenseRequiresAReceiptToBeSent')
      });
      return;
    }

    if (
      expenseParameters?.isProjectFieldMandatory &&
      (data.apportionment === undefined || data.apportionment.length === 0)
    ) {
      toast.error(
        lang.expenses.modal.create_manual_expense.project_selection_error[
          currentLangKey
        ]
      );
      return;
    }

    if (data.apportionment && data.apportionment?.length > 0) {
      const checkApportionment = ExpensesValidate.checkApportionment(
        data.apportionment,
        Currency.parseToFloat(data.value)
      );

      if (checkApportionment !== null) {
        const errorMessages: Record<ApportionmentErrorType, string> = {
          'percentage-is-not-100':
            lang.expenses.modal.create_manual_expense
              .sum_apportionment_project_error[currentLangKey],
          'sum-of-values': t(
            'errors.theSumOfTheApportionmentValuesMustBeEqualToTheExpenseValue'
          ),
          'duplicate-projects':
            lang.expenses.modal.create_manual_expense
              .duplicate_projects_in_the_apportionment_error[currentLangKey]
        };

        toast.error(errorMessages[checkApportionment]);
        return;
      }
    }

    // salva a primeira etapa do formulário para depois enviar a request
    createManualRouteExpenseFirstStepData.current = data;
    onAfterSubmitFirstStep?.();
  }

  const allowUserChangeExpenseProjectByReportProject =
    createManualRouteExpenseFirstStepData.current !== null &&
    (createManualRouteExpenseFirstStepData.current.apportionment ===
      undefined ||
      createManualRouteExpenseFirstStepData.current.apportionment.length ===
        0 ||
      createManualRouteExpenseFirstStepData.current.apportionment.length === 1);

  function onSubmitCreateManualRouteExpenseSecondStep(
    data: ICreateRouteExpenseSecondStepFieldsForm
  ): void {
    const formData = {
      ...(createManualRouteExpenseFirstStepData.current as ICreateRouteExpenseFirstStepFieldsForm),
      ...data
    };

    let apportionment: IApportionmentPayload[] | undefined;

    // se o usuário selecionou um relatório e confirmou a alteração dos dados, então eu preciso considerar o projeto do relatório no rateio
    if (
      allowUserChangeExpenseProjectByReportProject &&
      confirmedReportInformationBySelectedReport &&
      confirmedReportInformationBySelectedReport.project
    ) {
      // caso o usuário tenha colocado um projeto no rateio, então considera os dados do projeto do relatório
      if (
        formData.apportionment !== undefined &&
        formData.apportionment.length === 1
      ) {
        apportionment = [
          {
            ...formData.apportionment[0],
            project: String(
              confirmedReportInformationBySelectedReport.project.id
            )
          }
        ];
      } else {
        // caso o usuário não tenha definido rateio, então eu vou considerar o valor total da despesa para o projeto do relatório
        apportionment = [
          {
            project: String(
              confirmedReportInformationBySelectedReport.project.id
            ),
            percentage: 100,
            value: Currency.parseToFloat(formData.value)
          }
        ];
      }
    } else {
      apportionment = formData.apportionment?.map(item => ({
        ...item,
        project: item.project?.value ?? ''
      }));
    }

    const hasReportPaymentMethod =
      confirmedReportInformationBySelectedReport !== null &&
      confirmedReportInformationBySelectedReport.paymentMethod !== null;

    // caso o relatório tenha uma forma de pagamento, o valor do campo reembolsável é o da forma de pagamento do relatório
    const isReportPaymentMethodRefundable =
      (hasReportPaymentMethod &&
        paymentMethods !== undefined &&
        paymentMethods.find(
          item =>
            String(item.id) ===
            String(
              confirmedReportInformationBySelectedReport?.paymentMethod?.id
            )
        )?.refundable) ??
      false;

    const apportionmentAdjusted =
      ExpensesValidate.adjustApportionmentPercentageBeforeSendToApi(
        apportionment,
        Currency.parseToFloat(formData.value)
      );

    const mergedFormDataWithReportData: ICreateRouteExpensePayload = {
      ...formData,
      costsCenter: formData.costsCenter?.value ?? '',
      apportionment: apportionmentAdjusted,
      // se o usuário selecionou um relatório, confirmou a alteração dos dados e o relatório tem um centro de custo, então eu vou considerar o centro de custo do relatório
      ...(confirmedReportInformationBySelectedReport &&
      confirmedReportInformationBySelectedReport.costsCenter
        ? {
            costsCenter: String(
              confirmedReportInformationBySelectedReport.costsCenter?.id
            )
          }
        : {}),
      // se o usuário selecionou um relatório, confirmou a alteração dos dados e o relatório tem um método de pagamento, então eu vou considerar o método de pagamento do relatório
      ...(confirmedReportInformationBySelectedReport &&
      confirmedReportInformationBySelectedReport.paymentMethod
        ? {
            paymentMethod: String(
              confirmedReportInformationBySelectedReport.paymentMethod.id
            ),
            refundable: isReportPaymentMethodRefundable
          }
        : {})
    };

    if (expenseUuidToUpdate === undefined) {
      createManualRouteExpense(mergedFormDataWithReportData);
      return;
    }

    updateManualRouteExpense({
      ...mergedFormDataWithReportData,
      expenseUuid: expenseUuidToUpdate
    });
  }

  return {
    firstStepForm: {
      methods: firstStepMethods,
      handleSubmit: firstStepMethods.handleSubmit(
        onSubmitCreateManualRouteExpenseFirstStep
      ),
      selectedCurrencyId:
        createManualRouteExpenseFirstStepData.current?.currency
    },
    secondStepForm: {
      methods: secondStepMethods,
      handleSubmit: secondStepMethods.handleSubmit(
        onSubmitCreateManualRouteExpenseSecondStep
      )
    },
    isLoadingExpenseParameters,
    isCreatingRouteExpense,
    isUpdatingRouteExpense,
    allowUserChangeExpenseProjectByReportProject
  };
}
