import { useQueryCache } from 'data/cache';
import {
  FuelQueryKeys,
  FuelService,
  type IUseGetTypesOfOwners
} from 'data/modules/fuel';

export function useGetTypesOfOwners(): IUseGetTypesOfOwners {
  const { data, isLoading, isFetching } = useQueryCache({
    queryKey: [FuelQueryKeys.GET_TYPES_OF_OWNERS],
    queryFn: async () => await FuelService.getTypesOfOwners()
  });

  return {
    typesOfOwners: data ?? [],
    isLoadingTypesOfOwners: isLoading || isFetching
  };
}
