import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ModalBody = styled(motion.div)`
  margin-bottom: 2.5rem;
  padding: 0 1.6rem;
  font-family: Poppins, sans-serif;
  font-style: normal;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 44rem;
  }

  > p {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 3.2rem;
    letter-spacing: 0.75px;
  }
`;

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const ImageAndTextContent = styled.div`
  height: 38rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 3.2rem;
    text-align: center;
    letter-spacing: 0.75px;
    padding-left: 1rem;
    padding-right: 1rem;

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      padding-left: 4rem;
      padding-right: 4rem;
    }

    > span {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: end;
`;
