import { useEffect, useRef } from 'react';

import { DefaultButton, TextArea } from 'ds';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFlagsContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';
import { DefaultLayout } from 'presentation/templates';

import { Icons, routesPathPrefix } from 'shared/constants/global';
import { useGa4 } from 'shared/hooks/global';

import { SuggestionFormLabel } from './components/SuggestionFormLabel';
import { SuggestionSent } from './components/SuggestionSent';
import { useSuggestion } from './useSuggestion';

import {
  Container,
  DisclaimerContent,
  DisclaimerText,
  DisclaimerTip,
  DisclaimerWrapper,
  Form,
  FormFooter
} from './Suggestion.styles';

export function Suggestion(): JSX.Element {
  const { analyticsPageFlag } = useFlagsContext();
  const { t } = useTranslation('analytics');

  const { sendGaEvent } = useGa4();

  const {
    methods,
    handleBackToStart,
    isSendingSuggestion,
    isAnalysisSuggestionSent,
    handleSendAnalysisSuggestion,
    handleToggleAnalysisSuggestSent
  } = useSuggestion();

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      sendGaEvent('analytics', 'analytics_page');
    }

    return () => {
      isFirstRender.current = false;
    };
  }, [sendGaEvent]);

  return (
    <DefaultLayout
      pageNameAndBreadcrumbs={{
        pageName: t('pageName.suggestion'),
        isBreadcrumbsVisible: !!analyticsPageFlag,
        isBackButtonVisible: false,
        isClassicMode: false,
        breadcrumbs: [
          {
            name: t('pageName.analytics'),
            pageLink: routesPathPrefix.analytics
          },
          { name: t('pageName.suggestion') }
        ]
      }}
    >
      {isAnalysisSuggestionSent ? (
        <SuggestionSent
          onBackToStartClick={handleBackToStart}
          onSentAnotherClick={handleToggleAnalysisSuggestSent}
        />
      ) : (
        <>
          <Loader isLoading={isSendingSuggestion} />
          <Container>
            <DisclaimerContent>
              <DisclaimerWrapper>
                {analyticsPageFlag ? (
                  <>
                    <DisclaimerText>{t('disclaimer')}</DisclaimerText>
                    <DisclaimerTip>{t('tip')}</DisclaimerTip>
                  </>
                ) : (
                  <>
                    <DisclaimerText>{t('analysisDevelopment')}</DisclaimerText>
                    <DisclaimerTip>{t('howWeCanHelp')}</DisclaimerTip>
                  </>
                )}
              </DisclaimerWrapper>
              <Icons.IllustrationAnalyticsSuggestionIcon />
            </DisclaimerContent>
            <FormProvider {...methods}>
              <Form onSubmit={handleSendAnalysisSuggestion}>
                <SuggestionFormLabel
                  label={t('form.first.label')}
                  characterLimit={1000}
                />
                <TextArea.Controlled
                  name='type'
                  label={t('form.first.placeholder')}
                  textareaProps={{
                    placeholder: t('form.first.placeholder')
                  }}
                />

                <SuggestionFormLabel
                  label={t('form.second.label')}
                  characterLimit={1000}
                />
                <TextArea.Controlled
                  name='objective'
                  label={t('form.second.placeholder')}
                  textareaProps={{
                    placeholder: t('form.second.placeholder')
                  }}
                />

                <SuggestionFormLabel
                  label={t('form.third.label')}
                  characterLimit={1000}
                />
                <TextArea.Controlled
                  name='howSee'
                  label={t('form.third.placeholder')}
                  textareaProps={{
                    placeholder: t('form.third.placeholder')
                  }}
                />

                <SuggestionFormLabel
                  label={t('form.fourth.label')}
                  characterLimit={1000}
                />
                <TextArea.Controlled
                  name='frequency'
                  label={t('form.fourth.placeholder')}
                  className='two-rows'
                  textareaProps={{
                    placeholder: t('form.fourth.placeholder')
                  }}
                />

                <SuggestionFormLabel
                  label={t('form.fifth.label')}
                  characterLimit={100}
                />
                <TextArea.Controlled
                  name='contact'
                  label={t('form.fifth.placeholder')}
                  className='two-rows'
                  textareaProps={{
                    placeholder: t('form.fifth.placeholder')
                  }}
                />

                <FormFooter>
                  <p>{t('form.requiredTip')}</p>
                  <DefaultButton>{t('form.sentButton')}</DefaultButton>
                </FormFooter>
              </Form>
            </FormProvider>
          </Container>
        </>
      )}
    </DefaultLayout>
  );
}
