import { tokens } from 'ds';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: ${tokens.spacingXs};

  h3 {
    font-size: ${tokens.fontSizeXxs};
  }
`;

export const Content = styled.div`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  text-align: center;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  gap: ${tokens.spacingLg};

  & > div {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    gap: ${tokens.spacingXs};
  }

  #header {
    justify-content: end;
    margin: 0 !important;
    padding: 0.6rem;

    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  #title {
    line-height: 2.4rem;
  }

  #footer {
    margin: 0 !important;
  }

  #content {
    padding-right: 0 !important;
    min-height: unset;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: end;
  gap: ${tokens.spacingMd};
`;
