import { useQueryClient } from '@tanstack/react-query';

import { useMutationCache } from 'data/cache';
import { ExpensesQueryKeys } from 'data/modules/expenses';
import {
  FuelMutationKeys,
  FuelService,
  type IChangeExpenseTypePayload,
  type IUseChangeExpenseType,
  type IUseChangeExpenseTypeParams
} from 'data/modules/fuel';

export function useChangeExpenseType({
  onErrorChangeExpenseType,
  onSuccessChangeExpenseType
}: IUseChangeExpenseTypeParams): IUseChangeExpenseType {
  const {
    isLoading: isChangingExpenseType,
    mutateAsync: changeExpenseTypeAsync
  } = useMutationCache({
    mutationKey: [FuelMutationKeys.CHANGE_EXPENSE_TYPE],
    mutationFn: FuelService.changeExpenseType
  });

  const queryClient = useQueryClient();

  async function changeExpenseType(
    payload: IChangeExpenseTypePayload
  ): Promise<void> {
    try {
      await changeExpenseTypeAsync(payload);

      queryClient.removeQueries({
        queryKey: [ExpensesQueryKeys.GET_EXPENSE, payload.uuid]
      });

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES]
      });

      onSuccessChangeExpenseType?.();
    } catch (e) {
      onErrorChangeExpenseType?.();
    }
  }

  return {
    isChangingExpenseType,
    changeExpenseType
  };
}
