import { type IMultipleInputsProps } from './MultipleInputs.types';

import { Container } from './MultipleInputs.styles';

export function MultipleInputs({
  children,
  ...rest
}: IMultipleInputsProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
