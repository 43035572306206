import { useRef } from 'react';

import { usePagination } from 'ds/components/Table/Pagination/PaginationContainer/PaginationContainer.context';
import { useDsGa4, useOnClickOutside } from 'ds/hooks/globals';
import { DSIcons } from 'ds/icons';

import { usePaginationPages } from './usePaginationPages';

import { type IPaginationPagesProps } from './PaginationPages.types';
import { type LanguageType } from 'ds/types';

import {
  Container,
  Label,
  PerPageSelect,
  PerPageSelectContainer,
  PerPageSelectItem,
  PerPageSelectMenu
} from './PaginationPages.styles';

export function PaginationPages({
  options,
  langKey = 'pt',
  onChangePerPage,
  ...rest
}: IPaginationPagesProps): JSX.Element {
  const { showMenu, setShowMenu } = usePaginationPages();

  const { perPage } = usePagination();

  const paginationMessage: Record<LanguageType, string> = {
    pt: `Itens por página:`,
    en: `Items per page:`,
    es: `Artículos por página:`
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside<HTMLDivElement>(ref, () => {
    setShowMenu(false);
  });

  const { sendDsGaEvent } = useDsGa4();

  return (
    <Container
      ref={ref}
      $visible={showMenu}
      {...rest}
    >
      <Label>{paginationMessage[langKey]}</Label>
      <PerPageSelectContainer>
        <PerPageSelect
          onClick={() => {
            setShowMenu(!showMenu);

            sendDsGaEvent('components', 'pagination', {
              eventLabel: 'open_dropdown_pagination',
              description: 'click no dropdown'
            });
          }}
        >
          {perPage}
          <DSIcons.ChevronIcon />
        </PerPageSelect>

        <PerPageSelectMenu>
          {options.map(item => {
            return (
              <PerPageSelectItem
                onClick={() => {
                  onChangePerPage(item);
                  setShowMenu(false);

                  sendDsGaEvent('components', 'pagination', {
                    eventValue: item.toString()
                  });
                }}
                key={`perPageItem-${item}`}
              >
                {item}
              </PerPageSelectItem>
            );
          })}
        </PerPageSelectMenu>
      </PerPageSelectContainer>
    </Container>
  );
}
