import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  align-items: center;
  margin-bottom: 9.3rem;
  padding: 0 2rem;

  > p {
    font-size: 1.8rem;
    text-align: center;
  }
`;

export const IconContainer = styled.div`
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4.8rem;
  background-color: ${({ theme }) => theme.tokens.feedbackColorSuccessMedium};

  > svg {
    color: ${({ theme }) => theme.tokens.feedbackColorSuccessPure};
  }
`;
