import { SettingSidebar } from 'presentation/components/base/SettingsSidebar';

import { type ISettingsSidebarSubItemsProps } from './SettingsSidebarSubItems.types';

import { Container } from './SettingsSidebarSubItems.styles';

export function SettingsSidebarSubItems({
  listLevel,
  ...subItem
}: ISettingsSidebarSubItemsProps): JSX.Element {
  return (
    <>
      {subItem.subItems ? (
        <SettingSidebar.Group
          listLevel={listLevel}
          {...subItem}
        />
      ) : (
        <Container>
          <SettingSidebar.Item {...subItem} />
        </Container>
      )}
    </>
  );
}
