import { type ITransactionDetailsRelatedsItemProps } from './TransactionDetailsRelatedsItem.types';

import { Container } from './TransactionDetailsRelatedsItem.styles';

export function TransactionDetailsRelatedsItem({
  date,
  value,
  description
}: ITransactionDetailsRelatedsItemProps): JSX.Element {
  return (
    <Container>
      <div>
        <p title={description}>{description}</p>

        <span>{date}</span>
      </div>

      <p>{value}</p>
    </Container>
  );
}
