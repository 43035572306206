import { DSIcons, tokens } from 'ds';
import styled from 'styled-components';

import { type GroupCardStylesType } from './GroupCard.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<AddPrefixToType<GroupCardStylesType>>`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  background-color: ${tokens.neutralColorHighPure};
  border: solid 1px ${tokens.neutralColorHighDark};
  width: 100%;
  max-width: ${({ $isDetailsCard }) =>
    $isDetailsCard ? '31.1rem' : '29.92rem'};
  border-radius: 12px;
  cursor: ${({ $isDetailsCard }) => ($isDetailsCard ? 'default' : 'pointer')};
  transition: all 0.1s linear;
  padding: 1.6rem;

  .settingsIcon path {
    stroke: ${tokens.neutralColorLowDarkest};
  }

  &:hover {
    scale: ${({ $isDetailsCard }) => ($isDetailsCard ? 'none' : '1.03')};
  }
`;

export const SkeletonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${tokens.neutralColorHighPure};
  border: solid 1px ${tokens.neutralColorHighDark};
  width: 100%;
  max-width: 29.92rem;
  aspect-ratio: 2.23;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.1s linear;
  padding: 1.6rem;

  &:hover {
    scale: 1.03;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
`;

export const SettingsIcon = styled(DSIcons.SettingsIcon)`
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
`;

export const EditIcon = styled(DSIcons.EditIcon)`
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  cursor: pointer;
`;

export const Title = styled.h1<AddPrefixToType<GroupCardStylesType>>`
  color: ${({ $isDetailsCard }) =>
    $isDetailsCard
      ? tokens.neutralColorLowDarkest
      : tokens.brandColorSecondaryPure};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 2.4rem;
  letter-spacing: 0.75px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Text = styled.p`
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  line-height: 2.2rem;
  letter-spacing: 0.25px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

export const Value = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightBold};
  line-height: 3.2rem;
  letter-spacing: 1px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  margin-top: 1.4rem;
`;
