import { useMemo } from 'react';

import { tokens } from 'ds';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useAnalysisUserFiltersContext } from 'data/contexts';
import { useGetAnalysisUserHeader } from 'data/modules/analytics';

import { useUserExpenses } from 'presentation/pages/analytics/Analysis/UserExpenses/useUserExpenses';

import { CustomDate } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

import { type IUseUserAnalysisBiggestSpendingChart } from './UserAnalysisBiggestSpendingChart.types';
import { type IBasicBarChartProps } from 'ds/components/Chart/BasicBarChart/BasicBarChart.types';

export function useUserAnalysisBiggestSpendingChart(): IUseUserAnalysisBiggestSpendingChart {
  const { t } = useTranslation(['analytics', 'global']);

  const { payload } = useUserExpenses();

  const [analysisFilters, perPage, currentPage, getSelectedFastDateFilter] =
    useAnalysisUserFiltersContext(
      useShallow(state => [
        state.filters,
        state.perPage,
        state.currentPage,
        state.getSelectedFastDateFilter
      ])
    );

  const { analysisUserHeader, isLoadingAnalysisUserHeader } =
    useGetAnalysisUserHeader({
      ...payload,
      perPage,
      page: currentPage
    });

  const appliedDateFilter = useMemo(() => {
    const fastDateKey = getSelectedFastDateFilter();

    if (!fastDateKey) return undefined;

    switch (fastDateKey) {
      case 'TODAY':
        return t('analytics:filters.today');
      case 'LAST_WEEK':
        return t('filters.inTheLastDays', { days: '7' });
      case 'LAST_MONTH':
        return t('filters.inTheLastDays', { days: '30' });
      case 'THIS_MONTH':
        return t('filters.thisMonth');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    analysisFilters.endDate,
    getSelectedFastDateFilter,
    analysisFilters.initialDate
  ]);

  const title = `${t('userCharts.usersWhoSpentTheMost')} ${appliedDateFilter?.toLocaleLowerCase() ?? ''}`;

  const subtitle = `${t('userCharts.topSpendingUsers')} ${
    getSelectedFastDateFilter() === 'LAST_WEEK'
      ? `${t('global:from')} ${CustomDate.formatFromISO(analysisFilters.initialDate ?? '', 'dd/MM')} ${t('global:to')} ${CustomDate.formatFromISO(analysisFilters.endDate ?? '', 'dd/MM')}`.toLowerCase()
      : ''
  }`;

  // Obtém os 5 primeiros resultados
  // E formata os dados necessários para plotar o gráfico
  const chartData = useMemo(
    () =>
      analysisUserHeader.slice(0, 5).map(spending => ({
        name: spending.name,
        total: spending.total
      })),
    [analysisUserHeader]
  );

  const marginTop = 50;
  const pointWidth = 24;
  const groupPadding = 0;
  const marginBottom = 50;
  const pointPadding = 0.24;
  const barCount = chartData.length;

  // Calcula a altura do gráfico, com base na quantidade de linhas
  const chartHeight = useMemo(
    () =>
      marginTop +
      marginBottom +
      pointWidth * barCount * (1 + groupPadding + pointPadding),
    [barCount]
  );

  const chartValues = chartData.map(({ total }) => total);

  const hasHigherValues = Math.max(...chartValues) > 15;

  const chartProps: IBasicBarChartProps = {
    pointWidth,
    height: chartHeight,
    enableLegend: false,
    enableTooltip: false,
    colors: [tokens.brandColorPrimaryMedium],
    label: {
      enabled: true,
      formatter: options => {
        return Currency.format('BRL', options.point.y ?? 0, true);
      }
    },
    yAxios: {
      tickAmount: 6,
      enabled: true,
      showGridLine: true,
      format: 'R$ {value}',
      formatter: hasHigherValues
        ? undefined
        : options => {
            return Currency.format('BRL', Number(options.value), true);
          }
    },
    xAxios: {
      enabled: true,
      categories: chartData.map(({ name }) => name)
    },
    series: [
      {
        name: 'user-analysis-biggest-spending',
        data: chartValues
      }
    ],
    responsiveOptions: [
      {
        condition: {
          maxWidth: 700
        },
        chartOptions: {
          yAxis: {
            tickAmount: undefined
          }
        }
      }
    ]
  };

  return {
    title,
    subtitle,
    chartProps,
    isLoadingChart: isLoadingAnalysisUserHeader
  };
}
