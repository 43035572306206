import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type ProductBannerStyleType } from './ProductBanner.types';

export const Container = styled.div<AddPrefixToType<ProductBannerStyleType>>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ $reverse }) => ($reverse ? 'row-reverse' : 'row')};
  gap: ${({ theme }) => theme.tokens.spacingInsetHuge};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    gap: ${({ theme }) => theme.tokens.spacingInsetMdd};
    padding-bottom: ${({ theme }) => theme.tokens.spacingLg};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.tokens.spacingLg};

  > div {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.tokens.spacingMd};

    h4 {
      font-size: ${({ theme }) => theme.tokens.fontSizeMd};
      font-weight: ${({ theme }) => theme.tokens.fontWeightBold};
      color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
      font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
    }

    p {
      text-align: justify;
      white-space: pre-wrap;
      font-size: ${({ theme }) => theme.tokens.fontSizeXs};
      color: ${({ theme }) => theme.tokens.neutralColorLowDarkest};
      font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
      font-family: ${({ theme }) => theme.tokens.fontFamilyPoppins};
    }
  }

  .banner-action {
    width: fit-content;
  }
`;

export const BannerImage = styled.img`
  height: 100%;
  max-width: 50%;
  object-fit: contain;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 60%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    max-width: 100%;
  }
`;
