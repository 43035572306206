import { addDays, format, startOfDay } from 'date-fns';

import {
  type IExportMovementsInExcelPayload,
  type IPersistenceExportMovementsInExcelPayload
} from 'data/modules/cards/movements';

class ExportMovementsInExcelMapper {
  toPersistence(
    payload: IExportMovementsInExcelPayload
  ): IPersistenceExportMovementsInExcelPayload {
    return {
      all: payload.all,
      balances_id: payload.balancesId,
      end_date: `${format(addDays(startOfDay(payload.endDate), 1), 'yyyy-MM-dd').concat('T00:00:00.000Z')}`,
      start_date: `${format(addDays(startOfDay(payload.startDate), 1), 'yyyy-MM-dd').concat('T00:00:00.000Z')}`,
      timezone: payload.timezone,
      transactions_id: payload.transactionsId,
      card_group_id: payload.cardGroupId || undefined
    };
  }
}

export default new ExportMovementsInExcelMapper();
