import { DefaultButton } from 'ds';

import { type IButtonCardProps } from './ButtonCard.types';

import {
  CardDescription,
  CardTitle,
  Container,
  Content,
  IconWrapper
} from './ButtonCard.styles';

export function ButtonCard({
  icon,
  title,
  description,
  buttonLabel,
  onButtonClick,
  ...props
}: IButtonCardProps): JSX.Element {
  return (
    <Container {...props}>
      <IconWrapper>{icon}</IconWrapper>
      <Content>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
        <DefaultButton onClick={onButtonClick}>{buttonLabel}</DefaultButton>
      </Content>
    </Container>
  );
}
