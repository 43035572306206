import { useMemo } from 'react';

import {
  useGetRelatedTeamMembers,
  useGetTeamMembersItems
} from 'data/modules/teamMembers';

import { type ISelectOption } from 'presentation/components/base/Select/Select.types';

import { TransformArray } from 'shared/utils/global';

import {
  type IUseMembersWithAccess,
  type IUseMembersWithAccessParams
} from './MembersWithAccess.types';

export function useMembersWithAccessManageBusinessCard({
  uuid
}: IUseMembersWithAccessParams): IUseMembersWithAccess {
  const payload = {
    page: 1,
    perPage: 500,
    enabled: !!uuid
  };

  const { teamMembers, isLoadingTeamMembers } = useGetTeamMembersItems(payload);

  const { relatedTeamMembers, isLoadingRelatedTeamMembers } =
    useGetRelatedTeamMembers({
      uuid,
      enabled: !!uuid
    });

  const relatedTeamMembersOptions: ISelectOption[] = useMemo(() => {
    return TransformArray.objectsToSelectOptions(
      relatedTeamMembers,
      'uuid',
      'name'
    );
  }, [relatedTeamMembers]);

  const teamMembersOptions: ISelectOption[] = useMemo(() => {
    const filterTeamMembers = teamMembers.filter(
      option => !relatedTeamMembers.find(item => item.uuid === option.uuid)
    );

    return TransformArray.objectsToSelectOptions(
      filterTeamMembers,
      'uuid',
      'name'
    );
  }, [teamMembers, relatedTeamMembers]);

  return {
    teamMembersOptions,
    relatedTeamMembersOptions,
    isLoadingTeamMembers: isLoadingTeamMembers || isLoadingRelatedTeamMembers
  };
}
