import {
  type ICreateRouteExpensePayload,
  type IPersistenceCreateRouteExpensePayload
} from 'data/modules/expenses/types/expenses.types';

import { Currency } from 'shared/utils/format';

class CreateRouteExpenseMapper {
  toPersistence(
    domain: ICreateRouteExpensePayload
  ): IPersistenceCreateRouteExpensePayload {
    return {
      amount: Currency.parseToFloat(domain.value),
      amount_per_kilometer: Currency.parseToFloat(domain.mileagePaidValue),
      apportionments: domain.apportionment
        ? domain.apportionment.map(apportionment => ({
            // apportionment.project deve ser um ID (número inteiro)
            project_id: parseInt(apportionment.project),
            percentage: apportionment.percentage,
            amount: apportionment.value
          }))
        : [],
      // domain.costsCenter deve ser um ID (número inteiro)
      cost_center_id: parseInt(domain.costsCenter),
      // domain.currency deve ser um ID (número inteiro)
      currency_id: parseInt(domain.currency),
      is_reimbursable: `${domain.refundable}`,
      date: domain.date,
      kilometrage: Currency.parseToFloat(domain.mileage),
      observations: domain.observation ? domain.observation : undefined,
      // domain.paymentMethod deve ser um ID (número inteiro)
      payment_method_id: domain.paymentMethod
        ? parseInt(domain.paymentMethod)
        : undefined,
      receipt: domain.receipt ? domain.receipt : undefined,
      report_uuid: domain.report ? domain.report : undefined,
      // domain.preRegisteredSection deve ser um ID (número inteiro)
      route_id: domain.preRegisteredSection
        ? parseInt(domain.preRegisteredSection)
        : undefined,
      title: domain.description ? domain.description : undefined
    };
  }
}

export default new CreateRouteExpenseMapper();
