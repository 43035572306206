import { DSIcons, Modal, tokens } from 'ds';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import {
  type ExpenseDetailValueStyleType,
  type ViewExpensesModalStyleType
} from './ViewExpensesModal.types';

export const ExpenseContent = styled.div`
  width: 64%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: ${tokens.spacingNano};
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: ${tokens.spacingMd};
  width: 100%;
  justify-content: space-between;

  #view-image {
    height: auto;
    width: 30rem;
  }
`;

export const GpsTimestampDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${tokens.spacingQuarck};

  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: 1.1rem;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 2.4rem;
  letter-spacing: 0.75px;
`;

export const DateDivider = styled.div`
  width: 0.05rem;
  height: 100%;
  background-color: ${tokens.neutralColorHighDark};
`;

export const GpsTimestampDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${tokens.spacingNano};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
    flex-direction: column;
    align-items: start;
    gap: ${tokens.spacingMini};

    ${DateDivider} {
      display: none;
    }
  }
`;

export const ModalHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-family: ${tokens.fontFamilyPoppins};
  align-items: center;
  gap: ${tokens.spacingLg};
  flex-grow: 1;

  > span {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: ${tokens.spacingNano};

    .tooltip-container {
      word-break: break-word;
    }

    h3 {
      max-width: 46rem;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      white-space: nowrap;
    }

    > p {
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2.2rem;
      letter-spacing: 0.25px;
      color: ${tokens.neutralColorLowLightest};
    }

    @media screen and (max-width: 768px) {
      h3 {
        width: auto;
        max-width: 28rem;
        word-break: keep-all;
        white-space: nowrap;
        text-align: left;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: start;

    p {
      text-align: left;
    }
  }
`;

export const OdometerImageContainer = styled.div`
  display: flex;
  padding-top: ${tokens.spacingNano};
  padding-bottom: ${tokens.spacingNano};
  padding-right: 1.4rem;
  padding-left: ${tokens.spacingNano};
  justify-content: space-between;
  align-items: center;
  border-radius: ${tokens.borderRadiusXs};
  border: 1px solid ${tokens.neutralColorHighDark};

  .select-odometer-image-button {
    padding: 0.8rem 2rem;
    height: 3.2rem;
  }
`;

export const StyledModalContainer = styled(Modal.Container)`
  @media screen and (max-width: 1300px) {
    max-width: 98.4rem;
  }
`;

export const AvatarContent = styled.div`
  display: flex;
  gap: ${tokens.spacingNano};
  color: ${tokens.neutralColorLowDark};
  align-items: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.075rem;

  .avatar-odometer-image {
    cursor: pointer;
  }
`;

export const ExpenseAmount = styled.p`
  margin: 0;
  font-weight: ${tokens.fontWeightRegular};
  font-size: ${tokens.fontSizeXxs};
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  text-align: right;
`;

export const ModalSubHeader = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin-bottom: ${tokens.spacingMd};
  gap: ${tokens.spacingXs};

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const CoreContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  gap: ${tokens.spacingMd};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    > div {
      width: 100%;
    }

    #view-image {
      height: 40rem;
      width: 30rem;
    }
  }
`;

export const ExpenseValueContainer = styled.div`
  border: 1px solid ${tokens.neutralColorHighMedium};
  padding: ${tokens.spacingNano} ${tokens.spacingXs};
  border-radius: ${tokens.borderRadiusSm};
  color: ${tokens.neutralColorLowDarkest};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.spacingNano};
`;

export const ExpenseObservationsContainer = styled.div`
  border: 1px solid ${tokens.neutralColorHighMedium};
  padding: ${tokens.spacingNano} ${tokens.spacingXs};
  border-radius: ${tokens.borderRadiusSm};
  color: ${tokens.neutralColorLowDarkest};
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 11.1rem;

  .tooltip-observations-container {
    word-break: break-word;
    width: 100%;
    max-width: 59.6rem;
  }
`;

export const ExpenseDetailValueWithOverflow = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  letter-spacing: 0.075rem;
  color: ${tokens.neutralColorLowDark};
  word-break: break-word;
`;

export const ExpenseValueDate = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: 1.1rem;
  font-weight: ${tokens.fontWeightRegular};
  line-height: normal;
  color: ${tokens.neutralColorLowMedium};
  letter-spacing: 0.75px;
`;

export const ExpenseExchangeValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${tokens.spacingNano};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};
  line-height: normal;
  color: ${tokens.neutralColorLowMedium};
  letter-spacing: 0.75px;

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ExpenseValue = styled.h3`
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightSemibold};
  font-family: ${tokens.fontFamilyPoppins};
  line-height: normal;
  letter-spacing: 0.75px;
`;

export const ExpenseDetailsContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  gap: ${tokens.spacingXs};
  width: 100%;

  @media screen and (max-width: 980px) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
`;

export const ExpenseDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-content: center;
  white-space: nowrap;
  word-wrap: keep-all;
  gap: ${tokens.spacingXs};
  width: 50%;
`;

export const ExpenseDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ExpenseDetailTitle = styled.span`
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightMedium};
  font-size: ${tokens.fontSizeXxss};
  line-height: 2.2rem;
  letter-spacing: 0.025rem;
  color: ${tokens.neutralColorLowLight};
`;

export const ExpenseDetailValue = styled.span<
  AddPrefixToType<ExpenseDetailValueStyleType>
>`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  letter-spacing: 0.075rem;
  color: ${tokens.neutralColorLowDark};

  ${({ $lowercase }) => $lowercase && 'text-transform: lowercase;'}
`;

export const ModalFooter = styled.div<
  AddPrefixToType<ViewExpensesModalStyleType>
>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: ${({ $canDelete }) =>
    $canDelete ? 'space-between' : 'end'};
`;

export const AttachmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingNano};
  width: 100%;
`;

export const AttachmentItem = styled.a`
  display: flex;
  align-self: normal;
  gap: ${tokens.spacingNano};
  width: fit-content;
  text-decoration: none;

  &:visited {
    color: inherit;
  }
`;

export const Filename = styled.span`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: 0.75px;
  flex-grow: 1;
  word-break: break-all;
`;

export const StyledClipIcon = styled(DSIcons.ClipIcon)`
  color: ${tokens.brandColorSecondaryPure};
  flex-shrink: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingMd};

  > button {
    height: 4rem;
  }
`;

export const ExpenseTitle = styled.h3`
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightSemibold};
  letter-spacing: 0.75px;
  line-height: 3.2rem;
  text-align: center;
`;

export const StyledModalContent = styled(Modal.Content)`
  padding-right: 0;
  max-height: 69rem;
`;

export const StyledMotionDiv = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledModalHeader = styled(Modal.Header)`
  margin-bottom: ${tokens.spacingNano};
  gap: ${tokens.spacingXs};
`;
