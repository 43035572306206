import { type ICardInfoValueContainerProps } from './CardInfoValueContainer.types';

import { Container } from './CardInfoValueContainer.styles';

export function CardInfoValueContainer({
  children,
  ...rest
}: ICardInfoValueContainerProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
