import { useEffect } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { useAnalysisExpensesListContext, useModalContext } from 'data/contexts';

import {
  type IUseUserAnalysisExpensesParams,
  type IUseUserAnalysisExpensesTable
} from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisExpensesTable/UserAnalysisExpensesTable.types';

export function useUserAnalysisExpensesTable({
  expenses
}: IUseUserAnalysisExpensesParams): IUseUserAnalysisExpensesTable {
  const { visibleModal, handleOpenModal } = useModalContext();

  const [handleSetExpensesList, handleChangeExpenseIndex] =
    useAnalysisExpensesListContext(
      useShallow(state => [
        state.handleSetExpensesList,
        state.handleChangeExpenseIndex
      ])
    );

  function handleTableRowClick(expenseIndex: number): void {
    handleSetExpensesList(expenses);
    handleChangeExpenseIndex(expenseIndex);

    handleOpenModal('viewExpensesModal');
  }

  useEffect(() => {
    if (visibleModal !== 'viewExpensesModal') {
      handleSetExpensesList([]);
      handleChangeExpenseIndex(-1);
    }
  }, [handleChangeExpenseIndex, handleSetExpensesList, visibleModal]);

  return { handleTableRowClick };
}
