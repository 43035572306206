import { api, env } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import {
  completeUserOnboardingMock,
  getUserOnboardingAnswersMock,
  getUserOnboardingProgressMock,
  updateUserOnboardingAnswersMock
} from 'data/mocks/cards/userOnboarding';
import {
  type ICompleteUserOnboardingPayload,
  type IGetUserOnboardingAnswersPayload,
  type IPersistenceUserOnboardingProgress,
  type IUpdateUserOnboardingAnswersPayload,
  type IUserOnboardingAnswers,
  type IUserOnboardingProgress,
  type PersistenceUserOnboardingAnswersType
} from 'data/modules/cards/userOnboarding';
import { type IDefaultResponse } from 'data/modules/global';

import {
  GetUserOnboardingAnswersMapper,
  GetUserOnboardingProgressMapper,
  UpdateUserOnboardingAnswersMapper
} from './mappers';

class UserOnboardingService {
  async getUserOnboardingProgress(): Promise<IUserOnboardingProgress> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getUserOnboardingProgressMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceUserOnboardingProgress>
    >('/pay/users/onboarding');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetUserOnboardingProgressMapper.toDomain(data);
  }

  async getUserOnboardingAnswers(
    payload: IGetUserOnboardingAnswersPayload
  ): Promise<IUserOnboardingAnswers> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getUserOnboardingAnswersMock();
    }

    const response = await api.get<
      IDefaultResponse<PersistenceUserOnboardingAnswersType>
    >(`/pay/onboarding/user/${payload.onboardingUserId}/answers`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetUserOnboardingAnswersMapper.toDomain(data);
  }

  async updateUserOnboardingAnswers(
    payload: IUpdateUserOnboardingAnswersPayload
  ): Promise<null> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await updateUserOnboardingAnswersMock();
    }

    const response = await api.patch<IDefaultResponse<null>>(
      `/pay/onboarding/user/${payload.onboardingUserId}/step`,
      UpdateUserOnboardingAnswersMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async completeUserOnboarding(
    payload: ICompleteUserOnboardingPayload
  ): Promise<null> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await completeUserOnboardingMock();
    }

    const response = await api.patch<IDefaultResponse<null>>(
      `/pay/onboarding/user/${payload.onboardingUserId}/complete`
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }
}

export default new UserOnboardingService();
