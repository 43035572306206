import { useLangContext, useModalContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { DsDefaultButton, DsGhostButton } from 'presentation/ds/DsButton';
import { DsModal } from 'presentation/ds/DsModal';
import { OnboardingText } from 'presentation/pages/cards/Onboarding/components/General';

import { type IDeleteApprovalRepresentativesModalProps } from './DeleteApprovalRepresentativesModal.types';

import {
  Divider,
  FooterContainer,
  IconContainer,
  ModalContentContainer
} from './DeleteApprovalRepresentativesModal.styles';

export function DeleteApprovalRepresentativesModal({
  onConfirm
}: IDeleteApprovalRepresentativesModalProps): JSX.Element {
  const { handleCloseModal, visibleModal } = useModalContext();
  const [currentLangKey, global, deleteApprovalRepresentativesModal] =
    useLangContext(state => [
      state.currentLangKey,
      state.lang.global,
      state.lang.cards.onboarding.company.approval_representatives
        .delete_approval_representatives_modal
    ]);

  return (
    <DsModal
      title=''
      onClose={handleCloseModal}
      hasCloseButton={false}
      isOpen={visibleModal === 'confirmation'}
    >
      <ModalContentContainer>
        <IconContainer>
          <BaseIcon
            name='information'
            size={4}
          />
        </IconContainer>

        <OnboardingText>
          {deleteApprovalRepresentativesModal.message[currentLangKey]}
        </OnboardingText>

        <Divider />

        <FooterContainer>
          <DsGhostButton
            text={global.back[currentLangKey]}
            onClick={handleCloseModal}
          />
          <DsDefaultButton
            text={global.delete[currentLangKey]}
            onClick={onConfirm}
          />
        </FooterContainer>
      </ModalContentContainer>
    </DsModal>
  );
}
