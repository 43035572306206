import { useTranslation } from 'react-i18next';

import {
  useCardGroupsContext,
  useClassicModeContext,
  useLangContext,
  useModalContext
} from 'data/contexts';

import {
  ConfirmAddUsersModal,
  CreateCardGroupsModal,
  UsersCantBeAddedModal
} from 'presentation/pages/cards/Management/components/CardsGroupsCreation';
import {
  NewDepositModal,
  NewWithdrawalModal,
  RequestCardsModal
} from 'presentation/pages/cards/Management/components/ManagementSidebar';
import { SideCardDetailsModal } from 'presentation/pages/cards/Management/components/SideCardDetailsModal';
import { DefaultLayout } from 'presentation/templates';

import { localStorageKeys } from 'shared/constants/global';

import { CardsTable } from './components/CardsTable';
import { CardListPageHeader } from './components';
import { useCardsTour } from './tours';

import { Container } from './Cards.styles';

export function Cards(): JSX.Element {
  const { visibleModal } = useModalContext();
  const {
    currentLangKey,
    lang: { cards, global }
  } = useLangContext();
  const hasCardGroups = useCardGroupsContext(state => state.hasCardGroups);

  const { t } = useTranslation();

  useCardsTour({
    enabled: localStorage.getItem(localStorageKeys.cardsTour) !== 'true'
  });

  const isCardsClassicMode = useClassicModeContext(
    state => state.isCardsClassicMode
  );

  document.title = `vexpenses | ${cards.management.page_title[currentLangKey]}`;

  return (
    <DefaultLayout
      pageNameAndBreadcrumbs={{
        pageName: hasCardGroups ? t('cardsManagement') : t('cards'),
        isBackButtonVisible: false,
        isBreadcrumbsVisible: false,
        isClassicMode: isCardsClassicMode,
        isTourButtonVisible: true
      }}
    >
      <Container>
        <CardListPageHeader />

        <CardsTable />
      </Container>

      {visibleModal === 'deposit' && <NewDepositModal />}

      {visibleModal === 'withdrawal' && <NewWithdrawalModal />}

      {visibleModal === 'requestCards' && <RequestCardsModal />}

      {visibleModal === 'cardsGroupCreation' && <CreateCardGroupsModal />}

      {visibleModal === 'usersCantBeAdded' && <UsersCantBeAddedModal />}

      {visibleModal === 'confirmAddUsers' && <ConfirmAddUsersModal />}

      <SideCardDetailsModal
        isOpen={visibleModal === 'cardDetails'}
        title={global.card[currentLangKey]}
        side='right'
      />
    </DefaultLayout>
  );
}
