import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import {
  AccountMutationKeys,
  AccountService,
  type IResetCardPinFieldsForm,
  type IResetCardPinPayload,
  type IUseResetCardPin,
  type IUseResetCardPinParams
} from 'data/modules/cards/account';

import { useFormWithSchema } from 'shared/hooks/forms';

import { useResetCardPinSchema } from './schemas';

export function useResetCardPin({
  accountId,
  onAfterSuccess,
  resetFormOnSuccess = false
}: IUseResetCardPinParams): IUseResetCardPin {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset: formReset
  } = useFormWithSchema(useResetCardPinSchema());

  const { mutate, mutateAsync, isLoading } = useMutationCache({
    mutationKey: [AccountMutationKeys.RESET_CARD_PIN],
    mutationFn: async (payload: IResetCardPinPayload) =>
      await AccountService.resetCardPin(payload),
    onSuccess: () => {
      resetFormOnSuccess &&
        formReset({
          password: '',
          confirmPassword: ''
        });
      onAfterSuccess?.();
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function onSubmit(data: IResetCardPinFieldsForm): void {
    if (!accountId) {
      toast.error(
        lang.cards.statement.reset_pin_modal.unable_to_reset_pin[currentLangKey]
      );
      return;
    }

    mutate({
      accountId,
      pin: data.password
    });
  }

  return {
    handleResetCardPin: handleSubmit(onSubmit),
    changePin: mutateAsync,
    isResetingCardPin: isLoading,
    control,
    errors,
    reset: formReset
  };
}
