import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useCardGroupsContext, useModalContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { ActionModal } from 'presentation/components/global/ActionModal';

import { useConfirmAttachOfCardsOnGroup } from '../../../CardGroups/hooks';

import {
  CustomErrorIcon,
  FailedTransferListContainer,
  ListItemContainer,
  MessageContainer,
  ModalMessage
} from './UsersCantBeAddedModal.styles';

export function UsersCantBeAddedModal(): JSX.Element {
  const { handleOpenModal } = useModalContext();
  const { t } = useTranslation(['cards', 'global']);

  const [inconsistentUsersToAdd, isInCardGroupDetails] = useCardGroupsContext(
    useShallow(state => [
      state.inconsistentUsersToAdd,
      state.isInCardGroupDetails
    ])
  );

  const { handleVerifyCardsAttach } = useConfirmAttachOfCardsOnGroup();

  return (
    <ActionModal
      title=''
      onClose={() => {
        isInCardGroupDetails
          ? handleOpenModal('addCardToGroup')
          : handleOpenModal('cardsGroupCreation');

        handleVerifyCardsAttach();
      }}
      confirmLabel={t('global:understood')}
      onConfirm={() => {
        isInCardGroupDetails
          ? handleOpenModal('addCardToGroup')
          : handleOpenModal('cardsGroupCreation');
        handleVerifyCardsAttach();
      }}
    >
      <MessageContainer>
        <CustomErrorIcon>
          <BaseIcon
            size={6}
            name='close-circle'
            color='feedbackColorErrorLight'
          />
        </CustomErrorIcon>

        <ModalMessage>
          <span>{t('global:opsError')}</span>

          <span>{t('cards:usersCantBeAdd')}</span>
        </ModalMessage>
      </MessageContainer>

      <FailedTransferListContainer>
        {inconsistentUsersToAdd.map(user => (
          <ListItemContainer key={user.id}>
            <ModalMessage>{user.name}</ModalMessage>
          </ListItemContainer>
        ))}
      </FailedTransferListContainer>
    </ActionModal>
  );
}
