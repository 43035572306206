import { useQueryCache } from 'data/cache';
import {
  BusinessCardsQueryKeys,
  BusinessCardsService,
  type IUseGetVirtualMembers,
  type IUseGetVirtualMembersParams,
  type IVirtualMembers
} from 'data/modules/cards/businessCard';

export function useGetVirtualMembers({
  uuid,
  enabled
}: IUseGetVirtualMembersParams): IUseGetVirtualMembers {
  const {
    data,
    isLoading: isLoadingVirtualMembers,
    isFetching: isFetchingVirtualMembers
  } = useQueryCache({
    queryKey: [BusinessCardsQueryKeys.GET_VIRTUAL_MEMBERS, uuid],
    queryFn: async () => await BusinessCardsService.getVirtualMembers({ uuid }),
    enabled
  });

  return {
    virtualMembers: data ?? ({} as IVirtualMembers),
    isLoadingVirtualMembers,
    isFetchingVirtualMembers
  };
}
