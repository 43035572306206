import { Navigate, Route } from 'react-router-dom';

import { InconsistentKyc, Onboarding } from 'presentation/pages/cards';
import {
  AddPartnerLegalEntityData,
  AddPartnerNaturalPersonData,
  AddPartnerNaturalPersonOrLegalEntity,
  AddPartnerNaturalPersonType,
  ApprovalRepresentatives,
  CardsFunctionsActivation,
  CompanyAddress,
  CompanyData,
  CompanyOwners,
  Feedback,
  FillerRepresentativeData,
  FillerUserData,
  LastScreen,
  ManagersAndCardholdersInitialDirections,
  ManagersAndCardholdersList,
  OnboardingInProgress,
  PartnerOrRepresentative,
  PartnersList,
  PixKeyCreation,
  PoliciesAndTerms,
  RepresentativePermission,
  RepresentativePublicApproval,
  RequestCardsForm
} from 'presentation/pages/cards/Onboarding/components/Company';

import { CardsRoutes } from 'shared/constants/global';
import {
  AuthenticationMiddleware,
  CompanyOnboardingMiddleware
} from 'shared/middlewares';

export const companyOnboardingRoutes = [
  <Route
    key='onboarding/empresa'
    element={<AuthenticationMiddleware />}
  >
    <Route element={<CompanyOnboardingMiddleware />}>
      <Route
        path={CardsRoutes.COMPANY_ONBOARDING}
        element={<Onboarding />}
      >
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_BEGIN}
          element={<FillerUserData />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_2}
          element={<PoliciesAndTerms />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_3}
          element={<PartnerOrRepresentative />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_3_AUTHORIZATION}
          element={<RepresentativePermission />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_3_REPRESENTATIVES}
          element={<FillerRepresentativeData />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_3_APPROVAL_REPRESENTATIVES}
          element={<ApprovalRepresentatives />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_4}
          element={<CompanyData />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_5}
          element={<CompanyAddress />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_6}
          element={<CompanyOwners />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_7}
          element={<PartnersList />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_7_REPRESENTATIVE_TYPE}
          element={<AddPartnerNaturalPersonOrLegalEntity />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_7_FP_CLASSIFICATION}
          element={<AddPartnerNaturalPersonType />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_7_FP_DATA}
          element={<AddPartnerNaturalPersonData />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_7_JP_DATA}
          element={<AddPartnerLegalEntityData />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_8}
          element={<CardsFunctionsActivation />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_9}
          element={<ManagersAndCardholdersInitialDirections />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_10}
          element={<ManagersAndCardholdersList />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_11}
          element={<PixKeyCreation />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_12}
          element={<RequestCardsForm />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_13}
          element={<Feedback />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_FINAL}
          element={<LastScreen />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_IN_PROGRESS}
          element={<OnboardingInProgress />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_KYC}
          element={<InconsistentKyc isCompany />}
        />
        ,
        <Route
          path={CardsRoutes.COMPANY_ONBOARDING_APPROVE}
          element={<ApprovalRepresentatives />}
        />
        ,
      </Route>
    </Route>
  </Route>,
  <Route
    key='onboarding/approve'
    element={<AuthenticationMiddleware isPublic />}
  >
    <Route
      path={CardsRoutes.ONBOARDING_APPROVE}
      element={<Onboarding />}
    >
      <Route
        index
        element={<RepresentativePublicApproval />}
      />
    </Route>
  </Route>,
  <Route
    key={CardsRoutes.ONBOARDING_REDIRECT}
    path='onboarding'
    element={<Navigate to='/cartoes/onboarding/empresa' />}
  />
];
