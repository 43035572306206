import { Spinner } from 'presentation/components/base/Spinner';

import { Container } from './OnboardingLoader.styles';

export function OnboardingLoader(): JSX.Element {
  return (
    <Container>
      <Spinner color='primary' />
    </Container>
  );
}
