enum ReportsMutationKeys {
  CREATE_REPORT = 'CREATE_REPORT',
  LINK_EXPENSE_TO_REPORT = 'LINK_EXPENSE_TO_REPORT'
}

enum ReportsQueryKeys {
  GET_OPEN_REPORTS = 'GET_OPEN_REPORTS'
}

export { ReportsMutationKeys, ReportsQueryKeys };
