import { useMutationCache } from 'data/cache';
import {
  FuelMutationKeys,
  FuelService,
  type IDeleteVehiclePayload,
  type IUseDeleteVehicle,
  type IUseDeleteVehicleParams
} from 'data/modules/fuel';

export function useDeleteVehicle({
  onSuccessDeleteVehicle,
  onErrorDeleteVehicle
}: IUseDeleteVehicleParams): IUseDeleteVehicle {
  const { isLoading: isDeletingVehicle, mutateAsync: deleteVehicleAsync } =
    useMutationCache({
      mutationKey: [FuelMutationKeys.DELETE_VEHICLE],
      mutationFn: FuelService.deleteVehicle
    });

  async function deleteVehicle(payload: IDeleteVehiclePayload): Promise<void> {
    try {
      await deleteVehicleAsync(payload);

      onSuccessDeleteVehicle?.();
    } catch (e) {
      onErrorDeleteVehicle?.();
    }
  }

  return {
    deleteVehicle,
    isDeletingVehicle
  };
}
