import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';
import { useParams } from 'react-router-dom';

import { useMutationCache } from 'data/cache';
import {
  type IAccountStatement,
  type IAccountTransactionWithAdditionalInfo,
  type IGenerateStatementInFile,
  type IGenerateStatementInFilePayload,
  type IUseGenerateStatementInFile,
  type IUseGenerateStatementInFileParams,
  StatementQueryKeys,
  StatementService
} from 'data/modules/cards/statement';

import { Statement } from 'shared/utils/cards';

export function useGenerateStatementInFile(): IUseGenerateStatementInFile {
  const queryClient = useQueryClient();
  const { groupId } = useParams();

  async function generateFile(
    type: 'pdf' | 'excel',
    payload: IGenerateStatementInFilePayload
  ): Promise<IGenerateStatementInFile> {
    if (type === 'excel') {
      return await StatementService.generateStatementInExcel(payload);
    }

    return await StatementService.generateStatementInPdf(payload);
  }

  const { mutate, isLoading } = useMutationCache({
    mutationFn: async ({
      accountBalancesIds,
      endDate,
      searchInput,
      selectedCards,
      selectedMovementType,
      checkedAllUsers,
      startDate,
      timezone,
      userAccounts,
      companyAccounts,
      fileType
    }: IUseGenerateStatementInFileParams) => {
      if (checkedAllUsers) {
        const generateStatementInFileMutationPayload = {
          accountBalancesIds: null,
          allUsers: true,
          endDate,
          startDate,
          timezone,
          transactionsIds: null,
          ...(groupId && { cardGroupId: groupId })
        };

        return await generateFile(
          fileType,
          generateStatementInFileMutationPayload
        );
      }

      if (accountBalancesIds.length === 0) {
        const companyStatementPayload = {
          endDate,
          startDate,
          timezone
        };

        const companyStatement = await queryClient.ensureQueryData({
          queryKey: [
            StatementQueryKeys.GET_COMPANY_STATEMENT,
            companyStatementPayload
          ],
          queryFn: async () =>
            await StatementService.getCompanyStatement(companyStatementPayload)
        });

        const filteredCompanyStatement = Statement.filterStatementTransactions(
          companyStatement,
          selectedCards,
          selectedMovementType,
          searchInput
        );

        const statementTransactionsIds = filteredCompanyStatement.reduce<
          string[]
        >(
          (acc, statementRow) =>
            acc.concat(
              statementRow.items.map(({ transactionId }) => transactionId)
            ),
          []
        );

        const generateStatementInFileMutationPayload = {
          accountBalancesIds: null,
          allUsers: false,
          endDate,
          startDate,
          timezone,
          transactionsIds: statementTransactionsIds,
          ...(groupId && { cardGroupId: groupId })
        };

        return await generateFile(
          fileType,
          generateStatementInFileMutationPayload
        );
      }

      const accountStatementPayload = {
        endDate,
        startDate,
        timezone
      };

      const accountsStatements = await Promise.all(
        accountBalancesIds.map(
          async accountBalanceId =>
            await queryClient.ensureQueryData({
              queryKey: [
                StatementQueryKeys.GET_ACCOUNT_STATEMENT,
                { ...accountStatementPayload, accountBalanceId }
              ],
              queryFn: async () =>
                await StatementService.getAccountStatement({
                  ...accountStatementPayload,
                  accountBalanceId
                })
            })
        )
      );

      const adjustedAccountsStatements = accountsStatements.reduce<
        IAccountStatement[]
      >((acc, result) => {
        if (result) {
          return [...acc, ...result];
        }

        return acc;
      }, []);

      const filteredAccountStatement: IAccountStatement<IAccountTransactionWithAdditionalInfo>[] =
        adjustedAccountsStatements
          .map(accountStatementRow => {
            const transactionsWithUserName = accountStatementRow.items.map(
              transaction => {
                const userAccountTransactionUserName = userAccounts.find(
                  userAccount => userAccount.userId === transaction.userId
                )?.userName;

                const companyAccountTransactionUserName = companyAccounts.find(
                  companyAccount => companyAccount.id === transaction.userId
                )?.name;

                return {
                  ...transaction,
                  userName:
                    userAccountTransactionUserName ??
                    companyAccountTransactionUserName ??
                    ''
                };
              }
            );

            const filteredTransactions =
              Statement.filterAccountTransactionsWithUserName(
                transactionsWithUserName,
                selectedCards,
                selectedMovementType,
                searchInput
              );

            const adjustedAccountStatementRow = {
              ...accountStatementRow,
              items: filteredTransactions
            };

            return adjustedAccountStatementRow;
          })
          .filter(accountStatementRow => accountStatementRow.items.length > 0);

      const statementTransactionsIds = filteredAccountStatement.reduce<
        string[]
      >(
        (acc, statementRow) =>
          acc.concat(
            statementRow.items.map(({ transactionId }) => transactionId)
          ),
        []
      );

      const generateStatementInFileMutationPayload = {
        accountBalancesIds,
        allUsers: false,
        endDate,
        startDate,
        timezone,
        transactionsIds: statementTransactionsIds,
        ...(groupId && { cardGroupId: groupId })
      };

      return await generateFile(
        fileType,
        generateStatementInFileMutationPayload
      );
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  return {
    generateStatementInFile: mutate,
    isGeneratingStatementInFile: isLoading
  };
}
