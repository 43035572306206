import { useQueryClient } from '@tanstack/react-query';

import { useAuthContext, useModalContext } from 'data/contexts';
import {
  useGetOutsourcedUserAccounts,
  useGetUserAccountsByUserUuid
} from 'data/modules/cards/account';
import {
  BalanceQueryKeys,
  useRequestBalance
} from 'data/modules/cards/balance';

import { Currency } from 'shared/utils/format';

import { type IUseRequestBalanceModal } from './RequestBalanceModal.types';

export function useRequestBalanceModal(): IUseRequestBalanceModal {
  const user = useAuthContext(state => state.user);
  const { handleCloseModal } = useModalContext();
  const queryClient = useQueryClient();

  const isSharedAccount = !!user?.cards?.sharedAccounts;

  const { userAccounts, isFetchingUserAccounts } = useGetUserAccountsByUserUuid(
    {
      userUuid: user?.cards?.id as string,
      enabled: !isSharedAccount
    }
  );

  const { outsourcedUserAccounts, isFetchingOutsourcedUserAccounts } =
    useGetOutsourcedUserAccounts({
      outsourcedUserUuid: user?.cards?.id as string,
      enabled: isSharedAccount
    });

  const {
    handleRequestBalance,
    isRequestBalanceSuccess,
    isRequestingBalance,
    mutationReset,
    control,
    clearErrors,
    errors,
    getValues,
    reset,
    setValue
  } = useRequestBalance({
    accountBalanceId: isSharedAccount
      ? (outsourcedUserAccounts?.[0].balances.find(balance => balance.inUse)
          ?.id as string)
      : (userAccounts?.[0].balances.find(balance => balance.inUse)
          ?.id as string),
    onAfterSuccessUpdateBalanceSolicitations: () => {
      queryClient.invalidateQueries({
        queryKey: [BalanceQueryKeys.GET_BALANCE_SOLICITATIONS]
      });
    }
  });

  function handleCloseModalOnClick(): void {
    reset({
      currency: 'BRL'
    });

    if (isRequestBalanceSuccess) {
      mutationReset();
    }

    handleCloseModal();
  }

  function handleAddAmount(quantity: 100 | 1000 | 10000): void {
    const { amount } = getValues();
    const updatedAmount = amount
      ? Currency.parseToFloat(amount) + quantity
      : quantity;

    setValue('amount', Currency.format('BRL', updatedAmount));
    clearErrors('amount');
  }

  return {
    handleSubmit: handleRequestBalance,
    control,
    errors,
    getValues,
    isLoading:
      isFetchingUserAccounts ||
      isFetchingOutsourcedUserAccounts ||
      isRequestingBalance,
    isSuccess: isRequestBalanceSuccess,
    handleCloseModalOnClick,
    handleAddAmount
  };
}
