import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  gap: ${tokens.spacingXs};

  > div {
    flex: 1 0 20rem;

    h1,
    p {
      white-space: nowrap;
    }

    span {
      font-size: 2rem;
    }
  }
`;

export const SkeletonLoaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${tokens.spacingMd};

  span {
    height: 9rem;
    flex: 1 0 20rem;
    border-radius: ${tokens.borderRadiusMd};
  }
`;
