import { useMemo } from 'react';

import { type ObjectSchema } from 'yup';

import { yup } from 'data/config';
import { useLangContext } from 'data/contexts';
import { type IChangeVirtualMembersFieldsForm } from 'data/modules/cards/businessCard';

export function useChangeVirtualMembersSchema(): ObjectSchema<IChangeVirtualMembersFieldsForm> {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const changeVirtualMembersSchema: ObjectSchema<IChangeVirtualMembersFieldsForm> =
    useMemo(() => {
      const fieldRequiredMessage = lang.schemas.is_required[currentLangKey];

      return yup.object().shape({
        name: yup.string().required(fieldRequiredMessage),
        email: yup
          .string()
          .email(lang.schemas.invalid_email[currentLangKey])
          .required(fieldRequiredMessage)
      });
    }, [lang, currentLangKey]);

  return changeVirtualMembersSchema;
}
