import { useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'ds/utils';
import { useTranslation } from 'react-i18next';

import { useMutationCache } from 'data/cache';
import { useLangContext } from 'data/contexts';
import { type IErrorDefault } from 'data/contexts/modal/useModalContext.types';
import {
  type ApportionmentErrorType,
  ExpensesService,
  type IApportionmentPayload,
  type ICreateFuelExpenseFirstStepFieldsForm,
  type ICreateFuelExpensePayload,
  type ICreateFuelExpenseSecondStepFieldsForm,
  type IUpdateFuelExpensePayload,
  type IUseCreateFuelExpense,
  type IUseCreateFuelExpenseParams,
  useGetExpenseParameters
} from 'data/modules/expenses';
import { ExpensesValidate } from 'data/modules/expenses';
import {
  ExpensesMutationKeys,
  ExpensesQueryKeys
} from 'data/modules/expenses/keys/expenses.keys';
import { useGetPaymentMethods } from 'data/modules/paymentMethods';
import { ReportsQueryKeys } from 'data/modules/reports';

import { useFormWithSchema } from 'shared/hooks/forms';
import { CustomDate, CustomObject } from 'shared/utils/custom';
import { ErrorHandle } from 'shared/utils/errors';
import { Currency } from 'shared/utils/format';

import { useFirstStepFormSchema, useSecondStepFormSchema } from './schemas';

export function useCreateFuelExpense({
  onAfterSubmitFirstStep,
  onSuccessCreateFuelExpense,
  onSuccessUpdateFuelExpense,
  onErrorCreateOrUpdateFuelExpense,
  confirmedReportInformationBySelectedReport,
  expenseUuidToUpdate
}: IUseCreateFuelExpenseParams): IUseCreateFuelExpense {
  const { lang, currentLangKey } = useLangContext();

  const { expenseParameters, isLoadingExpenseParameters } =
    useGetExpenseParameters();

  const queryClient = useQueryClient();

  const createFuelExpenseFirstStepData =
    useRef<ICreateFuelExpenseFirstStepFieldsForm | null>(null);

  const { paymentMethods } = useGetPaymentMethods();

  const { t } = useTranslation('expenses');

  const defaultCurrencyFromParameter =
    expenseParameters?.defaultCurrency.user ??
    expenseParameters?.defaultCurrency.company;

  const valuesToCreateNewExpense = {
    currency: defaultCurrencyFromParameter
      ? String(defaultCurrencyFromParameter)
      : '',
    fuelType: null,
    licensePlate: '',
    mileage: '',
    pricePerLiter: '',
    quantityOfLiters: '',
    vehicleType: '',
    apportionment: [],
    costsCenter: undefined,
    date: CustomDate.formatDateToIso(new Date()),
    description: '',
    observation: '',
    paymentMethod: '',
    receipt: null,
    odometerImage: null,
    refundable: false,
    value: '',
    observationAttachments: []
  } as ICreateFuelExpenseFirstStepFieldsForm;

  const firstStepMethods = useFormWithSchema(
    useFirstStepFormSchema({
      userAction: expenseUuidToUpdate ? 'update' : 'create'
    }),
    {
      defaultValues: {
        paymentMethod: undefined,
        receipt: null,
        refundable: false
      },
      values: expenseUuidToUpdate ? undefined : valuesToCreateNewExpense
    }
  );

  const secondStepMethods = useFormWithSchema(useSecondStepFormSchema(), {
    defaultValues: {
      report: ''
    }
  });

  const {
    isLoading: isCreatingFuelExpense,
    mutateAsync: createFuelExpenseAsync
  } = useMutationCache({
    mutationKey: [ExpensesMutationKeys.CREATE_FUEL_EXPENSE],
    mutationFn: ExpensesService.createFuelExpense
  });

  const {
    isLoading: isUpdatingFuelExpense,
    mutateAsync: updateFuelExpenseAsync
  } = useMutationCache({
    mutationKey: [ExpensesMutationKeys.UPDATE_FUEL_EXPENSE],
    mutationFn: ExpensesService.updateFuelExpense
  });

  function onSubmitCreateFuelExpenseFirstStep(
    data: ICreateFuelExpenseFirstStepFieldsForm
  ): void {
    if (
      data.odometerImage === null &&
      expenseParameters?.fuelsPolicies?.isOdometerMandatory
    ) {
      toast.error(t('messages.odometerImageIsRequired'));
      firstStepMethods.setError('odometerImage', {
        type: 'required',
        message: t('messages.odometerImageIsRequired')
      });
      return;
    }

    if (
      expenseParameters?.isProjectFieldMandatory &&
      (data.apportionment === undefined || data.apportionment.length === 0)
    ) {
      toast.error(
        lang.expenses.modal.create_manual_expense.project_selection_error[
          currentLangKey
        ]
      );
      return;
    }

    if (data.apportionment && data.apportionment?.length > 0) {
      const checkApportionment = ExpensesValidate.checkApportionment(
        data.apportionment,
        Currency.parseToFloat(data.value)
      );

      if (checkApportionment !== null) {
        const errorMessages: Record<ApportionmentErrorType, string> = {
          'percentage-is-not-100':
            lang.expenses.modal.create_manual_expense
              .sum_apportionment_project_error[currentLangKey],
          'sum-of-values': t(
            'errors.theSumOfTheApportionmentValuesMustBeEqualToTheExpenseValue'
          ),
          'duplicate-projects':
            lang.expenses.modal.create_manual_expense
              .duplicate_projects_in_the_apportionment_error[currentLangKey]
        };

        toast.error(errorMessages[checkApportionment]);
        return;
      }
    }

    // salva a primeira etapa do formulário para depois enviar a request
    createFuelExpenseFirstStepData.current = data;

    onAfterSubmitFirstStep?.();
  }

  const allowUserChangeExpenseProjectByReportProject =
    createFuelExpenseFirstStepData.current !== null &&
    (createFuelExpenseFirstStepData.current.apportionment === undefined ||
      createFuelExpenseFirstStepData.current.apportionment.length === 0 ||
      createFuelExpenseFirstStepData.current.apportionment.length === 1);

  async function createFuelExpense(
    data: ICreateFuelExpensePayload
  ): Promise<void> {
    try {
      await createFuelExpenseAsync(data);

      onSuccessCreateFuelExpense?.();

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES]
      });

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
      });

      queryClient.removeQueries({
        queryKey: [ReportsQueryKeys.GET_OPEN_REPORTS]
      });
    } catch (e) {
      const errorMessage =
        ErrorHandle.getErrorMessage(e as IErrorDefault) ??
        t('messages.anErrorOccurredWhenRegisteringFuelExpense');

      onErrorCreateOrUpdateFuelExpense?.(errorMessage);
    }
  }

  async function updateFuelExpense(
    data: IUpdateFuelExpensePayload
  ): Promise<void> {
    try {
      const updatedFuelExpense = await updateFuelExpenseAsync(data);

      onSuccessUpdateFuelExpense?.();

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_PAGINATED_EXPENSES]
      });

      queryClient.invalidateQueries({
        queryKey: [ExpensesQueryKeys.GET_NUMBER_OF_EXPENSES_BY_STATUS]
      });

      queryClient.setQueryData(
        [ExpensesQueryKeys.GET_EXPENSE, expenseUuidToUpdate],
        updatedFuelExpense
      );

      queryClient.removeQueries({
        queryKey: [ReportsQueryKeys.GET_OPEN_REPORTS]
      });
    } catch (e) {
      const errorMessage =
        ErrorHandle.getErrorMessage(e as IErrorDefault) ??
        t('messages.anErrorOccurredWhenUpdatingFuelExpense');

      onErrorCreateOrUpdateFuelExpense?.(errorMessage);
    }
  }

  async function onSubmitCreateFuelExpenseSecondStep(
    data: ICreateFuelExpenseSecondStepFieldsForm
  ): Promise<void> {
    const formData = {
      ...(createFuelExpenseFirstStepData.current as ICreateFuelExpenseFirstStepFieldsForm),
      ...data
    };

    let apportionment: IApportionmentPayload[] | undefined;

    // se o usuário selecionou um relatório e confirmou a alteração dos dados, então eu preciso considerar o projeto do relatório no rateio
    if (
      allowUserChangeExpenseProjectByReportProject &&
      confirmedReportInformationBySelectedReport &&
      confirmedReportInformationBySelectedReport.project
    ) {
      // caso o usuário tenha colocado um projeto no rateio, então considera os dados do projeto do relatório
      if (
        formData.apportionment !== undefined &&
        formData.apportionment.length === 1
      ) {
        apportionment = [
          {
            ...formData.apportionment[0],
            project: String(
              confirmedReportInformationBySelectedReport.project.id
            )
          }
        ];
      } else {
        // caso o usuário não tenha definido rateio, então eu vou considerar o valor total da despesa para o projeto do relatório
        apportionment = [
          {
            project: String(
              confirmedReportInformationBySelectedReport.project.id
            ),
            percentage: 100,
            value: Currency.parseToFloat(formData.value)
          }
        ];
      }
    } else {
      apportionment = formData.apportionment?.map(item => ({
        ...item,
        project: item.project?.value ?? ''
      }));
    }

    const hasReportPaymentMethod =
      confirmedReportInformationBySelectedReport !== null &&
      confirmedReportInformationBySelectedReport.paymentMethod !== null;

    // caso o relatório tenha uma forma de pagamento, o valor do campo reembolsável é o da forma de pagamento do relatório
    const isReportPaymentMethodRefundable =
      (hasReportPaymentMethod &&
        paymentMethods !== undefined &&
        paymentMethods.find(
          item =>
            String(item.id) ===
            String(
              confirmedReportInformationBySelectedReport?.paymentMethod?.id
            )
        )?.refundable) ??
      false;

    const apportionmentAdjusted =
      ExpensesValidate.adjustApportionmentPercentageBeforeSendToApi(
        apportionment,
        Currency.parseToFloat(formData.value)
      );

    const mergedFormDataWithReportData: ICreateFuelExpensePayload =
      CustomObject.exchangeNullValues({
        ...formData,
        costsCenter: formData.costsCenter?.value ?? '',
        fuelType: formData.fuelType?.value ?? '',
        receipt: formData.receipt ?? null,
        observationAttachments: formData.observationAttachments ?? undefined,
        refundable: formData.refundable ?? undefined,
        apportionment: apportionmentAdjusted,
        // se o usuário selecionou um relatório, confirmou a alteração dos dados e o relatório tem um centro de custo, então eu vou considerar o centro de custo do relatório
        ...(confirmedReportInformationBySelectedReport &&
        confirmedReportInformationBySelectedReport.costsCenter
          ? {
              costsCenter: String(
                confirmedReportInformationBySelectedReport.costsCenter?.id
              )
            }
          : {}),
        // se o usuário selecionou um relatório, confirmou a alteração dos dados e o relatório tem um método de pagamento, então eu vou considerar o método de pagamento do relatório
        ...(confirmedReportInformationBySelectedReport &&
        confirmedReportInformationBySelectedReport.paymentMethod
          ? {
              paymentMethod: String(
                confirmedReportInformationBySelectedReport.paymentMethod.id
              ),
              refundable: isReportPaymentMethodRefundable
            }
          : {})
      });

    if (expenseUuidToUpdate === undefined) {
      createFuelExpense(mergedFormDataWithReportData);
      return;
    }

    updateFuelExpense({
      ...mergedFormDataWithReportData,
      expenseUuid: expenseUuidToUpdate
    });
  }

  return {
    firstStepForm: {
      methods: firstStepMethods,
      handleSubmit: firstStepMethods.handleSubmit(
        onSubmitCreateFuelExpenseFirstStep
      ),
      selectedCurrencyId: createFuelExpenseFirstStepData.current?.currency
    },
    secondStepForm: {
      methods: secondStepMethods,
      handleSubmit: secondStepMethods.handleSubmit(
        onSubmitCreateFuelExpenseSecondStep
      )
    },
    expenseParameters,
    isLoadingExpenseParameters,
    isCreatingFuelExpense,
    isUpdatingFuelExpense,
    allowUserChangeExpenseProjectByReportProject
  };
}
