import { type IStatusPillProps } from './StatusPill.types';

import { Container } from './StatusPill.styles';

export function StatusPill({
  status,
  children,
  ...props
}: IStatusPillProps): JSX.Element {
  return (
    <Container
      {...props}
      $status={status}
    >
      {children}
    </Container>
  );
}
