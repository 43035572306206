import {
  type ICreateVehiclePayload,
  type ICreateVehiclePayloadPersistence
} from 'data/modules/fuel/types/fuel.types';

class CreateVehicleMapper {
  toPersistence(
    payload: ICreateVehiclePayload
  ): ICreateVehiclePayloadPersistence {
    return {
      fuel_ids: payload.fuel,
      model: payload.model,
      plate: payload.plate,
      owner: payload.owner,
      vehicle_type_id: payload.type
    };
  }
}

export default new CreateVehicleMapper();
