import {
  type ICardGroup,
  type IGetCardGroupsParams,
  type IGetCardGroupsPayload,
  type IPersistenceCardGroup
} from 'data/modules/cards/cardGroups';

class GetCardGroupsMapper {
  toDomain(persistenceCardGroup: IPersistenceCardGroup): ICardGroup {
    return {
      id: persistenceCardGroup.id,
      name: persistenceCardGroup.name,
      balanceId: persistenceCardGroup.balance_id,
      isManager: persistenceCardGroup.is_manager,
      availableValue: undefined
    };
  }

  toPersistence(payload: IGetCardGroupsPayload): IGetCardGroupsParams {
    return {
      page: payload.page,
      limit: payload.limit,
      ...(payload.name && { name: payload.name }),
      ...(payload.managers && {
        managers: payload.managers
      }),
      ordenation_type: payload.ordenationType
    };
  }
}

export default new GetCardGroupsMapper();
