import {
  DefaultButton,
  Modal,
  SecondaryButton,
  Select,
  TextArea,
  TextButton,
  TextInput
} from 'ds';
import { AnimatePresence } from 'framer-motion';

import { useLangContext, useModalContext } from 'data/contexts';
import { useGetExpenseParameters } from 'data/modules/expenses';

import { Loader } from 'presentation/components/global/Loader';
import {
  SelectDynamicCostsCenters,
  SelectDynamicProjects
} from 'presentation/pages/expenses/ExpensesList/components/SelectDynamicCases';

import { animations } from 'shared/constants/animations';

import { useCreateReportForm } from './useCreateReportForm';

import { type ICreateReportFormProps } from './CreateReportForm.types';

import {
  Form,
  ModalContent,
  ModalFooter,
  MoreOptionsButtonContainer,
  OptionalInputsContainer,
  StyledModalContent
} from './CreateReportForm.styles';

export function CreateReportForm({
  onClickGoBackButton,
  stepperComponent,
  onReportCreated
}: ICreateReportFormProps): JSX.Element {
  const { lang, currentLangKey } = useLangContext();

  const { handleCloseModal } = useModalContext();

  const {
    isLoadingPaymentMethods,
    paymentMethodsOptions,
    advancePaymentsOptions,
    isLoadingAdvancePayments,
    form,
    isCreatingReport
  } = useCreateReportForm({
    onReportCreated
  });

  const { expenseParameters } = useGetExpenseParameters();

  const paymentMethodFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.reportPaymentMethod !== null
      ? expenseParameters.companyFields.reportPaymentMethod
      : lang.global.payment_form[currentLangKey];

  const costsCenterFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.reportCostCenter !== null
      ? expenseParameters.companyFields.reportCostCenter
      : lang.global.costs_center[currentLangKey];

  const projectFormLabel =
    expenseParameters?.companyFields.companyUsesFields &&
    expenseParameters?.companyFields.reportProject !== null
      ? expenseParameters?.companyFields.reportProject
      : lang.global.project[currentLangKey];

  return (
    <>
      <Loader isLoading={isCreatingReport} />
      <Modal.Header>
        <Modal.Title>
          {lang.reports.modal.create_report.create_report[currentLangKey]}
        </Modal.Title>
        <Modal.IconClose onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Form
        methods={form.methods}
        handleSubmit={form.handleSubmit}
        style={{
          overflow: 'auto'
        }}
      >
        <StyledModalContent>
          <ModalContent>
            {stepperComponent}

            <Form>
              <TextInput.Controlled
                small
                label={lang.reports.modal.create_report.title[currentLangKey]}
                name='title'
                placeholder={
                  lang.reports.modal.create_report.title[currentLangKey]
                }
              />

              <SelectDynamicCostsCenters customLabel={costsCenterFormLabel} />

              <MoreOptionsButtonContainer>
                <TextButton
                  size='small'
                  type='button'
                >
                  {
                    lang.reports.modal.create_report.more_options[
                      currentLangKey
                    ]
                  }
                </TextButton>
              </MoreOptionsButtonContainer>

              <AnimatePresence>
                <OptionalInputsContainer {...animations.fadeInOut}>
                  <Select.Controlled
                    small
                    name='advancePayment'
                    hasArrowDownIndicator
                    isLoading={isLoadingAdvancePayments}
                    menuPlacement='auto'
                    options={advancePaymentsOptions}
                    placeholder={
                      lang.reports.modal.create_report.bind_advance[
                        currentLangKey
                      ]
                    }
                    label={
                      lang.reports.modal.create_report.bind_advance[
                        currentLangKey
                      ]
                    }
                  />

                  <SelectDynamicProjects
                    name='project'
                    customLabel={projectFormLabel}
                  />

                  <Select.Controlled
                    small
                    menuPlacement='top'
                    name='paymentMethod'
                    isLoading={isLoadingPaymentMethods}
                    options={paymentMethodsOptions}
                    placeholder={paymentMethodFormLabel}
                    label={paymentMethodFormLabel}
                    hasArrowDownIndicator
                  />

                  <TextArea.Controlled
                    small
                    name='observation'
                    className='textarea-observation'
                    label={lang.global.observations[currentLangKey]}
                    textareaProps={{
                      placeholder: `${lang.global.observations[currentLangKey]}`
                    }}
                  />
                </OptionalInputsContainer>
              </AnimatePresence>
            </Form>
          </ModalContent>
        </StyledModalContent>
        <Modal.Footer>
          <ModalFooter>
            <SecondaryButton
              size='small'
              onClick={onClickGoBackButton}
              type='button'
            >
              {lang.global.back[currentLangKey]}
            </SecondaryButton>

            <DefaultButton
              size='small'
              type='submit'
              disabled={false}
            >
              {lang.global.advance[currentLangKey]}
            </DefaultButton>
          </ModalFooter>
        </Modal.Footer>
      </Modal.Form>
    </>
  );
}
