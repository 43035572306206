import { useMutationCache } from 'data/cache';
import {
  CardGroupsMutationKeys,
  CardGroupsService,
  type IEditCardGroupNamePayload,
  type IUseEditCardGroupName
} from 'data/modules/cards/cardGroups';

export function useEditCardGroupName(): IUseEditCardGroupName {
  const { mutateAsync, isPending } = useMutationCache({
    mutationKey: [CardGroupsMutationKeys.EDIT_CARD_GROUP_NAME],
    mutationFn: async (payload: IEditCardGroupNamePayload) => {
      await CardGroupsService.editGroupName(payload);
    }
  });

  return {
    isEditingCardGroupName: isPending,
    editCardGroupName: mutateAsync
  };
}
