import { SelectControlled } from './Controlled/SelectControlled.component';
import { SelectDefault } from './Default/SelectDefault.component';
import { SelectDynamicControlled } from './Dynamic/Controlled/SelectDynamicControlled.component';
import { SelectDynamicDefault } from './Dynamic/Default/SelectDynamicDefault.component';
import { SubMenuSelectControlled } from './SubMenu/Controlled/SubMenuSelectControlled.component';
import { SubMenuSelectDefault } from './SubMenu/Default/SubMenuSelectDefault.component';

export const Select = {
  Default: SelectDefault,
  Controlled: SelectControlled,
  DynamicControlled: SelectDynamicControlled,
  DynamicDefault: SelectDynamicDefault,
  SubMenuControlled: SubMenuSelectControlled,
  SubMenuDefault: SubMenuSelectDefault
};
