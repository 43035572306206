import Container from 'react-modal';

import { type IDsModalProps } from './DsModal.types';

import { CloseButton, Content, Header, Title } from './DsModal.styles';

Container.setAppElement('#root');

export function DsModal({
  isOpen,
  children,
  title,
  onClose,
  hasCloseButton = true
}: IDsModalProps): JSX.Element {
  return (
    <Container
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName='default-modal-overlay'
      className='ds-default-modal-content'
    >
      <Header>
        {typeof title === 'string' ? <Title>{title}</Title> : title}

        {hasCloseButton && (
          <CloseButton
            iconProps={{
              name: 'close',
              color: 'inherit',
              size: 1.4
            }}
            onClick={onClose}
          />
        )}
      </Header>

      <Content>{children}</Content>
    </Container>
  );
}
