import { env } from 'process';

import { api } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import {
  getAllocatedBalanceMock,
  getBalancesAndAccountMock,
  getBalanceSolicitationsMock,
  getBalanceSolicitationsStagedMock,
  getModifiedBalanceAllocationItemsFromCacheMock,
  getMyValueSolicitationsMock,
  getPixQrCodeStringMock,
  getQuantityOfBalanceSolicitationByStatusMock,
  getUnallocatedBalanceMock
} from 'data/mocks/cards/balance';
import {
  type IAllocatedBalance,
  type IBalancesAndAccount,
  type IBalancesAndAccountPayload,
  type IBalanceSolicitation,
  type IBalanceSolicitationApproved,
  type IBalanceSolicitationApprovedPayload,
  type IBalanceSolicitationCachePayload,
  type IGetAllocatedBalancePayload,
  type IGetBalanceSolicitations,
  type IGetBalanceSolicitationsPayload,
  type IGetMyValueSolicitationsPayload,
  type IModifiedBalanceAllocationItem,
  type IPersistenceAllocatedBalance,
  type IPersistenceBalancesAndAccount,
  type IPersistenceBalanceSolicitation,
  type IPersistenceBalanceSolicitationApproved,
  type IPersistenceBalanceSolicitationApprovedSummary,
  type IPersistenceModifiedBalanceAllocationItem,
  type IPersistencePixQrCodeString,
  type IPersistenceQuantityOfBalanceSolicitationByStatus,
  type IPersistenceRequestBalance,
  type IPersistenceTransferredBalanceAllocationItems,
  type IPersistenceUnallocatedBalance,
  type IPixQrCode,
  type IQuantityOfBalanceSolicitationByStatus,
  type IQuantityOfBalanceSolicitationByStatusPayload,
  type IRequestBalance,
  type IRequestBalancePayload,
  type IRequestCardsPayload,
  type IRequestWithdrawalPayload,
  type ISendModifiedBalanceAllocationItemsToCachePayload,
  type ITransferBalanceAllocationItemsPayload,
  type ITransferredBalanceAllocationItems,
  type IUnallocatedBalance,
  type IUnallocatedBalancePayload
} from 'data/modules/cards/balance';
import {
  type IDefaultResponse,
  type IDomainResponse,
  type IObjectsArray
} from 'data/modules/global';

import {
  BalanceSolicitationApprovedMapper,
  BalanceSolicitationApprovedStagedMapper,
  BalanceSolicitationMapper,
  GetAllocatedBalanceMapper,
  GetBalanceShowMapper,
  GetQuantityOfBalanceSolicitationByStatusMapper,
  GetUnallocatedBalanceMapper,
  ModifiedBalanceAllocationItemMapper,
  MyValueSolicitationsMapper,
  PixQrCodeMapper,
  RequestBalanceMapper,
  RequestCardsMapper,
  RequestWithdrawalMapper,
  SendModifiedBalanceAllocationItemsToCacheMapper,
  TransferBalanceAllocationItemsMapper
} from './mappers';

class BalanceService {
  async getUnallocatedBalance(
    payload: IUnallocatedBalancePayload
  ): Promise<IUnallocatedBalance> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getUnallocatedBalanceMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceUnallocatedBalance>
    >('/pay/company/balance', {
      params: {
        card_group_id: payload.cardGroupId || null
      }
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetUnallocatedBalanceMapper.toDomain(data);
  }

  async getAllocatedBalance(
    payload: IGetAllocatedBalancePayload
  ): Promise<IAllocatedBalance> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getAllocatedBalanceMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceAllocatedBalance>
    >('/pay/company/balance/allocated', {
      params: {
        refresh: payload?.refresh ?? 0,
        ...(payload.cardGroupId && { card_group_id: payload.cardGroupId })
      }
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetAllocatedBalanceMapper.toDomain(data);
  }

  async getBalanceSolicitationsStaged(): Promise<IBalanceSolicitation[]> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getBalanceSolicitationsStagedMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceBalanceSolicitationApprovedSummary[]>
    >('/pay/users/form-cache/balance_solicitations');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data?.map(BalanceSolicitationApprovedStagedMapper.toDomain) ?? null;
  }

  async getBalanceSolicitations(
    payload: IGetBalanceSolicitationsPayload
  ): Promise<IGetBalanceSolicitations> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getBalanceSolicitationsMock();
    }

    const response = await api.get<
      IDefaultResponse<IObjectsArray<IPersistenceBalanceSolicitation> | null>
    >(
      payload.uuidCardGroup
        ? `/pay/v2/app/card-groups/${payload.uuidCardGroup}/balance-solicitations`
        : '/pay/accounts/solicitations/balances',
      {
        params: {
          ...payload,
          date_type: payload.dateType ?? null,
          start_date: payload.startDate ?? null,
          end_date: payload.endDate ?? null,
          status:
            payload.status === 'ALL'
              ? ['SENT', 'APPROVED', 'REJECTED']
              : [payload.status]
        }
      }
    );

    const { data, total } = ResponseAdapter.formatObjectsArrayResponse(
      response.data
    );

    return {
      data: data?.map(BalanceSolicitationMapper.toDomain) ?? [],
      total: total ?? 0
    };
  }

  async getMyValueSolicitations(
    payload: IGetMyValueSolicitationsPayload
  ): Promise<IGetBalanceSolicitations> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getMyValueSolicitationsMock();
    }

    const response = await api.get<
      IDefaultResponse<IObjectsArray<IPersistenceBalanceSolicitation> | null>
    >('/pay/v2/app/accounts/solicitations/balances/logged-user-solicitations', {
      params: MyValueSolicitationsMapper.toPersistence(payload)
    });

    const { data, total } = ResponseAdapter.formatObjectsArrayResponse(
      response.data
    );

    return {
      data: data?.map(MyValueSolicitationsMapper.toDomain) ?? [],
      total: total ?? 0
    };
  }

  async sendBalanceSolicitationToCache(
    payload: IBalanceSolicitationCachePayload
  ): Promise<IDomainResponse<null>> {
    const response = await api.post<IDefaultResponse<null>>(
      '/pay/users/form-cache',
      BalanceSolicitationApprovedStagedMapper.toPersistence(payload)
    );

    return ResponseAdapter.formatRegularResponse(response.data);
  }

  async sendBalanceSolicitationApproved(
    payload: IBalanceSolicitationApprovedPayload[]
  ): Promise<IBalanceSolicitationApproved> {
    const response = await api.post<
      IDefaultResponse<IPersistenceBalanceSolicitationApproved>
    >('/pay/accounts/solicitations/balances/approve', payload);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return BalanceSolicitationApprovedMapper.toDomain(data);
  }

  async removeAllBalanceSolicitationsCache(): Promise<IDomainResponse<null>> {
    const response = await api.delete<IDefaultResponse<null>>(
      '/pay/users/form-cache/balance_solicitations'
    );

    return ResponseAdapter.formatRegularResponse(response.data);
  }

  async getModifiedBalanceAllocationItemsFromCache(): Promise<
    IModifiedBalanceAllocationItem[] | null
  > {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getModifiedBalanceAllocationItemsFromCacheMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceModifiedBalanceAllocationItem[] | null>
    >('/pay/users/form-cache/balance_allocation');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data?.map(ModifiedBalanceAllocationItemMapper.toDomain) ?? null;
  }

  async sendModifiedBalanceAllocationItemsToCache({
    balanceAllocationItems
  }: ISendModifiedBalanceAllocationItemsToCachePayload): Promise<null> {
    const response = await api.post<IDefaultResponse<null>>(
      '/pay/users/form-cache',
      SendModifiedBalanceAllocationItemsToCacheMapper.toPersistence(
        balanceAllocationItems
      )
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async deleteModifiedBalanceAllocationItemsFromCache(): Promise<null> {
    const response = await api.delete<IDefaultResponse<null>>(
      '/pay/users/form-cache/balance_allocation'
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async transferBalanceAllocationItems({
    balanceAllocationItems
  }: ITransferBalanceAllocationItemsPayload): Promise<ITransferredBalanceAllocationItems> {
    const response = await api.post<
      IDefaultResponse<IPersistenceTransferredBalanceAllocationItems>
    >(
      '/pay/company/transfers',
      balanceAllocationItems.map(
        TransferBalanceAllocationItemsMapper.toPersistence
      )
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return TransferBalanceAllocationItemsMapper.toDomain(data);
  }

  async requestBalance({
    amount,
    accountBalanceId,
    justify,
    datePrevision
  }: IRequestBalancePayload): Promise<IRequestBalance> {
    const response = await api.post<
      IDefaultResponse<IPersistenceRequestBalance>
    >('/pay/accounts/solicitations/balances', {
      balance_account_id: accountBalanceId,
      amount,
      justify,
      date_prevision: datePrevision
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return RequestBalanceMapper.toDomain(data);
  }

  async requestCards(payload: IRequestCardsPayload): Promise<null> {
    const response = await api.post<IDefaultResponse<null>>(
      '/pay/accounts/solicitations/cards',
      RequestCardsMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  /**
   * Recupera a string que vai gerar o QR code de depósito.
   */
  async getPixQrCodeString(): Promise<IPixQrCode | null> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getPixQrCodeStringMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistencePixQrCodeString>
    >('/pay/company/qr-code');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return PixQrCodeMapper.toDomain(data);
  }

  /**
   * Solicita um novo QR Code para depósito via PIX
   */
  async requestNewPixQrCode(): Promise<IPixQrCode | null> {
    const response = await api.post<
      IDefaultResponse<IPersistencePixQrCodeString>
    >('/pay/company/qr-code');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return PixQrCodeMapper.toDomain(data);
  }

  /**
   * Realiza um pedido de retirada de saldo da conta da empresa para uma conta corrente
   */
  async requestWithdrawal(payload: IRequestWithdrawalPayload): Promise<null> {
    const response = await api.post<IDefaultResponse<null>>(
      '/pay/company/balance/withdrawal',
      RequestWithdrawalMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async getQuantityOfBalanceSolicitationByStatus(
    payload: IQuantityOfBalanceSolicitationByStatusPayload
  ): Promise<IQuantityOfBalanceSolicitationByStatus> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getQuantityOfBalanceSolicitationByStatusMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceQuantityOfBalanceSolicitationByStatus>
    >('/pay/v2/app/accounts/solicitations/balances/quantity', {
      params: {
        card_group_id: payload.cardGroupId || null,
        balance_id: payload.balanceId || null
      }
    });

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetQuantityOfBalanceSolicitationByStatusMapper.toDomain(data);
  }

  async getBalancesAndAccount(
    params: IBalancesAndAccountPayload
  ): Promise<IBalancesAndAccount> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getBalancesAndAccountMock();
    }

    const response = await api.get<
      IDefaultResponse<IPersistenceBalancesAndAccount>
    >(`/pay/accounts/balances/${params.balanceId}`);

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return GetBalanceShowMapper.toDomain(data);
  }
}

export default new BalanceService();
