import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IUseCreatePixKey,
  type IUseCreatePixKeyParamas,
  type UpdateCompanyOnboardingAnswersPayloadType
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';
import { Onboarding } from 'shared/utils/cards';

export function useCreatePixKey({
  onUpdatePixKeyMutationSuccess
}: IUseCreatePixKeyParamas): IUseCreatePixKey {
  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS],
      query: async () => await CompanyOnboardingService.getProgress()
    }
  });

  const { isLoading, mutate } = useMutationCache({
    mutationKey: [
      CompanyOnboardingMutationKeys.UPDATE_COMPANY_ONBOARDING_ANSWERS
    ],
    mutationFn: async (payload: UpdateCompanyOnboardingAnswersPayloadType) => {
      await CompanyOnboardingService.updateCompanyOnboardingAnswers(payload);
    },
    onSuccess: () => {
      onUpdatePixKeyMutationSuccess();
    },
    onError: (error: Error) => {
      toast.error(error.message);
    }
  });

  function onSubmit(data: boolean): void {
    if (isLoading) {
      return;
    }

    mutate({
      uuidCompanyOnboarding:
        cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '',
      step: currentStep,
      context: 'configuration',
      answers: {
        usePix: data
      }
    });
  }

  return {
    handleUpdatePixKey: onSubmit,
    isUpdatingCompanyPoliciesAndTerms: isLoading
  };
}
