import { DefaultButton, DSIcons, SecondaryButton, TextButton } from 'ds';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  useAuthContext,
  useCardDetailsContext,
  useCardGroupsContext,
  useLangContext
} from 'data/contexts';
import { useGetCardDataByAccountId } from 'data/modules/cards/account';
import { useGetCardGroup } from 'data/modules/cards/account/useCases/get-card-group/useGetCardGroup';
import { useGetBalancesAndAccount } from 'data/modules/cards/balance';

import { CreditCard } from 'presentation/components/global/CreditCard';

import { useCardAndControllers } from './useCardAndControllers';

import { type ICardAndControllersProps } from './CardAndControllers.types';

import { Container, DangerSecondaryButton } from './CardAndControllers.styles';

export function CardAndControllers({
  onAddValue,
  onRemoveValue,
  onChangeCardStatus,
  onManageCard
}: ICardAndControllersProps): JSX.Element {
  const { groupId } = useParams();

  const {
    currentLangKey,
    lang: {
      cards: { valueAllocation, global }
    }
  } = useLangContext();

  const [selectedAccount] = useCardDetailsContext(
    useShallow(state => [state.selectedAccount])
  );

  const contextCardGroup = useCardGroupsContext(
    state => state.selectedCardGroup
  );

  const { cardGroupData } = useGetCardGroup({
    cardGroupId: groupId || '',
    enabled: !contextCardGroup
  });

  const user = useAuthContext(state => state.user);

  const { balancesAndAccount, isFetchingBalancesAndAccount } =
    useGetBalancesAndAccount({
      balanceId: selectedAccount?.balanceId as string,
      enabled: !selectedAccount?.accountId && !!selectedAccount?.balanceId
    });

  const { cardData, isFetchingCardData } = useGetCardDataByAccountId({
    accountId: selectedAccount?.accountId
      ? selectedAccount?.accountId
      : (balancesAndAccount?.account.id as string),
    enabled: true
  });

  const { isFetchingAccountBalancesAmounts } = useCardAndControllers();

  return (
    <Container>
      <CreditCard
        cardInfo={{
          name: selectedAccount?.name || '',
          availableBalance: selectedAccount?.availableBalance ?? '',
          number: cardData?.panMasked || '',
          status: cardData?.status || null
        }}
        isLoading={
          isFetchingCardData ||
          isFetchingAccountBalancesAmounts ||
          isFetchingBalancesAndAccount
        }
      />

      {(user?.cards?.type === 'ACCOUNT_MANAGER' ||
        (cardGroupData?.isManager && groupId)) && (
        <>
          <DefaultButton
            size='small'
            onClick={onAddValue}
            disabled={isFetchingCardData || isFetchingBalancesAndAccount}
          >
            {valueAllocation.actions.add_value[currentLangKey]}
          </DefaultButton>

          <SecondaryButton
            size='small'
            onClick={onRemoveValue}
            disabled={isFetchingCardData || isFetchingBalancesAndAccount}
          >
            {valueAllocation.actions.remove_value[currentLangKey]}
          </SecondaryButton>

          {cardData?.status && (
            <DangerSecondaryButton
              size='small'
              onClick={onChangeCardStatus}
              disabled={isFetchingCardData || isFetchingBalancesAndAccount}
            >
              {cardData.status === 'ACTIVE'
                ? global.block_card[currentLangKey]
                : global.unblock_card[currentLangKey]}
            </DangerSecondaryButton>
          )}

          {selectedAccount?.type === 'BUSINESS' && (
            <TextButton
              size='small'
              onClick={onManageCard}
            >
              {global.manage_card[currentLangKey]} <DSIcons.ArrowRightIcon />
            </TextButton>
          )}
        </>
      )}
    </Container>
  );
}
