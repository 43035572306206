import { type IGenericTableProps } from './GenericTable.types';

import { Container } from './GenericTable.styles';

export function GenericTable({
  thead,
  tbody,
  tableBackgroundColor = 'white',
  showHeaderOnMobile = true
}: IGenericTableProps): JSX.Element {
  return (
    <Container
      $tableBackgroundColor={tableBackgroundColor}
      $showHeaderOnMobile={showHeaderOnMobile}
    >
      <table>
        <thead>{thead}</thead>
        <tbody>{tbody}</tbody>
      </table>
    </Container>
  );
}
