import {
  type BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import { type Library } from '@googlemaps/js-api-loader';
import { useLoadScript } from '@react-google-maps/api';
import { useIsFetching } from '@tanstack/react-query';
import { toast } from 'ds/utils';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { env } from 'data/config';
import { useLangContext, useModalContext } from 'data/contexts';
import { CostCentersQueryKeys } from 'data/modules/costsCenters';
import { CurrenciesQueryKeys } from 'data/modules/currencies';
import {
  ExpensesQueryKeys,
  type ICreateRouteByMapDefaultFieldsForm,
  type IDetailedExpense,
  useCreateRouteByMap,
  useGetExpenseParameters
} from 'data/modules/expenses';
import { useGetExpenseDetails } from 'data/modules/expenses/useCases/get-expense-details/useGetExpenseDetails';
import { PaymentMethodsQueryKeys } from 'data/modules/paymentMethods';
import {
  type ReportInformationType,
  useGetOpenReports
} from 'data/modules/reports';

import { type ReadOnlyFieldsWhenUserIsUpdatingMapExpenseStateType } from 'presentation/pages/expenses/ExpensesList/components/Modal/CreateManualExpenseModal/CreateManualExpenseModal/CreateManualExpenseModal.types';
import { useRouteByMapModalTour } from 'presentation/pages/expenses/ExpensesList/components/Modal/CreateRouteByMapModal/tours';

import { routesPathPrefix } from 'shared/constants/global';
import { Currency } from 'shared/utils/format';
import { FreddySurvey, MockApi } from 'shared/utils/global';

import {
  type ICreateRouteByMapExpenseModalProps,
  type IUseCreateRouteByMapModal,
  type MapDirectionsResultsType,
  type StepType
} from './CreateRouteByMapModal.types';
import { type IRouteInputItem } from 'ds/components/Input/RouteInput/RouteInput.types';

export function useCreateRouteByMapModal({
  expenseUuidToUpdate
}: ICreateRouteByMapExpenseModalProps): IUseCreateRouteByMapModal {
  const { lang, currentLangKey } = useLangContext();

  const { t } = useTranslation('expenses');

  const [step, setStep] = useState<StepType>('recordRoute');

  const { handleOpenModal, handleCloseModal, handleChangeErrorMessage } =
    useModalContext();

  const [showGoBackModal, setShowGoBackModal] = useState(false);

  const [showSeparateReportWarningModal, setShowSeparateReportWarningModal] =
    useState(false);

  const [showCreateReportForm, setShowCreateReportForm] = useState(false);

  const userAction = expenseUuidToUpdate ? 'update' : 'create';

  const { expenseParameters } = useGetExpenseParameters();

  const navigate = useNavigate();

  const [
    readOnlyFieldsWhenUserIsUpdatingExpense,
    setReadOnlyFieldsWhenUserIsUpdatingExpense
  ] = useState<ReadOnlyFieldsWhenUserIsUpdatingMapExpenseStateType>(new Set());

  const { getEnsuredExpenseDetails, isFetchingAndPendingExpenseDetails } =
    useGetExpenseDetails({
      expenseUuid: expenseUuidToUpdate ?? '',
      enabled: false
    });

  function applyReadOnlyFieldsWhenUserIsUpdatingExpense(
    expenseToUpdate: IDetailedExpense
  ): void {
    if (userAction === 'create') {
      return;
    }

    const readOnlyFields: ReadOnlyFieldsWhenUserIsUpdatingMapExpenseStateType =
      new Set();

    if (
      expenseParameters?.companyApprovalType === 'CC' &&
      expenseToUpdate.report !== null
    ) {
      readOnlyFields.add('costsCenter');
    }

    setReadOnlyFieldsWhenUserIsUpdatingExpense(readOnlyFields);
  }

  async function loadExpenseAndPrePopulateFieldsFromUpdate(): Promise<void> {
    if (expenseUuidToUpdate === undefined) {
      return;
    }

    const expenseToUpdate = await getEnsuredExpenseDetails({
      expenseUuid: expenseUuidToUpdate
    });

    if (expenseToUpdate.routePoints) {
      const routes = expenseToUpdate.routePoints?.map(item => {
        return {
          id: item.placeId,
          label:
            lang.expenses.modal.create_route_by_map.add_new_place[
              currentLangKey
            ],
          place: {
            name: item.search,
            formatted_address: item.search,
            place_id: item.placeId
          }
        };
      });

      setMapRouteItems(routes);
    }

    const valuesFromExpenseToUpdate:
      | ICreateRouteByMapDefaultFieldsForm
      | undefined =
      expenseToUpdate !== undefined
        ? {
            description: expenseToUpdate.title,
            currency: expenseToUpdate.currency.id,
            value: Currency.format('BRL', expenseToUpdate.value),
            date: expenseToUpdate.date,
            costsCenter:
              expenseToUpdate.costsCenter !== null
                ? {
                    label: expenseToUpdate.costsCenter?.name,
                    value: expenseToUpdate.costsCenter?.id
                  }
                : undefined,
            paymentMethod: expenseToUpdate.paymentMethod?.id ?? '',
            refundable: expenseToUpdate.isReimbursable,
            observation: expenseToUpdate.observation ?? '',
            mileage: expenseToUpdate.kilometrage
              ? Currency.format(
                  'BRL',
                  Currency.parseToFloat(expenseToUpdate.kilometrage)
                )
              : '',
            mileagePaidValue: expenseToUpdate.amountPerKilometer
              ? Currency.format(
                  'BRL',
                  Currency.parseToFloat(expenseToUpdate.amountPerKilometer, 4),
                  false,
                  4
                )
              : '',
            apportionment:
              expenseToUpdate.apportionments?.map(item => ({
                percentage: item.percentage,
                project: {
                  label: item.project.name,
                  value: item.project.id
                },
                value: item.value
              })) ?? []
          }
        : undefined;

    defaultFieldsForm.methods.reset(valuesFromExpenseToUpdate);
    reportSelectionForm.methods.reset({
      report: expenseToUpdate.report?.uuid ?? ''
    });

    applyReadOnlyFieldsWhenUserIsUpdatingExpense(expenseToUpdate);
  }

  const [mapRouteItems, setMapRouteItems] = useState<IRouteInputItem[]>([
    {
      id: MockApi.fakeUuid(),
      label:
        lang.expenses.modal.create_route_by_map.add_new_place[currentLangKey],
      place: null
    },
    {
      id: MockApi.fakeUuid(),
      label:
        lang.expenses.modal.create_route_by_map.add_new_place[currentLangKey],
      place: null
    }
  ]);

  const [mapDirectionsResults, setMapDirectionsResults] =
    useState<MapDirectionsResultsType>();

  const places: Library[] = useMemo(() => {
    return ['places'];
  }, []);

  const mapIconsToGenerateRouteImage = useMemo(() => {
    const url = window.location.host;
    const protocol = window.location.protocol;

    // Se estiver em localhost, usa os ícones padrões do google, porque o caminho não é público
    if (url.includes('localhost')) {
      return {
        initialIcon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
        finalIcon: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
        waypointIcon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
      };
    }

    return {
      initialIcon: `${protocol}//${url}/map_initial.png`,
      finalIcon: `${protocol}//${url}/map_final.png`,
      waypointIcon: `${protocol}//${url}/map_waypoint.png`
    };
  }, []);

  async function generateRouteImage(): Promise<File | null> {
    if (mapDirectionsResults?.routes?.length) {
      const allRoutePoints = mapDirectionsResults.routes[0].overview_path.map(
        point => [point.lat(), point.lng()]
      );

      const extraMarkersGeolocations: {
        lat: number;
        lng: number;
      }[] = [];

      // se tiver mais de 2 pontos, tem pontos extras
      if (mapRouteItems.length > 2) {
        // remove o primeiro e o último item do array
        const extraItems = mapRouteItems.slice(1, mapRouteItems.length - 1);

        extraItems.forEach(item => {
          if (item.place?.geometry?.location) {
            extraMarkersGeolocations.push({
              lat: item.place.geometry.location.lat(),
              lng: item.place.geometry.location.lng()
            });
          }
        });
      }

      const initialPoint = `${allRoutePoints[0][0]},${allRoutePoints[0][1]}`;
      const finalPoint = `${allRoutePoints[allRoutePoints.length - 1][0]},${
        allRoutePoints[allRoutePoints.length - 1][1]
      }`;
      const allRoutePointsLength = allRoutePoints.length;

      const extraMarkersLength = extraMarkersGeolocations.length;

      let formatedPoints: string[] = [];
      // essa lógica é para diminuir a quantidade de pontos do mapa e foi retirada (e adaptada) do serviço de geração de imagem feito em python
      if (allRoutePointsLength > 340 - extraMarkersLength * 89) {
        const filteredPoints = allRoutePoints.filter(
          (point, n) =>
            n %
              Math.round(
                allRoutePointsLength / (340 - extraMarkersLength * 89)
              ) ===
            0
        );
        filteredPoints[filteredPoints.length - 1] =
          allRoutePoints[allRoutePoints.length - 1];
        formatedPoints = filteredPoints.map(
          point => `|${point[0].toFixed(5)},${point[1].toFixed(5)}`
        );
      } else if (allRoutePointsLength > 246 - extraMarkersLength * 89) {
        formatedPoints = allRoutePoints.map(
          point => `|${point[0].toFixed(5)},${point[1].toFixed(5)}`
        );
      } else {
        formatedPoints = allRoutePoints.map(
          point => `|${point[0].toFixed(9)},${point[1].toFixed(9)}`
        );
      }
      const path = formatedPoints.join('');

      let extraMarkersToAddOnUrl = '';
      for (const extraMarker of extraMarkersGeolocations) {
        extraMarkersToAddOnUrl += `&markers=icon:${
          mapIconsToGenerateRouteImage.waypointIcon
        }|${extraMarker.lat.toFixed(5)},${extraMarker.lng.toFixed(5)}`;
      }

      const mapImageUrl = `https://maps.googleapis.com/maps/api/staticmap?size=640x640${extraMarkersToAddOnUrl}&markers=icon:${mapIconsToGenerateRouteImage.initialIcon}|${initialPoint}&markers=icon:${mapIconsToGenerateRouteImage.finalIcon}|${finalPoint}&path=color:0x07589fff|geodesic:true|weight:7${path}&path=color:0x62b2f8ff|geodesic:true|weight:5${path}&key=${env.VITE_MAPS_API}`;

      try {
        const response = await fetch(mapImageUrl);
        const blob = await response.blob();

        const file = new File([blob], 'map.png', {
          type: 'image/png'
        });

        return file;
      } catch (e) {
        toast.error(
          lang.expenses.modal.create_route_by_map.error_generating_image[
            currentLangKey
          ]
        );
        return null;
      }
    }

    return null;
  }

  const [
    confirmedReportInformationBySelectedReport,
    setConfirmedReportInformationBySelectedReport
  ] = useState<ReportInformationType | null>(null);

  function handleUpdateExpenseDataBySelectedReport(
    data: ReportInformationType | null
  ): void {
    setConfirmedReportInformationBySelectedReport(data);
  }

  const {
    allowUserChangeExpenseProjectByReportProject,
    defaultFieldsForm,
    isCreatingRouteByMap,
    isLoadingExpenseParameters,
    reportSelectionForm,
    isUpdatingRouteByMap
  } = useCreateRouteByMap({
    generateRouteImage,
    expenseUuidToUpdate,
    onAfterSubmitDefaultFieldsForm: () => {
      setStep('selectReport');
    },
    onSuccessUpdateRouteByMap: () => {
      handleCloseModal();
      toast.success(t('messages.expenseMapUpdatedSuccessfully'));
    },
    onSuccessCreateRouteByMap: () => {
      handleCloseModal();
      toast.success(
        lang.expenses.modal.create_route_by_map
          .route_by_map_created_successfully[currentLangKey]
      );

      FreddySurvey.show();
    },
    onErrorCreateOrUpdateRouteByMapExpense: (message: string) => {
      handleChangeErrorMessage({
        title: expenseUuidToUpdate
          ? t('modal.errorWhenUpdatingExpense')
          : t('modal.errorWhenCreatingExpense'),
        message
      });
    },
    places: mapRouteItems.map(item => {
      return {
        placeId: item.place?.place_id,
        search: item.place?.formatted_address
      };
    }),
    mapDirectionsResults,
    confirmedReportInformationBySelectedReport
  });

  const {
    methods: {
      formState: { isDirty, errors }
    }
  } = defaultFieldsForm;

  const checkIfDateFieldHasError =
    errors.date !== undefined && errors.date.type === 'validated-by-project';

  const isValidatingDateLimitsByProject =
    useIsFetching({
      predicate: query => {
        return (
          query.queryKey[0] ===
            ExpensesQueryKeys.VALIDATE_DATE_LIMITS_BY_PROJECT &&
          query.state.status === 'pending'
        );
      }
    }) > 0;

  const isValidatingMileagePolicy =
    useIsFetching({
      predicate: query => {
        return (
          (query.queryKey[0] ===
            ExpensesQueryKeys.VALIDATE_MILEAGE_POLICY_BY_PROJECT ||
            query.queryKey[0] ===
              ExpensesQueryKeys.VALIDATE_MILEAGE_POLICY_BY_USER) &&
          query.state.status === 'pending'
        );
      }
    }) > 0;

  const isLoadingSomeData =
    useIsFetching({
      predicate: query => {
        return (
          (query.queryKey[0] === ExpensesQueryKeys.GET_TYPES_OF_EXPENSES ||
            query.queryKey[0] === PaymentMethodsQueryKeys.GET_PAYMENT_METHODS ||
            query.queryKey[0] === CostCentersQueryKeys.GET_COST_CENTERS ||
            query.queryKey[0] === CurrenciesQueryKeys.GET_CURRENCIES) &&
          query.state.status === 'pending'
        );
      }
    }) > 0 ||
    isLoadingExpenseParameters ||
    isValidatingDateLimitsByProject ||
    isValidatingMileagePolicy ||
    isFetchingAndPendingExpenseDetails;

  const { openReports } = useGetOpenReports();

  const selectedReport = useWatch({
    control: reportSelectionForm.methods.control,
    name: 'report'
  });

  const selectedReportCurrency = openReports?.find(
    ({ uuid }) => uuid === selectedReport
  )?.currency;

  function handleClearSelection(): void {
    reportSelectionForm.methods.setValue('report', '');

    setConfirmedReportInformationBySelectedReport(null);

    // quando limpar seleção de relatório na edição de despesa, habilita novamente o campo de centro de custos
    userAction === 'update' &&
      setReadOnlyFieldsWhenUserIsUpdatingExpense(oldState => {
        const newState = new Set(oldState);

        newState.delete('costsCenter');

        return newState;
      });
  }

  const selectedExpenseCurrencyId = defaultFieldsForm.selectedCurrencyId;

  const isExpenseCurrencyDifferentFromReportCurrency =
    selectedExpenseCurrencyId !== undefined &&
    selectedReportCurrency !== undefined &&
    selectedReportCurrency.id !== null &&
    String(selectedReportCurrency.id) !== selectedExpenseCurrencyId;

  const { isLoaded: isMapLoaded } = useLoadScript({
    googleMapsApiKey: env.VITE_MAPS_API,
    libraries: places,
    language: 'pt-br',
    region: 'pt-BR'
  });

  async function handleFirstStepGoBackButton(): Promise<void> {
    if (isDirty) {
      setShowGoBackModal(true);
      return;
    }

    if (userAction === 'update') {
      const expenseToUpdate = await getEnsuredExpenseDetails({
        expenseUuid: expenseUuidToUpdate as string
      });

      navigate(`${routesPathPrefix.expenses}/${expenseToUpdate.id}`);

      handleOpenModal('viewExpensesModal');
      return;
    }

    if (mapRouteItems.some(item => item.place !== null)) {
      setShowGoBackModal(true);
      return;
    }

    handleOpenModal('selectExpenseToAdd');
  }

  async function handleConfirmGoBackModal(): Promise<void> {
    if (userAction === 'update') {
      const expenseToUpdate = await getEnsuredExpenseDetails({
        expenseUuid: expenseUuidToUpdate as string
      });

      navigate(`${routesPathPrefix.expenses}/${expenseToUpdate.id}`);

      handleOpenModal('viewExpensesModal');
      return;
    }

    handleOpenModal('selectExpenseToAdd');
  }

  function handleClickGoBackButton(): void {
    if (step === 'recordRoute') {
      handleFirstStepGoBackButton();
      return;
    }

    if (step === 'defaultFields') {
      setStep('recordRoute');
      return;
    }

    if (step === 'selectReport') {
      setStep('defaultFields');

      if (isExpenseCurrencyDifferentFromReportCurrency) {
        defaultFieldsForm.methods.setError(
          'currency',
          {
            type: 'custom',
            message:
              lang.expenses.modal.different_currency_error[currentLangKey]
          },
          {
            shouldFocus: true
          }
        );
      }
    }
  }

  function handleClickOverlay(): void {
    if (isDirty) {
      setShowGoBackModal(true);
      return;
    }

    handleCloseModal();
  }

  function handleChangeFormExpenseCurrency(currencyId: string): void {
    defaultFieldsForm.methods.setValue('currency', currencyId);

    if (
      selectedReportCurrency !== undefined &&
      selectedReportCurrency.id !== null
    ) {
      const reportCurrencyId = String(selectedReportCurrency.id);

      if (currencyId === reportCurrencyId) {
        defaultFieldsForm.methods.clearErrors('currency');
        return;
      }

      defaultFieldsForm.methods.setError('currency', {
        type: 'custom',
        message: lang.expenses.modal.different_currency_error[currentLangKey]
      });
    }
  }

  const recalculatePositions = useCallback(async () => {
    if (!isMapLoaded) {
      return;
    }

    if (!mapRouteItems.every(item => item.place !== null)) {
      setMapDirectionsResults(undefined);
      return;
    }

    const placeIds = mapRouteItems.map(item => item.place?.place_id);

    if (
      placeIds.length !== new Set(placeIds).size &&
      mapRouteItems.length === 2
    ) {
      setMapDirectionsResults(undefined);
      toast.error(
        t('modal.uniqueAdressTitle'),
        undefined,
        t('modal.uniqueAdress')
      );
      return;
    }

    const directionsService = new google.maps.DirectionsService();

    const customItems = [...mapRouteItems].splice(1, mapRouteItems.length - 2);

    const waypoints: google.maps.DirectionsWaypoint[] = customItems.map(
      item => {
        return {
          location: {
            placeId: item.place?.place_id
          },
          stopover: true
        };
      }
    );

    try {
      const results = await directionsService.route({
        origin: mapRouteItems[0].place?.formatted_address as string,
        destination: mapRouteItems[mapRouteItems.length - 1].place
          ?.formatted_address as string,
        waypoints,
        region: 'pt-BR',
        travelMode: google.maps.TravelMode.DRIVING
      });

      // JSON anexado na doc da despesa para enviar pro back
      // Items.place contem o formatted_address e o place_id para enviar para o back
      setMapDirectionsResults(results);
    } catch (e) {
      setMapDirectionsResults(undefined);
      toast.warning(
        lang.expenses.modal.create_route_by_map.no_route_found[currentLangKey]
      );
    }
  }, [mapRouteItems, currentLangKey, lang, isMapLoaded, t]);

  useEffect(() => {
    recalculatePositions();
  }, [recalculatePositions]);

  const distance = mapDirectionsResults
    ? mapDirectionsResults.routes[0].legs.reduce(function (result, item) {
        return result + (item.distance?.value as number);
      }, 0) / 1000
    : 0;

  const advanceButtonDisabled =
    (step === 'recordRoute' &&
      (!isMapLoaded ||
        mapRouteItems.some(item => item.place === null) ||
        mapDirectionsResults === undefined)) ||
    (step === 'defaultFields' && isLoadingSomeData) ||
    (step === 'selectReport' && isExpenseCurrencyDifferentFromReportCurrency) ||
    checkIfDateFieldHasError;

  function handleSubmitAllStepsForm(e: BaseSyntheticEvent): void {
    e.preventDefault();

    if (step === 'recordRoute') {
      setStep('defaultFields');

      const adjustedDistance = Currency.roundToTwoDecimals(distance);

      defaultFieldsForm.methods.setValue(
        'mileage',
        Currency.format('BRL', adjustedDistance)
      );

      const mileagePaidValue =
        defaultFieldsForm.methods.getValues('mileagePaidValue');

      const mileagePaidValueAsNumber = Currency.parseToFloat(
        mileagePaidValue,
        4
      );

      const calculatedValue = Currency.format(
        'BRL',
        Currency.roundToTwoDecimals(adjustedDistance * mileagePaidValueAsNumber)
      );

      defaultFieldsForm.methods.setValue('value', calculatedValue);

      return;
    }

    if (step === 'defaultFields') {
      defaultFieldsForm.handleSubmit();
      return;
    }

    if (step === 'selectReport') {
      if (!selectedReport && userAction === 'create') {
        setShowSeparateReportWarningModal(true);
        return;
      }

      reportSelectionForm.handleSubmit();
    }
  }

  function updateFormAfterReportCreated(reportUuid: string): void {
    setShowCreateReportForm(false);
    setStep('selectReport');
    reportSelectionForm.methods.setValue('report', reportUuid);
  }

  useRouteByMapModalTour({
    step
  });

  useEffect(() => {
    if (expenseUuidToUpdate !== undefined && !isLoadingExpenseParameters) {
      loadExpenseAndPrePopulateFieldsFromUpdate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseUuidToUpdate, isLoadingExpenseParameters]);

  const showLoadingModal = isUpdatingRouteByMap || isCreatingRouteByMap;

  const defaultStepSelectedCostsCenterId =
    defaultFieldsForm.methods.getValues('costsCenter')?.value ?? '';

  const defaultStepSelectedPaymentMethodId =
    defaultFieldsForm.methods.getValues('paymentMethod') ?? '';

  const apportionmentValue =
    defaultFieldsForm.methods.getValues('apportionment');

  const defaultStepSelectedProjectId =
    apportionmentValue !== undefined && apportionmentValue.length === 1
      ? apportionmentValue[0]?.project?.value ?? ''
      : '';

  return {
    setShowCreateReportForm,
    setShowGoBackModal,
    setShowSeparateReportWarningModal,
    setStep,
    showCreateReportForm,
    showGoBackModal,
    showSeparateReportWarningModal,
    step,
    handleClickGoBackButton,
    isMapLoaded,
    mapDirectionsResults,
    distance,
    mapRouteItems,
    setMapRouteItems,
    advanceButtonDisabled,
    handleClearSelection,
    confirmedReportInformationBySelectedReport,
    handleUpdateExpenseDataBySelectedReport,
    allowUserChangeExpenseProjectByReportProject,
    defaultFieldsForm,
    selectedReport,
    isCreatingRouteByMap,
    reportSelectionForm,
    handleSubmitAllStepsForm,
    updateFormAfterReportCreated,
    isExpenseCurrencyDifferentFromReportCurrency,
    handleChangeFormExpenseCurrency,
    readOnlyFieldsWhenUserIsUpdatingExpense,
    showLoadingModal,
    userAction,
    handleClickOverlay,
    defaultStepSelectedCostsCenterId,
    defaultStepSelectedPaymentMethodId,
    defaultStepSelectedProjectId,
    handleConfirmGoBackModal
  };
}
