import { useEffect, useRef, useState } from 'react';

import { type IUseGrabAndScroll } from './useGrabAndScroll.types';

export function useGrabAndScroll<
  T extends HTMLElement = HTMLElement
>(): IUseGrabAndScroll<T> {
  const grabAndScrollElement = useRef<T>(null);

  let position = {
    top: 0,
    left: 0,
    x: 0,
    y: 0
  };

  function mouseMoveHandler(e: MouseEvent): void {
    e.preventDefault();

    const dx = e.clientX - position.x;
    const dy = e.clientY - position.y;

    if (grabAndScrollElement.current) {
      grabAndScrollElement.current.scrollTop = position.top - dy;
      grabAndScrollElement.current.scrollLeft = position.left - dx;
    }
  }

  function mouseUpHandler(e: MouseEvent): void {
    e.preventDefault();

    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    if (grabAndScrollElement.current) {
      grabAndScrollElement.current.style.cursor = 'grab';
      grabAndScrollElement.current.style.removeProperty('user-select');
    }
  }

  function mouseDownHandler(e: MouseEvent): void {
    e.preventDefault();

    if (grabAndScrollElement.current) {
      grabAndScrollElement.current.style.cursor = 'grabbing';
      grabAndScrollElement.current.style.userSelect = 'none';
    }

    position = {
      left: grabAndScrollElement?.current?.scrollLeft ?? 0,
      top: grabAndScrollElement?.current?.scrollTop ?? 0,
      x: e.clientX,
      y: e.clientY
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }

  useEffect(() => {
    if (grabAndScrollElement.current) {
      grabAndScrollElement.current.scrollTop = 100;
      grabAndScrollElement.current.scrollLeft = 150;

      grabAndScrollElement.current.addEventListener(
        'mousedown',
        mouseDownHandler
      );

      return () => {
        grabAndScrollElement.current &&
          grabAndScrollElement.current.removeEventListener(
            'mousedown',
            mouseDownHandler
          );
      };
    }

    // eslint-disable-next-line
  }, [grabAndScrollElement.current]);

  return {
    ref: grabAndScrollElement
  };
}
