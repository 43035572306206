import { DefaultTable, DSIcons } from 'ds';

import { useCardGroupsContext, useModalContext } from 'data/contexts';

import { Currency } from 'shared/utils/format';

import { type ICardGroupsTableRowProps } from './CardGroupsTableRow.types';

import { StyledRow } from './CardGroupsTableRow.styles';

export function CardGroupsTableRow({
  cardGroup,
  isFetchingAmounts,
  ...rest
}: ICardGroupsTableRowProps): JSX.Element {
  const { handleOpenModal } = useModalContext();
  const handleSelectCardGroup = useCardGroupsContext(
    state => state.handleSelectCardGroup
  );

  return (
    <StyledRow
      {...rest}
      onClick={() => {
        if (isFetchingAmounts) return;

        handleSelectCardGroup(cardGroup);
        handleOpenModal('groupCardDetails');
      }}
    >
      <DefaultTable.Td title={cardGroup.name}>{cardGroup.name}</DefaultTable.Td>

      <DefaultTable.Td isLoading={isFetchingAmounts}>
        {Currency.format('BRL', cardGroup.availableValue ?? 0, true)}
      </DefaultTable.Td>

      <DefaultTable.Td isLoading={isFetchingAmounts}>
        <DSIcons.SettingsIcon />
      </DefaultTable.Td>
    </StyledRow>
  );
}
