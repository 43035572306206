import {
  type IUpdateVehiclePayload,
  type IUpdateVehiclePayloadPersistence
} from 'data/modules/fuel/types/fuel.types';

class UpdateVehicleMapper {
  toPersistence(
    payload: IUpdateVehiclePayload
  ): IUpdateVehiclePayloadPersistence {
    return {
      fuel_ids: payload.fuel,
      model: payload.model,
      plate: payload.plate,
      owner: payload.owner,
      vehicle_type_id: payload.type
    };
  }
}

export default new UpdateVehicleMapper();
