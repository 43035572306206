import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (min-width: 834px) {
    grid-column: span 2;
  }
`;

export const Title = styled.h3`
  line-height: 3.2rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightSemibold};
`;

export const Description = styled.p`
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowMedium};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamilyPoppins};
`;
