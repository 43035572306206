import {
  type IDataToRepresentativeApproval,
  type IPersistenceDataToRepresentativeApproval
} from 'data/modules/cards/companyOnboarding';

class GetDataToRepresentativeApprovalMapper {
  toDomain(
    persistenceLegalRepresentativeToApproval: IPersistenceDataToRepresentativeApproval
  ): IDataToRepresentativeApproval {
    return {
      answerableName: persistenceLegalRepresentativeToApproval.answerable_name,
      legalRepresentativeName:
        persistenceLegalRepresentativeToApproval.legal_representative_name
    };
  }
}

export default new GetDataToRepresentativeApprovalMapper();
