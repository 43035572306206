import { DefaultTable } from 'ds';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'data/contexts';

import { useUserAnalysisExpensesTable } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisExpensesTable/useUserAnalysisExpensesTable';
import { UserAnalysisExpensesTableRow } from 'presentation/pages/analytics/Analysis/UserExpenses/components/UserAnalysisExpensesTableRow';

import { type IUserAnalysisExpensesTableProps } from './UserAnalysisExpensesTable.types';

import { TableWrapper } from './UserAnalysisExpensesTable.styles';

export function UserAnalysisExpensesTable({
  expenses
}: IUserAnalysisExpensesTableProps): JSX.Element {
  const { t } = useTranslation(['global', 'analytics']);
  const { handleTableRowClick } = useUserAnalysisExpensesTable({ expenses });

  const user = useAuthContext(state => state.user);

  return (
    <TableWrapper>
      <DefaultTable.Container>
        <DefaultTable.Thead>
          <DefaultTable.Tr>
            <DefaultTable.Th width='5%' />

            <DefaultTable.Th>{t('global:date')}</DefaultTable.Th>

            {user?.parameters?.hasOutsourcingAccess && (
              <DefaultTable.Th>
                {t('analytics:userResume.creatorUser')}
              </DefaultTable.Th>
            )}

            <DefaultTable.Th>{t('global:title')}</DefaultTable.Th>

            <DefaultTable.Th>{t('global:type')}</DefaultTable.Th>

            <DefaultTable.Th>{t('global:costCenter')}</DefaultTable.Th>

            <DefaultTable.Th>{t('global:projects')}</DefaultTable.Th>

            <DefaultTable.Th>{t('global:payment')}</DefaultTable.Th>

            <DefaultTable.Th>{t('global:amount')}</DefaultTable.Th>

            <DefaultTable.Th width='5%'>Obs.</DefaultTable.Th>
          </DefaultTable.Tr>
        </DefaultTable.Thead>

        <DefaultTable.Tbody>
          {expenses.map((expense, idx) => (
            <UserAnalysisExpensesTableRow
              {...expense}
              key={expense.expenseId}
              showUserColumn={user?.parameters?.hasOutsourcingAccess}
              onRowClick={() => {
                handleTableRowClick(idx);
              }}
            />
          ))}
        </DefaultTable.Tbody>
      </DefaultTable.Container>
    </TableWrapper>
  );
}
