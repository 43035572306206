import { api } from 'data/config';
import { ResponseAdapter } from 'data/global/adapters';
import { type IDefaultResponse } from 'data/modules/global';
import {
  type IRequestProductPayload,
  type IStoreProduct
} from 'data/modules/store';

import { RequestProductMapper } from './mappers';

class StoreService {
  async getStoreProducts(): Promise<IStoreProduct[]> {
    const response =
      await api.get<IDefaultResponse<IStoreProduct[]>>('/store/products');

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }

  async requestProduct(payload: IRequestProductPayload): Promise<null> {
    const response = await api.post<IDefaultResponse<null>>(
      '/store/products/request',
      RequestProductMapper.toPersistence(payload)
    );

    const { data } = ResponseAdapter.formatRegularResponse(response.data);

    return data;
  }
}

export default new StoreService();
