import { type ILegalNatureOption } from 'data/modules/auth';

/**
 * Lista de natureza jurídica da empresa
 */
export const legalNature: ILegalNatureOption[] = [
  {
    label: '101-5 - Órgão Público do Poder Executivo Federal',
    value: '101-5'
  },
  {
    label:
      '102-3 - Órgão Público do Poder Executivo Estadual ou do Distrito Federal',
    value: '102-3'
  },
  {
    label: '103-1 - Órgão Público do Poder Executivo Municipal',
    value: '103-1'
  },
  {
    label: '104-0 - Órgão Público do Poder Legislativo Federal',
    value: '104-0'
  },
  {
    label:
      '105-8 - Órgão Público do Poder Legislativo Estadual ou do Distrito Federal',
    value: '105-8'
  },
  {
    label: '106-6 - Órgão Público do Poder Legislativo Municipal',
    value: '106-6'
  },
  {
    label: '107-4 - Órgão Público do Poder Judiciário Federal',
    value: '107-4'
  },
  {
    label: '108-2 - Órgão Público do Poder Judiciário Estadual',
    value: '108-2'
  },
  {
    label: '110-4 - Autarquia Federal',
    value: '110-4'
  },
  {
    label: '111-2 - Autarquia Estadual ou do Distrito Federal',
    value: '111-2'
  },
  {
    label: '112-0 - Autarquia Municipal',
    value: '112-0'
  },
  {
    label: '113-9 - Fundação Pública de Direito Público Federal',
    value: '113-9'
  },
  {
    label:
      '114-7 - Fundação Pública de Direito Público Estadual ou do Distrito Federal',
    value: '114-7'
  },
  {
    label: '115-5 - Fundação Pública de Direito Público Municipal',
    value: '115-5'
  },
  {
    label: '116-3 - Órgão Público Autônomo Federal',
    value: '116-3'
  },
  {
    label: '117-1 - Órgão Público Autônomo Estadual ou do Distrito Federal',
    value: '117-1'
  },
  {
    label: '118-0 - Órgão Público Autônomo Municipal',
    value: '118-0'
  },
  {
    label: '119-8 - Comissão Polinacional',
    value: '119-8'
  },
  {
    label: '121-0 - Consórcio Público de Direito Público  (Associação Pública)',
    value: '121-0'
  },
  {
    label: '122-8 - Consórcio Público de Direito Privado',
    value: '122-8'
  },
  {
    label: '123-6 - Estado ou Distrito Federal',
    value: '123-6'
  },
  {
    label: '124-4 - Município',
    value: '124-4'
  },
  {
    label: '125-2 - Fundação Pública de Direito Privado Federal',
    value: '125-2'
  },
  {
    label:
      '126-0 - Fundação Pública de Direito Privado Estadual ou do Distrito Federal',
    value: '126-0'
  },
  {
    label: '127-9 - Fundação Pública de Direito Privado Municipal',
    value: '127-9'
  },
  {
    label: '128-7 - Fundo Público da Administração Indireta	Federal',
    value: '128-7'
  },
  {
    label:
      '129-5 - Fundo Público da Administração Indireta Estadual ou do Distrito Federal',
    value: '129-5'
  },
  {
    label: '130-9 - Fundo Público da Administração Indireta	Municipal',
    value: '130-9'
  },
  {
    label: '131-7 - Fundo Público da Administração Direta Federal',
    value: '131-7'
  },
  {
    label:
      '132-5 - Fundo Público da Administração Direta Estadual ou do Distrito Federal',
    value: '132-5'
  },
  {
    label: '133-3 - Fundo Público da Administração Direta	Municipal',
    value: '133-3'
  },
  {
    label: '134-1 - União',
    value: '134-1'
  },
  {
    label: '201-1 - Empresa Pública',
    value: '201-1'
  },
  {
    label: '203-8 - Sociedade de Economia Mista',
    value: '203-8'
  },
  {
    label: '204-6 - Sociedade Anônima Aberta',
    value: '204-6'
  },
  {
    label: '205-4 - Sociedade Anônima Fechada',
    value: '205-4'
  },
  {
    label: '206-2 - Sociedade Empresária Limitada',
    value: '206-2'
  },
  {
    label: '207-0 - Sociedade Empresária em Nome Coletivo',
    value: '207-0'
  },
  {
    label: '208-9 - Sociedade Empresária em Comandita Simples',
    value: '208-9'
  },
  {
    label: '209-7 - Sociedade Empresária em Comandita por Ações',
    value: '209-7'
  },
  {
    label: '212-7 - Sociedade em Conta de Participação',
    value: '212-7'
  },
  {
    label: '213-5 - Empresário Individual',
    value: '213-5'
  },
  {
    label: '214-3 - Cooperativa',
    value: '214-3'
  },
  {
    label: '215-1 - Consórcio de Sociedades',
    value: '215-1'
  },
  {
    label: '216-0 - Grupo de Sociedades',
    value: '216-0'
  },
  {
    label: '221-6 - Empresa Domiciliada no Exterior',
    value: '221-6'
  },
  {
    label: '223-2 - Sociedade Simples Pura',
    value: '223-2'
  },
  {
    label: '224-0 - Sociedade Simples Limitada',
    value: '224-0'
  },
  {
    label: '225-9 - Sociedade Simples em Nome Coletivo',
    value: '225-9'
  },
  {
    label: '226-7 - Sociedade Simples em Comandita Simples',
    value: '226-7'
  },
  {
    label: '229-1 - Consórcio Simples',
    value: '229-1'
  },
  {
    label:
      '230-5 - Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)',
    value: '230-5'
  },
  {
    label:
      '231-3 - Empresa Individual de Responsabilidade Limitada (de Natureza Simples)',
    value: '231-3'
  },
  {
    label: '232-1 - Sociedade Unipessoal de Advogados',
    value: '232-1'
  },
  {
    label: '233-0 - Cooperativas de Consumo',
    value: '233-0'
  },
  {
    label: '303-4 - Serviço Notarial e Registral (Cartório)',
    value: '303-4'
  },
  {
    label: '306-9 - Fundação Privada',
    value: '306-9'
  },
  {
    label: '307-7 - Serviço Social Autônomo',
    value: '307-7'
  },
  {
    label: '308-5 - Condomínio Edilício',
    value: '308-5'
  },
  {
    label: '310-7 - Comissão de Conciliação Prévia',
    value: '310-7'
  },
  {
    label: '311-5 - Entidade de Mediação e Arbitragem',
    value: '311-5'
  },
  {
    label: '313-1 - Entidade Sindical',
    value: '313-1'
  },
  {
    label:
      '320-4 - Estabelecimento, no Brasil, de Fundação ou  Associação Estrangeiras',
    value: '320-4'
  },
  {
    label: '321-2 - Fundação ou Associação Domiciliada no Exterior',
    value: '321-2'
  },
  {
    label: '322-0 - Organização Religiosa',
    value: '322-0'
  },
  {
    label: '323-9 - Comunidade Indígena',
    value: '323-9'
  },
  {
    label: '324-7 - Fundo Privado',
    value: '324-7'
  },
  {
    label: '325-5 - Órgão de Direção Nacional de Partido Político',
    value: '325-5'
  },
  {
    label: '326-3 - Órgão de Direção Regional de Partido Político',
    value: '326-3'
  },
  {
    label: '327-1 - Órgão de Direção Local de Partido Político',
    value: '327-1'
  },
  {
    label: '328-0 - Comitê Financeiro de Partido Político',
    value: '328-0'
  },
  {
    label: '329-8 - Frente Plebiscitária ou Referendária',
    value: '329-8'
  },
  {
    label: '330-1 - Organização Social (OS)',
    value: '330-1'
  },
  {
    label: '399-9 - Associação Privada',
    value: '399-9'
  },
  {
    label: '401-4 - Empresa Individual Imobiliária',
    value: '401-4'
  },
  {
    label: '409-0 - Candidato a Cargo Político Eletivo',
    value: '409-0'
  },
  {
    label: '412-0 - Produtor Rural (Pessoa Física)',
    value: '412-0'
  },
  {
    label: '501-0 - Organização Internacional',
    value: '501-0'
  },
  {
    label: '502-9 - Representação Diplomática Estrangeira',
    value: '502-9'
  },
  {
    label: '503-7 - Outras Instituições Extraterritoriais',
    value: '503-7'
  }
];
