import { FormProvider } from 'react-hook-form';

import { type IModalFormProps } from './ModalForm.types';

import { Container } from './ModalForm.styles';

export function ModalForm({
  children,
  methods,
  handleSubmit,
  ...rest
}: IModalFormProps): JSX.Element {
  return (
    <FormProvider {...methods}>
      <Container
        {...rest}
        onSubmit={handleSubmit}
      >
        {children}
      </Container>
    </FormProvider>
  );
}
