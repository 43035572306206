import { useLangContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';
import { CentralMessage } from 'presentation/pages/cards/Statement/components/CentralMessage';
import { StatementTable } from 'presentation/pages/cards/Statement/components/Table';
import { DefaultLayout, TripleColumn } from 'presentation/templates';

import { UserStatementSidebar } from './components/Sidebar';
import { useUserStatement } from './useUserStatement';

export function UserStatement(): JSX.Element {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  const {
    filteredAccountStatement,
    isLoadingAccountStatement,
    setStatementFilters,
    statementFilters
  } = useUserStatement();

  document.title = `vexpenses | ${lang.cards.statement.page_title[currentLangKey]}`;

  const centerElementToRender = !isLoadingAccountStatement ? (
    filteredAccountStatement.length === 0 ? (
      <CentralMessage
        iconName='arrow-left-right'
        title={lang.cards.statement.no_transactions_found[currentLangKey]}
        description={
          lang.cards.statement.transactions_will_be_listed_here[currentLangKey]
        }
      />
    ) : (
      <StatementTable
        showDayBalance={true}
        data={filteredAccountStatement}
        type='account'
      />
    )
  ) : (
    <></>
  );

  return (
    <DefaultLayout>
      <TripleColumn
        leftElement={
          <UserStatementSidebar
            initialDateFilter={statementFilters.dateFilter}
            onFilter={filters => {
              setStatementFilters(filters);
            }}
          />
        }
        centerElement={centerElementToRender}
        lgScreenGridSize={{
          left: '1fr',
          center: '3fr'
        }}
      />

      <Loader isLoading={isLoadingAccountStatement} />
    </DefaultLayout>
  );
}
