import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';
import { type ShareholderType } from 'data/modules/cards/companyOnboarding';

import { DsRadioButton, DsRadioGroup } from 'presentation/ds/DsRadio';
import {
  OnboardingFooter,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { Onboarding } from 'shared/utils/cards';

import { useNaturalPersonType } from './useNaturalPersonType';

import {
  Container,
  Content,
  ErrorMessage,
  OptionsWrapper,
  TitleContainer
} from './NaturalPersonType.styles';

export function NaturalPersonType(): JSX.Element {
  const navigate = useNavigate();

  const currentStep = Number(Onboarding.getCurrentStep(location.pathname));

  const [naturalPersonType, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.company.add_partner.natural_person_type,
    state.currentLangKey
  ]);

  const {
    selectedNaturalPersonType,
    handleSelectNaturalPersonType,
    handleNavigateNextStep,
    error,
    isEditMode
  } = useNaturalPersonType();

  return (
    <Container>
      <Content>
        <TitleContainer>
          <OnboardingTitle>
            {naturalPersonType.title[currentLangKey]}
          </OnboardingTitle>
        </TitleContainer>

        <OptionsWrapper>
          <DsRadioGroup
            direction='column'
            onChange={selectedOption => {
              handleSelectNaturalPersonType(selectedOption as ShareholderType);
            }}
            defaultValue={selectedNaturalPersonType}
          >
            <DsRadioButton
              value='partner'
              label={naturalPersonType.partner[currentLangKey]}
            />

            <DsRadioButton
              value='legalRepresentative'
              label={naturalPersonType.legal_representative[currentLangKey]}
            />

            <DsRadioButton
              value='proxyholder'
              label={naturalPersonType.proxyholder[currentLangKey]}
            />

            <DsRadioButton
              value='other'
              label={naturalPersonType.others[currentLangKey]}
            />
          </DsRadioGroup>

          {error && !selectedNaturalPersonType && (
            <ErrorMessage>{error}</ErrorMessage>
          )}
        </OptionsWrapper>
      </Content>

      <OnboardingFooter
        backButtonProps={{
          onClick: () => {
            navigate(
              isEditMode
                ? `/cartoes/onboarding/empresa/${currentStep}`
                : `/cartoes/onboarding/empresa/${currentStep}/tipo-representante`
            );
          }
        }}
        continueButtonProps={{
          onClick: handleNavigateNextStep
        }}
      />
    </Container>
  );
}
