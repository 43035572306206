import { DefaultTable } from 'ds';
import { useShallow } from 'zustand/react/shallow';

import {
  useCardDetailsContext,
  useCardGroupsContext,
  useCardsContext,
  useLangContext,
  useModalContext
} from 'data/contexts';
import { type OrderByType } from 'data/contexts/cards/cards/useCardsContext.types';

import {
  CardsTableEmpty,
  CardsTableRow,
  SearchAndFilters,
  TablePagination
} from 'presentation/pages/cards/Management/Cards/components/CardsTable';
import { CardGroupsOrSingleCardsTabs } from 'presentation/pages/cards/Management/components/CardGroupsOrSingleCardsTabs';
import { TableLoader } from 'presentation/pages/cards/Management/components/TableLoader';

import { Currency } from 'shared/utils/format';

import { useCardsTable } from './useCardsTable';

import { type ICardsTableProps } from './CardsTable.types';

import {
  CardsContainer,
  Container,
  StyledCreditCard,
  TableContainer
} from './CardsTable.styles';

export function CardsTable({ ...rest }: ICardsTableProps): JSX.Element {
  const {
    currentLangKey,
    lang: {
      cards: { global }
    }
  } = useLangContext();

  const [listLayout, order, handleChangeFilters] = useCardsContext(
    useShallow(state => [
      state.listLayout,
      state.filters.order,
      state.handleChangeFilters
    ])
  );

  const { cardsList, isLoadingCardsList } = useCardsTable();

  const { handleOpenModal } = useModalContext();

  const handleSetSelectedAccount = useCardDetailsContext(
    state => state.handleSetSelectedAccount
  );

  const hasCardGroups = useCardGroupsContext(state => state.hasCardGroups);

  return (
    <Container {...rest}>
      {hasCardGroups && (
        <CardGroupsOrSingleCardsTabs selectedTab='SINGLE_CARDS' />
      )}

      <SearchAndFilters />

      {isLoadingCardsList && (
        <TableLoader
          columns={6}
          rows={6}
          layout={listLayout}
        />
      )}

      {!isLoadingCardsList && cardsList.length === 0 && (
        <CardsTableEmpty>
          {global.cards_table.no_card_found[currentLangKey]}
        </CardsTableEmpty>
      )}

      {listLayout === 'LIST' && cardsList.length > 0 && !isLoadingCardsList && (
        <TableContainer>
          <DefaultTable.Container>
            <DefaultTable.Thead>
              <DefaultTable.Tr>
                <DefaultTable.Th
                  width='23%'
                  order={order.cardName.toLowerCase() as Lowercase<OrderByType>}
                  isActive
                  onClick={() => {
                    handleChangeFilters({
                      order: {
                        cardName: order.cardName === 'ASC' ? 'DESC' : 'ASC'
                      }
                    });
                  }}
                >
                  {global.cards_table.card_name[currentLangKey]}
                </DefaultTable.Th>

                <DefaultTable.Th width='18%'>
                  {global.cards_table.card_type[currentLangKey]}
                </DefaultTable.Th>

                <DefaultTable.Th width='18%'>
                  {global.cards_table.card[currentLangKey]}
                </DefaultTable.Th>

                <DefaultTable.Th width='18%'>
                  {global.cards_table.available_value[currentLangKey]}
                </DefaultTable.Th>

                <DefaultTable.Th width='18%'>
                  {global.cards_table.status[currentLangKey]}
                </DefaultTable.Th>

                <DefaultTable.Th
                  width='8%'
                  className='cards-tour-card-details'
                >
                  {global.cards_table.details[currentLangKey]}
                </DefaultTable.Th>
              </DefaultTable.Tr>
            </DefaultTable.Thead>

            <DefaultTable.Tbody>
              {cardsList.map(card => (
                <CardsTableRow
                  key={`${card.balanceId}-${card.name}-${card.number}`}
                  card={card}
                />
              ))}
            </DefaultTable.Tbody>
          </DefaultTable.Container>
        </TableContainer>
      )}

      {listLayout === 'CARDS' &&
        cardsList.length > 0 &&
        !isLoadingCardsList && (
          <CardsContainer>
            {cardsList.map(card => (
              <StyledCreditCard
                key={`${card.balanceId}-${card.name}-${card.number}`}
                isLoading={!card.currentValue && card.currentValue !== 0}
                cardInfo={{
                  name: card.name,
                  availableBalance: Currency.format(
                    'BRL',
                    card.currentValue as number,
                    true
                  ),
                  number: card.number as string,
                  status: card.status,
                  type: card.type
                }}
                onClick={() => {
                  handleOpenModal('cardDetails');

                  handleSetSelectedAccount({
                    accountId: card.accountId,
                    balanceId: card.balanceId,
                    name: card.name,
                    availableBalance: Currency.format(
                      'BRL',
                      card.currentValue as number,
                      true
                    ),
                    number: card.number as string,
                    status: card.status,
                    type: card.type,
                    userId: card.userId
                  });
                }}
              />
            ))}
          </CardsContainer>
        )}

      {(isLoadingCardsList || (cardsList && cardsList.length > 0)) && (
        <TablePagination />
      )}
    </Container>
  );
}
