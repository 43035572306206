import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type ContainerStylesType } from './ResponsiveCell.types';

export const Container = styled.td<AddPrefixToType<ContainerStylesType>>`
  ${({ $width }) => $width && `width: ${$width}%;`}
  color: ${({ theme }) => theme.colors.doveGray};
  padding: 1.2rem;

  > span:first-child {
    display: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    display: flex;
    gap: 1rem;

    > span {
      width: 50%;
    }

    > span:first-child {
      display: block;
      font-weight: 700;
    }
  }
`;
