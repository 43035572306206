import {
  type IDetailedExpense,
  type IPersistenceShowExpense
} from 'data/modules/expenses';

import { Currency } from 'shared/utils/format';

class GetExpenseMapper {
  // transforma uma string do tipo (50.5423421234) para número (50.54)
  stringToNumberFromPersistence(percentage: string): number {
    return parseFloat(parseFloat(percentage).toFixed(2));
  }

  formatApportionments(
    persistenceApportionments: IPersistenceShowExpense['apportionments'],
    expenseValue: number
  ): IDetailedExpense['apportionments'] {
    if (
      persistenceApportionments === null ||
      persistenceApportionments.length === 0
    ) {
      return [];
    }

    // se só tem um projeto, retorna ele com o valor da despesa
    if (persistenceApportionments.length === 1) {
      return [
        {
          percentage: this.stringToNumberFromPersistence(
            persistenceApportionments[0].percentual
          ),
          project: {
            id: String(persistenceApportionments[0].project.id),
            name: persistenceApportionments[0].project.nome
          },
          value: expenseValue
        }
      ];
    }

    // se tem mais de um projeto, divide o valor da despesa entre eles conforme porcentagem. Desconsiderando o último projeto inicialmente
    const lastProject = persistenceApportionments.pop();

    if (!lastProject) {
      return [];
    }

    // calcula o valor de cada projeto, desconsiderando o último projeto
    const expenseApportionment: IDetailedExpense['apportionments'] =
      persistenceApportionments.map(item => {
        const value = (expenseValue * parseFloat(item.percentual)) / 100;

        return {
          percentage: this.stringToNumberFromPersistence(item.percentual),
          project: {
            id: String(item.project.id),
            name: item.project.nome
          },
          value: parseFloat(value.toFixed(2))
        };
      });

    // para o último projeto, calcula o valor restante
    const lastProjectValue = Currency.subtractTwoDecimals(
      expenseValue,
      expenseApportionment.reduce(
        (acc, item) => Currency.sumTwoDecimals(acc, item.value),
        0
      )
    );

    // para o último projeto, calcula o valor da última porcentagem
    const lastProjectPercentage = Currency.subtractTwoDecimals(
      100,
      expenseApportionment.reduce(
        (acc, item) => Currency.sumTwoDecimals(acc, item.percentage),
        0
      )
    );

    return [
      ...expenseApportionment,
      {
        percentage: lastProjectPercentage,
        project: {
          id: String(lastProject.project.id),
          name: lastProject.project.nome
        },
        value: lastProjectValue
      }
    ];
  }

  toDomain(persistenceExpense: IPersistenceShowExpense): IDetailedExpense {
    return {
      costsCenter:
        persistenceExpense.cost_center !== null
          ? {
              id: String(persistenceExpense.cost_center.id),
              name: persistenceExpense.cost_center.nome
            }
          : null,
      uuid: persistenceExpense.uuid,
      date: persistenceExpense.date,
      title: persistenceExpense.title,
      isFromCards: persistenceExpense.is_from_cards,
      routeId:
        persistenceExpense.route_id !== null
          ? String(persistenceExpense.route_id)
          : null,
      receipt: persistenceExpense.receipt
        ? {
            url: persistenceExpense.receipt.original_url,
            name: persistenceExpense.receipt.name,
            type: persistenceExpense.receipt.type ?? 'IMG'
          }
        : null,
      type: persistenceExpense.type
        ? {
            id: String(persistenceExpense.type.id),
            description: persistenceExpense.type.description
          }
        : null,
      value: persistenceExpense.amount,
      currency: {
        id: String(persistenceExpense.currency.id),
        isoCode: persistenceExpense.currency.iso_code
      },
      report: persistenceExpense.report
        ? {
            uuid: persistenceExpense.report.uuid,
            id: persistenceExpense.report.id,
            description: persistenceExpense.report.description,
            status: persistenceExpense.report.status
          }
        : null,
      paymentMethod:
        persistenceExpense.payment_method !== null
          ? {
              id: String(persistenceExpense.payment_method.id),
              description: persistenceExpense.payment_method.description
            }
          : null,
      canDelete: persistenceExpense.is_deletable,
      canEdit: persistenceExpense.is_editable,
      isReconciled: !persistenceExpense.conciliation.is_enabled
        ? null
        : persistenceExpense.conciliation.status === 'reconciled',
      isImported: persistenceExpense.conciliation.was_imported,
      isReimbursable: persistenceExpense.is_reimbursable,
      wasValidated: persistenceExpense.was_validated,
      observationAttachments:
        persistenceExpense.observations_attachments?.map(item => {
          return {
            title: item.name,
            url: item.url
          };
        }) ?? null,
      conciliationBalance: persistenceExpense.conciliation_balance,
      convertedValue: persistenceExpense.converted_value,
      exchangeRate: persistenceExpense.exchange_rate,
      id: persistenceExpense.id,
      observation: persistenceExpense.observations,
      convertedCurrencyId: persistenceExpense.converted_currency_id,
      readOcr: persistenceExpense.read_ocr,
      transaction: persistenceExpense.transaction
        ? {
            amount: persistenceExpense.transaction?.amount,
            id: persistenceExpense.transaction?.id,
            finalBillingAmount:
              (persistenceExpense.transaction?.final_billing_amount ?? 0) * -1,
            finalInternationalAmount:
              (persistenceExpense.transaction?.final_international_amount ??
                0) * -1,
            isInternational: persistenceExpense.transaction?.is_international
          }
        : null,
      userCreatorId: persistenceExpense.user_creator_id,
      modifiedIntelliscanField: persistenceExpense.modified_intelliscan_field,
      userId: persistenceExpense.user_id,
      userCreatorName: persistenceExpense.user_creator_name,
      convertedCurrencyIso: persistenceExpense.converted_currency_iso,
      cardNumber: persistenceExpense.transaction
        ? persistenceExpense.transaction.card_number
        : '',
      amountPerKilometer: persistenceExpense.type
        ? persistenceExpense.type.amount_per_kilometer
        : null,
      routePoints: persistenceExpense.type
        ? persistenceExpense.type.route_points
        : null,
      kilometrage: persistenceExpense.type
        ? persistenceExpense.type.kilometrage
        : null,
      routePolyline: persistenceExpense.type
        ? persistenceExpense.type.route_polyline
        : null,
      routeType: persistenceExpense.type
        ? persistenceExpense.type.route_type
        : null,
      apportionments: this.formatApportionments(
        persistenceExpense.apportionments,
        persistenceExpense.amount
      ),
      fueling:
        persistenceExpense.fueling !== null
          ? {
              amountPerLitre: persistenceExpense.fueling.amount_per_litre,
              fuel: persistenceExpense.fueling.fuel,
              odometerImage:
                persistenceExpense.fueling.odometer_image !== null
                  ? {
                      id: persistenceExpense.fueling.odometer_image.id,
                      name: persistenceExpense.fueling.odometer_image.name,
                      originalUrl:
                        persistenceExpense.fueling.odometer_image.original_url,
                      type: persistenceExpense.fueling.odometer_image.type,
                      thumbnailUrl:
                        persistenceExpense.fueling.odometer_image.thumbnail_url,
                      uuid: persistenceExpense.fueling.odometer_image.uuid
                    }
                  : null,
              odometerKilometrage:
                persistenceExpense.fueling.odometer_kilometrage,
              vehicle: persistenceExpense.fueling.vehicle,
              vehicleType: persistenceExpense.fueling.vehicle_type,
              volume: persistenceExpense.fueling.volume
            }
          : null,
      createdAt: persistenceExpense.created_at,
      isReversal: persistenceExpense.reversal_status,
      gpsRouteFinalDateNaive:
        persistenceExpense.type?.gps_route_final_date_naive,
      gpsRouteFinalTimezone: persistenceExpense.type?.gps_route_final_timezone,
      gpsRouteStartDateNaive:
        persistenceExpense.type?.gps_route_start_date_naive,
      gpsRouteStartTimezone: persistenceExpense.type?.gps_route_start_timezone
    };
  }
}

export default new GetExpenseMapper();
