import {
  Alert,
  CurrencyInput,
  DefaultButton,
  Modal,
  SecondaryButton,
  TextArea
} from 'ds';
import { DatePicker } from 'ds/components/Input/DatePicker';
import { useTranslation } from 'react-i18next';

import { useModalContext } from 'data/contexts';

import { Loader } from 'presentation/components/global/Loader';

import { useRequestValueModal } from './useRequestValueModal';

import {
  Container,
  CurrencyInputContainer,
  DescriptionContent,
  ForecastAndAlertContainer,
  InputsContainer,
  ModalFooter
} from './RequestValueModal.styles';

export function RequestValueModal(): JSX.Element {
  const { handleCloseModal } = useModalContext();
  const { t, i18n } = useTranslation(['global', 'cards']);
  const { methods, handleSubmit, isRequestingBalance, handleAddAmount } =
    useRequestValueModal();

  if (isRequestingBalance) {
    return <Loader isLoading />;
  }

  return (
    <Modal.Container
      title={t('cards:requestValue')}
      onClose={() => {
        handleCloseModal();
      }}
      variant='medium'
    >
      <Modal.Header>
        <Modal.Title>{t('cards:requestValue')}</Modal.Title>

        <Modal.IconClose
          onClick={() => {
            handleCloseModal();
          }}
        />
      </Modal.Header>

      <Container
        methods={methods}
        handleSubmit={handleSubmit}
      >
        <DescriptionContent>
          {t('cards:enterTheAmountYouWishToRequestBelow')}
        </DescriptionContent>

        <ForecastAndAlertContainer>
          <DatePicker.Controlled
            name='datePrevision'
            label={t('cards:usagePrediction')}
            placeholder={t('cards:usagePrediction')}
            currentLangKey={i18n.language as 'pt' | 'en' | 'es'}
            className='date-picker-request-value'
            iconRight='CalendarScheduleIcon'
            variant='small'
          />

          <Alert type='info'>
            {t('cards:theAdministratorWillBeNotifiedOfTheRequest')}
          </Alert>
        </ForecastAndAlertContainer>

        <InputsContainer>
          <CurrencyInputContainer>
            <CurrencyInput.Controlled
              inputId='amount'
              name='amount'
              variant='small'
              leftElement='R$'
              autoComplete='off'
            />
          </CurrencyInputContainer>
          <SecondaryButton
            type='button'
            size='small'
            onClick={() => {
              handleAddAmount(100);
            }}
          >
            + 100
          </SecondaryButton>

          <SecondaryButton
            type='button'
            size='small'
            onClick={() => {
              handleAddAmount(1000);
            }}
          >
            + 1.000
          </SecondaryButton>

          <SecondaryButton
            type='button'
            size='small'
            onClick={() => {
              handleAddAmount(10000);
            }}
          >
            + 10.000
          </SecondaryButton>
        </InputsContainer>

        <TextArea.Controlled
          name='justify'
          textareaProps={{ placeholder: t('global:justification') }}
          className='textarea-request-value'
        />

        <ModalFooter>
          <SecondaryButton
            size='small'
            onClick={() => {
              handleCloseModal();
            }}
            type='button'
          >
            {t('global:cancel')}
          </SecondaryButton>

          <DefaultButton size='small'>{t('cards:placeOrder')}</DefaultButton>
        </ModalFooter>
      </Container>
    </Modal.Container>
  );
}
