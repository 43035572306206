import { useLangContext, useModalContext } from 'data/contexts';

import { DsDefaultButton, DsGhostButton } from 'presentation/ds/DsButton';
import { DsModal } from 'presentation/ds/DsModal';
import { DsProgressSteps } from 'presentation/ds/DsProgressSteps';

import activateCardStep1 from 'shared/assets/images/cards/activation/activate-card-step1.png';
import activateCardStep2 from 'shared/assets/images/cards/activation/activate-card-step2.png';
import activateCardStep3 from 'shared/assets/images/cards/activation/activate-card-step3.png';
import activateCardStep4 from 'shared/assets/images/cards/activation/activate-card-step4.png';
import { animations } from 'shared/constants/animations';

import { useActivateCardModal } from './useActivateCardModal';

import {
  ButtonsContainer,
  Content,
  ImageAndTextContent,
  ModalBody
} from './ActivateCardModal.styles';

export function ActivateCardModal(): JSX.Element {
  const { visibleModal } = useModalContext();
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);
  const { setStep, step, handleNextOrFinishButton, handleCloseModalOnClick } =
    useActivateCardModal();

  const contentByStep = {
    step1: {
      image: activateCardStep1,
      text: (
        <p>
          {
            lang.cards.statement.activate_card_modal.access_the_app[
              currentLangKey
            ]
          }
        </p>
      )
    },
    step2: {
      image: activateCardStep2,
      text: (
        <p>
          {
            lang.cards.statement.activate_card_modal.tab_the_button_sentence
              .tap_the_button[currentLangKey]
          }{' '}
          <span>
            {`"${lang.cards.statement.activate_card_modal.tab_the_button_sentence.activate_card[currentLangKey]}"`}
          </span>{' '}
          {
            lang.cards.statement.activate_card_modal.tab_the_button_sentence
              .in_the_app[currentLangKey]
          }
        </p>
      )
    },
    step3: {
      image: activateCardStep3,
      text: (
        <p>
          {
            lang.cards.statement.activate_card_modal.scan_qr_code[
              currentLangKey
            ]
          }
        </p>
      )
    },
    step4: {
      image: activateCardStep4,
      text: (
        <p>
          {lang.cards.statement.activate_card_modal.set_a_pin[currentLangKey]}
        </p>
      )
    }
  };

  return (
    <DsModal
      isOpen={visibleModal === 'activateCard'}
      onClose={handleCloseModalOnClick}
      title={
        lang.cards.statement.activate_card_modal.how_to_activate[currentLangKey]
      }
    >
      <ModalBody {...animations.fadeInOut}>
        <p>
          {`${lang.cards.statement.activate_card_modal.check_step_by_step[currentLangKey]}:`}
        </p>

        <Content {...animations.popFadeInOut}>
          <ImageAndTextContent>
            <img
              src={
                contentByStep[`step${step}` as keyof typeof contentByStep].image
              }
              alt={'Activate card step ' + step}
            />
            {contentByStep[`step${step}` as keyof typeof contentByStep].text}
          </ImageAndTextContent>

          <DsProgressSteps
            currentStep={step}
            totalSteps={4}
          />
        </Content>

        <ButtonsContainer>
          {step > 1 && (
            <DsGhostButton
              text={lang.global.back[currentLangKey]}
              onClick={() => {
                setStep(step - 1);
              }}
            />
          )}
          <DsDefaultButton
            text={
              step === 4
                ? lang.global.understood[currentLangKey]
                : lang.global.next[currentLangKey]
            }
            onClick={handleNextOrFinishButton}
          />
        </ButtonsContainer>
      </ModalBody>
    </DsModal>
  );
}
