import { type IStepperContainerProps } from './StepperContainer.types';

import { Container } from './StepperContainer.styles';

export function StepperContainer({
  children,
  breakpointToBreakIntoColumns = 768,
  ...rest
}: IStepperContainerProps): JSX.Element {
  return (
    <Container
      $breakpointToBreakIntoColumns={breakpointToBreakIntoColumns}
      {...rest}
    >
      {children}
    </Container>
  );
}
