import { useState } from 'react';

import { type IUseSelectDefault } from './SelectDefault.types';

export function useSelectDefault(): IUseSelectDefault {
  const [searchValue, setSearchValue] = useState<string>('');

  return {
    searchValue,
    setSearchValue
  };
}
