import { DSIcons } from 'ds/icons';
import { tokens } from 'ds/tokens';

import {
  type AlertType,
  type IAlertVariants
} from 'ds/components/Alert/Alert.types';

export const alertProps: Record<AlertType, IAlertVariants> = {
  info: {
    background: tokens.brandColorSecondaryLightest,
    font: tokens.brandColorSecondaryPure,
    icon: DSIcons.InfoIcon,
    border: tokens.brandColorPrimaryLight,
    close: tokens.brandColorPrimaryMedium
  },
  success: {
    background: tokens.feedbackColorSuccessLightest,
    font: tokens.feedbackColorSuccessPure,
    icon: DSIcons.SuccessIcon,
    border: tokens.feedbackColorSuccessPure,
    close: tokens.feedbackColorSuccessMedium
  },
  warning: {
    background: tokens.feedbackColorWarningLightest,
    font: tokens.feedbackColorWarningMediumDark,
    icon: DSIcons.WarningIcon,
    border: tokens.feedbackColorWarningPure,
    close: tokens.feedbackColorWarningMedium
  },
  error: {
    background: tokens.feedbackColorErrorLightest,
    font: tokens.feedbackColorErrorLight,
    icon: DSIcons.ErrorIcon,
    border: tokens.feedbackColorErrorLight,
    close: tokens.feedbackColorErrorMedium
  },
  outsourced: {
    background: tokens.neutralColorLowDark,
    font: tokens.neutralColorHighPure,
    icon: DSIcons.UsersIcon,
    border: tokens.neutralColorLowDark,
    close: tokens.neutralColorHighPure
  }
};
