import { tokens } from 'ds';
import styled from 'styled-components';

import { CreditCard } from 'presentation/components/global/CreditCard';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 1.6rem;
  padding: ${tokens.spacingXs} ${tokens.spacingXxs};
  border-radius: 16px;
  background-color: ${tokens.neutralColorHighLight};
  margin-bottom: 3.6rem;

  table {
    min-width: 100rem;
  }

  tr {
    height: 6.8rem;
  }

  td {
    &:last-child {
      cursor: pointer;
    }

    &:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const TableContainer = styled.div`
  max-width: 100%;
  overflow: auto;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
  padding-bottom: 0.5rem;

  & > div > div > div {
    width: 54%;
    flex-grow: 1;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: row wrap;
    justify-content: center;
    align-items: unset;
    gap: 2rem 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    justify-content: unset;
    column-gap: 2.724rem;
  }
`;

export const StyledCreditCard = styled(CreditCard)`
  width: 100%;
  height: 100%;
  max-width: 40rem;
  aspect-ratio: 1.587;
  background-size: cover;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.1s linear;

  &:hover {
    scale: 1.03;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 0.5rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 28.301rem;
    padding: 1.4rem 2rem;
    gap: 0.5rem;
  }
`;
