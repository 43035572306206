import {
  type CompanyOnboardingShareholderType,
  type PersistenceCompanyOnboardingShareholderType
} from 'data/modules/cards/companyOnboarding';

import { CustomDate, CustomObject } from 'shared/utils/custom';
import { Mask } from 'shared/utils/format';

class GetCompanyOnboardingShareholdersMapper {
  toDomain(
    persistenceCompanyOnboardingShareholder: PersistenceCompanyOnboardingShareholderType
  ): CompanyOnboardingShareholderType {
    if (persistenceCompanyOnboardingShareholder.type === 'INDIVIDUAL') {
      return {
        ...CustomObject.pick(persistenceCompanyOnboardingShareholder, [
          'email',
          'name',
          'type'
        ]),
        document: Mask.apply(
          'cpf',
          persistenceCompanyOnboardingShareholder.document
        ),
        birthDate: CustomDate.formatFromISO(
          persistenceCompanyOnboardingShareholder.birth_date
        ),
        shareholderType:
          persistenceCompanyOnboardingShareholder.shareholder_type ===
          'legal_representative'
            ? 'legalRepresentative'
            : persistenceCompanyOnboardingShareholder.shareholder_type,
        phoneNumber:
          persistenceCompanyOnboardingShareholder.phone_number &&
          Mask.apply(
            'phone',
            persistenceCompanyOnboardingShareholder.phone_number.slice(2)
          )
      };
    }

    return {
      ...CustomObject.pick(persistenceCompanyOnboardingShareholder, [
        'email',
        'type'
      ]),
      document: Mask.apply(
        'cnpj',
        persistenceCompanyOnboardingShareholder.document
      ),
      businessType: persistenceCompanyOnboardingShareholder.business_type,
      contract: persistenceCompanyOnboardingShareholder.contract,
      foundingDate: CustomDate.formatFromISO(
        persistenceCompanyOnboardingShareholder.founding_date
      ),
      legalName: persistenceCompanyOnboardingShareholder.legal_name,
      mainActivity: persistenceCompanyOnboardingShareholder.main_activity,
      phoneNumber:
        persistenceCompanyOnboardingShareholder.phone_number &&
        Mask.apply(
          'phone',
          persistenceCompanyOnboardingShareholder.phone_number.slice(2)
        )
    };
  }
}

export default new GetCompanyOnboardingShareholdersMapper();
