import { useRef } from 'react';

import { BaseIcon } from 'presentation/components/base/Icon';

import { type IDsRadioButtonProps } from './DsRadioButton.types';

import {
  Container,
  Label,
  StyledIndicator,
  StyledRadioItem
} from './DsRadioButton.styles';

export const DsRadioButton = ({
  disabled = false,
  value = '',
  label,
  iconProps,
  ...rest
}: IDsRadioButtonProps): JSX.Element => {
  const radioButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Container
      $disabled={disabled}
      onClick={() => {
        radioButtonRef.current?.click();
      }}
    >
      {iconProps && <BaseIcon {...iconProps} />}
      <StyledRadioItem
        ref={radioButtonRef}
        disabled={disabled}
        value={value}
        id={value}
        onClick={e => {
          e.stopPropagation();
        }}
        {...rest}
      >
        <StyledIndicator />
      </StyledRadioItem>
      <Label
        htmlFor={value}
        $disabled={disabled}
      >
        {label}
      </Label>
    </Container>
  );
};
