import { toast } from 'ds/utils';

import { useMutationCache } from 'data/cache';
import {
  CompanyOnboardingMutationKeys,
  CompanyOnboardingQueryKeys,
  CompanyOnboardingService,
  type IDeleteLegalRepresentativePayload,
  type IUseDeleteLegalRepresentative,
  type IUseDeleteLegalRepresentativeParams
} from 'data/modules/cards/companyOnboarding';

import { useHandleQueryCache } from 'shared/hooks/global';

export function useDeleteLegalRepresentative({
  onDeleteLegalRepresentativeMutationSuccess
}: IUseDeleteLegalRepresentativeParams): IUseDeleteLegalRepresentative {
  const { data: cachedCompanyOnboardingProgress } = useHandleQueryCache({
    query: {
      query: async () => await CompanyOnboardingService.getProgress(),
      key: [CompanyOnboardingQueryKeys.GET_COMPANY_ONBOARDING_PROGRESS]
    }
  });

  const cachedOnboardingCompanyId =
    cachedCompanyOnboardingProgress?.onboardingCompanyId ?? '';

  const { data: cachedRepresentatives, updateCache } = useHandleQueryCache({
    query: {
      query: async () =>
        await CompanyOnboardingService.getLegalRepresentatives({
          uuidCompanyOnboarding: cachedOnboardingCompanyId
        }),
      key: [CompanyOnboardingQueryKeys.GET_LEGAL_REPRESENTATIVES]
    },
    params: {
      uuidCompanyOnboarding: cachedOnboardingCompanyId
    }
  });

  const { isLoading: isDeletingLegalRepresentative, mutate } = useMutationCache(
    {
      mutationKey: [CompanyOnboardingMutationKeys.DELETE_LEGAL_REPRESENTATIVE],
      mutationFn: async (payload: IDeleteLegalRepresentativePayload) =>
        await CompanyOnboardingService.deleteLegalRepresentative(payload),
      onSuccess: (data, { uuid }) => {
        updateCache({
          newData:
            cachedRepresentatives?.filter(
              representative => representative.id !== uuid
            ) ?? []
        });
        onDeleteLegalRepresentativeMutationSuccess?.(uuid);
      },
      onError: (error: Error) => {
        toast.error(error.message);
      }
    }
  );

  async function handleDeleteLegalRepresentative(uuid: string): Promise<void> {
    if (isDeletingLegalRepresentative) return;

    mutate({
      uuid,
      uuidCompanyOnboarding: cachedOnboardingCompanyId
    });
  }

  return {
    isDeletingLegalRepresentative,
    handleDeleteLegalRepresentative
  };
}
