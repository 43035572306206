import { Numeric } from 'ds';
import { useDsGa4 } from 'ds/hooks/globals';
import { DSIcons } from 'ds/icons';

import { usePercentageList } from './usePercentageList';

import { type IPercentageListProps } from './PercentageList.types';
import { type LanguageType } from 'ds/types';

import {
  Container,
  Footer,
  ItemContainer,
  ItemHeader,
  ItemObservation,
  ItemPercentage,
  ItemPercentageContainer,
  ItemPercentageValue,
  ItemsContainer,
  ItemTitle,
  ItemValue,
  NoItemsMessage,
  ShowMore,
  Title
} from './PercentageList.styles';

export function PercentageList({
  items,
  title,
  noItemsMessage,
  langKey = 'pt'
}: IPercentageListProps): JSX.Element {
  const { isVisible, toggleShow, scrollRef } = usePercentageList();
  const { sendDsGaEvent } = useDsGa4();

  let currentIndex = 0;

  const showMoreMessage: Record<LanguageType, string> = {
    pt: `Ver mais`,
    en: `View more`,
    es: `Ver más`
  };

  const showLessMessage: Record<LanguageType, string> = {
    pt: `Ver menos`,
    en: `View less`,
    es: `Ver menos`
  };

  return (
    <Container>
      <Title>{title}</Title>

      <ItemsContainer
        ref={scrollRef}
        $active={isVisible && items.length > 3}
      >
        {items.length === 0 && (
          <NoItemsMessage>{noItemsMessage}</NoItemsMessage>
        )}

        {items.map((item, index) => {
          currentIndex = currentIndex > 4 ? 0 : currentIndex;

          return (
            <ItemContainer key={`list-item-container-${index}`}>
              <ItemHeader>
                <ItemTitle>{item.title}</ItemTitle>

                <ItemPercentageContainer>
                  <ItemValue>{item.value}</ItemValue>

                  <ItemPercentageValue>
                    ({Numeric.format('BRL', item.percentage)})
                  </ItemPercentageValue>
                </ItemPercentageContainer>
              </ItemHeader>

              <ItemPercentage
                $index={currentIndex++}
                $percentage={item.percentage}
              />

              {item.observation && (
                <ItemObservation>{item.observation}</ItemObservation>
              )}
            </ItemContainer>
          );
        })}
      </ItemsContainer>

      {items.length > 3 && (
        <Footer>
          <ShowMore
            onClick={() => {
              sendDsGaEvent('components', 'charts_porcentagens');
              toggleShow();
            }}
            $active={isVisible}
          >
            {isVisible ? showLessMessage[langKey] : showMoreMessage[langKey]}

            <DSIcons.ChevronIcon />
          </ShowMore>
        </Footer>
      )}
    </Container>
  );
}
