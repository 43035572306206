import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { DsTextInput } from 'presentation/ds/DsTextInput';
import {
  OnboardingFooter,
  OnboardingText,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { useFillerRepresentativeData } from './useFillerRepresentativeData';

import {
  AddButton,
  Container,
  RepresentativeContent,
  StyledForm
} from './FillerRepresentativeData.styles';

export function FillerRepresentativeData(): JSX.Element {
  const [global, forms, onboarding, currentLangKey] = useLangContext(state => [
    state.lang.global,
    state.lang.forms,
    state.lang.cards.onboarding,
    state.currentLangKey
  ]);

  const {
    errors,
    fields,
    control,
    setValue,
    handleAddPartner,
    handleAddLegalRepresentatives
  } = useFillerRepresentativeData();

  return (
    <Container>
      <OnboardingTitle>
        {onboarding.company.filler_representative.title[currentLangKey]}
      </OnboardingTitle>
      <StyledForm onSubmit={handleAddLegalRepresentatives}>
        <OnboardingText>
          {onboarding.company.filler_representative.description[currentLangKey]}
        </OnboardingText>
        {fields.map((field, index) => (
          <RepresentativeContent key={field.id}>
            <DsTextInput
              mask='letters'
              control={control}
              label={forms.full_name[currentLangKey]}
              name={`legalRepresentatives.${index}.name`}
              error={errors.legalRepresentatives?.[index]?.name?.message}
              onClear={() => {
                setValue(`legalRepresentatives.${index}.name`, '');
              }}
            />

            <DsTextInput
              type='email'
              label='Email'
              control={control}
              name={`legalRepresentatives.${index}.email`}
              error={errors.legalRepresentatives?.[index]?.email?.message}
              onClear={() => {
                setValue(`legalRepresentatives.${index}.email`, '');
              }}
            />
          </RepresentativeContent>
        ))}
        <AddButton
          type='button'
          onClick={handleAddPartner}
        >
          <BaseIcon name='add' />
          {global.other[currentLangKey]}
        </AddButton>

        <OnboardingFooter
          continueButtonProps={{
            text: global.to_send[currentLangKey],
            onClick: () => {}
          }}
        />
      </StyledForm>
    </Container>
  );
}
