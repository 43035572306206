import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type IStepGraphStyles } from './StepGraph.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  display: flex;
  gap: 0.6rem;
`;

export const StepIndicator = styled.div<AddPrefixToType<IStepGraphStyles>>`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 16px;
  background-color: #d9d9d9;

  ${({ $isCurrentStep }) =>
    $isCurrentStep &&
    css`
      width: 2rem;
      height: 0.8rem;
      border-radius: 16px;
      background-color: ${tokens.brandColorPrimaryPure};
    `}
`;
