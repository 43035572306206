import styled from 'styled-components';

export const BrazilianContainer = styled.p`
  small,
  strong {
    font-weight: 300;
  }

  small {
    margin-right: 0.3rem;
    color: ${({ theme }) => theme.colors.mineShaft};
    font-size: 1rem;
  }

  strong {
    color: ${({ theme }) => theme.colors.mineShaft};
    font-size: 1.6rem;
  }
`;

export const InternationalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > span {
    &:first-of-type {
      > small {
        font-size: 1rem;
        margin-right: 0.3rem;
      }

      > strong {
        font-size: 1.6rem;
      }
    }

    &:last-of-type {
      > small {
        font-size: 0.9rem;
        margin-right: 0.3rem;
      }

      > strong {
        font-size: 1.2rem;
      }
    }
  }
`;
