import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0.8rem;
  }

  > h1 {
    text-align: left;
    margin-top: 2.4rem;

    &:first-child {
      margin-bottom: 3rem;
    }
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-top: 3.2rem;
`;

export const Divider = styled.span`
  border: 1px solid ${({ theme }) => theme.tokens.neutralColorLowLight};
`;

export const TipsContainer = styled.div`
  flex-direction: column;
  padding: 1rem 0;
  display: flex;
  gap: 1.6rem;

  > :first-child {
    margin-bottom: 0.8rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 0 2.4rem;
    margin-top: 4.2rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin: 0 1.2rem;
    margin-top: 4.2rem;
  }
`;

export const TipItem = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  gap: 0.8rem;

  strong {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.tokens.neutralColorLowDark};
    font-family: ${({ theme }) => theme.tokens.fontFamilyRoboto};
    font-weight: ${({ theme }) => theme.tokens.fontWeightMedium};
  }
`;
