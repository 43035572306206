import { type ISectionProps } from './Section.types';

import { Container, SectionTitle } from './Section.styles';

export function Section({
  title,
  children,
  ...rest
}: ISectionProps): JSX.Element {
  return (
    <Container {...rest}>
      <SectionTitle>{title}</SectionTitle>
      {children}
    </Container>
  );
}
