import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useLangContext } from 'data/contexts';
import { useGetTypesOfExpenses } from 'data/modules/expenses';
import { useGetPaymentMethods } from 'data/modules/paymentMethods';

import { TransformArray } from 'shared/utils/global';

import {
  type IExpenseLabelWithQuantity,
  type IUseTableFilters,
  type IUseTableFiltersProps
} from './TableFilters.types';

export function useTableFilters({
  numberOfExpensesByStatus
}: IUseTableFiltersProps): IUseTableFilters {
  const { lang, currentLangKey } = useLangContext();

  const { t } = useTranslation('expenses');

  const [moreFiltersActive, setMoreFiltersActive] = useState(false);

  const expensesLabelsWithQuantity: IExpenseLabelWithQuantity[] =
    useMemo(() => {
      return [
        {
          label: lang.expenses.table.tabs.all[currentLangKey],
          quantity: numberOfExpensesByStatus?.all ?? 0,
          status: 'all'
        },
        {
          label: lang.expenses.table.tabs.single[currentLangKey],
          quantity: numberOfExpensesByStatus?.single ?? 0,
          status: 'single'
        },
        {
          label: lang.expenses.table.tabs.open[currentLangKey],
          quantity: numberOfExpensesByStatus?.open ?? 0,
          status: 'open'
        },
        {
          label: lang.expenses.table.tabs.sent[currentLangKey],
          quantity: numberOfExpensesByStatus?.sent ?? 0,
          status: 'sent'
        },
        {
          label: lang.expenses.table.tabs.reproved[currentLangKey],
          quantity: numberOfExpensesByStatus?.reproved ?? 0,
          status: 'reproved'
        },
        {
          label: lang.expenses.table.tabs.approved[currentLangKey],
          quantity: numberOfExpensesByStatus?.approved ?? 0,
          status: 'approved'
        },
        {
          label: lang.expenses.table.tabs.paid[currentLangKey],
          quantity: numberOfExpensesByStatus?.paid ?? 0,
          status: 'paid'
        }
      ];
    }, [lang, currentLangKey, numberOfExpensesByStatus]);

  const { typesOfExpenses } = useGetTypesOfExpenses({ withInactives: true });

  const typesOfExpensesOptionsDefault = TransformArray.objectsToSelectOptions(
    typesOfExpenses?.filter(item => item.active),
    'id',
    'description'
  );

  const typesOfExpensesOptionsDisabled = TransformArray.objectsToSelectOptions(
    typesOfExpenses?.filter(item => !item.active),
    'id',
    'description'
  );

  const { paymentMethods } = useGetPaymentMethods({ withInactives: true });

  const paymentMethodsOptionsDefault = TransformArray.objectsToSelectOptions(
    paymentMethods?.filter(item => item.active),
    'id',
    'description'
  );

  const paymentMethodsOptionsDisabled = TransformArray.objectsToSelectOptions(
    paymentMethods?.filter(item => !item.active),
    'id',
    'description'
  );

  const refundableOptions = useMemo(() => {
    const refundable = [
      {
        value: 'yes',
        label: lang.global.yes[currentLangKey]
      },
      {
        value: 'no',
        label: lang.global.no[currentLangKey]
      }
    ];

    return TransformArray.objectsToSelectOptions(refundable, 'value', 'label');
  }, [currentLangKey, lang]);

  paymentMethodsOptionsDisabled.length === 0 &&
    paymentMethodsOptionsDefault.unshift({
      label: lang.forms.all_options[currentLangKey],
      value: 'ALL'
    });

  typesOfExpensesOptionsDisabled.length === 0 &&
    typesOfExpensesOptionsDefault.unshift({
      label: lang.forms.all_options[currentLangKey],
      value: 'ALL'
    });

  const typesOfExpensesOptions =
    typesOfExpensesOptionsDisabled.length > 0 &&
    typesOfExpensesOptionsDefault.length > 0
      ? [
          {
            label: t('active'),
            options: typesOfExpensesOptionsDefault
          },
          {
            label: t('inactive'),
            options: typesOfExpensesOptionsDisabled,
            disabled: true
          }
        ]
      : typesOfExpensesOptionsDefault;

  const paymentMethodsOptions =
    paymentMethodsOptionsDisabled.length > 0 &&
    paymentMethodsOptionsDefault.length > 0
      ? [
          {
            label: t('active'),
            options: paymentMethodsOptionsDefault
          },
          {
            label: t('inactive'),
            options: paymentMethodsOptionsDisabled,
            disabled: true
          }
        ]
      : paymentMethodsOptionsDefault;

  return {
    expensesLabelsWithQuantity,
    typesOfExpensesOptions,
    moreFiltersActive,
    setMoreFiltersActive,
    paymentMethodsOptions,
    refundableOptions
  };
}
