import { useAuthContext } from 'data/contexts';
import {
  useGetCardDataByAccountId,
  useGetOutsourcedUserAccounts,
  useGetUserAccountsByUserUuid
} from 'data/modules/cards/account';

import {
  type IUseGetUserLoggedAndCardData,
  type IUseGetUserLoggedAndCardDataProps
} from './useGetUserLoggedAndCardData.types';

export function useGetUserLoggedAndCardData({
  enabled = true
}: IUseGetUserLoggedAndCardDataProps): IUseGetUserLoggedAndCardData {
  const user = useAuthContext(state => state.user);

  const isSharedAccount = !!user?.cards?.sharedAccounts;

  const { userAccounts, isFetchingUserAccounts } = useGetUserAccountsByUserUuid(
    {
      userUuid: user?.cards?.id as string,
      enabled: !isSharedAccount
    }
  );

  const { outsourcedUserAccounts, isFetchingOutsourcedUserAccounts } =
    useGetOutsourcedUserAccounts({
      outsourcedUserUuid: user?.cards?.id as string,
      enabled: isSharedAccount
    });

  const { cardData, isFetchingCardData } = useGetCardDataByAccountId({
    accountId: isSharedAccount
      ? (outsourcedUserAccounts?.[0].account.id as string)
      : (userAccounts?.[0].account.id as string),
    enabled:
      isSharedAccount && enabled
        ? !!outsourcedUserAccounts?.[0].account.id
        : !!userAccounts?.[0].account.id
  });

  return {
    userHasCard: !!cardData,
    userAccounts,
    cardData: cardData || null,
    outsourcedUserAccounts,
    isLoadingUserAccount:
      isFetchingOutsourcedUserAccounts ||
      isFetchingUserAccounts ||
      isFetchingCardData
  };
}
