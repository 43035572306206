import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  font-size: ${tokens.fontSizeXxs};
  line-height: 2.4rem;

  p {
    color: ${tokens.neutralColorLowMedium};
    font-weight: ${tokens.fontWeightMedium};
  }

  .value {
    display: flex;
    align-items: center;
    gap: ${tokens.spacingNano};
    color: ${tokens.neutralColorLowDark};
    font-weight: ${tokens.fontWeightRegular};

    svg {
      width: 2.4rem;
      height: 2.4rem;
      fill: ${tokens.brandColorPrimaryPure};
      cursor: pointer;
    }
  }

  .icon {
    color: ${tokens.brandColorPrimaryPure};
  }
`;
