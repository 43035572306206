import { type LanguageKeyType } from 'data/contexts/lang/useLangContext.types';
import {
  type IAccountTransactionWithAdditionalInfo,
  type IGenericStatement,
  type ITransaction,
  type StatementMovementType
} from 'data/modules/cards/statement';

import { type langJSON } from 'shared/lang';
import { CustomDate, CustomString } from 'shared/utils/custom';
import { Currency } from 'shared/utils/format';

export class Statement {
  filterVisibleTransactions<T extends ITransaction>(transactions: T[]): T[] {
    return transactions.filter(transaction => transaction.visible);
  }

  filterApprovedTransactions<T extends ITransaction>(transactions: T[]): T[] {
    return transactions.filter(
      transaction =>
        transaction.billingAmountAuthorized !== 0 &&
        (transaction.debitCredit === 'CREDIT' ||
          transaction.debitCredit === 'DEBIT')
    );
  }

  filterTransactionsByMovementType<T extends ITransaction>(
    transactions: T[],
    movementType: StatementMovementType
  ): T[] {
    // Movimentações de saída
    if (movementType === 'exits') {
      return transactions.filter(
        transaction => transaction.debitCredit === 'DEBIT'
      );
    }

    // Movimentações de entrada
    if (movementType === 'entries') {
      return transactions.filter(
        transaction => transaction.debitCredit === 'CREDIT'
      );
    }

    // Todas as movimentações
    return transactions;
  }

  filterTransactionsByCardsNumbers<T extends ITransaction>(
    transactions: T[],
    cardsNumbers: string[]
  ): T[] {
    if (cardsNumbers.length === 0) return transactions;

    return transactions.filter(transaction =>
      cardsNumbers.includes(transaction.cardNumber ?? '')
    );
  }

  filterTransactionsBySearchInput<T extends ITransaction>(
    transactions: T[],
    searchInput: string
  ): T[] {
    if (!searchInput) return transactions;

    const formattedSearchInput = searchInput.toLowerCase();

    return transactions.filter(transaction => {
      const formattedDate = CustomDate.formatFromISO(transaction.datetime);
      const formattedAmount = Currency.format('BRL', transaction.amount);

      return (
        formattedDate.toLowerCase().includes(formattedSearchInput) ||
        formattedAmount.toLowerCase().includes(formattedSearchInput) ||
        transaction.paymentDescription
          .toLowerCase()
          .includes(formattedSearchInput) ||
        (transaction.merchantName
          ?.toLowerCase()
          .includes(formattedSearchInput) ??
          transaction.cardNumber
            ?.toLowerCase()
            .includes(formattedSearchInput) ??
          false) ||
        transaction.amount
          .toString()
          .toLowerCase()
          .includes(formattedSearchInput)
      );
    });
  }

  filterStatementTransactions<T extends IGenericStatement>(
    statementRows: T[] | undefined,
    cardsNumbers: string[] | undefined = undefined,
    movementType: StatementMovementType | undefined = undefined,
    searchInput: string | undefined = undefined
  ): T[] {
    return (
      statementRows
        ?.map(statementRow => {
          const transactions = statementRow.items;

          const filteredVisibleTransactions =
            this.filterVisibleTransactions(transactions);

          const filteredApprovedTransactions = this.filterApprovedTransactions(
            filteredVisibleTransactions
          );

          const filteredTransactionsByMovementType =
            this.filterTransactionsByMovementType(
              filteredApprovedTransactions,
              movementType ?? 'all'
            );

          const filteredTransactionsByCardsNumbers =
            this.filterTransactionsByCardsNumbers(
              filteredTransactionsByMovementType,
              cardsNumbers ?? []
            );

          const filteredTransactionsBySearchInput =
            this.filterTransactionsBySearchInput(
              filteredTransactionsByCardsNumbers,
              searchInput ?? ''
            );

          return {
            ...statementRow,
            items: filteredTransactionsBySearchInput
          };
        })
        .filter(statementRow => statementRow.items.length > 0) ?? []
    );
  }

  filterTransactionsWithUserNameBySearchInput(
    transactions: IAccountTransactionWithAdditionalInfo[],
    searchInput: string
  ): IAccountTransactionWithAdditionalInfo[] {
    if (!searchInput) return transactions;

    const formattedSearchInput = searchInput.toLowerCase();

    return transactions.filter(transaction => {
      const formattedDate = CustomDate.formatFromISO(transaction.datetime);
      const formattedAmount = Currency.format('BRL', transaction.amount);

      return (
        CustomString.removeAccents(transaction.userName)
          .toLowerCase()
          .includes(formattedSearchInput) ||
        formattedDate.toLowerCase().includes(formattedSearchInput) ||
        formattedAmount.toLowerCase().includes(formattedSearchInput) ||
        transaction.paymentDescription
          .toLowerCase()
          .includes(formattedSearchInput) ||
        (transaction.merchantName
          ?.toLowerCase()
          .includes(formattedSearchInput) ??
          transaction.cardNumber
            ?.toLowerCase()
            .includes(formattedSearchInput) ??
          false) ||
        transaction.amount
          .toString()
          .toLowerCase()
          .includes(formattedSearchInput)
      );
    });
  }

  filterAccountTransactionsWithUserName(
    transactions: IAccountTransactionWithAdditionalInfo[],
    cardsNumbers: string[],
    movementType: StatementMovementType,
    searchInput: string
  ): IAccountTransactionWithAdditionalInfo[] {
    const filteredVisibleTransactions =
      this.filterVisibleTransactions(transactions);

    const filteredApprovedTransactions = this.filterApprovedTransactions(
      filteredVisibleTransactions
    );

    const filteredTransactionsByMovementType =
      this.filterTransactionsByMovementType(
        filteredApprovedTransactions,
        movementType
      );

    const filteredTransactionsByCardsNumbers =
      this.filterTransactionsByCardsNumbers(
        filteredTransactionsByMovementType,
        cardsNumbers
      );

    const filteredTransactionsWithUserNameBySearchInput =
      this.filterTransactionsWithUserNameBySearchInput(
        filteredTransactionsByCardsNumbers,
        searchInput
      );

    return filteredTransactionsWithUserNameBySearchInput;
  }

  sortStatementByDate(
    statementRowA: IGenericStatement,
    statementRowB: IGenericStatement
  ): number {
    const dateA = new Date(statementRowA.date);
    const dateB = new Date(statementRowB.date);

    return dateA.getTime() - dateB.getTime();
  }

  sortStatementArrayByDateDesc<T extends IGenericStatement>(data: T[]): T[] {
    const statementToOrder = [...data];

    statementToOrder.sort(this.sortStatementByDate);

    return statementToOrder.reverse();
  }

  sortAscTransactionsByDatetime(
    transactionA: ITransaction,
    transactionB: ITransaction
  ): number {
    const dateTimeA = new Date(transactionA.datetime);
    const dateTimeB = new Date(transactionB.datetime);

    return dateTimeA.getTime() - dateTimeB.getTime();
  }

  sortDescTransactionsByDatetime(
    transactionA: ITransaction,
    transactionB: ITransaction
  ): number {
    const dateTimeA = new Date(transactionA.datetime);
    const dateTimeB = new Date(transactionB.datetime);

    return (dateTimeA.getTime() - dateTimeB.getTime()) * -1;
  }

  sortAscTransactionsByCardNumber(
    transactionA: ITransaction,
    transactionB: ITransaction
  ): number {
    const cardNumberA = transactionA.cardNumber ?? '';
    const cardNumberB = transactionB.cardNumber ?? '';

    if (cardNumberA < cardNumberB) return -1;
    if (cardNumberA > cardNumberB) return 1;

    return 0;
  }

  sortDescTransactionsByCardNumber(
    transactionA: ITransaction,
    transactionB: ITransaction
  ): number {
    const cardNumberA = transactionA.cardNumber ?? '';
    const cardNumberB = transactionB.cardNumber ?? '';

    if (cardNumberA < cardNumberB) return 1;
    if (cardNumberA > cardNumberB) return -1;

    return 0;
  }

  sortAscTransactionsByDescription(
    transactionA: ITransaction,
    transactionB: ITransaction
  ): number {
    const descriptionA =
      transactionA.merchantName ?? transactionA.paymentDescription ?? '';

    const descriptionB =
      transactionB.merchantName ?? transactionB.paymentDescription ?? '';

    if (descriptionA.toLowerCase() < descriptionB.toLowerCase()) return -1;
    if (descriptionA.toLowerCase() > descriptionB.toLowerCase()) return 1;

    return 0;
  }

  sortDescTransactionsByDescription(
    transactionA: ITransaction,
    transactionB: ITransaction
  ): number {
    const descriptionA =
      transactionA.merchantName ?? transactionA.paymentDescription ?? '';

    const descriptionB =
      transactionB.merchantName ?? transactionB.paymentDescription ?? '';

    if (descriptionA.toLowerCase() < descriptionB.toLowerCase()) return 1;
    if (descriptionA.toLowerCase() > descriptionB.toLowerCase()) return -1;

    return 0;
  }

  sortAscTransactionsByAmount(
    transactionA: ITransaction,
    transactionB: ITransaction
  ): number {
    const amountA = transactionA.amount;
    const amountB = transactionB.amount;

    return amountA - amountB;
  }

  sortDescTransactionsByAmount(
    transactionA: ITransaction,
    transactionB: ITransaction
  ): number {
    const amountA = transactionA.amount;
    const amountB = transactionB.amount;

    return (amountA - amountB) * -1;
  }

  sortAscAccountTransactionsByUserName(
    transactionA: IAccountTransactionWithAdditionalInfo,
    transactionB: IAccountTransactionWithAdditionalInfo
  ): number {
    const userNameA = transactionA.userName;
    const userNameB = transactionB.userName;

    if (userNameA.toLowerCase() < userNameB.toLowerCase()) return -1;
    if (userNameA.toLowerCase() > userNameB.toLowerCase()) return 1;

    return 0;
  }

  sortDescAccountTransactionsByUserName(
    transactionA: IAccountTransactionWithAdditionalInfo,
    transactionB: IAccountTransactionWithAdditionalInfo
  ): number {
    const userNameA = transactionA.userName;
    const userNameB = transactionB.userName;

    if (userNameA.toLowerCase() < userNameB.toLowerCase()) return 1;
    if (userNameA.toLowerCase() > userNameB.toLowerCase()) return -1;

    return 0;
  }

  formatTransactionDescription(
    transaction: ITransaction,
    lang: typeof langJSON,
    currentLangKey: LanguageKeyType
  ): string {
    const description =
      (transaction.merchantName ?? transaction.paymentDescription) ||
      lang.cards.statement.table.no_description[currentLangKey];

    if (transaction.isReversal === 'FULLY') {
      return `${lang.cards.statement.table.reversal[currentLangKey]}: ${description}`;
    }

    if (transaction.isReversal === 'PARTIALLY') {
      return `${lang.cards.statement.table.partial_reversal[currentLangKey]}: ${description}`;
    }

    return description;
  }
}

export default new Statement();
