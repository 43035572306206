enum TeamMembersQueryKeys {
  GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS',
  GET_TEAM_MEMBERS_ITEMS = 'GET_TEAM_MEMBERS_ITEMS',
  GET_TEAM_MEMBERS_RESUME = 'GET_TEAM_MEMBERS_RESUME',
  GET_RELATED_TEAM_MEMBERS = 'GET_RELATED_TEAM_MEMBERS',
  GET_TEAM_MEMBERS_PROJECT_OPTIONS = 'GET_TEAM_MEMBERS_PROJECT_OPTIONS',
  GET_TEAM_MEMBERS_POSITION_OPTIONS = 'GET_TEAM_MEMBERS_POSITION_OPTIONS',
  GET_TEAM_MEMBERS_COST_CENTER_OPTIONS = 'GET_TEAM_MEMBERS_COST_CENTER_OPTIONS',
  GET_TEAM_MEMBERS_LIMIT_POLICY_OPTIONS = 'GET_TEAM_MEMBERS_LIMIT_POLICY_OPTIONS',
  GET_TEAM_MEMBERS_APPROVAL_FLOW_OPTIONS = 'GET_TEAM_MEMBERS_APPROVAL_FLOW_OPTIONS'
}

export { TeamMembersQueryKeys };
