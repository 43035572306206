import { RectButton } from 'presentation/components/base/Button';

import { type ITitleProps } from './Title.types';

import { Container } from './Title.styles';

export function Title({
  text,
  boldText = false,
  color = 'mineShaft',
  buttonProps,
  backgroundColor = 'gallery'
}: ITitleProps): JSX.Element {
  return (
    <Container
      $boldText={boldText}
      $color={color}
      $hasButton={!!buttonProps}
      $backgroundColor={backgroundColor}
    >
      <p>{text}</p>

      {buttonProps && (
        <RectButton
          type='button'
          {...buttonProps}
        >
          {buttonProps.children}
        </RectButton>
      )}
    </Container>
  );
}
