import { useNavigate } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import { type StoreIdentifierToGaEventType } from 'presentation/pages/store/Store/Store.types';

import activeDirectoryIcon from 'shared/assets/images/store/activeDirectory/activeDirectory-icon.png';
import cardsIcon from 'shared/assets/images/store/cards/cards-icon.png';
import cardsPjIcon from 'shared/assets/images/store/cardsPj/cardsPj-icon.png';
import conciliationIcon from 'shared/assets/images/store/conciliation/conciliation-icon.png';
import horusIcon from 'shared/assets/images/store/horus/horus-icon.png';
import outsourcingIcon from 'shared/assets/images/store/outsourcing/outsourcing-icon.png';
import { useGa4 } from 'shared/hooks/global/useGa4/useGa4';

import {
  type IProductCardProps,
  type IUseProductCard,
  type IUseProductCardParams
} from './ProductCard.types';

export function useProductCard({
  identifier,
  status
}: IUseProductCardParams): IUseProductCard {
  const navigate = useNavigate();
  const { sendGaEvent } = useGa4();

  const {
    currentLangKey,
    lang: {
      store: { productCards }
    }
  } = useLangContext();

  function getName(): string {
    try {
      return productCards.name[identifier][currentLangKey];
    } catch {
      return '';
    }
  }

  function getStatusName(): string {
    try {
      return productCards.status[status as keyof typeof productCards.status][
        currentLangKey
      ];
    } catch {
      return '';
    }
  }

  function getDescription(): string {
    try {
      return productCards.descriptions[identifier][currentLangKey];
    } catch {
      return '';
    }
  }

  function getStatusMessage(): string {
    try {
      return productCards.statusMessage[
        status as keyof typeof productCards.statusMessage
      ][currentLangKey];
    } catch {
      return '';
    }
  }

  function getIcon(): string {
    switch (identifier) {
      case 'horus':
        return horusIcon;
      case 'cards':
        return cardsIcon;
      case 'cards-pj':
        return cardsPjIcon;
      case 'outsourcing':
        return outsourcingIcon;
      case 'conciliation':
        return conciliationIcon;
      case 'active-directory':
        return activeDirectoryIcon;
      default:
        return '';
    }
  }

  function handleRequestProductClick(
    handler: IProductCardProps['onRequestProductClick']
  ): void {
    const events: StoreIdentifierToGaEventType = {
      horus: 'store_clickSolicitarContatoHorus',
      'active-directory': 'store_clickSolicitarContatoAD',
      cards: 'store_clickSolicitarContatoCartaoCorporativo',
      conciliation: 'store_clickSolicitarContatoConciliacao',
      outsourcing: 'store_clickSolicitarContatoTerceirizacao',
      'cards-pj': 'store_clickSolicitarContatoCartaoEmpresarial'
    };

    sendGaEvent('store', events[identifier]);

    handler(identifier);
  }

  function handleProductCardClick(): void {
    const events: StoreIdentifierToGaEventType = {
      horus: 'store_clickCardHorus',
      'active-directory': 'store_clickCardAD',
      cards: 'store_clickCardCartaoCorporativo',
      conciliation: 'store_clickCardConciliacao',
      outsourcing: 'store_clickCardTerceirizacao',
      'cards-pj': 'store_clickCardCartaoEmpresarial'
    };

    sendGaEvent('store', events[identifier]);

    navigate(identifier);
  }

  function handleLearnMoreClick(): void {
    const events: StoreIdentifierToGaEventType = {
      horus: 'store_clickSaibaMaisHorus',
      'active-directory': 'store_clickSaibaMaisAD',
      cards: 'store_clickSaibaMaisCartaoCorporativo',
      conciliation: 'store_clickSaibaMaisConciliacao',
      outsourcing: 'store_clickSaibaMaisTerceirizacao',
      'cards-pj': 'store_clickSaibaMaisCartaoEmpresarial'
    };

    sendGaEvent('store', events[identifier]);

    navigate(identifier);
  }

  return {
    name: getName(),
    thumb: getIcon(),
    handleLearnMoreClick,
    handleProductCardClick,
    handleRequestProductClick,
    statusName: getStatusName(),
    description: getDescription(),
    statusMessage: getStatusMessage()
  };
}
