import {
  type IAnalysis,
  type IPersistenceAnalysis
} from 'data/modules/analytics/types/analytics.types';

class GetAnalysisMapper {
  toDomain(persistence: IPersistenceAnalysis): IAnalysis {
    return {
      averageByUser: persistence.average_by_user,
      mostUsedProject: persistence.most_used_project,
      mostUsedCostCenter: persistence.most_used_cost_center,
      averageApprovalTime: persistence.average_approval_time,
      mostUsedExpenseType: persistence.most_used_expense_type,
      mostUsedPaymentForm: persistence.most_used_payment_form
    };
  }
}

export default new GetAnalysisMapper();
