import {
  type IGetTypesOfFuelsPayload,
  type IGetTypesOfFuelsPayloadPersistence,
  type ITypeOfFuel,
  type ITypeOfFuelPersistence
} from 'data/modules/fuel';

class GetTypesOfFuelsMapper {
  // isso é por conta da função toDomain que é chamada dentro de map
  constructor() {
    this.toDomain = this.toDomain.bind(this);
  }

  toDomain(payload: ITypeOfFuelPersistence): ITypeOfFuel {
    return {
      id: payload.id,
      description: payload.description,
      active: payload.active === 1,
      parentId: payload.parent_id,
      subTypes: payload.subtypes ? payload.subtypes.map(this.toDomain) : null
    };
  }

  toPersistence(
    payload?: IGetTypesOfFuelsPayload
  ): IGetTypesOfFuelsPayloadPersistence {
    return {
      vehicle_id: payload?.vehicleId,
      with_subtypes: payload?.withSubtypes
    };
  }
}

export default new GetTypesOfFuelsMapper();
