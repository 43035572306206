import Select from 'react-select';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import {
  type LabelContentStylesType,
  type SelectStylesType
} from './Select.types';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const LabelAndButtonsContent = styled.div<
  AddPrefixToType<LabelContentStylesType>
>`
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;

  ${({ $hasSelectAllOptionsButton, $hasCleanButtonOutsideInput }) =>
    $hasSelectAllOptionsButton &&
    $hasCleanButtonOutsideInput &&
    css`
      @media screen and (max-width: ${({ theme }) =>
          theme.breakpoints.desktop}px) {
        flex-direction: column;
        align-items: start;

        > span {
          margin-top: 0.5rem;
        }
      }
    `}

  ${({ $hasLabel }) =>
    $hasLabel &&
    css`
      justify-content: space-between;
    `}

  ${({ $hasLabel, $hasCleanButtonOutsideInput }) =>
    !$hasLabel &&
    $hasCleanButtonOutsideInput &&
    css`
      justify-content: end;
    `};

  > label {
    color: ${({ theme, $hasError }) =>
      $hasError ? theme.colors.danger : theme.colors.mineShaft};
    font-size: 1.2rem;

    ${({ $isDisabled }) =>
      $isDisabled &&
      css`
        color: ${({ theme }) => theme.colors.neutral};
      `}
  }

  > span {
    display: flex;
    gap: 0.5rem;

    > button {
      width: auto;
      white-space: nowrap;
    }
  }
`;

export const StyledSelect = styled(Select)<AddPrefixToType<SelectStylesType>>`
  .react-select-custom__control {
    width: 100%;
    font-size: 1.2rem;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid
      ${({ $error, theme }) =>
        $error ? theme.colors.danger : theme.colors.mercury};
    min-height: 3.7rem;
    transition-duration: 0.5s;
    cursor: pointer;
  }

  .react-select-custom__control--is-disabled {
    pointer-events: auto;
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.alabaster};

    &:hover {
      border-color: ${({ theme }) => theme.colors.mercury};
    }
  }

  .react-select-custom__control--menu-is-open {
    border-color: ${({ theme }) => theme.colors.blueJeans};
    box-shadow: 1px 0 8px 0 ${({ theme }) => theme.colors.blueJeans};
  }

  .react-select-custom__dropdown-indicator {
    display: ${({ $hasArrowDownIndicator }) =>
      $hasArrowDownIndicator ? `` : 'none'};
    padding: 0 0.8rem 0 0;
  }

  .react-select-custom__indicator-separator {
    display: none;
  }

  .react-select-custom__menu {
    border-radius: 0;
    margin-top: 0.3rem;

    > * {
      font-size: 1.2rem;
      max-height: 25rem;
    }
  }

  .react-select-custom__menu-list {
    max-height: 12rem;
  }

  .react-select-custom__option {
    font-size: 1.2rem;
    background-color: ${({ theme }) => theme.colors.white};

    &:hover,
    &:focus {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.mercury};
    }
  }

  .react-select-custom__option--is-selected {
    background-color: ${({ theme }) => theme.colors.mercury};
    color: ${({ theme }) => theme.colors.black};
  }

  .react-select-custom__multi-value__remove:hover {
    background-color: #0000000d;
    color: ${({ theme }) => theme.colors.black};
  }

  .react-select-custom__multi-value__label {
    font-size: 1.2rem;
  }
`;
