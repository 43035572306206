import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import {
  OnboardingText,
  OnboardingTitle
} from 'presentation/pages/cards/Onboarding/components/General';

import { Container, IconContainer } from './LastScreen.styles';

export function LastScreen(): JSX.Element {
  const [lastScreen, currentLangKey] = useLangContext(state => [
    state.lang.cards.onboarding.company.last_screen,
    state.currentLangKey
  ]);

  return (
    <Container>
      <IconContainer>
        <BaseIcon
          name='clock'
          size={4}
        />
      </IconContainer>
      <OnboardingTitle>{lastScreen.title[currentLangKey]}</OnboardingTitle>
      <OnboardingText>{lastScreen.message[currentLangKey]}</OnboardingText>
    </Container>
  );
}
