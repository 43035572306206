import { useEffect, useRef, useState } from 'react';

import {
  useGetPaginatedMembers,
  useInfinitePaginatedMembers
} from 'data/modules/fuel';

import { useDebounce, useInfiniteScroll } from 'shared/hooks/global';

import {
  type IUseUpdateVehicleSelectMembers,
  type IUseUpdateVehicleSelectMembersParams
} from './UpdateVehicleSelectMembers.types';

export function useUpdateVehicleSelectMembers({
  vehicleId
}: IUseUpdateVehicleSelectMembersParams): IUseUpdateVehicleSelectMembers {
  const infiniteScrollRef = useRef<null | HTMLDivElement>(null);
  const rootRef = useRef<null | HTMLDivElement>(null);

  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search);

  const [startFetchingUnlinkedMembers, setStartFetchingUnlinkedMembers] =
    useState(false);

  const { paginatedMembers } = useGetPaginatedMembers({
    page: 1
  });

  const { paginatedMembers: paginatedLinkedMembers } = useGetPaginatedMembers({
    linked: true,
    vehicleId
  });

  const [selectedMembers, setSelectedMembers] = useState(
    paginatedLinkedMembers?.total ?? 0
  );

  const {
    isLoadingInfiniteMembers: isLoadingLinkedInfiniteMembers,
    membersPagination: linkedMembersPagination,
    infiniteMembers: linkedInfiniteMembers
  } = useInfinitePaginatedMembers({
    perPage: 30,
    search: debouncedSearch,
    vehicleId,
    linked: true
  });

  const {
    isLoadingInfiniteMembers: isLoadingUnlinkedInfiniteMembers,
    membersPagination: unlinkedMembersPagination,
    infiniteMembers: unlinkedInfiniteMembers
  } = useInfinitePaginatedMembers({
    perPage: 30,
    search: debouncedSearch,
    vehicleId,
    linked: false,
    enabled: startFetchingUnlinkedMembers
  });

  useEffect(() => {
    // esse if é para que, caso a lista dos membros vinculados seja menor que 30, inicia as requests para os membros não vinculados
    if (
      !isLoadingLinkedInfiniteMembers &&
      linkedInfiniteMembers !== undefined &&
      linkedInfiniteMembers?.length < 30 &&
      !startFetchingUnlinkedMembers
    ) {
      setStartFetchingUnlinkedMembers(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingLinkedInfiniteMembers, linkedInfiniteMembers]);

  function handleDispatchRequestOnScroll(): void {
    if (isLoadingLinkedInfiniteMembers) {
      return;
    }

    const showedAllLinkedMembers =
      !isLoadingLinkedInfiniteMembers && !linkedMembersPagination.hasNextPage;

    if (showedAllLinkedMembers && !startFetchingUnlinkedMembers) {
      setStartFetchingUnlinkedMembers(true);
      return;
    }

    if (startFetchingUnlinkedMembers) {
      unlinkedMembersPagination.fetchNextPage();
      return;
    }

    linkedMembersPagination.fetchNextPage();
  }

  const hasNextPageToFetch =
    !startFetchingUnlinkedMembers ||
    (startFetchingUnlinkedMembers && unlinkedMembersPagination.hasNextPage);

  const isFetchingNextPage =
    unlinkedMembersPagination.isFetchingNextPage ||
    linkedMembersPagination.isFetchingNextPage;

  useInfiniteScroll({
    hasNextPage: hasNextPageToFetch,
    isFetchingNextPage,
    actionFn: handleDispatchRequestOnScroll,
    infiniteScrollRef: infiniteScrollRef.current,
    rootRef: rootRef.current,
    rootMargin: '30%'
  });

  function handleChangeSearch(value: string): void {
    setStartFetchingUnlinkedMembers(false);
    setSearch(value);
  }

  const showSelectAll = debouncedSearch === '';

  const totalMembers = paginatedMembers?.total ?? 0;

  return {
    infiniteScrollRef,
    rootRef,
    linkedInfiniteMembers,
    unlinkedInfiniteMembers,
    isLoadingLinkedInfiniteMembers,
    isLoadingUnlinkedInfiniteMembers,
    isFetchingInfiniteMembersNextPage: isFetchingNextPage,
    handleChangeSearch,
    search,
    showSelectAll,
    totalMembers,
    selectedMembers,
    setSelectedMembers
  };
}
