import { useQueryClient } from '@tanstack/react-query';

import { ExpensesQueryKeys } from 'data/modules/expenses/keys/expenses.keys';
import ExpensesService from 'data/modules/expenses/services/Expenses.service';
import {
  type IUseValidateMileagePolicyByUser,
  type IValidateMileagePolicyByUserPayload,
  type IValidateMileagePolicyByUserResponse
} from 'data/modules/expenses/types/expenses.types';

export function useValidateMileagePolicyByUser(): IUseValidateMileagePolicyByUser {
  const queryClient = useQueryClient();

  async function getEnsuredValidateMileagePolicyByUser(
    payload: IValidateMileagePolicyByUserPayload
  ): Promise<IValidateMileagePolicyByUserResponse | null> {
    try {
      const response = await queryClient.ensureQueryData({
        queryKey: [ExpensesQueryKeys.VALIDATE_MILEAGE_POLICY_BY_USER, payload],
        queryFn: async () =>
          await ExpensesService.validateMileagePolicyByUser(payload)
      });

      return response;
    } catch (e) {
      return null;
    }
  }

  return {
    getEnsuredValidateMileagePolicyByUser
  };
}
