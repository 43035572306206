import { tokens } from 'ds';
import styled from 'styled-components';

import { type OperationValueStyleType } from './ChargeCardsTableRow.types';
import { type AddPrefixToType } from 'ds/types';

export const CardDetailsButton = styled.button`
  background: none;
  border: none;
`;

export const OperationValue = styled.p<
  AddPrefixToType<OperationValueStyleType>
>`
  color: ${({ $type }) =>
    $type === 'CREDIT'
      ? tokens.feedbackColorSuccessPure
      : tokens.feedbackColorErrorPure};
  font-weight: ${tokens.fontWeightBold};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  line-height: 2.2rem;
  letter-spacing: 0.25px;
`;
