import { useQueryCache } from 'data/cache';
import {
  ApprovalFlowsQueryKeys,
  ApprovalFlowsService,
  type IUseGetApprovalFlows,
  type IUseGetApprovalFlowsParams
} from 'data/modules/approvalFlows';

export function useGetApprovalFlows({
  enabled,
  ...payload
}: IUseGetApprovalFlowsParams): IUseGetApprovalFlows {
  const { data, isLoading, isFetching } = useQueryCache({
    enabled,
    queryKey: [ApprovalFlowsQueryKeys.GET_APPROVAL_FLOWS, payload],
    queryFn: async () => await ApprovalFlowsService.getApprovalFlows(payload)
  });

  return {
    approvalFlows: data ?? [],
    isLoadingApprovalFlows: isLoading || isFetching
  };
}
