import {
  type IAnalysisUserHeader,
  type IAnalysisUserHeaderPayload,
  type IPersistenceAnalysisUserHeader,
  type IPersistenceAnalysisUserHeaderPayload
} from 'data/modules/analytics';

import { Currency } from 'shared/utils/format';

class GetAnalysisUserHeaderMapper {
  toPersistence(
    domain: IAnalysisUserHeaderPayload
  ): IPersistenceAnalysisUserHeaderPayload {
    return {
      page: domain.page,
      cost_centers: domain.costCenters,
      date_to_consider: domain.dateToConsider,
      expense_types: domain.expenseTypes,
      final_date: domain.finalDate,
      initial_date: domain.initialDate,
      payment_form: domain.paymentForm,
      payment_type: domain.paymentType,
      projects: domain.projects,
      report_codes: domain.reportCodes,
      status: domain.status,
      users: domain.users,
      per_page: domain.perPage
    };
  }

  toDomain(persistence: IPersistenceAnalysisUserHeader): IAnalysisUserHeader {
    return {
      averagePerExpense: Currency.parseToFloat(
        persistence?.average_per_expense ?? '0'
      ),
      departmentPosition: persistence.department_position,
      name: persistence.name,
      sumNoRefundable: Currency.parseToFloat(
        persistence?.sum_no_refundable ?? '0'
      ),
      sumRefundable: Currency.parseToFloat(persistence?.sum_refundable ?? '0'),
      total: Currency.parseToFloat(persistence?.total ?? '0'),
      totalExpenses: persistence.total_expenses,
      totalReports: persistence.total_reports
    };
  }
}

export default new GetAnalysisUserHeaderMapper();
