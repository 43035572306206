import {
  type ICurrency,
  type IPersistenceWebCurrency
} from 'data/modules/currencies/types/currencies.types';

class GetCurrenciesMapper {
  toDomain(persistence: IPersistenceWebCurrency): ICurrency {
    return {
      id: persistence.id,
      description: persistence.description,
      isoCode: persistence.iso_code,
      isoNumeric: persistence.iso_numeric,
      symbol: persistence.symbol
    };
  }
}

export default new GetCurrenciesMapper();
