import { type ReactNode } from 'react';

import { DSIcons, SkeletonLoader } from 'ds';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'presentation/components/base/Tooltip';
import { Movements } from 'presentation/pages/cards/Movements/utils';

import { CustomString } from 'shared/utils/custom';

import { useTransactionExpenseStatus } from './useTransactionExpenseStatus';

import { type ITransactionExpenseStatusProps } from './TransactionExpenseStatus.types';

import {
  IconContainer,
  TryAgainButton
} from './TransactionExpenseStatus.styles';

export function TransactionExpenseStatus({
  transaction
}: ITransactionExpenseStatusProps): ReactNode {
  const { status, isErrorExpense, isLoadingExpense, refetchExpense } =
    useTransactionExpenseStatus(transaction);

  const { t } = useTranslation('global');

  return (
    <>
      {isLoadingExpense && !isErrorExpense && (
        <SkeletonLoader
          color={'neutralColorHighMedium'}
          width='24px'
          height='24px'
        />
      )}

      {isErrorExpense && !isLoadingExpense && (
        <TryAgainButton>
          <DSIcons.ArrowGoBackIcon
            onClick={async () => await refetchExpense()}
            role='button'
          />
        </TryAgainButton>
      )}

      {!isErrorExpense &&
        !isLoadingExpense &&
        (status ? (
          <Tooltip
            id={`expense-status-${status.toLowerCase()}`}
            content={t(
              `expense${CustomString.capitalizeWord(status)}` as never
            )}
          >
            <IconContainer>
              {Movements.getExpenseStatusIcon(status)}
            </IconContainer>
          </Tooltip>
        ) : (
          Movements.getExpenseStatusIcon(status)
        ))}
    </>
  );
}
